import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon, IconButton } from '@cbrebuild/blocks';
import Select from '../../nucleus/select/select';
import tasksService from '../../services/tasks-service';
import userEventService from '../../services/user-event-service';

class ReminderTaskAlert extends React.Component {
  state = {
    dismissTask: false,
    snoozeTask: false,
  };

  completeTask = (taskID) => {
    // mark task associated with alert as complete
    const update = { is_complete: true };
    tasksService.updateTask(taskID, update)
      .then(() => {
        // track when a user completes a task
        const eventMetadata = {
          eventCategory: 'Task Alerts',
          eventAction: 'task_completed',
          eventLabel: 'task_completed_from_alert',
        };
        userEventService.trackEvent(eventMetadata);
      })
      .catch((error) => {
        console.log('Error completing task: ', error);
      });
  };

  completeTaskAndDismissAlert = () => {
    // dismiss alert object
    const { task } = this.props;
    this.setState({
      dismissTask: true,
    });
    this.completeTask(task.object_id);
    setTimeout(() => {
      this.props.handleDismissTaskAndAlert(task.id);
    }, 800);
  };

  handleReminderTimeTrackingEvent = (snoozeTime) => {
    const eventMetadata = {
      eventCategory: 'Task Alerts',
      eventAction: 'task_alerts_reminder_clicked',
      eventLabel: snoozeTime,
    };
    userEventService.trackEvent(eventMetadata);
  };

  handleDropdownChange = (e, task) => {
    const updatedTask = task;
    const taskID = task.id;
    const currentTime = new Date();
    let snoozeTime = '';
    let trackingSnoozeTime = ''; // specifically for user event tracking

    const { handleSnoozeAlert } = this.props;

    if (e.display_name === 'In 1 Hour') {
      updatedTask.alert_datetime = new Date(currentTime.setHours(currentTime.getHours() + 1)).toISOString();
      snoozeTime = 'Task Snoozed for 1 Hour';
      trackingSnoozeTime = 'task_alerts_reminder_time_1hour';
    } else if (e.display_name === 'In 1 Day') {
      updatedTask.alert_datetime = new Date(currentTime.setHours(currentTime.getHours() + 24)).toISOString();
      snoozeTime = 'Task Snoozed for 1 Day';
      trackingSnoozeTime = 'task_alerts_reminder_time_1day';
    } else {
      updatedTask.alert_datetime = new Date(currentTime.setDate((currentTime.getDate() + 7))).toISOString();
      snoozeTime = 'Task Snoozed for 1 Week';
      trackingSnoozeTime = 'task_alerts_reminder_time_1week';
    }
    this.setState({
      snoozeTask: true,
    });
    setTimeout(() => {
      handleSnoozeAlert(taskID, updatedTask.alert_datetime, snoozeTime);
      this.handleReminderTimeTrackingEvent(trackingSnoozeTime);
    }, 800);
  };

  render() {
    const {
      task,
      formatDate,
      handleNavigateToTaskTrackingEvent,
      turducken,
      taskAlertLink,
    } = this.props;
    const {
      dismissTask,
      snoozeTask,
    } = this.state;
    const markAsReadTaskItemClass = task.read === null ? 'task-item-unread' : '';
    const completeTaskItemClass = dismissTask === true ? 'task-item-complete' : '';
    const snoozeTaskItemClass = snoozeTask === true ? 'task-item-snoozed' : '';
    const renderFormattedDate = task.alert_datetime ? formatDate(task.alert_datetime) : '';
    const { deal } = task.related_object;
    const dealLink = deal ? `/deals/detail/${deal.id}/overview` : '';

    const taskControlOptions = [
      {
        value: 'hour',
        display_name: 'In 1 Hour',
      },
      {
        value: 'day',
        display_name: 'In 1 Day',
      },
      {
        value: 'week',
        display_name: 'In 1 Week',
      },
    ];

    return (
      <li className={`reminder-task-alert-item ${markAsReadTaskItemClass} ${completeTaskItemClass} ${snoozeTaskItemClass}`}>
        <div className="task">
          <div>
            <IconButton
              className="task-status"
              iconName="okay"
              onClick={this.completeTaskAndDismissAlert}
              variant="basic"
            />
          </div>
          <Link
            to={{
              pathname: taskAlertLink,
              taskId: task.related_object.id,
            }}
            {...(turducken ? { target: '_self' } : {})}
            onClick={handleNavigateToTaskTrackingEvent}
          >
            {task.message}
          </Link>
        </div>
        {deal && (
        <a href={dealLink}>
          <p className="deal">{deal.name} </p>
        </a>)}
        <p className="date">{renderFormattedDate}</p>
        <div className="task-controls">
          <Icon iconName="alarm" />
          <Select
            className="task-reminder-times"
            defaultOption="Remind Me"
            options={taskControlOptions}
            onChange={e => this.handleDropdownChange(e, task)}
          />
        </div>
      </li>
    );
  }
}

export default ReminderTaskAlert;

ReminderTaskAlert.propTypes = {
  handleDismissTaskAndAlert: PropTypes.func.isRequired,
  handleNavigateToTaskTrackingEvent: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
  handleSnoozeAlert: PropTypes.func.isRequired,
  task: PropTypes.shape({
    alert_datetime: PropTypes.string,
    id: PropTypes.number,
    message: PropTypes.string,
    object_id: PropTypes.number,
    read: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape(),
    ]),
    related_object: PropTypes.shape({
      id: PropTypes.number,
      deal: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    }),
  }).isRequired,
  turducken: PropTypes.bool.isRequired,
  taskAlertLink: PropTypes.string.isRequired,
};

