import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../nucleus/modal/modal';
import TeamCardMembers from '../teams/team-card-members';
import TeamColorPicker from '../teams/team-color-picker';

class CreateTeamModal extends React.Component {
  constructor(props) {
    super(props);

    this.teamNameErrorMessage = 'Team Name is required';
    this.teamMemberErrorMessage = 'Please add a team member before continuing.';

    this.state = {
      teamName: props.teamName,
      teamMembers: [],
      teamNameErrorMessage: '',
      teamMemberErrorMessage: '',
      teamColor: this.props.teamColor,
    };
  }

  componentDidMount() {
    this.mapUsertoTeamMember();
  }

  onAddTeamMember = (teamData) => {
    const email = {
      work: teamData.newTeamMember.work_email,
    };
    const newTeamMember = {
      avatar: teamData.newTeamMember.avatar,
      email,
      first_name: teamData.newTeamMember.first_name,
      last_name: teamData.newTeamMember.last_name,
      id: teamData.newTeamMember.user_id,
    };
    this.setState({
      teamMembers: [...this.state.teamMembers, newTeamMember],
      teamMemberErrorMessage: '',
    });
  };

  onRemoveTeamMember = (teamData) => {
    const members = this.state.teamMembers.filter(member => member.id !== teamData.memberToRemove.id);
    if (members.length === 0) {
      this.setState({ teamMemberErrorMessage: this.teamMemberErrorMessage });
    }
    this.setState({ teamMembers: members });
  };

  handleTeamNameChange = (teamName) => {
    this.setState({ teamName, teamNameErrorMessage: teamName ? '' : this.teamNameErrorMessage });
  };

  handleCreateTeam = () => {
    const {
      teamName,
      teamMembers,
      teamColor,
    } = this.state;
    const {
      onSubmit,
      onClose,
      isAdminPage,
    } = this.props;
    const data = {
      teamName,
      teamMembers,
      teamColor,
    };
    const isTeamMemberError = (isAdminPage && teamMembers.length === 0);
    const isTeamNameError = (!teamName || teamName.trim() === '');
    if (isTeamMemberError || isTeamNameError) {
      if (isTeamNameError) {
        this.setState({ teamNameErrorMessage: this.teamNameErrorMessage });
      }
      if (isTeamMemberError) {
        this.setState({ teamMemberErrorMessage: this.teamMemberErrorMessage });
      }
    } else {
      onClose();
      onSubmit(data);
    }
  };

  handleSetColor = (color) => {
    this.setState({ teamColor: color });
  };

  mapUsertoTeamMember = () => {
    const {
      userData,
      isAdminPage,
    } = this.props;
    if (!isAdminPage) {
      const teamMember = {
        id: userData.id,
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: { work: userData.work_email },
        avatar: userData.avatar,
      };
      this.setState({
        teamMembers: [teamMember],
      });
    }
  };

  render() {
    const {
      teamName,
      teamMembers,
      teamNameErrorMessage,
      teamMemberErrorMessage,
    } = this.state;
    const {
      onClose,
      userData,
      teamColor,
      isAdminPage,
    } = this.props;

    return (
      <Modal
        className="create-team-modal"
        modalHeader="New Team"
        primaryButtonText="Create Team"
        handleModalSubmit={this.handleCreateTeam}
        secondaryButtonText="Cancel"
        handleSecondaryButton={onClose}
        handleModalToggle={onClose}
        modalWidth={650}
        showModal
      >
        <div className="team-header">
          <div className={`team-name${teamNameErrorMessage ? ' missing-team-name' : ''}`}>
            <label><span className={`${teamNameErrorMessage ? 'missing-team-name-asterisk' : ''}`}>*</span> Team Name</label>
            <input
              type="text"
              value={teamName}
              maxLength="50"
              onChange={(e) => { e.persist(); this.handleTeamNameChange(e.target.value); }}
              required
            />
            {teamNameErrorMessage && <p>{teamNameErrorMessage}</p>}
          </div>
          <TeamColorPicker
            currentColor={teamColor}
            setColor={this.handleSetColor}
          />
        </div>
        <TeamCardMembers
          userData={userData}
          teamName={teamName}
          teamMembers={teamMembers}
          isCreating
          isAdminPage={isAdminPage}
          onAddTeamMember={this.onAddTeamMember}
          onRemoveTeamMember={this.onRemoveTeamMember}
          errorMessage={teamMemberErrorMessage}
        />
      </Modal>
    );
  }
}

CreateTeamModal.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    work_email: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
  teamName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  teamColor: PropTypes.string.isRequired,
  isAdminPage: PropTypes.bool,
};

CreateTeamModal.defaultProps = {
  teamName: '',
  isAdminPage: false,
};

export default CreateTeamModal;
