const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export default async function getCroppedImg(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop && pixelCrop.width;
  canvas.height = pixelCrop && pixelCrop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    (pixelCrop && pixelCrop.x),
    (pixelCrop && pixelCrop.y),
    (pixelCrop && pixelCrop.width),
    (pixelCrop && pixelCrop.height),
    0,
    0,
    (pixelCrop && pixelCrop.width),
    (pixelCrop && pixelCrop.height),
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, 'image/jpeg');
    } catch (error) {
      reject();
    }
  });
}

