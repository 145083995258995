import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import AdditionalCommissionItem from './AdditionalCommissionItem';
import AddItem from '../../../nucleus/add-item/add-item';
import Currency from '../../../nucleus/formats/currency';
import { createAdditionalCommission } from '../../../redux/actions/additional-commissions-actions';
import { selectAdditionalCommissionAmountTotal } from '../../../redux/selectors/voucher-selectors';

const selectAdditionCommissionIds = state => state.voucherAdditionalCommissions.additionalCommissions.map(({ id }) => id);

const AdditionalCommissions = ({
  dealId, dealType, showValidation,
}) => {
  const considerationIds = useSelector(selectAdditionCommissionIds, shallowEqual);
  const totalCommissions = useSelector(selectAdditionalCommissionAmountTotal, shallowEqual);
  const options = useSelector(state => state.voucher.options.additionalCommissionTypeOptions);
  const dispatch = useDispatch();

  return (
    <div className="financials-section-wrapper">
      <h3>
        {(dealType === 'consulting' || dealType === 'other') ? 'Commissions' : 'Additional Commissions'}
      </h3>
      <div className="table additional-commissions-table">
        <div className="table-header">
          <div className="table-cell">Commission Type</div>
          <div className="table-cell">Quantity</div>
          <div className="table-cell">Amount($)</div>
          <div className="table-cell">Additional Commission</div>
          <div className="table-cell" />
        </div>
        {considerationIds.map(id => <AdditionalCommissionItem key={id} id={id} showValidation={showValidation} />)}
        <div>
          <AddItem
            label="Add Commission"
            onClick={() => dispatch(createAdditionalCommission({
              acommission_type: options.filter(obj => obj.display_name === 'Consulting Fee')[0].value,
              total_units: null,
              amount: null,
              transaction: dealId,
            }))}
          />
        </div>
        <div className="table-row table-footer">
          <div className="table-cell">Totals</div>
          <div className="table-cell" />
          <div className="table-cell" />
          <div className="table-cell">
            <Currency
              value={totalCommissions}
              emptyState="$0.00"
            />
          </div>
          <div className="table-cell" />
        </div>
      </div>
    </div>
  );
};

AdditionalCommissions.propTypes = {
  dealId: PropTypes.number.isRequired,
  dealType: PropTypes.string.isRequired,
  showValidation: PropTypes.bool.isRequired,
};

export default AdditionalCommissions;
