import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import FeatureFlagListItem from '../../components/labs/feature-flag-list-item/feature-flag-list-item';
import userOptionsService from '../../services/user-options-service';
import { loadDealAndTransactionCount } from '../../redux/actions/deals-actions';
import userEventService from '../../services/user-event-service';

const FETCH_STATUS = {
  LOADING: 1,
  EMPTY: 2,
  OK: 3,
};

const LabsPage = ({
  loadUserOptions,
}) => {
  const [featureFlags, setFeatureFlags] = useState([]);
  const [fetchStatus, setFetchStatus] = useState(FETCH_STATUS.LOADING);
  const dispatch = useDispatch();

  useEffect(() => {
    userOptionsService.fetchFeatureFlags()
      .then(({ results }) => {
        setFeatureFlags(results);
        setFetchStatus(results.length ? FETCH_STATUS.OK : FETCH_STATUS.EMPTY);
      })
      .catch(() => {
        setFetchStatus(FETCH_STATUS.EMPTY);
      });
  }, []);

  // this only reloads the userOptions after a debounce so it doesn't do it on every checkbox change
  const handleLoadOptions = useCallback(debounce(loadUserOptions, 1000), []);

  const trackLeaseAbstractionFlag = (eventValue) => {
    userEventService.trackEvent({
      eventAction: 'lease_abstraction_flag_changed',
      eventCategory: 'Flag Action',
      eventValue,
    });
  };

  // updates the feature flag on checkbox change
  const handleOnFlagChange = (updatedFlag) => {
    const updatedFeatureFlags = featureFlags.map(featureFlag => (featureFlag.feature_flag === updatedFlag.feature_flag ? updatedFlag : featureFlag));
    setFeatureFlags(updatedFeatureFlags);
    userOptionsService.updateFeatureFlags({
      feature_flag: updatedFlag.feature_flag,
      enabled: updatedFlag.enabled,
    })
      .then(handleLoadOptions)
      .then(() => {
        dispatch(loadDealAndTransactionCount());
      })
      .then(() => {
        if (updatedFlag.feature_flag === 'lease_abstraction_flag') {
          trackLeaseAbstractionFlag(updatedFlag.enabled);
        }
      })
      .catch((error) => {
        console.error('Error updating feature flags: ', error);
      });
  };

  if (fetchStatus === FETCH_STATUS.LOADING) {
    return null;
  }
  return (
    <div className="labs-page page-container">
      <div>
        <div className="card-default info-card">
          <h2>Enable Labs</h2>
          {fetchStatus === FETCH_STATUS.OK && (
            <p>
              Want to try the latest features before anyone else? Check out our options below!
            </p>
          )}
          {fetchStatus === FETCH_STATUS.EMPTY && (
            <p>
              Want to try the latest features before anyone else? As new features become available for testing, you will see them appear in the list below.
            </p>
          )}
        </div>
        {fetchStatus === FETCH_STATUS.OK && (
          <div className="card-default">
            <h2 className="title">Available Features</h2>
            <ul className="flag-list">
              {featureFlags.map(flag => (
                <FeatureFlagListItem
                  key={flag.feature_flag}
                  flag={flag}
                  onChange={handleOnFlagChange}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

LabsPage.propTypes = {
  loadUserOptions: PropTypes.func.isRequired,
};

export default LabsPage;
