import React from 'react';
import PropTypes from 'prop-types';
import { fetchTeams } from '../../services/teams-service';

import AutocompleteFlyout from '../autocomplete/autocomplete-flyout';
import TeamTile from '../teams/team-tile';

class TeamAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: props.initSearchTerm,
    };
  }

  onSelect = (team) => {
    const {
      onSelect,
    } = this.props;
    if (team && typeof team !== 'string') {
      onSelect(team);
    } else if (this.props.initSearchTerm || this.props.initSearchTerm === '') {
      this.setState({ searchTerm: team });
      onSelect();
    }
  }

  getName = (team) => {
    if (this.props.initSearchTerm) {
      return `${team.name}`;
    }
    return '';
  };

  renderListItem = result => (
    <TeamTile
      team={result}
    />
  );

  render() {
    const {
      errorMessage,
      isDisabled,
      placeholder,
      filterResultsPredicate,
    } = this.props;
    return (
      <AutocompleteFlyout
        isDisabled={isDisabled}
        initSearchTerm={this.props.initSearchTerm || this.state.searchTerm}
        fetchRequest={fetchTeams}
        filterResultsPredicate={filterResultsPredicate}
        requestParams={{ page_size: 25 }}
        onChange={this.onSelect}
        placeholder={placeholder}
        findSelectedResultAttribute={this.getName}
        renderListItem={this.renderListItem}
        emptyState="Team Not Found"
        errorMessage={errorMessage}
        dataE2e="new-deal-team-textbox"
        clickReturnsAll
      />
    );
  }
}

TeamAutocomplete.propTypes = {
  isDisabled: PropTypes.bool,
  /** Callback for when a user clicks on a result */
  onSelect: PropTypes.func.isRequired,
  /** An ErrorMessage to show if field is required */
  errorMessage: PropTypes.string,
  /** The string you want to use in the input as a placeholder  */
  placeholder: PropTypes.string,
  /** function to filter out search results, e.g.
   * filtering out existing team members when adding new ones  */
  filterResultsPredicate: PropTypes.func,
  /** The string you want to use in the input to represent a broker  */
  initSearchTerm: PropTypes.string,
};

TeamAutocomplete.defaultProps = {
  isDisabled: false,
  errorMessage: '',
  placeholder: 'Search Teams',
  filterResultsPredicate: null,
  initSearchTerm: undefined,
};

export default TeamAutocomplete;
