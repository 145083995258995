import React from 'react';
import PropTypes from 'prop-types';
import { fetchOfficeNames } from '../../services/voucher/commissions-service';
import SearchableDropdown from './searchable-dropdown';

export const getDisplayText = ({ managing_office_code, managing_office_name }) => `${managing_office_name} (${managing_office_code})`;
export default function ManagingOfficeAutocomplete({
  errorMessage,
  onBlur,
  onSelect,
  placeholder,
  voucher_office_allocation_name,
}) {
  return (
    <SearchableDropdown
      errorMessage={errorMessage}
      fetchResults={fetchOfficeNames}
      getDisplayText={getDisplayText}
      onBlur={onBlur}
      onSelect={onSelect}
      placeholder={placeholder}
      searchableValues={['managing_office_code', 'managing_office_name']}
      value={voucher_office_allocation_name}
    />
  );
}

ManagingOfficeAutocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  voucher_office_allocation_name: PropTypes.string,
  onBlur: PropTypes.func,
};
ManagingOfficeAutocomplete.defaultProps = {
  errorMessage: '',
  placeholder: '',
  voucher_office_allocation_name: '',
  onBlur: undefined,
};
