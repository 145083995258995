import React from 'react';
import PropTypes from 'prop-types';

import FileUploadAccordian from '../../file-upload-accordian';
import countries from '../../../../data/countries.json';
import states from '../../../../data/states.json';
import {
  LeaseAbstractionField,
  LeaseAbstractionFieldType,
} from '../../leaseAbstractionField';

import '../../lease-abstraction.scss';

const getFilteredStates = (countryName = 'United States') => {
  const found = countries.find(c => c.display_name === countryName);
  return states.filter(s => s.country_id === found?.country_id);
};

const LandlordInformation = ({
  modalFieldState,
  modalFieldChangeHandler,
  fieldErrorMap,
}) => (
  <FileUploadAccordian accordianTitle="Landlord Information">
    <LeaseAbstractionField
      key="landlordCompanyName"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.landlordCompanyName,
        label: 'Company Name',
        errorMessage: fieldErrorMap.landlordCompanyName,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('landlordCompanyName', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="landlordCountryName"
      fieldType={LeaseAbstractionFieldType.SELECT}
      fieldData={{
        data: modalFieldState.landlordCountryName,
        label: 'Country',
        errorMessage: fieldErrorMap.landlordCountryName,
        options: countries,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler(
          'multipleFieldNames',
          {
            landlordCountryName: {
              ...modalFieldState.landlordCountryName,
              value: event.value,
            },
            landlordStateName: {
              ...modalFieldState.landlordStateName,
              value: '',
            },
            filteredStatesForLandlord: {
              value: getFilteredStates(event.value),
            },
          },
          'multiple',
        );
      }}
    />
    <LeaseAbstractionField
      key="landlordAddressLine1"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.landlordAddressLine1,
        label: 'Address Line 1',
        errorMessage: fieldErrorMap.landlordAddressLine1,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('landlordAddressLine1', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="landlordAddressLine2"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.landlordAddressLine2,
        label: 'Address Line 2',
        errorMessage: fieldErrorMap.landlordAddressLine2,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('landlordAddressLine2', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="landlordCity"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.landlordCity,
        label: 'City',
        errorMessage: fieldErrorMap.landlordCity,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('landlordCity', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="landlordStateName"
      fieldType={LeaseAbstractionFieldType.SELECT}
      fieldData={{
        data: modalFieldState.landlordStateName,
        label: 'State',
        errorMessage: fieldErrorMap.landlordStateName,
        options: modalFieldState.filteredStatesForLandlord.value,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('landlordStateName', { value: event.value });
      }}
    />
    <LeaseAbstractionField
      key="landlordZip"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.landlordZip,
        label: 'Zip',
        errorMessage: fieldErrorMap.landlordZip,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('landlordZip', {
          value: event.target.value,
        });
      }}
    />
    <p className="file-modal-sub-heading">Point of Contact</p>
    <LeaseAbstractionField
      key="landlordPOCFirstName"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.landlordPOCFirstName,
        label: 'First Name',
        errorMessage: fieldErrorMap.landlordPOCFirstName,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('landlordPOCFirstName', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="landlordPOCLastName"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.landlordPOCLastName,
        label: 'Last Name',
        errorMessage: fieldErrorMap.landlordPOCLastName,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('landlordPOCLastName', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="landlordPOCPhone"
      fieldType={LeaseAbstractionFieldType.PHONE}
      fieldData={{
        data: modalFieldState.landlordPOCPhone,
        label: 'Phone',
        errorMessage: fieldErrorMap.landlordPOCPhone,
      }}
      fieldChangeHandler={(value, isValid) => {
        modalFieldChangeHandler(
          'multipleFieldNames',
          {
            landlordPOCPhone: {
              ...modalFieldState.landlordPOCPhone,
              value,
            },
            landlordPOCPhoneValid: isValid,
          },
          'multiple',
        );
      }}
    />
    <LeaseAbstractionField
      key="landlordPOCEmail"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.landlordPOCEmail,
        label: 'E-Mail Address',
        errorMessage: fieldErrorMap.landlordPOCEmail,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('landlordPOCEmail', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="landlordPOCJobTitle"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.landlordPOCJobTitle,
        label: 'Job Title',
        errorMessage: fieldErrorMap.landlordPOCJobTitle,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('landlordPOCJobTitle', {
          value: event.target.value,
        });
      }}
    />
  </FileUploadAccordian>
);

LandlordInformation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modalFieldState: PropTypes.object,
  modalFieldChangeHandler: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fieldErrorMap: PropTypes.object,
};

LandlordInformation.defaultProps = {
  modalFieldState: {},
  modalFieldChangeHandler: () => {},
  fieldErrorMap: {},
};

export default LandlordInformation;
