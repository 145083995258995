import React from 'react';
import PropTypes from 'prop-types';
import StatusStrings from '../../utils/status-strings';

import ListActionHeader from '../../nucleus/list-action-header/list-action-header';
import DealListFilter from './deal-list-filter';
import FilterTag from '../../nucleus/legacy/tag/filter-tag';
import ListSort from '../../nucleus/list-sort/list-sort';
import SearchInput from '../../nucleus/search/search-input';

import userEventService from '../../services/user-event-service';

class DealActionsRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.sortingKeys = [
      {
        displayName: 'Date Edited',
        dataE2e: 'sort-flyout-date-edited',
        ascSortingKey: 'modified',
        descSortingKey: '-modified',
      },
      {
        displayName: 'Alphabetical',
        dataE2e: 'sort-flyout-alphabetical',
        ascSortingKey: 'name',
        descSortingKey: '-name',
      },
      {
        displayName: 'Conversion Potential',
        dataE2e: 'sort-flyout-conversion-potential',
        ascSortingKey: 'conversion_potential',
        descSortingKey: '-conversion_potential',
      },
      {
        displayName: 'Voucher Submitted Date',
        dataE2e: 'sort-flyout-voucher-submitted',
        ascSortingKey: 'voucher_submission_date',
        descSortingKey: '-voucher_submission_date',
      },
    ];
  }

  handleRemoveClosedDate = () => {
    const {
      handleFilterChange,
      initQueryParams: {
        date_approved_min,
        date_approved_max,
      },
    } = this.props;
    handleFilterChange({
      date_approved_min,
      date_approved_max,
    });
  };

  handleRemoveExpirationDate = () => {
    const {
      handleFilterChange,
      initQueryParams: {
        date_lease_to_min,
        date_lease_to_max,
      },
    } = this.props;
    handleFilterChange({
      date_lease_to_min,
      date_lease_to_max,
    });
  };

  handleRemoveCreatedBy = () => {
    const {
      handleFilterChange,
    } = this.props;
    handleFilterChange({
      created_by: null,
      created_by_name: null,
    });
  };

  handleRemoveConversionPotentialTag = () => {
    const {
      handleFilterChange,
      initQueryParams: {
        conversion_potential_min,
        conversion_potential_max,
      },
    } = this.props;
    handleFilterChange({
      conversion_potential_min,
      conversion_potential_max,
    });
  };

  handleRemoveDealTypeTag = (tag) => {
    const {
      handleFilterChange,
      initQueryParams,
      queryParams,
    } = this.props;
    const dealTypeRepRole = StatusStrings.getDealTypeAndRepRoleOption(tag);
    const repRoles = queryParams.rep_role.filter(r => r !== dealTypeRepRole.value);
    const newRepRoles = repRoles.length ? repRoles : initQueryParams.rep_role;
    const update = { rep_role: newRepRoles };
    if (!queryParams.building && newRepRoles.length === 0) {
      update.date_lease_to_min = initQueryParams.date_lease_to_min;
      update.date_lease_to_max = initQueryParams.date_lease_to_max;
    }
    handleFilterChange(update);
  };

  handleRemoveDealStageTag = (tag) => {
    const {
      handleFilterChange,
      initQueryParams,
      queryParams,
    } = this.props;

    const stages = queryParams.deal_life_cycle_stage.filter(stage => stage !== tag);
    const dealStages = stages.length ? stages : initQueryParams.deal_life_cycle_stage;

    handleFilterChange({
      deal_life_cycle_stage: dealStages,
    });
  };

  handleRemoveVoucherStatusTag = (tag) => {
    const {
      handleFilterChange,
      initQueryParams,
      queryParams,
    } = this.props;

    const statuses = queryParams.voucher_submission_status.filter(status => status !== tag);
    const voucherStatuses = statuses.length ? statuses : initQueryParams.voucher_submission_status;

    handleFilterChange({
      voucher_submission_status: voucherStatuses,
    });
  };

  handleRemoveIsArchiveTag = () => {
    const {
      handleFilterChange,
      initQueryParams,
    } = this.props;
    handleFilterChange({
      is_archived: initQueryParams.is_archived,
    });
  };

  handleRemoveIsUntaggedDealTag = () => {
    const {
      handleFilterChange,
      initQueryParams,
    } = this.props;
    handleFilterChange({
      is_untagged_deal: initQueryParams.is_untagged_deal,
    });
  };

  handleRemoveIsOnPipeline = () => {
    const {
      handleFilterChange,
      initQueryParams,
    } = this.props;
    handleFilterChange({
      is_on_pipeline: initQueryParams.is_on_pipeline,
    });
  };

  handleSortChange = (ordering) => {
    const {
      handleSortingKeyChange,
    } = this.props;
    handleSortingKeyChange(ordering);
    this.trackSortChange(ordering);
  };

  handleTagRemove = (tag) => {
    const {
      handleFilterChange,
      queryParams: {
        tags,
      },
    } = this.props;
    const filteredTags = tags.filter(i => i !== tag);
    handleFilterChange({ tags: filteredTags });
  };

  trackSearchFocus = () => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent({
      eventCategory: 'Search',
      eventAction: '_search_focus',
      eventLabel: 'on_focus',
    }, { actionPrefix, categoryPrefix });
  };

  trackSortChange = (newOrdering) => {
    const {
      queryParams: {
        ordering,
      },
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;

    userEventService.trackEvent({
      eventCategory: 'Sort',
      eventAction: '_sort_change',
      eventLabel: newOrdering,
      eventData: {
        from: ordering,
        to: newOrdering,
      },
    }, { actionPrefix, categoryPrefix });
  };

  trackSortAborted = () => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent({
      eventAction: '_sort_aborted',
      eventCategory: 'Sort',
      eventLabel: 'on_sort_aborted',
    }, { actionPrefix, categoryPrefix });
  };

  trackFilterAborted = () => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent({
      eventAction: '_filter_aborted',
      eventCategory: 'Filter',
      eventLabel: 'on_filter_aborted',
    }, { actionPrefix, categoryPrefix });
  };

  createSortingOptions = () => {
    const dateClosedKey = [
      {
        displayName: 'Date Closed',
        dataE2e: 'sort-flyout-date-closed',
        ascSortingKey: 'date_approved',
        descSortingKey: '-date_approved',
      },
      {
        displayName: 'Final SQFT',
        dataE2e: 'sort-flyout-voucher-submitted',
        ascSortingKey: 'sqft',
        descSortingKey: '-sqft',
      },
      {
        displayName: 'Lease Expiration Date',
        dataE2e: 'sort-flyout-voucher-submitted',
        ascSortingKey: 'primary_prior_property_lease_expiration',
        descSortingKey: '-primary_prior_property_lease_expiration',
      },
    ];
    const {
      queryParams: {
        deal_life_cycle_stage,
      },
    } = this.props;

    return (deal_life_cycle_stage[0] === 'closed' ? [...dateClosedKey, ...this.sortingKeys] : this.sortingKeys);
  }

  renderTags = () => {
    const {
      initQueryParams: {
        conversion_potential_max: initConversionPotentialMax,
        conversion_potential_min: initConversionPotentialMin,
        deal_life_cycle_stage: initStage,
        is_archived: initIsArchived,
        rep_role: initRepRole,
        voucher_submission_status: initStatus,
      },
      queryParams: {
        conversion_potential_max: conversionPotentialMax,
        conversion_potential_min: conversionPotentialMin,
        deal_life_cycle_stage: stage,
        rep_role: repRole,
        is_archived: isArchived,
        date_approved_min: closedMin,
        date_approved_max: closedMax,
        tags,
        date_lease_to_min,
        date_lease_to_max,
        voucher_submission_status: status,
        is_untagged_deal: isUntaggedDeal,
        is_on_pipeline: isOnPipeLine,
        created_by_name: createdByName,
        created_by: createdBy,
      },
    } = this.props;
    const renderedTags = [];
    if (conversionPotentialMax !== initConversionPotentialMax
      || conversionPotentialMin !== initConversionPotentialMin) {
      renderedTags.push((
        <FilterTag
          key="conversionPotential"
          label="Probability"
          maxValue={conversionPotentialMax}
          minValue={conversionPotentialMin || '0'}
          handleClearFilter={this.handleRemoveConversionPotentialTag}
        />
      ));
    }
    if (initStage.length !== stage.length) {
      if (stage.length) {
        renderedTags.push(...stage.map(s => (<FilterTag
          key={`tag-${s}`}
          value={s === 'prospect' ? 'Prospecting' : s}
          handleClearFilter={() => this.handleRemoveDealStageTag(s)}
        />)));
      }
    }
    if ((initStatus && initStatus.length) !== (status && status.length)) {
      if (status && status.length) {
        renderedTags.push(...status.map(s => (<FilterTag
          key={`tag-${s}`}
          value={s}
          handleClearFilter={() => this.handleRemoveVoucherStatusTag(s)}
        />)));
      }
    }
    if (initRepRole.length !== repRole.length) {
      if (repRole.length) {
        renderedTags.push(...repRole.map(s => (
          <FilterTag
            key={`tag-${s}`}
            value={StatusStrings.getDealTypeAndRepRoleOption(s).display_name}
            handleClearFilter={() => this.handleRemoveDealTypeTag(s)}
          />
        )));
      }
    }
    if (isArchived !== initIsArchived) {
      renderedTags.push((
        <FilterTag
          key="is-archived"
          value="archived"
          handleClearFilter={this.handleRemoveIsArchiveTag}
        />
      ));
    }
    if (isOnPipeLine) {
      renderedTags.push((
        <FilterTag
          key="is-on-pipeline"
          value="Pipeline Deals Only"
          handleClearFilter={this.handleRemoveIsOnPipeline}
        />
      ));
    }
    if (isUntaggedDeal) {
      renderedTags.push((
        <FilterTag
          key="is-untagged-deals"
          value="Untagged Deals Only"
          handleClearFilter={this.handleRemoveIsUntaggedDealTag}
        />
      ));
    }
    if (closedMin || closedMax) {
      renderedTags.push((
        <FilterTag
          key="closedate"
          label="Close Date"
          maxValue={closedMax}
          minValue={closedMin}
          handleClearFilter={this.handleRemoveClosedDate}
        />
      ));
    }
    if (date_lease_to_min || date_lease_to_max) {
      renderedTags.push((
        <FilterTag
          key="expiration"
          label="Lease Expiration Date"
          maxValue={date_lease_to_max}
          minValue={date_lease_to_min}
          handleClearFilter={this.handleRemoveExpirationDate}
        />
      ));
    }
    if (createdByName && createdBy) {
      renderedTags.push((
        <FilterTag
          key="createdBy"
          value={`Created By: ${createdByName}`}
          handleClearFilter={this.handleRemoveCreatedBy}
        />
      ));
    }
    if (tags.length) {
      renderedTags.push(...tags.map(tag => (
        <FilterTag
          key={`tag-${tag}`}
          value={tag}
          handleClearFilter={() => this.handleTagRemove(tag)}
        />
      )));
    }
    return !!renderedTags.length && (
      <div className="filter-tags-row">
        {renderedTags}
      </div>
    );
  };

  render() {
    const {
      allDealTags,
      handleSearchTerm,
      handleFilterChange,
      isLoading,
      queryParams,
      queryParams: {
        ordering,
        search,
      },
      initQueryParams,
      featureFlags,
    } = this.props;

    const renderTags = this.renderTags();
    return (
      <div className="deal-search-header">
        <ListActionHeader
          filter={(
            <DealListFilter
              key={`deal-list-filters-${renderTags.props ? renderTags.props.children.length : 0}`}
              allDealTags={allDealTags}
              onFilterChange={handleFilterChange}
              onFilterAbort={this.trackFilterAborted}
              queryParams={queryParams}
              initQueryParams={initQueryParams}
              featureFlags={featureFlags}
            />
          )}
          search={(
            <SearchInput
              searchKey="deals"
              initValue={search}
              handleSearchTermChange={handleSearchTerm}
              handleSearchFocus={this.trackSearchFocus}
            />
          )}
          sort={(
            <ListSort
              currentSortingKey={ordering}
              options={this.createSortingOptions()}
              onChange={this.handleSortChange}
              onSortAborted={this.trackSortAborted}
              position="right"
            />
          )}
          isLoading={isLoading}
        />
        {renderTags}
      </div>
    );
  }
}

DealActionsRow.propTypes = {
  allDealTags: PropTypes.arrayOf(PropTypes.string),
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
  handleSortingKeyChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  queryParams: PropTypes.shape({
    search: PropTypes.string,
    page: PropTypes.number,
    page_size: PropTypes.number,
    ordering: PropTypes.string,
    building: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    conversion_potential_min: PropTypes.number,
    conversion_potential_max: PropTypes.number,
    is_archived: PropTypes.bool,
    deal_life_cycle_stage: PropTypes.arrayOf(PropTypes.string),
    rep_role: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    date_approved_min: PropTypes.string,
    date_approved_max: PropTypes.string,
    date_lease_to_min: PropTypes.string,
    date_lease_to_max: PropTypes.string,
    voucher_submission_status: PropTypes.arrayOf(PropTypes.string),
    is_untagged_deal: PropTypes.bool,
    is_on_pipeline: PropTypes.bool,
    created_by: PropTypes.string,
    created_by_name: PropTypes.string,
  }).isRequired,
  initQueryParams: PropTypes.shape({
    search: PropTypes.string,
    page: PropTypes.number,
    page_size: PropTypes.number,
    ordering: PropTypes.string,
    building: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    conversion_potential_min: PropTypes.number,
    conversion_potential_max: PropTypes.number,
    is_archived: PropTypes.bool,
    deal_life_cycle_stage: PropTypes.arrayOf(PropTypes.string),
    rep_role: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    date_approved_min: PropTypes.string,
    date_approved_max: PropTypes.string,
    date_lease_to_min: PropTypes.string,
    date_lease_to_max: PropTypes.string,
    voucher_submission_status: PropTypes.arrayOf(PropTypes.string),
    is_untagged_deal: PropTypes.bool,
    is_on_pipeline: PropTypes.bool,
  }).isRequired,
  featureFlags: PropTypes.shape({}).isRequired,
};

DealActionsRow.defaultProps = {
  allDealTags: [],
};

export default DealActionsRow;

