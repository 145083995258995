import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../../nucleus/avatar/avatar';
import DealDetailTeamModalContainer from '../../modals/deal-detail-team-modal-container';
import TeamIndicator from '../../teams/team-indicator';

class DealMembers extends React.Component {
  state = {
    useSmallBreakpoint: false,
    showModal: false,
  };

  componentDidMount() {
    this.setBreakPoint(window.innerWidth);
    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize = (e) => {
    this.setBreakPoint(e.target.innerWidth);
  };

  setBreakPoint = (size) => {
    if (size < 1050) {
      this.setState({ useSmallBreakpoint: true });
    } else {
      this.setState({ useSmallBreakpoint: false });
    }
  };

  showTeamMembersModal = () => {
    this.setState({ showModal: true });
  };

  hideTeamMembersModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const {
      analyticProperties,
      dealUsers,
      showAddOrRemove,
      alignHover,
      dealTeams,
      maxAvatarsToShow,
      hideTeamMemberAvatars,
      usersToSuppress,
      teamsToSuppress,
      relatedTransactionId,
      commissionedUserIds,
      viewOnly,
    } = this.props;
    const {
      showModal,
      useSmallBreakpoint,
    } = this.state;


    const dealUsersToRender = dealUsers ? dealUsers.filter(a => !usersToSuppress.includes(a.user.id)) : [];
    const dealTeamsToRender = dealTeams ? dealTeams.filter(a => !teamsToSuppress.includes(a.id)) : [];

    const getTotalUsersInTeams = () => {
      let totalTeamUsersCount = 0;
      if (dealTeamsToRender.length !== 0) {
        dealTeamsToRender.forEach((dataItem) => {
          totalTeamUsersCount += dataItem.users.length;
        });
      }
      return totalTeamUsersCount;
    };
    const teamsCount = dealTeamsToRender.length;
    const usersCount = dealUsersToRender.length;
    const teamsSpace = maxAvatarsToShow - (usersCount + teamsCount);
    const totalCount = teamsCount + getTotalUsersInTeams() + usersCount;

    const largeAvatarBreakPoint = totalCount > maxAvatarsToShow ? (maxAvatarsToShow - teamsCount) : maxAvatarsToShow;
    let avatarBreakPoint;
    if (useSmallBreakpoint) {
      avatarBreakPoint = Math.floor(largeAvatarBreakPoint / 2);
    } else {
      avatarBreakPoint = largeAvatarBreakPoint;
    }
    const teamAvatarBreakPoint = hideTeamMemberAvatars ? -1 : Math.floor(teamsSpace / teamsCount);
    const showDollarIcon = member => (commissionedUserIds.includes(member.user.id) ? 'dollar' : null);

    let renderAvatar;
    const avatarOnClick = this.showTeamMembersModal;
    if (dealUsersToRender.length > avatarBreakPoint) {
      renderAvatar = dealUsersToRender.slice(0, avatarBreakPoint)
        .map(member => (
          <li key={member.user.id} onClick={avatarOnClick}>
            <Avatar
              image={member.user.avatar}
              firstName={member.user.first_name}
              lastName={member.user.last_name}
              enableHover
              isReadOnly={!!relatedTransactionId}
              subIcon={showDollarIcon(member)}
            />
          </li>
        ));
    } else {
      renderAvatar = dealUsersToRender.map(member => (
        <li key={member.user.id} onClick={avatarOnClick}>
          <Avatar
            image={member.user.avatar}
            firstName={member.user.first_name}
            lastName={member.user.last_name}
            enableHover
            isReadOnly={!!relatedTransactionId}
            subIcon={showDollarIcon(member)}
          />
        </li>
      ));
    }

    let isAtLimit = false;
    const hiddenCount = dealUsersToRender.length - avatarBreakPoint;
    const hiddenAvatars = '+';
    let additionalAvatars;
    if (hiddenCount > 0) {
      isAtLimit = true;
      additionalAvatars = `+${hiddenCount}`;
    }
    const extraMembers = isAtLimit ? dealUsersToRender.slice(avatarBreakPoint, dealUsersToRender.length)
      .map(member => (
        <li key={member.user.id}>{member.user.first_name} {member.user.last_name}</li>
      )) : '';

    const renderExtras = (
      <li className="extra-avatars" key="0" onClick={avatarOnClick}>
        {isAtLimit &&
          <ul>
            <p className="hoverstate nd-avatar md plus-n" data-initials={additionalAvatars}>
              <span className={`hoverstatetext align-${alignHover}`}>
                {extraMembers}
              </span>
            </p>
          </ul>
        }
      </li>
    );
    renderAvatar.push(renderExtras);

    const renderAddOrRemove = (
      <li className="extra-avatars" key="2" onClick={avatarOnClick}>
        <span>
          <p className="hoverstate nd-avatar md just-plus" data-e2e="deal-overview-add-member-button" data-initials={hiddenAvatars}>
            <span className="hoverstatetext align-center">Add/Remove</span>
          </p>
        </span>
      </li>
    );
    if (showAddOrRemove) {
      renderAvatar.push(renderAddOrRemove);
    }

    const renderModal = showAddOrRemove && showModal && (
      <DealDetailTeamModalContainer
        analyticProperties={analyticProperties}
        closeModal={this.hideTeamMembersModal}
        relatedTransactionId={relatedTransactionId}
        usersToSuppress={usersToSuppress}
        teamsToSuppress={teamsToSuppress}
        commissionedUserIds={commissionedUserIds}
        viewOnly={viewOnly}
      />
    );

    return (
      <div className="deal-collaborators">
        {dealTeamsToRender.length > 0 && dealTeamsToRender.map(team =>
        (
          <TeamIndicator
            analyticProperties={analyticProperties}
            key={team.id}
            teamName={team.name}
            teamMembers={team.users}
            teamColor={team.avatar_color}
            avatarBreakPoint={teamAvatarBreakPoint}
            allowDealTeamModal={showAddOrRemove}
            commissionedUserIds={commissionedUserIds}
            showTeamMembersModal={this.showTeamMembersModal}
          />
        ))}
        <div className="team">
          <ul>
            {renderAvatar}
          </ul>
          {renderModal}
        </div>
      </div>
    );
  }
}

DealMembers.propTypes = {
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  userData: PropTypes.shape({
    id: PropTypes.number,
  }),
  dealUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dealTeams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatar_color: PropTypes.string,
  })),
  relatedTransactionId: PropTypes.number,
  showAddOrRemove: PropTypes.bool,
  alignHover: PropTypes.string,
  maxAvatarsToShow: PropTypes.number,
  hideTeamMemberAvatars: PropTypes.bool,
  usersToSuppress: PropTypes.arrayOf(PropTypes.number),
  teamsToSuppress: PropTypes.arrayOf(PropTypes.number),
  commissionedUserIds: PropTypes.arrayOf(PropTypes.number),
  viewOnly: PropTypes.bool,
};

DealMembers.defaultProps = {
  dealTeams: [],
  showAddOrRemove: true,
  alignHover: 'left',
  maxAvatarsToShow: 9,
  hideTeamMemberAvatars: false,
  relatedTransactionId: null,
  usersToSuppress: [],
  teamsToSuppress: [],
  commissionedUserIds: [],
  viewOnly: false,
  userData: {},
};

export default DealMembers;
