import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';
import BaseInput from '../../nucleus/inputs/base-input';

class AutocompleteInput extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    placeholderValue: PropTypes.string,
    inputValue: PropTypes.string,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    dataE2e: PropTypes.string,
    handleOnClick: PropTypes.func,
    hideSearchIcon: PropTypes.bool,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onReturn: PropTypes.func.isRequired,
    focusOnMount: PropTypes.bool,
    variant: PropTypes.oneOf(['outlined', 'inline']),
  }

  static defaultProps = {
    isDisabled: false,
    inputValue: '',
    placeholderValue: 'Search',
    handleOnClick: () => { },
    errorMessage: '',
    dataE2e: '',
    hideSearchIcon: false,
    focusOnMount: false,
    variant: undefined,
  }

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      focused: false,
    };
  }

  componentDidMount() {
    if (this.props.focusOnMount) {
      this.inputRef.current.focus();
      this.props.onFocus();
    }
    this.addFocusListeners();
  }

  componentWillUnmount() {
    this.removeFocusListeners();
  }

  setFocused = focused => () => {
    this.setState({ focused });
  }

  addFocusListeners = () => {
    this.inputRef.current.addEventListener('focus', this.setFocused(true));
    this.inputRef.current.addEventListener('blur', this.setFocused(false));
  }

  removeFocusListeners = () => {
    this.inputRef.current.removeEventListener('focus', this.setFocused(true));
    this.inputRef.current.removeEventListener('blur', this.setFocused(false));
  }

  handleOnKeyDown = (e) => {
    const key = e.which || e.keyCode;
    if (key === 13 || key === 9) {
      this.props.onReturn();
    }
  };

  displayErrorState = () => {
    const {
      errorMessage,
    } = this.props;
    return (
      errorMessage ? <p className="error">{errorMessage}</p> : ''
    );
  }

  render() {
    const {
      errorMessage,
      inputValue,
      isDisabled,
      placeholderValue,
      handleChange,
      hideSearchIcon,
      isLoading,
      dataE2e,
      handleOnClick,
      onBlur,
      onFocus,
      variant,
    } = this.props;

    const isInvalidClassName = errorMessage.length > 0 ? 'invalid' : '';
    const searchInputValueExists = inputValue.length > 0;
    const searchIcon = (!searchInputValueExists && !hideSearchIcon) &&
      <Icon className="action-icon" iconName="magnifier" />;
    const loading = <div className={`loading-animation${isLoading ? ' active' : ''}`} />;
    return (
      <div className="autocomplete-input">
        <div className="search-input-wrapper">
          <BaseInput
            ref={this.inputRef}
            type={(!this.state.focused && variant === 'inline') ? 'text' : 'search'}
            className={`text-filter ${isInvalidClassName}`}
            placeholder={placeholderValue}
            value={inputValue}
            data-e2e={dataE2e}
            autoComplete="new-password"
            disabled={isDisabled}
            onChange={e => handleChange(e.target.value)}
            onClick={handleOnClick && handleOnClick}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={this.handleOnKeyDown}
            variant={variant}
          />
          {!this.state.focused && variant !== 'inline' && searchIcon}
          {this.state.focused && (
            <>
              {searchIcon}
              {loading}
            </>
          )}
        </div>
        {this.displayErrorState()}
      </div>
    );
  }
}

export default AutocompleteInput;
