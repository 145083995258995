import { connect } from 'react-redux';
import { toast } from '../../redux/actions/toasts-actions';
import { addTagToAllContactsTags, fetchAllContactsTags } from '../../redux/actions/tags-actions';

import MyContactsPage from './my-contacts-page';

const mapStateToProps = state => ({
  allContactTags: state.tags.allContactTags,
  userOptions: state.userOptions.userOptions,
});

const mapDispatchToProps = {
  addTagToAllContactsTags,
  fetchAllContactsTags,
  toast,
};
const mergeProps = (state, actions) => ({
  ...state,
  ...actions,
  analyticProperties: {
    actionPrefix: 'contacts_page',
    categoryPrefix: 'Contacts Page ',
  },
});

const MyContactsPageContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(MyContactsPage);

export default MyContactsPageContainer;
