import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import userEventService from '../../../services/user-event-service';
import AddItem from '../../../nucleus/add-item/add-item';
import ContactForm from '../../contact-form/contact-form';
import ContactListFilter from './contact-list-filter';
import ContactListItem from './contact-list-item/contact-list-item';
import EmptyState from '../../empty-state/empty-state';
import FilterTag from '../../../nucleus/legacy/tag/filter-tag';
import ListActionHeader from '../../../nucleus/list-action-header/list-action-header';
import ListSort from '../../../nucleus/list-sort/list-sort';
import LoadMore from '../../../nucleus/load-more/load-more';
import RowItem from '../../../nucleus/row-item/row-item';
import SearchInput from '../../../nucleus/search/search-input';
import ToggleView from '../../../nucleus/toggle-view/toggle-view';

const sortingKeys = [
  {
    displayName: 'First Name',
    ascSortingKey: 'given_name',
    descSortingKey: '-given_name',
  },
  {
    displayName: 'Last Name',
    ascSortingKey: 'surname',
    descSortingKey: '-surname',
  },
  {
    displayName: 'Company',
    ascSortingKey: 'company_name',
    descSortingKey: '-company_name',
  },
];

class ContactListPanel extends React.Component {
  constructor(props) {
    super(props);
    const cachedQuery = window.localStorage.getItem(props.cachedQueryKey);
    const initQuery = cachedQuery
      ? JSON.parse(cachedQuery)
      : props.initQuery;
    this.state = {
      openForms: {},
      query: {
        ordering: '-is_primary',
        page: 1,
        page_size: 25,
        search: '',
        ...initQuery,
      },
    };

    this.debounceSearch = debounce(() => {
      const { query: { search } } = this.state;
      if (search.length > 2) {
        this.handleFetchContacts({ page: 1 });
      } else {
        this.handleFetchContacts({ search: '' });
      }
    }, 300);
  }

  componentDidMount() {
    this.handleFetchContacts({});
  }

  handleFetchContacts = (params) => {
    const {
      cachedQueryKey,
      deal,
      onFetchContacts,
    } = this.props;
    const query = {
      ...this.state.query,
      ...params,
    };
    if (!query.outlook_sync_enabled) {
      delete query.outlook_sync_enabled;
    }
    this.setState({ query });
    onFetchContacts({ ...query, ...(deal.id ? { deal: deal.id } : {}) });
    if (!deal.id && cachedQueryKey) {
      window.localStorage.setItem(cachedQueryKey, JSON.stringify(query));
    }
  }

  handleLoadMore = () => this.handleFetchContacts({ page: this.state.query.page + 1 });

  handleSubmit = (contact, shouldAddToPersonal = false) => {
    const {
      deal: {
        id,
      },
      addContact,
      onUpdateContact,
    } = this.props;
    if (contact.id) {
      onUpdateContact(contact.id, { ...contact, display_toast: true }, !!this.state.query.hidden);
    } else {
      const contactToAdd = {
        ...contact,
        create_personal_contact: shouldAddToPersonal,
        deal: id,
      };
      addContact({ ...contactToAdd, display_toast: true });
    }
  };

  handleFilterChange = (params) => {
    this.setState({
      query: {
        ...this.state.query,
        ...params,
      },
    }, () => {
      this.handleFetchContacts({ page: 1 });
    });
  }

  handleSearchTermChange = (searchTerm) => {
    this.setState({
      query: {
        ...this.state.query,
        search: searchTerm,
      },
    }, () => {
      this.debounceSearch();
    });
  }

  handleSortChange = (ordering) => {
    this.setState({
      query: {
        ...this.state.query,
        ordering,
      },
    }, () => {
      this.handleFetchContacts({ page: 1 });
    });
  }

  handleContactTagClick = (tag) => {
    const { query } = this.state;
    if (!query.tags.find(t => t === tag)) {
      this.setState({
        query: {
          ...query,
          tags: [...query.tags, tag],
        },
      }, () => this.handleFetchContacts());
    }
    userEventService.trackEvent({
      eventCategory: 'My Contacts Action',
      eventAction: 'my_contact_tag_clicked',
      eventLabel: 'my_contacts',
    });
  }

  // this removes the tag at the the contactList
  // level by updating query.tags whose initial
  // tags value came from initQuery from LS
  removeTagFromListViewFilter = (key, value) => {
    let update;
    if (key === 'tags') {
      update = {
        [key]: this.state.query.tags.filter(tag => tag !== value),
      };
    } else {
      update = { [key]: value };
    }
    this.setState({
      query: {
        ...this.state.query,
        ...update,
      },
    }, () => this.handleFetchContacts());
  }

  updateOpenForms = (key) => {
    const { openForms } = this.state;
    if (!openForms[key]) {
      this.setState({
        openForms: {
          ...openForms,
          [key]: true,
        },
      });
    } else {
      delete openForms[key];
      this.setState({ openForms });
    }
  }

  /*
   * this functions returns a function for form events
   * this is done so children don't re-render unnecessarily
   * by not sending anonymous functions to children components
   */
  bindFormEventHandler = (key, action, toggle) => (...args) => {
    if (toggle) {
      toggle();
    }
    this.updateOpenForms(key);
    if (action) {
      action(...args);
    }
    if (key === 'new' && !action) {
      this.trackAddButtonClicked();
    }
  }

  trackAddButtonClicked = () => {
    const { deal: { id } } = this.props;
    userEventService.trackEvent({
      eventLabel: `${!id ? 'my' : 'deal'}_contacts`,
      eventCategory: `${!id ? 'My' : 'Deal'} Contacts Action`,
      eventAction: `add_${!id ? 'my' : 'deal'}_contact_clicked`,
    });
  }

  trackSearchFocus = () => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent({
      eventCategory: `${categoryPrefix}Search`,
      eventAction: `${actionPrefix}_search_focus`,
      eventLabel: 'on_focus',
    });
  }

  trackSortAborted = () => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent({
      eventAction: `${actionPrefix}_sort_aborted`,
      eventCategory: `${categoryPrefix}Sort`,
      eventLabel: 'on_sort_aborted',
    });
  }

  trackFilterAborted = () => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent({
      eventAction: `${actionPrefix}_filter_aborted`,
      eventCategory: `${categoryPrefix}Filter`,
    });
  }

  render() {
    const {
      allContactTags,
      analyticProperties,
      contacts,
      deal: {
        id,
      },
      onDeleteContact,
      initQuery,
      isContactsTab,
      isLoading,
      next,
      outlookSyncEnabled,
      primaryContact,
      onUpdateContact,
    } = this.props;
    const {
      openForms,
      query: {
        hidden,
        ordering,
        outlook_sync_enabled,
        search,
        tags,
      },
    } = this.state;

    const isEditing = Object.keys(openForms).length > 0;
    return (
      <div className="contact-list-panel card-default">
        {!id && (
          <React.Fragment>
            <ListActionHeader
              filter={(
                <ContactListFilter
                  allContactTags={allContactTags}
                  key={`contact-list-filter-key-${
                    (tags ? tags.length : 0) +
                    (hidden ? 1 : 0) +
                    (outlook_sync_enabled ? 1 : 0)
                    }`
                  }
                  tags={tags}
                  default_hidden={false}
                  hidden={hidden}
                  default_outlook_sync_enabled={false}
                  outlook_sync_enabled={outlook_sync_enabled}
                  onApply={this.handleFilterChange}
                  default_tags={initQuery.tags || []}
                  disabled={isEditing}
                  onAbort={this.trackFilterAborted}
                />
              )}
              isLoading={isLoading}
              search={(
                <SearchInput
                  disabled={isEditing}
                  searchKey={!id ? 'my contacts' : 'contacts'}
                  initValue={search}
                  handleSearchTermChange={this.handleSearchTermChange}
                  handleSearchFocus={this.trackSearchFocus}
                />
              )}
              sort={(
                <ListSort
                  currentSortingKey={ordering}
                  disabled={isEditing}
                  options={sortingKeys}
                  onChange={this.handleSortChange}
                  onSortAborted={this.trackSortAborted}
                />
              )}
            />
            {(hidden || outlook_sync_enabled || (tags && tags.length)) ? (
              <div className="contact-filter-list">
                {hidden && (
                  <FilterTag
                    disabled={isEditing}
                    isButton
                    key="hidden"
                    value="Hidden Contacts"
                    handleClearFilter={() => this.removeTagFromListViewFilter('hidden', false)}
                  />
                )}
                {outlook_sync_enabled && (
                  <FilterTag
                    disabled={isEditing}
                    isButton
                    key="outlook"
                    value="Outlook Contacts Only"
                    handleClearFilter={() => this.removeTagFromListViewFilter('outlook_sync_enabled', false)}
                  />
                )}
                {(tags && tags.length) ? tags.map(tag => (
                  <FilterTag
                    disabled={isEditing}
                    isButton
                    key={`tag=${tag}`}
                    value={tag}
                    handleClearFilter={() => this.removeTagFromListViewFilter('tags', tag)}
                  />
                )) : null}
              </div>
            ) : null}
          </React.Fragment>
        )}
        <div className="new-contact-section">
          <ToggleView
            render={toggle => (
              <RowItem
                additionalClassName={`add-contact-button-section${id ? '' : ' border-bottom'}`}
              >
                <AddItem
                  label={`${id ? 'Deal' : 'My'} Contact`}
                  onClick={this.bindFormEventHandler('new', undefined, toggle)}
                />
              </RowItem>
            )}
            elseRender={toggle => (<ContactForm
              analyticProperties={analyticProperties}
              contact={undefined}
              dealId={id}
              onCancel={this.bindFormEventHandler('new', undefined, toggle)}
              onSubmit={this.bindFormEventHandler('new', this.handleSubmit, toggle)}
              isPrimaryOnly={!contacts.length && !primaryContact}
            />)}
          />
        </div>
        {primaryContact &&
          <div>
            <div className="contact-list-header">
              <h2>Primary Contact</h2>
            </div>
            <ContactListItem
              allContactTags={allContactTags}
              analyticProperties={analyticProperties}
              contact={primaryContact}
              dealId={id}
              isContactsTab={isContactsTab || false}
              key={primaryContact.id}
              onCancel={this.bindFormEventHandler(primaryContact.id)}
              onDelete={this.bindFormEventHandler(primaryContact.id, onDeleteContact)}
              onEdit={this.bindFormEventHandler(primaryContact.id)}
              onSubmit={this.bindFormEventHandler(primaryContact.id, this.handleSubmit)}
              onTagClick={this.handleContactTagClick}
              onUpdate={onUpdateContact}
              outlookSyncEnabled={outlookSyncEnabled}
            />
          </div>
        }
        {contacts.length ?
          <div>
            {id && (
              <div className="contact-list-header">
                <h2>Deal Contacts</h2>
              </div>
            )}
            {
              contacts.map(contact => (
                <ContactListItem
                  allContactTags={allContactTags}
                  analyticProperties={analyticProperties}
                  dealId={id}
                  outlookSyncEnabled={outlookSyncEnabled}
                  onCancel={this.bindFormEventHandler(contact.id)}
                  onDelete={this.bindFormEventHandler(contact.id, onDeleteContact)}
                  onSubmit={this.bindFormEventHandler(contact.id, this.handleSubmit)}
                  enablePrimaryDealButton
                  key={contact.id}
                  contact={contact}
                  isContactsTab={isContactsTab || false}
                  onEdit={this.bindFormEventHandler(contact.id)}
                  onTagClick={this.handleContactTagClick}
                  onUpdate={onUpdateContact}
                />
              ))
            }
          </div>
          : null
        }
        {
          (!contacts.length && !primaryContact && !isLoading && !isEditing) &&
          <div className="empty-state-container">
            <EmptyState type="contacts" message={`Get started by creating a new ${id ? 'Deal ' : ''}Contact.`} />
          </div>
        }
        <LoadMore
          isLoading={isLoading}
          handleLoadMore={this.handleLoadMore}
          hasNextPage={next}
        />
      </div>
    );
  }
}

ContactListPanel.propTypes = {
  addContact: PropTypes.func.isRequired,
  allContactTags: PropTypes.arrayOf(PropTypes.string),
  analyticProperties: PropTypes.shape({
    actionPrefix: PropTypes.string,
    categoryPrefix: PropTypes.string,
  }).isRequired,
  cachedQueryKey: PropTypes.string,
  contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deal: PropTypes.shape({
    id: PropTypes.number,
  }),
  initQuery: PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.string),
  }),
  isLoading: PropTypes.bool.isRequired,
  isContactsTab: PropTypes.bool,
  next: PropTypes.number,
  onDeleteContact: PropTypes.func.isRequired,
  onFetchContacts: PropTypes.func.isRequired,
  onUpdateContact: PropTypes.func.isRequired,
  outlookSyncEnabled: PropTypes.bool,
  primaryContact: PropTypes.shape({
    id: PropTypes.number,
  }),
};

ContactListPanel.defaultProps = {
  allContactTags: [],
  cachedQueryKey: undefined,
  deal: {},
  initQuery: {},
  isContactsTab: undefined,
  next: null,
  outlookSyncEnabled: false,
  primaryContact: undefined,
};

export default ContactListPanel;
