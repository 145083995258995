import React, { useEffect } from 'react';
import { IconButton } from '@cbrebuild/blocks';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Percent from '../../../nucleus/formats/percent';
import VOUCHER_ROUTES from '../../../page-templates/voucher/voucher-subroutes';
import { fetchCommissions } from '../../../redux/actions/outside-broker-commissions-action';
import { fetchRebates } from '../../../redux/actions/rebates-actions';
import { fetchSharedFees } from '../../../redux/actions/shared-fees-actions';
import { PAYMENT_GROUPS } from '../../../services/voucher/commissions-service';
import formatAsCurrency from '../../../utils/format-as-currency';
import RequiredLabeledItem from './RequiredLabeledItem';

const AllocationsReview = () => {
  const {
    deal, outsideBrokers, rebates, sharedFees, voucher,
  } = useSelector(state => ({
    deal: state.deal.deal,
    outsideBrokers: state.outsideBrokerCommissions,
    rebates: state.rebates.rebates,
    sharedFees: state.sharedFees.sharedFees,
    voucher: state.voucher.voucher,
  }));
  const history = useHistory();
  const dispatch = useDispatch();
  const url = VOUCHER_ROUTES[2].routes[1].path.replace(':id', voucher.deal);
  const emptyState = '--';
  const requiredText = <p className="voucher-error-text">Required</p>;

  useEffect(() => {
    dispatch(fetchCommissions({
      transaction: deal.id,
      payment_group: PAYMENT_GROUPS.OUTSIDE_BROKER,
    }));
    dispatch(fetchCommissions({
      transaction: deal.id,
      payment_group: PAYMENT_GROUPS.TAX,
    }));
    dispatch(fetchSharedFees({
      transaction: deal.id,
      payment_group: PAYMENT_GROUPS.FEE_SHARE,
    }));
    dispatch(fetchRebates({
      transaction: deal.id,
      payment_group: PAYMENT_GROUPS.REBATE,
    }));
  }, [deal.id, dispatch]);

  return (
    <div className="voucher-review-group allocations-review">
      <div className="navigation">
        <Link className="h2 link" to={url}>Allocations</Link>
        <IconButton iconName="edit" onClick={() => { history.push(url); }} />
      </div>
      <div className="content">
        {outsideBrokers.commissions?.length === 0 && <>There were no outside brokers in this deal.</>}
        {outsideBrokers.commissions?.map((commission, index) => (
          <div className="two-cols" key={commission.id}>
            <div className="col-left">
              <h3>Outside Broker #{index + 1}</h3>
              <RequiredLabeledItem label="Full Name" item={commission.outside_broker_name} notRequired={voucher.is_early_invoice} isReview />
              <RequiredLabeledItem label="Rep Role" item={commission.voucher_broker_rep_role || emptyState} notRequired isReview />
              <RequiredLabeledItem label="License Expiration Date" item={commission.license_expdt} notRequired={!commission.paid_by_cbre} isReview />
              <RequiredLabeledItem label="License #" item={commission.license_code} notRequired={!commission.paid_by_cbre} isReview />
              <RequiredLabeledItem
                label="Amount"
                item={commission.gross_commission && (`${formatAsCurrency(commission.gross_commission)} (${commission.voucher_gross_commission_percent}%)`)}
                isReview
              />
            </div>
            <div className="col-right">
              <h3>Details</h3>
              <RequiredLabeledItem label="Company" item={commission.vendor?.name} notRequired isReview />
              <RequiredLabeledItem label="Address" item={commission.vendor} format="address" notRequired={voucher.is_early_invoice} isReview />
              <RequiredLabeledItem label="Is this outside broker a CBRE affiliate?" item={commission.partner ? 'Yes' : 'No'} notRequired isReview />
              <RequiredLabeledItem
                label="How is this outside broker being paid?"
                item={(commission.paid_by_cbre && 'Paid by CBRE') || (commission.paid_by_cbre === false && 'Paid directly') || null}
                isReview
              />
            </div>
          </div>
        ))}
        {sharedFees?.length > 0 &&
          <>
            <h3>Fee Sharing</h3>
            <table>
              <colgroup>
                <col name="from" />
                <col name="to lob" />
                <col />
                <col name="allocation" />
                <col name="amount" />
                <col name="rem emp id" />
              </colgroup>
              <tbody>
                <tr className="borderless-row">
                  <th>From</th>
                  <th>To LOB</th>
                  <th />
                  <th>Allocation</th>
                  <th>Amount</th>
                  <th>REM EMP ID</th>
                </tr>
                {sharedFees.map(sharedFee => (
                  <tr key={sharedFee.id}>
                    <td>{sharedFee.voucher_from_dept || requiredText}</td>
                    <td>{sharedFee.voucher_to_dept || requiredText}</td>
                    <td>&nbsp;</td>
                    <td><Percent value={Number(sharedFee.voucher_gross_commission_percent)} emptyState="" /></td>
                    <td>{formatAsCurrency(sharedFee.gross_commission) || requiredText}</td>
                    <td>{sharedFee.voucher_rem_employee_id || emptyState}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        }
        {rebates?.length > 0 &&
          <>
            <h3>Rebates</h3>
            <table>
              <colgroup>
                <col name="prv - client name" />
                <col />
                <col name="allocation" />
                <col name="amount" />
              </colgroup>
              <tbody>
                <tr className="borderless-row">
                  <th>RV - Client Name</th>
                  <th />
                  <th>Allocation</th>
                  <th>Amount</th>
                </tr>
                {rebates.map(rebate => (
                  <tr key={rebate.id}>
                    <td>{rebate.rebate_client_name}</td>
                    <td>&nbsp;</td>
                    <td><Percent value={Number(rebate.voucher_gross_commission_percent)} emptyState="" /></td>
                    <td>{formatAsCurrency(rebate.gross_commission) || requiredText}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        }
      </div>
    </div>
  );
};

export default AllocationsReview;
