import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from '@cbrebuild/blocks';

const NotFoundPage = () => (
  <div className="page-container not-found-page">
    <h1>The requested URL {window.location.pathname} was not found.</h1>
    <NavLink to="/dashboard">
      <Button>Go To Dashboard</Button>
    </NavLink>
  </div>
);

export default NotFoundPage;
