import React from 'react';
import { IconButton } from '@cbrebuild/blocks';
import moment from 'moment';
import { uniqueId } from 'lodash';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import VOUCHER_ROUTES from '../../../page-templates/voucher/voucher-subroutes';
import RequiredLabeledItem from './RequiredLabeledItem';

const InvestmentDetailsReview = () => {
  const {
    commercialProperties, voucher,
  } = useSelector(state => ({
    commercialProperties: state.voucher.commercialProperties,
    deal: state.deal.deal,
    voucher: state.voucher.voucher,
  }));
  const history = useHistory();
  const url = (VOUCHER_ROUTES[1].routes[4].path).replace(':id', voucher.deal);
  const emptyState = '--';

  return (
    <div className="voucher-review-group investment-details-review">
      <div className="navigation">
        <Link className="h2 link" to={url}>Investment Details</Link>
        <IconButton iconName="edit" onClick={() => { history.push(url); }} />
      </div>
      <div className="content">
        {commercialProperties && commercialProperties.map(property => (
          (
            !property.is_investment_property
            ? <legend key={uniqueId()} className="secondary-legend">This is not an investment property.</legend>
            : (
              <div key={uniqueId()}>
                <div className="two-cols">
                  <div className="col-left">
                    <h3>Occupancy</h3>
                    <RequiredLabeledItem label="Investor Type" item={property.investor_lender_type} notRequired={voucher.is_early_invoice} isReview />
                    <RequiredLabeledItem label="Vacancy %" item={property.vacancy_rate || emptyState} format="percent" notRequired isReview />
                    <RequiredLabeledItem label="Occupancy %" item={property.occupancy_rate || emptyState} format="percent" notRequired isReview />
                  </div>
                  <div className="col-right">
                    <h3>Property Details</h3>
                    <RequiredLabeledItem
                      label="Schedule Gross Income"
                      item={property.scheduled_gross_income || emptyState}
                      format="currency"
                      notRequired
                      isReview
                    />
                    <RequiredLabeledItem label="Property Expense" item={property.property_expense || emptyState} format="currency" notRequired isReview />
                    <RequiredLabeledItem label="Cap Rate/Yield" item={property.cap_yield_rate || emptyState} format="percent" notRequired isReview />
                    <RequiredLabeledItem
                      label="Net Operating Income"
                      item={property.net_operating_income || emptyState}
                      format="currency"
                      notRequired
                      isReview
                    />
                    <RequiredLabeledItem label="Spendable Return" item={property.spendable_return || emptyState} format="currency" notRequired isReview />
                    <RequiredLabeledItem label="Exchange" item={property.exchange || emptyState} format="currency" notRequired isReview />
                  </div>
                </div>
                <div>
                  <h3>Current Tenants</h3>
                  <table>
                    <colgroup>
                      <col name="tenant name" width="50%" />
                      <col name="lease expiration date" width="50%" />
                    </colgroup>
                    <tbody>
                      <tr className="borderless-row">
                        <th>Tenant Name</th>
                        <th>Lease Expiration Date</th>
                      </tr>
                      {property.tenants.map(tenant => (
                        <tr key={uniqueId()}>
                          <td>{tenant.name || emptyState}</td>
                          <td>{tenant.lease_expiration_date ? moment(tenant.lease_expiration_date).format('M/D/YY') : emptyState}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))))}
      </div>
    </div>
  );
};

export default InvestmentDetailsReview;
