import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import CommissionAgreementContainer from './commission-agreement-container';
import CommissionAllocationsContainer from './commission-allocations-container';
import InstallmentsContainer from './installments-container';
import PipelineComments from './pipeline-comments';
import Notice from '../../../nucleus/notices/notice';
import Select from '../../../nucleus/select/select';
import { getProductTypeFromValue, getProductTypes } from '../../../utils/deal-requirements-utils';
import FormGroup from '../../../nucleus/form-group/form-group';
import DealRequirementsFormContainer from '../requirements/deal-requirements-form-container';

const Financials = (props) => {
  const {
    deal,
    fetchOrCreateVoucher,
    addDealRequirements,
    updateDealRequirements,
    updateDeal,
  } = props;

  const [pipelineUpdatedTimestamp, setPipelineUpdatedTimestamp] = useState(deal.pipeline_modified_datetime);
  const pipelineUpdatedBy = deal.pipeline_modified_by?.first_name?.concat(' ', deal.pipeline_modified_by?.last_name);

  // enclose deal id to minimize work for sub-components
  const updatePipelineTimestamp = () => props.updateDealPipelineTimestamp(props.deal.id);

  useEffect(() => {
    fetchOrCreateVoucher(deal.id);
  }, [deal.id, fetchOrCreateVoucher]);

  useEffect(() => {
    if (deal.pipeline_modified_datetime == null) {
      setPipelineUpdatedTimestamp('');
      return;
    }

    const dateObj = new Date(deal.pipeline_modified_datetime);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    };

    const formattedDateStr = new Intl.DateTimeFormat('en-US', options).format(dateObj);
    setPipelineUpdatedTimestamp(formattedDateStr);
  }, [deal.pipeline_modified_datetime]);

  const commissionLink = `/deals/detail/${deal.id}/commissions`;

  if (deal.is_mta || (deal.linked_mta_transactions && deal.linked_mta_transactions.length !== 0)) {
    return <Redirect to={commissionLink} />;
  }

  const handleAddOrUpdateRequirements = (value) => {
    const timestamp = new Date().toISOString();
    if (deal.requirements) {
      updateDealRequirements(deal.requirements.id, {
        product: value.value,
        pipeline_modified_datetime: timestamp,
      }).then(() => {
        updateDeal(deal.id, {
          modified: new Date(),
        });
      })
        .catch(error => console.log('Update error - ', error));
    } else {
      addDealRequirements({
        deal: deal.id,
        product: value.value,
        pipeline_modified_datetime: timestamp,
      }).then(() => {
        updateDeal(deal.id, {
          modified: new Date(),
        });
      });
    }
  };

  return (
    <div className="financials">
      <Notice
        text="Deals must include installment information, estimated commission, and at least one commissioned user in order to be eligible for reporting.
              Missing Information will cause the deal to not appear in your generated pipeline report."
        type="warning"
      />
      <div className="financial-cards-container">
        <CommissionAgreementContainer updateDealPipelineTimestamp={updatePipelineTimestamp} />
        <InstallmentsContainer updateDealPipelineTimestamp={updatePipelineTimestamp} />
      </div>
      <div className="financial-cards-container separated">
        <div className="flex2">
          <CommissionAllocationsContainer updateDealPipelineTimestamp={updatePipelineTimestamp} />
        </div>
        <div className="flex1 deal-details">
          <h3>Deal Details</h3>
          <div className="form-row-with-labels-and-hints">
            <FormGroup
              label="Property Type"
              hasError={false}
            >
              <Select
                defaultOption={getProductTypeFromValue((deal.requirements && deal.requirements.product)) || 'Select'}
                options={getProductTypes()}
                onChange={handleAddOrUpdateRequirements}
              />
            </FormGroup>
          </div>
          <DealRequirementsFormContainer />
          <PipelineComments deal={props.deal} updateDeal={updateDeal} updateDealPipelineTimestamp={updatePipelineTimestamp} />
          <br />
          <br />
          <small>Pipeline details last updated: { pipelineUpdatedTimestamp } by { pipelineUpdatedBy }</small>
        </div>
      </div>
    </div>
  );
};


export default Financials;

Financials.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.number,
    pipeline_modified_datetime: PropTypes.string,
    pipeline_modified_by: PropTypes.shape({}),
    linked_mta_transactions: PropTypes.arrayOf(PropTypes.shape({})),
    is_mta: PropTypes.bool,
    requirements: PropTypes.shape({
      id: PropTypes.number,
      product: PropTypes.string,
    }),
  }).isRequired,
  updateDeal: PropTypes.func.isRequired,
  fetchOrCreateVoucher: PropTypes.func.isRequired,
  addDealRequirements: PropTypes.func.isRequired,
  updateDealRequirements: PropTypes.func.isRequired,
  updateDealPipelineTimestamp: PropTypes.func.isRequired,
};
