import TeamPageContainer from '../teams/team-page-container';
import ProxyAccessContainer from '../proxy/proxy-page-container';
import ProfileOverview from './profile-overview';
import ProfileLabsContainer from '../labs/labs-page-container';
import GrantProxyAccessContainer from '../proxy/grant-proxy-page-container';

const subroutes = [
  {
    path: '/profile/overview',
    exact: true,
    component: ProfileOverview,
  },
  {
    path: '/profile/labs',
    exact: true,
    component: ProfileLabsContainer,
  },
  {
    path: '/profile/teams',
    exact: true,
    component: TeamPageContainer,
  },
  {
    path: '/profile/switch-proxy',
    exact: true,
    component: ProxyAccessContainer,
  },
  {
    path: '/profile/manage-proxy',
    exact: true,
    component: GrantProxyAccessContainer,
  },
];

export default subroutes;
