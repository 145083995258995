import React from 'react';
import PropTypes from 'prop-types';
import Property from '../property/property';
import PaymentStatusIndicator from './payment-status-indicator';
import formatPaymentDate from '../../utils/format-payment-date';
import Currency from '../../nucleus/formats/currency';

const MyPaymentsTableRow = ({ payment }) => (
  <tr>
    <td>
      <Property
        building={payment.transaction && payment.transaction.final_properties && payment.transaction.final_properties[0]}
        callToAction={null}
      />
    </td>
    <td>{payment.installment_number}</td>
    <td>
      <PaymentStatusIndicator status={payment.payment_status} />
      <span>{formatPaymentDate(payment)}</span>
    </td>
    <td>
      <Currency value={payment.gross_amount} />
    </td>
    <td>
      <Currency value={payment.total_net_amount} />
    </td>
    <td>
      {payment.transaction &&
        <a href={`/deals/detail/${payment.related_deal_id || payment.transaction.id}/commissions`} target="_self">
          {payment.related_deal_name || payment.transaction.name}
        </a>
      }
      <div>{payment.deal_id || '--'}</div>
    </td>
  </tr>
);

MyPaymentsTableRow.propTypes = {
  payment: PropTypes.shape({
    deal_id: PropTypes.string,
    payment_status: PropTypes.string,
    gross_amount: PropTypes.number,
    installment_number: PropTypes.number,
    total_net_amount: PropTypes.number,
    related_deal_id: PropTypes.number,
    related_deal_name: PropTypes.string,
    transaction: PropTypes.shape({
      id: PropTypes.number,
      final_properties: PropTypes.arrayOf(PropTypes.shape({})),
      name: PropTypes.string,
    }),
  }).isRequired,
};

const MyPaymentsTable = ({ payments, isLoading }) => (
  <table className="my-payments-table">
    <colgroup>
      <col name="final property" width="20%" />
      <col name="installment #" width="10%" />
      <col name="status" width="15%" />
      <col name="gross" width="15%" />
      <col name="net" width="15%" />
      <col name="deal" width="25%" />
    </colgroup>
    <tbody>
      <tr className="dark-table-row">
        <th>Final Property</th>
        <th>Installment #</th>
        <th>Payment Status</th>
        <th>Gross</th>
        <th>Net</th>
        <th>Deal</th>
      </tr>
      {payments.map(payment => (
        <MyPaymentsTableRow
          key={payment.id}
          payment={payment}
        />
      ))}
      {!payments.length && !isLoading && (
        <tr className="empty-state-row">
          <td colSpan="6" >
            No payments data found with current filter.
            Please adjust your filter to see results.
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

MyPaymentsTable.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.shape({
    transaction: PropTypes.shape({}),
  })).isRequired,
  isLoading: PropTypes.bool,
};

MyPaymentsTable.defaultProps = {
  isLoading: false,
};

export default MyPaymentsTable;
