import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Button } from '@cbrebuild/blocks';
import { getObjectDifferences } from '../../utils/object-utilities';

import CreateDealModalContainer from '../../redux/containers/create-deal-modal-container';
import DealCardContainer from '../deal-card/deal-card-container';
import DealsActionRow from './deals-action-row';
import EmptyState from '../empty-state/empty-state';
import LoadMore from '../../nucleus/load-more/load-more';

import userEventService from '../../services/user-event-service';
import localStorageService from '../../services/local-storage-service';
import dealsService from '../../services/deals-service';

class DealList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCachedQuery: false,
      showModal: false,
      queryParams: { ...props.initQueryParams },
      favorites: [],
    };
    this.debounceHandleFetchDeals = debounce(() => {
      this.handleFetchDeals({
        ...props.analyticProperties,
        beforeResults: props.deals.length,
        type: 'search',
        queryParams: { ...this.state.queryParams },
      });
    }, 500);
  }

  componentDidMount() {
    this.getCachedQueryParams()
      .then(() => {
        this.handleFetchDeals();
        this.handleFetchDealTags();
        this.handleFetchFavorites();
      });
  }

  handleFetchFavorites = () => {
    dealsService.fetchAllDealFavorites()
      .then(data => data.json())
      .then((favorites) => {
        this.setState({ favorites });
      }).catch(error => console.log('Error getting favorites: ', error));
  };

  getCachedQueryParams = () => new Promise((resolve) => {
    const { queryParams } = this.state;
    const lastQuery = localStorageService.getDealListQueryFromCurrentQuery(queryParams);
    this.setState({
      isCachedQuery: !!lastQuery,
      queryParams: lastQuery ? { ...this.state.queryParams, ...lastQuery, page: 1 } : queryParams,
    }, resolve);
  });

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleFetchDealTags = () => {
    const { fetchDealTags } = this.props;
    fetchDealTags();
  };

  handleFetchDeals = (analyticProperties = {}) => {
    const {
      fetchDealsAndTransactionsES, user: { id },
    } = this.props;
    const { queryParams } = this.state;
    fetchDealsAndTransactionsES({ ...queryParams }, id, { ...analyticProperties });
  };

  handleTagClick = (tag) => {
    const { queryParams } = this.state;

    if (!queryParams.tags.includes(tag)) {
      const params = {
        ...queryParams,
        tags: [...queryParams.tags, tag],
      };

      this.handleFilterChange(params);
    }
  };

  handleFilterChange = (params) => {
    const { queryParams } = this.state;
    const { analyticProperties, deals } = this.props;
    const filterDiff = this.findFilterDifference(queryParams, params);
    this.setState({
      queryParams: {
        ...queryParams,
        page: 1,
        ...params,
      },
    }, () => this.handleFetchDeals({
      ...analyticProperties,
      beforeResults: deals.length,
      type: 'filter',
      filterDiff,
    }));
  };

  handleLoadMoreButton = () => {
    const {
      fetchDealsAndTransactionsES, user: { id },
    } = this.props;
    const { queryParams } = this.state;
    const fetch = fetchDealsAndTransactionsES;
    this.setState({
      queryParams: {
        ...queryParams,
        page: queryParams.page + 1,
      },
    }, () => fetch(this.state.queryParams, id));
  };

  handleSearchTerm = (search) => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        page: 1,
        search,
      },
    });
    this.debounceHandleFetchDeals();
  };

  handleSortingKeyChange = (ordering) => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        page: 1,
        ordering,
      },
    }, () => this.handleFetchDeals());
  };

  findFilterDifference = (prev, next) => {
    const prevFilters = prev;
    const nextFilters = { ...prev, ...next };
    return getObjectDifferences(prevFilters, nextFilters);
  };

  renderEmptyState = () => {
    const { initQueryParams } = this.props;
    switch (initQueryParams.deal_life_cycle_stage[0]) {
      case 'prospect': {
        return (
          <EmptyState
            callToAction={
              <Button onClick={this.handleShowModal}>
                Add Deal
              </Button>
            }
            emptyStateMessage="Start by adding a prospect and track them as they develop."
            type="deals"
          />
        );
      }
      case 'executing': {
        return (
          <EmptyState
            callToAction={
              <Button linkable>
                <a href="/deals/prospecting">Manage Prospects</a>
              </Button>
            }
            emptyStateMessage="Execute your active deals in one place."
            type="deals"
          />
        );
      }
      case 'closed': {
        return (
          <EmptyState
            callToAction={
              <Button linkable>
                <a href="/deals/executing">Manage Executing</a>
              </Button>
            }
            emptyStateMessage="See history of your deals and where they've gone."
            type="deals"
          />
        );
      }
      default:
        return <EmptyState type="deals" />;
    }
  };

  renderEmptyStateForPropertyDealList = () => { // eslint-disable-line
    const { initQueryParams } = this.props;
    if (!initQueryParams.building) {
      return (
        <EmptyState
          emptyStateMessage="There are currently no deals associated with this property."
          type="deals"
        />
      );
    }
  };

  trackIndexClick = (id, index) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
      dealCount,
      initQueryParams: {
        building,
        deal_life_cycle_stage,
      },
    } = this.props;
    if (!building) {
      localStorageService.saveDealTogglerData({
        id,
        index,
        count: dealCount,
        lifeCycle: deal_life_cycle_stage[0],
        source: 'Deals',
      });
    }
    if (this.state.queryParams.search) {
      userEventService.trackEvent({
        eventCategory: 'Search',
        eventAction: 'search_interaction',
        eventValue: index,
        eventData: {
          result_clicked_index: index,
          deal_list_type: this.props.analyticProperties.categoryPrefix,
        },
      }, { actionPrefix, categoryPrefix });
    }
  };

  render() {
    const {
      allDealTags,
      analyticProperties,
      deals,
      voucherEmail,
      hasNextPage,
      initQueryParams,
      isLoadingDeals,
      featureFlags,
      user,
    } = this.props;
    const {
      isCachedQuery,
      showModal,
      queryParams,
      favorites,
    } = this.state;

    const renderResults = deals.map((deal, index) => (
      <DealCardContainer
        key={deal.id}
        analyticProperties={analyticProperties}
        deal={deal}
        voucherEmail={voucherEmail}
        trackClicked={id => this.trackIndexClick(id, index)}
        handleTagClick={this.handleTagClick}
        index={index}
        isFavorite={favorites.includes(deal.id)}
        viewOnly={!deal.permissions.denormalized_user_ids.includes(user.id)}
      />
    ));
    return (
      <div className="deal-list page-container">
        <div className="deals-list">
          <DealsActionRow
            // if cached query is used this component needs to remount since SearchInput only takes an init value
            key={isCachedQuery ? 'default' : 'cached'}
            allDealTags={allDealTags}
            analyticProperties={analyticProperties}
            handleFilterChange={this.handleFilterChange}
            handleSearchTerm={this.handleSearchTerm}
            handleSortingKeyChange={this.handleSortingKeyChange}
            isLoading={isLoadingDeals}
            queryParams={queryParams}
            initQueryParams={initQueryParams}
            featureFlags={featureFlags}
          />
          <ul>
            {renderResults}
            {(!deals.length && !isLoadingDeals) &&
              <div className="empty-state-container">
                {this.renderEmptyState()}
              </div>
            }
          </ul>
          <LoadMore
            isLoading={isLoadingDeals}
            hasNextPage={hasNextPage}
            handleLoadMore={this.handleLoadMoreButton}
          />
        </div>
        {showModal &&
          <CreateDealModalContainer
            showModal={showModal}
            closeModal={this.handleCloseModal}
          />
        }
      </div>
    );
  }
}

DealList.propTypes = {
  allDealTags: PropTypes.arrayOf(PropTypes.string),
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  deals: PropTypes.arrayOf(PropTypes.shape({})),
  dealCount: PropTypes.number.isRequired,
  hasNextPage: PropTypes.number,
  isLoadingDeals: PropTypes.bool,
  fetchDealsAndTransactionsES: PropTypes.func.isRequired,
  fetchDealTags: PropTypes.func.isRequired,
  initQueryParams: PropTypes.shape({
    search: PropTypes.string,
    page: PropTypes.number,
    page_size: PropTypes.number,
    ordering: PropTypes.string,
    building: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    conversion_potential_min: PropTypes.number,
    conversion_potential_max: PropTypes.number,
    is_archived: PropTypes.bool,
    deal_life_cycle_stage: PropTypes.arrayOf(PropTypes.string),
    rep_role: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    date_approved_min: PropTypes.string,
    date_approved_max: PropTypes.string,
    date_lease_to_min: PropTypes.string,
    date_lease_to_max: PropTypes.string,
    voucher_submission_version: PropTypes.string,
    is_untagged_deal: PropTypes.bool,
    is_on_pipeline: PropTypes.bool,
  }).isRequired,
  featureFlags: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

DealList.defaultProps = {
  allDealTags: [],
  deals: [],
  hasNextPage: null,
  isLoadingDeals: false,
};

export default DealList;
