import React from 'react';
import PropTypes from 'prop-types';

import Currency from '../../../../nucleus/formats/currency';
import Percent from '../../../../nucleus/formats/percent';

const CommissionsDetailConsiderations = props => (
  <div style={{ overflowX: 'scroll' }}>
    <table>
      <colgroup>
        <col name="consideration type" width="15%" />
        <col name="period" width="11%" />
        <col name="rent" width="20%" />
        <col name="consideration" width="20%" />
        <col name="commisison rate" width="17%" />
        <col name="gross commission" width="17%" />
      </colgroup>
      <tbody>
        <tr className="borderless-row">
          <th>Consideration Type</th>
          <th>Period</th>
          <th>Rent</th>
          <th>Consideration</th>
          <th>Commission Rate</th>
          <th>Gross Commission</th>
        </tr>
        {props.considerations.map(consideration => (
          <tr key={consideration.id}>
            <td>{consideration.consideration_type}</td>
            <td>{consideration.total_units}</td>
            <td><Currency value={consideration.amount} /></td>
            <td><Currency value={consideration.consideration_amount} /></td>
            <td><Percent value={consideration.commission_percent} /></td>
            <td><Currency value={consideration.commission_amount} /></td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default CommissionsDetailConsiderations;

CommissionsDetailConsiderations.propTypes = {
  considerations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
