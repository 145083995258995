/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import pick from 'lodash/pick';
import queryParamUtils from '../../utils/query-param-utils';
import CreateDealModalContainer from '../../redux/containers/create-deal-modal-container';

// Currently used so Performance IQ can email brokers links to Deal IQ
// This controller will read data from URL query params and prepopulate that data in the deal creation modal

// Example query params:
// ?rep_role=landlord&client=LandlordCo&counter_party=TenantCo&property=1021%20Market%20Pl%20San%20Ramon%2C%20CA%2C%2094583
// ?rep_role=tenant&client=TenantCo&counter_party=LandlordCo

const ExternalDealSuggestPage = () => {
  const [showModal, setShowModal] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const params = queryParamUtils.getParamsFromURL();
  const paramNames = ['rep_role', 'client', 'counter_party', 'property', 'mta_id']; // the only param names we handle
  const pickedParams = pick(params, paramNames);

  const defaultParams = {
    deal_type: 'lease',
    rep_role: 'tenant',
    client: '',
  };

  const deal = {
    ...defaultParams,
    ...pickedParams,
  };

  // ensure params are valid
  const isValidDeal = () => {
    let isValid = true;

    if (deal.rep_role !== 'tenant' && deal.rep_role !== 'landlord') {
      isValid = false;
    }

    if (deal.rep_role === 'tenant' && !deal.client) {
      isValid = false;
    }

    if (deal.rep_role === 'landlord' && !deal.counter_party) {
      isValid = false;
    }

    return isValid;
  };

  if (!isValidDeal()) {
    return <Redirect push to="/dashboard" />;
  }

  deal.name = `${deal.client} ${capitalize(deal.rep_role)} Rep ${capitalize(deal.deal_type)}`;

  const handleCloseModal = () => {
    setShowModal(false);
    setShouldRedirect(true);
  };

  return (
    <div>
      <CreateDealModalContainer
        showModal={showModal}
        closeModal={handleCloseModal}
        dealFromExternalLink={deal}
      />
      {shouldRedirect && <Redirect push to="/dashboard" />}
    </div>
  );
};

export default ExternalDealSuggestPage;
