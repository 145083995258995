import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DealNotification = (props) => {
  const {
    handleNavigateTracking,
    variety,
    deal,
    performedBy,
    turducken,
  } = props;
  // Handle added to and removed from deal
  const dealStatus = variety === 'added_to_deal' ?
    ' added you to the deal ' : ' removed you from the deal ';
  // If added to deal, link to deal name. Otherwise display in bold
  const dealName = variety === 'added_to_deal' || variety === 'deal_linked_to_mta_transaction' ? (
    <Link
      className="deal-name-link"
      onClick={handleNavigateTracking}
      to={`/deals/detail/${deal.id}/overview`}
      {...(turducken ? { target: '_self' } : {})}
    >
      {`${deal.name}.`}
    </Link>
  ) : <span className="bold-name">{deal.name}.</span>;

  // Handle case with no performedBy name
  let namelessMessage = '';
  if (variety === 'added_to_deal') {
    namelessMessage = 'You have been added to the deal ';
  } else if (variety === 'removed_from_deal') {
    namelessMessage = 'You have been removed from the deal ';
  } else if (variety === 'deal_linked_to_mta_transaction') {
    namelessMessage = 'Commission information is now available for ';
  }

  const renderMessage = performedBy ? (
    <React.Fragment>
      <span className="bold-name">
        {`${performedBy.first_name} ${performedBy.last_name}`}
      </span>
      {dealStatus}{dealName}
    </React.Fragment>
  ) : (
    <React.Fragment>
      {namelessMessage}{dealName}
    </React.Fragment>
  );

  return (
    <p>{renderMessage}</p>
  );
};

export default DealNotification;

DealNotification.propTypes = {
  handleNavigateTracking: PropTypes.func.isRequired,
  deal: PropTypes.shape(),
  performedBy: PropTypes.shape({
    avatar: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  variety: PropTypes.string,
  turducken: PropTypes.bool.isRequired,
};

DealNotification.defaultProps = {
  deal: {},
  performedBy: PropTypes.shape({
    avatar: '',
    first_name: '',
    last_name: '',
  }),
  variety: PropTypes.string,
};
