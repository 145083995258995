import React from 'react';
import PropTypes from 'prop-types';

import FileUploadAccordian from '../../file-upload-accordian';
import {
  LeaseAbstractionField,
  LeaseAbstractionFieldType,
} from '../../leaseAbstractionField';

import '../../lease-abstraction.scss';

const tenantImprovementDetailOptions = [
  { display_name: 'Allowance', value: 'Allowance' },
  { display_name: 'As Is', value: 'As Is' },
  { display_name: 'Spec Suite', value: 'Spec Suite' },
  { display_name: 'Turn Key', value: 'Turn Key' },
];

const Expenses = ({
  modalFieldState,
  modalFieldChangeHandler,
  fieldErrorMap,
}) => (
  <FileUploadAccordian accordianTitle="Expenses">
    <LeaseAbstractionField
      key="tenantImprovementAllowance"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.tenantImprovementAllowance,
        label: 'Tenant Improvement Allownace ($/SqFt)',
        errorMessage: fieldErrorMap.tenantImprovementAllowance,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantImprovementAllowance', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="parkinRatio"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.parkinRatio,
        label: 'Parking Ratio (spots per 1000 SqFt)',
        errorMessage: fieldErrorMap.parkinRatio,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('parkinRatio', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="tenantImprovementDetails"
      fieldType={LeaseAbstractionFieldType.SELECT}
      fieldData={{
        data: modalFieldState.tenantImprovementDetails,
        label: 'Tenant Improvement Details',
        errorMessage: fieldErrorMap.tenantImprovementDetails,
        options: tenantImprovementDetailOptions,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantImprovementDetails', {
          value: event.value,
        });
      }}
    />
  </FileUploadAccordian>
);

Expenses.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modalFieldState: PropTypes.object,
  modalFieldChangeHandler: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fieldErrorMap: PropTypes.object,
};

Expenses.defaultProps = {
  modalFieldState: {},
  modalFieldChangeHandler: () => {},
  fieldErrorMap: {},
};

export default Expenses;
