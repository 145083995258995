import each from 'lodash/each';
import indexOf from 'lodash/indexOf';
import filter from 'lodash/filter';
import superFetch from '../../utils/super-fetch';

const LEASE_CONSIDERATION_TYPES = [
  'Annual Rent',
  'Free Annual Rent',
  'Monthly Rent',
  'Free Monthly Rent',
];

const FREE_LEASE = [
  LEASE_CONSIDERATION_TYPES[1],
  LEASE_CONSIDERATION_TYPES[3],
];

const LEASE_CONSIDERATION_TYPE_MONTHS = {
  'Annual Rent': 12,
  'Free Annual Rent': 12,
  'Monthly Rent': 1,
  'Free Monthly Rent': 1,
};

const fetchConsiderations = (dealId, additionalParams) => {
  const url = '/considerations';
  const options = {
    method: 'GET',
    params: {
      transaction: dealId,
      ...additionalParams,
    },
  };

  return superFetch(url, options)
    .then(data => data.json())
    .then(data => data.results);
};

const reorderConsiderations = async (considerationsArray) => {
  const url = '/consideration/reordering/';
  const options = { method: 'PATCH' };
  const body = JSON.stringify(considerationsArray);
  const data = await superFetch(url, options, body);
  return data.json();
};

const fetchLeaseConsiderationTypeOptions = () => {
  const url = '/considerations';
  const options = {
    method: 'OPTIONS',
  };

  return superFetch(url, options)
    .then(data => data.json())
    .then(data => data.actions.POST.consideration_type.choices)
    .then(choices => filter(
      choices,
      choice => indexOf(LEASE_CONSIDERATION_TYPES, choice.display_name) !== -1,
    ));
};

const createConsideration = (dataObj) => {
  const url = '/considerations';

  const options = {
    method: 'POST',
  };

  const body = JSON.stringify(dataObj);

  return superFetch(url, options, body)
    .then(data => data.json());
};

const updateConsideration = (id, dataObj) => {
  const url = `/considerations/${id}`;
  const options = {
    method: 'PUT',
  };
  const body = JSON.stringify(dataObj);

  return superFetch(url, options, body)
    .then(data => data.json());
};

const patchConsideration = (id, dataObj) => {
  const url = `/considerations/${id}`;
  const options = {
    method: 'PATCH',
  };
  const body = JSON.stringify(dataObj);

  return superFetch(url, options, body)
    .then(data => data.json());
};

const deleteConsideration = (id) => {
  const url = `/considerations/${id}`;
  const options = {
    method: 'DELETE',
  };

  return superFetch(url, options)
    .then(data => data);
};

const getTotalTerm = (considerations) => {
  let totalMonths = 0;
  each(considerations, ((consideration) => {
    if (!consideration.consideration_type || !consideration.total_units || consideration.deleted) {
      return;
    }
    const multiplier = LEASE_CONSIDERATION_TYPE_MONTHS[consideration.consideration_type];
    const months = consideration.total_units * multiplier;
    totalMonths += months;
  }));
  const units = totalMonths === 1 ? 'Month' : 'Months';
  return `${totalMonths} ${units}`;
};

export default {
  FREE_LEASE,
  LEASE_CONSIDERATION_TYPE_MONTHS,
  fetchConsiderations,
  reorderConsiderations,
  fetchLeaseConsiderationTypeOptions,
  createConsideration,
  updateConsideration,
  patchConsideration,
  deleteConsideration,
  getTotalTerm,
};

