import { connect } from 'react-redux';
import { toast } from '../../redux/actions/toasts-actions';
import TeamsPage from './teams-page';
import {
  addTeam,
  updateTeam,
  updateUserTeamAutoAddPreference,
  addTeamMember,
  removeTeamMember,
  removeTeamAndMember,
  fetchTeams,
} from '../../redux/actions/teams-actions';

const mapStateToProps = state => ({
  userData: state.userData.data,
  teams: state.teamsPage.teams ? state.teamsPage.teams : {},
});

const mapDispatchToProps = {
  addTeam,
  updateTeam,
  updateUserTeamAutoAddPreference,
  addTeamMember,
  removeTeamMember,
  removeTeamAndMember,
  fetchTeams,
  toast,
};

const TeamPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamsPage);

export default TeamPageContainer;
