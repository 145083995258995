import React from 'react';
import PropTypes from 'prop-types';

import PageHeaderDefault from '../../nucleus/header/page-header-default';
import Property from '../property/property';

const PropertyDetailHeader = (props) => {
  const {
    building,
    dealCount,
    id,
    url,
  } = props;
  return (
    <PageHeaderDefault
      additionalClasses="property-detail-header"
      pageUrl={url}
      tabs={[
        {
          link: `/properties/detail/${id}/deals`,
          text: `Deals (${dealCount})`,
        },
      ]}
    >
      <div className="property-details">
        <Property
          callToAction={null}
          building={building}
          isHeader
          name={building.primary_property_name}
        />
      </div>
    </PageHeaderDefault>
  );
};

PropertyDetailHeader.propTypes = {
  building: PropTypes.shape({
    primary_property_name: PropTypes.string,
  }),
  dealCount: PropTypes.number,
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

PropertyDetailHeader.defaultProps = {
  building: {},
  dealCount: 0,
};

export default PropertyDetailHeader;
