import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Textarea } from '@cbrebuild/blocks';
import FormGroup from '../../../nucleus/form-group/form-group';

const PipelineComments = ({
  deal: {
    id,
    pipeline_report_comments,
  } = {},
  updateDeal,
}) => {
  // since pipeline_report_comments can be null we can't set a default value above and we have to do it here.
  const savedComments = pipeline_report_comments || '';
  const [comments, setComments] = useState(savedComments);
  const [isSaving, setIsSaving] = useState(false);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setIsSaving(true);
    const update = setTimeout(() => {
      // if they are the same don't save, backend trims white space so we need to check a trimmed version too
      if (comments.trim() === savedComments) {
        setIsSaving(false);
      } else {
        updateDeal(id, { pipeline_report_comments: comments });
      }
    }, 500);
    return () => {
      // clear timer so it doesn't save until debounce has hit 500ms from last keystroke
      clearTimeout(update);
    };
  }, [comments, id, savedComments, updateDeal]);

  useEffect(() => {
    // if user isn't currently focused and isn't saving we can update it with a new server value
    if (!focused && !isSaving) {
      setComments(savedComments);
    }
  }, [focused, isSaving, savedComments]);

  const handleChange = ({ target: { value } }) => {
    setComments(value);
  };

  return (
    <React.Fragment>
      <FormGroup label="Pipeline Report Comments" hasError={false}>
        <span className="comment-status">{isSaving ? 'Saving...' : 'Saved'}</span>
        <Textarea
          rows={{ min: 4, max: 10 }}
          onChange={handleChange}
          value={comments}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </FormGroup>
    </React.Fragment>
  );
};

export default PipelineComments;

PipelineComments.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.number,
    pipeline_report_comments: PropTypes.string,
  }).isRequired,
  updateDeal: PropTypes.func.isRequired,
};
