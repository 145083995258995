import unionBy from 'lodash/unionBy';
import {
  ADD_TAG_TO_ALL_CONTACTS_TAGS,
  FETCH_ALL_CONTACTS_TAGS_FAILURE,
  FETCH_ALL_CONTACTS_TAGS_SUCCESS,
  TAGS_ADD_TAG_TO_DEAL_TAGS,
  TAGS_FETCH_DEAL_TAGS_FAILURE,
  TAGS_FETCH_DEAL_TAGS_SUCCESS,
} from '../actions/action-types';

const initialState = {
  allContactTags: [],
  dealTags: [],
};

// Tags Reducers
const fetchAllContactsTagsSuccess = (state, { payload: { tags_on_all_personal_contacts } }) => ({
  ...state,
  allContactTags: tags_on_all_personal_contacts,
});

const fetchDealTagsSuccess = (state, { payload: { tags_on_all_deals } }) => ({
  ...state,
  dealTags: tags_on_all_deals,
});

// no current error handling, so just return state
const fetchAllContactsTagsFailure = state => state;
const fetchDealTagsFailure = state => state;

const addDealTag = (state, { payload: { tag } }) => (
  state.dealTags.includes(tag)
    ? state
    : ({
      ...state,
      dealTags: [...state.dealTags, tag],
    })
);

const addTagToAllContactsTags = (state, { payload: { tags } }) => ({
  ...state,
  allContactTags: unionBy(state.allContactTags, tags),
});


const tags = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TAG_TO_ALL_CONTACTS_TAGS:
      return addTagToAllContactsTags(state, action);
    case FETCH_ALL_CONTACTS_TAGS_FAILURE:
      return fetchAllContactsTagsFailure(state, action);
    case FETCH_ALL_CONTACTS_TAGS_SUCCESS:
      return fetchAllContactsTagsSuccess(state, action);
    case TAGS_ADD_TAG_TO_DEAL_TAGS:
      return addDealTag(state, action);
    case TAGS_FETCH_DEAL_TAGS_FAILURE:
      return fetchDealTagsFailure(state, action);
    case TAGS_FETCH_DEAL_TAGS_SUCCESS:
      return fetchDealTagsSuccess(state, action);
    default:
      return state;
  }
};

export default tags;
