import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@cbrebuild/blocks';
import { isNumber } from 'util';
import userEventService from '../../services/user-event-service';
import CreateDealModalContainer from '../../redux/containers/create-deal-modal-container';

class GlobalNew extends React.Component {
  static propTypes = {
    closeMenu: PropTypes.func.isRequired,
    turducken: PropTypes.bool.isRequired,
  }

  state = {
    showModal: false,
  }

  openModal = () => {
    userEventService.trackEvent({
      eventAction: 'new_deal_button_clicked',
      eventCategory: 'Deal Creation',
    });

    const {
      closeMenu,
    } = this.props;
    this.setState({ showModal: true });
    closeMenu();
  };
  closeModal = (success) => {
    if (!isNumber(success)) {
      userEventService.trackEvent({
        eventAction: 'new_deal_canceled',
        eventCategory: 'Deal Creation',
      });
    }
    this.setState({ showModal: false });
  }

  render() {
    const { showModal } = this.state;

    const renderContainer = showModal && (
      <CreateDealModalContainer
        closeModal={this.closeModal}
        showModal={showModal}
        turducken={this.props.turducken}
      />
    );

    return (
      <div className="global-new">
        <Button
          className="global-new-button"
          onClick={this.openModal}
          data-e2e="new-deal-button"
        > New Deal
        </Button>
        {renderContainer}
      </div>
    );
  }
}

export default GlobalNew;
