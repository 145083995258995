const serializeParams = (params) => {
  const paramArray = [];
  Object.keys(params)
    .filter(key => params[key] !== null && params[key] !== undefined && params[key].length !== 0)
    .reduce((existingParamArray, nextKey) => {
      if (params[nextKey] instanceof Array) {
        existingParamArray.push(...params[nextKey].map(value => `${encodeURIComponent(nextKey)}=${encodeURIComponent(value)}`));
      } else {
        existingParamArray.push(`${encodeURIComponent(nextKey)}=${encodeURIComponent(params[nextKey])}`);
      }
      return existingParamArray;
    }, paramArray);
  return `?${paramArray.join('&')}`;
};

export default serializeParams;
