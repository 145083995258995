import { connect } from 'react-redux';

// actions
import { fetchConsiderations } from '../../../redux/actions/voucher/considerations-actions';
import { fetchAdditionalCommissions } from '../../../redux/actions/voucher/additional-commissions-actions';
import { updateDeal, initializeCommissionsTotal } from '../../../redux/actions/deal-actions';
import clearFinancials from '../../../redux/actions/voucher/financials-actions';
import { fetchCommissions } from '../../../redux/actions/voucher/commissions-actions';

import CommissionAgreement from './commission-agreement';

const mapStateToProps = state => ({
  deal: state.deal.deal,
  commissionsTotal: state.commissionsTotal,
  leaseConsiderationTypeOptions: state.voucher.options.leaseConsiderationTypeOptions,
});

const mapDispatchToProps = {
  fetchConsiderations,
  fetchAdditionalCommissions,
  updateDeal,
  initializeCommissionsTotal,
  clearFinancials,
  fetchCommissions,
};

const CommissionAgreementContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommissionAgreement);

export default CommissionAgreementContainer;
