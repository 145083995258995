import REQUEST_STATE from '../../utils/request-state';
import {
  VOUCHER_FETCH_COMMISSIONS_SUCCESS,
  VOUCHER_CREATE_COMMISSION_SUCCESS,
  VOUCHER_DELETE_COMMISSION_SUCCESS,
  VOUCHER_UPDATE_COMMISSION_SUCCESS,
} from '../actions/action-types';

const initialState = {
  state: REQUEST_STATE.LOADING,
  commissions: [],
};

const addCommissionSuccess = (state, action) => ({
  ...state,
  commissions: [...state.commissions, action.payload],
});

const deleteCommissionSuccess = (state, action) => ({
  ...state,
  commissions: state.commissions.filter(({ id }) => id !== action.payload),
});

const fetchCommissionsSuccess = (state, action) => ({
  ...state,
  commissions: action.payload,
  state: REQUEST_STATE.NONE,
});

const updateCommissionSuccess = (state, action) => ({
  ...state,
  commissions: state.commissions.map(commission => (
    commission.id !== action.payload.id
      ? commission : action.payload
  )),
});

const commissions = (state = initialState, action) => {
  switch (action.type) {
    case VOUCHER_CREATE_COMMISSION_SUCCESS:
      return addCommissionSuccess(state, action);
    case VOUCHER_DELETE_COMMISSION_SUCCESS:
      return deleteCommissionSuccess(state, action);
    case VOUCHER_FETCH_COMMISSIONS_SUCCESS:
      return fetchCommissionsSuccess(state, action);
    case VOUCHER_UPDATE_COMMISSION_SUCCESS:
      return updateCommissionSuccess(state, action);
    default:
      return state;
  }
};

export default commissions;
