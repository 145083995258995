import superFetch from '../utils/super-fetch';

const fetchTeamOptionsForAdmin = () => {
  const url = '/admin/teams';
  const options = {
    method: 'OPTIONS',
  };
  return superFetch(url, options)
    .then(response => response.json());
};

const fetchTeamsForAdmin = (params) => {
  // fetch all teams
  const url = '/admin/teams';
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(response => response.json());
};

const addTeamForAdmin = (params) => {
  // add a team from the admin page
  const {
    teamName: name,
    teamMembers: users,
    teamColor: avatar_color,
  } = params;
  const url = '/admin/teams';
  const options = {
    method: 'POST',
  };
  const body = {
    name,
    users,
    avatar_color,
  };

  return superFetch(url, options, JSON.stringify(body))
    .then(response => response.json());
};

const addTeamMemberForAdmin = (params) => {
  // add team members to a team from the admin page
  const url = `/admin/teams/${params.id}`;
  const options = {
    method: 'PATCH',
  };
  const body = [{
    op: 'add',
    path: '/users',
    value: params.newTeamMember.user_id,
  }];

  return superFetch(url, options, JSON.stringify(body))
    .then(data => data.json());
};

const removeTeamMemberForAdmin = (params) => {
  const url = `/admin/teams/${params.id}`;
  const options = {
    method: 'PATCH',
  };
  const body = [{
    op: 'remove',
    path: `/users/${params.memberToRemove.id}`,
  }];

  return superFetch(url, options, JSON.stringify(body))
    .then(data => data.json());
};

const updateTeamForAdmin = (params) => {
  // update a team from the admin page
  const url = `/admin/teams/${params.id}`;
  const options = {
    method: 'PUT',
  };
  const {
    id,
    teamName: name,
    teamColor: avatar_color,
    deleted,
  } = params;
  const body = {
    id,
    name,
    avatar_color,
    deleted,
  };

  return superFetch(url, options, JSON.stringify(body))
    .then(data => data.json());
};

const fetchDealsAndTransactionsESForAdmin = (params, requestId) => {
  const url = '/admin/es/dealsandtransactions';
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json())
    .then(data => ({ ...data, requestId }));
};

const linkDealsAndTransactionsForAdmin = (params) => {
  const {
    deal_pk,
    transaction_pk,
  } = params;
  const url = `/admin/deals/${deal_pk}/transactions/${transaction_pk}`;
  const options = {
    method: 'POST',
  };
  return superFetch(url, options)
    .then(response => response);
};

const unLinkDealsAndTransactionsForAdmin = (params) => {
  const {
    deal_pk,
    transaction_pk,
  } = params;
  const url = `/admin/deals/${deal_pk}/transactions/${transaction_pk}`;
  const options = {
    method: 'DELETE',
  };
  return superFetch(url, options)
    .then(response => response);
};

const fetchDealForAdmin = (id) => {
  const url = `/admin/deals/${id}`;
  const options = {
    method: 'GET',
  };
  return superFetch(url, options)
    .then(response => response.json());
};

const fetchGroups = (params) => {
  // fetch all groups
  const url = '/adminlabs/groups';
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(response => response.json())
    .catch(error => console.error('Error fetching groups: ', error));
};

const fetchGroup = (id) => {
  // fetch a group by id
  const url = `/adminlabs/groups/${id}`;
  const options = {
    method: 'GET',
  };
  return superFetch(url, options)
    .then(response => response.json())
    .catch(error => console.error('Error fetching group: ', error));
};

const fetchFlags = (params) => {
  // fetch all feature flags
  const url = '/adminlabs/flags';
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(response => response.json())
    .catch(error => console.error('Error fetching feature flags: ', error));
};

const addGroup = (params) => {
  // add a group from the admin page
  const url = '/adminlabs/groups';
  const options = {
    method: 'POST',
  };

  return superFetch(url, options, JSON.stringify(params))
    .then(response => response.json())
    .catch(error => console.error('Error adding group from admin page: ', error));
};

const addUserToGroup = (params) => {
  // add a user to a group from the admin page
  const url = '/adminlabs/groups/m2m/users';
  const options = {
    method: 'POST',
  };

  return superFetch(url, options, JSON.stringify(params))
    .then(response => response.json())
    .catch(error => console.error('Error adding user to group from admin page: ', error));
};

const removeUserFromGroup = (params) => {
  // remove a user from a group from the admin page
  const url = '/adminlabs/groups/m2m/users';
  const options = {
    method: 'DELETE',
  };

  return superFetch(url, options, JSON.stringify(params))
    .then(response => response.json())
    .catch(error => console.error('Error removing user from a group from admin page: ', error));
};

const fetchGroupUsers = (params) => {
  const url = '/adminlabs/users';
  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json())
    .catch((error) => {
      console.error('Error fetching search results: ', error);
    });
};

const fetchUsers = (params) => {
  const url = '/adminlabs/groups/search/users';
  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json())
    .catch((error) => {
      console.error('Error fetching search results: ', error);
    });
};

const addFlagToGroup = (params) => {
  // add a flag to a group from the admin page
  const url = '/adminlabs/groups/m2m/flags';
  const options = {
    method: 'POST',
  };

  return superFetch(url, options, JSON.stringify(params))
    .then(response => response.json())
    .catch(error => console.error('Error adding flag to group from admin page: ', error));
};

const removeFlagFromGroup = (params) => {
  // remove a flag from a group from the admin page
  const url = '/adminlabs/groups/m2m/flags';
  const options = {
    method: 'DELETE',
  };

  return superFetch(url, options, JSON.stringify(params))
    .then(response => response.json())
    .catch(error => console.error('Error removing flag from a group from admin page: ', error));
};

// toggle flag on for all users in a group
const turnOnFlagForAllUsers = (params) => {
  const url = '/adminlabs/users/m2m/flags';
  const options = {
    method: 'POST',
  };

  return superFetch(url, options, JSON.stringify(params))
    .then(response => response.json())
    .catch(error => console.error('Error adding flag to users from admin page: ', error));
};

// toggle flag off for all users in a group
const turnOffFlagForAllUsers = (params) => {
  const url = '/adminlabs/users/m2m/flags';
  const options = {
    method: 'DELETE',
  };

  return superFetch(url, options, JSON.stringify(params))
    .then(response => response.json())
    .catch(error => console.error('Error removing flag from all users from admin page: ', error));
};

const exportBadTransactions = () => {
  const url = '/mbb/export_mta_merge_failures';
  const options = {
    method: 'GET',
    responseType: 'arraybuffer',
  };
  return superFetch(url, options)
    .then(data => data.json());
};

export {
  fetchTeamOptionsForAdmin,
  fetchTeamsForAdmin,
  addTeamForAdmin,
  updateTeamForAdmin,
  addTeamMemberForAdmin,
  removeTeamMemberForAdmin,
  fetchDealsAndTransactionsESForAdmin,
  linkDealsAndTransactionsForAdmin,
  unLinkDealsAndTransactionsForAdmin,
  fetchDealForAdmin,
  fetchGroups,
  fetchFlags,
  addGroup,
  fetchGroup,
  addUserToGroup,
  removeUserFromGroup,
  fetchUsers,
  fetchGroupUsers,
  addFlagToGroup,
  removeFlagFromGroup,
  turnOnFlagForAllUsers,
  turnOffFlagForAllUsers,
  exportBadTransactions,
};
