import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AddItem from '../../nucleus/add-item/add-item';
import DealNote from './deal-note';

import { addNote, deleteNote, fetchNotes, updateNote } from '../../services/notes-service';
import userEventService from '../../services/user-event-service';

class DealCardNotePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
      showAddButton: true,
    };
  }

  handleCreate = (params) => {
    const {
      onChange,
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    addNote(params)
      .then((note) => {
        onChange(note);
      });

    userEventService.trackEvent(
      { eventAction: 'note_created', eventCategory: 'Note Action' },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  }

  handleUpdate = (id, params) => {
    const {
      onChange,
    } = this.props;
    updateNote(id, params)
      .then((savedNote) => {
        onChange(savedNote);
      });
    this.trackNoteUpdates(params);
  }

  trackNoteUpdates = (params) => {
    const {
      note,
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    if (!this.state.isEditing) {
      const edited = [];
      if (note.body !== params.body) {
        edited.push('body');
      }
      if (note.privacy !== params.privacy) {
        edited.push('privacy');
      }

      // track note updates
      userEventService.trackEvent({ eventAction: 'note_edited', eventCategory: 'Note Action', eventValue: edited }, { actionPrefix, categoryPrefix });
    }
  }

  handleDelete = (id) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    this.setState({ isNew: false, showAddButton: true });
    if (id) {
      deleteNote(id)
        .then(() => {
          this.handleFetchLastModifiedNote();
        });

      // tracks note deletion
      userEventService.trackEvent({ eventAction: 'note_deleted', eventCategory: 'Note Action' }, { actionPrefix, categoryPrefix });
    } else {
      this.handleFetchLastModifiedNote();
    }
  }

  handleFetchLastModifiedNote = () => {
    const {
      onChange,
    } = this.props;
    fetchNotes({
      deal: this.props.dealId,
      ordering: '-modified',
      page: 1,
      page_size: 1,
    }).then((response) => {
      if (response.results.length) {
        onChange(response.results[0]);
      } else {
        onChange(null);
      }
    });
  }

  handleAddButton = (isEditing) => {
    this.setState({ showAddButton: !isEditing });
    if (!isEditing) {
      this.setState({ isNew: false });
    }
  }

  initNewNote = () => {
    this.props.onChange(null);
    this.setState({ isNew: true });
  }

  render() {
    const {
      dealId,
      note,
      user,
    } = this.props;
    const {
      isNew,
      showAddButton,
    } = this.state;
    const renderNote = (note?.id || isNew)
      && (
        <DealNote
          dealId={dealId}
          hideTimeStamp
          isNew={isNew}
          key={isNew ? 'newNote' : note.id}
          note={note || undefined}
          onCreate={this.handleCreate}
          onDelete={this.handleDelete}
          onEdit={this.handleAddButton}
          onUpdate={this.handleUpdate}
          user={user}
          disablePredictiveNotes
        />
      );
    const renderAddButton = (showAddButton && !isNew)
      && (
        <AddItem
          label="Note"
          onClick={this.initNewNote}
        />
      );

    return (
      <div className="deal-card-note-panel">
        {renderNote}
        {renderAddButton}
      </div>
    );
  }
}

DealCardNotePanel.propTypes = {
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  dealId: PropTypes.number.isRequired,
  note: PropTypes.shape({
    id: PropTypes.number,
    body: PropTypes.string,
    privacy: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

DealCardNotePanel.defaultProps = {
  note: null,
};

export default DealCardNotePanel;

