import React from 'react';
import filter from 'lodash/filter';
import values from 'lodash/values';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';
import { getRoleKey, formatRent, formatSqFt, sortBrokers, handleEmailClick, handlePhoneClick } from '../../utils/transaction-utils';
import formatAsCurrency from '../../utils/format-as-currency';
import LabeledItem from '../../nucleus/labeled-item/labeled-item';
import Avatar from '../../nucleus/avatar/avatar';

class VoucherSearchListItem extends React.PureComponent {
  onDetailLinkClick = () => {
    this.props.handleDetailLinkClick(this.props.index);
  }

  getPartyName = (repRole, party) => {
    // params 'landlord', 'tenant', 'seller', 'buyer'
    const key = getRoleKey(repRole, party);
    return (this.props.transaction[key] && this.props.transaction[key].name) || '--';
  };

  formatCloseDate = date => (moment(date).format('MMMM D, YYYY'));

  formatCurrency = price => (price ? formatAsCurrency(price) : false);

  renderBrokers = () => {
    const { cbre_brokers } = this.props.transaction;
    const sortedBrokers = sortBrokers(cbre_brokers, 7);

    return !!cbre_brokers &&
      sortedBrokers.map((broker, index) => {
        if (broker.broker) {
          return this.renderBroker(broker, index);
        }
        return false;
      });
  };

  renderBroker = (broker, index) => {
    const {
      avatar,
      first_name,
      last_name,
      phone,
      email,
    } = broker.broker;

    const emptyState = '--';

    const renderBrokerName = () => {
      const brokerIsLead = broker.broker_is_lead && '(Lead)';
      return (
        <span className="broker-name">
          <span>{first_name} {last_name}</span>
          <span className="broker-is-lead"> {brokerIsLead}</span>
        </span>
      );
    };

    const renderPhoneNumber = () => {
      const phoneNumbers = filter(values(phone), number => number);
      return phoneNumbers.length ?
        <span className="broker-phone" onClick={() => handlePhoneClick(phoneNumbers[0])}>
          <span>{phoneNumbers[0]}</span>
        </span> :
        <span className="broker-phone disabled">{emptyState}</span>;
    };

    const renderEmailIcon = () => {
      const emails = filter(values(email), address => address);
      return emails.length ?
        <IconButton className="broker-email blxs-button-icon-small" iconName="envelope" onClick={() => handleEmailClick(emails[0])} /> :
        <span className="broker-email disabled">{emptyState}</span>;
    };

    return (
      <div className="broker-wrapper" key={index}>
        <Avatar image={avatar} firstName={first_name} lastName={last_name} sizing="sm" />
        {renderBrokerName()}
        {renderPhoneNumber()}
        {renderEmailIcon()}
      </div>
    );
  };

  render() {
    const {
      deal_type,
      rep_role,
      id,
      date_approved,
      deal_id,
      name,
      final_property,
      lead_office,
      client,
      square_feet,
      price_per_sqft,
      property_type,
      lease_rate_type,
      price,
    } = this.props.transaction;

    const repRole = (deal_type === 'sale' || deal_type === 'lease') && rep_role;

    const saleDetails = (deal_type === 'sale') &&
      <div className="col col-right">
        <div className="col col-right-1">
          <LabeledItem label="Deal ID" item={deal_id || '--'} />
          <LabeledItem label="Seller" item={this.getPartyName(rep_role, 'seller')} />
          <LabeledItem label="Buyer" item={this.getPartyName(rep_role, 'buyer')} />
          <LabeledItem label="Property Type" item={property_type || '--'} />
        </div>
        <div className="col col-right-2">
          <LabeledItem label="Close Date" item={this.formatCloseDate(date_approved) || '--'} />
          <LabeledItem label="Lead Office" item={lead_office || '--'} />
          <LabeledItem label="SqFt" item={formatSqFt(square_feet) || '--'} />
          <LabeledItem label="Sale Price" item={this.formatCurrency(price) || '--'} />
        </div>
      </div>;

    const leaseDetails = (deal_type === 'lease') &&
      <div className="col col-right">
        <div className="col col-right-1">
          <LabeledItem label="Deal ID" item={deal_id || '--'} />
          <LabeledItem label="Landlord" item={this.getPartyName(rep_role, 'landlord')} />
          <LabeledItem label="Tenant" item={this.getPartyName(rep_role, 'tenant')} />
          <LabeledItem label="Property Type" item={property_type || '--'} />
        </div>
        <div className="col col-right-2">
          <LabeledItem label="Close Date" item={this.formatCloseDate(date_approved) || '--'} />
          <LabeledItem label="Lead Office" item={lead_office || '--'} />
          <LabeledItem label="SqFt" item={formatSqFt(square_feet) || '--'} />
          <LabeledItem label="Annual Rent" item={formatRent(price_per_sqft, lease_rate_type) || '--'} />
        </div>
      </div>;

    const consultingDetails = (deal_type === 'consulting' || deal_type === 'other') &&
      <div className="col col-right">
        <div className="col col-right-1">
          <LabeledItem label="Deal ID" item={deal_id || '--'} />
          <LabeledItem label="Client" item={(client && client.name) || '--'} />
          <LabeledItem label="Property Type" item={property_type || '--'} />
        </div>
        <div className="col col-right-2">
          <LabeledItem label="Close Date" item={this.formatCloseDate(date_approved) || '--'} />
          <LabeledItem label="Lead Office" item={lead_office || '--'} />
        </div>
      </div>;

    return (
      <div className="voucher-search-list-item">
        <div className="title">
          <Link to={`/vouchers/detail/${id}`} onClick={this.onDetailLinkClick}>{name}</Link>
        </div>
        <div className="row">
          <div className="col col-left">
            <div className="col col-left-1">
              <div className="deal-type-indicator">
                <span>{deal_type}</span>
                <span> {repRole}</span>
              </div>
              <span className="address">{(final_property && final_property.full_address) || '--'}</span>
            </div>

            <div className="col col-left-2">
              {this.renderBrokers()}
            </div>
          </div>
          {saleDetails}
          {leaseDetails}
          {consultingDetails}
        </div>
      </div>
    ); // end return
  } // end render
}

VoucherSearchListItem.propTypes = {
  transaction: PropTypes.shape({
    date_approved: PropTypes.string,
    deal_id: PropTypes.string,
    deal_type: PropTypes.string,
    rep_role: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    final_property: PropTypes.shape({
      full_address: PropTypes.string,
    }),
    lead_office: PropTypes.string,
    lease_rate_type: PropTypes.string,
    client: PropTypes.shape({
      name: PropTypes.string,
    }),
    square_feet: PropTypes.number,
    price: PropTypes.number,
    price_per_sqft: PropTypes.number,
    property_type: PropTypes.string,
    cbre_brokers: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  index: PropTypes.number,
  handleDetailLinkClick: PropTypes.func,
};

VoucherSearchListItem.defaultProps = {
  index: null,
  handleDetailLinkClick: () => {},
};

export default VoucherSearchListItem;
