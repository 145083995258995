import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@cbrebuild/blocks';
import unionBy from 'lodash/unionBy';
import formatDueDate from '../../utils/format-due-date';
import { getShallowChangesFromDeepComparison, removeNullProperties } from '../../utils/object-utilities';

import tasksService from '../../services/tasks-service';
import predictiveNotesService from '../../services/predictive-notes-service';

import AddItem from '../../nucleus/add-item/add-item';
import LoadMore from '../../nucleus/load-more/load-more';
import Modal from '../../nucleus/modal/modal';
import TaskForm from '../tasks/task-form';
import TaskListItem from '../tasks/task-list-item';
import userEventService from '../../services/user-event-service';
import dealsService from '../../services/deals-service';

class TasksModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasNextPage: null,
      isLoading: false,
      page: 1,
      tasks: [],
      taskCount: 0,
      taskToEdit: null,
    };
  }

  componentDidMount() {
    this.refreshTaskList('refresh');
  }

  onSubmit = (id, params) => {
    if (id) {
      this.updateTask(id, params);
    } else {
      this.createTask(params);
    }
  };

  onCancel = () => {
    if (this.props.showForm) {
      this.props.onClose();
    } else {
      this.setState({
        showForm: false,
        taskToEdit: undefined,
      });
    }
  };

  onDelete = (id) => {
    const {
      onClose,
      showForm,
      toast,
      dealId,
    } = this.props;
    if (showForm) {
      tasksService.deleteTask(id)
        .then(() => {
          dealsService.updateDeal(dealId, {
            modified: new Date(),
          });
          toast('Task deleted');
          onClose();
        })
        .catch((error) => {
          toast('Error deleting task');
          console.log('Error deleting task: ', error);
          onClose();
        });
      this.trackTaskDeletion(id);
    } else {
      this.setState({
        showForm: false,
        taskToEdit: undefined,
      });
      this.deleteTask(id);
    }
  };

  handleAddTask = () => {
    this.setState({
      showForm: true,
      taskToEdit: undefined,
    });
  };

  handleEditTask = (task) => {
    this.setState({
      taskToEdit: task,
      showForm: true,
    });
  };

  handleTasksList = () => {
    this.setState({
      taskToEdit: undefined,
      showForm: false,
    });
  };

  handleLoadMore = () => {
    this.setState({
      ...this.state,
      page: this.state.page += 1,
    }, () => this.refreshTaskList('append'));
  };

  completeTask = (taskId) => {
    const update = { is_complete: true };
    const { dealId } = this.props;
    const { tasks } = this.state;
    const updatedTasks = tasks.map((task) => {
      if (task.id === taskId) {
        return {
          ...task,
          is_complete: true,
        };
      }
      return task;
    });
    this.setState({ tasks: updatedTasks });
    tasksService.updateTask(taskId, update)
      .then(() => {
        dealsService.updateDeal(dealId, {
          modified: new Date(),
        });
        this.props.toast('Task updated');
        const updatedTasksList = tasks.filter(t => t.id !== taskId);
        this.setState({ tasks: updatedTasksList });
      })
      .catch((error) => {
        this.props.toast('Error updating task');
        console.log('Error updating task: ', error);
      });
    this.trackTaskStatusChange(taskId);
  };

  trackTaskStatusChange = (id) => {
    const eventAction = 'task_modal_status';

    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;


    userEventService.trackEvent({
      eventAction, eventCategory: 'Task Action', eventValue: id,
    }, { actionPrefix, categoryPrefix });
  };

  createTask = (params) => {
    const {
      onClose,
      showForm,
      toast,
      addTask,
      dealId,
    } = this.props;

    const createTask = addTask || tasksService.createTask;
    createTask(params)
      .then((res) => {
        dealsService.updateDeal(dealId, {
          modified: new Date(),
        });
        if (!addTask) { // if using redux "addTask", redux will show the toast
          toast('Task added');
        } else {
          predictiveNotesService.trackCreated(res, 'custom', res.document_body);
        }

        if (showForm) {
          onClose();
        } else {
          this.setState({
            showForm: false,
            taskToEdit: undefined,
          });
          this.refreshTaskList('refresh');
        }
      })
      .catch(error => console.log('Error creating task: ', error));

    this.trackTaskCreation();
  };

  trackTaskCreation = () => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;

    const eventAction = 'task_created';
    userEventService.trackEvent({
      eventAction, eventCategory: 'Task Action',
    }, { actionPrefix: `${actionPrefix}_advance`, categoryPrefix });
  };

  updateTask = (id, params) => {
    const {
      onClose,
      showForm,
      toast,
      dealId,
    } = this.props;
    this.trackTaskUpdates(params);
    tasksService.updateTask(id, params)
      .then((task) => {
        dealsService.updateDeal(dealId, {
          modified: new Date(),
        });
        toast('Task updated');
        if (showForm) {
          onClose();
        } else {
          const updatedTasksList = this.state.tasks
            .map(t => ((t.id === task.id) ? task : t));
          this.setState({
            tasks: updatedTasksList,
            showForm: false,
            taskToEdit: undefined,
          });
        }
      })
      .catch((error) => {
        toast('Error updating task');
        console.log('Error updating task: ', error);
      });
  };

  trackTaskUpdates = (updatedProps) => {
    const {
      taskToEdit: taskProp,
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    const {
      taskToEdit: taskState,
    } = this.state;
    const taskToEdit = taskProp || taskState;
    const difference = getShallowChangesFromDeepComparison(taskToEdit, updatedProps);
    if (taskToEdit.assignees[0] && updatedProps.assignees[0] && taskToEdit.assignees[0].id === updatedProps.assignees[0]) {
      difference.assignees = null;
    }
    const keys = Object.keys(removeNullProperties(difference)).map(key => key);

    userEventService.trackEvent({
      eventAction: 'task_edited', eventCategory: 'Task Action', eventData: keys,
    }, { actionPrefix, categoryPrefix });
  };

  deleteTask = (taskId) => {
    const { dealId } = this.props;
    const { tasks } = this.state;
    tasksService.deleteTask(taskId)
      .then(() => {
        dealsService.updateDeal(dealId, {
          modified: new Date(),
        });
        this.props.toast('Task deleted');
        const updatedTasksList = tasks.filter(t => t.id !== taskId);
        this.setState({ tasks: updatedTasksList });
      })
      .catch((error) => {
        this.props.toast('Error deleting task');
        console.log('Error deleting task: ', error);
      });
    this.trackTaskDeletion(taskId);
  };

  trackTaskDeletion = (id) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;

    userEventService.trackEvent({
      eventAction: 'task_deleted', eventCategory: 'Task Action', eventValue: id,
    }, { actionPrefix, categoryPrefix });
  };

  refreshTaskList = (action) => {
    const { dealId } = this.props;
    const params = {
      deal: dealId,
      is_complete: false,
      ordering: 'due_datetime',
      page: this.state.page,
      page_size: 25,
    };
    this.setState({
      isLoading: true,
    });
    tasksService.fetchTasks(params)
      .then((data) => {
        if (data.count === 0) {
          this.setState({
            isLoading: false,
            tasks: [],
            taskCount: data.count,
            hasNextPage: data.next,
          });
        } else if (action === 'refresh') {
          this.setState({
            isLoading: false,
            tasks: data.results,
            tasksCount: data.count,
            hasNextPage: data.next,
          });
        } else if (action === 'append') {
          this.setState({
            isLoading: false,
            tasks: unionBy(this.state.tasks, data.results, 'id'),
            tasksCount: data.count,
            hasNextPage: data.next,
          });
        }
      })
      .catch((error) => {
        console.log('Error fetching tasks: ', error);
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const {
      dealName,
      user,
      onClose,
    } = this.props;

    const {
      hasNextPage,
      isLoading,
      tasks,
    } = this.state;

    const renderTaskFormView = (this.props.showForm || this.state.showForm) &&
      (<TaskForm
        dealId={this.props.dealId}
        onCancel={this.onCancel}
        onDelete={this.onDelete}
        onSubmit={this.onSubmit}
        taskToEdit={this.props.taskToEdit || this.state.taskToEdit}
        user={user}
      />);

    const renderTasksList = tasks && tasks.map((task) => {
      const dueDate = formatDueDate(task.due_datetime);
      return (<TaskListItem
        dueDate={dueDate}
        onCompleteTask={this.completeTask}
        onEditTask={this.handleEditTask}
        onDeleteTask={this.deleteTask}
        task={task}
        key={task.id}
      />);
    });

    const renderTasksListFormControls = !renderTaskFormView && (
      <React.Fragment>
        <AddItem
          onClick={this.handleAddTask}
          label="Task"
        />
        <Button onClick={onClose}>
          Close
        </Button>
      </React.Fragment>
    );

    const renderTasksListView = !renderTaskFormView &&
      (
        <React.Fragment>
          <div className="task-list">
            <ul>
              {renderTasksList}
            </ul>
            <LoadMore
              isLoading={isLoading}
              handleLoadMore={this.handleLoadMore}
              hasNextPage={hasNextPage}
            />
          </div>
          <footer>
            {renderTasksListFormControls}
          </footer>
        </React.Fragment>
      );

    return (
      <div>
        <Modal
          className="tasks-modal"
          showModal
          handleModalToggle={onClose}
          handleModalSubmit={onClose}
          handleSecondaryButton={onClose}
          modalHeader={`Tasks for ${dealName}`}
          hideModalControls
          modalWidth={650}
        >
          {renderTasksListView}
          {renderTaskFormView}
        </Modal>
      </div>
    );
  }
}

TasksModal.propTypes = {
  addTask: PropTypes.func,
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  dealId: PropTypes.number.isRequired,
  dealName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  showForm: PropTypes.bool,
  taskToEdit: PropTypes.shape({
    assignees: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
  }),
  toast: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

TasksModal.defaultProps = {
  addTask: null,
  showForm: false,
  taskToEdit: null,
};

export default TasksModal;
