import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';

import AddItem from '../../../nucleus/add-item/add-item';
import DealRequirementsFormContainer from '../requirements/deal-requirements-form-container';
import PropertyCard from './property-card';
import PropertyForm from './property-form';
import userEventService from '../../../services/user-event-service';

class PropertiesTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingCurrent: false,
      isAddingProspective: false,
    };
  }
  componentDidMount() {
    this.handleFetchProperties();
  }

  // on new property add
  getEmptyProperty = (usage) => {
    const { deal } = this.props;

    // if rep_role is 'buyer' or 'seller', default
    // spaces.owned to true, otherwise, its false
    const isOwned = !!((deal.rep_role === 'buyer' || deal.rep_role === 'seller'));
    // if rep_role is 'landlord' or 'tenant', default
    // spaces.whole_building to false, otherwise, its true
    const isWholeBuilding = !(deal.rep_role === 'landlord' || deal.rep_role === 'tenant');

    // there must be a default space to tie a property
    // to a deal
    const emptyProperty = {
      building: {},
      id: uniqueId(),
      name: undefined,
      usage,
      spaces: [{
        status: '',
        description: '',
        address_line2: undefined,
        id: uniqueId(),
        floor_code: '',
        lease_expiration_date: undefined,
        total_sqft: 0,
        owned: isOwned,
        usage,
        whole_building: isWholeBuilding,
        transactions: [
          {
            id: deal.id,
          },
        ],
      }],
    };

    return emptyProperty;
  };

  openNewCurrentPropertyForm = () => {
    this.setState({ isAddingCurrent: true });
  };

  openNewProspectivePropertyForm = () => {
    this.setState({ isAddingProspective: true });
  };

  closeNewCurrentPropertyForm = () => {
    this.setState({ isAddingCurrent: false });
  };

  closeNewProspectivePropertyForm = () => {
    this.setState({ isAddingProspective: false });
  };

  // on save
  handleAddProperty = (params, dealId) => {
    const {
      addProperty,
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    addProperty(params, dealId);
    userEventService.trackEvent({
      eventAction: 'add_property', eventCategory: 'Property Action', eventValue: dealId, eventData: params,
    }, { actionPrefix, categoryPrefix });
  };

  handleDeleteProperty = (property, dealId) => {
    const {
      deleteProperty,
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    const params = {
      building: property.building,
      name: property.name,
      spaces: [],
      usage: property.usage,
    };

    deleteProperty(property.id, params, dealId);
    userEventService.trackEvent({
      eventAction: 'delete_property', eventCategory: 'Property Action', eventValue: dealId, eventData: params,
    }, { actionPrefix, categoryPrefix });
  };

  handleFetchProperties = () => {
    const {
      deal,
      loadProperties,
    } = this.props;
    loadProperties(deal.id);
  };

  handleUpdateProperty = (id, params, dealId) => {
    const {
      updateProperty,
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    updateProperty(id, params, dealId);
    userEventService.trackEvent({
      eventAction: 'update_property', eventCategory: 'Property Action', eventValue: dealId, eventData: params,
    }, { actionPrefix, categoryPrefix });
  };

  render() {
    const {
      currentProperties,
      deal,
      prospectiveProperties,
      analyticProperties,
    } = this.props;

    const {
      isAddingCurrent,
      isAddingProspective,
    } = this.state;

    const isLandlordDealWithExistingProspect = deal.rep_role === 'landlord' && prospectiveProperties.length === 1;
    const render = deal.deal_life_cycle_stage === 'closed' ? null : (
      <div className="properties-tab detail-panels-layout">
        <div className="col-two-thirds">
          <div className="detail-card">
            <div className="header">
              <h2>Prospective Property</h2>
              {/* if deal is a landlord deal, only allow ONE propsective property */}
              {!isLandlordDealWithExistingProspect && !isAddingProspective
                &&
                <AddItem
                  label="Property"
                  onClick={this.openNewProspectivePropertyForm}
                />
              }
            </div>
            {isAddingProspective
              &&
              <PropertyForm
                analyticProperties={analyticProperties}
                addProperty={this.handleAddProperty}
                dealId={deal.id}
                deleteProperty={this.handleDeleteProperty}
                key="new-prospective-property"
                onClose={this.closeNewProspectivePropertyForm}
                property={this.getEmptyProperty('Prospective')}
              />
            }
            {prospectiveProperties.map(p => (
              <PropertyCard
                analyticProperties={analyticProperties}
                addProperty={this.handleAddProperty}
                dealId={deal.id}
                dealType={deal.deal_type}
                deleteProperty={this.handleDeleteProperty}
                key={p.id}
                property={p}
                updateProperty={this.handleUpdateProperty}
                repRole={deal.rep_role}
              />
            ))}
          </div>
          <div className="detail-card">
            <div className="header">
              <h2>Current Property</h2>
              {!isAddingCurrent
                &&
                <AddItem
                  label="Property"
                  onClick={this.openNewCurrentPropertyForm}
                />
              }
            </div>
            {isAddingCurrent
              &&
              <PropertyForm
                analyticProperties={analyticProperties}
                addProperty={this.handleAddProperty}
                dealId={deal.id}
                deleteProperty={this.handleDeleteProperty}
                key="new-current-property"
                onClose={this.closeNewCurrentPropertyForm}
                property={this.getEmptyProperty('Current')}
              />
            }
            {currentProperties.map(p => (
              <PropertyCard
                analyticProperties={analyticProperties}
                addProperty={this.handleAddProperty}
                dealId={deal.id}
                dealType={deal.deal_type}
                deleteProperty={this.handleDeleteProperty}
                key={p.id}
                property={p}
                updateProperty={this.handleUpdateProperty}
              />
            ))}
          </div>
        </div>
        <div className="col-one-third detail-card">
          <DealRequirementsFormContainer />
        </div>
      </div>
    );
    return render;
  }
}

PropertiesTab.propTypes = {
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  addProperty: PropTypes.func.isRequired,
  currentProperties: PropTypes.arrayOf(PropTypes.shape({
    building: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      full_address: PropTypes.string,
      id: PropTypes.number,
      postal_code: PropTypes.string,
      primary_property_name: PropTypes.string,
      state_abbreviation: PropTypes.string,
    }),
    id: PropTypes.number,
    name: PropTypes.string,
    spaces: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string,
      description: PropTypes.string,
      address_line2: PropTypes.undefined,
      id: PropTypes.null,
      floor_code: PropTypes.string,
      lease_expiration_date: PropTypes.undefined,
      total_sqft: PropTypes.number,
      owned: PropTypes.undefined,
      whole_building: PropTypes.undefined,
      transactions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.null,
      })),
    })),
    usage: PropTypes.string,
  })).isRequired,
  deal: PropTypes.shape({
    deal_life_cycle_stage: PropTypes.string,
    deal_type: PropTypes.string,
    id: PropTypes.number,
    rep_role: PropTypes.string,
  }).isRequired,
  deleteProperty: PropTypes.func.isRequired,
  loadProperties: PropTypes.func.isRequired,
  prospectiveProperties: PropTypes.arrayOf(PropTypes.shape({
    building: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      full_address: PropTypes.string,
      id: PropTypes.number,
      postal_code: PropTypes.string,
      primary_property_name: PropTypes.string,
      state_abbreviation: PropTypes.string,
    }),
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    name: PropTypes.string,
    spaces: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string,
      description: PropTypes.string,
      address_line2: PropTypes.undefined,
      id: PropTypes.null,
      floor_code: PropTypes.string,
      lease_expiration_date: PropTypes.undefined,
      total_sqft: PropTypes.number,
      owned: PropTypes.undefined,
      whole_building: PropTypes.undefined,
      transactions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.null,
      })),
    })),
    usage: PropTypes.string,
  })).isRequired,
  updateProperty: PropTypes.func.isRequired,
};

export default PropertiesTab;
