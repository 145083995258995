import { connect } from 'react-redux';
import {
  addDealMembers,
  addDealTeams,
} from '../actions/team-members-actions';
import { fetchTeams } from '../actions/teams-actions';


import CreateDealModal from '../../components/create-deal-modal/create-deal-modal';

const mapStateToProps = state => ({
  user: state.userData.data,
  userOptions: state.userOptions.userOptions,
  teams: state.teamsPage.teams ? state.teamsPage.teams : [],
});

const mapDispatchToProps = ({
  addDealMembers,
  addDealTeams,
  fetchTeams,
});

const CreateDealModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateDealModal);

export default CreateDealModalContainer;
