import React from 'react';
import PropTypes from 'prop-types';

import TabNav from '../tabnav/tabnav';

const PageHeaderDefault = (props) => {
  const {
    controls,
    subtitle,
    title,
    barColor,
    children,
    additionalClasses,
    tabs,
    pageUrl,
    hasProgressTab,
  } = props;

  const basicHeader = title && (
    <div className="basic-header-content">
      <div className="page-title-wrapper">
        {title && <h1 className="page-title">{title}</h1>}
        {subtitle && <p className="sub-title">{subtitle}</p>}
      </div>
      {controls && <div className="controls">{controls}</div>}
    </div>);

  const tabNav = tabs && <TabNav tabs={tabs} selectedTab={pageUrl} isProgressTab={hasProgressTab} />;

  return (
    <div className={`nd-page-header-default ${barColor} ${additionalClasses}`}>
      {basicHeader}
      {children}
      {tabNav}
    </div>
  );
};

PageHeaderDefault.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  controls: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  barColor: PropTypes.string,
  additionalClasses: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    rel: PropTypes.string,
    secondary: PropTypes.bool,
    hidden: PropTypes.bool,
  })),
  pageUrl: PropTypes.string,
  hasProgressTab: PropTypes.bool,
};

PageHeaderDefault.defaultProps = {
  title: '',
  subtitle: '',
  controls: '',
  children: '',
  barColor: '', // style defaults to green
  additionalClasses: '',
  tabs: null,
  pageUrl: '',
  hasProgressTab: false,
};

export default PageHeaderDefault;
