import unionBy from 'lodash/unionBy';

const initialState = {};

const loadGlobalSearchResultsSuccess = (state, action) => {
  const {
    previous,
    results,
  } = action.payload.response;

  const updatedState = {
    ...state,
    searchResults: previous
      ? unionBy(state.searchResults, results, 'id')
      : results,
  };
  return updatedState;
};

const loadGlobalSearchResultsFailure = (state, action) => {
  const payload = action.payload.searchResultsError;
  const updatedState = {
    ...state,
    searchResultsError: payload,
  };
  return updatedState;
};

const loadGlobalSearchMetadata = (state, action) => {
  const payload = action.payload.metadata;
  const updatedState = {
    ...state,
    metadata: {
      hasNextPage: payload.next,
    },
  };
  return updatedState;
};

const isLoading = (state, action) => {
  const updatedState = {
    ...state,
    isLoading: action.payload.loadingSearchResults,
  };
  return updatedState;
};

const clearSearchResultsSuccess = state => ({
  ...state,
  searchResults: [],
});

const globalSearch = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_GLOBAL_SEARCH_RESULTS_SUCCESS':
      return loadGlobalSearchResultsSuccess(state, action);
    case 'LOAD_GLOBAL_SEARCH_RESULTS_FAILURE':
      return loadGlobalSearchResultsFailure(state, action);
    case 'LOAD_GLOBAL_SEARCH_METADATA':
      return loadGlobalSearchMetadata(state, action);
    case 'IS_LOADING':
      return isLoading(state, action);
    case 'CLEAR_SEARCH_RESULTS_SUCCESS':
      return clearSearchResultsSuccess(state, action);
    default:
      return state;
  }
};

export default globalSearch;
