import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IconButton } from '@cbrebuild/blocks';
import Select from '../../../nucleus/select/select';
import FormGroup from '../../../nucleus/form-group/form-group';
import Datepicker from '../../../nucleus/datepicker/datepicker';

const FutureActionItem = ({
  future_action,
  future_action_date,
  index,
  notify_when_date,
  onDelete,
  onUpdate,
}) => {
  const { dealType } = useSelector(state => ({
    dealType: state.deal.deal?.deal_type,
  }));
  const futureActionOpts = [
    { display_name: 'Additional Space', value: 'Additional Space' },
    { display_name: 'Cancel', value: 'Cancel' },
    { display_name: 'Consulting Op', value: 'Consulting Op' },
    { display_name: 'Contingency', value: 'Contingency' },
    { display_name: 'Lease Expiration', value: 'Lease Expiration' },
    { display_name: 'Lease Expiration Reminder', value: 'Lease Expiration Reminder' },
    { display_name: 'Lease Expiration Reminder - Other', value: 'Lease Expiration Reminder - Other' },
    { display_name: 'Option To Buy', value: 'Option To Buy' },
    { display_name: 'Overage Rent', value: 'Overage Rent' },
    { display_name: 'Renewal', value: 'Renewal' },
    { display_name: 'Sale Op', value: 'Sale Op' },
  ];

  const notifTimeOpts = [
    { display_name: '3 months prior', value: '3 months prior' },
    { display_name: '6 months prior', value: '6 months prior' },
    { display_name: '9 months prior', value: '9 months prior' },
    { display_name: '12 months prior', value: '12 months prior' },
    { display_name: '24 months prior', value: '24 months prior' },
    { display_name: 'Every 12 months', value: 'Every 12 months' },
    { display_name: 'Immediate', value: 'Immediate' },
  ];

  return (
    <div className="form-row-with-labels-and-hints">
      <FormGroup
        label="Future Action"
        size="large"
        isRequired
        hasError={!future_action}
        errorMessage="You must select a future action"
      >
        <Select
          name="future_action"
          options={futureActionOpts}
          defaultOption={futureActionOpts.find(option => option.value === future_action)}
          onChange={({ value }) => onUpdate(index, 'future_action', value)}
        />
      </FormGroup>
      {
        (dealType !== 'consulting' && dealType !== 'other') &&
          <FormGroup
            label="Notification Time"
            size="large"
            isRequired
            hasError={!notify_when_date}
            errorMessage="You must select a notification time"
          >
            <Select
              name="notify_when_date"
              options={notifTimeOpts}
              defaultOption={notifTimeOpts.find(option => option.value === notify_when_date)}
              onChange={({ value }) => onUpdate(index, 'notify_when_date', value)}
            />
          </FormGroup>
      }
      {
        (dealType === 'consulting' || dealType === 'other') &&
          <FormGroup
            label="Notification Date"
            size="large"
            isRequired
            hasError={!future_action_date}
            errorMessage="You must select a notification date"
          >
            <Datepicker
              date={future_action_date || ''}
              onDateChange={date => onUpdate(index, 'future_action_date', date)}
            />
          </FormGroup>
      }
      <IconButton className="blxs-button-icon-small" iconName="close-circle" onClick={() => onDelete(index)} variant="basic" />
    </div>
  );
};

FutureActionItem.propTypes = {
  future_action: PropTypes.string,
  future_action_date: PropTypes.string,
  index: PropTypes.number.isRequired,
  notify_when_date: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

FutureActionItem.defaultProps = {
  future_action: null,
  future_action_date: null,
  notify_when_date: null,
};

export default FutureActionItem;
