import fileUploadRequester from '../utils/file-upload-requester';
import superFetch from '../utils/super-fetch';
/* global CONFIG */
const { apiUrl } = CONFIG;

const deleteAttachment = (fileId) => {
  const url = `${apiUrl}/mbb/attachments/${fileId}`;
  const options = {
    method: 'DELETE',
  };
  return superFetch(url, options)
    .then(data => data);
};

// initial fetch for attachments on load of Files page
const fetchAttachments = (params) => {
  const url = `${apiUrl}/mbb/attachments`;
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json())
    .then(data => data || []);
};

const fetchAttachmentsDownloadLink = (fileId, forceDownload) => {
  let url = `${apiUrl}/mbb/attachments/${fileId}/download`;
  let newWindow;
  const options = {
    method: 'GET',
  };
  if (!forceDownload) {
    // This is here so it will prevent pop up blockers. Pop up blockers
    // tend to be initiated by code that runs after ajax requests.
    // If you initiate some of the code before
    // the ajax call then it will allow it.
    newWindow = window.open('', '_blank');
    url = `${apiUrl}/mbb/attachments/${fileId}/view`;
  }
  return superFetch(url, options)
    .then(res => res.json())
    .then((res) => {
      if (res.attachment_download_url) {
        const link = document.createElement('a');
        link.href = res.attachment_download_url;
        link.download = 'download';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        newWindow.location.href = res.attachment_download_url;
      }
      return res;
    });
};

const fetchAttachmentsViewLink = (fileId) => {
  const url = `${apiUrl}/mbb/attachments/${fileId}/view`;
  const options = {
    method: 'GET',
  };

  return superFetch(url, options)
    .then(res => res.json())
    .then((res) => {
      if (res.attachment_download_url) {
        return res.attachment_download_url;
      }
      throw new Error('No attachment download URL found');
    });
};

const fetchAttachmentUploadData = (id) => {
  const url = `${apiUrl}/mbb/attachments/${id}/upload`;
  const options = {
    method: 'GET',
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const postAttachments = (params) => {
  const url = `${apiUrl}/mbb/attachments`;
  const options = {
    method: 'POST',
  };
  return superFetch(url, options, JSON.stringify(params))
    .then(data => data.json());
};

const postAttachmentsToS3 = (file, params, onProgress) => {
  const { fields } = params.attachment_upload_data;
  const { url } = params.attachment_upload_data;
  return fileUploadRequester(url, file, fields, onProgress);
};

const postAttachmentsToExtract = (params) => {
  const url = `${apiUrl}/mbb/attachmentextract`;
  const options = {
    method: 'POST',
  };
  return superFetch(url, options, JSON.stringify(params))
    .then(data => data.json());
};

const getAttachmentsExtract = (params) => {
  const url = `${apiUrl}/mbb/attachmentextract`;
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json());
};


const updateAttachment = (id, params) => {
  const url = `${apiUrl}/mbb/attachments/${id}`;
  const options = {
    method: 'PATCH',
  };
  return superFetch(url, options, JSON.stringify(params))
    .then(data => data.json());
};

export {
  deleteAttachment,
  fetchAttachments,
  fetchAttachmentsDownloadLink,
  fetchAttachmentUploadData,
  postAttachments,
  postAttachmentsToS3,
  postAttachmentsToExtract,
  getAttachmentsExtract,
  updateAttachment,
  fetchAttachmentsViewLink,
};
