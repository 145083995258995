import { connect } from 'react-redux';
import LabsPage from './labs-page';
import { loadUserOptions } from '../../redux/actions/user-options-actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  loadUserOptions,
};

const LabsPageContainer = connect(mapStateToProps, mapDispatchToProps)(LabsPage);

export default LabsPageContainer;
