/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, IconButton, Icon } from '@cbrebuild/blocks';

import predictiveNotesService from '../../services/predictive-notes-service';
import TasksModal from '../modals/tasks-modal';

const AutoTask = (props) => {
  const [isShowingOptions, setIsShowingOptions] = useState(false);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [taskTemplate, setTaskTemplate] = useState(null);

  const {
    addTask,
    deal,
    dismissAutoTask,
    noteBody,
    toast,
    user,
    userOptions,
    onStateChange,
  } = props;

  const formatTask = (timeOption, isForModal) => {
    const alertDatetime = moment(new Date());
    if (timeOption === 'tomorrow') {
      alertDatetime.add(1, 'days');
    } else if (timeOption === 'next week') {
      alertDatetime.add(7, 'days');
    }
    alertDatetime.set({
      hour: 8,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    const followUpTask = {
      title: `Follow Up: ${deal.name}`,
      deal: deal.id,
      is_private: false,
      document_body: noteBody,
      alert_datetime: alertDatetime.toISOString(),
      assignees: isForModal ? [user] : [user.id],
    };

    return followUpTask;
  };

  const createTask = async (timeOption) => {
    const newTask = formatTask(timeOption, false);
    const response = await addTask(newTask);
    predictiveNotesService.trackCreated(response, timeOption, noteBody);
    dismissAutoTask();
    onStateChange('inactive');
  };

  const openTaskModal = () => {
    const newTask = formatTask('tomorrow', true);
    setTaskTemplate(newTask);
    setIsShowingModal(true);
    onStateChange('modal');
  };

  return userOptions.bleeding_edge_toggle_on ? (
    <React.Fragment>
      {!isShowingOptions && (
        <Button
          className="small"
          variant="secondary"
          onClick={() => {
            setIsShowingOptions(true);
            onStateChange('active');
          }}
          id="auto-task-button"
        >
          <Icon iconName="wand" />
          Auto-Task
        </Button>
      )}
      {isShowingOptions && (
        <div className="auto-task-options">
          <Icon iconName="wand" />
          <div className="truncate">Make a task and remind me about it</div>
          <Button
            className="small"
            variant="secondary"
            onClick={() => createTask('tomorrow')}
          >
            Tomorrow
          </Button>
          <Button
            className="small"
            variant="secondary"
            onClick={() => createTask('next week')}
          >
            Next Week
          </Button>
          <Button
            className="small"
            variant="secondary"
            onClick={openTaskModal}
          >
            Custom
          </Button>
          <IconButton
            iconName="close"
            onClick={() => {
              dismissAutoTask(true, noteBody);
              onStateChange('inactive');
            }}
            variant="basic"
          />
        </div>
      )}
      {isShowingModal && (
        <TasksModal
          addTask={addTask}
          analyticProperties={{
            actionPrefix: 'predictive_notes',
            categoryPrefix: 'Predictive Notes ',
          }}
          dealName={deal.name}
          dealId={deal.id}
          onClose={() => {
            onStateChange('inactive');
            setIsShowingModal(false);
            dismissAutoTask();
          }}
          showForm
          taskToEdit={taskTemplate}
          toast={toast}
          user={user}
        />
      )}
    </React.Fragment>
  ) : null;
};

export default AutoTask;

AutoTask.propTypes = {
  addTask: PropTypes.func.isRequired,
  deal: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  dismissAutoTask: PropTypes.func.isRequired,
  noteBody: PropTypes.string.isRequired,
  toast: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  userOptions: PropTypes.shape({
    bleeding_edge_toggle_on: PropTypes.bool,
  }).isRequired,
  onStateChange: PropTypes.func.isRequired,
};
