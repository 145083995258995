import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import find from 'lodash/find';
import pull from 'lodash/pull';
import formatNumberWithCommas from './format-number-with-commas';
import formatAsCurrency from './format-as-currency';

const handleEmailClick = (email) => {
  window.location = `mailto:${email}`;
};

const handlePhoneClick = (phone) => {
  window.location = `tel:${phone}`;
};

const formatPricePerSqft = val => (val ? formatAsCurrency(val) : '');

const formatRent = (pricePerSqFt, leaseRateType) => {
  const currency = formatAsCurrency(pricePerSqFt);
  if (pricePerSqFt || leaseRateType) {
    return `${currency} / SqFt ${leaseRateType}`;
  }
  return '';
};

const formatSqFt = val => (val ? formatNumberWithCommas(val) : '');

const sortBrokers = (brokers, len) => {
  let sortedBrokers = cloneDeep(brokers);
  const leadBroker = find(sortedBrokers, broker => broker.broker_is_lead);
  if (sortedBrokers && sortedBrokers.length && leadBroker) {
    const sliceLength = len || sortedBrokers.length;
    const otherBrokers = (pull(sortedBrokers, leadBroker)).slice(0, sliceLength - 1);
    sortedBrokers = concat(leadBroker, otherBrokers);
  }
  return sortedBrokers;
};

const getRoleKey = (repRole, party) => {
  const REP_ROLE_MAP = {
    buyer: {
      buyer: 'client',
      seller: 'counter_party',
    },
    seller: {
      buyer: 'counter_party',
      seller: 'client',
    },
    landlord: {
      landlord: 'client',
      tenant: 'counter_party',
    },
    tenant: {
      landlord: 'counter_party',
      tenant: 'client',
    },
  };
  const map = REP_ROLE_MAP[repRole];
  const key = map[party];
  return key;
};

export {
  handleEmailClick,
  handlePhoneClick,
  formatPricePerSqft,
  formatSqFt,
  formatRent,
  sortBrokers,
  getRoleKey,
};
