import { fetchSharedFees, updateSharedFee } from './shared-fees-actions';
import { fetchCommissions as fetchOutsideBrokerCommissions, updateCommission as updateOutsideCommission } from './outside-broker-commissions-action';
import { fetchRebates, updateRebate } from './rebates-actions';
import { fetchCommissions, updateCommission } from './commissions-actions';
import { fetchAdditionalCommissions, fetchAdditionalCommissionTypeOptions } from './additional-commissions-actions';
import { fetchConsiderations, fetchLeaseConsiderationTypeOptions } from './considerations-actions';
import { fetchInstallments, fetchInstallmentOptions, updateInstallment } from './voucher/installments-actions';
import { updateDeal, updateDealPipelineTimestamp } from './deal-actions';
import {
  selectTotalCommissions, selectTotalCommissionsForRebates, selectTotalCommissionsForSharedFees, selectTotalCommissionsForCommissions,
  selectCommissions, selectTotalCommissionsForInstallments,
  selectSharedFees, selectOutsideBrokerCommissions, selectRebates, selectInstallments,
  selectOutsideBrokerIsPercentage, selectRebateIsPercentage, selectFeeSharingIsPercentage, selectCBRECommissionIsPercent, selectInstallmentIsPercentage,
} from '../selectors/voucher-selectors';
import calculatePercentValueFromTotal from '../../utils/calculate-percent-value-from-total';

// dispatch
const fetchFinancials = (dealId, dealType) => (dispatch) => {
  dispatch(fetchSharedFees({
    transaction: dealId,
    payment_group: 4,
  }));
  dispatch(fetchOutsideBrokerCommissions({
    transaction: dealId,
    payment_group: 2,
  }));
  dispatch(fetchRebates({
    transaction: dealId,
    payment_group: 3,
  }));
  dispatch(fetchCommissions({ transaction: dealId }));
  dispatch(fetchAdditionalCommissions(dealId));
  dispatch(fetchAdditionalCommissionTypeOptions());
  dispatch(fetchConsiderations(dealId));
  if (dealType === 'lease') {
    dispatch(fetchLeaseConsiderationTypeOptions());
  }
  dispatch(fetchInstallments(dealId));
  dispatch(fetchInstallmentOptions());
};

// patch a commission as needed using new base total to calculate
const patchItems = (dispatch, call, list, isPercent, baseTotal, keys = { value: 'gross_commission', percentage: 'voucher_gross_commission_percent' }) => {
  list.forEach((item) => {
    const changes = calculatePercentValueFromTotal(
      baseTotal, isPercent,
      isPercent
        ? item[keys.percentage]
        : item[keys.value],
      keys,
    );
    if (item[keys.percentage] !== changes[keys.percentage] || item[keys.value] !== changes[keys.value]) {
      dispatch(call(item.id, changes, true));
    }
  });
};

export const FINANCIAL_TYPES = {
  CONSIDERATION: 'CONSIDERATION',
  ADDITIONAL_COMMISSION: 'ADDITIONAL_COMMISSION',
  REBATE: 'REBATE',
  SHARED_FEE: 'SHARED_FEE',
  COMMISSION: 'COMMISSION',
  INSTALLMENT: 'INSTALLMENT',
};

const updateFinancials = (dealId, type) => async (dispatch, getState) => {
  const state = getState();
  const previousTotal = selectTotalCommissions(state);
  // get considerations and additional commissions to calculate base total
  await dispatch(fetchConsiderations(dealId));
  await dispatch(fetchAdditionalCommissions(dealId));
  await dispatch(fetchOutsideBrokerCommissions({ transaction: dealId, payment_group: 2 }));

  const totalCommissions = selectTotalCommissions(getState());
  // TODO, when do we need to run updates? should we send a type so it just does some or?
  if (totalCommissions === previousTotal && (type === FINANCIAL_TYPES.CONSIDERATION || type === FINANCIAL_TYPES.ADDITIONAL_COMMISSION)) {
    return;
  }
  // update deal base total
  dispatch(updateDeal(dealId, { estimated_commission: totalCommissions, modified: new Date() }));

  // fetch data
  await dispatch(fetchSharedFees({ transaction: dealId, payment_group: 4 }));
  await dispatch(fetchRebates({ transaction: dealId, payment_group: 3 }));
  await dispatch(fetchCommissions({ transaction: dealId }));
  await dispatch(fetchInstallments(dealId));

  // get totals
  const totalCommissionsForRebates = selectTotalCommissionsForRebates(getState());
  const totalCommissionsForSharedFees = selectTotalCommissionsForSharedFees(getState());
  const totalCommissionsForCommissions = selectTotalCommissionsForCommissions(getState());
  const totalCommissionsForInstallments = selectTotalCommissionsForInstallments(getState());

  // update outside broker commissions
  const outsideBrokerCommissions = selectOutsideBrokerCommissions(getState());
  const outsideBrokerIsPercent = selectOutsideBrokerIsPercentage(getState());
  patchItems(dispatch, updateOutsideCommission, outsideBrokerCommissions, outsideBrokerIsPercent, totalCommissions);

  // update shared fees
  const sharedFees = selectSharedFees(getState());
  const feeSharingIsPercent = selectFeeSharingIsPercentage(getState());
  patchItems(dispatch, updateSharedFee, sharedFees, feeSharingIsPercent, totalCommissionsForSharedFees);

  // update rebates
  const rebates = selectRebates(getState());
  const rebatesIsPercentage = selectRebateIsPercentage(getState());
  patchItems(dispatch, updateRebate, rebates, rebatesIsPercentage, totalCommissionsForRebates);

  // update commissions employee, office, pool
  const commissions = selectCommissions(getState());
  const commissionsIsPercent = selectCBRECommissionIsPercent(getState());
  patchItems(dispatch, updateCommission, commissions, commissionsIsPercent, totalCommissionsForCommissions);

  // update installments
  const installments = selectInstallments(getState());
  const installmentsIsPercentage = selectInstallmentIsPercentage(getState());
  patchItems(
    dispatch,
    updateInstallment,
    installments,
    installmentsIsPercentage,
    totalCommissionsForInstallments,
    { value: 'installment_amount', percentage: 'voucher_installment_percent' },
  );
  // update pipeline timestamp
  dispatch(updateDealPipelineTimestamp(dealId));
};

export {
  fetchFinancials,
  updateFinancials,
};
