import React from 'react';
import PropTypes from 'prop-types';

const StackedLabeledItem = props => (
  <div className="nd-stacked-labeled-item">
    <label>{props.label}</label>
    <p>{props.value || '--'}</p>
  </div>
);

StackedLabeledItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})]),
};
StackedLabeledItem.defaultProps = {
  value: null,
};
export default StackedLabeledItem;
