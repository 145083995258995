import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import unionBy from 'lodash/unionBy';
import pick from 'lodash/pick';
import moment from 'moment';
import PropTypes from 'prop-types';

// Services
import transactionsService from '../../services/transactions-service';
import propertiesService from '../../services/properties-service';
import dealsService from '../../services/deals-service';
import userEventService from '../../services/user-event-service';

// Components
import EmptyState from '../../components/empty-state/empty-state';
import PageHeaderDefault from '../../nucleus/header/page-header-default';
import LoadMore from '../../nucleus/load-more/load-more';
import Modal from '../../nucleus/modal/modal';
import VoucherSearchActionRow from '../../components/voucher-search/voucher-search-action-row';
import VoucherSearchAdvancedControls from '../../components/voucher-search/voucher-search-advanced-controls';
import VoucherSearchListItem from '../../components/voucher-search/voucher-search-list-item';

// Assets
import overflowSVG from '../../assets/images/svg/overflow.svg';

class VouchersSearchPage extends React.Component {
  constructor(props) {
    super(props);

    this.sortingKeys = [
      {
        displayName: 'Close Date',
        ascSortingKey: 'date_approved',
        descSortingKey: '-date_approved',
      },
      {
        displayName: 'Square Feet',
        ascSortingKey: 'square_feet',
        descSortingKey: '-square_feet',
      },
      {
        displayName: 'Lead Office',
        ascSortingKey: 'lead_office',
        descSortingKey: '-lead_office',
      },
    ];

    // note: UI shows deal-type, but search API expects rep_role parameter
    this.defaultSearchParams = {
      page: 1,
      ordering: this.sortingKeys[0].descSortingKey,
      lead_office: '',
      deal_id: '',
      property_type: '',
      rep_role: '',
      min_sqft: '',
      max_sqft: '',
      start_date: '',
      end_date: '',
    };

    this.defaultPropertyTypeSelection = {
      display_name: 'All Property Types',
      value: '',
    };

    this.dealTypeOptions = [{ display_name: 'All Deal Types', value: '' }]; // remainder populated from API

    // Get initial search params from localStorage or utilize defaults
    const initialSearchParams = (localStorage.getItem('transactions-search-params') && !this.props.location.search)//eslint-disable-line
      ? JSON.parse(localStorage.getItem('transactions-search-params'))
      : this.defaultSearchParams;

    const initialPropertyType = localStorage.getItem('transactions-search-property-type')
      ? JSON.parse(localStorage.getItem('transactions-search-property-type'))
      : this.defaultPropertyTypeSelection;

    // The date format accepted by the sever and the format displayed to the user are different
    this.formatDateDisplay = date => (date ? moment(date).format('M/D/YYYY') : '');

    this.state = {
      searchParams: {
        ...initialSearchParams,
        company: this.props.location.search
          ? new URLSearchParams(this.props.location.search).get('company')
          : initialSearchParams.company,
        start_date: initialSearchParams.start_date,
        end_date: initialSearchParams.end_date,
        page: 1,
      },
      transactions: [],
      dealTypeSelection: this.dealTypeOptions[0],
      propertyTypeOptions: [],
      propertyTypeSelection: initialPropertyType,
      filterTags: this.getFilterTags(initialSearchParams),
      count: 0,
      isLoading: true,
      noResults: false,
      next: null,
      showAdvancedSearchModal: false,
      validityDict: {
        start_date: true,
        end_date: true,
      },
    };

    this.debounceHandleFetchTransactions = debounce(this.handleFetchTransactions, 500);
  }

  componentDidMount() {
    this.handleFetchTransactions('refresh');
    this.handleFetchPropertyTypes();
    this.handleGetDealOptions();
    document.title = 'Deal IQ | Voucher Search';
  }

  getFilterTags = (params) => {
    const dealType = this.dealTypeOptions.find(element => element.value === params.rep_role);
    const dealTypeValue = (params.rep_role && dealType) ? dealType.display_name : '';

    return [
      {
        key: 'lead_office',
        label: 'Lead Office',
        value: params.lead_office,
      },
      {
        key: 'deal_id',
        label: 'Deal ID',
        value: params.deal_id,
      },
      {
        key: 'rep_role',
        label: 'Deal Type',
        value: dealTypeValue,
      },
      {
        key: 'property_type',
        label: 'Property Type',
        value: params.property_type,
      },
      {
        key: 'sqft_range',
        label: 'SqFt Range',
        minValue: params.min_sqft,
        maxValue: params.max_sqft,
      },
      {
        key: 'close_date',
        label: 'Close Date',
        minValue: this.formatDateDisplay(params.start_date),
        maxValue: this.formatDateDisplay(params.end_date),
      },
    ];
  };

  handleGetDealOptions = () => {
    dealsService.getDealOptions()
      .then((data) => {
        data.actions.POST.rep_role.choices.forEach((item) => {
          // label is combo of deal-type & rep_role. rep_role retained as value for search filter
          // eslint-disable-next-line
          const label = (item.value < 3) ? 'Lease - ' : (item.value < 5) ? 'Sale - ' : '';
          this.dealTypeOptions.push({
            display_name: label + item.display_name,
            value: item.display_name.toLowerCase(),
          });
        });
        if (this.state.searchParams.rep_role) {
          const initialDealType = this.dealTypeOptions.find(element => element.value === this.state.searchParams.rep_role);
          this.setState({
            dealTypeSelection: initialDealType,
          });
          this.updateFilterTags();
        }
      })
      .catch(error => console.error('Error fetching deal options: ', error));
  };

  trackAdvancedFilters = (numResults) => {
    const { searchParams } = this.state;

    const advancedParamKeys = [
      'deal_id',
      'end_date',
      'lead_office',
      'max_sqft',
      'min_sqft',
      'property_type',
      'rep_role',
      'start_date',
    ];

    // array of "advanced" filter keys which had non empty values in last search
    const advancedParamKeysUsed = advancedParamKeys.filter(key => searchParams[key]);

    // noop if no "advanced" filters were used in search
    if (!advancedParamKeysUsed.length) { return; }

    const eventAction = numResults ? 'advanced_search_success' : 'advanced_search_null_results';
    const eventLabel = advancedParamKeysUsed.join(', ');
    const advancedSearchParamsUsed = pick(searchParams, advancedParamKeysUsed);

    userEventService.trackEvent({
      eventCategory: 'Voucher Search',
      eventLabel,
      eventAction,
      eventData: advancedSearchParamsUsed,
    });
  };

  trackSearchResults = (numResults) => {
    const { searchParams } = this.state;

    this.trackAdvancedFilters(numResults);

    if (searchParams.company) {
      const eventAction = numResults ? 'company_search_success' : 'company_search_null_results';
      userEventService.trackEvent({
        eventLabel: searchParams.company,
        eventCategory: 'Voucher Search',
        eventAction,
      });
    }

    if (searchParams.location) {
      const eventAction = numResults ? 'location_search_success' : 'location_search_null_results';
      userEventService.trackEvent({
        eventAction,
        eventLabel: searchParams.location,
      });
    }
  };

  trackResultClicked = (index) => {
    const { searchParams } = this.state;
    if (searchParams.company) {
      userEventService.trackEvent({
        eventAction: 'company_search_interaction',
        eventCategory: 'Voucher Search',
        eventLabel: searchParams.company,
        eventValue: index,
      });
    }

    if (searchParams.location) {
      userEventService.trackEvent({
        eventAction: 'location_search_interaction',
        eventLabel: searchParams.location,
      });
    }
  };

  // The action argument determines if more transactions are added to the current list
  // or if the list needs to be refreshed (i.e. new search params)
  handleFetchTransactions = (action) => {
    const callback = () => {
      transactionsService
        .fetchTransactions(this.state.searchParams)
        .then((data) => {
          this.trackSearchResults(data.results.length);
          // Display the noResults asset if voucher search does not return any transactions
          if (data.results.length < 1) {
            this.setState({
              transactions: [],
              isLoading: false,
              noResults: true,
              count: 0,
            });
          } else if (action === 'append') {
            this.appendTransactions(data);
          } else if (action === 'refresh') {
            this.refreshTransactions(data);
          }
        })
        .catch(error => console.error('Error fetching transactions: ', error));
    };

    const paramsToCache = cloneDeep(this.state.searchParams);
    delete paramsToCache.page;
    localStorage.setItem('transactions-search-params', JSON.stringify(paramsToCache));
    localStorage.setItem('transactions-search-property-type', JSON.stringify(this.state.propertyTypeSelection));

    if (action === 'refresh') {
      this.setState({
        isLoading: true,
        transactions: [],
        searchParams: {
          ...this.state.searchParams,
          page: 1,
        },
      }, () => callback());
    } else if (action === 'append') {
      this.setState({
        isLoading: true,
        searchParams: {
          ...this.state.searchParams,
          page: (this.state.searchParams.page += 1),
        },
      }, () => callback());
    }
  };

  // Append to current transaction list on state (increment page)
  appendTransactions = (data) => {
    this.setState({
      transactions: unionBy(this.state.transactions, data.results),
      count: data.count,
      isLoading: false,
      noResults: false,
      next: data.next,
    });
  };

  // Refresh transaction list to account for new search params (reset page)
  refreshTransactions = (data) => {
    this.setState({
      transactions: data.results,
      count: data.count,
      isLoading: false,
      noResults: false,
      next: data.next,
    });
  };

  handleSearchTermChange = (value, searchKey) => {
    const newSearchParams = cloneDeep(this.state.searchParams);
    newSearchParams[searchKey] = value;

    // reset the URL to /vouchers if a user updates the search
    // after it has been pre-populated from SPOC search
    if (this.props.location.search) {
      window.history.replaceState(null, null, '/vouchers');
    }

    // Call api only when searchParams is different than the current search.
    if (!isEqual(this.state.searchParams, newSearchParams)) {
      this.setState({
        searchParams: newSearchParams,
      });
      this.debounceHandleFetchTransactions('refresh');
    }
  };

  handleFetchPropertyTypes = () => {
    propertiesService
      .fetchPropertyOptions()
      .then((data) => {
        const propertyTypeOptions = data.actions.POST.property_type.choices;
        // eslint-disable-next-line
        propertyTypeOptions.forEach((option) => {
          if (option.value === '') {
            const updatedOption = {
              ...option,
              display_name: 'All Property Types',
            };
            return updatedOption;
          }
        });

        // Change list order so that final index appears first
        const option = propertyTypeOptions.pop();
        propertyTypeOptions.unshift(option);

        this.setState({
          propertyTypeOptions,
        });
      })
      .catch((error) => {
        console.error('Error fetching property options: ', error);
      });
  };

  // Update state and localStorage with any search param changes that are invoked
  // by the user removing a filter tag and then fetch new results
  handleClearFilter = (searchKey) => {
    this.setState({
      transactions: [],
      isLoading: true,
      noResults: false,
    });

    if (searchKey === 'close_date') {
      this.setState(
        {
          searchParams: {
            ...this.state.searchParams,
            start_date: '',
            end_date: '',
          },
        },
        () => {
          this.updateFilterTags();
        },
      );
    } else if (searchKey === 'sqft_range') {
      this.setState(
        {
          searchParams: {
            ...this.state.searchParams,
            min_sqft: '',
            max_sqft: '',
          },

        },
        () => {
          this.updateFilterTags();
        },
      );
    } else {
      this.setState(
        {
          searchParams: {
            ...this.state.searchParams,
            [searchKey]: '',
          },
        },
        () => {
          this.updateFilterTags();
        },
      );
    }

    // update local storage when when deal_type filter is applied
    if (searchKey === 'rep_role') {
      this.setState({ dealTypeSelection: this.dealTypeOptions[0] });
    }

    // PropertyTypeSelection is a secondary localStorage item that needs an
    // additional update when property_type filter is applied
    if (searchKey === 'property_type') {
      this.setState({ propertyTypeSelection: this.defaultPropertyTypeSelection });
    }

    this.debounceHandleFetchTransactions('refresh');
  };

  handleSortingKeyChange = (ordering) => {
    // Upate searchParam and fetch results
    this.setState({
      searchParams: {
        ...this.state.searchParams,
        ordering,
      },
    });

    this.debounceHandleFetchTransactions('refresh');
  };

  trackLoadMoreClicked = () => {
    userEventService.trackEvent({
      eventCategory: 'Voucher Search',
      eventLabel: 'onClick',
      eventAction: 'load_more_clicked',
    });
  };

  handleLoadMore = () => {
    this.trackLoadMoreClicked();
    this.handleFetchTransactions('append');
  };

  trackFilterModalOpen = () => {
    userEventService.trackEvent({
      eventCategory: 'Voucher Search',
      eventLabel: 'onClick',
      eventAction: 'advanced_search_modal_opened',
    });
  };

  openAdvancedSearchModal = () => {
    this.trackFilterModalOpen();
    this.setState({ showAdvancedSearchModal: true });
  };

  // If the modal is closed without applying changes we want to refer to the cached params
  // on localStorage unless they do not exist yet then we fall back to the defaults
  toggleAdvancedSearchModal = () => {
    this.setState({
      showAdvancedSearchModal: !this.state.showAdvancedSearchModal,
      searchParams: localStorage.getItem('transactions-search-params')
        ? JSON.parse(localStorage.getItem('transactions-search-params'))
        : this.defaultSearchParams,
      propertyTypeSelection: localStorage.getItem('transactions-search-property-type')
        ? JSON.parse(localStorage.getItem('transactions-search-property-type'))
        : this.defaultPropertyTypeSelection,
    });
  };

  // Updates the state of the search params which updates the inputs on the
  // VoucherSearchAdvancedControls component via props
  updateAdvancedSearchParams = (value, searchKey) => {
    this.setState({
      searchParams: {
        ...this.state.searchParams,
        [searchKey]: value,
      },
    });
  };

  /* Update the state of the rep_role and deal-type selection
   */
  updateDealTypeSelection = (option) => {
    this.setState({
      searchParams: {
        ...this.state.searchParams,
        rep_role: option.value,
      },
      dealTypeSelection: option,
    });
  };

  // Updates the state of the property type and current selection which updates the Select
  // component via props
  updatePropertyTypeSelection = (option) => {
    this.setState({
      searchParams: {
        ...this.state.searchParams,
        property_type: option.value,
      },
      propertyTypeSelection: option,
    });
  };

  updateFieldValidity = (isFieldValid, key) => {
    this.setState({
      validityDict: {
        ...this.state.validityDict,
        [key]: isFieldValid,
      },
    });
  };

  // Reset all advanced search fields when user clicks "Clear" button on advanced search modal
  handleClearAdvancedSearch = () => {
    this.setState({
      searchParams: {
        ...this.state.searchParams,
        lead_office: '',
        deal_id: '',
        rep_role: '',
        property_type: '',
        min_sqft: '',
        max_sqft: '',
        start_date: '',
        end_date: '',
      },
      dealTypeSelection: this.dealTypeOptions[0],
      propertyTypeSelection: {
        display_name: 'All Property Types',
        value: '',
      },
    });
  };

  // Update localStorage search parameters when advancedSearchModal is submitted
  applyAdvancedSearchParams = () => {
    // Object.values(this.state.validityDict) will be an array of booleans, e.g. [true, false, true, true]
    // isFormValid = true when array above does not contain any false
    const isFormValid = Object.values(this.state.validityDict).every(val => val);
    if (!isFormValid) { return; }

    this.setState({
      showAdvancedSearchModal: false,
      searchParams: {
        ...this.state.searchParams,
        start_date: this.state.searchParams.start_date.replace(/\//g, '-'),
        end_date: this.state.searchParams.end_date.replace(/\//g, '-'),
      },
    });
    this.updateFilterTags();
    this.handleFetchTransactions('refresh');
  };

  updateFilterTags = () => {
    this.setState({
      filterTags: this.getFilterTags(this.state.searchParams),
    });
  };

  render() {
    const {
      searchParams,
      transactions,
      count,
      isLoading,
      noResults,
      next,
      showAdvancedSearchModal,
      propertyTypeOptions,
      filterTags,
    } = this.state;

    const {
      lead_office: leadOffice,
      deal_id: dealID,
      min_sqft: minSqFt,
      max_sqft: maxSqFt,
      start_date: startDate,
      end_date: endDate,
    } = this.state.searchParams;

    const { dealTypeSelection, propertyTypeSelection } = this.state;
    const resultText = count === 1 ? 'result' : 'results';
    const subtitle = `${count} ${resultText}`;

    const renderSearchItems = () =>
      map(transactions, (transaction, index) => (
        <VoucherSearchListItem
          key={transaction.id}
          transaction={transaction}
          index={index}
          handleDetailLinkClick={this.trackResultClicked}
        />
      ));

    // Show isLoading SVG while vouchers are being fetched
    const loadingState =
      isLoading &&
      <div className="no-results-warning">
        <img src={overflowSVG} alt="loading" />
        <h2>Loading Vouchers</h2>
      </div>;

    // Show nullSearchState SVG if no results are returned
    const nullSearchState = (!isLoading && noResults) && <EmptyState title="No Vouchers Found" message="Expand your search to view vouchers." />;

    const loadMoreButton =
      transactions &&
      transactions.length > 0 &&
      <LoadMore
        isLoading={isLoading}
        handleLoadMore={this.handleLoadMore}
        hasNextPage={!!next}
      />;

    const advancedSearchControls = (
      <Modal
        showModal={showAdvancedSearchModal}
        handleModalToggle={this.toggleAdvancedSearchModal}
        handleModalSubmit={this.applyAdvancedSearchParams}
        handleSecondaryButton={this.handleClearAdvancedSearch}
        modalHeader="Advanced Search"
        primaryButtonText="Apply"
        secondaryButtonText="Clear"
      >
        <VoucherSearchAdvancedControls
          leadOffice={leadOffice}
          dealID={dealID}
          dealTypeOptions={this.dealTypeOptions}
          dealTypeSelection={dealTypeSelection}
          propertyTypeOptions={propertyTypeOptions}
          propertyTypeSelection={propertyTypeSelection}
          minSqFt={minSqFt}
          maxSqFt={maxSqFt}
          startDate={startDate}
          endDate={endDate}
          updateAdvancedSearchParams={this.updateAdvancedSearchParams}
          updateDealTypeSelection={this.updateDealTypeSelection}
          updatePropertyTypeSelection={this.updatePropertyTypeSelection}
          updateFieldValidity={this.updateFieldValidity}
        />
      </Modal>
    );

    return (
      <div>
        <PageHeaderDefault
          title="Voucher Search"
          subtitle="Identify previous deals with companies."
          controls="Activity on this page is monitored closely by CBRE"
          subtitlePosition="inline"
        >
          <span className="count">{subtitle}</span>
        </PageHeaderDefault>
        <div className="page-container">
          <div className="voucher-search">
            <VoucherSearchActionRow
              searchParams={searchParams}
              sortingKeys={this.sortingKeys}
              filterTags={filterTags}
              handleSearchTermChange={this.handleSearchTermChange}
              handleClearFilter={this.handleClearFilter}
              handleSortingKeyChange={this.handleSortingKeyChange}
              openAdvancedSearchModal={this.openAdvancedSearchModal}
            />
            {advancedSearchControls}
            {loadingState}
            {nullSearchState}
            {renderSearchItems()}
            {loadMoreButton}
          </div>
        </div>
      </div>
    );
  }
}

VouchersSearchPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

VouchersSearchPage.defaultProps = {
  location: {
    search: '',
  },
};

export default VouchersSearchPage;
