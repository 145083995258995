import React from 'react';
import { IconButton } from '@cbrebuild/blocks';
import { uniqueId } from 'lodash';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import LabeledItem from '../../../nucleus/labeled-item/labeled-item';
import VOUCHER_ROUTES from '../../../page-templates/voucher/voucher-subroutes';
import RequiredLabeledItem from './RequiredLabeledItem';

const PropertyReview = () => {
  const {
    commercialProperties, deal, voucher,
  } = useSelector(state => ({
    commercialProperties: state.voucher.commercialProperties,
    deal: state.deal.deal,
    voucher: state.voucher.voucher,
  }));
  const history = useHistory();

  const dealType = deal.deal_type;
  const leaseOrSaleDealType = dealType === 'sale' || dealType === 'lease';
  const emptyState = '--';
  let url;
  let title;
  switch (dealType) {
    case 'lease':
      url = VOUCHER_ROUTES[1].routes[0].path;
      title = 'Lease Information';
      break;
    case 'sale':
      url = VOUCHER_ROUTES[1].routes[3].path;
      title = 'Property';
      break;
    case 'consulting':
    case 'other':
      url = VOUCHER_ROUTES[1].routes[7].path;
      title = 'Overview';
      break;
    default:
  }
  url = url.replace(':id', voucher.deal);

  return (
    <div className="voucher-review-group property-review">
      <div className="navigation">
        <Link className="h2 link" to={url}>{title}</Link>
        <IconButton iconName="edit" onClick={() => { history.push(url); }} />
      </div>
      <div className="content">
        {commercialProperties.length === 0 && <legend className="secondary-legend">There are no properties in this deal.</legend>}
        {dealType === 'lease' &&
          <div>
            <legend className="secondary-legend">Lease Overview</legend>
            <div className="two-cols">
              <div className="col-left">
                <RequiredLabeledItem label="Lease Start Date" item={deal.date_lease_from} notRequired={voucher.is_early_invoice} isReview />
                <RequiredLabeledItem label="Lease Expiration Date" item={voucher.lease_expiration_date} notRequired={voucher.is_early_invoice} isReview />
                <RequiredLabeledItem label="Lease Execution Date" item={deal.date_lease_execution} notRequired={voucher.is_early_invoice} isReview />
                <RequiredLabeledItem label="Lease Occupancy Date" item={deal.date_lease_occupancy} notRequired={voucher.is_early_invoice} isReview />
              </div>
              <div className="col-right">
                <RequiredLabeledItem label="Lease Agreement Type" item={deal.lease_agreement_type} notRequired={voucher.is_early_invoice} isReview />
                <RequiredLabeledItem label="Lease Transaction Type" item={deal.lease_income_type} notRequired={voucher.is_early_invoice} isReview />
                <LabeledItem label="Agile Deal" item={deal.is_agile_deal ? 'Yes' : 'No'} />
                {deal.is_agile_deal &&
                  <div>
                    <LabeledItem label="Number of Seats" item={deal.number_of_seats} emptyState={emptyState} />
                    <LabeledItem label="Cost per Seat" item={deal.cost_per_seat} emptyState={emptyState} />
                  </div>}
                <LabeledItem label="Sublease" item={deal.is_sublease ? 'Yes' : 'No'} />
              </div>
            </div>
          </div>
        }
        {commercialProperties && commercialProperties.map((property, index) => (
          (
            <div key={property.id || index}>
              {(dealType === 'lease' || dealType === 'consulting' || dealType === 'other') &&
              <legend className="secondary-legend">Property #{index + 1}</legend>}
              <div className="two-cols">
                <div className="col-left">
                  {dealType === 'sale' &&
                    <RequiredLabeledItem label="Estimated Close Date" item={property.sale_est_close_date} notRequired={voucher.is_early_invoice} isReview />}
                  <LabeledItem label="Confidential" item={property.is_property_confidential ? ' Yes' : 'No'} />
                  {dealType === 'sale' &&
                    <RequiredLabeledItem label="Ownership Type" item={property.ownership_type} notRequired={voucher.is_early_invoice} isReview />}
                  <LabeledItem label="Property Name" item={property.name} emptyState={emptyState} />
                  <RequiredLabeledItem label="Address" item={property} format="address" isReview />
                  <RequiredLabeledItem
                    label="Property Type / Subtype"
                    item={property.voucher_property_type_category}
                    notRequired={voucher.is_early_invoice}
                    isReview
                  />
                  {leaseOrSaleDealType &&
                    <LabeledItem label="Listed By CBRE" item={property.is_listed ? 'Yes' : 'No'} />}
                  {leaseOrSaleDealType && property.is_listed &&
                    <LabeledItem label="CBRE Listing #" item={property.cbre_listing_number} emptyState={emptyState} />}
                  {leaseOrSaleDealType &&
                    <LabeledItem label="Managed By CBRE" item={property.is_property_managed_by_cbre ? 'Yes' : 'No'} />}
                  <LabeledItem label="Mix Property ID" item={property.mix_property_id} emptyState={emptyState} />
                  {dealType === 'lease' &&
                    <LabeledItem label="Building Class" item={property.building_class} emptyState={emptyState} />}
                  {leaseOrSaleDealType &&
                    <LabeledItem label="Portfolio Name" item={property.portfolio_name} emptyState={emptyState} />}
                </div>
                <div className="col-right">
                  {leaseOrSaleDealType &&
                    <RequiredLabeledItem
                      label="Space Type"
                      item={property.space_type}
                      notRequired={voucher.is_early_invoice && leaseOrSaleDealType}
                      isReview
                    />}
                  {dealType === 'sale' &&
                    <LabeledItem label="Number of Tenants" item={property.number_of_tenants} emptyState={emptyState} />}
                  {dealType === 'sale' &&
                    <LabeledItem label="Number of Units" item={property.number_of_units} emptyState={emptyState} />}
                  {property.whole_building && leaseOrSaleDealType &&
                    <RequiredLabeledItem
                      label="Square Footage"
                      item={property.building_total_square_feet}
                      notRequired={voucher.is_early_invoice && !leaseOrSaleDealType}
                      isReview
                    />}
                  {leaseOrSaleDealType &&
                    <RequiredLabeledItem label="Parcel Size/Acres" item={property.parcel_size} format="number" emptyState={emptyState} notRequired isReview />}
                  {!property.whole_building &&
                    property.spaces.map((space, i) => (
                      (
                        <div key={uniqueId} >
                          <legend className="secondary-legend">Suite #{i + 1}</legend>
                          <LabeledItem label="Suite" item={space.address_line2} emptyState={emptyState} />
                          <LabeledItem label="Floor" item={space.floor} emptyState={emptyState} />
                          {!leaseOrSaleDealType &&
                            <LabeledItem label="Square Feet" item={space.space_total_square_feet} emptyState={emptyState} />}
                          {leaseOrSaleDealType &&
                            <RequiredLabeledItem label="Square Feet" item={space.space_total_square_feet} notRequired={voucher.is_early_invoice} isReview />}
                        </div>
                      )
                    ))}
                </div>
              </div>
            </div>
          )))}
      </div>
    </div>
  );
};

export default PropertyReview;
