import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon, IconButton } from '@cbrebuild/blocks';
import { useSelector } from 'react-redux';
import formatAsCurrency from '../../utils/format-as-currency';
import Tooltip from '../../nucleus/tooltip/tooltip';
import AllDealMembers from './all-deal-members';
import localStorageService from '../../services/local-storage-service';
import selectPipelineReport from '../../redux/selectors/pipeline-selectors';

const PipelineTable = React.forwardRef((props, ref) => {
  const {
    deals,
    ordering,
    handleColumnSort,
    selectForPipeline,
    isLoading,
    count,
  } = props;

  const isDescendingOrder = ordering[0] === '-';
  const arrowIcon = <Icon className={isDescendingOrder ? 'flip-vertical' : ''} iconName="arrow-up" />;
  const dealListIndex = JSON.parse(window.localStorage.getItem('dealListIndex'));

  const tableConfig = useSelector(selectPipelineReport);
  const colGroupWidthConf = {
    is_on_pipeline: 43,
    name: 362,
    deal_type: 162,
    deal_life_cycle_stage: 132,
    estimated_commission: 184,
    conversion_potential: 144,
    firstInstallmentDate: 144,
    client_name: 283,
    property_type: 162,
    property_address: 336,
    team: 402,
    lead_producer: 154,
    lease_type: 162,
    lease_term: 250,
    square_feet: 132,
  };
  return (
    <div className="pipeline-table-wrapper" ref={ref}>
      <table className="pipeline-table">
        <thead className="pipeline-table-header">
          <tr className="dark-table-row">
            {
             tableConfig.map(tc => (
                !tc.isHidden &&
                <th
                  key={tc.key}
                  id={tc.id}
                  className={`${tc.hasTwoParams ? '' : 'sortable-column-header'} ${tc.additionalStyleClass || ''}`}
                  onClick={tc.hasTwoParams ? () => handleColumnSort(tc.sortParam, true) : () => handleColumnSort(tc.sortParam)}
                  style={{ minWidth: `${colGroupWidthConf[tc.key]}px` }}
                >
                  <span className={tc.spanClass || ''}>
                    {tc.value} {ordering.includes(tc.sortParam) && arrowIcon}
                  </span>
                </th>
            ))}
          </tr>
        </thead>
        <tbody className="pipeline-table-body">
          {deals.map((deal, index) => (
            <PipelineTableRow
              key={deal.id}
              deal={deal}
              selectForPipeline={selectForPipeline}
              count={count}
              index={index}
              tableConfig={tableConfig}
              dealListIndex={dealListIndex}
            />
          ))}
          {!deals.length && !isLoading && (
            <tr className="empty-state-row">
              <td colSpan="8" >
                No deals matching your current filters.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
});

PipelineTable.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.object).isRequired,
  ordering: PropTypes.string.isRequired,
  handleColumnSort: PropTypes.func.isRequired,
  selectForPipeline: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  featureFlags: PropTypes.shape({}),
  count: PropTypes.number,
};

PipelineTable.defaultProps = {
  featureFlags: {},
  count: null,
};

const PipelineTableRow = (props) => {
  const {
    deal,
    selectForPipeline,
    index,
    count,
    tableConfig,
    dealListIndex,
  } = props;
  const {
    name,
    id,
    deal_life_cycle_stage,
    client_name,
    estimated_commission,
    estimated_commission_is_aggregate,
    is_on_pipeline,
    conversion_potential,
    property_address,
    is_mta,
    deal_type,
    rep_role,
    is_valid,
    firstInstallmentDate,
    property_type,
    lead_producer,
    pipeline_extra_fields: {
      lease_type,
      lease_term,
      square_feet,
    },
    permissions: {
      teams = [],
      users = [],
    } = {},
    brokers: {
      commissioned_users = {},
    } = {},
  } = deal;

  const [lastVisitedRow, setLastVisitedRow] = useState(false);

  const rowRef = useRef();
  useEffect(() => {
    if (dealListIndex === index) {
      setLastVisitedRow(true);
    }
  }, []);

  useEffect(() => {
    if (lastVisitedRow) {
      rowRef.current.scrollIntoView();
      // only enable scroll first time
      window.localStorage.removeItem('dealListIndex');
    }
  }, [lastVisitedRow]);

  const invalidWarning = (
    <Tooltip message="Click the deal to update required information.">
      <Icon iconName="warning" />
    </Tooltip>
  );

  const clickPipelineDeal = () => {
    localStorageService.saveDealTogglerData({
      id,
      index,
      count,
      source: 'Pipeline',
    });
  };

  const linkToDeal = is_mta ?
    `/deals/detail/${id}/commissions` :
    `/deals/detail/${id}/pipeline`;

  const addToPipeline = (<IconButton
    iconName={is_on_pipeline ? 'checkmark' : 'plus'}
    onClick={() => selectForPipeline(id, !is_on_pipeline, deal_life_cycle_stage)}
    variant={is_on_pipeline ? 'primary' : 'basic'}
  />);

  const requiredError = (<p className="error">* Required</p>);

  const dealLink = (
    <>
      <span className="invalid-icon">
        {is_valid ? '' : invalidWarning}
      </span>
      <span className="deal-link">
        <Link
          onClick={clickPipelineDeal}
          to={linkToDeal}
        >
          <span className="link-text">
            {name}
          </span>
        </Link>
      </span>
    </>
  );

  const dealType = `${deal_type} ${((deal_type !== 'consulting' && deal_type !== 'other') && ` - ${rep_role}`) || ''}`;

  const renderInfoIcon = estimated_commission_is_aggregate && (
    <Tooltip message="This is the sum total of multiple transactions associated to the deal">
      <Icon iconName="info-circle" />
    </Tooltip>);


  // need to find the key to the array and we don't know the deal associated with it
  const brokers = commissioned_users[Object.keys(commissioned_users).find(k => !!commissioned_users[k])] || [];

  const initTableData = {
    is_on_pipeline: addToPipeline,
    name: dealLink,
    lead_producer: `${lead_producer || '--'}`,
    deal_type: dealType,
    deal_life_cycle_stage,
    estimated_commission: <>{estimated_commission ? formatAsCurrency(estimated_commission) : requiredError} {renderInfoIcon || ''}</>,
    conversion_potential: `${conversion_potential ? `${conversion_potential}%` : '--'}`,
    firstInstallmentDate: `${firstInstallmentDate || '--'}`,
    client_name: `${client_name || '--'}`,
    property_address: `${property_address || '--'}`,
    property_type: `${property_type || '--'}`,
    lease_type: `${lease_type || '--'}`,
    lease_term: `${lease_term || '--'}`,
    square_feet: `${square_feet || '--'}`,
    team: <AllDealMembers
      teams={teams}
      users={users}
      brokers={brokers}
    />,
  };

  return (
    <>
      {/* Still working out the value of this row with product */}
      {/* They want (calc_comm || est_comm) but we are currently unable to sort by that */}
      {/* <td>{formatAsCurrency(calculated_commission || estimated_commission)}</td> */}
      <tr className={is_on_pipeline ? 'on-pipeline' : undefined} key={index} ref={rowRef} >
        {
          tableConfig.map(tc => (
            !tc.isHidden &&
            <td className={tc.tdClass} key={tc.key} >
              {initTableData[tc.key]}
            </td>
          ))
        }
      </tr>
    </>
  );
};

PipelineTableRow.propTypes = {
  dealListIndex: PropTypes.number,
  index: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  deal: PropTypes.shape({
    client_name: PropTypes.string,
    conversion_potential: PropTypes.number,
    deal_life_cycle_stage: PropTypes.string,
    deal_type: PropTypes.string,
    estimated_commission: PropTypes.number,
    estimated_commission_is_aggregate: PropTypes.bool,
    firstInstallmentDate: PropTypes.string,
    id: PropTypes.number.isRequired,
    is_mta: PropTypes.bool,
    is_on_pipeline: PropTypes.bool,
    is_valid: PropTypes.bool,
    name: PropTypes.string,
    property_address: PropTypes.string,
    property_type: PropTypes.string,
    lead_producer: PropTypes.string,
    rep_role: PropTypes.string,
    pipeline_extra_fields: PropTypes.shape({
      lease_type: PropTypes.string,
      lease_term: PropTypes.string,
      square_feet: PropTypes.number,
    }),
    permissions: PropTypes.shape({
      teams: PropTypes.arrayOf(PropTypes.shape({})),
      users: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    brokers: PropTypes.shape({}),
  }).isRequired,
  selectForPipeline: PropTypes.func.isRequired,
  tableConfig: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

PipelineTableRow.defaultProps = {
  dealListIndex: null,
};
export default PipelineTable;
