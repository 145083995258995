import moment from 'moment';
import momentT from 'moment-timezone';

const formatSubmissionDate = (voucher) => {
  let submittedDate = voucher.submitted_date;

  // If submission dates for voucher and early invoice exist then return the most recent
  if (voucher.submitted_date && voucher.early_invoice_submitted_date) {
    const dateDiff = moment(voucher.submitted_date).diff(moment(voucher.early_invoice_submitted_date));

    if (dateDiff < 0) {
      submittedDate = voucher.early_invoice_submitted_date;
    }
  } else if (voucher.early_invoice_submitted_date && !voucher.submitted_date) {
    submittedDate = voucher.early_invoice_submitted_date;
  }
  if (!submittedDate) {
    submittedDate = voucher.submission_results?.passed_to_queue_at;
  }
  const formattedDate =
    `${moment(submittedDate).format('MM/DD/YYYY')} at ${moment(submittedDate).format('hh:mm A ')}${momentT().tz('America/Los_Angeles').format('z')}`;
  return formattedDate;
};

export default formatSubmissionDate;
