/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */

const getParamsFromURL = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const paramsObject = {};

  for (const [key, value] of queryParams) {
    paramsObject[key] = value;
  }

  return paramsObject;
};

const storeParamsInURL = (params) => {
  const serializedParams = Object.entries(params)
    .filter(arr => arr[1] !== null)
    .map(arr => arr.join('='))
    .join('&');

  const queryString = `?${serializedParams}`;

  window.history.replaceState({}, '', queryString);
};

export default {
  getParamsFromURL,
  storeParamsInURL,
};
