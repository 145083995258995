import React from 'react';
import PropTypes from 'prop-types';

const VoucherStyledLabel = ({
  variant,
  hoverText,
  hoverAlign,
  content,
  className,
  voucherID,
  isvoucheriddisplay,
  dealstage,
  dealHeader,
  ...other
}) => (
  <div style={{ display: 'inline-grid' }}>
    <div {...other} className={`nd-styled-label ${variant} ${className || ''} hoverstate`}>
      <span>{content.toUpperCase()}</span>
      {hoverText && <span className={`hoverstatetext align-${hoverAlign}`}>{hoverText}</span>}
    </div>
    {isvoucheriddisplay && dealHeader &&
    <p className={`secondary-text ${dealstage}`} style={{ marginTop: '-15px', marginLeft: '0px' }}>Deal IQ Voucher ID: {voucherID}</p>}
  </div>);

VoucherStyledLabel.propTypes = {
  variant: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary', 'quaternary']),
  hoverText: PropTypes.shape({}),
  hoverAlign: PropTypes.oneOf(['left', 'center', 'right']),
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
  voucherID: PropTypes.string,
  isvoucheriddisplay: PropTypes.bool,
  dealstage: PropTypes.string,
  dealHeader: PropTypes.bool,
};

VoucherStyledLabel.defaultProps = {
  variant: 'default',
  hoverText: '',
  hoverAlign: 'center',
  className: '',
  voucherID: '',
  isvoucheriddisplay: false,
  dealstage: '',
  dealHeader: false,
};

export default VoucherStyledLabel;
