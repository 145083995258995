import React from 'react';
import PropTypes from 'prop-types';
import DealStatusForm from '../deal-status-action/deal-status-form';
import StatusStrings from '../../utils/status-strings';
import ButtonWithFlyout from '../../nucleus/button-with-flyout/button-with-flyout';

class DealStatusAction extends React.Component {
  static propTypes = {
    deal: PropTypes.shape({
      dealStage: PropTypes.string,
    }).isRequired,
    updateDeal: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  constructor(props) {
    super(props);

    this.buttonWithFlyoutRef = React.createRef();
  }

  setActiveNav = (dealStage) => {
    const eventName = 'setActiveNav';
    const event = new CustomEvent(eventName, {
      detail: dealStage,
    });

    window.dispatchEvent(event);
  }

  updateDealCounts = () => {
    const eventName = 'updateDealCounts';
    const event = new Event(eventName);

    window.dispatchEvent(event);
  }

  closeFlyout = () => {
    this.buttonWithFlyoutRef.current.closeFlyout();
  }

  render() {
    const {
      deal,
      updateDeal,
      disabled,
    } = this.props;

    let dealStageobj = {};
    if (deal && deal.deal_life_cycle_stage) {
      dealStageobj = StatusStrings.getDealStageOption(deal.deal_life_cycle_stage);
    }

    const dealStatusButtonContent = (
      <div className="button-content-wrapper">
        <div className={`deal-status-indicator ${dealStageobj.display_name}`} />
        <span>{dealStageobj.display_name}</span>
      </div>
    );

    return (
      <div className="deal-status-action">
        <ButtonWithFlyout
          buttonIcon="chevron-down"
          buttonText={dealStatusButtonContent}
          dataE2e="deal-status-flyout"
          ref={this.buttonWithFlyoutRef}
          position="flex"
          disabled={deal.is_mta || disabled}
        >
          <DealStatusForm
            deal={deal}
            closeFlyout={this.closeFlyout}
            updateDeal={updateDeal}
            updateDealCounts={this.updateDealCounts}
            setActiveNav={this.setActiveNav}
          />
        </ButtonWithFlyout>
      </div>
    );
  }
}

DealStatusAction.propTypes = {
  deal: PropTypes.shape({
    deal_life_cycle_stage: PropTypes.string.isRequired,
    is_mta: PropTypes.bool,
  }).isRequired,
};

export default DealStatusAction;
