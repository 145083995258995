import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../nucleus/modal/modal';
import Datepicker from '../../../nucleus/datepicker/datepicker';

import SyncDiagram from '../../../assets/images/png/sync-diagram.png';
import SyncLock from '../../../assets/images/png/sync-lock.png';
import CompanyAutocomplete from '../../autocomplete/company-autocomplete';
import Notice from '../../../nucleus/notices/notice';

class SyncSalesForceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      landlord: props.client || '',
      landlordError: '',
      showLandlordWarning: false,
      tempDateEstClose: props.dateEstClose,
      showError: false,
      isDateValid: true,
    };
  }

  handleDateChange = (date) => {
    if (date) {
      this.hideError();
    }
    this.setState({ tempDateEstClose: date });
  }

  handleSyncNow = () => {
    const {
      tempDateEstClose,
      landlord,
    } = this.state;
    const isValidLandlord = !this.props.isLeaseLandlord || (typeof landlord === 'string' && landlord) || !!landlord.name;
    if (!tempDateEstClose) {
      this.showError();
    }
    if (!isValidLandlord) {
      this.updateLandlordError('Landlord is required.');
    }

    if (!isValidLandlord || !tempDateEstClose) {
      return;
    }
    const params = { date_est_close: tempDateEstClose, user_wants_salesforce_export: true };
    if (this.props.isLeaseLandlord) {
      params.client = typeof landlord === 'string' ? { name: landlord } : { id: landlord.id };
    }
    this.props.handleModalSubmit(params);
  }

  handleModalToggle = () => {
    this.setState({ tempDateEstClose: this.props.dateEstClose });
    this.props.handleModalToggle();
  }

  handleCompanyChange = (company) => {
    const showLandlordWarning = !company.id && company !== '';
    const landlordError = company ? '' : 'Landlord is required.';
    this.setState({
      landlord: company,
      landlordError,
      showLandlordWarning,
    });
  };

  handleLandlordAlertDismiss = () => {
    this.setState({ showLandlordWarning: false });
  }

  showError = () => {
    this.setState({ showError: true });
  }

  hideError = () => {
    this.setState({ showError: false });
  }

  updateLandlordError = (landlordError) => {
    this.setState({ landlordError });
  }

  updateDateValidity = (bool) => {
    this.setState({ isDateValid: bool });
  }

  render() {
    const {
      showModal,
      isLeaseLandlord,
    } = this.props;
    const {
      landlord,
      landlordError,
      showLandlordWarning,
    } = this.state;

    // if date is not valid, Datepicker will show a separate error message
    // so we don't have to show this one
    const error = this.state.showError && this.state.isDateValid && (
      <p className="error-message">
        Estimated Close Date is required.
      </p>
    );

    const landlordAutocomplete = isLeaseLandlord && (
      <div className="field-item">
        <label className="small-input">* Landlord</label>
        <CompanyAutocomplete
          placeholder="Search for Company"
          onChange={this.handleCompanyChange}
          errorMessage={landlordError}
          initSearchTerm={landlord.name ? landlord.name : ''}
        />
        {showLandlordWarning && (
          <Notice
            text={` New Company "${landlord}" will be created.`}
            onDismiss={this.handleLandlordAlertDismiss}
            type="alert"
          />
        )}
      </div>
    );

    const columnClass = isLeaseLandlord ? 'col-2' : 'col-1';

    const modal = (
      <Modal
        className="sync-salesforce-modal"
        showModal={showModal}
        handleModalToggle={this.handleModalToggle}
        handleModalSubmit={this.handleSyncNow}
        handleSecondaryButton={this.handleModalToggle}
        modalHeader="Sync Deal To Salesforce"
        primaryButtonText="Sync Now"
        secondaryButtonText="Cancel"
        modalWidth={650}
      >
        <p className="row">
          Sync your deal to Salesforce with the click of a button
        </p>

        <div className="row">
          <div className="half-column">
            <img
              src={SyncDiagram}
              height="28"
              alt="sync diagram"
            />
            <h3 className="section-header">What is synced</h3>
            <ul>
              <li>Client Name</li>
              <li>Deal Name</li>
              <li>Deal Status</li>
              <li>Current Property</li>
              <li>Requirements</li>
              <li>Estimated Commission ($)</li>
            </ul>
          </div>
          <div className="half-column">
            <img
              src={SyncLock}
              height="28"
              alt="sync lock"
            />
            <h3 className="section-header">Not Synced</h3>
            <ul>
              <li>Contacts</li>
              <li>Notes</li>
              <li>Tasks</li>
              <li>Attachments</li>
            </ul>
          </div>
        </div>

        <div className="divider" />

        <div className="row">
          <p>
            Linking to Salesforce is a one-time, per-deal action.
            Going forward Deal IQ content for this deal will
            automatically be synced with Salesforce.
            <b>This action cannot be undone.</b>
          </p>
        </div>
        <div className={columnClass}>
          <div className="field-item">
            <label className="small-input">* Estimated Close Date</label>
            <Datepicker
              date={this.state.tempDateEstClose}
              onDateChange={this.handleDateChange}
              updateValidity={this.updateDateValidity}
              scrollOnFocus
            />
            {error}
          </div>
          {landlordAutocomplete}
        </div>
      </Modal>);
    return modal;
  }
}

SyncSalesForceModal.propTypes = {
  client: PropTypes.shape({}),
  dateEstClose: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  handleModalToggle: PropTypes.func.isRequired,
  handleModalSubmit: PropTypes.func.isRequired,
  isLeaseLandlord: PropTypes.bool,
};

SyncSalesForceModal.defaultProps = {
  client: undefined,
  dateEstClose: '',
  isLeaseLandlord: false,
};

export default SyncSalesForceModal;
