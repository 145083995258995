import formatNumberWithCommas from './format-number-with-commas';

const formatAsCurrency = (amount, decimal = 2) => {
  if (typeof amount !== 'number' && typeof amount !== 'string') {
    return '';
  }

  let num = Number(amount);

  if (Number.isNaN(num) === true) {
    return '';
  }

  // track if it was negative, then work with positive version
  const isNegative = num < 0;
  num = Math.abs(num);

  // format number with provided decimal point value
  const str = num.toFixed(decimal);

  // store dollars and cents separately for now
  const dollars = str.split('.')[0];
  const cents = str.split('.')[1];

  // add commas
  const commaFormattedDollars = formatNumberWithCommas(dollars);

  const sign = isNegative ? '-' : '';

  // now put it all together!! yeah!
  const fullyFormatted = cents === undefined ? `${sign}$${commaFormattedDollars}` : `${sign}$${commaFormattedDollars}.${cents}`;

  return fullyFormatted;
};

export default formatAsCurrency;
