import {
  ADD_TOAST,
  REMOVE_TOAST,
} from '../actions/toasts-actions';

const initialState = {
  toasts: [],
};

// Toasts Reducers
const addToast = (state, action) => {
  const { toasts } = state;
  const { id, message } = action.payload;
  const updatedToasts = [...toasts, { id, message }];
  return {
    ...state,
    toasts: updatedToasts,
  };
};

const removeToast = (state, action) => {
  const { toasts } = state;
  const { id } = action.payload;
  const updatedToasts = toasts.filter(t => t.id !== id);
  return {
    ...state,
    toasts: updatedToasts,
  };
};

const toasts = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOAST:
      return addToast(state, action);
    case REMOVE_TOAST:
      return removeToast(state, action);
    default:
      return state;
  }
};

export default toasts;
