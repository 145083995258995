import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconButton } from '@cbrebuild/blocks';

const AssignedTaskAlert = (props) => {
  const {
    task,
    formatDate,
    handleNavigateToTaskTrackingEvent,
    handleDismissAlert,
    turducken,
    taskAlertLink,
  } = props;
  const markAsReadTaskItemClass = task.read === null ? 'task-item-unread' : '';
  const renderFormattedDate = task.alert_datetime ? formatDate(task.alert_datetime) : '';
  const performedByMessage = task.performed_by
    ? (
      <React.Fragment>
        <span className="user-full-name">{task.performed_by.first_name} {task.performed_by.last_name} </span>
        assigned you a task
      </React.Fragment>
    )
    : 'You were assigned a task ';
  return (
    <li className={`assigned-task-alert-item ${markAsReadTaskItemClass}`}>
      <div className="task">
        <p>
          {performedByMessage}
          <Link
            to={{
              pathname: taskAlertLink,
              taskId: task.related_object.id,
            }}
            {...(turducken ? { target: '_self' } : {})}
            onClick={handleNavigateToTaskTrackingEvent}
          >
            {task.message}
          </Link>
        </p>
        <IconButton className="blxs-button-icon-small" iconName="close" onClick={() => handleDismissAlert(task.id)} variant="basic" />
      </div>
      <p className="date">{renderFormattedDate}
      </p>
    </li>
  );
};


export default AssignedTaskAlert;

AssignedTaskAlert.propTypes = {
  handleDismissAlert: PropTypes.func.isRequired,
  handleNavigateToTaskTrackingEvent: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
  task: PropTypes.shape({
    alert_datetime: PropTypes.string,
    id: PropTypes.number,
    performed_by: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    message: PropTypes.string,
    read: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape(),
    ]),
    related_object: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  turducken: PropTypes.bool.isRequired,
  taskAlertLink: PropTypes.string.isRequired,
};
