import {
  FETCH_OUTSIDE_BROKER_COMMISSIONS_SUCCESS,
  UPDATE_OUTSIDE_BROKER_COMMISSION_SUCCESS,
  CREATE_OUTSIDE_BROKER_COMMISSION_SUCCESS,
  DELETE_OUTSIDE_BROKER_COMMISSION_SUCCESS,
  UPDATE_OUTSIDE_BROKER_COMMISSION_VENDOR_SUCCESS,
} from '../actions/action-types';

const initialState = {
  commissions: [],
};

const outsideBrokerCommissions = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OUTSIDE_BROKER_COMMISSIONS_SUCCESS:
      return {
        ...state,
        commissions: action.payload,
      };
    case UPDATE_OUTSIDE_BROKER_COMMISSION_SUCCESS: {
      return {
        ...state,
        commissions: state.commissions.map(commission => (
          commission.id !== action.payload.id ? commission : action.payload
        )),
      };
    }
    case CREATE_OUTSIDE_BROKER_COMMISSION_SUCCESS: {
      return {
        ...state,
        commissions: [...state.commissions, action.payload],
      };
    }
    case DELETE_OUTSIDE_BROKER_COMMISSION_SUCCESS: {
      return {
        ...state,
        commissions: state.commissions.filter(({ id }) => id !== action.payload),
      };
    }
    case UPDATE_OUTSIDE_BROKER_COMMISSION_VENDOR_SUCCESS: {
      return {
        ...state,
        commissions: state.commissions.map(commission => (
          commission.id !== action.payload.id ? commission
            : { ...commission, vendor: action.payload.vendor }
        )),
      };
    }
    default:
      return state;
  }
};

export default outsideBrokerCommissions;
