import considerationsService from '../../../services/voucher/considerations-service';

import {
  FETCH_CONSIDERATIONS_SUCCESS,
  FETCH_VOUCHER_OPTIONS_SUCCESS,
  UPDATE_CONSIDERATIONS_IN_MODAL,
} from '../action-types';

// action creators
const fetchLeaseConsiderationTypeOptionsSuccess = options => ({
  type: FETCH_VOUCHER_OPTIONS_SUCCESS,
  payload: { leaseConsiderationTypeOptions: options },
});

const fetchConsiderationsSuccess = considerations => ({
  type: FETCH_CONSIDERATIONS_SUCCESS,
  payload: considerations,
});

const updateCosiderationsInModalSuccess = considerations => ({
  type: UPDATE_CONSIDERATIONS_IN_MODAL,
  payload: considerations,
});

// dispatches
const fetchLeaseConsiderationTypeOptions = () => (dispatch) => {
  considerationsService.fetchLeaseConsiderationTypeOptions()
    .then(options => dispatch(fetchLeaseConsiderationTypeOptionsSuccess(options)))
    .catch(error => console.log('Error fetching lease consideration type options: ', error));
};

const fetchConsiderations = dealId => dispatch => considerationsService.fetchConsiderations(dealId)
  .then((considerations) => {
    dispatch(fetchConsiderationsSuccess(considerations));
    return considerations;
  })
  .catch(error => console.log('Error fetching considerations: ', error));

const reorderConsiderations = considerationsArray => considerationsService.reorderConsiderations(considerationsArray)
  .then((considerationsOrderedResponse) => {
    console.log(considerationsOrderedResponse.message);
  }).catch(error => console.log('Error reordering considerations: ', error));

const updateConsiderations = dealId => (dispatch) => {
  considerationsService.fetchConsiderations(dealId)
    .then((considerations) => {
      dispatch(fetchConsiderationsSuccess(considerations));
    })
    .catch(error => console.log('Error fetching considerations: ', error));
};

const updateConsiderationsInModal = considerations => dispatch =>
  dispatch(updateCosiderationsInModalSuccess(considerations));

export {
  fetchConsiderations,
  reorderConsiderations,
  updateConsiderations,
  updateConsiderationsInModal,
  fetchLeaseConsiderationTypeOptions,
};
