import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import PageHeaderDefault from '../../nucleus/header/page-header-default';
import FullWidth from './full-width';
import FiftyFifty from './fifty-fifty';
import TwoThirds from './two-thirds';

function LayoutTemplates(props) {
  const subRoutes =
    [
      {
        link: '/layout-templates/full-width',
        path: '/layout-templates/full-width',
        text: 'Full Width',
        exact: true,
        component: FullWidth,
      },
      {
        link: '/layout-templates/fifty-fifty',
        path: '/layout-templates/fifty-fifty',
        text: 'Fifty Fifty',
        exact: true,
        component: FiftyFifty,
      },
      {
        link: '/layout-templates/two-thirds',
        path: '/layout-templates/two-thirds',
        text: 'Two Thirds',
        exact: true,
        component: TwoThirds,
      },
    ];

  return (
    <React.Fragment>
      <PageHeaderDefault
        title="Layout Templates"
        tabs={subRoutes}
        pageUrl={props.location.pathname}
      />
      {subRoutes.map(route =>
        (<Route
          path={route.link}
          exact={route.exact}
          key={route.link}
          component={route.component}
        />))}
    </React.Fragment>
  );
}

LayoutTemplates.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default LayoutTemplates;
