import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderValue: props.value,
      isDual: props.value && props.value.max !== undefined,
    };
  }

  shouldComponentUpdate(props) {
    if (props.value !== this.props.value) {
      this.onChange(props.value);
    }
    return true;
  }

  onChange = (sliderValue) => {
    this.setState({ sliderValue });
  }
  onChangeStart = () => {
  }
  onChangeComplete = (sliderValue) => {
    this.props.onChange(sliderValue);
  }
  render() {
    const {
      allowSameValues,
      disabled,
      draggableTrack,
      formatLabel,
      maxValue,
      minValue,
      label,
      step,
    } = this.props;

    const {
      sliderValue,
      isDual,
    } = this.state;

    const sliderClass = `nd-slider ${isDual ? ' dual' : ''}`;
    const renderLabel = label && <label>{label}</label>;
    return (
      <div className={sliderClass}>
        {renderLabel}
        <InputRange
          allowSameValues={allowSameValues}
          disabled={disabled}
          draggableTrack={draggableTrack}
          formatLabel={formatLabel}
          maxValue={maxValue}
          minValue={minValue}
          onChange={this.onChange}
          onChangeStart={this.onChangeStart}
          onChangeComplete={this.onChangeComplete}
          step={step}
          value={sliderValue}
        />
      </div>
    );
  }
}

Slider.propTypes = {
  allowSameValues: PropTypes.bool,
  disabled: PropTypes.bool,
  draggableTrack: PropTypes.bool,
  formatLabel: PropTypes.func,
  label: PropTypes.string,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
    }),
  ]).isRequired,
};

Slider.defaultProps = {
  allowSameValues: false,
  disabled: false,
  draggableTrack: false,
  formatLabel: () => { },
  label: undefined,
  step: 1,
};

export default Slider;

