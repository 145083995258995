import React from 'react';
import PropTypes from 'prop-types';
import clone from 'lodash/clone';
import findIndex from 'lodash/findIndex';
import debounce from 'lodash/debounce';
import unionBy from 'lodash/unionBy';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { RadioGroup, RadioButton, Button } from '@cbrebuild/blocks';
import { isEmpty } from 'lodash';


import pipelineService from '../../services/pipeline-service';
import trackingService from '../../services/tracking-service';
import dealsService from '../../services/deals-service';
import commaFormat from '../../utils/format-number-with-commas';
import formatAsCurrency from '../../utils/format-as-currency';

import PageHeaderDefault from '../../nucleus/header/page-header-default';
import ListActionHeader from '../../nucleus/list-action-header/list-action-header';
import EditColumnsButton from '../../components/edit-columns-button/edit-columns-button';
import LoadMore from '../../nucleus/load-more/load-more';
import PipelineFilters from '../../components/pipeline/pipeline-filters';
import PipelineFilterTags from '../../components/pipeline/pipeline-filter-tags';
import PipelineTable from '../../components/pipeline/pipeline-table';
import SearchInput from '../../nucleus/search/search-input';
import Modal from '../../nucleus/modal/modal';
import Notice from '../../nucleus/notices/notice';
import ProducerFilters from '../../components/pipeline/producer-filters';

class PipelineReportPage extends React.Component {
  constructor() {
    super();

    const cachedParams = this.getCachedParams();
    const defaultParams = {
      page: 1,
      page_size: 50,
      ordering: '-is_on_pipeline',
      tags: [],
      users: [],
      teams: [],
    };

    this.state = {
      deals: [],
      count: 0,
      pipelineCount: 0,
      filterPipelineCount: 0,
      hasNextPage: false,
      isLoading: true,
      emailSelection: '',
      showConfirmationModal: false,
      showEmailConfirmationModal: false,
      params: {
        ...defaultParams,
        ...cachedParams,
      },
      tableScrollLeft: 0,
      totalCBREGrossCommission: 0,
      totalUserGrossCommision: 0,
      totalSelectedGrossCommission: 0,
    };

    this.tableRef = React.createRef();
    this.headerRef = React.createRef();
  }

  componentDidMount() {
    document.title = 'Deal IQ | Pipeline Report';
    this.loadDeals();
    this.getPipelineCount();
    this.getPipelineGrossCommission();
    this.getUserPipelineGrossCommission();
    this.getPipelineGrossCommissionforSelectedUsers();
    this.props.fetchDealTags();
    this.props.loadDealsMetadata();
    window.addEventListener('resize', this.resizeTable);
    const tableContainer = this.tableRef.current;
    tableContainer.addEventListener('scroll', this.trackHorizontalScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeTable);
    const tableContainer = this.tableRef.current;
    tableContainer.removeEventListener('scroll', this.trackHorizontalScroll);
  }

  trackHorizontalScroll = debounce((event) => {
    const newTableScrollLeft = event.target.scrollLeft;
    if (newTableScrollLeft === this.state.tableScrollLeft) {
      return;
    }
    this.setState({ tableScrollLeft: newTableScrollLeft });
    const eventData = {
      eventCategory: 'Action',
      eventAction: 'pipeline_table_horizontal_scroll',
      eventValue: newTableScrollLeft,
      eventData: {
        tableViewWidth: event.target.clientWidth,
        tableTotalWidth: event.target.scrollWidth,
      },
    };
    trackingService.trackActions(eventData);
  }, 500);

  resizeTable = () => {
    const tableContainer = this.tableRef.current;
    const headerContinaer = this.headerRef.current;

    // 294px is estimated height for fixed height elements (topnav + pageheader + margin + loadmore + banner)
    tableContainer.style.maxHeight = window.innerHeight - 294 - headerContinaer.clientHeight;
  };

  scrollTable = () => {
    const tableContainer = this.tableRef.current;
    tableContainer.scrollTo({
      // 93 px is the estimate height of the table header and one pipeline table row
      // we use this, scrollTop and clientHeight so we can scroll the bottom item to the top of the list on load more
      top: (tableContainer.scrollTop + tableContainer.clientHeight) - 93,
      behavior: 'smooth',
    });
  };

  getPipelineCount = () => {
    // TODO: Update to use the below endpoint once both the regular and ES endpoint are in sync
    // dealsService.fetchDealsAndTransactionsES({ is_on_pipeline: true })
    //   .then((response) => {
    //     this.setState({ pipelineCount: response.count });
    //   })
    //   .catch(() => {
    //     this.setState({ pipelineCount: 0 });
    //   });
    pipelineService.fetchPipelineCount()
      .then((pipelineCount) => {
        this.setState({ pipelineCount });
      })
      .catch(() => {
        this.setState({ pipelineCount: 0 });
      });
    // }
  };

  getPipelineGrossCommission = () => {
    pipelineService.fetchPipelineGrossCommissions(this.props.userData.id)
      .then((data) => {
        const estimatedGrossCommission = data.total_commissions.total_estimated_gross_commission_on_transaction;
        const expectedGrossCommission = data.total_commissions.total_gross_commission_on_transaction;
        this.setState({
          totalCBREGrossCommission: parseFloat((estimatedGrossCommission + expectedGrossCommission).toFixed(2)),
        });
      })
      .catch(() => {
        console.log('error');
      });
  };

  getUserPipelineGrossCommission = () => {
    pipelineService.fetchPipelineGrossCommissions(this.props.userData.id)
      .then((data) => {
        const totalUserGrossCommision = data.total_commissions.total_gross_commission_to_user;
        this.setState({
          totalUserGrossCommision: totalUserGrossCommision ? parseFloat((data.total_commissions.total_gross_commission_to_user).toFixed(2)) : 0,
        });
      })
      .then(() => {
        console.log('error');
      });
  }

  getPipelineGrossCommissionforSelectedUsers = () => {
    const { params } = this.state;
    this.cacheParams(params);
    const { userData: { id: userId } } = this.props;
    const updatedParams = {
      selected_brokers: params.users.join(','),
      selected_teams: params.teams.join(','),
    };
    const fetch = pipelineService.fetchPipelineGrossCommissions;
    fetch(userId, updatedParams)
      .then((data) => {
        const totalSelectedGrossCommission = data.total_commissions.total_gross_commission_to_brokers;
        this.setState({
          totalSelectedGrossCommission: totalSelectedGrossCommission ? parseFloat(totalSelectedGrossCommission.toFixed(2)) : 0,
        });
      })
      .catch(() => {
        console.log('error');
      });
  };


  loadDeals = () => {
    const { params, deals } = this.state;
    this.cacheParams(params);

    // TODO: Update to dealsService.fetchDealsAndTransactionsES
    //  once both the regular and ES endpoint are in sync
    const fetch = pipelineService.fetchDeals;
    fetch({
      ...params,
      teams: params.teams.join(','),
      users: params.users.join(','),
    })
      .then((response) => {
        // if sent params aren't equal to current params ignore results
        if (!isEqual(params, this.state.params)) {
          return;
        }
        const { results, next, count } = response;
        const fetchedDeals = results.map(pipelineService.formatDealInstallments);

        this.setState({
          deals: deals.length ? unionBy(deals, fetchedDeals, 'id') : fetchedDeals,
          count,
          hasNextPage: (next !== null),
          isLoading: false,
        }, this.trackSearch);

        this.resizeTable();

        // scroll to bottom when loadmore
        if (params.page > 1) {
          this.scrollTable();
        }
      })
      .catch((error) => {
        console.error('Error fetching deals: ', error);
      });

    // Get Pipeline deals in filter results
    const { userData } = this.props;
    const filterParams = {
      ...params,
      is_on_pipeline: true,
      page: null,
      ordering: null,
      page_size: null,
      search: this.state.params.search,
      teams: this.state.params.teams.join(','),
      users: this.state.params.users.join(','),
    };
    pipelineService.fetchPipelineDealCounts(userData.id, filterParams)
      .then((res) => {
        const { filtered_pipeline_deals } = res.count_result;
        this.setState({
          filterPipelineCount: filtered_pipeline_deals,
        });
      })
      .catch((error) => {
        console.error('Error fetching filtered deal counts: ', error);
      });
  };

  loadMoreDeals = () => {
    const { params } = this.state;

    this.setState({
      params: {
        ...params,
        page: params.page + 1,
      },
      isLoading: true,
    }, this.loadDeals);

    const eventData = {
      eventCategory: 'Action',
      eventAction: 'load_more_clicked',
    };
    trackingService.trackActions(eventData);
  };

  selectForPipeline = async (id, bool, deal_life_cycle_stage) => {
    this.setState({ isLoading: true });
    const updateDeal = bool ? dealsService.addDealToPipeline : dealsService.removeDealFromPipeline;
    await updateDeal(id)
      .then(() => {
        const data = {
          eventCategory: 'Action',
          eventAction: bool ? 'deal_added_to_pipeline' : 'deal_removed_from_pipeline',
          eventData: {
            deal_pk: id,
            deal_stage: deal_life_cycle_stage,
          },
        };

        trackingService.trackActions(data);
      });

    const toastMessage = bool ? 'Deal added to pipeline.' : 'Deal removed from pipeline.';
    this.props.toast(toastMessage);

    let { pipelineCount, filterPipelineCount } = this.state;
    let { totalCBREGrossCommission } = this.state;
    pipelineCount += bool ? 1 : -1;
    filterPipelineCount += bool ? 1 : -1;
    const deals = clone(this.state.deals);
    const index = findIndex(deals, d => d.id === id);
    deals[index].is_on_pipeline = bool;
    totalCBREGrossCommission += bool ? deals[index].estimated_commission : -deals[index].estimated_commission;
    this.getUserPipelineGrossCommission();
    this.getPipelineGrossCommissionforSelectedUsers();

    this.setState({
      deals,
      pipelineCount,
      totalCBREGrossCommission,
      filterPipelineCount,
      isLoading: false,
    });
  };

  handleColumnSort = (column, trackOnly) => {
    if (trackOnly) {
      this.trackColumnSort(column, trackOnly);
      return;
    }

    let ordering;
    const currentOrdering = this.state.params.ordering;

    if (currentOrdering.includes(column)) {
      // user clicked current sort order, need to flip ascending/descending
      const isCurrentlyDescending = currentOrdering[0] === '-';
      ordering = isCurrentlyDescending ? column : `-${column}`;
    } else {
      // user clicked a new sort order
      ordering = column;
    }

    this.applyParams({ ordering });
    this.trackColumnSort();
  };

  applyParams = (newParams) => {
    this.setState({
      deals: [],
      hasNextPage: false,
      isLoading: true,
      params: {
        ...this.state.params,
        page: 1,
        ...newParams,
      },
    }, async () => {
      await this.loadDeals();
      this.getUserPipelineGrossCommission();
      this.getPipelineGrossCommissionforSelectedUsers();
    });
  };

  filterByPipeline = ({ target: { checked: bool } }) => {
    const value = bool || null;
    const params = { is_on_pipeline: value };
    this.applyParams(params);
  };

  searchByTerm = (input) => {
    const params = { search: input };
    this.applyParams(params);
  };

  filterByStage = (selectedOptionsArr) => {
    const values = selectedOptionsArr.map(o => o.value);
    const params = { deal_life_cycle_stage: values };
    this.applyParams(params);
  };

  filterByCommission = (value, key) => {
    const params = { [key]: value };
    this.applyParams(params);
  };

  filterByProbability = (value) => {
    const params = {
      conversion_potential_min: value.min,
      conversion_potential_max: value.max,
    };
    this.applyParams(params);
  };

  filterByInstallmentDate = (value, key) => {
    if (!this.state.params[key] && !value) {
      return;
    }

    const params = { [key]: value };
    this.applyParams(params);
  }

  filterByDealTypeRepRole = (selectedOptionsArr) => {
    const values = selectedOptionsArr.map(o => o.value);
    const params = { rep_role: values };
    this.applyParams(params);
  }

  filterByTag = (tag, remove) => {
    this.applyParams({
      tags: remove
        ? this.state.params.tags.filter(t => t !== tag)
        : [...this.state.params.tags, tag],
    });
  }

  filterByUntagged = ({ target: { checked } }) => {
    this.applyParams({
      tags: [],
      is_untagged_deal: checked || null,
    });
  }

  filterByTeam = ({ target: { name, checked } }) => {
    const ids = (this.state.params.teams || []).filter(a => a !== +name);
    this.applyParams({
      teams: !checked
        ? ids
        : [...ids, +name],
    });
  }

  filterByUser = ({ target: { name, checked } }) => {
    const ids = (this.state.params.users || []).filter(a => a !== +name);
    this.applyParams({
      users: !checked
        ? ids
        : [...ids, +name],
    });
  }

  filterByAllUsersAndTeams = (checked) => {
    this.applyParams({
      teams: !checked ? [] : this.props.teams.map(t => t.id),
      users: !checked ? [] : this.props.users.map(u => u.id),
    });
  }

  trackFilters = (isFilterClosed) => {
    const { params, count } = this.state;
    const prefix = 'pipeline_report_deal_filter_';
    const category = 'Pipeline Report Deal Filter';
    const label = 'on_filter';
    trackingService.trackFilters(prefix, category, label, params, count, isFilterClosed);
  };

  trackSearch = () => {
    const { params, count } = this.state;
    const searchParams = pick(params, 'search');
    trackingService.trackSearch(searchParams, count);
  };

  trackColumnSort = (column, trackOnly) => {
    const { params } = this.state;
    const data = {
      eventCategory: 'Sort',
      eventAction: 'on_column_sort',
      eventData: {
        sortable: !trackOnly,
        order: trackOnly ? '' : params.ordering,
        column,
      },
    };
    trackingService.trackActions(data);
  };

  cacheParams = (params) => {
    const paramsToCache = omit(params, ['page', 'page_size']);
    const paramString = JSON.stringify(paramsToCache);
    localStorage.setItem('pipeline-params', paramString);
  };

  getCachedParams = () => {
    const pipelineParams = localStorage.getItem('pipeline-params');
    const deaListIndex = localStorage.getItem('dealListIndex');
    let cachedParams = {};
    if (pipelineParams) {
      cachedParams = {
        ...JSON.parse(pipelineParams),
      };
    }
    if (deaListIndex) {
      const page_size = Math.ceil((JSON.parse(deaListIndex) + 1) / 50) * 50;
      cachedParams = {
        ...cachedParams,
        page_size,
      };
    }
    return cachedParams;
  };

  confirmationMessage = () => (
    <RadioGroup
      name="emailSelection"
      orientation="vertical"
      onChange={e => this.setState({ emailSelection: e.target.value })}
    >
      <RadioButton value="filtermail"> Pipeline deals in current filter/search ({
        this.state.filterPipelineCount > 1 ? `${this.state.filterPipelineCount} Deals` : `${this.state.filterPipelineCount} Deal`})
      </RadioButton>
      <RadioButton value="all" >All pipeline deals ({
      this.state.pipelineCount > 1 ? `${this.state.pipelineCount} Deals` : `${this.state.pipelineCount} Deal`})
      </RadioButton>
    </RadioGroup>
  )

  generateReport = () => {
    this.setState({
      showConfirmationModal: true,
      confirmationMessage: this.confirmationMessage(),
    });
  };

  emailModal = () => {
    const { userData } = this.props;
    const { proxy } = this.props;
    const confirmationMessage = !isEmpty(proxy) ? `Your pipeline report was sent to ${proxy.email} and ${userData.work_email}.` :
      `Your pipeline report was sent to ${userData.work_email}.`;
    this.setState({
      showEmailConfirmationModal: true,
      confirmationMessage,
    });
  };

  handleAllEmail = () => {
    const { userData } = this.props;
    const { params } = this.state;
    const { proxy } = this.props;
    pipelineService.generateReport(!isEmpty(proxy) ? proxy.id : userData.id)
      .then(() => { this.emailModal(); })
      .catch((error) => {
        console.error('Error generating report: ', error);
      });
    const filterParams = {
      ...params,
      is_on_pipeline: true,
      page: null,
      ordering: null,
      page_size: null,
      search: this.state.params.search,
      teams: this.state.params.teams.join(','),
      users: this.state.params.users.join(','),
    };

    pipelineService.fetchPipelineDealCounts(userData.id, filterParams)
      .then((res) => {
        const { pipeline_deal_counts, total_deals } = res.count_result;
        const eventData = {
          eventCategory: 'Action',
          eventAction: 'pipeline_report_generated',
          eventData: {
            prospects: pipeline_deal_counts['1'],
            executing: pipeline_deal_counts['3'],
            closed: pipeline_deal_counts['4'],
            total_deals_on_user: total_deals,
          },
        };
        trackingService.trackActions(eventData);
      })
      .catch((error) => {
        console.error('Error fetching deal counts: ', error);
      });
  }

  handleFilterEmail = () => {
    const { userData } = this.props;
    const { params } = this.state;
    const { proxy } = this.props;
    const filterParams = {
      ...params,
      is_on_pipeline: true,
      page: null,
      ordering: null,
      page_size: null,
      search: this.state.params.search,
      teams: this.state.params.teams.join(','),
      users: this.state.params.users.join(','),
    };
    pipelineService.generateFilterReport(!isEmpty(proxy) ? proxy.id : userData.id, filterParams)
      .then(() => { this.emailModal(); })
      .catch((error) => {
        console.error('Error generating report: ', error);
      });
  }
  hideConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
    // eslint-disable-next-line no-unused-expressions
    this.state.emailSelection === 'all' ? this.handleAllEmail() : this.handleFilterEmail();
  };

  hideCancelConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  hideEmailConfirmationModal = () => {
    this.setState({ showEmailConfirmationModal: false });
  };

  render() {
    const {
      deals,
      count,
      pipelineCount,
      hasNextPage,
      params,
      isLoading,
      showConfirmationModal,
      showEmailConfirmationModal,
      confirmationMessage,
      totalCBREGrossCommission,
      totalUserGrossCommision,
      totalSelectedGrossCommission,
    } = this.state;

    const subTitle = (
      <span className="subtitle">
        <span className="bold">{pipelineCount} deal{pipelineCount !== 1 ? 's' : ''} </span>
        <span>in pipeline</span>
        <span className="spacer" />
        <span>Total Gross: </span>
        <span className="bold">{formatAsCurrency(totalCBREGrossCommission)}</span>
        <span className="spacer" />
        <span>My Total Gross: </span>
        <span className="bold">{formatAsCurrency(totalUserGrossCommision)}</span>
        <span className="spacer" />
        <span>Total Gross for Selected Team/Collaborator: </span>
        <span className="bold">{formatAsCurrency(totalSelectedGrossCommission)}</span>
      </span>
    );

    const generateButton = <Button onClick={this.generateReport}>Generate</Button>;

    return (
      <div className="pipeline-report-page">
        <PageHeaderDefault title="Pipeline Report" subtitle={subTitle} controls={generateButton} />
        <div className="page-container">
          <Notice
            text="Deals must include installment information, estimated commission, and at least one commissioned user in order to be eligible for reporting.
                  Missing Information will cause the deal to not appear in your generated pipeline report."
            type="warning"
          />
          <div className="pipeline-report-content">
            <div className="pipeline-report-header" ref={this.headerRef}>
              <ListActionHeader
                filter={(
                  <div className="pipeline-filters-container">
                    <PipelineFilters
                      allDealTags={this.props.allDealTags}
                      currentParams={params}
                      filterByPipeline={this.filterByPipeline}
                      filterByStage={this.filterByStage}
                      filterByCommission={debounce(this.filterByCommission, 300)}
                      filterByProbability={debounce(this.filterByProbability, 300)}
                      filterByInstallmentDate={debounce(this.filterByInstallmentDate, 300)}
                      filterByDealTypeRepRole={debounce(this.filterByDealTypeRepRole, 300)}
                      filterByTag={debounce(this.filterByTag, 300)}
                      filterByUntagged={this.filterByUntagged}
                      onFilterOpen={() => this.trackFilters(false)}
                      onFilterClose={() => this.trackFilters(true)}
                    />
                    <ProducerFilters
                      currentParams={params}
                      teams={this.props.teams}
                      users={this.props.users}
                      filterByTeam={this.filterByTeam}
                      filterByUser={this.filterByUser}
                      filterByAllUsersAndTeams={this.filterByAllUsersAndTeams}
                      onFilterOpen={() => this.trackFilters(false)}
                      onFilterClose={() => this.trackFilters(true)}
                      buttonText="Teams"
                      placeholder="Search for Team or Team Member"
                    />
                  </div>
                )}
                search={(
                  <SearchInput
                    initValue={params.search}
                    handleSearchTermChange={debounce(this.searchByTerm, 300)}
                    searchKey="deals"
                  />
                )}
                sort={(
                  <div>
                    <span>Showing </span>
                    <span className="bold">{commaFormat(deals.length)} </span>
                    <span>of </span>
                    <span className="bold">{commaFormat(count)} </span>
                    <span>deal{deals.length !== 1 ? 's' : ''}</span>
                  </div>
                )}
                isLoading={isLoading}
              >
                <EditColumnsButton />
              </ListActionHeader>
              <PipelineFilterTags
                currentParams={params}
                applyParams={this.applyParams}
                teams={this.props.teams}
                users={this.props.users}
              />
            </div>
            <PipelineTable
              ref={this.tableRef}
              deals={deals}
              ordering={params.ordering}
              isLoading={isLoading}
              selectForPipeline={this.selectForPipeline}
              handleColumnSort={this.handleColumnSort}
              featureFlags={this.props.featureFlags}
              count={count}
            />
            <LoadMore
              isLoading={isLoading}
              hasNextPage={hasNextPage}
              handleLoadMore={this.loadMoreDeals}
            />
          </div>
        </div>
        {showConfirmationModal && (
          <Modal
            showModal
            modalHeader="Receive emailed pipeline report for"
            primaryButtonText="OK"
            secondaryButtonText="Cancel"
            handleModalToggle={this.hideCancelConfirmationModal}
            handleModalSubmit={this.hideConfirmationModal}
            handleSecondaryButton={this.hideCancelConfirmationModal}
          >
            <div>{confirmationMessage}</div>
          </Modal>
        )}
        {showEmailConfirmationModal && (
          <Modal
            showModal
            modalHeader="Report Sent"
            primaryButtonText="OK"
            handleModalToggle={this.hideEmailConfirmationModal}
            handleModalSubmit={this.hideEmailConfirmationModal}
          >
            <div>{confirmationMessage}</div>
          </Modal>
        )}
      </div>
    );
  }
}

PipelineReportPage.propTypes = {
  allDealTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchDealTags: PropTypes.func.isRequired,
  loadDealsMetadata: PropTypes.func.isRequired,
  toast: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    work_email: PropTypes.string,
    id: PropTypes.number,
  }),
  proxy: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

PipelineReportPage.defaultProps = {
  userData: {
    id: PropTypes.number.isRequired,
  },
};

export default PipelineReportPage;
