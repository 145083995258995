/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox } from '@cbrebuild/blocks';
import PageHeaderDefault from '../../nucleus/header/page-header-default';
import TopNavPublic from '../../components/navs/top-nav-public';
import fetchTerms from '../../services/terms-service';
import userService from '../../services/user-service';

const AgreementPage = ({ setShowGlobalNavigation }) => {
  const [termsSections, setTermsSections] = useState([]);
  const [signedAgreement, setSignedAgreement] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const getTerms = async () => {
    const terms = await fetchTerms();
    setTermsSections(terms.results[0].data);
  };

  useEffect(() => {
    document.title = 'Deal IQ | Terms of Agreement';

    if (setShowGlobalNavigation) {
      setShowGlobalNavigation(false);
    }

    getTerms();

    return () => {
      if (setShowGlobalNavigation) {
        setShowGlobalNavigation(true);
      }
    };
  }, []);

  const acceptTerms = async () => {
    const params = { signed_broker_user_agreement: true };
    try {
      await userService.updateUser(params);
    } catch (error) {
      console.log('Error updating user agreement: ', error);
    }

    const redirect = window.localStorage.getItem('termsOfAgreementRedirect');
    if (redirect) {
      window.localStorage.removeItem('termsOfAgreementRedirect');
      window.location.href = redirect;
    } else {
      window.location.href = '/dashboard';
    }
  };

  return (
    <React.Fragment>
      <TopNavPublic />
      <PageHeaderDefault
        title="Terms of Agreement"
      />
      <div className="terms-of-agreement-page page-container">
        <div className="terms-of-agreement-card">
          <div className="terms-of-agreement-container">
            {termsSections.map(section => (
              <React.Fragment key={section.subTitle}>
                <div className="section-header">{section.subTitle}</div>
                {section.contents.map(c => (<p key={c}>{c}</p>))}
              </React.Fragment>
            ))}
          </div>
          <Checkbox
            checked={signedAgreement}
            onChange={() => { setSignedAgreement(!signedAgreement); }}
          >I understand that checking this box constitutes a legal signature confirming that I acknowledge and agree to the above terms.
          </Checkbox>
          {showWarning && (
            <p className="terms-warning">By not accepting, you will not have access to Deal IQ.</p>
          )}
          <div className="controls">
            <Button
              variant="secondary"
              onClick={() => { setShowWarning(true); }}
            >
              Do Not Accept
            </Button>
            <Button
              disabled={!signedAgreement}
              onClick={acceptTerms}
            >
              Accept
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

AgreementPage.propTypes = {
  setShowGlobalNavigation: PropTypes.func,
};

AgreementPage.defaultProps = {
  setShowGlobalNavigation: null,
};

export default AgreementPage;
