import { connect } from 'react-redux';
import { toast } from '../../redux/actions/toasts-actions';
import TeamsPage from './teams-page';

import {
  loadAdminTeams as fetchTeams,
  addTeamForAdmin as addTeam,
  addTeamMemberForAdmin as addTeamMember,
  removeTeamMemberForAdmin as removeTeamMember,
  removeTeamAndMemberForAdmin as removeTeamAndMember,
  updateTeamForAdmin as updateTeam,
  undeleteTeam,
} from '../../redux/actions/admin-actions';

const mapStateToProps = state => ({
  userData: state.userData.data,
  teams: state.admin.teams ? state.admin.teams : [],
  hasNext: state.admin.hasNext ? state.admin.hasNext : null,
});

// AdminPage does not have the option to update auto-add for a team
// this will be used in TeamsPage as conditional to determine if user
// is on the AdminPage or TeamsPage when passed down as a prop
const updateUserTeamAutoAddPreference = undefined;

const mapDispatchToProps = {
  addTeam,
  updateTeam,
  updateUserTeamAutoAddPreference,
  addTeamMember,
  removeTeamMember,
  removeTeamAndMember,
  fetchTeams,
  undeleteTeam,
  toast,
};

const AdminPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamsPage);

export default AdminPageContainer;
