import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Icon } from '@cbrebuild/blocks';
import PageHeaderDefault from '../../nucleus/header/page-header-default';
import Modal from '../../nucleus/modal/modal';

const OutlookSyncPage = () => {
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setRedirect(true);
    }, 5000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [redirect]);

  if (redirect) {
    return <Redirect to="/my-contacts" />;
  }
  return (
    <div className="contacts-page">
      <PageHeaderDefault
        title="Contacts Page"
      />
      <Modal
        className="outlook-sync-modal"
        showModal
        handleModalToggle={() => setRedirect(true)}
        modalHeader={(
          <React.Fragment>
            <Icon iconName="outlook" />
            <span>Outlook Contact Sync</span>
          </React.Fragment>
        )}
        hideModalControls
      >
        <p>Outlook Sync is in Progress...</p>
      </Modal>
    </div>
  );
};

export default OutlookSyncPage;
