import React from 'react';
import PropTypes from 'prop-types';

const Progress = props => (
  <div className="nd-progress">
    <div
      className="progress-indicator"
      style={{ width: `${props.progress}%` }}
    />
  </div>
);

Progress.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default Progress;
