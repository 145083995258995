import React from 'react';
import PropTypes from 'prop-types';

const LoadingBar = ({
  isLoading,
}) => (<div className={`loading-animation${isLoading ? ' active' : ''}`} />);

export default LoadingBar;

LoadingBar.propTypes = {
  isLoading: PropTypes.bool,
};

LoadingBar.defaultProps = {
  isLoading: false,
};
