import superFetch from '../utils/super-fetch';

const fetchAlerts = (params) => {
  const url = '/alerts';
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const markAsRead = (alertID) => {
  // mark one alert as read
  const url = `/alerts/${alertID}/mark_as_read`;
  const options = {
    method: 'POST',
  };
  return superFetch(url, options)
    .then(data => data);
};

const markBatchAsRead = (alertIDs) => {
  // mark a batch of alerts as read
  const url = '/alerts/mark_as_read';
  const params = {
    ids: alertIDs,
  };
  const options = {
    method: 'POST',
    params,
  };
  return superFetch(url, options)
    .then(data => data);
};

const snoozeAlerts = (alertID, alertDatetime) => {
  const url = `/alerts/${alertID}/snooze`;
  const options = {
    method: 'POST',
  };
  const body = JSON.stringify({ alert_datetime: alertDatetime });
  return superFetch(url, options, body)
    .then(data => data);
};

const dismissAlert = (alertID) => {
  const url = `/alerts/${alertID}/dismiss`;
  const options = {
    method: 'POST',
  };
  return superFetch(url, options)
    .then(data => data);
};

const dismissAllAlert = () => {
  const url = '/alerts/dismiss?content_type=user,transaction,audittrail,team';
  const options = {
    method: 'POST',
  };
  return superFetch(url, options)
    .then(data => data);
};

export default {
  markAsRead,
  markBatchAsRead,
  dismissAlert,
  dismissAllAlert,
  fetchAlerts,
  snoozeAlerts,
};

