import {
  fetchTeamsForAdmin,
  addTeamForAdmin as addTeamService,
  updateTeamForAdmin as updateTeamService,
  addTeamMemberForAdmin as addTeamMemberService,
  removeTeamMemberForAdmin as removeTeamMemberService,
} from '../../services/admin-service';

import {
  ADD_TEAM_FOR_ADMIN_SUCCESS,
  ADD_TEAM_FOR_ADMIN_FAILURE,
  ADD_TEAM_MEMBER_FOR_ADMIN_SUCCESS,
  ADD_TEAM_MEMBER_FOR_ADMIN_FAILURE,
  LOAD_TEAMS_FOR_ADMIN_SUCCESS,
  LOAD_TEAMS_FOR_ADMIN_FAILURE,
  UNDELETE_TEAM_SUCCESS,
  UNDELETE_TEAM_FAILURE,
  UPDATE_TEAM_FOR_ADMIN_SUCCESS,
  UPDATE_TEAM_FOR_ADMIN_FAILURE,
  REMOVE_TEAM_MEMBER_FOR_ADMIN_SUCCESS,
  REMOVE_TEAM_MEMBER_FOR_ADMIN_FAILURE,
} from './action-types';

import { toast } from './toasts-actions';

const loadAdminTeamsSuccess = response => ({
  type: LOAD_TEAMS_FOR_ADMIN_SUCCESS,
  payload: response,
});

const addTeamForAdminSuccess = newTeam => ({
  type: ADD_TEAM_FOR_ADMIN_SUCCESS,
  payload: newTeam,
});

const addTeamMemberForAdminSuccess = team => ({
  type: ADD_TEAM_MEMBER_FOR_ADMIN_SUCCESS,
  payload: team,
});

const removeTeamMemberForAdminSuccess = team => ({
  type: REMOVE_TEAM_MEMBER_FOR_ADMIN_SUCCESS,
  payload: team,
});

const undeleteTeamSuccess = team => ({
  type: UNDELETE_TEAM_SUCCESS,
  payload: team,
});

const updateTeamForAdminSuccess = team => ({
  type: UPDATE_TEAM_FOR_ADMIN_SUCCESS,
  payload: team,
});

const handleError = (error, type) => ({
  type,
  payload: error,
});

// async actions
const loadAdminTeams = params => dispatch =>
  fetchTeamsForAdmin(params)
    .then(response => dispatch(loadAdminTeamsSuccess(response)))
    .catch(error => dispatch(handleError(error, LOAD_TEAMS_FOR_ADMIN_FAILURE)));

const addTeamForAdmin = params => dispatch =>
  addTeamService(params)
    .then(team => dispatch(addTeamForAdminSuccess(team)))
    .catch((error) => {
      dispatch(handleError(error, ADD_TEAM_FOR_ADMIN_FAILURE));
      dispatch(toast('Error Adding Team'));
    });

const addTeamMemberForAdmin = params => dispatch =>
  addTeamMemberService(params)
    .then((team) => {
      const { users } = team;
      const newMember = users.filter(u => u.id === params.newTeamMember.user_id)[0];
      const oldTeamMembers = users.filter(u => u !== newMember);
      const orderedTeam = {
        ...team,
        users: [newMember, ...oldTeamMembers],
      };
      dispatch(addTeamMemberForAdminSuccess(orderedTeam));
      dispatch(toast(`${params.newTeamMember.full_name} added to team "${params.teamName}"`));
    })
    .catch((error) => {
      dispatch(handleError(error, ADD_TEAM_MEMBER_FOR_ADMIN_FAILURE));
      dispatch(toast('Error Adding Team Member'));
    });

const removeTeamMemberForAdmin = params => dispatch =>
  // remove a non-you member from a team
  removeTeamMemberService(params)
    .then((team) => {
      dispatch(removeTeamMemberForAdminSuccess(team));
      dispatch(toast(`${params.memberToRemove.first_name} ${params.memberToRemove.last_name} removed from team "${params.teamName}"`));
    })
    .catch((error) => {
      dispatch(handleError(error, REMOVE_TEAM_MEMBER_FOR_ADMIN_FAILURE));
      dispatch(toast('Error Deleting Team Member'));
    });

const removeTeamAndMemberForAdmin = params => dispatch =>
  // remove team member and that team member is you
  removeTeamMemberService(params)
    .then((team) => {
      // remove user, but not the team from the admin page
      dispatch(removeTeamMemberForAdminSuccess(team));
      dispatch(toast(`You left team "${params.teamName}"`));
    })
    .catch((error) => {
      dispatch(handleError(error, REMOVE_TEAM_MEMBER_FOR_ADMIN_FAILURE));
      dispatch(toast('Error Leaving Team'));
    });

const undeleteTeam = params => dispatch =>
  updateTeamService(params)
    .then((team) => {
      dispatch(undeleteTeamSuccess(team));
      dispatch(toast(`Team ${params.teamName} is no longer deleted`));
    })
    .catch((error) => {
      dispatch(handleError(error, UNDELETE_TEAM_FAILURE));
      dispatch(toast('Error Un-Deleting Team'));
    });

const updateTeamForAdmin = params => dispatch =>
  updateTeamService(params)
    .then((team) => {
      dispatch(updateTeamForAdminSuccess(team));
    })
    .catch((error) => {
      dispatch(handleError(error, UPDATE_TEAM_FOR_ADMIN_FAILURE));
      dispatch(toast('Error Updating Team'));
    });

export {
  loadAdminTeams,
  addTeamForAdmin,
  addTeamMemberForAdmin,
  removeTeamMemberForAdmin,
  removeTeamAndMemberForAdmin,
  undeleteTeam,
  updateTeamForAdmin,
};
