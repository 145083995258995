import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Textarea } from '@cbrebuild/blocks';
import FormGroup from '../../../nucleus/form-group/form-group';
import Select from '../../../nucleus/select/select';
import Tooltip from '../../../nucleus/tooltip/tooltip';
import useFormState from '../../../hooks/useFormState';
import { updateCompData } from '../../../redux/actions/voucher/comp-data-actions';

const SaleComp = () => {
  const { compData } = useSelector(state => ({
    compData: state.compData,
  }));
  const dispatch = useDispatch();
  const {
    account_driven_deal,
    general_comments,
    seller_represented_by, seller_represented_by_agent,
    buyer_represented_by, buyer_represented_by_agent,
    onStateChange, onStateChanged,
  } = useFormState({
    account_driven_deal: compData?.account_driven_deal,
    general_comments: compData?.general_comments,
    seller_represented_by: compData?.seller_represented_by,
    seller_represented_by_agent: compData?.seller_represented_by_agent,
    buyer_represented_by: compData?.buyer_represented_by,
    buyer_represented_by_agent: compData?.buyer_represented_by_agent,
  }, update => dispatch(updateCompData(compData.id, update)));

  const accountDrivenOptions = [
    { display_name: 'Yes', value: 'Yes' },
    { display_name: 'No', value: 'No' },
  ];

  return (
    <div className="sale-comp-sections-wrapper">
      <h3>General Information</h3>
      <div>
        <div className="field-group">
          <div className="form-row-with-labels-and-hints">
            <FormGroup
              label="Seller Represented By"
              size="large"
              isRequired
              hasError={!seller_represented_by.value}
              errorMessage="Seller Represented By is required"
            >
              <input
                type="text"
                name="seller_represented_by"
                value={seller_represented_by.value || ''}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
          <div className="form-row-with-labels-and-hints">
            <div className="nd-form-group large">
              <label className="input-label">
                Account Driven
                <Tooltip
                  message={
                    <>
                      <h3>Account Driven</h3>
                      <br />
                      <p>Account Driven - Contractual strategic account relationship
                        <br />
                        One-Off - Locally sourced - not awarded due to strategic account client contract
                      </p>
                    </>
                    }
                >
                  <Icon iconName="info-circle" />
                </Tooltip>
              </label>
              <Select
                defaultOption={accountDrivenOptions.find(option => option.value === account_driven_deal.value)}
                onChange={({ value }) => onStateChanged({
                  target: { value, name: 'account_driven_deal', type: 'text' },
                })}
                options={accountDrivenOptions}
              />
            </div>
          </div>
        </div>
        <div className="form-row-with-labels-and-hints">
          <FormGroup
            label="Seller Represented By Agent"
            size="large"
            isRequired
            hasError={!seller_represented_by_agent.value}
            errorMessage="Seller Represented By Agent is required"
          >
            <input
              type="text"
              name="seller_represented_by_agent"
              value={seller_represented_by_agent.value || ''}
              onChange={onStateChange}
              onBlur={onStateChanged}
            />
          </FormGroup>
        </div>
        <div className="form-row-with-labels-and-hints">
          <FormGroup
            label="Buyer Represented By"
            size="large"
            isRequired
            hasError={!buyer_represented_by.value}
            errorMessage="Buyer Represented By is required"
          >
            <input
              type="text"
              name="buyer_represented_by"
              value={buyer_represented_by.value || ''}
              onChange={onStateChange}
              onBlur={onStateChanged}
            />
          </FormGroup>
        </div>
        <div className="form-row-with-labels-and-hints">
          <FormGroup
            label="Buyer Represented By Agent"
            size="large"
            isRequired
            hasError={!buyer_represented_by_agent.value}
            errorMessage="Buyer Represented By Agent is required"
          >
            <input
              type="text"
              name="buyer_represented_by_agent"
              value={buyer_represented_by_agent.value || ''}
              onChange={onStateChange}
              onBlur={onStateChanged}
            />
          </FormGroup>
        </div>
        <div className="form-row-with-labels-and-hints">
          <FormGroup hasError={false} label="General Comments" size="large">
            <Textarea
              id="general-comments"
              name="general_comments"
              rows={{ min: 4, max: 130 }}
              onChange={onStateChange}
              onBlur={onStateChanged}
              value={general_comments.value || ''}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default SaleComp;
