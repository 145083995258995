import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ReminderTaskAlert from './reminder-task-alert';
import AssignedTaskAlert from './assigned-task-alert';
import dealsService from '../../services/deals-service';

const TaskAlertItem = (props) => {
  const {
    task,
    handleDismissAlert,
    handleSnoozeAlert,
    handleNavigateToTaskTrackingEvent,
    formatDate,
    handleDismissTaskAndAlert,
    turducken,
  } = props;

  const [taskAlertLink, setTaskAlertLink] = useState('');

  useEffect(() => {
    let stopFetch = false;

    const fetchTaskAlertLink = async () => {
      const dealId = task.related_object.deal ? task.related_object.deal.id : '';
      // link to deals/tasks if user has access and /tasks otherwise
      let link;
      if (dealId) {
        try {
          await dealsService.fetchDeal(dealId);
          link = `/deals/detail/${dealId}/tasks`;
        } catch (error) {
          link = '/tasks';
        }
      } else {
        link = '/tasks';
      }
      if (stopFetch) {
        return;
      }
      setTaskAlertLink(link);
    };

    fetchTaskAlertLink();

    return () => {
      stopFetch = true;
    };
  }, []);

  const renderAlert = () => {
    switch (task.variety) {
      case 'task_reminder':
        return (
          <ReminderTaskAlert
            key={task.id}
            formatDate={formatDate}
            handleNavigateToTaskTrackingEvent={handleNavigateToTaskTrackingEvent}
            handleDismissTaskAndAlert={handleDismissTaskAndAlert}
            handleSnoozeAlert={handleSnoozeAlert}
            task={task}
            turducken={turducken}
            taskAlertLink={taskAlertLink}
          />
        );
      case 'assigned_to_task':
        return (
          <AssignedTaskAlert
            key={task.id}
            formatDate={formatDate}
            handleNavigateToTaskTrackingEvent={handleNavigateToTaskTrackingEvent}
            handleDismissAlert={handleDismissAlert}
            task={task}
            turducken={turducken}
            taskAlertLink={taskAlertLink}
          />
        );
      default:
        return '';
    }
  };
  return (
    <React.Fragment>
      {renderAlert()}
    </React.Fragment>
  );
};


export default TaskAlertItem;

TaskAlertItem.propTypes = {
  handleDismissAlert: PropTypes.func.isRequired,
  handleSnoozeAlert: PropTypes.func.isRequired,
  handleDismissTaskAndAlert: PropTypes.func.isRequired,
  handleNavigateToTaskTrackingEvent: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
  task: PropTypes.shape({
    variety: PropTypes.string.isRequired,
    performed_by: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    related_object: PropTypes.shape({
      deal: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
    alert_datetime: PropTypes.string,
    id: PropTypes.number,
    message: PropTypes.string,
    read: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape(),
    ]),
  }).isRequired,
  turducken: PropTypes.bool.isRequired,
};
