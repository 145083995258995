import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AddItem from '../../../nucleus/add-item/add-item';
import getDealProperty from '../../../utils/get-deal-property';
import Property from '../../property/property';
import CompanyAutocomplete from '../../autocomplete/company-autocomplete';
import companyService, { clientCounterPartyMap } from '../../../services/companies-service';


const DealClient = ({
  deal: {
    client,
    counter_party,
    deal_life_cycle_stage,
    id,
    rep_role,
    primary_occupied_property,
    primary_occupied_property_count,
    primary_prior_property,
    primary_prior_property_count,
  },
  toast,
  updateDeal,
  viewOnly,
}) => {
  const savedParties = { client, counter_party };
  const [parties, setParties] = useState({
    client,
    counter_party,
  });
  const [unsavedAttribute, setUnsavedAttribute] = useState();

  useEffect(() => {
    setParties({ client, counter_party });
  }, [client, counter_party]);

  // Memoize the property Label
  const propertyLabel = useMemo(() => {
    if (deal_life_cycle_stage === 'closed') {
      return 'Final Property';
    }
    switch (rep_role) {
      case 'tenant':
        // lease tenant
        if (deal_life_cycle_stage === 'prospect') {
          return 'Current Property';
        }
        // executing
        return 'Prospective Property';
      case 'landlord': // lease landlord
      case 'buyer': // sale buyer
        return 'Prospective Property';
      case 'seller': // sale seller
        return 'Property for Sale';
      default: // consulting/other
        return 'Current Property';
    }
  }, [deal_life_cycle_stage, rep_role]);

  // Memoize the property
  const property = useMemo(() => getDealProperty({
    deal_life_cycle_stage,
    rep_role,
    primary_occupied_property,
    primary_prior_property,
  }), [deal_life_cycle_stage, rep_role, primary_occupied_property, primary_prior_property]);

  const partyMap = clientCounterPartyMap[rep_role] || clientCounterPartyMap.other;

  const propertyCount = property?.field_type === 'primary_occupied_property'
    ? primary_occupied_property_count : primary_prior_property_count;

  const handlePartyChange = attribute => (value) => {
    setParties(current => ({
      ...current,
      [attribute]: typeof value === 'string'
        ? { ...current[attribute], name: value } : value,
    }));
  };

  useEffect(() => {
    if (unsavedAttribute) {
      setUnsavedAttribute();
      const saved = savedParties[unsavedAttribute];
      const unsaved = parties[unsavedAttribute];
      // if no name revert it back to the original
      if (!unsaved?.name) {
        setParties(current => ({
          ...current,
          [unsavedAttribute]: saved,
        }));
      } else {
        companyService.createCompanyHelper(saved, unsaved)
          .then((company) => {
            if (company) {
              updateDeal(id, {
                [unsavedAttribute]: company.id,
              });
            }
          }).catch(() => {
            toast('Error updating Company');
            setParties(current => ({
              ...current,
              [unsavedAttribute]: saved,
            }));
          });
      }
    }
  }, [id, parties, savedParties, toast, unsavedAttribute, updateDeal]);

  return (
    <div className={`deal-client ${deal_life_cycle_stage}`}>
      <div className="item">
        <div className="item-text data-label">
          {propertyLabel}
        </div>
        <div className="item-content">
          {property.name ? (
            <div className="property-column">
              <Property
                callToAction={null}
                name={property.name}
                building={property.building}
                property={property}
                isHeader={false}
              />
              {deal_life_cycle_stage !== 'closed' && propertyCount > 1 && window.location.pathname !== `/deals/detail/${id}/properties` &&
                <Link
                  className="see-more-properties"
                  to={`/deals/detail/${id}/properties`}
                >
                  See {propertyCount === 2 ?
                    `${propertyCount - 1} more property` :
                    `${propertyCount - 1} more properties`}
                </Link>
              }
            </div>
          ) : (
            <>
              {deal_life_cycle_stage === 'closed' || viewOnly ? (
                <p className="left-padding">N/A</p>
              ) : (
                <Link
                  className="add-property"
                  to={`/deals/detail/${id}/properties`}
                >
                  <AddItem label="Property" />
                </Link>
              )}
            </>
          )}
        </div>
      </div>
      {partyMap.map(party => (
        <div className="item" key={party.attribute}>
          <label className="item-text data-label">{party.label}</label>
          <CompanyAutocomplete
            className={`p bold ${deal_life_cycle_stage}`}
            onChange={handlePartyChange(party.attribute)}
            initSearchTerm={parties[party.attribute]?.name || ''}
            placeholder={`Search for ${party.label}`}
            onBlur={() => setUnsavedAttribute(party.attribute)}
            onReturn={() => setUnsavedAttribute(party.attribute)}
            variant="inline"
          />
        </div>
      ))}
    </div>
  );
};

DealClient.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    client: PropTypes.shape({}),
    counter_party: PropTypes.shape({}),
    deal_life_cycle_stage: PropTypes.string.isRequired,
    primary_occupied_property: PropTypes.shape({}),
    primary_occupied_property_count: PropTypes.number,
    primary_prior_property: PropTypes.shape({}),
    primary_prior_property_count: PropTypes.number,
    rep_role: PropTypes.string.isRequired,
  }).isRequired,
  toast: PropTypes.func,
  updateDeal: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

DealClient.defaultProps = {
  viewOnly: false,
  toast: () => { },
};

export default DealClient;
