import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Icon, ToggleSwitch } from '@cbrebuild/blocks';
import uniqueId from 'lodash/uniqueId';
import debounce from 'lodash/debounce';

import Modal from '../../nucleus/modal/modal';
import dealsService from '../../services/deals-service';
import teamMembersService from '../../services/team-members-service';
import { fetchDealsAndTransactionsESForAdmin } from '../../services/admin-service';
import DealsActionRow from '../property-details/deals-action-row';
import EmptyState from '../empty-state/empty-state';
import DealMembers from '../headers/deal-detail-header/deal-members';
import LoadMore from '../../nucleus/load-more/load-more';

class BulkRemoveDealsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deals: [],
      selectedDeals: [],
      selectedOn: false,
      queryParams: {
        building: null,
        conversion_potential_max: 100,
        conversion_potential_min: 0,
        date_approved_max: null,
        date_approved_min: null,
        date_lease_to_max: null,
        date_lease_to_min: null,
        deal_life_cycle_stage: [],
        is_archived: false,
        ordering: '-modified',
        page: 1,
        page_size: 10000,
        rep_role: [],
        search: '',
        tags: [],
        team: this.props.teamId,
      },
      initQueryParams: {
        building: null,
        conversion_potential_max: 100,
        conversion_potential_min: 0,
        date_approved_max: null,
        date_approved_min: null,
        date_lease_to_max: null,
        date_lease_to_min: null,
        deal_life_cycle_stage: [],
        is_archived: false,
        ordering: '-modified',
        page: 1,
        page_size: 10000,
        rep_role: [],
        search: '',
        tags: [],
        team: this.props.teamId,
      },
      currentTeam: {
        id: this.props.teamId,
        name: this.props.teamName,
        users: this.props.teamMembers,
        avatar_color: this.props.teamColor,
      },
      isLoadingDeals: true,
      dealsCount: 0,
      allDealTags: [],
      pageSize: 100,
      lastRequestId: undefined,
    };
    this.debounceHandleFetchDeals = debounce(() => {
      this.handleFetchDeals();
    }, 500);
  }

  componentDidMount() {
    this.getAllDealsCount();
    this.fetchAllTags();
  }

  addTeamToDeal = (deal) => {
    const {
      deals,
      selectedDeals,
      currentTeam,
    } = this.state;
    const dealsToUpdate = [...deals];
    const dealToUpdate = dealsToUpdate.find(element => element.id === deal.id);
    if (!dealToUpdate.permissions.teams.find(t => t.id === currentTeam.id)) {
      dealToUpdate.permissions.teams = [currentTeam, ...dealToUpdate.permissions.teams];
      dealsToUpdate[dealsToUpdate.indexOf(deal)] = dealToUpdate;
      this.setState({
        deals: dealsToUpdate,
      });
    }
    if (selectedDeals.find(element => element.id === deal.id)) {
      const rest = selectedDeals.filter(element => element.id !== deal.id);
      this.setState({
        selectedDeals: rest,
      }, () => {
        if (this.state.selectedOn && this.state.selectedDeals.length === 0) {
          this.selectedOnChange();
        }
      });
    }
  };

  addTeamToAllDeals = () => {
    const {
      deals,
      currentTeam,
    } = this.state;
    const updatedDeals = deals.map((deal) => {
      if (!deal.permissions.teams.find(t => t.id === currentTeam.id)) {
        deal.permissions.teams.unshift(currentTeam);
        return deal;
      }
      return deal;
    });
    this.setState({
      deals: [...updatedDeals],
      selectedDeals: [],
      selectedOn: false,
    });
  };

  confirmRemoveTeamFromDeals = () => {
    const {
      selectedDeals,
    } = this.state;
    const {
      teamId,
      teamName,
      removeFromDealCount,
    } = this.props;
    const removeTeamFromDealsData = selectedDeals.map(deal => ({
      deal_id: deal.id,
      user_id: null,
      team_id: teamId,
    }));

    teamMembersService.removeDealMember(removeTeamFromDealsData)
      .then(() => {
        removeFromDealCount(selectedDeals.length);
        this.handleCloseModal({
          message: `Removed ${teamName} from ${selectedDeals.length} ${selectedDeals.length === 1 ? 'deal' : 'deals'}`,
          numberOfDeals: selectedDeals.length,
          success: true,
          operation: 'Remove',
        });
      })
      .catch(() => {
        this.handleCloseModal({
          message: `Failed to remove ${teamName} from ${selectedDeals.length} ${selectedDeals.length === 1 ? 'deal' : 'deals'}. Please try again`,
          success: false,
        });
      });
  };

  fetchAllTags = () => {
    dealsService.fetchDealTags()
      .then((data) => {
        this.setState({
          allDealTags: data.tags_on_all_deals,
        });
      })
      .catch();
  };

  getAllDealsCount = () => {
    fetchDealsAndTransactionsESForAdmin({ ...this.state.initQueryParams, page_size: 1 })
      .then(response => this.setState({ dealsCount: response.count }, () => this.handleFetchDeals()))
      .catch(this.setState({ isLoadingDeals: false }));
  };

  handleCloseModal = (params) => {
    this.props.closeModal(params);
  };

  handleFetchDeals = () => {
    const requestId = uniqueId();
    this.setState({
      deals: [],
      queryParams: {
        ...this.state.queryParams,
        page: 1,
      },
      isLoadingDeals: true,
      lastRequestId: requestId,
    }, () => {
      fetchDealsAndTransactionsESForAdmin({ ...this.state.queryParams, page_size: this.state.dealsCount || 10000 })
        .then((response) => {
          if (requestId === this.state.lastRequestId) {
            const deals = response.results.map((deal) => {
              if (!deal.permissions.teams) {
                const updatedDeal = deal;
                updatedDeal.permissions.teams = [];
                return updatedDeal;
              }
              return deal;
            });
            this.setState({
              deals,
              isLoadingDeals: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            deals: [],
            isLoadingDeals: false,
          });
        });
    });
  };

  handleFilterChange = (params) => {
    const {
      queryParams,
    } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        ...params,
      },
    }, () => this.handleFetchDeals());
  };

  handleTagClick = (tag) => {
    const { queryParams } = this.state;

    if (!queryParams.tags.includes(tag)) {
      const params = {
        ...queryParams,
        tags: [...queryParams.tags, tag],
      };

      this.handleFilterChange(params);
    }
  };

  handleSearchTerm = (search) => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        search,
      },
    }, () => this.debounceHandleFetchDeals());
  };

  handleSortingKeyChange = (ordering) => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        page: 1,
        ordering,
      },
    }, () => this.handleFetchDeals());
  };

  loadMore = () => {
    this.setState({
      pageSize: this.state.pageSize + 100,
    });
  };

  removeTeamFromDeal = (deal) => {
    const {
      deals,
      selectedDeals,
      currentTeam,
    } = this.state;
    const dealToUpdate = deals.find(element => element.id === deal.id);
    dealToUpdate.permissions.teams = dealToUpdate.permissions.teams.filter(t => t.id !== currentTeam.id);
    deals[deals.indexOf(deal)] = dealToUpdate;
    this.setState({
      deals,
    });
    if (!selectedDeals.find(element => element.id === deal.id)) {
      this.setState({
        selectedDeals: [...selectedDeals, deal],
      });
    }
  };

  removeTeamFromAllDeals = () => {
    const {
      deals,
      currentTeam,
    } = this.state;
    const updatedDeals = deals.map((deal) => {
      const updatedDeal = deal;
      updatedDeal.permissions.teams = deal.permissions.teams.filter(t => t.id !== currentTeam.id);
      return updatedDeal;
    });
    this.setState({
      selectedDeals: updatedDeals,
      deals: updatedDeals,
    });
  };

  selectedOnChange = () => {
    this.setState({
      selectedOn: !this.state.selectedOn,
      pageSize: 100,
    });
  };

  renderLoadMore = () => (
    <LoadMore
      handleLoadMore={this.loadMore}
      hasNextPage={!this.state.selectedOn ? this.state.deals.length > this.state.pageSize : this.state.selectedDeals.length > this.state.pageSize}
    />
  );

  renderDealCountRow = () => {
    const {
      deals,
      selectedDeals,
      selectedOn,
    } = this.state;
    const indeterminate = (selectedDeals.length > 0 && selectedDeals.length < deals.length);
    const allSelected = selectedDeals.length === deals.length;
    return (
      <div className="bulk-remove-modal-count">
        <Checkbox
          indeterminate={indeterminate}
          checked={allSelected}
          onChange={({ target: { checked } }) => (!checked ? this.addTeamToAllDeals() : this.removeTeamFromAllDeals())}
        > {allSelected ? 'Unselect All' : 'Select All'}
        </Checkbox>
        {selectedDeals.length > 0 &&
          <ToggleSwitch
            checked={selectedOn}
            onChange={() => this.selectedOnChange()}
          >
            Selected only
          </ToggleSwitch>}
      </div>
    );
  };

  renderDealRow = (deal) => {
    const isSelected = this.state.selectedDeals.find(element => element.id === deal.id) ? 'selected' : '';
    const dealUsers = deal.permissions.users ? deal.permissions.users.map((individual) => {
      const user = {
        id: individual.id,
        avatar: individual.avatar,
        email: individual.email,
        first_name: individual.first_name,
        last_name: individual.last_name,
      };
      const member = {
        user,
      };
      return member;
    }) : [];
    const linkToDeal = () => {
      window.open(`/deals/detail/${deal.id}/overview`, '_blank');
    };
    const dealTeams = deal.permissions.teams || [];

    return (
      <li key={deal.id} className={`bulk-remove-modal-item ${isSelected} ${deal.deal_life_cycle_stage}`}>
        <div className="bulk-remove-modal-add-button">
          <Checkbox
            checked={isSelected}
            onChange={({ target: { checked } }) => (checked ? this.removeTeamFromDeal(deal) : this.addTeamToDeal(deal))}
          />
        </div>
        <div className="bulk-remove-modal-deal-name"><span className="bulk-remove-modal-deal-name-link" onClick={linkToDeal}>{deal.name}</span></div>
        <div className="bulk-remove-modal-deal-teams">
          <DealMembers
            analyticProperties={this.props.analyticProperties}
            dealUsers={dealUsers}
            dealTeams={dealTeams}
            maxAvatarsToShow={6}
            showAddOrRemove={false}
            alignHover="right"
            hideTeamMemberAvatars
          />
        </div>
      </li>);
  };

  renderColHeader = (
    <div className="bulk-remove-modal-header">
      <div className="bulk-remove-modal-deal">Deal</div>
      <div className="bulk-remove-modal-team">Collaborators</div>
    </div>
  );

  renderModalContent = () => {
    const {
      deals,
      selectedOn,
      selectedDeals,
      pageSize,
      isLoadingDeals,
    } = this.state;
    return (
      <div className="bulk-remove-modal-content">
        {!isLoadingDeals && !selectedOn &&
            deals.slice(0, pageSize).map(deal =>
              (selectedDeals.find(element => element.id === deal.id) ?
                this.renderDealRow(selectedDeals.find(element => element.id === deal.id)) :
                this.renderDealRow(deal)))}
        {!isLoadingDeals && selectedOn && selectedDeals.length > 0 && selectedDeals.slice(0, pageSize).map(deal => this.renderDealRow(deal))}
        {!isLoadingDeals && !deals.length && this.state.queryParams === this.state.initQueryParams &&
        <EmptyState
          type="deals"
          message="There are no deals that have this team permissioned on them"
        />}
        {!isLoadingDeals && !deals.length && this.state.queryParams !== this.state.initQueryParams &&
        <EmptyState
          type="deals"
          message="There are no deals for this filter/search. Please change the filter/search"
        />}
        {this.renderLoadMore()}
      </div>
    );
  };

  renderModalFooter = () => {
    const {
      teamName,
    } = this.props;
    const {
      selectedDeals,
    } = this.state;
    return (
      <div className="bulk-remove-deals-modal-footer">
        {selectedDeals.length > 0 &&
        <div className="bulk-remove-deals-notice">
          <p><Icon iconName="info-circle" />Removing {teamName} from {selectedDeals.length} {selectedDeals.length === 1 ? 'deal' : 'deals'}</p>
        </div>
          }
        {this.renderModalControls()}
      </div>
    );
  };

  renderModalControls = () => {
    const {
      selectedDeals,
    } = this.state;
    return (
      <div className="bulk-remove-deals-modal-controls">
        <Button
          onClick={this.handleCloseModal}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={this.confirmRemoveTeamFromDeals}
          className={selectedDeals.length === 0 ? 'disabled' : ''}
          disabled={selectedDeals.length === 0}
        >
          Confirm
        </Button>
      </div>
    );
  };

  render() {
    const {
      teamName,
      analyticProperties,
    } = this.props;
    const {
      isLoadingDeals,
      initQueryParams,
      queryParams,
      allDealTags,
    } = this.state;

    return (
      <Modal
        modalHeader={`Remove ${teamName} from Deals`}
        className="bulk-remove-deals-modal"
        showModal
        handleModalToggle={this.handleCloseModal}
        hideModalControls
        modalWidth={1080}
      >
        <DealsActionRow
          key={0}
          allDealTags={allDealTags}
          handleFilterChange={this.handleFilterChange}
          handleSearchTerm={this.handleSearchTerm}
          handleSortingKeyChange={this.handleSortingKeyChange}
          isLoading={isLoadingDeals}
          queryParams={queryParams}
          initQueryParams={initQueryParams}
          analyticProperties={analyticProperties}
          featureFlags={{}}
        />
        {this.renderDealCountRow()}
        {this.renderColHeader}
        {this.renderModalContent()}
        {this.renderModalFooter()}
      </Modal>
    );
  }
}

BulkRemoveDealsModal.propTypes = {
  teamId: PropTypes.number.isRequired,
  teamName: PropTypes.string.isRequired,
  teamMembers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  teamColor: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  removeFromDealCount: PropTypes.func.isRequired,
  analyticProperties: PropTypes.shape().isRequired,
};

export default BulkRemoveDealsModal;
