import { connect } from 'react-redux';
import {
  loadTaskAlerts,
  markTasksAsRead,
  snooze,
  completeTask,
  snoozeAndLoadNextTask,
  dismissAndLoadNextTask,
  clearSnoozeMessage,
  clearDismissMessage,
  dismissTaskAlert,
} from '../actions/alerts-actions';

import TaskAlerts from '../../components/notifications/task-alerts';

const mapStateToProps = state => ({
  // Alerts State
  tasks: state.alerts.allTaskIDs ?
    state.alerts.allTaskIDs.map(id => state.alerts.tasksByID[id]) : [],
  isLoadingTaskAlerts: state.alerts.isLoadingTaskAlerts,
  taskAlertsError: state.alerts.taskAlertsError,
  taskAlertsMetadata: state.alerts.taskAlertsMetadata,
  markTaskAsReadFailure: state.alerts.markTaskAsReadError,
  snoozeMessage: state.alerts.snoozeMessage,
  dismissMessage: state.alerts.dismissMessage,
});

const mapDispatchToProps = dispatch => ({
  // Alerts Actions
  setTaskAlertsState: params => dispatch(loadTaskAlerts(params)),
  setMarkTaskAsReadState: alertIDs =>
    dispatch(markTasksAsRead(alertIDs)),
  snoozeTask: (alertID, alertDatetime, snoozeMessage) =>
    dispatch(snooze(alertID, alertDatetime, snoozeMessage)),
  snoozeAndLoadNextTask: (alertID, alertDatetime, snoozeMessage, params) =>
    dispatch(snoozeAndLoadNextTask(alertID, alertDatetime, snoozeMessage, params)),
  clearSnoozeMessage: () => dispatch(clearSnoozeMessage()),
  dismissTask: alertID => dispatch(completeTask(alertID)),
  dismissAlert: alertID => dispatch(dismissTaskAlert(alertID)),
  dismissAndLoadNextTask: (alertID, params) =>
    dispatch(dismissAndLoadNextTask(alertID, params)),
  clearDismissMessage: () => dispatch(clearDismissMessage()),

});

const TaskAlertsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskAlerts);

export default TaskAlertsContainer;
