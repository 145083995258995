import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Avatar from '../../nucleus/avatar/avatar';
import subroutes from './profile-subroutes';
import PageHeaderDefault from '../../nucleus/header/page-header-default';

class ProfilePage extends React.Component {
  componentDidMount() {
    document.title = 'Deal IQ | Profile';
  }

  renderUserName = () => {
    const {
      first_name,
      last_name,
      avatar,
    } = this.props.userData || {};
    return (
      <div>
        <Avatar
          enableHover
          firstName={first_name}
          hoverAlign="left"
          image={avatar}
          lastName={last_name}
          sizing="lg"
        />&nbsp;
        {
          first_name && last_name
            ? `${first_name} ${last_name}`
            : ''
        }
      </div>);
  };

  renderTabs = (renderProxy, proxy) => [
    {
      link: '/profile/overview',
      text: 'Profile',
    },
    {
      link: '/profile/teams',
      text: 'Teams',
    },
    {
      link: '/profile/labs',
      text: 'Labs',
    },
    {
      link: '/profile/switch-proxy',
      hidden: !renderProxy,
      text: 'Proxy Access',
    },
    {
      link: '/profile/manage-proxy',
      hidden: !renderProxy,
      text: 'Grant Proxy Access',
      disabled: !isEmpty(proxy),
    },
  ];

  render() {
    const {
      userData,
      userOptions,
      loadUserData,
      updateUserData,
      proxy,
    } = this.props;

    return (
      <React.Fragment>
        <PageHeaderDefault
          title={this.renderUserName()}
          tabs={this.renderTabs(userOptions.feature_flags.proxy_user_flag, proxy)}
          pageUrl={this.props.location.pathname}
        />
        {subroutes.map((route) => {
          const RouteComponent = route.component;
          return (<Route
            path={route.path}
            exact={route.exact}
            key={route.path}
            render={() => (<RouteComponent
              userOptions={userOptions}
              user={userData}
              loadUserData={loadUserData}
              updateUserData={updateUserData}
              avatar={userData.avatar}
            />)}
          />);
        })}
        { this.props.location.pathname === '/profile' &&
          <Redirect to="/profile/overview" />
        }
      </React.Fragment>
    );
  }
}

ProfilePage.propTypes = {
  userData: PropTypes.shape({
    auth_groups: PropTypes.arrayOf(PropTypes.string),
    avatar: PropTypes.string,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  loadUserData: PropTypes.func.isRequired,
  updateUserData: PropTypes.func.isRequired,
  userOptions: PropTypes.shape({ feature_flags: PropTypes.shape({ proxy_user_flag: PropTypes.bool, react_vouchers_flag: PropTypes.bool }) }),
  proxy: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

ProfilePage.defaultProps = {
  userOptions: { feature_flags: { proxy_user_flag: false, react_vouchers_flag: false } },
  userData: {
    auth_groups: [],
  },
  location: {
    pathname: '',
  },
};

export default ProfilePage;
