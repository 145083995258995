import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ToggleSwitch } from '@cbrebuild/blocks';

import AddItem from '../../../nucleus/add-item/add-item';
import EmptyState from '../../empty-state/empty-state';
import DealNote from '../../deal-card/deal-note';
import ListActionHeader from '../../../nucleus/list-action-header/list-action-header';
import ListSort from '../../../nucleus/list-sort/list-sort';
import LoadMore from '../../../nucleus/load-more/load-more';

import userEventService from '../../../services/user-event-service';

const sortingKeys = [
  {
    displayName: 'Date Modified',
    ascSortingKey: 'modified',
    descSortingKey: '-modified',
  },
  {
    displayName: 'Date Created',
    ascSortingKey: 'created',
    descSortingKey: '-created',
  },
];

const cachedSortKey = 'note-list-sort';

class NoteListPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewNoteForm: false,
      queryParams: {
        created_by: null,
        ordering: window.localStorage.getItem(cachedSortKey) || sortingKeys[0].descSortingKey,
        page: 1,
      },
    };
  }

  componentDidMount() {
    this.handleFetchNotes();
  }

  handleCreateNote = (params) => {
    const { addNote } = this.props;
    addNote(params);
    this.trackNoteCreation();
  }

  handleDeleteNote = (id) => {
    const { deleteNote } = this.props;
    deleteNote(id);
    this.trackNoteDeletion();
  }

  handleEditModeToggle = (isEditing) => {
    if (!isEditing) {
      if (this.state.showNewNoteForm) {
        this.props.newNoteCleanup();
      }
      this.setState({
        showNewNoteForm: false,
      });
    }
  }

  handleFetchNotes = () => {
    const { dealId, fetchNotes, pageSize } = this.props;
    const { queryParams } = this.state;
    fetchNotes({ ...queryParams, deal: dealId, page_size: pageSize });
  }

  handleLoadMore = () => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        page: queryParams.page += 1,
      },
    }, () => this.handleFetchNotes());
  }

  handleSortChange = (ordering) => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        page: 1,
        ordering,
      },
    }, () => this.handleFetchNotes());
    this.trackSortChange(ordering);
    window.localStorage.setItem(cachedSortKey, ordering);
  }

  handleSwitchChange = () => {
    const { user } = this.props;
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        created_by: queryParams.created_by
          ? null
          : user.id,
      },
    }, () => this.handleFetchNotes());
  }

  handleUpdateNewNote = (id, params) => {
    const { updateNote, newNote } = this.props;
    updateNote(id, params);
    this.trackNoteUpdates(params, newNote);
  }

  handleUpdateNote = (id, params, note) => {
    const { updateNote } = this.props;
    updateNote(id, params);
    this.trackNoteUpdates(params, note);
  }

  initNewNote = () => {
    this.setState({
      showNewNoteForm: !this.state.showNewNoteForm,
    });
  }

  trackNoteCreation = () => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;

    userEventService.trackEvent(
      { eventCategory: 'Note Action', eventAction: 'note_created' },
      { actionPrefix, categoryPrefix },
    );
  }

  trackNoteDeletion = () => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;

    userEventService.trackEvent(
      { eventCategory: 'Note Action', eventAction: 'note_deleted' },
      { actionPrefix, categoryPrefix },
    );
  }

  trackNoteUpdates = (params, note) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;

    if (!this.state.showNewNoteForm) {
      const edited = [];
      if (note.body !== params.body) {
        edited.push('body');
      }
      if (note.privacy !== params.privacy) {
        edited.push('privacy');
      }
      userEventService.trackEvent(
        { eventCategory: 'Note Action', eventAction: 'note_edited' },
        { actionPrefix, categoryPrefix },
      );
    }
  }

  trackSortAborted = () => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;

    userEventService.trackEvent(
      { eventLabel: 'on_sort_aborted', eventCategory: 'Note Sort', eventAction: '_note_sort_aborted' },
      { actionPrefix, categoryPrefix },
    );
  }

  trackSortChange = (newOrdering) => {
    const {
      queryParams: {
        ordering,
      },
    } = this.state;
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;

    userEventService.trackEvent({
      eventLabel: newOrdering,
      eventCategory: 'Note Sort',
      eventAction: '_note_sort_change',
      eventData: {
        from: ordering,
        to: newOrdering,
      },
    }, { actionPrefix, categoryPrefix });
  }

  render() {
    const {
      dealId,
      isLoading,
      isOverview,
      next,
      newNote,
      count,
      notes,
      user,
      shouldTruncateNotes,
    } = this.props;
    const {
      showNewNoteForm,
      queryParams,
    } = this.state;

    return (
      <div className="card-default note-list-panel">
        <ListActionHeader
          filter={(
            <ToggleSwitch
              checked={queryParams.created_by !== null}
              onChange={this.handleSwitchChange}
            >
              My Notes Only
            </ToggleSwitch>
          )}
          isLoading={isLoading}
          search={count !== undefined ? (
            <Link to="notes">
              See {count} Note(s)
            </Link>
          ) : undefined}
          sort={(
            <ListSort
              currentSortingKey={queryParams.ordering}
              options={sortingKeys}
              onChange={this.handleSortChange}
              onSortAborted={this.trackSortAborted}
            />
          )}
        />
        {!showNewNoteForm &&
          <div className="add-note">
            <AddItem
              label="Note"
              onClick={this.initNewNote}
            />
          </div>
        }
        {showNewNoteForm &&
          <DealNote
            dealId={dealId}
            isNew={showNewNoteForm}
            key="newNote"
            note={newNote}
            onCreate={this.handleCreateNote}
            onDelete={this.handleDeleteNote}
            onEdit={this.handleEditModeToggle}
            onUpdate={this.handleUpdateNewNote}
            user={user}
          />
        }
        <ul>
          {notes ? notes.map(note => (
            <DealNote
              dealId={dealId}
              key={note.id}
              note={note}
              onCreate={this.handleCreateNote}
              onDelete={this.handleDeleteNote}
              onEdit={this.handleEditModeToggle}
              onUpdate={(id, params) => this.handleUpdateNote(id, params, note)}
              user={user}
              shouldTruncateNotes={shouldTruncateNotes}
            />
          )) : null}
          {(!showNewNoteForm && !notes.length && !isLoading) &&
          <div className="empty-state-container">
            <EmptyState
              hideImg={isOverview}
              message="Create private notes or assign notes to CBRE team members."
              type="notes"
            />
          </div>}
        </ul>
        <LoadMore
          isLoading={isLoading}
          handleLoadMore={this.handleLoadMore}
          hasNextPage={next}
        />
      </div>
    );
  }
}

NoteListPanel.propTypes = {
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  dealId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOverview: PropTypes.bool,
  next: PropTypes.number,
  newNote: PropTypes.shape({}),
  notes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  addNote: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  fetchNotes: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  newNoteCleanup: PropTypes.func.isRequired,
  count: PropTypes.number,
  pageSize: PropTypes.number,
  shouldTruncateNotes: PropTypes.bool,
};

NoteListPanel.defaultProps = {
  isOverview: false,
  next: null,
  newNote: undefined,
  count: undefined,
  pageSize: 25,
  shouldTruncateNotes: true,
};

export default NoteListPanel;
