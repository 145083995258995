import cloneDeep from 'lodash/cloneDeep';
import unset from 'lodash/unset';
import superFetch from '../../utils/super-fetch';

const formatInstallmentDate = installments => installments.map((i) => {
  // drop time values from store datetime string
  const newDate = i.installment_date ? (i.installment_date).split('T')[0] : null;
  return {
    ...i,
    installment_date: newDate,
  };
});

let installmentOptions;

const getOptions = () => new Promise((resolve, reject) => {
  if (installmentOptions) resolve(installmentOptions);
  superFetch('/installments', { method: 'OPTIONS' })
    .then(data => data.json())
    .then((data) => {
      installmentOptions = data.actions.POST.voucher_contingency_code.choices;
      resolve([...installmentOptions]);
    })
    .catch((error) => {
      console.error(error);
      reject(error);
    });
});

const fetchInstallments = (dealId, additionalParams) => {
  const url = '/installments';
  const params = {
    transaction: dealId,
    ...additionalParams,
  };

  // need to account for legacy payment_status that was a string, it's not an array
  let updatedParams = { ...params };
  let needReload = false;
  const installmentStatus = updatedParams?.payment_status;
  if (installmentStatus && typeof installmentStatus === 'string') {
    updatedParams = {
      ...params,
      payment_status: [installmentStatus],
    };
    window.localStorage.setItem('myInstallmentsParams', JSON.stringify(updatedParams));
    needReload = true;
  }
  const options = {
    method: 'GET',
    params: updatedParams,
  };
  return superFetch(url, options)
    .then(data => data.json())
    .then(data => formatInstallmentDate(data.results))
    .then((results) => {
      if (needReload) window.location.reload(true);
      return results.sort((a, b) => a.installment_number - b.installment_number);
    });
};

const fetchAllInstallments = (params) => {
  let paramObj = {};
  if (params) {
    if (params.users && params.brokers) {
      paramObj = {
        ...params,
        users: params.users.join(','),
        brokers: params.brokers.join(','),
      };
    } else {
      paramObj = {
        ...params,
      };
    }
  }
  const url = '/installments';

  // need to account for legacy payment_status that was a string, it's not an array
  let updatedParams = { ...paramObj };
  let needReload = false;
  const installmentStatus = updatedParams?.payment_status;
  if (installmentStatus && typeof installmentStatus === 'string') {
    updatedParams = {
      ...paramObj,
      payment_status: [installmentStatus],
    };
    window.localStorage.setItem('myInstallmentsParams', JSON.stringify(updatedParams));
    needReload = true;
  }
  const options = {
    method: 'GET',
    params: updatedParams,
  };
  return superFetch(url, options)
    .then((data) => {
      if (needReload) window.location.reload(true);
      return data.json();
    });
};

const updateInstallment = (id, params) => {
  const url = `/installments/${id}`;
  const options = {
    method: 'PATCH',
  };

  const body = JSON.stringify(params);

  return superFetch(url, options, body)
    .then(data => data.json());
};

const deleteInstallment = (id) => {
  const url = `/installments/${id}`;
  const options = {
    method: 'DELETE',
  };
  return superFetch(url, options)
    .then(data => data);
};

const addInstallment = (installmentTemplate) => {
  const url = '/installments';
  const options = {
    method: 'POST',
  };

  const body = installmentTemplate;

  return superFetch(url, options, JSON.stringify(body))
    .then(data => data.json());
};

const fetchOrCreateInstallments = (dealId, installmentTemplate) => {
  fetchInstallments(dealId)
    .then((installments) => {
      if (installments.length) {
        return installments;
      }

      return addInstallment(installmentTemplate)
        .then(installment => installment);
    });
};

const exportInstallments = (params) => {
  const paramsClone = cloneDeep(params);
  unset(paramsClone, 'page');
  unset(paramsClone, 'page_size');
  unset(paramsClone, 'ordering');
  paramsClone.brokers = paramsClone.brokers.join(',');
  paramsClone.users = paramsClone.users.join(',');

  const url = '/installments/export';
  const options = {
    method: 'GET',
    params: paramsClone,
    responseType: 'arraybuffer',
  };

  return superFetch(url, options)
    .then(data => data.json());
};

const getMetadata = (params) => {
  const url = '/installments/members';
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json());
};

export default {
  addInstallment,
  getOptions,
  fetchInstallments,
  fetchAllInstallments,
  updateInstallment,
  deleteInstallment,
  fetchOrCreateInstallments,
  exportInstallments,
  getMetadata,
};
