import superFetch from '../utils/super-fetch';

// used for company search
// voucher/companies api returns more details for vouchers
const fetchCompanies = (params) => {
  const url = '/mbb/clients';
  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json())
    .then(data => data.results || []);
};

const getCompany = (id) => {
  const url = `/mbb/voucher/companies/${id}`;
  const options = {
    method: 'GET',
  };

  return superFetch(url, options)
    .then(data => data.json());
};

const createCompany = (body) => {
  const url = '/mbb/voucher/companies';
  const options = {
    method: 'POST',
  };

  return superFetch(url, options, JSON.stringify(body))
    .then(data => data.json());
};

const patchCompany = (id, patch) => {
  const url = `/mbb/voucher/companies/${id}`;
  const options = {
    method: 'PATCH',
  };

  const body = JSON.stringify(patch);

  return superFetch(url, options, body)
    .then(data => data.json());
};

export const defaultCompanyData = {
  name: undefined,
  country: 'United States',
  address: null,
  address2: null,
  city: null,
  territory: null,
  postal: null,
};

/**
 * Creates, duplicate a company or rerturn undefined
 * @param {*} saved company
 * @param {*} unsaved company
 * @param {*} requiredFields
 * @returns company created, undefined if not created
 */
const createCompanyHelper = async (saved, unsaved, requiredFields) => {
  let company;
  const valid = !requiredFields ? true : Object.keys(requiredFields).find(key => (unsaved && unsaved[key]));
  // if not valid return undefined
  if (!valid) {
    company = undefined;
    // if no saved or unsaved don't create
  } else if ((!saved && !unsaved) || (saved && !unsaved)) {
    company = undefined;
    // no saved + new company, create with default and patch deal
  } else if ((!saved || saved?.id === undefined) && !unsaved.id) {
    company = await createCompany({
      ...defaultCompanyData,
      ...unsaved,
    });
    // no saved + existing company, get full company, create and patch deal
  } else if (!saved && unsaved.id) {
    const { id: exclude, ...data } = await getCompany(unsaved.id);
    company = await createCompany(data);
    // same company but new name, get, create with new name, patch
  } else if (saved.id === unsaved?.id && saved?.name !== unsaved?.name) {
    const { id: exclude, ...data } = await getCompany(unsaved.id);
    company = await createCompany({ ...data, name: unsaved.name });
    // new existing company, get, create, patch
  } else if (saved.id !== unsaved?.id && saved?.name !== unsaved?.name) {
    const { id: exclude, ...data } = await getCompany(unsaved.id);
    company = await createCompany({ ...data, name: unsaved.name });
  }
  return company;
};

/**
 * Maps client/counter party attributes to Label by using rep_role of the deal
 */
export const clientCounterPartyMap = {
  tenant: [
    { label: 'Tenant', attribute: 'client' },
    { label: 'Landlord', attribute: 'counter_party' },
  ],
  landlord: [
    { label: 'Tenant', attribute: 'counter_party' },
    { label: 'Landlord', attribute: 'client' },
  ],
  buyer: [
    { label: 'Buyer', attribute: 'client' },
    { label: 'Seller', attribute: 'counter_party' },
  ],
  seller: [
    { label: 'Buyer', attribute: 'counter_party' },
    { label: 'Seller', attribute: 'client' },
  ],
  consulting: [
    { label: 'Client', attribute: 'client' },
  ],
  other: [
    { label: 'Client', attribute: 'client' },
  ],
};


export default {
  fetchCompanies,
  getCompany,
  createCompany,
  createCompanyHelper,
  patchCompany,
};
