import { connect } from 'react-redux';
import PropertiesList from './properties-list';

const mapStateToProps = state => ({
  user: state.userData.data,
});

const PropertiesListContainer = connect(mapStateToProps)(PropertiesList);

export default PropertiesListContainer;
