/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import debounce from 'lodash/debounce';
import unionBy from 'lodash/unionBy';
import { Link } from 'react-router-dom';

import propertiesService from '../../services/properties-service';
import userEventService from '../../services/user-event-service';

import EmptyState from '../../components/empty-state/empty-state';
import LoadMore from '../../nucleus/load-more/load-more';
import PageHeader from '../../nucleus/header/page-header-default';
import Property from '../../components/property/property';
import PropertiesListPageActionsRow from '../../components/properties/properties-list-page-actions-row';

const analyticProperties = {
  action: 'properties_list_',
  category: 'Properties List ',
};

class PropertiesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      isLoading: false,
      hasNextPage: 0,
      searchState: {
        page_size: 25,
        search: '',
        page: 1,
        ordering: '-building__modified',
      },
      properties: [],
    };

    this.debounceHandleFetchProperties = debounce((action) => {
      this.handleFetchProperties(action);
    }, 500);
  }

  componentDidMount() {
    this.handleFetchProperties('refresh');
    document.title = 'Deal IQ | Properties';
  }

  handleFetchProperties = (action) => {
    this.setState({
      isLoading: true,
    });
    const {
      properties,
      searchState,
    } = this.state;
    propertiesService.fetchProperties(searchState)
      .then((data) => {
        const hasResults = data.count !== 0;
        if (!hasResults) {
          this.setState({
            count: data.count,
            isLoading: false,
            hasNextPage: data.next,
            properties: [],
          });
          this.trackSearchResult(hasResults, searchState.search);
        } else if (action === 'refresh') {
          this.setState({
            count: data.count,
            isLoading: false,
            hasNextPage: data.next,
            properties: data.results,
          });
          this.trackSearchResult(hasResults, searchState.search);
        } else if (action === 'append') {
          this.setState({
            count: data.count,
            isLoading: false,
            hasNextPage: data.next,
            properties: unionBy(properties, data.results),
          });
        }
      })
      .catch((error) => {
        console.log('Error fetching properties: ', error);
        this.setState({
          isLoading: false,
        });
      });
  };

  handleSearchTerm = (searchTermUpdate) => {
    this.setState({
      searchState: {
        ...this.state.searchState,
        search: searchTermUpdate,
        page: 1,
      },
    });
    this.debounceHandleFetchProperties('refresh');
  };

  handleLoadMoreButton = () => {
    this.setState({
      searchState: {
        ...this.state.searchState,
        page: this.state.searchState.page += 1,
      },
    });
    this.debounceHandleFetchProperties('append');
  };

  renderLandlords = (property, maxLandlords) => (
    property.landlords && property.landlords.length > 0 ? property.landlords.slice(0, maxLandlords).join(', ') : '--'
  );

  trackSearchResult = (hasResults, search) => {
    if (search) {
      const eventAction = `${analyticProperties.action}search_${hasResults ? 'success' : 'null_results'}`;
      const eventCategory = `${analyticProperties.category}Search`;
      userEventService.trackEvent({
        eventCategory,
        eventAction,
        eventLabel: search,
        search_term: search,
      });
    }
  };

  trackFocusEvent = () => {
    const eventAction = `${analyticProperties.action}search_focus`;
    const eventMetadata = {
      eventCategory: `${analyticProperties.category}Search`,
      eventAction,
      eventLabel: 'on_focus',
    };
    userEventService.trackEvent(eventMetadata);
  };

  trackIndexClicked = ({ currentTarget: { dataset } }) => {
    const index = dataset.propertyIndex;
    const { searchState: { search } } = this.state;

    if (search) {
      const eventAction = `${analyticProperties.action}result_clicked_index`;
      const eventCategory = `${analyticProperties.category}Interaction`;
      userEventService.trackEvent({
        eventCategory,
        eventAction,
        eventLabel: index,
        result_list_clicked: index,
      });
    }
  };

  render() {
    const {
      count,
      isLoading,
      hasNextPage,
      properties,
      searchState,
    } = this.state;

    return (
      <React.Fragment>
        <PageHeader
          title="Properties"
          subtitle={count}
        />
        <div className="properties-page page-container">
          <div className="properties-list">
            <PropertiesListPageActionsRow
              handleSearchTerm={this.handleSearchTerm}
              searchState={searchState}
              isLoading={isLoading}
              handleSearchFocus={this.trackFocusEvent}
            />
            <ul>
              {properties.length > 0 && properties.map((property, index) => (
                <li
                  data-building-id={property.building_id}
                  data-property-index={index}
                  key={property.building_id}
                  onClick={this.trackIndexClicked}
                >
                  <Link to={`/properties/detail/${property.building.id}`}>
                    <Property
                      callToAction={null}
                      name={property.building_name}
                      building={property.building || ''}
                      property={property}
                      isHeader
                    />
                  </Link>
                  <div className="info">
                    <div className="landlord">
                      <label>Landlord</label>
                      <span>{this.renderLandlords(property, 10)}</span>
                    </div>
                    <div className="spaces">
                      <label>Spaces</label>
                      <div>{property.spaces_count}</div>
                    </div>
                    <div className="deals">
                      <label>Deals</label>
                      <div>{property.total_deals_count ? property.total_deals_count : '--'}</div>
                    </div>
                  </div>
                </li>
              ))
              }
            </ul>
            {(properties.length === 0 && !isLoading) &&
              <div className="empty-state-container">
                <EmptyState type="properties" />
              </div>
              }
            <LoadMore
              isLoading={isLoading}
              hasNextPage={hasNextPage}
              handleLoadMore={this.handleLoadMoreButton}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PropertiesList;

