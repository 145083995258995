import React from 'react';
import PropTypes from 'prop-types';
import AutocompleteFlyout from '../autocomplete/autocomplete-flyout';
import companiesService from '../../services/companies-service';

class CompanyAutocomplete extends React.Component {
  static propTypes = {
    /** Callback for when a user modifies the input, via typing or clicking a result */
    onChange: PropTypes.func.isRequired,
    /** An ErrorMessage to show if field is required */
    errorMessage: PropTypes.string,
    /** The string you want to use in the input as a placeholder  */
    placeholder: PropTypes.string,
    /** string used as input value on init  */
    initSearchTerm: PropTypes.string,
    dataE2e: PropTypes.string,
    label: PropTypes.string,
    onBlur: PropTypes.func,
    focusOnMount: PropTypes.bool,
    variant: PropTypes.oneOf(['outlined', 'inline']),
  };

  static defaultProps = {
    errorMessage: '',
    placeholder: 'Search Companies',
    initSearchTerm: '',
    dataE2e: '',
    label: 'Company',
    onBlur: () => { },
    focusOnMount: false,
    variant: undefined,
  };

  getName = result => result.name || result;

  renderListItem = ({
    name, address: {
      address, city, territory, country, postal,
    },
  }) => (
    <div>
      {[name, address, city, territory, postal, country].filter(a => a).join(', ')}
    </div>
  );

  renderFooterContent = onSelect => (
    <p onClick={() => onSelect(this.props.initSearchTerm)}>{`New ${this.props.label} "${this.props.initSearchTerm}"`}</p>
  );

  render() {
    const {
      onChange,
      errorMessage,
      placeholder,
      initSearchTerm,
      dataE2e,
      onBlur,
      focusOnMount,
      variant,
    } = this.props;

    return (
      <AutocompleteFlyout
        initSearchTerm={initSearchTerm}
        fetchRequest={companiesService.fetchCompanies}
        requestParams={{ page_size: 25 }}
        onChange={onChange}
        placeholder={placeholder}
        renderListItem={this.renderListItem}
        findSelectedResultAttribute={this.getName}
        renderFooterContent={this.renderFooterContent}
        errorMessage={errorMessage}
        dataE2e={dataE2e}
        onBlur={onBlur}
        onReturn={onBlur}
        focusOnMount={focusOnMount}
        variant={variant}
      />
    );
  }
}
export default CompanyAutocomplete;
