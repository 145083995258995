import React from 'react';
import { PropTypes } from 'prop-types';
import { Redirect } from 'react-router-dom';

import DealListContainer from '../../redux/containers/deal-list-container';

const DefaultRoute = props => <Redirect to={`/properties/detail/${props.id}/deals`} />;
DefaultRoute.propTypes = {
  id: PropTypes.string.isRequired,
};

const propertyDetailSubroutes = [
  {
    path: '/properties/detail/:id/',
    exact: true,
    component: props => <DefaultRoute {...props} />,
  },
  {
    path: '/properties/detail/:id/deals',
    exact: true,
    component: DealListContainer,
    analyticProperties: {
      actionPrefix: 'deal_list',
      categoryPrefix: 'Deal List ',
    },
  },
];

export default propertyDetailSubroutes;
