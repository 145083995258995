import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const TeamNotification = (props) => {
  const {
    handleNavigateTracking,
    variety,
    relatedObject,
    performedBy,
    userData,
    turducken,
  } = props;
  const {
    referenced_user_name: referencedUserName,
    referenced_user_id: referencedUserId,
    referenced_team_name: referencedTeamName,
  } = relatedObject;

  const alertAboutSelf = referencedUserId === userData.id;

  const renderTeamName = variety !== 'removed_from_team' ? (
    <Link
      className="deal-name-link"
      onClick={handleNavigateTracking}
      to="/profile/teams"
      {...(turducken ? { target: '_self' } : {})}
    >
      {referencedTeamName}
    </Link>
  ) : <span className="bold-name">{referencedTeamName}.</span>;

  const namelessMessage = () => {
    if (variety === 'added_to_team') {
      return alertAboutSelf ?
        'You have been added to team ' :
        `${referencedUserName} has been added to team `;
    } else if (variety === 'removed_from_team') {
      return alertAboutSelf ?
        'You have been removed from team ' :
        `${referencedUserName} has been removed from team `;
    }
    return 'A change has been made to team ';
  };

  const messageAction = () => {
    if (variety === 'added_to_team') {
      return alertAboutSelf ?
        ' added you to team ' :
        ` added ${referencedUserName} to team `;
    } else if (variety === 'removed_from_team') {
      return alertAboutSelf ?
        ' removed you from team ' :
        ` removed ${referencedUserName} from team `;
    }
    return ' made a change to team ';
  };

  const renderMessage = performedBy ? (
    <React.Fragment>
      <span className="bold-name">
        {`${performedBy.first_name} ${performedBy.last_name}`}
      </span>
      {messageAction()}
      <span className="bold-name">
        {renderTeamName}
      </span>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {namelessMessage}
      <span className="bold-name">
        {renderTeamName}
      </span>
    </React.Fragment>
  );

  return (
    <p>{renderMessage}</p>
  );
};

export default TeamNotification;

TeamNotification.propTypes = {
  handleNavigateTracking: PropTypes.func.isRequired,
  variety: PropTypes.string,
  relatedObject: PropTypes.shape(),
  performedBy: PropTypes.shape({
    avatar: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  turducken: PropTypes.bool.isRequired,
  userData: PropTypes.shape(),
};

TeamNotification.defaultProps = {
  variety: '',
  relatedObject: {},
  performedBy: PropTypes.shape({
    avatar: '',
    first_name: '',
    last_name: '',
  }),
  userData: { id: null },
};
