import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import uniqueId from 'lodash/uniqueId';

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.modalRoot = Object.assign(document.createElement('div'), {
      id: `modal-root-${uniqueId()}`,
    });
    this.domNode = document.body.appendChild(this.modalRoot);
  }

  componentDidMount() {
    document.body.appendChild(this.modalRoot);
    this.modalRoot.setAttribute('class', 'confirmation-modal-backdrop react');
  }

  componentWillUnmount() {
    document.body.appendChild(this.modalRoot);
    this.modalRoot.removeAttribute('class', 'confirmation-modal-backdrop react');
  }

  render() {
    const {
      className,
      children,
      showModal,
    } = this.props;

    const renderModal = showModal && (
      <div className={`nd-confirmation-modal ${className}`}>
        {children}
      </div>
    );
    return ReactDOM.createPortal(
      renderModal,
      this.domNode,
    );
  }
}

ConfirmationModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  showModal: PropTypes.bool.isRequired,
};

ConfirmationModal.defaultProps = {
  className: '',
};

export default ConfirmationModal;
