import React from 'react';
import PropTypes from 'prop-types';

const Tag = props => (
  <div className="tag">
    <p>{props.tag}</p>
  </div>
);

Tag.propTypes = {
  tag: PropTypes.string.isRequired,
};

export default Tag;
