import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';
import Flyout from '../../flyout/flyout';
import Tag from './tag';

class TagSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textInput: '',
      showFlyout: false,
    };
  }
  state = {
    textInput: '',
    showFlyout: false,
  }

  applyFilter = ({ target: { value: textInput } }) => {
    this.setState({ textInput });
  }

  showFlyout = () => {
    this.setState({ showFlyout: true });
  }

  closeFlyout = () => {
    this.setState({ showFlyout: false });
  }

  render() {
    const {
      onSelect,
      selectedTags,
      tags,
    } = this.props;
    const {
      showFlyout,
      textInput,
    } = this.state;

    const filteredTags = tags
      .filter(tag => (!textInput || tag.includes(textInput))
        && !(selectedTags.find(t => t === tag)));

    const searchInputValueExists = textInput.length > 0;
    const searchIcon = !searchInputValueExists &&
      <Icon className="action-icon" iconName="magnifier" />;

    return (
      <div className="tag-search">
        <div className="search-input-wrapper">
          <input
            type="text"
            onChange={this.applyFilter}
            placeholder="Search Tags"
            value={textInput}
            onFocus={this.showFlyout}
            disabled={this.props.disabled}
          />
          {searchIcon}
        </div>
        <Flyout
          closeFlyout={this.closeFlyout}
          showFlyout={showFlyout}
        >
          {filteredTags.length
            ? filteredTags.map(tag => (
              <span className="tag-span" key={tag} onClick={() => onSelect(tag)} ><Tag tag={tag} /></span>
            ))
            : <p>No tags found</p>
          }
        </Flyout>
      </div>
    );
  }
}

TagSearch.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TagSearch.defaultProps = {
  tags: [],
  selectedTags: [],
  disabled: false,
};

export default TagSearch;
