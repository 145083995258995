import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { IconButton, RadioButton, Icon } from '@cbrebuild/blocks';

import userEventService from '../../../services/user-event-service';
import Avatar from '../../../nucleus/avatar/avatar';
import BrokerAutocomplete from '../../autocomplete/broker-autocomplete';
import FunctionalUnitAutocomplete from '../../autocomplete/functional-unit-autocomplete';
import PercentDollarInput from '../../../nucleus/inputs/percent-dollar-input';
import { fetchBroker } from '../../../services/brokers-service';
import { BROKER_TYPES } from '../../../services/voucher/commissions-service';
import FormGroup from '../../../nucleus/form-group/form-group';
import ManagingOfficeAutocomplete, { getDisplayText as getOfficeName } from '../../autocomplete/managing-office-autocomplete';
import PoolAccountAutocomplete, { getDisplayText as getPoolName } from '../../autocomplete/pool-account-autocomplete';
import Tooltip from '../../../nucleus/tooltip/tooltip';

class CommissionAllocationItem extends Component {
  state = {
    brokerAvatar: {},
    // Commenting Client Name As Per User Story CB-1396120
    // clientName: this.props.commission && this.props.commission.voucher_office_client_name,
  };
  componentDidMount() {
    this.getAvatar();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.commission !== this.props.commission) {
      this.getAvatar();
    }
  }

  onRemoveClick = () => {
    const { deleteCommission, commission } = this.props;
    deleteCommission(commission.id)
      .then(() => this.props.updateDealPipelineTimestamp());
    // track removed broker only for employees, not for offices and pool accounts.
    const removedBroker = commission.broker;
    if (!removedBroker) {
      return;
    }
    userEventService.trackEvent(
      {
        eventAction: 'broker_removed_from_allocations',
        eventCategory: 'Action',
        eventValue: removedBroker.id,
        eventData: {
          removed_user_id: removedBroker.id,
          lead_producer: commission.broker_is_lead,
        },
      },
      {
        actionPrefix: 'pipeline_details',
        categoryPrefix: 'Pipeline Details ',
      },
    );
  };

  getAvatar = () => {
    if (this.props.commission.broker) {
      fetchBroker(this.props.commission.broker.id)
        .then((data) => {
          this.setState({ brokerAvatar: data });
        })
        .catch((error) => {
          console.error('Error fetching broker: ', error);
        });
    }
  };

  getBrokerFullName = () => {
    const { broker } = this.props.commission;
    if (broker) {
      const firstName = broker.first_name ? broker.first_name : '';
      const lastName = broker.last_name ? broker.last_name : '';
      return `${firstName} ${lastName}`;
    }
    return '';
  };

  // Commenting Client Name As Per User Story CB-1396120
  // handleClientName = (value) => {
  //   this.setState({ clientName: value });
  // }

  getBrokerHint = () => {
    let hint = '';
    const { broker } = this.props.commission;
    if (broker) {
      if (broker.work_email) {
        hint += broker.work_email;
      }

      if (broker.work_email && (broker.peoplesoft_id || broker.managing_office_name)) {
        hint += '\n';
      }

      if (broker.peoplesoft_id) {
        hint += `ID: ${broker.peoplesoft_id}`;
      }

      if (broker.peoplesoft_id && broker.managing_office_name) {
        hint += ' / ';
      }

      if (broker.managing_office_name) {
        hint += broker.managing_office_name;
      }
    }

    return hint;
  };

  setLeadProducer = () => {
    this.props.setLeadProducer(this.props.commission.id);
  };

  handlePercentDollarBlur = (percent, dollar) => {
    this.props.updateCommission(this.props.commission.id, {
      voucher_gross_commission_percent: percent,
      gross_commission: dollar,
    });
  };

  // Commenting Client Name As Per User Story CB-1396120
  // updateClientName = () => {
  //   const { clientName } = this.state;
  //   const data = {
  //     voucher_office_client_name: clientName,
  //   };
  //   this.props.updateCommission(this.props.commission.id, data);
  // }

  updateBroker = (selectedBroker) => {
    const broker = selectedBroker ? selectedBroker.id : null;
    const formerBroker = this.props.commission.broker;
    const data = {
      broker,
    };

    this.props.updateCommission(this.props.commission.id, data)
      .then(((res) => {
        const addedBrokerId = res.broker ? res.broker.id : null;
        const formerBrokerId = formerBroker ? formerBroker.id : null;

        // update timestamp if broker changed or was removed
        if (addedBrokerId !== formerBrokerId) {
          this.props.updateDealPipelineTimestamp();
        }

        if (addedBrokerId === null) {
          return;
        }
        userEventService.trackEvent(
          {
            eventAction: 'broker_added_to_allocations',
            eventCategory: 'Action',
            eventValue: addedBrokerId,
            eventData: {
              added_user_id: addedBrokerId,
              deal_id: this.props.deal.id,
              deal_on_pipeline: this.props.deal.is_on_pipeline,
            },
          },
          {
            actionPrefix: 'pipeline_details',
            categoryPrefix: 'Pipeline Details ',
          },
        );
      }));
  };

  updateOfficeName = (office) => {
    const patch = {
      voucher_office_allocation_name: null,
      officename: '',
    };
    if (office) {
      patch.voucher_office_allocation_name = getOfficeName(office);
      patch.officename = office.managing_office_code;
    }
    this.props.updateCommission(this.props.commission.id, patch)
      .then(() => {
        this.props.updateDealPipelineTimestamp();
      });
  };

  updateFunctionalUnitName = (unit) => {
    this.props.updateCommission(
      this.props.commission.id,
      {
        deptid: unit ? unit.functional_unit_code : '',
      },
    )
      .then(() => {
        this.props.updateDealPipelineTimestamp();
      });
  };

  updatePoolName = (pool) => {
    const patch = !pool ? {
      voucher_cbre_pool_name: null,
      deptid: '',
      officename: '',
    } : {
      voucher_cbre_pool_name: getPoolName(pool),
      deptid: pool.functional_unit_code,
      officename: pool.managing_office_code,
    };
    this.props.updateCommission(this.props.commission.id, patch)
      .then(() => {
        this.props.updateDealPipelineTimestamp();
      });
  };

  functionalTipMessage = 'Type or scroll within the list to search and enter the Functional Unit name/code.';
  officeTipMessage = 'Type or scroll within the list to search and enter the Managing Office name/code.';
  poolTipMessage = 'Type or scroll within the list to search and enter the Pool Account name/code.';

  infoTooltipMessage = message => (
    <Tooltip message={message}>
      <Icon iconName="info-circle" />
    </Tooltip>
  );

  render() {
    const {
      commission,
      brokerType,
      isCommissionPercent,
      toggleSign,
      baseTotal,
      updateDealPipelineTimestamp,
    } = this.props;
    const { brokerAvatar } = this.state;
    const isLeadRadio = (
      <RadioButton
        name={uniqueId()}
        value={commission.broker_is_lead}
        checked={commission.broker_is_lead}
        onChange={this.setLeadProducer}
      >
        Lead Producer
      </RadioButton>
    );
    const removeIcon = <IconButton className="blxs-button-icon-small" iconName="close-circle" onClick={this.onRemoveClick} variant="basic" />;

    const brokerItem = (
      <div className="form-row-with-labels-and-two-line-hints">
        <Avatar
          enableHover
          hoverAlign="left"
          firstName={commission.broker ? commission.broker.first_name : ''}
          lastName={commission.broker ? commission.broker.last_name : ''}
          image={brokerAvatar.avatar}
        />
        <FormGroup
          label="Broker"
          isRequired
          hasError={!commission.broker}
          size="large"
          errorMessage="Required"
        >
          <BrokerAutocomplete
            onSelect={this.updateBroker}
            initSearchTerm={this.getBrokerFullName()}
          />
          <div className="input-hint">
            {this.getBrokerHint()}
          </div>
        </FormGroup>
        <PercentDollarInput
          label="Amount"
          isRequired
          baseTotal={baseTotal}
          toggleSign={toggleSign}
          updateChange={(percent, dollar) => { this.handlePercentDollarBlur(percent, dollar); }}
          percentModel={commission.voucher_gross_commission_percent}
          dollarModel={commission.gross_commission}
          isPercent={isCommissionPercent}
          updateDealPipelineTimestamp={updateDealPipelineTimestamp}
          min={0}
          maxLength={20}
          maxPercent={100}
          maxPercentWarningText="Warning: The commission split exceeds 100%."
        />
        {isLeadRadio}
        {removeIcon}
      </div>
    );

    const officeItem = (
      <div className="form-row-with-labels-and-hints">
        <div className="full-fumo">
          <div className="nonbroker-dropdown functional-unit">
            <FormGroup
              label={<>Functional Unit <span className="noCapitalize">{this.infoTooltipMessage(this.functionalTipMessage)}</span> </>}
              hasError
              isRequired={!!commission.officename && !commission.deptid}
              errorMessage="Functional Unit is Required"
            >
              <FunctionalUnitAutocomplete
                deptid={commission.deptid}
                onSelect={this.updateFunctionalUnitName}
                placeholder="Select"
                errorMessage={!!commission.officename && !commission.deptid ? ' ' : undefined}
              />
            </FormGroup>
          </div>
          <div className="nonbroker-dropdown office">
            <FormGroup
              label={<>Managing Office <span className="noCapitalize">{this.infoTooltipMessage(this.officeTipMessage)}</span></>}
              isRequired={!!commission.deptid && !commission.officename}
              hasError
              errorMessage="Office is Required"
            >
              <ManagingOfficeAutocomplete
                voucher_office_allocation_name={commission.voucher_office_allocation_name}
                onSelect={this.updateOfficeName}
                placeholder="Select"
                errorMessage={!!commission.deptid && !commission.officename ? ' ' : undefined}
              />
            </FormGroup>
          </div>
        </div>
        {/* Commenting Client Name As Per User Story CB-1396120 */ }
        {/* <div className="label-display">
          <FormGroup label="Client Name (If Applicable)" size="medium" className="due-upon-field" >
            <input
              type="text"
              name="voucher_office_client_name"
              value={this.state.clientName}
              onChange={e => this.handleClientName(e.target.value)}
              onBlur={() => this.updateClientName()}
            />
          </FormGroup>
        </div> */}
        <PercentDollarInput
          label="Amount"
          baseTotal={baseTotal}
          toggleSign={toggleSign}
          updateChange={(percent, dollar) => { this.handlePercentDollarBlur(percent, dollar); }}
          percentModel={commission.voucher_gross_commission_percent}
          dollarModel={commission.gross_commission}
          isPercent={isCommissionPercent}
          updateDealPipelineTimestamp={updateDealPipelineTimestamp}
          min={0}
          maxLength={20}
          maxPercent={100}
          maxPercentWarningText="Warning: The commission split exceeds 100%."
        />
        {isLeadRadio}
        {removeIcon}
      </div>
    );

    const poolItem = (
      <div className="form-row-with-labels-and-hints">
        <div className="nonbroker-dropdown pool">
          <FormGroup
            label={<>Pool Account <span className="noCapitalize"> {this.infoTooltipMessage(this.poolTipMessage)}</span></>}
            hasError={false}
          >
            <PoolAccountAutocomplete
              voucher_cbre_pool_name={commission.voucher_cbre_pool_name}
              onSelect={this.updatePoolName}
              placeholder="Select"
            />
          </FormGroup>
        </div>
        <PercentDollarInput
          label="Amount"
          baseTotal={baseTotal}
          toggleSign={toggleSign}
          updateChange={(percent, dollar) => { this.handlePercentDollarBlur(percent, dollar); }}
          percentModel={commission.voucher_gross_commission_percent}
          dollarModel={commission.gross_commission}
          isPercent={isCommissionPercent}
          updateDealPipelineTimestamp={updateDealPipelineTimestamp}
          min={0}
          maxLength={20}
          maxPercent={100}
          maxPercentWarningText="Warning: The commission split exceeds 100%."
        />
        {isLeadRadio}
        {removeIcon}
      </div>
    );

    const renderItemByType = (propTypes) => {
      switch (propTypes) {
        case BROKER_TYPES.EMPLOYEE:
          return brokerItem;
        case BROKER_TYPES.OFFICE:
          return officeItem;
        case BROKER_TYPES.POOL:
          return poolItem;
        default:
          return '';
      }
    };

    return (
      <React.Fragment>
        {renderItemByType(brokerType)}
      </React.Fragment>
    );
  }
}

CommissionAllocationItem.propTypes = ({
  commission: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    broker: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      work_email: PropTypes.string,
      peoplesoft_id: PropTypes.string,
      managing_office_name: PropTypes.string,
    }),
    voucher_gross_commission_percent: PropTypes.number,
    voucher_office_client_name: PropTypes.string,
    gross_commission: PropTypes.number,
    broker_is_lead: PropTypes.bool,
    voucher_office_allocation_name: PropTypes.string,
    voucher_cbre_pool_name: PropTypes.string,
    deptid: PropTypes.string,
    officename: PropTypes.string,
  }).isRequired,
  brokerType: PropTypes.oneOf(['employee', 'office', 'pool']).isRequired,
  updateCommission: PropTypes.func.isRequired,
  baseTotal: PropTypes.number.isRequired,
  isCommissionPercent: PropTypes.bool,
  toggleSign: PropTypes.func.isRequired,
  deleteCommission: PropTypes.func.isRequired,
  setLeadProducer: PropTypes.func.isRequired,
  deal: PropTypes.shape({
    id: PropTypes.number,
    is_on_pipeline: PropTypes.bool,
  }),
  updateDealPipelineTimestamp: PropTypes.func.isRequired,
});

CommissionAllocationItem.defaultProps = ({
  isCommissionPercent: true,
  deal: {
    id: 0,
    is_on_pipeline: false,
  },
});

export default CommissionAllocationItem;
