import superFetch from '../utils/super-fetch';

const getDealTeam = (params) => {
  const url = '/mbb/members';
  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json());
};

// TODO tryJSON should be a part of superFetch
// In instances when a successful response has no data or is not a json blob, data.json() blows up
// tryJSON handles this and returns the response as it is
const tryJSON = data => data.json().catch(() => data);

const addDealMember = (params) => {
  // send data to bulk add endpoint if array of objects. if single object, send to members endpoint
  const url = `/mbb/members${Array.isArray(params) ? '/bulk' : ''}`;
  const options = {
    method: 'POST',
  };
  return superFetch(url, options, JSON.stringify(params))
    .then(tryJSON);
};

// direct service calls to removeDealMember should catch and handle errors
const removeDealMember = (params) => {
  const url = `/mbb/members/${Array.isArray(params) ? 'bulk' : `${params}`}`;
  const options = {
    method: 'DELETE',
  };

  return superFetch(url, options, JSON.stringify(params))
    .then(() => ({ params, ...options }));
};


export default {
  getDealTeam,
  addDealMember,
  removeDealMember,
};
