import React from 'react';
import PropTypes from 'prop-types';

const PaymentStatusIndicator = ({ status }) => (
  <span className={`status-indicator ${status}`} />
);

PaymentStatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
};

export default PaymentStatusIndicator;
