import { useEffect, useState, useRef } from 'react';
import predictiveNotesService from '../../services/predictive-notes-service';
import dealsService from '../../services/deals-service';


export const updateDealModified = id => dealsService.updateDeal(id, { modified: new Date() });

const detectPredictiveNote = async (disabled, followup, text) => {
  if (!disabled && !followup) {
    const index = await predictiveNotesService.getPredictiveIndex(text);
    const SUCCESS_THRESHOLD = 0.5;
    if (index > SUCCESS_THRESHOLD) {
      predictiveNotesService.trackDisplayed(text, index);
      return true;
    }
  }
  return followup;
};

const AUTO_TASK_STATE = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  MODAL: 'modal',
};

const initialFocus = { textarea: false, radio: false, button: false };

export default function useDealNoteState({
  isNew, id, body, privacy, dealId, disablePredictiveNotes, debounce,
  onCreate, onDelete, onEdit, onUpdate,
}) {
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(isNew);
  const [focused, setFocused] = useState(initialFocus);
  const [noteBody, setNoteBody] = useState(body || '');
  const [notePrivacy, setNotePrivacy] = useState(privacy || 'team');
  const [followup, setFollowup] = useState(false);
  const [autoTaskDismissed, setAutoTaskDismissed] = useState(false);
  const textareaRef = useRef();
  const [synced, setSynced] = useState(true);
  const ref = useRef();
  const [autoTaskState, setAutoTaskState] = useState(AUTO_TASK_STATE.INACTIVE);

  // tracks focus for clicks outside in relation to the Autotask component
  useEffect(() => {
    const onClick = (e) => {
      if (ref.current && !ref.current.contains(e.target) && autoTaskState !== AUTO_TASK_STATE.MODAL) {
        setAutoTaskState(AUTO_TASK_STATE.INACTIVE);
      }
    };
    if (autoTaskState !== AUTO_TASK_STATE.INACTIVE) {
      document.addEventListener('click', onClick);
    }
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [autoTaskState]);

  // moves the cursor to the end of text in the textarea on focus
  useEffect(() => {
    if (focused.textarea && textareaRef.current && typeof textareaRef.current.selectionStart === 'number') {
      textareaRef.current.selectionStart = textareaRef.current.value.length;
    }
  }, [focused.textarea]);

  // checks for updates that may have happened during a creation request
  useEffect(() => {
    if (id && creating) {
      setCreating(false);
      const patch = {};
      if (body.trim() !== noteBody.trim()) {
        patch.body = noteBody;
      }
      if (privacy !== notePrivacy) {
        patch.privacy = notePrivacy;
      }
      if (patch.body || patch.privacy) {
        onUpdate(id, patch);
        detectPredictiveNote(disablePredictiveNotes, followup, noteBody)
          .then(setFollowup);
        updateDealModified(dealId);
      }
    }
    // we only want this to run after creating is complete to see if something changed during the request
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creating, id]);

  // save body and permissions after debounce time is met
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!noteBody && id && !editing) {
        onDelete(id);
        updateDealModified(dealId);
      } else if (((noteBody.trim() && noteBody.trim() !== body) || privacy !== notePrivacy) && !creating) {
        if (id) {
          const patch = {};
          if (noteBody.trim() !== body) {
            patch.body = noteBody;
          }
          if (privacy !== notePrivacy) {
            patch.privacy = notePrivacy;
          }
          if (patch.body || patch.privacy) {
            onUpdate(id, patch);
            updateDealModified(dealId);
            detectPredictiveNote(disablePredictiveNotes, followup, noteBody)
              .then(setFollowup);
          }
        } else {
          // dont' create a note on permission change if there is no body
          if (noteBody === '') {
            return;
          }
          setCreating(true);
          onCreate({
            privacy: notePrivacy,
            body: noteBody,
            deal: dealId,
          });
          updateDealModified(dealId);
        }
      }
    }, debounce);
    return () => {
      clearTimeout(timeout);
    };
    // since the events are created in a parent class where we can't memo them, theree reference is always different causing this to run
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body, noteBody, notePrivacy, privacy]);

  // keeps track of focus state for all inputs
  const onFocusChange = ({ target: { type: name }, type }) => setFocused({ ...focused, [name]: type === 'focus' });

  // determines if state is in sync with props
  useEffect(() => {
    if (body !== noteBody.trim() || privacy !== notePrivacy) {
      setSynced(false);
    } else {
      setSynced(true);
    }
  }, [body, noteBody, privacy, notePrivacy]);

  // handles side effects for if editing mode is showing or not
  useEffect(() => {
    const timeout = setTimeout(() => {
      if ((focused.radio || focused.textarea || focused.button) && !editing) {
        setEditing(true);
        onEdit(true);
      }
      if ((!focused.radio && !focused.textarea && !focused.button && autoTaskState === AUTO_TASK_STATE.INACTIVE)) {
        setEditing(false);
        if (!noteBody.trim() && id && !editing) {
          onDelete(id);
          updateDealModified(dealId);
          onEdit(false);
          return;
        }
        if (synced || (!noteBody.trim() && !id)) {
          onEdit(false);
        }
      }
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
    // since the events are created in a parent class where we can't memo them, theree reference is always different causing this to run
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused.radio, focused.textarea, focused.button, editing, synced, autoTaskState]);

  const onBodyChange = ({ target: { value } }) => setNoteBody(value);
  const onPrivacyChange = ({ target: { value } }) => setNotePrivacy(value);

  const onEditToggle = () => {
    setEditing(current => !current);
  };

  const onTaskDismiss = (wasDismissedByUser, text) => {
    if (wasDismissedByUser) {
      predictiveNotesService.trackDismissed(text);
    }
    setAutoTaskDismissed(true);
  };

  const onAutoTaskStateChange = (state) => {
    if (state === AUTO_TASK_STATE.INACTIVE) {
      setFocused({ ...focused, textarea: true });
      textareaRef.current.focus();
    }
    setAutoTaskState(state);
  };

  const onDeleteNote = () => {
    onDelete(id);
    setFocused(initialFocus);
    updateDealModified(dealId);
    onEdit(false);
  };

  return {
    onFocusChange,
    noteBody,
    notePrivacy,
    editing,
    followup,
    autoTaskDismissed,
    onBodyChange,
    onPrivacyChange,
    onEditToggle,
    onTaskDismiss,
    onDeleteNote,
    textareaRef,
    ref,
    autoTaskState,
    onAutoTaskStateChange,
  };
}
