import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';

import Datepicker from '../../../nucleus/datepicker/datepicker';
import Select from '../../../nucleus/select/select';

const SpacesFields = (props) => {
  const renderSpaceTypeFields = () => {
    const {
      onInputChange,
      onLeaseExpirationChange,
      onStatusChange,
      remove,
      spaces,
      property,
    } = props;
    return spaces && spaces.map(space => (
      <React.Fragment key={space.id}>
        <section className="suite-fields">
          <div className="integer-fields-group">
            <div className="fields-wrapper">
              <div className="floor">
                <label>* Floor</label>
                <input
                  className={space.floor_code_error ? 'invalid' : ''}
                  name="floor_code"
                  onChange={e => onInputChange(e, space.id)}
                  type="text"
                  value={space.floor_code || ''}
                />
              </div>
              <div className="suite">
                <label>Suite</label>
                <input
                  name="address_line2"
                  onChange={e => onInputChange(e, space.id)}
                  type="text"
                  value={space.address_line2 || ''}
                />
              </div>
              <div className="sqft">
                <label>SQFT</label>
                <input
                  name="total_sqft"
                  onChange={e => onInputChange(e, space.id)}
                  type="number"
                  value={space.total_sqft || ''}
                />
              </div>
            </div>
            <div>
              <IconButton
                className="secondary-icon-button"
                iconName="close"
                onClick={() => remove(space.id)}
                variant="basic"
              />
            </div>
          </div>
          <div className="other-fields-group">
            <div className="status">
              <label>Status</label>
              <Select
                defaultOption={space.status || 'Select'}
                onChange={e => onStatusChange(e, space.id)}
                options={[
                  { display_name: 'Available', value: 'Available' },
                  { display_name: 'Not Available', value: 'Not Available' },
                ]}
              />
            </div>
            {property.usage === 'Current' &&
              <div className="lease-exp">
                <label>Lease Expiration</label>
                <Datepicker
                  date={space.lease_expiration_date}
                  onDateChange={date => onLeaseExpirationChange(date, space.id)}
                  scrollOnFocus
                />
              </div>
            }
            <div className="comments">
              <label>Comments</label>
              <input
                name="description"
                onChange={e => onInputChange(e, space.id)}
                type="text"
                value={space.description || ''}
              />
            </div>
          </div>
        </section>
        {space.floor_code_error &&
          <p className="invalid-message">Floor is required</p>
        }
      </React.Fragment>
    ));
  };

  return (
    <React.Fragment>
      <div className="spaces-fields">
        <div className="fieldset">
          {renderSpaceTypeFields()}
        </div>
      </div>
    </React.Fragment>
  );
};

SpacesFields.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onLeaseExpirationChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  property: PropTypes.shape({
    building: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      full_address: PropTypes.string,
      id: PropTypes.number,
      postal_code: PropTypes.string,
      primary_property_name: PropTypes.string,
      state_abbreviation: PropTypes.string,
    }),
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    name: PropTypes.string,
    spaces: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string,
      description: PropTypes.string,
      address_line2: PropTypes.undefined,
      id: PropTypes.null,
      floor_code: PropTypes.string,
      lease_expiration_date: PropTypes.undefined,
      total_sqft: PropTypes.number,
      owned: PropTypes.undefined,
      whole_building: PropTypes.undefined,
      transactions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.null,
      })),
    })),
    usage: PropTypes.string,
  }),
  spaces: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.string,
    description: PropTypes.string,
    address_line2: PropTypes.undefined,
    id: PropTypes.null,
    floor_code: PropTypes.string,
    floor_code_error: PropTypes.bool,
    lease_expiration_date: PropTypes.undefined,
    total_sqft: PropTypes.number,
    owned: PropTypes.undefined,
    whole_building: PropTypes.undefined,
    transactions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.null,
    })),
  })),
};

SpacesFields.defaultProps = {
  property: {},
  spaces: [{
    status: '',
    description: '',
    address_line2: undefined,
    id: null,
    floor_code: '',
    floor_code_error: false,
    lease_expiration_date: undefined,
    total_sqft: 0,
    owned: undefined,
    whole_building: undefined,
    transactions: [
      {
        id: null,
      },
    ],
  }],
};

export default SpacesFields;
