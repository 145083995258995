import { connect } from 'react-redux';
import {
  addNote,
  deleteNote,
  fetchNotes,
  updateNote,
  newNoteCleanup,
} from '../../../redux/actions/deal-notes-actions';
import NotesTab from './notes-tab';

const mapStateToProps = state => ({
  dealId: state.deal.deal.id,
  isLoading: state.dealNotes.isLoading,
  newNote: state.dealNotes.newNote,
  next: state.dealNotes.next,
  notes: state.dealNotes.notes,
  user: state.userData.data,
});

const mapDispatchToProps = {
  addNote,
  deleteNote,
  fetchNotes,
  updateNote,
  newNoteCleanup,
};

const mergeProps = (state, actions, props) => ({
  ...state,
  ...actions,
  analyticProperties: {
    actionPrefix: `${props.analyticProperties.actionPrefix}_notes_tab`,
    categoryPrefix: `${props.analyticProperties.categoryPrefix}Notes Tab `,
  },
});

const NotesTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(NotesTab);

export default NotesTabContainer;
