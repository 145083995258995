import { connect } from 'react-redux';

// actions
import {
  updateConsiderations,
  updateConsiderationsInModal,
  fetchConsiderations,
} from '../../../redux/actions/voucher/considerations-actions';
import {
  updateAdditionalCommissions,
  updateAdditionalCommissionsInModal,
  fetchAdditionalCommissions,
} from '../../../redux/actions/voucher/additional-commissions-actions';
import { fetchCommissions } from '../../../redux/actions/voucher/commissions-actions';
import { updateDeal, initializeCommissionsTotal } from '../../../redux/actions/deal-actions';

import CommissionsModal from './commissions-modal';

const mapStateToProps = state => ({
  dealId: state.deal.deal.id,
  dealType: state.deal.deal.deal_type,
  tempConsiderations: state.tempConsiderations,
  tempAdditionalCommissions: state.tempAdditionalCommissions,
});

const mapDispatchToProps = {
  updateConsiderations,
  updateConsiderationsInModal,
  updateAdditionalCommissions,
  updateAdditionalCommissionsInModal,
  fetchConsiderations,
  fetchAdditionalCommissions,
  initializeCommissionsTotal,
  updateDeal,
  fetchCommissions,
};

const CommissionsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommissionsModal);

export default CommissionsModalContainer;
