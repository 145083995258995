import superFetch from '../utils/super-fetch';
import userEventService from '../services/user-event-service';

const getPredictiveIndex = (text) => {
  const url = '/predictivenotes';
  const options = {
    method: 'POST',
  };
  const body = [[text]];

  return superFetch(url, options, JSON.stringify(body))
    .then(res => res.json())
    .then(res => res.results[0])
    .catch(() => -1);
};

// tracking functions
const trackDisplayed = (noteBody, predictiveIndex) => {
  const event = {
    eventCategory: 'Predictive Notes',
    eventAction: 'predictive_notes_displayed',
    eventLabel: 'predictive_notes_displayed',
    eventData: {
      predictiveIndex,
      noteBody,
    },
  };

  userEventService.trackEvent(event);
};

const trackCreated = (task, timeOption, noteBody) => {
  const event = {
    eventCategory: 'Predictive Notes',
    eventAction: 'predictive_notes_task_created',
    eventLabel: 'predictive_notes_task_created',
    eventData: {
      taskId: task.id,
      timeOption,
      noteBody,
    },
  };

  if (timeOption === 'custom') {
    event.eventData.alertDateTime = task.alert_datetime;
  }

  userEventService.trackEvent(event);
};

const trackDismissed = (noteBody) => {
  const event = {
    eventCategory: 'Predictive Notes',
    eventAction: 'predictive_notes_dismissed',
    eventLabel: 'predictive_notes_dismissed',
    eventData: {
      noteBody,
    },
  };

  userEventService.trackEvent(event);
};


export default {
  getPredictiveIndex,
  trackDisplayed,
  trackCreated,
  trackDismissed,
};
