/* global CONFIG */
import jwtDecode from 'jwt-decode';

import superFetch from '../utils/super-fetch';

const {
  apiUrl,
  appId,
  messagingSenderId,
  vapidKey,
  testURL,
} = CONFIG;
const SERVICE_WORKER_PATH = '/app/firebase-messaging-sw.js';

const isTokenSentToServer = () => window.localStorage.getItem('pushTokenSentToServer') === '1';

const setTokenSentToServer = (sent) => {
  window.localStorage.setItem('pushTokenSentToServer', sent ? '1' : '0');
};

const sendTokenToServer = (currentToken) => {
  const jwt = localStorage.getItem('JWT');
  const decoded = jwtDecode(jwt);
  // Don't store new token when using a hijacked account
  if (decoded.isHijackedUser && (apiUrl.indexOf('localhost') > -1 || apiUrl.indexOf('review.galactica') > -1)) return;
  if (!isTokenSentToServer()) {
    const url = '/device/gcm';
    const options = { method: 'POST' };
    const body = JSON.stringify({
      registration_id: currentToken,
      cloud_message_type: 'FCM',
    });

    superFetch(url, options, body)
      .then(() => {
        setTokenSentToServer(true);
      })
      .catch(error => console.error('Error fetching alerts: ', error));
  } else {
    console.log('Token already sent to server so won\'t send it again ' +
      'unless it changes');
  }
};

const init = (setNotificationsState, setTaskAlertsState) => {
  firebase.initializeApp({ // eslint-disable-line
    messagingSenderId,
    appId,
  });

  const messageHandler = (payload) => {
    console.log('Message received. ', payload);
    // trigger alerts requests
    const alertObject = JSON.parse(payload.data.object);
    if (alertObject.variety && (alertObject.variety === 'task_reminder' || alertObject.variety === 'assigned_to_task')) {
      setTaskAlertsState();
    } else {
      setNotificationsState();
    }
  };

  const messaging = firebase.messaging(); // eslint-disable-line
  messaging.usePublicVapidKey(vapidKey);

  navigator.serviceWorker.register(SERVICE_WORKER_PATH)
    .then((registration) => {
      console.log('Registration successful, scope is:', registration.scope);
      messaging.useServiceWorker(registration);

      messaging.requestPermission()
        .then(() => {
          console.log('Notification permission granted.');
          // Get Instance ID token. Initially this makes a network call, once retrieved
          // subsequent calls to getToken will return from cache.
          messaging.getToken()
            .then((currentToken) => {
              console.log(currentToken);
              if (currentToken) {
                sendTokenToServer(currentToken);
              } else {
                // Show permission request.
                console.log('No Instance ID token available. Request permission to generate one.');
                setTokenSentToServer(false);
              }
            })
            .catch((err) => {
              console.log('An error occurred while retrieving token. ', err);
              setTokenSentToServer(false);
            });
        })
        .catch((err) => {
          console.log('Unable to get permission to notify.', err);
        });
    });

  messaging.onTokenRefresh(() => {
    messaging.getToken()
      .then((refreshedToken) => {
        console.log('Token refreshed.');
        setTokenSentToServer(false);
        sendTokenToServer(refreshedToken);
      })
      .catch((err) => {
        console.log('Unable to retrieve refreshed token ', err);
      });
  });

  messaging.onMessage(messageHandler);

  if (testURL) {
    module.exports.messageHandler = messageHandler;
  }
};

export default {
  init,
};
