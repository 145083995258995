import { connect } from 'react-redux';
import {
  archiveDeal,
  deleteDeal,
  restoreDeal,
  updateDeal,
  addPrimaryContact,
  deletePrimaryContact,
  updatePrimaryContact,
  updateDealNote,
  fetchDealNoteAndTask,
} from '../../redux/actions/deals-actions';
import {
  favoriteDeal,
  unfavoriteDeal,
} from '../../redux/actions/deal-actions';

import { addDealTag } from '../../redux/actions/tags-actions';
import { toast } from '../../redux/actions/toasts-actions';
import DealCard from './deal-card';

const mapStateToProps = state => ({
  dealTags: state.tags.dealTags,
  user: state.userData.data,
  featureFlags: state.userOptions.userOptions.feature_flags || {},
  dealUsers: state.teamMembers.dealUsers,
  dealTeams: state.teamMembers.dealTeams,
});

const mapDispatchToProps = {
  archiveDeal,
  deleteDeal,
  restoreDeal,
  toast,
  updateDeal,
  addPrimaryContact,
  deletePrimaryContact,
  updatePrimaryContact,
  updateDealNote,
  addDealTag,
  favoriteDeal,
  unfavoriteDeal,
  fetchDealNoteAndTask,
};

const DealCardContainer = connect(mapStateToProps, mapDispatchToProps)(DealCard);

export default DealCardContainer;
