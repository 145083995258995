import React from 'react';
import { useSelector } from 'react-redux';
import Considerations from './Considerations';
import AdditionalCommissions from './AdditionalCommissions';
import useVoucherValidation from '../useVoucherValidation';

const PAGE_NUMBER = 3.1;

const ConsiderationTab = () => {
  const { id, dealType, voucherId } = useSelector(state => ({
    id: state.deal.deal?.id,
    dealType: state.deal.deal?.deal_type,
    voucherId: state.voucher.voucher?.id,
  }));
  const { showPageValidation } = useVoucherValidation(voucherId, PAGE_NUMBER);
  return (
    <div className="considerations-tab horizontal-overflow">
      {dealType !== 'other' && dealType !== 'consulting' && <Considerations dealId={id} dealType={dealType} showValidation={showPageValidation} />}
      <AdditionalCommissions dealId={id} dealType={dealType} showValidation={showPageValidation} />
    </div>
  );
};

export default ConsiderationTab;
