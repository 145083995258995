import unionBy from 'lodash/unionBy';

import {
  ADD_DEAL_CONTACT_SUCCESS,
  ADD_DEAL_CONTACT_FAILURE,
  CLEAR_DEAL_STORE,
  DELETE_DEAL_CONTACT_SUCCESS,
  DELETE_DEAL_CONTACT_FAILURE,
  IS_LOADING_CONTACTS_ACTION,
  FETCH_DEAL_CONTACTS_SUCCESS,
  FETCH_DEAL_CONTACTS_FAILURE,
  UPDATE_DEAL_CONTACT_SUCCESS,
  UPDATE_DEAL_CONTACT_FAILURE,
} from '../actions/action-types';

const initialState = {
  count: 0,
  error: undefined,
  isLoading: false,
  next: null,
  previous: null,
  contacts: [],
  primaryContact: undefined,
};

const addContactSuccess = (state, { payload: newContact }) => {
  if (newContact.is_primary) {
    const contacts = state.primaryContact
      ? [{ ...state.primaryContact, is_primary: false }, ...state.contacts]
      : [...state.contacts];
    return ({
      ...state,
      primaryContact: newContact,
      contacts,
    });
  }
  return ({
    ...state,
    contacts: [newContact, ...state.contacts],
  });
};

const clearStore = () => ({
  ...initialState,
});

const deleteContactSuccess = (state, { payload: { id } }) => {
  if (state.primaryContact && state.primaryContact.id === id) {
    return ({
      ...state,
      primaryContact: undefined,
    });
  }
  return ({
    ...state,
    contacts: state.contacts.filter(contact => contact.id !== id),
  });
};

const fetchContactsSuccess = (state, action) => {
  const {
    count,
    next,
    previous,
    results,
  } = action.payload;
  const allContacts = previous === null
    ? results
    : unionBy(state.contacts, results, 'id');
  return ({
    ...state,
    count,
    next,
    contacts: allContacts.filter(contact => !contact.is_primary),
    previous,
    primaryContact: allContacts.find(contact => contact.is_primary),
  });
};

const updateContactSuccess = (state, { payload: updatedContact }) => {
  if (updatedContact.is_primary) {
    const contacts = state.primaryContact && updatedContact.id !== state.primaryContact.id
      ? [{ ...state.primaryContact, is_primary: false }, ...state.contacts.filter(contact => contact.id !== updatedContact.id)]
      : [...state.contacts.filter(contact => contact.id !== updatedContact.id)];
    return ({
      ...state,
      primaryContact: updatedContact,
      contacts,
    });
  } else if (!updatedContact.is_primary && state.primaryContact && state.primaryContact.id === updatedContact.id) {
    return ({
      ...state,
      primaryContact: undefined,
      contacts: [updatedContact, ...state.contacts],
    });
  }
  return ({
    ...state,
    contacts: state.contacts
      .map(contact => (contact.id === updatedContact.id ? updatedContact : contact)),
  });
};

const isLoading = (state, action) => ({
  ...state,
  isLoading: action.payload.isLoading,
});

const dealContactsFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const dealContacts = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEAL_CONTACT_SUCCESS:
      return addContactSuccess(state, action);
    case CLEAR_DEAL_STORE:
      return clearStore();
    case DELETE_DEAL_CONTACT_SUCCESS:
      return deleteContactSuccess(state, action);
    case FETCH_DEAL_CONTACTS_SUCCESS:
      return fetchContactsSuccess(state, action);
    case UPDATE_DEAL_CONTACT_SUCCESS:
      return updateContactSuccess(state, action);
    case IS_LOADING_CONTACTS_ACTION:
      return isLoading(state, action);
    case ADD_DEAL_CONTACT_FAILURE:
    case DELETE_DEAL_CONTACT_FAILURE:
    case UPDATE_DEAL_CONTACT_FAILURE:
    case FETCH_DEAL_CONTACTS_FAILURE:
      return dealContactsFailure(state, action);
    default:
      return state;
  }
};

export default dealContacts;
