import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';

const Badge = (props) => {
  // render Badge as the child of an icon button for correct positioning
  const {
    displayBadge,
    count,
    displayWarning,
  } = props;

  const badgeCount = count > 99 ? '99+' : count;
  let renderBadge = displayBadge && count > 0 ? (
    <div className="nd-badge">
      <p className="count">{badgeCount}</p>
    </div>
  ) : '';
  if (displayWarning) { // notifications are not enabled
    renderBadge = (
      <div className="nd-badge warning">
        <Icon iconName="warning" />
      </div>
    );
  }

  return (
    <React.Fragment>
      {renderBadge}
    </React.Fragment>
  );
};

export default Badge;

Badge.propTypes = {
  displayBadge: PropTypes.bool,
  count: PropTypes.number,
  displayWarning: PropTypes.bool,
};

Badge.defaultProps = {
  displayBadge: true,
  count: 0,
  displayWarning: false,
};
