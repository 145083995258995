import propertiesService from '../../services/properties-service';
import { loadDeal } from '../actions/deal-actions';
import { toast } from './toasts-actions';

import {
  ADD_PROPERTY_SUCCESS,
  ADD_PROPERTY_FAILURE,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAILURE,
  LOAD_PROPERTIES_SUCCESS,
  LOAD_PROPERTIES_FAILURE,
  IS_LOADING_PROPERTIES,
  UPDATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_FAILURE,
} from './action-types';

// Properties Action Creators
const addPropertySuccess = property => ({
  type: ADD_PROPERTY_SUCCESS,
  payload: { ...property },
});

const addPropertyFailure = error => ({
  type: ADD_PROPERTY_FAILURE,
  payload: { error },
});

const deletePropertySuccess = id => ({
  type: DELETE_PROPERTY_SUCCESS,
  payload: { id },
});

const deletePropertyFailure = error => ({
  type: DELETE_PROPERTY_FAILURE,
  payload: { error },
});

const loadPropertiesSuccess = response => ({
  type: LOAD_PROPERTIES_SUCCESS,
  payload: { response },
});

const loadPropertiesFailure = error => ({
  type: LOAD_PROPERTIES_FAILURE,
  payload: { error },
});

const isLoadingProperties = isLoading => ({
  type: IS_LOADING_PROPERTIES,
  payload: { isLoading },
});

const updatePropertySuccess = property => ({
  type: UPDATE_PROPERTY_SUCCESS,
  payload: property,
});

const updatePropertyFailure = error => ({
  type: UPDATE_PROPERTY_FAILURE,
  payload: { error },
});

// PROPERTIES ASYNC ACTIONS:
const addProperty = (params, dealId) => dispatch =>
  propertiesService.addDealProperty(params)
    .then((property) => {
      dispatch(addPropertySuccess(property));
      dispatch(loadDeal(dealId));
    })
    .catch(error => dispatch(addPropertyFailure(error)));

const loadProperties = dealId => (dispatch) => {
  dispatch(isLoadingProperties(true));
  return propertiesService.fetchDealProperties(dealId)
    .then(data => dispatch(loadPropertiesSuccess(data)))
    .catch(error => dispatch(loadPropertiesFailure(error)))
    .then(() => dispatch(isLoadingProperties(false)));
};

const updateProperty = (id, params, dealId) => dispatch =>
  propertiesService.updateDealProperty(id, params)
    .then((property) => {
      dispatch(updatePropertySuccess(property));
      dispatch(loadDeal(dealId));
    })
    .catch(error => dispatch(updatePropertyFailure(error)));

const deleteProperty = (id, params, dealId) => dispatch =>
  propertiesService.updateDealProperty(id, params)
    .then((property) => {
      dispatch(deletePropertySuccess(property.id));
      dispatch(toast('Property has been deleted'));
      dispatch(loadDeal(dealId));
    })
    .catch((error) => {
      dispatch(deletePropertyFailure(error));
      dispatch(toast('Error deleting property'));
    });

export {
  addProperty,
  deleteProperty,
  loadProperties,
  updateProperty,
};
