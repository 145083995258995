import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { isValidPostal } from '../components/voucher/voucher-validation';
import vouchersService from '../services/voucher/vouchers-service';
import propertiesService from '../services/properties-service';

const eventTargetType = {
  text: true,
  number: true,
  checkbox: true,
};

const dereferenceProperties = properties => properties.map(property => ({
  ...property,
  spaces: property.spaces.map(space => ({ ...space })),
}));

const parseEventTarget = ({
  name, value, checked, type,
} = {}) => {
  const update = { name, type };
  switch (type) {
    case 'number': {
      update.value = parseInt(value, 10);
      break;
    }
    case 'checkbox': {
      update.value = checked;
      break;
    }
    default: {
      update.value = value;
    }
  }
  return update;
};

export default function useCommercialPropertyState({ dealId, filterPropertyResultsBy, createIfEmpty = false }) {
  const mounted = useRef(true);
  const [savedProperties, setSavedProperties] = useState([]);
  const [properties, setProperties] = useState([]);
  const [isTenantRelocating, setIsTenantRelocating] = useState(false);
  const [dealProperties, setDealProperties] = useState([]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const onPropertyCreate = (data = {}) => {
    vouchersService.createCommercialProperty({
      country: 'United States',
      deal: dealId,
      location_type_new: false,
      ...data,
    }).then((res) => {
      const update = [...properties, res];
      setProperties(update);
      const tenantPriorLocations = res.results.filter(property => property.location_type_new === false);
      if (tenantPriorLocations.length) {
        setIsTenantRelocating(true);
      } else {
        setIsTenantRelocating(false);
      }
      setSavedProperties(dereferenceProperties(update));
    });
  };

  const onInvestmentPropertyCreate = (data = {}) => {
    vouchersService.createCommercialProperty({
      deal: dealId,
      is_investment_property: true,
      ...data,
    }).then((res) => {
      const update = [...properties, res];
      setProperties(update);
      setSavedProperties(dereferenceProperties(update));
    });
  };

  useEffect(() => {
    if (dealId) {
      vouchersService.fetchCommercialProperty(dealId)
        .then((res) => {
          if (!mounted.current) return;
          const update = !filterPropertyResultsBy ? res.results : res.results.filter(filterPropertyResultsBy);
          setProperties(update);
          if (!update[0] && createIfEmpty) {
            onPropertyCreate();
          }
          setSavedProperties(dereferenceProperties(update));
        }).catch(() => {
          if (!mounted.current) return;
          setProperties([]);
          setSavedProperties([]);
        });
      propertiesService.fetchDealProperties(dealId)
        .then((res) => {
          if (!mounted.current) return;
          setDealProperties(res.results);
        }).catch(() => {
          if (!mounted.current) return;
          setDealProperties([]);
        });
    } else {
      if (!mounted.current) return;
      setProperties([]);
      setSavedProperties([]);
    }
  }, [dealId]);

  const onPropertyBlur = propertyId => ({ target }) => {
    const previous = savedProperties.find(property => property.id === propertyId);
    const parsed = parseEventTarget(target);
    if (!target || previous[parsed.name] === parsed.value) return;
    const found = properties.find(property => property.id === propertyId);
    if (parsed.name === 'postal_code' && !isValidPostal(parsed.value, found.country)) {
      const update = properties.map(property => (property.Id !== propertyId ? property : ({
        ...property, postal_code: '',
      })));
      setProperties(update);
      parsed.value = null;
    }
    let patch = {
      [parsed.name]: parsed.value,
    };
    if (parsed.name === 'country') {
      patch = {
        [parsed.name]: parsed.value,
        state_abbreviation: null,
      };
    }
    vouchersService.updateCommercialProperty(propertyId, patch).then((res) => {
      const update = properties.map(property => (property.id === propertyId ? res : property));
      setProperties(update);
      setSavedProperties(dereferenceProperties(update));
    });
  };

  const onInvestmentPropertyChange = (propId, value) => {
    const patch = {
      deal: dealId,
      is_investment_property: value,
    };
    vouchersService.updateCommercialProperty(propId, patch).then((res) => {
      const update = properties.map(property => (property.id === propId ? res : property));
      setProperties(update);
      setSavedProperties(dereferenceProperties(update));
    });
  };

  const onInvestPropertyClear = (propId) => {
    const patch = {
      deal: dealId,
      investor_lender_type: null,
      vacancy_rate: null,
      occupancy_rate: null,
      scheduled_gross_income: null,
      property_expense: null,
      cap_yield_rate: null,
      net_operating_income: null,
      spendable_return: null,
      exchange: null,
      is_investment_property: false,
      tenants: [{ name: null, lease_expiration_date: null }],
    };
    const update = properties.map(property => (property.id !== propId ? property : ({
      ...property, ...patch, deal_property_id: null,
    })));
    setProperties(update);
    vouchersService.updateCommercialProperty(propId, patch).then((res) => {
      const updateDeal = properties.map(property => (property.id === propId ? res : property));
      setProperties(updateDeal);
      setSavedProperties(dereferenceProperties(updateDeal));
    });
  };

  const onPropertyChange = propertyId => ({ target }) => {
    const parsed = parseEventTarget(target);
    if (eventTargetType[parsed.type]) {
      const update = properties.map(property => (property.id !== propertyId ? property : ({
        ...property, [parsed.name]: parsed.value,
      })));
      setProperties(update);
    } else {
      const patch = parsed.name !== 'country' ? { [parsed.name]: parsed.value } : {
        [parsed.name]: parsed.value,
        state_abbreviation: null,
      };
      const update = properties.map(property => (property.id !== propertyId ? property : ({
        ...property, ...patch,
      })));
      setProperties(update);
      onPropertyBlur(propertyId)({ target });
    }
  };

  const onInvestorChange = propertyId => ({ target }) => {
    const patch = {
      deal: dealId,
      [target.name]: target.value,
    };
    vouchersService.updateCommercialProperty(propertyId, patch).then((res) => {
      const update = properties.map(property => (property.id === propertyId ? res : property));
      setProperties(update);
      setSavedProperties(dereferenceProperties(update));
    });
  };

  const onPropertyDelete = (propertyId) => {
    const update = properties.filter(property => property.id !== propertyId);
    setProperties(update);
    setSavedProperties(dereferenceProperties(update));
    vouchersService.deleteCommercialProperty(propertyId);
  };

  const onTenantDelete = (propertyId, tenantIndex) => {
    let tenants;
    const update = properties.map((property) => {
      if (property.id !== propertyId) {
        return property;
      }
      tenants = property.tenants.filter((s, i) => i !== tenantIndex);
      return { ...property, tenants };
    });
    setProperties(update);
    vouchersService.updateCommercialProperty(propertyId, { tenants });
  };

  const onDealPropertySelect = (propertyId, dealproperty) => {
    const patch = {
      name: dealproperty.name,
      country: dealproperty.building.country,
      address: dealproperty.building.address,
      city: dealproperty.building.city,
      state_abbreviation: dealproperty.building.state_abbreviation,
      postal_code: dealproperty.building.postal_code,
    };
    const update = properties.map(property => (property.id !== propertyId ? property : ({
      ...property, ...patch, deal_property_id: dealproperty.id,
    })));
    setProperties(update);
    vouchersService.updateCommercialProperty(propertyId, patch).then((res) => {
      const updateDeal = properties.map(property => (property.id === propertyId ? res : property));
      setProperties(updateDeal);
      setSavedProperties(dereferenceProperties(updateDeal));
    });
  };

  const onDealPropertyAdd = (propertyId, dealproperty, dealPropName) => {
    const patch = {
      name: dealPropName,
      country: dealproperty.building.country,
      address: dealproperty.building.address,
      city: dealproperty.building.city,
      state_abbreviation: dealproperty.building.state_abbreviation,
      postal_code: dealproperty.building.postal_code,
    };
    const update = properties.map(property => (property.id !== propertyId ? property : ({
      ...property, ...patch, deal_property_id: dealproperty.id,
    })));
    setProperties(update);
    vouchersService.updateCommercialProperty(propertyId, patch).then((res) => {
      const updateDeal = properties.map(property => (property.id === propertyId ? res : property));
      setProperties(updateDeal);
      setSavedProperties(dereferenceProperties(updateDeal));
    });
  };

  const onCommercialPropertyClear = (propertyId) => {
    const patch = {
      name: '',
      country: '',
      address: '',
      city: '',
      state_abbreviation: '',
      postal_code: '',
    };
    const update = properties.map(property => (property.id !== propertyId ? property : ({
      ...property, ...patch, deal_property_id: null,
    })));
    setProperties(update);
    vouchersService.updateCommercialProperty(propertyId, patch).then((res) => {
      const updateDeal = properties.map(property => (property.id === propertyId ? res : property));
      setProperties(updateDeal);
      setSavedProperties(dereferenceProperties(updateDeal));
    });
  };

  const onSpaceCreate = propertyId => (data = {
    address_line2: '',
    floor: '',
    lease_expiration_date: null,
    lease_start_date: null,
    space_total_square_feet: null,
  }) => {
    const update = properties.map((property) => {
      if (property.id !== propertyId) {
        return property;
      }
      return { ...property, spaces: [...property.spaces, data] };
    });
    setProperties(update);
  };

  const onSpaceDelete = (propertyId, spaceIndex) => {
    let spaces;
    const update = properties.map((property) => {
      if (property.id !== propertyId) {
        return property;
      }
      spaces = property.spaces.filter((s, i) => i !== spaceIndex);
      return { ...property, spaces };
    });
    setProperties(update);
    vouchersService.updateCommercialProperty(propertyId, { spaces });
  };

  const onSpaceBlur = (propertyId, spaceIndex) => ({ target: { name, value } }) => {
    let spaces;
    let updatedProperty;
    const update = properties.map((property) => {
      if (property.id !== propertyId) {
        return property;
      }
      let updatedValue = value;
      if (name === 'space_total_square_feet') {
        updatedValue = parseInt(value, 10);
      }
      spaces = [...property.spaces];
      spaces[spaceIndex] = {
        ...spaces[spaceIndex],
        [name]: (updatedValue !== null || updatedValue !== '') ? updatedValue : null,
      };
      updatedProperty = { ...property, spaces };
      return { ...property, spaces };
    });
    setProperties(update);
    vouchersService.updateCommercialProperty(propertyId, { ...updatedProperty });
  };

  const onSpaceChange = (propertyId, spaceIndex) => ({ target: { name, value } }) => {
    let spaces;
    const update = properties.map((property) => {
      if (property.id !== propertyId) {
        return property;
      }
      spaces = [...property.spaces];
      spaces[spaceIndex] = {
        ...spaces[spaceIndex],
        [name]: (value !== null || value !== '') ? value : null,
      };
      return { ...property, spaces };
    });
    setProperties(update);
  };

  const removeExponents = (parsedVal) => {
    if (!_.isNumber(parsedVal)) {
      return parsedVal;
    }

    const data = String(parsedVal).split(/[eE]/);
    if (data.length === 1) return data[0];

    let z = '';
    const sign = parsedVal < 0 ? '-' : '';
    const str = data[0].replace('.', '');
    let mag = Number(data[1]) + 1;

    if (mag < 0) {
      z = `${sign}0.`;
      while (mag++) z += '0';
      return z + str.replace(/^-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    return str + z;
  };

  const getViewDecimals = (parsedVal) => {
    const normalizedValue = removeExponents(parsedVal);
    const valueSplit = normalizedValue.toString().split('.');
    return valueSplit[1];
  };

  const convertToCurrency = (val) => {
    const parseVal = val;
    const decimals = getViewDecimals(parseVal);

    // Example: value = 10, return 10.00
    if (decimals === undefined) {
      return `${parseVal}.00`;
    }

    // Example: value = 10.1, return 10.10
    if (decimals.toString().length === 1) {
      return `${parseVal}0`;
    }

    return parseVal;
  };

  const onOccupancyBlur = propertyId => ({ target }) => {
    let parsedCurrency = '';
    const previous = savedProperties.find(property => property.id === propertyId);
    const parsed = parseEventTarget(target);
    if (!target || previous[parsed.name] === parsed.value) return;
    if (target.value === null || target.value === '') {
      parsedCurrency = null;
    } else {
      parsedCurrency = convertToCurrency(target.value);
    }
    const patch = {
      [parsed.name]: parsedCurrency,
    };
    vouchersService.updateCommercialProperty(propertyId, patch).then((res) => {
      const update = properties.map(property => (property.id === propertyId ? res : property));
      setProperties(update);
      setSavedProperties(dereferenceProperties(update));
    });
  };

  const onCurrencyBlur = (propertyId, propName) => ({ target }) => {
    let parsedCurrency = '';
    let propVal = target.value;
    if (propVal < 0 || propVal > 100000000000) {
      return false;
    }
    if (propVal.includes('$')) {
      propVal = propVal.replace('$', '');
    }
    const previous = savedProperties.find(property => property.id === propertyId);
    if (!target || previous[propName] === propVal) return false;
    if (propVal === null || propVal === '') {
      parsedCurrency = null;
    } else {
      parsedCurrency = propVal.replace(/,/g, '');
    }
    const patch = {
      [propName]: parsedCurrency,
    };
    vouchersService.updateCommercialProperty(propertyId, patch).then((res) => {
      const update = properties.map(property => (property.id === propertyId ? res : property));
      setProperties(update);
      setSavedProperties(dereferenceProperties(update));
    });
    return true;
  };

  const onOccupancyChange = propertyId => ({ target }) => {
    const parsed = parseEventTarget(target);
    if (parsed.name === 'vacancy_rate' || parsed.name === 'occupancy_rate' || parsed.name === 'cap_yield_rate') {
      if (parsed.value < 0 || parsed.value > 100) {
        return false;
      }
    }
    // if (parsed.value !== null || parsed.value !== '') {
    //   parsed.value = parsed.value.replace('$', '');
    // }
    if (eventTargetType[parsed.type]) {
      const update = properties.map(property => (property.id !== propertyId ? property : ({
        ...property, [parsed.name]: target.value,
      })));
      setProperties(update);
    }
    return true;
  };

  const onTenantCreate = propertyId => (data = {
    lease_expiration_date: null, name: null,
  }) => {
    const update = properties.map((property) => {
      if (property.id !== propertyId) {
        return property;
      }
      return { ...property, tenants: [...property.tenants, data] };
    });
    setProperties(update);
  };

  const onTenantBlur = (propertyId, tenantIndex) => ({ target: { name, value } }) => {
    let tenants;
    const update = properties.map((property) => {
      if (property.id !== propertyId) {
        return property;
      }
      tenants = [...property.tenants];
      tenants[tenantIndex] = {
        ...tenants[tenantIndex],
        [name]: value,
      };
      return { ...property, tenants };
    });
    setProperties(update);
    vouchersService.updateCommercialProperty(propertyId, { tenants });
  };

  const onTenantChange = (propertyId, tenantIndex) => ({ target: { name, value } }) => {
    let tenants;
    const update = properties.map((property) => {
      if (property.id !== propertyId) {
        return property;
      }
      tenants = [...property.tenants];
      tenants[tenantIndex] = {
        ...tenants[tenantIndex],
        [name]: value,
      };
      return { ...property, tenants };
    });
    setProperties(update);
  };

  const onTenantDateChange = (propertyId, tenantIndex, date = null, propName) => {
    let tenants;
    const update = properties.map((property) => {
      if (property.id !== propertyId) {
        return property;
      }
      tenants = [...property.tenants];
      tenants[tenantIndex] = {
        ...tenants[tenantIndex],
        [propName]: date ? moment(date).toDate().toISOString() : null,
      };
      return { ...property, tenants };
    });
    setProperties(update);
    vouchersService.updateCommercialProperty(propertyId, { tenants });
  };

  return {
    isTenantRelocating,
    properties,
    dealProperties,
    onTenantBlur,
    onTenantChange,
    onDealPropertySelect,
    onDealPropertyAdd,
    onTenantCreate,
    onCommercialPropertyClear,
    onPropertyBlur,
    onOccupancyBlur,
    onOccupancyChange,
    onPropertyChange,
    onPropertyCreate,
    onPropertyDelete,
    onCurrencyBlur,
    onSpaceCreate,
    onSpaceChange,
    onSpaceBlur,
    onTenantDateChange,
    onInvestmentPropertyCreate,
    onInvestmentPropertyChange,
    onTenantDelete,
    onSpaceDelete,
    onInvestPropertyClear,
    onInvestorChange,
  };
}
