/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton } from '@cbrebuild/blocks';

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: props.initValue || '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const minLength = this.props.minSearchLength;
    let currentSearch = this.state.searchTerm;
    const lastSearch = prevState.searchTerm;

    if (currentSearch.length < minLength && lastSearch.length < minLength) {
      return;
    }
    if (currentSearch.length < minLength && lastSearch.length >= minLength) {
      currentSearch = '';
    }

    if (currentSearch !== lastSearch) {
      this.props.handleSearchTermChange(currentSearch, this.props.searchKey);
    }
  }

  updateSearchTerm = (e) => {
    this.setState({ searchTerm: e.target.value });
  }

  clearSearchTerm = () => {
    this.setState({ searchTerm: '' });
  }

  render() {
    const searchInputValueExists = this.state.searchTerm.length > 0;
    const searchIcon = !searchInputValueExists &&
      <Icon className={`action-icon ${this.props.disabled ? ' icon-disabled' : ''}`} iconName="magnifier" />;
    const closeIcon = searchInputValueExists && (
      <IconButton
        className={`action-icon blxs-button-icon-small ${this.props.disabled ? ' icon-disabled' : ''}`}
        iconName="close-circle"
        onClick={this.props.disabled ? undefined : this.clearSearchTerm}
        variant="basic"
      />
    );
    // if multiple words are sent it will capitalize each word
    const placeholder = this.props.searchKey && this.props.searchKey.toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    const {
      disabled,
      handleSearchFocus,
      autofocus,
      searchKeyAction,
    } = this.props;

    const {
      searchTerm,
    } = this.state;

    return (
      <div className="nd-search-input-wrapper">
        <input
          type="text"
          disabled={disabled ? 'disabled' : ''}
          autoFocus={autofocus ? 'autofocus' : ''}
          placeholder={`${searchKeyAction} ${placeholder}`}
          value={searchTerm}
          onChange={this.updateSearchTerm}
          onFocus={handleSearchFocus}
        />
        {searchIcon}
        {closeIcon}
      </div>
    );
  }
}

SearchInput.propTypes = {
  handleSearchTermChange: PropTypes.func.isRequired,
  searchKey: PropTypes.string.isRequired,
  initValue: PropTypes.string,
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  handleSearchFocus: PropTypes.func,
  minSearchLength: PropTypes.number,
  searchKeyAction: PropTypes.string,
};

SearchInput.defaultProps = {
  initValue: '',
  disabled: false,
  autofocus: false,
  handleSearchFocus: () => { },
  minSearchLength: 3,
  searchKeyAction: 'Search for',
};

export default SearchInput;
