import { fetchSearchResults, fetchDealsAndTransactionsSearchResults } from '../../services/global-search-service';
import userEventService from '../../services/user-event-service';

const loadGlobalSearchResultsSuccess = response => ({
  type: 'LOAD_GLOBAL_SEARCH_RESULTS_SUCCESS',
  payload: { response },
});

const loadGlobalSearchResultsFailure = error => ({
  type: 'LOAD_GLOBAL_SEARCH_RESULTS_FAILURE',
  payload: { searchResultsError: error },
});

const loadGlobalSearchMetadata = metadata => ({
  type: 'LOAD_GLOBAL_SEARCH_METADATA',
  payload: { metadata },
});

const isLoading = loadingSearchResults => ({
  type: 'IS_LOADING',
  payload: { loadingSearchResults },
});

const clearSearchResultsSuccess = () => ({
  type: 'CLEAR_SEARCH_RESULTS_SUCCESS',
});

// Async Action
const loadGlobalSearchResults = (search, page) => (dispatch) => {
  dispatch(isLoading(true));
  const params = {
    page_size: 25,
    page,
    search,
  };

  // User event tracking calls for successful search or null
  // search have to live here so that we can ensure they
  // fire AFTER loadGlobalSearchResultsSuccess and
  // clearSearchResultsSuccess run
  const trackSuccessfulSearch = () => {
    userEventService.trackEvent({
      eventCategory: 'Global Search',
      eventAction: 'global_search_success',
      eventLabel: search,
    });
  };

  const trackNullResults = () => {
    userEventService.trackEvent({
      eventCategory: 'Global Search',
      eventAction: 'global_search_null_results',
      eventLabel: search,
    });
  };

  fetchSearchResults(params)
    .then((data) => {
      if (params.search !== '') {
        dispatch(loadGlobalSearchResultsSuccess(data));
        // if results exists, track successful search,
        // else, track null search results

        if (data.results.length !== 0) {
          dispatch(trackSuccessfulSearch);
        } else {
          dispatch(trackNullResults);
        }
      } else {
        dispatch(clearSearchResultsSuccess());
      }
      dispatch(loadGlobalSearchMetadata(data));
    })
    .then(() => dispatch(isLoading(false)))
    .catch(error => dispatch(loadGlobalSearchResultsFailure(error)));
};

// Async Action
const loadDealsAndTransactionsGlobalSearchResults = (search, page) => (dispatch) => {
  dispatch(isLoading(true));
  const params = {
    page_size: 25,
    page,
    search,
    ordering: '-modified',
  };

  // User event tracking calls for successful search or null
  // search have to live here so that we can ensure they
  // fire AFTER loadGlobalSearchResultsSuccess and
  // clearSearchResultsSuccess run
  const trackSuccessfulSearch = () => {
    userEventService.trackEvent({
      eventCategory: 'Global Search',
      eventAction: 'global_search_success',
      eventLabel: search,
    });
  };

  const trackNullResults = () => {
    userEventService.trackEvent({
      eventCategory: 'Global Search',
      eventAction: 'global_search_null_results',
      eventLabel: search,
    });
  };

  fetchDealsAndTransactionsSearchResults(params)
    .then((data) => {
      if (params.search !== '') {
        dispatch(loadGlobalSearchResultsSuccess(data));
        // if results exists, track successful search,
        // else, track null search results

        if (data.results.length !== 0) {
          dispatch(trackSuccessfulSearch);
        } else {
          dispatch(trackNullResults);
        }
      } else {
        dispatch(clearSearchResultsSuccess());
      }
      dispatch(loadGlobalSearchMetadata(data));
    })
    .then(() => dispatch(isLoading(false)))
    .catch(error => dispatch(loadGlobalSearchResultsFailure(error)));
};


export {
  loadGlobalSearchResultsSuccess,
  loadGlobalSearchResultsFailure,
  loadGlobalSearchMetadata,
  isLoading,
  loadGlobalSearchResults,
  loadDealsAndTransactionsGlobalSearchResults,
  clearSearchResultsSuccess,
};
