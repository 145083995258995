import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@cbrebuild/blocks';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import VOUCHER_ROUTES from '../../page-templates/voucher/voucher-subroutes';
import { submitVoucher } from '../../redux/actions/voucher/voucher-actions';

const isLeaseOrSale = dealType => dealType === 'lease' || dealType === 'sale';
const getRoutes = dealType => VOUCHER_ROUTES
  .filter(route => (
    isLeaseOrSale(dealType) || route.text !== 'Lease Comp'))
  .map(route => ({
    ...route,
    routes: !route.routes
      ? route.routes
      : route.routes.filter(subRoute => (
        !subRoute.type ? true : subRoute.type === dealType
      )),
  }));

const getNextLabel = (text, dealType, isEarlyInvoice) => {
  if (text && text !== 'Lease Comp') {
    const submitText = isEarlyInvoice ? 'Send Early Invoice Request' : 'Send Voucher';
    return text === 'Confirmation' ? submitText : text;
  }
  return `${isLeaseOrSale(dealType) ? `${dealType}` : 'voucher'} ${text ? 'comp' : 'details'}`;
};

const getUrl = (path, id, clearSubtab = true) => {
  const url = path.replace(':id', id);
  return !clearSubtab ? url : url.replace(':subtab?', '');
};

export const createLinks = (dealType, location, isEarlyInvoice) => {
  let prev = { url: '', label: 'back' };
  const next = { url: '', label: '' };
  const [, , id, tab, subtab] = location.split('/');
  const routes = getRoutes(dealType);

  // eslint-disable-next-line no-labels, no-restricted-syntax
  routeLoop:
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    const [, , , routeTab] = route.path.split('/');
    if (routeTab.includes(tab)) {
      if (!route.routes) {
        if (routes[i + 1]) {
          const nextRoute = routes[i + 1];
          next.url = getUrl(nextRoute.path, id, true);
          next.label = getNextLabel(nextRoute.text, dealType, isEarlyInvoice);
          if (!i) {
            prev = null;
          } else {
            const prevRoute = routes[i - 1];
            prev.url = prevRoute.routes
              ? getUrl(prevRoute.routes[prevRoute.routes.length - 1].path, id)
              : getUrl(prevRoute.path, id, true);
          }
        } else {
          prev = null;
          next.url = `/deals/detail/${id}/overview`;
          next.label = 'complete';
        }
        break;
      }
      for (let j = 0; j < route.routes.length; j++) {
        const [, , , , routeSubTab] = route.routes[j].path.split('/');
        if (routeSubTab.includes(subtab)) {
          if (j === route.routes.length - 1) {
            const nextSubRoute = routes[i + 1];
            next.url = getUrl(nextSubRoute.path, id, true);
            next.label = getNextLabel(nextSubRoute.text, dealType, isEarlyInvoice);
          } else {
            next.url = getUrl(route.routes[j + 1].path, id);
            next.label = 'continue';
          }
          if (!j) {
            const prevRoute = routes[i - 1];
            prev.url = prevRoute.routes
              ? getUrl(prevRoute.routes[prevRoute.routes.length - 1].path, id)
              : getUrl(prevRoute.path, id, true);
          } else {
            prev.url = getUrl(route.routes[j - 1].path, id, true);
          }
          // eslint-disable-next-line no-labels
          break routeLoop;
        }
      }
    }
  }
  return ({
    prev, next,
  });
};

const BottomNav = ({
  location,
}) => {
  const {
    dealType,
    isEarlyInvoice,
    voucherId,
  } = useSelector(state => ({
    dealType: state.deal.deal?.deal_type,
    isEarlyInvoice: state.voucher?.voucher?.is_early_invoice,
    voucherId: state.voucher?.voucher?.id,
  }));
  const dispatch = useDispatch();

  const onSubmitVoucher = () => {
    dispatch(submitVoucher(voucherId));
  };

  const { prev, next } = useMemo(() => createLinks(dealType, location, isEarlyInvoice), [dealType, location]);
  const isVoucherValid = JSON.parse(localStorage.getItem('voucher_valid'));
  let curPage = location.split('/');
  curPage = curPage[curPage.length - 1];
  const disableButton = !isVoucherValid && curPage === 'review' ? 'disabled' : '';
  const submitText = isEarlyInvoice ? 'Send Early Invoice Request' : 'Send Voucher';

  return (
    <div className="actions controls">
      {prev && (
        <Link to={prev.url} className="cancel-btn">
          {prev.label}
        </Link>
      )}
      {next && (
        <div className="secondary-controls">
          <Link
            to={next.url}
            className={`
              ${next.label !== submitText ? 'next-btn' : ''}
              ${(next.label !== 'Continue' || !prev) ? ' upload-btn' : ''}
              ${disableButton}
            `}
            onClick={() => (next.label === submitText ? onSubmitVoucher() : '')}
          >
            {next.label !== submitText && (next.label !== 'Continue' || !prev) && <Icon className="next-link" iconName="arrow-right" size="x-large" />}
            {next.label}
          </Link>
          {disableButton && (
            <span className="error">Enter required fields to continue</span>
          )}
        </div>
      )}
    </div>
  );
};

BottomNav.propTypes = {
  location: PropTypes.string.isRequired,
};

export default BottomNav;
