import { connect } from 'react-redux';

import {
  addDealContact,
  updateDealContact,
  deleteDealContact,
  fetchDealContacts,
} from '../../../redux/actions/deal-contacts-actions';
import PrimaryContactPanel from '../contacts/primary-contact-panel';

const mapStateToProps = state => ({
  deal: state.deal.deal,
  primaryContact: state.dealContacts.primaryContact,
});

const mapDispatchToProps = {
  addDealContact,
  updateDealContact,
  deleteDealContact,
  fetchDealContacts,
};

const OverviewPrimaryContactPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrimaryContactPanel);

export default OverviewPrimaryContactPanelContainer;
