import superFetch from '../utils/super-fetch';

const deleteContact = (id) => {
  const url = `/mbb/contacts/${id}`;
  const options = { method: 'DELETE' };

  return superFetch(url, options)
    .then(data => data);
};

const getContacts = (params) => {
  const url = '/mbb/contacts';
  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json());
};

const patchContact = (id, update) => {
  const url = `/mbb/contacts/${id}`;
  const options = { method: 'PATCH' };
  const body = JSON.stringify(update);

  return superFetch(url, options, body)
    .then(data => data.json());
};

const postContact = (update) => {
  const url = '/mbb/contacts';
  const options = { method: 'POST' };
  const body = JSON.stringify(update);

  return superFetch(url, options, body)
    .then(data => data.json());
};

const getPersonalContacts = (params) => {
  const url = '/mbb/contacts/personal';
  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json());
};

const patchPersonalContact = (id, update) => {
  const url = `/mbb/contacts/personal/${id}`;
  const options = { method: 'PATCH' };
  const body = JSON.stringify(update);

  return superFetch(url, options, body)
    .then(data => data.json());
};

const postPersonalContact = (update) => {
  const url = '/mbb/contacts/personal';
  const options = { method: 'POST' };
  const body = JSON.stringify(update);

  return superFetch(url, options, body)
    .then(data => data.json());
};

const deletePersonalContact = (id) => {
  const url = `/mbb/contacts/personal/${id}`;
  const options = { method: 'DELETE' };

  return superFetch(url, options)
    .then(data => data);
};

const fetchContactTags = () => {
  const url = '/mbb/contacts/personal/tags';
  const options = { method: 'GET' };

  return superFetch(url, options)
    .then(data => data.json());
};


export default {
  deleteContact,
  getContacts,
  patchContact,
  postContact,
  deletePersonalContact,
  getPersonalContacts,
  patchPersonalContact,
  postPersonalContact,
  fetchContactTags,
};
