import React from 'react';
import PropTypes from 'prop-types';

import ContactListPanel from './contact-list-panel';

const ContactsTab = ({
  addDealContact,
  analyticProperties,
  contacts,
  deal,
  deleteDealContact,
  fetchDealContacts,
  isLoading,
  next,
  primaryContact,
  updateDealContact,
}) =>
  (
    <div className="contacts-tab detail-panels-layout">
      <div className="col-two-thirds">
        <ContactListPanel
          addContact={addDealContact}
          analyticProperties={analyticProperties}
          contacts={contacts}
          deal={deal}
          initQuery={{
            ordering: '-is_primary',
            page: 1,
            page_size: 25,
          }}
          isLoading={isLoading}
          isContactsTab
          next={next}
          primaryContact={primaryContact}
          onDeleteContact={deleteDealContact}
          onFetchContacts={fetchDealContacts}
          onUpdateContact={updateDealContact}
        />
      </div>
    </div>
  );

ContactsTab.propTypes = {
  analyticProperties: PropTypes.shape({
    actionPrefix: PropTypes.string,
    categoryPrefix: PropTypes.string,
  }).isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deal: PropTypes.shape({
    id: PropTypes.number,
  }),
  isLoading: PropTypes.bool.isRequired,
  next: PropTypes.number,
  primaryContact: PropTypes.shape({}),
  addDealContact: PropTypes.func.isRequired,
  deleteDealContact: PropTypes.func.isRequired,
  fetchDealContacts: PropTypes.func.isRequired,
  updateDealContact: PropTypes.func.isRequired,
};

ContactsTab.defaultProps = {
  deal: undefined,
  next: null,
  primaryContact: undefined,
};

export default ContactsTab;
