import superFetch from '../utils/super-fetch';

const getVoucherFiles = (voucherUniqueID) => {
  const url = `/mbb/open/vouchers/${voucherUniqueID}`;
  const options = {
    method: 'GET',
  };

  return superFetch(url, options)
    .then(data => data.json());
};

export default {
  getVoucherFiles,
};
