/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ExpandableComponent from '../../nucleus/expandable-component/expandable-component';
import LoadMore from '../../nucleus/load-more/load-more';
import Modal from '../../nucleus/modal/modal';
import dealsService from '../../services/deals-service';
import DealMembers from '../headers/deal-detail-header/deal-members';

const AddTeamMemberModal = (props) => {
  const {
    handleModalSubmit,
    handleModalToggle,
    showModal,
    newTeamMember,
    dealCount,
    teamId,
  } = props;

  const [dealsOpen, setDealsOpen] = useState(false);
  const [deals, setDeals] = useState([]);
  const [next, setNext] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);

  const submitWithNewTeamMember = () => {
    handleModalSubmit(newTeamMember);
  };

  const handleFetchDeals = () => {
    const queryParams = {
      page,
      page_size: 10,
      team: teamId,
    };
    setLoading(true);
    dealsService.fetchDeals(queryParams)
      .then((response) => {
        setDeals(deals.concat(response.results));
        setNext(response.next);
        setPage(page + 1);
        setLoading(false);
      })
      .catch(error => console.log('Error fetching deals: ', error));
  };

  useEffect(() => {
    handleFetchDeals();
  }, []);

  const changeDealOpen = () => {
    setDealsOpen(!dealsOpen);
  };

  const renderDealRow = (deal) => {
    const dealUsers = deal.permissions.users.map((individual) => {
      const user = {
        id: individual.id,
        avatar: individual.avatar,
        email: individual.email,
        first_name: individual.first_name,
        last_name: individual.last_name,
      };
      const member = {
        user,
      };
      return member;
    });
    const linkToDeal = () => {
      window.open(`/deals/detail/${deal.id}/overview`, '_blank');
    };
    const dealTeams = deal.permissions.teams;
    return (
      <li key={deal.id} className={`deal-list-item ${deal.deal_life_cycle_stage}`}>
        <div className="deal-list-deal-name" onClick={linkToDeal}>{deal.name}</div>
        <div className="deal-list-deal-teams">
          <DealMembers
            dealUsers={dealUsers}
            dealTeams={dealTeams}
            maxAvatarsToShow={5}
            showAddOrRemove={false}
            hideTeamMemberAvatars
          />
        </div>
      </li>);
  };

  const renderDealList = () => deals.map(deal => renderDealRow(deal));

  return (

    <Modal
      className="add-team-member-modal"
      showModal={showModal}
      handleModalToggle={handleModalToggle}
      handleModalSubmit={submitWithNewTeamMember}
      handleSecondaryButton={handleModalToggle}
      modalHeader="Add Team Member"
      primaryButtonText="Confirm"
      secondaryButtonText="Cancel"
      modalWidth={600}
    >
      <p className="modal-text">
        You are giving <span className="bold-name">{newTeamMember.full_name}</span> access
        to <span className="bold-name">{dealCount}</span> existing deal{dealCount === 1 ? '' : 's'}, and potentially exposing sensitive deal
        information.  Are you sure you want to add them to this team and share {dealCount === 1 ? 'this deal' : 'these deals'}?
      </p>

      <p className="view-deals" onClick={() => changeDealOpen()}>View Deals</p>

      <ExpandableComponent
        isOpen={dealsOpen}
        additionalClassName={dealsOpen ? 'show-border' : ''}
      >
        <div className="deal-list-content">
          {renderDealList()}
          <LoadMore
            handleLoadMore={handleFetchDeals}
            hasNextPage={next}
            isLoading={isLoading}
          />
        </div>
      </ExpandableComponent>
    </Modal>);
};

AddTeamMemberModal.propTypes = {
  teamId: PropTypes.number.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleModalToggle: PropTypes.func.isRequired,
  handleModalSubmit: PropTypes.func.isRequired,
  newTeamMember: PropTypes.shape().isRequired,
  dealCount: PropTypes.number.isRequired,
};

export default AddTeamMemberModal;
