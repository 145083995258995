import React from 'react';
import PropTypes from 'prop-types';
import unionBy from 'lodash/unionBy';
import ContactListPanel from '../../components/deal-details/contacts/contact-list-panel';
import contactsService from '../../services/contacts-service';
import PageHeaderDefault from '../../nucleus/header/page-header-default';
import userEventService from '../../services/user-event-service';
import { getObjectDifferences } from '../../utils/object-utilities';

class MyContactsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      isLoading: false,
      lastQuery: undefined,
      next: null,
    };
  }

  componentDidMount() {
    this.props.fetchAllContactsTags();
    document.title = 'Deal IQ | My Contacts';
  }

  addContact = (params) => {
    const { addTagToAllContactsTags, toast } = this.props;
    contactsService.postPersonalContact(params)
      .then((contact) => {
        if (contact.tags) {
          addTagToAllContactsTags(contact.tags);
        }
        this.setState({ contacts: [contact, ...this.state.contacts] });
      }).catch(() => {
        toast('Error creating contact');
      });
  };

  deleteContact = (id) => {
    contactsService.deletePersonalContact(id)
      .then(() => {
        this.setState({
          contacts: this.state.contacts
            .filter(contact => contact.id !== id),
        });
        this.props.toast('Contact deleted');
      }).catch(() => {
        this.props.toast('Error deleting contact');
      });
  };

  fetchContacts = (params) => {
    this.setState({ isLoading: true });
    const {
      count,
      lastQuery,
    } = this.state;
    contactsService.getPersonalContacts(params)
      .then((res) => {
        this.setState({
          count: res.count,
          contacts: res.previous
            ? unionBy(this.state.contacts, res.results, 'id')
            : res.results,
          isLoading: false,
          lastQuery: params,
          next: res.next,
        });
        if (lastQuery) {
          if (lastQuery.ordering !== params.ordering) {
            this.trackSortChange(params.ordering, lastQuery.ordering);
          } else if (lastQuery.search !== params.search) {
            this.trackSearchChange(params.ordering, lastQuery.ordering, res.count);
          } else {
            this.trackFilterChange(params, lastQuery, res.count, count);
          }
        }
      }).catch(() => {
        this.setState({ isLoading: false });
        this.props.toast('Error fetching contacts');
      });
  };

  trackFilterChange = (nextQuery, previousQuery, nextCount, previousCount) => {
    const filterDiff = getObjectDifferences(previousQuery, nextQuery);
    const eventAction = `my_contacts_filter_${nextCount > 0 ? 'success' : 'null_results'}`;
    userEventService.trackEvent({
      eventCategory: 'My Contacts Filter',
      eventLabel: 'on_filter',
      eventAction,
      eventData: {
        filter_diff: filterDiff,
        before_results: previousCount,
        after_results: nextCount,
      },
    });
  };

  trackSearchChange = (searchTerm, query, count) => {
    const eventAction = `my_contacts_search_${count > 0 ? 'success' : 'null_results'}`;
    userEventService.trackEvent({
      eventCategory: 'My Contacts Search',
      eventAction,
      eventLabel: searchTerm,
      eventData: {
        search_term: searchTerm,
        query: { ...query },
      },
    });
  };

  trackSortChange = (nextOrder, previousOrder) => {
    userEventService.trackEvent({
      eventCategory: 'Sort',
      eventAction: 'my_contacts_sort_change',
      eventLabel: nextOrder,
      eventData: {
        from: previousOrder,
        to: nextOrder,
      },
    });
  };

  updateContact = (id, params, showingHidden = false) => {
    const { addTagToAllContactsTags, toast } = this.props;
    contactsService.patchPersonalContact(id, params)
      .then((updatedContact) => {
        // this logic decides whether to update or remove, if same update else remove
        const contacts = updatedContact.hidden === showingHidden
          ? this.state.contacts
            .map(contact => (contact.id === id ? updatedContact : contact))
          : this.state.contacts
            .filter(contact => contact.id !== updatedContact.id);
        this.setState({
          contacts,
        });
        addTagToAllContactsTags(updatedContact.tags);
      }).catch(() => {
        toast('Error updating contact');
      });
  };

  render() {
    const {
      contacts,
      isLoading,
      next,
    } = this.state;
    const {
      allContactTags,
      addTagToAllContactsTags,
      userOptions,
    } = this.props;
    return (
      <div className="my-contacts-page">
        <PageHeaderDefault
          title="My Contacts"
        />
        <div className="page-container">
          <ContactListPanel
            addContact={this.addContact}
            addTagToAllContactsTags={addTagToAllContactsTags}
            allContactTags={allContactTags}
            analyticProperties={this.props.analyticProperties}
            contacts={contacts}
            cachedQueryKey="personal-contact-sort-filter"
            initQuery={{
              ordering: 'given_name',
              page: 1,
              page_size: 25,
              search: '',
              hidden: false,
              tags: [],
            }}
            isLoading={isLoading}
            next={next}
            onDeleteContact={this.deleteContact}
            onFetchContacts={this.fetchContacts}
            onUpdateContact={this.updateContact}
            outlookSyncEnabled={userOptions.outlook_sync_enabled}
          />
        </div>
      </div>
    );
  }
}

MyContactsPage.propTypes = {
  allContactTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  analyticProperties: PropTypes.shape({
    actionPrefix: PropTypes.string,
    categoryPrefix: PropTypes.string,
  }).isRequired,
  addTagToAllContactsTags: PropTypes.func.isRequired,
  fetchAllContactsTags: PropTypes.func.isRequired,
  toast: PropTypes.func.isRequired,
  userOptions: PropTypes.shape({
    outlook_sync_enabled: PropTypes.bool,
  }),
};

MyContactsPage.defaultProps = {
  userOptions: null,
};

export default MyContactsPage;
