import superFetch from '../utils/super-fetch';

const addNote = (params) => {
  const url = '/mbb/notes';
  const options = {
    method: 'POST',
  };
  const body = {
    body: params.body,
    privacy: params.privacy,
    deal: params.deal,
  };
  return superFetch(url, options, JSON.stringify(body))
    .then(data => data.json());
};

const deleteNote = (noteId) => {
  const url = `/mbb/notes/${noteId}`;
  const options = {
    method: 'DELETE',
  };
  return superFetch(url, options)
    .then(data => data);
};

const fetchNotes = (params) => {
  const url = '/mbb/notes';
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const updateNote = (id, params) => {
  const url = `/mbb/notes/${id}`;
  const options = {
    method: 'PATCH',
  };
  return superFetch(url, options, JSON.stringify(params))
    .then(data => data.json());
};

export {
  addNote,
  deleteNote,
  fetchNotes,
  updateNote,
};
