import unionBy from 'lodash/unionBy';

import {
  ADD_DEAL_NOTE_SUCCESS,
  ADD_DEAL_NOTE_FAILURE,
  DELETE_DEAL_NOTE_SUCCESS,
  DELETE_DEAL_NOTE_FAILURE,
  IS_LOADING_NOTES_ACTION,
  FETCH_DEAL_NOTES_SUCCESS,
  FETCH_DEAL_NOTES_FAILURE,
  UPDATE_DEAL_NOTE_SUCCESS,
  UPDATE_DEAL_NOTE_FAILURE,
  CLEAR_DEAL_STORE,
  NEW_NOTE_CLEANUP,
} from '../actions/action-types';

const initialState = {
  count: 0,
  isLoading: false,
  newNote: undefined,
  next: null,
  notes: [],
  previous: null,
  error: undefined,
};

const addDealNoteSuccess = (state, { payload: newNote }) => ({
  ...state,
  newNote,
});

const clearStore = () => ({
  ...initialState,
});

const deleteDealNoteSuccess = (state, { payload: { id } }) => ({
  ...state,
  count: state.count - 1,
  newNote: undefined,
  notes: state.notes.filter(note => note.id !== id),
});

const fetchDealNotesSuccess = (state, action) => {
  const {
    count,
    next,
    previous,
    results,
  } = action.payload;
  const notes = previous === null
    ? results
    : unionBy(state.notes, results, 'id');
  return ({
    ...state,
    count,
    next,
    notes,
    previous,
  });
};

const updateDealNoteSuccess = (state, { payload: note }) => {
  const found = state.notes.find(n => n.id === note.id);
  return ({
    ...state,
    ...!found
      ? { newNote: note }
      : { notes: state.notes.map(n => (n.id === note.id ? note : n)) },
  });
};

const newNoteCleanup = state => (!state.newNote ? state : ({
  ...state,
  newNote: undefined,
  count: state.count + 1,
  notes: [state.newNote, ...state.notes],
}));

const isLoading = (state, action) => ({
  ...state,
  isLoading: action.payload.isLoading,
});

const dealNotesFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const dealNotes = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEAL_NOTE_SUCCESS:
      return addDealNoteSuccess(state, action);
    case CLEAR_DEAL_STORE:
      return clearStore();
    case DELETE_DEAL_NOTE_SUCCESS:
      return deleteDealNoteSuccess(state, action);
    case FETCH_DEAL_NOTES_SUCCESS:
      return fetchDealNotesSuccess(state, action);
    case UPDATE_DEAL_NOTE_SUCCESS:
      return updateDealNoteSuccess(state, action);
    case IS_LOADING_NOTES_ACTION:
      return isLoading(state, action);
    case NEW_NOTE_CLEANUP:
      return newNoteCleanup(state, action);
    case ADD_DEAL_NOTE_FAILURE:
    case DELETE_DEAL_NOTE_FAILURE:
    case UPDATE_DEAL_NOTE_FAILURE:
    case FETCH_DEAL_NOTES_FAILURE:
      return dealNotesFailure(state, action);
    default:
      return state;
  }
};

export default dealNotes;
