import {
  FETCH_COMP_DATA_SUCCESS,
  UPDATE_COMP_DATA_SUCCESS,
  CREATE_COMP_DATA_SUCCESS,
} from '../../actions/action-types';

const initialState = {
  compData: {},
};

const compData = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMP_DATA_SUCCESS:
    case UPDATE_COMP_DATA_SUCCESS:
    case CREATE_COMP_DATA_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default compData;
