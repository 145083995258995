import { connect } from 'react-redux';
import { toast } from '../../redux/actions/toasts-actions';
import TasksPage from './tasks-page';

const mapStateToProps = state => ({
  user: state.userData.data,
  proxy: state.proxy.proxySettings,
});

const mapDispatchToProps = { toast };

const TasksPageContainer = connect(mapStateToProps, mapDispatchToProps)(TasksPage);

export default TasksPageContainer;
