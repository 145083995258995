import { connect } from 'react-redux';
import CreateTeamModal from './create-team-modal';

const mapStateToProps = state => ({
  userData: state.userData.data && state.userData.data.id ? state.userData.data : null,
});

const CreateTeamModalContainer = connect(mapStateToProps)(CreateTeamModal);

export default CreateTeamModalContainer;
