import ReactDOM from 'react-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@cbrebuild/blocks';
import uniqueId from 'lodash/uniqueId';
import LoadingBar from '../loading-bar/loading-bar';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.createModalRootWithId = Object.assign(document.createElement('div'), {
      id: `modal-root-${uniqueId()}`,
    });
    this.appendCreateModalRoot = document.body.appendChild(this.createModalRootWithId);
  }

  componentDidMount() {
    document.body.appendChild(this.createModalRootWithId);
    if (this.props.showModal) {
      this.shouldComponentUpdate(this.props);
    }
  }

  // as the modal gets toggled, we should apply the overlay and click
  // handlers accordingly. if showModal === true, apply overlay class
  // if showModal === false, remove overlay class
  shouldComponentUpdate(nextProps) {
    if (nextProps.showModal) {
      setTimeout(() => {
        document.addEventListener('mousedown', this.handleClickOutside);
      }, 100);
      this.createModalRootWithId.setAttribute('class', 'modal-active modal-backdrop-overlay react');
      // prevent scroll on body
      document.body.classList.add('noscroll');
      this.setScrollBorder();
    } else {
      document.removeEventListener('mousedown', this.handleClickOutside);
      this.createModalRootWithId.removeAttribute('class', 'modal-active modal-backdrop-overlay react');
      // enable scoll on body
      document.body.classList.remove('noscroll');
    }
    return true;
  }

  componentWillUnmount() {
    document.body.removeChild(this.createModalRootWithId);
  }

  setWrapperReference = (ele) => {
    this.wrapperReference = ele;
  }

  setScrollBorder = () => {
    // setScrollBorder requires 0 timeout to wait until the dom elements are ready
    setTimeout(() => {
      const content = document.getElementsByClassName('modal-content')[0];
      const controls = document.getElementsByClassName('modal-controls')[0];
      if (content) {
        const {
          scrollHeight,
          clientHeight,
        } = content;

        if (controls && scrollHeight > clientHeight) {
          controls.classList.add('scroll-border');
        }
      }
    }, 0);
  }

  handleClickOutside = (event) => {
    if (this.wrapperReference && !this.wrapperReference.contains(event.target)) {
      this.props.handleModalToggle();
    }
  }

  displayModalControls = () => {
    const {
      additionalControlName,
      additionalControlClassName,
      handleAdditionalControl,
      handleModalSubmit,
      handleSecondaryButton,
      primaryButtonText,
      secondaryButtonText,
      submitButtonDisabled,
      hideModalControls,
    } = this.props;

    // addtionalControlName is a prop that can be used for modals that perform
    // more actions than the standard "Save" or "Cancel". a good example of
    // an additional control is a "Delete". you can also pass additionalControlClassname
    // to customize the style/layout of your control
    const renderAdditionalControl = additionalControlName && additionalControlClassName ? (
      <Button
        className={additionalControlClassName}
        variant="secondary"
        onClick={handleAdditionalControl}
      >
        {additionalControlName}
      </Button>
    ) : '';

    return hideModalControls ? '' : (
      <div className="modal-controls">
        {renderAdditionalControl}
        {!!secondaryButtonText && (
          <Button
            className="secondary-button"
            variant="secondary"
            data-e2e="modal-secondary-no-button"
            onClick={handleSecondaryButton}
          >
            {secondaryButtonText}
          </Button>
        )}
        <Button
          className="primay-button"
          data-e2e="modal-primary-yes-button"
          disabled={submitButtonDisabled}
          onClick={handleModalSubmit}
        >
          {primaryButtonText}
        </Button>
      </div>
    );
  }

  render() {
    const {
      handleModalToggle,
      modalHeader,
      showModal,
      modalWidth,
    } = this.props;

    const renderHeader = modalHeader &&
      <div className="modal-header">
        <h2>{modalHeader}</h2>
        <IconButton
          className="blxs-button-icon-small"
          iconName="close"
          onClick={handleModalToggle}
          aria-label="close"
          variant="basic"
        />
        <LoadingBar isLoading={this.props.isLoading} />
      </div>;

    const modalStyle = {
      width: `${modalWidth}px`,
    };

    const renderModal = showModal ? (
      <div
        role="dialog"
        className={`nd-modal ${this.props.className}`}
        ref={this.setWrapperReference}
        style={modalStyle}
      >
        {renderHeader}
        <div className="modal-content">
          {this.props.children}
        </div>
        {this.displayModalControls()}
      </div>
    ) : '';

    return (
      ReactDOM.createPortal(
        renderModal,
        this.appendCreateModalRoot,
      )
    );
  }
}

Modal.propTypes = {
  additionalControlName: PropTypes.string,
  additionalControlClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  handleAdditionalControl: PropTypes.func,
  handleModalSubmit: PropTypes.func,
  handleSecondaryButton: PropTypes.func,
  handleModalToggle: PropTypes.func,
  isLoading: PropTypes.bool,
  modalHeader: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  primaryButtonText: PropTypes.string,
  showModal: PropTypes.bool,
  secondaryButtonText: PropTypes.string,
  submitButtonDisabled: PropTypes.bool,
  hideModalControls: PropTypes.bool, // work around until we redesign modal API V2
  modalWidth: PropTypes.number,
};

Modal.defaultProps = {
  additionalControlName: '',
  additionalControlClassName: '',
  className: '',
  handleAdditionalControl: () => { },
  handleModalSubmit: () => { },
  handleSecondaryButton: () => { },
  handleModalToggle: () => { },
  isLoading: undefined,
  modalHeader: '',
  primaryButtonText: '',
  secondaryButtonText: '',
  showModal: false,
  submitButtonDisabled: false,
  hideModalControls: false, // work around until we redesign modal API V2
  modalWidth: 600,
};

export default Modal;

