import React from 'react';
import PropTypes from 'prop-types';

import NDButton from '../button/button';

const Option = (props) => {
  const {
    onClick,
    isActive,
    isHighlighted,
    option,
  } = props;

  const isSelected = isActive ? ' selected' : '';
  const isHighlight = isHighlighted ? ' highlighted' : '';
  const optionItem =
    (
      <li className="nd-option-item">
        <NDButton
          className={`option-button${isHighlight}${isSelected}`}
          variant="option"
          onClick={() => onClick(option)}
          selected={isActive}
          disabled={option.disabled}
          dataE2e={option.dataE2e}
          width="full"
          icon={isSelected && 'checkmark'}
          iconPosition="right"
        >
          {option.display_name}
        </NDButton>
      </li>
    );

  return optionItem;
};

Option.propTypes = {
  isActive: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  option: PropTypes.shape({
    display_name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  dataE2e: PropTypes.string,
};

Option.defaultProps = {
  isActive: false,
  isHighlighted: false,
  dataE2e: '',
};

export default Option;
