import React from 'react';
import PropTypes from 'prop-types';
import RowItem from '../../nucleus/row-item/row-item';

class ResultList extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    renderListItem: PropTypes.func.isRequired,
    searchTerm: PropTypes.string,
    renderFooterContent: PropTypes.func,
    onReturn: PropTypes.func.isRequired,
  }

  static defaultProps = {
    searchTerm: '',
    renderFooterContent: undefined,
  }

  constructor(props) {
    super(props);
    this.state = {
      list: null,
      selectedIndex: null,
    };
  }

  /**
   * When a result list mounts a listener is added to track keyboard interactions
   * this lets the user arrow up and down thru the list and press tab or enter to select one.
   */
  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
  }
  /**
   * When a result list unmounts, it stops listening so we don't get multiple listeners happening and
   * also create weird side affects on the rest of the page after the list has been closed
   */
  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      this.onTabEnterPress();
    } else if (e.keyCode === 38) {
      e.preventDefault(); // prevent cursor from moving to beginning of input
      this.onUpArrowPress();
    } else if (e.keyCode === 40) {
      this.onDownArrowPress();
    }
  }

  /**
   * On up arrow press selectedIndex should decrement up the list or start at the bottom if null,
   * should wrap around the results.length and take in to account if the footerContent row exists
   */
  onUpArrowPress = () => {
    const {
      selectedIndex,
      list,
    } = this.state;
    const {
      renderFooterContent,
      results,
    } = this.props;
    const length = results.length + (renderFooterContent ? 0 : -1);
    let newIndex = selectedIndex;
    if (selectedIndex && selectedIndex !== 0) {
      newIndex = selectedIndex - 1;
      this.setState({ selectedIndex: newIndex });
    } else {
      newIndex = length;
      this.setState({ selectedIndex: newIndex });
    }
    if (list.children[selectedIndex]) {
      const element = list.children[selectedIndex].children[0];
      const parentElement = element.offsetParent;
      if (parentElement) {
        parentElement.scrollTop = element.offsetTop - element.offsetHeight;
      }
    }
  }
  /**
   * On down arrow press selectedIndex should increment dow the list or start at the top if null,
   * should wrap around the results.length and take in to account if the footerContent row exists
   */
  onDownArrowPress = () => {
    const {
      selectedIndex,
      list,
    } = this.state;
    const {
      renderFooterContent,
      results,
    } = this.props;
    const length = results.length + (renderFooterContent ? 0 : -1);
    let newIndex = selectedIndex;
    if (selectedIndex !== null && selectedIndex < length) {
      newIndex = selectedIndex + 1;
      this.setState({ selectedIndex: newIndex });
    } else {
      newIndex = 0;
      this.setState({ selectedIndex: newIndex });
    }
    if (list.children[selectedIndex]) {
      const element = list.children[selectedIndex].children[0];
      const parentElement = element.offsetParent;
      if (parentElement) {
        parentElement.scrollTop = element.offsetTop + element.offsetHeight;
      }
    }
  }

  /**
   * On tab or enter, and there is a list and and a selectedIndex
   * we find the selectedIndex for the child in the list
   * then when select the first child of that div so we are clicking on the RowItem
   * which then will act like we are actually mouse clicking on the item.
   */
  onTabEnterPress = () => {
    const {
      selectedIndex,
      list,
    } = this.state;
    if (selectedIndex !== null && list) {
      const element = list.children[selectedIndex].children[0];
      element.click();
    } else {
      this.props.onReturn();
    }
  }

  setListRef = (node) => {
    this.setState({ list: node });
  }

  renderResults = () => {
    const {
      results,
      onSelect,
      renderListItem,
    } = this.props;
    const {
      selectedIndex,
    } = this.state;
    return results.map((result, index) => (
      <div
        key={result.id || index}
        className={index === selectedIndex ? 'highlighted' : ''}
      >
        <RowItem onClick={() => onSelect(result)}>
          {renderListItem(result)}
        </RowItem>
      </div>
    ));
  }

  renderFooter = () => {
    const {
      onSelect,
      renderFooterContent,
      results,
      searchTerm,
    } = this.props;
    const {
      selectedIndex,
    } = this.state;

    if (renderFooterContent && searchTerm.length) {
      return (
        <footer className={results.length === selectedIndex ? 'highlighted' : ''}>
          {renderFooterContent(onSelect)}
        </footer>
      );
    }
    return null;
  }

  render() {
    const list = this.renderResults();
    const footer = this.renderFooter();

    return (
      <div className="result-list" ref={this.setListRef}>
        {list}
        {footer}
      </div>
    );
  }
}

export default ResultList;
