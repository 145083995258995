import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../nucleus/modal/modal';

const DeleteDealModal = (props) => {
  const modal = (
    <Modal
      showModal={props.showModal}
      handleModalToggle={props.handleModalToggle}
      handleModalSubmit={props.handleModalSubmit}
      handleSecondaryButton={props.handleModalToggle}
      modalHeader="Delete Deal"
      primaryButtonText="Delete"
      secondaryButtonText="Cancel"
      modalWidth={400}
    >
      <div className="delete-deal-modal-content">
        <p>
          Deleting this deal will permanently remove it from your account. \
          Alternatively, you can &#39;Archive&#39; the deal and it will be removed from your view, but easily accessible if needed later.
        </p>
      </div>
    </Modal>);

  return modal;
};

DeleteDealModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleModalToggle: PropTypes.func.isRequired,
  handleModalSubmit: PropTypes.func.isRequired,
};

export default DeleteDealModal;
