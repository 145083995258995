import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';

const FilterTag = (props) => {
  const renderTag = () => {
    const leftIcon = props.leftIcon
      ? <IconButton className="blxs-button-icon-small" iconName={props.leftIcon} onClick={props.handleCheckFilter} variant="basic" />
      : '';

    // checkmark only comes from Admin labs page, which would not affect min/max value
    // so we keep those intact
    if (props.label && props.value) {
      return (
        <div>
          {leftIcon}
          <span>{props.label}: {props.value}</span>
        </div>
      );
    } else if (props.value) {
      return (
        <div>{leftIcon}<span>{props.value}</span></div>
      );
    } else if (props.minValue && props.maxValue) {
      return <span>{`${props.label}: ${props.minValue} - ${props.maxValue}`}</span>;
    } else if (props.minValue) {
      return <span>{`${props.label}: > ${props.minValue}`}</span>;
    } else if (props.maxValue) {
      return <span>{`${props.label}: < ${props.maxValue}`}</span>;
    }
    return '';
  };

  const onTagClick = (props.onTagClick && !props.disabled) ? () => {
    props.onTagClick(props.value);
  } : undefined;

  // this is to accomodate a style of filter
  // tag that is used in the react version of
  // the deal card. this allows us to mimic
  // the filter tag in the angular version of
  // the deal card by updating its UI to match
  const onClickAction = props.disabled ? null : (e) => {
    e.stopPropagation();
    props.handleClearFilter();
  };
  const renderCTA = icon => (
    <IconButton iconName={icon} className="icon-wrapper blxs-button-icon-small" onClick={onClickAction} variant="basic" />
  );

  return (
    <div className={props.isButton ? 'filter-tag' : 'filter-tag-override'} onClick={onTagClick}>
      <div className={`tag${props.disabled ? ' disabled' : ''}`}>
        {renderTag()}
        {renderCTA(props.rightIcon)}
      </div>
    </div>
  );
};

FilterTag.propTypes = {
  disabled: PropTypes.bool,
  isButton: PropTypes.bool,
  handleClearFilter: PropTypes.func.isRequired,
  label: PropTypes.string,
  minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onTagClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  handleCheckFilter: PropTypes.func,
};

FilterTag.defaultProps = {
  disabled: false,
  isButton: true,
  label: '',
  minValue: '',
  maxValue: '',
  onTagClick: undefined,
  value: '',
  leftIcon: null,
  rightIcon: 'close',
  handleCheckFilter: () => { },
};

export default FilterTag;
