import React from 'react';
import { useSelector } from 'react-redux';
import useVoucherValidation from '../useVoucherValidation';
import Installments from './Installments';

const PAGE_NUMBER = 3.4;

const InstallmentsTab = () => {
  const { id, voucherId } = useSelector(state => ({
    id: state.deal.deal?.id,
    voucherId: state.voucher.voucher?.id,
  }));
  const { showPageValidation } = useVoucherValidation(voucherId, PAGE_NUMBER);
  return (
    <React.Fragment>
      <Installments dealId={id} voucherId={voucherId} showValidation={showPageValidation} />
    </React.Fragment>
  );
};

export default InstallmentsTab;
