/* global CONFIG */
/* eslint-env browser */
import superFetch from '../utils/super-fetch';
import authService from '../services/auth-service';

let userCache;

const {
  apiUrl,
} = CONFIG;
const userUrl = `${apiUrl}/user`;

const handleTermsOfAgreement = (user) => {
  if (!user) { return; }
  const needsToSignAgreementPage = user.signed_broker_user_agreement === false;
  const isNotOnAgreementPage = window.location.href.indexOf('agreement') === -1;

  if (needsToSignAgreementPage && isNotOnAgreementPage) {
    const currentPageUrl = window.location.href;
    // store current page for purpose of redirecting after accepting terms
    window.localStorage.setItem('termsOfAgreementRedirect', currentPageUrl);
    window.location.href = '/agreement';
  }
};

const fetchUser = () => {
  const options = {
    method: 'GET',
  };

  return superFetch(userUrl, options)
    .then(res => res.json())
    .then((user) => {
      userCache = { ...user };
      handleTermsOfAgreement(user);
      return user;
    });
};

const updateUser = (params) => {
  const options = {
    method: 'PATCH',
  };
  return superFetch(userUrl, options, JSON.stringify(params))
    .then(data => data.json());
};

const uploadFile = (key, file) => {
  const formData = new FormData();

  formData.append(key, file, file.name);

  const defaultHeaders = {
    Authorization: `JWT ${authService.getToken()}`,
  };

  return fetch(userUrl, {
    method: 'PATCH',
    headers: defaultHeaders,
    body: formData,
  }).then(data => data.json());
};

const getUser = () => (userCache ? Promise.resolve(userCache) : fetchUser());

export default {
  getUser, updateUser, fetchUser, uploadFile,
};
