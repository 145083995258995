const getCounterPartyLabel = ({ rep_role }) => {
  switch (rep_role) {
    case 'tenant':
      return 'landlord';
    case 'landlord':
      return 'tenant';
    case 'buyer':
      return 'seller';
    case 'seller':
      return 'buyer';
    default:
      return '';
  }
};

export default getCounterPartyLabel;
