import React from 'react';
import PropTypes from 'prop-types';

import voucherFileService from '../../services/voucher-files-service';

import PageHeaderDefault from '../../nucleus/header/page-header-default';
import FileDownloadList from '../../components/file-download/file-download-list';
import TopNavPublic from '../../components/navs/top-nav-public';

class VoucherFilesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dealName: '',
      clientName: '',
      files: [],
    };
  }

  componentDidMount() {
    document.title = 'Deal IQ | Voucher File Download';

    this.props.setShowGlobalNavigation(false);
    // hide global navigation, because this page will be accessed
    //  by people who are not Deal IQ users

    const voucherUniqueID = this.props.match.params.id;

    voucherFileService.getVoucherFiles(voucherUniqueID)
      .then((res) => {
        if (res.detail === 'Not found.') {
          return;
        }

        this.setState({
          dealName: res.deal_name,
          clientName: res.client_name,
          files: res.voucher_supporting_documents,
        });
      })
      .catch(error => console.error('Error getting voucher files: ', error));
  }

  componentWillUnmount() {
    this.props.setShowGlobalNavigation(true);
  }

  render() {
    return (
      <React.Fragment>
        <TopNavPublic />
        <PageHeaderDefault
          title={this.state.dealName}
          subtitle={this.state.clientName}
        />
        <FileDownloadList
          files={this.state.files}
          uniqueID={this.props.match.params.id}
        />
      </React.Fragment>
    );
  }
}

VoucherFilesPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  setShowGlobalNavigation: PropTypes.func.isRequired,
};

export default VoucherFilesPage;
