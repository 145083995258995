import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { RadioButton, RadioGroup } from '@cbrebuild/blocks';
import { useDispatch, useSelector } from 'react-redux';
import proxyService from '../../services/proxy-service';
import RowItem from '../../nucleus/row-item/row-item';
import { Person, PersonType } from '../../components/person';
import EmptyState from '../../components/empty-state/empty-state';
import Notice from '../../nucleus/notices/notice';
import { setProxyAccess } from '../../redux/actions/proxy-settings-actions';
// import DealIQLogo from '../../assets/images/svg/deal-iq-logo-only.svg';
import DealIQLogo from '../../assets/images/svg/loading-icon.svg';
import { loadDealAndTransactionCount } from '../../redux/actions/deals-actions';

const ProxyAccess = ({
  userData,
}) => {
  const [showProxySwitchErrorToast, setShowProxySwitchErrorToast] = useState(false);
  const [proxyAccessList, setProxyAccessList] = useState([]);
  const { proxy, userOptions } = useSelector(state => ({
    proxy: state.proxy,
    userOptions: state.userOptions,
  }));
  const [selectedProxyAccess, setSelectedProxyAccess] = useState(isEmpty(proxy.proxySettings) ? userData.id : proxy.proxySettings.id);
  const [renderProxyPage, setRenderProxyPage] = useState(false);
  const [isLoadingAccess, setIsLoadingAccess] = useState(false);

  const dispatch = useDispatch();

  const proxySwitchErrorMessage = "Your proxy profile access couldn't be set! Do you still have this access?";
  const proxyProfilesActivateText = 'You currently have proxy access to the following user profiles, click on one to activate it:';

  const setProxySelection = (proxySelection) => {
    setShowProxySwitchErrorToast(false);
    setSelectedProxyAccess(proxySelection);
    let proxyObjectSet;

    proxyObjectSet = proxyAccessList.find(proxyAccessElement => proxyAccessElement.user.id === parseInt(proxySelection, 10));
    if (!proxyObjectSet) {
      proxyObjectSet = { user: { id: userData.id } };
    }
    proxyService.switchProxyUser(proxyObjectSet.user.id).then(() => {
      dispatch(setProxyAccess(proxyObjectSet.user.id === userData.id ? {} : proxyObjectSet.user));
      dispatch(loadDealAndTransactionCount());
    }).catch(() => {
      dispatch(setProxyAccess({}));
      setShowProxySwitchErrorToast(true);
    });
  };

  const getProxyUsers = useCallback(async () => {
    await proxyService.fetchProxyUser()
      .then((res) => {
        if (!!res && !!res.privilege_access_users) {
          setProxyAccessList(res.privilege_access_users);
          setIsLoadingAccess(false);
        }
      });
  }, []);

  useEffect(() => {
    setIsLoadingAccess(true);
    getProxyUsers();
  }, [getProxyUsers, userData]);

  useEffect(() => {
    setSelectedProxyAccess(isEmpty(proxy.proxySettings) ? userData.id : proxy.proxySettings.id);
  }, [proxy.proxySettings, userData.id]);

  useEffect(() => {
    if (!!userOptions && !!userOptions.userOptions && !!userOptions.userOptions.feature_flags) {
      setRenderProxyPage(userOptions.userOptions.feature_flags.proxy_user_flag);
    }
  }, [userOptions]);

  const renderNoProxyAccess = () => (isEmpty(proxyAccessList) && (
    <div className="empty-state-container">
      <EmptyState
        type="proxy access"
        message="You can ask a user to grant you proxy access to their profile and it will appear here."
      />
    </div>
  ));

  const renderSwitchErrorToast = showProxySwitchErrorToast ?
    (<Notice
      text={proxySwitchErrorMessage}
      type="critical"
      onDismiss={() => setShowProxySwitchErrorToast(false)}
    />)
    : '';

  const renderProxyAccess = () => (
    <div className="deal-card proxy-access-card">
      <div className="proxy-access-profiles">
        {proxyProfilesActivateText}
      </div>
      <RadioGroup
        name="proxy-value-selection"
        selectedValue={selectedProxyAccess}
        onChange={e => setProxySelection(e.target.value)}
      >
        <RadioButton value={userData.id} className="no-proxy-class proxy-access-profile-containter">
          <RowItem key="noproxykeyitem">
            <Person
              person={{ given_name: 'No Profile', email: 'Use your default access' }}
              isSelf
              commissioned
              type={PersonType.MEMBER}
              hoverAlign="left"
            />
          </RowItem>
        </RadioButton>
        {proxyAccessList.map(access => (
          <RadioButton className="proxy-access-profile-containter" value={access.user.id} key={`proxy_access_${access.user.id}`}>
            <RowItem>
              <Person
                person={access.user}
                type={PersonType.MEMBER}
                hoverAlign="left"
              />
            </RowItem>
          </RadioButton>
    ))}
      </RadioGroup>
      {renderSwitchErrorToast}
    </div>
  );

  const renderLoadingSpinner = () => (
    <div className="spinner-container">
      <div className="loading-spinner">
        <img
          alt="Loading"
          src={DealIQLogo}
        />
      </div>
    </div>
  );

  const proxyAccesSection = () => {
    if (isLoadingAccess) {
      return renderLoadingSpinner();
    } else if (!isEmpty(proxyAccessList)) {
      return renderProxyAccess();
    }
    return renderNoProxyAccess();
  };

  return (
    <div className="proxy-page">
      {renderProxyPage && (
      <div className="page-container">
        <div className="card-default">
          <h2 className="title">Proxy Access</h2>
          { proxyAccesSection() }
        </div>
      </div>)
      }
    </div>
  );
};

ProxyAccess.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default ProxyAccess;
