import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import uniqueId from 'lodash/uniqueId';
import { Icon } from '@cbrebuild/blocks';

import Flyout from '../../nucleus/flyout/flyout';
import LoadingIndicator from '../../nucleus/loading-indicator/loading-indicator';
import LoadMore from '../../nucleus/load-more/load-more';
import SearchResult from './search-result';

import userEventService from '../../services/user-event-service';

import emptyStateImg from '../../assets/images/svg/empty-state.svg';

class GlobalSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      showFlyout: false,
      page: 1,
    };
    this.debounceFetchSearchResults = debounce(() => {
      if (this.state.search) {
        this.props.setDealsAndTransactionsGlobalSearchState(this.state.search, this.state.page);
      } else {
        this.props.clearSearchResultsSuccess();
      }
    }, 500);
  }

  executeSearch = (e, text) => {
    const input = ((e.target && e.target.value) || text) || '';

    this.setState({
      ...this.state,
      search: input,
    });
    // if there is no search term, then reset
    // the page number and close the flyout menu
    if (input === '') {
      this.setState({
        page: 1,
      });

      this.closeFlyout();
    }
    // if the current search term in state does
    // not match the input, reset the page number
    if (this.state.search !== input) {
      this.setState({
        page: 1,
      });
    }

    // only start searching after the first 2 characters
    if (!text) {
      if (this.state.search && this.state.search.length > 1) {
        this.debounceFetchSearchResults();
      }
    } else { // copy and paste
      this.debounceFetchSearchResults();
      this.openFlyout();
    }
  };

  handleInputChange = (e) => {
    this.executeSearch(e);
  };

  handleCopyAndPaste = (e) => {
    const s = (e.clipboardData && e.clipboardData.items && e.clipboardData.items[0]) || {};

    if (s.getAsString && typeof s.getAsString === 'function') {
      s.getAsString((text) => {
        if (text.length > 1) {
          this.executeSearch(e, text);
        }
      });
    }
  };

  handleLoadMoreButton = () => {
    this.setState({
      ...this.state,
      page: this.state.page += 1,
    }, () => this.debounceFetchSearchResults());
  };

  trackOnFocus = () => {
    userEventService.trackEvent({
      eventAction: 'global_search_focus', eventCategory: 'Global Search', eventLabel: 'onFocus',
    });
    this.openFlyout();
  };

  // this gets invoked when a user clicks out of global search,
  // the flyout will close, and api/user_events will be sent as
  // a global_search_abort event
  trackAbortSearch = () => {
    const { search } = this.state;
    userEventService.trackEvent({
      eventAction: 'global_search_aborted', eventCategory: 'Global Search', eventLabel: search,
    });
    this.closeFlyout();
  };

  // similar to trackAbortSearch, however this function is soley
  // responsible for closing the flyout, it does not include
  // any user tracking logic. we do this because closeFlyout
  // is triggered by a number of actions, and we do not want
  // to send an trackAbortSearch tracking event every time flyout
  // is closed
  closeFlyout = () => {
    this.setState({
      showFlyout: false,
    });
  };

  resetSearch = () => {
    if (!this.state.showFlyout) {
      this.setState({
        search: '',
      });

      this.debounceFetchSearchResults();
    }
  };

  openFlyout = () => {
    const { search } = this.state;
    if (search !== '') {
      this.setState({
        showFlyout: true,
      });
    }
  };

  render() {
    const {
      isLoading,
      metadata,
      searchResults,
      turducken,
    } = this.props;

    const {
      showFlyout,
    } = this.state;

    const emptyState = (
      <div className="empty-state">
        <img src={emptyStateImg} alt="No search results" />
        <p>No Results Found</p>
      </div>
    );

    const loadMore = metadata && metadata.hasNextPage ? (
      <LoadMore
        isLoading={isLoading}
        handleLoadMore={this.handleLoadMoreButton}
        hasNextPage={metadata.hasNextPage}
      />
    ) : '';

    const renderEmptyStateOrLoading = isLoading === false && !searchResults.length ? emptyState : <LoadingIndicator />;

    // if there are search results and loading has finished, render <SearchResult />,
    // otherwise render the loading indicator
    const result = searchResults.length > 0 ? searchResults.map((_result, index) => (
      <SearchResult
        index={index}
        key={uniqueId()}
        result={_result}
        turducken={turducken}
        tags={_result.tags}
        closeFlyout={() => {
          this.closeFlyout();
          this.resetSearch();
        }}
        onBlur={() => {
          this.resetSearch();
        }}
      />
    )) : renderEmptyStateOrLoading;

    return (
      <div className="global-search">
        <div className="search">
          <input
            placeholder="Search for Deals"
            type="text"
            onMouseDown={this.trackOnFocus}
            onKeyDown={this.openFlyout}
            onChange={this.handleInputChange}
            onPaste={(e) => {
              e.persist();
              this.handleCopyAndPaste(e);
            }}
            onBlur={() => {
              this.resetSearch();
            }}
            value={this.state.search}
          />
          <Icon iconName="magnifier" />
        </div>
        <Flyout
          showFlyout={showFlyout}
          closeFlyout={this.trackAbortSearch}
        >
          <div className="results">
            <ul>
              {result}
              {loadMore}
            </ul>
          </div>
        </Flyout>
      </div>
    );
  }
}

GlobalSearch.propTypes = {
  isLoading: PropTypes.bool,
  metadata: PropTypes.shape({
    hasNextPage: PropTypes.bool,
    numberOfPages: PropTypes.number,
  }),
  searchResults: PropTypes.arrayOf(PropTypes.shape()),
  setDealsAndTransactionsGlobalSearchState: PropTypes.func.isRequired,
  clearSearchResultsSuccess: PropTypes.func.isRequired,
  turducken: PropTypes.bool.isRequired,
};

GlobalSearch.defaultProps = {
  isLoading: false,
  metadata: {},
  searchResults: [],
};

export default GlobalSearch;
