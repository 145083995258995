import * as compDataService from '../../../services/voucher/comp-data-service';
import {
  FETCH_COMP_DATA_SUCCESS,
  UPDATE_COMP_DATA_SUCCESS,
  CREATE_COMP_DATA_SUCCESS,
} from './../action-types';

// action creators
const fetchCompDataSuccess = compData => ({
  type: FETCH_COMP_DATA_SUCCESS,
  payload: compData,
});

const updateCompDataSuccess = compData => ({
  type: UPDATE_COMP_DATA_SUCCESS,
  payload: compData,
});

const createCompDataSuccess = compData => ({
  type: CREATE_COMP_DATA_SUCCESS,
  payload: compData,
});

// async action creators
const fetchCompData = params => dispatch =>
  compDataService.fetchCompData(params)
    .then(compData => dispatch(fetchCompDataSuccess(compData)))
    .catch(console.error);

const updateCompData = (id, params) => dispatch =>
  compDataService.updateCompData(id, params)
    .then(compData => dispatch(updateCompDataSuccess(compData)))
    .catch(console.error);

const createCompData = params => dispatch =>
  compDataService.createCompData(params)
    .then(compData => dispatch(createCompDataSuccess(compData)))
    .catch(console.error);

export {
  fetchCompData,
  updateCompData,
  createCompData,
};
