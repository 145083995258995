import superFetch from '../utils/super-fetch';

const fetchTeamOptions = () => {
  // fetch team options
  const url = '/teams';
  const options = {
    method: 'OPTIONS',
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const fetchTeams = (params) => {
  // fetch all teams
  const url = '/teams';
  const updateParams = { ...params, page_size: 100 };
  const options = {
    method: 'GET',
    params: updateParams,
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const fetchTeamByID = async (id, isAdmin = false) => {
  // fetch a single team based on id
  const url = `/teams/${id}`;
  const adminURL = `/admin/teams/${id}`;
  const options = {
    method: 'GET',
  };
  const data = await superFetch(isAdmin ? adminURL : url, options);
  return data.json();
};


const addTeam = (params) => {
  const url = '/teams';
  const options = {
    method: 'POST',
  };
  const body = {
    name: params.teamName,
    users: params.teamMembers,
    avatar_color: params.teamColor,
  };
  return superFetch(url, options, JSON.stringify(body))
    .then(data => data.json());
};

const updateTeam = (params) => {
  const url = `/teams/${params.id}`;
  const options = {
    method: 'PUT',
  };
  const body = {
    id: params.id,
    name: params.teamName,
    avatar_color: params.teamColor,

  };
  return superFetch(url, options, JSON.stringify(body))
    .then(data => data.json());
};

const updateUserTeamAutoAddPreference = (params) => {
  const url = '/user/teampreference';
  const options = {
    method: 'PATCH',
  };
  const body = {
    team_id: params.team_id,
    auto_add: params.auto_add,
  };
  return superFetch(url, options, JSON.stringify(body))
    .then(data => data.json());
};

const getTeamSplitDetails = async (teamId) => {
  const url = '/user/teampreference';
  const params = {
    team_id: teamId,
  };
  const options = {
    method: 'GET',
    params,
  };
  const data = await superFetch(url, options);
  return data.json();
};

const patchDefaultSplits = async (patchBody) => {
  const url = '/user/teampreference';
  const options = {
    method: 'PATCH',
  };
  const data = await superFetch(url, options, JSON.stringify(patchBody));
  return data.json();
};

const addTeamMember = (params) => {
  const url = `/teams/${params.id}`;
  const options = {
    method: 'PATCH',
  };
  const body = [{
    op: 'add',
    path: '/users',
    value: params.newTeamMember.user_id,
  }];
  return superFetch(url, options, JSON.stringify(body))
    .then(data => data.json());
};

const removeTeamMember = (params) => {
  const url = `/teams/${params.id}`;
  const options = {
    method: 'PATCH',
  };
  const body = [{
    op: 'remove',
    path: `/users/${params.memberToRemove.id}`,
  }];
  return superFetch(url, options, JSON.stringify(body))
    .then(data => data.json());
};
export {
  fetchTeamOptions,
  fetchTeams,
  fetchTeamByID,
  addTeam,
  updateTeam,
  updateUserTeamAutoAddPreference,
  addTeamMember,
  removeTeamMember,
  getTeamSplitDetails,
  patchDefaultSplits,
};
