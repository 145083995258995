import dealsService from '../../services/deals-service';
import { addRequirements, updateRequirements } from '../../services/requirements-service';
import { toast } from '../actions/toasts-actions';
import {
  CLEAR_DEAL_STORE,
  FAVORITE_DEAL_FAILURE,
  IS_LOADING_DEAL,
  LOAD_DEAL_SUCCESS,
  LOAD_DEAL_FAILURE,
  UPDATE_DEAL_SUCCESS,
  UPDATE_DEAL_FAILURE,
  UPDATE_DEAL_SUCCESS_ES,
  UPDATE_DEAL_FAILURE_ES,
  UPDATE_DEAL_CLIENT_SUCCESS,
  UPDATE_DEAL_CLIENT_FAILURE,
  UPDATE_DEAL_REQUIREMENTS_SUCCESS,
  UPDATE_DEAL_REQUIREMENTS_FAILURE,
  UNFAVORITE_DEAL_FAILURE,
  INITIALIZE_COMMISSIONS_TOTAL,
} from './action-types';

// Deal Action Creators
const loadDealSuccess = deal => ({
  type: LOAD_DEAL_SUCCESS,
  payload: { deal },
});

const loadDealFailure = error => ({
  type: LOAD_DEAL_FAILURE,
  payload: { error },
});

const clearDealStore = () => ({
  type: CLEAR_DEAL_STORE,
});

const isLoadingDeal = isLoading => ({
  type: IS_LOADING_DEAL,
  payload: { isLoading },
});

const updateDealSuccess = deal => ({
  type: UPDATE_DEAL_SUCCESS,
  payload: { deal },
});

const updateDealFailure = error => ({
  type: UPDATE_DEAL_FAILURE,
  payload: { error },
});

const updateDealSuccessES = ({ id, params }) => ({
  type: UPDATE_DEAL_SUCCESS_ES,
  payload: { id, params },
});

const updateDealFailureES = error => ({
  type: UPDATE_DEAL_FAILURE_ES,
  payload: { error },
});

const updateDealRequirementsSuccess = requirements => ({
  type: UPDATE_DEAL_REQUIREMENTS_SUCCESS,
  payload: { requirements },
});

const updateDealRequirementsFailure = error => ({
  type: UPDATE_DEAL_REQUIREMENTS_FAILURE,
  payload: { error },
});

const updateDealClientSuccess = ({ client }) => ({
  type: UPDATE_DEAL_CLIENT_SUCCESS,
  payload: { client },
});

const updateDealClientFailure = error => ({
  type: UPDATE_DEAL_CLIENT_FAILURE,
  payload: { error },
});

const favoriteDealFailure = error => ({
  type: FAVORITE_DEAL_FAILURE,
  payload: { error },
});

const unfavoriteDealFailure = error => ({
  type: UNFAVORITE_DEAL_FAILURE,
  payload: { error },
});

const initializeCommissionsTotal = total => ({
  type: INITIALIZE_COMMISSIONS_TOTAL,
  payload: total,
});


// DEAL ASYNC ACTIONS:
// Deprecated
const loadDeal = (id, clearStore) => (dispatch) => {
  if (clearStore) dispatch(clearDealStore());
  dispatch(isLoadingDeal(true));

  return dealsService.fetchDeal(id)
    .then((data) => {
      dispatch(loadDealSuccess(data));
    })
    .then(() => dispatch(isLoadingDeal(false)))
    .catch((error) => {
      if (error.status === 404) {
        window.location.href = '/dashboard';
      }
      dispatch(loadDealFailure(error));
    });
};

const loadDealFromES = (id, clearStore) => (dispatch) => {
  if (clearStore) dispatch(clearDealStore());
  dispatch(isLoadingDeal(true));
  const params = { id };
  return dealsService.fetchDealsAndTransactionsES(params)
    .then((data) => {
      dispatch(loadDealSuccess(data.results[0]));
    })
    .then(() => dispatch(isLoadingDeal(false)))
    .catch((error) => {
      if (error.status === 404) {
        window.location.href = '/dashboard';
      }
      dispatch(loadDealFailure(error));
    });
};

const updateDeal = (id, params) => dispatch =>
  dealsService.updateDeal(id, params)
    .then((data) => {
      dispatch(updateDealSuccess(data));
      if (params.is_deleted) {
        const event = new Event('updateDealCounts');
        window.dispatchEvent(event);
      }
    })
    .catch(error => dispatch(updateDealFailure(error)));

const updateDealES = (id, params) => dispatch =>
  dealsService.updateDeal(id, params)
    .then(() => dispatch(updateDealSuccessES({ id, params })))
    .catch(error => dispatch(updateDealFailureES(error)));

const updateClient = (id, params) => dispatch =>
  dealsService.updateClient(id, params)
    .then(data => dispatch(updateDealClientSuccess(data)))
    .catch(error => dispatch(updateDealClientFailure(error)));

const favoriteDeal = id => dispatch =>
  dealsService.favoriteDeal(id)
    .then(() => dispatch(loadDeal(id)))
    .catch(error => dispatch(favoriteDealFailure(error)));

const unfavoriteDeal = id => dispatch =>
  dealsService.unfavoriteDeal(id)
    .then(() => dispatch(loadDeal(id)))
    .catch(error => dispatch(unfavoriteDealFailure(error)));

const addDealRequirements = params => dispatch =>
  addRequirements(params)
    .then((data) => {
      dispatch(updateDealRequirementsSuccess(data));
      dispatch(toast('Deal Updated'));
    })
    .catch((error) => {
      dispatch(updateDealRequirementsFailure(error));
      dispatch(toast('Failed to update deal.'));
    });

const updateDealRequirements = (id, params) => dispatch =>
  updateRequirements(id, params)
    .then((data) => {
      dispatch(updateDealRequirementsSuccess(data));
      dispatch(toast('Deal Updated'));
    })
    .catch((error) => {
      dispatch(updateDealRequirementsFailure(error));
      dispatch(toast('Failed to update deal.'));
    });

const updateDealPipelineTimestamp = id => dispatch =>
  dealsService.updateDeal(id, { pipeline_modified_datetime: new Date().toISOString() })
    .then(data => dispatch(updateDealSuccess(data)))
    .catch(error => dispatch(updateDealFailure(error)));

export {
  clearDealStore,
  isLoadingDeal,
  loadDeal,
  loadDealFromES,
  updateDeal,
  updateDealES,
  updateClient,
  addDealRequirements,
  updateDealRequirements,
  favoriteDeal,
  unfavoriteDeal,
  updateDealPipelineTimestamp,
  initializeCommissionsTotal,
};
