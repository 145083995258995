import unionBy from 'lodash/unionBy';
import {
  ADD_FILES_SUCCESS,
  ADD_FILES_FAILURE,
  CLEAR_DEAL_STORE,
  DELETE_FILE_SUCCESS,
  FETCH_FILES_SUCCESS,
  FETCH_FILES_FAILURE,
  FETCH_UPLOADED_FILES_FAILURE,
  IS_LOADING_FILES,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_FAILURE,
  ABSTRACTION_LEASE_FILE
} from '../actions/action-types';

const initialState = {
  count: 0,
  error: undefined,
  files: [],
  isLoadingFiles: false,
  next: null,
  previous: null,
  abstractionLeaseFile: {}
};

const addFilesSuccess = (state, { payload: newFile }) => ({
  ...state,
  files: [newFile, ...state.files],
});

const clearStore = () => ({
  ...initialState,
});

const deleteFileSuccess = (state, { payload: { id } }) => ({
  ...state,
  files: state.files.filter(file => file.id !== id),
});

const filesFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const fetchFilesSuccess = (state, action) => {
  const {
    count,
    next,
    previous,
    results,
  } = action.payload;
  const files = previous === null
    ? results
    : unionBy(state.files, results, 'id');
  return ({
    ...state,
    count,
    files,
    next,
    previous,
  });
};

const isLoadingFiles = (state, action) => ({
  ...state,
  isLoadingFiles: action.payload.isLoading,
});

const updateFileSuccess = (state, { payload: { file } }) => ({
  ...state,
  files: state.files.map(f => (f.id === file.id ? file : f)),
});

const dealFiles = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILES_SUCCESS:
      return addFilesSuccess(state, action);
    case CLEAR_DEAL_STORE:
      return clearStore();
    case DELETE_FILE_SUCCESS:
      return deleteFileSuccess(state, action);
    case UPDATE_FILE_SUCCESS:
      return updateFileSuccess(state, action);
    case FETCH_FILES_SUCCESS:
      return fetchFilesSuccess(state, action);
    case IS_LOADING_FILES:
      return isLoadingFiles(state, action);
    case ADD_FILES_FAILURE:
    case FETCH_FILES_FAILURE:
    case FETCH_UPLOADED_FILES_FAILURE:
    case UPDATE_FILE_FAILURE:
      return filesFailure(state, action);
    case ABSTRACTION_LEASE_FILE:
    console.log(action)
      return {...state, abstractionLeaseFile: action.payload.file}
    default:
      return state;
  }
};

export default dealFiles;

