import {
  deleteAttachment,
  fetchAttachments,
  fetchAttachmentsDownloadLink,
  fetchAttachmentUploadData,
  postAttachments,
  postAttachmentsToS3,
  postAttachmentsToExtract,
  updateAttachment,
} from '../../services/attachments-service';

import {
  ADD_FILES_SUCCESS,
  DELETE_FILE_SUCCESS,
  FETCH_FILES_DOWNLOAD_LINK_SUCCESS,
  FETCH_FILES_SUCCESS,
  IS_LOADING_FILES,
  UPDATE_FILE_SUCCESS,
  ABSTRACTION_LEASE_FILE,
} from './action-types';

import { toast } from './toasts-actions';

const addFilesSuccess = file => ({
  type: ADD_FILES_SUCCESS,
  payload: { ...file },
});

const deleteFileSuccess = id => ({
  type: DELETE_FILE_SUCCESS,
  payload: { id },
});

const fetchFilesSuccess = res => ({
  type: FETCH_FILES_SUCCESS,
  payload: { ...res },
});

const fetchFilesDownloadLinkSuccess = res => ({
  type: FETCH_FILES_DOWNLOAD_LINK_SUCCESS,
  payload: { ...res },
});

const isLoadingFiles = isLoading => ({
  type: IS_LOADING_FILES,
  payload: { isLoading },
});

const updateFileSuccess = file => ({
  type: UPDATE_FILE_SUCCESS,
  payload: { file },
});

const abstractionLeaseFile = file => ({
  type: ABSTRACTION_LEASE_FILE,
  payload: { file },
});

const deleteFile = id => dispatch => deleteAttachment(id)
  .then(() => {
    dispatch(deleteFileSuccess(id));
    dispatch(toast('File(s) Deleted'));
  })
  .catch(() => {
    dispatch(toast('Error Deleting File(s)'));
  });

const fetchFilesDownloadLink = (file, forceDownload) => (dispatch) => {
  dispatch(isLoadingFiles(true));
  return fetchAttachmentsDownloadLink(file, forceDownload)
    .then(res => dispatch(fetchFilesDownloadLinkSuccess(res)))
    .catch(() => {
      dispatch(toast('Error Downloading Files'));
    })
    .then(() => dispatch(isLoadingFiles(false)));
};

// fetch existing files
const fetchFiles = params => (dispatch) => {
  dispatch(isLoadingFiles(true));
  return fetchAttachments(params)
    .then(res => dispatch(fetchFilesSuccess(res)))
    .catch(() => {
      dispatch(toast('Error Fetching Files'));
    })
    .then(() => dispatch(isLoadingFiles(false)));
};

const updateFile = (id, params) => dispatch => updateAttachment(id, params)
  .then(res => dispatch(updateFileSuccess(res)))
  .catch(() => {
    dispatch(toast('Error Updating Files'));
  });

// POST a new file to /attachments endpoint and then upon success,
// init the upload to S3 workflow
// const uploadFile = (file, params, onProgress) => (dispatch) => {
//   let fileData;
//   return postAttachments(params)
//     .then((res) => {
//       fileData = res;
//       console.log(fileData);
//       return fetchAttachmentUploadData(res.id);
//     })
//     .then(res => postAttachmentsToS3(file, res, onProgress))
//     .then(() => postAttachmentsToExtract({ deal: fileData.deal, file: fileData.id }))
//     .then((res) => {
//       console.log(res);
//       return dispatch(addFilesSuccess(res));
//     })
//     .catch(() => {
//       dispatch(toast('Error Adding New Files'));
//     });
// };

const uploadFile = (file, params, onProgress) => (dispatch) => {
  let fileData;
  return postAttachments(params)
    .then((res) => {
      fileData = res;
      return fetchAttachmentUploadData(res.id);
    })
    .then(res => postAttachmentsToS3(file, res, onProgress))
    .then(() => postAttachmentsToExtract({ deal: fileData.deal, file: fileData.id, attachment_file_key: null }))
    .then(() => dispatch(addFilesSuccess(fileData)))
    .catch((error) => {
      dispatch(toast(`Error uploading ${file.name}. Please try again.`));
      if (error.status !== 400 && fileData) {
        deleteAttachment(fileData.id);
      }
    });
};

export {
  deleteFile,
  fetchFiles,
  fetchFilesDownloadLink,
  updateFile,
  uploadFile,
  abstractionLeaseFile,
};
