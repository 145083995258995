import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Currency from '../../../nucleus/formats/currency';
import ExpandToggle from '../../../nucleus/expand-toggle/expand-toggle';

const CommissionsSummary = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const { myCommissionSummary } = props;

  const getStatusIconColor = (item) => {
    let color = '';
    switch (item.status) {
      case 'Paid':
        color = 'green';
        break;
      case 'Receivable':
        color = 'red';
        break;
      case 'Upcoming':
        color = 'blue';
        break;
      default:
        color = '';
    }
    return `status-icon ${color}`;
  };

  const renderItems = myCommissionSummary.items.map(item => (
    <tr key={item.status}>
      <td className="center">
        <div className={getStatusIconColor(item)} />
      </td>
      <td>{item.status}</td>
      <td>
        <Currency value={item.gross} />
      </td>
      <td>
        <Currency value={item.net} />
      </td>
    </tr>
  ));

  const renderTable = isOpen && (
    <table>
      <colgroup>
        <col name="status-icon" width="40px" />
        <col name="status" />
        <col name="gross" />
        <col name="net" />
      </colgroup>
      <tbody>
        <tr className="borderless-row">
          <th />
          <th>Status</th>
          <th>Gross</th>
          <th>Net</th>
        </tr>
        {renderItems}
        <tr className="text-dark">
          <td />
          <td>Subtotal</td>
          <td>
            <Currency value={myCommissionSummary.subtotal.gross} />
          </td>
          <td>
            <Currency value={myCommissionSummary.subtotal.net} />
          </td>
        </tr>
        <tr>
          <td />
          <td>Escalations</td>
          <td />
          <td>
            <Currency value={myCommissionSummary.escalations} />
          </td>
        </tr>
        <tr className="text-dark text-semi-bold">
          <td />
          <td>Total</td>
          <td />
          <td>
            <Currency value={myCommissionSummary.total} />
          </td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <div className="commissions-card">
      <div className="header-row">
        <h2>My Commission Summary</h2>
        <ExpandToggle onClick={handleClick} isOpen={isOpen} />
      </div>
      {renderTable}
    </div>
  );
};

CommissionsSummary.propTypes = {
  myCommissionSummary: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string,
      gross: PropTypes.number,
      net: PropTypes.number,
    })),
    subtotal: PropTypes.shape({
      gross: PropTypes.number,
      net: PropTypes.number,
      split: PropTypes.number,
    }),
    escalations: PropTypes.number,
    total: PropTypes.number,
  }),
};

CommissionsSummary.defaultProps = {
  myCommissionSummary: {
    items: [],
    subtotal: {
      gross: 0,
      net: 0,
      split: 0,
    },
    escalations: 0,
    total: 0,
  },
};

export default CommissionsSummary;
