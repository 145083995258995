import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Currency from '../../../../nucleus/formats/currency';

const formatUTCDate = date => (moment.utc(date).format('M/D/YYYY'));

const CommissionsDetailEscalations = props => (
  <table>
    <colgroup>
      <col name="date" width="50%" />
      <col name="amount" width="50%" />
    </colgroup>
    <tbody>
      <tr className="borderless-row">
        <th>Date</th>
        <th>Amount</th>
      </tr>
      {props.escalations.map(escalation => (
        <tr key={escalation.id}>
          <td>{formatUTCDate(escalation.accounting_datetime)}</td>
          <td><Currency value={escalation.amount} /></td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default CommissionsDetailEscalations;

CommissionsDetailEscalations.propTypes = {
  escalations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
