import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';

import Notice from '../../nucleus/notices/notice';
import PropertyAutocomplete from '../autocomplete/property-autocomplete';
import Property from '../property/property';
import RowItem from '../../nucleus/row-item/row-item';
import Tooltip from '../../nucleus/tooltip/tooltip';

const NewDealProperties = (props) => {
  // if a property exists through props, this means we have
  // entered the create deal workflow via property detail.
  // in this case, we only want to show the property info.
  // as an immutable card so the user cannot make updates to the
  // property
  const {
    properties,
    property: existingProperty,
    addProperty,
    removeProperty,
    errorMessage,
    onNoticeDismiss,
    showPropertyNotice,
    initSearchTerm,
    onChange,
  } = props;

  const createPropertiesAlert = showPropertyNotice && (
    <Notice
      text="Adding more than one property will create multiple deals"
      onDismiss={onNoticeDismiss}
      type="alert"
    />);

  const propertyList = properties.map((property) => {
    const {
      magicKey,
      building,
      name,
    } = property;

    const onRemoveProperty = () => {
      removeProperty(magicKey);
    };

    return (
      <RowItem key={uniqueId()}>
        <Property
          name={name}
          building={building}
          callToAction={onRemoveProperty}
        />
      </RowItem>
    );
  });

  const filterAutocompleteResultsPredicate = property =>
    (!properties.some(p => (property.magicKey === p.magicKey)));

  return (
    <section className="properties">
      <div className="header">
        <h3>Property Information</h3>
      </div>
      {existingProperty && isEmpty(existingProperty) &&
        <label>
          * Prospective Property
          <Tooltip message="Try entering a street address, intersection, and include the city name." />
        </label>
      }
      <div className="property-field">
        {(existingProperty && isEmpty(existingProperty))
          ? (
            <React.Fragment>
              <PropertyAutocomplete
                initSearchTerm={initSearchTerm}
                onChange={onChange}
                onSelect={addProperty}
                errorMessage={errorMessage}
                filterResultsPredicate={filterAutocompleteResultsPredicate}
              />
              <React.Fragment>{createPropertiesAlert}</React.Fragment>
              <div className="property-list">
                {propertyList}
              </div>
            </React.Fragment>
          )
          : (
            <Property
              name={existingProperty.name}
              building={existingProperty.building}
              callToAction={existingProperty.onRemoveProperty}
            />
          )
        }
      </div>
    </section>
  );
};

NewDealProperties.propTypes = {
  addProperty: PropTypes.func.isRequired,
  removeProperty: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({})),
  errorMessage: PropTypes.string,
  onNoticeDismiss: PropTypes.func.isRequired,
  showPropertyNotice: PropTypes.bool.isRequired,
  property: PropTypes.shape({
    building: PropTypes.shape({}),
    magicKey: PropTypes.string,
    name: PropTypes.string,
  }),
  initSearchTerm: PropTypes.string,
  onChange: PropTypes.func,
};

NewDealProperties.defaultProps = {
  properties: [],
  errorMessage: '',
  property: {},
  initSearchTerm: '',
  onChange: () => {},
};
export default NewDealProperties;
