/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import { Button } from '@cbrebuild/blocks';
import moment from 'moment';
import supportService from '../../services/support-service';

const SupportWhatsNew = () => {
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(1);
  const [changeLogs, setChangeLogs] = useState([]);

  const onLoadMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    const options = {
      page,
      page_size: 25,
    };

    supportService.getChangeLogs(options)
      .then((data) => {
        setChangeLogs(changeLogs.concat(data && data.results));
        setNext(data.next);
      })
      .catch((error) => {
        console.log('Error fetching change logs: ', error);
      });
  }, [page]);

  return (
    <Fragment>
      <section className="support-content change-logs">
        {
          changeLogs.map((log) => {
            const date = moment(log.date)
              .format('DD/MMM')
              .split('/');
            const day = date[0];
            const month = date[1];

            return (
              <div className="change-log" key={log.id}>
                <div className="date-bubble">
                  <span className="date-bubble-month">{month}</span>
                  <span className="date-bubble-day">{day}</span>
                </div>

                <div className="change-log-content">
                  <div className="excerpt">
                    <div className="title-wrapper">
                      <h2 className="title">{log.title}</h2>
                      <p className="date">{moment(log.date)
                        .format('MMM DD, YYYY')}
                      </p>
                    </div>
                    <p>{log.description}</p>
                  </div>
                  <div className="preview">
                    <img src={log.image_url} alt={log.title} className="preview-image" />
                  </div>
                </div>
              </div>
            );
          })
        }
      </section>
      {next &&
      <div className="change-logs controls">
        <Button
          className="load-more"
          onClick={e => onLoadMore(e)}
        >
          Load More
        </Button>
      </div>
      }
    </Fragment>
  );
};

SupportWhatsNew.propTypes = {};

export default SupportWhatsNew;
