import unionBy from 'lodash/unionBy';

import {
  ADD_TEAM_FOR_ADMIN_SUCCESS,
  ADD_TEAM_FOR_ADMIN_FAILURE,
  ADD_TEAM_MEMBER_FOR_ADMIN_SUCCESS,
  ADD_TEAM_MEMBER_FOR_ADMIN_FAILURE,
  LOAD_TEAMS_FOR_ADMIN_SUCCESS,
  LOAD_TEAMS_FOR_ADMIN_FAILURE,
  UNDELETE_TEAM_SUCCESS,
  UNDELETE_TEAM_FAILURE,
  UPDATE_TEAM_FOR_ADMIN_SUCCESS,
  UPDATE_TEAM_FOR_ADMIN_FAILURE,
  REMOVE_TEAM_MEMBER_FOR_ADMIN_SUCCESS,
  REMOVE_TEAM_MEMBER_FOR_ADMIN_FAILURE,
} from '../actions/action-types';

const initialState = { teams: [], hasNext: null };

const loadTeamsForAdminSuccess = (state, { payload: response }) => ({
  ...state,
  teams: response.previous ?
    unionBy(state.teams, response.results, 'id') :
    response.results,
  hasNext: response.next,
});

const addTeamForAdminSuccess = (state, { payload: newTeam }) => ({
  ...state,
  teams: [newTeam, ...state.teams],
});

const updateTeamForAdminSuccess = (state, { payload: team }) => {
  const teamToUpdate = state.teams.filter(t => t.id === team.id);
  const updatedTeams = [...state.teams];
  updatedTeams[updatedTeams.indexOf(...teamToUpdate)] = team;
  return ({
    ...state,
    teams: updatedTeams,
  });
};

const adminTeamsFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const admin = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TEAMS_FOR_ADMIN_SUCCESS:
      return loadTeamsForAdminSuccess(state, action);
    case ADD_TEAM_FOR_ADMIN_SUCCESS:
      return addTeamForAdminSuccess(state, action);
    case ADD_TEAM_MEMBER_FOR_ADMIN_SUCCESS:
    case REMOVE_TEAM_MEMBER_FOR_ADMIN_SUCCESS:
    case UNDELETE_TEAM_SUCCESS:
    case UPDATE_TEAM_FOR_ADMIN_SUCCESS:
      return updateTeamForAdminSuccess(state, action);
    case LOAD_TEAMS_FOR_ADMIN_FAILURE:
    case ADD_TEAM_FOR_ADMIN_FAILURE:
    case ADD_TEAM_MEMBER_FOR_ADMIN_FAILURE:
    case REMOVE_TEAM_MEMBER_FOR_ADMIN_FAILURE:
    case UNDELETE_TEAM_FAILURE:
    case UPDATE_TEAM_FOR_ADMIN_FAILURE:
      return adminTeamsFailure(state, action);
    default:
      return state;
  }
};

export default admin;
