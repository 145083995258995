import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../nucleus/modal/modal';
import ContactForm from '../contact-form/contact-form';

class ContactModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  onCancel = () => {
    this.setState(
      { showModal: false },
      () => this.props.onCancel(),
    );
  }

  onDelete = (id) => {
    this.setState(
      { showModal: false },
      () => this.props.onDelete(id),
    );
  }

  onSubmit = (contact, shouldAddToPersonal) => {
    this.setState(
      { showModal: false },
      () => this.props.onSubmit(contact, shouldAddToPersonal),
    );
  }

  render() {
    const {
      analyticProperties,
      isPrimaryOnly,
      contact,
      dealId,
    } = this.props;

    return (
      <Modal
        className="contact-modal"
        showModal={this.state.showModal}
        handleModalToggle={this.onCancel}
        modalHeader={`${(contact && contact.id) ? 'Edit' : 'Add'} Deal Contact`}
        modalWidth={650}
        hideModalControls
      >
        <ContactForm
          analyticProperties={analyticProperties}
          isPrimaryOnly={isPrimaryOnly}
          contact={contact}
          dealId={dealId}
          onCancel={this.onCancel}
          onDelete={this.onDelete}
          onSubmit={this.onSubmit}
        />
      </Modal >
    );
  }
}

ContactModal.propTypes = {
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  contact: PropTypes.shape({
    id: PropTypes.number,
  }),
  dealId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isPrimaryOnly: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ContactModal.defaultProps = {
  contact: {},
  dealId: null,
  isPrimaryOnly: false,
};


export default ContactModal;
