import React, { useState, useEffect } from 'react';
import { RadioGroup, RadioButton, Icon, IconButton, Button } from '@cbrebuild/blocks';
import PropTypes from 'prop-types';
import NumberInput from '../../../nucleus/inputs/number-input';
import ConfirmationModal from '../../../nucleus/confirmation-modal/confirmation-modal';
import useCommercialPropertyState from '../../../hooks/useCommercialPropertyState';
import vouchersService from '../../../services/voucher/vouchers-service';
import AddItem from '../../../nucleus/add-item/add-item';
import useVoucherValidation from '../useVoucherValidation';
import Datepicker from '../../../nucleus/datepicker/datepicker';
import Select from '../../../nucleus/select/select';

const InvestmentDetails = ({
  deal,
  voucher,
}) => {
  const [propertyOptions, setPropertyOptions] = useState([{ display_name: 'Select', value: null }]);
  const [showModal, setShowModal] = useState(false);
  const {
    properties, onOccupancyBlur, onCurrencyBlur, onOccupancyChange, onInvestmentPropertyChange,
    onTenantCreate, onTenantBlur, onTenantChange, onTenantDelete, onTenantDateChange, onInvestPropertyClear, onInvestorChange,
  } = useCommercialPropertyState({ dealId: deal.id, createIfEmpty: true });

  const PAGE_NUMBER = 2.2;

  // uses the useValidation hook to see if this page should show all validation
  const { showPageValidation } = useVoucherValidation(voucher.id, PAGE_NUMBER);

  useEffect(() => {
    vouchersService.getPropertyOptions().then((res) => {
      if (res.actions.POST.investor_lender_type.choices.length > 0 && propertyOptions.length === 1) {
        setPropertyOptions(propertyOptions.concat(res.actions.POST.investor_lender_type.choices));
      }
    });
  }, [deal.id]);

  const closeConfirmationModal = () => {
    setShowModal(false);
  };

  const handleInvestmentPropertyChange = (event, propId) => {
    if (event.target.value === 'true') {
      onInvestmentPropertyChange(propId, true);
    } else {
      setShowModal(true);
    }
  };

  return (
    <div className="investment-tab">
      {properties.map(cp => (
        <div key={cp.id}>
          <h2>Is this an investment property?</h2>
          <RadioGroup
            name="is_investment_property"
            className="radioVal"
            selectedValue={`${cp.is_investment_property}`}
            onChange={e => handleInvestmentPropertyChange(e, cp.id)}
            orientation="vertical"
          >
            <RadioButton value="false">No</RadioButton>
            <RadioButton value="true">Yes</RadioButton>
          </RadioGroup>
          {cp.is_investment_property &&
            <div>
              <div className="field select-subtype">
                <label>
                  <span className={(cp.investor_lender_type === '' && showPageValidation) ?
                  'asterisk-error' : ''}
                  >*
                  </span> Investor Type
                </label>
                <Select
                  className={`${cp.investor_lender_type === '' && showPageValidation ? 'errorInput' : ''} prop-category invest-field`}
                  defaultOption={cp.investor_lender_type || 'Select'}
                  options={propertyOptions.filter(opt => opt.value !== '')}
                  onChange={e => onInvestorChange(cp.id)({
                  target: {
                    type: 'select',
                    name: 'investor_lender_type',
                    value: e.value === null ? '' : e.value,
                  },
                })}
                />
                {((cp.investor_lender_type === '' || cp.investor_lender_type === null) && showPageValidation) &&
                <p style={{ marginTop: '10px' }} className="asterisk-error">Investor type is required</p>}
              </div>
              <legend>Occupancy</legend>
              <div className="field">
                <label>Vacancy (%)</label>
                <input
                  type="number"
                  name="vacancy_rate"
                  min="0"
                  max="100"
                  value={cp.vacancy_rate || ''}
                  onChange={onOccupancyChange(cp.id)}
                  onBlur={onOccupancyBlur(cp.id)}
                />
              </div>
              <div className="field">
                <label>Occupancy (%)</label>
                <input
                  type="number"
                  name="occupancy_rate"
                  min="0"
                  max="100"
                  value={cp.occupancy_rate || ''}
                  onChange={onOccupancyChange(cp.id)}
                  onBlur={onOccupancyBlur(cp.id)}
                />
              </div>
              <legend>Investment Details</legend>
              <div className="invest-field">
                <label>Schedule Gross Income ($)</label>
                <NumberInput
                  name="scheduled_gross_income"
                  value={parseFloat(cp.scheduled_gross_income).toFixed(2)}
                  onBlur={onCurrencyBlur(cp.id, 'scheduled_gross_income')}
                />
              </div>
              <div className="invest-field">
                <label>Property Expense ($)</label>
                <NumberInput
                  name="property_expense"
                  value={parseFloat(cp.property_expense).toFixed(2)}
                  onBlur={onCurrencyBlur(cp.id, 'property_expense')}
                />
              </div>
              <div className="invest-field">
                <label>Cap Rate/Yield (%)</label>
                <input
                  type="number"
                  name="cap_yield_rate"
                  min="0"
                  max="100"
                  value={cp.cap_yield_rate || ''}
                  onChange={onOccupancyChange(cp.id)}
                  onBlur={onOccupancyBlur(cp.id)}
                />
              </div>
              <div className="invest-field">
                <label>Net Operating Income ($)</label>
                <NumberInput
                  name="net_operating_income"
                  value={parseFloat(cp.net_operating_income).toFixed(2)}
                  onBlur={onCurrencyBlur(cp.id, 'net_operating_income')}
                />
              </div>
              <div className="invest-field">
                <label>Spendable Return ($)</label>
                <NumberInput
                  name="spendable_return"
                  value={parseFloat(cp.spendable_return).toFixed(2)}
                  onBlur={onCurrencyBlur(cp.id, 'spendable_return')}
                />
              </div>
              <div className="invest-field">
                <label>Exchange ($)</label>
                <NumberInput
                  name="exchange"
                  value={parseFloat(cp.exchange).toFixed(2)}
                  onBlur={onCurrencyBlur(cp.id, 'exchange')}
                />
              </div>
              <h2 className="tenant-info-label">Current Tenant Information</h2>
              {cp.tenants.map((tenant, i) => (
              // eslint-disable-next-line react/no-array-index-key
                <div key={`${cp.id}-${i}`}>
                  <div className="form-row">
                    <div className="field-group col-9 tenant-name">
                      <label>Tenant Name</label>
                      <input
                        type="text"
                        name="name"
                        value={tenant.name || ''}
                        onChange={onTenantChange(cp.id, i)}
                        onBlur={onTenantBlur(cp.id, i)}
                      />
                    </div>
                    <div className="field-group col-3">
                      <label>Lease Expiration Date</label>
                      <Datepicker
                        date={tenant.lease_expiration_date}
                        onDateChange={date => onTenantDateChange(cp.id, i, date, 'lease_expiration_date')}
                      />
                    </div>
                    <Icon className="closeIcon delete-tenant" onClick={() => onTenantDelete(cp.id, i)} iconName="close-circle" size="large" />
                  </div>
                </div>
            ))}
              <p className="card-content">
                <AddItem
                  label="Add Tenant"
                  onClick={() => onTenantCreate(cp.id)()}
                />
              </p>
            </div>
          }
          {showModal &&
            <ConfirmationModal
              className="deal-file-delete-confirmation tenant-modal voucher-modal"
              onCloseModal={closeConfirmationModal}
              showModal={showModal}
            >
              <div className="header-modal">
                <h1 className="header-content conf-header">Confirmation</h1>
                <IconButton
                  iconName="close-circle"
                  className="icon-circle"
                  variant="basic"
                  onClick={closeConfirmationModal}
                />
              </div>
              <div className="conf-text">
                <p>This will delete investment detail. Are you sure?</p>
              </div>
              <footer className="actions">
                <Button
                  className="cancel-btn"
                  variant="secondary"
                  onClick={closeConfirmationModal}
                >
                  Cancel
                </Button>
                <Button
                  className="upload-btn ok-btn"
                  onClick={() => {
                setShowModal(false);
                // onPropertyConfChange(cp.id, false);
                onInvestPropertyClear(cp.id);
              }}
                >Ok
                </Button>
              </footer>
            </ConfirmationModal>
      }
        </div>
      ))}
    </div>
  );
};

InvestmentDetails.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  voucher: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  deal: PropTypes.shape({
    rep_role: PropTypes.string.isRequired,
    deal_type: PropTypes.string,
    is_dual_rep_deal: PropTypes.bool,
    id: PropTypes.number,
    client: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
};

InvestmentDetails.defaultProps = {
  location: '',
};

export default InvestmentDetails;
