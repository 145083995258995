import contactsService from '../../services/contacts-service';
import dealsService from '../../services/deals-service';
import {
  ADD_TAG_TO_ALL_CONTACTS_TAGS,
  FETCH_ALL_CONTACTS_TAGS_FAILURE,
  FETCH_ALL_CONTACTS_TAGS_SUCCESS,
  TAGS_ADD_TAG_TO_DEAL_TAGS,
  TAGS_FETCH_DEAL_TAGS_FAILURE,
  TAGS_FETCH_DEAL_TAGS_SUCCESS,
} from '../actions/action-types';

// Tags Actions Creators for My Contacts Tags
const addTagToAllContactsTagsSuccess = tags => ({
  type: ADD_TAG_TO_ALL_CONTACTS_TAGS,
  payload: { tags },
});

const fetchAllContactsTagsSuccess = data => ({
  type: FETCH_ALL_CONTACTS_TAGS_SUCCESS,
  payload: { ...data },
});

const fetchAllContactsTagsFailure = error => ({
  type: FETCH_ALL_CONTACTS_TAGS_FAILURE,
  payload: { error },
});

// Tags Actions Creators for Deals Tags
const addDealTagSuccess = tag => ({
  type: TAGS_ADD_TAG_TO_DEAL_TAGS,
  payload: { tag },
});

const fetchDealTagsSuccess = data => ({
  type: TAGS_FETCH_DEAL_TAGS_SUCCESS,
  payload: { ...data },
});

const fetchDealTagsFailure = error => ({
  type: TAGS_FETCH_DEAL_TAGS_FAILURE,
  payload: { error },
});


// Actions
const addDealTag = tag => dispatch => dispatch(addDealTagSuccess(tag));
const addTagToAllContactsTags = tag => dispatch => dispatch(addTagToAllContactsTagsSuccess(tag));

// ASYNC Actions
const fetchDealTags = () => dispatch =>
  dealsService.fetchDealTags()
    .then(data => dispatch(fetchDealTagsSuccess(data)))
    .catch(error => dispatch(fetchDealTagsFailure(error)));

const fetchAllContactsTags = () => dispatch =>
  contactsService.fetchContactTags()
    .then(data => dispatch(fetchAllContactsTagsSuccess(data)))
    .catch(error => dispatch(fetchAllContactsTagsFailure(error)));

export {
  addDealTag,
  addTagToAllContactsTags,
  fetchDealTags,
  fetchAllContactsTags,
};
