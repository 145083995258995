import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@cbrebuild/blocks';
import uniqueId from 'lodash/uniqueId';
import userEventService from '../../services/user-event-service';

import Dropzone from '../../nucleus/dropzone/dropzone';
import FileToUploadListItem from './file-to-upload-list-item';
import Notice from '../../nucleus/notices/notice';
import dealsService from '../../services/deals-service';

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      isUploadingFiles: false,
      showFileLimitExceededNotice: false,
    };
  }

  dismissNotice = () => {
    this.setState({ showFileLimitExceededNotice: false });
  }

  handleFilesAdded = (fileDataArr) => {
    const files = fileDataArr
      .filter((fileData) => {
        if (fileData.size > 1073741824) {
          this.setState({ showFileLimitExceededNotice: true });
          return false;
        }
        return true;
      })
      .map(fileData => ({
        fileData,
        // fileData is a JS file object so be wary as it has some special
        // behavior (i.e. cannot be spread over like regular objects)
        isPrivate: false,
        name: fileData.name, // user chosen attachment name, default to uploaded file name
        tempId: uniqueId(),
        uploadProgress: 0,
      }));
    this.setState(prevState => ({
      files: prevState.files.concat(files),
    }));
  }

  handleRemove = (tempId) => {
    const {
      dealId,
    } = this.props;
    this.setState({
      files: this.state.files.filter((file => file.tempId !== tempId)),
    });

    dealsService.updateDeal(dealId, {
      modified: new Date(),
    });
  }

  handleFileNameChange = (tempId, value) => {
    const {
      dealId,
    } = this.props;

    this.setState({
      files: this.state.files.map(file => (file.tempId === tempId
        ? ({ ...file, name: value })
        : file)),
    });

    dealsService.updateDeal(dealId, {
      modified: new Date(),
    });
  }

  handlePrivacyChange = (tempId, value) => {
    const {
      dealId,
    } = this.props;

    this.setState({
      files: this.state.files.map(file => (file.tempId === tempId
        ? ({ ...file, isPrivate: value })
        : file)),
    });

    dealsService.updateDeal(dealId, {
      modified: new Date(),
    });
  }

  handleProgress = (progressEvent, tempId) => {
    const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
    if (uploadProgress === 100) {
      this.setState({
        files: this.state.files.filter((file => file.tempId !== tempId)),
      });
    } else {
      this.setState({
        files: this.state.files.map(file => (file.tempId === tempId
          ? ({ ...file, uploadProgress })
          : file)),
      });
    }
  };

  handleUploadFiles = async () => {
    const { files } = this.state;
    const { dealId } = this.props;
    this.setState({ isUploadingFiles: true });
    const promises = files.map(this.sendRequest);
    await Promise.all(promises)
      .then(() => {
        dealsService.updateDeal(dealId, {
          modified: new Date(),
        });
        this.trackUpload(files.length);
      });
    this.setState({
      isUploadingFiles: false,
      files: [],
    });
  }

  sendRequest = file => new Promise(async (resolve) => {
    const {
      dealId,
      uploadFile,
    } = this.props;
    const {
      fileData,
      isPrivate,
      name,
      tempId,
    } = file;
    const params = {
      deal: dealId,
      title: name,
      // document_name is not mutable, it should
      // always be the original file name
      document_name: fileData.name,
      privacy: isPrivate ? 'private' : 'team',
    };
    await uploadFile(fileData, params, e => this.handleProgress(e, tempId));
    this.trackSize(fileData.size);
    this.trackType(fileData.type);
    resolve();
  })

  trackType = (eventValue) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent(
      {
        eventAction: 'file_type_uploaded',
        eventCategory: 'File Action',
        eventValue,
      },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  }

  trackSize = (eventValue) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent(
      {
        eventAction: 'file_size_uploaded',
        eventCategory: 'File Action',
        eventValue,
      },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  }

  trackUpload = (eventValue) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent(
      {
        eventAction: 'files_uploaded',
        eventCategory: 'File Action',
        eventValue,
      },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  }

  render() {
    const {
      files,
      isUploadingFiles,
      showFileLimitExceededNotice,
    } = this.state;

    return (
      // has to be named deal-file-upload because of namespace
      // issue with file-upload
      <div className="deal-file-upload">
        <div className="dropzone-wrapper">
          <Dropzone
            onFilesAdded={this.handleFilesAdded}
            disabled={isUploadingFiles}
          />
        </div>
        <ul>
          {showFileLimitExceededNotice &&
            <Notice
              text="Max file size is 1GB. Some of your files were removed from the list."
              onDismiss={this.dismissNotice}
              type="warning"
            />
          }
          {files.map(file => (
            <FileToUploadListItem
              key={file.tempId}
              file={file}
              onFileNameChange={value => this.handleFileNameChange(file.tempId, value)}
              onPrivacyChange={value => this.handlePrivacyChange(file.tempId, value)}
              onRemove={value => this.handleRemove(file.tempId, value)}
            />
          ))}
        </ul>
        {files.length > 0 &&
          <div className="actions">
            <Button
              disabled={isUploadingFiles}
              onClick={this.handleUploadFiles}
            > Upload
            </Button>
          </div>
        }
      </div>
    );
  }
}

FileUpload.propTypes = {
  analyticProperties: PropTypes.shape({
    actionPrefix: PropTypes.string,
    categoryPrefix: PropTypes.string,
  }).isRequired,
  dealId: PropTypes.number.isRequired,
  uploadFile: PropTypes.func.isRequired,
};

export default FileUpload;
