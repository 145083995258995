import userEventService from './user-event-service';

let previousLocation = null;

const trackNavigationEvent = (toState, fromState) => {
  const eventMetadata = {
    eventCategory: 'Navigation',
    eventAction: 'navigation',
    eventLabel: toState,
    eventData: {
      fromState,
      toState,
    },
  };

  userEventService.trackEvent(eventMetadata);
};

const onRouteChange = () => {
  if (window.location.href === previousLocation) { return; }
  trackNavigationEvent(window.location.href, previousLocation);
  previousLocation = window.location.href;
};

export default { onRouteChange };
