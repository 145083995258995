// eslint-disable-next-line
const convertDealTypeRepRole = (dealType, repRole) => {
  if (repRole === 'tenant') {
    return { display_name: 'Lease - Tenant', value: 'tenant' };
  }
  if (repRole === 'landlord') {
    return { display_name: 'Lease - Landlord', value: 'landlord' };
  }
  if (repRole === 'buyer') {
    return { display_name: 'Sale - Buyer', value: 'buyer' };
  }
  if (repRole === 'seller') {
    return { display_name: 'Sale - Seller', value: 'seller' };
  }
  if (repRole === 'consulting') {
    return { display_name: 'Consulting', value: 'consulting' };
  }
  if (repRole === 'other') {
    return { display_name: 'Other', value: 'other' };
  }
};


export default convertDealTypeRepRole;
