import { Icon, IconButton } from '@cbrebuild/blocks';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Percent from '../../../nucleus/formats/percent';
import VOUCHER_ROUTES from '../../../page-templates/voucher/voucher-subroutes';
import { BROKER_TYPES } from '../../../services/voucher/commissions-service';
import formatAsCurrency from '../../../utils/format-as-currency';

const CommissionsReview = () => {
  const {
    commissions, officeCommissions, poolCommissions, voucher,
  } = useSelector(state => ({
    commissions: state.commissions.commissions.filter(({ cbre_broker_type }) => cbre_broker_type === BROKER_TYPES.EMPLOYEE),
    officeCommissions: state.commissions.commissions.filter(({ cbre_broker_type }) => cbre_broker_type === BROKER_TYPES.OFFICE),
    poolCommissions: state.commissions.commissions.filter(({ cbre_broker_type }) => cbre_broker_type === BROKER_TYPES.POOL),
    voucher: state.voucher.voucher,
  }));
  const history = useHistory();
  const url = VOUCHER_ROUTES[2].routes[2].path.replace(':id', voucher.deal);
  const requiredText = () => {
    localStorage.setItem('voucher_valid', JSON.stringify(false));
    return (<p className="voucher-error-text">Required</p>);
  };
  const displayError = !voucher.is_early_invoice && !commissions.length;
  if (displayError) localStorage.setItem('voucher_valid', JSON.stringify(false));
  return (
    <div className="voucher-review-group commissions-review">
      <div className="navigation">
        <Link className="h2 link" to={url}>Commissions</Link>
        <IconButton iconName="edit" onClick={() => { history.push(url); }} />
      </div>
      <div className="content">
        <h3>Commissions</h3>
        {displayError
          && <div className="voucher-error-text">You must add at least one commission to this {voucher.is_early_invoice ? 'early invoice' : 'voucher'}</div>}
        {commissions.length ?
          <table>
            <colgroup>
              <col name="broker" />
              <col name="rep role" />
              <col name="allocation" />
              <col name="amount" />
              <col name="producer roler" />
              <col name="lead producer" />
            </colgroup>
            <tbody>
              <tr className="borderless-row">
                <th>Broker</th>
                <th>Rep Role</th>
                <th>Allocation</th>
                <th>Amount</th>
                <th>Producer Role</th>
                <th>Lead Producer</th>
              </tr>
              {commissions.map(commission => (
                <tr key={commission.id}>
                  <td>
                    {commission.broker ? (
                      <div className="person-component">
                        <div className="person-textbox">
                          <p className="primary-content">{commission.broker.first_name} {commission.broker.last_name}</p>
                          <p className="secondary-content">{commission.broker.work_email || '--'}</p>
                          {commission.broker.peoplesoft_id && <p className="secondary-content">ID: {commission.broker.peoplesoft_id}</p>}
                          {commission.broker.office_name && <p className="secondary-content">{commission.broker.office_name}</p>}
                        </div>
                      </div>
                    ) : requiredText()}
                  </td>
                  <td>{commission.voucher_broker_rep_role || requiredText()}</td>
                  <td><Percent value={Number(commission.voucher_gross_commission_percent)} emptyState="0%" /></td>
                  <td>{commission.gross_commission ? formatAsCurrency(commission.gross_commission) : requiredText()}</td>
                  <td>{commission.producer_role ? commission.producer_role : requiredText()}</td>
                  <td>{commission.broker_is_lead && <Icon iconName="checkmark" />}</td>
                </tr>
              ))}
            </tbody>
          </table>
        : ''}
        {officeCommissions.length ?
          <table>
            <colgroup>
              <col name="office" />
              <col name="unit" />
              <col name="client name" />
              <col name="allocation" />
              <col name="amount" />
              <col name="producer role" />
              <col name="lead producer" />
            </colgroup>
            <tbody>
              <tr className="borderless-row">
                <th>Office</th>
                <th>Unit</th>
                <th>Client Name</th>
                <th>Allocation</th>
                <th>Amount</th>
                <th>Producer Role</th>
                <th>Lead Producer</th>
              </tr>
              {officeCommissions.map(officeCommission => (
                <tr key={officeCommission.id}>
                  <td>{officeCommission.voucher_office_allocation_name || requiredText()}</td>
                  <td>{officeCommission.deptid || requiredText()}</td>
                  <td>{officeCommission.voucher_office_client_name }</td>
                  <td><Percent value={Number(officeCommission.voucher_gross_commission_percent)} emptyState="0%" /></td>
                  <td>{officeCommission.gross_commission ? formatAsCurrency(officeCommission.gross_commission) : requiredText()}</td>
                  <td>{officeCommission.producer_role || requiredText()}</td>
                  <td>{officeCommission.broker_is_lead && <Icon iconName="checkmark" />}</td>
                </tr>
              ))}
            </tbody>
          </table>
        : ''}
        {poolCommissions.length ?
          <table>
            <colgroup>
              <col name="pool account" />
              <col />
              <col name="allocation" />
              <col name="amount" />
              <col name="producer role" />
              <col name="lead producer" />
            </colgroup>
            <tbody>
              <tr className="borderless-row">
                <th>Pool Account</th>
                <th />
                <th>Allocation</th>
                <th>Amount</th>
                <th>Producer Role</th>
                <th>Lead Producer</th>
              </tr>
              {poolCommissions.map(poolCommission => (
                <tr key={poolCommission.id}>
                  <td>{poolCommission.voucher_cbre_pool_name || requiredText()}</td>
                  <td />
                  <td><Percent value={Number(poolCommission.voucher_gross_commission_percent)} emptyState="0%" /></td>
                  <td>{poolCommission.gross_commission ? formatAsCurrency(poolCommission.gross_commission) : requiredText()}</td>
                  <td>{poolCommission.producer_role || requiredText()}</td>
                  <td>{poolCommission.broker_is_lead && <Icon iconName="checkmark" />}</td>
                </tr>
              ))}
            </tbody>
          </table>
        : ''}
      </div>
    </div>
  );
};

export default CommissionsReview;
