const isEmpty = value => value == null || value === '';

const computeValue = (object, path) =>
  !isEmpty(path) &&
  path.split('.').reduce((a, b) => (a && !isEmpty(a[b]) ? a[b] : ''), object);

const validatePayload = (validationSchema, payload) => {
  let invalidFieldCount = 0;
  const errorMap = {};
  validationSchema.forEach(({
    path, RefName, pattern, message, emptyCheck = false,
  }) => {
    const fieldValue = computeValue(payload, path);
    const patternCheck = isEmpty(pattern) ? true : pattern.test(fieldValue);
    const isValid = isEmpty(fieldValue) ? !emptyCheck : patternCheck;
    const referenceText = RefName || path;
    if (!isValid && !errorMap[referenceText]) {
      invalidFieldCount += 1;
      errorMap[referenceText] = message;
    }
  });
  return {
    isPayloadValid: invalidFieldCount === 0,
    errorMap,
  };
};

export default validatePayload;
