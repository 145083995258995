import {
  FETCH_REBATES_SUCCESS,
  CREATE_REBATE_SUCCESS,
  DELETE_REBATE_SUCCESS,
  UPDATE_REBATE_SUCCESS,
} from '../actions/action-types';


const initialState = {
  rebates: [],
};

const addRebateSuccess = (state, action) => ({
  ...state,
  rebates: [...state.rebates, action.payload],
});

const deleteRebateSuccess = (state, action) => ({
  ...state,
  rebates: state.rebates.filter(({ id }) => id !== action.payload),
});

const fetchRebatesSuccess = (state, action) => ({
  ...state,
  rebates: action.payload,
});

const updateRebateSuccess = (state, action) => ({
  ...state,
  rebates: state.rebates.map(rebate => (
    rebate.id !== action.payload.id
      ? rebate : action.payload
  )),
});

const rebates = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_REBATE_SUCCESS:
      return addRebateSuccess(state, action);
    case DELETE_REBATE_SUCCESS:
      return deleteRebateSuccess(state, action);
    case FETCH_REBATES_SUCCESS:
      return fetchRebatesSuccess(state, action);
    case UPDATE_REBATE_SUCCESS:
      return updateRebateSuccess(state, action);
    default:
      return state;
  }
};

export default rebates;
