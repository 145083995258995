import { connect } from 'react-redux';

import Toaster from '../../nucleus/toast/toaster';

const mapStateToProps = state => ({
  toasts: state.toasts.toasts,
});

const ToasterContainer = connect(mapStateToProps)(Toaster);

export default ToasterContainer;
