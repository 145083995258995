import getOperatingSystem from '../utils/get-operating-system';
import superFetch from '../utils/super-fetch';
import userService from './user-service';

/* global CONFIG */
// console.log(CONFIG);
const { gaTrackingID, userEventsApi } = CONFIG;

const trackEvent = (data, analyticProperties = {}) => {
  userService.getUser().then((user) => {
    const userID = user.id;

    const {
      actionPrefix,
      categoryPrefix,
    } = analyticProperties;

    const eventMetadata = {
      eventCategory: `${categoryPrefix || ''}${data.eventCategory}`,
      eventLabel: data.eventLabel || ((actionPrefix && data.eventAction) ?
        `${data.eventAction}_from_${actionPrefix}` : undefined),
      eventValue: data.eventValue,
      eventAction: data.eventAction,
      eventData: data.eventData,
    };

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const gaEnabled = window.ga && gaTrackingID;
    if (gaEnabled) {
      window.ga('create', gaTrackingID);
      // send event to GA
      const gaOptions = {
        hitType: 'event',
      };

      // look in metadata for category and label
      if (eventMetadata.eventCategory) {
        gaOptions.eventCategory = eventMetadata.eventCategory;
      }
      if (eventMetadata.eventLabel) {
        gaOptions.eventLabel = eventMetadata.eventLabel;
      }
      if (eventMetadata.eventValue) {
        gaOptions.eventValue = eventMetadata.eventValue;
      }
      if (eventMetadata.eventAction) {
        gaOptions.eventAction = eventMetadata.eventAction;
      }

      window.ga('send', gaOptions);
    }

    // send event to pawprint if in staging/prod
    if (userEventsApi) {
      const pawprintOptions = {
        event: eventMetadata.eventAction,
        user_id: userID,
        timestamp: new Date().toUTCString(),
        metadata: {
          platform: getOperatingSystem(userAgent),
          eventCategory: eventMetadata.eventCategory,
          eventLabel: eventMetadata.eventLabel,
          eventValue: eventMetadata.eventValue,
          ...eventMetadata.eventData,
        },
      };

      console.log(data);
      const httpOptions = {
        method: 'POST',
      };
      superFetch(userEventsApi, httpOptions, JSON.stringify(pawprintOptions));
    }
  });
};

export default { trackEvent };
