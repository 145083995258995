import { connect } from 'react-redux';
import { loadGlobalSearchResults, loadDealsAndTransactionsGlobalSearchResults, clearSearchResultsSuccess } from '../actions/global-search-actions';

import GlobalSearch from '../../components/global-search/global-search';

const mapStateToProps = state => ({
  metadata: state.globalSearch.metadata,
  searchResults: state.globalSearch.searchResults,
  isLoading: state.globalSearch.isLoading,
  featureFlags: state.userOptions.userOptions.feature_flags || {},
  // User Data State
  userDataID: state.userData.data.id,
});

const mapDispatchToProps = dispatch => ({
  setGlobalSearchState: (search, page) => dispatch(loadGlobalSearchResults(search, page)),
  setDealsAndTransactionsGlobalSearchState: (search, page) => dispatch(loadDealsAndTransactionsGlobalSearchResults(search, page)),
  clearSearchResultsSuccess: () => dispatch(clearSearchResultsSuccess()),
});

const GlobalSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlobalSearch);

export default GlobalSearchContainer;
