import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import PageHeaderDefault from '../../nucleus/header/page-header-default';
import subroutes from './detail-subroutes';

import DealDetailHeaderContainer from '../../components/headers/deal-detail-header/deal-detail-header-container';
import proxyService from '../../services/proxy-service';

class DealDetailPage extends React.Component {
  state = {
    isLoadingDeal: false,
  };
  componentDidMount() {
    this.loadDeal(true);
  }

  // Redirects deal detail page to dash board when user left the deal.
  // Should happen on page level to avoid error on dealing with the deal that has no user access.
  componentDidUpdate(prevProps) {
    if (prevProps.deal && this.props.deal && parseInt(this.props.match.params.id, 10) !== this.props.deal.id && !this.state.isLoadingDeal) {
      this.loadDeal();
    } else if (
      (prevProps.dealUsers.length !== 0 && prevProps.dealUsers !== this.props.dealUsers) ||
      (prevProps.dealTeams.length !== 0 && prevProps.dealTeams !== this.props.dealTeams)) {
      const isUserOnDeal = this.props.dealUsers.findIndex(member => parseInt(member.user.id, 10) === this.props.userData.id) > -1;
      const isUserOnCommission = this.props.relatedTransactionUsers.findIndex(member => parseInt(member.user.id, 10) === this.props.userData.id) > -1;
      const isUserOnTeam = this.props.dealTeams // Map through each team's array of users to find if user is a member of any team
        .map(dealTeam => dealTeam.team.users
          .findIndex(member => parseInt(member.id, 10) === this.props.userData.id) > -1)
        .findIndex(item => item === true) > -1;
      // Map through each commission team's array of users to find if user is a member of any team
      const isUserOnCommissionTeam = this.props.relatedTransactionTeams
        .map(commissionTeam => commissionTeam.team.users
          .findIndex(member => parseInt(member.id, 10) === this.props.userData.id) > -1)
        .findIndex(item => item === true) > -1;
      if (!isUserOnDeal && !isUserOnTeam && !isUserOnCommission && !isUserOnCommissionTeam) {
        // Need to check if user is proxy
        if (this.props.dealUsers.length !== 0) {
          this.props.dealUsers.forEach(async (dealUser) => {
            const proxyAccess = await proxyService.fetchProxyUser({ deal_id: dealUser.deal });
            const dealToCheck = proxyAccess.find(deal => parseInt(deal.deal_id, 10) === dealUser.deal);
            const foundProxyAccess = dealToCheck.proxy_users.find(user => user.id === this.props.userData.id);
            if (!foundProxyAccess) {
              window.location.assign('/');
            }
          });
        } else {
          window.location.assign('/');
        }
      }
    }
  }
  componentWillUnmount() {
    this.props.clearDealStore();
  }

  loadDeal = (clearStore = false) => {
    const {
      setDealState,
    } = this.props;
    this.setState({ isLoadingDeal: true });
    const dealId = this.props.match.params.id;
    setDealState(dealId, clearStore)
      .then(() => {
        this.setState({ isLoadingDeal: false });
      });
    this.props.loadDealTeam({ deal: dealId, page_size: 999 });
  };

  commissionsHidden = () => {
    const {
      deal: {
        is_mta,
        linked_mta_transactions: linkedTransactions,
        deal_life_cycle_stage: dealStage,
      },
    } = this.props;
    if (dealStage === 'closed' && is_mta) {
      return false;
    }
    return !(!is_mta && (linkedTransactions && linkedTransactions.length !== 0));
  };

  propertiesHidden = () => {
    const {
      deal: {
        is_mta,
        linked_mta_transactions: linkedTransactions,
        deal_life_cycle_stage: dealStage,
      },
    } = this.props;

    if (is_mta || dealStage === 'closed' || (linkedTransactions && linkedTransactions.length !== 0)) {
      return true;
    }
    return false;
  };

  vouchersHidden = () => {
    const {
      deal,
    } = this.props;
    return !(deal.linked_mta_transactions && deal.linked_mta_transactions.length !== 0);
  };

  pipelineDisabled = () => {
    const {
      deal,
    } = this.props;

    return (deal.is_mta || (deal.linked_mta_transactions && deal.linked_mta_transactions.length !== 0));
  };

  isViewOnly = () => {
    const {
      userData: {
        id: userId,
      },
      deal: {
        permissions: {
          denormalized_user_ids,
        },
      },
    } = this.props;
    return !denormalized_user_ids.includes(userId);
  };

  render() {
    const {
      deal,
      dealUsers,
      dealTeams,
      userOptions: {
        feature_flags,
      },
      updateDealState,
      match,
    } = this.props;

    const analyticProperties = {
      actionPrefix: 'deal_details',
      categoryPrefix: 'Deal Details ',
    };

    if (!deal || deal.id !== parseInt(match.params.id, 10)) {
      return null;
    }

    if (deal.is_deleted) {
      let stagePath = this.props.deal.deal_life_cycle_stage;
      if (this.props.deal.deal_life_cycle_stage === 'prospect') {
        stagePath += 's';
      }
      return <Redirect to={`/deals/${stagePath}`} />;
    }
    const loadingState = '';

    // map deal-stage to barColor
    const barColors = {
      prospect: 'blue',
      executing: 'orange',
      closed: 'lime',
    };

    const archived = deal.is_archived ? 'archived' : '';

    const dealStage = deal.deal_life_cycle_stage;
    const viewOnly = this.isViewOnly();
    const commissionLink = `/deals/detail/${deal.id}/commissions`;
    const voucherLink = `/deals/detail/${deal.id}/voucher`;
    if (viewOnly &&
      this.props.location.pathname !== commissionLink &&
      this.props.location.pathname !== voucherLink) {
      return <Redirect to={commissionLink} />;
    }

    const tabs = dealId => [
      {
        link: `/deals/detail/${dealId}/overview`,
        text: 'Overview',
        disabled: viewOnly,
      },
      {
        link: `/deals/detail/${dealId}/notes`,
        text: 'Notes',
        disabled: viewOnly,
      },
      {
        link: `/deals/detail/${dealId}/tasks`,
        text: 'Tasks',
        disabled: viewOnly,
      },
      {
        link: `/deals/detail/${dealId}/files`,
        text: 'Files',
        disabled: viewOnly,
      },
      {
        link: `/deals/detail/${dealId}/properties`,
        text: 'Properties',
        hidden: this.propertiesHidden(),
        disabled: viewOnly,
      },
      {
        link: `/deals/detail/${dealId}/contacts`,
        text: 'Deal Contacts',
        disabled: viewOnly,
      },
      {
        link: `/deals/detail/${dealId}/voucher`,
        text: 'Voucher',
        hidden: this.vouchersHidden(),
      },
      {
        link: `/deals/detail/${dealId}/pipeline`,
        text: 'Pipeline Details',
        disabled: this.pipelineDisabled(),
      },
      {
        link: `/deals/detail/${dealId}/commissions`,
        text: 'Commissions',
        secondary: true,
        hidden: this.commissionsHidden(),
      },
    ];
    const filterSubroutes = ({ flag }) =>
      feature_flags[flag] === undefined
      || feature_flags[flag] === true;

    const renderContent = () => {
      document.title = `Deal IQ | ${deal.name}`;
      const {
        userOptions,
      } = this.props;
      return (
        <React.Fragment key={`deal-details-${deal.id}`}>
          <PageHeaderDefault
            barColor={`${barColors[dealStage]}`}
            additionalClasses={`${archived}`}
            tabs={tabs(deal.id, feature_flags)}
            pageUrl={this.props.location.pathname}
          >
            <DealDetailHeaderContainer
              analyticProperties={analyticProperties}
              deal={deal}
              updateDeal={updateDealState}
              dealUsers={dealUsers}
              dealTeams={dealTeams}
              featureFlags={feature_flags}
              viewOnly={viewOnly}
            />
          </PageHeaderDefault>
          <div className="page-container">
            {feature_flags &&
              subroutes
                .filter(filterSubroutes)
                .map((route) => {
                  const RouteComponent = route.component;
                  return (
                    <Route
                      path={route.path}
                      exact={route.exact}
                      key={route.path}
                      render={() => (<RouteComponent
                        analyticProperties={analyticProperties}
                        deal={deal}
                        updateDeal={updateDealState}
                        key={`deal-${deal.id}-tab`}
                        taskId={this.props.location.taskId}
                        feature_flags={userOptions && userOptions.feature_flags}
                      />)
                      }
                    />
                  );
                })
            }
          </div>
        </React.Fragment>
      );
    };

    return !deal || this.state.isLoadingDeal
      ? loadingState
      : renderContent();
  }
}

DealDetailPage.propTypes = {
  deal: PropTypes.shape(),
  dealUsers: PropTypes.arrayOf(PropTypes.shape()),
  relatedTransactionUsers: PropTypes.arrayOf(PropTypes.shape()),
  relatedTransactionTeams: PropTypes.arrayOf(PropTypes.shape()),
  dealTeams: PropTypes.arrayOf(PropTypes.shape()),
  setDealState: PropTypes.func,
  loadDealTeam: PropTypes.func,
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    taskId: PropTypes.number,
  }).isRequired,
  updateDealState: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    id: PropTypes.number,
  }),
  userOptions: PropTypes.shape({
    bleeding_edge_toggle_on: PropTypes.bool,
    feature_flags: PropTypes.shape({}),
    labs_toggle_on: PropTypes.bool,
  }),
  clearDealStore: PropTypes.func.isRequired,
};

DealDetailPage.defaultProps = {
  deal: null,
  dealUsers: [],
  relatedTransactionUsers: [],
  relatedTransactionTeams: [],
  dealTeams: [],
  userOptions: {
    bleeding_edge_toggle_on: false,
    feature_flags: null,
    labs_toggle_on: false,
  },
  userData: {
    id: null,
  },
  setDealState: () => { },
  loadDealTeam: () => { },
};

export default DealDetailPage;
