import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';
import RowItem from '../../nucleus/row-item/row-item';
import { Person, PersonType } from '../person';

import BrokerAutocomplete from '../autocomplete/broker-autocomplete';
import Modal from '../../nucleus/modal/modal';
import AddTeamMemberModal from './add-team-member-modal';
import { fetchTeamByID } from '../../services/teams-service';

// import DealIQLogo from '../../assets/images/svg/deal-iq-logo-only.svg';
import DealIQLogo from '../../assets/images/svg/loading-icon.svg';

class TeamCardMembers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showConfirmAddTeamMemberModal: false,
      memberToRemove: undefined,
      teamMembersData: [],
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.id) {
      (async () => {
        this.setState({
          loading: true,
        });
        await fetchTeamByID(this.props.id, this.props.isAdminPage)
          .then((res) => {
            if (res && res.users) {
              this.setState({
                teamMembersData: res.users,
                loading: false,
              });
            }
          });
      })();
    }
  }

  renderTeamMembers() {
    const {
      teamMembers,
      isCreating,
      userData,
      isAdminPage,
    } = this.props;
    return (
      teamMembers.map(teamMember => (
        <RowItem key={teamMember.id}>
          <Person
            person={teamMember}
            type={PersonType.MEMBER}
            isSelf={teamMember.id === userData.id}
            hoverAlign="left"
            note={`Default Split: 
            ${this.state.teamMembersData.find(data => data.id === teamMember.id)?.default_splits_percentage || '0'}%`
          }
          />
          {isCreating && (isAdminPage || teamMember.id !== userData.id) &&
            <IconButton
              className="blxs-button-icon-small"
              iconName="close"
              onClick={() => {
                this.handleRemoveTeamMember(teamMember);
              }}
              variant="basic"
            />}
          {!isCreating && teamMembers &&
            <IconButton
              className="blxs-button-icon-small"
              iconName="close"
              onClick={() => {
                this.showConfirmationModal(teamMember);
              }}
              variant="basic"
            />}
        </RowItem>
      ))
    );
  }

  handleAddTeamMember = (newTeamMember) => {
    const {
      dealCount,
      id,
    } = this.props;

    if (dealCount > 0 && newTeamMember) {
      this.setState({
        showConfirmAddTeamMemberModal: true,
        newTeamMember,
      });
    } else if ((!id || dealCount === 0) && newTeamMember) {
      this.handleConfirmAddTeamMember(newTeamMember);
    }
  };

  handleRemoveTeamMember = (memberToRemove, removingSelf) => {
    const {
      id,
      teamName,
      onRemoveTeamMember,
      onRemoveTeamAndMember,
    } = this.props;
    const {
      isCreating,
      showModal,
    } = this.state;
    const data = {
      id,
      teamName,
      memberToRemove,
    };
    if (memberToRemove && !removingSelf) {
      onRemoveTeamMember(data);
    } else if (memberToRemove && removingSelf) {
      onRemoveTeamAndMember(data);
    }
    if (showModal && !isCreating) {
      this.closeConfirmationModal();
    }
  };

  handleConfirmAddTeamMember = (newTeamMember) => {
    const {
      id,
      teamName,
      onAddTeamMember,
    } = this.props;

    const data = {
      id,
      teamName,
      newTeamMember,
    };
    onAddTeamMember(data);
    this.toggleConfirmAddTeamMemberModal();
  };

  toggleConfirmAddTeamMemberModal = () => {
    this.setState({
      showConfirmAddTeamMemberModal: false,
      newTeamMember: undefined,
    });
  };

  closeConfirmationModal = () => {
    this.setState({
      showModal: false,
      memberToRemove: undefined,
    });
  };

  showConfirmationModal = (teamMember) => {
    this.setState({
      showModal: true,
      memberToRemove: teamMember,
    });
  };

  renderConfirmationModal = () => {
    const {
      isCreating,
      teamName,
      userData,
    } = this.props;
    const {
      showModal,
      memberToRemove,
    } = this.state;
    let modal;

    if (showModal && !isCreating && (memberToRemove.id === userData.id)) {
      modal = (
        <Modal
          className="remove-team-member-modal"
          modalHeader={`Leave Team "${teamName}"`}
          primaryButtonText="Leave Team"
          handleModalSubmit={() => this.handleRemoveTeamMember(memberToRemove, true)}
          secondaryButtonText="Cancel"
          handleSecondaryButton={this.closeConfirmationModal}
          handleModalToggle={this.closeConfirmationModal}
          modalWidth={650}
          showModal
        >
          <div className="confirmation-modal-header">
            <p>Are you sure you want to leave this team? You will lose access to the deals associated with this team.
            </p>
          </div>
        </Modal>);
    } else if (showModal && !isCreating && memberToRemove) {
      modal = (
        <Modal
          className="remove-team-member-modal"
          modalHeader="Remove Team Member"
          primaryButtonText="Remove"
          handleModalSubmit={() => this.handleRemoveTeamMember(memberToRemove, false)}
          secondaryButtonText="Cancel"
          handleSecondaryButton={this.closeConfirmationModal}
          handleModalToggle={this.closeConfirmationModal}
          modalWidth={650}
          showModal
        >
          <div className="confirmation-modal-header">
            <p>Are you sure you want to remove &quot;{memberToRemove.first_name} {memberToRemove.last_name}&quot; from this team?</p>
          </div>
        </Modal>);
    }
    return modal;
  };

  renderConfirmAddTeamMemberModal = () => {
    const {
      newTeamMember,
    } = this.state;
    const {
      id,
      dealCount,
    } = this.props;
    if (newTeamMember && dealCount && id) {
      return (
        <AddTeamMemberModal
          teamId={id}
          showModal={this.state.showConfirmAddTeamMemberModal}
          handleModalToggle={this.toggleConfirmAddTeamMemberModal}
          handleModalSubmit={this.handleConfirmAddTeamMember}
          newTeamMember={newTeamMember}
          dealCount={dealCount}
        />
      );
    }
    return false;
  };

  render() {
    const {
      showConfirmAddTeamMemberModal,
    } = this.state;
    const {
      teamMembers,
      isCreating,
      errorMessage,
      isAdminPage,
    } = this.props;

    const filterAutocompleteResultsPredicate = newTeamMember => (!teamMembers.some(member => (newTeamMember.id === member.id)));

    const renderLoadingSpinner = () => (
      <div className="spinner-container">
        <div className="loading-spinner">
          <img
            alt="Loading"
            src={DealIQLogo}
          />
        </div>
      </div>
    );

    return (
      <div className="team-card-members">
        <section>
          <div className="team-card-autocomplete">
            <BrokerAutocomplete
              onSelect={this.handleAddTeamMember}
              placeholder="Add People to Team"
              filterResultsPredicate={filterAutocompleteResultsPredicate}
            />
          </div>
          {isCreating &&
          <div>
            <h4>
              Team Members
            </h4>
            <p className={`${isAdminPage && errorMessage ? 'missing-team-members' : ''}`}>{isAdminPage && errorMessage}</p>
          </div>
          }
          <div className={this.state.loading ? 'loading-element' : 'member-container'}>
            {this.state.loading && renderLoadingSpinner()}
            {!this.state.loading && this.renderTeamMembers()}
          </div>
        </section>
        {this.renderConfirmationModal()}
        {showConfirmAddTeamMemberModal && this.renderConfirmAddTeamMemberModal()}
      </div>
    );
  }
}

TeamCardMembers.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  teamMembers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.shape({
      work: PropTypes.string,
    }),
    avatar: PropTypes.string,
  })),
  teamName: PropTypes.string,
  dealCount: PropTypes.number,
  id: PropTypes.number,
  isCreating: PropTypes.bool,
  isAdminPage: PropTypes.bool,
  onAddTeamMember: PropTypes.func.isRequired,
  onRemoveTeamMember: PropTypes.func.isRequired,
  onRemoveTeamAndMember: PropTypes.func,
  errorMessage: PropTypes.string,
};

TeamCardMembers.defaultProps = {
  teamMembers: [],
  teamName: '',
  dealCount: -1, // a positive number would falsely show in the modal and 0 would submit without confirmation
  id: undefined,
  isCreating: false,
  isAdminPage: false,
  onRemoveTeamAndMember: () => {
  },
  errorMessage: '',
};

export default TeamCardMembers;
