import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import './file-upload-accordian.scss';

const FileUploadAccordian = ({ children, accordianTitle }) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <>
      <div
        onClick={() => {
          setExpanded(!expanded);
        }}
        className="accordian-header"
      >
        <p className="accordian-title">{accordianTitle}</p>
        {expanded ? <FaChevronDown /> : <FaChevronUp />}
      </div>
      {expanded && <div className="accordian-body">{children}</div>}
      <div className="accordian-divider" />
    </>
  );
};

FileUploadAccordian.propTypes = {
  accordianTitle: PropTypes.string.isRequired,
  children: PropTypes.node,
};

FileUploadAccordian.defaultProps = {
  children: null,
};

export default FileUploadAccordian;
