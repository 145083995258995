import considerationsService from '../../services/voucher/considerations-service';

import {
  FETCH_CONSIDERATIONS_BY_DEAL_ID_SUCCESS,
  FETCH_VOUCHER_OPTIONS_SUCCESS,
  CREATE_CONSIDERATION_SUCCESS,
  DELETE_CONSIDERATION_SUCCESS,
  UPDATE_CONSIDERATION_SUCCESS,
  UPDATE_CONSIDERATION_STATE,
} from './action-types';
import { updateFinancials, FINANCIAL_TYPES } from './financial-actions';
import REQUEST_STATE from '../../utils/request-state';

// action creators
const fetchLeaseConsiderationTypeOptionsSuccess = options => ({
  type: FETCH_VOUCHER_OPTIONS_SUCCESS,
  payload: { leaseConsiderationTypeOptions: options },
});

const fetchConsiderationsSuccess = considerations => ({
  type: FETCH_CONSIDERATIONS_BY_DEAL_ID_SUCCESS,
  payload: considerations,
});

const createConsiderationSuccess = consideration => ({
  type: CREATE_CONSIDERATION_SUCCESS,
  payload: consideration,
});

const deleteConsiderationSuccess = id => ({
  type: DELETE_CONSIDERATION_SUCCESS,
  payload: id,
});

const updateConsiderationSuccess = consideration => ({
  type: UPDATE_CONSIDERATION_SUCCESS,
  payload: consideration,
});

const updateConsiderationState = state => ({
  type: UPDATE_CONSIDERATION_STATE,
  payload: state,
});


// dispatch
const fetchLeaseConsiderationTypeOptions = () => dispatch =>
  considerationsService.fetchLeaseConsiderationTypeOptions()
    .then(options => dispatch(fetchLeaseConsiderationTypeOptionsSuccess(options)))
    .catch(error => console.error('Error fetching lease consideration type options: ', error));

const createConsideration = post => dispatch =>
  considerationsService.createConsideration(post)
    .then(res => dispatch(createConsiderationSuccess(res)))
    .catch(error => console.error('Error creating considerations: ', error));

const deleteConsideration = id => (dispatch, getState) =>
  considerationsService.deleteConsideration(id)
    .then(() => {
      dispatch(updateFinancials(getState().deal.deal.id, FINANCIAL_TYPES.CONSIDERATION));
      dispatch(deleteConsiderationSuccess(id));
    })
    .catch(error => console.error('Error deleting consideration: ', error));

const updateConsideration = (id, patch) => dispatch =>
  considerationsService.patchConsideration(id, patch)
    .then((res) => {
      // only patch financials if commission amount changes
      if ('commission_amount' in patch || 'commission_percent' in patch) {
        dispatch(updateFinancials(res.transaction, FINANCIAL_TYPES.CONSIDERATION));
      }
      dispatch(updateConsiderationSuccess(res));
    })
    .catch(error => console.error('Error updating consideration: ', error));

const fetchConsiderations = dealId => (dispatch) => {
  dispatch(updateConsiderationState(REQUEST_STATE.LOADING));
  return considerationsService.fetchConsiderations(dealId)
    .then(considerations => dispatch(fetchConsiderationsSuccess(considerations)))
    .catch(error => console.error('Error fetching considerations: ', error))
    .finally(() => dispatch(updateConsiderationState(REQUEST_STATE.NONE)));
};

export {
  createConsideration,
  deleteConsideration,
  fetchConsiderations,
  updateConsideration,
  fetchLeaseConsiderationTypeOptions,
};
