import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';

/**
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @param {number} precision
 * @param {number} integerPrecision
 * @returns {{invalid:boolean; outOfRange: boolean}}
 */
export const validate = (value, min = null, max = null, precision = null, integerPrecision = null) => {
  const validMin = min !== null;
  const validMax = max !== null;
  const validPrecision = precision !== null;
  const validIntegerPrecision = integerPrecision !== null;
  const state = {
    invalid: false,
    outOfRange: false,
  };
  if (!value) {
    return state;
  }
  if (value === '.' || value === '-') {
    state.invalid = true;
  } else if (
    (validMin && validMax && (value < min || value > max))
    || (!validMax && validMin && value < min)
    || (!validMin && validMax && value > max)
    || (validIntegerPrecision && value.length > integerPrecision)
  ) {
    state.outOfRange = true;
    state.invalid = true;
  }
  const [, decimal = ''] = value.toString().split('.');
  if (validPrecision && (decimal.length > precision)) {
    state.invalid = true;
  }
  return state;
};


/**
 * @param {string | number} value
 * @param {number} precision
 * @param {number} integerPrecision
 * @returns {string}
 */
export const prettify = (value = '', precision, integerPrecision) => {
  if (!value) return '';
  let stringValue = value.toString();
  // determine if is negative value
  const isNegative = stringValue[0] === '-';
  // strip non numeric and then strip extra -
  stringValue = stringValue.replace(/[^\d.-]/g, '').replace(/[-]/g, '');
  let [integer, ...decimal] = stringValue.split('.');
  // remove unneeded zeros up to 5 indexes in
  integer = integer.length ? parseInt(integer.slice(0, 5), 10) + integer.slice(5) : integer;
  if (integer && integer.length) {
    integer = integer.slice(0, integerPrecision || integer.length);
  }
  // adds commas as needed
  integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // if multiple periods were inputted we join the numbers back together
  decimal = decimal.length > 1 ? decimal.join('') : decimal[0];
  // split will return an '' string if . otherwise it will be undefined
  if (decimal === '') {
    decimal = '.';
  } else if (decimal && decimal.length) {
    decimal = `.${decimal.slice(0, precision || decimal.length)}`;
  }
  return `${isNegative ? '-' : ''}${integer}${decimal || ''}`;
};

/**
 * @param {string} value
 * @param {number} precision
 * @param {number} integerPrecision
 * @returns {number}
 */
export const dePrettify = (value, precision, integerPrecision) => {
  if (!value) return null;
  // determine if is negative value
  const isNegative = typeof value === 'number' ? value < 0 : value[0] === '-';
  // strip non numeric and then strip extra -
  const stripped = value.toString().replace(/[^\d.-]/g, '').replace(/[-]/g, '');
  const [integer, decimal = ''] = stripped.split('.');
  const updatedInteger = integer && integer.length ? integer.slice(0, integerPrecision || integer.length) : integer;
  const updatedDecimal = (decimal && decimal.length) ? `.${decimal.slice(0, precision || decimal.length)}` : '';
  const result = parseFloat(`${isNegative ? '-' : ''}${updatedInteger}${updatedDecimal}`, 10);
  return result;
};

/**
 * @param {string} original
 * @param {string} modified
 * @param {number} currentPosition
 * @returns {number}
 */
export const getUpdatedCursorLocation = (original, modified, currentPosition) => {
  if (currentPosition !== original.length) {
    if (currentPosition && (currentPosition === '.' || original[currentPosition - 1].replace(/[^\d]/g, ''))) {
      // count the amount of commas and - and move cursor to correct position
      const valNonNumericCount = (original.slice(0, currentPosition).match(/-|,/g) || []).length;
      const updatedNonNumericCount = (modified.slice(0, currentPosition).match(/-|,/g) || []).length;
      const shift = updatedNonNumericCount - valNonNumericCount;
      const cursorPosition = shift + currentPosition;
      return cursorPosition;
    }
    return currentPosition - 1;
  }
  return null;
};

const NumberInput = ({
  label,
  value,
  precision,
  min,
  max,
  integerPrecision,
  onBlur,
  updateInvalidStatus,
  updateOutOfRangeStatus,
  updateValue,
  disabled,
  width,
  placeholder,
  inputRef,
}) => {
  const [displayValue, setDisplayValue] = useState(prettify(value));
  const [errorState, setErrorState] = useState({
    invalid: false, outOfRange: false,
  });
  const [focused, setFocused] = useState(false);

  // when value prop changes and component is not focused update display value
  useEffect(() => {
    if (!focused && !errorState.invalid && !errorState.outOfRange) {
      setDisplayValue(prettify(value));
      // maybe this should be another useEffect?
      if (value !== displayValue) {
        const validation = validate(value, min, max, precision);
        if (validation.invalid) {
          updateInvalidStatus();
        }
        if (validation.outOfRange) {
          updateOutOfRangeStatus();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, focused, min, max, precision]);

  const handleChange = ({ target }) => {
    const { value: val, selectionStart } = target;
    const updated = prettify(val, precision, integerPrecision);

    setDisplayValue(updated);

    window.requestAnimationFrame(() => {
      const cursorPosition = getUpdatedCursorLocation(val, updated, selectionStart);
      if (cursorPosition !== null) {
        target.setSelectionRange(cursorPosition, cursorPosition);
      }
    });

    const updatedValue = dePrettify(val, precision, integerPrecision);

    const validation = validate(updatedValue, min, max, integerPrecision, precision);
    if (!validation.invalid && !validation.outOfRange) {
      updateValue(updatedValue);
    }
    setErrorState(validation);
    if (validation.invalid) {
      updateInvalidStatus();
    }
    if (validation.outOfRange) {
      updateOutOfRangeStatus();
    }
  };

  return (
    <div className={`form-group ${width}`}>
      {label && <label>{label}</label>}
      <input
        className="number-input"
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        value={displayValue}
        ref={inputRef}
        aria-invalid={errorState.invalid}
        onFocus={() => setFocused(true)}
        onBlur={(e) => {
          setFocused(false);
          onBlur(e);
        }}
        disabled={disabled}
      />
    </div>
  );
};

NumberInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  min: PropTypes.number,
  precision: PropTypes.number,
  max: PropTypes.number,
  integerPrecision: PropTypes.number,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  updateValue: PropTypes.func,
  onBlur: PropTypes.func,
  updateInvalidStatus: PropTypes.func,
  updateOutOfRangeStatus: PropTypes.func,
  disabled: PropTypes.bool,
  width: PropTypes.oneOf(['small', 'medium', 'large', 'x-large', '']),
  placeholder: PropTypes.string,
};

NumberInput.defaultProps = {
  label: '',
  value: null,
  precision: null,
  integerPrecision: null,
  min: null,
  max: null,
  onBlur: () => {},
  updateInvalidStatus: () => {},
  updateOutOfRangeStatus: () => {},
  disabled: false,
  updateValue: () => {},
  width: '',
  placeholder: '',
  inputRef: null,
};

export default NumberInput;
