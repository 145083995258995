import React from 'react';
import { Card } from '../../nucleus/cards/cards';
import Notice from '../../nucleus/notices/notice';

function FiftyFifty() {
  return (
    <div className="page-container">
      <Notice text="This is Warning Notice" type="warning" />
      <div className="col-container">
        <div className="col flex-1">
          <Card>
            <h2>Card 1</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nulla a tempor libero. Aenean eu condimentum est. In semper a dolor quis imperdiet.
              Cras et ultrices est. Quisque vestibulum ullamcorper maximus.
              Integer maximus vehicula magna, id vulputate nunc porta ut.
              Proin convallis pretium convallis. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Mauris suscipit
              lacus sit amet vulputate tempor.
            </p>
          </Card>
          <Card>
            <h2>Card 2</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nulla a tempor libero. Aenean eu condimentum est. In semper a dolor quis imperdiet.
              Cras et ultrices est. Quisque vestibulum ullamcorper maximus.
              Integer maximus vehicula magna, id vulputate nunc porta ut.
              Proin convallis pretium convallis. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Mauris suscipit
              lacus sit amet vulputate tempor.
            </p>
          </Card>
        </div>
        <div className="col flex-1">
          <Card>
            <h2>Card 3</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nulla a tempor libero. Aenean eu condimentum est. In semper a dolor quis imperdiet.
              Cras et ultrices est. Quisque vestibulum ullamcorper maximus.
              Integer maximus vehicula magna, id vulputate nunc porta ut.
              Proin convallis pretium convallis. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Mauris suscipit
              lacus sit amet vulputate tempor.
            </p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nulla a tempor libero. Aenean eu condimentum est. In semper a dolor quis imperdiet.
              Cras et ultrices est. Quisque vestibulum ullamcorper maximus.
              Integer maximus vehicula magna, id vulputate nunc porta ut.
              Proin convallis pretium convallis. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Mauris suscipit
              lacus sit amet vulputate tempor.
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default FiftyFifty;
