/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import tracker from '../../services/dashboard-tracking-service';
import traunchDetailsService from '../../services/traunch-details-service';
import useLocalStorage from '../../hooks/use-local-storage';

import Widget from '../../nucleus/widget/widget';
import Currency from '../../nucleus/formats/currency';
import ExpandToggle from '../../nucleus/expand-toggle/expand-toggle';
import ExpandableComponent from '../../nucleus/expandable-component/expandable-component';
import Tooltip from '../../nucleus/tooltip/tooltip';

const CommissionsWidget = ({ user }) => {
  if (!user || !user.id) {
    return null;
  }

  const [totals, setTotals] = useState([]);
  const [isOpen, setIsOpen] = useLocalStorage('commissions-widget-is-open', true);
  const [hasPrevReceivable, setHasPrevReceivable] = useState(false);

  const handleFetch = async () => {
    const params = {
      start_date: moment().startOf('year').format('YYYY-MM-DD'),
      end_date: moment().endOf('year').format('YYYY-MM-DD'),
      user_id: user.id,
    };

    const response = await traunchDetailsService.fetchTraunchDetailsChart(params);

    const paramsForPrevYear = {
      end_date: `${moment().year() - 1}-12-31`,
      start_date: `${moment().year() - 1}-01-01`,
      user_id: user.id,
    };

    const responseForPrevYear = await traunchDetailsService.fetchTraunchDetailsChart(paramsForPrevYear);
    const translatedTotals = traunchDetailsService.translateTotals(response.totals, responseForPrevYear.totals);
    setTotals(translatedTotals);

    const receivablesArray = responseForPrevYear && responseForPrevYear.totals.filter(total => total.payment_status === 'Receivable');
    const prevReceivable = receivablesArray && receivablesArray.length ? receivablesArray[0] : null;
    if (prevReceivable) {
      setHasPrevReceivable(true);
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  const handleExpandToggle = () => {
    tracker.trackCommissionsWidgetToggle(!isOpen);
    setIsOpen(!isOpen);
  };

  return (
    <Widget
      className="commissions-widget"
      title="YTD Commissions"
      controls={<ExpandToggle isOpen={isOpen} onClick={handleExpandToggle} />}
    >
      <ExpandableComponent isOpen={isOpen}>
        {totals.map((total) => {
          let label = '';
          switch (total.payment_status) {
            case 'Paid':
              label = 'Paid This Year';
              break;
            case 'Receivable':
              label = 'Past Due';
              break;
            case 'Upcoming':
              label = 'Upcoming';
              break;
            case 'total':
              label = `${moment().year()} Total`;
              break;
            default:
          }

          return (
            <div className="widget-content-row" key={total.payment_status}>
              <div className="data-label">
                {label}
                {hasPrevReceivable && total.payment_status === 'Receivable' && <Tooltip message={`Includes past due payments from ${moment().year() - 1}`} />}
              </div>
              <div className="data-wrapper">
                <div className="data-row xl-headline">
                  <Currency value={total.gross_amount} />
                  <span className={`data-tag ${total.payment_status}`}>Gross</span>
                </div>
                <div className="data-row h1">
                  <Currency value={total.total_net_amount} />
                  <span className={`data-tag inverse ${total.payment_status}`}>Net</span>
                </div>
              </div>
            </div>
          );
        })}
        <Link
          onClick={tracker.trackCommissionsWidgetLinkToChart}
          className="see-more-link"
          to="/commissions/annual_chart"
        >
          See More
        </Link>
      </ExpandableComponent>
    </Widget>
  );
};

export default CommissionsWidget;

CommissionsWidget.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
};

CommissionsWidget.defaultProps = {
  user: {},
};
