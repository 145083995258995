/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const modalContent = 'modal-content';
const videoButton = 'video-button';

const VideoModal = (props) => {
  const [showModal, toggleShowModal] = useState(props.showModal);

  const handleClick = (e) => {
    if (!(e && e.target && e.target.parentNode)) return;

    if (e.target.parentNode.className === modalContent) {
      setTimeout(() => {
        toggleShowModal(true);
      }, 100);
    } else if (e.target.parentNode.className.includes(videoButton)) {
      // outside click
      toggleShowModal(!showModal);
    } else {
      toggleShowModal(false);
    }
  };

  useMemo(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);

    // unmount
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [showModal]);
  const {
    height,
    width,
    url,
  } = props;

  const renderModal = showModal ? (
    <div
      className="video-modal"
      role="dialog"
    >
      <div className={modalContent}>
        {/* eslint-disable-next-line */}
        <video height={height} width={width} autoPlay> 
          <source src={url} type="video/mp4" />
          <track kind="" srcLang="en" label="English" />
        </video>
      </div>
    </div>) : <div />;

  return renderModal;
};

VideoModal.propTypes = {
  url: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};

VideoModal.defaultProps = {
  height: 450,
  width: 800,
};

export default VideoModal;
