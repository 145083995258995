import unionBy from 'lodash/unionBy';
import {
  DEALS_ARCHIVE_DEAL_SUCCESS,
  DEALS_RESTORE_DEAL_SUCCESS,
  DEALS_DELETE_DEAL_SUCCESS,
  DEALS_UPDATE_DEAL_SUCCESS,
  DEALS_DELETE_DEAL_CONTACT_SUCCESS,
  DEALS_UPDATE_DEAL_CONTACT_SUCCESS,
  DEALS_UPDATE_DEAL_NOTE_SUCCESS,
  DEALS_CLEAR_STORE,
  DEALS_FETCH_DEALS_SUCCESS,
  DEALS_FETCH_DEALS_FAILURE,
  DEALS_FETCH_DEALS_AND_TRANSACTIONS_ES_SUCCESS,
  DEALS_FETCH_DEALS_AND_TRANSACTIONS_ES_FAILURE,
  DEALS_SET_FETCH_REQUEST_ID,
  DEALS_NOTE_AND_TASK_SUCCESS,
  DEALS_NOTE_AND_TASK_FAILURE,
  DEALS_LOAD_PROSPECT_COUNT_SUCCESS,
  DEALS_LOAD_EXECUTING_COUNT_SUCCESS,
  DEALS_LOAD_CLOSED_COUNT_SUCCESS,
  DEALS_LOAD_DEAL_COUNT_FAILURE,
  DEALS_SAVE_QUERY,
} from '../actions/action-types';

const initialState = {
  dealCount: 0,
  dealCountError: undefined,
  dealCountProspect: 0,
  dealCountExecuting: 0,
  dealCountClosed: 0,
  deals: [],
  dealsError: undefined,
  hasNextPage: undefined,
  requestId: undefined,
  searchState: undefined,
  toastMessage: undefined,
  lastQuery: undefined,
};

const archiveDealSuccess = (state, action) => {
  const { deals } = state;
  const { deal } = action.payload;
  const updatedDeals = deals.map(d => (d.id === deal.id ? deal : d));
  return {
    ...state,
    deals: updatedDeals,
  };
};

const restoreDealSuccess = (state, action) => {
  const { deal } = action.payload;
  const deals = state.deals.map(d => (d.id === deal.id ? deal : d));
  return {
    ...state,
    deals,
  };
};

const deleteDealSuccess = (state, action) => {
  const deals = state.deals.filter(d => d.id !== action.payload.id);
  return {
    ...state,
    deals,
    dealCount: state.dealCount - 1,
  };
};

const updateDealSuccess = (state, action) => {
  const { deal } = action.payload;
  const deals = state.deals.map(d => (d.id === deal.id ? deal : d));
  return {
    ...state,
    deals,
  };
};

const updateDealContactSuccess = (state, action) => {
  const { id, contact } = action.payload;
  const deals = state.deals.map(d => (d.id === id
    ? { ...d, primary_deal_contact: contact }
    : d
  ));
  return {
    ...state,
    deals,
  };
};

const deleteDealContactSuccess = (state, action) => {
  const { id } = action.payload;
  const deals = state.deals.map(d => (d.id === id
    ? { ...d, primary_deal_contact: null }
    : d
  ));
  return {
    ...state,
    deals,
  };
};

const updateDealNoteInDealsSuccess = (state, action) => {
  const { id, note } = action.payload;
  const deals = state.deals.map(d => (d.id === id
    ? { ...d, last_modified_note: note }
    : d
  ));
  return {
    ...state,
    deals,
  };
};

const clearDealsStore = state => ({
  ...state,
  dealCount: 0,
  deals: [],
  dealsError: undefined,
  hasNextPage: undefined,
  searchState: undefined,
});

const fetchDealsSuccess = (state, action) => {
  const {
    count,
    next,
    results,
    previous,
  } = action.payload;
  return {
    ...state,
    dealCount: count,
    deals: previous
      ? unionBy(state.deals, results, 'id')
      : results,
    dealsError: null,
    hasNextPage: next,
    requestId: undefined,
  };
};

const fetchDealsAndTransactionsESSuccess = (state, action) => {
  const {
    count,
    next,
    results,
    previous,
  } = action.payload;
  const updatedResults = results.map((deal) => {
    if (!deal.tags) {
      const dealToUpdate = deal;
      dealToUpdate.tags = [];
      return dealToUpdate;
    }
    return deal;
  });
  return {
    ...state,
    dealCount: count,
    deals: previous
      ? unionBy(state.deals, results, 'id')
      : updatedResults,
    dealsError: null,
    hasNextPage: next,
    requestId: undefined,
  };
};

const fetchDealNoteAndTaskSuccess = (state, action) => {
  const { results } = action.payload;
  const { deals } = state;
  results.forEach((res) => {
    const dealToUpdate = deals.find(deal => deal.id === res.id);
    if (dealToUpdate) {
      dealToUpdate.last_modified_note = res.last_modified_note || {};
      dealToUpdate.next_due_tasks = res.next_due_tasks || { results: [], count: 0 };
      dealToUpdate.total_commission_mta = res.total_commission_mta || null;
    }
  });
  return {
    ...state,
    deals: [...deals],
  };
};

const fetchDealNoteAndTaskFailure = (state, action) => {
  const dealIDs = action.payload;
  const { deals } = state;
  dealIDs.forEach((dealID) => {
    const dealToUpdate = deals.find(deal => deal.id === dealID);
    if (dealToUpdate) {
      dealToUpdate.last_modified_note = 'error';
      dealToUpdate.next_due_tasks = 'error';
    }
  });
  return {
    ...state,
    deals: [...deals],
  };
};

const fetchDealsFailure = (state, action) => ({
  ...state,
  dealCount: 0,
  deals: [],
  dealsError: action.payload.error,
  requestId: undefined,
});

const setRequestId = (state, action) => ({
  ...state,
  requestId: action.payload.requestId,
});

const loadProspectCountSuccess = (state, action) => ({
  ...state,
  dealCountProspect: action.payload.dealCount,
});

const loadExecutingCountSuccess = (state, action) => ({
  ...state,
  dealCountExecuting: action.payload.dealCount,
});

const loadClosedCountSuccess = (state, action) => ({
  ...state,
  dealCountClosed: action.payload.dealCount,
});

const loadDealCountFailure = (state, action) => ({
  ...state,
  dealCountError: action.payload.error,
});

const saveQuery = (state, action) => ({
  ...state,
  lastQuery: action.payload.query,
});

const deals = (state = initialState, action) => {
  switch (action.type) {
    case DEALS_ARCHIVE_DEAL_SUCCESS:
      return archiveDealSuccess(state, action);
    case DEALS_RESTORE_DEAL_SUCCESS:
      return restoreDealSuccess(state, action);
    case DEALS_DELETE_DEAL_SUCCESS:
      return deleteDealSuccess(state, action);
    case DEALS_UPDATE_DEAL_SUCCESS:
      return updateDealSuccess(state, action);
    case DEALS_UPDATE_DEAL_CONTACT_SUCCESS:
      return updateDealContactSuccess(state, action);
    case DEALS_DELETE_DEAL_CONTACT_SUCCESS:
      return deleteDealContactSuccess(state, action);
    case DEALS_UPDATE_DEAL_NOTE_SUCCESS:
      return updateDealNoteInDealsSuccess(state, action);
    case DEALS_CLEAR_STORE:
      return clearDealsStore(state);
    case DEALS_SAVE_QUERY:
      return saveQuery(state, action);
    case DEALS_FETCH_DEALS_SUCCESS:
      return fetchDealsSuccess(state, action);
    case DEALS_FETCH_DEALS_AND_TRANSACTIONS_ES_SUCCESS:
      return fetchDealsAndTransactionsESSuccess(state, action);
    case DEALS_FETCH_DEALS_FAILURE:
    case DEALS_FETCH_DEALS_AND_TRANSACTIONS_ES_FAILURE:
      return fetchDealsFailure(state, action);
    case DEALS_NOTE_AND_TASK_SUCCESS:
      return fetchDealNoteAndTaskSuccess(state, action);
    case DEALS_NOTE_AND_TASK_FAILURE:
      return fetchDealNoteAndTaskFailure(state, action);
    case DEALS_SET_FETCH_REQUEST_ID:
      return setRequestId(state, action);
    case DEALS_LOAD_PROSPECT_COUNT_SUCCESS:
      return loadProspectCountSuccess(state, action);
    case DEALS_LOAD_EXECUTING_COUNT_SUCCESS:
      return loadExecutingCountSuccess(state, action);
    case DEALS_LOAD_CLOSED_COUNT_SUCCESS:
      return loadClosedCountSuccess(state, action);
    case DEALS_LOAD_DEAL_COUNT_FAILURE:
      return loadDealCountFailure(state, action);
    default:
      return state;
  }
};

export default deals;
