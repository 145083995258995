import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Button, Icon } from '@cbrebuild/blocks';
import { isEmpty } from 'lodash';
import * as adminService from '../../services/admin-service';

import EmptyState from '../../components/empty-state/empty-state';
import SearchInput from '../../nucleus/search/search-input';
import Tooltip from '../../nucleus/tooltip/tooltip';
import FormGroup from '../../nucleus/form-group/form-group';
import DealDetailHeader from '../../components/headers/deal-detail-header/deal-detail-header';
import Modal from '../../nucleus/modal/modal';
import Toast from '../../nucleus/toast/toast';

const MODAL_STATE = {
  NONE: null,
  SUCCESS: 'success',
  FAILURE: 'failure',
};

const TOAST_MSG = {
  NONE: '',
  LINK_SUCCESS: 'Transaction linked successfully',
  LINK_FAIL: 'Transaction link failed',
  UNLINK_SUCCESS: 'Transaction unlinked successfully',
  UNLINK_FAIL: 'Transaction unlink failed',
};

const AdminLinking = (props) => {
  const { userData, proxy } = props;
  const [modalState, setModalState] = useState(MODAL_STATE.NONE);
  const [deal, setDeal] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [newTransactionID, setNewTransactionID] = useState('');
  const [toastMsg, setToastMsg] = useState(TOAST_MSG.NONE);

  const searchDeal = (searchTerm) => {
    setDeal({});
    setTransactions([]);
    if (searchTerm) {
      adminService.fetchDealsAndTransactionsESForAdmin({ search: searchTerm })
        .then((dealResponse) => {
          const matchingDeal = dealResponse.results.filter(result => result.is_mta === false);
          setDeal(matchingDeal.length ? matchingDeal[0] : {});
          if (matchingDeal.length && matchingDeal[0].linked_mta_transactions && matchingDeal[0].linked_mta_transactions.length) {
            const promises = [];
            matchingDeal[0].linked_mta_transactions.map(transaction => promises.push(adminService.fetchDealForAdmin(transaction)));
            Promise.all(promises)
              .then(setTransactions)
              .catch(console.error);
          }
        })
        .catch(console.error);
    }
  };

  const linkDeal = (id) => {
    let updateDeal = false;
    if (id) {
      const dealId = deal ? deal.id : '';
      adminService.linkDealsAndTransactionsForAdmin({ deal_pk: dealId, transaction_pk: id })
        .then(() => {
          setToastMsg(TOAST_MSG.LINK_SUCCESS);
          updateDeal = true;
        })
        .catch((error) => {
          setToastMsg(TOAST_MSG.LINK_FAIL);
          console.error(error);
        });
    } else {
      setToastMsg(TOAST_MSG.LINK_FAIL);
    }
    setTimeout(() => {
      setToastMsg(TOAST_MSG.NONE);
      if (deal && updateDeal) {
        searchDeal(deal.id);
      }
    }, 2000);
  };

  const unlinkDeal = (id) => {
    let updateDeal = false;
    if (id) {
      const dealId = deal ? deal.id : '';
      adminService.unLinkDealsAndTransactionsForAdmin({ deal_pk: dealId, transaction_pk: id })
        .then(() => {
          setToastMsg(TOAST_MSG.UNLINK_SUCCESS);
          updateDeal = true;
        })
        .catch((error) => {
          setToastMsg(TOAST_MSG.UNLINK_FAIL);
          console.error(error);
        });
    } else {
      setToastMsg(TOAST_MSG.UNLINK_FAIL);
    }
    setTimeout(() => {
      setToastMsg(TOAST_MSG.NONE);
      if (deal && updateDeal) {
        searchDeal(deal.id);
      }
    }, 2000);
  };

  const exportBadMTATransactions = async () => {
    try {
      await adminService.exportBadTransactions();
      setModalState(MODAL_STATE.SUCCESS);
    } catch (error) {
      setModalState(MODAL_STATE.FAILURE);
    }
  };

  const modalMessage = () => {
    if (MODAL_STATE.SUCCESS) {
      return !isEmpty(proxy) ? `Your file will be emailed to ${userData.employee_work_email} and ${proxy.email} once it is ready.` :
        `Your file will be emailed to ${userData.employee_work_email} once it is ready.`;
    }
    return 'There was an error exporting your file.';
  };

  const modalHeader = () => {
    if (MODAL_STATE.SUCCESS) {
      return 'CREATING YOUR FILE';
    }
    return 'EXPORT FAILED';
  };

  const closeModal = () => {
    setModalState(MODAL_STATE.NONE);
  };

  const renderCardHeader = item => (
    <React.Fragment>
      <span className={`banner ${item.deal_life_cycle_stage}`} />
      <DealDetailHeader
        deal={item}
        dealUsers={item.permissions.users ? item.permissions.users.map(user => ({ user })) : []}
        dealTeams={item.permissions.teams ? item.permissions.teams.map(team => ({ team })) : []}
        updateDeal={() => {}}
        toast={() => {}}
        featureFlags={{}}
        adminView
        viewOnly
      />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className="admin-linking">
        <div className="header-card">
          <Button className="issue-report" onClick={exportBadMTATransactions}>
            Generate Issue Report
          </Button>
          <div className="input-label">Search Deals and Transactions</div>
          <div className="search-deals">
            <SearchInput searchKey="" searchKeyAction="Enter a Voucher ID, a Deal URL ID, or Deal ID #" handleSearchTermChange={debounce(searchDeal, 300)} />
            <Tooltip message="Voucher ID can be found on the voucher for a deal. Deal URL ID is the 6-digit number located in the URL when viewing a deal.
            Deal ID # is found in the deal header for an unlinked transaction."
            >
              <Icon iconName="info-circle" />
            </Tooltip>
          </div>
        </div>
        {!deal.id &&
        <div className="empty-state-container">
          <EmptyState type="deals" title="Search for a deal or transaction to get started" />
        </div>}
        {deal && deal.id && <div className="admin-linking-heading"><h2>Deal IQ Deal</h2></div>}
        {deal && deal.permissions &&
        <div className="deal-section">
          {renderCardHeader(deal)}
          <FormGroup label="Link MTA Transaction" className="link-mta-transaction" hasError={false} size="large">
            <input placeholder="Enter Transaction Deal ID #" type="text" value={newTransactionID} onChange={e => setNewTransactionID(e.target.value)} />
            <Tooltip message="Deal ID # for a transaction can be found in the deal header for an unlinked transaction.">
              <Icon iconName="info-circle" />
            </Tooltip>
          </FormGroup>
          <Button
            className="link-button"
            disabled={!newTransactionID.length}
            onClick={() => linkDeal(newTransactionID)}
            type="button"
            variant="secondary"
          >
            Link Transaction
          </Button>
        </div>}
        {transactions && transactions.length !== 0 && <div className="admin-linking-heading"><h2>Transaction(s)</h2></div>}
        {transactions && transactions.length !== 0 &&
        transactions.map(transaction =>
          (
            <div key={transaction.id} className="transaction-section">
              {renderCardHeader(transaction)}
              <Button
                className="unlink-button"
                onClick={() => unlinkDeal(transaction.id)}
              >
                Unlink Transaction
              </Button>
            </div>
          ))
      }
      </div>
      <Modal
        showModal={modalState !== MODAL_STATE.NONE}
        handleModalSubmit={closeModal}
        handleModalToggle={closeModal}
        modalHeader={modalHeader()}
        primaryButtonText="Ok"
        modalWidth={400}
      >
        <p>{modalMessage()}</p>
      </Modal>
      {toastMsg && <Toast message={toastMsg} />}
    </React.Fragment>);
};

AdminLinking.propTypes = {
  userData: PropTypes.shape({
    employee_work_email: PropTypes.string,
  }).isRequired,
  proxy: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

export default AdminLinking;
