import { connect } from 'react-redux';
import AdminPage from './admin-page';

const mapStateToProps = state => ({
  userData: (state.userData.data && state.userData.data.id) ? state.userData.data : {},
  userOptions: state.userOptions.userOptions,
});

const AdminPageContainer = connect(
  mapStateToProps,
  null,
)(AdminPage);

export default AdminPageContainer;
