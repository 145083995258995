import React from 'react';
import PropTypes from 'prop-types';

import getS3DownloadUrl from '../../services/s3-file-service';
import overflowSVG from '../../assets/images/svg/overflow.svg';


class S3FileDownloadPage extends React.Component {
  state = {
    downloadUrl: '',
  }

  componentDidMount() {
    this.getS3DownloadLinkFromUrl();
  }

  getS3DownloadLinkFromUrl = () => {
    const parsedUrl = this.parseUrl();
    if (parsedUrl) {
      getS3DownloadUrl(parsedUrl.key, parsedUrl.filename)
        .then((res) => {
          this.setState({ downloadUrl: res.download_url });
          this.downloadFile(res.download_url);
        })
        .catch(error => console.log('Error getting download URL: ', error));
    }
  }

  parseUrl = () => {
    // parses the url and returns the S3 key, and the download filename, if they exist
    // valid url will have format <domain>/file_download/<key>?filename=<filename>
    const { pathname, search } = this.props.location;
    const splitPath = pathname.split('file_download/');
    const key = pathname.indexOf('file_download/') > -1 &&
      splitPath.length === 2 && splitPath[1] !== '' ?
      splitPath[1] : null;
    const filename = search && search.indexOf('filename') > -1 ?
      search.split('?filename=')[1] : '';
    return {
      key,
      filename,
    };
  }

  downloadFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'download';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {
    return (
      <div className="s3-file-download-page card-default">
        <h1>Your file is downloading!</h1>
        <img src={overflowSVG} alt="downloading" />
        <p>Your download should finish shortly. If it does not,
          click here to <a href={this.state.downloadUrl}>download your file</a>.
        </p>
      </div>
    );
  }
}

export default S3FileDownloadPage;

S3FileDownloadPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }),
};

S3FileDownloadPage.defaultProps = {
  location: PropTypes.shape({
    search: '',
  }),
};
