import React from 'react';
import { IconButton } from '@cbrebuild/blocks';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import VOUCHER_ROUTES from '../../../page-templates/voucher/voucher-subroutes';
import considerationsService from '../../../services/voucher/considerations-service';
import { selectAdditionalCommissionAmountTotal, selectConsiderationsCommissionAmountTotal } from '../../../redux/selectors/voucher-selectors';
import Currency from '../../../nucleus/formats/currency';
import formatAsCurrency from '../../../utils/format-as-currency';

const selectTotalTerms = state => state.voucherConsiderations.considerations.reduce((acc, cur) => acc + (cur.total_units || 0), 0);
const selectTotalConsiderations = state => state.voucherConsiderations.considerations.reduce((acc, cur) => acc + (cur.consideration_amount || 0), 0);

const ConsiderationReview = () => {
  const {
    additionalCommissions, considerations, deal, voucher,
  } = useSelector(state => ({
    additionalCommissions: state.voucherAdditionalCommissions?.additionalCommissions,
    considerations: state.voucherConsiderations?.considerations,
    deal: state.deal.deal,
    voucher: state.voucher.voucher,
  }));

  const leaseStartDate = deal.date_lease_from || voucher.official_start_date;
  const leaseExpirationDate = deal.lease_expiration_date || voucher.lease_expiration_date;
  const totalTerms = useSelector(selectTotalTerms, shallowEqual);
  const totalConsiderations = useSelector(selectTotalConsiderations, shallowEqual);
  const totalCommissions = useSelector(selectConsiderationsCommissionAmountTotal, shallowEqual);
  const totalAddCommissions = useSelector(selectAdditionalCommissionAmountTotal, shallowEqual);
  const history = useHistory();

  const requiredText = <p className="voucher-error-text">Required</p>;
  const dealType = deal.deal_type;
  const isLeaseOrSaleDealType = dealType === 'sale' || dealType === 'lease';
  const isFreeRent = (type) => {
    if (considerationsService.FREE_LEASE.indexOf(type) > -1) {
      return true;
    }
    return false;
  };

  const strings = {
    title: 'Consideration',
    url: VOUCHER_ROUTES[2].routes[0].path,
    unitLabel: 'Period',
    amountLabel: 'Rent',
    additionalCommissionsTitle: 'Additional Commission',
  };
  switch (dealType) {
    case 'sale':
      strings.url = VOUCHER_ROUTES[2].routes[0].path;
      strings.unitLabel = 'Quantity';
      strings.amountLabel = 'Price';
      break;
    case 'consulting':
    case 'other':
      strings.title = 'Commissions';
      strings.url = VOUCHER_ROUTES[2].routes[2].path;
      strings.additionalCommissionsTitle = 'Commissions';
      break;
    default:
  }
  strings.url = strings.url.replace(':id', voucher.deal);

  const leaseTermMatchConsideration = () => {
    const start = moment(leaseStartDate);
    const end = moment(leaseExpirationDate);
    return Math.abs(end.diff(start, 'months', true) - totalTerms) <= 0.34;
  };
  const displayLeaseTermMatchConsideration = dealType === 'lease' && considerations.length && !leaseTermMatchConsideration();

  const disabledTypeMap = {
    'Free Monthly Rent': true,
    'Free Annual Rent': true,
  };
  const isLastItem = consideration => consideration.id === considerations[considerations.length - 1].id;

  return (
    <div className="voucher-review-group consideration-review">
      <div className="navigation">
        <Link className="h2 link" to={strings.url}>{strings.title}</Link>
        <IconButton iconName="edit" onClick={() => { history.push(strings.url); }} />
      </div>
      <div className="content">
        {isLeaseOrSaleDealType && <h3>Consideration Breakdown</h3>}
        {!considerations.length && isLeaseOrSaleDealType &&
          <div className="voucher-error-text error">
            You must add at least one consideration to this {voucher.is_early_invoice ? 'early invoice' : 'voucher'}
          </div>
        }
        {isLeaseOrSaleDealType &&
          <table>
            <colgroup>
              <col name="consideration type" />
              <col name="unit label" />
              <col name="amount label" />
              <col name="consideration" />
              <col name="commission rate" />
              <col name="gross commissions" />
            </colgroup>
            <tbody>
              <tr>
                <th>Consideration Type</th>
                <th>{strings.unitLabel}</th>
                <th>{strings.amountLabel}</th>
                <th>Consideration</th>
                <th>Commission Rate</th>
                <th>Gross Commissions</th>
              </tr>
              {considerations.map(consideration => (
                <tr key={consideration.id}>
                  <td>{consideration.consideration_type || requiredText}</td>
                  <td className={(displayLeaseTermMatchConsideration && isLastItem(consideration)) ? 'empty-border-bottom' : ''}>
                    {consideration.total_units || requiredText}
                  </td>
                  {(!!disabledTypeMap[consideration.consideration_type] && <td><Currency value={consideration.amount} emptyState="$0.00" /></td>)
                    || (
                      <td>{consideration.amount ? <Currency value={consideration.amount} emptyState="" />
                        : !!disabledTypeMap[consideration.consideration_type.value]}
                      </td>)}
                  <td><Currency value={consideration.consideration_amount} emptyState="$0.00" /></td>
                  {!isFreeRent(consideration.consideration_type) && <td>{consideration.commission_percent}</td>}
                  {isFreeRent(consideration.consideration_type) && <td>N/A</td>}
                  <td><Currency value={consideration.commission_amount} emptyState="$0.00" /></td>
                </tr>
            ))}
              <tr>
                <td>Totals</td>
                <td className={displayLeaseTermMatchConsideration ? 'warning-cell' : ''}>
                  {dealType !== 'lease' ? '' : totalTerms} Months
                </td>
                <td />
                <td><Currency value={totalConsiderations} emptyState="$0.00" /></td>
                <td />
                <td><Currency value={totalCommissions} emptyState="$0.00" /></td>
                <td />
              </tr>
            </tbody>
          </table>
        }
        {displayLeaseTermMatchConsideration &&
          <p className="warning-text">
            Warning: Total consideration length entered on the Financials tab does not match the lease term entered on the Lease Details tab.
          </p>
        }
        <h3>{strings.additionalCommissionsTitle}</h3>
        <table>
          <colgroup>
            <col name="commission type" />
            <col name="quantity" />
            <col name="amount" />
            <col name="additional commission" />
          </colgroup>
          <tbody>
            <tr>
              <th>Commission Type</th>
              <th>Quantity</th>
              <th>Amount($)</th>
              <th>Additional Commission</th>
              <th />
            </tr>
            {additionalCommissions.map(additionalCommission => (
              <tr key={additionalCommission.id}>
                <td>{additionalCommission.acommission_type || requiredText}</td>
                <td>{additionalCommission.total_units || requiredText}</td>
                <td>{formatAsCurrency(additionalCommission.amount) || requiredText}</td>
                <td>{formatAsCurrency(additionalCommission.commission_amount) || requiredText}</td>
              </tr>
            ))}
            <tr>
              <td>Totals</td>
              <td />
              <td />
              <td><Currency value={totalAddCommissions} emptyState="$0.00" /></td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ConsiderationReview;
