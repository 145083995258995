import React from 'react';
import PropTypes from 'prop-types';
import Select from '../select/select';

const TimePicker = (props) => {
  const {
    onSelect,
    selectedHour,
  } = props;
  const options = [
    { display_name: '12:00AM', value: '0' },
    { display_name: '1:00AM', value: '1' },
    { display_name: '2:00AM', value: '2' },
    { display_name: '3:00AM', value: '3' },
    { display_name: '4:00AM', value: '4' },
    { display_name: '5:00AM', value: '5' },
    { display_name: '6:00AM', value: '6' },
    { display_name: '7:00AM', value: '7' },
    { display_name: '8:00AM', value: '8' },
    { display_name: '9:00AM', value: '9' },
    { display_name: '10:00AM', value: '10' },
    { display_name: '11:00AM', value: '11' },
    { display_name: '12:00PM', value: '12' },
    { display_name: '1:00PM', value: '13' },
    { display_name: '2:00PM', value: '14' },
    { display_name: '3:00PM', value: '15' },
    { display_name: '4:00PM', value: '16' },
    { display_name: '5:00PM', value: '17' },
    { display_name: '6:00PM', value: '18' },
    { display_name: '7:00PM', value: '19' },
    { display_name: '8:00PM', value: '20' },
    { display_name: '9:00PM', value: '21' },
    { display_name: '10:00PM', value: '22' },
    { display_name: '11:00PM', value: '23' },
  ];
  const defaultOption = options[selectedHour];
  const onChange = option => onSelect(parseInt(option.value, 10));
  return (
    <div className="nd-time-picker">
      <Select
        key={defaultOption.value}
        defaultOption={defaultOption}
        onChange={onChange}
        options={options}
        dropdownIcon="clock"
      />
    </div>
  );
};

TimePicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedHour: PropTypes.number,
};

TimePicker.defaultProps = {
  selectedHour: 0,
};

export default TimePicker;

