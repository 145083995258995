import React, { useEffect, useState } from 'react';
import { Checkbox, RadioGroup, RadioButton, Button, IconButton } from '@cbrebuild/blocks';
import PropTypes from 'prop-types';
import CompanyForm from '../CompanyForm';
import AddressForm from '../AddressForm';
import useCompanyState from '../../../hooks/useCompanyState';
import useCommercialPropertyState from '../../../hooks/useCommercialPropertyState';
import ConfirmationModal from '../../../nucleus/confirmation-modal/confirmation-modal';
import VoucherContactForm from '../../voucher/contacts/voucher-contact-form';
import { getAndSetLastPageVisited } from '../voucher-validation';

// Note: This component represents the Tenant, Buyer, and Client information tabs.

const typeMap = {
  lease: 'Tenant',
  sale: 'Buyer',
  other: 'Client',
  consulting: 'Client',
};

const contactMap = {
  lease: 'tenant',
  sale: 'buyer',
  consulting: 'consulting',
  other: 'other',
};

const TenantInformation = ({
  deal,
  deal: {
    rep_role,
    is_dual_rep_deal,
    deal_type,
  },
  voucher,
  updateDeal,
  addDealContact,
  fetchDealContacts,
  updateDealContact,
  deleteDealContact,
  contactInfo,
}) => {
  const [contactInfoExist, setIsContactInfoExist] = useState(false);
  const partyType = (rep_role === 'landlord' || rep_role === 'seller') ? 'counter_party' : 'client';
  const isReppedDeal = (partyType === 'client') || is_dual_rep_deal || (deal_type === 'consulting' || deal_type === 'other');

  const [showValidation, setShowValidation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {
    company, onCompanyBlur, onCompanyChange,
  } = useCompanyState(deal[partyType] || undefined, partyType, update => updateDeal(deal.id, update));

  const validatePage = () => {
    const currentPageNumber = 2.2;
    const lastPageVisited = getAndSetLastPageVisited(voucher.id, currentPageNumber);

    if (lastPageVisited >= currentPageNumber) {
      setShowValidation(true);
    }
  };
  const queryContactParams = {
    ordering: '-is_primary',
    page: 1,
    page_size: 25,
  };

  useEffect(() => {
    validatePage();
    fetchDealContacts({ ...queryContactParams, deal: deal.id }).then(() => {
      setIsContactInfoExist(true);
    });
  }, []);

  const {
    properties = [], isTenantRelocating = false, onPropertyBlur, onPropertyChange, onPropertyCreate, onPropertyDelete,
    onSpaceBlur, onSpaceChange,
  } = useCommercialPropertyState({
    dealId: deal.id, filterPropertyResultsBy: p => p.location_type_new === false,
  });
  const [property] = properties;
  const handleRelocationChange = ({ target: { value } }) => {
    if (value === 'true') {
      onPropertyCreate();
    } else {
      setShowModal(true);
    }
  };

  const closeConfirmationModal = () => {
    setShowModal(false);
  };

  const onRemapPropertyEvent = (e, callback) => {
    const { target: { type, name, value } } = e;
    if (name === 'postal') {
      callback({
        ...e,
        target: {
          ...e.target, name: 'postal_code', type, value,
        },
      });
    } else if (name === 'territory') {
      callback({
        ...e,
        target: {
          ...e.target, name: 'state_abbreviation', type, value,
        },
      });
    } else {
      callback(e);
    }
  };

  return (
    <div className="tenant-tab">
      <div>
        <React.Fragment>
          <h2>Add {typeMap[deal_type]} Information</h2>
          <legend>{typeMap[deal_type]} Information</legend>
        </React.Fragment>
        <CompanyForm
          infoType={deal_type}
          partyType={contactMap[deal_type]}
          {...company}
          onChange={onCompanyChange}
          onBlur={onCompanyBlur}
          showValidation={showValidation}
        />
        {(deal.deal_type === 'lease' || deal.deal_type === 'sale') &&
          <div className="field-group">
            <Checkbox
              checked={isReppedDeal}
              disabled
            >Is {deal_type === 'lease' ? 'Lessee' : typeMap[deal_type]} our client?
            </Checkbox>
          </div>
        }
      </div>
      {contactInfoExist &&
        <VoucherContactForm
          dealId={deal.id}
          contactInfo={contactInfo}
          addDealContact={addDealContact}
          updateDealContact={updateDealContact}
          deleteDealContact={deleteDealContact}
          updateDeal={updateDeal}
          showValidation={showValidation && partyType === 'client'}
          contactType={contactMap[deal_type]}
        />
      }
      {deal_type === 'lease' && (
        <div>
          <legend className="secondary-legend">Is the tenant relocating as part of this lease?</legend>
          <RadioGroup
            name="tenant-relocation"
            className="radioVal"
            selectedValue={isTenantRelocating}
            onChange={handleRelocationChange}
            orientation="vertical"
          >
            <RadioButton value={false}>No</RadioButton>
            <RadioButton value>Yes, the tenant is relocating</RadioButton>
          </RadioGroup>
        </div>
      )}
      {property && deal_type === 'lease' && (
        <div>
          <div><legend>Tenant Prior Address</legend></div>
          <AddressForm
            address={property.address || ''}
            city={property.city || ''}
            country={property.country || ''}
            postal={property.postal_code || ''}
            territory={property.state_abbreviation || ''}
            onChange={e => onRemapPropertyEvent(e, onPropertyChange(property.id))}
            onBlur={e => onRemapPropertyEvent(e, onPropertyBlur(property.id))}
            isRelocating
            showValidation={showValidation}
            className="prior-address"
          />
          <div className="field">
            <label>Prior rent area(SqFt)</label>
            <input
              type="number"
              name="space_total_square_feet"
              value={(property?.spaces || [])[0]?.space_total_square_feet || ''}
              onChange={onSpaceChange(property.id, 0)}
              onBlur={onSpaceBlur(property.id, 0)}
            />
          </div>
          <div>
            <legend className="secondary-legend">Was the prior lease a percentage lease (for retail spaces only)?</legend>
            <RadioGroup
              name="percentage_rent"
              className="radioVal"
              selectedValue={property.percentage_rent === true || property.percentage_rent === 'true' ? 'true' : 'false'}
              onChange={onPropertyChange(property.id)}
              orientation="vertical"
            >
              <RadioButton value="false">No</RadioButton>
              <RadioButton value="true">Yes, it was a percentage lease</RadioButton>
            </RadioGroup>
          </div>
        </div>
      )}
      {showModal &&
        <ConfirmationModal
          className="deal-file-delete-confirmation tenant-modal voucher-modal"
          onCloseModal={closeConfirmationModal}
          showModal={showModal}
        >
          <div className="header-modal">
            <h1 className="header-content conf-header">Confirmation</h1>
            <IconButton
              iconName="close-circle"
              className="icon-circle"
              variant="basic"
              onClick={closeConfirmationModal}
            />
          </div>
          <div className="conf-text">
            <p>This will remove all data from the Tenant Prior Address section. Are you sure you want to make this change?</p>
          </div>
          <footer className="actions">
            <Button
              className="cancel-btn"
              variant="secondary"
              onClick={closeConfirmationModal}
            >
              Cancel
            </Button>
            <Button
              className="upload-btn ok-btn"
              onClick={() => {
                setShowModal(false);
                onPropertyDelete(property.id);
              }}
            >Ok
            </Button>
          </footer>
        </ConfirmationModal>
      }
    </div>
  );
};

TenantInformation.propTypes = {
  voucher: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  deal: PropTypes.shape({
    rep_role: PropTypes.string.isRequired,
    deal_type: PropTypes.string,
    is_dual_rep_deal: PropTypes.bool,
    id: PropTypes.number,
    client: PropTypes.shape({
      id: PropTypes.number,
    }),
    counter_party: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  updateDeal: PropTypes.func.isRequired,
  addDealContact: PropTypes.func.isRequired,
  updateDealContact: PropTypes.func.isRequired,
  deleteDealContact: PropTypes.func.isRequired,
  fetchDealContacts: PropTypes.func.isRequired,
  contactInfo: PropTypes.arrayOf(PropTypes.any),
};

TenantInformation.defaultProps = {
  contactInfo: {},
};


export default TenantInformation;
