import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, IconButton } from '@cbrebuild/blocks';

class BannerNotice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dismissed: false,
    };
  }

  dismiss = () => {
    this.setState({ dismissed: true });
    this.props.onDismiss();
  }

  render() {
    const {
      iconClassName,
      mainMessage,
      secondaryMessage,
      controlButtonText,
      onControlButtonClick,
      onDismiss,
    } = this.props;

    const displayIcon = iconClassName && (
      <Icon className="icon" iconName={iconClassName} />
    );

    const displaySecondaryMessage = secondaryMessage && (
      <span className="secondary-message">
        {secondaryMessage}
      </span>
    );

    const displayButton = controlButtonText && onControlButtonClick && (
      <Button onClick={onControlButtonClick}>
        {controlButtonText}
      </Button>
    );

    const displayDismiss = onDismiss && (
      <IconButton
        className="dismiss blxs-button-icon-small"
        iconName="close"
        onClick={this.dismiss}
        variant="basic"
      />
    );

    const displayBannerNotice = !this.state.dismissed && (
      <div className="nd-banner-notice">
        <div className="banner-notice-content">
          {displayIcon}
          <span className="main-message">
            {mainMessage}
          </span>
          {displaySecondaryMessage}
        </div>
        <div className="banner-notice-control">
          {displayButton}
          {displayDismiss}
        </div>
      </div>
    );

    return (
      <React.Fragment>
        {displayBannerNotice}
      </React.Fragment>
    );
  }
}

BannerNotice.propTypes = {
  iconClassName: PropTypes.string,
  mainMessage: PropTypes.string.isRequired,
  secondaryMessage: PropTypes.string,
  controlButtonText: PropTypes.string,
  onControlButtonClick: PropTypes.func,
  onDismiss: PropTypes.func,
};

BannerNotice.defaultProps = {
  iconClassName: null,
  secondaryMessage: null,
  controlButtonText: null,
  onControlButtonClick: null,
  onDismiss: null,
};

export default BannerNotice;
