class StatusStrings {
  dealStage = [
    { display_name: 'Prospecting', value: 'prospect', numeric_value: 1 },
    { display_name: 'Executing', value: 'executing', numeric_value: 2 },
    { display_name: 'Closed', value: 'closed', numeric_value: 3 },
  ];

  subStage = [
    { display_name: 'Touring', value: 'touring' },
    { display_name: 'RFP', value: 'rfp' },
    { display_name: 'LOI', value: 'loi' },
    { display_name: 'Lease Review', value: 'lease review' },
  ];

  dealTypeAndRepRole = [
    { deal_type: 'lease', display_name: 'Lease - Tenant', value: 'tenant' },
    { deal_type: 'lease', display_name: 'Lease - Landlord', value: 'landlord' },
    { deal_type: 'sale', display_name: 'Sale - Buyer', value: 'buyer' },
    { deal_type: 'sale', display_name: 'Sale - Seller', value: 'seller' },
    { deal_type: 'consulting', display_name: 'Consulting', value: 'consulting' },
    { deal_type: 'other', display_name: 'Other', value: 'other' },
  ];

  dealArchiveStatus = [
    { display_name: 'Active', value: 'active' },
    { display_name: 'Archived', value: 'archived' },
  ];

  voucherStatus = [
    { display_name: 'No Submission', value: 'Voucher Not Submitted' },
    { display_name: 'Early Invoice Submitted', value: 'Early Invoice Submitted' },
    { display_name: 'Early Invoice Resubmitted', value: 'Early Invoice Resubmitted' },
    { display_name: 'Voucher Submitted', value: 'Voucher Submitted' },
    { display_name: 'Voucher Resubmitted', value: 'Voucher Resubmitted' },
    { display_name: 'Voucher Completed', value: 'Voucher Completed' },
  ];

  getVoucherStatuses = () => this.voucherStatus;

  getDealStages = () => this.dealStage;

  getDealSubStages = () => this.subStage;

  getDealStageOption = dealStageValue => this.dealStage.filter(o => o.value === dealStageValue)[0];

  getVoucherStatusOption = voucherStatusValue => this.voucherStatus.filter(o => o.value === voucherStatusValue)[0];

  getDealTypeAndRepRoleOption = repRole => this.dealTypeAndRepRole.find(o => o.value === repRole);

  getDealSubstageOption = (dealSubstageValue) => {
    if (dealSubstageValue && dealSubstageValue.length > 0) {
      return this.subStage.filter(dealSubstage => dealSubstage.value === dealSubstageValue)[0];
    }
    return {};
  }

  getDealStageByNumeric = (dealStageNumeric) => {
    const found = this.dealStage.find(ds => ds.numeric_value.toString() === (dealStageNumeric && dealStageNumeric.toString()));

    if (found) {
      return found.value;
    }

    return '';
  }

  flattenToDealTypeArray = (dealTypeAndRepRoleArray) => {
    /*
    take an input like `this.dealTypeAndRepRole`
    and return a unique `deal_type` Array
    */
    const uniqueDealTypeHash = dealTypeAndRepRoleArray.map(option => option.deal_type)
      .reduce((agg, nextItem) => {
        // remove any duplicate items from list
        // eslint-disable-next-line
        agg[nextItem] = true;
        return agg;
      }, {});
    return Object.keys(uniqueDealTypeHash);
  }
}

export default new StatusStrings();
