import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@cbrebuild/blocks';
import Select from '../../../nucleus/select/select';
import NumberInput from '../../../nucleus/inputs/NumberInput';
import Currency from '../../../nucleus/formats/currency';
import { updateAdditionalCommissions, deleteAdditionalCommission } from '../../../redux/actions/additional-commissions-actions';
import { updateDealPipelineTimestamp } from '../../../redux/actions/deal-actions';
import useFormState, { EVENT_VALUE_TYPES } from '../../../hooks/useFormState';

const typeOverrides = {
  total_units: EVENT_VALUE_TYPES.FLOAT,
  amount: EVENT_VALUE_TYPES.FLOAT,
};

const selectAdditionalCommissionById = (state, id) => (
  state.voucherAdditionalCommissions.additionalCommissions.find(additionalCommission => additionalCommission.id === id)
);

const AdditionalCommissionItem = ({ id, showValidation }) => {
  const additionalCommission = useSelector(state => selectAdditionalCommissionById(state, id)) || {};
  const options = useSelector(state => state.voucher.options.additionalCommissionTypeOptions || []);
  const dealId = useSelector(state => state.deal.deal?.id);
  const dispatch = useDispatch();

  const {
    acommission_type, total_units, amount, commission_amount,
    onStateChange, onStateChanged,
  } = useFormState({
    amount: additionalCommission.amount,
    acommission_type: additionalCommission.acommission_type,
    commission_amount: additionalCommission.commission_amount,
    total_units: additionalCommission.total_units,
  }, (update, current, patchCurrent) => {
    const patch = {};
    if ('amount' in update || 'total_units' in update) {
      patch.commission_amount = current.total_units.value * current.amount.value;
      patchCurrent(patch);
    }
    dispatch(updateAdditionalCommissions(id, { ...update, ...patch }));
    dispatch(updateDealPipelineTimestamp(dealId));
  }, typeOverrides);

  const onDelete = () => {
    dispatch(deleteAdditionalCommission(id));
    dispatch(updateDealPipelineTimestamp(dealId));
  };

  const invalidTotalUnit = (showValidation || total_units.dirty) && total_units.value === null;
  const invalidAmount = (showValidation || amount.dirty) && amount.value === null;

  return (
    <div className="table-row form-row">
      <div className="table-cell">
        <Select
          name="acommission_type"
          defaultOption={options.find(option => option.value === acommission_type.value)}
          onChange={({ value }) => onStateChanged({
            target: { value, name: 'acommission_type', type: 'text' },
          })}
          options={options}
        />
      </div>
      <div className="table-cell">
        <NumberInput
          name="total_units"
          value={total_units.value}
          precision={0}
          onChange={onStateChange}
          onBlur={onStateChanged}
          placeholder="Quantity"
          invalid={invalidTotalUnit}
        />
        {invalidTotalUnit && (
          <p className="voucher-error-text">Required</p>
        )}
      </div>
      <div className="table-cell">
        <NumberInput
          name="amount"
          value={amount.value}
          precision={4}
          onChange={onStateChange}
          onBlur={onStateChanged}
          placeholder="Amount"
          invalid={invalidAmount}
        />
        {invalidAmount && (
          <p className="voucher-error-text">Required</p>
        )}
      </div>
      <div className="table-cell">
        <Currency
          value={commission_amount.value}
          emptyState="--"
        />
      </div>
      <div className="table-cell">
        <IconButton
          className="blxs-button-icon-small"
          iconName="close-circle"
          onClick={onDelete}
          variant="basic"
        />
      </div>

    </div>
  );
};

AdditionalCommissionItem.propTypes = {
  id: PropTypes.number.isRequired,
  showValidation: PropTypes.bool.isRequired,
};

export default AdditionalCommissionItem;
