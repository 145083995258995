import React from 'react';
import PropTypes from 'prop-types';
import { fetchPoolAccounts } from '../../services/voucher/commissions-service';
import SearchableDropdown from './searchable-dropdown';

export const getDisplayText = ({ producer, office, name }) => {
  const result = `${producer}: ${office} - ${name}`;
  return result.length > 61 ? `${result.substring(0, 61)}...` : result;
};
export default function PoolAccountAutocomplete({
  errorMessage,
  placeholder,
  onBlur,
  onSelect,
  voucher_cbre_pool_name,
}) {
  return (
    <SearchableDropdown
      errorMessage={errorMessage}
      fetchResults={fetchPoolAccounts}
      getDisplayText={getDisplayText}
      onBlur={onBlur}
      onSelect={onSelect}
      placeholder={placeholder}
      searchableValues={['producer', 'office', 'name']}
      value={voucher_cbre_pool_name}
    />
  );
}

PoolAccountAutocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  voucher_cbre_pool_name: PropTypes.string,
  onBlur: PropTypes.func,
};
PoolAccountAutocomplete.defaultProps = {
  errorMessage: '',
  placeholder: '',
  voucher_cbre_pool_name: '',
  onBlur: undefined,
};
