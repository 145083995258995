import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import SearchInput from '../../nucleus/search/search-input';
import TeamCard from '../../components/teams/team-card';
import CreateTeamModalContainer from '../../components/create-team-modal/create-team-modal-container';
import EmptyState from '../../components/empty-state/empty-state';
import RowItem from '../../nucleus/row-item/row-item';
import AddItem from '../../nucleus/add-item/add-item';
import LoadMore from '../../nucleus/load-more/load-more';
import ListActionHeader from '../../nucleus/list-action-header/list-action-header';

const analyticProperties = {
  actionPrefix: 'team_',
  categoryPrefix: 'Teams ',
};

class TeamsPage extends React.Component {
  state = {
    showNewTeamForm: false,
    isLoading: true,
    params: {
      page: 1,
      page_size: 50,
      search: '',
    },
  };

  componentDidMount() {
    this.props.fetchTeams(this.state.params)
      .then(() => {
        this.setState({ isLoading: false });
      });
  }

  initNewTeam = () => {
    this.setState({
      showNewTeamForm: !this.state.showNewTeamForm,
    });
  };

  closeEditMode = (isEditing) => {
    if (!isEditing) {
      this.setState({
        showNewTeamForm: false,
      });
    }
  };

  closeNewTeamModal = () => {
    this.setState({
      showNewTeamForm: false,
    });
  };

  randomColor = () => {
    const colorOptions = ['lime', 'green', 'gold', 'apricot', 'orange', 'red', 'magenta', 'sky', 'teal', 'blue', 'indigo', 'purple'];
    return colorOptions[Math.floor(Math.random() * colorOptions.length)];
  };

  // fetch teams according to searched term and updates
  // the page back to 1 for AdminPage only
  searchTeams = (search) => {
    this.setState({
      params: {
        ...this.state.params,
        search,
        page: 1,
      },
    }, () => {
      this.props.fetchTeams(this.state.params);
    });
  };

  // handles the Load More button for AdminPage only to tackle
  // pagination as necessary
  handleLoadMore = () => {
    this.setState({
      isLoading: true,
      params: {
        ...this.state.params,
        page: this.state.params.page + 1,
      },
    }, () => {
      this.props.fetchTeams(this.state.params)
        .then(() => {
          this.setState({
            isLoading: false,
          });
        });
    });
  };

  render() {
    const {
      userData,
      teams,
      addTeam,
      addTeamMember,
      removeTeamMember,
      removeTeamAndMember,
      undeleteTeam,
      updateTeam,
      updateUserTeamAutoAddPreference,
      hasNext,
      toast,
    } = this.props;

    const {
      showNewTeamForm,
      isLoading,
    } = this.state;

    // we want to hide certain functionality on the admin page
    // AdminPage is the only parent that doesn't send down this prop
    // so use this prop to determine where we are, and what buttons to hide
    const isAdminPage = !updateUserTeamAutoAddPreference;

    const searchBar = (
      <section className="search-card card-default">
        <ListActionHeader
          search={<SearchInput
            searchKey="teams and users"
            handleSearchTermChange={debounce(this.searchTeams, 300)}
          />}
        />
      </section>
    );

    const loadMore = (
      <section className="load-more-card card-default">
        <LoadMore
          isLoading={isLoading}
          hasNextPage={hasNext}
          handleLoadMore={this.handleLoadMore}
        />
      </section>
    );

    const renderTeamCards = () => (teams && teams.length > 0) &&
        (teams.map(team =>
          (
            <TeamCard
              key={team.id}
              id={team.id}
              deleted={team.deleted}
              userData={userData}
              teamName={team.name}
              teamColor={team.avatar_color}
              teamMembers={team.users}
              dealCount={team.deal_count}
              onEdit={this.closeEditMode}
              onUpdate={updateTeam}
              onUpdateUserTeamAutoAddPreference={updateUserTeamAutoAddPreference}
              onAddTeamMember={addTeamMember}
              onRemoveTeamMember={removeTeamMember}
              onRemoveTeamAndMember={removeTeamAndMember}
              undeleteTeam={undeleteTeam}
              audit_trail={team.audit_trail}
              auto_add_to_deals={team.auto_add_to_deals}
              analyticProperties={analyticProperties}
              toast={toast}
            />
          ))
        );

    const renderNoTeams = () => (teams && !isLoading && teams.length === 0 &&
        (
        <div className="empty-state-container">
          <EmptyState
            type="teams"
            message="Get started by creating a new team"
          />
        </div>
        )
    );

    return (
      <div className="teams-page">
        <div className="page-container">
          { // search bar will only be available in the AdminPage
            isAdminPage && searchBar
          }
          <RowItem additionalClassName="add-team-button">
            <AddItem
              label="Team"
              onClick={this.initNewTeam}
            />
          </RowItem>
          {renderTeamCards()}
          {renderNoTeams()}
          { // load more button will only be available in the AdminPage
            // to tackle pagination due to the amount of teams present
            hasNext && isAdminPage && loadMore
          }
        </div>
        {showNewTeamForm &&
        <CreateTeamModalContainer
          onSubmit={addTeam}
          onClose={this.closeNewTeamModal}
          teamColor={this.randomColor()}
          isAdminPage={isAdminPage}
        />}
      </div>
    );
  }
}

TeamsPage.propTypes = {
  userData: PropTypes.shape({}).isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    audit_trail: PropTypes.arrayOf(PropTypes.shape()),
    auto_add_to_deals: PropTypes.bool,
  })),
  hasNext: PropTypes.number,
  addTeam: PropTypes.func.isRequired,
  fetchTeams: PropTypes.func.isRequired,
  undeleteTeam: PropTypes.func,
  updateTeam: PropTypes.func.isRequired,
  updateUserTeamAutoAddPreference: PropTypes.func,
  addTeamMember: PropTypes.func.isRequired,
  removeTeamMember: PropTypes.func.isRequired,
  removeTeamAndMember: PropTypes.func.isRequired,
  toast: PropTypes.func,
};

TeamsPage.defaultProps = {
  teams: PropTypes.arrayOf(PropTypes.shape({
    id: null,
    name: null,
    audit_trail: [{}],
    auto_add_to_deals: false,
  })),
  undeleteTeam: null,
  updateUserTeamAutoAddPreference: null,
  hasNext: null,
  toast: () => {},
};
export default TeamsPage;
