import { connect } from 'react-redux';

// actions
import {
  fetchCommissions,
  createCommission,
  updateCommission,
  deleteCommission,
} from '../../../redux/actions/voucher/commissions-actions';
import { updateVoucher } from '../../../redux/actions/voucher/voucher-actions';

import CommissionAllocations from './commission-allocations';

/*
  baseTotal represents denominator for calculations in PercentDollarInput sub-component.
  commissionsTotal: expected gross commission
  deal.estimated_commission: estimated total commission (or expected, but that will be caught by commissionsTotal)
*/
const mapStateToProps = state => ({
  deal: state.deal.deal,
  commissions: state.voucherCommissions,
  baseTotal: state.commissionsTotal || state.deal.deal.estimated_commission || 0,
  voucher: state.voucher.voucher,
  teamMembers: state.teamMembers,
});

const mapDispatchToProps = {
  fetchCommissions,
  createCommission,
  updateCommission,
  deleteCommission,
  updateVoucher,
};

const CommissionAllocationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommissionAllocations);

export default CommissionAllocationsContainer;
