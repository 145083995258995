/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { IconButton } from '@cbrebuild/blocks';
import { isEmpty } from 'lodash';
import PageHeaderDefault from '../../nucleus/header/page-header-default';
import subroutes from './commissions-subroutes';
import traunchDetailsService from '../../services/traunch-details-service';
import installmentService from '../../services/voucher/installments-service';
import Modal from '../../nucleus/modal/modal';

const CommissionsPage = (props) => {
  if (!props.user.id) {
    return null;
  }

  // need to account for legacy payment_status that was a string, it's not an array
  useEffect(() => {
    let needReload = false;
    const paymentStatus = JSON.parse(window.localStorage.getItem('myPaymentsParams'))?.payment_status;
    if (typeof paymentStatus === 'string') {
      needReload = true;
      const paymentParams = JSON.parse(window.localStorage.getItem('myPaymentsParams'));
      const updatedParams = {
        ...paymentParams,
        payment_status: ['Paid', 'Receivable', 'Upcoming'],
      };
      window.localStorage.setItem('myPaymentsParams', JSON.stringify(updatedParams));
    }
    const installmentStatus = JSON.parse(window.localStorage.getItem('myInstallmentsParams'))?.payment_status;
    if (typeof installmentStatus === 'string') {
      needReload = false;
      const installParams = JSON.parse(window.localStorage.getItem('myInstallmentsParams'));
      const updatedParams = {
        ...installParams,
        payment_status: ['Paid', 'Receivable', 'Upcoming', 'Write Off'],
      };
      window.localStorage.setItem('myInstallmentsParams', JSON.stringify(updatedParams));
    }
    return () => {
      if (needReload) window.location.reload(true);
      needReload = false;
    };
  });

  const { featureFlags, proxy } = props;

  const [showModal, setShowModal] = useState(false);

  const [modalContents, setModalContents] = useState({
    header: '',
    body: '',
  });

  const tabs = [
    {
      link: '/commissions/annual_chart',
      text: 'Annual Chart',
    },
    {
      link: '/commissions/my_payments',
      text: 'My Payments',
    },
    {
      link: '/commissions/installments',
      text: 'Installments',
    },
  ];

  useMemo(() => {
    document.title = 'Deal IQ | Commissions';
  }, []);

  const currentPath = props.match.params.subroute;

  const showOKModal = () => {
    setModalContents({
      ...modalContents,
      header: 'Creating your file.',
      body: !isEmpty(proxy) ? `Your file will be emailed to ${props.user.work_email} and ${proxy.email} once it is ready.` :
        `Your file will be emailed to ${props.user.work_email} once it is ready.`,
    });
    setShowModal(true);
  };

  const showErrorModal = (message) => {
    setModalContents({
      ...modalContents,
      header: 'Export failed.',
      body: message,
    });
    setShowModal(true);
  };

  const exportFile = () => {
    if (currentPath === 'my_payments') {
      const myPaymentsExportParam = JSON.parse(window.localStorage.getItem('myPaymentsParams'));
      traunchDetailsService.exportTraunchDetailsMyPayments({
        ...myPaymentsExportParam,
        user_id: props.user.id,
      })
        .then(() => {
          showOKModal();
        })
        .catch(() => {
          showErrorModal('My Payments is empty.');
        });
    } else if (currentPath === 'installments') {
      const myInstallmentsParam = JSON.parse(window.localStorage.getItem('myInstallmentsParams'));
      installmentService.exportInstallments({ ...myInstallmentsParam, is_mta: true })
        .then(() => {
          showOKModal();
        })
        .catch(() => {
          showErrorModal('Installments is empty.');
        });
    }
  };

  const exportButton = (
    currentPath !== 'annual_chart' ? (
      <IconButton
        className="icon-as-button"
        iconName="download"
        onClick={exportFile}
      />
    ) : ''
  );

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const subtitle = (
    <span className="subtitle-container">
      It may take up to 72 hours for installment and commission information to be displayed in Deal IQ.
      Additionally, upcoming and past due installments will reflect standard commission splits, and
      Traunch changes will be reflected when cash has been received.
    </span>
  );

  return (
    <React.Fragment>
      <PageHeaderDefault
        pageUrl={props.match.url}
        title="Commissions"
        subtitle={subtitle}
        tabs={tabs}
        controls={exportButton}
      />
      <div className="page-container">
        {subroutes.map((route) => {
          const RouteComponent = route.component;
          return (
            <Route
              path={route.path}
              exact={route.exact}
              key={route.path}
              render={() => (<RouteComponent user={props.user} featureFlags={featureFlags} />
              )}
            />
          );
        })}
      </div>
      {showModal &&
        <Modal
          showModal={showModal}
          primaryButtonText="OK"
          handleModalSubmit={handleCloseModal}
        >
          <React.Fragment>
            <h1 className="uppercase">{modalContents.header}</h1>
            <p>{modalContents.body}</p>
          </React.Fragment>
        </Modal>}
    </React.Fragment>
  );
};

export default CommissionsPage;

CommissionsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subroute: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    work_email: PropTypes.string,
  }).isRequired,
  featureFlags: PropTypes.shape({}),
  proxy: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

CommissionsPage.defaultProps = {
  featureFlags: {},
};
