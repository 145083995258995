import superFetch from '../utils/super-fetch';

const fetchPropertyOptions = () => {
  // fetch property options
  const url = '/landlord/properties';
  const options = {
    method: 'OPTIONS',
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const fetchProperties = (params) => {
  // fetch all properties
  const url = '/properties/groupby';
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const fetchBuilding = (id) => {
  // fetch property by id
  const url = `/buildings/${id}`;
  const options = {
    method: 'GET',
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const fetchPropertyDeals = (id) => {
  // fetch deals associated with a property
  const url = `/mbb/deals?building=${id}`;
  const options = {
    method: 'GET',
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const fetchDealProperties = (id) => {
  // fetch properties associated with a deal
  const url = `/v2/landlord/properties?deal_id=${id}`;
  const options = {
    method: 'GET',
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const addDealProperty = (params) => {
  const url = '/v2/landlord/properties';
  const options = {
    method: 'POST',
  };
  return superFetch(url, options, JSON.stringify(params))
    .then(data => data.json());
};

const updateDealProperty = (id, params) => {
  const url = `/v2/landlord/properties/${id}`;
  const options = {
    method: 'PATCH',
  };
  const body = JSON.stringify(params);
  return superFetch(url, options, body)
    .then(data => data.json());
};

const addTeamProperty = (params) => {
  const url = '/landlord/property/new';
  const options = {
    method: 'POST',
  };
  return superFetch(url, options, JSON.stringify(params))
    .then(data => data.json());
};

export default {
  fetchPropertyOptions,
  fetchBuilding,
  fetchProperties,
  fetchPropertyDeals,
  fetchDealProperties,
  updateDealProperty,
  addDealProperty,
  addTeamProperty,
};

