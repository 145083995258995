import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, RadioButton, Icon } from '@cbrebuild/blocks';
import useCommercialPropertyState from '../../../hooks/useCommercialPropertyState';
import { getAndSetLastPageVisited } from '../voucher-validation';
import AddressForm from '../AddressForm';
import Select from '../../../nucleus/select/select';
import PropertyAutocomplete from '../../autocomplete/property-autocomplete';
import vouchersService from '../../../services/voucher/vouchers-service';
import Tooltip from '../../../nucleus/tooltip/tooltip';
import Datepicker from '../../../nucleus/datepicker/datepicker';
import FormGroup from '../../../nucleus/form-group/form-group';

const PropertySaleTab = ({
  deal,
  voucher,
}) => {
  const [showValidation, setShowValidation] = useState(false);
  const [showMore, setShowMore] = useState({});
  const [linkedProperties, setLinkedProperties] = useState({});
  const [propertyOptions, setPropertyOptions] = useState([{ display_name: 'Select', value: null }]);
  const [ownershipTypeOptions, setOwnershipTypeOptions] = useState([{ display_name: 'Select', value: null }]);
  const [spaceTypeOptions, setSpaceTypeOptions] = useState([{ display_name: 'Select', value: null }]);
  const {
    properties, dealProperties, onPropertyBlur, onPropertyChange,
    onSpaceBlur, onSpaceChange, onSpaceDelete, onDealPropertySelect, onDealPropertyAdd,
  } = useCommercialPropertyState({ dealId: deal.id, createIfEmpty: true });
  const [saleProperty, setSaleProperty] = useState(properties && properties[0]);
  const validatePage = () => {
    const currentPageNumber = 2.1;
    const lastPageVisited = getAndSetLastPageVisited(voucher.id, currentPageNumber);

    if (lastPageVisited >= currentPageNumber) {
      setShowValidation(true);
    }
  };

  const addProperty = (id, property) => {
    onDealPropertyAdd(id, property, property.name);
  };

  useEffect(() => {
    vouchersService.getPropertyOptions().then((res) => {
      const voucherCommPropList = res.actions.POST;
      if (voucherCommPropList.voucher_property_type_category.choices.length > 0 && propertyOptions.length === 1) {
        setPropertyOptions(propertyOptions.concat(voucherCommPropList.voucher_property_type_category.choices));
      }
      if (voucherCommPropList.ownership_type.choices.length > 0 && ownershipTypeOptions.length === 1) {
        setOwnershipTypeOptions(ownershipTypeOptions.concat(voucherCommPropList.ownership_type.choices));
      }
      if (voucherCommPropList.space_type.choices.length > 0 && spaceTypeOptions.length === 1) {
        setSpaceTypeOptions(spaceTypeOptions.concat(voucherCommPropList.space_type.choices));
      }
    });
    validatePage();
    setSaleProperty(properties && properties[0]);
  }, [propertyOptions, ownershipTypeOptions, validatePage, properties, spaceTypeOptions]);

  const handleShowMore = (cpId) => {
    setShowMore(current => ({
      ...current, [cpId]: !current[cpId],
    }));
  };

  const handleLinkedProperty = (cpId, dp) => {
    onDealPropertySelect(cpId, dp);
    setLinkedProperties(current => ({
      ...current, [cpId]: dp.id,
    }));
  };

  const onRemapPropertyEvent = (e, callback) => {
    const { target: { type, name, value } } = e;
    if (name === 'postal') {
      callback({
        ...e,
        target: {
          ...e.target, name: 'postal_code', type, value,
        },
      });
    } else if (name === 'territory') {
      callback({
        ...e,
        target: {
          ...e.target, name: 'state_abbreviation', type, value,
        },
      });
    } else {
      callback(e);
    }
  };

  return (
    <div>
      <h2>Basic Information</h2>
      {saleProperty &&
        <>
          <legend>Details</legend>
          <div className="calendar">
            <FormGroup
              label="Estimated Close Date"
              size="large"
              isRequired
              hasError={!saleProperty.sale_est_close_date && showValidation}
              errorMessage="Estimated Close Date is required"
            >
              <Datepicker
                onDateChange={(date) => {
                  onPropertyChange(saleProperty.id)({
                    target: {
                      type: 'date',
                      name: 'sale_est_close_date',
                      value: date || '',
                    },
                  });
                }}
                date={saleProperty.sale_est_close_date || ''}
              />
            </FormGroup>
          </div>
          <div className="voucher-property">
            <form>
              <legend>Property</legend>
              <div>
                <ul className="property-list">
                  {dealProperties
                    && dealProperties.filter(dp => linkedProperties[saleProperty?.id] !== dp.id).map((dp, i) => (
                      (i >= 2 && !showMore[saleProperty?.id]) ? null :
                    (
                      <li key={saleProperty.id} className="property-list-style" onClick={() => handleLinkedProperty(saleProperty?.id, dp)}>
                        <div className="person-component">
                          <div className="person-textbox">
                            <h4>{dp.name}</h4>
                            <p>{dp.building.full_address}</p>
                          </div>
                        </div>
                        <div>
                          <span className="add-icon">
                            <Icon iconName="plus-circle-outline" size="large" />
                          </span>
                        </div>
                      </li>
                    )))
                  }
                </ul>
              </div>
              <div className="div-align">
                <label>Search for Property
                  <Tooltip
                    message={
                      <>
                        <h3>Property Search Tip</h3>
                        <br />
                        <p>Try entering a street address, intersection, and include the city name.</p>
                      </>
                    }
                  >
                    <Icon iconName="info-circle" />
                  </Tooltip>
                </label>
                <PropertyAutocomplete
                  errorMessage=""
                  onSelect={property => addProperty(saleProperty?.id, property)}
                />
              </div>
              <div className="field">
                <label>Property Name</label>
                <input
                  type="text"
                  name="name"
                  value={saleProperty?.name || ''}
                  onChange={onPropertyChange(saleProperty?.id)}
                  onBlur={onPropertyBlur(saleProperty?.id)}
                />
              </div>
              <AddressForm
                address={saleProperty.address || ''}
                city={saleProperty.city || ''}
                country={saleProperty.country || ''}
                postal={saleProperty.postal_code || ''}
                territory={saleProperty.state_abbreviation || ''}
                onChange={e => onRemapPropertyEvent(e, onPropertyChange(saleProperty.id))}
                onBlur={e => onRemapPropertyEvent(e, onPropertyBlur(saleProperty.id))}
                isRelocating
                showValidation={showValidation}
              />
              <legend className="secondary-legend">Is Property Confidential?</legend>
              <RadioGroup
                name="is_property_confidential"
                className="radioVal"
                selectedValue={`${saleProperty.is_property_confidential}`}
                onChange={onPropertyChange(saleProperty.id)}
                orientation="vertical"
              >
                <RadioButton value="false">No</RadioButton>
                <RadioButton value="true">Yes</RadioButton>
              </RadioGroup>
              <legend className="secondary-legend">Is this the whole building, or suites in a building?</legend>
              <RadioGroup
                name="whole_building"
                className="radioVal"
                selectedValue={`${saleProperty.whole_building}`}
                onChange={onPropertyChange(saleProperty.id)}
                orientation="vertical"
              >
                <RadioButton value="true">Whole Building</RadioButton>
                <RadioButton value="false">Suite(s)</RadioButton>
              </RadioGroup>
              <div className="field select-subtype">
                <label>
                  <span className={(saleProperty.ownership_type === '' && showValidation)
                    ? 'asterisk-error' : ''}
                  >*
                  </span> Ownership Type
                </label>
                <Select
                  className={saleProperty.ownership_type === '' ? 'errorInput' : ''}
                  defaultOption={saleProperty.ownership_type || 'Select'}
                  options={ownershipTypeOptions.filter(opt => opt.value !== '')}
                  onChange={e => onPropertyChange(saleProperty.id)({
                  target: {
                    type: 'select',
                    name: 'ownership_type',
                    value: e.value === null ? '' : e.value,
                  },
                })}
                />
                {(saleProperty.ownership_type === '' && showValidation) &&
                <p style={{ marginTop: '10px' }} className="asterisk-error">Ownership Type is required</p>}
              </div>
              <div className="field select-subtype">
                <label>
                  <span className={(saleProperty.voucher_property_type_category === '' && showValidation) ?
                  'asterisk-error' : ''}
                  >*
                  </span> Property Type / Subtype
                </label>
                <Select
                  className={saleProperty.voucher_property_type_category === '' ? 'errorInput' : ''}
                  defaultOption={saleProperty.voucher_property_type_category || 'Select'}
                  options={propertyOptions.filter(opt => opt.value !== '')}
                  onChange={e => onPropertyChange(saleProperty.id)({
                  target: {
                    type: 'select',
                    name: 'voucher_property_type_category',
                    value: e.value === null ? '' : e.value,
                  },
                })}
                />
                {(saleProperty.voucher_property_type_category === '' && showValidation) &&
                <p style={{ marginTop: '10px' }} className="asterisk-error">Property Type / Subtype is required</p>}
              </div>
              <div className="field select-subtype">
                <label>
                  <span className={(saleProperty.space_type === '' && showValidation) ?
                  'asterisk-error' : ''}
                  >*
                  </span> Space Type
                </label>
                <Select
                  className={saleProperty.space_type === '' ? 'errorInput' : ''}
                  defaultOption={saleProperty.space_type || 'Select'}
                  options={spaceTypeOptions.filter(opt => opt.value !== '')}
                  onChange={e => onPropertyChange(saleProperty.id)({
                  target: {
                    type: 'select',
                    name: 'space_type',
                    value: e.value === null ? '' : e.value,
                  },
                })}
                />
                {(saleProperty.space_type === '' && showValidation) &&
                <p style={{ marginTop: '10px' }} className="asterisk-error">Space Type is required</p>}
              </div>
              <div className="field">
                <label>Number of Tenants</label>
                <input
                  type="text"
                  name="number_of_tenants"
                  value={saleProperty.number_of_tenants || ''}
                  onChange={onPropertyChange(saleProperty.id)}
                  onBlur={onPropertyBlur(saleProperty.id)}
                />
              </div>
              <div className="field">
                <label>Number of Units</label>
                <input
                  type="text"
                  name="number_of_units"
                  value={saleProperty.number_of_units || ''}
                  onChange={onPropertyChange(saleProperty.id)}
                  onBlur={onPropertyBlur(saleProperty.id)}
                />
              </div>
              <div className={`${saleProperty.whole_building ? 'form-row' : ''}`}>
                <div className="field-group col-2">
                  {saleProperty.whole_building ?
                    <div className="form-row-with-labels-and-hints">
                      <FormGroup
                        label="Square Footage"
                        size="large"
                        isRequired
                        hasError={!saleProperty.building_total_square_feet && showValidation}
                        errorMessage="Square Footage is required"
                      >
                        <input
                          type="text"
                          name="building_total_square_feet"
                          value={(saleProperty.building_total_square_feet && Math.round(saleProperty.building_total_square_feet)) || ''}
                          onChange={onPropertyChange(saleProperty.id)}
                          onBlur={onPropertyBlur(saleProperty.id)}
                        />
                      </FormGroup>
                    </div>
                  : ''
                  }
                </div>
                <div className="field-group col-2">
                  <div className="form-row-with-labels-and-hints">
                    <FormGroup
                      label="Parcel Size/Acres"
                      size="large"
                      hasError={false}
                    >
                      <input
                        type="text"
                        name="parcel_size"
                        value={(saleProperty.parcel_size && Math.round(saleProperty.parcel_size)) || ''}
                        onChange={onPropertyChange(saleProperty.id)}
                        onBlur={onPropertyBlur(saleProperty.id)}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
              <legend className="secondary-legend">Is Property listed by CBRE?</legend>
              <RadioGroup
                name="is_listed"
                className="radioVal"
                selectedValue={`${saleProperty.is_listed}`}
                onChange={onPropertyChange(saleProperty.id)}
                orientation="vertical"
              >
                <RadioButton value="false">No</RadioButton>
                <RadioButton value="true">Yes</RadioButton>
              </RadioGroup>
              {saleProperty.is_listed &&
                <div className="field">
                  <label>CBRE Listing #</label>
                  <input
                    type="text"
                    name="cbre_listing_number"
                    value={saleProperty.cbre_listing_number || ''}
                    onChange={onPropertyChange(saleProperty.id)}
                    onBlur={onPropertyBlur(saleProperty.id)}
                  />
                </div>
              }
              <legend className="secondary-legend">Is Property Managed by CBRE?</legend>
              <RadioGroup
                name="is_property_managed_by_cbre"
                className="radioVal"
                selectedValue={`${saleProperty.is_property_managed_by_cbre}`}
                onChange={onPropertyChange(saleProperty.id)}
                orientation="vertical"
              >
                <RadioButton value="false">No</RadioButton>
                <RadioButton value="true">Yes</RadioButton>
              </RadioGroup>
              <div className="field">
                <label>Mix Property ID</label>
                <input
                  type="text"
                  name="mix_property_id"
                  value={saleProperty.mix_property_id || ''}
                  onChange={onPropertyChange(saleProperty.id)}
                  onBlur={onPropertyBlur(saleProperty.id)}
                />
              </div>
              <div className="field">
                <label>Portfolio Name</label>
                <input
                  type="text"
                  name="portfolio_name"
                  value={saleProperty.portfolio_name || ''}
                  onChange={onPropertyChange(saleProperty.id)}
                  onBlur={onPropertyBlur(saleProperty.id)}
                />
              </div>
              {!saleProperty.whole_building && saleProperty.spaces.map((space, i) => (
              // eslint-disable-next-line react/no-array-index-key
                <div key={`${saleProperty.id}-${i}`}>
                  <legend>Suite</legend>
                  <div className="form-row">
                    <div className="field-group col-3">
                      <label>Suite</label>
                      <input
                        type="text"
                        name="address_line2"
                        value={space.address_line2 || ''}
                        onChange={onSpaceChange(saleProperty.id, i)}
                        onBlur={onSpaceBlur(saleProperty.id, i)}
                      />
                    </div>
                    <div className="field-group col-3">
                      <label>Floor</label>
                      <input
                        type="text"
                        name="floor"
                        value={space.floor || ''}
                        onChange={onSpaceChange(saleProperty.id, i)}
                        onBlur={onSpaceBlur(saleProperty.id, i)}
                      />
                    </div>
                    <div className="field-group col-3">
                      <label>Square Feet</label>
                      <input
                        type="number"
                        name="space_total_square_feet"
                        value={space.space_total_square_feet || ''}
                        onChange={onSpaceChange(saleProperty.id, i)}
                        onBlur={onSpaceBlur(saleProperty.id, i)}
                      />
                    </div>
                    {saleProperty.spaces.length > 1 &&
                    <Icon className="closeIcon" onClick={() => onSpaceDelete(saleProperty.id, i)} iconName="close-circle" size="large" />
                }
                  </div>
                </div>
            ))
            }
            </form>
          </div>
        </>
      }
      <div className="voucher-property">
        {properties.map(cp => (
          <form key={cp.id}>
            <span>
              {dealProperties.length > (linkedProperties[cp.id] ? 3 : 2) &&
                <React.Fragment>
                  <span className="poc-text">
                    {showMore[cp.id] ?
                      <span className="show-more" onClick={() => handleShowMore(cp.id)}>
                        <Icon iconName="minus-square icon-square" size="small" /> <span className="show-text">Show Less</span>
                      </span> :
                      <span className="show-more" onClick={() => handleShowMore(cp.id)}>
                        <Icon iconName="plus-square icon-square" size="small" />
                        <span className="show-text">Show More</span> ({dealProperties.length - (linkedProperties[cp.id] ? 3 : 2)})
                      </span>}
                  </span>
                </React.Fragment>}
            </span>
          </form>
        ))
        }
      </div>
    </div>
  );
};

PropertySaleTab.propTypes = {
  voucher: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  deal: PropTypes.shape({
    rep_role: PropTypes.string.isRequired,
    deal_type: PropTypes.string,
    is_dual_rep_deal: PropTypes.bool,
    id: PropTypes.number,
    client: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
};

export default PropertySaleTab;
