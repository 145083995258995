import * as commissionsService from '../../../services/voucher/commissions-service';
import {
  FETCH_COMMISSIONS_SUCCESS,
  UPDATE_COMMISSION_SUCCESS,
  CREATE_COMMISSION_SUCCESS,
  DELETE_COMMISSION_SUCCESS,
} from '../action-types';

// action creators
const fetchCommissionsSuccess = commissions => ({
  type: FETCH_COMMISSIONS_SUCCESS,
  payload: commissions,
});

const updateCommissionSuccess = commission => ({
  type: UPDATE_COMMISSION_SUCCESS,
  payload: commission,
});

const createCommissionSuccess = commission => ({
  type: CREATE_COMMISSION_SUCCESS,
  payload: commission,
});

const deleteCommissionSuccess = id => ({
  type: DELETE_COMMISSION_SUCCESS,
  payload: id,
});

// asyn action creators
const fetchCommissions = params => dispatch => commissionsService.fetchCommissions(params)
  .then(commissions => dispatch(fetchCommissionsSuccess(commissions)))
  .catch(console.error);

const updateCommission = (id, data) => dispatch => commissionsService.updateCommission(id, data)
  .then((commission) => {
    dispatch(updateCommissionSuccess(commission));
    return commission;
  })
  .catch(console.error);

const createCommission = params => dispatch => commissionsService.createCommission(params)
  .then(commission => dispatch(createCommissionSuccess(commission)))
  .catch(console.error);

const deleteCommission = id => dispatch => commissionsService.deleteCommission(id)
  .then(() => {
    dispatch(deleteCommissionSuccess(id));
    return id;
  })
  .catch(console.error);

export {
  fetchCommissions,
  updateCommission,
  createCommission,
  deleteCommission,
};
