import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import LabeledItem from '../../nucleus/labeled-item/labeled-item';
import { getRoleKey } from '../../utils/transaction-utils';

class VoucherDetailInformation extends React.Component {
  renderContent = () => {
    const {
      deal_type,
      date_approved,
      lead_office,
      client,
      date_lease_from,
      date_lease_to,
      rep_role,
      naics_desc,
    } = this.props.transaction;

    const emptyState = '--';

    /* Params: 'landlord', 'tenant', 'seller', 'buyer'
     * This looks for the key of given 'party' based on the 'repRole'.
     * and then it returns the name of the party based on the key('client' or 'counter_party'). */
    const getPartyName = (repRole, party) => {
      const key = getRoleKey(repRole, party);
      return (this.props.transaction[key] && this.props.transaction[key].name) || emptyState;
    };

    const formatDate = (date) => {
      if (date) {
        return moment(date).format('ll');
      }

      return emptyState;
    };

    const renderLeaseContent = deal_type === 'lease' &&
    (
      <div className="lease-information">
        <div className="half-column">
          <LabeledItem label="Close Date" item={formatDate(date_approved)} />
          <LabeledItem label="Lead Office" item={lead_office || emptyState} />
          <LabeledItem label="Landlord" item={getPartyName(rep_role, 'landlord')} />
          <LabeledItem label="Tenant" item={getPartyName(rep_role, 'tenant')} />
          <LabeledItem label="NAICS Code/Desc" item={naics_desc || emptyState} />
        </div>
        <div className="half-column">
          <LabeledItem label="Deal Type" item={deal_type || emptyState} />
          <LabeledItem label="Industry" item={emptyState} />
          <LabeledItem label="Lease Start" item={formatDate(date_lease_from)} />
          <LabeledItem label="Lease End" item={formatDate(date_lease_to)} />
        </div>
      </div>
    );

    const renderSaleContent = deal_type === 'sale' &&
    (
      <div className="sale-information">
        <div className="half-column">
          <LabeledItem label="Close Date" item={formatDate(date_approved)} />
          <LabeledItem label="Lead Office" item={lead_office || emptyState} />
          <LabeledItem label="Seller" item={getPartyName(rep_role, 'seller')} />
          <LabeledItem label="Buyer" item={getPartyName(rep_role, 'buyer')} />
          <LabeledItem label="NAICS Code/Desc" item={naics_desc || emptyState} />
        </div>
        <div className="half-column">
          <LabeledItem label="Deal Type" item={deal_type || emptyState} />
          <LabeledItem label="Industry" item={emptyState} />
        </div>
      </div>
    );

    const renderConsultingContent = (deal_type === 'consulting' || deal_type === 'other') &&
    (
      <div className="consulting-information">
        <div className="half-column">
          <LabeledItem label="Close Date" item={formatDate(date_approved)} />
          <LabeledItem label="Lead Office" item={lead_office || emptyState} />
          <LabeledItem label="Client" item={client.name || emptyState} />
        </div>
        <div className="half-column">
          <LabeledItem label="Deal Type" item={deal_type || emptyState} />
          <LabeledItem label="Industry" item={emptyState} />
        </div>
      </div>
    );

    return (
      <React.Fragment>
        {renderLeaseContent}
        {renderSaleContent}
        {renderConsultingContent}
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="panel" id="informtaion-panel">
        <div className="panel-header">
          Deal Information
        </div>
        <div className="panel-content panel-content-padding">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

VoucherDetailInformation.propTypes = {
  transaction: PropTypes.shape({
    client: PropTypes.shape({
      name: PropTypes.string,
    }),
    counter_party: PropTypes.shape({}),
    deal_type: PropTypes.string,
    date_approved: PropTypes.string,
    date_lease_from: PropTypes.string,
    date_lease_to: PropTypes.string,
    lead_office: PropTypes.string,
    naics_desc: PropTypes.string,
    rep_role: PropTypes.string,
  }).isRequired,
};

export default VoucherDetailInformation;
