import {
  addTeam as addNewTeam,
  fetchTeams as fetchTeamsList,
  fetchTeamByID,
  updateTeam as updateCurrentTeam,
  updateUserTeamAutoAddPreference as updateUserTeamAutoAddToDeals,
  addTeamMember as addNewTeamMember,
  removeTeamMember as removeCurrentTeamMember,
} from '../../services/teams-service';

import {
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FAILURE,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAILURE,
  FETCH_TEAM_BY_ID_SUCCESS,
  FETCH_TEAM_BY_ID_FAILURE,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAILURE,
  ADD_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_FAILURE,
  REMOVE_TEAM_MEMBER_SUCCESS,
  REMOVE_TEAM_MEMBER_FAILURE,
  REMOVE_TEAM_AND_MEMBER_SUCCESS,
  REMOVE_TEAM_AND_MEMBER_FAILURE,
  UPDATE_USER_TEAM_AUTO_ADD_SUCCESS,
  UPDATE_USER_TEAM_AUTO_ADD_FAILURE,
} from './action-types';
import { toast } from './toasts-actions';

import userEventService from '../../services/user-event-service';

const analyticProperties = {
  actionPrefix: 'team_',
  category: 'Teams',
};

const addTeamMemberSuccess = team => ({
  type: ADD_TEAM_MEMBER_SUCCESS,
  payload: { ...team },
});

const addTeamSuccess = team => ({
  type: ADD_TEAM_SUCCESS,
  payload: { ...team },
});

const fetchTeamsListSuccess = response => ({
  type: FETCH_TEAMS_SUCCESS,
  payload: { ...response },
});

const fetchTeamByIDSuccess = response => ({
  type: FETCH_TEAM_BY_ID_SUCCESS,
  payload: { ...response },
});

const removeTeamMemberSuccess = team => ({
  type: REMOVE_TEAM_MEMBER_SUCCESS,
  payload: { ...team },
});

const removeTeamAndMemberSuccess = team => ({
  type: REMOVE_TEAM_AND_MEMBER_SUCCESS,
  payload: { ...team },
});

const updateTeamSuccess = team => ({
  type: UPDATE_TEAM_SUCCESS,
  payload: { ...team },
});

const updateUserTeamAutoAddSuccess = team => ({
  type: UPDATE_USER_TEAM_AUTO_ADD_SUCCESS,
  payload: { ...team },
});

const handleError = (error, type) => ({
  type,
  payload: { error },
});

const addTeamMember = params => dispatch =>
  addNewTeamMember(params)
    .then((team) => {
      const { users } = team;
      const newMember = users.filter(u => u.id === params.newTeamMember.user_id)[0];
      const oldTeamMembers = users.filter(u => u !== newMember);
      const orderedTeam = {
        ...team,
        users: [newMember, ...oldTeamMembers],
      };
      dispatch(addTeamMemberSuccess(orderedTeam));
      dispatch(toast(`${params.newTeamMember.full_name} added to team "${params.teamName}"`));

      userEventService.trackEvent({
        eventAction: `${analyticProperties.actionPrefix}member_added`,
        eventCategory: analyticProperties.category,
        eventLabel: team.id,
        eventData: {
          team_pk: team.id,
          user_pk: params.newTeamMember.user_id,
        },
      });
    })
    .catch((error) => {
      dispatch(handleError(error, ADD_TEAM_MEMBER_FAILURE));
      dispatch(toast('Error Adding Team Member'));
    });

const addTeam = params => dispatch =>
  addNewTeam(params)
    .then((team) => {
      dispatch(addTeamSuccess(team));

      userEventService.trackEvent({
        eventAction: `${analyticProperties.actionPrefix}created`,
        eventCategory: analyticProperties.category,
        eventLabel: team.id,
        eventData: {
          team_pk: team.id,
          teamMember_pks: { ...team.users },
        },
      });
    })
    .catch((error) => {
      dispatch(handleError(error, ADD_TEAM_FAILURE));
      dispatch(toast('Error Adding Team'));
    });

const fetchTeams = () => dispatch =>
  // fetch all teams
  fetchTeamsList()
    .then((response) => {
      dispatch(fetchTeamsListSuccess(response));
    })
    .catch((error) => {
      dispatch(handleError(error, FETCH_TEAMS_FAILURE));
      dispatch(toast('Error Fetching Teams'));
    });

const fetchTeam = id => dispatch =>
  // fetch an individuals team's data
  fetchTeamByID(id)
    .then((response) => {
      dispatch(fetchTeamByIDSuccess(response));
    })
    .catch((error) => {
      dispatch(handleError(error, FETCH_TEAM_BY_ID_FAILURE));
    });

const trackTeamRemoval = (teamId, memberToRemoveId) => {
  userEventService.trackEvent({
    eventAction: `${analyticProperties.actionPrefix}member_removed`,
    eventCategory: analyticProperties.category,
    eventLabel: teamId,
    eventData: {
      team_pk: teamId,
      user_pk: memberToRemoveId,
    },
  });
};

const removeTeamMember = params => dispatch =>
  removeCurrentTeamMember(params)
    .then((team) => {
      dispatch(removeTeamMemberSuccess(team));
      dispatch(toast(`${params.memberToRemove.first_name} ${params.memberToRemove.last_name} removed from team "${params.teamName}"`));
      trackTeamRemoval(team.id, params.memberToRemove.id);
    })
    .catch((error) => {
      dispatch(handleError(error, REMOVE_TEAM_MEMBER_FAILURE));
      dispatch(toast('Error Removing Team Member'));
    });

const removeTeamAndMember = params => dispatch =>
  removeCurrentTeamMember(params)
    .then((team) => {
      dispatch(removeTeamAndMemberSuccess(team));
      dispatch(toast(`You left team "${params.teamName}"`));
      trackTeamRemoval(team.id, params.memberToRemove.id);
    })
    .catch((error) => {
      dispatch(handleError(error, REMOVE_TEAM_AND_MEMBER_FAILURE));
      dispatch(toast('Error Leaving Team'));
    });

const updateTeam = params => dispatch =>
  updateCurrentTeam(params)
    .then((team) => {
      dispatch(updateTeamSuccess(team));

      userEventService.trackEvent({
        eventAction: `${analyticProperties.actionPrefix}changed`,
        eventCategory: analyticProperties.category,
        eventLabel: team.id,
        eventData: {
          team_pk: team.id,
          color: team.avatar_color,
          name: team.name,
        },
      });
    })
    .catch((error) => {
      dispatch(handleError(error, UPDATE_TEAM_FAILURE));
      dispatch(toast('Error Updating Team'));
    });

const updateUserTeamAutoAddPreference = params => dispatch =>
  updateUserTeamAutoAddToDeals(params)
    .then((team) => {
      dispatch(toast(`Auto-Suggest To New Deals ${params.auto_add ? '"On"' : '"Off"'} for ${team.name}`));
      dispatch(updateUserTeamAutoAddSuccess(team));
      userEventService.trackEvent({
        eventAction: `${analyticProperties.actionPrefix}member_autoadd_changed`,
        eventCategory: analyticProperties.category,
        eventLabel: team.id,
        eventData: {
          team_pk: team.id,
          user_pk: params.user_id,
          enabled: params.auto_add,
        },
      });
    })
    .catch((error) => {
      dispatch(handleError(error, UPDATE_USER_TEAM_AUTO_ADD_FAILURE));
      dispatch(toast('Error Updating Auto-add'));
    });

export {
  addTeam,
  updateTeam,
  updateUserTeamAutoAddPreference,
  addTeamMember,
  removeTeamMember,
  removeTeamAndMember,
  fetchTeams,
  fetchTeam,
};
