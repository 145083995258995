import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';

import AutocompleteFlyout from '../autocomplete/autocomplete-flyout';
import { search, buildingLookup } from '../../services/esri-service';
import abbreviate from '../../utils/state-abbreviator';

class PropertyAutocomplete extends React.Component {
  static propTypes = {
    /** Callback for when a user clicks on a result */
    onSelect: PropTypes.func.isRequired,
    /** An ErrorMessage to show if field is required */
    errorMessage: PropTypes.string,
    /** The string you want to use in the input as a placeholder  */
    placeholder: PropTypes.string,
    initSearchTerm: PropTypes.string,
    filterResultsPredicate: PropTypes.func,
    dataE2e: PropTypes.string,
    onChange: PropTypes.func,
  };
  static defaultProps = {
    errorMessage: '',
    placeholder: 'Search Address',
    initSearchTerm: '',
    filterResultsPredicate: null,
    dataE2e: 'properties-autocomplete',
    onChange: () => {},
  };
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  onChange = (property) => {
    const {
      onSelect,
      onChange,
    } = this.props;

    if (typeof property === 'string') {
      onChange(property);
    } else {
      onChange('');
    }

    if (property.unknownProperty) {
      const unknownProperty = {
        magicKey: uniqueId(),
        name: 'Unknown Property',
        building: {
          address: null,
          city: null,
          stateName: null,
          postal_code: null,
          country: null,
        },
      };
      onSelect(unknownProperty, true);
      return;
    }
    if (property && typeof property !== 'string') {
      this.setState({ isLoading: true });
      const params = {
        singleLine: property.text,
        magicKey: property.magicKey,
        f: 'json',
      };
      buildingLookup(params)
        .then((res) => {
          const result = {
            magicKey: property.magicKey,
            name: get(res, 'candidates[0].attributes.PlaceName'),
            esriSearchText: get(res, 'candidates[0].address'),
            building: {
              address: get(res, 'candidates[0].attributes.StAddr'),
              city: get(res, 'candidates[0].attributes.City'),
              stateName: get(res, 'candidates[0].attributes.Region'),
              country: get(res, 'candidates[0].attributes.Country'),
            },
          };

          if (result.building.country === 'CAN') {
            result.building.postal_code = `${get(res, 'candidates[0].attributes.Postal')} ${get(res, 'candidates[0].attributes.PostalExt')}`;
          } else {
            result.building.postal_code = get(res, 'candidates[0].attributes.Postal');
          }

          if (result.building.stateName) {
            result.building.state_abbreviation = abbreviate(result.building.stateName);
          }
          this.setState({ isLoading: false });
          onSelect(result);
        }).catch(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  fetchAddresses = (params) => {
    const searchParams = {
      f: 'json',
      countryCode: 'USA,CAN,BR,AR,CL,CO,MX',
      text: params.search,
      maxSuggestions: 15,
    };
    return new Promise((resolve, reject) => {
      search(searchParams)
        .then((data) => {
          if (data.suggestions) {
            resolve(data.suggestions);
          } else {
            resolve([]);
          }
        }).catch((error) => {
          reject(error);
        });
    });
  }

  renderListItem = result => (
    <div> {result.text}</div>
  );

  renderFooterContent = onSelect => (
    <p onClick={() => onSelect({ unknownProperty: true })}>Exact address match not found. Select as Unknown Property.</p>
  )

  render() {
    const {
      errorMessage,
      placeholder,
      initSearchTerm,
      filterResultsPredicate,
      dataE2e,
    } = this.props;
    const { isLoading } = this.state;
    const loadingMessage = isLoading && 'Loading Property Information';

    return (
      <div>
        <AutocompleteFlyout
          initSearchTerm={initSearchTerm}
          fetchRequest={this.fetchAddresses}
          filterResultsPredicate={filterResultsPredicate}
          renderFooterContent={this.renderFooterContent}
          onChange={this.onChange}
          placeholder={loadingMessage || placeholder}
          renderListItem={this.renderListItem}
          errorMessage={errorMessage}
          isLoading={isLoading}
          dataE2e={dataE2e}
        />
      </div>
    );
  }
}

export default PropertyAutocomplete;
