import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';
import Cropper from 'react-easy-crop';
import moment from 'moment';
import Slider from '../../nucleus/slider/slider';
import Modal from '../../nucleus/modal/modal';
import cropImage from './crop-image';
import userService from '../../services/user-service';

class ImageCropperModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 4 / 4,
      errorMessage: null,
      croppedImage: null,
      showModal: props.showModal,
    };

    this.fileInputRef = React.createRef();

    if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
      this.setState({ errorMessage: 'The File APIs are not fully supported in this browser.' });
    }
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = async (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });

    const croppedImage = await cropImage(
      this.state.imageSrc,
      this.state.croppedAreaPixels,
    );

    this.setState({ croppedImage });
  };

  onZoomChange = async (zoom) => {
    this.setState({ zoom });

    const croppedImage = await cropImage(
      this.state.imageSrc,
      this.state.croppedAreaPixels,
    );

    this.setState({ croppedImage });
  };

  onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0 && !this.state.errorMessage) {
      const file = e.target.files[0];

      const imageDataUrl = await this.readFile(file);
      this.setState({
        imageSrc: imageDataUrl,
        crop: { x: 0, y: 0 },
        zoom: 1,
        croppedImage: imageDataUrl,
      });
    }
  };

  dataURItoBlob = (dataURI) => {
    if (!dataURI) return null;

    const binary = atob(dataURI.split(',')[1]); // eslint-disable-line
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const array = [];

    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }

    return new Blob([new Uint8Array(array)], {type: mimeString}); // eslint-disable-line
  };

  saveCroppedImage = async () => {
    // get file from blob url
    const file = await fetch(this.state.croppedImage).then(r => r.blob());

    // read file into base64 encoded image
    const imageDataUrl = await this.readFile(file);

    // convert the base64 encoded image into a blob
    const blob = this.dataURItoBlob(imageDataUrl);

    // add a timestamp to bust cache
    const type = blob.type.split('/')[1];
    blob.name = `pic${moment().format('YYYYMMDDhhmmss')}.${type}`;
    userService.uploadFile('avatar', blob).then(() => {
      this.setState({ showModal: false });
      this.props.loadUserData();
    })
      .catch(error => console.log('Error uploading file: ', error));
  };

  readFile = file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

  openFileDialog = () => {
    this.fileInputRef.current.click();
  };

  render() {
    const {
      closeModal,
    } = this.props;

    const {
      showModal,
    } = this.state;

    return (
      <Modal
        showModal={showModal}
        handleModalToggle={closeModal}
        modalHeader="Upload Avatar"
        modalWidth={400}
        handleSecondaryButton={closeModal}
        handleModalSubmit={this.saveCroppedImage}
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
      >
        <div className="image-cropper-modal">
          {this.state.imageSrc && (
            <Fragment>
              <div className="crop-container">
                <img src={this.state.croppedImage} alt="cropped" />
              </div>
              <div className="crop-container">
                <Cropper
                  image={this.state.imageSrc}
                  cropShape="round"
                  crop={this.state.crop}
                  zoom={this.state.zoom}
                  aspect={this.state.aspect}
                  onCropChange={this.onCropChange}
                  onCropComplete={this.onCropComplete}
                  onZoomChange={this.onZoomChange}
                  showGrid={false}
                />
              </div>
              <Slider
                minValue={1}
                maxValue={5}
                onChange={(e, zoom) => this.onZoomChange(e, zoom)}
                value={this.state.zoom || 1}
              />
            </Fragment>
        )}

          {!this.state.imageSrc &&
            <Fragment>
              <div
                className="nd-dropzone"
                onClick={this.openFileDialog}
              >
                <input
                  ref={this.fileInputRef}
                  className="file"
                  type="file"
                  onChange={e => this.onFileChange(e)}
                />
                <span className="dropzone-text">
                  <Icon iconName="add-note" />
                  Click to upload
                </span>
              </div>
            </Fragment>
          }
        </div>
      </Modal>
    );
  }
}

ImageCropperModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  loadUserData: PropTypes.func.isRequired,
};

ImageCropperModal.defaultProps = {
};

export default ImageCropperModal;
