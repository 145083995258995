import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import LabeledItem from '../../../nucleus/labeled-item/labeled-item';
import formatNumberWithCommas from '../../../utils/format-number-with-commas';
import formatAsCurrency from '../../../utils/format-as-currency';
import Percent from '../../../nucleus/formats/percent';
import { validateAddress } from '../voucher-validation';

const RequiredLabeledItem = ({
  format,
  item,
  isReview,
  notRequired,
  label,
}) => {
  let showError = false;
  let updatedItem = item;
  if (format !== 'bool' && !item && item !== 0 && !notRequired) {
    updatedItem = `${label} is required`;
    showError = true;
    if (isReview) {
      localStorage.setItem('voucher_valid', JSON.stringify(false));
    }
  }
  if (item || format === 'bool') {
    switch (format) {
      case 'address':
        if (validateAddress(item)) {
          updatedItem = (
            <>
              {item.address} <br />
              {item.city}, {item.state_abbreviation || item.territory} {item.postal_code || item.postal}
            </>
          );
        } else {
          updatedItem = `${label} is required`;
          showError = true;
        }
        break;
      case 'bool':
        updatedItem = item ? 'Yes' : 'No';
        break;
      case 'currency':
        updatedItem = formatAsCurrency(item);
        break;
      case 'number':
        updatedItem = item && formatNumberWithCommas(Number(item).toFixed(0));
        break;
      case 'percent':
        updatedItem = <Percent decimalRounding={2} value={Number(item)} />;
        break;
      case 'shortDate':
        updatedItem = moment(item).format('M/D/YY');
        break;
      default:
    }
  }

  return (
    <div className={`${showError && 'labeled-item-error'}`}>
      <LabeledItem label={label} item={updatedItem} emptyState="--" />
    </div>
  );
};

RequiredLabeledItem.propTypes = {
  format: PropTypes.string,
  isReview: PropTypes.bool,
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.any]),
  label: PropTypes.string.isRequired,
  notRequired: PropTypes.bool,
};

RequiredLabeledItem.defaultProps = {
  format: null,
  isReview: false,
  item: '',
  notRequired: false,
};

export default RequiredLabeledItem;
