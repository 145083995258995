import {
  CREATE_ADDITIONAL_COMMISSIONS_SUCCESS,
  DELETE_ADDITIONAL_COMMISSIONS_SUCCESS,
  FETCH_ADDITIONAL_COMMISSIONS_BY_DEAL_ID_SUCCESS,
  UPDATE_ADDITIONAL_COMMISSIONS_SUCCESS,
} from '../actions/action-types';

const initialState = {
  additionalCommissions: [],
};

const addAdditionalCommissionSuccess = (state, action) => ({
  ...state,
  additionalCommissions: [...state.additionalCommissions, action.payload],
});

const deleteAdditionalCommissionSuccess = (state, action) => ({
  ...state,
  additionalCommissions: state.additionalCommissions.filter(({ id }) => id !== action.payload),
});

const fetchAdditionalCommissionsSuccess = (state, action) => ({
  ...state,
  additionalCommissions: action.payload,
});

const updateAdditionalCommissionSuccess = (state, action) => ({
  ...state,
  additionalCommissions: state.additionalCommissions.map(additionalCommission => (
    additionalCommission.id !== action.payload.id
      ? additionalCommission : action.payload
  )),
});

const additionalCommissions = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ADDITIONAL_COMMISSIONS_SUCCESS:
      return addAdditionalCommissionSuccess(state, action);
    case DELETE_ADDITIONAL_COMMISSIONS_SUCCESS:
      return deleteAdditionalCommissionSuccess(state, action);
    case FETCH_ADDITIONAL_COMMISSIONS_BY_DEAL_ID_SUCCESS:
      return fetchAdditionalCommissionsSuccess(state, action);
    case UPDATE_ADDITIONAL_COMMISSIONS_SUCCESS:
      return updateAdditionalCommissionSuccess(state, action);
    default:
      return state;
  }
};

export default additionalCommissions;

