import moment from 'moment';

const formatDueDate = (isoStr) => {
  if (typeof isoStr !== 'string') {
    return '';
  }
  const date = moment(isoStr);
  const endOfToday = moment().endOf('day');
  const startOfToday = moment().startOf('day');

  const tomorrow = moment().add(1, 'day').endOf('day');

  if (date <= endOfToday && date >= startOfToday) return 'Due Today';
  if (date <= tomorrow && date >= endOfToday) return 'Due Tomorrow';
  return date.format('l');
};

export default formatDueDate;
