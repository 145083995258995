import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';
import ButtonWithFlyout from '../../nucleus/button-with-flyout/button-with-flyout';
import TeamAvatar from '../teams/team-avatar';

class TeamColorPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentColor: this.props.currentColor,
    };
  }

  setCurrentColorOption = (option) => {
    const { setColor } = this.props;
    this.setState({
      currentColor: option,
    });
    this.buttonWithFlyout.closeFlyout();
    setColor(option);
  };

  render() {
    const {
      currentColor,
    } = this.state;
    const {
      colorOptions,
    } = this.props;

    const renderColorOptions = colorOptions && colorOptions.map(option => (
      <div key={option} className="hoverstate option-item">
        <div
          className="menu-item-button color-select"
          onClick={() => this.setCurrentColorOption(option)}
        >
          <TeamAvatar
            teamColor={option}
            isCurrent={option === currentColor}
          />
          <span className="hoverstatetext align-center">
            {option}
          </span>
        </div>
      </div>
    ));

    const colorButton = (
      <div
        className="team-color-picker-button"
      >
        <TeamAvatar
          teamColor={currentColor}
        />
        <Icon iconName="chevron-down" />
      </div>
    );

    return (
      <div className="team-color-picker-wrapper">
        <label>Team Color</label>
        <div className="team-color-picker-flyout-with-name">
          <ButtonWithFlyout
            buttonText={colorButton}
            onRef={(ref) => { this.buttonWithFlyout = ref; }}
          >
            <div className="select">
              {renderColorOptions}
            </div>
          </ButtonWithFlyout>
          <p className="selected-color data-label">{this.state.currentColor}</p>
        </div>
      </div>
    );
  }
}

TeamColorPicker.propTypes = {
  setColor: PropTypes.func.isRequired,
  currentColor: PropTypes.string.isRequired,
  colorOptions: PropTypes.arrayOf(PropTypes.string),
};

TeamColorPicker.defaultProps = {
  colorOptions: ['lime',
    'green',
    'gold',
    'apricot',
    'orange',
    'red',
    'magenta',
    'sky',
    'teal',
    'blue',
    'indigo',
    'purple',
  ],
};

export default TeamColorPicker;
