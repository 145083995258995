import React from 'react';
// import DealIQLogo from '../../assets/images/svg/deal-iq-logo-on-black.svg';
import DealIQLogo from '../../assets/images/svg/deal-iq-logo-white-on-black.svg';

const TopNavPublic = () => (
  <div id="top-nav-public" className="top-nav">
    <a href="/">
      <div className="nav-logo">
        <img
          alt="Deal IQ Logo"
          src={DealIQLogo}
        />
      </div>
    </a>
  </div>
);

export default TopNavPublic;
