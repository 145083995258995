import React, { useState, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@cbrebuild/blocks';
import unionBy from 'lodash/unionBy';
import GroupCard from '../../components/labs/group/group-card';
import {
  fetchGroups,
  fetchFlags,
  addGroup,
  fetchGroup,
  addUserToGroup,
  removeUserFromGroup,
  addFlagToGroup,
  removeFlagFromGroup,
  turnOnFlagForAllUsers,
  turnOffFlagForAllUsers,
  fetchGroupUsers,
} from '../../services/admin-service';
import RowItem from '../../nucleus/row-item/row-item';
import AddTag from '../../nucleus/legacy/tag/add-tag';
import FilterTag from '../../nucleus/legacy/tag/filter-tag';
import Modal from '../../nucleus/modal/modal';
import { Person, PersonType } from '../../components/person';
import EmptyState from '../../components/empty-state/empty-state';
import LoadMore from '../../nucleus/load-more/load-more';
import ListActionHeader from '../../nucleus/list-action-header/list-action-header';
import BrokerAutocomplete from '../../components/autocomplete/broker-autocomplete';

const MODAL_WIDTH = 650;
const PAGE_SIZE = 25;

const AdminLabs = (props) => {
  const {
    toast,
    userData,
  } = props;
  const [allFeatureFlags, setAllFeatureFlags] = useState([]);
  const [suggestedFeatureFlags, setSuggestedFeatureFlags] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [searchUserNextPage, setSearchUserNextPage] = useState(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [selectedFlag, setSelectedFlag] = useState({});
  const [flags, setFlags] = useState([]);
  const [users, setUsers] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showRemoveUserModal, toggleRemoveUserModal] = useState(false);
  const [showRemoveFlagModal, toggleRemoveFlagModal] = useState(false);
  const groupNameRef = useRef();

  const fillFeatureFlagSuggestions = (flagsParam) => {
    fetchFlags().then((flagData) => {
      const features = flagData.results;
      setAllFeatureFlags(features);

      // fill tag suggestion array with what's not already in group
      const suggested = features.reduce((accum, current) => {
        const found = flagsParam.find(flag => flag.name === current.name);

        if (!found) {
          accum.push(current.name);
        }
        return accum;
      }, []);

      setSuggestedFeatureFlags(suggested);
    });
  };

  const fetchUsersForGroup = useCallback((group, page = 1, reload = false) => {
    if (group && group.id && !isLoadingUsers) {
      setIsLoadingUsers(true);
      if (reload) {
        const promises = [];
        for (let i = 1; i <= page; i++) {
          promises.push(fetchGroupUsers({ page_size: PAGE_SIZE, page: i, group_id: group.id }));
        }
        Promise.all(promises)
          .then((responses) => {
            const res = responses.reduce((acc, {
              count, next, results = [], previous,
            }) => ({
              results: unionBy(acc.results || [], results, 'id'),
              // eslint-disable-next-line no-nested-ternary
              next: next === null ? null : next > acc.next ? next : acc.next,
              previous,
              count,
            }), { next: null, count: 0 });
            setUsers(res.results);
            setUserCount(res.count);
            setSearchUserNextPage(res.next);
          }).finally(() => {
            setIsLoadingUsers(false);
          });
      } else {
        fetchGroupUsers({ page_size: PAGE_SIZE, page, group_id: group.id })
          .then(({
            count, next, results = [], previous,
          }) => {
            setSearchUserNextPage(next);
            const allUsers = previous
              ? unionBy(users, results, 'id')
              : results;
            setUsers(allUsers);
            setUserCount(count);
          }).finally(() => {
            setIsLoadingUsers(false);
          });
      }
    }
  }, [isLoadingUsers, users]);

  // call between group toggling
  const refreshGroup = useCallback((group, addToExistingGroups = false) => {
    if (group && group.id) {
      fetchGroup(group.id).then((groupData) => {
        setSelectedGroup(groupData);
        setFlags(groupData && groupData.flags);
        if (addToExistingGroups) {
          groups.push(groupData);
        }
        fillFeatureFlagSuggestions(groupData.flags);
      });
    }
  }, [groups]);

  // fetch all groups, then for each group feed it with users, flags, flag suggestions
  const initialize = useCallback((group) => {
    fetchGroups().then((response) => {
      if (response.results.length > 0) {
        setGroups(response.results);
      }

      if ((Object.entries(selectedGroup).length === 0 && selectedGroup.constructor === Object)) {
        refreshGroup(response.results && response.results[0]);
        fetchUsersForGroup(response.results && response.results[0]);
      }

      if (group) {
        refreshGroup(group);
        fetchUsersForGroup(group);
      }
    });
  }, [fetchUsersForGroup, refreshGroup, selectedGroup]);

  useMemo(() => {
    if (groups.length === 0) {
      initialize();
    }
  }, [groups, initialize]);

  const closeEditMode = () => {
    setErrorMessage('');
    setIsAdding(false);

    // default to first group
    refreshGroup(groups[0]);
    fetchUsersForGroup(groups[0]);
  };

  // save a group after name is supplied
  const onGroupSave = () => {
    const name = groupNameRef.current.value;

    if (name) {
      const groupNames = groups.map(group => group.name);

      if (groupNames.includes(name)) {
        setErrorMessage('Group already exists, please choose another name');
        return;
      }
      addGroup({
        name,
      }).then((result) => {
        if (!result || !result.id) {
          throw new Error('Adding new group throws error ', result);
        }

        setIsAdding(false);

        // refresh group for other data like features and users, and add it to existing groups list
        refreshGroup(result, true);
        fetchUsersForGroup(result);
      })
        .catch((error) => {
          toast('There was a problem adding this group');
          console.error(error);
        });

      return;
    }

    setErrorMessage('Group Name is required');
  };

  const loadMore = () => {
    fetchUsersForGroup(selectedGroup, searchUserNextPage);
  };

  const handleToggleFlag = (flag) => {
    const params = {
      flag_id: flag.id,
      group_id: selectedGroup.id,
    };

    if (flag.enabled_for_all_users) {
      turnOffFlagForAllUsers(params).then((result) => {
        if (!result) {
          throw new Error('Turning off flag throws error ', result);
        }
        refreshGroup(selectedGroup);

        toast(`${flag.name} has been turned off for all users`);
      }).catch((error) => {
        toast(`There was a problem turning off ${flag.name}`);
        console.log(error);
      });
    } else {
      turnOnFlagForAllUsers(params).then((result) => {
        if (!result) {
          throw new Error('Turning on flag throws error ', result);
        }
        refreshGroup(selectedGroup);

        toast(`${flag.name} has been turned on for all users`);
      }).catch((error) => {
        toast(`There was a problem turning on ${flag.name}`);
        console.log(error);
      });
    }
  };

  const handleAddUser = (user) => {
    addUserToGroup({
      user_id: user.user_id,
      group_id: selectedGroup.id,
    }).then((data) => {
      if (data) {
        refreshGroup(selectedGroup);
        // determine how many pages should be fetched
        const pages = users
          ? Math.ceil((users.length + 1) / PAGE_SIZE)
          : 1;
        const maxPage = (PAGE_SIZE * pages) >= (users ? users.length : 0) ? pages : (pages - 1 || 1);
        fetchUsersForGroup(selectedGroup, maxPage, true);
      }
    });
  };

  const handleRemoveUser = (user) => {
    const params = {
      user_id: user.id,
      group_id: selectedGroup.id,
    };

    removeUserFromGroup(params).then((result) => {
      if (result && result.detail) {
        toggleRemoveUserModal(false);
        throw new Error('Removing user throws error ', result);
      }

      toast(`${user.first_name} ${user.last_name} has been removed`);
      toggleRemoveUserModal(false);

      // refresh current group
      refreshGroup(selectedGroup);
      // determine how many pages should be fetched
      const pages = users
        ? Math.ceil((users.length - 1) / PAGE_SIZE)
        : 1;
      fetchUsersForGroup(selectedGroup, pages, true);
    })
      .catch((error) => {
        toast(`There was a problem removing ${user.name}`);
        toggleRemoveUserModal(false);
        console.error(error);
      });
  };

  const handleAddFlag = (flag) => {
    // do a lookup against all feature flags for id of passed in flag
    const found = allFeatureFlags.find(aff => aff.name === flag);

    if (found) {
      const params = {
        flag_id: found.id,
        group_id: selectedGroup.id,
      };

      addFlagToGroup(params).then(() => {
        refreshGroup(selectedGroup);
      });
    }
  };

  const handleRemoveFlag = (flag) => {
    const params = {
      flag_id: flag.id,
      group_id: selectedGroup.id,
    };

    removeFlagFromGroup(params).then((result) => {
      if (result && result.detail) {
        toggleRemoveFlagModal(false);
        throw new Error('Removing flag throws error ', result);
      }

      toast(`${flag.name} has been removed`);
      toggleRemoveFlagModal(false);

      // refresh current group
      refreshGroup(selectedGroup);
    })
      .catch((error) => {
        toast(`There was a problem removing ${flag.name}`);
        toggleRemoveFlagModal(false);
        console.error(error);
      });
  };

  const filterBrokerAutocompleteResultsPredicate = broker => !(users.some(member => (broker.user_id === member.id)));

  return (
    <div className="admin-labs">
      <div className="groups">
        <RowItem
          key="-1"
          onClick={() => {
            setIsAdding(true);
            setSelectedGroup({});
            setFlags([]);
            setUsers([]);
          }}
        >
          <GroupCard
            group={{ name: 'Group' }}
            icon="plus"
          />
        </RowItem>
        {groups && groups.map(item => (
          <RowItem
            key={item.id}
            onClick={() => {
              refreshGroup(item);
              fetchUsersForGroup(item, 1);
              setIsAdding(false);
              setErrorMessage('');
            }}
          >
            <GroupCard
              group={item}
              icon="group"
              isCurrent={item.name === (selectedGroup && selectedGroup.name)}
            />
          </RowItem>
        ))}
      </div>
      <div className="group-detail">
        <div className="group-header">
          <h2>
            {!isAdding && selectedGroup && selectedGroup.name}
          </h2>
        </div>
        <div className="group-form">
          {isAdding && (
            <input
              type="text"
              className={errorMessage ? 'error' : ''}
              placeholder="Enter a group name"
              ref={groupNameRef}
              required
            />
          )}
          <span className="error">{errorMessage}</span>
          {isAdding && (
            <div className="group-controls">
              <Button variant="secondary" onClick={closeEditMode}>
                Cancel
              </Button>
              <Button onClick={onGroupSave}>
                Save
              </Button>
            </div>
          )}
        </div>
        {!isAdding && (
          <div className="features">
            <div className="feature-flags">
              <p className="feature-label">Features</p>
              <div className="feature-flags-content">
                {flags && flags.map(flag => (
                  <FilterTag
                    isButton={false}
                    key={flag.id}
                    label=""
                    value={flag.name}
                    rightIcon="bin"
                    disabled={false}
                    handleClearFilter={() => {
                      setSelectedFlag(flag);
                      toggleRemoveFlagModal(true);
                    }}
                    handleCheckFilter={() => {
                      handleToggleFlag(flag);
                    }}
                  />
                ))}
                <AddTag
                  onSubmit={handleAddFlag}
                  suggestions={suggestedFeatureFlags}
                  placeHolder="Add Feature"
                />
                {showRemoveFlagModal && (
                  <Modal
                    className="remove-flag-modal"
                    modalHeader={`Remove ${selectedFlag.name}`}
                    primaryButtonText="Remove Flag"
                    handleModalSubmit={() => {
                      handleRemoveFlag(selectedFlag);
                    }}
                    secondaryButtonText="Cancel"
                    handleSecondaryButton={() => { toggleRemoveFlagModal(false); }}
                    handleModalToggle={() => { toggleRemoveFlagModal(!showRemoveFlagModal); }}
                    modalWidth={MODAL_WIDTH}
                    showModal={showRemoveFlagModal}
                  >
                    <div className="confirmation-modal-header">
                      <p>Are you sure you want to remove {selectedFlag.name}?</p>
                    </div>
                  </Modal>
                )}
              </div>
            </div>
          </div>
        )}
        {!isAdding && (
          <ListActionHeader
            filter={(
              <BrokerAutocomplete
                placeholder="Add a user"
                onSelect={handleAddUser}
                filterResultsPredicate={filterBrokerAutocompleteResultsPredicate}
              />
            )}
            sort={<p>{`Showing ${users ? users.length : 0} out of ${userCount} users.`}</p>}
          />
        )}
        {!isAdding && (!users || users.length === 0) && (
          <div className="empty-state-container">
            <EmptyState
              type="users"
              message="Get started by adding a new user"
            />
          </div>
        )}
        <div className="users">
          {!isAdding && users && users.map(user => (
            <RowItem key={user.id}>
              <Person
                person={user}
                type={PersonType.MEMBER}
                isSelf={user.id === userData.id}
                hoverAlign="left"
              />
              {isAdding && (user.id !== userData.id) &&
                <IconButton
                  className="blxs-button-icon-small"
                  iconName="close"
                  onClick={() => {
                    setSelectedUser(user);
                    toggleRemoveUserModal(true);
                  }}
                  variant="basic"
                />}
              {!isAdding && users &&
                <IconButton
                  className="blxs-button-icon-small"
                  iconName="close"
                  onClick={() => {
                    setSelectedUser(user);
                    toggleRemoveUserModal(true);
                  }}
                  variant="basic"
                />}
            </RowItem>
          ))}
          <LoadMore isLoading={isLoadingUsers} hasNextPage={searchUserNextPage} handleLoadMore={loadMore} />
          {showRemoveUserModal && (
            <Modal
              className="remove-user-modal"
              modalHeader={`Remove ${selectedUser.first_name} ${selectedUser.last_name}`}
              primaryButtonText="Remove User"
              handleModalSubmit={() => {
                handleRemoveUser(selectedUser);
              }}
              secondaryButtonText="Cancel"
              handleSecondaryButton={() => { toggleRemoveUserModal(false); }}
              handleModalToggle={() => { toggleRemoveUserModal(!showRemoveUserModal); }}
              modalWidth={MODAL_WIDTH}
              showModal={showRemoveUserModal}
            >
              <div className="confirmation-modal-header">
                <p>Are you sure you want to remove {selectedUser.first_name} {selectedUser.last_name}?</p>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </div >);
};

AdminLabs.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  toast: PropTypes.func,
};

AdminLabs.defaultProps = {
  toast: () => { },
};

export default AdminLabs;
