/* eslint-env browser */
import superFetch from '../utils/super-fetch';

const getSPOCResults = (params) => {
  const url = '/spoc';

  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json());
};

export default { getSPOCResults };
