import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Select from '../../nucleus/select/select';

class LeaseExpirationNotification extends React.PureComponent {
  state = {
    snoozeTime: '',
    snoozeMessage: '',
  }

  handleSnoozeDropdown = (e) => {
    const { handleSnooze } = this.props;
    const currentTime = new Date();
    const currentMonth = currentTime.getMonth();
    let snoozeTime = '';
    let snoozeMessage = '';
    let trackingSnoozeTime = '';

    if (e.value === '3') {
      snoozeTime = new Date(currentTime.setMonth(currentMonth + 3));
      snoozeMessage = 'Notification snoozed for 3 Months';
      trackingSnoozeTime = 'lease_expiration_notification_reminder_time_3months';
    } else if (e.value === '6') {
      snoozeTime = new Date(currentTime.setMonth(currentMonth + 6));
      snoozeMessage = 'Notification snoozed for 6 Months';
      trackingSnoozeTime = 'lease_expiration_notification_reminder_time_6months';
    } else {
      snoozeTime = new Date(currentTime.setFullYear(currentTime.getFullYear() + 1));
      snoozeMessage = 'Notification snoozed for 1 Year';
      trackingSnoozeTime = 'lease_expiration_notification_reminder_time_12months';
    }
    this.setState({
      snoozeTime,
      snoozeMessage,
    }, () => handleSnooze(this.state.snoozeTime, this.state.snoozeMessage, trackingSnoozeTime));
  };

  renderMessage = (message) => {
    const {
      deal,
      handleNavigateTracking,
      turducken,
    } = this.props;

    const dealNameLink = (
      <Link
        className="deal-name-link"
        onClick={handleNavigateTracking}
        to={`/deals/detail/${deal.id}/overview`}
        {...(turducken ? { target: '_self' } : {})}
      >{deal.name}
      </Link>
    );

    const updatedMessage = message.split(`${deal.name}`);
    return (
      <p className="lease-exp-message">{updatedMessage[0]}{dealNameLink}{updatedMessage[1]}</p>
    );
  }

  render() {
    const { message } = this.props;

    const snoozeOptions = [
      {
        value: '3',
        display_name: '3 Months',
      },
      {
        value: '6',
        display_name: '6 Months',
      },
      {
        value: '12',
        display_name: '1 Year',
      },
    ];

    return (
      <React.Fragment>
        {this.renderMessage(message)}
        <div className="snooze-controls">
          <Select
            className="reminder-times"
            defaultOption="Remind Me"
            options={snoozeOptions}
            onChange={e => this.handleSnoozeDropdown(e)}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default LeaseExpirationNotification;

LeaseExpirationNotification.propTypes = {
  handleNavigateTracking: PropTypes.func.isRequired,
  handleSnooze: PropTypes.func.isRequired,
  deal: PropTypes.shape(),
  message: PropTypes.string.isRequired,
  turducken: PropTypes.bool.isRequired,
};

LeaseExpirationNotification.defaultProps = {
  deal: {},
};
