import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';

import Modal from '../../../nucleus/modal/modal';
import considerationsService from '../../../services/voucher/considerations-service';
import additionalCommissionsService from '../../../services/voucher/additional-commissions-service';
import ConsiderationsContainer from './considerations-container';
import AdditionalCommissionsContainer from './additional-commissions-container';
import sumTotalCommissions from '../../../services/voucher/total-commissions-service';
import { PAYMENT_GROUPS } from '../../../services/voucher/commissions-service';
import sequentialExec from '../../../utils/promise-utils';

class CommissionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      considerationsIsPercent: undefined,
    };
  }

  getConsiderationUpdates = () => {
    const { considerationsIsPercent } = this.state;
    const {
      tempConsiderations,
    } = this.props;

    const {
      deleteConsideration,
      updateConsideration,
      createConsideration,
    } = considerationsService;

    if (tempConsiderations !== undefined || considerationsIsPercent !== undefined) {
      const promises = [];
      const considerationsPayload = [];
      map(tempConsiderations, (consideration) => {
        if (consideration.deleted && !consideration.created) {
          promises.push(deleteConsideration(consideration.id));
        }

        if (consideration.updated && !consideration.deleted && !consideration.created) {
          const payload = cloneDeep(consideration);
          delete payload.updated;
          promises.push(updateConsideration(consideration.id, payload));
        }

        if (consideration.created && !consideration.deleted) {
          const payload = cloneDeep(consideration);
          delete payload.created;
          delete payload.updated;
          delete payload.id;
          considerationsPayload.push(payload);
        }
      });

      if (considerationsPayload.length > 0) {
        promises.push(sequentialExec(considerationsPayload, createConsideration));
      }

      return promises;
    }
    return [];
  };

  getAdditionalCommissionUpdates = () => {
    const {
      tempAdditionalCommissions,
    } = this.props;

    const {
      deleteCommission,
      updateCommission,
      createCommission,
    } = additionalCommissionsService;

    if (tempAdditionalCommissions !== undefined) {
      const promises = [];
      map(tempAdditionalCommissions, (commission) => {
        if (commission.deleted && !commission.created) {
          promises.push(deleteCommission(commission.id));
        }

        if (commission.updated && !commission.created && !commission.deleted) {
          const payload = cloneDeep(commission);
          delete payload.updated;
          promises.push(updateCommission(commission.id, payload));
        }

        if (commission.created && !commission.deleted) {
          const payload = cloneDeep(commission);
          delete payload.created;
          delete payload.updated;
          delete payload.id;
          promises.push(createCommission(payload));
        }
      });
      return promises;
    }
    return [];
  };

  handleCommissionsModalSubmit = () => {
    const {
      updateConsiderations,
      updateAdditionalCommissions,
      updateConsiderationsInModal,
      updateAdditionalCommissionsInModal,
      updateDealPipelineTimestamp,
      initializeCommissionsTotal,
      fetchConsiderations,
      fetchAdditionalCommissions,
      fetchCommissions,
      updateDeal,
      toggleModal,
      dealId,
    } = this.props;

    let total = 0;
    let totalCommissions = 0;
    let fetchedConsiderations = [];
    let fetchedAdditionalCommissions = [];

    const params = {
      transaction: dealId,
    };

    const sumOtherCommissions = arr => arr.reduce((acc, cur) => (
      (cur.payment_group !== PAYMENT_GROUPS.CBRE_BROKER && cur.payment_group !== PAYMENT_GROUPS.FEE_SHARE)
        ? (acc + cur.gross_commission)
        : acc
    ), 0);

    // handle all updates (update, delete, create) for considerations and additionalCommissions
    // and fetch new list asynchronously
    // in order to update totals without stopping each other.
    const considerationUpdates = this.getConsiderationUpdates();
    const additionalCommissionUpdates = this.getAdditionalCommissionUpdates();

    Promise.all(considerationUpdates)
      .then(() => {
        if (considerationUpdates.length) {
          updateConsiderations(dealId);
          updateDealPipelineTimestamp();
        }
      })
      .then(() => {
        Promise.all(additionalCommissionUpdates)
          .then(() => {
            if (additionalCommissionUpdates.length) {
              updateAdditionalCommissions(dealId);
              updateDealPipelineTimestamp();
            }
          });
        return fetchConsiderations(dealId);
      })
      .then((considerations) => {
        fetchedConsiderations = considerations;
        return fetchAdditionalCommissions(dealId);
      })
      .then((additionalCommissions) => {
        fetchedAdditionalCommissions = additionalCommissions;
        totalCommissions = sumTotalCommissions(fetchedConsiderations, fetchedAdditionalCommissions);
        return fetchCommissions(params);
      })
      .then((commissions) => {
        const allCommissions = (commissions.payload);
        const outsideBrokerCommissions = sumOtherCommissions(allCommissions).toFixed(2);
        total = totalCommissions - outsideBrokerCommissions;
        updateDeal(dealId, { estimated_commission: total.toFixed(2), modified: new Date() });
        return initializeCommissionsTotal(total);
      })
      .catch(error => console.log('Error :', error));

    // reset temp considerations and additionalCommissions and close the modal;
    updateConsiderationsInModal([]);
    updateAdditionalCommissionsInModal([]);
    toggleModal();
  };

  handleCommissionsModalCancel = () => {
    // reset temp considerations and additionalCommissions and close the modal;
    this.props.updateConsiderationsInModal([]);
    this.props.updateAdditionalCommissionsInModal([]);
    this.props.toggleModal();
  };

  render() {
    const {
      dealType,
      dealId,
      toggleModal,
      updateDealPipelineTimestamp,
    } = this.props;

    const renderConsiderations =
      (dealType === 'lease' || dealType === 'sale') &&
      <ConsiderationsContainer
        dealId={dealId}
        dealType={dealType}
        updateDealPipelineTimestamp={updateDealPipelineTimestamp}
      />;

    const renderAdditionalCommissions =
      (<AdditionalCommissionsContainer
        dealId={dealId}
        dealType={dealType}
      />);

    return (
      <Modal
        modalWidth={800}
        modalHeader="Commission Agreement"
        primaryButtonText="Save"
        handleModalSubmit={this.handleCommissionsModalSubmit}
        secondaryButtonText="Cancel"
        handleSecondaryButton={this.handleCommissionsModalCancel}
        handleModalToggle={toggleModal}
        showModal
      >
        {renderConsiderations}
        {renderAdditionalCommissions}
      </Modal>);
  }
}

CommissionsModal.propTypes = {
  dealId: PropTypes.number.isRequired,
  dealType: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  // states
  tempConsiderations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tempAdditionalCommissions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // actions
  updateConsiderations: PropTypes.func.isRequired,
  updateAdditionalCommissions: PropTypes.func.isRequired,
  updateConsiderationsInModal: PropTypes.func.isRequired,
  updateAdditionalCommissionsInModal: PropTypes.func.isRequired,
  updateDealPipelineTimestamp: PropTypes.func.isRequired,
  initializeCommissionsTotal: PropTypes.func.isRequired,
  updateDeal: PropTypes.func.isRequired,
  fetchConsiderations: PropTypes.func.isRequired,
  fetchAdditionalCommissions: PropTypes.func.isRequired,
  fetchCommissions: PropTypes.func.isRequired,
};

export default CommissionsModal;
