import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import uniqueId from 'lodash/uniqueId';

import considerationsService from '../../../services/voucher/considerations-service';

import ConsiderationItem from './consideration-item';
import AddItem from '../../../nucleus/add-item/add-item';
import Currency from '../../../nucleus/formats/currency';

class Considerations extends Component {
  componentDidMount() {
    this.fetchLeaseConsiderationTypeOptions();
    // This gets considerations state fetched from API
    // and replaces tempConsiderations with it
    // so that users can play with it without changing the original state.
    this.props.updateConsiderationsInModal(this.props.considerations);
  }

  getConsiderationTemplate = () => ({
    id: uniqueId(), // temp id
    transaction: this.props.dealId,
    consideration_type: this.props.dealType === 'lease' ? 'Monthly Rent' : 'Purchase Price',
    total_units: null,
    amount: null,
    consideration_amount: 0, // set default to 0 to supply base total to percent dollar component
    commission_amount: null,
    created: true, // temp field to notifiy modal to update
  });

  getTotalConsiderations = () => reduce(
    this.props.tempConsiderations,
    (acc, cur) => acc + ((!cur.deleted && cur.consideration_amount) || 0),
    0,
  );

  getTotalCommissionAmount = () => reduce(
    this.props.tempConsiderations,
    (acc, cur) => acc + ((!cur.deleted && cur.commission_amount) || 0),
    0,
  );

  getTotalTerm = () => {
    if (this.props.dealType === 'lease') {
      return considerationsService.getTotalTerm(this.props.tempConsiderations);
    }
    return null;
  }

  fetchLeaseConsiderationTypeOptions = () => {
    if (this.props.dealType === 'lease' && !this.props.leaseConsiderationTypeOptions.length) {
      this.props.fetchLeaseConsiderationTypeOptions();
    }
  }

  addConsideration = () => {
    const newItem = this.getConsiderationTemplate();
    const considerations = cloneDeep(this.props.tempConsiderations);
    considerations.push(newItem);
    this.props.updateConsiderationsInModal(considerations);
  }

  updateConsideration = (id, key, value) => {
    const considerations = cloneDeep(this.props.tempConsiderations);
    const consideration = find(considerations, { id });
    consideration[key] = value;
    consideration.updated = true; // temp field to notifiy modal to update
    this.props.updateConsiderationsInModal(considerations);
  }

  deleteConsideration = (id) => {
    const considerations = cloneDeep(this.props.tempConsiderations);
    const consideration = find(considerations, { id });
    consideration.deleted = true; // temp field to notifiy modal to update
    this.props.updateConsiderationsInModal(considerations);
  }

  // for items to calculate consideration_amount on update
  calculateConsideration = (id) => {
    const considerations = cloneDeep(this.props.tempConsiderations);
    const consideration = find(considerations, { id });
    consideration.consideration_amount =
      consideration.total_units * consideration.amount;
    consideration.updated = true;
    this.props.updateConsiderationsInModal(considerations);
  }

  togglePercentDollarSign = () => {
    const {
      updateVoucher,
      updateDealPipelineTimestamp,
      voucher,
    } = this.props;
    updateVoucher(voucher.id, {
      considerations_is_percent: !voucher.considerations_is_percent,
    })
      .then(() => updateDealPipelineTimestamp());
  }

  updateCommissionRate = (id, percentValue, dollarValue) => {
    const considerations = cloneDeep(this.props.tempConsiderations);
    const consideration = find(considerations, { id });
    consideration.commission_percent = percentValue;
    consideration.commission_amount = dollarValue;
    if (!consideration.created) {
      consideration.updated = true; // temp field to notifiy modal to update
    }
    this.props.updateConsiderationsInModal(considerations);
  }

  render() {
    const {
      dealType,
      leaseConsiderationTypeOptions,
      tempConsiderations,
      voucher,
    } = this.props;
    const renderConsiderationItem = !!tempConsiderations.length &&
      tempConsiderations.map((consideration, index) =>
        !consideration.deleted && (<ConsiderationItem
          key={consideration.id || index}
          dealType={dealType}
          consideration={consideration}
          leaseConsiderationTypeOptions={leaseConsiderationTypeOptions}
          updateConsideration={this.updateConsideration}
          calculateConsideration={this.calculateConsideration}
          deleteConsideration={this.deleteConsideration}
          isConsiderationPercent={voucher.considerations_is_percent}
          togglePercentDollarSign={this.togglePercentDollarSign}
          updateCommissionRate={this.updateCommissionRate}
        />));

    const unitLabel = (dealType === 'lease') ? 'Period' : 'Quantity';
    const amountLabel = (dealType === 'lease') ? 'Rent($)' : 'Price($)';

    const renderTotalRow = (
      <div className="table-row table-footer">
        <div className="table-cell">Totals</div>
        <div className="table-cell">
          {this.getTotalTerm()}
        </div>
        <div className="table-cell" />
        <div className="table-cell">
          <Currency
            value={this.getTotalConsiderations()}
            empthState="--"
          />
        </div>
        <div className="table-cell" />
        <div className="table-cell">
          <Currency
            value={this.getTotalCommissionAmount()}
            empthState="--"
          />
        </div>
        <div className="table-cell" />
      </div>
    );

    return (
      <div className="financials-section-wrapper">
        <h3>Considerations Breakdown</h3>
        <div className="table considerations-table">
          <div className="table-header">
            <div className="table-cell">Consideration Type</div>
            <div className="table-cell">{unitLabel}</div>
            <div className="table-cell">{amountLabel}</div>
            <div className="table-cell">Consideration</div>
            <div className="table-cell">Commission Rate</div>
            <div className="table-cell">Commission</div>
            <div className="table-cell" />
          </div>
          {renderConsiderationItem}
          <div>
            <AddItem label="Add Consideration" onClick={this.addConsideration} />
          </div>
          {renderTotalRow}
        </div>
      </div>
    );
  }
}

Considerations.propTypes = {
  dealId: PropTypes.number.isRequired,
  dealType: PropTypes.string.isRequired,
  // states
  considerations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tempConsiderations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  leaseConsiderationTypeOptions: PropTypes.arrayOf(PropTypes.shape()),
  voucher: PropTypes.shape({
    id: PropTypes.number,
    considerations_is_percent: PropTypes.bool,
  }),
  // actions
  updateConsiderationsInModal: PropTypes.func.isRequired,
  fetchLeaseConsiderationTypeOptions: PropTypes.func.isRequired,
  updateVoucher: PropTypes.func.isRequired,
  updateDealPipelineTimestamp: PropTypes.func.isRequired,
};

Considerations.defaultProps = {
  leaseConsiderationTypeOptions: [],
  voucher: {
    considerations_is_percent: true,
  },
};

export default Considerations;

