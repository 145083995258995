import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../nucleus/select/select';
import Datepicker from '../../nucleus/datepicker/datepicker';

class VoucherSearchAdvancedControls extends React.Component {
  // Updates parent state with input changes
  handleInputChange = (event, searchKey) => {
    const { value } = event.target;
    this.props.updateAdvancedSearchParams(value, searchKey);
  }

  handleDateChange = (date, searchKey) => {
    this.props.updateAdvancedSearchParams(date, searchKey);
  }

  // Update parent state with dropdown selections
  handleDealTypeChange = (option) => {
    this.props.updateDealTypeSelection(option);
  }
  handlePropertyTypeChange = (option) => {
    this.props.updatePropertyTypeSelection(option);
  }

  render() {
    const {
      leadOffice,
      dealID,
      dealTypeSelection,
      propertyTypeSelection,
      minSqFt,
      maxSqFt,
      startDate,
      endDate,
      dealTypeOptions,
      propertyTypeOptions,
    } = this.props;

    return (
      <div className="advanced-search-controls">
        <div className="field-item">
          <label>Lead Office</label>
          <input type="text" value={leadOffice} onChange={event => this.handleInputChange(event, 'lead_office')} />
        </div>
        <div className="field-item">
          <label>Deal ID</label>
          <input type="text" value={dealID} onChange={event => this.handleInputChange(event, 'deal_id')} />
        </div>
        <div className="field-item">
          <label>Deal Type</label>
          <Select
            id="deal-types"
            className="select-component"
            defaultOption={dealTypeSelection}
            onChange={this.handleDealTypeChange}
            options={dealTypeOptions}
            onClear
          />
        </div>
        <div className="field-item">
          <label>Property Type</label>
          <Select
            id="property-types"
            className="select-component"
            defaultOption={propertyTypeSelection}
            onChange={this.handlePropertyTypeChange}
            options={propertyTypeOptions}
            onClear
          />
        </div>
        <label className="small-input">SqFt Range: Min &mdash; Max</label>
        <div className="field-group min-max-fields">
          <div className="field-item range min">
            <input type="number" placeholder="Min" value={minSqFt} onChange={event => this.handleInputChange(event, 'min_sqft')} />
          </div>
          <div className="field-item range">
            <input type="number" placeholder="Max" value={maxSqFt} onChange={event => this.handleInputChange(event, 'max_sqft')} />
          </div>
        </div>
        <label className="small-input">Close Date: Start &mdash; End</label>
        <div className="field-group min-max-fields">
          <div className="field-item range min">
            <Datepicker
              date={startDate}
              onDateChange={date => this.handleDateChange(date, 'start_date')}
              updateValidity={isValid => this.props.updateFieldValidity(isValid, 'start_date')}
              scrollOnFocus
            />
          </div>
          <div className="field-item range">
            <Datepicker
              date={endDate}
              onDateChange={date => this.handleDateChange(date, 'end_date')}
              updateValidity={isValid => this.props.updateFieldValidity(isValid, 'end_date')}
              scrollOnFocus
            />
          </div>
        </div>
      </div>
    );
  }
}

VoucherSearchAdvancedControls.propTypes = {
  leadOffice: PropTypes.string.isRequired,
  dealID: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  minSqFt: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  maxSqFt: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  dealTypeSelection: PropTypes.shape({
    display_name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  dealTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    display_name: PropTypes.string,
    value: PropTypes.string,
  })),
  propertyTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    display_name: PropTypes.string,
    value: PropTypes.string,
  })),
  propertyTypeSelection: PropTypes.shape({
    display_name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  updateAdvancedSearchParams: PropTypes.func.isRequired,
  updateDealTypeSelection: PropTypes.func.isRequired,
  updatePropertyTypeSelection: PropTypes.func.isRequired,
  updateFieldValidity: PropTypes.func.isRequired,
};

VoucherSearchAdvancedControls.defaultProps = {
  propertyTypeOptions: [],
  dealTypeOptions: [],
};

export default VoucherSearchAdvancedControls;
