import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Icon, IconButton } from '@cbrebuild/blocks';
import ConfirmationModal from '../../../nucleus/confirmation-modal/confirmation-modal';
import dealsService from '../../../services/deals-service';
import userEventService from '../../../services/user-event-service';

class VoucherFileListItem extends React.Component {
  // eslint-disable-next-line react/sort-comp
  container = React.createRef();
  state = {
    open: false,
    showModal: false,
  }

  closeConfirmationModal = () => {
    this.setState({ showModal: false });
  }

  handleDeleteFile = (id) => {
    const { deleteFile, file } = this.props;
    deleteFile(id);

    dealsService.updateDeal(file.deal, {
      modified: new Date(),
    });
  }

  trackDownloadFile = () => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent(
      {
        eventAction: 'file_downloaded',
        eventCategory: 'File Action',
      },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  }

  handleFileDownload = (id) => {
    const { fetchFilesDownloadLink } = this.props;
    fetchFilesDownloadLink(id, true);
    this.trackDownloadFile();
  }

  addToVoucher = () => {
    const {
      file,
      updateFile,
    } = this.props;
    const params = {
      ...file,
      is_lease_document: true,
    };

    updateFile(file.id, params);
    dealsService.updateDeal(file.deal, {
      modified: new Date(),
    });
  }

  removeFromVoucher = () => {
    const {
      file,
      updateFile,
    } = this.props;
    const params = {
      ...file,
      is_lease_document: false,
    };

    updateFile(file.id, params);
    dealsService.updateDeal(file.deal, {
      modified: new Date(),
    });
  }

  showConfirmationModal = () => {
    this.setState({ showModal: true });
    this.setState(state => ({
      open: !state.open,
    }));
  }
  toggleDropdown = () => {
    this.setState(state => ({
      open: !state.open,
    }));
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.container.current && !this.container.current.contains(event.target)) {
      this.setState({
        open: false,
      });
    }
  };

  render() {
    const {
      file,
      voucher,
      isAddButton,
      isDropDown,
    } = this.props;
    const {
      showModal,
    } = this.state;

    return (
      <React.Fragment>
        {(Object.keys(file).length !== 0) &&
          <li className="voucher-file-list-item" key={file.id}>
            <div className="file-icon-wrapper">
              <Icon iconName="document" size="x-large" />
              <div className={`doc-type-extension doc-type-${file.file_type}`} onClick={this.testClick}>{file.file_type}</div>
            </div>
            <div className="file-info">
              <a className="file-list-name">{file.title}</a>
              <span className="file-metadata">
                {file.created && moment(file.created).format('ll')}
              </span>
            </div>
            <div className="file-actions">
              {isAddButton ? <IconButton
                className={isAddButton ? 'add-voucher-icon' : 'download-voucher-icon'}
                iconName="plus-circle-outline"
                onClick={this.addToVoucher}
              /> : <IconButton
                className="download-voucher-icon"
                iconName="download"
                onClick={() => this.handleFileDownload(file.id)}
              />}
              {isDropDown &&
                <div className="dropdown-menu-voucher" ref={this.container}>
                  {isAddButton ? <span className="add-button" onClick={this.addToVoucher}>Add to Voucher</span> : <Icon
                    iconName="more-v"
                    onClick={this.toggleDropdown}
                    onBlur={this.toggleDropdown}
                  />}
                  {this.state.open && (
                    <div className="dropdown-list-voucher">
                      <ul>
                        <li onClick={this.removeFromVoucher}> Remove from {voucher.is_early_invoice ? 'Early Invoice' : 'Voucher'}</li>
                        <li onClick={this.showConfirmationModal}>Delete File</li>
                      </ul>
                    </div>
                  )}
                </div>
              }
            </div>
          </li>
        }
        {showModal &&
          <ConfirmationModal
            className="deal-file-delete-confirmation voucher-modal"
            onCloseModal={this.closeConfirmationModal}
            showModal={showModal}
          >
            <div className="header-modal">
              <h1 className="header-content conf-header">Confirmation</h1>
              <IconButton
                iconName="close-circle"
                className="icon-circle"
                variant="basic"
                onClick={this.closeConfirmationModal}
              />
            </div>
            <div className="conf-text">
              <p>This attachment will be deleted permanently. Are you sure?</p>
            </div>
            <footer className="actions">
              <Button
                className="cancel-btn"
                variant="secondary"
                onClick={this.closeConfirmationModal}
              >
                Cancel
              </Button>
              <Button className="upload-btn ok-btn" onClick={() => this.handleDeleteFile(file.id)}>
                Ok
              </Button>
            </footer>
          </ConfirmationModal>
        }
      </React.Fragment>
    );
  }
}

VoucherFileListItem.propTypes = {
  analyticProperties: PropTypes.shape({
    actionPrefix: PropTypes.string,
    categoryPrefix: PropTypes.string,
  }),
  voucher: PropTypes.shape({
    is_early_invoice: PropTypes.bool,
  }).isRequired,
  isAddButton: PropTypes.bool.isRequired,
  isDropDown: PropTypes.bool,
  deleteFile: PropTypes.func,
  fetchFilesDownloadLink: PropTypes.func.isRequired,
  file: PropTypes.shape({
    id: PropTypes.number,
    deal: PropTypes.number,
    document_name: PropTypes.string,
    title: PropTypes.string,
    file_type: PropTypes.string,
    file_size: PropTypes.number,
    created_by: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      avatar: PropTypes.string,
      email: PropTypes.shape({
        work: PropTypes.string,
      }),
    }),
    created: PropTypes.string,
    modified: PropTypes.string,
    can_edit: PropTypes.bool,
    privacy: PropTypes.string,
    is_lease_document: PropTypes.bool,
  }).isRequired,
  updateFile: PropTypes.func,
  userData: PropTypes.shape({
    id: PropTypes.number,
  }),
};

VoucherFileListItem.defaultProps = {
  analyticProperties: ({
    actionPrefix: '',
    categoryPrefix: '',
  }),
  deleteFile: () => {},
  isDropDown: true,
  updateFile: () => {},
  userData: {},
};

export default VoucherFileListItem;
