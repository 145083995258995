/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@cbrebuild/blocks';
import PageHeaderDefault from '../../nucleus/header/page-header-default';
import TopNavPublic from '../../components/navs/top-nav-public';

const AppstorePage = ({ setShowGlobalNavigation }) => {
  useEffect(() => {
    document.title = 'Deal IQ | Mobile';

    if (setShowGlobalNavigation) {
      setShowGlobalNavigation(false);
    }

    return () => {
      if (setShowGlobalNavigation) {
        setShowGlobalNavigation(true);
      }
    };
  }, []);

  const installApp = () => {
    // iOS download handler
    window.location.href = `itms-services://?action=download-manifest&url=https://${window.location.host}}/react-assets/manifest.plist`;
  };

  return (
    <React.Fragment>
      <TopNavPublic />
      <PageHeaderDefault
        title="Install Deal IQ Mobile for iOS"
      />
      <div className="page-container ">
        <div className="col-two-thirds card-default">
          <ol>
            <li>
              <Button onClick={installApp}>Tap to Install</Button>
            </li>
            <li>Confirm the installation dialog.</li>
            <li>Press your <span className="bold">Home Button</span> to see the installation progress. Note that the Deal IQ icon might appear on any of your
              home screens.
            </li>
            <li>Before using the app for the first time, you will have to follow these simple steps:
              <br /> Settings &gt; General &gt; Device Management &gt; CBRE Group
              Inc &gt; Trust
            </li>
            <li>Open the app and enter your login details to get started.</li>
          </ol>
        </div>
      </div>

    </React.Fragment>
  );
};

AppstorePage.propTypes = {
  setShowGlobalNavigation: PropTypes.func,
};

AppstorePage.defaultProps = {
  setShowGlobalNavigation: null,
};

export default AppstorePage;
