import React from 'react';
import PropTypes from 'prop-types';

const LoadMore = (props) => {
  const {
    isLoading,
    hasNextPage,
    handleLoadMore,
  } = props;

  const loading = hasNextPage > 0 && isLoading ? (
    <p className="nd-load-more-text">Loading...</p>) : '';

  const renderButton = hasNextPage > 0 && !isLoading ? (
    <div
      className="nd-load-more-button"
      onClick={handleLoadMore}
    >
      Load More
    </div>
  ) : loading;

  return (
    <React.Fragment>
      {renderButton}
    </React.Fragment>
  );
};

export default LoadMore;

LoadMore.propTypes = {
  isLoading: PropTypes.bool,
  hasNextPage: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  handleLoadMore: PropTypes.func.isRequired,
};

LoadMore.defaultProps = {
  isLoading: false,
  hasNextPage: null,
};
