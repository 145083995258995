import reduce from 'lodash/reduce';
import flatten from 'lodash/flatten';

const reduceCommissions = arr => reduce(arr, (acc, cur) => acc + cur.commission_amount, 0);

const sumTotalCommissions = (considerations, additionalCommissions) => {
  const allCommissions = flatten([considerations, additionalCommissions]);
  const sum = Number(reduceCommissions(allCommissions).toFixed(2));
  return sum;
};

export default sumTotalCommissions;
