import floor from 'lodash/floor';
import round from 'lodash/round';
/*
* baseTotal represents expected total commission (if commission allocation / installment)
* or individual consideration amount (if consideration)
*/
export default function calculatePercentValueFromTotal(baseTotal, isPercent, value, name = { percentage: 'percent', value: 'value' }) {
  let dollar;
  let percent;

  if (isPercent) {
    percent = value;

    if (percent === null) {
      dollar = null;
    } else if (baseTotal === 0) {
      dollar = 0;
    } else {
      dollar = round(((baseTotal * percent) / 100), 2);
    }
  } else {
    dollar = value;
    if (dollar === null) {
      percent = null;
    } else if (baseTotal === 0) {
      percent = 0;
    } else {
      percent = floor(((dollar / baseTotal) * 100), 8);
    }
  }
  return ({
    [name.percentage]: percent,
    [name.value]: dollar,
  });
}
