/* global CONFIG */
const { intercomID } = CONFIG;

let hasInitialized = false;

const init = (userData) => {
  if (hasInitialized) {
    return;
  }

  const intercomData = {
    app_id: intercomID,
    name: `${userData.first_name} ${userData.last_name}`,
    user_id: userData.employee_id,
    user_hash: userData.intercom_hash,
    widget: {
      activator: '#IntercomSupport',
    },
    hide_default_launcher: true,
  };

  if (userData.avatar) {
    intercomData.avatar = {
      type: 'avatar',
      image_url: userData.avatar,
    };
  }

  if (userData.work_email) {
    intercomData.email = userData.work_email;
  }

  if (userData.title) {
    userData.job_title = userData.title; // eslint-disable-line
  }

  if (userData.signed_broker_user_agreement ||
    userData.signed_manager_user_agreement) {
    window.Intercom('boot', intercomData);
    hasInitialized = true;
  }
};

export default {
  init,
};

