import { connect } from 'react-redux';
import Installments from './installments';

// actions
import {
  fetchInstallments,
  addInstallment,
  createInstallment,
  updateInstallment,
  deleteInstallment,
} from '../../../redux/actions/voucher/installments-actions';

import { updateVoucher } from '../../../redux/actions/voucher/voucher-actions';

/*
  baseTotal represents denominator for calculations in PercentDollarInput sub-component.
  commissionsTotal: expected gross commission
  deal.estimated_commission: estimated total commission (or expected, but that will be caught by commissionsTotal)
*/
const mapStateToProps = state => ({
  deal: state.deal.deal,
  installments: state.voucherInstallments,
  voucher: state.voucher.voucher,
  baseTotal: state.commissionsTotal || state.deal.deal.estimated_commission || 0,
});

const mapDispatchToProps = {
  fetchInstallments,
  addInstallment,
  createInstallment,
  updateInstallment,
  deleteInstallment,
  updateVoucher,
};

const InstallmentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Installments);

export default InstallmentsContainer;
