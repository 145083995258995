import React from 'react';
import PropTypes from 'prop-types';
import AutocompleteFlyout from './autocomplete-flyout';
import vouchersService from '../../services/voucher/vouchers-service';

class NaicsAutocomplete extends React.Component {
    getName = result => `${result.naics_description}`;
    renderListItem = result => <div>{`${result.naics_code}-${result.naics_description}`}</div>;
    render() {
      const {
        onChange,
        errorMessage,
        placeholder,
        initSearchTerm,
        onBlur,
      } = this.props;
      return (
        <AutocompleteFlyout
          initSearchTerm={initSearchTerm}
          fetchRequest={initSearchTerm.length ? ({ search }) => vouchersService.searchMtaNaicsChoicesByCode(search) : vouchersService.getMtaNaicsChoices}
          requestParams={{ page_size: 100 }}
          onChange={onChange}
          placeholder={placeholder}
          renderListItem={this.renderListItem}
          findSelectedResultAttribute={this.getName}
          errorMessage={errorMessage}
          clickReturnsAll
          onBlur={onBlur}
        />
      );
    }
}
export default NaicsAutocomplete;

NaicsAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  initSearchTerm: PropTypes.string,
  onBlur: PropTypes.func,
};
NaicsAutocomplete.defaultProps = {
  errorMessage: '',
  placeholder: '',
  initSearchTerm: '',
  onBlur: undefined,
};
