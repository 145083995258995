import React from 'react';
import Proptypes from 'prop-types';
import Toast from './toast';

const toaster = ({ toasts }) => (
  <div className="nd-toaster">
    {
      toasts
        .slice(0, 3)
        .map(t => <Toast key={t.id} message={t.message} />)
    }
  </div>
);

toaster.propTypes = {
  toasts: Proptypes.arrayOf(Proptypes.shape()).isRequired,
};

export default toaster;
