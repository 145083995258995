import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AutocompleteFlyout from './autocomplete-flyout';

const renderItem = getDisplayText => item => <div>{getDisplayText(item)}</div>;
const getResults = (fetchResults, searchableValues) => ({ search }) => {
  const filter = search.toLowerCase();
  return fetchResults()
    .then(res => (
      res.filter(result =>
        searchableValues.reduce((acc, attribute) =>
          acc || result[attribute].toLowerCase().includes(filter), false))))
    .catch(() => []);
};

export default function SearchableDropdown({
  errorMessage,
  fetchResults,
  findby,
  getDisplayText,
  onBlur,
  onSelect,
  placeholder,
  searchableValues,
  value,
}) {
  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    fetchResults()
      .then((res) => {
        const result = res.find(item => (findby ? item[findby] === value : getDisplayText(item) === value));
        if (result) {
          setSearching(false);
          setSearch(getDisplayText(result));
        } else if (value) {
          setSearching(false);
          setSearch(value);
        }
      });
  }, [fetchResults, findby, getDisplayText, value]);

  const onChange = (selection) => {
    if (typeof selection === 'string') {
      setSearch(selection);
      if (!searching) {
        setSearching(true);
        // update the parent to unselected if something was selected
        if (value) {
          onSelect();
        }
      }
    } else {
      setSearching(false);
      onSelect({
        ...selection,
        displayText: getDisplayText(selection),
      });
    }
  };

  return (
    <AutocompleteFlyout
      initSearchTerm={search}
      fetchRequest={getResults(fetchResults, searchableValues)}
      requestParams={{ page_size: 100 }}
      onChange={onChange}
      placeholder={placeholder}
      renderListItem={renderItem(getDisplayText)}
      findSelectedResultAttribute={getDisplayText}
      errorMessage={errorMessage}
      onBlur={onBlur}
      minSearchLength={0}
    />
  );
}

SearchableDropdown.propTypes = {
  errorMessage: PropTypes.string,
  fetchResults: PropTypes.func.isRequired,
  findby: PropTypes.string,
  getDisplayText: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  searchableValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string,
};
SearchableDropdown.defaultProps = {
  errorMessage: '',
  findby: undefined,
  placeholder: '',
  value: '',
  onBlur: undefined,
};
