import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon, IconButton } from '@cbrebuild/blocks';

import AddItem from '../../nucleus/add-item/add-item';
import AddTag from '../../nucleus/legacy/tag/add-tag';
import ContactModal from '../modals/contact-modal';
import DealLabel from '../deal-label/deal-label';
import VoucherLabel from '../voucher-label/voucher-label';
import voucherService from '../../services/voucher/vouchers-service';
import DealActionsDropdown from '../headers/deal-detail-header/deal-actions-dropdown';
import DealCardFinancialsLeft from './deal-card-financials-left';
import DealCardFinancialsRight from './deal-card-financials-right';
import DealCardTasks from './deal-card-tasks';
import DealCardNotePanel from './deal-card-note-panel';
import FilterTag from '../../nucleus/legacy/tag/filter-tag';
import userEventService from '../../services/user-event-service';
import getDealProperty from '../../utils/get-deal-property';
import dealsService from '../../services/deals-service';
import LoadingIndicator from '../../nucleus/loading-indicator/loading-indicator';
import StyledLabel from '../../nucleus/styled-label/styled-label';

class DealCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeQuickTab: 'note',
      showContactModal: false,
      isFavorite: props.isFavorite,
      voucherEmail:{},
    };
  }

  componentDidMount() {
    const { deal } = this.props;
    if (deal.voucher_submission_status && deal.voucher_submission_status.toLowerCase() !== 'voucher not submitted') {
      fetch(voucherService.fetchVoucherEmail(deal.id)
        .then(data => {
          this.setState({ voucherEmail: data });
        }))
        .catch(error => console.error('Error:', error));
    } 
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFavorite !== this.props.isFavorite) {
      this.setFavorite(this.props.isFavorite);
    }
  }

  setFavorite(isFavorite) {
    this.setState({ isFavorite });
  }

  handleRemoveTag = (tag) => {
    const {
      deal: {
        id,
        tags,
      },
      updateDeal,
    } = this.props;
    const filteredTags = tags.filter(t => t !== tag);
    updateDeal(id, { tags: filteredTags, modified: new Date() }, 'Tag removed');
    this.trackTagRemoval(id);
  };

  handleTagClick = (tag) => {
    const {
      deal: {
        id,
      },
      handleTagClick,
    } = this.props;

    handleTagClick(tag);
    this.trackTagClicked(id);
  };

  trackTagRemoval = (id) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent(
      { eventCategory: 'Tags Action', eventValue: id, eventAction: 'deal_tag_removed' },
      { actionPrefix, categoryPrefix },
    );
  };

  trackTagClicked = (id) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent(
      { eventCategory: 'Tags Action', eventValue: id, eventAction: 'deal_tag_clicked' },
      { actionPrefix, categoryPrefix },
    );
  };

  handleAddTag = (tag) => {
    const {
      deal: {
        id,
        tags,
      },
      updateDeal,
    } = this.props;
    const exists = tags.find(t => t === tag);
    if (!exists) {
      const newTag = tag.trim();
      tags.push(newTag);
      updateDeal(id, { tags, modified: new Date() }, 'Tag added');
      this.trackTagAddition(id);
      this.props.addDealTag(newTag);
    }
  };

  trackTagAddition = (id) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent(
      { eventAction: 'deal_tag_added', eventCategory: 'Tags Action', eventValue: id },
      { actionPrefix, categoryPrefix },
    );
  };

  handleConversionPotentialChange = (value) => {
    const {
      deal: {
        id,
      },
      updateDeal,
    } = this.props;
    updateDeal(id, { conversion_potential: value, modified: new Date() }, 'Deal updated');
    this.trackConversionPotentialChange(id);
  };

  trackConversionPotentialChange = (id) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent(
      { eventAction: 'deal_conversion_potential_changed', eventCategory: 'Action', eventValue: id },
      { actionPrefix, categoryPrefix },
    );
  };

  handleContactModalSubmit = (contact, shouldAddToPersonal = false) => {
    const {
      deal: {
        id,
      },
      addPrimaryContact,
      updatePrimaryContact,
    } = this.props;
    if (contact.id) {
      updatePrimaryContact(id, contact.id, contact);
      this.setState({ showContactModal: false });
    } else {
      addPrimaryContact(id, { ...contact, create_personal_contact: shouldAddToPersonal, deal: id });
      this.setState({ showContactModal: false });
    }

    dealsService.updateDeal(id, { modified: new Date() });
  };

  handleDeleteContact = (contactId) => {
    const {
      deal: {
        id,
      },
      deletePrimaryContact,
    } = this.props;
    deletePrimaryContact(id, contactId);
    dealsService.updateDeal(id, {
      modififed: new Date(),
    });
    this.setState({ showContactModal: false });
  };

  closeContactModal = () => {
    this.setState({ showContactModal: false });
  };

  openContactModal = () => {
    this.setState({ showContactModal: true });
  };

  handleNoteChange = (note) => {
    const {
      deal: {
        id,
      },
      updateDealNote,
    } = this.props;
    updateDealNote(id, note);
  };

  updateDealFromDropDownActions = (id, args) => {
    const {
      archiveDeal,
      deleteDeal,
      restoreDeal,
      updateDeal,
    } = this.props;

    const params = Object.assign({
      modified: new Date(),
    }, args);

    if (params.is_deleted) {
      deleteDeal(id);
    } else if (params.is_archived === true) {
      archiveDeal(id);
    } else if (params.is_archived === false) {
      restoreDeal(id);
    } else if (params.user_wants_salesforce_export) {
      updateDeal(id, params, 'Deal updated');
    } else if (params.deal_type && params.rep_role) {
      updateDeal(id, params, 'Deal updated');
    }
  };

  showNoteTab = () => {
    if (this.state.activeQuickTab !== 'note') {
      this.setState({ activeQuickTab: 'note' });
    }
    this.trackQuickTabClick('note');
  };

  showTaskTab = () => {
    if (this.state.activeQuickTab !== 'tasks') {
      this.setState({ activeQuickTab: 'tasks' });
    }
    this.trackQuickTabClick('tasks');
  };

  trackQuickTabClick = (tab) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    const eventAction = `deal_card_${tab}_tab_clicked`;
    userEventService.trackEvent({
      eventAction, eventCategory: 'Action',
    }, { actionPrefix, categoryPrefix });
  };

  toggleFavorite = () => {
    const {
      deal,
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;

    const {
      isFavorite,
    } = this.state;

    const toggleIsFavorited = isFavorite ? dealsService.unfavoriteDeal : dealsService.favoriteDeal;

    toggleIsFavorited(deal.id)
      .then(() => {
        this.setState({ isFavorite: !isFavorite });
      })
      .catch((error) => {
        console.log('Error updating favorite preference: ', error);
      });

    const eventAction = isFavorite ? 'favorite_deal' : 'unfavorite_deal';
    userEventService.trackEvent(
      { eventAction, eventCategory: 'Action', eventValue: deal.id },
      { actionPrefix, categoryPrefix },
    );
  };

  showMultiple = () => {
    const {
      user,
      deal,
      deal: {
        permissions,
      },
      viewOnly,
    } = this.props;
    if (deal.linked_mta_transactions && deal.linked_mta_transactions.length > 1 && !viewOnly) return true;
    if (deal.linked_mta_transactions && deal.linked_mta_transactions.length === 1 && !viewOnly) return false;
    let permissionOn = 0;
    if (permissions.commissioned_users) {
      // eslint-disable-next-line no-unused-vars
      Object.entries(permissions.commissioned_users).forEach(([key, value]) => {
        if (value.includes(user.id)) permissionOn++;
      });
    }
    if (viewOnly && permissionOn > 1) return true;
    return false;
  }

  render() {
    const {
      activeQuickTab,
      showContactModal,
      isFavorite,
      voucherEmail,
    } = this.state;
    const {
      analyticProperties,
      deal,
      dealTags,
      dealUsers,
      dealTeams,
      toast,
      trackClicked,
      user,
      featureFlags,
      index,
      fetchDealNoteAndTask,
      viewOnly,
    } = this.props;
    const viewOnlyClass = viewOnly ? 'view-only' : '';
    const property = getDealProperty(deal);

    const { actionPrefix, categoryPrefix } = analyticProperties;
    const showStage = /property/i.test(actionPrefix || categoryPrefix);

    // get requirements or an object
    const requirements = deal.requirements || {};

    // conditionally renders salesforce icon
    const renderSyncToSalesforce = deal.user_wants_salesforce_export && <Icon iconName="cloud-sync" />;
    const renderTransAct = deal.created_by_username === 'API_TransAct_Integration' && <Icon iconName="transact" />;

    // conditionally renders property
    const shouldRenderProperty = !(deal.deal_type === deal.rep_role && deal.deal_life_cycle_stage === 'closed');
    const renderProperty = shouldRenderProperty && (property.id
      ? (
        <div className="deal-card-property">
          <h3 className="deal-card-property-name">{property.name}</h3>
          <p className="deal-card-property-address">{property.building.address}</p>
          <p className="deal-card-property-address">{property.building.city}, {property.building.state_abbreviation} {property.building.postal_code}</p>
        </div>
      ) : (
        <div className="deal-card-property">
          {deal.deal_life_cycle_stage !== 'closed' &&
            <Link className="add-property" to={`/deals/detail/${deal.id}/properties`}>
              <AddItem label="Property" dataE2e={`add-property-button-${index}`} />
            </Link>
          }
        </div>
      ));

    // conditionally renders contact modal
    const renderContactModal = showContactModal && (
      <ContactModal
        analyticProperties={analyticProperties}
        contact={deal.primary_deal_contact}
        dealId={deal.id}
        isPrimaryOnly
        onCancel={this.closeContactModal}
        onDelete={this.handleDeleteContact}
        onSubmit={this.handleContactModalSubmit}
      />
    );

    // conditionally renders contact
    const renderContact = deal.primary_deal_contact
      ? (
        <div className="deal-card-contact">
          <h3 className="deal-card-contact-name">{deal.primary_deal_contact.given_name} {deal.primary_deal_contact.surname}</h3>
          <div className="email-phone">
            <span>{deal.primary_deal_contact.phone_numbers && deal.primary_deal_contact.phone_numbers[0]
              ? (
                <a className="primary-link" href={`tel:${deal.primary_deal_contact.phone_numbers[0].number}`}>
                  {deal.primary_deal_contact.phone_numbers[0].number}
                </a>
              ) : <AddItem label="Phone" onClick={this.openContactModal} />
            }
            </span>
            <span>{deal.primary_deal_contact.email_addresses && deal.primary_deal_contact.email_addresses[0]
              ? (
                <a className="primary-link" href={`mailto:${deal.primary_deal_contact.email_addresses[0].address}`}>
                  <Icon iconName="envelope" />
                </a>
              ) : <AddItem label="Email" onClick={this.openContactModal} />
            }
            </span>
          </div>
        </div>
      ) : (
        <AddItem label="Primary Contact" dataE2e={`add-primary-contact-button-${index}`} onClick={this.openContactModal} />
      );

    const renderNoteTab = () => {
      if (viewOnly) {
        return (
          <p className="no-access">
            You do not have access to notes or tasks for this deal.
          </p>);
      } else if (deal.last_modified_note === 'error') {
        return (
          <p className="nd-loading-indicator error">
            Failed to load
          </p>);
      } else if (deal.last_modified_note === undefined) return <LoadingIndicator />;
      return (<DealCardNotePanel
        analyticProperties={analyticProperties}
        dealId={deal.id}
        note={deal.last_modified_note}
        onChange={this.handleNoteChange}
        user={user}
      />);
    };

    const renderTasksTab = () => {
      if (deal.next_due_tasks === 'error') {
        return (
          <p className="nd-loading-indicator error">
            Failed to load
          </p>);
      } else if (deal.next_due_tasks === undefined) return <LoadingIndicator />;
      return (<DealCardTasks
        analyticProperties={analyticProperties}
        dealName={deal.name}
        dealId={deal.id}
        toast={toast}
        user={user}
        tasks={deal.next_due_tasks ? deal.next_due_tasks.results : []}
        taskCount={deal.next_due_tasks ? deal.next_due_tasks.count : 0}
        fetchDealNoteAndTask={fetchDealNoteAndTask}
      />);
    };

    // render quickTab
    // eslint-disable-next-line no-nested-ternary
    const renderQuickTab = this.state.activeQuickTab === 'note'
      ? renderNoteTab()
      : renderTasksTab();

    // renders tags
    const renderTags = deal.tags.map(tag => (
      <FilterTag
        isButton={false}
        key={tag}
        handleClearFilter={() => this.handleRemoveTag(tag)}
        label=""
        value={tag}
        onTagClick={this.handleTagClick}
      />
    ));

    return (
      <div className={`deal-card${deal.is_archived ? ' is-archived' : ''}`}>
        <div className="deal-card-header">
          <div className="deal-title">
            <IconButton
              className="h1"
              iconName={isFavorite ? 'star-filled' : 'star-outline'}
              onClick={this.toggleFavorite}
            />
            <Link
              className="primary-link"
              onClick={() => trackClicked(deal.id)}
              to={
                !viewOnly ?
                `/deals/detail/${deal.id}/overview` :
                  `/deals/detail/${deal.id}/commissions`
              }
            >
              {deal.name}
            </Link>
            { renderSyncToSalesforce }
            { renderTransAct }
          </div>
          <div className="deal-menu">
            <IconButton
              className={`${viewOnlyClass} ${!viewOnly && activeQuickTab === 'note' ? ' active' : ''}`}
              data-e2e="listview-notes-toggle"
              iconName="note"
              onClick={!viewOnly ? this.showNoteTab : () => {}}
            />
            <IconButton
              className={`${viewOnlyClass} ${!viewOnly && activeQuickTab === 'tasks' ? ' active' : ''}`}
              data-e2e="listview-tasks-toggle"
              iconName="clipboard"
              onClick={!viewOnly ? this.showTaskTab : () => {}}
            />
            <DealActionsDropdown
              analyticProperties={analyticProperties}
              deal={deal}
              dealUsers={dealUsers}
              dealTeams={dealTeams}
              isKebab
              hideToasts
              updateDeal={this.updateDealFromDropDownActions}
              viewOnly={viewOnly}
            />
          </div>
        </div>
        <div className="deal-card-labels">
          <DealLabel
            dealStage={deal.deal_life_cycle_stage}
            dealType={deal.deal_type || ''}
            repRole={deal.rep_role || ''}
            showStage={showStage}
          />
          <VoucherLabel
            deal={deal}
            voucherEmail={voucherEmail}
          />
          {viewOnly && <StyledLabel content="Commission View" variant="default" />}
          {deal.is_on_pipeline && <StyledLabel content="Added to pipeline" />}
        </div>
        <div className="deal-card-content">
          <div className="deal-card-left">
            {renderProperty}
            {!viewOnly && renderContact}
          </div>
          <div className="deal-card-middle">
            <DealCardFinancialsLeft
              client={deal.client}
              counterParty={deal.counter_party}
              currentLeaseExp={deal.date_lease_to}
              dealIds={deal.deal_ids}
              dealLifeCycleStage={deal.deal_life_cycle_stage}
              dealType={deal.deal_type}
              estimatedClose={deal.date_est_close}
              progress={deal.deal_progress}
              property={property}
              repRole={deal.rep_role}
              requirements={requirements}
            />
            <DealCardFinancialsRight
              id={deal.id}
              closeDate={deal.date_approved}
              conversionPotential={deal.conversion_potential}
              dateLeaseTo={deal.date_lease_to}
              dealLifeCycleStage={deal.deal_life_cycle_stage}
              dealType={deal.deal_type}
              estimatedCommission={deal.estimated_commission}
              finalSQFT={deal.sqft}
              grossCommission={deal.total_commission_mta}
              onConversionChange={this.handleConversionPotentialChange}
              property={property}
              repRole={deal.rep_role}
              requirements={requirements}
              salePrice={deal.total_consideration}
              featureFlags={featureFlags}
              multipleCommissions={this.showMultiple()}
            />
          </div>
          <div className="deal-card-right">
            {renderQuickTab}
          </div>
        </div>
        <div className="deal-card-footer">
          {!viewOnly && renderTags}
          {!viewOnly && <AddTag
            onSubmit={this.handleAddTag}
            suggestions={dealTags.filter(tag => !deal.tags.includes(tag))}
          />}
        </div>
        {renderContactModal}
      </div>
    );
  }
}

DealCard.propTypes = {
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }),
  addDealTag: PropTypes.func.isRequired,
  deal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    client: PropTypes.shape({}),
    conversion_potential: PropTypes.number,
    counter_party: PropTypes.shape({}),
    created_by_username: PropTypes.string,
    date_est_close: PropTypes.string,
    date_approved: PropTypes.string,
    date_lease_to: PropTypes.string,
    deal_ids: PropTypes.arrayOf(PropTypes.string),
    deal_progress: PropTypes.string,
    deal_life_cycle_stage: PropTypes.string.isRequired,
    deal_type: PropTypes.string,
    estimated_commission: PropTypes.number,
    is_archived: PropTypes.bool,
    is_favorite: PropTypes.bool,
    is_on_pipeline: PropTypes.bool,
    last_modified_note: PropTypes.shape({}),
    linked_mta_transactions: PropTypes.arrayOf(PropTypes.number),
    name: PropTypes.string,
    primary_occupied_property: PropTypes.shape({}),
    primary_prior_property: PropTypes.shape({}),
    rep_role: PropTypes.string,
    requirements: PropTypes.shape({}),
    sqft: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
    total_commission_mta: PropTypes.number,
    total_consideration: PropTypes.number,
    has_next_tasks: PropTypes.shape({}),
    next_due_tasks: PropTypes.shape({
      count: PropTypes.number,
      results: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    permissions: PropTypes.shape({
      commissioned_users: PropTypes.shape({}),
    }),
    primary_deal_contact: PropTypes.shape({
      email_addresses: PropTypes.arrayOf(PropTypes.shape({
        address: PropTypes.string,
      })),
      given_name: PropTypes.string,
      surname: PropTypes.string,
      phone_numbers: PropTypes.arrayOf(PropTypes.shape({
        number: PropTypes.string,
      })),
    }),
    user_wants_salesforce_export: PropTypes.bool,
  }).isRequired,
  dealUsers: PropTypes.arrayOf(PropTypes.shape({})),
  dealTeams: PropTypes.arrayOf(PropTypes.shape({})),
  dealTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  addPrimaryContact: PropTypes.func.isRequired,
  archiveDeal: PropTypes.func.isRequired,
  deleteDeal: PropTypes.func.isRequired,
  deletePrimaryContact: PropTypes.func.isRequired,
  restoreDeal: PropTypes.func.isRequired,
  toast: PropTypes.func.isRequired,
  trackClicked: PropTypes.func.isRequired,
  updateDeal: PropTypes.func.isRequired,
  updatePrimaryContact: PropTypes.func.isRequired,
  updateDealNote: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  handleTagClick: PropTypes.func.isRequired,
  featureFlags: PropTypes.shape({}),
  index: PropTypes.number.isRequired,
  fetchDealNoteAndTask: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  isFavorite: PropTypes.bool.isRequired,
};

DealCard.defaultProps = {
  analyticProperties: {
    actionPrefix: 'deal_details',
    categoryPrefix: 'Deal Detail ',
  },
  dealUsers: null,
  dealTeams: null,
  viewOnly: false,
  featureFlags: {},
};

export default DealCard;
