import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import BannerNotice from '../../../nucleus/notices/banner-notice';
import CreateDealModalContainer from '../../../redux/containers/create-deal-modal-container';

class LeaseExpirationBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewDealModal: false,
    };
  }

  toggleNewDealModal = () => {
    this.setState({ showNewDealModal: !this.state.showNewDealModal });
  }

  render() {
    const { deal, viewOnly } = this.props;
    const { showNewDealModal } = this.state;

    const dealExpiresIn = moment(deal.date_lease_to).diff(moment(), 'days');
    const showLeaseExpirationNotice =
      deal.deal_life_cycle_stage === 'closed' &&
      deal.deal_type === 'lease' &&
      dealExpiresIn <= 730;

    const formattedDateLeaseTo = moment(deal.date_lease_to).format('LL');

    const leaseExpirationNoticeMessage = (dealExpiresIn > 0) ?
      `This lease expires on ${formattedDateLeaseTo}.` :
      'This lease has expired.';
    const trackingEventProperties = {
      eventCategory: 'Deal Overview Action',
      eventLabel: `deal_followup_created_from_${this.props.deal.deal_life_cycle_stage}_deal_details`,
      eventAction: 'deal_followup_created',
    };

    const client = (deal.deal_type === 'lease' && deal.rep_role === 'landlord') ? deal.counter_party : deal.client;

    return (
      showLeaseExpirationNotice &&
      <React.Fragment>
        { viewOnly ?
          <BannerNotice
            mainMessage={leaseExpirationNoticeMessage}
            secondaryMessage="Create a new prospect to follow up with this client."
          /> :
          <BannerNotice
            mainMessage={leaseExpirationNoticeMessage}
            secondaryMessage="Create a new prospect to follow up with this client."
            controlButtonText="Follow up"
            onControlButtonClick={this.toggleNewDealModal}
          />
        }
        <CreateDealModalContainer
          clientCompany={client}
          closeModal={this.toggleNewDealModal}
          showModal={showNewDealModal}
          trackingEventProperties={trackingEventProperties}
        />
      </React.Fragment>
    );
  }
}

LeaseExpirationBanner.propTypes = {
  deal: PropTypes.shape().isRequired,
  viewOnly: PropTypes.bool,
};

LeaseExpirationBanner.defaultProps = {
  viewOnly: false,
};

export default LeaseExpirationBanner;
