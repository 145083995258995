import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Linkify from 'react-linkify';
import { Icon, IconButton, Textarea, RadioGroup, RadioButton } from '@cbrebuild/blocks';

import Avatar from '../../nucleus/avatar/avatar';
import AutoTaskContainer from '../auto-task/auto-task-container';
import useDealNoteState from './useDealNoteState';


const formatDate = (input) => {
  if (!input) return '';
  const dateInput = moment(input).startOf('day');
  const today = moment().startOf('day');
  const yesterday = moment().startOf('day').subtract(1, 'day');
  if (dateInput.isSame(today)) {
    return 'Today';
  }
  if (dateInput.isSame(yesterday)) {
    return 'Yesterday';
  }
  return dateInput.format('MMM DD, YYYY');
};

const formatTime = (input) => {
  if (!input) return '';
  const dateInput = moment(input);
  return dateInput.format('LT');
};

const NoteInformation = ({
  firstName, avatar, lastName, created, modified, privacy, hideTimeStamp,
}) => {
  const createdDate = formatDate(created);
  const modifiedDate = formatDate(modified);
  const createdTime = !hideTimeStamp ? ` at ${formatTime(created)}` : '';
  const modifiedTime = !hideTimeStamp ? ` at ${formatTime(modified)}` : '';
  return (
    <div className="note-information">
      <Avatar
        enableHover
        firstName={firstName}
        image={avatar}
        lastName={lastName}
        sizing="lg"
      />
      <div className="note-author-stats">
        <p className="note-author">{firstName} {lastName}</p>
        <p className="note-stats">
          {createdDate}
          {modifiedDate && modifiedDate !== createdDate
            ? ` (Modified ${modifiedDate}${modifiedTime})`
            : `${createdTime}`
          }
          {createdDate || modifiedDate ? ' |  ' : ''}
          <span className="capitalize">{privacy}</span>
        </p>
      </div>
    </div>
  );
};

NoteInformation.propTypes = {
  firstName: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  modified: PropTypes.string.isRequired,
  privacy: PropTypes.string.isRequired,
  hideTimeStamp: PropTypes.bool.isRequired,
};

NoteInformation.defaultProps = {
  avatar: null,
};

const ReadonlyBody = ({
  body, dealId, shouldTruncateNotes,
}) => {
  const { readOnlyBody, wasTruncated } = useMemo(() => {
    const truncate = shouldTruncateNotes && (body.length > 250 || body.split('\n').length > 10);
    return ({
      wasTruncated: truncate,
      readOnlyBody: !truncate ? body : `${body.slice(0, 250).split('\n').filter((line, index) => index < 10).join('\n')}...`,
    });
  }, [shouldTruncateNotes, body]);

  return (
    <div className="note-body">
      <pre>
        <Linkify componentDecorator={(href, text, key) => (
          <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        )}
        >{readOnlyBody}
        </Linkify>
      </pre>
      {wasTruncated && (
        <div className="primary-link">
          <Link className="primary-link" to={`/deals/detail/${dealId}/notes`}>
            See All <Icon iconName="chevron-right" />
          </Link>
        </div>
      )}
    </div>
  );
};

ReadonlyBody.propTypes = {
  body: PropTypes.string.isRequired,
  dealId: PropTypes.number.isRequired,
  shouldTruncateNotes: PropTypes.bool.isRequired,
};

export default function DealNote({
  note: {
    body = '',
    can_edit,
    created = '',
    created_by,
    id,
    modified = '',
    privacy = 'team',
  } = {},
  hideTimeStamp,
  shouldTruncateNotes,
  debounceTime = 400,
  disablePredictiveNotes,
  user,
  onCreate,
  onDelete,
  onEdit,
  onUpdate,
  dealId,
  isNew,
}) {
  const {
    first_name: firstName,
    avatar,
    last_name: lastName,
  } = created_by || user;

  const {
    onFocusChange, onEditToggle, onBodyChange, onPrivacyChange, onTaskDismiss, onDeleteNote,
    notePrivacy = 'team', noteBody = '', editing, followup, autoTaskDismissed, textareaRef, ref, onAutoTaskStateChange,
  } = useDealNoteState({
    dealId, id, isNew, body, privacy, disablePredictiveNotes, debounce: debounceTime, onCreate, onDelete, onEdit, onUpdate,
  });

  return (
    <div className="deal-note">
      <div className="note-header">
        <NoteInformation
          avatar={avatar}
          created={created}
          firstName={firstName}
          hideTimeStamp={hideTimeStamp}
          lastName={lastName}
          modified={modified}
          privacy={privacy}
        />
        {can_edit && !editing && (
          <IconButton
            iconName="edit"
            onClick={onEditToggle}
            variant="basic"
          />
        )}
      </div>
      {editing ? (
        <div ref={ref}>
          <div className="note-body note-editing" >
            <Textarea
              ref={textareaRef}
              autoFocus
              // key={id}
              rows={{ min: 2, max: 8 }}
              value={noteBody}
              onChange={onBodyChange}
              onFocus={onFocusChange}
              onBlur={onFocusChange}
              placeholder="Click to add note..."
            />
            {followup && !autoTaskDismissed &&
              <AutoTaskContainer
                user={user}
                noteBody={noteBody}
                dismissAutoTask={onTaskDismiss}
                onStateChange={onAutoTaskStateChange}
              />
            }
          </div>
          <div className="note-footer">
            <RadioGroup
              key={id}
              name="note-options"
              selectedValue={notePrivacy}
              onChange={onPrivacyChange}
            >
              <RadioButton
                value="team"
                onFocus={onFocusChange}
                onBlur={onFocusChange}
              >Team
              </RadioButton>
              <RadioButton
                value="private"
                onFocus={onFocusChange}
                onBlur={onFocusChange}
              >Private
              </RadioButton>
            </RadioGroup>
            <div className="right">
              {id && <IconButton
                className="secondary-icon-button"
                iconName="bin"
                onClick={onDeleteNote}
                variant="basic"
                onFocus={onFocusChange}
                onBlur={onFocusChange}
              />}
              <div className="save-state">
                {(
                  // eslint-disable-next-line no-nested-ternary
                  !noteBody ? '' : (body !== noteBody.trim() || privacy !== notePrivacy ? 'Saving' : 'Saved')
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (<ReadonlyBody dealId={dealId} body={body} shouldTruncateNotes={shouldTruncateNotes} />)}
    </div>
  );
}

DealNote.propTypes = {
  dealId: PropTypes.number.isRequired,
  debounceTime: PropTypes.number,
  disablePredictiveNotes: PropTypes.bool,
  hideTimeStamp: PropTypes.bool,
  isNew: PropTypes.bool,
  note: PropTypes.shape({
    id: PropTypes.number,
    can_edit: PropTypes.bool,
    created: PropTypes.string,
    created_by: PropTypes.shape({}),
    body: PropTypes.string,
    modified: PropTypes.string,
    privacy: PropTypes.string,
  }),
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  shouldTruncateNotes: PropTypes.bool,
  user: PropTypes.shape({}).isRequired,
};

DealNote.defaultProps = {
  debounceTime: 400,
  disablePredictiveNotes: false,
  hideTimeStamp: false,
  isNew: false,
  note: {
    id: undefined,
    can_edit: true,
    created: undefined,
    created_by: null,
    body: '',
    modified: undefined,
    privacy: 'team',
  },
  onEdit: () => { },
  shouldTruncateNotes: true,
};
