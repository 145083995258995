import React from 'react';
import { useSelector } from 'react-redux';
import useVoucherValidation from '../useVoucherValidation';
import BrokerCommissions from './BrokerCommissions';
import OfficeCommissions from './OfficeCommissions';
import PoolCommissions from './PoolCommissions';

const PAGE_NUMBER = 3.3;

const CommissionsTab = () => {
  const {
    dealId, deal_type, voucher, cbre_commission_is_percent, commissions, commissionsState,
  } = useSelector(state => ({
    dealId: state.deal.deal?.id,
    deal_type: state.deal.deal?.deal_type,
    voucher: state.voucher.voucher,
    cbre_commission_is_percent: state.voucher.voucher?.cbre_commission_is_percent,
    commissions: state.commissions.commissions.filter(({ cbre_broker_type }) => !!cbre_broker_type),
    commissionsState: state.commissions.state,
  }));
  const { showPageValidation } = useVoucherValidation(voucher.id, PAGE_NUMBER);

  return (
    <div className="commissions-tab horizontal-overflow">
      <h2>Commissions</h2>
      {!commissions.length && commissionsState === 'NONE' && (
        <p className="error-message">
          You must add at least one commission to this {voucher.is_early_invoice ? 'early invoice' : 'voucher'}
        </p>
      )}
      <BrokerCommissions
        dealId={dealId}
        deal_type={deal_type}
        voucherId={voucher.id}
        cbre_commission_is_percent={cbre_commission_is_percent}
        showValidation={showPageValidation}
        shouldAddAsLeadBroker={!commissions.length}
      />
      <OfficeCommissions
        dealId={dealId}
        voucherId={voucher.id}
        cbre_commission_is_percent={cbre_commission_is_percent}
        showValidation={showPageValidation}
        shouldAddAsLeadBroker={!commissions.length}
      />
      <PoolCommissions
        dealId={dealId}
        voucherId={voucher.id}
        cbre_commission_is_percent={cbre_commission_is_percent}
        showValidation={showPageValidation}
        shouldAddAsLeadBroker={!commissions.length}
      />
    </div>
  );
};


export default CommissionsTab;
