import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';

const TeamAvatar = (props) => {
  const {
    teamName,
    teamColor,
    isCurrent,
    type,
  } = props;

  const nameSplit = teamName ? teamName.trim().replace(/\s\s+/g, ' ').split(' ') : undefined;

  let initials;
  if (nameSplit) {
    initials = nameSplit.length > 1 ?
      nameSplit[0][0] + nameSplit[1][0] :
      teamName.substring(0, 2);
  }

  return (
    <React.Fragment>
      <span className={`color-icon ${teamColor} ${type}`}>
        {initials && initials.toUpperCase()}
        {isCurrent && <Icon iconName="checkmark" />}
      </span>
    </React.Fragment>
  );
};


TeamAvatar.propTypes = {
  teamName: PropTypes.string,
  teamColor: PropTypes.string,
  isCurrent: PropTypes.bool,
  type: PropTypes.oneOf(['card', 'expand', 'collapse']),
};

TeamAvatar.defaultProps = {
  teamName: '',
  teamColor: 'blue',
  isCurrent: false,
  type: 'card',
};

export default TeamAvatar;
