import React from 'react';
import PropTypes from 'prop-types';
import VoucherFileUpload from './file-upload-button';
import VoucherFileListItem from './voucher-file-list-item';
import LoadMore from '../../../nucleus/load-more/load-more';

class VoucherFiles extends React.Component {
  state = {
    queryParams: {
      ordering: '-modified',
      page: 1,
      page_size: 25,
    },
    isAddButton: true,
  }

  componentDidMount() {
    const { queryParams } = this.state;
    const { dealId, fetchFiles } = this.props;
    fetchFiles({ ...queryParams, deal: dealId });
  }

  handleLoadMore = () => {
    const { queryParams } = this.state;
    const { dealId, fetchFiles } = this.props;
    this.setState({
      queryParams: {
        ...queryParams,
        page: queryParams.page += 1,
      },
    }, () => fetchFiles({ ...queryParams, deal: dealId }));
  }

  render() {
    const {
      dealId,
      deleteFile,
      fetchFilesDownloadLink,
      files,
      isLoadingFiles,
      next,
      updateFile,
      uploadFile,
      userData,
      voucher,
    } = this.props;

    return (
      <div>
        <div className="files-tab detail-panels-layout">
          <ul className="download-list">
            {files &&
              files.map(file => (
                file.is_lease_document &&
                <VoucherFileListItem
                  deleteFile={deleteFile}
                  fetchFilesDownloadLink={fetchFilesDownloadLink}
                  file={file}
                  key={file.id}
                  updateFile={updateFile}
                  userData={userData}
                  voucher={voucher}
                  isAddButton={false}
                />
              ))
            }
          </ul>
          {files.length >= 25 &&
          <LoadMore
            isLoading={isLoadingFiles}
            handleLoadMore={this.handleLoadMore}
            hasNextPage={next}
          />
          }
        </div>
        <div className="col-two-thirds">
          <VoucherFileUpload
            dealId={dealId}
            deleteFile={deleteFile}
            fetchFilesDownloadLink={fetchFilesDownloadLink}
            isLoadingFiles={isLoadingFiles}
            uploadFile={uploadFile}
          />
          {files && files.filter(f => f.is_lease_document === false).length > 0
            && <legend className="begin-voucher">Add Files from Deal (Not sent along with Voucher)</legend>}
          <ul className="download-list">
            {files.map(file => (
            file.is_lease_document === false &&
            <VoucherFileListItem
              deleteFile={deleteFile}
              fetchFilesDownloadLink={fetchFilesDownloadLink}
              file={file}
              key={file.id}
              updateFile={updateFile}
              userData={userData}
              voucher={voucher}
              isAddButton={this.state.isAddButton}
            />
              ))
            }
          </ul>
        </div>
      </div>
    );
  }
}

VoucherFiles.propTypes = {
  dealId: PropTypes.number.isRequired,
  deleteFile: PropTypes.func.isRequired,
  fetchFiles: PropTypes.func.isRequired,
  fetchFilesDownloadLink: PropTypes.func.isRequired,
  voucher: PropTypes.shape({ subProp: PropTypes.string }).isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    deal: PropTypes.number,
    document_name: PropTypes.string,
    title: PropTypes.string,
    file_type: PropTypes.string,
    file_size: PropTypes.number,
    created_by: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      avatar: PropTypes.string,
      email: PropTypes.shape({
        work: PropTypes.string,
      }),
    }),
    created: PropTypes.string,
    modified: PropTypes.string,
    can_edit: PropTypes.bool,
    privacy: PropTypes.string,
    is_lease_document: PropTypes.bool,
  })).isRequired,
  isLoadingFiles: PropTypes.bool,
  next: PropTypes.number,
  updateFile: PropTypes.func,
  uploadFile: PropTypes.func.isRequired,
  userData: PropTypes.shape({}).isRequired,
};

VoucherFiles.defaultProps = {
  isLoadingFiles: false,
  next: null,
  updateFile: () => {},
};

export default VoucherFiles;
