import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Checkbox, Icon, IconButton } from '@cbrebuild/blocks';

import Avatar from '../../nucleus/avatar/avatar';
import RowItem from '../../nucleus/row-item/row-item';

const TaskListItem = (props) => {
  const {
    dueDate,
    onEditTask,
    task,
    deal,
    onCompleteTask,
    showDealLink,
    showEditIcon,
  } = props;

  const {
    avatar,
    first_name: firstName,
    last_name: lastName,
  } = task.assignees[0] || {};

  const highPriority = task.is_priority === true ?
    <Icon iconName="warning" /> : '';

  return (
    <RowItem additionalClassName="task-list-item tall" key={task.id}>
      <div className="row-start">
        <Checkbox
          checked={task.is_complete}
          onChange={() => onCompleteTask(task.id)}
          value={task.id}
        />
        <div className="truncate-section">
          <div className="title">
            {highPriority}
            <h3>{task.title}</h3>
          </div>
          <p className="description">{task.document_body}</p>
          {showDealLink && (
            <div className="deal-name">
              <Link to={`/deals/detail/${deal}/overview`}>{task.deal_name}</Link>
            </div>
          )}
        </div>
      </div>
      <div className="row-end">
        <p className="due-date">{dueDate}</p>
        <Avatar
          image={avatar}
          firstName={firstName}
          lastName={lastName}
          enableHover
        />
        {showEditIcon && (
          <IconButton iconName="edit" onClick={() => onEditTask(task)} />
        )}
      </div>
    </RowItem>
  );
};

TaskListItem.propTypes = {
  dueDate: PropTypes.string,
  onCompleteTask: PropTypes.func.isRequired,
  onEditTask: PropTypes.func.isRequired,
  task: PropTypes.shape({
    assignees: PropTypes.arrayOf(PropTypes.shape({})),
    deal_name: PropTypes.string,
    document_body: PropTypes.string,
    id: PropTypes.number,
    is_complete: PropTypes.bool,
    is_priority: PropTypes.bool,
    title: PropTypes.string,
  }).isRequired,
  deal: PropTypes.number,
  showDealLink: PropTypes.bool,
  showEditIcon: PropTypes.bool,
};

TaskListItem.defaultProps = {
  dueDate: 'Missing Due Date',
  deal: null,
  showDealLink: false,
  showEditIcon: true,
};

export default TaskListItem;
