import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { _ } from 'core-js';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@cbrebuild/blocks';
import { Link, useHistory } from 'react-router-dom';
import VOUCHER_ROUTES from '../../../page-templates/voucher/voucher-subroutes';
import RequiredLabeledItem from './RequiredLabeledItem';
import { fetchDealContacts } from '../../../redux/actions/deal-contacts-actions';
import { validateAddress } from '../voucher-validation';
import companiesService from '../../../services/companies-service';

const CompanyContactReview = ({ partyType }) => {
  const {
    commercialProperties, deal, dealContacts, voucher,
  } = useSelector(state => ({
    commercialProperties: state.voucher.commercialProperties,
    deal: state.deal.deal,
    dealContacts: state.dealContacts.contacts,
    voucher: state.voucher.voucher,
  }));
  const [company, setCompany] = useState();
  const [companyIsLoaded, setCompanyIsLoaded] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDealContacts({ deal: deal.id }));
  }, [deal.id, dispatch, partyType]);

  const dealType = deal.deal_type;
  const isMiscDeal = dealType === 'consulting' || dealType === 'other';
  const isRepped = deal.rep_role === partyType || deal.is_dual_rep_deal || isMiscDeal;
  const isClient = deal.rep_role === partyType || isMiscDeal;
  const isPartyType = args => !!_.find(args, arg => arg === partyType);
  const REP_ROLE_MAP = {
    landlord: {
      landlord: 'client',
      tenant: 'counter_party',
    },
    tenant: {
      landlord: 'counter_party',
      tenant: 'client',
    },
    buyer: {
      buyer: 'client',
      seller: 'counter_party',
    },
    seller: {
      buyer: 'counter_party',
      seller: 'client',
    },
  };
  // @param {string} the section, landlord or tenant
  const getRoleMapKey = (section) => {
    const map = REP_ROLE_MAP[deal.rep_role];
    return map[section];
  };
    // Must get company information using companies api, not from the deal
  const companyFromDeal = isMiscDeal ? deal.client : deal[getRoleMapKey(partyType)];
  useEffect(() => {
    if (companyFromDeal) {
      companiesService.getCompany(companyFromDeal.id)
        .then(res => setCompany(res))
        .then(() => setCompanyIsLoaded(true));
    }
  }, [companyFromDeal]);

  const updatedDealContact = dealContacts.length && (dealContacts.filter(con => con.contact_type === partyType))[0];
  const contact = updatedDealContact && {
    ...updatedDealContact,
    fullName: updatedDealContact?.given_name?.concat(' ', updatedDealContact.surname),
  };
  let tenantPriorLocation;
  if (isPartyType(['tenant'])) {
    const tenantPriorLocations = commercialProperties.filter(prop => prop.location_type_new === false);
    if (tenantPriorLocations.length) {
      tenantPriorLocation = { ...tenantPriorLocations[0] };
    } else {
      tenantPriorLocation = null;
    }
  }

  const strings = {
    title: '',
    subtitle: '',
    url: '',
  };
  switch (partyType) {
    case 'tenant':
      strings.title = 'Tenant Information';
      strings.subtitle = 'Tenant';
      strings.url = VOUCHER_ROUTES[1].routes[1].path;
      break;
    case 'landlord':
      strings.title = 'Landlord Information';
      strings.subtitle = 'Landlord';
      strings.url = VOUCHER_ROUTES[1].routes[2].path;
      break;
    case 'seller':
      strings.title = 'Seller Information';
      strings.subtitle = 'Seller';
      strings.url = VOUCHER_ROUTES[1].routes[6].path;
      break;
    case 'buyer':
      strings.title = 'Buyer Information';
      strings.subtitle = 'Buyer';
      strings.url = VOUCHER_ROUTES[1].routes[5].path;
      break;
    case 'consulting':
    case 'other':
      strings.title = 'Client Information';
      strings.url = VOUCHER_ROUTES[1].routes[8].path;
      break;
    default:
      break;
  }
  strings.url = strings.url.replace(':id', voucher.deal);

  return (
    <div className="voucher-review-group company-contact-review">
      <div className="navigation">
        <Link className="h2 link" to={strings.url}>{strings.title}</Link>
        <IconButton iconName="edit" onClick={() => { history.push(strings.url); }} />
      </div>
      <div className="content">
        <div className="two-cols">
          <div className="col-left">
            <h3>{strings.subtitle}</h3>
            {company &&
              <>
                <RequiredLabeledItem label="Company Name" item={company?.name} isReview={companyIsLoaded} />
                {isPartyType(['tenant', 'buyer']) &&
                  <RequiredLabeledItem
                    label="Business Type / Use ID"
                    item={company?.voucher_naics_category}
                    notRequired={!isPartyType(['tenant', 'buyer']) && voucher.is_early_invoice}
                    isReview={companyIsLoaded}
                  />
                }
                <RequiredLabeledItem label="Address" format="address" item={company} isReview={companyIsLoaded} />
                {isPartyType(['buyer']) && (
                  <>
                    <RequiredLabeledItem label="Purchaser Type" item={company?.voucher_purchaser_type} notRequired={voucher.is_early_invoice} isReview />
                    <RequiredLabeledItem
                      label="Investor Type"
                      item={company?.voucher_investor_type}
                      notRequired={company && (company.voucher_purchaser_type !== '01-Investor')}
                      isReview
                    />
                  </>
                )}
                <RequiredLabeledItem label="GWS Client" item={company?.gws_client} notRequired isReview />
                <RequiredLabeledItem label="Parent Company" item={company?.parent_company} notRequired isReview />
                {isPartyType(['tenant']) &&
                  <RequiredLabeledItem label="Tenant Credit Rating" item={company?.credit_rating} notRequired isReview />
                }
                {!isMiscDeal &&
                  <RequiredLabeledItem label="Is Repped?" item={isRepped} format="bool" notRequired isReview />
                }
                {isPartyType(['tenant']) &&
                  <RequiredLabeledItem label="Tenant Relocating" item={!!tenantPriorLocation} format="bool" notRequired isReview />
                }
              </>}
          </div>
          <div className="col-right">
            <h3>Point of Contact</h3>
            {contact &&
              <>
                <RequiredLabeledItem label="Name" item={(contact && contact.fullName) || null} notRequired={!isClient} isReview />
                <RequiredLabeledItem label="Phone #" item={(contact && contact.phone_numbers[0]?.number) || null} notRequired={!isClient} isReview />
                <RequiredLabeledItem label="Email Address" item={(contact && contact.email_addresses[0]?.address) || null} notRequired={!isClient} isReview />
                <RequiredLabeledItem label="Job Title" item={(contact && contact.job_title) || null} notRequired isReview />
              </>
            }
          </div>
        </div>
      </div>
      {isPartyType(['tenant']) && tenantPriorLocation &&
        <div className="content">
          <h3>Tenant Prior Address</h3>
          <RequiredLabeledItem
            label="Prior Address"
            item={validateAddress(tenantPriorLocation, true) && tenantPriorLocation}
            format="address"
            notRequired={voucher.is_early_invoice}
            isReview
          />
          <RequiredLabeledItem
            label="Prior Rent Area (SqFt)"
            item={tenantPriorLocation.spaces[0]?.space_total_square_feet}
            format="number"
            notRequired
            isReview
          />
          <RequiredLabeledItem label="Percentage Lease" item={tenantPriorLocation.percentage_rent} format="bool" notRequired isReview />
        </div>
      }
    </div>
  );
};

CompanyContactReview.propTypes = {
  partyType: PropTypes.oneOf(['tenant', 'landlord', 'seller', 'buyer', 'consulting', 'other']).isRequired,
};

export default CompanyContactReview;
