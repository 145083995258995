import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, RadioButton, Icon, Tooltip } from '@cbrebuild/blocks';
import Datepicker from '../../../nucleus/datepicker/datepicker';
import useVoucherValidation from '../useVoucherValidation';
import NumberInput from '../../../nucleus/inputs/number-input';
import AddressForm from '../AddressForm';
import Select from '../../../nucleus/select/select';
import useCommercialPropertyState from '../../../hooks/useCommercialPropertyState';
import PropertyAutocomplete from '../../autocomplete/property-autocomplete';
import vouchersService from '../../../services/voucher/vouchers-service';

const LeaseInformation = ({
  deal,
  voucher,
  updateDeal,
  updateVoucher,
}) => {
  const [leaseStartDate, setLeaseStartDate] = useState('');
  const [leaseEndDate, setLeaseEndDate] = useState('');
  const [leaseExecutionDate, setLeaseExecutionDate] = useState('');
  const [leaseOccupancyDate, setLeaseOccupancyDate] = useState('');
  const [agreementType, setAgreementType] = useState('');
  const [transactionType, setTransactionType] = useState('');
  const [agreementErrorDrpDwn, setAgreementErrorDrpDwn] = useState(false);
  const [linkedProperties, setLinkedProperties] = useState({});
  const [leaseErrorDrpDwn, setLeaseErrorDrpDwn] = useState(false);
  const [showMore, setShowMore] = useState({});
  const [, setAgileDeal] = useState('');
  const [, setLeaseDeal] = useState('');
  const [costPerSeat, setCostPerSeat] = useState('');
  const [propertyOptions, setPropertyOptions] = useState([{ display_name: 'Select', value: null }]);
  const [noOfSeats, setNoOfSeats] = useState('');
  const {
    properties, dealProperties, onPropertyChange, onPropertyBlur, onSpaceBlur, onSpaceChange, onCurrencyBlur, onDealPropertySelect, onDealPropertyAdd,
  } = useCommercialPropertyState({ dealId: deal.id, createIfEmpty: true });
  const PAGE_NUMBER = 2.1;

  // uses the useValidation hook to see if this page should show all validation
  const { showPageValidation } = useVoucherValidation(voucher.id, PAGE_NUMBER);

  const agreementTypeData = [
    { value: '', display_name: 'Select' },
    { value: 'Full Service Gross', display_name: 'Full Service Gross' },
    { value: 'Gross', display_name: 'Gross' },
    { value: 'Gross W / Tax Protection', display_name: 'Gross W / Tax Protection' },
    { value: 'Modified Gross', display_name: 'Modified Gross' },
    { value: 'Net', display_name: 'Net' },
    { value: 'Net / Net', display_name: 'Net / Net' },
    { value: 'Net / Net / Net', display_name: 'Net / Net / Net' },
    { value: 'Other', display_name: 'Other' },
  ];

  const spaceTypeData = [
    { value: '', display_name: 'Select' },
    { value: 'Build to Suit', display_name: 'Build to Suit' },
    { value: 'New/Existing', display_name: 'New/Existing' },
    { value: 'None Selected', display_name: 'None Selected' },
    { value: 'Previously Occupied', display_name: 'Previously Occupied' },
  ];

  const transactionTypeData = [
    { value: '', display_name: 'Select' },
    { value: 'Expansion', display_name: 'Expansion' },
    { value: 'Month to Month', display_name: 'Month to Month' },
    { value: 'New', display_name: 'New' },
    { value: 'Option', display_name: 'Option' },
    { value: 'Renewal', display_name: 'Renewal' },
  ];

  const buildingClassData = [
    { value: 'A', display_name: 'A' },
    { value: 'B', display_name: 'B' },
    { value: 'C', display_name: 'C' },
    { value: 'D', display_name: 'D' },
  ];

  const onRemapPropertyEvent = (e, callback) => {
    const { target: { type, name, value } } = e;
    if (name === 'postal') {
      callback({
        ...e,
        target: {
          ...e.target, name: 'postal_code', type, value,
        },
      });
    } else if (name === 'territory') {
      callback({
        ...e,
        target: {
          ...e.target, name: 'state_abbreviation', type, value,
        },
      });
    } else {
      callback(e);
    }
  };

  const handleLinkedProperty = (cpId, dp) => {
    onDealPropertySelect(cpId, dp);
    setLinkedProperties(current => ({
      ...current, [cpId]: dp.id,
    }));
  };

  const handleDateChange = (date, propName) => {
    const dateVal = date !== '' ? date : null;
    const patch = {
      [propName]: dateVal,
    };
    if (propName === 'lease_expiration_date') {
      updateVoucher(voucher.id, patch);
    } else {
      updateDeal(deal.id, patch);
    }
    switch (propName) {
      case 'date_lease_from':
        setLeaseStartDate(dateVal);
        break;
      case 'lease_expiration_date':
        setLeaseEndDate(dateVal);
        break;
      case 'date_lease_execution':
        setLeaseExecutionDate(dateVal);
        break;
      case 'date_lease_occupancy':
        setLeaseOccupancyDate(dateVal);
        break;
      default:
        break;
    }
  };

  const handleAgreementChange = (event) => {
    updateDeal(deal.id, { lease_agreement_type: event.value });
    setAgreementType(event.value);
  };

  const handleLeaseIncomeTypeChange = (event) => {
    updateDeal(deal.id, { lease_income_type: event.value });
    setTransactionType(event.value);
  };

  const handleDealChange = (event) => {
    const radioValue = event.target.value;
    setAgileDeal(radioValue);
    updateDeal(deal.id, { is_agile_deal: radioValue });
  };

  const handleLeaseChange = (event) => {
    const radioValue = event.target.value;
    setLeaseDeal(radioValue);
    updateDeal(deal.id, { is_sublease: radioValue });
  };

  const handleSeatChange = (event) => {
    updateDeal(deal.id, { cost_per_seat: event.target.value });
    setCostPerSeat(event.target.value);
  };

  const handleNumseatChange = (event) => {
    updateDeal(deal.id, { number_of_seats: event.target.value });
    setNoOfSeats(event.target.value);
  };

  const handleSeatChangeBlur = (event) => {
    const enteredVal = event.target.value;
    const re = /^[0-9\b]+$/;
    if (enteredVal !== '' || re.test(enteredVal)) {
      let num = Number(enteredVal);
      // If there is no decimal, or the decimal is less than 2 digits, toFixed
      if (String(num).split('.').length < 2 || String(num).split('.')[1].length <= 2) {
        num = num.toFixed(2);
      }
      setCostPerSeat(num);
    }
  };

  const handleShowMore = (cpId) => {
    setShowMore(current => ({
      ...current, [cpId]: !current[cpId],
    }));
  };

  const addProperty = (id, property) => {
    onDealPropertyAdd(id, property, property.name);
  };

  useEffect(() => {
    if (deal.lease_agreement_type == null || deal.lease_agreement_type === 'Select' || deal.lease_agreement_type === '') {
      setAgreementErrorDrpDwn(true);
    } else {
      setAgreementErrorDrpDwn(false);
    }
    if (deal.lease_income_type == null || deal.lease_income_type === 'Select' || deal.lease_income_type === '') {
      setLeaseErrorDrpDwn(true);
    } else {
      setLeaseErrorDrpDwn(false);
    }
    vouchersService.getPropertyOptions().then((res) => {
      if (res.actions.POST.voucher_property_type_category.choices.length > 0 && propertyOptions.length === 1) {
        setPropertyOptions(propertyOptions.concat(res.actions.POST.voucher_property_type_category.choices));
      }
    });
    if (deal) {
      setLeaseStartDate(deal.date_lease_from);
      setLeaseEndDate(voucher.lease_expiration_date);
      setLeaseExecutionDate(deal.date_lease_execution);
      setLeaseOccupancyDate(deal.date_lease_occupancy);
    }
  }, [deal.lease_agreement_type, deal.lease_income_type, deal.number_of_seats, deal.date_lease_from, voucher.lease_expiration_date, deal, propertyOptions]);

  return (
    <div className="lease-information-tab">
      <h2>What New Properties are Being Leased?</h2>
      <form>
        <legend>Details</legend>
        <div className="flex-fields">
          <div className="field-group date-fields">
            <label>
              <span className={`${!deal.date_lease_from && showPageValidation ? 'errorInput' : ''}`}>* </span>
              <span>Lease Start Date</span>
            </label>
            <Datepicker
              date={leaseStartDate}
              onDateChange={date => handleDateChange(date, 'date_lease_from')}
            />
            {(!deal.date_lease_from && showPageValidation) && <span className="date-error">Lease Start Date is required</span>}
          </div>
          <div className="field-group date-fields">
            <label>
              <span className={`${!voucher.lease_expiration_date && showPageValidation ? 'errorInput' : ''}`}>* </span>
              <span>Lease Expiration Date</span>
            </label>
            <Datepicker
              date={leaseEndDate}
              onDateChange={date => handleDateChange(date, 'lease_expiration_date')}
            />
            {(!voucher.lease_expiration_date && showPageValidation) && <span className="date-error">Lease Expiration Date is required</span>}
          </div>
        </div>
        <div className="flex-fields">
          <div className="field-group date-fields">
            <label>
              <span className={`${!deal.date_lease_execution && showPageValidation ? 'errorInput' : ''}`}>* </span>
              <span>Lease Execution Date</span>
            </label>
            <Datepicker
              date={leaseExecutionDate}
              onDateChange={date => handleDateChange(date, 'date_lease_execution')}
            />
            {(!deal.date_lease_execution && showPageValidation) && <span className="date-error">Lease Execution Date is required</span>}
          </div>
          <div className="field-group date-fields">
            <label>
              <span className={`${!deal.date_lease_occupancy && showPageValidation ? 'errorInput' : ''}`}>* </span>
              <span>Lease Occupancy Date</span>
            </label>
            <Datepicker
              date={leaseOccupancyDate}
              onDateChange={date => handleDateChange(date, 'date_lease_occupancy')}
            />
            {(!deal.date_lease_occupancy && showPageValidation) && <span className="date-error">Lease Occupancy Date is required</span>}
          </div>
        </div>
        <div className="field select-subType">
          <label>
            <span className={(agreementType === '' || agreementErrorDrpDwn) ?
            'asterisk-error' : ''}
            >*
            </span> Lease Agreement Type
          </label>
          <Select
            className={deal.lease_agreement_type === '' ? 'errorInput' : ''}
            defaultOption={deal.lease_agreement_type || 'Select'}
            options={agreementTypeData}
            onChange={handleAgreementChange}
          />
          {(agreementErrorDrpDwn) &&
          <p className="asterisk-error top10">Lease Agreement Type is required</p>}
        </div>
        <div className="field select-subtype">
          <label>
            <span className={(transactionType === '' || leaseErrorDrpDwn) ?
            'asterisk-error' : ''}
            >*
            </span> Lease Transaction Type
          </label>
          <Select
            className={deal.lease_income_type === '' ? 'errorInput' : ''}
            defaultOption={deal.lease_income_type || 'Select'}
            options={transactionTypeData}
            onChange={handleLeaseIncomeTypeChange}
          />
          {(leaseErrorDrpDwn) &&
          <p className="asterisk-error top10">Lease Transaction Type is required</p>}
        </div>
        <div>
          <legend className="secondary-legend">Is this an agile/coworking deal?</legend>
          <RadioGroup
            name="coworking-deal"
            className="radioVal"
            selectedValue={`${deal.is_agile_deal}`}
            onChange={handleDealChange}
            orientation="vertical"
          >
            <RadioButton value="false">No</RadioButton>
            <RadioButton value="true">Yes</RadioButton>
          </RadioGroup>
        </div>
        {deal.is_agile_deal === 'true' &&
        <div>
          <div className="field">
            <label>Number of Seats</label>
            <input
              type="number"
              name="number-of-seats"
              value={deal.number_of_seats || noOfSeats || ''}
              onChange={handleNumseatChange}
            />
          </div>
          <div className="field">
            <label>Cost per Seat($)</label>
            <input
              type="number"
              name="cost-per-seat"
              value={deal.cost_per_seat || costPerSeat || ''}
              onChange={handleSeatChange}
              onBlur={handleSeatChangeBlur}
            />
          </div>
        </div>}
        <div>
          <legend className="secondary-legend">Is this a sublease?</legend>
          <RadioGroup
            name="sublease-deal"
            className="radioVal"
            selectedValue={`${deal.is_sublease}`}
            onChange={handleLeaseChange}
            orientation="vertical"
          >
            <RadioButton value="false">No</RadioButton>
            <RadioButton value="true">Yes</RadioButton>
          </RadioGroup>
        </div>
        <div>
          {properties.map(cp => (
            <div key={cp.id}>
              <div>
                <legend>Property Being Leased</legend>
                <div>
                  <ul className="property-list">
                    {dealProperties && dealProperties.filter(dp => linkedProperties[cp.id] !== dp.id).map((dp, i) => ((i >= 2 && !showMore[cp.id]) ? null :
                  (
                    <li className="property-list-style" key={dp.id} onClick={() => handleLinkedProperty(cp.id, dp)}>
                      <div className="person-component">
                        <div className="person-textbox">
                          <h4>{dp.name}</h4>
                          <p>{dp.building.full_address}</p>
                        </div>
                      </div>
                      <div>
                        <span className="add-icon">
                          <Icon iconName="plus-circle-outline" size="large" />
                        </span>
                      </div>
                    </li>
                  )))
                }
                  </ul>
                </div>
                <span>
                  {dealProperties.length > (linkedProperties[cp.id] ? 3 : 2) &&
                  <React.Fragment>
                    <span className="poc-text">
                      {showMore[cp.id] ?
                        <span className="show-more" onClick={() => handleShowMore(cp.id)}>
                          <Icon iconName="minus-square icon-square" size="small" /> <span className="show-text">Show Less</span>
                        </span> :
                        <span className="show-more" onClick={() => handleShowMore(cp.id)}>
                          <Icon iconName="plus-square icon-square" size="small" />
                          <span className="show-text">Show More</span> ({dealProperties.length - (linkedProperties[cp.id] ? 3 : 2)})
                        </span>}
                    </span>
                  </React.Fragment>}
                </span>
              </div>
              <div className="div-align">
                <label>Property Search Tip
                  <Tooltip
                    message={
                      <>
                        <h3>Property Search Tip</h3>
                        <br />
                        <p>Try entering a street address, intersection, and include the city name.</p>
                      </>
                    }
                  >
                    <Icon iconName="info-circle" />
                  </Tooltip>
                </label>
                <PropertyAutocomplete
                  errorMessage=""
                  onSelect={property => addProperty(cp.id, property)}
                />
              </div>
              <div className="field">
                <label>Property Name</label>
                <input
                  type="text"
                  name="name"
                  value={cp.name || ''}
                  onChange={onPropertyChange(cp.id)}
                />
              </div>
              <AddressForm
                address={cp.address || ''}
                city={cp.city || ''}
                country={cp.country || ''}
                postal={cp.postal_code || ''}
                territory={cp.state_abbreviation || ''}
                onChange={e => onRemapPropertyEvent(e, onPropertyChange(cp.id))}
                onBlur={e => onRemapPropertyEvent(e, onPropertyBlur(cp.id))}
                isRelocating
                showValidation={showPageValidation}
              />
              <div>
                <legend className="secondary-legend">Is Property Confidential?</legend>
                <RadioGroup
                  name="is_property_confidential"
                  className="radioVal"
                  selectedValue={`${cp.is_property_confidential}`}
                  onChange={onPropertyChange(cp.id)}
                  orientation="vertical"
                >
                  <RadioButton value="false">No</RadioButton>
                  <RadioButton value="true">Yes</RadioButton>
                </RadioGroup>
              </div>
              <div>
                <legend className="secondary-legend">Is this the whole building, or suites in a building?</legend>
                <RadioGroup
                  name="whole_building"
                  className="radioVal"
                  selectedValue={`${cp.whole_building}`}
                  onChange={onPropertyChange(cp.id)}
                  orientation="vertical"
                >
                  <RadioButton value="true">Whole Building</RadioButton>
                  <RadioButton value="false">Suite(s)</RadioButton>
                </RadioGroup>
              </div>
              <div className="field select-subType sub-type">
                <label>
                  <span className={(cp.voucher_property_type_category === '' && showPageValidation) ?
                  'asterisk-error' : ''}
                  >*
                  </span> Property Type / Subtype
                </label>
                <Select
                  className={cp.voucher_property_type_category === '' ? 'errorInput' : ''}
                  defaultOption={cp.voucher_property_type_category || 'Select'}
                  options={propertyOptions.filter(opt => opt.value !== '')}
                  onChange={e => onPropertyChange(cp.id)({
                  target: {
                    type: 'select',
                    name: 'voucher_property_type_category',
                    value: e.value === null ? '' : e.value,
                  },
                })}
                />
                {(cp.voucher_property_type_category === '' && showPageValidation) &&
                <p className="asterisk-error top10">Property Type / Subtype is required</p>}
              </div>
              <div className="field select-subType">
                <label>
                  <span className={(cp.space_type === '' && showPageValidation) ?
                  'asterisk-error' : ''}
                  >*
                  </span> Space Type
                </label>
                <Select
                  className={cp.space_type === '' ? 'errorInput' : ''}
                  defaultOption={cp.space_type || 'Select'}
                  options={spaceTypeData.filter(opt => opt.value !== '')}
                  onChange={e => onPropertyChange(cp.id)({
                  target: {
                    type: 'select',
                    name: 'space_type',
                    value: e.value === null ? '' : e.value,
                  },
                })}
                />
                {(cp.space_type === '' && showPageValidation) &&
                <p className="asterisk-error top10">Space Type is required</p>}
              </div>
              {cp.whole_building === true &&
              <div className="field">
                <label>
                  <span className={(cp.building_total_square_feet === null && showPageValidation) ?
                  'asterisk-error' : ''}
                  >*
                  </span>Square Footage
                </label>
                <NumberInput
                  name="building_total_square_feet"
                  value={cp.building_total_square_feet !== null ? parseFloat(cp.building_total_square_feet.toString()) : null}
                  onBlur={onCurrencyBlur(cp.id, 'building_total_square_feet')}
                />
                {((cp.building_total_square_feet === null || cp.building_total_square_feet === '') && showPageValidation) &&
                <p className="asterisk-error square-foot">Square Footage is required</p>}
              </div>}
              <div className="field">
                <label>Parcel Size/Acres</label>
                <NumberInput
                  name="parcel_size"
                  value={cp.parcel_size !== null ? parseFloat(cp.parcel_size.toString()) : null}
                  onBlur={onCurrencyBlur(cp.id, 'parcel_size')}
                />
              </div>
              <div>
                <legend className="secondary-legend">Is Property listed by CBRE?</legend>
                <RadioGroup
                  name="is_listed"
                  className="radioVal"
                  selectedValue={`${cp.is_listed}`}
                  onChange={onPropertyChange(cp.id)}
                  orientation="vertical"
                >
                  <RadioButton value="false">No</RadioButton>
                  <RadioButton value="true">Yes</RadioButton>
                </RadioGroup>
              </div>
              {cp.is_listed === true &&
              <div className="field">
                <label>CBRE Listing #</label>
                <input
                  type="text"
                  name="cbre_listing_number"
                  value={cp.cbre_listing_number || ''}
                  onChange={onPropertyChange(cp.id)}
                  onBlur={onPropertyBlur(cp.id)}
                />
              </div>}
              <div>
                <legend className="secondary-legend">Is Property Managed by CBRE?</legend>
                <RadioGroup
                  name="is_property_managed_by_cbre"
                  className="radioVal"
                  selectedValue={`${cp.is_property_managed_by_cbre}`}
                  onChange={onPropertyChange(cp.id)}
                  orientation="vertical"
                >
                  <RadioButton value="false">No</RadioButton>
                  <RadioButton value="true">Yes</RadioButton>
                </RadioGroup>
              </div>
              <div className="field">
                <label>Mix Property ID</label>
                <input
                  type="text"
                  name="mix_property_id"
                  value={cp.mix_property_id || ''}
                  onChange={onPropertyChange(cp.id)}
                  onBlur={onPropertyBlur(cp.id)}
                />
              </div>
              <div className="field select-subType">
                <label>
                  Building Class
                </label>
                <Select
                  defaultOption={cp.building_class || 'Select'}
                  options={buildingClassData.filter(opt => opt.value !== '')}
                  onChange={e => onPropertyChange(cp.id)({
                  target: {
                    type: 'select',
                    name: 'building_class',
                    value: e.value === null ? '' : e.value,
                  },
                })}
                />
              </div>
              <div className="field">
                <label>Portfolio Name</label>
                <input
                  type="text"
                  name="portfolio_name"
                  value={cp.portfolio_name || ''}
                  onChange={onPropertyChange(cp.id)}
                  onBlur={onPropertyBlur(cp.id)}
                />
              </div>
              <div>
                {!cp.whole_building && cp.spaces?.map((space, i) => (
              // eslint-disable-next-line react/no-array-index-key
                  <div key={`${cp.id}-${i}`}>
                    <legend>Suite
                    </legend>
                    <div className="form-row">
                      <div className="field-group col-3">
                        <label>Suite</label>
                        <input
                          type="text"
                          name="address_line2"
                          value={space.address_line2 || ''}
                          onChange={onSpaceChange(cp.id, i)}
                          onBlur={onSpaceBlur(cp.id, i)}
                        />
                      </div>
                      <div className="field-group col-3">
                        <label>Floor</label>
                        <input
                          type="text"
                          name="floor"
                          value={space.floor || ''}
                          onChange={onSpaceChange(cp.id, i)}
                          onBlur={onSpaceBlur(cp.id, i)}
                        />
                      </div>
                      <div className={space.space_total_square_feet === null || space.space_total_square_feet === ''
                          ? 'field-group col-3 top10' : 'field-group col-3'}
                      >
                        <label>
                          <span className={((space.space_total_square_feet === null || space.space_total_square_feet === '') && showPageValidation)
                            ? 'asterisk-error' : ''}
                          >*
                          </span>
                          <span> Square Feet</span>
                        </label>
                        <input
                          className={(space.space_total_square_feet === null || space.space_total_square_feet === '') ? 'errorInput' : ''}
                          type="number"
                          name="space_total_square_feet"
                          value={space.space_total_square_feet || ''}
                          onChange={onSpaceChange(cp.id, i)}
                          onBlur={onSpaceBlur(cp.id, i)}
                        />
                        {((space.space_total_square_feet === null || space.space_total_square_feet === '') && showPageValidation) &&
                        <p className="asterisk-error">Square Feet is required</p>}
                      </div>
                    </div>
                  </div>
            ))
            }
              </div>
            </div>
        ))}
        </div>
      </form>
    </div>
  );
};

LeaseInformation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  updateDeal: PropTypes.func.isRequired,
  updateVoucher: PropTypes.func.isRequired,
  voucher: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lease_expiration_date: PropTypes.string,
  }).isRequired,
  deal: PropTypes.shape({
    rep_role: PropTypes.string,
    whole_building: PropTypes.string,
    date_lease_from: PropTypes.string,
    date_lease_execution: PropTypes.string,
    date_lease_occupancy: PropTypes.string,
    lease_agreement_type: PropTypes.string,
    lease_income_type: PropTypes.string,
    number_of_seats: PropTypes.string,
    date_lease_to: PropTypes.string,
    deal_type: PropTypes.string,
    is_agile_deal: PropTypes.bool,
    is_dual_rep_deal: PropTypes.bool,
    id: PropTypes.number,
    is_sublease: PropTypes.bool,
    cost_per_seat: PropTypes.string,
    client: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
};

LeaseInformation.defaultProps = {
  location: '',
};

export default LeaseInformation;
