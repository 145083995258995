/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Icon } from '@cbrebuild/blocks';

import DealActionsDropdown from './deal-actions-dropdown';
import DealClient from './deal-client';
import DealPagination from './deal-pagination';
import DealStatusAction from '../../deal-status-action/deal-status-action';
import DealMembers from './deal-members';
import LeaseExpirationBanner from './lease-expiration-banner';
import NDButton from '../../../nucleus/button/button';
import ButtonWithFlyout from '../../../nucleus/button-with-flyout/button-with-flyout';
import voucherService from '../../../services/voucher/vouchers-service';
import EventService from '../../../services/user-event-service';
import DealNameAndLabel from './deal-name-and-label';

import dealsService from '../../../services/deals-service';
import VoucherButton from '../../voucher/VoucherButton';

const DealDetailHeader = (props) => {
  const {
    analyticProperties,
    deal,
    updateDeal,
    dealUsers,
    dealTeams,
    featureFlags,
    viewOnly,
    adminView,
    toast,
    fetchVoucherByDealId,
    voucher,
    userData,
  } = props;
  if (!deal) {
    return null;
  }

  // eslint-disable-next-line no-nested-ternary
  const voucherPath = !(deal.deal_type === 'lease') ? (deal.deal_type !== 'sale') ? 'misc_voucher' : 'sale_voucher' : 'voucher';
  const dealStage = deal.deal_life_cycle_stage;
  const voucherId = voucher.unique_id;

  const getAllUsersInTeams = () => {
    const allUsersInTeams = new Set();
    dealTeams.forEach((dataItem) => {
      dataItem.team.users.map(user => allUsersInTeams.add(user.id));
    });
    return allUsersInTeams;
  };

  const dealUsersToRender = dealUsers.filter(dataItem => !getAllUsersInTeams().has(dataItem.user.id));
  const dealTeamsToRender = dealTeams.map(dataItem => dataItem.team);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    dealsService.fetchDealFavorite(deal.id)
      .then((response) => {
        if (response.status === 204) {
          setIsFavorite(true);
        }
      }).catch(error => console.log('Error getting favorite status: ', error));
  }, []);

  useEffect(() => {
    if (!viewOnly && !adminView) {
      // set voucher in the store
      fetchVoucherByDealId(deal.id);
    }
  }, []);

  const [voucherEmail, setVoucherEmail] = useState([]);
  useEffect(() => {

      voucherService.fetchVoucherEmail(deal.id)
      .then((res) => {
                setVoucherEmail(res);
              });
  }, []);

  // TODO: remove voucher button related logic from the header when React Voucher is done.
  const updateAndGotoVoucher = (vid, earlyInvoice) => {
    // use store voucher.id or state voucherId
    voucherService.updateVoucher(vid, {
      is_early_invoice: earlyInvoice,
      official_start_date: earlyInvoice ? voucher.official_start_date : voucher.official_start_date || moment().format(),
    }).then(() => {
      EventService.trackEvent({
        eventCategory: earlyInvoice ? 'Early Invoice' : 'Voucher',
        eventAction: earlyInvoice ? 'early_invoice_selected' : 'voucher_selected',
        eventValue: deal.id,
      });
      updateDeal(deal.id, {
        modified: new Date(),
      });
      window.location.assign(`/${voucherPath}/${deal.id}/1_getting_started`);
    }).catch(error => console.log('Error updating voucher: ', error));
  };

  // TODO: remove voucher button related logic from the header when React Voucher is done.
  const goToVoucher = (earlyInvoice) => {
    // check voucher id from the redux store
    // if not, create voucher
    if (!voucher.id) {
      voucherService.fetchVoucherByDealId(deal.id)
        .then((existingVoucher) => {
          console.log('@@@@@@@existing voucher@@@@@', existingVoucher);
          if (existingVoucher && Object.keys(existingVoucher).length > 0) {
            updateAndGotoVoucher(existingVoucher.id, earlyInvoice);
          } else {
            voucherService.createVoucher(deal.id)
              .then((res) => {
                updateAndGotoVoucher(res.id, earlyInvoice);
              });
          }
        });
    } else {
      // if voucher id from the redux store exist, use it.
      updateAndGotoVoucher(voucher.id, earlyInvoice);
    }
  };

  const getAllCommissionedUsers = () => {
    const commissionedUsers = deal.permissions.commissioned_users || {};
    const allCommissionUsers = new Set();
    const commissions = Object.keys(commissionedUsers);
    commissions.forEach((commission) => {
      commissionedUsers[commission].map(userId => allCommissionUsers.add(userId));
    });
    return Array.from(allCommissionUsers);
  };

  const hasLinkedTransactions = deal.linked_mta_transactions && deal.linked_mta_transactions.length > 0;
  const disableVoucherButton = (dealStage === 'closed' && deal.is_mta) || viewOnly;
  const reviseVoucherClass =
    (deal.voucher_submission_status === 'Voucher Submitted' ||
      deal.voucher_submission_status === 'Voucher Resubmitted' ||
      deal.voucher_submission_status === 'Voucher Completed') &&
    dealStage === 'closed' ? 'revise-voucher' : '';

  const renderVoucherButtonTitle = () => {
    let voucherTitle = 'Voucher';
    if (voucher && voucher.is_early_invoice) {
      voucherTitle = 'Early Invoice';
    } else if (reviseVoucherClass) {
      voucherTitle = 'Revise Voucher';
    }
    return voucherTitle;
  };

  const isReactVoucherOn = featureFlags.react_vouchers_flag;

  return (
    <div id="deal-detail-header">
      {!adminView && <LeaseExpirationBanner deal={deal} viewOnly={viewOnly} />}
      <div className="deal-header-top">
        <DealNameAndLabel
          analyticProperties={analyticProperties}
          deal={deal}
          updateDeal={updateDeal}
          featureFlags={featureFlags}
          viewOnly={viewOnly}
          toast={toast}
          adminView={adminView}
          isFavorite={isFavorite}
          voucherID={voucherId}
          isEarlyInvoice={voucher.is_early_invoice}
          voucherEmail={voucherEmail}
        />
        <div className="deal-actions">
          {!adminView ?
            <div className="deal-actions-top">
              <DealActionsDropdown
                analyticProperties={analyticProperties}
                deal={deal}
                updateDeal={updateDeal}
                dealUsers={dealUsers}
                dealTeams={dealTeams}
                viewOnly={viewOnly}
              />
              <DealStatusAction
                deal={deal}
                updateDeal={updateDeal}
                disabled={hasLinkedTransactions || viewOnly}
              />
              {isReactVoucherOn ? <VoucherButton deal={deal} isDisabled={disableVoucherButton} /> : (
                <React.Fragment>
                  <Button
                    className={`voucher-btn ${reviseVoucherClass}`}
                    data-e2e="deal-detail-nav-voucher"
                    onClick={() => goToVoucher(voucher.is_early_invoice)}
                    disabled={disableVoucherButton}
                  >
                    <Icon iconName="cash-dollars" />
                    {renderVoucherButtonTitle()}
                  </Button>
                  {!reviseVoucherClass &&
                    <ButtonWithFlyout
                      className="voucher-dropdown"
                      buttonIcon="chevron-down"
                      dataE2e="deal-voucher-dropdown-button"
                      buttonVariant="primary"
                      position="right"
                      disabled={disableVoucherButton}
                    >
                      <NDButton
                        variant="option"
                        dataE2e="deal-voucher-flyout-voucher"
                        onMouseDown={() => goToVoucher(false)}
                        width="full"
                      >
                        Voucher
                      </NDButton>
                      <NDButton
                        variant="option"
                        dataE2e="deal-voucher-flyout-early-invoice"
                        onMouseDown={() => goToVoucher(true)}
                        width="full"
                      >
                        Early Invoice
                      </NDButton>
                    </ButtonWithFlyout>
                  }
                </React.Fragment>)}
            </div> :
            <div className="deal-actions-top" />}
          <div className="deal-collaborators">
            <DealMembers
              analyticProperties={analyticProperties}
              dealUsers={dealUsersToRender}
              dealTeams={dealTeamsToRender}
              proxyMembers={deal.permissions.proxy_user}
              maxAvatarsToShow={9}
              commissionedUserIds={getAllCommissionedUsers()}
              viewOnly={viewOnly}
              showAddOrRemove={!adminView}
              hideTeamMemberAvatars={adminView}
              userData={userData}
            />
          </div>
        </div>
      </div>
      <div className="deal-header-bottom">
        <DealClient
          deal={deal}
          updateDeal={updateDeal}
          viewOnly={viewOnly}
          toast={toast}
        />
      </div>
      {!adminView &&
      <div className="pagination-controls">
        <DealPagination dealID={deal.id} featureFlags={featureFlags} />
      </div>}
    </div>
  );
};

DealDetailHeader.propTypes = {
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }),
  userData: PropTypes.shape({}).isRequired,
  deal: PropTypes.shape({
    name: PropTypes.string,
    client: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
    dealStage: PropTypes.string,
    deal_life_cycle_stage: PropTypes.string,
    deal_type: PropTypes.string,
    id: PropTypes.number,
    is_mta: PropTypes.bool,
    linked_mta_transactions: PropTypes.arrayOf(PropTypes.number),
    permissions: PropTypes.shape({
      commissioned_users: PropTypes.shape({}),
      proxy_user: PropTypes.shape(),
    }),
    voucher_submission_status: PropTypes.string,
  }).isRequired,
  dealTeams: PropTypes.arrayOf(PropTypes.shape({
    team: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      teamColor: PropTypes.string,
      users: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.shape({
          work: PropTypes.string,
        }),
        avatar: PropTypes.string,
      })),
    }),
  })),
  dealUsers: PropTypes.arrayOf(PropTypes.shape()),
  updateDeal: PropTypes.func.isRequired,
  featureFlags: PropTypes.shape().isRequired,
  viewOnly: PropTypes.bool,
  adminView: PropTypes.bool,
  toast: PropTypes.func.isRequired,
  fetchVoucherByDealId: PropTypes.func.isRequired,
  voucher: PropTypes.shape({
    id: PropTypes.number,
    is_early_invoice: PropTypes.bool,
    official_start_date: PropTypes.string,
    unique_id: PropTypes.string,
  }),
};

DealDetailHeader.defaultProps = {
  dealUsers: [],
  dealTeams: [],
  viewOnly: false,
  adminView: false,
  analyticProperties: {},
  voucher: {},
};

export default DealDetailHeader;
