/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */

// A promise based sequential execution
async function sequentialExec(data, callback) {
  const fullfilled = [];
  for (const payload of data) {
    const newPromise = await callback(payload);
    fullfilled.push(newPromise);
  }
  return fullfilled;
}

export default sequentialExec;
