import React from 'react';
import { useSelector } from 'react-redux';
import useVoucherValidation from '../useVoucherValidation';
import FeeSharing from './FeeSharing';
import OutsideBrokers from './OutsideBrokers';
import Rebates from './Rebates';

const PAGE_NUMBER = 3.2;

const AllocationsTab = () => {
  const {
    id, voucherId, outside_broker_is_percent, fee_sharing_is_percent, rebate_is_percent,
  } = useSelector(state => ({
    id: state.deal.deal?.id,
    dealType: state.deal.deal?.deal_type,
    voucherId: state.voucher.voucher?.id,
    outside_broker_is_percent: state.voucher.voucher?.outside_broker_is_percent,
    fee_sharing_is_percent: state.voucher.voucher?.fee_sharing_is_percent,
    rebate_is_percent: state.voucher.voucher?.rebate_is_percent,
  }));
  const { showPageValidation } = useVoucherValidation(voucherId, PAGE_NUMBER);
  return (
    <>
      <OutsideBrokers
        dealId={id}
        voucherId={voucherId}
        outside_broker_is_percent={outside_broker_is_percent}
        showValidation={showPageValidation}
      />
      <FeeSharing
        dealId={id}
        voucherId={voucherId}
        fee_sharing_is_percent={fee_sharing_is_percent}
        showValidation={showPageValidation}
      />
      <Rebates
        dealId={id}
        voucherId={voucherId}
        rebate_is_percent={rebate_is_percent}
        showValidation={showPageValidation}
      />
    </>
  );
};

export default AllocationsTab;
