import {
  FETCH_CONSIDERATIONS_BY_DEAL_ID_SUCCESS,
  CREATE_CONSIDERATION_SUCCESS,
  DELETE_CONSIDERATION_SUCCESS,
  UPDATE_CONSIDERATION_SUCCESS,
  UPDATE_CONSIDERATION_STATE,
} from '../actions/action-types';
import REQUEST_STATE from '../../utils/request-state';

const initialState = {
  considerations: [],
  state: REQUEST_STATE.INITIALIZING,
};

const addConsiderationSuccess = (state, action) => ({
  ...state,
  considerations: [...state.considerations, action.payload],
});

const deleteConsiderationSuccess = (state, action) => ({
  ...state,
  considerations: state.considerations.filter(({ id }) => id !== action.payload),
});

const fetchConsiderationsSuccess = (state, action) => ({
  ...state,
  considerations: action.payload,
});

const updateConsiderationSuccess = (state, action) => ({
  ...state,
  considerations: state.considerations.map(consideration => (
    consideration.id !== action.payload.id
      ? consideration : action.payload
  )),
});

const updateConsiderationState = (state, action) => ({
  ...state,
  state: action.payload,
});

const considerations = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CONSIDERATION_SUCCESS:
      return addConsiderationSuccess(state, action);
    case DELETE_CONSIDERATION_SUCCESS:
      return deleteConsiderationSuccess(state, action);
    case FETCH_CONSIDERATIONS_BY_DEAL_ID_SUCCESS:
      return fetchConsiderationsSuccess(state, action);
    case UPDATE_CONSIDERATION_SUCCESS:
      return updateConsiderationSuccess(state, action);
    case UPDATE_CONSIDERATION_STATE:
      return updateConsiderationState(state, action);
    default:
      return state;
  }
};

export default considerations;
