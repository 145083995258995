import { connect } from 'react-redux';
import {
  removeDealMembers,
  removeDealTeams,
  addDealMembers,
  addDealTeams,
  addCommissionMembers,
  removeCommissionMembers,
  addCommissionTeams,
  removeCommissionTeams,
} from '../../redux/actions/team-members-actions';
import { addRecipients } from '../../redux/actions/voucher/voucher-actions';
import DealTeamModal from './deal-team-modal';

const mapStateToProps = state => ({
  deal: state.deal.deal || {},
  dealUsers: state.teamMembers.dealUsers || [],
  dealTeams: state.teamMembers.dealTeams || [],
  relatedTransactionUsers: state.teamMembers.relatedTransactionUsers || [],
  relatedTransactionTeams: state.teamMembers.relatedTransactionTeams || [],
  user: state.userData.data || {},
});

const mapDispatchToProps = ({
  addDealMembers,
  addCommissionMembers,
  addDealTeams,
  addCommissionTeams,
  addRecipients,
  removeDealMembers,
  removeCommissionMembers,
  removeDealTeams,
  removeCommissionTeams,
});

const DealDetailTeamModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealTeamModal);

export default DealDetailTeamModalContainer;
