import {
  ADD_PROPERTY_SUCCESS,
  ADD_PROPERTY_FAILURE,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAILURE,
  LOAD_PROPERTIES_SUCCESS,
  LOAD_PROPERTIES_FAILURE,
  IS_LOADING_PROPERTIES,
  UPDATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_FAILURE,
} from '../actions/action-types';

const initialState = {
  error: undefined,
  isLoadingProperties: false,
  properties: [],
  propertiesError: undefined,
};

const addPropertySuccess = (state, action) => ({
  ...state,
  properties: [
    action.payload,
    ...state.properties,
  ],
});

const addPropertyFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const deletePropertySuccess = (state, action) => ({
  ...state,
  properties: state.properties.filter(p => p.id !== action.payload.id),
});

const deletePropertyFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const loadPropertiesSuccess = (state, action) => {
  const { response } = action.payload;
  return {
    ...state,
    properties: response.results,
  };
};

const loadPropertiesFailure = (state, action) => ({
  ...state,
  propertiesError: action.payload.error,
});

const isLoadingProperties = (state, action) => ({
  ...state,
  isLoadingProperties: action.payload.isLoadingProperties,
});

const updatePropertySuccess = (state, action) => ({
  ...state,
  properties: state.properties.map(p => (p.id === action.payload.id
    ? action.payload
    : p)),
});

const updatePropertyFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const properties = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PROPERTY_SUCCESS:
      return addPropertySuccess(state, action);
    case ADD_PROPERTY_FAILURE:
      return addPropertyFailure(state, action);
    case DELETE_PROPERTY_SUCCESS:
      return deletePropertySuccess(state, action);
    case DELETE_PROPERTY_FAILURE:
      return deletePropertyFailure(state, action);
    case IS_LOADING_PROPERTIES:
      return isLoadingProperties(state, action);
    case LOAD_PROPERTIES_SUCCESS:
      return loadPropertiesSuccess(state, action);
    case LOAD_PROPERTIES_FAILURE:
      return loadPropertiesFailure(state, action);
    case UPDATE_PROPERTY_SUCCESS:
      return updatePropertySuccess(state, action);
    case UPDATE_PROPERTY_FAILURE:
      return updatePropertyFailure(state, action);
    default:
      return state;
  }
};

export default properties;
