import { isEmailField, isDecimalField } from './validation';

const validationSchema = [
  isEmailField('tenantPOCEmail.value', 'tenantPOCEmail'),
  isEmailField('landlordPOCEmail.value', 'landlordPOCEmail'),
  isDecimalField('parcelSize.value', 'parcelSize'),
  isDecimalField('baseRent.value', 'baseRent'),
  isDecimalField('tenantImprovementAllowance.value', 'tenantImprovementAllowance'),
];

export default validationSchema;
