import React from 'react';
import PropTypes from 'prop-types';

import FileUpload from '../../file-upload/file-upload';
import FileListItem from '../../file-upload/file-list-item';
import LoadMore from '../../../nucleus/load-more/load-more';
import userOptionsService from '../../../services/user-options-service';
import authService from '../../../services/auth-service';
import companiesService, { defaultCompanyData } from '../../../services/companies-service';
import contactsService from '../../../services/contacts-service';

import vouchersService from '../../../services/voucher/vouchers-service';
import dealsService from '../../../services/deals-service';
import * as compDataService from '../../../services/voucher/comp-data-service';
/* global CONFIG */
const { apiUrl } = CONFIG;

class FilesTab extends React.Component {
  state = {
    queryParams: {
      ordering: '-modified',
      page: 1,
      page_size: 25,
    },
  }

  componentDidMount() {
    const { queryParams } = this.state;
    const { dealId, fetchFiles } = this.props;
    fetchFiles({ ...queryParams, deal: dealId });
    let lease_abstraction_enabled;
    userOptionsService.fetchFeatureFlags()
      .then(({ results }) => {
        lease_abstraction_enabled = results?.find(featureFlag => featureFlag.feature_flag === 'lease_abstraction_flag')?.enabled;

        // Only check processing status if lease_abstraction_flag is enabled
        if (lease_abstraction_enabled) {
          const url = `${apiUrl}/mbb/deals/${dealId}`;
          fetch(url, {
            headers: {
              Authorization: `JWT ${authService.getToken()}`,
            },
          })
            .then(response => response.json())
            .then((data) => {
              if (data.deal_type === 'lease') {
                // set up tenant and landlord contacts for lease deals
                this.fetchLeaseContacts();
                const promises = [];

                if (data.counter_party === null) {
                  console.log('Creating company...');
                  const createCompanyPromise = companiesService.createCompany({ ...defaultCompanyData })
                    .then(res => dealsService.updateDeal(dealId, { counter_party: res.id }));
                  promises.push(createCompanyPromise);
                }

                if (data.voucher === null) {
                  console.log('Creating voucher...');
                  const createVoucherPromise = vouchersService.createVoucher(dealId);
                  promises.push(createVoucherPromise);
                }

                if (data.voucher_commercial_properties.length === 0) {
                  console.log('Creating commercial property...');
                  const createCommercialPropertyPromise = vouchersService.createCommercialProperty({
                    deal: dealId,
                  });
                  promises.push(createCommercialPropertyPromise);
                }

                if (data.internalcomp === null) {
                  console.log('Creating internal comp...');
                  const createInternalCompPromise = compDataService.createCompData({
                    deal: dealId,
                    [`${data.rep_role}_represented_by`]: 'CBRE',
                    ...(data.deal_type !== 'sale' && {
                      rent_escalation_is_custom: false,
                    }),
                  });
                  promises.push(createInternalCompPromise);
                }
                return Promise.all(promises);
              }
              return Promise.resolve();
            })
            .catch(error => console.error('Error fetching or processing data:', error));
        }
      })
      .catch(error => console.error('Error:', error));
  }

  fetchLeaseContacts = async (params = {
    ordering: '-is_primary', page: 1, page_size: 25, deal: this.props.dealId,
  }) => {
    try {
      const response = await contactsService.getContacts(params);
      if (response && response.results) {
        const tenantContact = response.results.find(contact => contact.contact_type === 'tenant');
        const landlordContact = response.results.find(contact => contact.contact_type === 'landlord');
        if (!tenantContact) {
          const tenantContactResponse = await contactsService.postContact({
            contact_type: 'tenant',
            create_personal_contact: true,
            deal: this.props.dealId,
            email_addresses: [],
            given_name: '',
            is_primary: false,
            phone_numbers: [],
            surname: '',
          });
          console.log('Created tenant contact:', tenantContactResponse);
        }
        if (!landlordContact) {
          const landlordContactResponse = await contactsService.postContact({
            contact_type: 'landlord',
            create_personal_contact: true,
            deal: this.props.dealId,
            email_addresses: [],
            given_name: '',
            is_primary: false,
            phone_numbers: [],
            surname: '',
          });
          console.log('Created landlord contact:', landlordContactResponse);
        }
      } else {
        console.error('No data found in response');
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  handleLoadMore = () => {
    const { queryParams } = this.state;
    const { dealId, fetchFiles } = this.props;
    this.setState({
      queryParams: {
        ...queryParams,
        page: queryParams.page += 1,
      },
    }, () => fetchFiles({ ...queryParams, deal: dealId }));
  }

  render() {
    const {
      analyticProperties,
      dealId,
      deleteFile,
      fetchFilesDownloadLink,
      files,
      isLoadingFiles,
      next,
      updateFile,
      uploadFile,
      userData,
    } = this.props;

    return (
      <div className="files-tab detail-panels-layout">
        <div className="col-two-thirds card-default">
          <FileUpload
            analyticProperties={analyticProperties}
            dealId={dealId}
            deleteFile={deleteFile}
            fetchFilesDownloadLink={fetchFilesDownloadLink}
            isLoadingFiles={isLoadingFiles}
            uploadFile={uploadFile}
          />
          <ul className="files">
            {files &&
              files.map(file => (
                <FileListItem
                  analyticProperties={analyticProperties}
                  deleteFile={deleteFile}
                  fetchFilesDownloadLink={fetchFilesDownloadLink}
                  file={file}
                  key={file.id}
                  updateFile={updateFile}
                  userData={userData}
                />
              ))
            }
          </ul>
          {files.length >= 25 &&
            <LoadMore
              isLoading={isLoadingFiles}
              handleLoadMore={this.handleLoadMore}
              hasNextPage={next}
            />
          }
        </div>
      </div>
    );
  }
}

FilesTab.propTypes = {
  analyticProperties: PropTypes.shape({
    actionPrefix: PropTypes.string,
    categoryPrefix: PropTypes.string,
  }).isRequired,
  dealId: PropTypes.number.isRequired,
  deleteFile: PropTypes.func.isRequired,
  fetchFiles: PropTypes.func.isRequired,
  fetchFilesDownloadLink: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    deal: PropTypes.number,
    document_name: PropTypes.string,
    title: PropTypes.string,
    file_type: PropTypes.string,
    file_size: PropTypes.number,
    created_by: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      avatar: PropTypes.string,
      email: PropTypes.shape({
        work: PropTypes.string,
      }),
    }),
    created: PropTypes.string,
    modified: PropTypes.string,
    can_edit: PropTypes.bool,
    privacy: PropTypes.string,
    is_lease_document: PropTypes.bool,
  })).isRequired,
  isLoadingFiles: PropTypes.bool,
  next: PropTypes.number,
  updateFile: PropTypes.func,
  uploadFile: PropTypes.func.isRequired,
  userData: PropTypes.shape({}).isRequired,
};

FilesTab.defaultProps = {
  isLoadingFiles: false,
  next: null,
  updateFile: () => {},
};

export default FilesTab;
