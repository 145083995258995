import installmentsService from '../../../services/voucher/installments-service';
import {
  FETCH_INSTALLMENTS_SUCCESS,
  FETCH_INSTALLMENT_OPTIONS_SUCCESS,
  UPDATE_INSTALLMENT_SUCCESS,
  CREATE_INSTALLMENT_SUCCESS,
  DELETE_INSTALLMENT_SUCCESS,
} from '../action-types';

// action creators
const fetchInstallmentOptionsSuccess = options => ({
  type: FETCH_INSTALLMENT_OPTIONS_SUCCESS,
  payload: options,
});

const fetchInstallmentsSuccess = installments => ({
  type: FETCH_INSTALLMENTS_SUCCESS,
  payload: installments,
});

const updateInstallmentSuccess = installment => ({
  type: UPDATE_INSTALLMENT_SUCCESS,
  payload: installment,
});

const createInstallmentSuccess = installment => ({
  type: CREATE_INSTALLMENT_SUCCESS,
  payload: installment,
});

const deleteInstallmentSuccess = id => ({
  type: DELETE_INSTALLMENT_SUCCESS,
  payload: id,
});

// async action creators
const createInstallment = installmentTemplate => dispatch =>
  installmentsService.addInstallment(installmentTemplate)
    .then(installment => dispatch(createInstallmentSuccess(installment)));

const fetchInstallmentOptions = () => dispatch =>
  installmentsService.getOptions()
    .then(options => dispatch(fetchInstallmentOptionsSuccess(options)))
    .catch(() => { });

const fetchInstallments = dealId => dispatch =>
  installmentsService.fetchInstallments(dealId)
    .then(installments => dispatch(fetchInstallmentsSuccess(installments)));

const updateInstallment = (id, data) => dispatch => installmentsService.updateInstallment(id, data)
  .then((installment) => {
    dispatch(updateInstallmentSuccess(installment));
  });

const deleteInstallment = id => dispatch => installmentsService.deleteInstallment(id)
  .then(dispatch(deleteInstallmentSuccess(id)));

const addInstallment = installmentTemplate => dispatch => installmentsService.addInstallment(installmentTemplate)
  .then(installment => dispatch(createInstallmentSuccess(installment)));

export {
  fetchInstallmentOptions,
  fetchInstallments,
  updateInstallment,
  createInstallment,
  deleteInstallment,
  addInstallment,
};
