import isEqual from 'lodash/isEqual';
import uniqueId from 'lodash/uniqueId';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddItem from '../../../nucleus/add-item/add-item';
import { updateVoucher } from '../../../redux/actions/voucher/voucher-actions';
import FutureActionItem from './FutureActionItem';

const FutureActionsTab = () => {
  const {
    futureActions, voucher,
  } = useSelector(state => ({
    voucher: state.voucher.voucher,
    futureActions: state.voucher.voucher?.future_actions,
  }));

  const dispatch = useDispatch();

  const filterEmptyFutureAction = (action) => {
    if (action.future_action || action.future_action_date || action.notify_when_date) {
      return true;
    }
    return false;
  };

  const [displayedActions, setDisplayedActions] = useState(futureActions?.filter(filterEmptyFutureAction));

  const newItem = {
    future_action: null,
    future_action_date: null,
    notify_when_date: null,
  };

  useEffect(() => {
    const clonedDisplay = [...displayedActions].filter(filterEmptyFutureAction);

    // ensure there is at least one object to satisfy BE requirements
    if (clonedDisplay.length === 0) {
      clonedDisplay.push(newItem);
    }

    // futureActions represents database state, clonedDisplay represents UI
    // equality check prevents redundant patches
    if (!isEqual(clonedDisplay, futureActions)) {
      dispatch(updateVoucher(voucher.id, {
        future_actions: clonedDisplay,
      }));
    }
  });

  const onFutureActionItemDelete = (index) => {
    const clonedDisplay = [...displayedActions];
    clonedDisplay.splice(index, 1);
    setDisplayedActions(clonedDisplay);
  };

  const onFutureActionItemUpdate = (index, name, value) => {
    const clonedDisplay = [...displayedActions];
    clonedDisplay[index] = {
      ...clonedDisplay[index],
      [name]: value,
    };
    setDisplayedActions(clonedDisplay);
  };

  return (
    <React.Fragment>
      <div className="future-actions-tab">
        <h2>Set Future Actions</h2>
        <p>Schedule a reminder to take future actions for this deal.</p>
        {displayedActions?.map((futureAction, index) => (
          <FutureActionItem
            key={uniqueId()}
            onDelete={onFutureActionItemDelete}
            onUpdate={onFutureActionItemUpdate}
            index={index}
            future_action={futureAction.future_action}
            future_action_date={futureAction.future_action_date}
            notify_when_date={futureAction.notify_when_date}
          />
        ))}
        <div>
          <AddItem
            label="Add Future Action"
            onClick={() => {
              setDisplayedActions([
                ...displayedActions,
                newItem,
              ]);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default FutureActionsTab;
