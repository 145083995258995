import { SET_PROXY_ACCESS } from './action-types';

// Proxy Settings Action Creators
const setProxyAccess = proxy => ({
  type: SET_PROXY_ACCESS,
  payload: proxy,
});

export {
  // eslint-disable-next-line import/prefer-default-export
  setProxyAccess,
};
