import React, { Component } from 'react';
import PropTypes from 'prop-types';
import floor from 'lodash/floor';
import round from 'lodash/round';

import Select from '../select/select';
import NumberInput from './number-input';
import Currency from '../formats/currency';
import Percent from '../formats/percent';
import FormGroup from '../../nucleus/form-group/form-group';

const DEFAULT_ERROR_MESSAGE = 'Required';
class PercentDollarInput extends Component {
  state = {
    newPercent: this.props.percentModel,
    newDollar: this.props.dollarModel,
    hasError: this.props.isRequired && !this.props.percentModel && !this.props.dollarModel,
    errorMessage: DEFAULT_ERROR_MESSAGE,
  }

  componentDidUpdate(prevProps) {
    if (this.props.baseTotal !== prevProps.baseTotal) {
      // re-calculate percentModel/dollarModel
      const value = this.props.isPercent ? this.props.percentModel : this.props.dollarModel;
      this.updatePercentDollar(value, true);
    }
  }

  // handle sign toggle
  handleSelectChange = () => {
    this.props.toggleSign();
  }

  // handle input changes
  updatePercentDollar = (value, willUpdate) => {
    const {
      /*
      baseTotal represents expected total commission (if commission allocation / installment)
      or individual consideration amount (if consideration)
      */
      baseTotal,
      isPercent,
      isRequired,
    } = this.props;

    let newDollar;
    let newPercent;

    if (isPercent) {
      newPercent = value;

      if (newPercent === null) {
        newDollar = null;
      } else if (baseTotal === 0) {
        newDollar = 0;
      } else {
        newDollar = round(((baseTotal * newPercent) / 100), 2);
      }
    } else {
      newDollar = value;

      if (newDollar === null) {
        newPercent = null;
      } else if (baseTotal === 0) {
        newPercent = 0;
      } else {
        newPercent = floor(((newDollar / baseTotal) * 100), 8);
      }
    }

    this.setState({
      newPercent,
      newDollar,
      hasError: isRequired && (!newPercent && !newDollar),
      errorMessage: DEFAULT_ERROR_MESSAGE,
    });

    // when percent dollar values are updated by baseTotal change
    // it should update the change back to the parent
    // so that it can handle corresponding changes. i.e: sending patch request
    if (willUpdate) {
      this.props.updateChange(newPercent, newDollar);
    }
  }

  updateInvalidStatus = () => {
    const errorMessage = `${this.props.isRequired ? `${DEFAULT_ERROR_MESSAGE}: ` : ''}Invalid`;
    this.setState({ hasError: true, errorMessage });
  }

  updateOutOfRangeStatus = () => {
    const errorMessage = `${this.props.isRequired ? `${DEFAULT_ERROR_MESSAGE}: ` : ''}Out of Range`;
    this.setState({ hasError: true, errorMessage });
  }

  render() {
    const {
      isPercent,
      disabled,
      width,
      isRequired,
      label,
      updateChange,
      updateDealPipelineTimestamp,
      min,
      max,
      maxLength,
      maxPercent,
      maxPercentWarningText,
    } = this.props;

    const {
      newPercent,
      newDollar,
      hasError,
      errorMessage,
    } = this.state;

    const options = [
      {
        display_name: '$',
        value: '$',
      },
      {
        display_name: '%',
        value: '%',
      },
    ];

    const defaultOption = isPercent ? options[1] : options[0];
    const renderPercentInput = isPercent &&
      (<NumberInput
        value={newPercent}
        precision={8}
        updateValue={this.updatePercentDollar}
        onBlur={() => {
          updateChange(newPercent, newDollar);
          updateDealPipelineTimestamp();
        }}
        disabled={disabled}
        min={min}
        max={max}
        integerPrecision={maxLength ? maxLength - 8 : undefined}
        updateInvalidStatus={this.updateInvalidStatus}
        updateOutOfRangeStatus={this.updateOutOfRangeStatus}
      />);
    const renderDollarInput = !isPercent &&
      (<NumberInput
        value={newDollar}
        precision={2}
        updateValue={this.updatePercentDollar}
        onBlur={() => {
          updateChange(newPercent, newDollar);
          updateDealPipelineTimestamp();
        }}
        disabled={disabled}
        min={min}
        max={max}
        integerPrecision={maxLength ? maxLength - 2 : undefined}
        updateInvalidStatus={this.updateInvalidStatus}
        updateOutOfRangeStatus={this.updateOutOfRangeStatus}
      />);

    const displayMaxPercentWarning = maxPercent && newPercent > maxPercent;
    const renderHint = (
      <span className={`${displayMaxPercentWarning ? 'warning-text' : ''}`}>
        {isPercent ?
          <Currency value={newDollar} emptyState="--" />
          : <Percent value={newPercent} emptyState="--" />}
        {displayMaxPercentWarning && <><br />{maxPercentWarningText}</>}
      </span>
    );

    return (
      <FormGroup
        isRequired={isRequired || hasError}
        size={width}
        label={label}
        hasError={hasError}
        errorMessage={errorMessage}
        className="percent-dollar-input"
      >
        <React.Fragment>
          <div className={`percent-dollar-container ${displayMaxPercentWarning ? 'warning-border' : ''}`}>
            <Select
              onClear
              defaultOption={defaultOption}
              onChange={this.handleSelectChange}
              options={options}
              disabled={disabled}
            />
            {renderPercentInput}
            {renderDollarInput}
          </div>
          <div className="input-hint">{renderHint}</div>
        </React.Fragment>
      </FormGroup>
    );
  }
}

PercentDollarInput.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isPercent: PropTypes.bool,
  toggleSign: PropTypes.func.isRequired,
  percentModel: PropTypes.number,
  dollarModel: PropTypes.number,
  baseTotal: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  maxPercent: PropTypes.number,
  maxPercentWarningText: PropTypes.string,
  width: PropTypes.oneOf(['small', 'medium', 'large', 'x-large', '']),
  updateChange: PropTypes.func.isRequired,
  updateDealPipelineTimestamp: PropTypes.func.isRequired,
};

PercentDollarInput.defaultProps = {
  label: '',
  isRequired: false,
  isPercent: false,
  percentModel: 0,
  dollarModel: 0,
  disabled: false,
  min: null,
  max: null,
  maxLength: null,
  maxPercent: null,
  maxPercentWarningText: '',
  width: '',
};

export default PercentDollarInput;
