import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AddItem from '../../../nucleus/add-item/add-item';
import OfficeCommissionItem from './OfficeCommissionItem';
import { createCommission } from '../../../redux/actions/commissions-actions';


const selectCommissionsByIds = state => state.commissions.commissions
  .filter(({ cbre_broker_type }) => cbre_broker_type === 'office')
  .map(({ id }) => id);

const OfficeCommissions = ({
  dealId, voucherId, showValidation, cbre_commission_is_percent, shouldAddAsLeadBroker,
}) => {
  const dispatch = useDispatch();
  const commissionIds = useSelector(selectCommissionsByIds, shallowEqual);
  return (
    <div className="financials-section-wrapper">
      {commissionIds.map(id => (
        <OfficeCommissionItem
          key={id}
          id={id}
          dealId={dealId}
          voucherId={voucherId}
          showValidation={showValidation}
          cbre_commission_is_percent={cbre_commission_is_percent}
        />
      ))}
      <div>
        <AddItem
          label="Add Office"
          onClick={() => dispatch(createCommission({
            broker_is_lead: shouldAddAsLeadBroker,
            cbre_broker_type: 'office',
            payment_group: 1,
            transaction: dealId,
          }))}
        />
      </div>
    </div>
  );
};

OfficeCommissions.propTypes = {
  showValidation: PropTypes.bool.isRequired,
  dealId: PropTypes.number.isRequired,
  voucherId: PropTypes.number.isRequired,
  cbre_commission_is_percent: PropTypes.bool.isRequired,
  shouldAddAsLeadBroker: PropTypes.bool.isRequired,
};

export default OfficeCommissions;
