import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import FormatAsCurrency from '../../utils/format-as-currency';
import FormatNumberWithCommas from '../../utils/format-number-with-commas';

import Slider from '../../nucleus/slider/slider';
import StackedLabeledItem from '../../nucleus/stacked-labeled-item/stacked-labeled-item';

const DealCardFinancialsRight = (props) => {
  const {
    id,
    closeDate,
    conversionPotential,
    dateLeaseTo,
    dealLifeCycleStage,
    dealType,
    estimatedCommission,
    grossCommission,
    property,
    requirements,
    repRole,
    salePrice,
    finalSQFT,
    onConversionChange,
    multipleCommissions,
  } = props;
  switch (dealLifeCycleStage) {
    case 'prospect': {
      let renderSQFT;
      let sqftRange;
      if (dealType !== repRole) {
        if (repRole === 'seller') {
          const formattedSQFT = property.aggregate_sqft && FormatNumberWithCommas(property.aggregate_sqft);
          renderSQFT = (
            <StackedLabeledItem
              label="CURRENT SQFT"
              value={formattedSQFT}
            />
          );
        } else {
          const { min, max } = (requirements && requirements.sqft) || {};
          const minFormatted = min && FormatNumberWithCommas(min);
          const maxFormatted = max && FormatNumberWithCommas(max);
          if (minFormatted && maxFormatted) {
            sqftRange = `${minFormatted} - ${maxFormatted}`;
          } else if (minFormatted) {
            sqftRange = `${minFormatted}+`;
          } else if (maxFormatted) {
            sqftRange = `0 - ${maxFormatted}`;
          } else {
            sqftRange = '--';
          }
          renderSQFT = (
            <StackedLabeledItem
              label="SQFT RANGE"
              value={sqftRange}
            />
          );
        }
      }
      return (
        <div className="deal-card-financials-right">
          {renderSQFT}
          <label className="deal-card-label">Probability</label>
          <Slider
            formatLabel={v => `${v}%`}
            minValue={0}
            maxValue={100}
            onChange={onConversionChange}
            step={5}
            value={conversionPotential}
          />
        </div>
      );
    }
    case 'executing': {
      return (
        <div className="deal-card-financials-right">
          <StackedLabeledItem
            label="ESTIMATED COMMISSION"
            value={FormatAsCurrency(estimatedCommission)}
          />
          <label className="deal-card-label">Probability</label>
          <Slider
            formatLabel={v => `${v}%`}
            minValue={0}
            maxValue={100}
            onChange={onConversionChange}
            step={5}
            value={conversionPotential}
          />
        </div>
      );
    }
    case 'closed': {
      const leaseExpiration = dateLeaseTo && moment(dateLeaseTo).format('LL');
      const formattedClosedDate = closeDate && moment(closeDate).format('LL');
      const formattedSalePrice = salePrice && FormatAsCurrency(salePrice);
      const formattedGrossCommission = grossCommission && FormatAsCurrency(grossCommission);
      const formattedSQFT = finalSQFT && FormatNumberWithCommas(finalSQFT);

      return (
        <div className="deal-card-financials-right">
          {dealType === 'lease' &&
            <Fragment>
              <StackedLabeledItem
                label="FINAL SQFT"
                value={formattedSQFT}
              />
              <StackedLabeledItem
                label="LEASE EXPIRATION"
                value={leaseExpiration}
              />
            </Fragment>
          }
          {dealType === 'sale' &&
          <>
            <StackedLabeledItem
              label="FINAL SQFT"
              value={formattedSQFT}
            />
            <StackedLabeledItem
              label="SALE PRICE"
              value={formattedSalePrice}
            />
          </>
          }
          <StackedLabeledItem
            label="GROSS COMMISSION"
            value={multipleCommissions ?
              <Link
                className="see-more-link"
                to={`/deals/detail/${id}/commissions`}
              >
                View multiple commissions
              </Link> :
                formattedGrossCommission}
          />
          <StackedLabeledItem
            label="CLOSE DATE"
            value={formattedClosedDate}
          />
        </div >
      );
    }
    default: {
      return null;
    }
  }
};

DealCardFinancialsRight.propTypes = {
  id: PropTypes.number.isRequired,
  closeDate: PropTypes.string,
  conversionPotential: PropTypes.number.isRequired,
  dateLeaseTo: PropTypes.string,
  dealLifeCycleStage: PropTypes.string.isRequired,
  dealType: PropTypes.string.isRequired,
  estimatedCommission: PropTypes.number,
  finalSQFT: PropTypes.number,
  grossCommission: PropTypes.number,
  onConversionChange: PropTypes.func.isRequired,
  property: PropTypes.shape({}),
  repRole: PropTypes.string.isRequired,
  requirements: PropTypes.shape({}),
  salePrice: PropTypes.number,
  multipleCommissions: PropTypes.bool,
};

DealCardFinancialsRight.defaultProps = {
  closeDate: null,
  dateLeaseTo: null,
  estimatedCommission: null,
  finalSQFT: null,
  grossCommission: null,
  property: {
    building: {},
  },
  requirements: { sqft: null },
  salePrice: null,
  multipleCommissions: false,
};

export default DealCardFinancialsRight;
