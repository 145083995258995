import { connect } from 'react-redux';
import { toast } from '../../redux/actions/toasts-actions';
import AdminLinking from './admin-linking';

const mapStateToProps = state => ({
  userData: (state.userData.data && state.userData.data.id) ? state.userData.data : {},
  proxy: state.proxy.proxySettings,
});

const AdminLinkingContainer = connect(
  mapStateToProps,
  { toast },
)(AdminLinking);

export default AdminLinkingContainer;
