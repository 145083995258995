import React from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';
import { Button, Icon } from '@cbrebuild/blocks';

import FilterTag from '../../nucleus/legacy/tag/filter-tag';
import SearchInput from '../../nucleus/search/search-input';
import ListSort from '../../nucleus/list-sort/list-sort';
import userEventService from '../../services/user-event-service';

class VoucherSearchActionRow extends React.Component {
  handleCompanySearchFocus = () => {
    const eventMetadata = {
      eventCategory: 'Voucher Search',
      eventLabel: 'onFocus',
      eventAction: 'company_search_focus',
    };

    userEventService.trackEvent(eventMetadata);
  }

  handleLocationSearchFocus = () => {
    const eventMetadata = {
      eventCategory: 'Voucher Search',
      eventLabel: 'onFocus',
      eventAction: 'location_search_focus',
    };

    userEventService.trackEvent(eventMetadata);
  }

  render() {
    const {
      searchParams,
      handleSearchTermChange,
      handleSortingKeyChange,
      handleClearFilter,
      sortingKeys,
      filterTags,
      openAdvancedSearchModal,
    } = this.props;

    const currentSortingKey = window.localStorage.getItem('voucher-search-sort') || searchParams.ordering;
    const renderListSort = !!sortingKeys.length && <ListSort
      currentSortingKey={currentSortingKey}
      options={sortingKeys}
      onChange={handleSortingKeyChange}
    />;

    const renderFilterTags = () => {
      // remove tags with no values from list
      this.validFilterTags =
        filter((filterTags), tag =>
          tag.value || tag.minValue || tag.maxValue);
      return map(
        this.validFilterTags,
        tag => (<FilterTag
          isButton
          key={uniqueId()}
          handleClearFilter={() => handleClearFilter(tag.key)}
          label={tag.label}
          value={tag.value}
          minValue={tag.minValue}
          maxValue={tag.maxValue}
        />),
      );
    };

    return (
      <div id="voucher-search-action-row">
        <div className="primary-controls">
          <Icon iconName="filter" />
          <div className="control-group">
            <SearchInput
              handleSearchTermChange={handleSearchTermChange}
              searchKey="company"
              initValue={this.props.searchParams.company}
              handleSearchFocus={this.handleCompanySearchFocus}
            />
            <SearchInput
              minSearchLength={2}
              handleSearchTermChange={handleSearchTermChange}
              searchKey="location"
              initValue={this.props.searchParams.location}
              handleSearchFocus={this.handleLocationSearchFocus}
            />
            <Button variant="text" onClick={openAdvancedSearchModal}>Advanced Search</Button>
          </div>
        </div>
        <div className="secondary-controls">
          {renderListSort}
        </div>
        <div className="filter-tags">
          {renderFilterTags()}
        </div>
      </div>
    );
  }
}

VoucherSearchActionRow.propTypes = {
  searchParams: PropTypes.shape({
    page: PropTypes.number,
    company: PropTypes.string,
    location: PropTypes.string,
    ordering: PropTypes.string,
  }).isRequired,
  sortingKeys: PropTypes.arrayOf(PropTypes.shape({
    displayName: PropTypes.string,
    ascSortingKey: PropTypes.string,
    descSortingKey: PropTypes.string,
  })),
  filterTags: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.shape({
      label: PropTypes.string,
      minValue: PropTypes.string,
      maxValue: PropTypes.string,
    }),
  ])),
  handleSearchTermChange: PropTypes.func.isRequired,
  handleClearFilter: PropTypes.func,
  handleSortingKeyChange: PropTypes.func,
  openAdvancedSearchModal: PropTypes.func,
};

VoucherSearchActionRow.defaultProps = {
  handleClearFilter: () => {},
  handleSortingKeyChange: () => {},
  openAdvancedSearchModal: () => { },
  sortingKeys: [],
  filterTags: [],
};

export default VoucherSearchActionRow;
