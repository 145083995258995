import React, { useEffect, useState } from 'react';
import { Checkbox } from '@cbrebuild/blocks';
import PropTypes from 'prop-types';
import CompanyForm from '../CompanyForm';
import useCompanyState from '../../../hooks/useCompanyState';
import VoucherContactForm from '../../voucher/contacts/voucher-contact-form';
import { getAndSetLastPageVisited } from '../voucher-validation';

// Note: This component represents the Landlord and Seller information tabs.

const LandLordInformation = ({
  deal: {
    deal_type,
    is_dual_rep_deal,
    rep_role,
  } = {},
  voucher,
  deal = {},
  updateDeal,
  addDealContact,
  updateDealContact,
  deleteDealContact,
  fetchDealContacts,
  contactInfo,
}) => {
  const partyType = (rep_role === 'landlord' || rep_role === 'seller') ? 'client' : 'counter_party';
  const partyName = deal_type === 'lease' ? 'Landlord' : 'Seller';

  const {
    company, onCompanyBlur, onCompanyChange,
  } = useCompanyState(deal[partyType] || undefined, partyType, update => updateDeal(deal.id, update));
  const [showValidation, setShowValidation] = useState(false);

  const validatePage = () => {
    const currentPageNumber = 2.3;
    const lastPageVisited = getAndSetLastPageVisited(voucher.id, currentPageNumber);

    if (lastPageVisited >= currentPageNumber) {
      setShowValidation(true);
    }
  };

  useEffect(() => {
    validatePage();
  }, []);

  return (
    <div>
      <h2>Add {partyName} Information</h2>
      <legend>{partyName} Information</legend>
      <CompanyForm
        id={company.id}
        infoType={rep_role}
        partyType={partyType}
        address={company.address}
        address2={company.address2}
        city={company.city}
        country={company.country}
        postal={company.postal}
        territory={company.territory}
        gws_client={company.gws_client}
        credit_rating={company.credit_rating}
        parent_company={company.parent_company}
        voucher_naics_category={company.voucher_naics_category}
        name={company.name}
        voucher_purchaser_type={company.voucher_purchaser_type}
        voucher_investor_type={company.voucher_investor_type}
        onChange={onCompanyChange}
        onBlur={onCompanyBlur}
        showValidation={showValidation}
      />
      <div className="field-group">
        <Checkbox
          checked={partyType === 'client' || is_dual_rep_deal}
          disabled
        >Is {partyName} our client?
        </Checkbox>
      </div>
      <VoucherContactForm
        dealId={deal.id}
        contactInfo={contactInfo}
        updateDeal={updateDeal}
        addDealContact={addDealContact}
        fetchDealContacts={fetchDealContacts}
        updateDealContact={updateDealContact}
        deleteDealContact={deleteDealContact}
        showValidation={showValidation && partyType === 'client'}
        contactType={deal_type === 'lease' ? 'landlord' : 'seller'}
      />
    </div>
  );
};

LandLordInformation.propTypes = {
  deal: PropTypes.shape({
    client: PropTypes.shape({
      id: PropTypes.number,
    }),
    counter_party: PropTypes.shape({
      id: PropTypes.number,
    }),
    deal_type: PropTypes.string,
    is_dual_rep_deal: PropTypes.bool,
    rep_role: PropTypes.string,
  }),
  voucher: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  updateDeal: PropTypes.func.isRequired,
  addDealContact: PropTypes.func.isRequired,
  updateDealContact: PropTypes.func.isRequired,
  deleteDealContact: PropTypes.func.isRequired,
  fetchDealContacts: PropTypes.func.isRequired,
  contactInfo: PropTypes.arrayOf(PropTypes.any),
};

LandLordInformation.defaultProps = {
  deal: undefined,
  contactInfo: {},
};

export default LandLordInformation;
