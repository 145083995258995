import * as commissionsService from '../../services/voucher/commissions-service';
import {
  FETCH_REBATES_SUCCESS,
  UPDATE_REBATE_SUCCESS,
  CREATE_REBATE_SUCCESS,
  DELETE_REBATE_SUCCESS,
} from './action-types';
import { updateFinancials } from './financial-actions';

// action creators
const fetchRebatesSuccess = rebates => ({
  type: FETCH_REBATES_SUCCESS,
  payload: rebates,
});

const updateRebateSuccess = rebate => ({
  type: UPDATE_REBATE_SUCCESS,
  payload: rebate,
});

const createRebateSuccess = rebate => ({
  type: CREATE_REBATE_SUCCESS,
  payload: rebate,
});

const deleteRebateSuccess = id => ({
  type: DELETE_REBATE_SUCCESS,
  payload: id,
});

// async action creators
const fetchRebates = params => dispatch => commissionsService.fetchCommissions(params)
  .then(rebates => dispatch(fetchRebatesSuccess(rebates)))
  .catch(console.error);

const updateRebate = (id, data, ignoreFinancialUpdate = false) => dispatch => commissionsService.updateCommission(id, data)
  .then((rebate) => {
    if ('gross_commission' in data && !ignoreFinancialUpdate) {
      dispatch(updateFinancials(rebate.transaction));
    }
    dispatch(updateRebateSuccess(rebate));
  })
  .catch(console.error);

const createRebate = params => dispatch => commissionsService.createCommission(params)
  .then(rebate => dispatch(createRebateSuccess(rebate)))
  .catch(console.error);

const deleteRebate = id => (dispatch, getState) => commissionsService.deleteCommission(id)
  .then(() => {
    dispatch(updateFinancials(getState().deal.deal.id));
    dispatch(deleteRebateSuccess(id));
  })
  .catch(console.error);

export {
  fetchRebates,
  updateRebate,
  createRebate,
  deleteRebate,
};
