import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';
import superFetch from '../utils/super-fetch';

// Traunch Details = User Commissions
const fetchTraunchDetails = (params) => {
  // unset(params, 'sort_by');
  const url = '/traunchdetails';

  // need to account for legacy payment_status that was a string, it's not an array
  const paymentStatus = params?.payment_status;
  let updatedParams = { ...params };
  let needReload = false;
  if (paymentStatus && typeof paymentStatus === 'string') {
    updatedParams = {
      ...params,
      payment_status: [paymentStatus],
    };
    window.localStorage.setItem('myPaymentsParams', JSON.stringify(updatedParams));
    needReload = true;
  }
  const options = {
    method: 'GET',
    params: updatedParams,
  };

  return superFetch(url, options)
    .then((data) => {
      if (needReload) window.location.reload(true);
      return data.json();
    });
};

const fetchTraunchDetailsChart = (params) => {
  const url = '/traunchdetails/chart';
  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json());
};

const getTotal = (totals) => {
  let netTotal = 0;
  let grossTotal = 0;
  totals.forEach((item) => {
    netTotal += item.total_net_amount;
    grossTotal += item.gross_amount;
  });

  return {
    payment_status: 'total',
    total_net_amount: netTotal,
    gross_amount: grossTotal,
  };
};

const translateTotals = (currentYearTotals, prevYearTotals) => {
  const receivablesArray = prevYearTotals.filter(total => total.payment_status === 'Receivable');
  const prevReceivable = receivablesArray.length ? receivablesArray[0] : null;

  const getEmptyTotal = status => ({
    gross_amount: 0,
    payment_status: status,
    total_all: 0,
    total_net_amount: 0,
  });

  const paidTotalFromResponse = currentYearTotals.find(total => total.payment_status === 'Paid') || getEmptyTotal('Paid');
  const receivableTotalFromResponse = currentYearTotals.find(total => total.payment_status === 'Receivable') || getEmptyTotal('Receivable');
  const upcommingTotalFromResponse = currentYearTotals.find(total => total.payment_status === 'Upcoming') || getEmptyTotal('Upcoming');
  const totalOfTotals = getTotal(currentYearTotals);

  if (prevReceivable) {
    receivableTotalFromResponse.gross_amount += prevReceivable.gross_amount;
    receivableTotalFromResponse.total_net_amount += prevReceivable.total_net_amount;
    totalOfTotals.gross_amount += prevReceivable.gross_amount;
    totalOfTotals.total_net_amount += prevReceivable.total_net_amount;
  }

  return [paidTotalFromResponse, receivableTotalFromResponse, upcommingTotalFromResponse, totalOfTotals];
};

const exportTraunchDetailsMyPayments = (params) => {
  const paramsClone = cloneDeep(params);
  unset(paramsClone, 'page');
  unset(paramsClone, 'page_size');
  unset(paramsClone, 'ordering');

  const url = '/traunchdetails/my_payments/export';
  const options = {
    method: 'GET',
    params: paramsClone,
    responseType: 'arraybuffer',
  };

  return superFetch(url, options)
    .then(data => data.json());
};

export default {
  fetchTraunchDetails,
  fetchTraunchDetailsChart,
  exportTraunchDetailsMyPayments,
  getTotal,
  translateTotals,
};
