/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
import { ToggleSwitch } from '@cbrebuild/blocks';

import StatusStrings from '../../utils/status-strings';

import Slider from '../../nucleus/slider/slider';
import ButtonSegmentedGroup from '../../nucleus/button-segmented-group/button-segmented-group';
import NumberInput from '../../nucleus/inputs/number-input';
import Datepicker from '../../nucleus/datepicker/datepicker';
import TagSearch from '../../nucleus/legacy/tag/tag-search';
import FilterTag from '../../nucleus/legacy/tag/filter-tag';
import FilterMenu from '../../nucleus/filter-menu/FilterMenu';

const PipelineFilters = (props) => {
  const {
    allDealTags,
    currentParams,
    filterByPipeline,
    filterByStage,
    filterByCommission,
    filterByProbability,
    filterByInstallmentDate,
    filterByDealTypeRepRole,
    filterByTag,
    filterByUntagged,
    onFilterOpen,
    onFilterClose,
  } = props;

  const dealStageOptions = StatusStrings.dealStage;
  const dealTypeAndRepRoleOptions = StatusStrings.dealTypeAndRepRole;
  const selectedDealStages = dealStageOptions.filter(o =>
    currentParams.deal_life_cycle_stage && currentParams.deal_life_cycle_stage.includes(o.value));

  const selectedDealTypeAndRepRole = dealTypeAndRepRoleOptions.filter(o =>
    currentParams.rep_role && currentParams.rep_role.includes(o.value));

  return (
    <FilterMenu
      actionIcon="filter"
      buttonText="Filter"
      buttonIcon="chevron-down"
      position="left"
      onOpen={onFilterOpen}
      onClose={onFilterClose}
    >
      <div className="pipeline-filters">
        <div className="filter">
          <ToggleSwitch
            checked={!!currentParams.is_on_pipeline}
            onChange={filterByPipeline}
          >
            Pipeline Deals Only
          </ToggleSwitch>
        </div>
        <div className="filter">
          <div className="filter-label">Deal Type</div>
          <ButtonSegmentedGroup
            isMultiSelect
            isMultiSelectGrid
            onChange={filterByDealTypeRepRole}
            options={dealTypeAndRepRoleOptions}
            selected={selectedDealTypeAndRepRole}
          />
        </div>
        <div className="filter">
          <div className="filter-label">Deal Stage</div>
          <ButtonSegmentedGroup
            isMultiSelect
            onChange={filterByStage}
            options={dealStageOptions}
            selected={selectedDealStages}
          />
        </div>
        <div className="filter">
          <div className="filter-label">Probability</div>
          <Slider
            formatLabel={v => `${v}%`}
            minValue={0}
            maxValue={100}
            onChange={filterByProbability}
            step={5}
            value={{
              min: currentParams.conversion_potential_min || 0,
              max: currentParams.conversion_potential_max || 100,
            }}
          />
        </div>
        <div className="filter">
          <div className="filter-label">Gross Commission Range ($)</div>
          <div className="filter-range-container">
            <NumberInput
              placeholder="Min"
              value={currentParams.estimated_commission_min}
              updateValue={value => filterByCommission(value, 'estimated_commission_min')}
            />
            <span className="range-divider">-</span>
            <NumberInput
              placeholder="Max"
              value={currentParams.estimated_commission_max}
              updateValue={value => filterByCommission(value, 'estimated_commission_max')}
            />
          </div>
        </div>
        <div className="filter">
          <div className="filter-label">Installment Date</div>
          <div className="filter-range-container">
            <div>
              <label>Start</label>
              <Datepicker
                date={currentParams.installment_date_range_after}
                onDateChange={date => filterByInstallmentDate(date, 'installment_date_range_after')}
              />
            </div>
            <span className="range-divider">-</span>
            <div>
              <label>End</label>
              <Datepicker
                date={currentParams.installment_date_range_before}
                onDateChange={date => filterByInstallmentDate(date, 'installment_date_range_before')}
              />
            </div>
          </div>
        </div>
        <div className="filter">
          <div className="filter-label tags-filter-label">
            Tags
            <ToggleSwitch
              checked={!!currentParams.is_untagged_deal}
              onChange={filterByUntagged}
            >
              Untagged Deals Only
            </ToggleSwitch>
          </div>
          <TagSearch
            onSelect={filterByTag}
            tags={allDealTags}
            selectedTags={currentParams.tags || []}
            disabled={currentParams.is_untagged_deal}
          />
        </div>
        <div className="filter">
          {(currentParams.tags || []).map(tag => (
            <FilterTag
              isButton
              key={tag}
              handleClearFilter={() => filterByTag(tag, true)}
              value={tag}
            />
          ))}
        </div>
      </div>
    </FilterMenu>
  );
};

PipelineFilters.propTypes = {
  allDealTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentParams: PropTypes.shape({
    conversion_potential_min: PropTypes.number,
    conversion_potential_max: PropTypes.number,
    deal_life_cycle_stage: PropTypes.string,
    estimated_commission_min: PropTypes.number,
    estimated_commission_max: PropTypes.number,
    installment_date_range_after: PropTypes.string,
    installment_date_range_before: PropTypes.string,
    is_on_pipeline: PropTypes.bool,
    is_untagged_deal: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.string),
    rep_role: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  filterByPipeline: PropTypes.func.isRequired,
  filterByStage: PropTypes.func.isRequired,
  filterByCommission: PropTypes.func.isRequired,
  filterByProbability: PropTypes.func.isRequired,
  filterByInstallmentDate: PropTypes.func.isRequired,
  filterByDealTypeRepRole: PropTypes.func.isRequired,
  filterByTag: PropTypes.func.isRequired,
  filterByUntagged: PropTypes.func.isRequired,
  onFilterOpen: PropTypes.func.isRequired,
  onFilterClose: PropTypes.func.isRequired,
};

export default PipelineFilters;
