import {
  LOAD_USER_OPTIONS_SUCCESS,
  IS_LOADING_USER_OPTIONS,
  LOAD_USER_OPTIONS_FAILURE,
  UPDATE_USER_OPTIONS_SUCCESS,
  UPDATE_USER_OPTIONS_FAILURE,
  IS_UPDATING_USER_OPTIONS,
} from '../actions/action-types';

const initialState = {};

// User Options Reducers
const loadUserOptionsSuccess = (state, action) => {
  const updatedState = {
    ...state,
    userOptions: action.payload.userOptions,
  };
  return updatedState;
};

const loadUserOptionsFailure = (state, action) => {
  const updatedState = {
    ...state,
    error: action.payload.error,
  };
  return updatedState;
};

const isLoadingUserOptions = (state, action) => {
  const updatedState = {
    ...state,
    isLoadingOptions: action.payload.isLoadingOptions,
  };
  return updatedState;
};

const updateUserOptionsSuccess = (state, action) => {
  const updatedState = {
    ...state,
    userOptions: action.payload.userOptions,
  };
  return updatedState;
};

const updateUserOptionsFailure = (state, action) => {
  const updatedState = {
    ...state,
    error: action.payload.error,
  };
  return updatedState;
};

const isUpdatingUserOptions = (state, action) => {
  const updatedState = {
    ...state,
    isUpdatingUserOptions: action.payload.isUpdatingUserOptions,
  };
  return updatedState;
};

const userOptions = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_OPTIONS_SUCCESS:
      return loadUserOptionsSuccess(state, action);
    case LOAD_USER_OPTIONS_FAILURE:
      return loadUserOptionsFailure(state, action);
    case IS_LOADING_USER_OPTIONS:
      return isLoadingUserOptions(state, action);
    case UPDATE_USER_OPTIONS_SUCCESS:
      return updateUserOptionsSuccess(state, action);
    case UPDATE_USER_OPTIONS_FAILURE:
      return updateUserOptionsFailure(state, action);
    case IS_UPDATING_USER_OPTIONS:
      return isUpdatingUserOptions(state, action);
    default:
      return state;
  }
};

export default userOptions;
