/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const MAX_DURATION = 1.5;
const MIN_DURATION = 0.3;
const DIVIDER = 1000;

const ExpandableComponent = (props) => {
  const expandableRef = React.createRef();
  const contentRef = React.createRef();
  // sets duration based on the height of the content
  const getDuration = (height) => {
    const duration = height / DIVIDER;
    if (duration > MAX_DURATION) { return MAX_DURATION; }
    if (duration < MIN_DURATION) { return MIN_DURATION; }
    return duration;
  };

  const collapseSection = (expandable) => {
    const { style } = expandable;
    if (!style.height) {
      return;
    }
    const sectionHeight = expandable.scrollHeight;
    const elementTransition = expandable.style.transition;
    style.transition = '';
    requestAnimationFrame(() => {
      style.height = `${sectionHeight}px`;
      style.transition = elementTransition;
      style.transitionDuration = `${getDuration(sectionHeight)}s`;
      requestAnimationFrame(() => {
        style.height = '0px';
      });
    });
  };

  const expandSection = (expandable, content) => {
    const { style } = expandable;
    // sometimes depending on the styles of the children a height offset can be used to smooth out the transition
    const { heightOffset } = props;
    const sectionHeight = content.scrollHeight || 0;
    style.height = `${sectionHeight + heightOffset}px`;
    style.transitionDuration = `${getDuration(sectionHeight)}s`;
    const onComplete = () => {
      if (style.height !== '0px') {
        style.height = 'auto';
        expandable.removeEventListener('transitionend', onComplete);
      }
    };
    expandable.addEventListener('transitionend', onComplete);
  };

  useEffect(() => {
    if (!expandableRef.current) {
      return;
    }
    if (!props.isOpen) {
      collapseSection(expandableRef.current);
    } else {
      expandSection(expandableRef.current, contentRef.current);
    }
  }, [props.isOpen]);

  return (
    <div className={`nd-expandable-component ${props.additionalClassName}`} ref={expandableRef}>
      <div className="content" ref={contentRef}>
        {props.children}
      </div>
    </div>
  );
};

ExpandableComponent.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  additionalClassName: PropTypes.string,
  heightOffset: PropTypes.number,
};

ExpandableComponent.defaultProps = {
  children: null,
  additionalClassName: '',
  heightOffset: 0,
};

export default ExpandableComponent;
