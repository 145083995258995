import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

import { getObjectDifferences } from '../utils/object-utilities';
import userEventService from './user-event-service';

let prevFilters;
let prevFiltersResultCount;
let prevSearchTerm;
let prevSearchResultCount;

const trackChanges = (analytics, prefix, category, label) => {
  const {
    beforeParams,
    afterParams,
    beforeCount,
    afterCount,
  } = analytics;

  const diff = getObjectDifferences(beforeParams, afterParams);
  if (isEmpty(diff)) {
    return;
  }

  // form event data
  const eventAction = prefix + (afterCount ? 'success' : 'null_results');
  const eventMetadata = {
    eventCategory: category,
    eventLabel: label,
    eventAction,
    eventData: {
      before_results: beforeCount,
      after_results: afterCount,
      filter_diff: diff,
    },
  };
  userEventService.trackEvent(eventMetadata);
};

const trackSearch = (searchTerm, resultCount) => {
  if (prevSearchTerm) {
    const analytics = {
      beforeParams: prevSearchTerm,
      beforeCount: prevSearchResultCount,
      afterParams: searchTerm,
      afterCount: resultCount,
    };
    const prefix = 'pipeline_report_search_';
    const category = 'Pipeline Report Search';
    const label = 'on_search';
    trackChanges(analytics, prefix, category, label);
  }

  prevSearchTerm = searchTerm;
  prevSearchResultCount = resultCount;
};

const trackFilters = (prefix, category, label, filters, resultCount, isFilterClosed) => {
  // per a conversation with Quentin and Rob, we only want to track event
  // when the filter dropdown is being closed
  if (isFilterClosed && prevFilters) {
    const analytics = {
      beforeParams: prevFilters,
      beforeCount: prevFiltersResultCount,
      afterParams: filters,
      afterCount: resultCount,
    };
    trackChanges(analytics, prefix, category, label);
  } else {
    prevFilters = cloneDeep(filters);
    prevFiltersResultCount = resultCount;
  }
};

const trackActions = (data) => {
  const analyticProperties = {
    actionPrefix: 'pipeline_report',
    categoryPrefix: 'Pipeline Report ',
  };

  userEventService.trackEvent(data, analyticProperties);
};

export default {
  trackFilters,
  trackSearch,
  trackActions,
};
