import { connect } from 'react-redux';
import {
  loadNotifications,
  markNotificationsAsRead,
  dismissNotification,
  dismissAllNotification,
  snooze,
  snoozeAndLoadNextNotification,
  dismissAndLoadNextNotification,
  clearSnoozeMessage,
  clearDismissMessage,
} from '../actions/alerts-actions';

import { fetchTeam } from '../../redux/actions/teams-actions';

import NotificationsAlerts from '../../components/notifications/notifications-alerts';

const mapStateToProps = state => ({
  notifications: state.alerts.allNotificationIDs ?
    state.alerts.allNotificationIDs.map(id => state.alerts.notificationsByID[id])
    : [],
  isLoadingNotifications: state.alerts.isLoadingNotifications,
  notificationsError: state.alerts.notificationsError,
  notificationsMetadata: state.alerts.notificationsMetadata,
  userData: state.userData.data,
  teams: state.teamsPage.teams ? state.teamsPage.teams : {},
});

const mapDispatchToProps = dispatch => ({
  setNotificationsState: params => dispatch(loadNotifications(params)),
  setMarkNotificationsAsRead: alertIDs =>
    dispatch(markNotificationsAsRead(alertIDs)),
  snoozeNotification: (alertID, alertDatetime, snoozeMessage) =>
    dispatch(snooze(alertID, alertDatetime, snoozeMessage)),
  snoozeAndLoadNextNotification: (alertID, alertDatetime, snoozeMessage, params) =>
    dispatch(snoozeAndLoadNextNotification(alertID, alertDatetime, snoozeMessage, params)),
  clearSnoozeMessage: () => dispatch(clearSnoozeMessage()),
  dismissNotification: alertID => dispatch(dismissNotification(alertID)),
  dismissAllNotification: () => dispatch(dismissAllNotification()),
  dismissAndLoadNextNotification: (alertID, params) =>
    dispatch(dismissAndLoadNextNotification(alertID, params)),
  clearDismissMessage: () => dispatch(clearDismissMessage()),
  fetchTeamById: id => dispatch(fetchTeam(id)),
});

const NotificationsAlertsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsAlerts);

export default NotificationsAlertsContainer;
