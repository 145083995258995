import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, RadioButton, Icon, Textarea } from '@cbrebuild/blocks';
import { useDispatch, useSelector } from 'react-redux';
import VoucherComments from '../voucher/VoucherComments';
import VoucherFilesContainer from './files/voucher-files-container';
import { updateDeal } from '../../redux/actions/deal-actions';
import { fetchOfficeTypes, updateVoucher } from '../../redux/actions/voucher/voucher-actions';
import useVoucherValidation from './useVoucherValidation';
import BottomNav from './BottomNav';
import useFormState from '../../hooks/useFormState';
import Tooltip from '../../nucleus/tooltip/tooltip';
import Select from '../../nucleus/select/select';

const sourceByOptions = [
  { value: 'Office', display_name: 'Office' },
  { value: 'Int Office', display_name: 'Int Office' },
  { value: 'Affiliate', display_name: 'Affiliate' },
  { value: 'GCS', display_name: 'GCS' },
];

const PAGE_NUMBER = 1.1;

const GettingStarted = ({ location }) => {
  const { deal, voucher, offices } = useSelector(state => ({
    deal: state.deal.deal,
    voucher: state.voucher.voucher,
    offices: state.voucher.officeTypes.map(o => ({
      display_name: o.managing_office_name,
      value: o.managing_office_name,
    })),
  }));
  const dispatch = useDispatch();
  const {
    is_referral_deal, is_confidential, is_dual_rep_deal, voucher_lead_office, sourced_by, voucher_office_name,
    onStateChange: onDealChange, onStateChanged: onDealChanged,
  } = useFormState({
    is_referral_deal: deal.is_referral_deal,
    is_confidential: deal.is_confidential,
    is_dual_rep_deal: deal.is_dual_rep_deal,
    sourced_by: deal.sourced_by,
    voucher_lead_office: deal.voucher_lead_office || '',
    voucher_office_name: deal.voucher_office_name || '',
    reason_for_early_invoice: deal.reason_for_early_invoice || '',
  }, (update) => { dispatch(updateDeal(deal.id, update)); });

  const {
    reason_for_early_invoice, onStateChange: onVoucherChange, onStateChanged: onVoucherChanged,
  } = useFormState({
    reason_for_early_invoice: voucher.reason_for_early_invoice,
  }, update => dispatch(updateVoucher(voucher.id, update)));

  // uses the useValidation hook to see if this page should show all validation
  const { showPageValidation } = useVoucherValidation(voucher.id, PAGE_NUMBER);

  // fetches offices on load for lead office dropdown
  useEffect(() => {
    dispatch(fetchOfficeTypes());
  }, [dispatch]);

  const handleSourceByChange = (event) => {
    if (event.value !== 'Select') {
      dispatch(updateDeal(deal.id, { sourced_by: event.value }));
    }
  };

  const handleLeadOffice = (event) => {
    if (event.value !== 'Select') {
      dispatch(updateDeal(deal.id, { voucher_lead_office: event.value }));
    }
  };

  // memoizes error states as input changes
  const validVoucherOfficeName = useMemo(() => !(voucher_office_name.value === '' || voucher_office_name.value === null), [voucher_office_name]);
  const validReasonForEarlyInvoice = useMemo(() => (
    !(reason_for_early_invoice.value === '' || reason_for_early_invoice.value === null)), [reason_for_early_invoice]);

  // since we use these variable 3 times each below, setting a variable instead
  const officeNameError = (showPageValidation || voucher_office_name.dirty) && !validVoucherOfficeName;
  const reasonForEarlyInvoiceError = (showPageValidation || reason_for_early_invoice.dirty) && !validReasonForEarlyInvoice;
  const welcomeMessage = dealType => (
    dealType === 'lease' || dealType === 'sale' ?
      <>
        <h2>Begin {dealType.charAt(0).toUpperCase() + dealType.slice(1)} Voucher</h2>
        <legend className="begin-voucher">Attach Files to Voucher (Sent along with Voucher)</legend>
        <p>{`Required with this voucher: ${dealType.charAt(0).toUpperCase() + dealType.slice(1)} Agreement and Commission Agreement.`}</p>
      </> :
      <>
        <h2>Begin Miscellaneous Voucher</h2>
        <legend className="begin-voucher">Attach Files to Voucher (Sent along with Voucher)</legend>
        <p>Attach any related documents for this voucher.</p>
      </>
  );

  return (
    <React.Fragment>
      <div className="voucher-content">
        {voucher.is_early_invoice ?
          <span>
            <h2>Request Early Invoice</h2>
            <p>To request an early invoice, complete the Early Invoice Request by entering all required fields.
              Once complete, enter your Business Operations individual/group email address to the recipient list
              and select the Send button. Your Business Operations team will then process your early invoice request.
              For additional information, please reference the
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://cbre.box.com/s/q5ivrwqvt8ky6134ffsjylre9cm09mra"
                className="md-mbBroker-theme"
              > Early Invoice Process Job Aid.
              </a>
            </p>
            <label><span className={`${reasonForEarlyInvoiceError ? 'asterisk-error' : ''}`}>* </span>Reason for requesting an Early Invoice</label>
            <Textarea
              name="reason_for_early_invoice"
              className={`${reasonForEarlyInvoiceError ? 'errorInput ' : ''}invoiceArea`}
              onChange={onVoucherChange}
              value={reason_for_early_invoice.value || ''}
              rows={{ min: 4, max: 130 }}
              onBlur={onVoucherChanged}
            />
            {reasonForEarlyInvoiceError && <p className="asterisk-error errorMsg">Reason for requesting an Early Invoice is required</p>}
            <legend className="begin-voucher">Attach Files to Early Invoice</legend>
            <p>Attach any related documents for this early invoice.</p>
          </span>
          : welcomeMessage(deal.deal_type)
        }
        <p>There is a limit on size of single files to <strong>5GB.</strong></p>
        <div className="voucher-file-upload-content">
          <VoucherFilesContainer voucher={voucher} dealId={deal.id} />
        </div>
        <div>
          <legend>Details</legend>
          <legend className="secondary-legend">Is this deal Confidential?</legend>
          <RadioGroup
            name="is_confidential"
            selectedValue={`${is_confidential.value}`}
            className="radioVal"
            onChange={onDealChanged}
            orientation="vertical"
          >
            <RadioButton value="false">No</RadioButton>
            <RadioButton value="true">Yes - Do not send to Voucher Search or VOL.</RadioButton>
          </RadioGroup>
          <legend className="secondary-legend">Is this a Referral Deal?</legend>
          <RadioGroup
            name="is_referral_deal"
            className="radioVal"
            selectedValue={`${is_referral_deal.value}`}
            onChange={onDealChanged}
            orientation="vertical"
          >
            <RadioButton value="false">No</RadioButton>
            <RadioButton value="true">Yes</RadioButton>
          </RadioGroup>
          <legend className="secondary-legend">
            Rep Role: <strong>{deal.deal_type}</strong>
          </legend>
          <div className="radioVal">Did CBRE represent both sides of this deal?
            <Tooltip message="
              These are only deals where CBRE represented both sides of the deal. This indicates to Accounting whether to expect two vouchers or not."
            >
              <Icon iconName="info-circle" />
            </Tooltip>
          </div>
          <RadioGroup
            name="is_dual_rep_deal"
            className="radioval"
            selectedValue={`${is_dual_rep_deal.value}`}
            onChange={onDealChanged}
            orientation="vertical"
          >
            <RadioButton value="false">No</RadioButton>
            <RadioButton value="true">Yes</RadioButton>
          </RadioGroup>
          <div className="field drop-downs">
            <label>
              <span className={(voucher_lead_office.value === '' || voucher_lead_office.value === 'Select') ?
              'asterisk-error' : ''}
              >*
              </span>Lead Office
            </label>
            <Select
              className={(voucher_lead_office.value === '' || voucher_lead_office.value === 'Select') ? 'errorInput' : ''}
              defaultOption={voucher_lead_office.value || 'Select'}
              options={offices.filter(opt => opt.value !== '')}
              onChange={handleLeadOffice}
            />
            { (voucher_lead_office.value === '' || voucher_lead_office.value === 'Select') &&
            <p className="asterisk-error error-text">Lead Office is required</p>}
          </div>
          <div className="field drop-downs">
            <label>
              Sourced By
            </label>
            <Select
              defaultOption={sourced_by.value || 'Select'}
              options={sourceByOptions.filter(opt => opt.value !== '')}
              onChange={handleSourceByChange}
            />
          </div>
          <div className="field">
            <label><span className={`${officeNameError ? 'asterisk-error' : ''}`}>* </span> Office Name</label>
            <input
              type="text"
              name="voucher_office_name"
              className={`${officeNameError ? 'errorInput' : ''}`}
              value={voucher_office_name.value}
              onChange={onDealChange}
              onBlur={onDealChanged}
            />
            {officeNameError ? <p className="asterisk-error">Office Name is required</p> : ''}
          </div>

          <BottomNav location={location.pathname} />
        </div>
      </div>
      <VoucherComments />
    </React.Fragment>
  );
};

GettingStarted.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default GettingStarted;
