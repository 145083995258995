import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../../nucleus/loading-bar/loading-bar';
import AllocationsReview from './AllocationsReview';
import BillToReview from './BillToReview';
import CommissionsReview from './CommissionsReview';
import CompDataReview from './CompDataReview';
import CompanyContactReview from './CompanyContactReview';
import ConsiderationReview from './ConsiderationReview';
import FutureActionsReview from './FutureActionsReview';
import GettingStartedReview from './GettingStartedReview';
import InstallmentsReview from './InstallmentsReview';
import InvestmentDetailsReview from './InvestmentDetailsReview';
import PropertyReview from './PropertyReview';
import { fetchAdditionalCommissions } from '../../../redux/actions/voucher/additional-commissions-actions';
import { fetchCommercialProperty } from '../../../redux/actions/voucher/voucher-actions';
import { fetchCompData } from '../../../redux/actions/voucher/comp-data-actions';
import { fetchConsiderations } from '../../../redux/actions/considerations-actions';
import { fetchFiles } from '../../../redux/actions/deal-files-actions';
import { fetchFinancials } from '../../../redux/actions/financial-actions';
import BannerNotice from '../../../nucleus/notices/banner-notice';

const VoucherInvalid = () => {
  const isVoucherValid = JSON.parse(localStorage.getItem('voucher_valid'));
  return (
    <>
      {!isVoucherValid
        && <BannerNotice iconClassName="warning" mainMessage="Enter all required fields including Comp fields before continuing" />}
    </>
  );
};

const Review = () => {
  const {
    deal, dealType, voucher,
  } = useSelector(state => ({
    deal: state.deal.deal,
    dealType: state.deal.deal?.deal_type,
    voucher: state.voucher.voucher,
  }));

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    localStorage.setItem('voucher_valid', JSON.stringify(true));
  }, []);

  useEffect(() => {
    dispatch(fetchAdditionalCommissions(deal.id));
    dispatch(fetchCommercialProperty(deal.id));
    dispatch(fetchCompData({ deal: deal.id }));
    dispatch(fetchConsiderations(deal.id));
    dispatch(fetchFiles({
      queryParams: {
        ordering: '-modified',
        page: 1,
        page_size: 25,
      },
      deal: deal.id,
    }));
    dispatch(fetchFinancials(deal.id, dealType));
    return setIsLoading(false);
  }, [deal.id, dealType, dispatch]);

  const displayCompanyContactReview = () => {
    switch (dealType) {
      case 'sale':
        return (
          <>
            <CompanyContactReview partyType="buyer" />
            <CompanyContactReview partyType="seller" />
          </>
        );
      case 'lease':
        return (
          <>
            <CompanyContactReview partyType="tenant" />
            <CompanyContactReview partyType="landlord" />
          </>
        );
      case 'consulting':
        return <CompanyContactReview partyType="consulting" />;
      case 'other':
        return <CompanyContactReview partyType="other" />;
      default:
        return '';
    }
  };
  return (
    <div className="review-tab">
      {isLoading
        ? <LoadingBar isLoading />
        : (
          <>
            <VoucherInvalid />
            <h2>Review {voucher.is_early_invoice ? 'Early Invoice Request' : 'Voucher'}</h2>
            <p>Look over information and make sure it&apos;s accurate. Red means that you should take a second look.</p>
            <GettingStartedReview />
            <PropertyReview />
            {dealType === 'sale' && <InvestmentDetailsReview />}
            {displayCompanyContactReview()}
            <ConsiderationReview />
            <AllocationsReview />
            <CommissionsReview />
            <InstallmentsReview />
            <BillToReview />
            <FutureActionsReview />
            {(dealType === 'lease' || dealType === 'sale') && <CompDataReview />}
          </>
        )
      }
    </div>
  );
};

export default Review;
