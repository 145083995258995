import { connect } from 'react-redux';
import { toast } from '../../redux/actions/toasts-actions';
import { addTask } from '../../redux/actions/deal-tasks-actions';
import AutoTask from './auto-task';

const mapStateToProps = state => ({
  deal: state.deal.deal,
  userOptions: state.userOptions.userOptions,
});

const mapDispatchToProps = {
  addTask,
  toast,
};

const AutoTaskContainer = connect(mapStateToProps, mapDispatchToProps)(AutoTask);
export default AutoTaskContainer;
