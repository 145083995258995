import React from 'react';
import PropTypes from 'prop-types';
import TeamIndicator from '../teams/team-indicator';
import Avatar from '../../nucleus/avatar/avatar';

const AllDealMembers = ({
  teams,
  users,
  brokers,
  maxShown,
}) => {
  // combines the users and filters out duplicates
  const allUsers = [...users.filter(u => !brokers.find(b => b.id === u.id)), ...brokers.map(b => ({ ...b, commissioned: true }))];
  // combines them into one array so we can overflow when it's past max size
  const list = [...teams, ...allUsers];
  const hasHidden = list.length > maxShown;
  const cutOff = maxShown - 1;
  const visibleList = list.slice(0, hasHidden ? cutOff : maxShown);
  const hoverList = list.slice(cutOff);

  return (
    <ul className="all-deal-members">
      {visibleList.map(item => (item.users
        ? (
          <li key={item.id}>
            <TeamIndicator
              teamName={item.name}
              teamMembers={item.users}
              teamColor={item.avatar_color}
              avatarBreakPoint={-1}
              showTeamMembersModal={() => { }}
            />
          </li>
        )
        : (
          <li key={item.id}>
            <Avatar
              sizing="md"
              image={item.avatar}
              firstName={item.first_name}
              lastName={item.last_name}
              enableHover
              subIcon={item.commissioned ? 'dollar' : null}
            />
          </li>
        )))}
      {!hasHidden ? undefined : (
        <li className="extra-avatars" key="1">
          <ul>
            <p className="hoverstate nd-avatar md plus-n" data-initials={`+${hoverList.length}`}>
              <span className="hoverstatetext">
                {hoverList.map(({
                  id, first_name, last_name, users: teamUsers, name,
                }) => ((
                  <li key={id}>
                    {teamUsers ? `Team: ${name}` : `${first_name} ${last_name}`}
                  </li>
                )))}
              </span>
            </p>
          </ul>
        </li>
      )}
    </ul>
  );
};

AllDealMembers.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  brokers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  maxShown: PropTypes.number,
};

AllDealMembers.defaultProps = {
  maxShown: 8,
  teams: [],
  users: [],
  brokers: [],
};

export default AllDealMembers;
