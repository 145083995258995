/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import findIndex from 'lodash/findIndex';
import debounce from 'lodash/debounce';

import tracker from '../../services/dashboard-tracking-service';
import tasksService from '../../services/tasks-service';

import Widget from '../../nucleus/widget/widget';
import TaskListPanel from '../deal-details/tasks/task-list-panel';

const TasksWidget = ({ user, toast }) => {
  if (!user || !user.id) { return null; }

  const [isLoading, setIsLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [savedParams, setSavedParams] = useState({});

  const fetchTasks = async (params) => {
    setSavedParams(params);
    setIsLoading(true);
    try {
      const { results } = await tasksService.fetchTasks(params);
      setTasks(results);
    } catch (error) {
      console.log('Error fetching tasks: ', error);
    }
    setIsLoading(false);
  };

  const refreshTasks = (updatedTasks) => {
    const clearedTasks = updatedTasks.filter(t => !t.is_complete);
    setTasks(clearedTasks);
    fetchTasks(savedParams);
  };

  const debouncedRefreshTasks = useCallback(debounce(refreshTasks, 2000), [setTasks, savedParams]); // eslint-disable-line

  const completeTask = async (id, isComplete) => {
    const params = { is_complete: isComplete };
    try {
      const updatedTask = await tasksService.updateTask(id, params);
      const index = findIndex(tasks, t => t.id === id);
      const updatedTasks = [...tasks];
      updatedTasks[index] = updatedTask;
      setTasks(updatedTasks);
      if (isComplete) {
        toast('Task completed');
      }
      debouncedRefreshTasks(updatedTasks);
    } catch (error) {
      console.log('Error completing task: ', error);
      toast('Error completing task');
    }
  };

  return (
    <Widget
      title="Tasks"
      // controls={<Link to="/tasks">See More</Link>}
      className="tasks-widget"
    >
      <div className="tasks-widget-content">
        <TaskListPanel
          user={user}
          fetchTasks={fetchTasks}
          analyticProperties={{
            actionPrefix: 'dashboard_tasks',
            categoryPrefix: 'Dashboard Tasks ',
          }}
          tasks={tasks}
          isLoading={isLoading}
          completeTask={completeTask}
          pageSize={5}
          showDealLink
          showEditIcon={false}
          showHeader={false}
        />
        {!!tasks.length && (
          <Link
            to="/tasks"
            onClick={tracker.trackTasksWidgetLinkToTasks}
          >
            <div className="see-more-link">
              See More
            </div>
          </Link>
        )}
      </div>
    </Widget>
  );
};

TasksWidget.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  toast: PropTypes.func.isRequired,
};

TasksWidget.defaultProps = {
  user: {},
};

export default TasksWidget;
