import { connect } from 'react-redux';
import {
  deleteFile,
  fetchFiles,
  fetchFilesDownloadLink,
  uploadFile,
  updateFile,
} from '../../../redux/actions/deal-files-actions';
import FilesTab from './files-tab';

const mapStateToProps = state => ({
  dealId: state.deal.deal.id,
  files: state.dealFiles.files,
  isLoadingFiles: state.dealFiles.isLoadingFiles,
  next: state.dealFiles.next,
  userData: state.userData.data || {},
});

const mapDispatchToProps = {
  deleteFile,
  fetchFiles,
  fetchFilesDownloadLink,
  uploadFile,
  updateFile,
};

const mergeProps = (state, actions, props) => ({
  ...state,
  ...actions,
  analyticProperties: {
    actionPrefix: `${props.analyticProperties.actionPrefix}_files_tab`,
    categoryPrefix: `${props.analyticProperties.categoryPrefix}Files Tab `,
  },
});

const FileUploadContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(FilesTab);

export default FileUploadContainer;
