import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';
import Avatar from '../../nucleus/avatar/avatar';
import PersonType from './person-type';
import EllipsisTooltip from './ellipsis-tooltip';

const Person = (props) => {
  const {
    person,
    isSelf,
    sizing,
    type,
    emptyStateMessage,
    hoverAlign,
    commissioned,
    isProxy,
    note,
  } = props;

  const {
    outlook_sync_enabled,
    given_name: givenName,
    surname,
    first_name: firstName,
    last_name: lastName,
    avatar: image,
    work_email: workEmail,
    job_title: jobTitle,
    managing_office_name: officeName,
  } = person;

  const emptyValue = '--';
  const company = person.company ? person.company : { name: '' };
  const email = person.email && (person.email.work || typeof person.email === 'string') ? person.email : '';
  const displayedFirstName = givenName || firstName;
  const displayedLastName = surname || lastName;
  const shownEmail = email.work || workEmail || email;

  switch (type) {
    case PersonType.CONTACT: {
      return (
        <div className="person-component">
          <Avatar
            firstName={displayedFirstName}
            lastName={displayedLastName}
            image={image}
            sizing={sizing}
            hoverAlign={hoverAlign}
            enableHover
          />
          <div className="person-textbox">
            <p className="primary-content">
              {displayedFirstName} {displayedLastName} {isSelf ? ' (You)' : ''} {outlook_sync_enabled ? <Icon iconName="outlook" /> : ''}
            </p>
            <p className="secondary-content">{jobTitle} {jobTitle && company.name && '|'} {company.name}</p>
          </div>
        </div>
      );
    }
    case PersonType.CONTACT_AUTOCOMPLETE: {
      return (
        <div className="person-component">
          <Avatar
            firstName={displayedFirstName}
            lastName={displayedLastName}
            image={image}
            sizing={sizing}
            hoverAlign={hoverAlign}
            enableHover
          />
          <div className="person-textbox">
            <p className="primary-content">{displayedFirstName} {displayedLastName} {isSelf ? ' (You)' : ''}</p>
            <p className="secondary-content">{jobTitle} {jobTitle && company.name && '|'} {company.name}</p>
            <p className="secondary-content">{person.email_addresses.length ? person.email_addresses[0].address : ''}</p>
          </div>
          {!person.deal && <Icon iconName="contacts" />}
        </div>
      );
    }
    case PersonType.RECIPIENT: {
      return (
        <div className="person-component">
          <Icon iconName="envelope icon" />
          <div className="person-textbox">
            <p className="primary-content email">{shownEmail || emptyStateMessage} {isSelf ? ' (You)' : ''}</p>
          </div>
        </div>
      );
    }
    case PersonType.MEMBER: {
      return (
        <div className="person-component">
          <Avatar
            firstName={displayedFirstName}
            lastName={displayedLastName}
            image={image}
            sizing={sizing}
            hoverAlign={hoverAlign}
            enableHover
            subIcon={commissioned ? 'dollar' : ''}
          />
          <div className="person-textbox">
            <p className="primary-content">
              {`${displayedFirstName} `}
              {displayedLastName}
              {isSelf ? ' (You)' : ''}
              {!isSelf && isProxy ? ' (as Proxy)' : '' }
            </p>
            <p className="secondary-content email">
              <EllipsisTooltip phrase={shownEmail || emptyValue} />
            </p>
            {note && (
            <p className="secondary-content">
              {note}
            </p>
            )
            }
          </div>
        </div>
      );
    }
    case PersonType.MEMBER_VIEW_ONLY: {
      return (
        <div className="person-component">
          <Avatar
            firstName={displayedFirstName}
            lastName={displayedLastName}
            image={image}
            sizing={sizing}
            hoverAlign={hoverAlign}
            enableHover
            subIcon={commissioned ? 'dollar' : ''}
            isReadOnly
          />
          <div className="person-textbox">
            <p className="primary-content">{displayedFirstName} {displayedLastName} {isSelf ? ' (You)' : ''}</p>
            <p className="secondary-content email">
              <EllipsisTooltip phrase={shownEmail || emptyValue} />
            </p>
          </div>
        </div>
      );
    }
    case PersonType.MEMBER_AUTOCOMPLETE: {
      return (
        <div className="person-component">
          <Avatar
            firstName={displayedFirstName}
            lastName={displayedLastName}
            image={image}
            sizing={sizing}
            hoverAlign={hoverAlign}
            enableHover
          />
          <div className="person-textbox">
            <p className="primary-content">
              {displayedFirstName} {displayedLastName}
              {isSelf ? ' (You)' : ''}
              {displayedFirstName || displayedLastName ? '' : emptyValue}
            </p>
            <p className="secondary-content email">{shownEmail || emptyValue}</p>
            <p className="secondary-content office">{officeName || emptyValue}</p>
          </div>
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

Person.propTypes = {
  person: PropTypes.shape({
    given_name: PropTypes.string,
    surname: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    job_title: PropTypes.string,
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
    email: PropTypes.oneOfType([
      PropTypes.shape({
        work: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    work_email: PropTypes.string,
    avatar: PropTypes.string,
    managing_office_name: PropTypes.string,
  }),
  type: PropTypes.oneOf([PersonType.MEMBER, PersonType.MEMBER_VIEW_ONLY, PersonType.RECIPIENT,
    PersonType.CONTACT, PersonType.CONTACT_AUTOCOMPLETE, PersonType.MEMBER_AUTOCOMPLETE]),
  isSelf: PropTypes.bool,
  hoverAlign: PropTypes.string,
  sizing: PropTypes.oneOf(['sm', 'lg']),
  emptyStateMessage: PropTypes.string,
  commissioned: PropTypes.bool,
  isProxy: PropTypes.bool,
  note: PropTypes.string,
};

Person.defaultProps = {
  person: {
    given_name: '',
    surname: '',
    first_name: '',
    last_name: '',
    job_title: '',
    company: {
      name: '',
    },
    email: '',
    work_email: '',
    avatar: '',
    managing_office_name: '',
  },
  type: PersonType.RECIPIENT,
  isSelf: false,
  hoverAlign: 'center',
  sizing: 'lg',
  emptyStateMessage: 'Email is missing. Please enter manually.',
  commissioned: false,
  isProxy: false,
  note: '',
};

export default Person;

