import React from 'react';
import PropTypes from 'prop-types';
import GeneralInformation from './sections/general-information';
import RentAndEscalations from './sections/rent-and-escalations';
import Expenses from './sections/expenses';

const LeaseComp = ({ modalFieldState, modalFieldChangeHandler, fieldErrorMap }) => (
  <>
    <GeneralInformation
      modalFieldState={modalFieldState}
      modalFieldChangeHandler={modalFieldChangeHandler}
      fieldErrorMap={fieldErrorMap}
    />
    <RentAndEscalations
      modalFieldState={modalFieldState}
      modalFieldChangeHandler={modalFieldChangeHandler}
      fieldErrorMap={fieldErrorMap}
    />
    <Expenses
      modalFieldState={modalFieldState}
      modalFieldChangeHandler={modalFieldChangeHandler}
      fieldErrorMap={fieldErrorMap}
    />
  </>
);

LeaseComp.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modalFieldState: PropTypes.object,
  modalFieldChangeHandler: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fieldErrorMap: PropTypes.object,
};

LeaseComp.defaultProps = {
  modalFieldState: {},
  modalFieldChangeHandler: () => {},
  fieldErrorMap: {},
};

export default LeaseComp;
