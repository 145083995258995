import React from 'react';
import PropTypes from 'prop-types';
import Currency from '../../../nucleus/formats/currency';
import Percent from '../../../nucleus/formats/percent';

const BreakdownItem = ({
  name, subname, value, percent,
}) => (
  <div className="breakdown-item">
    <div className="breakdown-item-title">
      <div className="breakdown-item-name">
        {name || '- No entry -'}
      </div>
      {subname && (
        <div className="breakdown-item-subname">
          {subname}
        </div>
      )}
    </div>
    <div className="breakdown-values">
      <div>
        <Currency
          value={value}
          emptyState="$0.00"
        />
      </div>
      <div>
        <Percent
          value={percent}
          emptyState="0%"
        />
      </div>
    </div>
  </div>
);

BreakdownItem.propTypes = {
  name: PropTypes.string,
  subname: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.number,
  percent: PropTypes.number,
};

BreakdownItem.defaultProps = {
  name: undefined,
  subname: undefined,
  value: undefined,
  percent: undefined,
};

export default BreakdownItem;
