import React from 'react';
import PropTypes from 'prop-types';
import StyledLabel from '../../nucleus/styled-label/styled-label';

const DealLabel = (props) => {
  const {
    dealStage,
    dealType,
    repRole,
    showStage,
  } = props;

  const labelVariants = {
    prospecting: 'primary',
    executing: 'secondary',
    closed: 'tertiary',
  };

  const title = dealType === repRole ? dealType : `${dealType} - ${repRole}`;
  // get human readable deal stage name
  const readableDealStage = dealStage === 'prospect' ? 'prospecting' : dealStage;
  const stage = showStage ? `${readableDealStage}: ` : '';
  return (
    <StyledLabel content={stage + title} variant={labelVariants[readableDealStage]} />
  );
};

DealLabel.propTypes = {
  dealStage: PropTypes.string.isRequired,
  dealType: PropTypes.string.isRequired,
  repRole: PropTypes.string.isRequired,
  showStage: PropTypes.bool,
};

DealLabel.defaultProps = {
  showStage: false,
};

export default DealLabel;
