import React from 'react';
import PropTypes from 'prop-types';

const NotificationTime = (props) => {
  const { alertDatetime } = props;

  const formatDate = (datetime) => {
    // format notification date to Wed, Aug 8 2018 format
    const formattedDate = datetime.toDateString().split(' ');
    const day = formattedDate.shift();
    return `${day}, ${formattedDate.join(' ')}`;
  };

  // format notification time to 1:30 PM format
  const formatTime = datetime => datetime.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  const renderDate = (datetime) => {
    // If the datetime passed in is today or yesterday, format it using toLocaleString
    // Otherwise just display as normal toDateString
    // First, get midnight today and yesterday, for comparisons
    const midnightToday = new Date();
    midnightToday.setHours(0, 0, 0, 0);
    // get midnight yesterday, without mutating today's datetime object
    const midnightYesterday = new Date();
    midnightYesterday.setHours(0, 0, 0, 0);
    midnightYesterday.setDate(midnightToday.getDate() - 1);
    // if the datetime passed in is older than yesterday, render as toDateString
    // otherwise, render it in 'Yesterday/ Today at XX:XX AM' format, in 12 hr clock
    const notificationTime = new Date(datetime);

    if (notificationTime < midnightYesterday) {
      return formatDate(notificationTime);
    } else if (notificationTime < midnightToday) {
      return `Yesterday, ${formatTime(notificationTime)}`;
    }
    return `Today, ${formatTime(notificationTime)}`;
  };

  return (
    <p>{renderDate(alertDatetime)}</p>
  );
};

export default NotificationTime;

NotificationTime.propTypes = {
  alertDatetime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
};
