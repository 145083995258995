import React from 'react';
import PropTypes from 'prop-types';

const Toast = props => (
  <div className="nd-toast">
    <p>{props.message}</p>
  </div>
);

export default Toast;

Toast.propTypes = {
  message: PropTypes.string,
};

Toast.defaultProps = {
  message: '',
};
