import { connect } from 'react-redux';
import DealDetailHeader from './deal-detail-header';
import { toast } from '../../../redux/actions/toasts-actions';
import { fetchVoucherByDealId, createVoucher } from '../../../redux/actions/voucher/voucher-actions';

const mapStateToProps = state => ({
  voucher: state.voucher.voucher,
  userData: state.userData.data,
});

const mapDispatchToProps = {
  toast,
  fetchVoucherByDealId,
  createVoucher,
};

const DealDetailHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealDetailHeader);

export default DealDetailHeaderContainer;
