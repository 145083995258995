import { connect } from 'react-redux';
import { toast } from '../../redux/actions/toasts-actions';
import ProxyAccess from './proxy-page';

const mapStateToProps = state => ({
  userData: (state.userData.data && state.userData.data.id) ? state.userData.data : {},
});

const mapDispatchToProps = {
  toast,
};

const ProxyAccessContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProxyAccess);

export default ProxyAccessContainer;
