import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import VoucherStyledLabel from '../../nucleus/styled-label/voucher-styled-label';

const VoucherLabel = (props) => {
  const {
    deal,
    hoverAlign,
    voucherID,
    dealHeader,
    isEarlyInvoice,
    voucherEmail,
  } = props;

  const dateMessage = (
    <>
      <span> Submitted on {moment.utc(voucherEmail.submitted_date).format('M/D/YYYY')}</span>
      <ul>{voucherEmail.email_to?.map(email => (<li key={email}>{email}</li>))}</ul>
    </>);
  const isVoucherIdDisplay = deal.voucher_submission_status === 'Voucher Submitted' ||
                                  deal.voucher_submission_status === 'Voucher Resubmitted' ||
                                  deal.voucher_submission_status === 'Voucher Completed' ||
                                  isEarlyInvoice;
  const voucherLabel = (deal.voucher_submission_status && deal.voucher_submission_status.toLowerCase() !== 'voucher not submitted') ?
    (<VoucherStyledLabel
      content={deal.voucher_submission_status}
      isvoucheriddisplay={isVoucherIdDisplay}
      variant="quaternary"
      hoverText={dateMessage}
      hoverAlign={hoverAlign}
      dealstage={deal.deal_life_cycle_stage}
      voucherID={voucherID}
      dealHeader={dealHeader}
    />)
    : null;

  return voucherLabel;
};

VoucherLabel.propTypes = {
  deal: PropTypes.shape({}).isRequired,
  hoverAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

VoucherLabel.defaultProps = {
  hoverAlign: 'center',
};

export default VoucherLabel;
