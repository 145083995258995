import userService from '../../services/user-service';
import {
  LOAD_USER_DATA_SUCCESS,
  LOAD_USER_DATA_FAILURE,
  IS_LOADING_USER_DATA,
  UPDATE_USER_DATA_SUCCESS,
  IS_UPDATING_USER_DATA,
  UPDATE_USER_DATA_FAILURE,
} from './action-types';

// User Service Action Creators
const loadUserDataSuccess = userData => ({
  type: LOAD_USER_DATA_SUCCESS,
  payload: { userData },
});

const loadUserDataFailure = error => ({
  type: LOAD_USER_DATA_FAILURE,
  payload: { error },
});

const isLoadingUserData = isLoading => ({
  type: IS_LOADING_USER_DATA,
  payload: { isLoading },
});

// Async Action
const loadUserData = () => (dispatch) => {
  dispatch(isLoadingUserData(true));
  userService.getUser()
    .then((data) => {
      dispatch(loadUserDataSuccess(data));
    })
    .then(() => dispatch(isLoadingUserData(false)))
    .catch(error => dispatch(loadUserDataFailure(error)));
};

const updateUserSuccess = userData => ({
  type: UPDATE_USER_DATA_SUCCESS,
  payload: { userData },
});

const updateUserFailure = error => ({
  type: UPDATE_USER_DATA_FAILURE,
  payload: { error },
});

const isUpdatingUserData = isUpdating => ({
  type: IS_UPDATING_USER_DATA,
  payload: { isUpdating },
});

// Async Action

const updateUserData = params => (dispatch) => {
  dispatch(isUpdatingUserData(true));
  userService.updateUser(params)
    .then((data) => {
      dispatch(updateUserSuccess(data));
    })
    .then(() => dispatch(isUpdatingUserData(false)))
    .catch(error => dispatch(updateUserFailure(error)));
};

export {
  loadUserDataSuccess,
  loadUserDataFailure,
  isLoadingUserData,
  loadUserData,
  updateUserSuccess,
  updateUserFailure,
  isUpdatingUserData,
  updateUserData,
};
