import _ from 'lodash';
import clone from 'lodash/clone';
import moment from 'moment';
import superFetch from '../utils/super-fetch';

// a backend bug in the API results in duplicate deals being returned
// specifically with certain sort orders and the installment date filter
const filterDuplicates = (res) => {
  let { count, results } = res;
  const containsId = {};
  results = [...results].filter((deal) => {
    if (containsId[deal.id]) {
      count += -1;
      return false;
    }
    containsId[deal.id] = true;
    return true;
  });

  return {
    ...res,
    count,
    results,
  };
};

const fixParams = (params) => {
  // This is a hacky frontend bandaid for a backend bug which is not yet understood (CB-10151).
  // (Greg, James M, and Joel suspect it is a bug with Django Rest Framework)
  // Passing any filter will fix the bug. This behavior is observed but not understood.
  // So when no filters are set, we add an all-inclusive filter.
  // So there is always at least one filter query param applied.
  const fixed = clone(params);
  if (!params.deal_life_cycle_stage || params.deal_life_cycle_stage.length === 0) {
    fixed.deal_life_cycle_stage = ['prospect', 'executing', 'closed'];
  }
  return fixed;
};

const fetchDeals = (params) => {
  const fixedParams = fixParams(params);
  const url = '/mbb/pipeline';
  const options = {
    method: 'GET',
    params: fixedParams,
  };
  return superFetch(url, options)
    .then(data => data.json())
    .then(filterDuplicates);
};

const fetchPipelineCount = () => {
  const params = {
    page_size: 0,
    is_on_pipeline: true,
  };

  return fetchDeals(params)
    .then(res => res.count);
};

const fetchDeal = (id) => {
  const url = `/mbb/pipeline/${id}`;
  const options = {
    method: 'GET',
  };

  return superFetch(url, options)
    .then(data => data.json());
};

const fetchPipelineDealCounts = (id, params) => {
  const fixedParams = fixParams(params);
  const url = `/mbb/user/userpipeline/${id}/pipeline_deal_counts`;
  const options = {
    method: 'GET',
    params: fixedParams,
  };

  return superFetch(url, options)
    .then(data => data.json());
};

const updateDeal = (id, params) => {
  const url = `/mbb/pipeline/${id}`;
  const options = {
    method: 'PATCH',
  };

  const body = JSON.stringify(params);

  return superFetch(url, options, body)
    .then(data => data.json());
};

const generateReport = (userId) => {
  const url = `/mbb/user/userpipeline/${userId}/pipeline_report`;
  const options = {
    method: 'GET',
  };

  return superFetch(url, options)
    .then(data => data.json());
};

const generateFilterReport = (userId, params) => {
  const url = `/mbb/user/userpipeline/${userId}/pipeline_report`;
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const formatDealInstallments = (deal) => {
  const firstInstallmentDate = _
    .chain([...deal.installments])
    .map(i => i.installment_date)
    .filter(date => date)
    .map(d => moment(d.split('T')[0]).format('M/D/YYYY'))
    .head()
    .value();

  return {
    ...deal,
    firstInstallmentDate,
  };
};

const fetchPipelineGrossCommissions = (userId, params) => {
  const url = `/mbb/user/userkpi/${userId}/pipeline_commission_totals`;
  const options = {
    method: 'GET',
    params: (params && {
      selected_brokers: params.selected_brokers,
      selected_teams: params.selected_teams,
    }) || '',
  };
  return superFetch(url, options)
    .then(data => data.json());
};

export default {
  fetchDeals,
  fetchDeal,
  updateDeal,
  fetchPipelineCount,
  generateReport,
  fetchPipelineDealCounts,
  formatDealInstallments,
  fetchPipelineGrossCommissions,
  generateFilterReport,
};
