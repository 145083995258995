import { connect } from 'react-redux';
// actions
import { updateDeal } from '../../../redux/actions/deal-actions';
import { addDealContact, fetchDealContacts, updateDealContact, deleteDealContact } from '../../../redux/actions/deal-contacts-actions';

import LandlordInformation from './LandLordInformationTab';

const mapStateToProps = state => ({
  deal: state.deal.deal,
  voucher: state.voucher.voucher,
  contactInfo: state.dealContacts.contacts,
});

const mapDispatchProps = {
  updateDeal,
  addDealContact,
  fetchDealContacts,
  updateDealContact,
  deleteDealContact,
};

const LandlordInformationTabContainer = connect(
  mapStateToProps,
  mapDispatchProps,
)(LandlordInformation);

export default LandlordInformationTabContainer;
