import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton } from '@cbrebuild/blocks';

import DealLabel from '../../deal-label/deal-label';
import BaseInput from '../../../nucleus/inputs/base-input';
import userEventService from '../../../services/user-event-service';
import VoucherLabel from '../../voucher-label/voucher-label';
import dealsService from '../../../services/deals-service';
import StyledLabel from '../../../nucleus/styled-label/styled-label';

class DealNameAndLabel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFavorite: props.isFavorite,
      isOnPipeline: props.deal.is_on_pipeline,
      dealName: props.deal.name,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFavorite !== this.props.isFavorite) {
      this.setIsFavorite(this.props.isFavorite);
    }
    if (prevProps.deal.name !== this.props.deal.name) {
      this.setDealName(this.props.deal.name);
    }
  }

  setIsFavorite(isFavorite) {
    this.setState({ isFavorite });
  }

  toggleFavorite = () => {
    const {
      deal,
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;

    const {
      isFavorite,
    } = this.state;

    const toggleIsFavorited = isFavorite ? dealsService.unfavoriteDeal : dealsService.favoriteDeal;

    toggleIsFavorited(deal.id)
      .then(() => {
        this.setState({ isFavorite: !isFavorite });
      })
      .catch((error) => {
        console.log('Error updating favorite preference: ', error);
      });

    const eventAction = isFavorite ? 'favorite_deal' : 'unfavorite_deal';
    userEventService.trackEvent(
      { eventAction, eventCategory: 'Action', eventValue: deal.id },
      { actionPrefix, categoryPrefix },
    );
  };

  pipelineToggleEvent = (deal, is_on_pipeline) => {
    const data = {
      eventCategory: 'Action',
      eventAction: is_on_pipeline ? 'deal_removed_from_pipeline' : 'deal_added_to_pipeline',
      eventData: {
        deal_pk: deal.pk,
        deal_stage: deal.deal_life_cycle_stage,
      },
    };

    userEventService.trackEvent(data, this.props.analyticProperties);
  };

  handlePipelineButtonClick = () => {
    const { deal, toast } = this.props;
    const { isOnPipeline } = this.state;
    const newOnPipelineStateValue = !isOnPipeline;

    this.setState({ isOnPipeline: newOnPipelineStateValue });

    if (newOnPipelineStateValue) {
      toast('Deal added to pipeline.');
    } else {
      toast('Deal removed from pipeline.');
    }

    if (newOnPipelineStateValue) {
      dealsService.addDealToPipeline(deal.id)
        .then(() => {
          this.pipelineToggleEvent(deal, true);
        })
        .catch(() => {
          this.setState({ isOnPipeline: false });
          toast('Something went wrong, please try again!');
        });
    } else {
      dealsService.removeDealFromPipeline(deal.id)
        .then(() => {
          this.pipelineToggleEvent(deal, false);
        })
        .catch(() => {
          this.setState({ isOnPipeline: true });
          toast('Something went wrong, please try again!');
        });
    }
  };

  setDealName = (dealName) => {
    this.setState({ dealName });
  }

  handleDealNameChange = ({ target: { value } }) => {
    this.setDealName(value);
  }

  updateDealName = () => {
    const { dealName } = this.state;
    const {
      deal: {
        id, name,
      },
    } = this.props;
    if (!dealName) {
      this.setState({ dealName: name });
    } else if (name !== dealName) {
      this.props.updateDeal(id, { name: dealName });
    }
  }

  render() {
    const {
      deal,
      viewOnly,
      adminView,
      voucherID,
      isEarlyInvoice,
      voucherEmail,
    } = this.props;
    const {
      isFavorite,
      isOnPipeline,
      dealName,
    } = this.state;
    const {
      deal_life_cycle_stage: dealStage,
      deal_type: dealType,
      user_wants_salesforce_export: userWantsSalesforceExport,
      created_by_username: createdByUserName,
      rep_role: repRole,
    } = deal;

    const syncSalesforceIcon = userWantsSalesforceExport && (
      <Icon iconName="cloud-sync" />
    );
    const renderTransAct = createdByUserName === 'API_TransAct_Integration' && (<Icon iconName="transact" />);
    const salesforceId = (
      <span className={`secondary-text ${dealStage}`}>Pursuit ID: {deal.salesforce_id || (deal.deal_life_cycle_stage === 'closed' ? '--' : 'Pending')}</span>
    );

    const dealIDValue = (deal.deal_ids && deal.deal_ids.length > 0) ? deal.deal_ids.join(', ') : '--';
    const dealId = deal.deal_life_cycle_stage === 'closed' && (
      <span className={`secondary-text ${dealStage}`}>Deal ID #: {dealIDValue}</span>
    );

    return (
      <div className={`deal-name-and-label ${dealStage}`}>
        <div className="deal-name-header">
          {!adminView &&
            <IconButton
              iconName={isFavorite ? 'star-filled' : 'star-outline'}
              onClick={this.toggleFavorite}
              tabIndex="-1"
            />}
          <BaseInput
            className={`deal-title ${dealStage}`}
            onBlur={this.updateDealName}
            onChange={this.handleDealNameChange}
            placeholder="Deal Name"
            value={dealName}
            variant="inline"
            viewOnly={viewOnly || adminView}
          />
          { syncSalesforceIcon }
          {renderTransAct}
          <div
            className={`pipeline-control ${isOnPipeline ? 'on-pipeline' : ''}`}
            onClick={this.handlePipelineButtonClick}
          >
            <Icon iconName={isOnPipeline ? 'checkmark' : 'plus'} />
            <span>Pipeline</span>
          </div>
        </div>
        <div className="deal-name-labels">
          <DealLabel
            dealStage={dealStage}
            dealType={dealType || ''}
            repRole={repRole || ''}
          />
          <VoucherLabel
            deal={deal}
            voucherID={voucherID}
            isEarlyInvoice={isEarlyInvoice}
            voucherEmail={voucherEmail}
            dealHeader
          />
          {viewOnly && !adminView && <StyledLabel content="Commission View" />}
          {dealId}
          {salesforceId}
        </div>
      </div>
    );
  }
}

export default DealNameAndLabel;

DealNameAndLabel.propTypes = {
  deal: PropTypes.shape({
    created_by_username: PropTypes.string,
    deal_ids: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.string),
    ]),
    deal_type: PropTypes.string,
    id: PropTypes.number.isRequired,
    deal_life_cycle_stage: PropTypes.string.isRequired,
    name: PropTypes.string,
    primary_occupied_property: PropTypes.shape({}),
    primary_prior_property: PropTypes.shape({}),
    rep_role: PropTypes.string,
    requirements: PropTypes.shape({}),
    salesforce_id: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    user_wants_salesforce_export: PropTypes.bool,
    is_on_pipeline: PropTypes.bool,
  }).isRequired,
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }),
  isFavorite: PropTypes.bool.isRequired,
  updateDeal: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  adminView: PropTypes.bool,
  toast: PropTypes.func.isRequired,
  voucherID: PropTypes.string,
  isEarlyInvoice: PropTypes.bool,
};

DealNameAndLabel.defaultProps = {
  analyticProperties: {
    actionPrefix: 'deal_details',
    categoryPrefix: 'Deal Detail ',
  },
  viewOnly: false,
  adminView: false,
  voucherID: '',
  isEarlyInvoice: false,
};
