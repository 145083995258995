import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import countries from '../../data/countries.json';
import states from '../../data/states.json';
import Select from '../../nucleus/select/select';
import { isValidAddress1, isValidCity, isValidPostal } from './voucher-validation';

const AddressForm = ({
  postal = '',
  city = '',
  address = '',
  address2 = '',
  territory = '',
  country = '',
  isRelocating = false,
  onChange,
  onBlur,
  showValidation,
}) => {
  // validation logic
  const postalIsValid = useMemo(() => isValidPostal(postal, country), [postal, country]);
  const addressIsValid = useMemo(() => isValidAddress1(address), [address]);
  const cityIsValid = useMemo(() => isValidCity(city), [city]);
  const [countryName, setCountryName] = useState('');
  const getDefaultCountryAliases = () => ['US', 'USA', 'U.S.', 'U.S.A.', 'United States', 'United States of America'];

  useEffect(() => {
    if (getDefaultCountryAliases().indexOf(country) !== -1 || !country) {
      setCountryName('United States');
    } else {
      setCountryName(country);
    }
  }, [country]);

  // updates labels and territories on country change
  const {
    filteredStates, postalLabel, stateLabel,
  } = useMemo(() => {
    const isUSA = getDefaultCountryAliases().indexOf(country) !== -1;
    const found = countries.find(c => c.display_name === countryName);
    return {
      filteredStates: states.filter(s => s.country_id === found?.country_id),
      stateLabel: isUSA ? 'State' : 'Province / Region',
      postalLabel: isUSA ? 'Zip' : 'Postal Code',
    };
  }, [country, countryName]);

  const handleCountryChange = (e) => {
    const target = { name: 'country', type: 'select-one', value: e.display_name };
    setCountryName(target.value);
    onChange({ target });
  };
  const handleStateChange = (e) => {
    const target = { name: 'territory', type: 'select-one', value: e.display_name };
    onChange({ target });
  };

  return (
    <div className="address-form">
      <div className="field select-subType">
        <label>
          <span className={(countryName === '') ? 'asterisk-error' : ''}>*</span> Country
        </label>
        <Select
          className={countryName === '' ? 'errorInput' : ''}
          defaultOption={countryName || 'Select'}
          options={countries}
          onChange={e => handleCountryChange(e)}
        />
        {(countryName === '') && <p className="asterisk-error top10">Country is required</p>}
      </div>
      <div className="form-row">
        <div className="field-group col-2">
          <label><span className={`${!addressIsValid && showValidation ? 'asterisk-error' : ''}`}>* </span> Address {!isRelocating && 'Line 1'}</label>
          <input
            type="text"
            name="address"
            className={`${!addressIsValid && showValidation ? 'errorInput' : ''}`}
            value={address || ''}
            onChange={onChange}
            onBlur={onBlur}
          />
          {!addressIsValid && showValidation ? <p className="asterisk-error">Address {!isRelocating && 'Line 1'} is required</p> : ''}
        </div>
        {!isRelocating && (
          <div className="field-group col-2">
            <label>Address Line 2</label>
            <input
              type="text"
              name="address2"
              value={address2 || ''}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>
        )}
      </div>
      <div className="form-row">
        <div className="field-group col-2">
          <label><span className={`${!cityIsValid && showValidation ? 'asterisk-error' : ''}`}>* </span> City</label>
          <input
            type="text"
            name="city"
            className={`${!cityIsValid && showValidation ? 'errorInput' : ''}`}
            value={city || ''}
            onChange={onChange}
            onBlur={onBlur}
          />
          {!cityIsValid && showValidation ? <p className="asterisk-error">City is required</p> : ''}
        </div>
        <div className="field col-4">
          <label>
            <span className={(territory === '') ? 'asterisk-error' : ''}>*</span> {stateLabel}
          </label>
          <Select
            className={territory === '' ? 'selectErrorInput' : ''}
            defaultOption={territory || 'Select'}
            options={filteredStates}
            onChange={e => handleStateChange(e)}
          />
          {(territory === '') && <p className="asterisk-error">State is required</p>}
        </div>
        <div className="field col-4">
          <label><span className={`${!postalIsValid && showValidation ? 'asterisk-error' : ''}`}>* </span> {postalLabel}</label>
          <input
            type="text"
            name="postal"
            className={`${!postalIsValid && showValidation ? 'errorInput' : ''}`}
            value={postal || ''}
            onChange={onChange}
            onBlur={onBlur}
          />
          {!postalIsValid && showValidation ? <p className="asterisk-error">Zip is required</p> : ''}
        </div>
      </div>
    </div>
  );
};

AddressForm.propTypes = {
  postal: PropTypes.string,
  city: PropTypes.string,
  address: PropTypes.string,
  address2: PropTypes.string,
  territory: PropTypes.string,
  country: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  isRelocating: PropTypes.bool,
  showValidation: PropTypes.bool,
};

AddressForm.defaultProps = {
  postal: '',
  city: '',
  address: '',
  address2: '',
  territory: '',
  country: '',
  isRelocating: false,
  onBlur: () => { },
  showValidation: false,
};

export default AddressForm;
