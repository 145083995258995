import React from 'react';
import PropTypes from 'prop-types';
import LoadingBar from '../../nucleus/loading-bar/loading-bar';

/**
 * a reusable action header for list
 * can use one or many options
 */
const ListActionHeader = ({
  children,
  filter,
  isLoading,
  search,
  sort,
}) =>
  (
    <div className="nd-list-action-header">
      <div className="filter-section">{filter}</div>
      {search && <div className={`search-section${(!filter && !sort) ? ' only-search' : ''}`}>{search}</div>}
      <div className="sort-section">{sort}</div>
      { children }
      <LoadingBar isLoading={isLoading} />
    </div>
  );

ListActionHeader.propTypes = {
  filter: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  isLoading: PropTypes.bool,
  search: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  sort: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  children: PropTypes.node,
};

ListActionHeader.defaultProps = {
  filter: null,
  isLoading: false,
  search: null,
  sort: null,
  children: null,
};

export default ListActionHeader;
