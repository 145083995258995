import userEventService from './user-event-service';

const eventCategory = 'Dashboard Actions';

const trackCommissionsWidgetToggle = (isOpen) => {
  const event = {
    eventCategory,
    eventAction: `commissions_widget_${isOpen ? 'shown' : 'hidden'}`,
    eventLabel: 'commissions_widget_action',
  };

  userEventService.trackEvent(event);
};

const trackCommissionsWidgetLinkToChart = () => {
  const event = {
    eventCategory,
    eventAction: 'linked_to_commissions_chart',
    eventLabel: 'commissions_widget_action',
  };

  userEventService.trackEvent(event);
};

const trackPaymentsWidgetToggle = (isOpen) => {
  const event = {
    eventCategory,
    eventAction: `payments_and_installments_widget_${isOpen ? 'shown' : 'hidden'}`,
    eventLabel: 'payments_and_installments_widget_action',
  };

  userEventService.trackEvent(event);
};

const trackPaymentsWidgetTabClicked = (tab) => {
  const formatted = tab.replace(' ', '_').toLowerCase();

  const event = {
    eventCategory,
    eventAction: `${formatted}_tab_clicked`,
    eventLabel: 'payments_and_installments_widget_action',
  };

  userEventService.trackEvent(event);
};

const trackPaymentsWidgetFilterClicked = (filter, currentTab) => {
  const event = {
    eventCategory,
    eventAction: `${filter.toLowerCase()}_filter_clicked`,
    eventLabel: 'payments_and_installments_widget_action',
    eventData: {
      currentTab,
    },
  };

  userEventService.trackEvent(event);
};

const trackPaymentsWidgetLinkToDeal = (dealId, currentTab) => {
  const event = {
    eventCategory,
    eventAction: 'linked_to_deal_detail',
    eventLabel: 'payments_and_installments_widget_action',
    eventData: {
      dealId,
      currentTab,
    },
  };

  userEventService.trackEvent(event);
};

const trackDealsWidgetTabClicked = (tab) => {
  const formatted = tab.replace(' ', '_').toLowerCase();

  const event = {
    eventCategory,
    eventAction: `${formatted}_tab_clicked`,
    eventLabel: 'deals_widget_action',
  };

  userEventService.trackEvent(event);
};

const trackDealsWidgetLinkToDeal = (dealId, currentTab) => {
  const event = {
    eventCategory,
    eventAction: 'linked_to_deal_detail',
    eventLabel: 'deals_widget_action',
    eventData: {
      dealId,
      currentTab,
    },
  };

  userEventService.trackEvent(event);
};

const trackTasksWidgetLinkToTasks = () => {
  const event = {
    eventCategory,
    eventAction: 'linked_to_tasks_page',
    eventLabel: 'tasks_widget_action',
  };

  userEventService.trackEvent(event);
};

export default {
  trackCommissionsWidgetToggle,
  trackCommissionsWidgetLinkToChart,
  trackPaymentsWidgetTabClicked,
  trackPaymentsWidgetToggle,
  trackPaymentsWidgetFilterClicked,
  trackPaymentsWidgetLinkToDeal,
  trackDealsWidgetTabClicked,
  trackDealsWidgetLinkToDeal,
  trackTasksWidgetLinkToTasks,
};
