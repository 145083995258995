import React from 'react';
import PropTypes from 'prop-types';

const FormGroup = ({
  label,
  isRequired,
  hasError,
  errorMessage,
  children,
  className,
  size,
}) => {
  const asterisk = (
    isRequired ? <span className="asterisk">* </span> : ''
  );

  const generatedErrorMessage = <span><span className="capitalize">{label}</span> is required</span>;

  const error = (
    (isRequired && hasError) ?
      <p className="input-hint error">{errorMessage || generatedErrorMessage}</p> : ''
  );

  return (
    <div className={`nd-form-group ${size} ${className} ${isRequired ? 'required' : ''} ${(isRequired && hasError) ? 'invalid' : ''}`}>
      <label className="input-label">{asterisk}
        <span className="capitalize">{label}</span>
      </label>
      {children}
      {error}
    </div>
  );
};

FormGroup.propTypes = {
  isRequired: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf([
    '',
    'small',
    'medium',
    'large',
    'x-large',
  ]),
};

FormGroup.defaultProps = {
  isRequired: false,
  errorMessage: '',
  className: '',
  size: '',
};


export default FormGroup;
