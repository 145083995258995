import GettingStarted from '../../components/voucher/GettingStartedTab';
// Voucher Details
import VoucherDetails from '../../components/voucher/VoucherDetails';
import LeaseInformationTabContainer from '../../components/voucher/LeaseInformation/LeaseInformationContainer';
import TenantInformationContainer from '../../components/voucher/TenantInformation/TenantInformationContainer';
import LandlordInformationTabContainer from '../../components/voucher/LandlordInformation/LandlordInformationTabContainer';
import PropertyInformationContainer from '../../components/voucher/PropertyInformation/PropertyTabContainer';
import InvestmentDetailsTabContainer from '../../components/voucher/InvestmentDetails/InvestmentDetailsTabContainer';
// Financials
import Financials from '../../components/voucher/FinancialsTab';
import Consideration from '../../components/voucher/ConsiderationTab/ConsiderationTab';
import Allocations from '../../components/voucher/AllocationsTab/AllocationsTab';
import Commissions from '../../components/voucher/CommissionsTab/CommissionsTab';
import InstallmentsTab from '../../components/voucher/InstallmentsTab/InstallmentsTab';
import BillToContainer from '../../components/voucher/Bill-To/BillToTabContainer';
// CompData
import CompDataTab from '../../components/voucher/CompDataTab/CompDataTab';
// Review
import ReviewSend from '../../components/voucher/ReviewSend';
import FutureActions from '../../components/voucher/FutureActionsTab/FutureActionsTab';
import Review from '../../components/voucher/ReviewTab/ReviewTab';
import Send from '../../components/voucher/SendTab/SendTab';
// Confirmation
import Confirmation from '../../components/voucher/ConfirmationTab';

const VOUCHER_ROUTES = [
  {
    path: '/voucher/:id/getting_started',
    component: GettingStarted,
    routes: null,
  },
  {
    path: '/voucher/:id/voucher_details/:subtab?',
    component: VoucherDetails,
    routes: [
      {
        path: '/voucher/:id/voucher_details/lease_information',
        component: LeaseInformationTabContainer,
        type: 'lease',
      },
      {
        path: '/voucher/:id/voucher_details/tenant_information',
        component: TenantInformationContainer,
        type: 'lease',
      },
      {
        path: '/voucher/:id/voucher_details/landlord_information',
        component: LandlordInformationTabContainer,
        type: 'lease',
      },
      {
        path: '/voucher/:id/voucher_details/property',
        component: PropertyInformationContainer,
        type: 'sale',
      },
      {
        path: '/voucher/:id/voucher_details/investment_details',
        component: InvestmentDetailsTabContainer,
        type: 'sale',
      },
      {
        path: '/voucher/:id/voucher_details/buyer_information',
        component: TenantInformationContainer,
        type: 'sale',
      },
      {
        path: '/voucher/:id/voucher_details/seller_information',
        component: LandlordInformationTabContainer,
        type: 'sale',
      },
      {
        path: '/voucher/:id/voucher_details/overview',
        component: PropertyInformationContainer,
        type: 'consulting',
      },
      {
        path: '/voucher/:id/voucher_details/client',
        component: TenantInformationContainer,
        type: 'consulting',
      },
      {
        path: '/voucher/:id/voucher_details/overview',
        component: PropertyInformationContainer,
        type: 'other',
      },
      {
        path: '/voucher/:id/voucher_details/client',
        component: TenantInformationContainer,
        type: 'other',
      },
    ],
  },
  {
    path: '/voucher/:id/financials/:subtab?',
    component: Financials,
    text: 'Financials',
    routes: [
      {
        path: '/voucher/:id/financials/consideration',
        component: Consideration,
      },
      {
        path: '/voucher/:id/financials/allocations',
        component: Allocations,
      },
      {
        path: '/voucher/:id/financials/commissions',
        component: Commissions,
      },
      {
        path: '/voucher/:id/financials/installments',
        component: InstallmentsTab,
      },
      {
        path: '/voucher/:id/financials/bill-to',
        component: BillToContainer,
      },
    ],
  },
  {
    path: '/voucher/:id/comp_data',
    component: CompDataTab,
    text: 'Lease Comp',
    routes: null,
  },
  {
    path: '/voucher/:id/review_and_send/:subtab?',
    component: ReviewSend,
    text: 'Review & Send',
    routes: [
      {
        path: '/voucher/:id/review_and_send/future_actions',
        component: FutureActions,
      },
      {
        path: '/voucher/:id/review_and_send/review',
        component: Review,
      },
      {
        path: '/voucher/:id/review_and_send/send',
        component: Send,
      },
    ],
  },
  {
    path: '/voucher/:id/confirmation',
    component: Confirmation,
    text: 'Confirmation',
    routes: null,
  },
];

export default VOUCHER_ROUTES;
