import React from 'react';
import PropTypes from 'prop-types';
import AutocompleteFlyout from '../autocomplete/autocomplete-flyout';
import ContactService from '../../services/contacts-service';
import { Person, PersonType } from '../person';

class ContactAutocomplete extends React.Component {
  static propTypes = {
    /** Callback for when a user modifies the input, via typing or clicking a result */
    onChange: PropTypes.func.isRequired,
    /** callback when a user loses focus from input */
    onBlur: PropTypes.func,
    /** An ErrorMessage to show if field is required */
    errorMessage: PropTypes.string,
    /** The string you want to use in the input as a placeholder  */
    placeholder: PropTypes.string,
    /** string used as input value on init  */
    initSearchTerm: PropTypes.string,
    /** string for advanced search to prepend first name  */
    firstName: PropTypes.string,
    /** string for advanced search to append first name  */
    lastName: PropTypes.string,
    /** boolean for disableSearch  */
    disableSearchRequest: PropTypes.bool,
  };

  static defaultProps = {
    disableSearchRequest: false,
    errorMessage: '',
    placeholder: 'Search Contacts',
    initSearchTerm: '',
    firstName: '',
    lastName: '',
    onBlur: () => { },
  };

  advancedGetContacts = (params) => {
    const {
      disableSearchRequest,
      firstName,
      lastName,
    } = this.props;
    if (disableSearchRequest) return Promise.resolve([]);
    const pre = firstName ? `${firstName} ` : '';
    const post = lastName ? ` ${lastName}` : '';
    const advancedParams = {
      ...params,
      search: pre + params.search + post,
    };
    return ContactService.getContacts(advancedParams).then(res => res.results || []).catch(() => []);
  }

  renderListItem = result => (
    <Person
      person={result}
      type={PersonType.CONTACT_AUTOCOMPLETE}
    />
  );

  render() {
    const {
      disableSearchRequest,
      errorMessage,
      initSearchTerm,
      onChange,
      onBlur,
      placeholder,
    } = this.props;
    return (
      <AutocompleteFlyout
        initSearchTerm={initSearchTerm}
        disableSearchRequest={disableSearchRequest}
        fetchRequest={this.advancedGetContacts}
        requestParams={{ page_size: 25 }}
        onChange={onChange}
        placeholder={placeholder}
        renderListItem={this.renderListItem}
        findSelectedResultAttribute={this.getName}
        errorMessage={errorMessage}
        onBlur={onBlur}
      />
    );
  }
}

export default ContactAutocomplete;
