const convertToReactDataStructure = (query) => {
  const {
    deal_life_cycle_stage,
    rep_role,
    tags,
  } = query;
  return {
    ...query,
    deal_life_cycle_stage: deal_life_cycle_stage ? [deal_life_cycle_stage] : [],
    rep_role: rep_role ? [rep_role] : [],
    tags: tags ? tags.split(',') : [],
  };
};

const convertToAngularDataStructure = (query) => {
  const {
    conversion_potential_min,
    conversion_potential_max,
    deal_life_cycle_stage,
    rep_role,
    tags,
  } = query;
  const data = {
    ...query,
    deal_life_cycle_stage: (deal_life_cycle_stage && deal_life_cycle_stage.length) ? deal_life_cycle_stage[0] : null,
    rep_role: (rep_role && rep_role.length) ? rep_role[0] : null,
    tags: (tags && tags.length) ? tags.join() : null,
  };
  if (conversion_potential_min && conversion_potential_min !== 0) {
    data.conversion_potential_min = conversion_potential_min;
  }
  if (conversion_potential_max && conversion_potential_max === 100) {
    data.conversion_potential_max = conversion_potential_max;
  }
  return data;
};

const saveDealListQuery = (query) => {
  const {
    building,
    deal_life_cycle_stage,
  } = query;
  if (!building && deal_life_cycle_stage[0]) {
    const key = deal_life_cycle_stage[0] === 'prospect' ? 'prospecting' : deal_life_cycle_stage[0];
    // this can be removed after react migration
    const data = convertToAngularDataStructure(query);
    window.localStorage.setItem(`${key}State`, JSON.stringify(data));
  }
};

const getDealListQuery = (key) => {
  const savedQuery = window.localStorage.getItem(key);
  return savedQuery ? convertToReactDataStructure(JSON.parse(savedQuery)) : undefined;
};

const getDealListQueryFromCurrentQuery = (query) => {
  const {
    building,
    deal_life_cycle_stage,
  } = query;
  if (deal_life_cycle_stage.length === 1 && !building) {
    const key = deal_life_cycle_stage[0] === 'prospect' ? 'prospecting' : deal_life_cycle_stage[0];
    return getDealListQuery(`${key}State`);
  }
  return undefined;
};

const getDealTogglerData = () => JSON.parse(window.localStorage.getItem('dealTogglerData'));

const saveDealTogglerData = (data) => {
  window.localStorage.setItem('dealTogglerData', JSON.stringify(data));
};

export default {
  getDealListQuery,
  getDealListQueryFromCurrentQuery,
  getDealTogglerData,
  saveDealListQuery,
  saveDealTogglerData,
};
