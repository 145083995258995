
import uniqueId from 'lodash/uniqueId';

// Toasts Actions Constants
const ADD_TOAST = 'TOASTS:ADD_TOAST';
const REMOVE_TOAST = 'TOASTS:REMOVE_TOAST';

// Toasts Actions Creators
const addToast = (id, message) => ({
  type: ADD_TOAST,
  payload: { id, message },
});

const removeToast = id => ({
  type: REMOVE_TOAST,
  payload: { id },
});

// ASYNC ACTIONS:
const toast = message => (dispatch) => {
  const id = uniqueId();
  dispatch(addToast(id, message));
  setTimeout(() => {
    dispatch(removeToast(id));
  }, 5000);
};

export {
  toast,
  ADD_TOAST,
  REMOVE_TOAST,
};
