import teamService from '../../services/team-members-service';
import {
  LOAD_DEAL_TEAM_SUCCESS,
  LOAD_DEAL_TEAM_FAILURE,
  LOAD_COMMISSION_TEAM_SUCCESS,
  UPDATE_DEAL_USERS_SUCCESS,
  UPDATE_COMMISSION_USERS_SUCCESS,
  UPDATE_DEAL_USERS_FAILURE,
  UPDATE_DEAL_TEAMS_SUCCESS,
  UPDATE_COMMISSION_TEAMS_SUCCESS,
  UPDATE_DEAL_TEAMS_FAILURE,
} from './action-types';
import { toast } from './toasts-actions';

import userEventService from '../../services/user-event-service';

const analyticProperties = {
  actionPrefix: 'deal_member_',
  category: 'Deal Membership',
};

// Team Actions Creators
const loadDealTeamSuccess = teamMembers => ({
  type: LOAD_DEAL_TEAM_SUCCESS,
  payload: { teamMembers },
});

const loadDealTeamFailure = error => ({
  type: LOAD_DEAL_TEAM_FAILURE,
  payload: { error },
});

const loadCommissionTeamSuccess = teamMembers => ({
  type: LOAD_COMMISSION_TEAM_SUCCESS,
  payload: { teamMembers },
});

const updateDealUsersSuccess = data => ({
  type: UPDATE_DEAL_USERS_SUCCESS,
  payload: { ...data },
});

const updateCommissionUsersSuccess = data => ({
  type: UPDATE_COMMISSION_USERS_SUCCESS,
  payload: { ...data },
});

const updateDealUsersFailure = error => ({
  type: UPDATE_DEAL_USERS_FAILURE,
  payload: { error },
});

const updateDealTeamsSuccess = data => ({
  type: UPDATE_DEAL_TEAMS_SUCCESS,
  payload: { ...data },
});

const updateCommissionTeamsSuccess = data => ({
  type: UPDATE_COMMISSION_TEAMS_SUCCESS,
  payload: { ...data },
});

const updateDealTeamsFailure = error => ({
  type: UPDATE_DEAL_TEAMS_FAILURE,
  payload: { error },
});

// ASYNC ACTIONS:
const loadDealTeam = params => dispatch =>
  teamService.getDealTeam(params)
    .then((data) => {
      dispatch(loadDealTeamSuccess(data.results));
    })
    .catch(error => dispatch(loadDealTeamFailure(error)));

const loadCommissionTeam = params => dispatch =>
  teamService.getDealTeam(params)
    .then((data) => {
      dispatch(loadCommissionTeamSuccess(data.results));
    })
    .catch(error => dispatch(loadDealTeamFailure(error)));

const addDealMember = data => dispatch =>
  teamService.addDealMember(data)
    .then((result) => {
      dispatch(updateDealUsersSuccess(result));
      userEventService.trackEvent({
        eventAction: `${analyticProperties.actionPrefix}add_user`,
        eventCategory: analyticProperties.category,
        eventLabel: `${analyticProperties.actionPrefix}add_user`,
        eventValue: result.user.id,
        eventData: {
          deal_pk: result.deal,
          user_pk: result.user.id,
        },
      });
    })
    .catch(error => dispatch(updateDealUsersFailure(error)));

const addDealMembers = (deal, userIds) => dispatch =>
  userIds.forEach(userId =>
    dispatch(addDealMember({
      deal,
      team_id: null,
      user_id: userId,
    })));

const addCommissionMember = data => dispatch =>
  teamService.addDealMember(data)
    .then((result) => {
      dispatch(updateCommissionUsersSuccess(result));
      userEventService.trackEvent({
        eventAction: `${analyticProperties.actionPrefix}add_commission_user`,
        eventCategory: analyticProperties.category,
        eventLabel: `${analyticProperties.actionPrefix}add_commission_user`,
        eventValue: result.user.id,
        eventData: {
          deal_pk: result.deal,
          user_pk: result.user.id,
        },
      });
    })
    .catch(error => dispatch(updateDealUsersFailure(error)));

const addCommissionMembers = (deal, userIds) => dispatch =>
  userIds.forEach(userId =>
    dispatch(addCommissionMember({
      deal,
      team_id: null,
      user_id: userId,
    })));

const addDealTeam = data => dispatch =>
  teamService.addDealMember(data)
    .then((result) => {
      dispatch(updateDealTeamsSuccess(result));
      userEventService.trackEvent({
        eventAction: `${analyticProperties.actionPrefix}add_team`,
        eventCategory: analyticProperties.category,
        eventLabel: `${analyticProperties.actionPrefix}add_team`,
        eventValue: result.team.id,
        eventData: {
          deal_pk: result.deal,
          team_pk: result.team.id,
        },
      });
    })
    .catch(error => dispatch(updateDealTeamsFailure(error)));

const addDealTeams = (deal, teamIds) => dispatch =>
  teamIds.forEach(teamId =>
    dispatch(addDealTeam({
      deal,
      team_id: teamId,
      user_id: null,
    })));

const addCommissionTeam = data => dispatch =>
  teamService.addDealMember(data)
    .then((result) => {
      dispatch(updateCommissionTeamsSuccess(result));
      userEventService.trackEvent({
        eventAction: `${analyticProperties.actionPrefix}add_commission_team`,
        eventCategory: analyticProperties.category,
        eventLabel: `${analyticProperties.actionPrefix}add_commission_team`,
        eventValue: result.team.id,
        eventData: {
          deal_pk: result.deal,
          team_pk: result.team.id,
        },
      });
    })
    .catch(error => dispatch(updateDealTeamsFailure(error)));

const addCommissionTeams = (deal, teamIds) => dispatch =>
  teamIds.forEach(teamId =>
    dispatch(addCommissionTeam({
      deal,
      team_id: teamId,
      user_id: null,
    })));

const removeDealMember = (dealMemberId, dealId) => dispatch =>
  teamService.removeDealMember(dealMemberId)
    .then((data) => {
      dispatch(updateDealUsersSuccess(data));
      userEventService.trackEvent({
        eventAction: `${analyticProperties.actionPrefix}remove_user`,
        eventCategory: analyticProperties.category,
        eventLabel: `${analyticProperties.actionPrefix}remove_user`,
        eventValue: data.id,
        eventData: {
          deal_pk: dealId,
          user_pk: data.id,
        },
      });
    })
    .catch((error) => {
      dispatch(updateDealUsersFailure(error));
      dispatch(toast('Error Removing Deal Collaborator'));
    });

const removeDealMembers = (dealMemberIds, dealId) => dispatch =>
  dealMemberIds.forEach(dealMemberId =>
    dispatch(removeDealMember(dealMemberId, dealId)));

const removeCommissionMember = (commissionMemberId, dealId) => dispatch =>
  teamService.removeDealMember(commissionMemberId)
    .then((data) => {
      dispatch(updateCommissionUsersSuccess(data));
      userEventService.trackEvent({
        eventAction: `${analyticProperties.actionPrefix}remove_commission_user`,
        eventCategory: analyticProperties.category,
        eventLabel: `${analyticProperties.actionPrefix}remove_commission_user`,
        eventValue: data.id,
        eventData: {
          deal_pk: dealId,
          user_pk: data.id,
        },
      });
    })
    .catch((error) => {
      dispatch(updateDealUsersFailure(error));
      dispatch(toast('Error Removing Deal Collaborator'));
    });

const removeCommissionMembers = (commissionMemberIds, dealId) => dispatch =>
  commissionMemberIds.forEach(commissionMemberId =>
    dispatch(removeCommissionMember(commissionMemberId, dealId)));

const removeDealTeam = (dealTeamId, dealId) => dispatch =>
  teamService.removeDealMember(dealTeamId)
    .then((data) => {
      dispatch(updateDealTeamsSuccess(data));
      userEventService.trackEvent({
        eventAction: `${analyticProperties.actionPrefix}remove_team`,
        eventCategory: analyticProperties.category,
        eventLabel: `${analyticProperties.actionPrefix}remove_team`,
        eventValue: data.id,
        eventData: {
          deal_pk: dealId,
          team_pk: data.id,
        },
      });
    })
    .catch(error => dispatch(updateDealTeamsFailure(error)));

const removeDealTeams = (dealTeamIds, dealId) => dispatch =>
  dealTeamIds.forEach(dealTeamId =>
    dispatch(removeDealTeam(dealTeamId, dealId)));

const removeCommissionTeam = (dealTeamId, dealId) => dispatch =>
  teamService.removeDealMember(dealTeamId)
    .then((data) => {
      dispatch(updateCommissionTeamsSuccess(data));
      userEventService.trackEvent({
        eventAction: `${analyticProperties.actionPrefix}remove_commission_team`,
        eventCategory: analyticProperties.category,
        eventLabel: `${analyticProperties.actionPrefix}remove_commission_team`,
        eventValue: data.id,
        eventData: {
          deal_pk: dealId,
          team_pk: data.id,
        },
      });
    })
    .catch(error => dispatch(updateDealTeamsFailure(error)));

const removeCommissionTeams = (dealTeamIds, dealId) => dispatch =>
  dealTeamIds.forEach(dealTeamId =>
    dispatch(removeCommissionTeam(dealTeamId, dealId)));

export {
  loadDealTeam,
  loadCommissionTeam,
  addDealMember,
  addDealMembers,
  addCommissionMember,
  addCommissionMembers,
  removeDealMember,
  removeDealMembers,
  addDealTeam,
  addDealTeams,
  addCommissionTeam,
  addCommissionTeams,
  removeCommissionMember,
  removeCommissionMembers,
  removeDealTeam,
  removeDealTeams,
  removeCommissionTeam,
  removeCommissionTeams,
};
