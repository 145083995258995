import { connect } from 'react-redux';

import OverviewTab from './overview-tab';

const mergeProps = (state, actions, props) => ({
  ...state,
  ...actions,
  createdDetails: {
    createdBy: props.deal.created_by_display_name,
    createdDate: props.deal.created,
  },
  analyticProperties: {
    actionPrefix: `${props.analyticProperties.actionPrefix}_overview_tab`,
    categoryPrefix: `${props.analyticProperties.categoryPrefix}Overview Tab `,
  },
});

const OverviewTabContainer = connect(
  null,
  null,
  mergeProps,
)(OverviewTab);

export default OverviewTabContainer;
