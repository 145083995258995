import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import RowItem from '../../nucleus/row-item/row-item';
import BottomNav from './BottomNav';
import { Person, PersonType } from '../person';
import formatSubmissionDate from '../../utils/format-submission-date';
import { populateRecipients } from '../../redux/actions/voucher/voucher-actions';
import vouchersService from '../../services/voucher/vouchers-service';

const Confirmation = (props) => {
  const {
    recipients, voucher,
  } = useSelector(state => ({
    recipients: state.voucher.recipients || state.voucher.voucher.send_to_email_addresses,
    voucher: state.voucher.voucher,
  }));
  const dispatch = useDispatch();
  const submissionDate = formatSubmissionDate(voucher);
  useEffect(() => {
    dispatch(populateRecipients(voucher.id));
  }, [dispatch, voucher]);

  // voucher needs to get refetched manually b/c the latest one doesn't contain early invoice info
  const [isEarlyInvoice, setIsEarlyInvoice] = useState(voucher.is_early_invoice);
  useEffect(() => {
    if (voucher.deal) {
      vouchersService.fetchVoucher(voucher.id)
        .then((res) => {
          setIsEarlyInvoice(res.is_early_invoice);
        });
    }
  }, [isEarlyInvoice]);
  return (
    <div className="voucher-content confirmation-tab">
      <h2>Congratulations, your {isEarlyInvoice ? 'early invoice request' : 'voucher'} has been sent!</h2>
      <p><span className="label">Time Submitted</span> <strong>{submissionDate}</strong></p>
      <p>We’ve sent your {isEarlyInvoice === true ? 'early invoice request' : 'voucher'} to the following E-Mail Addresses:</p>
      <div>
        {recipients?.map(recipient => (
          <RowItem key={recipient.user_id || uniqueId()} >
            <Person
              person={recipient}
              type={recipient.employee_id ? PersonType.MEMBER : PersonType.RECIPIENT}
            />
          </RowItem>
          ))}
      </div>
      <BottomNav location={props.location.pathname} />
    </div>
  );
};

Confirmation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

Confirmation.defaultProps = {
  location: '',
};

export default Confirmation;
