import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { RadioButton, RadioGroup } from '@cbrebuild/blocks';
import PercentDollarInput from '../../../nucleus/inputs/PercentDollarInput';
import Datepicker from '../../../nucleus/datepicker/datepicker';
import EmailInput from '../../../nucleus/inputs/email-input';
import PhoneInput from '../../../nucleus/inputs/phone-input';
import Select from '../../../nucleus/select/select';
import { updateVoucher } from '../../../redux/actions/voucher/voucher-actions';
import { updateDealPipelineTimestamp } from '../../../redux/actions/deal-actions';
import AddressForm from '../AddressForm';
import useFormState, { EVENT_VALUE_TYPES } from '../../../hooks/useFormState';
import { updateCommission, updateCommissionVendor } from '../../../redux/actions/outside-broker-commissions-action';
import companiesService from '../../../services/companies-service';
import { selectTotalCommissions } from '../../../redux/selectors/voucher-selectors';

const selectOutsideBrokerById = (state, id) => (
  state.outsideBrokerCommissions.commissions.find(commission => commission.id === id)
);

const typeOverrides = {
  voucher_gross_commission_percent: EVENT_VALUE_TYPES.FLOAT,
  gross_commission: EVENT_VALUE_TYPES.FLOAT,
};

const OutsideBrokerItem = ({
  id,
  dealId,
  voucherId,
  showValidation,
  outside_broker_is_percent,
}) => {
  const dealType = useSelector(state => state.deal.deal?.deal_type);
  const commission = useSelector(state => selectOutsideBrokerById(state, id)) || {};
  const voucherBrokerRepRoleOptions = useSelector(state => state.options.voucher_broker_rep_role)
    .filter((option) => {
      if (option.display_name.match('NONE')) return option;
      switch (dealType) {
        case 'sale':
          return option.display_name.match(/Buyer|Seller/);
        case 'lease':
          return option.display_name.match(/Landlord|Tenant/);
        default:
          return !option.display_name.match(/Buyer|Seller|Landlord|Tenant|Client/);
      }
    });
  const baseTotal = useSelector(selectTotalCommissions);
  const dispatch = useDispatch();
  const {
    gross_commission, license_expdt, license_code, outside_broker_name, outside_broker_email,
    outside_broker_phone_number, outside_broker_title, paid_by_cbre, partner,
    voucher_broker_rep_role, voucher_gross_commission_percent,
    onStateChange: onCommissionChange, onStateChanged: onCommissionChanged,
  } = useFormState({
    partner: commission.partner,
    paid_by_cbre: commission.paid_by_cbre,
    voucher_broker_rep_role: commission.voucher_broker_rep_role,
    outside_broker_name: commission.outside_broker_name,
    outside_broker_email: commission.outside_broker_email,
    outside_broker_phone_number: commission.outside_broker_phone_number,
    outside_broker_title: commission.outside_broker_title,
    license_expdt: commission.license_expdt,
    license_code: commission.license_code,
    voucher_gross_commission_percent: commission.voucher_gross_commission_percent,
    gross_commission: commission.gross_commission,
  }, changes => dispatch(updateCommission(id, changes)), typeOverrides);

  const {
    address, address2, city, name: vendorName, postal, territory, country,
    onStateChange: onVendorChange, onStateChanged: onVendorChanged,
  } = useFormState({
    address: commission.vendor?.address || '',
    address2: commission.vendor?.address2 || '',
    city: commission.vendor?.city || '',
    name: commission.vendor?.name || '',
    postal: commission.vendor?.postal || '',
    territory: commission.vendor?.territory || '',
    country: commission.vendor?.country || '',
    // updates on form change
  }, async (changes, current, patchCurrent) => {
    const patch = {};
    if (changes.name) {
      // create new company on name change for EDP
      const update = Object.keys(current).reduce((acc, key) => {
        acc[key] = current[key].value;
        return acc;
      }, {});
      const company = await companiesService.createCompany({ ...update, name: changes.name });
      patchCurrent(company);
      dispatch(updateCommission(id, { vendor: company.id }));
      return;
    }
    if (changes.country) {
      patch.territory = '';
    }
    patchCurrent(patch);
    dispatch(updateCommissionVendor(dealId, commission.vendor.id, { ...changes, ...patch }));
    // TODO: voucher_gross_commission_percent updates the other commissions?
  });

  // update voucher
  const onUpdateVoucher = ({ target: { name, value } }) => {
    dispatch(updateVoucher(voucherId, { [name]: value }));
    dispatch(updateDealPipelineTimestamp(dealId));
  };

  const onEmailChange = (value, valid) => {
    const data = {
      outside_broker_email: value,
    };
    if (valid) {
      dispatch(updateCommission(id, data));
    }
  };

  const onPhoneNumberChange = (value, valid) => {
    const data = {
      outside_broker_phone_number: value,
    };
    if (valid) {
      dispatch(updateCommission(id, data));
    }
  };

  // validation
  const invalidOutsideBrokerName = (showValidation || outside_broker_name.dirty) && !outside_broker_name.value;
  const invalidGrossCommission = (showValidation || gross_commission.dirty) && !gross_commission.value;

  // license expiration date and license # are required when OSB is paid by cbre
  const [paidByCBRE, setPaidByCBRE] = useState(paid_by_cbre.value);
  const togglePaidByCBRE = () => setPaidByCBRE(!paidByCBRE);

  return (
    <div className="outside-broker-item">
      <legend className="secondary-legend">
        Is this outside broker a CBRE affiliate?
      </legend>
      <RadioGroup
        name="partner"
        selectedValue={`${partner.value}`}
        className="radioVal"
        onChange={onCommissionChanged}
        orientation="vertical"
      >
        <RadioButton value="false">No</RadioButton>
        <RadioButton value="true">Yes</RadioButton>
      </RadioGroup>
      <legend className="secondary-legend">
        <span className={`${paid_by_cbre.value === null ? 'asterisk-error' : ''}`}>* </span>
        How is this outside broker being paid?
      </legend>
      <RadioGroup
        name="paid_by_cbre"
        selectedValue={`${paid_by_cbre.value}`}
        className={`${paid_by_cbre.value === null ? 'radiobutton-error' : ''} radioVal`}
        onChange={(event) => {
          togglePaidByCBRE();
          onCommissionChanged(event);
        }}
        orientation="vertical"
      >
        <RadioButton value="true">Paid by CBRE</RadioButton>
        <RadioButton value="false">Paid directly</RadioButton>
      </RadioGroup>
      {paid_by_cbre.value === null ? <p className="asterisk-error"> How is outside broker being paid is required</p> : ''}
      <div className="field">
        <label>Company</label>
        <input
          type="text"
          name="name"
          value={vendorName.value}
          onChange={onVendorChange}
          onBlur={onVendorChanged}
        />
      </div>
      <div className="field">
        <label><span className={`${invalidOutsideBrokerName ? 'asterisk-error' : ''}`}>* </span> Full Name</label>
        <input
          className={invalidOutsideBrokerName ? 'errorInput' : ''}
          type="text"
          name="outside_broker_name"
          value={outside_broker_name.value || ''}
          onChange={onCommissionChange}
          onBlur={onCommissionChanged}
        />
        {invalidOutsideBrokerName ? <p className="asterisk-error">Full Name is required</p> : ''}
      </div>
      <AddressForm
        address={address.value}
        address2={address2.value}
        city={city.value}
        country={country.value}
        postal={postal.value}
        territory={territory.value}
        onChange={e => (e.target.type === 'select-one' ? onVendorChanged(e) : onVendorChange(e))}
        onBlur={onVendorChanged}
        showValidation={showValidation}
      />
      <div className="field field-select">
        <label>Rep Role</label>
        <Select
          defaultOption={voucher_broker_rep_role.value}
          onChange={({ value }) => onCommissionChanged({
            target: { value, type: 'text', name: 'voucher_broker_rep_role' },
          })}
          options={voucherBrokerRepRoleOptions}
        />
      </div>
      <div className="form-row form-row-datepicker">
        <div className="field-group col-2">
          <label>
            {paidByCBRE &&
              <span className={((license_expdt.value === null || license_expdt.value === '') && showValidation) ?
                'asterisk-error' : ''}
              >*
              </span>
            }
            <span> License Expiration Date</span>
          </label>
          <Datepicker
            date={license_expdt.value || ''}
            onDateChange={date => onCommissionChanged({ target: { value: date, name: 'license_expdt', type: 'date' } })}
            requiredText={(!license_expdt.value && paidByCBRE) ? 'License expiration date is required' : null}
          />
        </div>
        <div className="field-group col-2">
          <label>
            {paidByCBRE &&
              <span className={((license_code.value === null || license_code.value === '') && showValidation) ?
              'asterisk-error' : ''}
              >*
              </span>
            }
            <span> License #</span>
          </label>
          <input
            className={((license_code.value === null || license_code.value === '') && paidByCBRE) ? 'errorInput' : ''}
            type="text"
            name="license_code"
            value={license_code.value || ''}
            onChange={onCommissionChange}
            onBlur={onCommissionChanged}
          />
          {((license_code.value === null || license_code.value === '') && showValidation && paidByCBRE) &&
            <p className="asterisk-error">License # is required</p>}
        </div>
      </div>
      <PercentDollarInput
        isRequired={invalidGrossCommission}
        label={`${invalidGrossCommission ? '' : '* '}amount`}
        name={{
          percentage: 'voucher_gross_commission_percent',
          value: 'gross_commission',
          select: 'outside_broker_is_percent',
        }}
        className={invalidGrossCommission ? 'errorInput' : ''}
        onTypeChange={onUpdateVoucher}
        onChange={onCommissionChange}
        onBlur={onCommissionChanged}
        baseTotal={baseTotal}
        percentage={voucher_gross_commission_percent.value}
        value={gross_commission.value}
        isPercent={outside_broker_is_percent}
      />
      <br />
      <PhoneInput
        value={outside_broker_phone_number.value || ''}
        onChange={(value, valid) => { onPhoneNumberChange(value, valid); }}
        onBlur={(value, valid) => { onPhoneNumberChange(value, valid); }}
      />
      <br />
      <EmailInput
        value={outside_broker_email.value || ''}
        onChange={(value, valid) => { onEmailChange(value, valid); }}
        label="Email"
      />
      <div className="field">
        <label>Job Title</label>
        <input
          type="text"
          name="outside_broker_title"
          value={outside_broker_title.value || ''}
          onChange={onCommissionChange}
          onBlur={onCommissionChanged}
        />
      </div>
    </div>
  );
};

OutsideBrokerItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  showValidation: PropTypes.bool.isRequired,
  dealId: PropTypes.number.isRequired,
  voucherId: PropTypes.number.isRequired,
  outside_broker_is_percent: PropTypes.bool.isRequired,
};

export default OutsideBrokerItem;
