
import React from 'react';
import PropTypes from 'prop-types';

const ContactSyncNotification = (props) => {
  const {
    handleDismiss,
    handleTracking,
    message,
    notificationID,
    turducken,
  } = props;

  const handleClick = (id) => {
    handleDismiss(id);
    handleTracking();
  };

  return (
    <div className="contact-sync-notification">
      <p>{message}</p>
      <a
        className="refresh"
        onClick={() => handleClick(notificationID)}
        href="my-contacts"
        {...(turducken ? { target: '_self' } : {})}
      >Refresh
      </a>
    </div>
  );
};

export default ContactSyncNotification;

ContactSyncNotification.propTypes = {
  handleDismiss: PropTypes.func.isRequired,
  handleTracking: PropTypes.func.isRequired,
  message: PropTypes.string,
  notificationID: PropTypes.number.isRequired,
  turducken: PropTypes.bool.isRequired,
};

ContactSyncNotification.defaultProps = {
  message: '',
};
