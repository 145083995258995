/* global CONFIG */
import moment from 'moment';

const {
  initiateLoginUrl,
  ssoCallbackUrl,
} = CONFIG;


const getToken = () => window.localStorage.getItem('JWT');

const setToken = (token) => {
  window.localStorage.setItem('JWT', token);
};

const setLastUrl = (url) => {
  window.localStorage.setItem('lastUrl', url);
};

const getLastUrl = () => window.localStorage.getItem('lastUrl');

const getInitiateLoginUrl = () => `${initiateLoginUrl}?next=${
  encodeURIComponent(ssoCallbackUrl)}`;


const initiateLogin = () => {
  let forwardToUrl = window.location.pathname + window.location.search;
  // forward to dashboard if the last url visited was unauthorized page
  if (forwardToUrl === '/unauthorized') {
    forwardToUrl = '/dashboard';
  }

  setLastUrl(forwardToUrl);
  window.location.href = getInitiateLoginUrl();
};

const getTokenFromUrl = () => {
  const reg = /tk=(.*)/;
  const results = reg.exec(window.location.hash);
  const token = results && results[1];
  return token || false;
};

const handleSSO = () => {
  // Auth flow:
  // (first run, unauthorized user just came to dealiq.io/foobar)
  // 1. no token in URL -> DealIQ redirects to SSO
  // 2. user authenticates on SSO -> SSO redirects back to DealIQ with token in URL
  // (function runs again, except now there is a token in URL)
  // 3. store token -> DealIQ redirects now authorized user to dealiq.io/foobar they came from
  const token = getTokenFromUrl();

  if (token) {
    setToken(token);
    window.location.href = getLastUrl() || '/';
  } else {
    initiateLogin();
  }
};

const clearCookies = () => {
  // cookies are returned as a string of all cookies, separated by ';'
  // each cookie is a key value pair, separated by an '='
  // delete all cookies by setting each key to a past expiration date
  const allCookies = (window.document.cookie).split(';');
  allCookies.forEach((cookie) => {
    const cookieKey = cookie.split('=')[0];
    window.document.cookie = `${cookieKey}; expires = ${moment.utc('2000-01-01').toString()}; path=/ `;
  });
};

const logOut = () => {
  clearCookies();
  window.localStorage.clear();
  window.location.href = `${CONFIG.welcomeUrl}/#/?loggedout=true`;
};


export default {
  getToken, setToken, handleSSO, logOut,
};
