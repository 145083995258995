import { connect } from 'react-redux';

// actions
import { fetchOrCreateVoucher } from '../../../redux/actions/voucher/voucher-actions';
import { addDealRequirements, updateDealRequirements, updateDealPipelineTimestamp } from '../../../redux/actions/deal-actions';

import Financials from './financials';

const mapStateToProps = state => ({
  voucher: state.voucher,
});

const mapDispatchProps = {
  fetchOrCreateVoucher,
  addDealRequirements,
  updateDealRequirements,
  updateDealPipelineTimestamp,
};

const FinancialsContainer = connect(
  mapStateToProps,
  mapDispatchProps,
)(Financials);

export default FinancialsContainer;

