import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

// these are the only types we are currently supporting
// currently we use moment in our date picker we are using moment here.
export const EVENT_VALUE_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  FLOAT: 'float',
  DATE: 'date',
};
export const parseEventValue = (value, type) => {
  switch (type) {
    case EVENT_VALUE_TYPES.FLOAT: {
      return value ? Number.parseFloat(value) : value;
    } case EVENT_VALUE_TYPES.NUMBER: {
      return value ? Number.parseInt(value, 10) : value;
    } case EVENT_VALUE_TYPES.BOOLEAN: {
      return Boolean(value);
    } case EVENT_VALUE_TYPES.DATE: {
      return value ? moment(value).toDate().toISOString() : null;
    } default: {
      return value;
    }
  }
};

const parseModel = (entity, current = {}) => Object.keys(entity).reduce((acc, key) => {
  acc[key] = {
    value: entity[key],
    dirty: 'dirty' in current ? current.dirty : false,
    editing: 'editing' in current ? current.editing : false,
  };
  return acc;
}, {});

const useFormState = (
  entity = {},
  onSave,
  typeOverrides = {},
) => {
  const pendingChanges = useRef();
  const [formState, setFormState] = useState(() => parseModel(entity));

  const onStateChange = ({ target: { name, value, type } }) => {
    if (typeof name === 'object') {
      const update = name.reduce((acc, n) => {
        acc[n] = {
          value: parseEventValue(value[n], typeOverrides[n] || type),
          dirty: true,
          editing: true,
        };
        return acc;
      }, {});
      setFormState({ ...formState, ...update });
    } else {
      setFormState({
        ...formState,
        [name]: {
          value: parseEventValue(value, typeOverrides[name] || type, name),
          dirty: true,
          editing: true,
        },
      });
    }
  };

  const onStateChanged = ({ target: { name, value, type } }) => {
    const update = {};
    // if value is an object we lop thru and add to update if different
    if (typeof name === 'object') {
      const hasChange = !!name.find(n => parseEventValue(value[n], typeOverrides[n] || type) !== entity[n]);
      if (hasChange) {
        name.forEach((n) => {
          const parsed = parseEventValue(value[n], typeOverrides[n] || type);
          update[n] = {
            value: parsed,
            dirty: true,
            editing: false,
          };
        });
      }
    } else {
      const parsed = parseEventValue(value, typeOverrides[name] || type);
      if (entity[name] !== parsed) {
        update[name] = {
          value: parsed,
          dirty: true,
          editing: false,
        };
      }
    }
    const newState = { ...formState, ...update };
    setFormState(newState);
    if (Object.keys(update).length) {
      const changes = Object.keys(update).reduce((acc, key) => {
        acc[key] = update[key].value;
        return acc;
      }, {});
      pendingChanges.current = { ...(pendingChanges.current || {}), ...changes };
      onSave(changes, newState, (patch) => {
        const patchedState = Object.keys(patch).reduce((acc, key) => {
          if (acc[key]) {
            acc[key].value = patch[key];
          }
          return acc;
        }, newState);
        setFormState(patchedState);
      });
    }
  };

  useEffect(() => {
    const isEditing = !!Object.keys(formState).find(key => formState[key].editing);
    if (isEditing) {
      return;
    }
    if (pendingChanges.current) {
      Object.keys(pendingChanges.current).forEach((key) => {
        if (entity[key] === pendingChanges.current[key]) {
          delete pendingChanges.current[key];
        }
      });
      if (Object.keys(pendingChanges.current).length) {
        return;
      }
      // no pending changes, update pending changes and formState
      pendingChanges.current = undefined;
      setFormState(current => parseModel(entity, current));
      return;
    }
    // check to see if the model is different and needs to be rehydrated
    const hasUpdate = !!Object.keys(formState).find(key => entity[key] !== formState[key].value);
    if (hasUpdate) {
      setFormState(current => parseModel(entity, current));
    }
  }, [entity, formState]);
  return {
    ...formState,
    onStateChange,
    onStateChanged,
    pendingChanges: pendingChanges.current,
  };
};

export default useFormState;
