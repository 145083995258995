/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import debounce from 'lodash/debounce';
import unionBy from 'lodash/unionBy';
import { IconButton } from '@cbrebuild/blocks';
import { isEmpty } from 'lodash';

import tasksService from '../../services/tasks-service';
import TaskListPanel from '../../components/deal-details/tasks/task-list-panel';
import PageHeaderDefault from '../../nucleus/header/page-header-default';
import Modal from '../../nucleus/modal/modal';

const MODAL_STATE = {
  NONE: null,
  SUCCESS: 'success',
  FAILURE: 'failure',
};

const TasksPage = (props) => {
  const {
    toast,
    user,
    location: {
      taskId,
    },
    proxy,
  } = props;
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(0);
  const [lastQuery, setLastQuery] = useState(null);
  const [showingIsComplete, setShowingIsComplete] = useState(false);
  const [modalState, setModalState] = useState(MODAL_STATE.NONE);
  const [taskCount, setTaskCount] = useState(0);

  useEffect(() => {
    document.title = 'Deal IQ | Tasks';
  });

  const fetchTasks = async (params) => {
    setIsLoading(true);
    try {
      const {
        count,
        results,
        next,
        previous,
      } = await tasksService.fetchTasks(params);
      setTasks(previous ? unionBy(tasks, results, 'id') : results);
      setHasNext(next);
      setShowingIsComplete(params.is_complete);
      setTaskCount(count);
    } catch (error) {
      console.log('Error fetching tasks: ', error);
      toast('Error fetching tasks');
    }
    setIsLoading(false);
    setLastQuery(params);
  };

  const clearTasks = (updatedTasks) => {
    const clearedTasks = updatedTasks.filter(t => t.is_complete === showingIsComplete);
    setTasks(clearedTasks);
  };

  const debouncedClearTasks = useCallback(debounce(clearTasks, 2000), [setTasks, showingIsComplete]);

  const addTask = async (task) => {
    try {
      const newTask = await tasksService.createTask(task);
      const updatedTasks = [...tasks, newTask];
      setTasks(updatedTasks);
      toast('Task added');
    } catch (error) {
      console.log('Error adding task: ', error);
      toast('Error adding task');
    }
  };

  const updateTask = async (id, task, toastMessage) => {
    try {
      const updatedTask = await tasksService.updateTask(id, task);
      const index = findIndex(tasks, t => t.id === id);
      const updatedTasks = [...tasks];
      updatedTasks[index] = updatedTask;
      setTasks(updatedTasks);
      toast(toastMessage || 'Task updated');
      return updatedTasks;
    } catch (error) {
      console.log('Error updating task: ', error);
      toast('Error updating task');
      return tasks;
    }
  };

  const deleteTask = async (id) => {
    try {
      await tasksService.deleteTask(id);
      const index = findIndex(tasks, t => t.id === id);
      const updatedTasks = [...tasks];
      updatedTasks.splice(index, 1);
      setTasks(updatedTasks);
      toast('Task deleted');
    } catch (error) {
      console.log('Error deleting task: ', error);
      toast('Error deleting task');
    }
  };

  const toggleIsComplete = async (id, isComplete) => {
    const params = { is_complete: isComplete };
    const toastMessage = isComplete && 'Task completed';
    const updatedTasks = await updateTask(id, params, toastMessage);
    debouncedClearTasks(updatedTasks);
  };

  const exportTaskList = async () => {
    const params = {
      ...lastQuery,
    };
    delete params.ordering;
    delete params.page_size;
    delete params.page;
    try {
      await tasksService.exportTasks(params);
      setModalState(MODAL_STATE.SUCCESS);
    } catch (error) {
      setModalState(MODAL_STATE.FAILURE);
    }
  };

  const closeModal = () => {
    setModalState(MODAL_STATE.NONE);
  };

  return (
    <React.Fragment>
      <PageHeaderDefault
        title="Tasks"
        controls={(<IconButton iconName="download" onClick={exportTaskList} />)}
      />
      <div className="page-container tasks-page">
        <TaskListPanel
          user={props.user}
          fetchTasks={fetchTasks}
          analyticProperties={{
            actionPrefix: 'tasks_page',
            categoryPrefix: 'Tasks Page ',
          }}
          tasks={tasks}
          isLoading={isLoading}
          next={hasNext}
          completeTask={toggleIsComplete}
          addTask={addTask}
          updateTask={updateTask}
          deleteTask={deleteTask}
          showDealLink
          taskId={taskId}
          showFSS
          count={taskCount}
        />
      </div>
      {modalState === MODAL_STATE.SUCCESS && (
        <Modal
          showModal
          handleModalSubmit={closeModal}
          handleModalToggle={closeModal}
          modalHeader="CREATING YOUR FILE"
          primaryButtonText="Ok"
          modalWidth={400}
        >
          {!isEmpty(proxy) ?
           (<p>Your file will be emailed to {user.employee_work_email} and {proxy.email} once it is ready.</p>) :
           (<p>Your file will be emailed to {user.employee_work_email} once it is ready.</p>)}
        </Modal>
      )}
      {modalState === MODAL_STATE.FAILURE && (
        <Modal
          showModal
          handleModalSubmit={closeModal}
          handleModalToggle={closeModal}
          modalHeader="Export failed."
          primaryButtonText="Ok"
          modalWidth={400}
        >
          <p>List is Empty</p>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default TasksPage;

TasksPage.propTypes = {
  user: PropTypes.shape({
    employee_work_email: PropTypes.string,
  }).isRequired,
  toast: PropTypes.func.isRequired,
  location: PropTypes.shape({
    taskId: PropTypes.number,
  }),
  proxy: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

TasksPage.defaultProps = {
  location: {
    taskId: undefined,
  },
};
