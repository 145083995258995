import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import uniqueId from 'lodash/uniqueId';
import { Button, Icon } from '@cbrebuild/blocks';
import ContactAutocomplete from '../../autocomplete/contact-autocomplete';
import EmailInput from '../../../nucleus/inputs/email-input';
import PhoneInput from '../../../nucleus/inputs/phone-input';
import { Person, PersonType } from '../../../../src/components/person/index';
import { isValidFirstName, isValidLastName } from '../voucher-validation';

const VoucherContactForm = ({
  dealId,
  contactInfo,
  updateDeal,
  addDealContact,
  updateDealContact,
  deleteDealContact,
  contactType,
  hasClrBtn,
  showValidation,
}) => {
  const contactData = {};
  const phoneCounts = {
    work: 0,
    home: 0,
    mobile: 0,
  };
  contactData.phone_numbers = contactData.phone_numbers || [];
  contactData.email_addresses = contactData.email_addresses || [];
  if (contactData.phone_numbers.length === 0) {
    contactData.phone_numbers.push({
      uniqueId: uniqueId(),
      number: '',
      type: 'work',
      default: '',
    });
    phoneCounts.work = 1;
  } else {
    contactData.phone_numbers = contactData.phone_numbers.map((val) => {
      const phoneNumber = {
        ...val,
        uniqueId: uniqueId(),
        isValid: true,
      };
      phoneCounts[val.type] += 1;
      return phoneNumber;
    });
  }
  if (contactData.email_addresses.length === 0) {
    contactData.email_addresses.push({
      uniqueId: uniqueId(),
      address: '',
      name: '',
      type: 'work',
      default: '',
    });
  } else {
    contactData.email_addresses = contactData.email_addresses.map((val) => {
      const emailAddress = {
        ...val,
        uniqueId: uniqueId(),
        isValid: true,
      };
      return emailAddress;
    });
  }
  const [contact, setContact] = useState(contactData);
  const [contactsInfoData, setContactsInfoData] = useState([]);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const firstNameValid = useMemo(() => isValidFirstName(contact.given_name), [contact.given_name]);
  const lastNameValid = useMemo(() => isValidLastName(contact.surname), [contact.surname]);
  const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
  const [isEmailAddressError, setIsEmailAddressError] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showMore, setshowMore] = useState(false);
  const [totalDealData, setTotalDealData] = useState([]);
  const [totalContactData, setTotalContactData] = useState([]);
  const [jobTitle, setJobTitle] = useState('');

  const contactToAdd = {
    contact_type: contactType,
    deal: dealId,
    email_addresses: [],
    given_name: '',
    is_primary: false,
    phone_numbers: [],
    surname: '',
    create_personal_contact: false,
  };

  const isContactEmpty = (ctEMpty) => {
    const isCntctEmpty = !ctEMpty.given_name &&
      !ctEMpty.surname &&
      (!ctEMpty.phone_numbers.length || (ctEMpty.phone_numbers.length && !ctEMpty.phone_numbers[0].number)) &&
      (!ctEMpty.email_addresses.length || (ctEMpty.email_addresses.length && !ctEMpty.email_addresses[0].address));
    return isCntctEmpty;
  };

  const getContactInfo = (data) => {
    if (!data) {
      return {};
    }
    const cntctInfo = data.filter(ctData => ctData.contact_type === 'deal' && !isContactEmpty(ctData));
    if (cntctInfo.length) {
      return cntctInfo;
    }
    return [];
  };

  const getTypeContactInfo = (data) => {
    if (!data) {
      return {};
    }
    const ctTypeData = data.filter(ctData => ctData.contact_type === contactType);
    if (ctTypeData.length) {
      return ctTypeData[0];
    } else if (ctTypeData.length > 1) {
      ctTypeData.pop();
    }
    return [];
  };

  useEffect(() => {
    if (contactInfo && contactInfo.length) {
      if (contactInfo !== undefined || contactInfo.length !== 0) {
        const data = getContactInfo(contactInfo);
        setTotalDealData(data);
        setTotalContactData(contactInfo);
        let limitedItems = '';
        if (!showMore) {
          limitedItems = data.filter((datas, idx) => idx < 2);
        } else {
          limitedItems = data;
        }
        const typeData = getTypeContactInfo(contactInfo);
        if (typeData.given_name === null && showValidation) {
          setFirstNameError('First Name is required');
        } else {
          setFirstNameError('');
        }
        if (typeData.surname === null && showValidation) {
          setLastNameError('Last Name is required');
        } else {
          setLastNameError('');
        }
        setJobTitle(typeData.job_title);
        if (typeData.length === 0) {
          addDealContact({ ...contactToAdd, display_toast: false });
          setContact(contactToAdd);
          setContactsInfoData(limitedItems);
        } else {
          setContact(typeData);
          setContactsInfoData(limitedItems);
        }
      }
    } else {
      addDealContact({ ...contactToAdd, display_toast: false });
      setContact(contactToAdd);
    }
  }, [contactInfo, addDealContact]);

  const onSubmit = (ctTobeSubmitted) => {
    updateDealContact(ctTobeSubmitted.id, { ...ctTobeSubmitted, display_toast: false });
    totalDealData.unshift(ctTobeSubmitted);
    const updatedContactList = totalContactData.filter(contactList => contactList.contact_type === 'deal' && !isContactEmpty(contactList));
    setTotalDealData(updatedContactList);
    const updatedData = totalDealData.length > 2 && !showMore ? totalDealData.filter((datas, idx) => idx < 2) : totalDealData;
    setContactsInfoData(updatedData);
    addDealContact({ ...contactToAdd, display_toast: false });
    setContact(contactToAdd);
  };

  const phoneNumberOnChange = (value, index, isValid) => {
    if (value === null || value === '') {
      setIsPhoneNumberError(true);
    } else {
      setIsPhoneNumberError(false);
    }
    contact.phone_numbers[index].number = value;
    contact.phone_numbers[index].isValid = isValid;
    setContact(contact);
    if (contact.id && contact.phone_numbers[index].isValid) {
      setIsPhoneValid(true);
      updateDealContact(contact.id, { ...contact, phone_numbers: contact.phone_numbers, display_toast: false });
    }
  };

  const phoneNumberRender = () => {
    let phoneRender = [];
    if (contact.phone_numbers && contact.phone_numbers.length === 0) {
      contact.phone_numbers.push({
        uniqueId: uniqueId(),
        number: '',
        type: 'work',
        default: '',
        isValid: false,
      });
      phoneCounts.work = 1;
    } else {
      contact.phone_numbers = contact.phone_numbers.map((val) => {
        const phoneNumber = {
          ...val,
          uniqueId: uniqueId(),
          isValid: true,
        };
        return phoneNumber;
      });
    }
    if (contact.phone_numbers.length) {
      phoneRender = (
        <div className="phone-row">
          <div className="phone-input">
            <PhoneInput
              value={contact.phone_numbers[0].number}
              onChange={(value, isValid) => { phoneNumberOnChange(value, 0, isValid); }}
              onBlur={(value, isValid) => { phoneNumberOnChange(value, 0, isValid); }}
            />
            <label>{((isPhoneNumberError || contact.phone_numbers[0].number === '') && showValidation)
            && <span className="asterisk-error">Phone # is required</span>}
            </label>
            <label>{!isPhoneValid && <span className="asterisk-error errorMsg">Invalid Phone Number</span>
               }
            </label>
          </div>
        </div>);
    }
    return phoneRender;
  };

  const emailOnChange = (value, isValid) => {
    if (value === null || value === '') {
      setIsEmailAddressError(true);
    } else {
      setIsEmailAddressError(false);
    }
    contact.email_addresses[0].address = value;
    contact.email_addresses[0].isValid = isValid;
    setContact(contact);
  };

  const emailOnBlur = (value, isValid) => {
    contact.email_addresses[0].address = value;
    contact.email_addresses[0].isValid = isValid;
    if (contact.id && isValid) {
      updateDealContact(contact.id, { email_addresses: contact.email_addresses, display_toast: false });
      setIsEmailValid(true);
    }
  };

  const inputOnChange = (inputName) => {
    contact[inputName] = jobTitle;
    setContact(contact);
    if (contact.id) {
      updateDealContact(contact.id, { job_title: contact[inputName], display_toast: false });
    }
  };

  const emailAddressRender = () => {
    let emailRender = [];
    if (contact.email_addresses && contact.email_addresses.length === 0) {
      contact.email_addresses.push({
        uniqueId: uniqueId(),
        address: '',
        name: '',
        type: 'work',
        default: '',
        isValid: false,
      });
    } else {
      contact.email_addresses = contact.email_addresses.map((val) => {
        const emailAddress = {
          ...val,
          uniqueId: uniqueId(),
          isValid: true,
        };
        return emailAddress;
      });
    }

    if (contact.email_addresses.length) {
      emailRender =
        (
          <div className="email-row">
            <div className="email-input">
              <EmailInput
                isValid
                value={contact.email_addresses[0].address}
                onChange={(value, isValid) => { emailOnChange(value, isValid); }}
                onBlur={(value, isValid) => { emailOnBlur(value, isValid); }}
              />
              <label>{((isEmailAddressError || contact.email_addresses[0].address === '') && showValidation)
               && <span className="asterisk-error errorMsg">Email Address is required</span>
               }
              </label>
              <label>{!isEmailValid && <span className="asterisk-error errorMsg">Invalid Email Address</span>
               }
              </label>
            </div>
          </div>);
    }
    return emailRender;
  };

  const nameOnChange = (input, inputField) => {
    const ctChange = contact;
    if (isString(input)) {
      contact[inputField] = input;
    } else {
      const numbers =
        input.phone_numbers.map(val => ({ ...val, uniqueId: uniqueId(), isValid: true }));
      const emailAddresses =
        input.email_addresses.map(val => ({ ...val, uniqueId: uniqueId(), isValid: true }));
      delete ctChange.reference_contact;
      ctChange.contact_type = contactType;
      contact.given_name = input.given_name;
      contact.surname = input.surname;
      contact.phone_numbers = numbers;
      contact.email_addresses = emailAddresses;
      contact.job_title = input.job_title;
    }
    setContact(ctChange);
    if (inputField === 'given_name') {
      if (input === '' && showValidation) {
        setFirstNameError('First Name is required');
        updateDealContact(ctChange.id, { given_name: input, display_toast: false });
      } else {
        setFirstNameError('');
      }
    } else if (inputField === 'surname') {
      if (input === '' && showValidation) {
        setLastNameError('Last Name is required');
        updateDealContact(ctChange.id, { surname: input, display_toast: false });
      } else {
        setLastNameError('');
      }
    }
  };

  const validateForm = () => {
    if (!firstNameValid && showValidation) {
      setFirstNameError('First Name is required');
      return false;
    }
    if (!lastNameValid && showValidation) {
      setLastNameError('Last Name is required');
      return false;
    }
    const phoneNumbersValid =
      contact.phone_numbers.every(value => (value.isValid && !isEmpty(value.number)));
    if (!phoneNumbersValid) {
      setIsPhoneValid(false);
      return false;
    }
    const emailValid =
      contact.email_addresses.every(value => (value.isValid && !isEmpty(value.address)));
    if (!emailValid) {
      return false;
    }
    return true;
  };

  const removeEmptyEmailAddresses = emails => emails.filter(val => !(isEmpty(val.address) && isEmpty(val.name) && isEmpty(val.default)));

  const removeEmptyPhoneNumbers = phones => phones.filter(val => !isEmpty(val.number));

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }
    contact.phone_numbers = removeEmptyPhoneNumbers(contact.phone_numbers);
    if (contact.phone_numbers.length > 0) {
      contact.phone_numbers = contact.phone_numbers.map(({ isValid, uniqueId, ...keep }) => keep); // eslint-disable-line
    }
    contact.email_addresses = removeEmptyEmailAddresses(contact.email_addresses);
    if (contact.email_addresses.length > 0) {
      contact.email_addresses =
        contact.email_addresses.map(({ isValid, uniqueId, ...keep }) => keep); // eslint-disable-line
    }
    contact.surname = contact.surname ? contact.surname : '';
    contact.contact_type = 'deal';
    contact.is_primary = false;
    onSubmit(contact, true);
    updateDeal(dealId, {
      modified: new Date(),
    });
  };

  const handleShowMore = () => {
    const data = totalDealData;
    setshowMore(!showMore);
    if (!showMore) {
      setContactsInfoData(data);
    } else {
      setContactsInfoData(data.length > 2 ? data.filter((datas, idx) => idx < 2) : data);
    }
  };

  const handleContactChange = (changeData) => {
    const dataToChange = changeData;
    if (isContactEmpty(contact)) {
      deleteDealContact(contact.id, false);
      delete contact.id;
    } else {
      updateDealContact(contact.id, { contact_type: 'deal', display_toast: false });
    }
    if (dataToChange.id) {
      updateDealContact(dataToChange.id, { contact_type: contactType, display_toast: false });
    }
    contact.contact_type = 'deal';
    dataToChange.contact_type = contactType;
    let updatedContactList = totalContactData.filter(contactList => contactList.id !== dataToChange.id
      && contactList.contact_type === 'deal' && !isContactEmpty(contactList));
    setTotalDealData(updatedContactList);
    if (updatedContactList.length > 2 && !showMore) {
      const updatedList = updatedContactList;
      updatedContactList = updatedList.filter((datas, idx) => idx < 2);
    }
    setContactsInfoData(updatedContactList);
    setContact(dataToChange);
    if (dataToChange.given_name === '' && showValidation) {
      setFirstNameError('First Name is required');
    } else {
      setFirstNameError('');
    }
    if (dataToChange.surname === '' && showValidation) {
      setLastNameError('Last Name is required');
    } else {
      setLastNameError('');
    }
    if (dataToChange.phone_numbers.length) {
      if (dataToChange.phone_numbers[0].number) {
        const phoneNumbersValid =
      contact.phone_numbers.every(value => (value.isValid && !isEmpty(value.number)));
        if (!phoneNumbersValid) {
          return false;
        }
      }
    }
    if (dataToChange.email_addresses.length) {
      if (dataToChange.email_addresses[0].email_address) {
        const emailValid =
      contact.email_addresses.every(value => (value.isValid && !isEmpty(value.address)));
        if (!emailValid) {
          setIsEmailValid(false);
          return false;
        }
      }
    }
    return true;
  };

  const handleJobChange = (event) => {
    setJobTitle(event.target.value);
  };

  const OnBlur = (input, inputField) => {
    const ctChange = contact;
    if ((input !== null && ctChange[inputField] !== null)) {
      if (ctChange.id && inputField === 'given_name') {
        updateDealContact(ctChange.id, { ...ctChange, given_name: input, display_toast: false });
      } else {
        updateDealContact(ctChange.id, { ...ctChange, surname: input, display_toast: false });
      }
    }
  };

  return (
    <div className="contact-form voucher-contact-poc">
      <div className="legend-style"><legend>Point of Contact</legend></div>
      {hasClrBtn &&
      <div>
        <ul className="contact-style">
          {contactsInfoData && contactsInfoData.map(contactsData =>
        (
          <li key={contactsData.id} className="voucher-file-list-item contact-list" onClick={() => handleContactChange(contactsData)}>
            <Person person={contactsData} type={PersonType.CONTACT} />
            <div>
              <span className="add-icon">
                <Icon iconName="plus-circle-outline" size="large" />
              </span>
            </div>
          </li>
             ))
            }
        </ul>
        { totalDealData.length > 2 &&
        <React.Fragment>
          <span className="poc-text">
            {showMore ?
              <span className="show-more" onClick={handleShowMore}>
                <Icon iconName="minus-square icon-square" size="small" /> <span className="show-text">show Less</span>
              </span> :
              <span className="show-more" onClick={handleShowMore}>
                <Icon iconName="plus-square icon-square" size="small" />
                <span className="show-text">show More</span> ({totalDealData.length - 2})
              </span>}
          </span>
        </React.Fragment> }
      </div>
}
      <p>Any changes made will be reflected on the Deal Contacts page.</p>
      <div className="first-name">
        <label>
          {showValidation && <span className={`${(!firstNameValid) && 'asterisk-error'}`}>* </span>}
          First Name
        </label>
        <ContactAutocomplete
          disableSearchRequest={!dealId}
          initSearchTerm={contact.given_name || ''}
          onChange={input => nameOnChange(input, 'given_name')}
          lastName={contact.surname}
          errorMessage={firstNameError}
          placeholder={dealId ? 'Search Contacts' : ''}
          onBlur={() => OnBlur(contact.given_name, 'given_name')}
        />
      </div>
      <div className="last-name">
        <label>
          {showValidation && <span className={`${!lastNameValid && 'asterisk-error'}`}>* </span>}
          Last Name
        </label>
        <ContactAutocomplete
          disableSearchRequest={!dealId}
          initSearchTerm={contact.surname || ''}
          onChange={input => nameOnChange(input, 'surname')}
          firstName={contact.given_name}
          onBlur={() => OnBlur(contact.surname, 'surname')}
          errorMessage={lastNameError}
          placeholder={dealId ? 'Search Contacts' : ''}
        />
      </div>
      {phoneNumberRender()}
      {emailAddressRender()}
      <div className="job-name">
        <label>Job Title</label>
        <input
          type="text"
          value={jobTitle || ''}
          onChange={handleJobChange}
          onBlur={() => inputOnChange('job_title')}
        />
      </div>
      {(!isContactEmpty(contact) && hasClrBtn) && <Button className="btn-clear" onClick={handleSubmit}>Clear</Button>}
    </div>
  );
};

VoucherContactForm.propTypes = {
  contactInfo: PropTypes.arrayOf(PropTypes.any),
  dealId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  addDealContact: PropTypes.func.isRequired,
  updateDealContact: PropTypes.func.isRequired,
  updateDeal: PropTypes.func.isRequired,
  contactType: PropTypes.string.isRequired,
  deleteDealContact: PropTypes.func,
  showValidation: PropTypes.bool,
  hasClrBtn: PropTypes.bool,
};

VoucherContactForm.defaultProps = {
  contactInfo: {},
  dealId: null,
  deleteDealContact: () => { },
  showValidation: false,
  hasClrBtn: true,
};

export default VoucherContactForm;
