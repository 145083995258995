import { connect } from 'react-redux';

import MainNav from './main-nav';

const mapStateToProps = state => ({
  userOptions: state.userOptions.userOptions,
});

const MainNavContainer = connect(mapStateToProps)(MainNav);

export default MainNavContainer;
