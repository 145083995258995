import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';

import considerationsService from '../../../services/voucher/considerations-service';
import additionalCommissionsService from '../../../services/voucher/additional-commissions-service';
import installmentsService from '../../../services/voucher/installments-service';
import financialsService from '../../../services/voucher/financials-service';
import escalationsService from '../../../services/voucher/escalations-service';

import Notice from '../../../nucleus/notices/notice';
import EmptyState from '../../empty-state/empty-state';
import DealMembers from '../../headers/deal-detail-header/deal-members';
import CommissionsOverview from './commissions-overview';
import CommissionsDetail from './commissions-detail';
import CommissionsDetailTabs from './commissions-detail-tabs/commissions-detail-tabs';
import CommissionsSummary from './commissions-summary';

class CommissionsTab extends React.Component {
  state = {
    financials: null,
    considerations: null,
    additionalCommissions: null,
    installments: null,
    escalations: null,
    numInstallmentsPastDue: null,
    commissionCount: this.props.deal.linked_mta_transactions ? this.props.deal.linked_mta_transactions.length : 0,
    commissionPage: 0,
    permissionError: false,
  };

  componentDidMount() {
    const {
      id,
      linked_mta_transactions,
      is_mta: isMTA,
    } = this.props.deal;

    const {
      commissionPage,
    } = this.state;

    const transactionID = !isMTA ? linked_mta_transactions[commissionPage] : id;
    this.loadCommissionTabData(transactionID);
  }

  fetchFinancials = (dealId) => {
    financialsService.fetchFinancials(dealId).then((financials) => {
      this.setState({ financials });
    }).catch(error => this.handleFinancialsError(error));
  };

  handleFinancialsError = (error) => {
    if ([404, 403].includes(error.status)) {
      this.setState({ permissionError: true });
    }
    console.log('Error :', error);
  };

  fetchConsiderations = (dealId, params) => {
    considerationsService.fetchConsiderations(dealId, params).then((considerations) => {
      this.setState({ considerations });
    }).catch(error => console.log('Error :', error));
  };

  fetchAdditionalCommissions = (dealId, params) => {
    additionalCommissionsService.fetchCommissions(dealId, params).then((additionalCommissions) => {
      this.setState({ additionalCommissions });
    }).catch(error => console.log('Error :', error));
  };

  fetchInstallments = (dealId, params) => {
    installmentsService.fetchInstallments(dealId, params).then((installments) => {
      const numInstallmentsPastDue = installments.filter(i => i.payment_status === 'Receivable').length;
      this.setState({ installments, numInstallmentsPastDue });
    }).catch(error => console.log('Error :', error));
  };

  fetchEscalations = (dealId) => {
    escalationsService.fetchEscalations(dealId).then((escalations) => {
      this.setState({ escalations });
    }).catch(error => console.log('Error :', error));
  };

  fetchPermissions = (dealId) => {
    const {
      loadCommissionTeam,
    } = this.props;
    const params = { deal: dealId, page_size: 999 };
    loadCommissionTeam(params);
  };

  loadCommissionTabData = (transactionID) => {
    const params = { is_mta: true };
    const installment_params = { is_mta: true, ordering: '-installment_date' };
    this.fetchFinancials(transactionID);
    this.fetchConsiderations(transactionID, params);
    this.fetchAdditionalCommissions(transactionID, params);
    this.fetchInstallments(transactionID, installment_params);
    this.fetchEscalations(transactionID);
    this.fetchPermissions(transactionID);
  };

  handleChangeCommission = (page) => {
    const {
      linked_mta_transactions,
    } = this.props.deal;
    this.loadCommissionTabData(linked_mta_transactions[page]);
    this.setState({
      commissionPage: page,
      permissionError: false,
    });
  };

  renderChangeCommission = () => {
    const {
      commissionCount,
      commissionPage,
    } = this.state;

    const prevBtnClass = (commissionPage === 0) ? 'disabled' : '';
    const nextBtnClass = (commissionPage === commissionCount - 1) ? 'disabled' : '';

    return (
      <div className="commission-pagination-row">
        <div className="pagination-info">
          <Icon iconName="info-circle" />
          <p className="info-text">This deal has multiple commission records</p>
        </div>
        <div className="commission-pagination">
          <div className={`pagination-btn ${prevBtnClass}`} onClick={() => this.handleChangeCommission(commissionPage - 1)}>
            <Icon iconName="chevron-left" data-e2e="chevron-left-button" />
          </div>
          <div className="pagination-text">
            <span>Commissions</span>
            <span className="black">{commissionPage + 1}</span>
            <span>&nbsp;/&nbsp;</span>
            <span className="black">{commissionCount}</span>
          </div>
          <div className={`pagination-btn ${nextBtnClass}`} onClick={() => this.handleChangeCommission(commissionPage + 1)}>
            <Icon iconName="chevron-right" data-e2e="chevron-right-button" />
          </div>
        </div>
      </div>
    );
  };

  renderCommissionHeader = () => {
    const {
      analyticProperties,
      relatedTransactionUsers,
      relatedTransactionTeams,
      dealTeams,
      dealUsers,
      deal: {
        permissions: {
          commissioned_users,
        },
      },
    } = this.props;
    const {
      linked_mta_transactions,
    } = this.props.deal;

    const {
      financials,
      commissionPage,
      permissionError,
    } = this.state;

    const commissionMTADealId = financials && financials.deal_id ? financials.deal_id : 'Pending';

    const commissionTeamsToRender = relatedTransactionTeams.map(team => team.team);

    const getAllUsersToSuppress = () => {
      const usersToSuppress = new Set();
      dealUsers.forEach((tm) => { usersToSuppress.add(tm.user.id); });
      dealTeams.forEach((tm) => {
        tm.team.users.forEach((user) => {
          usersToSuppress.add(user.id);
        });
      });
      return Array.from(usersToSuppress);
    };

    const usersToSuppress = getAllUsersToSuppress();
    const usersNotSuppressed = relatedTransactionUsers.filter(tm => !usersToSuppress.includes(tm.user.id));

    const teamsToSuppress = dealTeams.map(teammember => teammember.team.id);
    const teamsNotSuppressed = relatedTransactionTeams.filter(tm => !teamsToSuppress.includes(tm.team.id));

    const commissionedUsers = commissioned_users ? commissioned_users[linked_mta_transactions[commissionPage]] : [];

    return (
      <div className="commission-tab-header">
        <div className="commissions-id-and-permissions">
          <p className="commissions-deal-id"> Deal ID: {permissionError ? '--------------' : commissionMTADealId}</p>
          <div className="commission-permissions">
            {usersNotSuppressed.length === 0 && teamsNotSuppressed.length === 0 && !permissionError && (
              <p className="empty-permission-text">
                Add Commission View Users
              </p>
            )}
            {!permissionError &&
              <DealMembers
                analyticProperties={analyticProperties}
                dealUsers={relatedTransactionUsers}
                dealTeams={commissionTeamsToRender}
                relatedTransactionId={linked_mta_transactions[commissionPage]}
                avatarLargeBreakPoint={9}
                usersToSuppress={usersToSuppress}
                teamsToSuppress={teamsToSuppress}
                hideTeamMemberAvatars
                commissionedUserIds={commissionedUsers}
              />
            }
          </div>

        </div>
        {linked_mta_transactions && linked_mta_transactions.length > 1 && this.renderChangeCommission()}
      </div>
    );
  };

  render() {
    const {
      deal: {
        is_mta,
        linked_mta_transactions,
      },
    } = this.props;
    const {
      additionalCommissions,
      considerations,
      escalations,
      financials,
      installments,
      numInstallmentsPastDue,
      permissionError,
    } = this.state;

    const myCommissionSummary = financials && financials.financial_details.my_commission_summary;
    const overviewData = financials && financials.financial_details.commission_overview_table;
    const myCommissionDetail = financials && financials.financial_details.deal_financial_overview;

    if (!is_mta && linked_mta_transactions.length === 0) {
      return null;
    }

    return (
      <div className="commissions-tab">
        { !is_mta && linked_mta_transactions && this.renderCommissionHeader() }
        {!!numInstallmentsPastDue &&
          <Notice
            text={`${numInstallmentsPastDue} installment${numInstallmentsPastDue !== 1 ? 's are' : ' is'} past due`}
            type="critical"
            onDismiss={() => this.setState({ numInstallmentsPastDue: null })}
          />
        }
        { permissionError ?
          <div className="empty-state-container">
            <EmptyState
              type="commission"
              message="You do not have permission to view this commission"
            />
          </div>
        :
          <div className="detail-panels-layout">
            <div className="col-two-thirds">
              {overviewData && <CommissionsOverview data={overviewData} />}
              {installments && considerations && escalations && additionalCommissions &&
                <CommissionsDetailTabs
                  additionalCommissions={additionalCommissions}
                  considerations={considerations}
                  escalations={escalations.results}
                  installments={installments}
                />
              }
            </div>
            <div className="col-one-third">
              {myCommissionSummary && <CommissionsSummary myCommissionSummary={myCommissionSummary} />}
              {myCommissionDetail && <CommissionsDetail myCommissionDetail={myCommissionDetail} />}
            </div>
          </div>
        }
      </div>
    );
  }
}

export default CommissionsTab;

CommissionsTab.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    linked_mta_transactions: PropTypes.arrayOf(PropTypes.number),
    is_mta: PropTypes.bool.isRequired,
    permissions: PropTypes.shape({
      commissioned_users: PropTypes.shape(),
    }),
  }),
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  relatedTransactionUsers: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
  })),
  dealUsers: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
  })),
  relatedTransactionTeams: PropTypes.arrayOf(PropTypes.shape()),
  dealTeams: PropTypes.arrayOf(PropTypes.shape()),
  loadCommissionTeam: PropTypes.func.isRequired,
};

CommissionsTab.defaultProps = {
  relatedTransactionUsers: [],
  dealUsers: [],
  relatedTransactionTeams: [],
  dealTeams: [],
  deal: {
    linked_mta_transactions: [],
    permissions: {
      commissioned_users: {},
    },
  },
};
