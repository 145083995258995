import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { Icon } from '@cbrebuild/blocks';
import readOnlySVG from '../../assets/images/svg/avatar-read-only-status.svg';

class Avatar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bgStyle: undefined,
      initials: '',
    };
  }

  componentDidMount() {
    this.setAvatarContent();
  }

  // component receives new props when userService returns API data
  // https://reactjs.org/docs/react-component.html#componentdidupdate
  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      this.setAvatarContent();
      return true;
    }
    return false;
  }

  getAvatarClass = () => {
    const {
      sizing,
    } = this.props;
    const size = sizing.length ? ` ${sizing}` : '';
    return `hoverstate nd-avatar${size}`;
  };

  getHoverClass = () => {
    const {
      hoverAlign,
    } = this.props;
    return `hoverstatetext align-${hoverAlign}`;
  };

  setAvatarContent = () => {
    const {
      isReadOnly,
      firstName,
      lastName,
      image,
    } = this.props;

    const initials = (firstName && lastName)
      ? firstName.charAt(0) + lastName.charAt(0)
      : firstName && firstName.charAt(0);

    this.setState({ initials });

    // url(readOnly.svg), url(image.jpg)
    const readOnlyURL = isReadOnly ? `url(${readOnlySVG}), ` : '';

    // check if image is valid, using promise for async image request
    this.checkImage()
      .then(() => {
        this.setState({ bgStyle: { backgroundImage: `${readOnlyURL}url(${image})` }, initials: '' });
      })
      .catch(() => {
        if (isReadOnly) {
          this.setState({ bgStyle: { backgroundImage: `url(${readOnlySVG})` } });
        }
        // need catch block to avoid jest errors
      });
  };

  // image loading handled in separate async method to allow test stubs
  checkImage = () => new Promise((resolve, reject) => {
    const {
      image: url,
    } = this.props;

    const image = new Image();
    image.onload = () => {
      resolve();
    };
    image.onerror = () => {
      reject();
    };
    if (url !== null) {
      image.src = url;
    } else {
      reject();
    }
  });

  render() {
    const {
      firstName,
      lastName,
      enableHover,
      subIcon,
    } = this.props;

    const {
      bgStyle,
      initials,
    } = this.state;

    const fullName = `${firstName} ${lastName}`;
    const renderHover = enableHover ? <span className={this.getHoverClass()}>{fullName}</span> : '';
    const icon = subIcon ? <Icon className="sub-icon" iconName={subIcon} /> : '';

    return (
      <span className="profile">
        <p
          className={this.getAvatarClass()}
          style={bgStyle}
          data-initials={initials}
        >
          {icon}
          {renderHover}
        </p>
      </span>
    );
  }
}

Avatar.propTypes = {
  image: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  sizing: PropTypes.string,
  enableHover: PropTypes.bool,
  hoverAlign: PropTypes.oneOf(['left', 'center', 'right']),
  subIcon: PropTypes.string,
  isReadOnly: PropTypes.bool,
};

Avatar.defaultProps = {
  image: null,
  firstName: '',
  lastName: '',
  sizing: 'md',
  enableHover: false,
  hoverAlign: 'center',
  subIcon: null,
  isReadOnly: false,
};

export default Avatar;
