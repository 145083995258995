import React from 'react';
import PropTypes from 'prop-types';

const ButtonSegmented = (props) => {
  const {
    isActive,
    isDisabled,
    isMultiSelect,
    onChange,
    option,
  } = props;

  const handleOnChange = (selectedOption) => {
    if (selectedOption.disabled) {
      return;
    }
    onChange(selectedOption);
  };

  const activeOptionClass = isActive ? ' active' : '';
  const disabledOptionClass = isDisabled ? ' disabled' : '';

  return (
    <div className={`nd-button-segmented${activeOptionClass}${disabledOptionClass}`}>
      <label>
        {option.display_name}
        <input
          id="button-segmented"
          data-e2e={`filter-multiselect-${option.value}`}
          type={isMultiSelect ? 'checkbox' : 'radio'}
          name="button-segmented"
          value={option.value}
          onChange={() => handleOnChange(option)}
        />
      </label>
    </div>
  );
};

ButtonSegmented.propTypes = {
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  option: PropTypes.shape({
    display_name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
    ]),
    value: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

ButtonSegmented.defaultProps = {
  isActive: false,
  isDisabled: false,
  isMultiSelect: false,
};

export default ButtonSegmented;
