import React, { Component } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { IconButton } from '@cbrebuild/blocks';

import Select from '../../../nucleus/select/select';
import NumberInput from '../../../nucleus/inputs/number-input';
import Currency from '../../../nucleus/formats/currency';

class AdditionalCommissionItem extends Component {
  onCommissionChange = (key, val) => {
    this.props.updateCommission(this.props.commission.id, key, val);
  }

  onCommissionBlur = () => {
    this.props.calculateCommission(this.props.commission.id);
  }

  onChangeOption = (option) => {
    this.onCommissionChange('acommission_type', option.value);
  }

  onRemoveClick = () => {
    this.props.deleteCommission(this.props.commission.id);
  }

  render() {
    const {
      acommission_type,
      total_units,
      amount,
      commission_amount,
    } = this.props.commission;

    const closeIcon = <IconButton className="blxs-button-icon-small" iconName="close-circle" onClick={this.onRemoveClick} variant="basic" />;

    const defaultOption = find(
      this.props.additionalCommissionTypeOptions,
      options => options.value === acommission_type,
    );

    return (
      <div className="table-row form-row">
        <div className="table-cell">
          <Select
            defaultOption={defaultOption}
            onChange={this.onChangeOption}
            options={this.props.additionalCommissionTypeOptions}
          />
        </div>
        <div className="table-cell">
          <NumberInput
            value={total_units}
            precision={0}
            updateValue={val => this.onCommissionChange('total_units', val)}
            onBlur={this.onCommissionBlur}
          />
        </div>
        <div className="table-cell">
          <NumberInput
            value={amount}
            precision={4}
            updateValue={val => this.onCommissionChange('amount', val)}
            onBlur={this.onCommissionBlur}
          />
        </div>
        <div className="table-cell">
          <Currency
            value={commission_amount}
            emptyState="--"
          />
        </div>
        <div className="table-cell">
          {closeIcon}
        </div>

      </div>
    );
  }
}

AdditionalCommissionItem.propTypes = {
  commission: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    acommission_type: PropTypes.string,
    total_units: PropTypes.number,
    amount: PropTypes.number,
    commission_amount: PropTypes.number,
  }).isRequired,
  additionalCommissionTypeOptions: PropTypes.arrayOf(PropTypes.shape()),
  updateCommission: PropTypes.func.isRequired,
  calculateCommission: PropTypes.func.isRequired,
  deleteCommission: PropTypes.func.isRequired,
};

AdditionalCommissionItem.defaultProps = {
  additionalCommissionTypeOptions: [],
};

export default AdditionalCommissionItem;
