import {
  ADD_TEAM_SUCCESS,
  ADD_TEAM_FAILURE,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAILURE,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAILURE,
  FETCH_TEAM_BY_ID_SUCCESS,
  FETCH_TEAM_BY_ID_FAILURE,
  ADD_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_FAILURE,
  REMOVE_TEAM_MEMBER_SUCCESS,
  REMOVE_TEAM_MEMBER_FAILURE,
  REMOVE_TEAM_AND_MEMBER_SUCCESS,
  REMOVE_TEAM_AND_MEMBER_FAILURE,
  UPDATE_USER_TEAM_AUTO_ADD_SUCCESS,
  UPDATE_USER_TEAM_AUTO_ADD_FAILURE,
} from '../actions/action-types';

const initialState = {
  teams: [],
};

const addTeamSuccess = (state, { payload: newTeam }) => ({
  ...state,
  teams: [newTeam, ...state.teams],
});

const updateTeamSuccess = (state, { payload: team }) => {
  const teamToUpdate = state.teams.filter(t => t.id === team.id);
  const teams = [...state.teams];
  teams[teams.indexOf(...teamToUpdate)] = team;
  return ({
    ...state,
    teams,
  });
};

const fetchTeamsSuccess = (state, action) => {
  const {
    results,
  } = action.payload;
  const teams = results;
  return ({
    ...state,
    teams,
  });
};

const deleteTeamSuccess = (state, { payload: team }) => {
  // deletes the team from the redux store
  const restTeams = state.teams.filter(t => t.id !== team.id);
  return ({
    ...state,
    teams: [...restTeams],
  });
};

const teamsFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const teamsPage = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEAM_SUCCESS:
      return addTeamSuccess(state, action);
    case FETCH_TEAMS_SUCCESS:
      return fetchTeamsSuccess(state, action);
    case REMOVE_TEAM_AND_MEMBER_SUCCESS:
      return deleteTeamSuccess(state, action);
    case UPDATE_USER_TEAM_AUTO_ADD_SUCCESS:
    case ADD_TEAM_MEMBER_SUCCESS:
    case REMOVE_TEAM_MEMBER_SUCCESS:
    case UPDATE_TEAM_SUCCESS:
    case FETCH_TEAM_BY_ID_SUCCESS:
      return updateTeamSuccess(state, action);
    case ADD_TEAM_FAILURE:
    case UPDATE_TEAM_FAILURE:
    case FETCH_TEAMS_FAILURE:
    case FETCH_TEAM_BY_ID_FAILURE:
    case ADD_TEAM_MEMBER_FAILURE:
    case REMOVE_TEAM_MEMBER_FAILURE:
    case REMOVE_TEAM_AND_MEMBER_FAILURE:
    case UPDATE_USER_TEAM_AUTO_ADD_FAILURE:
      return teamsFailure(state, action);
    default:
      return state;
  }
};

export default teamsPage;
