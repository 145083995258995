import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import { Checkbox } from '@cbrebuild/blocks';

import DealInformation from '../new-deal/deal-information';
import DealOverview from '../new-deal/deal-overview';
import Modal from '../../nucleus/modal/modal';
import NewDealProperties from '../new-deal/new-deal-properties';
import DealTeamInformation from '../new-deal/deal-team-information';
import Toast from '../../nucleus/toast/toast';
import userEventService from '../../services/user-event-service';
import teamMemberService from '../../services/team-members-service';
import dealsService from '../../services/deals-service';
import companiesService from '../../services/companies-service';
import convertDealTypeRepRole from '../../utils/deal-type-rep-role';
import proxyService from '../../services/proxy-service';

const analyticProperties = {
  newDeal: 'new_deal_',
  dealCreation: 'deal_creation_',
  category: 'Deal Creation',
};

const leaseLandlord = {
  display_name: 'Lease - Landlord',
  value: 'landlord',
  dataE2e: 'flyout-landlord',
};

// CreateDealModal can be used for 3 different use cases:
// 1. Creating a new deal from the global "NEW DEAL" btn from the sidebar
//    * if there are no checks for dealToDuplicate or property from props,
//      then we know we are looking at global new deal code
// 2. Duplicating a deal from the Deal Detail view from the "Actions" dropdown
//    * if dealToDuplicate exists in props, then we know we are in the duplicate
//      deal workflow
// 3. Creating a new from from the Property Detail view
//    * if a property exists in props, then we know we are in the new deal workflow
//      off of an existing property
class CreateDealModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: '',
      landlordCompany: '',
      dealType: isEmpty(props.property) ? 'Select' : leaseLandlord,
      dealName: '',
      dealStage: 'prospect',
      shouldDuplicateContacts: false,
      shouldDuplicateAttachments: false,
      teamMembers: [],
      dealTeams: [],
      isRequested: false,
      properties: !isEmpty(props.property) ? [props.property] : [],
      submitted: false,
      submissionError: '',
      showCompanyWarning: false,
      showPropertyNotice: false,
      selectedPropertyIsInvalid: false,
      redirectPath: '',
    };
  }

  componentDidMount() {
    const {
      fetchTeams,
      clientCompany,
      dealToDuplicate,
      dealFromExternalLink,
    } = this.props;
    const {
      teamsInitialized,
    } = this.state;
    if (dealToDuplicate) {
      this.initializeDuplicateDeal();
      this.initializeDuplicateDealCollaborators();
      this.initializeDuplicateDealTeams();
    } else {
      if (dealFromExternalLink) {
        this.initializeDealFromExternalLink();
      }

      if (clientCompany) {
        this.setCompany(clientCompany);
      }
      this.initializeTeamMembers();
      if (!teamsInitialized) {
        fetchTeams()
          .then(() => this.initializeTeams())
          .catch(() => this.initializeTeams());
      }
    }
  }

  onCompanyAlertDismiss = () => {
    this.setState({ showCompanyWarning: false });
  };

  onPropertyNoticeDismiss = () => {
    this.setState({ showPropertyNotice: false });
  };

  onNewDealFormSubmit = async () => {
    const {
      addDealTeams,
    } = this.props;
    if (!this.state.isRequested) {
      this.setState({ isRequested: true });
      if (this.validateForm()) {
        const body = await this.getDealPayload();
        dealsService.addDeal(body)
          .then(async (data) => {
            if (!data || !data.id) {
              throw new Error('new-deal-service returned erraneous data: ', data);
            }
            await this.trackNewDeal(body, data);
            addDealTeams(data.id, this.teamsToTeamIds(body.deal_teams));
            this.updateDealCounts();
            this.onFormSuccess(data.id);
          })
          .catch((error) => {
            this.onFormFailure(error);
            this.setState({ isRequested: false });
          });
      } else {
        this.setState({
          isRequested: false,
          submitted: true,
          selectedPropertyIsInvalid: false,
        });
      }
    }
  };

  onFormSuccess = (id) => {
    const {
      properties,
      dealStage,
    } = this.state;
    const { closeModal, turducken } = this.props;
    if (properties.length > 1) {
      const stage = dealStage === 'prospect' ? 'prospects' : 'executing';
      const redirectPath = `/deals/${stage}`;
      if (turducken) {
        window.location.href = redirectPath;
      } else {
        this.setState({ redirectPath });
      }
    } else {
      const redirectPath = `/deals/detail/${id}/overview`;
      if (turducken) {
        window.location.href = redirectPath;
      } else {
        this.setState({ redirectPath });
      }
    }
    closeModal();
  };

  onFormFailure = () => {
    this.setSubmissionError('There was a problem submitting your deal, please try again.');
    setTimeout(() => {
      this.setSubmissionError('');
    }, 2000);
  };

  updateDealCounts = () => {
    const eventName = 'updateDealCounts';
    const event = new Event(eventName);

    window.dispatchEvent(event);
  }

  // due to BE limitations, get the deal_type based on the rep_role.
  // rep_role === dealTypeValue, the return is the deal_type
  getDealType = (dealTypeValue) => { // eslint-disable-line
    if (dealTypeValue === 'tenant') {
      return 'lease';
    }
    if (dealTypeValue === 'landlord') {
      return 'lease';
    }
    if (dealTypeValue === 'buyer') {
      return 'sale';
    }
    if (dealTypeValue === 'seller') {
      return 'sale';
    }
    if (dealTypeValue === 'consulting') {
      return 'consulting';
    }
    if (dealTypeValue === 'other') {
      return 'other';
    }
  };

  getDealPayload = async () => {
    const {
      dealToDuplicate,
      dealFromExternalLink,
    } = this.props;
    const {
      company,
      landlordCompany,
      dealType,
      dealName,
      dealStage,
      dealTeams,
      shouldDuplicateContacts,
      shouldDuplicateAttachments,
    } = this.state;

    const propertiesPayload = this.getPropertiesPayload();
    const companyPayload = await this.getCompanyPayload(company);
    const landlordCompanyPayload = landlordCompany ? await this.getCompanyPayload(landlordCompany) : '';
    const params = {
      deal_life_cycle_stage: dealStage,
      name: dealName,
      team_members: this.getTeamPayload(),
      deal_teams: dealTeams,
      rep_role: dealType.value,
      deal_type: this.getDealType(dealType.value),
    };

    if (dealType.value === 'landlord') {
      params.counter_party = companyPayload;
      if (landlordCompanyPayload) {
        // params.client = '' is not accepted by BE
        // set only if it exsits.
        params.client = landlordCompanyPayload;
      }

      if (dealFromExternalLink && dealFromExternalLink.client) {
        params.client = await this.getCompanyPayload(dealFromExternalLink.client);
      }
    } else {
      params.client = companyPayload;

      if (dealFromExternalLink && dealFromExternalLink.counter_party) {
        params.counter_party = await this.getCompanyPayload(dealFromExternalLink.counter_party);
      }
    }

    if (propertiesPayload) {
      params.properties = propertiesPayload;
    }

    // Duplicate Deal Logic
    if (dealToDuplicate) {
      Object.assign(params, {
        duplicate_deal_id: dealToDuplicate.id,
        is_archived: dealToDuplicate.is_archived,
        is_mta: false, // BE to reinforce
        salesforce_id: '', // BE to reinforce
        user_wants_salesforce_export: false, // BE to reinforce
        deal_id: null, // BE to reinforce
        duplicate_deal_contacts: !!shouldDuplicateContacts,
        duplicate_deal_attachments: !!shouldDuplicateAttachments,
      });
    }
    return params;
  };

  getCreateCompanyPayload = async (company) => {
    let body = {
      name: null,
      country: 'United States',
      address: null,
      address2: null,
      city: null,
      territory: null,
      postal: null,
    };

    if (typeof company === 'string') {
      // handle new company
      body = {
        ...body,
        name: company,
      };
    } else {
      // handle copied company
      body = {
        ...body,
        ...company,
      };

      // handle selected company
      // flatten address object from selected company
      if ((typeof company.address) !== 'string' && company.address !== null) {
        try {
          // try and get the full company obj
          const fullCompany = await companiesService.getCompany(company.id);
          body = {
            ...fullCompany,
          };
        } catch (error) {
          // on failure use the one we got from autocomplete
          body = {
            ...body,
            ...company,
          };
        }
      }
    }
    return body;
  };

  getCompanyPayload = async (company) => {
    const data = await this.getCreateCompanyPayload(company);
    let newCompany;
    try {
      newCompany = await companiesService.createCompany(data);
      return { id: newCompany.id };
    } catch (error) {
      console.error('Error adding company: ', error);
      return '';
    }
  };

  getPropertyErrorMessage = () => {
    const { submitted, selectedPropertyIsInvalid } = this.state;
    const invalidPropertyMessage = 'Please select a property with a city, state, zip and address.';
    const propertyRequiredMessage = 'A Property is required, please select one from the list';
    if (submitted && !this.isValidProperty() && selectedPropertyIsInvalid) {
      return invalidPropertyMessage;
    }
    if (submitted && !this.isValidProperty()) {
      return propertyRequiredMessage;
    }
    if (selectedPropertyIsInvalid) {
      return invalidPropertyMessage;
    }
    return '';
  };

  getPropertiesPayload = () => {
    const { properties, dealType } = this.state;
    const { property } = this.props;
    let propertiesPayload;
    if (isEmpty(property)) {
      propertiesPayload = properties.map(p => ({
        name: p.name,
        address: p.building.address,
        city: p.building.city,
        postal_code: p.building.postal_code,
        state_abbreviation: p.building.state_abbreviation || p.building.stateName,
        country: p.building.country,
      }));
    } else {
      propertiesPayload = [{
        name: property.name,
        address: property.building.address,
        city: property.building.city,
        postal_code: property.building.postal_code,
        state_abbreviation: property.building.state_abbreviation || property.building.stateName,
        country: property.building.country,
      }];
    }
    return dealType.value === 'landlord' ? propertiesPayload : null;
  };

  getTeamPayload = () => {
    const { user } = this.props;
    const {
      teamMembers,
    } = this.state;
    return teamMembers
      .filter(m => m.user_id !== user.id)
      .map(member => ({
        user_id: member.user_id,
        deal_id: null,
      }));
  };

  setDealName = (dealName) => {
    this.setState({
      dealName,
    });
  };

  setDealType = (dealType) => {
    const { properties } = this.state;
    this.setState({
      dealType,
    });
    if (dealType.value !== 'landlord' && properties.length) {
      this.setState({
        properties: [],
      });
    }
  };

  setCompany = (company) => {
    const showCompanyWarning = !company.id && company !== '';
    this.setState({
      company,
      showCompanyWarning,
    });
  };

  setLandlordCompany = (landlordCompany) => {
    // landlord company field isn't required and doesn't need warning
    this.setState({
      landlordCompany,
    });
  }

  setDealStage = (type) => {
    this.setState({
      dealStage: type,
    });
  };

  setSubmissionError = (submissionError) => {
    this.setState({
      submissionError,
    });
  };

  teamsToTeamIds = dealTeams => dealTeams.map(dealTeam => dealTeam.id);

  trackNewDeal = (body, data) => {
    const { dealFromExternalLink } = this.props;

    const eventData = {
      dealType: body.deal_type,
      dealLifeCycle: body.deal_life_cycle_stage,
      isDealNameEntered: !!body.name.length,
      isNewCompany: body.client ? !body.client.id : !body.counter_party.id,
      member_pks: body.team_members,
      team_pks: body.deal_teams,
    };

    if (dealFromExternalLink && dealFromExternalLink.mta_id) {
      eventData.sourced_by = 'Performance IQ Expiring Lease Delegation';
      eventData.expiring_lease_mta_id = dealFromExternalLink.mta_id;
    }

    userEventService.trackEvent({
      eventCategory: analyticProperties.category,
      eventAction: `${analyticProperties.newDeal}created`,
      eventLabel: data.id,
      eventData,
    });
  };

  initializeTeamMembers = async () => {
    const proxyProfile = await proxyService.fetchProxyUser().then(async (res) => {
      const activeProxyProfile = res.privilege_access_users.find(proxyAccess => proxyAccess.is_active);
      return activeProxyProfile?.user || undefined;
    });
    const {
      user,
    } = this.props;
    const proxyProfileUser = proxyProfile ? {
      ...proxyProfile,
      user_id: proxyProfile.id,
      isSelf: false,
      isProxy: true,
    } : undefined;
    const selfUser = {
      ...user,
      user_id: user.id,
      isSelf: true,
    };
    this.setState({
      teamMembers: [proxyProfile ? proxyProfileUser : selfUser],
    });
  };

  initializeTeams = () => {
    const {
      teams,
    } = this.props;
    const autoAddedTeams = teams.filter(team => team.auto_add_to_deals === true);
    this.setState({
      dealTeams: [...autoAddedTeams],
      teamsInitialized: true,
    });
  };

  addProperty = (property, isUnknownProperty = false) => {
    const {
      properties,
    } = this.state;
    // the property cannot already be in the list
    const found = properties.find(p => property.magicKey === p.magicKey);
    if (found) {
      return;
    }
    // validate the selected property
    if (!isUnknownProperty) {
      const isValidProperty = this.isValidSelectedProperty(property);
      if (!isValidProperty) {
        return;
      }
    }
    const updateProperties = [...properties, property];
    const updatedShowPropertyNotice = updateProperties.length > 1;
    this.setState({
      properties: updateProperties,
      showPropertyNotice: updatedShowPropertyNotice,
    });
  };

  removeProperty = (magicKey) => {
    const { properties } = this.state;
    const updateProperties = properties.filter(property => magicKey !== property.magicKey);
    this.setState({
      properties: updateProperties,
    });
  };

  addTeam = (team) => {
    const {
      dealTeams,
    } = this.state;

    this.setState({ dealTeams: [...dealTeams, team] });
  };

  addTeamMember = (broker) => {
    const {
      user,
    } = this.props;
    const {
      teamMembers,
    } = this.state;
    // the broker we are adding cannot be the user
    if (user.id === broker.user_id) {
      return;
    }
    // the broker cannot already be a teammember
    const isTeamMember = teamMembers.find(member => broker.user_id === member.user_id);
    if (isTeamMember) {
      return;
    }
    const member = {
      ...broker,
      isSelf: false,
    };
    // This will sort selfUser first then other members
    const members = [...teamMembers, member].sort((a, b) => b.isSelf - a.isSelf);
    this.setState({
      teamMembers: [...members],
    });
  };

  removeTeam = (teamId) => {
    const updatedTeams = this.state.dealTeams.filter((team) => {
      if (teamId !== team.id) {
        return true;
      }
      return false;
    });
    this.setState({ dealTeams: updatedTeams });
  };

  removeTeamMember = (userId) => {
    const updatedTeamMembers = this.state.teamMembers.filter((member) => {
      if (userId !== member.user_id) {
        return true;
      }
      return false;
    });

    this.setState({
      teamMembers: updatedTeamMembers,
    });
  };

  validateForm = () => this.isValidCompany() && this.isValidDealType() && this.isValidProperty();

  isValidCompany = () => !(!this.state.company || Object.keys(this.state.company).length === 0);

  isValidDealType = () => this.state.dealType !== 'Select';

  isValidProperty = () => this.state.dealType.value !== 'landlord' || (this.state.dealType.value === 'landlord' && this.state.properties.length > 0);

  invalidPropertyFields = property =>
    ['address', 'city', 'state_abbreviation', 'postal_code'].filter(key => !(property.building[key]));

  isValidSelectedProperty = (property) => {
    if (this.invalidPropertyFields(property).length > 0) {
      this.setState({
        selectedPropertyIsInvalid: true,
      });
      return false;
    }
    this.setState({
      selectedPropertyIsInvalid: false,
    });
    return true;
  };

  initializeDealFromExternalLink = () => {
    const { dealFromExternalLink } = this.props;

    const isLeaseLandlord = dealFromExternalLink.deal_type === 'lease' && dealFromExternalLink.rep_role === 'landlord';
    const company = isLeaseLandlord ? dealFromExternalLink.counter_party : dealFromExternalLink.client;
    const landlordCompany = isLeaseLandlord ? dealFromExternalLink.client : dealFromExternalLink.counter_party;

    this.setState({
      company: company || '',
      landlordCompany: landlordCompany || '',
      dealType: dealFromExternalLink.deal_type ? convertDealTypeRepRole(dealFromExternalLink.deal_type, dealFromExternalLink.rep_role) : this.state.dealType,
      dealName: dealFromExternalLink.name,
      shouldDuplicateContacts: false,
      shouldDuplicateAttachments: false,
      properties: [],
      propertyNameText: dealFromExternalLink.property,
    });
  };

  initializeDuplicateDeal = () => {
    const {
      dealType,
      dealName,
      dealStage,
    } = this.state;
    const {
      dealToDuplicate,
    } = this.props;
    const hasInitialProperty = dealToDuplicate.primary_occupied_property && dealToDuplicate.primary_occupied_property.id;
    const initialProperty = hasInitialProperty ? [dealToDuplicate.primary_occupied_property] : [];
    const isLeaseLandlord = dealToDuplicate.deal_type === 'lease' && dealToDuplicate.rep_role === 'landlord';
    const company = isLeaseLandlord ? dealToDuplicate.counter_party : dealToDuplicate.client;
    const landlordCompany = isLeaseLandlord ? dealToDuplicate.client : dealToDuplicate.counter_party;

    this.setState({
      company: company || '',
      landlordCompany: landlordCompany || '',
      dealType: dealToDuplicate.deal_type ? convertDealTypeRepRole(dealToDuplicate.deal_type, dealToDuplicate.rep_role) : dealType,
      dealName: dealToDuplicate.name ? `${dealToDuplicate.name} (Copy)` : dealName,
      dealStage: dealToDuplicate.deal_life_cycle_stage === 'excuting' ? dealToDuplicate.deal_life_cycle_stage : dealStage,
      shouldDuplicateContacts: true,
      shouldDuplicateAttachments: true,
      properties: initialProperty,
    });
  };

  initializeDuplicateDealCollaborators = () => {
    // set up individual collaborators on a duplicate deal
    const {
      dealToDuplicate,
      dealUsersToDuplicate,
    } = this.props;
    if (dealUsersToDuplicate) {
      this.modifyDuplicateDealCollaborators(dealUsersToDuplicate);
    } else {
      teamMemberService.getDealTeam({
        deal: dealToDuplicate.id,
        page_size: 999,
      })
        .then((data) => {
          this.modifyDuplicateDealCollaborators(data.results);
        })
        .catch(error => console.log('Error fetching deal team: ', error));
    }
  };

  modifyDuplicateDealCollaborators = (dealIndividualCollaborators) => {
    const {
      user,
    } = this.props;
    const team = dealIndividualCollaborators ?
      dealIndividualCollaborators
        .map(member => ({
          ...member.user,
          user_id: member.user.id,
          isSelf: false,
        })) : [];

    const selfCollaborator = team.find(member => user.id === member.user_id);
    const selfUpdated = {
      ...selfCollaborator,
      isSelf: true,
    };
    team[team.indexOf(selfCollaborator)] = selfUpdated;
    const sortedTeam = team.sort((a, b) => b.isSelf - a.isSelf);
    this.setState({ teamMembers: sortedTeam });
  };

  initializeDuplicateDealTeams = () => {
    // set up teams on a duplicate deal
    const {
      dealTeamsToDuplicate,
      fetchTeams,
    } = this.props;
    fetchTeams();
    if (dealTeamsToDuplicate) {
      const dealTeams = dealTeamsToDuplicate.map(dealTeam => dealTeam.team);
      this.setState({ dealTeams });
    }
  };

  duplicateContacts = ({ target: { checked } }) => {
    this.setState({
      shouldDuplicateContacts: checked,
    });
  };

  duplicateFiles = ({ target: { checked } }) => {
    this.setState({
      shouldDuplicateAttachments: checked,
    });
  };

  render() {
    const {
      teams, // this.props.teams is all teams of which user is a member
      dealToDuplicate,
      closeModal,
      showModal,
      property,
    } = this.props;

    const {
      dealName,
      dealType,
      dealStage,
      company,
      landlordCompany,
      properties,
      shouldDuplicateContacts,
      shouldDuplicateAttachments,
      dealTeams, // this.state.dealTeams is all teams added to the deal
      teamMembers,
      submitted,
      submissionError,
      showCompanyWarning,
      showPropertyNotice,
      redirectPath,
      propertyNameText,
    } = this.state;

    let companyErrorMessage = '';
    if (submitted && !this.isValidCompany()) {
      companyErrorMessage = 'Company is required';
    }

    let dealTypeErrorMessage = '';
    if (submitted && !this.isValidDealType() && isEmpty(this.props.property)) {
      dealTypeErrorMessage = 'Deal Type is required';
    }

    const dealPropertyErrorMessage = this.getPropertyErrorMessage();

    const renderToast = submissionError ? <Toast message={submissionError} /> : '';

    const dealProperties = (typeof dealType !== 'string' && dealType.value === 'landlord') &&
      (<NewDealProperties
        addProperty={this.addProperty}
        removeProperty={this.removeProperty}
        properties={properties}
        property={property}
        errorMessage={dealPropertyErrorMessage}
        onNoticeDismiss={this.onPropertyNoticeDismiss}
        showPropertyNotice={showPropertyNotice}
        initSearchTerm={propertyNameText || ''}
        onChange={(searchText) => { this.setState({ propertyNameText: searchText }); }}
      />);

    // Duplicate Deal Logic
    // if deal exists, we know this is a Duplicate Deal modal so dynamically
    // update the information accordingly so Modal UI updates accordingly
    const renderModalHeader = dealToDuplicate ? 'Duplicate Deal' : 'New Deal';
    const renderModalButtonText = dealToDuplicate ? 'Duplicate Deal' : 'Create Deal';
    const renderContactsCheckBox = dealToDuplicate ? (
      <Checkbox
        checked={shouldDuplicateContacts}
        onChange={this.duplicateContacts}
      >Duplicate Contacts
      </Checkbox>
    ) : '';
    const renderFilesCheckBox = dealToDuplicate ? (
      <Checkbox
        checked={shouldDuplicateAttachments}
        onChange={this.duplicateFiles}
      >Duplicate Files
      </Checkbox>
    ) : '';

    const renderForm = this.props.showModal ? (
      <form className="deal-form" autoComplete="off">
        {renderToast}
        <DealOverview
          dealType={dealType}
          setDealType={this.setDealType}
          errorMessage={dealTypeErrorMessage}
          property={property}
        />
        <DealInformation
          dealName={dealName}
          dealStage={dealStage}
          dealType={dealType}
          company={company}
          landlordCompany={landlordCompany}
          setDealName={this.setDealName}
          setDealStage={this.setDealStage}
          showCompanyWarning={showCompanyWarning}
          setCompany={this.setCompany}
          setLandlordCompany={this.setLandlordCompany}
          errorMessage={companyErrorMessage}
          initCompanySearchTerm={company.name ? company.name : company}
          initLandlordSearchTerm={landlordCompany.name ? landlordCompany.name : landlordCompany}
          onCompanyAlertDismiss={this.onCompanyAlertDismiss}
        />
        {renderContactsCheckBox}
        {renderFilesCheckBox}
        {dealProperties}
        <DealTeamInformation
          addTeam={this.addTeam}
          addTeamMember={this.addTeamMember}
          removeTeam={this.removeTeam}
          removeTeamMember={this.removeTeamMember}
          allTeams={teams}
          dealTeams={dealTeams}
          teamMembers={teamMembers}
        />
      </form>
    ) : '';
    return (
      <React.Fragment>
        {redirectPath && <Redirect push to={redirectPath} />}
        {this.props.user &&
          <Modal
            modalHeader={renderModalHeader}
            className="create-deal-modal"
            showModal={showModal}
            handleModalToggle={closeModal}
            handleSecondaryButton={closeModal}
            primaryButtonText={renderModalButtonText}
            secondaryButtonText="Cancel"
            handleModalSubmit={this.onNewDealFormSubmit}
            isLoading={this.state.isRequested}
          >
            {renderForm}
          </Modal>
        }
      </React.Fragment>
    );
  }
}

CreateDealModal.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  teams: PropTypes.arrayOf(PropTypes.shape({})),
  closeModal: PropTypes.func.isRequired,
  fetchTeams: PropTypes.func.isRequired,
  addDealTeams: PropTypes.func.isRequired,
  clientCompany: PropTypes.shape({}),
  dealToDuplicate: PropTypes.shape({
    client: PropTypes.shape({
    }),
    counter_party: PropTypes.shape({
    }),
    deal_life_cycle_stage: PropTypes.string,
    deal_type: PropTypes.string,
    id: PropTypes.number,
    is_archived: PropTypes.bool,
    name: PropTypes.string,
    primary_occupied_property: PropTypes.shape({
      id: PropTypes.number,
    }),
    rep_role: PropTypes.string,
  }),
  dealFromExternalLink: PropTypes.shape({
    client: PropTypes.shape({
    }),
    counter_party: PropTypes.shape({
    }),
    deal_life_cycle_stage: PropTypes.string,
    deal_type: PropTypes.string,
    property: PropTypes.shape({}),
    mta_id: PropTypes.string,
    name: PropTypes.string,
    rep_role: PropTypes.string,
  }),
  dealUsersToDuplicate: PropTypes.arrayOf(PropTypes.shape({})),
  dealTeamsToDuplicate: PropTypes.arrayOf(PropTypes.shape({})),
  showModal: PropTypes.bool,
  turducken: PropTypes.bool,
  property: PropTypes.shape({
    name: PropTypes.string,
    building: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      stateName: PropTypes.string,
      state_abbreviation: PropTypes.string,
      country: PropTypes.string,
      postal_code: PropTypes.string,
    }),
  }),
};

CreateDealModal.defaultProps = {
  user: null,
  teams: [],
  clientCompany: null,
  dealToDuplicate: null,
  dealFromExternalLink: null,
  dealUsersToDuplicate: [],
  dealTeamsToDuplicate: [],
  showModal: false,
  turducken: false,
  property: {},
};

export default CreateDealModal;
