import { connect } from 'react-redux';
import {
  addNote,
  deleteNote,
  fetchNotes,
  updateNote,
  newNoteCleanup,
} from '../../../redux/actions/deal-notes-actions';
import NoteListPanel from '../notes/note-list-panel';

const mapStateToProps = state => ({
  dealId: state.deal.deal.id,
  isLoading: state.dealNotes.isLoading,
  newNote: state.dealNotes.newNote,
  count: state.dealNotes.count,
  notes: state.dealNotes.notes,
  next: null,
  pageSize: 3,
  user: state.userData.data,
});

const mapDispatchToProps = {
  addNote,
  deleteNote,
  fetchNotes,
  updateNote,
  newNoteCleanup,
};

const OverviewNoteListPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NoteListPanel);


export default OverviewNoteListPanelContainer;
