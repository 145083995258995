import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { IconButton } from '@cbrebuild/blocks';
import { useSelector } from 'react-redux';
import proxyService from '../../services/proxy-service';
import RowItem from '../../nucleus/row-item/row-item';
import { Person, PersonType } from '../../components/person';
import EmptyState from '../../components/empty-state/empty-state';
import BrokerAutocomplete from '../../components/autocomplete/broker-autocomplete';
import Notice from '../../nucleus/notices/notice';
// import DealIQLogo from '../../assets/images/svg/deal-iq-logo-only.svg';
import DealIQLogo from '../../assets/images/svg/loading-icon.svg';

const GrantProxyAccess = ({
  userData,
}) => {
  const [proxyMembers, setProxyMembers] = useState([]);
  const [showProxyAddErrorToast, setShowProxyAddErrorToast] = useState(false);
  const { proxy, userOptions } = useSelector(state => ({
    proxy: state.proxy,
    userOptions: state.userOptions,
  }));
  const [renderProxyPage, setRenderProxyPage] = useState(false);
  const [isLoadingProxies, setIsLoadingProxies] = useState(false);
  const [userInProcess, setUserInProcess] = useState('');

  const params = {
    user_id: userData.id,
    status: 'Active',
  };
  const proxyAddErrorMessage = `This user (${userInProcess}) cannot be added as a Proxy! Please review their Proxy settings`;

  const getProxyUsers = useCallback(async () => {
    await proxyService.fetchProxyUser()
      .then((res) => {
        if (!!res && !!res.proxy_users) {
          setProxyMembers(res.proxy_users);
          setIsLoadingProxies(false);
        }
      });
  }, []);

  const addNewProxyUser = async (selectedUser) => {
    setUserInProcess(selectedUser.work_email);
    setShowProxyAddErrorToast(false);
    const updatedParams = { ...params, proxy_user_id: selectedUser.user_id };
    try {
      await proxyService.addProxyUser(updatedParams);
    } catch (error) {
      setShowProxyAddErrorToast(true);
    }
    setIsLoadingProxies(true);
    await getProxyUsers();
  };

  const removeProxyUser = async (id) => {
    await proxyService.removeProxyUser(id);
    setIsLoadingProxies(true);
    await getProxyUsers();
  };

  useEffect(() => {
    setIsLoadingProxies(true);
    getProxyUsers();
  }, [getProxyUsers, userData]);

  useEffect(() => {
    if (!!userOptions && !!userOptions.userOptions && !!userOptions.userOptions.feature_flags) {
      setRenderProxyPage(userOptions.userOptions.feature_flags.proxy_user_flag && isEmpty(proxy.proxySettings));
    }
  }, [userOptions, proxy]);

  const renderNoProxyUsers = () => (isEmpty(proxyMembers) &&
    (
    <div className="empty-state-container">
      <EmptyState
        type="users"
        message="Give proxy access by searching and adding a user."
      />
    </div>
    )
  );

  const renderAddErrorToast = showProxyAddErrorToast ? (
    <div className="cannot-grant-notice">
      <Notice
        text={proxyAddErrorMessage}
        type="critical"
        onDismiss={() => {
          setShowProxyAddErrorToast(false);
      }}
      />
    </div>) : '';

  const renderProxyUsers = () => (
    <>
      <h4>Added Proxy Members</h4>
      <div className="member-container">
        {proxyMembers.map(user => (
          <RowItem key={`proxies_${user.proxy_user.id}`}>
            <Person
              person={user.proxy_user}
              type={PersonType.MEMBER}
              hoverAlign="left"
            />
            <IconButton
              className="blxs-button-icon-small"
              iconName="close"
              onClick={() => removeProxyUser(user.id)}
              variant="basic"
            />
          </RowItem>
      ))}
      </div>
    </>
  );

  const renderLoadingSpinner = () => (
    <div className="spinner-container">
      <div className="loading-spinner">
        <img
          alt="Loading"
          src={DealIQLogo}
        />
      </div>
    </div>
  );

  const proxyUsersSection = () => {
    if (isLoadingProxies) {
      return renderLoadingSpinner();
    } else if (!isEmpty(proxyMembers)) {
      return renderProxyUsers();
    }
    return renderNoProxyUsers();
  };

  return (
    <div className="proxy-page">
      {renderProxyPage && (
      <div className="page-container">
        <div className="card-default">
          <h2 className="title">Grant Proxy Access</h2>
          <div className="team-card-members">
            <section>
              <p className="add-user-proxy-text">Add a user that can access your account as your proxy.</p>
              <div className="team-card-autocomplete">
                <BrokerAutocomplete
                  onSelect={addNewProxyUser}
                  placeholder="Make a user your Proxy"
                />
              </div>
              {renderAddErrorToast}
              { proxyUsersSection()}
            </section>
          </div>
        </div>
      </div>)
      }
    </div>
  );
};

GrantProxyAccess.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default GrantProxyAccess;
