/* eslint-disable no-nested-ternary */
/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { RadioGroup, RadioButton, Button, IconButton } from '@cbrebuild/blocks';
import useCompanyState from '../../../hooks/useCompanyState';
import VoucherContactForm from '../contacts/voucher-contact-form';
import ConfirmationModal from '../../../nucleus/confirmation-modal/confirmation-modal';
import { getAndSetLastPageVisited } from '../voucher-validation';
import CompanyForm from '../CompanyForm';
import formatTelephone from '../../../utils/format-telephone';
import VOUCHER_ROUTES from '../../../page-templates/voucher/voucher-subroutes';

const BillTo = ({
  deal = {},
  voucher = {},
  contactInfo,
  updateDeal,
  commissions,
  addDealContact,
  fetchDealContacts,
  updateDealContact,
  deleteDealContact,
}) => {
  const [displayOtherContactInfo, setDisplayOtherContactInfo] = useState(false);
  const [dealType, setDealType] = useState('');
  const [dealId, setDealId] = useState('');
  const [otherContact, setOtherContact] = useState({});
  const [clientPartyContact, setClientPartyContact] = useState({});
  const [counterPartyContact, setCounterPartyContact] = useState({});
  const [showValidation, setShowValidation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const queryContactParams = {
    ordering: '-is_primary',
    page: 1,
    page_size: 25,
  };

  const outside_brokers = commissions.filter(commision => commision.vendor_id !== null);

  const companyParty = (deal.billed_to_type === 'Tenant' || deal.billed_to_type === 'Buyer')
    ? deal.client
    : ((deal.billed_to_type === 'Landlord' || deal.billed_to_type === 'Seller')
      ? deal.counter_party : deal.billed_party);
  const billedToParty = (deal.billed_to_type === 'Tenant' || deal.billed_to_type === 'Buyer')
    ? 'client'
    : ((deal.billed_to_type === 'Landlord' || deal.billed_to_type === 'Seller')
      ? 'counter_party' : 'billed_party');

  const {
    company, onCompanyBlur, onCompanyChange, onCompanyCreate,
  } = useCompanyState(companyParty || undefined, billedToParty, update => updateDeal(deal.id, update));

  const validatePage = () => {
    const currentPageNumber = 2.2;
    const lastPageVisited = getAndSetLastPageVisited(voucher?.id, currentPageNumber);

    if (lastPageVisited >= currentPageNumber) {
      setShowValidation(true);
    }
  };

  const getTypeContactInfo = (data, type) => {
    if (!data) {
      return {};
    }
    const ctTypeData = data.filter(ctData => ctData.contact_type === type);
    if (ctTypeData.length) {
      return ctTypeData[0];
    }
    return [];
  };

  const contactMap = {
    lease: 'tenant',
    sale: 'buyer',
    consulting: 'consulting',
    other: 'other',
  };

  // Obtain contact info to be displayed
  useEffect(() => {
    if (deal.billed_to_type !== 'Other') {
      const clientType = contactMap[deal.deal_type];
      const clientContact = getTypeContactInfo(contactInfo, clientType);
      if (clientContact.length !== 0) {
        setClientPartyContact(clientContact);
      }
      const counterType = deal.deal_type === 'lease' ? 'landlord' : 'seller';
      const counterContact = getTypeContactInfo(contactInfo, counterType);
      if (counterContact.length !== 0) {
        setCounterPartyContact(counterContact);
      }
    }
  }, [clientPartyContact, contactInfo, counterPartyContact, deal.billed_to_type, deal.deal_type]);

  const billedTypeChange = (event, id) => {
    if (event.target.value.split(' ')[0] === 'Outside') {
      setDealType("Outside Broker");
      setDealId(deal.id);
      if (deal.billed_to_type === 'Other') {
        setShowModal(true);
      }
      updateDeal(deal.id, { billed_party: id, billed_to_type: "Outside Broker" });
    } else {
      setDealType(event.target.value);
      setDealId(id);
      if (deal.billed_to_type === 'Other') {
        setShowModal(true);
      } else if (event.target.value === 'Seller' || event.target.value === 'Buyer' ||
          event.target.value === 'Tenant' || event.target.value === 'Landlord' ||
          event.target.value === 'Client') {
        updateDeal(deal.id, { billed_party: id, billed_to_type: event.target.value });
      }
      if (event.target.value === 'Other') {
        onCompanyCreate();
        updateDeal(deal.id, { billed_party: id, billed_to_type: event.target.value });
        fetchDealContacts({ ...queryContactParams, deal: deal.id }).then(() => {
          setDisplayOtherContactInfo(true);
        });
      }
    }
  };

  const closeConfirmationModal = () => {
    setShowModal(false);
  };

  const deleteContact = () => {
    deleteDealContact(otherContact.id, false);
    updateDeal(deal.id, { billed_party: dealId, billed_to_type: dealType });
    setDisplayOtherContactInfo(false);
  };

  const getDealNameType = () => {
    const dealNameType = {};
    if (deal.deal_type === 'lease') {
      dealNameType[0] = 'Tenant';
      dealNameType[1] = 'Landlord';
    }
    if (deal.deal_type === 'sale') {
      dealNameType[0] = 'Buyer';
      dealNameType[1] = 'Seller';
    }
    if (deal.deal_type === 'consulting' || deal.deal_type === 'other') {
      dealNameType[1] = 'Client';
    }
    return dealNameType[1];
  };

  useEffect(() => {
    validatePage();
    const dealNameType = getDealNameType();
    if (deal.billed_to_type === null && deal.client.name !== null) {
      updateDeal(deal?.id, { billed_party: deal?.client.id, billed_to_type: dealNameType });
    }
    if (billedToParty === 'counter_party' && deal.counter_party === null) {
      onCompanyCreate();
    }
    fetchDealContacts({ ...queryContactParams, deal: deal?.id })
      .then(() => {
        if (deal.billed_to_type === 'Other') {
          setDisplayOtherContactInfo(true);
        }
        const otherContactData = getTypeContactInfo(contactInfo, "other");
        if (otherContactData.length !== 0) {
          setOtherContact(otherContactData);
        }
      });
  }, [fetchDealContacts, billedToParty, deal]);

  const history = useHistory();
  const hasAllClientInfo = clientPartyContact && clientPartyContact.given_name && clientPartyContact.surname
    && clientPartyContact.email_addresses[0]?.address && clientPartyContact.phone_numbers[0]?.number;
  const hasAllCounterPartyInfo = counterPartyContact && counterPartyContact.given_name && counterPartyContact.surname
    && counterPartyContact.email_addresses[0]?.address && counterPartyContact.phone_numbers[0]?.number;

  return (
    <div className="bill-to-tab">
      <legend className="secondary-legend">Who is the Billable Party?</legend>
      <div className="party-header-container">
        <RadioGroup
          name="confidential-options"
          selectedValue={deal.billed_to_type || ""}
          onChange={e => billedTypeChange(e, deal.client.id)}
          className="radioVal"
          orientation="vertical"
        >
          {(deal.deal_type === 'lease' || deal.deal_type === 'sale') ?
            <RadioButton value={(deal.deal_type === 'lease' ? 'Tenant' : 'Buyer')}>
              <div>{(deal.deal_type === 'lease' ? 'Tenant' : 'Buyer')}</div>
            </RadioButton> :
            <RadioButton value={((deal.deal_type === 'other' || deal.deal_type === 'consulting') ? 'Client' : '')}>
              {((deal.deal_type === 'other' || deal.deal_type === 'consulting') ? 'Client' : '')}
            </RadioButton>
          }
          {(deal.client &&
            <div className="party-sub-container">
              <div className="info-container">
                <span className="semibold">Company Info:</span>
                <div >{deal.client.name && deal.client.name}</div>
                <div>{deal.client.address && deal.client.address} {deal.client.address2 && deal.client.address2}</div>
                <div>{deal.client.city && `${deal.client.city},`}
                  {deal.client.territory !== null && ` ${deal.client.territory}`}
                  {deal.client.postal !== null && ` ${deal.client.postal}`}
                </div>
              </div>
              <div className="info-container">
                <span className="semibold">Contact Info:</span>
                <div>
                  {clientPartyContact?.given_name && `${clientPartyContact?.given_name} `}
                  {clientPartyContact?.surname && `${clientPartyContact?.surname} `}
                  {clientPartyContact?.job_title && `- ${clientPartyContact?.job_title}`}
                </div>
                <div>{clientPartyContact?.email_addresses && clientPartyContact.email_addresses[0]?.address}</div>
                <div>{clientPartyContact?.phone_numbers && formatTelephone(clientPartyContact.phone_numbers[0]?.number)}</div>
                {((deal.billed_to_type === 'Buyer') || (deal.billed_to_type === 'Client') || (deal.billed_to_type === 'Tenant'))
                  && !hasAllClientInfo && <p className="error-message">Missing required contact information</p>}
              </div>
            </div>
            )}
        </RadioGroup>
        <IconButton
          iconName="edit"
          onClick={() => {
            switch (deal.deal_type) {
              case 'lease':
                history.push(VOUCHER_ROUTES[1].routes[1].path.replace(':id', deal.id));
                break;
              case 'sale':
                history.push(VOUCHER_ROUTES[1].routes[5].path.replace(':id', deal.id));
                break;
              case 'consulting':
              case 'other':
                history.push(VOUCHER_ROUTES[1].routes[8].path.replace(':id', deal.id));
                break;
              default:
                break;
            }
          }}
        />
      </div>
      {(deal.deal_type === 'lease' || deal.deal_type === 'sale') &&
        <div className="party-header-container">
          <RadioGroup
            name="confidential-options"
            selectedValue={deal.billed_to_type || ""}
            onChange={e => billedTypeChange(e, deal.counter_party?.id)}
            className="radioVal"
            orientation="vertical"
            checked
          >
            <RadioButton value={deal.deal_type === 'lease' ? 'Landlord' : 'Seller'}>
              {deal.deal_type === 'lease' ? 'Landlord' : 'Seller'}
            </RadioButton>
            {(deal.counter_party &&
              <div className="party-sub-container">
                <div className="info-container">
                  <span className="semibold">Company Info:</span>
                  <div>{deal.counter_party.name && deal.counter_party.name}</div>
                  <div>{deal.counter_party.address && deal.counter_party.address} {deal.counter_party.address2 && deal.counter_party.address2}</div>
                  <div>{deal.counter_party.city && `${deal.counter_party.city},`}
                    {deal.counter_party.territory !== null && ` ${deal.counter_party.territory}`}
                    {deal.counter_party.postal !== null && ` ${deal.counter_party.postal}`}
                  </div>
                </div>
                <div className="info-container">
                  <span className="semibold">Contact Info:</span>
                  <div>
                    {counterPartyContact?.given_name && `${counterPartyContact?.given_name} `}
                    {counterPartyContact?.surname && `${counterPartyContact?.surname} `}
                    {counterPartyContact?.job_title && `- ${counterPartyContact?.job_title}`}
                  </div>
                  <div>{counterPartyContact?.email_addresses && counterPartyContact.email_addresses[0]?.address}</div>
                  <div>{counterPartyContact?.phone_numbers && formatTelephone(counterPartyContact.phone_numbers[0]?.number)}</div>
                  {((deal.billed_to_type === 'Landlord') || (deal.billed_to_type === 'Seller'))
                    && !hasAllCounterPartyInfo && <p className="error-message">Missing required contact information</p>}
                </div>
              </div>
            )}
          </RadioGroup>
          <IconButton
            iconName="edit"
            onClick={() => {
              switch (deal.deal_type) {
                case 'lease':
                  history.push(VOUCHER_ROUTES[1].routes[2].path.replace(':id', deal.id));
                  break;
                case 'sale':
                  history.push(VOUCHER_ROUTES[1].routes[6].path.replace(':id', deal.id));
                  break;
                default:
                  break;
              }
            }}
          />
        </div>
      }
      {outside_brokers.length > 0 &&
        <>
          {outside_brokers?.map((broker, index) => (
            <RadioGroup
              name="outside-options"
              selectedValue={`${deal.billed_to_type} ${deal.billed_party.id}`}
              onChange={e => billedTypeChange(e, broker.vendor.id)}
              className="radioVal"
              orientation="vertical"
              key={broker.id}
            >
              <RadioButton value={`Outside Broker ${broker.vendor.id}`}>
                {outside_brokers.length === 1 ? 'Outside Broker' : `Outside Broker #${index + 1}`}
              </RadioButton>
              <div className="party-sub-container">
                <div className="info-container">
                  <span className="semibold">Company Info:</span>
                  <div>{broker.vendor.name}</div>
                  <div>{broker.vendor.address && broker.vendor.address} {broker.vendor.address2 && broker.vendor.address2}</div>
                  <div>{broker.vendor.city && `${broker.vendor.city},`}
                    {broker.vendor.territory !== null && ` ${broker.vendor.territory}`}
                    {broker.vendor.postal !== null && ` ${broker.vendor.postal}`}
                  </div>
                </div>
                <div className="info-container">
                  <span className="semibold">Contact Info:</span>
                  <div>
                    {broker.outside_broker_name}
                    {broker.outside_broker_title && ` - ${broker.outside_broker_title}`}
                  </div>
                  <div>{broker.outside_broker_email}</div>
                  <div>{formatTelephone(broker.outside_broker_phone_number)}</div>
                </div>
              </div>
            </RadioGroup>

          ))}
        </>
      }
      <RadioGroup
        name="confidential-options"
        selectedValue={deal.billed_to_type || ""}
        onChange={billedTypeChange}
        className="radioVal"
        orientation="vertical"
      >
        <RadioButton value="Other">
          Other
        </RadioButton>
      </RadioGroup>
      {displayOtherContactInfo &&
        <React.Fragment>
          <h2>Bill To</h2>
          <legend>Information</legend>
          <CompanyForm
            infoType="BillTo"
            {...company}
            onChange={onCompanyChange}
            onBlur={onCompanyBlur}
            showValidation={showValidation}
          />
          <VoucherContactForm
            dealId={deal.id}
            contactInfo={contactInfo}
            updateDeal={updateDeal}
            addDealContact={addDealContact}
            fetchDealContacts={fetchDealContacts}
            updateDealContact={updateDealContact}
            deleteDealContact={deleteDealContact}
            contactType="other"
            showValidation={showValidation}
            hasClrBtn={false}
          />
        </React.Fragment>
      }
      {showModal &&
        <ConfirmationModal
          className="deal-file-delete-confirmation tenant-modal voucher-modal"
          onCloseModal={closeConfirmationModal}
          showModal={showModal}
        >
          <div className="header-modal">
            <h1 className="header-content conf-header">Confirmation</h1>
            <IconButton
              iconName="close-circle"
              className="icon-circle"
              variant="basic"
              onClick={closeConfirmationModal}
            />
          </div>
          <div className="conf-text">
            <p>This will delete the Other billed party contact. Are you sure?</p>
          </div>
          <footer className="actions">
            <Button
              className="cancel-btn"
              variant="secondary"
              onClick={closeConfirmationModal}
            >
              Cancel
            </Button>
            <Button
              className="upload-btn ok-btn"
              onClick={() => {
              setShowModal(false);
              deleteContact();
          }}>
              Ok
            </Button>
          </footer>
        </ConfirmationModal>
      }
    </div>
  );
};

BillTo.propTypes = {
  voucher: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  deal: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  updateDeal: PropTypes.func.isRequired,
  addDealContact: PropTypes.func.isRequired,
  commissions: PropTypes.arrayOf(PropTypes.any),
  updateDealContact: PropTypes.func.isRequired,
  deleteDealContact: PropTypes.func.isRequired,
  fetchDealContacts: PropTypes.func.isRequired,
  contactInfo: PropTypes.arrayOf(PropTypes.any),
};

BillTo.defaultProps = {
  contactInfo: {},
  commissions: [],
};

export default BillTo;
