import React from 'react';
import { IconButton } from '@cbrebuild/blocks';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import VOUCHER_ROUTES from '../../../page-templates/voucher/voucher-subroutes';
import { formatItemsInLines, getAddressString, validateAddress } from '../voucher-validation';
import RequiredLabeledItem from './RequiredLabeledItem';

const BillToReview = () => {
  const {
    contacts, deal, voucher,
  } = useSelector(state => ({
    contacts: state.dealContacts.contacts,
    deal: state.deal.deal,
    voucher: state.voucher.voucher,
  }));

  const history = useHistory();
  const url = VOUCHER_ROUTES[2].routes[4].path.replace(':id', voucher.deal);
  const partyMap = {
    tenant: {
      tenant: deal.client,
      landlord: deal.counter_party,
    },
    landlord: {
      tenant: deal.counter_party,
      landlord: deal.client,
    },
    buyer: {
      buyer: deal.client,
      seller: deal.counter_party,
    },
    seller: {
      buyer: deal.counter_party,
      seller: deal.client,
    },
  };
  let billToCompany;
  let contactType;
  if (deal.billed_to_type === 'Client') {
    billToCompany = deal.client;
    contactType = 'consulting';
  } else {
    billToCompany = partyMap[deal.rep_role] && partyMap[deal.rep_role][deal.billed_to_type?.toLowerCase()];
    contactType = deal.billed_to_type?.toLowerCase();
  }
  const billToCompanyLine = formatItemsInLines([
    billToCompany?.name || '',
    getAddressString(billToCompany),
  ]);
  const contact = contacts?.filter(({ contact_type }) => contact_type === contactType)[0];
  let nameAndTitle = '';
  const otherContact = contact;
  if (contact) {
    if (otherContact && otherContact.given_name && otherContact.surname) {
      otherContact.fullName = otherContact.given_name.concat(' ', otherContact.surname);
    }
    if (!contact.email_addresses[0]) {
      contact.email_addresses.push({ type: 'work', address: '', default: false });
    }
    if (contact?.given_name && contact.surname) {
      nameAndTitle = `${contact.given_name} ${contact.surname}`;
    } else {
      nameAndTitle = contact.given_name || contact.surname;
    }

    if (nameAndTitle && nameAndTitle.length && contact.job_title) {
      nameAndTitle += ` - ${contact.job_title}`;
    } else if (contact.job_title) {
      nameAndTitle += contact.job_title;
    }
  }

  const hasAllContactInfo = contact && contact.given_name && contact.surname
    && contact.email_addresses[0].address && contact.phone_numbers[0].number;
  if (contact !== undefined && !hasAllContactInfo) {
    localStorage.setItem('voucher_valid', JSON.stringify(false));
  }
  const billToContactLine = formatItemsInLines([
    nameAndTitle,
    contact?.email_addresses && contact.email_addresses[0] && contact.email_addresses[0].address,
    contact?.phone_numbers && contact.phone_numbers[0] && contact.phone_numbers[0].number,
  ]);
  const billedPartyAddressString = validateAddress(deal.billed_party) ? deal.billed_party : '';

  return (
    <div className="voucher-review-group bill-to-review">
      <div className="navigation">
        <Link className="h2 link" to={url}>Bill To</Link>
        <IconButton iconName="edit" onClick={() => { history.push(url); }} />
      </div>
      <div className="content">
        {deal.billed_to_type !== 'Other' &&
          (!deal.billed_to_type
            ? <h3 className="voucher-error-text">Billed To is required</h3>
            : <h3>Billed to {deal.billed_to_type}</h3>)
        }
        {deal.billed_to_type !== 'Other' && (billToCompanyLine ?
          <div>
            <div className="semibold">Company Info:</div>
            <pre className="p">{billToCompanyLine}</pre>
          </div>
          : '')
        }
        {deal.billed_to_type !== 'Other' && (contact ?
          <div>
            <div className="semibold">Contact Info:</div>
            <pre className="p">{billToContactLine}</pre>
            {!hasAllContactInfo && <p className="error-message">Missing required contact information</p>}
          </div>
          : '')
        }
        {deal.billed_to_type === 'Other' ?
          <div className="two-cols">
            <div className="col-left">
              <h3>Company</h3>
              <RequiredLabeledItem label="Company Name" item={deal.billed_party?.name} isReview />
              <RequiredLabeledItem label="Address" format="address" item={billedPartyAddressString} isReview />
            </div>
            <div className="col-right">
              <h3>Contact</h3>
              <RequiredLabeledItem label="Name" item={otherContact?.fullName} isReview />
              <RequiredLabeledItem label="Phone #" item={otherContact?.phone_numbers[0]?.number} isReview />
              <RequiredLabeledItem label="Email Address" item={otherContact?.email_addresses[0]?.address} isReview />
              <RequiredLabeledItem label="Job Title" item={otherContact?.job_title} notRequired isReview />
            </div>
          </div>
          : ''
        }
        {deal.billed_to_type === 'Outside Broker' &&
          <div className="two-cols">
            <div className="col-left">
              <h3>Company</h3>
              <RequiredLabeledItem label="Company Name" item={deal.billed_party?.name} isReview />
              <RequiredLabeledItem label="Address" format="address" item={billedPartyAddressString} isReview />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default BillToReview;
