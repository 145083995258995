import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton } from '@cbrebuild/blocks';
import AddItem from '../../../nucleus/add-item/add-item';
import ContactModal from '../../modals/contact-modal';
import dealsService from '../../../services/deals-service';

class PrimaryContactPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryContactParams: {
        ordering: '-is_primary',
        page: 1,
        page_size: 1,
      },
      showContactModal: false,
    };
  }

  componentDidMount() {
    this.handleFetchContact();
  }

  handleFetchContact = () => {
    const { deal: { id }, fetchDealContacts } = this.props;
    const { queryContactParams } = this.state;
    fetchDealContacts({ ...queryContactParams, deal: id });
  }

  openContactModal = () => this.setState({ showContactModal: true });
  closeContactModal = () => this.setState({ showContactModal: false });

  handleDeleteContact = (id) => {
    const {
      deal,
    } = this.props;
    this.setState({ showContactModal: false });
    this.props.deleteDealContact(id, true);
    dealsService.updateDeal(deal.id, { modified: new Date() });
  }

  handleContactModalSubmit = (contact, shouldAddToPersonal = false) => {
    const {
      deal: {
        id,
      },
      addDealContact,
      updateDealContact,
    } = this.props;
    if (contact.id) {
      updateDealContact(contact.id, { ...contact, display_toast: true });
      dealsService.updateDeal(id, { modified: new Date() });
      this.setState({ showContactModal: false });
    } else {
      addDealContact({
        ...contact, create_personal_contact: shouldAddToPersonal, display_toast: true, deal: id,
      });
      dealsService.updateDeal(id, { modified: new Date() });
      this.setState({ showContactModal: false });
    }
  }

  render() {
    const {
      analyticProperties,
      deal,
      primaryContact,
    } = this.props;
    const {
      showContactModal,
    } = this.state;

    return (
      <div className="card-default primary-contact-panel">
        {primaryContact
          ? (
            <div>
              <div className="card-header">
                <h2>Primary Contact</h2>
                <IconButton className="primary-icon-button" iconName="edit" onClick={this.openContactModal} />
              </div>
              <div className="card-content">
                <p>{primaryContact.given_name} {primaryContact.surname}</p>
                {
                  primaryContact.phone_numbers.length
                    ? (
                      <div>
                        <a className="contact-info" href={`tel:${primaryContact.phone_numbers[0].number}`}>
                          <Icon iconName="phone" />
                          {primaryContact.phone_numbers[0].number}
                        </a>
                      </div>
                    )
                    : null
                }
                {
                  primaryContact.email_addresses.length
                    ? (
                      <div>
                        <a className="contact-info" href={`mailto:${primaryContact.email_addresses[0].address}`}>
                          <Icon iconName="envelope" />
                          {primaryContact.email_addresses[0].address}
                        </a>
                      </div>
                    )
                    : null
                }
              </div>
            </div>
          )
          : (
            <div className="card-content">
              <h2>Primary Contact</h2>
              <AddItem
                label="Add Primary Contact"
                onClick={this.openContactModal}
              />
            </div>
          )
        }
        {showContactModal && (
          <ContactModal
            analyticProperties={analyticProperties}
            contact={primaryContact}
            dealId={deal.id}
            isPrimaryOnly
            onCancel={this.closeContactModal}
            onDelete={this.handleDeleteContact}
            onSubmit={this.handleContactModalSubmit}
          />
        )}
      </div>
    );
  }
}

PrimaryContactPanel.propTypes = {
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  deal: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  primaryContact: PropTypes.shape({
    email_addresses: PropTypes.arrayOf(PropTypes.shape({
      address: PropTypes.string,
    })),
    given_name: PropTypes.string,
    surname: PropTypes.string,
    phone_numbers: PropTypes.arrayOf(PropTypes.shape({
      number: PropTypes.string,
    })),
  }),
  addDealContact: PropTypes.func.isRequired,
  updateDealContact: PropTypes.func.isRequired,
  deleteDealContact: PropTypes.func.isRequired,
  fetchDealContacts: PropTypes.func.isRequired,
};

PrimaryContactPanel.defaultProps = {
  primaryContact: undefined,
};

export default PrimaryContactPanel;
