import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@cbrebuild/blocks';

const AddItem = ({ dataE2e, label, onClick }) => (
  <Button
    className="nd-add-item"
    variant="text"
    onClick={onClick}
  >
    <Icon
      iconName="plus-circle-outline"
      data-e2e={dataE2e}
    />
    {label}
  </Button>
);

AddItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  dataE2e: PropTypes.string,
};

AddItem.defaultProps = {
  onClick: () => { },
  dataE2e: 'add-item',
};

export default AddItem;
