import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton } from '@cbrebuild/blocks';

const Notice = (props) => {
  const {
    text,
    type,
    dismissText,
    onDismiss,
  } = props;
  const noticeClass = `nd-notice ${type}`;
  return (
    <div className={noticeClass}>
      <div className="notice-content">
        <p><Icon iconName={type === 'info' ? 'info-circle' : 'warning'} /> {text}</p>
      </div>
      {onDismiss && (
        <React.Fragment>
          {!dismissText && <IconButton className="blxs-button-icon-small" iconName="close" onClick={onDismiss} variant="basic" />}
          {dismissText && <span className="dismiss-link" onClick={onDismiss}>{dismissText}</span>}
        </React.Fragment>
      )}
    </div>
  );
};

Notice.propTypes = {
  text: PropTypes.string.isRequired,
  onDismiss: PropTypes.func,
  dismissText: PropTypes.string,
  type: PropTypes.oneOf(['info', 'warning', 'critical', 'alert']).isRequired,
};

Notice.defaultProps = {
  dismissText: '',
  onDismiss: null,
};

export default Notice;
