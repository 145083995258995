import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FormatAsCurrency from '../../utils/format-as-currency';
import FormatNumberWithCommas from '../../utils/format-number-with-commas';
import getCounterPartyLabel from '../../utils/get-counter-party-label';

import StackedLabeledItem from '../../nucleus/stacked-labeled-item/stacked-labeled-item';

const DealCardFinancialsLeft = (props) => {
  const {
    currentLeaseExp,
    dealLifeCycleStage,
    dealType,
    property,
    requirements,
    repRole,
    dealIds,
    estimatedClose,
    progress,
    client,
    counterParty,
  } = props;
  switch (dealLifeCycleStage) {
    case 'prospect': {
      const leaseExpiration = currentLeaseExp && moment(currentLeaseExp).format('LL');
      const salePriceRange = (requirements && requirements.sale_price) || null;
      const formattedSQFT = property.aggregate_sqft && FormatNumberWithCommas(property.aggregate_sqft);
      const requirementPropertyType = (requirements && requirements.product) || undefined;
      return (
        <div className="deal-card-financials-left">
          {dealType === 'lease' &&
            <Fragment>
              <StackedLabeledItem
                label="LEASE EXPIRATION"
                value={leaseExpiration}
              />
              <StackedLabeledItem
                label="PROPERTY TYPE"
                value={requirementPropertyType}
              />
            </Fragment>
          }
          {repRole === 'tenant' &&
            <StackedLabeledItem
              label="CURRENT SQFT"
              value={formattedSQFT}
            />
          }
          {repRole === 'buyer' &&
            <Fragment>
              <StackedLabeledItem
                label="PROPERTY TYPE"
                value={requirementPropertyType}
              />
              <StackedLabeledItem
                label="PRICE RANGE"
                value={salePriceRange ? `${FormatAsCurrency(salePriceRange.min)} - ${FormatAsCurrency(salePriceRange.max)}` : '--'}
              />
            </Fragment>
          }
          {repRole === 'seller' &&
            <Fragment>
              <StackedLabeledItem
                label="PROPERTY TYPE"
                value={requirementPropertyType}
              />
              <StackedLabeledItem
                label="ASKING PRICE"
                value={FormatAsCurrency(requirements.asking_price)}
              />
            </Fragment>
          }
          {dealType === repRole &&
            <StackedLabeledItem
              label="PROPERTY TYPE"
              value={requirementPropertyType}
            />
          }
        </div>
      );
    }
    case 'executing': {
      let sqftRange;
      const estimatedCloseDate = estimatedClose && moment(estimatedClose).format('LL');
      const { min, max } = (requirements && requirements.sqft) || {};
      const minFormatted = min && FormatNumberWithCommas(min);
      const maxFormatted = max && FormatNumberWithCommas(max);
      if (minFormatted && maxFormatted) {
        sqftRange = `${minFormatted} - ${maxFormatted}`;
      } else if (minFormatted) {
        sqftRange = `${minFormatted}+`;
      } else if (maxFormatted) {
        sqftRange = `0 - ${maxFormatted}`;
      } else {
        sqftRange = '--';
      }
      sqftRange = (
        <StackedLabeledItem
          label="SQFT RANGE"
          value={sqftRange}
        />
      );
      return (
        <div className="deal-card-financials-left">
          {dealType === 'lease' &&
            <StackedLabeledItem
              label="PROGRESS"
              value={progress}
            />
          }
          {(dealType === 'lease' || repRole === 'buyer') &&
            <Fragment>{sqftRange}</Fragment>
          }
          <StackedLabeledItem
            label="ESTIMATED CLOSE DATE"
            value={estimatedCloseDate}
          />
          {repRole === 'seller' &&
            <StackedLabeledItem
              label="ASKING PRICE"
              value={FormatAsCurrency(requirements.asking_price)}
            />
          }
        </div>
      );
    }
    case 'closed': {
      const dealIdValue = dealIds.length === 0 ? '--' : dealIds.join(', ');
      return (
        <div className="deal-card-financials-left">
          <StackedLabeledItem
            label="id"
            value={dealIdValue}
          />
          {dealType !== repRole && // rep role is tenant, landlord, seller, or buyer
            <Fragment>
              <StackedLabeledItem
                label={repRole}
                value={client && client.name}
              />
              <StackedLabeledItem
                label={getCounterPartyLabel({ rep_role: repRole })}
                value={counterParty && counterParty.name}
              />
            </Fragment>
          }
          {dealType === repRole && // rep role is consulting or other
            <StackedLabeledItem
              label="client"
              value={client && client.name}
            />
          }
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

DealCardFinancialsLeft.propTypes = {
  client: PropTypes.shape({}),
  counterParty: PropTypes.shape({}),
  dealIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  dealLifeCycleStage: PropTypes.string.isRequired,
  dealType: PropTypes.string.isRequired,
  estimatedClose: PropTypes.string,
  progress: PropTypes.string,
  property: PropTypes.shape({}),
  repRole: PropTypes.string.isRequired,
  requirements: PropTypes.shape({}),
};

DealCardFinancialsLeft.defaultProps = {
  client: null,
  counterParty: null,
  dealIds: [],
  estimatedClose: null,
  progress: null,
  property: {
    building: {},
  },
  requirements: { sqft: null },
};

export default DealCardFinancialsLeft;
