import React from 'react';
import PropTypes from 'prop-types';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import reducers from './redux/reducers/root-reducer';


const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk)),
);

const ReduxRoot = props => (
  <Provider store={store}>
    {props.children}
  </Provider>
);

ReduxRoot.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ReduxRoot;
