import { connect } from 'react-redux';
import { loadCommissionTeam } from '../../../redux/actions/team-members-actions';
import CommissionsTab from './commissions-tab';

const mapStateToProps = state => ({
  deal: state.deal.deal,
  isLoading: state.dealContacts.isLoading,
  relatedTransactionUsers: state.teamMembers.relatedTransactionUsers,
  relatedTransactionTeams: state.teamMembers.relatedTransactionTeams,
  dealUsers: state.teamMembers.dealUsers,
  dealTeams: state.teamMembers.dealTeams,
  user: state.userData.data,
  feature_flags: state.userOptions.userOptions.feature_flags,
});

const mapDispatchToProps = {
  loadCommissionTeam,
};

const mergeProps = (state, actions, props) => ({
  ...state,
  ...actions,
  analyticProperties: {
    actionPrefix: `${props.analyticProperties.actionPrefix}_commissions_tab`,
    categoryPrefix: `${props.analyticProperties.categoryPrefix}Commissions Tab `,
  },
});

const CommissionsTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(CommissionsTab);

export default CommissionsTabContainer;
