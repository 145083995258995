import taskService from '../../services/tasks-service';
import {
  ADD_DEAL_TASK_SUCCESS,
  ADD_DEAL_TASK_FAILURE,
  DELETE_DEAL_TASK_SUCCESS,
  DELETE_DEAL_TASK_FAILURE,
  IS_LOADING_TASKS_ACTION,
  FETCH_DEAL_TASKS_SUCCESS,
  FETCH_DEAL_TASKS_FAILURE,
  UPDATE_DEAL_TASK_SUCCESS,
  UPDATE_DEAL_TASK_FAILURE,
} from './action-types';
import { toast } from './toasts-actions';

const AddDealTaskSuccess = task => ({
  type: ADD_DEAL_TASK_SUCCESS,
  payload: { ...task },
});

const deleteDealTaskSuccess = id => ({
  type: DELETE_DEAL_TASK_SUCCESS,
  payload: { id },
});

const fetchDealTaskSuccess = response => ({
  type: FETCH_DEAL_TASKS_SUCCESS,
  payload: { ...response },
});

const isLoadingAction = isLoading => ({
  type: IS_LOADING_TASKS_ACTION,
  payload: { isLoading },
});

const updateDealTaskSuccess = task => ({
  type: UPDATE_DEAL_TASK_SUCCESS,
  payload: { ...task },
});

const handleError = (error, type) => ({
  type,
  payload: { error },
});

const addTask = params => dispatch =>
  taskService.createTask(params)
    .then((task) => {
      dispatch(AddDealTaskSuccess(task));
      dispatch(toast('Task Added'));
      return task;
    }).catch((error) => {
      dispatch(handleError(error, ADD_DEAL_TASK_FAILURE));
      dispatch(toast('Error Adding Task'));
    });

const completeTask = (id, isComplete) => dispatch =>
  taskService.updateTask(id, { is_complete: isComplete })
    .then(() => {
      dispatch(deleteDealTaskSuccess(id));
      dispatch(toast(isComplete ? 'Task Completed' : 'Task marked as uncompleted.'));
    }).catch((error) => {
      dispatch(handleError(error, UPDATE_DEAL_TASK_FAILURE));
      dispatch(toast('Error Updating Task'));
    });

const deleteTask = id => dispatch =>
  taskService.deleteTask(id)
    .then(() => {
      dispatch(deleteDealTaskSuccess(id));
      dispatch(toast('Task Deleted'));
    }).catch((error) => {
      dispatch(handleError(error, DELETE_DEAL_TASK_FAILURE));
      dispatch(toast('Error Deleting Task'));
    });

const fetchTasks = query => (dispatch) => {
  dispatch(isLoadingAction(true));
  return taskService.fetchTasks(query)
    .then(response => dispatch(fetchDealTaskSuccess(response)))
    .catch((error) => {
      dispatch(handleError(error, FETCH_DEAL_TASKS_FAILURE));
      dispatch(toast('Error Fetching Tasks'));
    })
    .then(() => dispatch(isLoadingAction(false)));
};

const updateTask = (id, params) => dispatch =>
  taskService.updateTask(id, params)
    .then((task) => {
      dispatch(updateDealTaskSuccess(task));
      dispatch(toast('Task Updated'));
    }).catch((error) => {
      dispatch(handleError(error, UPDATE_DEAL_TASK_FAILURE));
      dispatch(toast('Error Updating Task'));
    });

export {
  addTask,
  completeTask,
  deleteTask,
  fetchTasks,
  updateTask,
};
