import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';

const getChangedProperties = (original, changed) => {
  let changedProperties = reduce(changed, (result, value, key) => {
    const equalTo = isEqual(value, original[key]);

    if (equalTo) {
      return result;
    }
    return result.concat(key);
  }, []);


  // Make a second pass to see if any keys in the original object have been removed
  changedProperties = reduce(original, (result, value, key) => {
    const equalTo = isEqual(value, changed[key]);

    if (equalTo || result.indexOf(key) !== -1) {
      return result;
    }
    return result.concat(key);
  }, changedProperties);

  return changedProperties;
};

// Returns an object with keys corresponding to the changed keys and values corresponding
// to the new value. Removed properties or NaN values defaults to null.
const getShallowChangesFromDeepComparison = (original, changed) => {
  const changedProps = getChangedProperties(original, changed);
  const diffs = {};

  if (changedProps.length > 0) {
    Object.keys(changedProps).forEach((propName, index) => {
      const key = changedProps[index];
      const val = changed[key];
      if (val === undefined || val === null || val === '' || (typeof val === 'number' && Number.isNaN(val))) {
        diffs[key] = null;
      } else {
        diffs[key] = val;
      }
    });
  }
  return diffs;
};

const isNullOrUndefined = property => property === null || property === undefined;

// Removes an properties that are null
const removeNullProperties = (obj) => {
  const cleaned = {};
  Object.keys(obj).forEach((key) => {
    if (!isNullOrUndefined(obj[key])) {
      cleaned[key] = obj[key];
    }
  });
  return cleaned;
};

const getObjectDifferences = (original, changed) => {
  const changes = getShallowChangesFromDeepComparison(original, changed);
  const objectDifferences = {};
  Object.keys(changes).forEach((key) => {
    objectDifferences[key] = {
      from: original[key] || null,
      to: changed[key] || null,
    };
  });
  return objectDifferences;
};

export {
  getObjectDifferences,
  getShallowChangesFromDeepComparison,
  removeNullProperties,
};
