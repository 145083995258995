import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import { ToggleSwitch } from '@cbrebuild/blocks';
import formatDueDate from '../../../utils/format-due-date';
import { getShallowChangesFromDeepComparison, removeNullProperties } from '../../../utils/object-utilities';

import EmptyState from '../../empty-state/empty-state';
import SearchInput from '../../../nucleus/search/search-input';
import ToggleView from '../../../nucleus/toggle-view/toggle-view';
import TaskListItem from '../../tasks/task-list-item';
import TaskForm from '../../tasks/task-form';
import ListActionHeader from '../../../nucleus/list-action-header/list-action-header';
import ListSort from '../../../nucleus/list-sort/list-sort';
import AddItem from '../../../nucleus/add-item/add-item';
import LoadMore from '../../../nucleus/load-more/load-more';
import ButtonWithFlyout from '../../../nucleus/button-with-flyout/button-with-flyout';
import ButtonSegmentedGroup from '../../../nucleus/button-segmented-group/button-segmented-group';
import FilterTag from '../../../nucleus/legacy/tag/filter-tag';
import userEventService from '../../../services/user-event-service';
import dealsService from '../../../services/deals-service';
import tasksService from '../../../services/tasks-service';

const sortingKeys = [
  {
    displayName: 'Date Due',
    ascSortingKey: 'due_datetime',
    descSortingKey: '-due_datetime',
  },
  {
    displayName: 'High Priority',
    ascSortingKey: 'is_priority, due_datetime',
    descSortingKey: '-is_priority, due_datetime',
  },
  {
    displayName: 'Task Title',
    ascSortingKey: 'title',
    descSortingKey: '-title',
  },
  {
    displayName: 'Date Created',
    ascSortingKey: 'created',
    descSortingKey: '-created',
  },
  {
    displayName: 'Reminder Date',
    ascSortingKey: 'alert_datetime',
    descSortingKey: '-alert_datetime',
  },
];

const cachedSortKey = 'task-list-sort';

const assigneeFilterOptions = [
  { display_name: 'All', value: 'all' },
  { display_name: 'My Tasks Only', value: 'my tasks only' },
];

class TaskListPanel extends React.Component {
  constructor(props) {
    super(props);

    const currentSortingKey = (props.deal && props.deal.id) ? (window.localStorage.getItem(cachedSortKey) || sortingKeys[0].ascSortingKey) :
      sortingKeys[0].ascSortingKey;

    this.state = {
      query: {
        is_complete: false,
        ordering: currentSortingKey,
        page: 1,
        assignees: [],
      },
      showForm: false,
      openTaskForms: [],
      task: null,
    };
  }

  componentDidMount() {
    const { taskId } = this.props;
    this.fetchTasks();
    if (taskId) {
      this.fetchTask();
    }
  }

  componentDidUpdate(prevProps) {
    const { taskId } = this.props;
    const { task } = this.state;

    if (taskId && (prevProps.taskId !== taskId)) {
      this.fetchTask();
    }
    // clear previous task
    if (!taskId && task) {
      this.clearTask();
    }
  }

  clearTask = () => {
    this.setState({ task: null });
  };

  fetchTask = () => {
    const { taskId } = this.props;
    tasksService.fetchTask(taskId)
      .then((task) => {
        this.setState({ task });
      });
  };

  fetchTasks = (params = {}) => {
    const {
      deal,
      fetchTasks,
      pageSize,
    } = this.props;
    const query = {
      ...this.state.query,
      ...params,
    };

    this.setState({ query });

    const newParams = deal ? { ...query, deal: deal.id, page_size: pageSize } : { ...query, page_size: pageSize };
    fetchTasks(newParams);
  };

  handleSearchTasks = search => this.fetchTasks({ search });
  handleAssigneeFilterChange = ({ value }) => this.fetchTasks({ assignees: value !== 'all' ? [this.props.user.id] : [], page: 1 });
  handleRemoveAssigneeFilterTag = () => this.fetchTasks({ assignees: [], page: 1 });
  handleCompletedFilterChange = ({ target: { checked: is_complete } }) => this.fetchTasks({ is_complete, page: 1 });
  handleRemoveCompletedFilterTag = () => this.fetchTasks({ is_complete: false, page: 1 });
  handleSortChange = (sort) => {
    const {
      query: {
        ordering,
      },
    } = this.state;
    this.fetchTasks({ ordering: sort, page: 1 });
    this.trackSortChange(sort, ordering);
    window.localStorage.setItem(cachedSortKey, sort);
  };
  handleLoadMore = () => this.fetchTasks({ page: this.state.query.page + 1 });
  handleToggleAddForm = () => this.setState({ showForm: !this.state.showForm });

  handleAddTask = (id, task) => {
    const {
      deal,
    } = this.props;
    this.props.addTask(task);
    this.handleToggleAddForm();
    this.trackTaskCreation();

    if (deal && deal.id) {
      dealsService.updateDeal(deal.id, {
        modified: new Date(),
      });
    }
  }
  handleUpdateTask = toggle => (id, updatedTask) => {
    const original = this.props.tasks.find(task => task.id === id);
    const {
      deal,
    } = this.props;

    this.props.updateTask(id, updatedTask).then(() => {
      toggle();
      this.setState({ openTaskForms: this.state.openTaskForms.filter(i => i !== id) });
      if (deal && deal.id) {
        dealsService.updateDeal(deal.id, {
          modified: new Date(),
        });
      }
    });

    this.trackTaskUpdates(updatedTask, original);
  };

  handleCompleteTask = (id, isCompleted) => {
    const { completeTask } = this.props;
    completeTask(id, isCompleted);
    this.trackTaskStatusChange(id);
  };

  handleDeleteTask = (id) => {
    const { deleteTask, deal } = this.props;
    deleteTask(id);
    this.setState({ openTaskForms: this.state.openTaskForms.filter(i => i !== id) });
    this.trackTaskDeletion(id);
    if (deal && deal.id) {
      dealsService.updateDeal(deal.id, {
        modified: new Date(),
      });
    }
  }

  handleToggleView = (id, isEdit, toggle) => {
    const {
      openTaskForms,
    } = this.state;
    if (isEdit) {
      this.setState({
        openTaskForms: [...openTaskForms, id],
      });
    } else {
      this.setState({
        openTaskForms: openTaskForms.filter(i => i !== id),
      });
    }
    toggle();
  };

  trackSortChange = (newOrder, oldOrder) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent({
      eventCategory: 'Sort',
      eventLabel: newOrder,
      eventAction: '_sort_change',
      eventData: {
        from: oldOrder,
        to: newOrder,
      },
    }, { actionPrefix, categoryPrefix });
  };

  trackTaskCreation = () => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent({
      eventCategory: 'Sort',
      eventAction: 'task_created',
    }, { actionPrefix, categoryPrefix });
  };

  trackTaskDeletion = (id) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent({
      eventCategory: 'Task Action',
      eventAction: 'task_deleted',
      eventValue: id,
    }, { actionPrefix, categoryPrefix });
  };

  trackTaskStatusChange = (id) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    userEventService.trackEvent({
      eventCategory: 'Task Action',
      eventAction: 'task_status',
      eventValue: id,
    }, { actionPrefix, categoryPrefix });
  };

  trackTaskUpdates = (updatedProps, original) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    const difference = getShallowChangesFromDeepComparison(original, updatedProps);
    if (original.assignees[0] && updatedProps.assignees[0] && original.assignees[0].id === updatedProps.assignees[0]) {
      difference.assignees = null;
    }
    const keys = Object.keys(removeNullProperties(difference)).map(key => key);
    userEventService.trackEvent({
      eventCategory: 'Task Action',
      eventAction: 'task_edited',
      eventData: keys,
    }, { actionPrefix, categoryPrefix });
  };

  renderTaskItem = (taskItem) => {
    const {
      user,
      showDealLink,
      showEditIcon,
    } = this.props;
    return (
      <li key={taskItem.id}>
        <ToggleView
          key={taskItem.id}
          render={toggle =>
            (<TaskListItem
              dueDate={formatDueDate(taskItem.due_datetime)}
              onCompleteTask={id => this.handleCompleteTask(id, !taskItem.is_complete)}
              onEditTask={() => this.handleToggleView(taskItem.id, true, toggle)}
              task={taskItem}
              deal={taskItem.deal}
              showDealLink={showDealLink}
              showEditIcon={showEditIcon}
            />)
          }
          elseRender={(toggle) => {
            const update = this.handleUpdateTask(toggle);
            return (
              <div className="edit-task-form-wrapper">
                <TaskForm
                  dealId={taskItem.deal}
                  onSubmit={update}
                  onCancel={() => this.handleToggleView(taskItem.id, false, toggle)}
                  onDelete={this.handleDeleteTask}
                  taskToEdit={taskItem}
                  user={user}
                  showDealAutocomplete={showDealLink}
                />
              </div>
            );
          }}
        />
      </li>
    );
  };

  render() {
    const {
      count,
      deal,
      isLoading,
      isOverview,
      next,
      tasks,
      user,
      showDealLink,
      showHeader,
      taskId,
      showFSS,
    } = this.props;
    const {
      query,
      showForm,
      openTaskForms,
      task,
    } = this.state;

    const disableActionHeader = showForm || openTaskForms.length > 0;

    let header;
    if (showHeader && showFSS) {
      // shows on all tasks page, and deal detail tasks tab
      header = (
        <React.Fragment>
          <ListActionHeader
            filter={(
              <ButtonWithFlyout
                actionIcon="filter"
                buttonText="Filter"
                buttonIcon="chevron-down"
                disabled={disableActionHeader}
                position="left"
              >
                <div className="filters">
                  <ButtonSegmentedGroup
                    onChange={this.handleAssigneeFilterChange}
                    options={assigneeFilterOptions}
                    selected={(query.assignees.length) ? assigneeFilterOptions[1] : assigneeFilterOptions[0]}
                  />
                  <div className="filter-option">
                    <ToggleSwitch
                      checked={query.is_complete}
                      onChange={this.handleCompletedFilterChange}
                    >
                      Show Completed Only
                    </ToggleSwitch>
                  </div>
                </div>
              </ButtonWithFlyout>
            )}
            search={(
              <SearchInput
                handleSearchTermChange={debounce(this.handleSearchTasks, 300)}
                searchKey="tasks"
                disabled={disableActionHeader}
              />
            )}
            isLoading={isLoading}
            sort={(
              <ListSort
                currentSortingKey={query.ordering}
                onChange={this.handleSortChange}
                onAborted={() => { }}
                options={sortingKeys}
                disabled={disableActionHeader}
              />
            )}
          />
          {(query.is_complete || query.assignees.length)
            ? (
              <div className="filter-bar">
                {(query.is_complete)
                  ? <FilterTag
                      disabled={disableActionHeader}
                      handleClearFilter={this.handleRemoveCompletedFilterTag}
                      value="completed tasks"
                  />
                  : null}
                {(query.assignees.length)
                  ? <FilterTag
                      disabled={disableActionHeader}
                      handleClearFilter={this.handleRemoveAssigneeFilterTag}
                      value="my tasks only"
                  />
                  : null}
              </div>
            ) : null
          }
        </React.Fragment>
      );
    } else if (showHeader) {
      // shows on deal details overview tasks panel
      header = (
        <div className="loading-animation-wrapper">
          <div className="nd-row-item tall">
            <h2>Tasks</h2>
            <Link to="tasks">
              See {count} Task(s)
            </Link>
          </div>
          <div className={`loading-animation${isLoading ? ' active' : ''}`} />
        </div>
      );
    } else {
      // hide header on dashboard tasks widget
      header = null;
    }

    let taskList;
    // render list of task based on props if applicable
    if (taskId && task) {
      taskList = this.renderTaskItem(task);
    } else {
      taskList = (tasks.map(t => this.renderTaskItem(t)));
    }

    let link;
    // add 'See N more Task(s)'
    if ((count > 1) && tasks && tasks.length && taskId) {
      const seeMoreText = `See ${(count - 1)} more Task${(count - 1) > 1 ? 's' : ''}`;
      if (disableActionHeader) {
        link = (
          <div className="p bold link-disabled">
            {seeMoreText}
          </div>
        );
      } else {
        link = (
          <Link to="tasks">
            {seeMoreText}
          </Link>
        );
      }
    }

    const addTaskBar = (
      <React.Fragment>
        <AddItem label="Task" onClick={this.handleToggleAddForm} />
        {link || ''}
      </React.Fragment>
    );

    return (
      <div className="card-default task-list-panel">
        {header}
        {showHeader && (
          <div className="add-task">
            {
              !showForm
                ? addTaskBar
                : (
                  <div className="add-task-form-wrapper">
                    <TaskForm
                      dealId={deal && deal.id}
                      onSubmit={this.handleAddTask}
                      onCancel={this.handleToggleAddForm}
                      onDelete={this.handleDeleteTask}
                      taskToEdit={undefined}
                      user={user}
                      showDealAutocomplete={showDealLink}
                    />
                  </div>
                )
            }
          </div>
        )}
        <ul className="task-list">
          {taskList}
          {(!showForm && !tasks.length && !isLoading) &&
          <div className="empty-state-container">
            <EmptyState
              hideImg={isOverview}
              message="Create private tasks or assign tasks to CBRE team members."
              type="tasks"
            />
          </div>}
        </ul>
        {!taskId &&
        <LoadMore
          handleLoadMore={this.handleLoadMore}
          hasNextPage={!!next}
          isLoading={isLoading}
        />}
      </div>
    );
  }
}

TaskListPanel.propTypes = {
  analyticProperties: PropTypes.shape({
    actionPrefix: PropTypes.string,
    categoryPrefix: PropTypes.string,
  }).isRequired,
  deal: PropTypes.shape({
    id: PropTypes.number,
  }),
  isLoading: PropTypes.bool.isRequired,
  isOverview: PropTypes.bool,
  next: PropTypes.number,
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  addTask: PropTypes.func,
  completeTask: PropTypes.func.isRequired,
  deleteTask: PropTypes.func,
  fetchTasks: PropTypes.func.isRequired,
  updateTask: PropTypes.func,
  count: PropTypes.number,
  pageSize: PropTypes.number,
  showDealLink: PropTypes.bool,
  showEditIcon: PropTypes.bool,
  showHeader: PropTypes.bool,
  taskId: PropTypes.number,
  showFSS: PropTypes.bool,
};
TaskListPanel.defaultProps = {
  isOverview: false,
  next: null,
  count: undefined,
  pageSize: 25,
  deal: null,
  showDealLink: false,
  showEditIcon: true,
  showHeader: true,
  taskId: undefined,
  showFSS: false,
  addTask: () => { },
  updateTask: () => { },
  deleteTask: () => { },
};

export default TaskListPanel;
