import React from 'react';
import PropTypes from 'prop-types';

const Widget = ({
  title,
  controls,
  children,
  className,
  alternateHeader,
}) => (
  <div className={`nd-widget ${className}`}>
    <div className="widget-header">
      {alternateHeader || <h3>{title}</h3>}
      <div className="controls">
        {controls}
      </div>
    </div>
    <div className="widget-content">
      {children}
    </div>
  </div>
);

export default Widget;

Widget.propTypes = {
  alternateHeader: PropTypes.shape({}),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  controls: PropTypes.shape({}),
  className: PropTypes.string,
};

Widget.defaultProps = {
  alternateHeader: null,
  title: '',
  controls: null,
  className: '',
};
