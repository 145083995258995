import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';

const ExpandToggle = (props) => {
  const { disabled, isOpen, onClick } = props;
  const expandIconClass = isOpen ? ' open' : '';
  const disabledClass = disabled ? ' disabled' : '';
  return (
    <IconButton className={`h2 nd-expand-toggle ${expandIconClass}${disabledClass}`} iconName="chevron-right" onClick={onClick} />
  );
};

ExpandToggle.propTypes = {
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

ExpandToggle.defaultProps = {
  disabled: false,
  isOpen: false,
  onClick: () => {},
};

export default ExpandToggle;
