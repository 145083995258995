import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import uniqueId from 'lodash/uniqueId';
import { Button, Checkbox, Icon, IconButton, Textarea } from '@cbrebuild/blocks';

import {
  getShallowChangesFromDeepComparison,
  removeNullProperties,
} from '../../utils/object-utilities';

import companyService from '../../services/companies-service';
import userEventService from '../../services/user-event-service';
import dealsService from '../../services/deals-service';
import AddTag from '../../nucleus/legacy/tag/add-tag';
import ButtonListFlyout from '../legacy/button-list-flyout/button-list-flyout';
import ContactAutocomplete from '../autocomplete/contact-autocomplete';
import CompanyAutocomplete from '../autocomplete/company-autocomplete';
import EmailInput from '../../nucleus/inputs/email-input';
import FilterTag from '../../nucleus/legacy/tag/filter-tag';
import PhoneInput from '../../nucleus/inputs/phone-input';
import Select from '../../nucleus/select/select';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    const contact = cloneDeep(this.props.contact) || {};
    if (this.props.isPrimaryOnly) {
      contact.is_primary = true;
    }
    const phoneCounts = {
      work: 0,
      home: 0,
      mobile: 0,
    };
    contact.personal_notes = contact.personal_notes
      ? contact.personal_notes
      : '';
    contact.phone_numbers = (!contact.phone_numbers)
      ? []
      : contact.phone_numbers;
    contact.email_addresses = (!contact.email_addresses)
      ? []
      : contact.email_addresses;
    contact.business_address = (isEmpty(contact.business_address))
      ? {}
      : contact.business_address;
    contact.home_address = (isEmpty(contact.home_address))
      ? {}
      : contact.home_address;
    contact.other_address = (isEmpty(contact.other_address))
      ? {}
      : contact.other_address;
    if (contact.phone_numbers.length === 0) {
      contact.phone_numbers.push({
        uniqueId: uniqueId(),
        number: '',
        type: 'work',
        default: '',
      });
      phoneCounts.work = 1;
    } else {
      contact.phone_numbers = contact.phone_numbers.map((val) => {
        const phoneNumber = {
          ...val,
          uniqueId: uniqueId(),
          isValid: true,
        };
        phoneCounts[val.type] += 1;
        return phoneNumber;
      });
    }
    if (contact.email_addresses.length === 0) {
      contact.email_addresses.push({
        uniqueId: uniqueId(),
        address: '',
        name: '',
        type: 'work',
        default: '',
      });
    } else {
      contact.email_addresses = contact.email_addresses.map((val) => {
        const emailAddress = {
          ...val,
          uniqueId: uniqueId(),
          isValid: true,
        };
        return emailAddress;
      });
    }
    this.state = {
      phoneAllowed: {
        work: 2,
        home: 2,
        mobile: 1,
      },
      phoneCounts,
      firstNameError: '',
      contact,
      addToPersonalContacts: false,
    };
    this.addressTop = null;
  }

  getPhoneNumberListFlyout = () => {
    const {
      work: workCount,
      home: homeCount,
      mobile: mobileCount,
    } = this.state.phoneCounts;
    const {
      work: workAllowed,
      home: homeAllowed,
      mobile: mobileAllowed,
    } = this.state.phoneAllowed;
    const workDisplayName = (workAllowed === workCount)
      ? `Work (MAX ${workCount})`
      : 'Work';
    const homeDisplayName = (homeAllowed === homeCount)
      ? `Home (MAX ${homeCount})`
      : 'Home';
    const mobileDisplayName = (mobileAllowed === mobileCount)
      ? `Mobile (MAX ${mobileCount})`
      : 'Mobile';
    return [
      {
        displayName: workDisplayName,
        onClick: () => { this.addPhoneNumber('work'); },
        disabled: (workAllowed === workCount),
      },
      {
        displayName: homeDisplayName,
        onClick: () => { this.addPhoneNumber('home'); },
        disabled: (homeAllowed === homeCount),
      },
      {
        displayName: mobileDisplayName,
        onClick: () => { this.addPhoneNumber('mobile'); },
        disabled: (mobileAllowed === mobileCount),
      },
    ];
  }

  getPhoneNumberListSelect = () => {
    const {
      work: workCount,
      home: homeCount,
      mobile: mobileCount,
    } = this.state.phoneCounts;
    const {
      work: workAllowed,
      home: homeAllowed,
      mobile: mobileAllowed,
    } = this.state.phoneAllowed;
    const workDisplayName = (workAllowed === workCount)
      ? `Work (MAX ${workCount})`
      : 'Work';
    const homeDisplayName = (homeAllowed === homeCount)
      ? `Home (MAX ${homeCount})`
      : 'Home';
    const mobileDisplayName = (mobileAllowed === mobileCount)
      ? `Mobile (MAX ${mobileCount})`
      : 'Mobile';
    return [
      {
        display_name: workDisplayName,
        value: 'work',
        disabled: (workAllowed === workCount),
      },
      {
        display_name: homeDisplayName,
        value: 'home',
        disabled: (homeAllowed === homeCount),
      },
      {
        display_name: mobileDisplayName,
        value: 'mobile',
        disabled: (mobileAllowed === mobileCount),
      },
    ];
  }

  getEmailListFlyout = () => {
    const { contact } = this.state;
    const workDisplayName = (contact.email_addresses && contact.email_addresses.length > 2)
      ? 'Work (MAX 3)'
      : 'Work';
    const personalDisplayName = (contact.email_addresses && contact.email_addresses.length > 2)
      ? 'Personal (MAX 3)'
      : 'Personal';
    const disabled = (contact.email_addresses && contact.email_addresses.length > 2);
    return [
      {
        displayName: workDisplayName,
        onClick: () => { this.addEmailAddress('work'); },
        disabled,
      },
      {
        displayName: personalDisplayName,
        onClick: () => { this.addEmailAddress('personal'); },
        disabled,
      },
    ];
  }

  getEmailListSelect = () => [
    { display_name: 'Work', value: 'work' },
    { display_name: 'Personal', value: 'personal' },
  ]

  getAddressListFlyout = () => {
    const { contact } = this.state;
    const workDisplayName = (contact.business_address && !isEmpty(contact.business_address))
      ? 'Work (MAX 1)'
      : 'Work';
    const homeDisplayName = (contact.home_address && !isEmpty(contact.home_address))
      ? 'Home (MAX 1)'
      : 'Home';
    const otherDisplayName = (contact.other_address && !isEmpty(contact.other_address))
      ? 'Other (MAX 1)'
      : 'Other';
    return [
      {
        displayName: workDisplayName,
        onClick: () => { this.addAddress('work'); },
        disabled: (contact.business_address && !isEmpty(contact.business_address)),
      },
      {
        displayName: homeDisplayName,
        onClick: () => { this.addAddress('home'); },
        disabled: (contact.home_address && !isEmpty(contact.home_address)),
      },
      {
        displayName: otherDisplayName,
        onClick: () => { this.addAddress('other'); },
        disabled: (contact.other_address && !isEmpty(contact.other_address)),
      },
    ];
  }

  getAddressListSelect = () => {
    const { contact } = this.state;
    const workDisplayName = (contact.business_address && !isEmpty(contact.business_address))
      ? 'Work (MAX 1)'
      : 'Work';
    const homeDisplayName = (contact.home_address && !isEmpty(contact.home_address))
      ? 'Home (MAX 1)'
      : 'Home';
    const otherDisplayName = (contact.other_address && !isEmpty(contact.other_address))
      ? 'Other (MAX 1)'
      : 'Other';
    return [
      {
        display_name: workDisplayName,
        value: 'work',
        disabled: (contact.business_address && !isEmpty(contact.business_address)),
      },
      {
        display_name: homeDisplayName,
        value: 'home',
        disabled: (contact.home_address && !isEmpty(contact.home_address)),
      },
      {
        display_name: otherDisplayName,
        value: 'other',
        disabled: (contact.other_address && !isEmpty(contact.other_address)),
      },
    ];
  }

  addAddress = (type) => {
    const typeMap = {
      work: 'business_address',
      home: 'home_address',
      other: 'other_address',
    };
    this.addressTop = type;
    const { contact } = this.state;
    contact[typeMap[type]] = {
      street: '',
      city: '',
      state: '',
      countryOrRegion: '',
      postalCode: '',
    };
    this.setState({ contact });
  }
  removeAddress = (type) => {
    const typeMap = {
      work: 'business_address',
      home: 'home_address',
      other: 'other_address',
    };

    const { contact } = this.state;
    contact[typeMap[type]] = {};
    this.setState({ contact });
  }

  addPhoneNumber = (type) => {
    const {
      phoneCounts,
      contact,
    } = this.state;
    contact.phone_numbers.unshift({
      uniqueId: uniqueId(),
      number: '',
      type,
      default: '',
    });
    phoneCounts[type] += 1;
    this.setState({
      phoneCounts,
      contact,
    });
  }

  emailTypeChange = (value, index) => {
    const { contact } = this.state;
    contact.email_addresses[index].type = value;
    this.setState({ contact });
  }

  phoneNumberTypeChange = (value, index) => {
    const { contact } = this.state;
    let { phoneCounts } = this.state;
    contact.phone_numbers[index].type = value;
    phoneCounts = {
      work: 0,
      home: 0,
      mobile: 0,
    };
    contact.phone_numbers.forEach((number) => {
      phoneCounts[number.type] += 1;
    });
    this.setState({
      phoneCounts,
      contact,
    });
  }

  addressTypeChange = (newType, oldType) => {
    const typeMap = {
      work: 'business_address',
      home: 'home_address',
      other: 'other_address',
    };
    const { contact } = this.state;
    contact[typeMap[newType]] = contact[typeMap[oldType]];
    contact[typeMap[oldType]] = {};
    this.setState({ contact });
  }

  removePhoneNumber = (index) => {
    const {
      phoneCounts,
      contact,
    } = this.state;
    phoneCounts[contact.phone_numbers[index].type] -= 1;
    contact.phone_numbers.splice(index, 1);
    this.setState({
      phoneCounts,
      contact,
    });
  }

  removeEmailAddress = (index) => {
    const { contact } = this.state;
    contact.email_addresses.splice(index, 1);
    this.setState({ contact });
  }

  phoneNumberOnChange = (value, index, isValid) => {
    const { contact } = this.state;
    contact.phone_numbers[index].number = value;
    contact.phone_numbers[index].isValid = isValid;
    this.setState({ contact });
  }

  phoneNumberRender = () => {
    let phoneRender = [];
    const { contact } = this.state;
    if (contact.phone_numbers) {
      phoneRender = contact.phone_numbers.map((val, index) => (
        <div className="phone-row" key={val.uniqueId}>
          <IconButton
            className="clear-button blxs-button-icon-small"
            iconName="close"
            onClick={() => { this.removePhoneNumber(index); }}
            variant="basic"
          />
          <div className="phone-type">
            <label>Phone Type</label>
            <Select
              onChange={(option) => { this.phoneNumberTypeChange(option.value, index); }}
              defaultOption={this.getPhoneNumberListSelect().find(obj => obj.value === contact.phone_numbers[index].type)}
              options={this.getPhoneNumberListSelect()}
            />
          </div>
          <div className="phone-input">
            <PhoneInput
              value={val.number}
              onChange={(value, isValid) => { this.phoneNumberOnChange(value, index, isValid); }}
              onBlur={(value, isValid) => { this.phoneNumberOnChange(value, index, isValid); }}
            />
          </div>
        </div>
      ));
    }
    return phoneRender;
  }

  addressInputOnChange = (e, addressType, input) => {
    const { contact } = this.state;
    contact[addressType] = {
      ...contact[addressType],
      [input]: e.target.value,
    };
    this.setState({ contact });
  }

  addressRenderBase = (type) => {
    let addressRender = '';
    const typeMap = {
      work: 'business_address',
      home: 'home_address',
      other: 'other_address',
    };
    const { contact } = this.state;
    if (!isEmpty(contact[typeMap[type]])) {
      addressRender = (
        <React.Fragment key={type}>
          <div className="address-street-row">
            <IconButton
              className="blxs-button-icon-small"
              iconName="close"
              onClick={() => { this.removeAddress(type); }}
              variant="basic"
            />
            <div className="address-type">
              <label>Address Type</label>
              <Select
                onChange={(option) => { this.addressTypeChange(option.value, type); }}
                defaultOption={this.getAddressListSelect().find(obj => obj.value === type)}
                options={this.getAddressListSelect()}
              />
            </div>
            <div className="address-street">
              <label>Street</label>
              <input
                type="text"
                value={contact[typeMap[type]].street}
                onChange={e => this.addressInputOnChange(e, typeMap[type], 'street')}
              />
            </div>
          </div>
          <div className="address-city-row">
            <div className="address-city">
              <label>City</label>
              <input
                type="text"
                value={contact[typeMap[type]].city}
                onChange={e => this.addressInputOnChange(e, typeMap[type], 'city')}
              />
            </div>
            <div className="address-state">
              <label>State</label>
              <input
                type="text"
                value={contact[typeMap[type]].state}
                onChange={e => this.addressInputOnChange(e, typeMap[type], 'state')}
              />
            </div>
          </div>
          <div className="address-country-row">
            <div className="address-country">
              <label>Country</label>
              <input
                type="text"
                value={contact[typeMap[type]].countryOrRegion}
                onChange={e => this.addressInputOnChange(e, typeMap[type], 'countryOrRegion')}
              />
            </div>
            <div className="address-zipcode">
              <label>Zipcode</label>
              <input
                type="text"
                value={contact[typeMap[type]].postalCode}
                onChange={e => this.addressInputOnChange(e, typeMap[type], 'postalCode')}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
    return addressRender;
  }

  addressRender = () => {
    let types = ['work', 'home', 'other'];
    const render = [];
    if (this.addressTop) {
      render.push(this.addressRenderBase(this.addressTop));
      types = types.filter(item => item !== this.addressTop);
    }
    types.forEach(type => render.push(this.addressRenderBase(type)));
    return render;
  }

  addEmailAddress = (type) => {
    const { contact } = this.state;
    contact.email_addresses.unshift({
      uniqueId: uniqueId(),
      address: '',
      name: '',
      type,
      default: '',
    });
    this.setState({ contact });
  }

  emailOnChange = (value, index, isValid) => {
    const { contact } = this.state;
    contact.email_addresses[index].address = value;
    contact.email_addresses[index].isValid = isValid;
    this.setState({ contact });
  }

  inputOnChange = (e, inputName) => {
    const { contact } = this.state;
    contact[inputName] = e.target.value;
    this.setState({ contact });
  }

  emailAddressRender = () => {
    let emailAddressRender = [];
    const { contact } = this.state;
    if (contact.email_addresses) {
      emailAddressRender = contact.email_addresses.map((val, index) =>
        (
          <div className="email-row" key={val.uniqueId}>
            <IconButton
              className="blxs-button-icon-small"
              iconName="close"
              onClick={() => { this.removeEmailAddress(index); }}
              variant="basic"
            />
            <div className="email-type">
              <label>Email Type</label>
              <Select
                onChange={(option) => { this.emailTypeChange(option.value, index); }}
                defaultOption={this.getEmailListSelect().find(obj => obj.value === contact.email_addresses[index].type)}
                options={this.getEmailListSelect()}
              />
            </div>
            <div className="email-input">
              <EmailInput
                value={val.address}
                onChange={(value, isValid) => { this.emailOnChange(value, index, isValid); }}
              />
            </div>
          </div>));
    }
    return emailAddressRender;
  }

  nameOnChange = (input, inputField) => {
    let { contact } = this.state;
    if (isString(input)) {
      contact[inputField] = input;
    } else {
      const numbers =
        input.phone_numbers.map(val => ({ ...val, uniqueId: uniqueId(), isValid: true }));
      const emailAddresses =
        input.email_addresses.map(val => ({ ...val, uniqueId: uniqueId(), isValid: true }));
      contact = {
        ...input,
        phone_numbers: numbers,
        email_addresses: emailAddresses,
        is_primary: contact.is_primary,
      };
      delete contact.reference_contact;
      // the server will by default set `contact_type=deal`
      delete contact.contact_type;
      delete contact.id;
    }
    this.setState({
      firstNameError: '',
      contact,
    });
  }

  companyOnChange = (input) => {
    const { contact } = this.state;
    if (isString(input)) {
      contact.company = {
        ...contact.company,
        name: input,
      };
    } else {
      contact.company = input;
    }
    this.setState({ contact });
  }

  validateform = () => {
    const { contact } = this.state;
    if (isEmpty(contact.given_name)) {
      this.setState({ firstNameError: 'First Name is required' });
      return false;
    }
    const phoneNumbersValid =
      contact.phone_numbers.every(value => (value.isValid || isEmpty(value.number)));
    if (!phoneNumbersValid) {
      return false;
    }
    const emailValid =
      contact.email_addresses.every(value => (value.isValid || isEmpty(value.address)));
    if (!emailValid) {
      return false;
    }
    return true;
  }

  isAddressEmpty = (addr) => {
    if (isEmpty(addr)) {
      return true;
    }
    if (isEmpty(addr.city) &&
      isEmpty(addr.countryOrRegion) &&
      isEmpty(addr.postalCode) &&
      isEmpty(addr.state) &&
      isEmpty(addr.street)) {
      return true;
    }
    return false;
  }

  removeEmptyEmailAddresses = emails => emails.filter(val => !(isEmpty(val.address) && isEmpty(val.name) && isEmpty(val.default)))

  removeEmptyPhoneNumbers = phones => phones.filter(val => !isEmpty(val.number));

  toggleAddToPersonalContacts = ({ target: { checked: addToPersonalContacts } }) => {
    this.setState({ addToPersonalContacts });
  };

  handleSubmit = async () => {
    if (!this.validateform()) {
      return;
    }
    const { contact, addToPersonalContacts } = this.state;
    contact.phone_numbers = this.removeEmptyPhoneNumbers(contact.phone_numbers);
    if (contact.phone_numbers.length > 0) {
      contact.phone_numbers = contact.phone_numbers.map(({ isValid, uniqueId, ...keep }) => keep); // eslint-disable-line
    }
    contact.email_addresses = this.removeEmptyEmailAddresses(contact.email_addresses);
    if (contact.email_addresses.length > 0) {
      contact.email_addresses =
        contact.email_addresses.map(({ isValid, uniqueId, ...keep }) => keep); // eslint-disable-line
    }
    if (this.isAddressEmpty(contact.business_address)) {
      contact.business_address = {};
    }
    if (this.isAddressEmpty(contact.home_address)) {
      contact.home_address = {};
    }
    if (this.isAddressEmpty(contact.other_address)) {
      contact.other_address = {};
    }
    // we need to duplicate a company if this one already exists
    if (contact.company) {
      const saved = this.props.contact;
      const unsaved = contact.company;
      let company;
      // if no name remove company from contact
      if (!unsaved.name) {
        company = { id: undefined };
      } else {
        company = await companyService.createCompanyHelper(saved, unsaved);
      }
      if (company) {
        contact.company = company.id;
      }
    }
    this.trackContactAction(contact, addToPersonalContacts);
    this.props.onSubmit(contact, addToPersonalContacts);

    const {
      dealId,
    } = this.props;
    dealsService.updateDeal(dealId, {
      modified: new Date(),
    });
  }

  handleDelete = () => {
    const {
      onDelete,
      dealId,
    } = this.props;
    onDelete(this.state.contact.id, true);
    userEventService.trackEvent({
      eventLabel: `${!dealId ? 'my' : 'deal'}_contacts`,
      eventCategory: `${!dealId ? 'My' : 'Deal'} Contacts Action`,
      eventAction: `${!dealId ? 'my' : 'deal'}_contact_deleted`,
    });
    dealsService.updateDeal(dealId, {
      modified: new Date(),
    });
  }

  trackContactAction = (contact, addToPersonalContacts) => {
    const {
      contact: originalContact,
      dealId,
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    const renderDealText = dealId ? 'deal_' : 'my_';
    const renderActionText = contact.id ? 'edited' : 'created';
    const renderIsPrimaryText = contact.is_primary ? 'primary_' : '';
    const eventAction = `${renderIsPrimaryText}${renderDealText}contact_${renderActionText}`;
    const data = contact.id
      ? {
        edited: Object
          .keys(removeNullProperties(getShallowChangesFromDeepComparison(originalContact, contact)))
          .map(key => key),
      }
      : {};

    userEventService.trackEvent(
      {
        eventAction,
        eventCategory: `${dealId ? 'Deal' : 'My'} Contacts Action`,
        eventData: data,
      },
      dealId ? {
        actionPrefix,
        categoryPrefix,
      } : { actionPrefix },
    );

    if (addToPersonalContacts) {
      userEventService.trackEvent(
        { eventAction: 'contact_created', eventCategory: 'Contacts Action' },
        {
          actionPrefix,
          categoryPrefix,
        },
      );
    }
  }

  updatePrimaryButton = ({ target: { checked } }) => {
    this.setState({
      contact: {
        ...this.state.contact,
        is_primary: checked,
      },
    });
  }

  updateHideFromDealIQ = ({ target: { checked } }) => {
    this.setState({
      contact: {
        ...this.state.contact,
        hidden: checked,
      },
    });
    userEventService.trackEvent({
      eventAction: `my_contact_${checked ? 'hide' : 'unhide'}_from_dealiq`,
      eventCategory: 'My Contacts Action',
      eventLabel: 'my_contacts',
    });
  }

  updateOutlookSyncEnabled = ({ target: { checked } }) => {
    this.setState({
      contact: {
        ...this.state.contact,
        outlook_sync_enabled: checked,
      },
    });
    userEventService.trackEvent({
      eventAction: 'my_contact_moved_to_outlook',
      eventCategory: 'My Contacts Action',
      eventLabel: 'my_contacts',
    });
  }

  handleAddTag = (newTag) => {
    const {
      contact: {
        id,
        tags = [],
      },
    } = this.state;
    this.setState({
      contact: {
        ...this.state.contact,
        tags: [...tags.filter(tag => tag !== newTag), newTag],
      },
    });
    userEventService.trackEvent({
      eventAction: `my_contact_tag_added${id ? '' : '_to_new_contact'}`,
      eventCategory: 'My Contacts Action',
      eventLabel: 'my_contacts',
    });
  }

  handleRemoveTag = (tagToRemove) => {
    this.setState({
      contact: {
        ...this.state.contact,
        tags: this.state.contact.tags.filter(tag => tag !== tagToRemove),
      },
    });
    userEventService.trackEvent({
      eventAction: `my_contact_tag_removed${this.state.contact.id ? '' : '_from_new_contact'}`,
      eventCategory: 'My Contacts Action',
      eventLabel: 'my_contacts',
    });
  }

  render() {
    const {
      allContactTags,
      dealId,
      onCancel,
      outlookSyncEnabled,
    } = this.props;
    const {
      addToPersonalContacts,
      contact,
      firstNameError,
    } = this.state;
    const phoneRender = this.phoneNumberRender();
    const emailRender = this.emailAddressRender();
    const addressRender = this.addressRender();
    let companyName = '';
    if (isString(contact.company)) {
      companyName = contact.company;
    } else if (contact.company && contact.company.name) {
      companyName = contact.company.name;
    }
    const showAddToPersonal = dealId && (contact.deal !== null && !contact.id);

    return (
      <div className="contact-form">
        <form>
          <div className="checkbox-row">
            {dealId && (
              <Checkbox
                checked={contact.is_primary}
                onChange={this.updatePrimaryButton}
                disabled={this.props.isPrimaryOnly}
              >Is Primary Deal Contact
              </Checkbox>
            )}
            {(!dealId && contact.id) && (
              <Checkbox
                checked={contact.hidden}
                onChange={this.updateHideFromDealIQ}
              >Hide From Deal IQ
              </Checkbox>
            )}
            {(outlookSyncEnabled && this.props.contact && !this.props.contact.outlook_sync_enabled && !dealId) && (
              <Checkbox
                checked={contact.outlook_sync_enabled}
                onChange={this.updateOutlookSyncEnabled}
              >Move to Outlook
              </Checkbox>
            )}
            {(outlookSyncEnabled && this.props.contact && this.props.contact.outlook_sync_enabled && !dealId) && (
              <label className="moved-outlook-label">
                <Icon iconName="outlook" />
                Moved to Outlook
              </label>
            )}
          </div>
          <div className="name-row">
            <div className="first-name">
              <label>* First Name</label>
              <ContactAutocomplete
                disableSearchRequest={!dealId}
                initSearchTerm={contact.given_name || ''}
                onChange={input => this.nameOnChange(input, 'given_name')}
                lastName={contact.surname}
                errorMessage={firstNameError}
                placeholder={dealId ? 'Search Contacts' : ''}
              />
            </div>
            <div className="last-name">
              <label>Last Name</label>
              <ContactAutocomplete
                disableSearchRequest={!dealId}
                initSearchTerm={contact.surname || ''}
                onChange={input => this.nameOnChange(input, 'surname')}
                firstName={contact.given_name}
                placeholder={dealId ? 'Search Contacts' : ''}
              />
            </div>
          </div>
          <div className="job-row">
            <div className="job-name">
              <label>Job Title</label>
              <input
                type="text"
                value={contact.job_title || ''}
                onChange={e => this.inputOnChange(e, 'job_title')}
              />
            </div>
            <div className="company-name">
              <label>Company/Client</label>
              <CompanyAutocomplete initSearchTerm={companyName} onChange={this.companyOnChange} />
            </div>
          </div>
          <div>
            <ButtonListFlyout
              buttonList={this.getPhoneNumberListFlyout()}
              buttonListName="Phone"
              buttonListIcon="plus-circle-outline"
            />
          </div>
          {phoneRender}
          <div className="email-flyout">
            <ButtonListFlyout
              buttonList={this.getEmailListFlyout()}
              buttonListName="Email"
              buttonListIcon="plus-circle-outline"
            />
          </div>
          {emailRender}
          <div className="address-flyout">
            <div>
              <ButtonListFlyout
                buttonList={this.getAddressListFlyout()}
                buttonListName="Address"
                buttonListIcon="plus-circle-outline"
              />
            </div>
            {addressRender}
          </div>
          {!dealId && (
            <div className="contact-tags">
              <label>Tags</label>
              <div className="contact-tags-content">
                {contact.tags && (
                  contact.tags.map(tag => (
                    <FilterTag
                      isButton={false}
                      key={`contact-tag=${tag}`}
                      value={tag}
                      handleClearFilter={() => this.handleRemoveTag(tag)}
                    />
                  ))
                )}
                <AddTag
                  onSubmit={this.handleAddTag}
                  suggestions={allContactTags}
                />
              </div>
            </div>
          )}
          <div className="notes-row">
            <div>
              <label>Notes</label>
              <Textarea
                rows={{ min: 4, max: 10 }}
                onChange={e => this.inputOnChange(e, 'personal_notes')}
                value={contact.personal_notes || ''}
              />
            </div>
          </div>
        </form>
        <footer className={`form-footer${showAddToPersonal ? '' : ' align-right'}`}>
          {showAddToPersonal && (
            <Checkbox
              checked={addToPersonalContacts}
              onChange={this.toggleAddToPersonalContacts}
            >Add To My Contacts
            </Checkbox>)
          }
          {contact.id && <IconButton className="secondary-icon-button" iconName="bin" onClick={this.handleDelete} variant="basic" />}
          <Button variant="secondary" onClick={onCancel}>Cancel</Button>
          <Button onClick={this.handleSubmit}>Save</Button>
        </footer>
      </div>
    );
  }
}

ContactForm.propTypes = {
  allContactTags: PropTypes.arrayOf(PropTypes.string),
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  contact: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    is_primary: PropTypes.bool,
    outlook_sync_enabled: PropTypes.bool,
  }),
  dealId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isPrimaryOnly: PropTypes.bool,
  outlookSyncEnabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

ContactForm.defaultProps = {
  allContactTags: [],
  contact: {},
  dealId: null,
  isPrimaryOnly: false,
  outlookSyncEnabled: false,
  onDelete: () => { },
};

export default ContactForm;
