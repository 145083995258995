/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox } from '@cbrebuild/blocks';

import ButtonWithFlyout from '../../nucleus/button-with-flyout/button-with-flyout';
import ButtonSegmentedGroup from '../../nucleus/button-segmented-group/button-segmented-group';
import FilterTag from '../../nucleus/legacy/tag/filter-tag';
import Datepicker from '../../nucleus/datepicker/datepicker';
import StatusStrings from '../../utils/status-strings';
import Slider from '../../nucleus/slider/slider';
import TagSearch from '../../nucleus/legacy/tag/tag-search';
import Tooltip from '../../nucleus/tooltip/tooltip';
import BrokerAutocomplete from '../autocomplete/broker-autocomplete';
import Select from '../../nucleus/select/select';

class DealListFilter extends React.Component {
  constructor(props) {
    super(props);
    // default prop values are handled in the deal-list-container.js
    // and come through as this.props.initQueryParams
    this.state = {
      conversionPotential: null,
      isCloseStartDateValid: true,
      isCloseEndDateValid: true,
      isExpirationStartDateValid: true,
      isExpirationEndDateValid: true,
      selectedUntaggedDeal: this.props.queryParams.is_untagged_deal || false,
      selectedDealStage: null,
      selectedDealStatus: null,
      selectedTags: null,
      selectedDealTypeAndRepRole: null,
      selectedCloseStartDate: null,
      selectedCloseEndDate: null,
      selectedExpirationStartDate: null,
      selectedExpirationEndDate: null,
      selectedVoucherStatus: null,
      hasCleared: false,
      isOnPipeLine: this.props.queryParams.is_on_pipeline || false,
      createdBy: this.props.queryParams.created_by || null,
      createdByName: this.props.queryParams.created_by_name || '',
    };
    this.buttonWithFlyoutRef = React.createRef();
    this.tagSearchRef = React.createRef();
    this.selectOptions = [
      { display_name: 'Created By', value: 'createdBy' },
    ];
  }

  onApply = () => {
    const filters = this.stateToFilters();
    this.props.onFilterChange(filters);
    // expose closeFlyout() method from the child
    this.buttonWithFlyoutRef.current.closeFlyout();
  };

  onClear = () => {
    this.setState({
      conversionPotential: this.getInitQueryParamsConversionPotential(),
      selectedDealStage: this.getInitQueryParamsDealStage(),
      selectedDealStatus: this.getInitQueryParamsDealStatus(),
      selectedDealTypeAndRepRole: this.getInitQueryParamsDealTypeAndRepRole(),
      selectedTags: this.getInitQueryParamsTags(),
      selectedCloseStartDate: this.props.initQueryParams.date_approved_min,
      selectedCloseEndDate: this.props.initQueryParams.date_approved_max,
      selectedExpirationStartDate: this.props.initQueryParams.date_lease_to_min,
      selectedExpirationEndDate: this.props.initQueryParams.date_lease_to_max,
      hasCleared: true,
      selectedVoucherStatus: this.getInitQueryParamsVoucherStatus() || [],
      selectedUntaggedDeal: false,
      isOnPipeLine: this.props.initQueryParams.is_on_pipeline || false,
      createdBy: this.props.initQueryParams.created_by || null,
      createdByName: '',
    });
    this.tagSearchRef.current.applyFilter({ target: { value: '' } });
  };

  onConversionChange = (value) => {
    this.setState({
      conversionPotential: value,
    });
  };

  onExpirationDateChange = (value, startOrEnd = 'start_date') => {
    if (startOrEnd === 'start_date') {
      this.setState({
        selectedExpirationStartDate: value,
      });
    } else {
      this.setState({
        selectedExpirationEndDate: value,
      });
    }
  };

  onCloseDateChange = (value, startOrEnd = 'start_date') => {
    if (startOrEnd === 'start_date') {
      this.setState({
        selectedCloseStartDate: value,
      });
    } else {
      this.setState({
        selectedCloseEndDate: value,
      });
    }
  };

  onDealStageChange = (value) => {
    this.setState({
      selectedDealStage: value,
    });
  };

  onVoucherStatusChange = (value) => {
    this.setState({
      selectedVoucherStatus: value,
    });
  };

  onDealTypeChange = (value) => {
    let selectedDealTypeAndRepRole;
    if (value instanceof Array) {
      selectedDealTypeAndRepRole = value;
    } else {
      selectedDealTypeAndRepRole = value
        ? [value]
        : [];
    }
    const update = { selectedDealTypeAndRepRole };
    if (selectedDealTypeAndRepRole.length === 1 && selectedDealTypeAndRepRole[0].deal_type !== 'lease') {
      update.selectedExpirationStartDate = null;
      update.selectedExpirationEndDate = null;
    }
    this.setState(update);
  };

  onDealStatusChange = (option) => {
    this.setState({
      selectedDealStatus: option,
    });
  };

  onTagRemove = (tag) => {
    const existingTags = this.state.selectedTags || this.props.queryParams.tags;
    const selectedTags = existingTags.filter(t => t !== tag);
    this.setState({ selectedTags });
  };

  onTagSelect = (tag) => {
    const selectedTags = this.state.selectedTags ? [...this.state.selectedTags, tag] : [...this.props.queryParams.tags, tag];
    this.setState({
      selectedTags,
    });
  };

  onUntaggedDealSelect = ({ target: { checked } }) => {
    this.setState({
      selectedUntaggedDeal: checked,
      selectedTags: [],
    });
  };

  onCreatedBySelect = () => {
    // placeholder function until we add more options to select
  }

  onAutoCompleteSelect = (selected) => {
    if (!selected) {
      this.setState({ createdBy: null, createdByName: '' });
      return;
    }
    const { user_id, full_name } = selected;
    this.setState({
      createdBy: user_id,
      createdByName: full_name,
    });
  }

  // get init params
  getInitQueryParamsConversionPotential = () => ({
    min: this.props.initQueryParams.conversion_potential_min,
    max: this.props.initQueryParams.conversion_potential_max,
  });

  getInitQueryParamsDealStage = () =>
    this.dealStageFiltersToState(this.props.initQueryParams.deal_life_cycle_stage);

  getInitQueryParamsDealStatus = () =>
    this.dealArchiveStatusFiltersToState(this.props.initQueryParams.is_archived);

  getInitQueryParamsDealTypeAndRepRole = () =>
    this.dealTypeRepRolFiltersToState(this.props.initQueryParams.rep_role);

  getInitQueryParamsTags = () => this.props.initQueryParams.tags;

  // get params
  getQueryParamsConversionPotential = () => ({
    min: this.props.queryParams.conversion_potential_min,
    max: this.props.queryParams.conversion_potential_max,
  });

  getQueryParamsDealStatus = () =>
    this.dealArchiveStatusFiltersToState(this.props.queryParams.is_archived);

  getQueryParamsDealStage = () =>
    this.dealStageFiltersToState(this.props.queryParams.deal_life_cycle_stage);

  getQueryParamsVoucherStatus = () =>
    this.voucherStatusFiltersToState(this.props.queryParams.voucher_submission_status) || [];

  getQueryParamsDealTypeAndRepRole = () =>
    this.dealTypeRepRolFiltersToState(this.props.queryParams.rep_role);

  getInitQueryParamsVoucherStatus = () =>
    this.voucherStatusFiltersToState(this.props.initQueryParams.voucher_submission_status);

  getDatesForRender = () => {
    const {
      selectedCloseEndDate,
      selectedCloseStartDate,
      selectedExpirationStartDate,
      selectedExpirationEndDate,
      hasCleared,
    } = this.state;
    if (hasCleared) {
      return {
        selectedCloseEndDate,
        selectedCloseStartDate,
        selectedExpirationStartDate,
        selectedExpirationEndDate,
      };
    }
    return {
      selectedExpirationStartDate: selectedExpirationStartDate || this.props.queryParams.date_lease_to_min,
      selectedExpirationEndDate: selectedExpirationEndDate || this.props.queryParams.date_lease_to_max,
      selectedCloseStartDate: selectedCloseStartDate || this.props.queryParams.date_approved_min,
      selectedCloseEndDate: selectedCloseEndDate || this.props.queryParams.date_approved_max,
    };
  };
  // props passed from DealListContainer are in a
  // schema compatible with the API filters. We need
  // to transform these to a schema
  dealStageFiltersToState = dealLifeCycleStageFilters =>
    dealLifeCycleStageFilters.map(value => StatusStrings.dealStage.find(option => option.value === value));

  voucherStatusFiltersToState = filters =>
    filters && filters.map(value => StatusStrings.getVoucherStatuses().find(option => option.value === value));

  dealArchiveStatusFiltersToState = (isArchived) => {
    if (isArchived) {
      return StatusStrings.dealArchiveStatus.find(option => option.value === 'archived');
    }
    return StatusStrings.dealArchiveStatus.find(option => option.value === 'active');
  };

  dealTypeRepRolFiltersToState = repRoles =>
    repRoles.map(repRole => StatusStrings.getDealTypeAndRepRoleOption(repRole));

  // take the filter values in state and map
  // them to a filters {} that can be passed up
  // to onFilterChange()
  stateToFilters = () => {
    const {
      conversionPotential,
      selectedDealStatus,
      selectedDealStage,
      selectedDealTypeAndRepRole,
      selectedCloseStartDate,
      selectedTags,
      selectedCloseEndDate,
      selectedExpirationStartDate,
      selectedExpirationEndDate,
      selectedVoucherStatus,
      selectedUntaggedDeal,
      isOnPipeLine,
      createdBy,
      createdByName,
    } = this.state;
    const {
      queryParams: {
        building,
      },
    } = this.props;
    const filters = {};
    filters.conversion_potential_min = conversionPotential ?
      conversionPotential.min : this.props.queryParams.conversion_potential_min;

    filters.conversion_potential_max = conversionPotential ?
      conversionPotential.max : this.props.queryParams.conversion_potential_max;

    const dealStatus = selectedDealStatus || this.getQueryParamsDealStatus();
    filters.is_archived = dealStatus.value === 'archived';

    const dealStage = selectedDealStage || this.getQueryParamsDealStage();
    filters.deal_life_cycle_stage = dealStage.map(option => option.value);

    const dealTypeAndRepRole = selectedDealTypeAndRepRole || this.getQueryParamsDealTypeAndRepRole();
    filters.rep_role = dealTypeAndRepRole.map(option => option.value);

    filters.tags = selectedTags || [];

    filters.date_approved_min = selectedCloseStartDate;
    filters.date_approved_max = selectedCloseEndDate;

    if (!building && dealStage.length) {
      filters.date_lease_to_min = selectedExpirationStartDate;
      filters.date_lease_to_max = selectedExpirationEndDate;
    }

    const voucherStatus = selectedVoucherStatus || this.getQueryParamsVoucherStatus();
    filters.voucher_submission_status = voucherStatus.map(option => option.value);

    if (selectedUntaggedDeal) {
      filters.is_untagged_deal = selectedUntaggedDeal;
    } else {
      filters.is_untagged_deal = null;
    }

    filters.is_on_pipeline = isOnPipeLine || null;

    filters.created_by = createdBy || null;

    filters.created_by_name = createdByName || null;

    return filters;
  };

  updateCloseStartDateValidity = (isValid) => {
    this.setState({ isCloseStartDateValid: isValid });
  };

  updateCloseEndDateValidity = (isValid) => {
    this.setState({ isCloseEndDateValid: isValid });
  };

  updateExpirationStartDateValidity = (isValid) => {
    this.setState({ isExpirationStartDateValid: isValid });
  };

  updateExpirationEndDateValidity = (isValid) => {
    this.setState({ isExpirationEndDateValid: isValid });
  };

  updateIsOnPipeline = ({ target: { checked } }) => {
    this.setState({ isOnPipeLine: checked });
  };

  render() {
    const {
      conversionPotential,
      isCloseEndDateValid,
      isCloseStartDateValid,
      isExpirationStartDateValid,
      isExpirationEndDateValid,
      selectedUntaggedDeal,
      selectedDealStage,
      selectedDealStatus,
      selectedDealTypeAndRepRole,
      selectedTags,
      selectedVoucherStatus,
      isOnPipeLine,
    } = this.state;

    const {
      allDealTags,
      initQueryParams: {
        deal_life_cycle_stage: initDealStage,
      },
      onFilterAbort,
      queryParams,
    } = this.props;
    const {
      selectedCloseEndDate,
      selectedCloseStartDate,
      selectedExpirationStartDate,
      selectedExpirationEndDate,
    } = this.getDatesForRender();
    // we need to know if its a property deal list so we know which form elements can be shown
    const isPropertyDealListFilter = queryParams.building !== undefined;
    const renderConversionPotential = conversionPotential || this.getQueryParamsConversionPotential();
    const renderDealTypeAndRepRole = selectedDealTypeAndRepRole || this.getQueryParamsDealTypeAndRepRole();
    const isTypeLease = renderDealTypeAndRepRole.length === 1 && renderDealTypeAndRepRole[0].deal_type === 'lease';
    const renderDealStage = selectedDealStage || this.getQueryParamsDealStage();
    const renderDealStatus = selectedDealStatus || this.getQueryParamsDealStatus();
    const renderVoucherStatus = selectedVoucherStatus || this.getQueryParamsVoucherStatus();
    const isButtonDisabled = !isCloseStartDateValid || !isCloseEndDateValid || !isExpirationStartDateValid || !isExpirationEndDateValid;
    const tagsToRender = selectedTags || this.props.queryParams.tags;

    const renderTags = tagsToRender.map(tag => (
      <FilterTag
        isButton
        key={tag}
        handleClearFilter={() => this.onTagRemove(tag)}
        value={tag}
      />
    ));

    return (
      <div className="deal-list-filter">
        <ButtonWithFlyout
          actionIcon="filter"
          buttonIcon="chevron-down"
          buttonText="Filter"
          dataE2e="deal-listview-filter"
          onAbort={onFilterAbort}
          ref={this.buttonWithFlyoutRef}
        >
          <div className="content">
            <Checkbox
              checked={isOnPipeLine}
              onChange={this.updateIsOnPipeline}
            ><span className="input-label">Pipeline Deals Only</span>
            </Checkbox>
            {!queryParams.building
              ? (
                <label>
                  Deal Type
                  <Tooltip message="Please Select One Deal Type." />
                </label>
                )
              : <label>Deal Type</label>
            }
            <ButtonSegmentedGroup
              isMultiSelect={isPropertyDealListFilter}
              isMultiSelectGrid
              onChange={this.onDealTypeChange}
              options={StatusStrings.dealTypeAndRepRole}
              selected={isPropertyDealListFilter ? renderDealTypeAndRepRole : renderDealTypeAndRepRole[0] || {}}
            />
            {isPropertyDealListFilter && (
              <React.Fragment>
                <label>Deal Stage</label>
                <ButtonSegmentedGroup
                  isMultiSelect
                  onChange={this.onDealStageChange}
                  options={StatusStrings.dealStage}
                  selected={renderDealStage}
                />
              </React.Fragment>
            )}

            <label>Deal Status</label>
            <ButtonSegmentedGroup
              onChange={this.onDealStatusChange}
              options={StatusStrings.dealArchiveStatus}
              selected={renderDealStatus}
            />
            {(!isPropertyDealListFilter && initDealStage[0] !== 'executing') && (
              <div className="lease-expiration">
                <label>
                  Lease Expiration
                  <Tooltip message="Applicable for Lease Tenant and Lease Landlord Deal Types only." />
                </label>
                <div className="min-max-fields">
                  <div className="min">
                    <label>Start</label>
                    <Datepicker
                      date={selectedExpirationStartDate}
                      disabled={!isTypeLease}
                      onDateChange={this.onExpirationDateChange}
                      updateValidity={this.updateExpirationStartDateValidity}
                    />
                  </div>
                  <div>
                    <label>End</label>
                    <Datepicker
                      date={selectedExpirationEndDate}
                      disabled={!isTypeLease}
                      onDateChange={date => this.onExpirationDateChange(date, 'end_date')}
                      updateValidity={this.updateExpirationEndDateValidity}
                    />
                  </div>
                </div>
              </div>
            )}
            {(isPropertyDealListFilter || initDealStage[0] !== 'closed') && (
              <React.Fragment>
                <label>Probability</label>
                <Slider
                  formatLabel={v => `${v}%`}
                  minValue={0}
                  maxValue={100}
                  onChange={this.onConversionChange}
                  step={5}
                  value={renderConversionPotential}
                />
              </React.Fragment>
            )}
            {(isPropertyDealListFilter || initDealStage[0] === 'closed') && (
              <React.Fragment>
                <label>Close Date</label>
                <div className="min-max-fields">
                  <div className="min">
                    <label>Start</label>
                    <Datepicker
                      date={selectedCloseStartDate}
                      onDateChange={this.onCloseDateChange}
                      updateValidity={this.updateCloseStartDateValidity}
                    />
                  </div>
                  <div>
                    <label>End</label>
                    <Datepicker
                      date={selectedCloseEndDate}
                      onDateChange={date => this.onCloseDateChange(date, 'end_date')}
                      updateValidity={this.updateCloseEndDateValidity}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
            <React.Fragment>
              <label className="voucher-status">Voucher Status</label>
              <ButtonSegmentedGroup
                isMultiSelect
                isMultiSelectGrid
                onChange={this.onVoucherStatusChange}
                options={StatusStrings.getVoucherStatuses()}
                selected={renderVoucherStatus}
              />
            </React.Fragment>
            <label className="tags-label">Tags</label>
            <div className="tag-search-container">
              <TagSearch
                onSelect={this.onTagSelect}
                tags={allDealTags}
                ref={this.tagSearchRef}
                selectedTags={selectedTags || []}
                disabled={selectedUntaggedDeal}
              />
              <div className="no-tags">
                <Checkbox
                  checked={selectedUntaggedDeal}
                  onChange={this.onUntaggedDealSelect}
                ><span className="input-label">Untagged deals only</span>
                </Checkbox>
              </div>
            </div>
            <div className="selected-tags">
              {renderTags}
            </div>
            <label className="created-by-label">User</label>
            <div className="created-by-container">
              <Select
                options={this.selectOptions}
                onChange={this.onCreatedBySelect}
                defaultOption={this.selectOptions[0]}
                onClear
              />
              <BrokerAutocomplete
                onSelect={this.onAutoCompleteSelect}
                initSearchTerm={this.state.createdByName || undefined}
              />
            </div>
          </div>
          <div className="button-controls">
            <Button onClick={this.onClear} data-e2e="clear-button" variant="secondary">Clear</Button>
            <Button
              onClick={this.onApply}
              data-e2e="apply-button"
              disabled={isButtonDisabled}
            >
              Apply
            </Button>
          </div>
        </ButtonWithFlyout>
      </div>
    );
  }
}

DealListFilter.propTypes = {
  allDealTags: PropTypes.arrayOf(PropTypes.string),
  onFilterAbort: PropTypes.func,
  onFilterChange: PropTypes.func.isRequired,
  queryParams: PropTypes.shape({
    search: PropTypes.string,
    page: PropTypes.number,
    page_size: PropTypes.number,
    ordering: PropTypes.string,
    building: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    conversion_potential_min: PropTypes.number,
    conversion_potential_max: PropTypes.number,
    is_archived: PropTypes.bool,
    deal_life_cycle_stage: PropTypes.arrayOf(PropTypes.string),
    rep_role: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    date_approved_min: PropTypes.string,
    date_approved_max: PropTypes.string,
    date_lease_to_min: PropTypes.string,
    date_lease_to_max: PropTypes.string,
    voucher_submission_status: PropTypes.arrayOf(PropTypes.string),
    is_untagged_deal: PropTypes.bool,
    is_on_pipeline: PropTypes.bool,
    created_by: PropTypes.string,
    created_by_name: PropTypes.string,
  }).isRequired,
  initQueryParams: PropTypes.shape({
    search: PropTypes.string,
    page: PropTypes.number,
    page_size: PropTypes.number,
    ordering: PropTypes.string,
    building: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    conversion_potential_min: PropTypes.number,
    conversion_potential_max: PropTypes.number,
    is_archived: PropTypes.bool,
    deal_life_cycle_stage: PropTypes.arrayOf(PropTypes.string),
    rep_role: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    date_approved_min: PropTypes.string,
    date_approved_max: PropTypes.string,
    date_lease_to_min: PropTypes.string,
    date_lease_to_max: PropTypes.string,
    voucher_submission_status: PropTypes.arrayOf(PropTypes.string),
    is_untagged_deal: PropTypes.bool,
    is_on_pipeline: PropTypes.bool,
    created_by: PropTypes.string,
    created_by_name: PropTypes.string,
  }).isRequired,
};

DealListFilter.defaultProps = {
  allDealTags: [],
  onFilterAbort: () => { },
};

export default DealListFilter;
