import React from 'react';
import PropTypes from 'prop-types';

import SearchInput from '../../nucleus/search/search-input';
import ListActionHeader from '../../nucleus/list-action-header/list-action-header';

const PropertiesListPageActionsRow = (props) => {
  const {
    handleSearchTerm,
    handleSearchFocus,
    isLoading,
  } = props;
  return (
    <ListActionHeader
      search={(
        <SearchInput
          searchKey="property"
          handleSearchTermChange={handleSearchTerm}
          handleSearchFocus={handleSearchFocus}
        />
        )}
      isLoading={isLoading}
    />
  );
};

PropertiesListPageActionsRow.propTypes = {
  handleSearchTerm: PropTypes.func.isRequired,
  handleSearchFocus: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PropertiesListPageActionsRow;
