import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';
import Avatar from '../../nucleus/avatar/avatar';

const NotificationIcon = (props) => {
  const {
    markAsRead,
    performedBy,
    variety,
  } = props;

  // Render Avatar
  const renderAvatar = performedBy ? (
    <Avatar
      image={performedBy.avatar || ''}
      firstName={performedBy.first_name || ''}
      lastName={performedBy.last_name || ''}
      hoverAlign="left"
      enableHover
    />
  ) : '';

  const renderIcon = () => {
    switch (variety) {
      case 'added_to_deal':
      case 'removed_from_deal':
      case 'added_to_team':
      case 'removed_from_team':
      case 'team_added_to_deal':
      case 'team_removed_from_deal':
      case 'bulk_add_to_deals_failed':
      case 'bulk_remove_from_deals_failed':
        return (
          <div className="avatar-wrapper" onClick={markAsRead}>
            {renderAvatar}
          </div>
        );
      case 'lease_expiration_reminder':
        return (
          <div className="lease-expiration">
            <Icon iconName="building" />
          </div>
        );
      case 'user_needs_to_grant_outlook_sync':
        return (
          <div className="contact-sync">
            <Icon iconName="outlook" />
          </div>
        );
      case 'deal_linked_to_mta_transaction':
        return (
          <div className="commission-link">
            <Icon iconName="dollar" />
          </div>
        );
      default:
        return '';
    }
  };

  return (
    <div className="notification-icon">
      {renderIcon()}
    </div>
  );
};

export default NotificationIcon;

NotificationIcon.propTypes = {
  markAsRead: PropTypes.func,
  performedBy: PropTypes.shape({
    avatar: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  variety: PropTypes.string,
};

NotificationIcon.defaultProps = {
  markAsRead: () => { },
  performedBy: PropTypes.shape({
    avatar: '',
    first_name: '',
    last_name: '',
  }),
  variety: PropTypes.string,
};
