import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';

const Button = (props) => {
  const {
    id,
    variant,
    type,
    size,
    width,
    icon,
    iconPosition,
    selected,
    disabled,
    onClick,
    onFocus,
    onMouseDown,
    onKeyDown,
    dataE2e,
    ariaLabel,
    className,
  } = props;

  // eslint-disable-next-line
  const classNames = `nd-button ${variant} ${size} ${width} ${disabled ? 'disabled' : ''} ${selected ? 'selected' : ''} ${icon ? `has-icon-${iconPosition}` : ''} ${className}`;
  return (
    <button
      id={id}
      type={type}
      className={classNames}
      disabled={disabled}
      onClick={onClick}
      onFocus={onFocus}
      onMouseDown={onMouseDown}
      onKeyDown={onKeyDown}
      data-e2e={dataE2e}
      aria-label={ariaLabel}
    >
      {icon && iconPosition === 'left' && <Icon iconName={icon} />}
      <div className="btn-content">{props.children}</div>
      {icon && iconPosition === 'right' && <Icon iconName={icon} />}
    </button>
  );
};

export default Button;

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'action',
    'option',
    'icon',
  ]),
  type: PropTypes.oneOf([
    'button',
    'submit',
    'reset',
  ]),
  size: PropTypes.oneOf([
    '',
    'small',
  ]),
  width: PropTypes.oneOf([
    '',
    'full',
  ]),
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf([
    'left',
    'right',
  ]),
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseDown: PropTypes.func,
  onKeyDown: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string, // Button Texts
    PropTypes.object, // HTML dom including font-icons
  ]),
  dataE2e: PropTypes.string,
  ariaLabel: PropTypes.string,
};

Button.defaultProps = {
  id: '',
  className: '',
  variant: 'primary',
  type: 'button',
  size: '',
  width: '',
  icon: '',
  iconPosition: 'left',
  selected: false,
  disabled: false,
  onClick: () => {},
  onFocus: () => {},
  onMouseDown: () => {},
  onKeyDown: () => {},
  children: null,
  dataE2e: '',
  ariaLabel: '',
};
