import { connect } from 'react-redux';
import CommissionsPage from './commissions-page';


const mapStateToProps = state => ({
  user: state.userData.data,
  featureFlags: state.userOptions.userOptions.feature_flags || {},
  proxy: state.proxy.proxySettings,
});

const mergeProps = (state, actions) => ({
  ...state,
  ...actions,
  analyticProperties: {
    actionPrefix: 'commissions',
    categoryPrefix: 'Commissions ',
  },
});

const CommissionsPageContainer = connect(mapStateToProps, mergeProps)(CommissionsPage);

export default CommissionsPageContainer;
