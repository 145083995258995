import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton } from '@cbrebuild/blocks';
import { Progress } from '@cbrebuild/blocks-react';

const FileUploadModalListItem = (props) => {
  const { file, onFileNameChange, onRemove } = props;
  // eslint-disable-next-line no-bitwise
  const fileType = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
  const { name, uploadProgress } = file;

  return (
    <React.Fragment>
      <li>
        <div>
          <Icon iconName="document" size="x-large" />
          <div className={`doc-type-extension doc-type-${fileType}`}>
            {fileType}
          </div>
        </div>
        <div>
          <span className="attachment-name">* Attachment Name</span>
          <input
            onChange={e => onFileNameChange(e.target.value)}
            type="text"
            value={name}
            className="input-text"
          />
        </div>
        <div className="file-item-actions">
          <IconButton iconName="close" onClick={onRemove} variant="basic" />
        </div>
      </li>
      {!!uploadProgress && <Progress progress={uploadProgress} />}
    </React.Fragment>
  );
};

FileUploadModalListItem.propTypes = {
  file: PropTypes.shape({
    fileData: PropTypes.shape({
      lastModified: PropTypes.number,
      lastModifiedDate: PropTypes.shape({}),
      name: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
      webkitRelativePath: PropTypes.string,
    }),
    isPrivate: PropTypes.bool,
    name: PropTypes.string,
    uploadProgress: PropTypes.number,
  }).isRequired,
  onFileNameChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default FileUploadModalListItem;
