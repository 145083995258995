import {
  CREATE_COMPANY,
} from '../actions/action-types';

const initialState = {
  companies: [],
};

const company = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_COMPANY:
    default:
      return state;
  }
};

export default company;
