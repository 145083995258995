import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Currency from '../../../nucleus/formats/currency';
import Percent from '../../../nucleus/formats/percent';

const CommissionsDetail = (props) => {
  const { myCommissionDetail } = props;

  const dealTypeMap = {
    1: 'Sale Detail',
    2: 'Lease Detail',
    3: 'Consulting Detail',
    4: 'Other Detail',
  };

  const repRoleClientMap = {
    Tenant: 'Tenant',
    Landlord: 'Landlord',
    Buyer: 'Buyer',
    Seller: 'Seller',
    Consulting: 'Client',
    Other: 'Client',
  };

  const repRoleCounterPartyMap = {
    Tenant: 'Landlord',
    Landlord: 'Tenant',
    Buyer: 'Seller',
    Seller: 'Buyer',
    Consulting: 'None',
    Other: 'None',
  };

  const formatUTCDate = date => (moment.utc(date).format('M/D/YYYY'));

  const leaseStart =
    (dealTypeMap[myCommissionDetail.deal_type] === 'Lease Detail') && (
      <tr className="borderless-row">
        <td className="title">Start Date</td>
        <td>
          {myCommissionDetail.primary_space_lease_start &&
            formatUTCDate(myCommissionDetail.primary_space_lease_start)}
        </td>
      </tr>
    );

  const leaseEnd =
    (dealTypeMap[myCommissionDetail.deal_type] === 'Lease Detail') && (
      <tr className="borderless-row">
        <td className="title">End Date</td>
        <td>
          {myCommissionDetail.primary_space_lease_end &&
            formatUTCDate(myCommissionDetail.primary_space_lease_end)}
        </td>
      </tr>
    );

  return (
    <div className="commissions-card commissions-detail">
      <h2 className="header-row">
        {dealTypeMap[myCommissionDetail.deal_type]}
      </h2>
      <table className="labeled-item-table">
        <tbody>
          <tr className="borderless-row">
            <td className="label-data">{repRoleClientMap[myCommissionDetail.rep_role] ? repRoleClientMap[myCommissionDetail.rep_role] : 'Client'}</td>
            <td className="value-data">{myCommissionDetail.client ? myCommissionDetail.client : '--'}</td>
          </tr>
          {!['Consulting', 'Other'].includes(myCommissionDetail.rep_role) &&
          <tr className="borderless-row">
            <td className="label-data">
              {repRoleCounterPartyMap[myCommissionDetail.rep_role] ? repRoleCounterPartyMap[myCommissionDetail.rep_role] : 'Counter Party'}
            </td>
            <td className="value-data">{myCommissionDetail.counter_party ? myCommissionDetail.counter_party : '--'}</td>
          </tr>}
          <tr className="borderless-row">
            <td className="label-data">Lead Office</td>
            <td className="value-data">{myCommissionDetail.lead_office ? myCommissionDetail.lead_office : '--'}</td>
          </tr>
          <tr className="borderless-row">
            <td className="label-data">Consideration</td>
            <td className="value-data"><Currency value={myCommissionDetail.total_consideration} /></td>
          </tr>
          <tr className="borderless-row">
            <td className="label-data">Comm. Rate</td>
            <td className="value-data"><Percent value={myCommissionDetail.total_commission_rate} decimalRounding={2} /></td>
          </tr>
          <tr className="borderless-row">
            <td className="label-data">Total Comm.</td>
            <td className="value-data"><Currency value={myCommissionDetail.total_commission} /></td>
          </tr>
          <tr className="borderless-row">
            <td className="label-data">Close Date</td>
            <td className="value-data">{myCommissionDetail.close_date && formatUTCDate(myCommissionDetail.close_date)}</td>
          </tr>
          {leaseStart}
          {leaseEnd}
        </tbody>
      </table>
    </div>
  );
};

CommissionsDetail.propTypes = {
  myCommissionDetail: PropTypes.shape({
    client: PropTypes.string,
    counter_party: PropTypes.string,
    lead_office: PropTypes.string,
    close_date: PropTypes.string,
    deal_type: PropTypes.number,
    rep_role: PropTypes.string,
    primary_space_lease_end: PropTypes.string,
    primary_space_lease_start: PropTypes.string,
    total_commission: PropTypes.number,
    total_commission_rate: PropTypes.number,
    total_consideration: PropTypes.number,
  }),
};

CommissionsDetail.defaultProps = {
  myCommissionDetail: {
    client: '',
    counter_party: '',
    lead_office: '',
    close_date: '',
    deal_type: 1,
    rep_role: 'Tenant',
    primary_space_lease_end: '',
    primary_space_lease_start: '',
    total_commission: 0,
    total_commission_rate: 0,
    total_consideration: 0,
  },
};

export default CommissionsDetail;
