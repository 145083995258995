import React from 'react';
import PropTypes from 'prop-types';
import PropertyLeaseTab from './PropertyLeaseTab';
import PropertySaleTab from './PropertySaleTab';

const PropertyTab = ({
  deal,
  voucher,
}) => (
  <div className="property-tab">
    {
        (deal.deal_type === 'lease')
          ? <PropertyLeaseTab deal={deal} voucher={voucher} />
          : <PropertySaleTab deal={deal} voucher={voucher} />
      }
  </div>
);

PropertyTab.propTypes = {
  voucher: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  deal: PropTypes.shape({
    rep_role: PropTypes.string.isRequired,
    deal_type: PropTypes.string,
    is_dual_rep_deal: PropTypes.bool,
    id: PropTypes.number,
    client: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
};

export default PropertyTab;
