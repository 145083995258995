import React from 'react';
import PropTypes from 'prop-types';

const StyledLabel = ({
  variant,
  hoverText,
  hoverAlign,
  content,
  className,
  ...other
}) => (
  <div {...other} className={`nd-styled-label ${variant} ${className || ''} hoverstate`}>
    <span>{content.toUpperCase()}</span>
    {hoverText && <span className={`hoverstatetext align-${hoverAlign}`}>{hoverText}</span>}
  </div>);

StyledLabel.propTypes = {
  variant: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary', 'quaternary']),
  hoverText: PropTypes.string,
  hoverAlign: PropTypes.oneOf(['left', 'center', 'right']),
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
};

StyledLabel.defaultProps = {
  variant: 'default',
  hoverText: '',
  hoverAlign: 'center',
  className: '',
};

export default StyledLabel;
