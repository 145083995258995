import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { Checkbox } from '@cbrebuild/blocks';
import NumberInput from '../../nucleus/inputs/NumberInput';
import { Person, PersonType } from '../person';
import { fetchTeamByID, patchDefaultSplits } from '../../services/teams-service';
// import DealIQLogo from '../../assets/images/svg/deal-iq-logo-only.svg';
import DealIQLogo from '../../assets/images/svg/loading-icon.svg';

const TeamCardSplit = ({
  userData, teamMembers,
  id,
  isAdminPage,
}) => {
  const [teamSplitsDetails, setTeamSplitsDetails] = useState([]);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const maxPercentage = 100;
  const minPercentage = 0;

  const getTeamSplit = useCallback(async (teamId, adminPage) => {
    await fetchTeamByID(teamId, adminPage)
      .then((res) => {
        if (res && res.users) {
          const usersSplitDetails = res.users;
          const matchID = usersSplitDetails.map(userDetail => ({ ...userDetail, user_id: userDetail.id }));
          setTeamSplitsDetails(matchID);
        }
      }).then(() => {
        setIsLoadingDetails(false);
      });
  }, []);

  useEffect(() => {
    setIsLoadingDetails(true);
    getTeamSplit(id, isAdminPage);
  }, [getTeamSplit, id, isAdminPage]);

  async function percentageChange(e, memberDetail) {
    const { error, ...toUpdate } = memberDetail;
    await patchDefaultSplits({ ...toUpdate, default_splits_percentage: e.target.value }).then(() => {
      const patchedValue = [
        ...teamSplitsDetails.filter(detail => detail.user_id !== toUpdate.user_id),
        { ...toUpdate, default_splits_percentage: e.target.value },
      ];
      setTeamSplitsDetails(patchedValue);
    });
  }

  async function pipelineChange(e, memberDetail) {
    await patchDefaultSplits({ ...memberDetail, is_added_to_pipeline: !memberDetail.is_added_to_pipeline }).then(() => {
      const patchedValue = [
        ...teamSplitsDetails.filter(detail => detail.user_id !== memberDetail.user_id),
        { ...memberDetail, is_added_to_pipeline: !memberDetail.is_added_to_pipeline },
      ];
      setTeamSplitsDetails(patchedValue);
    });
  }

  const renderTeamMembers = () => (
    teamMembers.map((teamMember) => {
      const memberDetail = teamSplitsDetails.find(splitDetail => teamMember.id === splitDetail.user_id);
      const updateInvalidStatus = () => {
        const patchedValue = [
          ...teamSplitsDetails.filter(detail => detail.user_id !== memberDetail.user_id),
          { ...memberDetail, error: 'Invalid Value' },
        ];
        setTeamSplitsDetails(patchedValue);
      };
      const updateOutOfRangeStatus = () => {
        const patchedValue = [
          ...teamSplitsDetails.filter(detail => detail.user_id !== memberDetail.user_id),
          { ...memberDetail, error: 'Out of Range (0-100% only)' },
        ];
        setTeamSplitsDetails(patchedValue);
      };
      return (
        <tr key={`member-row-${teamMember.id}`}>
          <td className="team-split-person">
            <Person
              person={teamMember}
              type={PersonType.MEMBER}
              isSelf={teamMember.id === userData.id}
              hoverAlign="left"
            />
          </td>
          <td className={`${memberDetail?.error ? 'warning-text' : 'normal-table-data'}`}>
            <NumberInput
              value={memberDetail.default_splits_percentage}
              precision={8}
              onChange={e => percentageChange(e, memberDetail)}
              min={minPercentage}
              max={maxPercentage}
              updateInvalidStatus={updateInvalidStatus}
              updateOutOfRangeStatus={updateOutOfRangeStatus}
              placeholder="0"
            />
            <div>
              {memberDetail?.error}
            </div>
          </td>
          <td className="selection-cell">
            <Checkbox checked={memberDetail.is_added_to_pipeline} onChange={e => pipelineChange(e, memberDetail)} />
          </td>
        </tr>
      );
    })
  );

  const renderTeamTable = () => (
    <table>
      {
        !isEmpty(teamSplitsDetails) && (
          <thead>
            <tr>
              <th>Deal Collaborators</th>
              <th style={{ width: 100 }}>Commission Split (%)</th>
              <th className="selection-header">Add to Pipeline</th>
            </tr>
          </thead>
        )
      }
      <tbody>
        {
          !isEmpty(teamSplitsDetails) && renderTeamMembers()
        }
      </tbody>
    </table>);

  const renderLoadingSpinner = () => (
    <div className="spinner-container">
      <div className="loading-spinner">
        <img
          alt="Loading"
          src={DealIQLogo}
        />
      </div>
    </div>
  );

  const renderTeamsSection = () => {
    if (isLoadingDetails) {
      return renderLoadingSpinner();
    }
    return renderTeamTable();
  };

  return (
    <div className="team-splits-table-container">
      {
      renderTeamsSection()
      }
    </div>);
};

TeamCardSplit.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  teamMembers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  id: PropTypes.number.isRequired,
  isAdminPage: PropTypes.bool,
};

TeamCardSplit.defaultProps = {
  isAdminPage: false,
};

export default TeamCardSplit;
