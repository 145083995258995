import React from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import AutocompleteInput from './autocomplete-input';
import RowItem from '../../nucleus/row-item/row-item';
import ResultList from './result-list';
import Flyout from '../../nucleus/flyout/flyout';

class AutocompleteFlyout extends React.Component {
  static propTypes = {
    isDisabled: PropTypes.bool,
    fetchRequest: PropTypes.func.isRequired,
    requestParams: PropTypes.shape({}),
    initSearchTerm: PropTypes.string,
    placeholder: PropTypes.string,
    debounceTime: PropTypes.number,
    disableSearchRequest: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    renderListItem: PropTypes.func.isRequired,
    emptyState: PropTypes.string,
    findSelectedResultAttribute: PropTypes.func,
    filterResultsPredicate: PropTypes.func,
    isLoading: PropTypes.bool,
    dataE2e: PropTypes.string,
    renderFooterContent: PropTypes.func,
    clickReturnsAll: PropTypes.bool,
    onBlur: PropTypes.func,
    onReturn: PropTypes.func,
    focusOnMount: PropTypes.bool,
    variant: PropTypes.oneOf(['outlined', 'inline']),
    minSearchLength: PropTypes.number,
    label: PropTypes.string,
  };
  static defaultProps = {
    isDisabled: false,
    requestParams: {},
    initSearchTerm: '',
    errorMessage: '',
    placeholder: 'Search',
    debounceTime: 300,
    disableSearchRequest: false,
    emptyState: null,
    findSelectedResultAttribute: null,
    filterResultsPredicate: null,
    isLoading: false,
    dataE2e: '',
    renderFooterContent: null,
    clickReturnsAll: false,
    onBlur: () => { },
    onReturn: () => { },
    focusOnMount: false,
    variant: undefined,
    label: '',
    minSearchLength: 3,
  };
  constructor(props) {
    super(props);
    this.state = {
      showFlyout: false,
      searchTerm: '',
      results: [],
      isLoading: false,
    };
    this.clickableContent = React.createRef(null);
    this.debounceSearchResults = debounce(() => {
      const { searchTerm } = this.state;
      const {
        fetchRequest,
        requestParams,
        filterResultsPredicate,
        renderFooterContent,
        emptyState,
        clickReturnsAll,
        minSearchLength,
      } = this.props;

      const params = clickReturnsAll && searchTerm === '' ?
        { ...requestParams } :
        { ...requestParams, search: searchTerm };
      if (searchTerm.length >= minSearchLength || (clickReturnsAll && searchTerm.length === 0)) {
        this.setState({ isLoading: true });
        fetchRequest(params)
          .then((response) => {
            const data = (response && response.results) ? response.results : response;
            // filter results if a filter predicate function was provided
            const results = filterResultsPredicate ? data.filter(filterResultsPredicate) : data;
            const showFlyout = !!results.length || !!renderFooterContent || !!emptyState;
            this.setState({ results, showFlyout, isLoading: false });
          }).catch(() => {
            this.setState({ showFlyout: false, isLoading: false });
          });
      } else {
        this.setState({ results: [], showFlyout: false });
      }
    }, this.props.debounceTime);
  }
  shouldComponentUpdate(props) {
    if (props.initSearchTerm !== this.props.initSearchTerm) {
      this.setState({ searchTerm: props.initSearchTerm });
    }
    return true;
  }

  componentWillUnmount() {
    this.removeMouseDownListener();
  }

  onChange = (searchTerm) => {
    this.setState({
      searchTerm: searchTerm || '',
    }, () => {
      this.debounceSearchResults();
      this.props.onChange(searchTerm);
    });
  };

  // update the value of the autocomplete input, close flyout, and communicate selection to parent
  onSelect = (selected) => {
    const { onChange, findSelectedResultAttribute, onBlur } = this.props;
    const searchTerm = findSelectedResultAttribute && selected ? findSelectedResultAttribute(selected) : '';
    this.setState({ searchTerm });
    this.closeFlyout();
    onChange(selected);
    onBlur();
  };

  closeFlyout = () => {
    this.setState({ showFlyout: false });
  };

  handleInputOnReturn = () => {
    if (!this.state.showFlyout) {
      this.props.onReturn();
    }
  };

  handleOnFocus = () => {
    this.addMouseDownListener();
  };

  handleOnBlur = () => {
    this.removeMouseDownListener();
  };

  addMouseDownListener = () => {
    document.addEventListener('mousedown', this.listenForEvents);
  };

  removeMouseDownListener = () => {
    document.removeEventListener('mousedown', this.listenForEvents);
  };

  listenForEvents = (event) => {
    const clickedInBoundary = this.clickableContent.current && this.clickableContent.current.contains(event.target);
    if (this.clickableContent.current && !clickedInBoundary) {
      this.props.onBlur(event);
    }
  };

  renderResults = () => {
    const {
      results,
      searchTerm,
    } = this.state;
    const {
      renderFooterContent,
      renderListItem,
      emptyState,
      onReturn,
    } = this.props;
    if (emptyState && results.length === 0) {
      return (
        <RowItem>{emptyState}</RowItem>
      );
    }
    return (
      <React.Fragment>
        <ResultList
          onSelect={this.onSelect}
          closeFlyout={this.closeFlyout}
          renderFooterContent={renderFooterContent}
          renderListItem={renderListItem}
          results={results}
          searchTerm={searchTerm}
          onReturn={onReturn}
        />
      </React.Fragment>
    );
  };
  render() {
    const {
      searchTerm,
      showFlyout,
      isLoading,
    } = this.state;
    const {
      dataE2e,
      disableSearchRequest,
      errorMessage,
      isDisabled,
      isLoading: forceIsLoading,
      placeholder,
      focusOnMount,
      variant,
      label,
    } = this.props;
    const loading = forceIsLoading || isLoading;
    const renderResults = this.renderResults();
    const flyoutClass = `autocomplete-flyout-results${errorMessage ? ' with-error' : ''}`;
    const labelHTML = label && <label>{label}</label>;
    return (
      <div className="autocomplete-flyout" ref={this.clickableContent}>
        {labelHTML}
        <AutocompleteInput
          isDisabled={isDisabled}
          hideSearchIcon={disableSearchRequest}
          handleChange={term => this.onChange(term)}
          placeholderValue={placeholder}
          inputValue={searchTerm || this.props.initSearchTerm}
          errorMessage={this.props.errorMessage}
          isLoading={loading}
          dataE2e={dataE2e}
          handleOnClick={this.debounceSearchResults}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
          onReturn={this.handleInputOnReturn}
          focusOnMount={focusOnMount}
          variant={variant}
        />
        <Flyout
          additionalClassName={flyoutClass}
          showFlyout={showFlyout}
          closeFlyout={this.closeFlyout}
        >
          {renderResults}
        </Flyout>
      </div>
    );
  }
}
export default AutocompleteFlyout;
