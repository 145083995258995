import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RouteWithSubRoutes from '../../utils/voucher-route-utils';
import TabNav from '../../nucleus/tabnav/tabnav';
import VoucherComments from '../voucher/VoucherComments';
import BottomNav from './BottomNav';

const getSubTabs = (id, type) => {
  switch (type) {
    case 'lease':
      return [
        {
          link: `/voucher/${id}/voucher_details/lease_information`,
          text: 'Lease Information',
        },
        {
          link: `/voucher/${id}/voucher_details/tenant_information`,
          text: 'Tenant Information',
        },
        {
          link: `/voucher/${id}/voucher_details/landlord_information`,
          text: 'Landlord Information',
        },
      ];
    case 'sale':
      return [
        {
          link: `/voucher/${id}/voucher_details/property`,
          text: 'Property',
        },
        {
          link: `/voucher/${id}/voucher_details/investment_details`,
          text: 'Investment Details',
        },
        {
          link: `/voucher/${id}/voucher_details/buyer_information`,
          text: 'Buyer Information',
        },
        {
          link: `/voucher/${id}/voucher_details/seller_information`,
          text: 'Seller Information',
        },
      ];
    default:
      return [
        {
          link: `/voucher/${id}/voucher_details/overview`,
          text: 'Overview',
        },
        {
          link: `/voucher/${id}/voucher_details/client`,
          text: 'Client',
        },
      ];
  }
};

const VoucherDetails = ({
  location,
  match: {
    params: {
      id,
      subtab,
    },
  },
  routes,
}) => {
  const dealType = useSelector(state => state.deal.deal?.deal_type);
  const tabs = useMemo(() => (
    (dealType && id) ? getSubTabs(id, dealType) : null
  ), [dealType, id]);

  return (
    <React.Fragment>
      <div className="voucher-content">
        <TabNav tabs={tabs} selectedTab={location.pathname} />
        {!subtab && <Redirect to={tabs[0].link} />}
        <Switch>
          {routes && routes.map(route => (
            <RouteWithSubRoutes key={route.path} {...route} />
          ))}
        </Switch>
        <BottomNav location={location.pathname} />
      </div>
      <VoucherComments />
    </React.Fragment>
  );
};

VoucherDetails.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
  })).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      subtab: PropTypes.string,
    }),
  }).isRequired,
};

export default VoucherDetails;
