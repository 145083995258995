import additionalCommissionsService from '../../../services/voucher/additional-commissions-service';

import {
  FETCH_ADDITIONAL_COMMISSIONS_SUCCESS,
  FETCH_VOUCHER_OPTIONS_SUCCESS,
  UPDATE_ADDITIONAL_COMMISSIONS_IN_MODAL,
} from '../action-types';

// action creators
const fetchAddtionalCommissionTypeOptionsSuccess = options => ({
  type: FETCH_VOUCHER_OPTIONS_SUCCESS,
  payload: { additionalCommissionTypeOptions: options },
});

const fetchAdditionalCommissionsSuccess = commissions => ({
  type: FETCH_ADDITIONAL_COMMISSIONS_SUCCESS,
  payload: commissions,
});

const updateAdditionalCommissionsInModalSuccess = commissions => ({
  type: UPDATE_ADDITIONAL_COMMISSIONS_IN_MODAL,
  payload: commissions,
});

// dispatches
const fetchAdditionalCommissionTypeOptions = () => dispatch =>
  additionalCommissionsService.fetchAdditionalCommissionTypeOptions()
    .then(options => dispatch(fetchAddtionalCommissionTypeOptionsSuccess(options)))
    .catch(console.error);

const fetchAdditionalCommissions = params => dispatch => additionalCommissionsService.fetchCommissions(params)
  .then((commissions) => {
    dispatch(fetchAdditionalCommissionsSuccess(commissions));
    return commissions;
  })
  .catch(console.error);

const updateAdditionalCommissions = params => dispatch =>
  additionalCommissionsService.fetchCommissions(params)
    .then((commissions) => {
      dispatch(fetchAdditionalCommissionsSuccess(commissions));
    })
    .catch(console.error);

const updateAdditionalCommissionsInModal = commission => dispatch =>
  dispatch(updateAdditionalCommissionsInModalSuccess(commission));

export {
  fetchAdditionalCommissionTypeOptions,
  fetchAdditionalCommissions,
  updateAdditionalCommissions,
  updateAdditionalCommissionsInModal,
};
