import React from 'react';
import PropTypes from 'prop-types';

import FileUploadAccordian from '../../file-upload-accordian';
import countries from '../../../../data/countries.json';
import states from '../../../../data/states.json';
import {
  LeaseAbstractionField,
  LeaseAbstractionFieldType,
} from '../../leaseAbstractionField';

import '../../lease-abstraction.scss';

const getFilteredStates = (countryName = 'United States') => {
  const found = countries.find(c => c.display_name === countryName);
  return states.filter(s => s.country_id === found?.country_id);
};

const TenantInformation = ({
  modalFieldState,
  modalFieldChangeHandler,
  fieldErrorMap,
}) => (
  <FileUploadAccordian accordianTitle="Tenant Information">
    <LeaseAbstractionField
      key="tenantCompanyName"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.tenantCompanyName,
        label: 'Company Name',
        errorMessage: fieldErrorMap.tenantCompanyName,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantCompanyName', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="tenantCountryName"
      fieldType={LeaseAbstractionFieldType.SELECT}
      fieldData={{
        data: modalFieldState.tenantCountryName,
        label: 'Country',
        errorMessage: fieldErrorMap.tenantCountryName,
        options: countries,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler(
          'multipleFieldNames',
          {
            tenantCountryName: {
              ...modalFieldState.tenantCountryName,
              value: event.value,
            },
            tenantStateName: { ...modalFieldState.tenantStateName, value: '' },
            filteredStatesForTenant: { value: getFilteredStates(event.value) },
          },
          'multiple',
        );
      }}
    />
    <LeaseAbstractionField
      key="tenantAddressLine1"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.tenantAddressLine1,
        label: 'Address Line 1',
        errorMessage: fieldErrorMap.tenantAddressLine1,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantAddressLine1', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="tenantAddressLine2"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.tenantAddressLine2,
        label: 'Address Line 2',
        errorMessage: fieldErrorMap.tenantAddressLine2,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantAddressLine2', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="tenantCity"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.tenantCity,
        label: 'City',
        errorMessage: fieldErrorMap.tenantCity,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantCity', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="tenantStateName"
      fieldType={LeaseAbstractionFieldType.SELECT}
      fieldData={{
        data: modalFieldState.tenantStateName,
        label: 'State',
        errorMessage: fieldErrorMap.tenantStateName,
        options: modalFieldState.filteredStatesForTenant.value,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantStateName', { value: event.value });
      }}
    />
    <LeaseAbstractionField
      key="tenantZip"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.tenantZip,
        label: 'Zip',
        errorMessage: fieldErrorMap.tenantZip,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantZip', {
          value: event.target.value,
        });
      }}
    />
    <p className="file-modal-sub-heading">Point of Contact</p>
    <LeaseAbstractionField
      key="tenantPOCFirstName"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.tenantPOCFirstName,
        label: 'First Name',
        errorMessage: fieldErrorMap.tenantPOCFirstName,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantPOCFirstName', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="tenantPOCLastName"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.tenantPOCLastName,
        label: 'Last Name',
        errorMessage: fieldErrorMap.tenantPOCLastName,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantPOCLastName', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="tenantPOCPhone"
      fieldType={LeaseAbstractionFieldType.PHONE}
      fieldData={{
        data: modalFieldState.tenantPOCPhone,
        label: 'Phone',
        errorMessage: fieldErrorMap.tenantPOCPhone,
      }}
      fieldChangeHandler={(value, isValid) => {
        modalFieldChangeHandler(
          'multipleFieldNames',
          {
            tenantPOCPhone: {
              ...modalFieldState.tenantPOCPhone,
              value,
            },
            tenantPOCPhoneValid: isValid,
          },
          'multiple',
        );
      }}
    />
    <LeaseAbstractionField
      key="tenantPOCEmail"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.tenantPOCEmail,
        label: 'E-Mail Address',
        errorMessage: fieldErrorMap.tenantPOCEmail,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantPOCEmail', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="tenantPOCJobTitle"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.tenantPOCJobTitle,
        label: 'Job Title',
        errorMessage: fieldErrorMap.tenantPOCJobTitle,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantPOCJobTitle', {
          value: event.target.value,
        });
      }}
    />
  </FileUploadAccordian>
);

TenantInformation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modalFieldState: PropTypes.object,
  modalFieldChangeHandler: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fieldErrorMap: PropTypes.object,
};

TenantInformation.defaultProps = {
  modalFieldState: {},
  modalFieldChangeHandler: () => {},
  fieldErrorMap: {},
};

export default TenantInformation;
