const productTypes = [
  { value: 'office', display_name: 'Office' },
  { value: 'industrial', display_name: 'Industrial' },
  { value: 'retail', display_name: 'Retail' },
  { value: 'multi-family', display_name: 'Multi-family' },
  { value: 'land', display_name: 'Land' },
  { value: 'hotel', display_name: 'Hotel' },
  { value: 'other', display_name: 'Other' },
];

const leaseTypes = [
  { value: 'new lease', display_name: 'New Lease' },
  { value: 'renewal', display_name: 'Renewal' },
  { value: 'modification', display_name: 'Modification' },
  { value: 'sublease', display_name: 'Sublease' },
  { value: 'expansion', display_name: 'Expansion' },
  { value: 'contraction', display_name: 'Contraction' },
  { value: 'termination', display_name: 'Termination' },
  { value: 'relocation', display_name: 'Relocation' },
];


const getProductTypes = () => productTypes;

const getLeaseTypes = () => leaseTypes;

const getProductTypeFromValue = (value) => {
  const productType = productTypes.find(p => p.value === value);
  return productType;
};

const getLeaseTypeFromValue = (value) => {
  const leaseType = leaseTypes.find(p => p.value === value);
  return leaseType;
};

export {
  getProductTypes,
  getProductTypeFromValue,
  getLeaseTypes,
  getLeaseTypeFromValue,
};
