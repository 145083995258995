import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import '@cbrebuild/blocks/dist/styles/components.css';
import cssVars from 'css-vars-ponyfill';
import MainNavContainer from './components/navs/main-nav-container';
import intercomService from './services/intercom-service';
import './styles.scss';
import Agreement from './page-templates/agreement/agreement-page';

cssVars({
  watch: true,
});

const App = ({
  loadUserData,
  loadUserOptions,
  user,
  userOptions,
}) => {
  useEffect(() => {
    loadUserData();
    loadUserOptions();
  }, [loadUserData, loadUserOptions]);

  useEffect(() => {
    if (user) {
      intercomService.init(user);
    }
  }, [user]);

  if (user && !user.signed_broker_user_agreement && !userOptions) {
    return (
      <div className="flex-fill layout-align-start-stretch layout-row flex-grow react">
        <Agreement />
      </div>
    );
  }

  if (!user || !userOptions) {
    // don't mount rest of app until both user and userOptions are fetched and stored in redux
    return null;
  }
  return (
    <div className="flex-fill layout-align-start-stretch layout-row flex-grow react">
      <MainNavContainer turducken={false} />
    </div>
  );
};

export default App;

App.propTypes = {
  loadUserData: PropTypes.func.isRequired,
  loadUserOptions: PropTypes.func.isRequired,
  user: PropTypes.shape({
    signed_broker_user_agreement: PropTypes.bool,
  }),
  userOptions: PropTypes.shape({
    feature_flags: PropTypes.shape({
      survey_gizmo_flag: PropTypes.bool,
    }),
  }),
};

App.defaultProps = {
  user: null,
  userOptions: null,
};
