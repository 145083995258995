import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';

import AlertsEmptyState from './alerts-empty-state';
import Badge from '../../nucleus/badge/badge';
import Flyout from '../../nucleus/flyout/flyout';
import LoadMore from '../../nucleus/load-more/load-more';
import NotificationItem from './notification-item';
import Toast from '../../nucleus/toast/toast';
import NDButton from '../../nucleus/button/button';
import userEventService from '../../services/user-event-service';
import blockedStateImg from '../../assets/images/svg/notifications-blocked.svg';

class NotificationsAlerts extends React.Component {
  state = {
    showFlyout: false,
    page: 1,
    showToast: false,
    toastMessage: '',
    isLoading: false,
  };
  componentDidMount() {
    const { setNotificationsState } = this.props;
    setNotificationsState(); // get notification alerts
    this.onSetInterval(); // begin polling the API
  }

  shouldComponentUpdate(nextProps) {
    const {
      snoozeMessage,
      dismissMessage,
      clearSnoozeMessage,
      clearDismissMessage,
      isPushEnabled,
    } = nextProps;
    const {
      snoozeMessage: prevSnoozeMessage,
      dismissMessage: prevDismissMessage,
    } = this.props;

    if (prevSnoozeMessage !== snoozeMessage && snoozeMessage) {
      this.handleToast(snoozeMessage, clearSnoozeMessage);
    }
    if (prevDismissMessage !== dismissMessage && dismissMessage) {
      this.handleToast(dismissMessage, clearDismissMessage);
    }
    if (isPushEnabled) { this.onClearInterval(); }
    return true;
  }

  componentWillUnmount() {
    this.onClearInterval();
  }

  onSetInterval = () => {
    const { setNotificationsState } = this.props;
    this.alertHandle = setInterval(() => {
      // if no JWT token, stop polling
      const jwt = localStorage.getItem('JWT');
      if (!jwt) {
        this.onClearInterval();
      } else if (!this.state.showFlyout) {
        setNotificationsState();
      }
    }, 60000);
  };

  onClearInterval = () => {
    clearInterval(this.alertHandle);
  };

  alertHandle = null;

  dismissNotification = (notificationID) => {
    const {
      notifications,
      notificationsMetadata,
      dismissNotification,
      dismissAndLoadNextNotification,
    } = this.props;

    if (notificationsMetadata.hasNextPage) {
      dismissAndLoadNextNotification(notificationID, notifications.length);
    } else {
      dismissNotification(notificationID);
    }
  };

  dismissAllNotification = () => {
    const {
      dismissAllNotification,
      setNotificationsState,
    } = this.props;
    this.setState({ isLoading: true });
    dismissAllNotification();
    setTimeout(() => {
      this.setState({ isLoading: false }, () => {
        setNotificationsState();
      });
    }, 2000);
  };

  handleSnoozeAlert = (notificationID, updatedNotification, snoozeMessage) => {
    const {
      notifications,
      notificationsMetadata,
      snoozeNotification,
      snoozeAndLoadNextNotification,
    } = this.props;

    if (notificationsMetadata.hasNextPage) {
      snoozeAndLoadNextNotification(
        notificationID,
        updatedNotification,
        snoozeMessage,
        notifications.length,
      );
    } else {
      snoozeNotification(notificationID, updatedNotification, snoozeMessage);
    }
  };

  handleToast = (toastMessage, clearMessage) => {
    this.setState({
      showToast: true,
      toastMessage,
    });
    setTimeout(() => {
      this.setState({
        showToast: false,
        toastMessage: '',
      });
      clearMessage();
    }, 800);
  };

  markAsRead = () => {
    // markAsRead batch processes notifications using the object_id rather id
    // this method takes an array of objectIDs and stringifies them
    // so we can POST them to the API in the correct format.
    // We track task.id (alertIDs) so we can markNotificationsAsReadPending
    // and update local state until the next API poll occurs
    const {
      notifications,
      setMarkNotificationsAsRead,
      setNotificationsState,
    } = this.props;
    const alertIDs = [];
    notifications.forEach((notification) => {
      if (notification.read === null) {
        alertIDs.push(notification.id);
      }
    });
    this.setState({
      page: 1,
    });
    if (alertIDs.length > 0) {
      setMarkNotificationsAsRead(alertIDs);
    } else {
      setNotificationsState();
    }
  };

  // track when a user opens notification alerts when badge count
  // exists. also track when a user opens notificagtion alerts
  // when badge count does not exist
  handleBadgeCountTrackingEvent = () => {
    const {
      notificationsMetadata,
    } = this.props;
    const eventLabel = notificationsMetadata.badgeCount
      ? 'notification_alerts_opened_with_badge_count'
      : 'notification_alerts_opened_without_badge_count';
    const eventMetadata = {
      eventCategory: 'Notification Alerts',
      eventLabel,
      eventAction: 'notification_alerts_opened',
      eventData: {
        badgeCount: notificationsMetadata.badgeCount,
      },
    };
    userEventService.trackEvent(eventMetadata);
  };

  openFlyout = () => {
    this.setState({
      showFlyout: true,
    });
    this.handleBadgeCountTrackingEvent();
  };

  closeFlyout = () => {
    this.setState({
      showFlyout: false,
      page: 1,
    }, () => this.markAsRead());
    const eventMetadata = {
      eventCategory: 'Notification Alerts',
      eventAction: 'notification_alerts_closed',
      eventLabel: 'notification_alerts_closed_from_notifications',
    };
    userEventService.trackEvent(eventMetadata);
  };

  handleLoadMoreButton = () => {
    const { setNotificationsState } = this.props;
    this.setState({ page: this.state.page += 1 }, () => {
      setNotificationsState({ page: this.state.page });
    });
  };

  AlertsBlockedState = (
    <div className="alerts-empty-state">
      <h2><Icon iconName="warning" /> Notifications are disabled</h2>
      <p className="bold">You must enable Deal IQ notificatons in your browser</p>
      <br />
      <p>Click the small lock icon in the upper left of your browser</p>
      <img src={blockedStateImg} alt="No notifications" style={{ width: '95%' }} />
    </div>
  );

  render() {
    const {
      isLoadingNotifications,
      notifications,
      notificationsMetadata,
      isPushEnabled,
      userData,
      fetchTeamById,
    } = this.props;

    const {
      showFlyout,
      showToast,
      toastMessage,
    } = this.state;

    let renderNotifications = notifications.length ? notifications.map(notification => (
      <NotificationItem
        notification={notification}
        key={notification.id}
        closeFlyout={this.closeFlyout}
        handleSnoozeAlert={this.handleSnoozeAlert}
        setDismissState={this.dismissNotification}
        turducken={this.props.turducken}
        userData={userData}
        fetchTeam={fetchTeamById}
      />
    )) : <AlertsEmptyState type="notification" />;

    const renderToast = showToast ? <Toast message={toastMessage} /> : '';

    let loadMore = (
      <LoadMore
        isLoading={isLoadingNotifications}
        handleLoadMore={this.handleLoadMoreButton}
        hasNextPage={notificationsMetadata.hasNextPage}
      />
    );

    const iconClick = this.openFlyout;
    if (isPushEnabled && Notification.permission !== 'granted') {
      renderNotifications = this.AlertsBlockedState;
      loadMore = '';
    }

    return (
      <React.Fragment>
        <NDButton
          variant="icon"
          icon="alert"
          ariaLabel="Notification Alerts"
          onClick={iconClick}
        >
          <Badge
            count={notificationsMetadata.badgeCount}
            displayBadge={!this.state.showFlyout}
            displayWarning={renderNotifications === this.AlertsBlockedState}
          />
        </NDButton>
        <Flyout
          additionalClassName="notifications-alerts-flyout "
          showFlyout={showFlyout}
          closeFlyout={this.closeFlyout}
        >
          <div className="notification-sticky-header">
            <p className="notifications-alerts-header">Notification Alerts
              { isPushEnabled && Notification.permission === 'granted' &&
                notifications.length ?
                  <span className="inner-text-notification" onClick={() => this.dismissAllNotification()}>Clear All Notifications</span>
                : ''
              }
            </p>
            <div className={`loading-animation${this.state.isLoading ? ' active' : ''}`} />
          </div>
          <ul className="notifications-list">
            {renderNotifications}
          </ul>
          {loadMore}
        </Flyout>
        {renderToast}
      </React.Fragment>
    );
  }
}

export default NotificationsAlerts;

NotificationsAlerts.propTypes = {
  isLoadingNotifications: PropTypes.bool,
  notifications: PropTypes.arrayOf(PropTypes.shape({
    notification: PropTypes.shape({
      related_object: PropTypes.shape(),
      object_id: PropTypes.number,
      performed_by: PropTypes.shape({
        avatar: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      }),
      alert_datetime: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      read: PropTypes.string,
      variety: PropTypes.string,
    }),
  })),
  notificationsMetadata: PropTypes.shape({
    hasNextPage: PropTypes.number,
    badgeCount: PropTypes.number,
  }),
  setNotificationsState: PropTypes.func.isRequired,
  setMarkNotificationsAsRead: PropTypes.func.isRequired,
  snoozeNotification: PropTypes.func.isRequired,
  snoozeAndLoadNextNotification: PropTypes.func.isRequired,
  clearSnoozeMessage: PropTypes.func.isRequired,
  snoozeMessage: PropTypes.string,
  dismissNotification: PropTypes.func.isRequired,
  dismissAllNotification: PropTypes.func.isRequired,
  dismissAndLoadNextNotification: PropTypes.func.isRequired,
  clearDismissMessage: PropTypes.func.isRequired,
  dismissMessage: PropTypes.string,
  isPushEnabled: PropTypes.bool,
  turducken: PropTypes.bool.isRequired,
  userData: PropTypes.shape(),
  fetchTeamById: PropTypes.func.isRequired,
};

NotificationsAlerts.defaultProps = {
  isLoadingNotifications: false,
  notifications: [{
    notification: PropTypes.shape({
      related_object: {},
      object_id: 0,
      performed_by: PropTypes.shape({
        avatar: '',
        first_name: '',
        last_name: '',
      }),
      alert_datetime: new Date(),
      read: '',
      variety: '',
    }),
  }],
  notificationsMetadata: {
    hasNextPage: 0,
    badgeCount: 0,
  },
  snoozeMessage: '',
  dismissMessage: '',
  isPushEnabled: false,
  userData: {},
};
