import * as commissionsService from '../../services/voucher/commissions-service';
import {
  FETCH_COMMISSION_OPTIONS_SUCCESS,
} from './action-types';

// action creators
const fetchCommissionOptionsSuccess = options => ({
  type: FETCH_COMMISSION_OPTIONS_SUCCESS,
  payload: options,
});

// async action creators
const fetchCommissionOptions = () => dispatch => commissionsService.fetchOptions()
  .then(({ actions: { POST } }) => dispatch(fetchCommissionOptionsSuccess(POST)))
  .catch(console.error);


export {
  // eslint-disable-next-line import/prefer-default-export
  fetchCommissionOptions,
};
