import React from 'react';
import PropTypes from 'prop-types';

import FileUploadAccordian from '../../file-upload-accordian';
import {
  LeaseAbstractionField,
  LeaseAbstractionFieldType,
} from '../../leaseAbstractionField';

const RentAndEscalations = ({
  modalFieldState,
  modalFieldChangeHandler,
  fieldErrorMap,
}) => (
  <FileUploadAccordian accordianTitle="Rent & Escalation">
    <LeaseAbstractionField
      key="baseRent"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.baseRent,
        label: 'Base Rent($/SqFt per year)',
        errorMessage: fieldErrorMap.baseRent,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('baseRent', {
          value: event.target.value,
        });
      }}
    />
  </FileUploadAccordian>
);

RentAndEscalations.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modalFieldState: PropTypes.object,
  modalFieldChangeHandler: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fieldErrorMap: PropTypes.object,
};

RentAndEscalations.defaultProps = {
  modalFieldState: {},
  modalFieldChangeHandler: () => {},
  fieldErrorMap: {},
};

export default RentAndEscalations;
