import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import { Button, IconButton, RadioGroup, RadioButton } from '@cbrebuild/blocks';

import userEventService from '../../../services/user-event-service';

import AddItem from '../../../nucleus/add-item/add-item';
import ConfirmationModal from '../../../nucleus/confirmation-modal/confirmation-modal';
import Datepicker from '../../../nucleus/datepicker/datepicker';
import Property from '../../property/property';
import PropertyAutocomplete from '../../autocomplete/property-autocomplete';
import SpacesFields from './spaces-fields';

class PropertyForm extends React.Component {
  constructor(props) {
    super(props);
    const spaces = props.property.spaces.map(space => ({
      description: space.description,
      floor_code: space.floor_code,
      id: space.id,
      lease_expiration_date: space.lease_expiration_date,
      owned: space.owned,
      status: space.status,
      address_line2: space.address_line2,
      total_sqft: space.total_sqft,
      whole_building: space.whole_building,
    }));
    this.state = {
      invalidFloor: false,
      invalidSelectedProperty: false,
      selectedProperty: null,
      showModal: false,
      showSpaceFields: !!spaces[0].floor_code,
      // spaces will always have a length because there is always a
      // a default space that gets created in order to tie a property
      // to a deal to accommodate the BE. upon new property add, we do
      // not want to show SpacesFields by default but we DO want to show
      // SpacesFields for properties that already exist with spaces that
      // are entering an edit workflow. in order to achieve this, we
      // have to key off of the floor_code. if there is a floor_ code
      // we know its a valid space (property with spaces) so we
      // show SpacesFields, otherwise, do not show SpacesFields for
      // a new property
      showAddSpacesOption: !!spaces[0].floor_code || (!spaces[0].whole_building && !spaces[0].owned),
      spaces,
      submitted: false,
    };
  }

  onCloseConfirmationModal = () => {
    this.setState({ showModal: false });
  }

  onDeleteProperty = (property, dealId) => {
    const { deleteProperty } = this.props;
    deleteProperty(property, dealId);
    this.onCloseConfirmationModal();
  }

  onInputChange = (e, id) => {
    const { spaces } = this.state;
    const { target } = e;
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    const updatedSpaces = spaces.map((s) => {
      if (s.id === id) {
        return {
          ...s,
          [target.name]: target.name !== 'total_sqft'
            ? target.value
            : parseInt(target.value, 10),
        };
      }
      return s;
    });
    // re-evaluate any changing floor_code input to remove validation
    const spaceValidation = (target.name === 'floor_code')
      ? this.hasInvalidFloors(updatedSpaces)
      : null;
    this.setState({
      invalidFloor: spaceValidation
        ? spaceValidation.hasAnyInvalidFloors
        : false,
      spaces: spaceValidation
        ? spaceValidation.spacesWithErrorFlags
        : updatedSpaces,
    });
    userEventService.trackEvent({
      eventAction: 'suite_edits', eventCategory: 'Property Action', eventData: updatedSpaces,
    }, { actionPrefix, categoryPrefix });
  }

  onLeaseExpirationChange = (date, id) => {
    const { spaces } = this.state;
    const updatedSpaces = spaces.map((s) => {
      if (s.id === id) {
        /* istanbul ignore next */
        return {
          ...s,
          lease_expiration_date: date === '' ? null : date,
        };
      }
      return s;
    });
    this.setState({ spaces: updatedSpaces });
  }

  onOwnedChange = (e) => {
    const { spaces } = this.state;
    const updatedSpaces = spaces.map((s) => {
      if (e === 'owned') {
        /* istanbul ignore next */
        return {
          ...s,
          owned: true,
          floor_code_error: false,
          whole_building: false,
        };
      }
      return {
        ...s,
        owned: false,
        floor_code_error: false,
      };
    });
    this.setState({
      showAddSpacesOption: e !== 'owned',
      spaces: updatedSpaces,
      invalidFloor: false,
    });
  }

  onShowConfirmationModal = () => {
    this.setState({ showModal: true });
  }

  onStatusChange = (e, id) => {
    const { spaces } = this.state;
    const status = e.value;
    const updatedSpaces = spaces.map((s) => {
      /* istanbul ignore if */
      if (s.id === id) {
        return {
          ...s,
          status,
        };
      }
      return s;
    });
    this.setState({ spaces: updatedSpaces });
  }

  onSave = () => {
    const {
      addProperty,
      onClose,
      dealId,
      property,
      updateProperty,
    } = this.props;
    const {
      spaces,
      selectedProperty,
    } = this.state;
    // modify/transform spaces on the way out
    // depending on whether they are suites, wholeBuilding or owned
    const {
      validateSuites,
      spacesPayload,
    } = this.transformSpacesPayload(spaces, property.usage);
    // (selectedProperty && property.id === 'string') means it's an add property workflow
    // (selectedProperty && property.id === 'number') means it's an update property workflow
    // (!selectedProperty && property.id === 'number') means it's an update spaces workflow, but no updates to property
    const propertyToValidate = (!selectedProperty && typeof property.id === 'number')
      ? property
      : selectedProperty;
    // if this is an update to an existing property, reuse property id key
    if (selectedProperty && typeof property.id === 'number') {
      propertyToValidate.id = property.id;
    }
    const isValidForm = this.isValidForm(spacesPayload, propertyToValidate, validateSuites);
    if (!isValidForm) {
      return;
    }
    // build payload:
    // if this is a uniquely generated uuid for a new space
    // then its a string and we don't want to send it to the server
    if (typeof propertyToValidate.id === 'string') {
      delete propertyToValidate.id;
    }
    const params = {
      building: propertyToValidate.building,
      name: propertyToValidate.name,
      spaces: spacesPayload.map((s) => {
        // if this is a uniquely generated uuid for a new space
        // then its a string and we don't want to send it to the server
        if (typeof s.id === 'string') {
          delete s.id; // eslint-disable-line
        }
        return s;
      }),
      usage: propertyToValidate.usage,
    };
    if (propertyToValidate && propertyToValidate.id) {
      updateProperty(propertyToValidate.id, params, dealId);
      onClose();
    } else {
      addProperty(params, dealId);
      onClose();
    }
  }


  onWholeBuildingChange = (e) => {
    const { spaces } = this.state;
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
    } = this.props;
    const updatedSpaces = spaces.map((s) => {
      if (e === 'whole_building') {
        return {
          ...s,
          whole_building: true,
          floor_code: undefined,
          floor_code_error: false,
        };
      }
      return {
        ...s,
        whole_building: false,
        floor_code_error: false,
      };
    });
    this.setState({
      showAddSpacesOption: e !== 'whole_building',
      showSpaceFields: e !== 'whole_building',
      spaces: updatedSpaces,
      invalidFloor: false,
    });
    userEventService.trackEvent({
      eventAction: 'whole_building_change', eventCategory: 'Property Action', eventData: updatedSpaces,
    }, { actionPrefix, categoryPrefix });
  }

  getPropertyErrorMessage = () => {
    const {
      invalidSelectedProperty,
      selectedProperty,
      submitted,
    } = this.state;
    const invalidPropertyMessage = 'Please select a property with a city, state, zip and address.';
    const propertyRequiredMessage = 'A Property is required, please select one from the list';
    if (submitted && selectedProperty && invalidSelectedProperty) {
      return invalidPropertyMessage;
    }
    if (submitted && !selectedProperty && invalidSelectedProperty) {
      return propertyRequiredMessage;
    }
    if (invalidSelectedProperty) {
      return invalidPropertyMessage;
    }
    return '';
  }

  isValidForm = (spaces, property, validateSuites = false) => {
    const { showSpaceFields } = this.state;
    let isValid = true;
    const spaceValidation = (validateSuites && showSpaceFields)
      ? this.hasInvalidFloors(spaces)
      : null;
    if (!property || (spaceValidation && spaceValidation.hasAnyInvalidFloors)) {
      this.setState({
        invalidFloor: spaceValidation
          ? spaceValidation.hasAnyInvalidFloors
          : false,
        spaces: spaceValidation
          ? spaceValidation.spacesWithErrorFlags
          : spaces,
        invalidSelectedProperty: !property,
        submitted: true,
      });
      isValid = false;
    }
    return isValid;
  }

  hasInvalidFloors = (spaces) => {
    // add an extra key with an error flag for spaces that don't have required floor_code
    let hasAnyInvalidFloors = false;
    const spacesWithErrorFlags = spaces.map((s) => {
      let updatedSpace;
      if (s.floor_code === '') {
        updatedSpace = {
          ...s,
          floor_code_error: true,
        };
        hasAnyInvalidFloors = true;
      } else {
        updatedSpace = {
          ...s,
          floor_code_error: false,
        };
      }
      return updatedSpace;
    });
    return {
      hasAnyInvalidFloors,
      spacesWithErrorFlags,
    };
  }

  transformSpacesPayload = (spaces, usage) => {
    const {
      dealId,
    } = this.props;
    // used to know if we are adding suites and whether we should validate them
    let validateSuites = true;
    // transform spaces and reset space validation
    let spacesPayload = spaces.map((space) => {
      const spacePayload = {
        ...space,
        usage,
        floor_code_error: false, // used in spaces-fields.js to render error message
        transactions: [
          {
            id: dealId,
          },
        ],
      };
      return spacePayload;
    });
    const firstSpace = spacesPayload[0];
    if (firstSpace && firstSpace.whole_building) {
      spacesPayload = [{
        ...firstSpace, // take previous updates
        whole_building: true,
        floor_code: '',
        address_line2: '',
        status: '',
        description: '',
      }];
      validateSuites = false;
    }
    if (firstSpace && firstSpace.owned) {
      spacesPayload = [{
        ...firstSpace, // take previous updates
        owned: true,
        floor_code: '',
        address_line2: '',
        status: '',
        description: '',
      }];
      validateSuites = false;
    }
    return {
      spacesPayload,
      validateSuites,
    };
  }

  addSpace = () => {
    if (this.state.showSpaceFields) {
      this.setState({
        spaces: [...this.state.spaces,
          {
            description: '',
            floor_code: '',
            id: uniqueId(),
            lease_expiration_date: null,
            owned: false,
            status: '',
            address_line2: null,
            total_sqft: undefined,
            whole_building: false,
          },
        ],
      });
    } else {
      this.setState({ showSpaceFields: true });
    }
  }

  addProperty = (selectedProperty, isUnknownProperty = false) => {
    // validate the selected property
    /* istanbul ignore if */
    if (!isUnknownProperty) {
      const isValidProperty = this.isValidSelectedProperty(selectedProperty);
      if (!isValidProperty) {
        return;
      }
    }
    this.setState({ selectedProperty });
  }

  invalidPropertyFields = property =>
    ['address', 'city', 'state_abbreviation', 'postal_code'].filter(key => !(property.building[key]));

  isValidSelectedProperty = (property) => {
    if (this.invalidPropertyFields(property).length > 0) {
      this.setState({ invalidSelectedProperty: true });
      return false;
    }
    this.setState({ invalidSelectedProperty: false });
    return true;
  };

  remove = (id) => {
    const { spaces } = this.state;
    if (spaces.length === 1) {
      this.setState({ showSpaceFields: false });
    } else {
      this.setState({ spaces: spaces.filter(s => s.id !== id) });
    }
  }

  render() {
    const {
      invalidFloor,
      invalidSelectedProperty,
      selectedProperty,
      showModal,
      showSpaceFields,
      showAddSpacesOption,
      spaces,
      submitted,
    } = this.state;
    const {
      dealId,
      onClose,
      property,
    } = this.props;
    const building = selectedProperty
      ? selectedProperty.building
      : property.building;
    const propertyErrorMessage = this.getPropertyErrorMessage();
    const propertyName = selectedProperty
      ? selectedProperty.name
      : property.name;
    return (
      <div className="property-form">
        <div className="property-form-group">
          {!isEmpty(building) &&
            <Property
              building={building}
              callToAction={null}
              name={propertyName}
            />
          }
          <PropertyAutocomplete
            errorMessage={propertyErrorMessage}
            onSelect={this.addProperty}
          />
          {(property.usage === 'Current' &&
            <div className="property-situation">
              <p>What is the client&#39;s current occupancy/ownership?</p>
              <RadioGroup
                name={`property-options-${spaces[0].id}`}
                onChange={e => this.onOwnedChange(e.target.value)}
                selectedValue={(spaces[0].owned === false) ? 'lease' : 'owned'}
              >
                <RadioButton value="lease">Lease</RadioButton>
                <RadioButton value="owned">Owned</RadioButton>
              </RadioGroup>
            </div>)}
          {!spaces[0].owned &&
            <React.Fragment>
              <RadioGroup
                name={`space-type-options-${spaces[0].id}`}
                onChange={e => this.onWholeBuildingChange(e.target.value)}
                selectedValue={(spaces[0].whole_building === false) ? 'suite' : 'whole_building'}
              >
                <RadioButton value="suite">Suites</RadioButton>
                <RadioButton value="whole_building">Whole Building</RadioButton>
              </RadioGroup>
            </React.Fragment>
          }
          {showAddSpacesOption &&
            <div className="spaces-fields-header">
              <span>Spaces</span>
              <AddItem
                label="Spaces"
                onClick={this.addSpace}
              />
            </div>
          }
          {(showSpaceFields && showAddSpacesOption) &&
            <SpacesFields
              onInputChange={this.onInputChange}
              onLeaseExpirationChange={this.onLeaseExpirationChange}
              onStatusChange={this.onStatusChange}
              property={property}
              remove={this.remove}
              spaces={spaces}
            />
          }
          {((spaces[0].owned || spaces[0].whole_building) && !showSpaceFields) &&
            <div className={spaces[0].whole_building && property.usage === 'Current' ? 'whole-building-fields' : 'owned-field'}>
              <div>
                <label>SQFT</label>
                <input
                  name="total_sqft"
                  onChange={e => this.onInputChange(e, spaces[0].id)}
                  type="number"
                  value={spaces[0].total_sqft || ''}
                />
              </div>
              {spaces[0].whole_building && property.usage === 'Current' &&
                <div>
                  <label>Lease Expiration</label>
                  <Datepicker
                    date={spaces[0].lease_expiration_date || null}
                    onDateChange={e => this.onLeaseExpirationChange(e, spaces[0].id)}
                    scrollOnFocus
                  />
                </div>
              }
            </div>
          }
          <div className="form-controls">
            {(property.building && property.building.id) &&
              <IconButton onClick={this.onShowConfirmationModal} variant="basic" iconName="bin" />}
            <Button
              variant="secondary"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={(submitted && (invalidFloor || invalidSelectedProperty))}
              onClick={this.onSave}
            >
              Save Property
            </Button>
          </div>
        </div>
        {showModal &&
          <ConfirmationModal
            className="deal-property-delete-confirmation"
            onCloseModal={this.onCloseConfirmationModal}
            showModal={showModal}
          >
            <h1>Are you sure?</h1>
            <p>This building will be permanently deleted from this deal.</p>
            <footer>
              <Button
                variant="secondary"
                onClick={this.onCloseConfirmationModal}
              >
                Cancel
              </Button>
              <Button
                className="confirmationclose"
                onClick={() => this.onDeleteProperty(property, dealId)}
              >
                Delete
              </Button>
            </footer>
          </ConfirmationModal>}
      </div>
    );
  }
}

PropertyForm.propTypes = {
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  addProperty: PropTypes.func.isRequired,
  dealId: PropTypes.number.isRequired,
  deleteProperty: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  property: PropTypes.shape({
    building: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      full_address: PropTypes.string,
      id: PropTypes.number,
      postal_code: PropTypes.string,
      primary_property_name: PropTypes.string,
      state_abbreviation: PropTypes.string,
    }),
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    name: PropTypes.string,
    spaces: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string,
      description: PropTypes.string,
      address_line2: PropTypes.undefined,
      id: PropTypes.null,
      floor_code: PropTypes.string,
      lease_expiration_date: PropTypes.undefined,
      total_sqft: PropTypes.number,
      owned: PropTypes.undefined,
      whole_building: PropTypes.undefined,
      transactions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.null,
      })),
    })),
    usage: PropTypes.string,
  }),
  updateProperty: PropTypes.func,
};

PropertyForm.defaultProps = {
  property: {},
  updateProperty: () => {},
};

export default PropertyForm;
