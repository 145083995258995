import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';

import ContactSyncNotification from './contact-sync-notification';
import DealNotification from './deal-notification';
import DealTeamNotification from './deal-team-notification';
import TeamNotification from './team-notification';
import LeaseExpirationNotification from './lease-expiration-notification';
import NotificationIcon from './notification-icon';
import NotificationTime from './notification-time';

import userEventService from '../../services/user-event-service';
import BulkFailNotification from './bulk-fail-notification';

class NotificationItem extends React.PureComponent {
  state = {
    showSnoozeClass: false,
    dismissedClass: '',
  };

  handleSnooze = (snoozeTime, snoozeMessage, trackingSnoozeTime) => {
    const {
      notification,
      handleSnoozeAlert,
    } = this.props;

    this.setState({ showSnoozeClass: true });
    setTimeout(() => {
      handleSnoozeAlert(notification.id, snoozeTime, snoozeMessage);
      this.handleLeaseExpirationNotificationTracking(trackingSnoozeTime);
    }, 800);
  };

  handleDismiss = (id) => {
    const { setDismissState } = this.props;
    // add animation for dismissed notifications, delay API call
    this.setState({ dismissedClass: 'dismissed' }, () => {
      setTimeout(() => {
        setDismissState(id);
      }, 300);
    });
  };

  // ** User Event Tracking Methods ** //
  // track when a user navigates to deal page from deal
  // notification alert
  handleNavigateToDealTracking = () => {
    const {
      closeFlyout,
    } = this.props;
    const eventMetadata = {
      eventCategory: 'Notification Alerts',
      eventLabel: 'navigate_to_deal_page_from_notification',
      eventAction: 'navigate_to_deal_page',
    };
    userEventService.trackEvent(eventMetadata);
    closeFlyout();
  };
  // track when a user snoozes a lease exp notification
  handleLeaseExpirationNotificationTracking = (snoozeValue) => {
    const eventMetadata = {
      eventCategory: 'Notification Alerts',
      eventLabel: snoozeValue,
      eventAction: 'lease_expiration_notification_reminder_clicked',
    };
    userEventService.trackEvent(eventMetadata);
  };
  // track when a user navigates to contacts page from contact
  // sync notification alert
  handleContactSyncNotificationTracking = () => {
    const {
      closeFlyout,
    } = this.props;
    const eventMetadata = {
      eventCategory: 'Notification Alerts',
      eventAction: 'navigate_to_contact_page',
      eventLabel: 'navigate_to_contact_page_from_notification',
    };
    userEventService.trackEvent(eventMetadata);
    closeFlyout();
  };
  // ** End of User Event Tracking Methods ** //

  renderNotification = () => {
    const {
      closeFlyout,
      notification,
      userData,
      fetchTeam,
    } = this.props;
    const {
      variety,
      message = '',
      related_object: relatedObject,
      performed_by: performedBy,
      read,
    } = notification;

    switch (variety) {
      case 'added_to_deal':
        return (
          <DealNotification
            variety={variety}
            deal={relatedObject}
            performedBy={performedBy}
            handleNavigateTracking={this.handleNavigateToDealTracking}
            turducken={this.props.turducken}
          />
        );
      case 'removed_from_deal':
        return (
          <DealNotification
            variety={variety}
            deal={relatedObject}
            closeFlyout={closeFlyout}
            performedBy={performedBy}
            handleNavigateTracking={this.handleNavigateToDealTracking}
            turducken={this.props.turducken}
          />
        );
      case 'added_to_team':
      case 'removed_from_team':
        return (
          <TeamNotification
            relatedObject={relatedObject}
            variety={variety}
            performedBy={performedBy}
            handleNavigateTracking={this.handleNavigateToDealTracking}
            turducken={this.props.turducken}
            userData={userData}
          />
        );
      case 'team_added_to_deal':
      case 'team_removed_from_deal':
        return (
          <DealTeamNotification
            relatedObject={relatedObject}
            variety={variety}
            performedBy={performedBy}
            handleNavigateTracking={this.handleNavigateToDealTracking}
            turducken={this.props.turducken}
            read={read}
            fetchTeam={fetchTeam}
          />
        );
      case 'lease_expiration_reminder':
        return (
          <LeaseExpirationNotification
            closeFlyout={closeFlyout}
            deal={relatedObject}
            message={message}
            handleSnooze={this.handleSnooze}
            handleNavigateTracking={this.handleNavigateToDealTracking}
            turducken={this.props.turducken}
          />
        );
      case 'user_needs_to_grant_outlook_sync':
        return (
          <ContactSyncNotification
            handleDismiss={this.handleDismiss}
            message={message}
            notificationID={notification.id}
            handleTracking={this.handleContactSyncNotificationTracking}
            turducken={this.props.turducken}
          />
        );
      case 'bulk_add_to_deals_failed':
      case 'bulk_remove_from_deals_failed':
        return (
          <BulkFailNotification
            variety={variety}
            relatedObject={relatedObject}
            turducken={this.props.turducken}
          />
        );
      case 'deal_linked_to_mta_transaction':
        return (
          <DealNotification
            variety={variety}
            deal={relatedObject}
            closeFlyout={closeFlyout}
            performedBy={performedBy}
            handleNavigateTracking={this.handleNavigateToDealTracking}
            turducken={this.props.turducken}
          />
        );
      default:
        return '';
    }
  };

  render() {
    const {
      performed_by: performedBy = {},
      alert_datetime: alertDatetime = '',
      variety,
      read,
    } = this.props.notification;

    const {
      closeFlyout,
      notification,
    } = this.props;

    const {
      showSnoozeClass,
      dismissedClass,
    } = this.state;

    // Render notification alert message
    const markAsReadClass = read === null ? 'item-unread' : '';
    const snoozeClass = showSnoozeClass ? 'snoozed' : '';

    return (
      <React.Fragment>
        <li className={`notification-item ${markAsReadClass} ${snoozeClass} ${dismissedClass}`}>
          <NotificationIcon
            closeFlyout={closeFlyout}
            performedBy={performedBy}
            variety={variety}
          />
          <section className="notification-message">
            {this.renderNotification()}
            <NotificationTime alertDatetime={alertDatetime} />
          </section>
          <IconButton className="blxs-button-icon-small" iconName="close" onClick={() => this.handleDismiss(notification.id)} variant="basic" />
        </li>
      </React.Fragment>
    );
  }
}

export default NotificationItem;

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
    related_object: PropTypes.shape(),
    performed_by: PropTypes.shape({
      avatar: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    alert_datetime: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    message: PropTypes.string,
    read: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    variety: PropTypes.string,
  }),
  closeFlyout: PropTypes.func.isRequired,
  handleSnoozeAlert: PropTypes.func.isRequired,
  setDismissState: PropTypes.func.isRequired,
  turducken: PropTypes.bool.isRequired,
  userData: PropTypes.shape(),
  fetchTeam: PropTypes.func.isRequired,
};

NotificationItem.defaultProps = {
  notification: PropTypes.shape({
    related_object: {},
    performed_by: PropTypes.shape({
      avatar: '',
      first_name: '',
      last_name: '',
    }),
    alert_datetime: new Date(),
    read: '',
    variety: '',
  }),
  userData: { id: null },
};
