import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import { Icon } from '@cbrebuild/blocks';
import 'react-day-picker/lib/style.css';

/* Legacy comments:
  The React-Day-Picker package deals with native JS date objects. This component
  acts as a shim to interface with the picker in a way that makes it easy to use.
  We use moment to mutate JS date objects to date strings that our frontend
  can display in the format: 'M/D/YYYY'

  This shim takes in 2 required props:
  1. onDateChange() required prop that updates the parent when a date change occurss
  2. 'date' required prop that sets the date from parent
  3. optional updateValidity() prop so this component can inform its parents when an invalid date has been entered

  Our backend consumes dates in the format: 'YYYY-MM-DD' to be search query friendly, any dates
  coming in as props should have this format to minimize date format
  manipulation oustide of this component. We need to ensure that dates leaving this component
  are formatted properly for backend consumption
*/

/* Dates are consumed and used in a variety of ways in this component. */
const formatForDisplay = date => moment(date).format('M/D/YYYY');
const formatForOutput = date => (date ? moment(date).format('YYYY-MM-DD') : '');

const formatForInput = date => (date ? moment(date).toDate() : '');
const formatForValidity = date => moment(date, 'M/D/YYYY', true);
const formatForParsing = date =>
  (formatForValidity(date).isValid()
    ? formatForValidity(date).toDate()
    : undefined);

const defaultErrorText = 'Date format should be MM/DD/YYYY';

const Datepicker = ({
  disabled,
  onDateChange,
  onDayPickerHide,
  onBlur,
  onFocus,
  date: origDate,
  updateValidity,
  scrollOnFocus,
  requiredText,
}) => {
  const [currDate, setCurrDate] = useState(formatForInput(origDate));
  const [isCurrDateValid, setIsCurrDateValid] = useState(formatForInput(origDate).toString() !== 'Invalid Date');
  const [isEditing, setIsEditing] = useState(false);

  const inputRef = useRef(null); // for calendar clicking functionality

  useEffect(() => {
    setCurrDate(formatForInput(origDate));
    setIsCurrDateValid(formatForInput(origDate).toString() !== 'Invalid Date');
    updateValidity(formatForInput(origDate).toString() !== 'Invalid Date');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origDate]);

  // Handle when input is changed.
  const handleDayChange = (date, _modifiers, dayPickerInput) => {
    onDateChange(formatForOutput(date));
    setCurrDate(date);

    const newValidity = date
      ? formatForValidity(date).isValid()
      : dayPickerInput.getInput().value === '';
    if (newValidity !== isCurrDateValid) {
      setIsCurrDateValid(newValidity);
      updateValidity(newValidity);
    }
  };

  // Handle when a date is selected via clicking away or by clicking on a date on the calendar.
  const handleDayPickerHide = () => {
    setIsEditing(false);
    if (formatForOutput(currDate) !== formatForOutput(origDate)) {
      onDayPickerHide(formatForOutput(currDate));
    }
  };

  // Enable scrollOnFocus.
  const handleFocus = () => {
    setTimeout(() => {
      const dayPickerInputOverlay = document.getElementsByClassName('DayPickerInput-Overlay')[0];
      if (dayPickerInputOverlay && scrollOnFocus) {
        dayPickerInputOverlay.scrollIntoView();
      }
    });
    setIsEditing(true);
    onFocus();
  };

  // Handle the calendar icon.
  const handleCalendarClick = () => {
    inputRef.current.input.focus();
    handleFocus();
  };

  // Formatting
  const getErrorMessage = () => {
    if (!isCurrDateValid && !isEditing) {
      return <p className="error-message">{defaultErrorText}</p>;
    } else if (!origDate && requiredText) {
      return <p className="error-message">{requiredText}</p>;
    }
    return '';
  };

  const getCustomClasses = () => ({
    container: `DayPickerInput ${
      (!isCurrDateValid && !isEditing) || getErrorMessage()
        ? 'invalid-input'
        : ''
    }`,
    overlay: 'DayPickerInput-Overlay',
    overlayWrapper: 'DayPickerInput-OverlayWrapper',
  });

  return (
    <div className="nd-datepicker">
      <DayPickerInput
        inputProps={{
          onFocus: handleFocus,
          onBlur,
          disabled,
        }}
        classNames={getCustomClasses()}
        formatDate={formatForDisplay}
        parseDate={formatForParsing}
        value={currDate}
        onDayPickerHide={handleDayPickerHide}
        onDayChange={handleDayChange}
        placeholder="MM/DD/YYYY"
        ref={inputRef}
      />
      <Icon onClick={handleCalendarClick} iconName="calendar" />
      {getErrorMessage()}
    </div>
  );
};

export default Datepicker;

Datepicker.propTypes = {
  disabled: PropTypes.bool,
  onDateChange: PropTypes.func.isRequired,
  onDayPickerHide: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  date: PropTypes.string,
  updateValidity: PropTypes.func,
  scrollOnFocus: PropTypes.bool,
  requiredText: PropTypes.string,
};

Datepicker.defaultProps = {
  date: '',
  disabled: false,
  onBlur: () => {},
  onFocus: () => {},
  scrollOnFocus: false,
  updateValidity: () => {},
  onDayPickerHide: () => {},
  requiredText: '',
};
