import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CommissionsDetailInstallments from './commissions-detail-installments';
import CommissionsDetailConsiderations from './commissions-detail-considerations';
import CommissionsDetailOtherCommissions from './commissions-detail-other-commissions';
import CommissionsDetailEscalations from './commissions-detail-escalations';

const CommissionsDetailTabs = (props) => {
  const tabs = ['installments', 'considerations', 'other commissions', 'escalations'];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <div className="commissions-card commissions-detail-tabs">
      <div className="nd-tabnavs">
        <ul>
          {tabs.map(tab => (
            <li
              key={tab}
              className={activeTab === tab ? 'active' : ''}
              onClick={() => setActiveTab(tab)}
            >
              <a>{tab}</a>
            </li>
          ))}
        </ul>
      </div>

      {activeTab === tabs[0] && <CommissionsDetailInstallments installments={props.installments} />}
      {activeTab === tabs[1] && <CommissionsDetailConsiderations considerations={props.considerations} />}
      {activeTab === tabs[2] && <CommissionsDetailOtherCommissions additionalCommissions={props.additionalCommissions} />}
      {activeTab === tabs[3] && <CommissionsDetailEscalations escalations={props.escalations} />}
    </div>
  );
};

export default CommissionsDetailTabs;

CommissionsDetailTabs.propTypes = {
  additionalCommissions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  considerations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  escalations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  installments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

