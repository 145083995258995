import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { Textarea } from '@cbrebuild/blocks';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {
  updateDeal,
} from '../../redux/actions/deal-actions';


const VoucherComments = (props) => {
  const { deal, voucher } = useSelector(state => ({
    deal: state.deal.deal,
    voucher: state.voucher.voucher,
  }));
  const voucherText = voucher.is_early_invoice ? 'Early Invoice Comments' : 'Voucher Comments';
  const placeholderText = voucher.is_early_invoice ? 'Add comments to early invoice...' : 'Add comments to voucher ...';
  const [isCardSaving, setIsCardSaving] = useState(true);
  const [, setIsCardSaved] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [comments, setComments] = useState('');

  const saveComments = useCallback(debounce((userInput) => {
    setIsCardSaving(true);
    props.updateDeal(deal.id, { comments: userInput });
    deal.comments = userInput;
    setIsCardSaved(true);
    setIsCardSaving(false);
  }, 500));

  const handleChange = ({ target: { value } }) => {
    setComments(value);
    saveComments(value);
    setIsEditing(true);
    setIsCardSaving(true);
  };


  return (
    <div className="side-card">
      <div className="side-card-header">{voucherText}</div>
      <div className="side-card-content">
        <div className="side-card-label">Comments</div>
        <span className="side-card-status">
          {
          (() => {
            if (isEditing) {
              if (isCardSaving) {
                return (<p>Saving...</p>);
              }

                return (<p>Saved</p>);
            }

              return (<p />);
          })()
          }
        </span>
        <Textarea
          id="deal-comments"
          rows={{ min: 4, max: 130 }}
          onChange={handleChange}
          value={comments || deal.comments}
          placeholder={placeholderText}
        />
      </div>
    </div>
  );
};


const mapDispatchToProps = {
  updateDeal,
};

export default connect(
  null,
  mapDispatchToProps,
)(VoucherComments);

VoucherComments.propTypes = {
  updateDeal: PropTypes.func.isRequired,
};
