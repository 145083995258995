import { SET_PROXY_ACCESS } from '../actions/action-types';

const initialState = {
  proxySettings: {},
};

// Proxy Settings Reducers
const setProxy = (state, action) => ({
  ...state,
  proxySettings: action.payload,
});

const proxy = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROXY_ACCESS:
      return setProxy(state, action);
    default:
      return state;
  }
};

export default proxy;
