import * as commissionsService from '../../services/voucher/commissions-service';
import {
  FETCH_SHARED_FEES_SUCCESS,
  UPDATE_SHARED_FEE_SUCCESS,
  CREATE_SHARED_FEE_SUCCESS,
  DELETE_SHARED_FEE_SUCCESS,
} from './action-types';
import { updateFinancials } from './financial-actions';

// action creators
const fetchSharedFeesSuccess = sharedFees => ({
  type: FETCH_SHARED_FEES_SUCCESS,
  payload: sharedFees,
});

const updateSharedFeeSuccess = sharedFee => ({
  type: UPDATE_SHARED_FEE_SUCCESS,
  payload: sharedFee,
});

const createSharedFeeSuccess = sharedFee => ({
  type: CREATE_SHARED_FEE_SUCCESS,
  payload: sharedFee,
});

const deleteSharedFeeSuccess = id => ({
  type: DELETE_SHARED_FEE_SUCCESS,
  payload: id,
});

// async action creators
const fetchSharedFees = params => dispatch => commissionsService.fetchCommissions(params)
  .then(commissions => dispatch(fetchSharedFeesSuccess(commissions)))
  .catch(console.error);

const updateSharedFee = (id, data, ignoreFinancialUpdate = false) => dispatch => commissionsService.updateCommission(id, data)
  .then((commission) => {
    if ('gross_commission' in data && !ignoreFinancialUpdate) {
      dispatch(updateFinancials(commission.transaction));
    } dispatch(updateSharedFeeSuccess(commission));
  })
  .catch(console.error);

const createSharedFee = params => dispatch => commissionsService.createCommission(params)
  .then(commission => dispatch(createSharedFeeSuccess(commission)))
  .catch(console.error);

const deleteSharedFee = id => (dispatch, getState) => commissionsService.deleteCommission(id)
  .then(() => {
    dispatch(updateFinancials(getState().deal.deal.id));
    dispatch(deleteSharedFeeSuccess(id));
  })
  .catch(console.error);

export {
  fetchSharedFees,
  createSharedFee,
  deleteSharedFee,
  updateSharedFee,
};
