import {
  FETCH_COMMISSION_OPTIONS_SUCCESS,
} from '../actions/action-types';


const initialState = {
  cbre_broker_type: [],
  payment_group: [],
  producer_role: [],
  voucher_broker_rep_role: [],
  voucher_from_dept: [],
  voucher_to_dept: [],
};

const cleanOptions = (options) => {
  const cleaned = options.filter(option => option.display_name !== '');
  if (cleaned.length !== options.length) {
    return [{ display_name: 'NONE', value: '' }, ...cleaned];
  }
  return options;
};

const fetchCommissionOptionsSuccess = (state, { payload }) => ({
  ...state,
  ...(Object.keys(initialState).reduce((acc, key) => {
    if (payload[key]) {
      acc[key] = cleanOptions(payload[key].choices);
    }
    return acc;
  }, {})),
});

const options = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMISSION_OPTIONS_SUCCESS:
      return fetchCommissionOptionsSuccess(state, action);
    default:
      return state;
  }
};

export default options;
