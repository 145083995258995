import * as commissionsService from '../../services/voucher/commissions-service';
import companyService from '../../services/companies-service';
import {
  FETCH_OUTSIDE_BROKER_COMMISSIONS_SUCCESS,
  UPDATE_OUTSIDE_BROKER_COMMISSION_SUCCESS,
  CREATE_OUTSIDE_BROKER_COMMISSION_SUCCESS,
  DELETE_OUTSIDE_BROKER_COMMISSION_SUCCESS,
  UPDATE_OUTSIDE_BROKER_COMMISSION_VENDOR_SUCCESS,
} from './action-types';
import { updateFinancials } from './financial-actions';

// action creators
const fetchCommissionsSuccess = commissions => ({
  type: FETCH_OUTSIDE_BROKER_COMMISSIONS_SUCCESS,
  payload: commissions,
});

const updateCommissionSuccess = commission => ({
  type: UPDATE_OUTSIDE_BROKER_COMMISSION_SUCCESS,
  payload: commission,
});

const createCommissionSuccess = commission => ({
  type: CREATE_OUTSIDE_BROKER_COMMISSION_SUCCESS,
  payload: commission,
});

const deleteCommissionSuccess = id => ({
  type: DELETE_OUTSIDE_BROKER_COMMISSION_SUCCESS,
  payload: id,
});

const updateCommissionVendorSuccess = (id, vendor) => ({
  type: UPDATE_OUTSIDE_BROKER_COMMISSION_VENDOR_SUCCESS,
  payload: { id, vendor },
});

// async action creators
const fetchCommissions = params => dispatch => commissionsService.fetchCommissions(params)
  .then(commissions => dispatch(fetchCommissionsSuccess(commissions)))
  .catch(console.error);

const updateCommission = (id, data) => dispatch => commissionsService.updateCommission(id, data)
  .then((commission) => {
    if ('paid_by_cbre' in data || 'voucher_gross_commission_percent' in data || 'gross_commission' in data) {
      dispatch(updateFinancials(commission.transaction, 'paid_by_cbre' in data));
    }
    dispatch(updateCommissionSuccess(commission));
  })
  .catch(console.error);

const createCommission = params => dispatch => companyService.createCompany({
  address: null,
  address2: null,
  city: null,
  country: 'United States',
  name: null,
  postal: null,
  territory: null,
})
  .then(company => commissionsService.createCommission({ vendor: company.id, ...params }))
  .then(commission => dispatch(createCommissionSuccess(commission)))
  .catch(console.error);

const deleteCommission = id => (dispatch, getState) => commissionsService.deleteCommission(id)
  .then(() => {
    dispatch(updateFinancials(getState().deal.deal.id));
    dispatch(deleteCommissionSuccess(id));
  })
  .catch(console.error);

const updateCommissionVendor = (id, companyId, patch) => dispatch => companyService.patchCompany(companyId, patch)
  .then(res => dispatch(updateCommissionVendorSuccess(id, res)))
  .catch(console.error);


export {
  fetchCommissions,
  updateCommission,
  createCommission,
  deleteCommission,
  updateCommissionVendor,
};
