import { connect } from 'react-redux';

// actions
import {
  updateConsiderationsInModal,
  fetchLeaseConsiderationTypeOptions,
} from '../../../redux/actions/voucher/considerations-actions';
import { updateVoucher } from '../../../redux/actions/voucher/voucher-actions';

import Considerations from './considerations';

const mapStateToProps = state => ({
  considerations: state.considerations,
  tempConsiderations: state.tempConsiderations,
  leaseConsiderationTypeOptions: state.voucher.options.leaseConsiderationTypeOptions,
  voucher: state.voucher.voucher,
});

const mapDispatchToProps = {
  updateConsiderationsInModal,
  fetchLeaseConsiderationTypeOptions,
  updateVoucher,
};

const ConsiderationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Considerations);

export default ConsiderationsContainer;
