import alertsService from '../../services/alerts-service';

// Task Alerts Action Creators
const loadTaskAlertsSuccess = taskAlerts => ({
  type: 'LOAD_TASK_ALERTS_SUCCESS',
  payload: { taskAlerts },
});

const loadTaskAlertsFailure = error => ({
  type: 'LOAD_TASK_ALERTS_FAILURE',
  payload: { taskAlertsError: error },
});

const loadTaskAlertsNextPage = taskAlerts => ({
  type: 'LOAD_TASK_ALERTS_NEXT_PAGE',
  payload: { taskAlerts },
});

const loadTaskAlertsMetadata = metadata => ({
  type: 'LOAD_TASK_ALERTS_METADATA',
  payload: { metadata },
});

const isLoadingTaskAlerts = loadingTaskAlerts => ({
  type: 'IS_LOADING_TASK_ALERTS',
  payload: { loadingTaskAlerts },
});

const markTasksAsReadFailure = error => ({
  type: 'MARK_TASKS_AS_READ_FAILURE',
  payload: { markTasksAsReadError: error },
});

const markTasksAsReadPending = alertIDs => ({
  type: 'MARK_TASKS_AS_READ_PENDING',
  payload: { alertIDs },
});

// Notification Alerts Action Creators
const loadNotificationsSuccess = notifications => ({
  type: 'LOAD_NOTIFICATIONS_SUCCESS',
  payload: { notifications },
});

const loadNotificationsFailure = error => ({
  type: 'LOAD_NOTIFICATIONS_FAILURE',
  payload: { notificationsError: error },
});

const loadNotificationsNextPage = notifications => ({
  type: 'LOAD_NOTIFICATIONS_NEXT_PAGE',
  payload: { notifications },
});

const loadNotificationsMetadata = metadata => ({
  type: 'LOAD_NOTIFICATIONS_METADATA',
  payload: { metadata },
});

const isLoadingNotifications = loadingNotifications => ({
  type: 'IS_LOADING_NOTIFICATIONS',
  payload: { loadingNotifications },
});

const markNotificationsAsReadFailure = error => ({
  type: 'MARK_NOTIFICATIONS_AS_READ_FAILURE',
  payload: { markNotificationsAsReadError: error },
});

const markNotificationsAsReadPending = alertIDs => ({
  type: 'MARK_NOTIFICATIONS_AS_READ_PENDING',
  payload: { alertIDs },
});

// All Alert Action Creators
const snoozeSuccess = (alertID, snoozeMessage) => ({
  type: 'SNOOZE_SUCCESS',
  payload: { alertID, snoozeMessage },
});

const snoozeFailure = error => ({
  type: 'SNOOZE_FAILURE',
  payload: { error },
});

const clearSnoozeMessage = () => ({
  type: 'CLEAR_SNOOZE_MESSAGE',
});

const dismissSuccess = (alertID, dismissMessage) => ({
  type: 'DISMISS_SUCCESS',
  payload: { alertID, dismissMessage },
});

const dismissFailure = error => ({
  type: 'DISMISS_FAILURE',
  payload: { error },
});

const clearDismissMessage = () => ({
  type: 'CLEAR_DISMISS_MESSAGE',
});

// Async Actions -- Tasks
const loadTaskAlerts = taskParams => (dispatch) => {
  dispatch(isLoadingTaskAlerts(true));

  const params = {
    ...taskParams,
    page_size: 25,
    page: 1,
    content_type: 'task',
  };

  if (taskParams && taskParams.page) {
    params.page = taskParams.page;
  }

  alertsService.fetchAlerts(params)
    .then((data) => {
      // data comes back with an array of objects for unread_counts; user, transaction, task.
      // { unread_count:[{transaction:1},{user:0},{task:12}]}
      const metadata = {
        unread: data.unread_counts,
        next: data.next,
      };
      if (params.page > 1) {
        // handle pagination
        // handle no results
        if (!data.results) {
          metadata.next = null;
          metadata.unread = [];
        }
        dispatch(loadTaskAlertsNextPage(data.results));
      } else {
        dispatch(loadTaskAlertsSuccess(data.results));
      }
      dispatch(loadTaskAlertsMetadata(metadata));
    })
    .then(() => dispatch(isLoadingTaskAlerts(false)))
    .catch(error => dispatch(loadTaskAlertsFailure(error)));
};

const loadNextTaskAlert = taskParams => (dispatch) => {
  const params = {
    ...taskParams,
    page_size: 1,
    page: 1,
    content_type: 'task',
  };

  if (taskParams && taskParams.page) {
    params.page = taskParams.page;
  }
  if (taskParams && taskParams.page_size) {
    params.page_size = taskParams.page_size;
  }

  return alertsService.fetchAlerts(params)
    .then((data) => {
      // data comes back with an array of objects for unread_counts; user, transaction, task.
      // { unread_count:[{transaction:1},{user:0},{task:12}]}
      const metadata = {
        unread: data.unread_counts,
        next: undefined,
      };
      if (data.detail === 'Invalid Page') {
        metadata.next = null;
        metadata.unread = [];
        return dispatch(loadTaskAlertsMetadata(metadata));
      }
      dispatch(loadTaskAlertsNextPage(data.results));
      if (!data.next) {
        metadata.next = null;
      }
      return dispatch(loadTaskAlertsMetadata(metadata));
    })
    .then(() => dispatch(isLoadingTaskAlerts(false)))
    .catch(error => dispatch(loadTaskAlertsFailure(error)));
};

const markTasksAsRead = alertIDs => (dispatch) => {
  if (alertIDs.length > 0) {
    dispatch(markTasksAsReadPending(alertIDs));
  }
  dispatch(loadTaskAlertsMetadata({ unread: [{ task: 0 }] }));

  alertsService.markBatchAsRead(alertIDs)
    .then(() => {
      // upon successfull POST to mark_as_read/ load all alerts
      // get the first page
      dispatch(loadTaskAlerts());
    })
    .catch((error) => {
      dispatch(markTasksAsReadFailure(error));
      dispatch(loadTaskAlerts());
    });
};

// Async Actions -- Notifications
const loadNotifications = notificationsParams => (dispatch) => {
  dispatch(isLoadingNotifications(true));
  const params = {
    ...notificationsParams,
    page: 1,
    page_size: 25,
    content_type: 'user,transaction,audittrail,team',
  };

  if (notificationsParams && notificationsParams.page) {
    params.page = notificationsParams.page;
  }

  alertsService.fetchAlerts(params)
    .then((data) => {
      // data comes back with an array of objects for unread_counts; user, transaction, task.
      // { unread_count:[{transaction:1},{user:0},{task:12}]}
      const metadata = {
        unread: data.unread_counts,
        next: data.next,
      };
      // handle pagination
      if (params.page > 1) {
        if (!data.results) {
          metadata.unread = [];
          metadata.next = null;
        }
        dispatch(loadNotificationsNextPage(data.results));
      } else {
        dispatch(loadNotificationsSuccess(data.results));
      }
      dispatch(loadNotificationsMetadata(metadata));
    })
    .then(() => dispatch(isLoadingNotifications(false)))
    .catch(error => dispatch(loadNotificationsFailure(error)));
};

const loadNextNotificationAlert = notificationsParams => (dispatch) => {
  const params = {
    ...notificationsParams,
    page: 1,
    page_size: 1,
    content_type: 'user,transaction,audittrail,team',
  };

  if (notificationsParams && notificationsParams.page) {
    params.page = notificationsParams.page;
  }
  if (notificationsParams && notificationsParams.page_size) {
    params.page_size = notificationsParams.page_size;
  }

  return alertsService.fetchAlerts(params)
    .then((data) => {
      // data comes back with an array of objects for unread_counts; user, transaction, task.
      // { unread_count:[{transaction:1},{user:0},{task:12},{audittrail:2},{team:2}]}
      const metadata = {
        unread: data.unread_counts,
        next: undefined,
      };
      if (data.detail === 'Invalid Page') {
        metadata.next = null;
        metadata.unread = [];
        return dispatch(loadNotificationsMetadata(metadata));
      }
      dispatch(loadNotificationsNextPage(data.results));
      if (!data.next) {
        metadata.next = null;
      }
      return dispatch(loadNotificationsMetadata(metadata));
    })
    .then(() => dispatch(isLoadingNotifications(false)))
    .catch(error => dispatch(loadNotificationsFailure(error)));
};

const markNotificationsAsRead = alertIDs => (dispatch) => {
  if (alertIDs.length > 0) {
    dispatch(markNotificationsAsReadPending(alertIDs));
  }
  dispatch(loadNotificationsMetadata({ unread: [{ user: 0 }, { transaction: 0 }, { audittrail: 0 }, { team: 0 }] }));

  const formattedAlertIDs = alertIDs.join(',');

  alertsService.markBatchAsRead(formattedAlertIDs)
    .then(() => {
      // upon successfull POST to mark_as_read/ load all alerts
      // get the first page
      dispatch(loadNotifications());
    })
    .catch((error) => {
      dispatch(markNotificationsAsReadFailure(error));
      dispatch(loadNotifications());
    });
};

// Async Actions -- all
const snooze = (alertID, alertDatetime, snoozeMessage) => dispatch =>
  alertsService.dismissAlert(alertID, alertDatetime)
    .then(() => dispatch(snoozeSuccess(alertID, snoozeMessage)))
    .catch(error => dispatch(snoozeFailure({ ...error, snoozeMessage: 'Failed to Snooze Task, please try again.' })));

const snoozeAndLoadNextNotification = (alertID, alertDatetime, snoozeMessage, page) => dispatch =>
  dispatch(snooze(alertID, alertDatetime, snoozeMessage))
    .then(() => dispatch(loadNextNotificationAlert({ page, page_size: 1 })))
    .catch(error => error);

const snoozeAndLoadNextTask = (alertID, alertDatetime, snoozeMessage, page) => dispatch =>
  dispatch(snooze(alertID, alertDatetime, snoozeMessage))
    .then(() => dispatch(loadNextTaskAlert({ page, page_size: 1 })))
    .catch(error => error);

const completeTask = alertID => dispatch =>
  alertsService.dismissAlert(alertID)
    .then(() => dispatch(dismissSuccess(alertID, 'Task Completed')))
    .catch(error => dispatch(dismissFailure({ ...error, dismissMessage: 'Failed to complete task, please try again.' })));

const dismissTaskAlert = alertID => dispatch =>
  alertsService.dismissAlert(alertID)
    .then(() => dispatch(dismissSuccess(alertID, 'Task Alert Deleted')))
    .catch(error => dispatch(dismissFailure({ ...error, dismissMessage: 'Failed to delete task alert, please try again.' })));

const dismissNotification = alertID => dispatch =>
  alertsService.dismissAlert(alertID)
    .then(() => dispatch(dismissSuccess(alertID, 'Notification Deleted')))
    .catch(error => dispatch(dismissFailure({ ...error, dismissMessage: 'Failed to delete notification, please try again.' })));

const dismissAllNotification = () => dispatch =>
  alertsService.dismissAllAlert()
    .then(() => dispatch(dismissSuccess('All Notification(s) Deleted')))
    .catch(error => dispatch(dismissFailure({ ...error, dismissMessage: 'Failed to delete notification, please try again.' })));

const dismissAndLoadNextNotification = (alertID, page) => dispatch =>
  dispatch(dismissNotification(alertID))
    .then(() => dispatch(loadNextNotificationAlert({ page, page_size: 1 })))
    .catch(error => error);

const dismissAndLoadNextTask = (alertID, page) => dispatch =>
  dispatch(completeTask(alertID))
    .then(() => dispatch(loadNextTaskAlert({ page, page_size: 1 })))
    .catch(error => error);

export {
  loadTaskAlerts,
  loadNextTaskAlert,
  markTasksAsRead,
  markTasksAsReadPending,
  loadNotifications,
  markNotificationsAsRead,
  markNotificationsAsReadPending,
  snooze,
  snoozeSuccess,
  clearSnoozeMessage,
  completeTask,
  dismissTaskAlert,
  dismissNotification,
  dismissAllNotification,
  dismissSuccess,
  clearDismissMessage,
  snoozeAndLoadNextNotification,
  dismissAndLoadNextNotification,
  snoozeAndLoadNextTask,
  dismissAndLoadNextTask,
  isLoadingTaskAlerts,
  isLoadingNotifications,
};
