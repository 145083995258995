import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ContactForm from '../../../contact-form/contact-form';
import ContactListItemDetails from './contact-list-item-details';
import ContactListItemHeader from './contact-list-item-header';
import ExpandableComponent from '../../../../nucleus/expandable-component/expandable-component';

const ContactListItem = ({
  allContactTags,
  analyticProperties,
  contact,
  dealId,
  enablePrimaryButton,
  isContactsTab,
  onCancel,
  onDelete,
  onEdit,
  onSubmit,
  onTagClick,
  onUpdate,
  outlookSyncEnabled,
}) => {
  const itemRef = React.createRef();
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenToggle = () => {
    setIsOpen(!isOpen);
  };

  const [isEdit, setisEdit] = useState(false);
  const handleEditToggle = () => {
    setisEdit(!isEdit);
    setIsOpen(true);
    onEdit();
  };

  const scrollToItem = () => {
    const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
    if (isSmoothScrollSupported) {
      itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      itemRef.current.scrollIntoView(true);
    }
  };

  const handleCancel = () => {
    setisEdit(false);
    onCancel();
    scrollToItem();
  };

  const handleSubmit = (...args) => {
    setisEdit(false);
    onSubmit(...args);
    scrollToItem();
  };

  const addTag = (newTag) => {
    onUpdate(contact.id, {
      tags: [...contact.tags.filter(tag => tag !== newTag), newTag],
    });
  };

  const removeTag = (tagToRemove) => {
    onUpdate(contact.id, {
      tags: [...contact.tags.filter(tag => tag !== tagToRemove)],
    });
  };

  return (
    <div className="contact-list-item" ref={itemRef}>
      <ContactListItemHeader
        contact={contact}
        isEdit={isEdit}
        isOpen={isOpen}
        onEdit={handleEditToggle}
        onOpen={handleOpenToggle}
      />
      <ExpandableComponent isOpen={isOpen} heightOffset={20}>
        {!isEdit ? (
          <ContactListItemDetails
            allContactTags={allContactTags}
            contact={contact}
            isContactsTab={isContactsTab}
            onAddTag={addTag}
            onEdit={handleEditToggle}
            onRemoveTag={removeTag}
            onTagClick={onTagClick}
          />
        ) :
          (
            <ContactForm
              allContactTags={allContactTags}
              analyticProperties={analyticProperties}
              contact={contact}
              dealId={dealId}
              outlookSyncEnabled={outlookSyncEnabled}
              onCancel={handleCancel}
              onDelete={onDelete}
              onSubmit={handleSubmit}
              enablePrimaryDealButton={enablePrimaryButton}
            />
          )
        }
      </ExpandableComponent>
    </div>
  );
};

ContactListItem.propTypes = {
  analyticProperties: PropTypes.shape({
    actionPrefix: PropTypes.string,
    categoryPrefix: PropTypes.string,
  }).isRequired,
  allContactTags: PropTypes.arrayOf(PropTypes.string),
  contact: PropTypes.shape({
    id: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  dealId: PropTypes.number,
  enablePrimaryButton: PropTypes.bool,
  isContactsTab: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onTagClick: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  outlookSyncEnabled: PropTypes.bool,
};

ContactListItem.defaultProps = {
  allContactTags: [],
  dealId: undefined,
  enablePrimaryButton: false,
  isContactsTab: undefined,
  onTagClick: () => { },
  onUpdate: () => { },
  outlookSyncEnabled: false,
};

export default ContactListItem;
