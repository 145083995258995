import DealDetails from './redux/containers/deal-detail-container';
import VouchersSearch from './page-templates/vouchers/voucher-search-page';
import VouchersDetail from './page-templates/vouchers/voucher-detail-page';
import VoucherFiles from './page-templates/voucher/voucher-files-page';
import VoucherSubmission from './page-templates/voucher/voucher-submission';
import PipelineReport from './page-templates/pipeline-report/pipeline-report-page-container';
import PropertiesList from './page-templates/properties/properties-list-container';
import PropertyDetails from './page-templates/property/property-detail-page-container';
import S3FileDownload from './page-templates/file-download/s3-file-download-page';
import DealList from './page-templates/deals/deal-list-page-container';
import ProfilePage from './page-templates/profile/profile-page-container';
import DashboardPage from './page-templates/dashboard/dashboard-container';
import TasksPage from './page-templates/tasks/tasks-page-container';
import MyContacts from './page-templates/contacts/my-contacts-page-container';
import OutlookSync from './page-templates/contacts/outlook-sync-page';
import Commissions from './page-templates/commissions/commissions-page-container';
import Support from './page-templates/support/support-page';
import Agreement from './page-templates/agreement/agreement-page';
import Appstore from './page-templates/appstore/appstore-page';
import NotFound from './page-templates/not-found/not-found-page';
import Admin from './page-templates/admin/admin-page-container';
import ExternalDealSuggest from './page-templates/external-deal-suggest/external-deal-suggest-page';
import LayoutTemplates from './page-templates/layout-templates/layout-templates';
import SPOCSearch from './page-templates/spoc-search/spoc-search';
import Tools from './page-templates/tools/tools-page';

const routes = [
  {
    path: '/dashboard',
    exact: true,
    component: DashboardPage,
  },
  {
    path: '/deals/:type',
    exact: true,
    component: DealList,
  },
  {
    path: '/deals/detail/:id',
    component: DealDetails,
  },
  {
    path: '/commissions/:subroute',
    exact: true,
    component: Commissions,
  },
  {
    path: '/tools',
    exact: true,
    component: Tools,
  },
  // paths prefixed for tools are utilized when
  // spoc_search FF is turned off
  {
    path: '/tools/vouchers',
    exact: true,
    component: VouchersSearch,
  },
  {
    path: '/tools/vouchers/detail/:id',
    component: VouchersDetail,
  },
  // paths without the tools prefixd are utilized when
  // spoc_search FF is turned on
  {
    path: '/vouchers',
    exact: true,
    component: VouchersSearch,
  },
  {
    path: '/vouchers/detail/:id',
    component: VouchersDetail,
  },
  {
    path: '/voucher/files_download/:id',
    component: VoucherFiles,
  },
  {
    path: '/voucher/:id/:progress_tab/:subtab?',
    component: VoucherSubmission,
  },
  {
    path: '/spoc-search',
    component: SPOCSearch,
    exact: true,
  },
  {
    path: '/my-contacts',
    exact: true,
    component: MyContacts,
  },
  {
    path: '/my-contacts/outlook-sync',
    exact: true,
    component: OutlookSync,
  },
  {
    path: '/properties',
    exact: true,
    component: PropertiesList,
  },
  {
    path: '/properties/detail/:id/:tab?',
    component: PropertyDetails,
  },
  {
    path: '/file_download/:key',
    component: S3FileDownload,
  },
  {
    path: '/pipeline',
    exact: true,
    component: PipelineReport,
  },
  {
    path: '/profile',
    exact: false,
    component: ProfilePage,
  },
  {
    path: '/admin',
    exact: false,
    component: Admin,
  },
  {
    path: '/tasks',
    exact: true,
    component: TasksPage,
  },
  {
    path: '/support',
    exact: false,
    component: Support,
  },
  {
    path: '/agreement',
    exact: true,
    component: Agreement,
  },
  {
    path: '/appstore',
    exact: true,
    component: Appstore,
  },
  {
    path: '/external-deal-suggest/',
    component: ExternalDealSuggest,
  },
  {
    path: '/layout-templates/',
    component: LayoutTemplates,
  },
  {
    component: NotFound,
  },
];

export default routes;
