import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectTotalCommissions, selectAllocationsTotal,
  selectSharedFees, selectOutsideBrokerCommissions, selectRebates, selectCommissions, selectInstallments,
  selectCommissionsGrossCommissionTotal, selectOutsideBrokerCommissionGrossCommissionTotal, selectRebatesGrossCommissionTotal,
} from '../../../redux/selectors/voucher-selectors';
import Currency from '../../../nucleus/formats/currency';
import BreakdownItem from './BreakdownItem';
import BreakdownSectionSummary from './BreakdownSectionSummary';


const commissionsNameMap = {
  employee: item => (item.broker ? `${item?.broker?.first_name} ${item?.broker?.last_name}` : ''),
  office: item => item.voucher_office_allocation_name,
  pool: item => item.voucher_cbre_pool_name,
};

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

const FinancialBreakdown = () => {
  const dealType = useSelector(state => state.deal?.deal?.deal_type);
  const totalCommissions = useSelector(selectTotalCommissions);
  const totalAllocated = useSelector(selectAllocationsTotal);
  const outsideBrokers = useSelector(selectOutsideBrokerCommissions);
  const outsideBrokersTotal = useSelector(selectOutsideBrokerCommissionGrossCommissionTotal);
  const sharedFees = useSelector(selectSharedFees);
  const rebates = useSelector(selectRebates);
  const rebatesTotal = useSelector(selectRebatesGrossCommissionTotal);
  const commissions = useSelector(selectCommissions);
  const commissionsTotal = useSelector(selectCommissionsGrossCommissionTotal);
  const installments = useSelector(selectInstallments).filter(i => i.installment_amount);

  return (
    <div className="side-card financial-breakdown">
      <div className="side-card-header">Financial Breakdown</div>
      <div className="side-card-content">
        <div className="total-header-section">
          <div className="total-box-commissions">
            <div className="total-box-label">Total Commissions</div>
            <Currency
              value={totalCommissions}
              emptyState="$0.00"
            />
          </div>
          <div className={`total-box-allocations ${totalCommissions === totalAllocated ? 'completed-allocations' : ''}`}>
            <div className="total-box-label">Total Allocated</div>
            <Currency
              value={totalAllocated}
              emptyState="$0.00"
            />
          </div>
        </div>
        <span />
      </div>
      {outsideBrokers.length !== 0 && (
        <>
          <h3 className="financial-breakdown-section-header">Outside Brokers</h3>
          {outsideBrokers.map(item => (
            <BreakdownItem
              key={item.id}
              name={item.outside_broker_name}
              subname={!item.paid_by_cbre && '(Direct Pay)'}
              value={item.gross_commission}
              percent={item.voucher_gross_commission_percent}
            />
          ))}
          <BreakdownSectionSummary
            reducedBy={outsideBrokersTotal}
            remaining={totalCommissions - outsideBrokersTotal}
          />
        </>
      )}
      {sharedFees.length !== 0 && (
        <>
          <h3 className="financial-breakdown-section-header">Fee Share</h3>
          {sharedFees.map(item => (
            <BreakdownItem
              key={item.id}
              name={`${item.voucher_from_dept || '- No entry -'} to ${item.voucher_to_dept || '- No entry -'}`}
              value={item.gross_commission}
              percent={item.voucher_gross_commission_percent}
            />
          ))}
          <BreakdownSectionSummary
            reducedBy={0}
            remaining={totalCommissions - outsideBrokersTotal}
          />
        </>
      )}
      {rebates.length !== 0 && (
        <>
          <h3 className="financial-breakdown-section-header">Rebates</h3>
          {rebates.map(item => (
            <BreakdownItem
              key={item.id}
              name={item.rebate_client_name}
              value={item.gross_commission}
              percent={item.voucher_gross_commission_percent}
            />
          ))}
          <BreakdownSectionSummary
            reducedBy={rebatesTotal}
            remaining={totalCommissions - (outsideBrokersTotal + rebatesTotal)}
          />
        </>
      )}
      {commissions.length !== 0 && (
        <>
          <h3 className="financial-breakdown-section-header">Commissions</h3>
          {commissions.map(item => (
            <BreakdownItem
              key={item.id}
              name={commissionsNameMap[item.cbre_broker_type](item)}
              value={item.gross_commission}
              percent={item.voucher_gross_commission_percent}
            />
          ))}
          <BreakdownSectionSummary
            reducedBy={commissionsTotal}
            remaining={totalCommissions - (outsideBrokersTotal + rebatesTotal + commissionsTotal)}
          />
        </>
      )}
      {installments.length !== 0 && (
        <>
          <h3 className="financial-breakdown-section-header financial-breakdown-section-border">Installments</h3>
          {installments.map(item => (
            <BreakdownItem
              key={item.id}
              name={dealType === 'lease'
                ? item.voucher_contingency_code
                : item.installment_date && new Date(item.installment_date).toLocaleDateString(undefined, dateOptions)
              }
              value={item.installment_amount}
              percent={item.voucher_installment_percent}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default FinancialBreakdown;
