import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageHeaderDefault from '../../nucleus/header/page-header-default';
import transactionsService from '../../services/transactions-service';
import userEventService from '../../services/user-event-service';
import VoucherDetailProducers from '../../components/voucher-search/voucher-detail-producers';
import VoucherDetailProperty from '../../components/voucher-search/voucher-detail-property';
import VoucherDetailInformation from '../../components/voucher-search/voucher-detail-information';

class VouchersDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction: undefined,
    };
  }

  componentDidMount() {
    transactionsService.fetchTransaction(this.props.match.params.id)
      .then((data) => {
        if (data.error && data.error === 404) {
          window.location.replace('/notfound');
        }
        this.setState({
          transaction: data,
        });
      });
    document.title = 'Deal IQ | Voucher';
  }

  trackBackButtonClicked = () => {
    const eventMetadata = {
      eventCategory: 'Voucher Search',
      eventLabel: 'onClick',
      eventAction: 'back_to_voucher_search_clicked',
    };
    userEventService.trackEvent(eventMetadata);
  }

  render() {
    const loadingState = '';
    const { transaction } = this.state;

    const renderContent = () => {
      const {
        name,
        deal_id,
        cbre_brokers,
        final_property,
        price_per_sqft,
        price,
        deal_type,
        lease_rate_type,
        square_feet,
        property_type,
      } = transaction;

      const subtitle = `Deal ID: ${deal_id}`;
      const controls = <Link to="/vouchers" onClick={this.trackBackButtonClicked} className="button-link">Back to voucher search</Link>;

      return (
        <React.Fragment>
          <PageHeaderDefault
            title={name}
            subtitle={subtitle}
            controls={controls}
          />
          <div className="voucher-detail-content">
            <div className="left-column">
              <VoucherDetailInformation transaction={transaction} />
              <VoucherDetailProducers brokers={cbre_brokers} />
            </div>
            <VoucherDetailProperty
              squareFeet={square_feet}
              property={final_property}
              dealType={deal_type}
              pricePerSqFt={price_per_sqft}
              price={price}
              leaseRateType={lease_rate_type}
              propertyType={property_type}
            />
          </div>
        </React.Fragment>
      );
    };

    const pageContent = !transaction || !transaction.id
      ? loadingState
      : renderContent();

    return pageContent;
  }
}

VouchersDetailPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
    }).isRequired,
  }).isRequired,
};

export default VouchersDetailPage;
