import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@cbrebuild/blocks';
import PercentDollarInput from '../../../nucleus/inputs/PercentDollarInput';
import Select from '../../../nucleus/select/select';
import { updateVoucher } from '../../../redux/actions/voucher/voucher-actions';
import { updateDealPipelineTimestamp } from '../../../redux/actions/deal-actions';
import useFormState, { EVENT_VALUE_TYPES } from '../../../hooks/useFormState';
import { deleteSharedFee, updateSharedFee } from '../../../redux/actions/shared-fees-actions';
import FormGroup from '../../../nucleus/form-group/form-group';
import { selectTotalCommissionsForSharedFees } from '../../../redux/selectors/voucher-selectors';

const selectSharedFeeById = (state, id) => (
  state.sharedFees.sharedFees.find(fee => fee.id === id)
);

const typeOverrides = {
  voucher_gross_commission_percent: EVENT_VALUE_TYPES.FLOAT,
  gross_commission: EVENT_VALUE_TYPES.FLOAT,
};

const FeeSharingItem = ({
  id,
  dealId,
  voucherId,
  showValidation,
  fee_sharing_is_percent,
}) => {
  const sharedFee = useSelector(state => selectSharedFeeById(state, id));
  const baseTotal = useSelector(selectTotalCommissionsForSharedFees);
  const { voucherFromDeptOptions, voucherToDeptOptions } = useSelector(state => ({
    voucherFromDeptOptions: state.options.voucher_from_dept,
    voucherToDeptOptions: state.options.voucher_to_dept,
  }));
  const dispatch = useDispatch();
  const {
    voucher_rem_employee_id, voucher_from_dept, voucher_to_dept, gross_commission, voucher_gross_commission_percent,
    onStateChange, onStateChanged,
  } = useFormState({
    voucher_rem_employee_id: sharedFee.voucher_rem_employee_id,
    voucher_from_dept: sharedFee.voucher_from_dept,
    voucher_to_dept: sharedFee.voucher_to_dept,
    gross_commission: sharedFee.gross_commission,
    voucher_gross_commission_percent: sharedFee.voucher_gross_commission_percent,
  }, changes => dispatch(updateSharedFee(id, changes)), typeOverrides);

  const onUpdateVoucher = ({ target: { name, value } }) => {
    dispatch(updateVoucher(voucherId, { [name]: value }));
    dispatch(updateDealPipelineTimestamp(dealId));
  };

  const onDelete = () => {
    dispatch(deleteSharedFee(id));
  };

  const invalidVoucherFromDept = (showValidation || voucher_from_dept.dirty) && !voucher_from_dept.value;
  const invalidVoucherToDept = (showValidation || voucher_to_dept.dirty) && !voucher_to_dept.value;
  const invalidGrossCommission = (showValidation || gross_commission.dirty) && !gross_commission.value;

  return (
    <div className="form-row-with-labels-and-hints">
      <FormGroup label="From" size="large" isRequired hasError={invalidVoucherFromDept} errorMessage="Required" className="due-upon-field">
        <Select
          defaultOption={voucherFromDeptOptions.find(option => option.value === voucher_from_dept.value)}
          onChange={({ value }) => onStateChanged({
            target: { value, name: 'voucher_from_dept', type: 'text' },
          })}
          options={voucherFromDeptOptions}
        />
      </FormGroup>
      <FormGroup label="To LOB" size="large" isRequired hasError={invalidVoucherToDept} errorMessage="Required" className="due-upon-field">
        <Select
          defaultOption={voucherToDeptOptions.find(option => option.value === voucher_to_dept.value)}
          onChange={({ value }) => onStateChanged({
            target: { value, name: 'voucher_to_dept', type: 'text' },
          })}
          options={voucherToDeptOptions}
        />
      </FormGroup>
      <PercentDollarInput
        isRequired={invalidGrossCommission}
        label={`${invalidGrossCommission ? '' : '* '}amount`}
        name={{
          percentage: 'voucher_gross_commission_percent',
          value: 'gross_commission',
          select: 'fee_sharing_is_percent',
        }}
        className={invalidGrossCommission ? 'errorInput' : ''}
        onTypeChange={onUpdateVoucher}
        onChange={onStateChange}
        onBlur={onStateChanged}
        baseTotal={baseTotal}
        percentage={voucher_gross_commission_percent.value}
        value={gross_commission.value}
        isPercent={fee_sharing_is_percent}
      />
      <FormGroup label="REM EMP ID" size="large" className="due-upon-field" hasError={false}>
        <input
          type="text"
          name="voucher_rem_employee_id"
          value={voucher_rem_employee_id.value || ''}
          onChange={onStateChange}
          onBlur={onStateChanged}
        />
      </FormGroup>
      <IconButton className="blxs-button-icon-small" iconName="close-circle" onClick={onDelete} variant="basic" />
    </div>
  );
};

FeeSharingItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  showValidation: PropTypes.bool.isRequired,
  dealId: PropTypes.number.isRequired,
  voucherId: PropTypes.number.isRequired,
  fee_sharing_is_percent: PropTypes.bool.isRequired,
};

export default FeeSharingItem;
