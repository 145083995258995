import React from 'react';
import PropTypes from 'prop-types';

import Currency from '../../../../nucleus/formats/currency';

const CommissionsDetailOtherCommissions = props => (
  <table>
    <colgroup>
      <col name="commission type" width="25%" />
      <col name="units" width="25%" />
      <col name="commission amount" width="25%" />
      <col name="gross commission" width="25%" />
    </colgroup>
    <tbody>
      <tr className="borderless-row">
        <th>Commission Type</th>
        <th>Units</th>
        <th>Commission Amount</th>
        <th>Gross Commission</th>
      </tr>
      {props.additionalCommissions.map(comm => (
        <tr key={comm.id}>
          <td>{comm.acommission_type}</td>
          <td>{comm.total_units}</td>
          <td><Currency value={comm.amount} /></td>
          <td><Currency value={comm.commission_amount} /></td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default CommissionsDetailOtherCommissions;

CommissionsDetailOtherCommissions.propTypes = {
  additionalCommissions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
