import superFetch from '../utils/super-fetch';


const getS3DownloadUrl = (key, filename) => {
  const url = `/file_download/${key}`;
  const params = { filename };
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json());
};

export default getS3DownloadUrl;
