import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Icon } from '@cbrebuild/blocks';
import voucherService from '../../services/voucher/vouchers-service';
import NDButton from '../../nucleus/button/button';
import ButtonWithFlyout from '../../nucleus/button-with-flyout/button-with-flyout';

const VoucherButton = (props) => {
  const {
    deal,
    isDisabled,
  } = props;

  const [isCurrentEarlyInvoice, setIsCurrentEarlyInvoice] = useState(false);

  const {
    id: dealId,
    voucher_submission_status: voucherSubmissionStatus,
    deal_life_cycle_stage: dealStage,
  } = deal;

  useEffect(() => {
    voucherService.fetchVoucherByDealId(dealId)
      .then((res) => {
        if (res) {
          setIsCurrentEarlyInvoice(res.is_early_invoice);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dealId]);

  // Voucher can be revised when deal is closed, but it should hide early invoice button
  const isVoucherRevise = (
    voucherSubmissionStatus === 'Voucher Submitted' ||
    voucherSubmissionStatus === 'Voucher Resubmitted' ||
    voucherSubmissionStatus === 'Voucher Completed'
  ) && dealStage === 'closed';

  const voucherButtonClass = isVoucherRevise ? 'revise-voucher' : '';

  const renderVoucherButtonTitle = () => {
    let voucherTitle = 'Voucher';
    if (isCurrentEarlyInvoice) {
      voucherTitle = 'Early Invoice';
    } else if (isVoucherRevise) {
      voucherTitle = 'Revise Voucher';
    }
    return voucherTitle;
  };

  return (
    <div className="voucher-btn-wrapper">
      <Link
        className="voucher-btn-link"
        to={{
          pathname: `/voucher/${dealId}/getting_started`,
          state: { isEarlyInvoice: isCurrentEarlyInvoice },
        }}
      >
        <Button
          className={`voucher-btn ${voucherButtonClass}`}
          disabled={isDisabled}
        >
          <Icon iconName="cash-dollars" />
          {renderVoucherButtonTitle()}
        </Button>
      </Link>
      {!isVoucherRevise &&
        <ButtonWithFlyout
          className="voucher-dropdown"
          buttonIcon="chevron-down"
          dataE2e="deal-voucher-dropdown-button"
          buttonVariant="primary"
          position="right"
          disabled={isDisabled}
        >
          <Link to={{
            pathname: `/voucher/${dealId}/getting_started`,
            state: { isEarlyInvoice: false },
          }}
          >
            <NDButton
              variant="option"
              dataE2e="deal-voucher-flyout-voucher"
              width="full"
            >
              Voucher
            </NDButton>
          </Link>
          <Link to={{
            pathname: `/voucher/${dealId}/getting_started`,
            state: { isEarlyInvoice: true },
          }}
          >
            <NDButton
              variant="option"
              dataE2e="deal-voucher-flyout-early-invoice"
              width="full"
            >
              Early Invoice
            </NDButton>
          </Link>
        </ButtonWithFlyout>
      }
    </div>
  );
};

export default VoucherButton;

VoucherButton.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.number,
    deal_life_cycle_stage: PropTypes.string,
    voucher_submission_status: PropTypes.string,
  }).isRequired,
  isDisabled: PropTypes.bool,
};

VoucherButton.defaultProps = {
  isDisabled: false,
};
