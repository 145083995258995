/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, RadioButton, RadioGroup, Textarea } from '@cbrebuild/blocks';
import useFormState, { EVENT_VALUE_TYPES } from '../../../hooks/useFormState';
import FormGroup from '../../../nucleus/form-group/form-group';
import { updateCompData } from '../../../redux/actions/voucher/comp-data-actions';
import Select from '../../../nucleus/select/select';
import NumberInput from '../../../nucleus/inputs/NumberInput';
import PercentDollarInput from '../../../nucleus/inputs/PercentDollarInput';
import Tooltip from '../../../nucleus/tooltip/tooltip';

const typeOverrides = {
  base_rent: EVENT_VALUE_TYPES.FLOAT,
  tenant_improvement_allowance_amount_per_sqft: EVENT_VALUE_TYPES,
  parking_expenses_amount_per_space: EVENT_VALUE_TYPES,
  operating_expenses_amount_per_sqft: EVENT_VALUE_TYPES,
  tax_amount: EVENT_VALUE_TYPES,
  electric_expense_amount_per_sqft: EVENT_VALUE_TYPES,
  office_area: EVENT_VALUE_TYPES,
  office_rent_amount_per_sqft: EVENT_VALUE_TYPES,
  warehouse_rent_amount_per_sqft: EVENT_VALUE_TYPES,
  clear_height: EVENT_VALUE_TYPES,
  rent_escalation_percent: EVENT_VALUE_TYPES,
  rent_escalation_dollar: EVENT_VALUE_TYPES,
  rent_escalation_is_percent: EVENT_VALUE_TYPES,
};

const LeaseComp = () => {
  const { compData, voucher } = useSelector(state => ({
    compData: state.compData,
    voucher: state.voucher.voucher,
  }));
  const dispatch = useDispatch();
  const {
    account_driven_deal,
    general_comments,
    tenant_represented_by, tenant_represented_by_agent,
    landlord_represented_by, landlord_represented_by_agent,
    base_rent, free_rent, rent_escalation_is_custom,
    rent_escalation_percent, rent_escalation_dollar, rent_escalation_is_percent,
    tenant_improvement_allowance_amount_per_sqft, parking_ratio, tenant_improvements_detail,
    parking_expenses_amount_per_space, operating_expenses_amount_per_sqft,
    tax_amount, electric_included_in_operating_expenses, electric_expense_amount_per_sqft,
    office_area, office_rent_amount_per_sqft, warehouse_rent_amount_per_sqft,
    clear_height, power_amps, power_volts, gl_door_count, dh_door_count,
    truckwell_count, cross_dock_count, rail_serviced,
    onStateChange, onStateChanged,
  } = useFormState({
    account_driven_deal: compData?.account_driven_deal,
    general_comments: compData?.general_comments,
    tenant_represented_by: compData?.tenant_represented_by,
    tenant_represented_by_agent: compData?.tenant_represented_by_agent,
    landlord_represented_by: compData?.landlord_represented_by,
    landlord_represented_by_agent: compData?.landlord_represented_by_agent,
    base_rent: compData?.base_rent,
    free_rent: compData?.free_rent,
    rent_escalation_is_custom: compData?.rent_escalation_is_custom,
    rent_escalation_percent: compData?.rent_escalation_percent,
    rent_escalation_dollar: compData?.rent_escalation_dollar,
    rent_escalation_is_percent: compData?.rent_escalation_is_percent,
    tenant_improvement_allowance_amount_per_sqft: compData?.tenant_improvement_allowance_amount_per_sqft,
    parking_ratio: compData?.parking_ratio,
    tenant_improvements_detail: compData?.tenant_improvements_detail,
    parking_expenses_amount_per_space: compData?.parking_expenses_amount_per_space,
    operating_expenses_amount_per_sqft: compData?.operating_expenses_amount_per_sqft,
    tax_amount: compData?.tax_amount,
    electric_included_in_operating_expenses: compData?.electric_included_in_operating_expenses,
    electric_expense_amount_per_sqft: compData?.electric_expense_amount_per_sqft,
    office_area: compData?.office_area,
    office_rent_amount_per_sqft: compData?.office_rent_amount_per_sqft,
    warehouse_rent_amount_per_sqft: compData?.warehouse_rent_amount_per_sqft,
    clear_height: compData?.clear_height,
    power_amps: compData?.power_amps,
    power_volts: compData?.power_volts,
    gl_door_count: compData?.gl_door_count,
    dh_door_count: compData?.dh_door_count,
    truckwell_count: compData?.truckwell_count,
    cross_dock_count: compData?.cross_dock_count,
    rail_serviced: compData?.rail_serviced,
  }, changes => dispatch(updateCompData(compData.id, changes)), typeOverrides);

  const yesNoOptions = [
    { display_name: 'Yes', value: 'Yes' },
    { display_name: 'No', value: 'No' },
  ];

  const leaseEscalationOptions = [
    { display_name: 'Fixed', value: 'false' },
    { display_name: 'Custom', value: 'true' },
  ];

  const tenantImprovementDetailOptions = [
    { display_name: 'Allowance', value: 'Allowance' },
    { display_name: 'As Is', value: 'As Is' },
    { display_name: 'Spec Suite', value: 'Spec Suite' },
    { display_name: 'Turn Key', value: 'Turn Key' },
  ];

  const electricExpenseIsRequired = electric_included_in_operating_expenses.value === false
    || electric_included_in_operating_expenses.value === 'false';

  const infoToolTip = (
    <Tooltip message="0 is a valid value.">
      <Icon iconName="info-circle" />
    </Tooltip>
  );
  const infoToolTipDollar = (
    <Tooltip message="$0.00 is a valid value.">
      <Icon iconName="info-circle" />
    </Tooltip>
  );
  return (
    <div className="lease-comp-sections-wrapper">
      <div>
        <h3>General Information</h3>
        <div>
          <div className="field-group">
            <div className="form-row-with-labels-and-hints">
              <FormGroup
                label="Tenant Represented By"
                size="large"
                isRequired={!voucher.is_early_invoice}
                hasError={!tenant_represented_by.value}
                errorMessage="Tenant Represented By is required"
              >
                <input
                  type="text"
                  name="tenant_represented_by"
                  value={tenant_represented_by.value || ''}
                  onChange={onStateChange}
                  onBlur={onStateChanged}
                />
              </FormGroup>
            </div>
            <div className="form-row-with-labels-and-hints">
              <div className="nd-form-group large">
                <label className="input-label">
                  Account Driven
                  <Tooltip
                    message={
                      <>
                        <h3>Account Driven</h3>
                        <br />
                        <p>Account Driven - Contractual strategic account relationship
                          <br />
                          One-Off - Locally sourced - not awarded due to strategic account client contract
                        </p>
                      </>
                    }
                  >
                    <Icon iconName="info-circle" />
                  </Tooltip>
                </label>
                <Select
                  defaultOption={yesNoOptions.find(option => option.value === account_driven_deal.value)}
                  onChange={({ value }) => onStateChanged({
                    target: { value, name: 'account_driven_deal', type: 'text' },
                  })}
                  options={yesNoOptions}
                />
              </div>
            </div>
          </div>
          <div className="form-row-with-labels-and-hints">
            <FormGroup
              label="Tenant Represented By Agent"
              size="large"
              isRequired={!voucher.is_early_invoice}
              hasError={!tenant_represented_by_agent.value}
              errorMessage="Tenant Represented By Agent is required"
            >
              <input
                type="text"
                name="tenant_represented_by_agent"
                value={tenant_represented_by_agent.value || ''}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
          <div className="form-row-with-labels-and-hints">
            <FormGroup
              label="Landlord Represented By"
              size="large"
              isRequired={!voucher.is_early_invoice}
              hasError={!landlord_represented_by.value}
              errorMessage="Landlord Represented By is required"
            >
              <input
                type="text"
                name="landlord_represented_by"
                value={landlord_represented_by.value || ''}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
          <div className="form-row-with-labels-and-hints">
            <FormGroup
              label="Landlord Represented By Agent"
              size="large"
              isRequired={!voucher.is_early_invoice}
              hasError={!landlord_represented_by_agent.value}
              errorMessage="Landlord Represented By Agent is required"
            >
              <input
                type="text"
                name="landlord_represented_by_agent"
                value={landlord_represented_by_agent.value || ''}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <div>
        <h3>Rent &amp; Escalations</h3>
        <div className="form-row-with-labels-and-hints">
          <FormGroup
            label={<>Base Rent ($/SqFt <span className="lowercase">per year</span>){infoToolTipDollar}</>}
            size="large"
            isRequired={!voucher.is_early_invoice}
            hasError={!base_rent.value && base_rent.value !== 0}
            errorMessage="Base Rent ($/SF per year) is required"
          >
            <NumberInput
              name="base_rent"
              value={base_rent.value}
              integerPrecision={0}
              precision={2}
              onChange={onStateChange}
              onBlur={onStateChanged}
            />
          </FormGroup>
        </div>
        <div className="form-row-with-labels-and-hints">
          <FormGroup
            className="free-rent"
            label={<>Free Rent{infoToolTip}</>}
            size="large"
            isRequired={!voucher.is_early_invoice}
            hasError={!free_rent.value && free_rent.value !== 0}
            errorMessage="Free Rent is required"
          >
            <NumberInput
              name="free_rent"
              value={free_rent.value}
              precision={0}
              onChange={onStateChange}
              onBlur={onStateChanged}
            />
            Months
          </FormGroup>
        </div>
        <div className="form-row-with-labels-and-hints">
          <FormGroup
            label="Lease Escalation"
            size="large"
            isRequired={!voucher.is_early_invoice}
            hasError={rent_escalation_is_custom.value === undefined}
            errorMessage="Lease Escalation is required"
          >
            <Select
              defaultOption={leaseEscalationOptions.find(option => option.value === rent_escalation_is_custom.value?.toString())}
              options={leaseEscalationOptions}
              onChange={({ value }) => onStateChanged({
                target: { value, name: 'rent_escalation_is_custom', type: 'text' },
              })}
            />
          </FormGroup>
        </div>
        <div className="form-row-with-labels-and-hints">
          <PercentDollarInput
            label={<>Escalation <span className="lowercase">(per year)</span></>}
            isRequired={!voucher.is_early_invoice}
            name={{
              percentage: 'rent_escalation_percent',
              value: 'rent_escalation_dollar',
              select: 'rent_escalation_is_percent',
            }}
            onTypeChange={onStateChanged}
            onChange={onStateChange}
            onBlur={onStateChanged}
            baseTotal={0}
            percentage={rent_escalation_percent.value}
            value={rent_escalation_dollar.value}
            isPercent={rent_escalation_is_percent.value}
            isZeroValid
          />
          {infoToolTipDollar}
        </div>
      </div>
      <div>
        <h3>Expenses</h3>
        <div className="field-group">
          <div className="form-row-with-labels-and-hints">
            <FormGroup
              label={<>Tenant Improvement Allowance ($/SqFt){infoToolTipDollar}</>}
              size="large"
              isRequired={!voucher.is_early_invoice}
              hasError={!tenant_improvement_allowance_amount_per_sqft.value && tenant_improvement_allowance_amount_per_sqft.value !== 0}
              errorMessage="Tenant Improvement Allowance ($/SF) is required"
            >
              <NumberInput
                name="tenant_improvement_allowance_amount_per_sqft"
                value={tenant_improvement_allowance_amount_per_sqft.value}
                precision={2}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
          <div className="form-row-with-labels-and-hints">
            <div className="nd-form-group large">
              <label>
                Parking Ratio (spots per 1000 SqFt)
              </label>
              <input
                type="text"
                name="parking_ratio"
                value={parking_ratio.value || ''}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </div>
          </div>
        </div>
        <div className="field-group">
          <div className="form-row-with-labels-and-hints">
            <FormGroup
              label="Tenant Improvements Detail"
              size="large"
              isRequired={!voucher.is_early_invoice}
              hasError={!tenant_improvements_detail.value}
              errorMessage="Tenant Improvements Detail is required"
            >
              <Select
                defaultOption={tenantImprovementDetailOptions.find(option => option.value === tenant_improvements_detail.value)}
                options={tenantImprovementDetailOptions}
                onChange={({ value }) => onStateChanged({
                  target: { value, name: 'tenant_improvements_detail', type: 'text' },
                })}
              />
            </FormGroup>
          </div>
          <div className="form-row-with-labels-and-hints">
            <div className="nd-form-group large">
              <label>
                Parking Expenses ($/Space)
              </label>
              <NumberInput
                name="parking_expenses_amount_per_space"
                precision={2}
                value={parking_expenses_amount_per_space.value}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </div>
          </div>
        </div>
        <div className="field-group">
          <div className="form-row-with-labels-and-hints">
            <FormGroup
              label={<>Operating Expenses ($/SqFt <span className="warning-text">per year</span>){infoToolTipDollar}</>}
              size="large"
              isRequired={!voucher.is_early_invoice}
              hasError={!operating_expenses_amount_per_sqft.value && operating_expenses_amount_per_sqft.value !== 0}
              errorMessage="Operating Expenses ($/SqFt) is required"
            >
              <NumberInput
                name="operating_expenses_amount_per_sqft"
                value={operating_expenses_amount_per_sqft.value}
                precision={2}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
          <div className="form-row-with-labels-and-hints">
            <FormGroup
              label={<>Taxes ($/SqFt <span className="warning-text">per year</span>){infoToolTipDollar}</>}
              size="large"
              isRequired={!voucher.is_early_invoice}
              hasError={!tax_amount.value && tax_amount.value !== 0}
              errorMessage="Taxes ($/SqFt) is required"
            >
              <NumberInput
                name="tax_amount"
                value={tax_amount.value}
                precision={2}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
        </div>
        <div>
          <legend className="secondary-legend">
            Is electric included in the operating expenses?
          </legend>
          <RadioGroup
            name="electric_included_in_operating_expenses"
            className="radioVal"
            selectedValue={electric_included_in_operating_expenses.value?.toString()}
            onChange={onStateChanged}
            orientation="vertical"
          >
            <RadioButton value="true">Yes, electric is included</RadioButton>
            <RadioButton value="false">No</RadioButton>
          </RadioGroup>
        </div>
        <div className="form-row-with-labels-and-hints">
          <FormGroup
            hasError={!electric_expense_amount_per_sqft.value && electric_expense_amount_per_sqft.value !== 0}
            label={<>Electric Expenses ($/SqFt <span className="warning-text">per year</span>){infoToolTipDollar}</>}
            size="large"
            isRequired={!voucher.is_early_invoice || electricExpenseIsRequired}
            errorMessage="Electric Expenses ($/SqFt) is required"
          >
            <NumberInput
              name="electric_expense_amount_per_sqft"
              value={electric_expense_amount_per_sqft.value}
              precision={2}
              onChange={onStateChange}
              onBlur={onStateChanged}
            />
          </FormGroup>
        </div>
      </div>
      <div>
        <h3>Industrial Leases</h3>
        <div className="field-group">
          <div className="form-row-with-labels-and-hints">
            <FormGroup hasError={false} label="Office Area (SqFt)" size="large">
              <NumberInput
                name="office_area"
                value={office_area.value}
                precision={0}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
          <div className="form-row-with-labels-and-hints">
            <FormGroup hasError={false} label="Office Rent ($/SqFt)" size="large">
              <NumberInput
                name="office_rent_amount_per_sqft"
                value={office_rent_amount_per_sqft.value}
                precision={2}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
        </div>
        <div className="field-group">
          <div className="form-row-with-labels-and-hints">
            <FormGroup hasError={false} label="Warehouse Rent ($/SqFt)" size="large">
              <NumberInput
                name="warehouse_rent_amount_per_sqft"
                value={warehouse_rent_amount_per_sqft.value}
                precision={2}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
          <div className="form-row-with-labels-and-hints">
            <FormGroup hasError={false} label="Clear Height (Feet)" size="large">
              <input
                type="text"
                name="clear_height"
                value={clear_height.value || ''}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
        </div>
        <div className="field-group">
          <div className="form-row-with-labels-and-hints">
            <FormGroup hasError={false} label="Power (Amps)" size="large">
              <NumberInput
                name="power_amps"
                value={power_amps.value}
                precision={0}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
          <div className="form-row-with-labels-and-hints">
            <FormGroup hasError={false} label="Power (Volts)" size="large">
              <NumberInput
                name="power_volts"
                value={power_volts.value}
                precision={0}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
        </div>
        <div className="field-group">
          <div className="form-row-with-labels-and-hints">
            <FormGroup hasError={false} label="GL Door Count" size="large">
              <NumberInput
                name="gl_door_count"
                value={gl_door_count.value}
                precision={0}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
          <div className="form-row-with-labels-and-hints">
            <FormGroup hasError={false} label="DH Door Count" size="large">
              <NumberInput
                name="dh_door_count"
                value={dh_door_count.value}
                precision={0}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
        </div>
        <div className="field-group">
          <div className="form-row-with-labels-and-hints">
            <FormGroup hasError={false} label="Truckwell Count" size="large">
              <NumberInput
                name="truckwell_count"
                value={truckwell_count.value}
                precision={0}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
          <div className="form-row-with-labels-and-hints">
            <FormGroup hasError={false} label="Cross Dock Count" size="large">
              <NumberInput
                name="cross_dock_count"
                value={cross_dock_count.value}
                precision={0}
                onChange={onStateChange}
                onBlur={onStateChanged}
              />
            </FormGroup>
          </div>
        </div>
        <div className="form-row-with-labels-and-hints">
          <FormGroup hasError={false} label="Rail Serviced" size="large">
            <Select
              defaultOption={yesNoOptions.find(option => option.value === rail_serviced.value)}
              onChange={({ value }) => onStateChanged({
                target: { value, name: 'rail_serviced', type: 'text' },
              })}
              options={yesNoOptions}
            />
          </FormGroup>
        </div>
      </div>
      <div>
        <FormGroup hasError={false} label="General Comments" size="large">
          <Textarea
            id="general-comments"
            name="general_comments"
            rows={{ min: 4, max: 130 }}
            onChange={onStateChange}
            onBlur={onStateChanged}
            value={general_comments.value || ''}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default LeaseComp;
