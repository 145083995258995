import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@cbrebuild/blocks';
import Select from '../../../nucleus/select/select';
import { updateDealPipelineTimestamp } from '../../../redux/actions/deal-actions';
import PercentDollarInput from '../../../nucleus/inputs/PercentDollarInput';
import FormGroup from '../../../nucleus/form-group/form-group';
import Datepicker from '../../../nucleus/datepicker/datepicker';
import { updateVoucher } from '../../../redux/actions/voucher/voucher-actions';
import { deleteInstallment, updateInstallment } from '../../../redux/actions/voucher/installments-actions';
import useFormState, { EVENT_VALUE_TYPES } from '../../../hooks/useFormState';
import { selectTotalCommissionsForInstallments } from '../../../redux/selectors/voucher-selectors';

const selectInstallmentById = (state, id) => (
  state.voucherInstallments.find(installment => installment.id === id)
);

const typeOverrides = {
  installment_amount: EVENT_VALUE_TYPES.FLOAT,
  voucher_installment_percent: EVENT_VALUE_TYPES.FLOAT,
  installment_date: EVENT_VALUE_TYPES.DATE,
};

const InstallmentItem = ({ id, showValidation }) => {
  const installment = useSelector(state => selectInstallmentById(state, id)) || {};
  const options = useSelector(state => state.voucher.voucherContingencyCodeChoices || []);
  const dealId = useSelector(state => state.deal.deal?.id);
  const dealType = useSelector(state => state.deal.deal?.deal_type);
  const voucherId = useSelector(state => state.voucher.voucher?.id);
  const installment_is_percent = useSelector(state => state.voucher.voucher?.installment_is_percent);
  const baseTotal = useSelector(selectTotalCommissionsForInstallments);
  const dispatch = useDispatch();

  const {
    installment_amount, installment_date, voucher_contingency_code, voucher_installment_percent,
    onStateChange, onStateChanged,
  } = useFormState({
    installment_amount: installment.installment_amount,
    voucher_installment_percent: installment.voucher_installment_percent,
    installment_date: installment.installment_date,
    voucher_contingency_code: installment.voucher_contingency_code,
  }, (update) => {
    dispatch(updateInstallment(id, update));
    dispatch(updateDealPipelineTimestamp(dealId));
  }, typeOverrides);

  const onDelete = () => {
    dispatch(deleteInstallment(id));
    dispatch(updateDealPipelineTimestamp(dealId));
  };

  const onUpdateVoucher = ({ target: { name, value } }) => {
    dispatch(updateVoucher(voucherId, { [name]: value }));
    dispatch(updateDealPipelineTimestamp(dealId));
  };

  const invalidCode = (showValidation || voucher_contingency_code.dirty) && voucher_contingency_code.value === null;
  const invalidInstallAmount = (showValidation || installment_amount.dirty) && installment_amount.value === null;
  const invalidInstallmentDate = (showValidation || installment_date.dirty) && installment_date.value === null;

  return (
    <div className="form-row-with-labels-and-hints">
      {dealType === 'lease' && (
        <FormGroup label="Due Upon" size="large" isRequired hasError={invalidCode} errorMessage="Required" className="due-upon-field">
          <Select
            name="voucher_contingency_code"
            defaultOption={options.find(option => option.value === voucher_contingency_code.value)}
            onChange={({ value }) => onStateChanged({
              target: { value, name: 'voucher_contingency_code', type: 'text' },
            })}
            options={options}
          />
        </FormGroup>
      )}
      <PercentDollarInput
        isRequired={invalidInstallAmount}
        label={`${invalidInstallAmount ? '' : '* '}amount`}
        name={{
          percentage: 'voucher_installment_percent',
          value: 'installment_amount',
          select: 'installment_is_percent',
        }}
        isPercent={installment_is_percent}
        value={installment_amount.value}
        percentage={voucher_installment_percent.value}
        onTypeChange={onUpdateVoucher}
        onChange={onStateChange}
        onBlur={onStateChanged}
        baseTotal={baseTotal}
      />
      <FormGroup label="Due Date" size="medium" isRequired hasError={invalidInstallmentDate} errorMessage="Required">
        <Datepicker
          date={installment_date.value}
          onDateChange={date => onStateChanged({ target: { value: date, name: 'installment_date', type: 'date' } })}
        />
      </FormGroup>
      <IconButton className="blxs-button-icon-small" iconName="close-circle" onClick={onDelete} variant="basic" />
    </div>
  );
};

InstallmentItem.propTypes = {
  id: PropTypes.number.isRequired,
  showValidation: PropTypes.bool.isRequired,
};

export default InstallmentItem;
