import VoucherTabContainer from '../../components/deal-details/voucher/voucher-tab-container';
import FinancialsContainer from '../../components/deal-details/financials/financials-container';
import CommissionsTabContainer from '../../components/deal-details/commissions/commissions-tab-container';
import FilesTabContainer from '../../components/deal-details/files/files-tab-container';
import NotesTabContainer from '../../components/deal-details/notes/notes-tab-container';
import PropertiesTabContainer from '../../components/deal-details/properties/properties-tab-container';
import TasksTabContainer from '../../components/deal-details/tasks/tasks-tab-container';
import ContactsTabContainer from '../../components/deal-details/contacts/contacts-tab-container';
import OverviewTabContainer from '../../components/deal-details/overview/overview-tab-container';

const subroutes = [
  {
    path: '/deals/detail/:id/pipeline',
    exact: true,
    component: FinancialsContainer,
  },
  {
    path: '/deals/detail/:id/overview',
    exact: true,
    component: OverviewTabContainer,
  },
  {
    path: '/deals/detail/:id/notes',
    exact: true,
    component: NotesTabContainer,
  },
  {
    path: '/deals/detail/:id/tasks',
    exact: true,
    component: TasksTabContainer,
  },
  {
    path: '/deals/detail/:id/files',
    exact: true,
    component: FilesTabContainer,
  },
  {
    path: '/deals/detail/:id/properties',
    exact: true,
    component: PropertiesTabContainer,
  },
  {
    path: '/deals/detail/:id/contacts',
    exact: true,
    component: ContactsTabContainer,
  },
  {
    path: '/deals/detail/:id/commissions',
    exact: true,
    component: CommissionsTabContainer,
  },
  {
    path: '/deals/detail/:id/voucher',
    exact: true,
    component: VoucherTabContainer,
  },
];

export default subroutes;
