import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import NaicsAutocomplete from '../autocomplete/naics-autocomplete';
import AddressForm from './AddressForm';
import Select from '../../nucleus/select/select';
import { isValidCompanyName, isValidNaicsCategory } from './voucher-validation';
import companiesService from '../../services/companies-service';

const transactionTypeData = [
  { value: '', display_name: 'None' },
  { value: 'A', display_name: 'Class A' },
  { value: 'B', display_name: 'Class B' },
  { value: 'C', display_name: 'Class C' },
  { value: 'D', display_name: 'Class D' },
];

const purchaserTypes = [
  { value: '', display_name: 'None' },
  { value: '01-Investor', display_name: '01-Investor' },
  { value: '02-Owner/User', display_name: '02-Owner/User' },
  { value: '03-Developer', display_name: '03-Developer' },
  { value: '04-Government', display_name: '04-Government' },
  { value: '05-Lender', display_name: '05-Lender' },
];

const investorTypes = [
  { value: '', display_name: 'None' },
  { value: '01-Private', display_name: '01-Private' },
  { value: '02-Institutional', display_name: '02-Institutional' },
  { value: '03-REIT', display_name: '03-REIT' },
  { value: '04-Syndicate', display_name: '04-Syndicate' },
  { value: '05-Other', display_name: '05-Other' },
];

const CompanyForm = ({
  id = '',
  infoType,
  partyType,
  gws_client = '',
  credit_rating = '',
  parent_company = '',
  voucher_naics_category = '',
  name = '',
  voucher_purchaser_type = '',
  voucher_investor_type = '',
  postal = '',
  city = '',
  address = '',
  address2 = '',
  territory = '',
  country = '',
  isRelocating = false,
  onChange,
  onFocus,
  onBlur,
  showValidation,
}) => {
  // validation rules
  const nameValid = useMemo(() => isValidCompanyName(name), [name]);
  const voucherNaicsCategoryValid = useMemo(() => isValidNaicsCategory(voucher_naics_category), [voucher_naics_category]);

  const handleCreditRating = (event) => {
    if (event.value !== 'Select') {
      const patch = { credit_rating: event.value };
      companiesService.patchCompany(id, patch);
    }
  };

  const handleInvestorType = (event) => {
    if (event.value !== 'Select') {
      const patch = { voucher_investor_type: event.value };
      companiesService.patchCompany(id, patch);
    }
  };

  const handlePurchaserType = (event) => {
    if (event.value !== 'Select') {
      const patch = { voucher_purchaser_type: event.value };
      companiesService.patchCompany(id, patch);
    }
  };

  const handleBusinessTypeChange = (value) => {
    if (value?.naics_code) {
      onChange({
        target: {
          name: ['voucher_naics_code', 'voucher_naics_category'],
          value: [value.naics_code, value.naics_description],
          type: 'select-one',
          hasMultiple: true,
        },
      });
    } else if (value === '') {
      onChange({
        target: {
          name: ['voucher_naics_code', 'voucher_naics_category'],
          value: [null, null],
          type: 'select-one',
          hasMultiple: true,
        },
      });
    } else {
      onChange({ target: { name: 'voucher_naics_category', value, type: 'text' } });
    }
  };

  return (
    <div>
      <div className="field">
        <label><span className={`${!nameValid && showValidation ? 'asterisk-error' : ''}`}>* </span> Company Name</label>
        <input
          type="text"
          name="name"
          className={`${!nameValid && showValidation ? 'errorInput' : ''}`}
          value={name || ''}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {!nameValid && showValidation ? <p className="asterisk-error">Company Name is required</p> : ''}
      </div>
      {(partyType === 'tenant' || partyType === 'buyer') &&
        <React.Fragment>
          <div className="field-group autocomplete-wrapper">
            <label><span className={`${!voucherNaicsCategoryValid && showValidation ? 'asterisk-error' : ''}`}>* </span> Business Type / Use ID</label>
            <NaicsAutocomplete
              initSearchTerm={voucher_naics_category || ''}
              placeholder="Type to filter business categories"
              onChange={handleBusinessTypeChange}
            />
            {!voucherNaicsCategoryValid && showValidation ?
              <p className="asterisk-error errorMsg">You must select Business Type / Use ID from search results</p> : ''}
          </div>
        </React.Fragment>
      }
      <AddressForm
        address={address}
        address2={address2}
        city={city}
        country={country === 'US' ? 'United States' : country}
        postal={postal}
        territory={territory}
        isRelocating={isRelocating}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        showValidation={showValidation}
      />
      {infoType !== 'BillTo' &&
      <React.Fragment>
        <div className="field">
          <label>GWS Client</label>
          <input
            type="text"
            name="gws_client"
            value={gws_client || ''}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
        <div className="field">
          <label>Parent Company</label>
          <input
            type="text"
            name="parent_company"
            value={parent_company || ''}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
      </React.Fragment>
}
      {infoType === 'lease' &&
        <div className="field drop-downs">
          <label>
            Tenant Credit Rating
          </label>
          <Select
            name="credit_rating"
            defaultOption={credit_rating ? `Class ${credit_rating}` : 'Select'}
            options={transactionTypeData}
            onChange={handleCreditRating}
          />
        </div>
      }
      {infoType === 'sale' &&
        <React.Fragment>
          <div className="field drop-downs">
            <label>
              <span className={voucher_purchaser_type === '' ? 'asterisk-error' : ''}>*
              </span>Purchaser Type
            </label>
            <Select
              className={voucher_purchaser_type === '' ? 'errorInput' : ''}
              defaultOption={voucher_purchaser_type || 'Select'}
              options={purchaserTypes}
              onChange={handlePurchaserType}
            />
            {voucher_purchaser_type === '' &&
            <p className="asterisk-error error-text">Purchaser Type is required</p>}
          </div>
          <div className="field drop-downs">
            <label>
              <span className={voucher_investor_type === '' ? 'asterisk-error' : ''}>*</span>
              Investor Type
            </label>
            <Select
              className={voucher_investor_type === '' ? 'errorInput' : ''}
              name="voucher_investor_type"
              defaultOption={voucher_investor_type || 'Select'}
              options={investorTypes}
              onChange={handleInvestorType}
            />
          </div>
        </React.Fragment>
      }
    </div>
  );
};

CompanyForm.propTypes = {
  id: PropTypes.number,
  infoType: PropTypes.string.isRequired,
  partyType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  credit_rating: PropTypes.string,
  gws_client: PropTypes.string,
  name: PropTypes.string,
  parent_company: PropTypes.string,
  voucher_purchaser_type: PropTypes.string,
  voucher_investor_type: PropTypes.string,
  voucher_naics_category: PropTypes.string,
  postal: PropTypes.string,
  city: PropTypes.string,
  address: PropTypes.string,
  address2: PropTypes.string,
  territory: PropTypes.string,
  country: PropTypes.string,
  isRelocating: PropTypes.bool,
  showValidation: PropTypes.bool,
};

CompanyForm.defaultProps = {
  onFocus: () => { },
  onBlur: () => { },
  id: null,
  postal: '',
  city: '',
  address: '',
  address2: '',
  name: '',
  territory: '',
  country: '',
  isRelocating: false,
  credit_rating: '',
  gws_client: '',
  parent_company: '',
  voucher_purchaser_type: '',
  voucher_investor_type: '',
  voucher_naics_category: '',
  showValidation: false,
};

export default CompanyForm;
