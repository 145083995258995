import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';
import Flyout from '../../flyout/flyout';

const initStateValue = {
  focused: false,
  selectedIndex: undefined,
  suggestions: [],
  value: '',
};
class AddTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initStateValue,
    };
    this.inputRef = React.createRef();
    this.listRef = React.createRef();
  }

  handleBlur = () => {
    this.setState({ focused: false });
  }

  handleChange = ({ target: { value } }) => {
    if (value !== ' ') {
      const suggestions = (value && this.props.suggestions.length)
        ? this.props.suggestions.filter(tag => tag.includes(value)).splice(0, 10)
        : [];
      this.setState({ value, suggestions, selectedIndex: undefined });
    }
  }

  handleFocus = () => {
    this.inputRef.current.focus();
    this.setState({ focused: true });
  }

  handleKeyDown = (event) => {
    const {
      selectedIndex,
      suggestions,
      value,
    } = this.state;
    const key = event.which || event.keyCode;
    // space bar press
    if (value && key === 32) {
      event.preventDefault();
      this.props.onSubmit(this.state.value.toLowerCase());
      this.setState({ value: '', suggestions: [], selectedIndex: undefined });
    }
    // tab or enter press
    if (value && (key === 13 || key === 9)) {
      event.preventDefault();
      if (selectedIndex !== undefined) {
        this.props.onSubmit(suggestions[selectedIndex]);
      } else {
        this.props.onSubmit(this.state.value.toLowerCase());
      }
      this.setState({ value: '', suggestions: [], selectedIndex: undefined });
      // up arrow press
    } else if (key === 38 && suggestions.length) {
      event.preventDefault(); // prevent cursor from moving to beginning of input
      if (!selectedIndex) {
        this.setState({ selectedIndex: suggestions.length - 1 });
        this.scrollIntoView((suggestions.length - 1));
      } else {
        this.setState({ selectedIndex: (selectedIndex - 1) });
        this.scrollIntoView((selectedIndex - 1));
      }
      // down arrow
    } else if (key === 40 && suggestions.length) {
      if (selectedIndex === undefined || selectedIndex === (suggestions.length - 1)) {
        this.setState({ selectedIndex: 0 });
        this.scrollIntoView(0);
      } else {
        this.setState({ selectedIndex: (selectedIndex + 1) });
        this.scrollIntoView((selectedIndex + 1));
      }
    }
  }

  scrollIntoView = (index) => {
    if (this.listRef.current.children[index]) {
      const element = this.listRef.current.children[index];
      const parentElement = element.offsetParent;
      if (parentElement) {
        parentElement.scrollTop = (element.offsetTop + element.offsetHeight) - 40;
      }
    }
  }

  bindHandleSelect = suggestion => () => {
    this.setState({
      focused: false,
      selectedIndex: undefined,
      suggestions: [],
      value: '',
    });
    this.props.onSubmit(suggestion);
  }

  handleSubmit = () => {
    const { value } = this.state;
    const lowerValue = value.toLowerCase();
    if (lowerValue) {
      this.props.onSubmit(lowerValue);
      this.setState({
        focused: false,
        selectedIndex: undefined,
        value: '',
      });
    }
  }

  render() {
    const {
      focused,
      selectedIndex,
      suggestions,
      value,
    } = this.state;

    const {
      placeHolder,
    } = this.props;

    return (
      <div className="add-tag-container">
        <div className="add-tag">
          <input
            ref={this.inputRef}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            placeholder={placeHolder}
            value={value}
            onKeyDown={this.handleKeyDown}
          />
          {focused || value ? (
            <IconButton
              iconName="checkmark"
              onClick={this.handleSubmit}
            />
          ) :
            (
              <IconButton
                iconName="plus"
                onClick={this.handleFocus}
              />
            )}
        </div>
        {suggestions.length ? (
          <Flyout
            additionalClassName="add-tag-flyout"
            showFlyout
          >
            <ul ref={this.listRef}>
              {suggestions.map((suggestion, index) => (
                <li
                  className={index === selectedIndex ? 'highlighted' : ''}
                  key={suggestion}
                  onClick={this.bindHandleSelect(suggestion)}
                >{suggestion}
                </li>
              ))}
            </ul>
          </Flyout>
        ) : null}
      </div>
    );
  }
}

AddTag.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  placeHolder: PropTypes.string,
};

AddTag.defaultProps = {
  suggestions: [],
  placeHolder: 'Add Tag',
};

export default AddTag;

