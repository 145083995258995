import React from 'react';
import PropTypes from 'prop-types';

const LabeledItem = (props) => {
  const {
    label,
    item,
    emptyState,
  } = props;

  const renderedItem = item || item === 0 ? item : emptyState;
  const itemClass = item || item === 0 ? '' : 'empty-state';

  return (
    <div className="nd-labeled-item">
      <p className="label">{label}</p>
      <div className={`item ${itemClass}`}>
        {renderedItem}
      </div>
    </div>);
};

export default LabeledItem;

LabeledItem.propTypes = {
  label: PropTypes.string,
  item: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  emptyState: PropTypes.string,
};

LabeledItem.defaultProps = {
  label: '',
  item: '',
  emptyState: '',
};
