import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon } from '@cbrebuild/blocks';

const FileDownloadItem = props => (
  <div className="file-download-item">
    <div>
      <a className="file-name" href={props.file.document_download_link}>{props.file.title}</a>
      <div className="file-date">{moment(props.file.created).format('ll')}</div>
    </div>
    <a href={props.file.document_download_link}>
      <Icon iconName="download" />
    </a>
  </div>
);

FileDownloadItem.propTypes = {
  file: PropTypes.shape({
    document_download_link: PropTypes.string,
    title: PropTypes.string,
    created: PropTypes.string,
  }).isRequired,
};

export default FileDownloadItem;
