/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isNull, isEmpty } from 'lodash';
import moment from 'moment';
import findIndex from 'lodash/findIndex';
import { Icon, Button } from '@cbrebuild/blocks';
import tracker from '../../services/dashboard-tracking-service';
import dealsService from '../../services/deals-service';
import getCounterPartyLabel from '../../utils/get-counter-party-label';
import getDealProperty from '../../utils/get-deal-property';

import ButtonWithFlyout from '../../nucleus/button-with-flyout/button-with-flyout';
import Widget from '../../nucleus/widget/widget';
import Currency from '../../nucleus/formats/currency';
import StackedLabeledItem from '../../nucleus/stacked-labeled-item/stacked-labeled-item';
import DealLabel from '../deal-label/deal-label';
import Datepicker from '../../nucleus/datepicker/datepicker';
import Tooltip from '../../nucleus/tooltip/tooltip';
import LoadMore from '../../nucleus/load-more/load-more';

const DealsWidget = () => {
  const tabs = ['Upcoming Expirations', 'Favorite Deals'];
  const EXPIRATION_START_DATE = 'EXPIRATION_START_DATE';
  const EXPIRATION_END_DATE = 'EXPIRATION_END_DATE';

  const getExpirationStartDateKey = () => window.localStorage.getItem(EXPIRATION_START_DATE);

  const getExpirationEndDateKey = () => window.localStorage.getItem(EXPIRATION_END_DATE);

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [expiringDeals, setExpiringDeals] = useState([]);
  const [favoriteDeals, setFavoriteDeals] = useState([]);
  const [filteredExpiringDeals, setFilteredExpiringDeals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [next, setNext] = useState(null);
  const [selectedExpirationStartDate, setSelectedExpirationStartDate] = useState(getExpirationStartDateKey());
  const [selectedExpirationEndDate, setSelectedExpirationEndDate] = useState(getExpirationEndDateKey());
  const [isExpirationStartDateValid, setIsExpirationStartDateValid] = useState(true);
  const [isExpirationEndDateValid, setIsExpirationEndDateValid] = useState(true);
  const buttonWithFlyoutRef = useRef();
  const isButtonDisabled = !isExpirationStartDateValid || !isExpirationEndDateValid;

  const fetchUpcomingExpirations = async (conf) => {
    setIsLoading(true);

    let params = {
      page_size: 10,
      ordering: 'date_lease_to',
      deal_type: 'lease',
      is_mta: true,
      deal_life_cycle_stage: 'closed',
      date_lease_to_min: moment().format('YYYY-MM-DD'),
      is_archived: false,
    };

    params = { ...params, ...conf };

    let response;
    try {
      response = await dealsService.fetchDeals(params);
      setNext(response.next);
      setExpiringDeals(expDeals => [...expDeals, ...response.results]);
    } catch (error) {
      console.log('Error fetching expiring deals: ', error);
    }
    setIsLoading(false);
  };

  const toggleFavorite = (id, isFavorite) => {
    const updateDeal = isFavorite ? dealsService.unfavoriteDeal : dealsService.favoriteDeal;
    updateDeal(id)
      .then(() => {
        const index = findIndex(favoriteDeals, deal => (deal.id === id));
        const updatedFavoriteDeals = [...favoriteDeals];
        updatedFavoriteDeals[index].is_favorite = !isFavorite;
        setFavoriteDeals(updatedFavoriteDeals);
      })
      .catch((error) => {
        console.log('Error updating favorite preference: ', error);
      });
  };

  const handleLoadMore = () => {
    if (next) {
      fetchUpcomingExpirations({ page: next });
    }
  };

  const fetchFavoriteDeals = async () => {
    setIsLoading(true);

    const params = {
      page_size: 25,
      ordering: '-modified',
      favorited: true,
      is_archived: false,
    };

    let response;
    try {
      response = await dealsService.fetchDeals(params);
      setFavoriteDeals(response.results);
    } catch (error) {
      console.log('Error fetching favorite deals: ', error);
    }
    setIsLoading(false);
  };

  const updateExpirationStartDateValidity = (isValid) => {
    setIsExpirationStartDateValid(isValid);
  };

  const updateExpirationEndDateValidity = (isValid) => {
    setIsExpirationEndDateValid(isValid);
  };

  const onExpirationDateChange = (value, startOrEnd = 'start_date') => {
    if (startOrEnd === 'start_date') {
      return isEmpty(value) ? setSelectedExpirationStartDate(null) : setSelectedExpirationStartDate(value);
    }
    return isEmpty(value) ? setSelectedExpirationEndDate(null) : setSelectedExpirationEndDate(value);
  };

  const isValidDate = (expDate) => {
    if (isNull(selectedExpirationStartDate) && isNull(selectedExpirationEndDate)) return true;
    let isValid = false;
    if (selectedExpirationStartDate) {
      if (moment(expDate).isSameOrAfter(selectedExpirationStartDate)) {
        isValid = true;
      } else {
        return isValid;
      }
    }
    if (selectedExpirationEndDate) {
      if (moment(expDate).isSameOrBefore(selectedExpirationEndDate)) {
        isValid = true;
      } else {
        isValid = false;
      }
    }

    return isValid;
  };
  const onClear = () => {
    setSelectedExpirationEndDate(null);
    setSelectedExpirationStartDate(null);
  };

  const filterExpiringDeals = () => {
    setFilteredExpiringDeals(expiringDeals.filter(deal => isValidDate(deal.date_lease_to)));
  };

  const setExpirationDateKey = () => {
    const { localStorage } = window;

    // eslint-disable-next-line no-unused-expressions
    selectedExpirationStartDate ? localStorage.setItem(EXPIRATION_START_DATE, selectedExpirationStartDate) : localStorage.removeItem(EXPIRATION_START_DATE);
    return selectedExpirationEndDate ? localStorage.setItem(EXPIRATION_END_DATE, selectedExpirationEndDate) : localStorage.removeItem(EXPIRATION_END_DATE);
  };

  const onApply = () => {
    filterExpiringDeals();
    setExpirationDateKey();
    buttonWithFlyoutRef.current.closeFlyout();
  };

  useEffect(() => {
    if (activeTab === tabs[0]) {
      fetchUpcomingExpirations();
    } else if (activeTab === tabs[1]) {
      fetchFavoriteDeals();
    }
  }, [activeTab]);

  useEffect(() => {
    if (!isEmpty(expiringDeals)) {
      filterExpiringDeals();
    }
  }, [expiringDeals]);
  const widgetHeader = (
    <div className="nd-tabnavs">
      <ul>
        {tabs.map(tab => (
          <li
            key={tab}
            className={activeTab === tab ? 'active' : ''}
            onClick={() => {
              setActiveTab(tab);
              tracker.trackDealsWidgetTabClicked(tab);
            }}
          >
            <a>{tab}</a>
          </li>
        ))}
      </ul>
      { activeTab === tabs[0] ?
        <ButtonWithFlyout
          actionIcon="filter"
          buttonIcon="chevron-down"
          buttonText="Filter"
          dataE2e="deal-listview-filter"
          onAbort={() => {}}
          ref={buttonWithFlyoutRef}
          position="right"
        >
          <div className="content">
            <div className="lease-expiration">
              <label>
                Lease Expiration
                <Tooltip message="Applicable for Lease Tenant and Lease Landlord Deal Types only." />
              </label>
              <div className="min-max-fields">
                <div className="min">
                  <label>Start</label>
                  <Datepicker
                    date={selectedExpirationStartDate}
                    onDateChange={onExpirationDateChange}
                    updateValidity={updateExpirationStartDateValidity}
                  />
                </div>
                <div>
                  <label>End</label>
                  <Datepicker
                    date={selectedExpirationEndDate}
                    onDateChange={date => onExpirationDateChange(date, 'end_date')}
                    updateValidity={updateExpirationEndDateValidity}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="button-controls">
            <Button onClick={onClear} data-e2e="clear-button" variant="secondary">Clear</Button>
            <Button
              onClick={onApply}
              data-e2e="apply-button"
              disabled={isButtonDisabled}
            >
              Apply
            </Button>
          </div>
        </ButtonWithFlyout> : <></>
}
    </div>
  );

  return (
    <Widget
      className="deals-widget"
      alternateHeader={widgetHeader}
    >
      {activeTab === tabs[0] && (
        filteredExpiringDeals.map(deal => (
          <ExpiringDealCard
            key={deal.id}
            deal={deal}
          />
        ))
      )}
      {activeTab === tabs[0] && !filteredExpiringDeals.length && expiringDeals.length > 0 && !isLoading && (
        <div className="widget-content-row extra-padding">
          No upcoming expirations found with current filter. Please adjust your filter to see results.
        </div>
      )
      }
      {activeTab === tabs[0] && expiringDeals.length > 0 && filteredExpiringDeals.length > 0 && (
        <LoadMore isLoading={isLoading} hasNextPage={next} handleLoadMore={handleLoadMore} />
      )
      }
      {activeTab === tabs[0] && !expiringDeals.length && !isLoading && (
        <div className="widget-content-row extra-padding">
          No upcoming expirations found.
        </div>
      )}
      {activeTab === tabs[1] && (
        favoriteDeals.map(deal => (
          <FavoriteDealCard
            key={deal.id}
            deal={deal}
            toggleFavorite={() => toggleFavorite(deal.id, deal.is_favorite)}
          />
        ))
      )}
      {activeTab === tabs[1] && !favoriteDeals.length && !isLoading && (
        <div className="widget-content-row extra-padding">
          No favorite deals found.
        </div>
      )}
    </Widget>
  );
};

const ExpiringDealCard = ({ deal }) => {
  const property = getDealProperty(deal);

  return (
    <div className="widget-content-row">
      <div className="flex-row space-between margin-bottom">
        <Link
          to={`/deals/detail/${deal.id}/overview`}
          onClick={() => { tracker.trackDealsWidgetLinkToDeal(deal.id, 'Upcoming Expirations'); }}
        >
          <h3>{deal.name}</h3>
        </Link>
        <DealLabel
          dealStage={deal.deal_life_cycle_stage}
          dealType={deal.deal_type || ''}
          repRole={deal.rep_role || ''}
        />
      </div>
      <div className="flex-row three-column">
        <div>
          <StackedLabeledItem
            label="Gross Commission"
            value={<Currency value={deal.total_commission_mta} />}
          />
          <StackedLabeledItem
            label="Property Address"
            value={property.building && property.building.full_address}
          />
        </div>
        <div>
          <StackedLabeledItem
            label={deal.rep_role !== deal.deal_type && deal.rep_role ? deal.rep_role : 'Client'}
            value={deal.client && deal.client.name}
          />
          <StackedLabeledItem
            label={getCounterPartyLabel(deal) || ''}
            value={deal.counter_party && deal.counter_party.name}
          />
        </div>
        <StackedLabeledItem
          label="Lease Expiration"
          value={moment(deal.date_lease_to).format('MM/DD/YYYY')}
        />
      </div>
    </div>
  );
};

const FavoriteDealCard = ({ deal, toggleFavorite }) => {
  const {
    id,
    name,
    deal_life_cycle_stage,
    deal_type,
    deal_progress,
    is_favorite,
    rep_role,
    is_mta,
    total_commission_mta,
    estimated_commission,
    primary_deal_contact,
  } = deal;

  let stage = deal_life_cycle_stage;
  if (stage === 'executing') {
    stage += ` - ${deal_progress}`;
  }

  return (
    <div className="widget-content-row">
      <div className="flex-row space-between margin-bottom">
        <div className="flex-row">
          <Icon iconName={is_favorite ? 'star-filled' : 'star-outline'} onClick={toggleFavorite} tabIndex="-1" />
          <Link
            to={`/deals/detail/${id}/overview`}
            onClick={() => { tracker.trackDealsWidgetLinkToDeal(id, 'Favorite Deals'); }}
          >
            <h3>{name}</h3>
          </Link>
        </div>
        <DealLabel
          dealStage={deal_life_cycle_stage}
          dealType={deal_type || ''}
          repRole={rep_role || ''}
        />
      </div>
      <div className="flex-row three-column">
        <StackedLabeledItem
          label="Stage"
          value={deal_life_cycle_stage === 'prospect' ? 'Prospecting' : stage}
        />
        <StackedLabeledItem
          label={is_mta ? 'Gross Commission' : 'Estimated Commission'}
          value={<Currency value={is_mta ? total_commission_mta : estimated_commission} />}
        />
        <StackedLabeledItem
          label="Primary Contact"
          value={primary_deal_contact && `${primary_deal_contact.given_name} ${primary_deal_contact.surname}`}
        />
      </div>
    </div>
  );
};

ExpiringDealCard.propTypes = {
  deal: PropTypes.shape({
    client: PropTypes.shape({
      name: PropTypes.string,
    }),
    counter_party: PropTypes.shape({
      name: PropTypes.string,
    }),
    date_lease_to: PropTypes.string,
    deal_life_cycle_stage: PropTypes.string,
    deal_type: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    rep_role: PropTypes.string,
    total_commission_mta: PropTypes.number,
  }).isRequired,
};

FavoriteDealCard.propTypes = {
  deal: PropTypes.shape({
    deal_life_cycle_stage: PropTypes.string,
    deal_progress: PropTypes.string,
    deal_type: PropTypes.string,
    estimated_commission: PropTypes.number,
    id: PropTypes.number,
    is_favorite: PropTypes.bool,
    is_mta: PropTypes.bool,
    name: PropTypes.string,
    primary_deal_contact: PropTypes.shape({
      given_name: PropTypes.string,
      surname: PropTypes.string,
    }),
    rep_role: PropTypes.string,
    total_commission_mta: PropTypes.number,
  }).isRequired,
  toggleFavorite: PropTypes.func.isRequired,
};

export default DealsWidget;
