import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SortableList from './SortableListLocal';
import ConsiderationItem from './ConsiderationItem';
import AddItem from '../../../nucleus/add-item/add-item';
import Currency from '../../../nucleus/formats/currency';
import { createConsideration } from '../../../redux/actions/considerations-actions';
import { reorderConsiderations } from '../../../redux/actions/voucher/considerations-actions';
import REQUEST_STATE from '../../../utils/request-state';
import { selectConsiderationsCommissionAmountTotal } from '../../../redux/selectors/voucher-selectors';

const selectConsiIds = state => state.voucherConsiderations.considerations.sort((a, b) => a.position_id - b.position_id).map(consideration => consideration.id);
const selectFullConsiderations = state => state.voucherConsiderations.considerations.map(consideration => consideration);
const selectTotalTerms = state => state.voucherConsiderations.considerations.reduce((acc, cur) => acc + (cur.total_units || 0), 0);
const selectTotalConsiderations = state => state.voucherConsiderations.considerations.reduce((acc, cur) => acc + (cur.consideration_amount || 0), 0);

/**
 * Considerations
 */
const Considerations = ({
  dealId, dealType, showValidation,
}) => {
  const [shouldCreate, setShouldCreate] = useState(true);
  const considerationIds = useSelector(selectConsiIds, shallowEqual);
  const allConsiderations = useSelector(selectFullConsiderations, shallowEqual);
  const requestState = useSelector(state => state.voucherConsiderations.state);
  const totalTerms = useSelector(selectTotalTerms, shallowEqual);
  const totalConsiderations = useSelector(selectTotalConsiderations, shallowEqual);
  const totalCommissions = useSelector(selectConsiderationsCommissionAmountTotal, shallowEqual);
  const leaseStartDate = useSelector(state => state.deal.deal.date_lease_from || state.voucher.voucher.official_start_date);
  const leaseExpirationDate = useSelector(state => state.deal.deal.lease_expiration_date || state.voucher.voucher.lease_expiration_date);
  const dispatch = useDispatch();
  const [ids, setIds] = useState([]);
  const [allSortedConsiderations, setAllSortedConsiderations] = useState([]);
  const isEarlyInvoice = useSelector(state => state.voucher.voucher?.is_early_invoice);

  const handleOrderChange = (oldIndex, newIndex) => {
    const local = [...allSortedConsiderations];
    local.splice(newIndex, 0, local.splice(oldIndex, 1)[0]);
    const considerationsReqBody = local.map((consideration, index) => ({ id: consideration.id, position_id: (index + 1), transaction: dealId }));
    reorderConsiderations(considerationsReqBody);
  };

  const onDeleteReorder = (id) => {
    const local = [...allSortedConsiderations];
    // eslint-disable-next-line max-len
    const considerationsReqBody = local.filter(consideration => consideration.id !== id).map((consideration, index) => ({ ...consideration, position_id: (index + 1) }));
    reorderConsiderations(considerationsReqBody);
  };

  // on page load, if there are no consideration after fetching, we add one
  useEffect(() => {
    if (!considerationIds.length && requestState === REQUEST_STATE.NONE && shouldCreate) {
      setShouldCreate(false);
      dispatch(createConsideration({
        amount: null,
        commission_amount: null,
        consideration_amount: 0,
        consideration_type: dealType === 'lease' ? 'Monthly Rent' : 'Purchase Price',
        transaction: dealId,
        total_units: null,
        position_id: 1,
      }));
    } else if (requestState === REQUEST_STATE.NONE && shouldCreate && considerationIds.length) {
      setShouldCreate(false);
    }
    setAllSortedConsiderations(allConsiderations.sort((a, b) => a.position_id - b.position_id));
    setIds(considerationIds);
  }, [considerationIds, ids, considerationIds.length, dealId, dealType, dispatch, shouldCreate, requestState, allConsiderations]);

  const leaseTermMatchConsideration = () => {
    const start = moment(leaseStartDate);
    const end = moment(leaseExpirationDate);
    // Considered one month if it's more than 3 weeks
    return Math.abs(end.diff(start, 'months', true) - totalTerms) <= 0.34;
  };
  const displayLeaseTermMatchConsideration = dealType === 'lease' && considerationIds.length && !leaseTermMatchConsideration();

  return (
    <div className="financials-section-wrapper">
      <h3>Consideration Breakdown</h3>
      <div className="table considerations-table">
        <div className="table-header">
          <div className="table-cell">Consideration Type</div>
          <div className="table-cell">{(dealType === 'lease') ? 'Period' : 'Quantity'}</div>
          <div className="table-cell">{(dealType === 'lease') ? 'Rent($)' : 'Price($)'}</div>
          <div className="table-cell">Consideration</div>
          <div className="table-cell">Commission Rate</div>
          <div className="table-cell">Commission</div>
          <div className="table-cell" />
        </div>
        <SortableList
          className="blx-sortable-list-reverse considerations-container"
          onDrop={handleOrderChange}
        >
          { considerationIds.map(id => (
            <ConsiderationItem className="considerations-single-item" key={id} id={id} showValidation={showValidation} onDeleteReorder={onDeleteReorder} />
          ))}
        </SortableList>

        {!shouldCreate && !considerationIds.length && requestState === REQUEST_STATE.NONE && (
          <p className="error-message">
            You must add at least one consideration to this {isEarlyInvoice ? 'early invoice' : 'voucher'}
          </p>
        )}
        <div>
          <AddItem
            label="Add Consideration"
            onClick={() => dispatch(createConsideration({
              amount: null,
              commission_amount: null,
              consideration_amount: 0,
              consideration_type: dealType === 'lease' ? 'Monthly Rent' : 'Purchase Price',
              transaction: dealId,
              total_units: null,
              position_id: (allSortedConsiderations.length + 1),
            }))}
          />
        </div>
        <div className="table-row table-footer">
          <div className="table-cell">Totals</div>
          <div className={`table-cell ${displayLeaseTermMatchConsideration ? 'warning-cell' : ''}`}>
            {totalTerms} Months
          </div>
          <div className="table-cell" />
          <div className="table-cell">
            <Currency
              value={totalConsiderations}
              emptyState="$0.00"
            />
          </div>
          <div className="table-cell" />
          <div className="table-cell">
            <Currency
              value={totalCommissions}
              emptyState="$0.00"
            />
          </div>
          <div className="table-cell" />
        </div>
      </div>
      {displayLeaseTermMatchConsideration &&
        <p className="warning-text">
          Warning: Total consideration length entered on the Financials tab does not match the lease term entered on the Lease Details tab.
        </p>
      }
    </div>
  );
};

Considerations.propTypes = {
  dealId: PropTypes.number.isRequired,
  dealType: PropTypes.string.isRequired,
  showValidation: PropTypes.bool.isRequired,
};

export default Considerations;

