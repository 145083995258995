import React from 'react';
import { Redirect } from 'react-router-dom';
import LabsContainer from './admin-labs-container'; // to be uncommented
import AdminTeamPageContainer from '../teams/admin-page-container';
import AdminLinkingContainer from './admin-linking-container';

const subroutes = [
  {
    path: '/admin/',
    exact: true,
    component: () => <Redirect to="/admin/labs" />,
  },
  {
    path: '/admin/labs',
    exact: true,
    component: LabsContainer,
  },
  {
    path: '/admin/teams',
    exact: true,
    component: AdminTeamPageContainer,
  },
  {
    path: '/admin/linking',
    exact: true,
    component: AdminLinkingContainer,
  },
];

export default subroutes;
