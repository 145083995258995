import {
  DEALS_LOAD_METADATA_SUCCESS,
  DEALS_LOAD_METADATA_FAILURE,
} from '../actions/action-types';

const initialState = {
  teams: [],
  users: [],
};

/**
 * Will use the commented code once the serializer is done.
 */
const loadDealsMetadata = (state, action) => {
  // const types = ['teams', 'users'];
  // const { arr: { teams, users } } = action.payload.reduce((acc, { permissions }) => {
  //   types.forEach((type) => {
  //     if (permissions[type]) {
  //       permissions[type].filter(item => !acc.map[type][item.id]).forEach((item) => {
  //         acc.map[type][item.id] = true;
  //         acc.arr[type].push(item);
  //       });
  //     }
  //   });
  //   return acc;
  // }, { map: { teams: {}, users: {} }, arr: { teams: [], users: [] } });
  const {
    teams,
    brokers,
    users: allUsers,
  } = action.payload;
  const userMap = {};
  allUsers.forEach((user) => {
    if (!userMap[user.id]) {
      userMap[user.id] = { ...user, email: { work: user.email } };
    }
  });
  brokers.forEach((user) => {
    // ignores ids that are NaN
    if (!userMap[user.id] && (+user.id).toString() !== 'NaN') {
      userMap[user.id] = { ...user, id: +user.id, email: { work: user.email } };
    }
  });
  const users = Object.keys(userMap).map(key => userMap[key]);

  return ({
    ...state,
    teams,
    users,
  });
};

const loadDealsMetadataFailure = state => ({
  ...state,
});

const dealsMetadata = (state = initialState, action) => {
  switch (action.type) {
    case DEALS_LOAD_METADATA_SUCCESS:
      return loadDealsMetadata(state, action);
    case DEALS_LOAD_METADATA_FAILURE:
      return loadDealsMetadataFailure(state);
    default:
      return state;
  }
};

export default dealsMetadata;
