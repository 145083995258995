import { connect } from 'react-redux';
import {
  addTask,
  completeTask,
  deleteTask,
  fetchTasks,
  updateTask,
} from '../../../redux/actions/deal-tasks-actions';
import TasksTab from './tasks-tab';

const mapStateToProps = state => ({
  deal: state.deal.deal,
  isLoading: state.dealTasks.isLoading,
  next: state.dealTasks.next,
  tasks: state.dealTasks.tasks,
  count: state.dealTasks.count,
  user: state.userData.data,
});

const mapDispatchToProps = {
  addTask,
  completeTask,
  deleteTask,
  fetchTasks,
  updateTask,
};

const mergeProps = (state, actions, props) => ({
  ...state,
  ...actions,
  ...props,
  analyticProperties: {
    actionPrefix: `${props.analyticProperties.actionPrefix}_tasks_tab`,
    categoryPrefix: `${props.analyticProperties.categoryPrefix}Tasks Tab `,
  },
});

const TasksTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(TasksTab);

export default TasksTabContainer;
