import AnnualChart from './annual-chart';
import MyPayments from './my-payments';
import MyInstallments from './my-installments';

const subroutes = [
  {
    path: '/commissions/annual_chart',
    exact: true,
    component: AnnualChart,
  },
  {
    path: '/commissions/my_payments',
    exact: true,
    component: MyPayments,
  },
  {
    path: '/commissions/installments',
    exact: true,
    component: MyInstallments,
  },
];

export default subroutes;
