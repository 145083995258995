import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Icon, ToggleSwitch } from '@cbrebuild/blocks';
import uniqueId from 'lodash/uniqueId';
import debounce from 'lodash/debounce';

import Modal from '../../nucleus/modal/modal';
import dealsService from '../../services/deals-service';
import teamMembersService from '../../services/team-members-service';
import DealsActionRow from '../property-details/deals-action-row';
import EmptyState from '../empty-state/empty-state';
import Notice from '../../nucleus/notices/notice';
import DealMembers from '../headers/deal-detail-header/deal-members';
import LoadMore from '../../nucleus/load-more/load-more';

class BulkAddDealsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deals: [],
      selectedDeals: [],
      selectedOn: false,
      queryParams: {
        building: null,
        conversion_potential_max: 100,
        conversion_potential_min: 0,
        date_approved_max: null,
        date_approved_min: null,
        date_lease_to_max: null,
        date_lease_to_min: null,
        deal_life_cycle_stage: [],
        is_archived: false,
        ordering: '-modified',
        page: 1,
        page_size: 10000,
        rep_role: [],
        search: '',
        tags: [],
        team_not: this.props.teamId,
      },
      initQueryParams: {
        building: null,
        conversion_potential_max: 100,
        conversion_potential_min: 0,
        date_approved_max: null,
        date_approved_min: null,
        date_lease_to_max: null,
        date_lease_to_min: null,
        deal_life_cycle_stage: [],
        is_archived: false,
        ordering: '-modified',
        page: 1,
        page_size: 10000,
        rep_role: [],
        search: '',
        tags: [],
        team_not: this.props.teamId,
      },
      currentTeam: {
        id: this.props.teamId,
        name: this.props.teamName,
        users: this.props.teamMembers,
        avatar_color: this.props.teamColor,
      },
      isLoadingDeals: true,
      dealsCount: 0,
      allDealTags: [],
      showAllDeals: false,
      pageSize: 100,
      lastRequestId: undefined,
      dealsWithoutTeams: [],
    };
    this.debounceHandleFetchDeals = debounce(() => {
      this.handleFetchDeals();
    }, 500);
  }

  componentDidMount() {
    this.getAllDealsCount();
    this.fetchAllTags();
  }

  addTeamToDeal = (deal) => {
    const {
      deals,
      selectedDeals,
      currentTeam,
    } = this.state;
    const dealsToUpdate = [...deals];
    const dealToUpdate = dealsToUpdate.find(element => element.id === deal.id);
    if (!dealToUpdate.permissions.teams.find(t => t.id === currentTeam.id)) {
      dealToUpdate.permissions.teams = [currentTeam, ...dealToUpdate.permissions.teams];
      dealsToUpdate[dealsToUpdate.indexOf(deal)] = dealToUpdate;
      this.setState({
        deals: dealsToUpdate,
      });
    }
    if (!selectedDeals.find(element => element.id === deal.id)) {
      this.setState({
        selectedDeals: [...selectedDeals, deal],
      });
    }
  };

  addTeamToAllDeals = () => {
    const {
      deals,
      currentTeam,
    } = this.state;
    const updatedDeals = deals.map((deal) => {
      if (!deal.permissions.teams.find(t => t.id === currentTeam.id)) {
        deal.permissions.teams.unshift(currentTeam);
        return deal;
      }
      return deal;
    });
    this.setState({
      deals: [...updatedDeals],
      selectedDeals: [...updatedDeals],
    });
  };

  addTeamToDealsWithNoTeams = () => {
    const {
      deals,
      currentTeam,
    } = this.state;
    const dealsWithNoTeams = deals.filter(deal => !this.dealHasOtherTeams(deal));
    const updatedDeals = deals.map((deal) => {
      if (!this.dealHasOtherTeams(deal) && !deal.permissions.teams.find(t => t.id === currentTeam.id)) {
        deal.permissions.teams.unshift(currentTeam);
        return deal;
      }
      return deal;
    });
    const updatedSelectedDeals = dealsWithNoTeams.map((deal) => {
      if (!deal.permissions.teams.find(t => t.id === currentTeam.id)) {
        deal.permissions.teams.unshift(currentTeam);
        return deal;
      }
      return deal;
    });
    this.setState({
      deals: [...updatedDeals],
      selectedDeals: [...updatedSelectedDeals],
    });
  };

  confirmAddTeamsToDeals = () => {
    const {
      selectedDeals,
    } = this.state;
    const {
      teamName,
      teamId,
      addToDealCount,
    } = this.props;
    const addTeamToDealsData = selectedDeals.map(deal => ({
      deal_id: deal.id,
      user_id: null,
      team_id: teamId,
    }));

    teamMembersService.addDealMember(addTeamToDealsData)
      .then(() => {
        addToDealCount(selectedDeals.length);
        this.handleCloseModal({
          message: `Added ${teamName} to ${selectedDeals.length} ${selectedDeals.length === 1 ? 'deal' : 'deals'}`,
          numberOfDeals: selectedDeals.length,
          success: true,
          operation: 'Add',
        });
      })
      .catch(() => {
        this.handleCloseModal({
          message: `Failed to add ${teamName} to ${selectedDeals.length} ${selectedDeals.length === 1 ? 'deal' : 'deals'}. Please try again`,
          success: false,
        });
      });
  };

  fetchAllTags = () => {
    dealsService.fetchDealTags()
      .then((data) => {
        this.setState({
          allDealTags: data.tags_on_all_deals,
        });
      })
      .catch();
  };

  countDealsSelectedWithTeams = () => !!this.state.selectedDeals.filter(deal => this.dealHasOtherTeams(deal)).length;

  countDealsWithNoTeams = () => this.state.deals.filter(deal => !this.dealHasOtherTeams(deal)).length;

  fetchDealsService = dealsService.fetchDealsAndTransactionsES;

  getAllDealsCount = () => {
    this.fetchDealsService({
      ...this.state.initQueryParams,
      page_size: 1,
    })
      .then((response) => {
        this.setState(
          { dealsCount: response.count },
          () => this.handleFetchDeals(),
        );
      })
      .catch(this.setState({ isLoadingDeals: false }));
  };

  handleCloseModal = (params) => {
    this.props.closeModal(params);
  };

  handleFetchDeals = () => {
    const requestId = uniqueId();
    this.setState({
      deals: [],
      queryParams: {
        ...this.state.queryParams,
        page: 1,
      },
      isLoadingDeals: true,
      lastRequestId: requestId,
    }, () => {
      this.fetchDealsService({
        ...this.state.queryParams,
        page_size: this.state.dealsCount || 10000,
      })
        .then((response) => {
          if (requestId === this.state.lastRequestId) {
            const deals = response.results
              // Temporarily filter out deals that the user is just a commissioned user
              .filter(({
                permissions: { denormalized_user_ids = [] } = {},
              }) => denormalized_user_ids.includes(this.props.userData.id))
              .map((deal) => {
                if (!deal.permissions.teams) {
                  const updatedDeal = deal;
                  updatedDeal.permissions.teams = [];
                  return updatedDeal;
                }
                return deal;
              });
            const dealsWithoutTeams = deals.filter(deal => deal.permissions.teams.length === 0);
            this.setState({
              deals,
              isLoadingDeals: false,
              pageSize: 100,
              dealsWithoutTeams,
            });
          }
        })
        .catch(() => {
          this.setState({
            deals: [],
            isLoadingDeals: false,
            dealsWithoutTeams: [],
            pageSize: 100,
          });
        });
    });
  };

  handleFilterChange = (params) => {
    const {
      queryParams,
    } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        ...params,
      },
    }, () => this.handleFetchDeals());
  };

  handleTagClick = (tag) => {
    const { queryParams } = this.state;

    if (!queryParams.tags.includes(tag)) {
      const params = {
        ...queryParams,
        tags: [...queryParams.tags, tag],
      };

      this.handleFilterChange(params);
    }
  };

  handleSearchTerm = (search) => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        search,
      },
    }, () => this.debounceHandleFetchDeals());
  };

  handleSortingKeyChange = (ordering) => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        page: 1,
        ordering,
      },
    }, () => this.handleFetchDeals());
  };

  loadMore = () => {
    this.setState({
      pageSize: this.state.pageSize + 100,
    });
  };

  removeTeamFromDeal = (deal) => {
    const {
      deals,
      selectedDeals,
      currentTeam,
    } = this.state;
    const dealToUpdate = deals.find(element => element.id === deal.id);
    dealToUpdate.permissions.teams = dealToUpdate.permissions.teams.filter(t => t.id !== currentTeam.id);
    deals[deals.indexOf(deal)] = dealToUpdate;
    this.setState({
      deals,
    });
    if (selectedDeals.find(element => element.id === deal.id)) {
      const rest = selectedDeals.filter(element => element.id !== deal.id);
      this.setState({
        selectedDeals: rest,
      }, () => {
        if (this.state.selectedOn && this.state.selectedDeals.length === 0) {
          this.selectedOnChange();
        }
      });
    }
  };

  removeTeamFromAllDeals = () => {
    const {
      deals,
      currentTeam,
    } = this.state;
    const updatedDeals = deals.map((deal) => {
      const updatedDeal = deal;
      updatedDeal.permissions.teams = deal.permissions.teams.filter(t => t.id !== currentTeam.id);
      return updatedDeal;
    });
    this.setState({
      selectedDeals: [],
      selectedOn: false,
      deals: updatedDeals,
    });
  };

  selectedOnChange = () => {
    this.setState({
      selectedOn: !this.state.selectedOn,
      pageSize: 100,
    });
  };

  changeShowAllDeals = () => {
    this.setState({
      showAllDeals: !this.state.showAllDeals,
      pageSize: 100,
      selectedDeals: this.state.showAllDeals ? this.state.selectedDeals.filter(selected => (
        !selected.permissions.teams.filter(t => t.id !== this.props.teamId).length
      )) : this.state.selectedDeals,
    });
  };

  dealHasOtherTeams = (deal) => {
    const teamsOnDeal = deal.permissions.teams;
    return !!teamsOnDeal.filter(team => team.id !== this.props.teamId).length;
  };

  renderLoadMore = () => {
    const {
      selectedOn,
      deals,
      selectedDeals,
      showAllDeals,
      pageSize,
      dealsWithoutTeams,
    } = this.state;
    return (
      <LoadMore
        handleLoadMore={this.loadMore}
        /* eslint-disable-next-line no-nested-ternary */
        hasNextPage={!selectedOn ?
          (showAllDeals ? deals.length > pageSize : dealsWithoutTeams.length > pageSize) :
          selectedDeals.length > pageSize}
      />
    );
  };

  groupSelectionControls = (numDealsWOTeams) => {
    const {
      showAllDeals,
      selectedDeals,
      deals,
      selectedOn,
    } = this.state;
    const dealCount = showAllDeals ? deals.length : numDealsWOTeams;
    const indeterminate = selectedDeals.length > 0 && selectedDeals.length < dealCount;
    const allSelected = selectedDeals.length === dealCount;
    const addTeam = showAllDeals ? this.addTeamToAllDeals : this.addTeamToDealsWithNoTeams;

    return (
      <div className="bulk-add-modal-count">
        <Checkbox
          checked={allSelected}
          indeterminate={indeterminate}
          onChange={({ target: { checked } }) => (!checked ? this.removeTeamFromAllDeals() : addTeam())}
        >{allSelected ? 'Unselect All' : 'Select All'}
        </Checkbox>
        {selectedDeals.length > 0 &&
        <ToggleSwitch
          checked={selectedOn}
          onChange={this.selectedOnChange}
        >
          Selected only
        </ToggleSwitch>}
        {!!deals.length && deals.length !== numDealsWOTeams &&
          <ToggleSwitch
            checked={showAllDeals}
            onChange={this.changeShowAllDeals}
          >
            Include Deals With Teams
          </ToggleSwitch>
        }
      </div>
    );
  };

  renderDealRow = (deal) => {
    const isSelected = this.state.selectedDeals.find(element => element.id === deal.id) ? 'selected' : '';
    const dealUsers = deal.permissions.users ? deal.permissions.users.map((individual) => {
      const user = {
        id: individual.id,
        avatar: individual.avatar,
        email: individual.email,
        first_name: individual.first_name,
        last_name: individual.last_name,
      };
      const member = {
        user,
      };
      return member;
    }) : [];
    const linkToDeal = () => {
      window.open(`/deals/detail/${deal.id}/overview`, '_blank');
    };
    const dealTeams = deal.permissions.teams || [];

    return (
      <li key={deal.id} className={`bulk-add-modal-item ${isSelected} ${deal.deal_life_cycle_stage}`}>
        <div className="bulk-add-modal-add-button">
          <Checkbox
            checked={isSelected}
            onChange={({ target: { checked } }) => (checked ? this.addTeamToDeal(deal) : this.removeTeamFromDeal(deal))}
          />
        </div>
        <div className="bulk-add-modal-deal-name"><span className="bulk-add-modal-deal-name-link" onClick={linkToDeal}>{deal.name}</span></div>
        <div className="bulk-add-modal-deal-teams">
          <DealMembers
            analyticProperties={this.props.analyticProperties}
            dealUsers={dealUsers}
            dealTeams={dealTeams}
            maxAvatarsToShow={6}
            showAddOrRemove={false}
            alignHover="right"
            hideTeamMemberAvatars
          />
        </div>
      </li>);
  };

  renderModalContent = () => {
    const {
      deals,
      selectedOn,
      selectedDeals,
      showAllDeals,
      pageSize,
      dealsWithoutTeams,
      isLoadingDeals,
      dealsCount,
    } = this.state;
    return (
      <div className="bulk-add-modal-content">
        {!isLoadingDeals && !selectedOn && showAllDeals &&
            deals.slice(0, pageSize).map(deal =>
              (selectedDeals.find(element => element.id === deal.id) ?
                this.renderDealRow(selectedDeals.find(element => element.id === deal.id)) :
                this.renderDealRow(deal)))}
        {!isLoadingDeals && !selectedOn && !showAllDeals &&
            dealsWithoutTeams.slice(0, pageSize).map(deal =>
              // eslint-disable-next-line no-nested-ternary
              (!this.dealHasOtherTeams(deal) ?
                (selectedDeals.find(element => element.id === deal.id) ?
                  this.renderDealRow(selectedDeals.find(element => element.id === deal.id)) :
                  this.renderDealRow(deals.find(element => element.id === deal.id))) :
                ''))}
        {!isLoadingDeals && selectedOn && selectedDeals.length > 0 && selectedDeals.slice(0, pageSize).map(deal => this.renderDealRow(deal))}
        {!isLoadingDeals && !deals.length && dealsCount === 0 &&
        <EmptyState
          type="deals"
          message="All deals have this team on them"
        />}
        {!isLoadingDeals && !deals.length && dealsCount !== 0 &&
        <EmptyState
          type="deals"
          message="There are no deals that match this filter/search. Please change the filter/search"
        />}
        {!isLoadingDeals && deals.length !== 0 && !this.countDealsWithNoTeams() && !showAllDeals &&
        <EmptyState
          type="deals"
          message={'All deals have other teams on them. Turn on the "Deals with Teams" filter to see all deals.'}
        />}
        {this.renderLoadMore()}
      </div>
    );
  };

  render() {
    const {
      teamName,
      analyticProperties,
      featureFlags,
    } = this.props;
    const {
      isLoadingDeals,
      initQueryParams,
      queryParams,
      allDealTags,
      showAllDeals,
      selectedDeals,
    } = this.state;

    const numDealsWOTeams = this.countDealsWithNoTeams();

    return (
      <Modal
        modalHeader={`Add ${teamName} to Deals`}
        className="bulk-add-deals-modal"
        showModal
        handleModalToggle={this.handleCloseModal}
        hideModalControls
        modalWidth={1080}
      >
        <DealsActionRow
          key={0}
          allDealTags={allDealTags}
          handleFilterChange={this.handleFilterChange}
          handleSearchTerm={this.handleSearchTerm}
          handleSortingKeyChange={this.handleSortingKeyChange}
          isLoading={isLoadingDeals}
          queryParams={queryParams}
          initQueryParams={initQueryParams}
          analyticProperties={analyticProperties}
          featureFlags={featureFlags}
        />
        {this.groupSelectionControls(numDealsWOTeams)}

        {(showAllDeals || this.countDealsSelectedWithTeams()) &&
          <div className="bulk-add-deals-modal-warning">
            <Notice
              onDismiss={() => {}}
              type="warning"
              text="By enabling this filter and selecting deals, you run a greater risk of sharing deals between teams you are on."
            />
          </div>
        }
        <div className="bulk-add-modal-header">
          <div className="bulk-add-modal-deal">Deal</div>
          <div className="bulk-add-modal-team">Collaborators</div>
        </div>

        {this.renderModalContent()}

        <div className="bulk-add-deals-modal-footer">
          {selectedDeals.length > 0 &&
            <div className="bulk-add-deals-notice">
              <p><Icon iconName="info-circle" />Adding {teamName} to {selectedDeals.length} {selectedDeals.length === 1 ? 'deal' : 'deals'}</p>
            </div>
          }
          <div className="bulk-add-deals-modal-controls">
            <Button
              onClick={this.handleCloseModal}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={this.confirmAddTeamsToDeals}
              className={selectedDeals.length === 0 ? 'disabled' : ''}
              disabled={selectedDeals.length === 0}
            >
              Confirm
            </Button>
          </div>

        </div>
      </Modal>
    );
  }
}

BulkAddDealsModal.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  teamId: PropTypes.number.isRequired,
  teamName: PropTypes.string,
  teamMembers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  teamColor: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  addToDealCount: PropTypes.func.isRequired,
  analyticProperties: PropTypes.shape().isRequired,
  featureFlags: PropTypes.shape({}),
};

BulkAddDealsModal.defaultProps = {
  teamName: '',
  teamColor: '',
  featureFlags: {},
};

export default BulkAddDealsModal;
