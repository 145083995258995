import {
  CLEAR_DEAL_STORE,
  LOAD_DEAL_SUCCESS,
  LOAD_DEAL_FAILURE,
  IS_LOADING_DEAL,
  UPDATE_DEAL_SUCCESS,
  UPDATE_DEAL_FAILURE,
  UPDATE_DEAL_SUCCESS_ES,
  UPDATE_DEAL_FAILURE_ES,
  UPDATE_DEAL_CLIENT_SUCCESS,
  UPDATE_DEAL_CLIENT_FAILURE,
  UPDATE_DEAL_REQUIREMENTS_SUCCESS,
  UPDATE_DEAL_REQUIREMENTS_FAILURE,
  FAVORITE_DEAL_FAILURE,
  UNFAVORITE_DEAL_FAILURE,
} from '../actions/action-types';

const initialState = {
  deal: undefined,
  error: undefined,
  favoriteError: undefined,
  isLoading: false,
  unfavoriteError: undefined,
};

const clearStore = () => ({
  ...initialState,
});

const loadDealSuccess = (state, action) => ({
  ...state,
  deal: action.payload.deal,
});

const loadDealFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const isLoadingDeal = (state, action) => ({
  ...state,
  isLoading: action.payload.isLoading,
});

const updateDealSuccess = (state, action) => ({
  ...state,
  deal: action.payload.deal,
});

const updateDealFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const updateDealSuccessES = (state, action) => ({
  ...state,
  deal: {
    ...state.deal,
    ...action.payload.params,
  },
});

const updateDealFailureES = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const updateDealClientSuccess = (state, action) => ({
  ...state,
  deal: {
    ...state.deal,
    client: action.payload.client,
  },
});

const updateDealClientFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const updateDealRequirementsSuccess = (state, action) => ({
  ...state,
  deal: {
    ...state.deal,
    requirements: action.payload.requirements,
  },
});

const updateDealRequirementsFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const favoriteDealFailure = (state, action) => ({
  ...state,
  favoriteError: action.payload.error,
});

const unfavoriteDealFailure = (state, action) => ({
  ...state,
  unfavoriteError: action.payload.error,
});

const deal = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_DEAL_STORE:
      return clearStore();
    case LOAD_DEAL_SUCCESS:
      return loadDealSuccess(state, action);
    case LOAD_DEAL_FAILURE:
      return loadDealFailure(state, action);
    case IS_LOADING_DEAL:
      return isLoadingDeal(state, action);
    case UPDATE_DEAL_SUCCESS:
      return updateDealSuccess(state, action);
    case UPDATE_DEAL_FAILURE:
      return updateDealFailure(state, action);
    case UPDATE_DEAL_SUCCESS_ES:
      return updateDealSuccessES(state, action);
    case UPDATE_DEAL_FAILURE_ES:
      return updateDealFailureES(state, action);
    case UPDATE_DEAL_CLIENT_SUCCESS:
      return updateDealClientSuccess(state, action);
    case UPDATE_DEAL_CLIENT_FAILURE:
      return updateDealClientFailure(state, action);
    case UPDATE_DEAL_REQUIREMENTS_SUCCESS:
      return updateDealRequirementsSuccess(state, action);
    case UPDATE_DEAL_REQUIREMENTS_FAILURE:
      return updateDealRequirementsFailure(state, action);
    case FAVORITE_DEAL_FAILURE:
      return favoriteDealFailure(state, action);
    case UNFAVORITE_DEAL_FAILURE:
      return unfavoriteDealFailure(state, action);
    default:
      return state;
  }
};

export default deal;
