import unionBy from 'lodash/unionBy';

import {
  ADD_DEAL_TASK_SUCCESS,
  ADD_DEAL_TASK_FAILURE,
  CLEAR_DEAL_STORE,
  DELETE_DEAL_TASK_SUCCESS,
  DELETE_DEAL_TASK_FAILURE,
  IS_LOADING_TASKS_ACTION,
  FETCH_DEAL_TASKS_SUCCESS,
  FETCH_DEAL_TASKS_FAILURE,
  UPDATE_DEAL_TASK_SUCCESS,
  UPDATE_DEAL_TASK_FAILURE,
} from '../actions/action-types';

const initialState = {
  count: 0,
  error: undefined,
  isLoading: false,
  next: null,
  previous: null,
  tasks: [],
};

const addDealTaskSuccess = (state, { payload: task }) => ({
  ...state,
  count: state.count + 1,
  tasks: [task, ...state.tasks],
});

const clearStore = () => ({
  ...initialState,
});

const deleteDealTaskFailure = (state, { payload: { id } }) => ({
  ...state,
  count: state.count - 1,
  tasks: state.tasks.filter(task => task.id !== id),
});

const fetchDealTasksSuccess = (state, action) => {
  const {
    count,
    next,
    previous,
    results,
  } = action.payload;
  const tasks = previous === null
    ? results
    : unionBy(state.tasks, results, 'id');
  return ({
    ...state,
    count,
    next,
    tasks,
    previous,
  });
};

const updateDealTaskSuccess = (state, { payload: task }) => ({
  ...state,
  tasks: state.tasks
    .map(t => (t.id === task.id ? task : t)),
});

const isLoading = (state, action) => ({
  ...state,
  isLoading: action.payload.isLoading,
});

const dealTasksFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const dealTasks = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEAL_TASK_SUCCESS:
      return addDealTaskSuccess(state, action);
    case CLEAR_DEAL_STORE:
      return clearStore();
    case DELETE_DEAL_TASK_SUCCESS:
      return deleteDealTaskFailure(state, action);
    case FETCH_DEAL_TASKS_SUCCESS:
      return fetchDealTasksSuccess(state, action);
    case UPDATE_DEAL_TASK_SUCCESS:
      return updateDealTaskSuccess(state, action);
    case IS_LOADING_TASKS_ACTION:
      return isLoading(state, action);
    case ADD_DEAL_TASK_FAILURE:
    case DELETE_DEAL_TASK_FAILURE:
    case UPDATE_DEAL_TASK_FAILURE:
    case FETCH_DEAL_TASKS_FAILURE:
      return dealTasksFailure(state, action);
    default:
      return state;
  }
};

export default dealTasks;
