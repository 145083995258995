import React from 'react';
import PropTypes from 'prop-types';
import TeamAvatar from './team-avatar';

const TeamTile = ({
  hoverAlign,
  showHover,
  team: {
    name,
    avatar_color: teamColor,
    users,
  },
}) => ((
  <div className="team-tile">
    <TeamAvatar
      teamName={name}
      teamColor={teamColor}
    />
    <div className="team-textbox">
      <p className="primary-content">{name}</p>
      <p className="hoverstate secondary-content">{users.length} {users.length === 1 ? 'member' : 'members'}
        {showHover && (
          <span className={`hoverstatetext align-${hoverAlign}`}>
            {users.map(teamMember => (
              <li key={teamMember.id}>{teamMember.first_name} {teamMember.last_name}</li>
            ))}
          </span>
        )}
      </p>
    </div>
  </div>
));

TeamTile.propTypes = {
  team: PropTypes.shape({
    name: PropTypes.string,
    avatar_color: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.shape()),
  }),
  showHover: PropTypes.bool,
  hoverAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

TeamTile.defaultProps = {
  team: {
    name: '',
    avatar_color: '',
    users: [],
  },
  showHover: false,
  hoverAlign: 'center',

};

export default TeamTile;
