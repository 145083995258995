import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, RadioButton, Icon } from '@cbrebuild/blocks';
import PercentDollarInput from '../../../nucleus/inputs/PercentDollarInput';
import FormGroup from '../../../nucleus/form-group/form-group';
import useFormState, { EVENT_VALUE_TYPES } from '../../../hooks/useFormState';
import Select from '../../../nucleus/select/select';
import { updateVoucher } from '../../../redux/actions/voucher/voucher-actions';
import { updateDealPipelineTimestamp } from '../../../redux/actions/deal-actions';
import ManagingOfficeAutocomplete from '../../autocomplete/managing-office-autocomplete';
import FunctionalUnitAutocomplete from '../../autocomplete/functional-unit-autocomplete';
import { deleteCommission, updateCommission } from '../../../redux/actions/commissions-actions';
import { selectTotalCommissionsForCommissions } from '../../../redux/selectors/voucher-selectors';
import Tooltip from '../../../nucleus/tooltip/tooltip';

const selectCommissionById = (state, id) => (
  state.commissions.commissions.find(commission => commission.id === id)
);

const typeOverrides = {
  voucher_gross_commission_percent: EVENT_VALUE_TYPES.FLOAT,
  gross_commission: EVENT_VALUE_TYPES.FLOAT,
  broker_is_lead: EVENT_VALUE_TYPES.BOOLEAN,
};

const OfficeCommissionItem = ({
  dealId,
  voucherId,
  id,
  showValidation,
  cbre_commission_is_percent,
}) => {
  const commission = useSelector(state => selectCommissionById(state, id));
  const options = useSelector(state => state.options) || {
    producer_role: [],
  };
  const baseTotal = useSelector(selectTotalCommissionsForCommissions);
  const dispatch = useDispatch();

  const {
    deptid,
    gross_commission,
    officename,
    producer_role,
    voucher_gross_commission_percent,
    voucher_office_allocation_name,
    voucher_office_client_name,
    onStateChange, onStateChanged,
  } = useFormState({
    broker_is_lead: commission.broker_is_lead,
    deptid: commission.deptid,
    gross_commission: commission.gross_commission,
    officename: commission.officename,
    producer_role: commission.producer_role,
    voucher_gross_commission_percent: commission.voucher_gross_commission_percent,
    voucher_office_allocation_name: commission.voucher_office_allocation_name,
    voucher_office_client_name: commission.voucher_office_client_name,
  }, changes => dispatch(updateCommission(id, changes)), typeOverrides);

  const onDelete = () => {
    dispatch(deleteCommission(id));
  };

  const onUpdateVoucher = ({ target: { name, value } }) => {
    dispatch(updateVoucher(voucherId, { [name]: value }));
    dispatch(updateDealPipelineTimestamp(dealId));
  };
  const functionalTipMessage = 'Type or scroll within the list to search and enter the Functional Unit name/code.';
  const officeTipMessage = 'Type or scroll within the list to search and enter the Managing Office name/code.';

  const infoTooltipMessage = message => (
    <Tooltip message={message}>
      <Icon iconName="info-circle" />
    </Tooltip>
  );

  const invalidGrossCommission = (showValidation || gross_commission.dirty) && !gross_commission.value;
  const invalidProducerRole = (showValidation || producer_role.dirty) && !producer_role.value;
  const invalidDeptId = (showValidation || deptid.dirty) && !!officename.value && !deptid.value;
  const invalidOfficename = (showValidation || officename.dirty) && !!deptid.value && !officename.value;

  return (
    <div className="form-row-with-labels-and-two-line-hints">
      <div className="full-fumo">
        <div className="nonbroker-dropdown functional-unit">
          <FormGroup
            label={<> Functional Unit <span className="noCapitalize"> {infoTooltipMessage(functionalTipMessage)}</span></>}
            hasError
            isRequired={invalidDeptId}
            errorMessage="Functional Unit is Required"
          >
            <FunctionalUnitAutocomplete
              deptid={deptid.value}
              onSelect={e => onStateChanged({
                  target: {
                    value: typeof e === 'object' ? e.functional_unit_code : '',
                    name: 'deptid',
                    type: 'text',
                  },
                })}
              placeholder="Select"
              errorMessage={invalidDeptId ? ' ' : undefined}
            />
          </FormGroup>
        </div>
        <div className="nonbroker-dropdown office">
          <FormGroup
            label={<>Managing Office <span className="noCapitalize">{infoTooltipMessage(officeTipMessage)}</span></>}
            isRequired={invalidOfficename}
            hasError
            errorMessage="Office is Required"
          >
            <ManagingOfficeAutocomplete
              voucher_office_allocation_name={voucher_office_allocation_name.value}
              onSelect={e => onStateChanged({
                  target: {
                    value: typeof e === 'object' ? {
                      officename: e.managing_office_code,
                      voucher_office_allocation_name: e.displayText,
                    } : {
                      officename: '',
                      voucher_office_allocation_name: null,
                    },
                    name: ['officename', 'voucher_office_allocation_name'],
                    type: 'text',
                  },
                })}
              placeholder="Select"
              errorMessage={invalidOfficename ? ' ' : undefined}
            />
          </FormGroup>
        </div>
      </div>
      <div className="label-display">
        <FormGroup label="Client Name (If Applicable)" size="medium" className="due-upon-field" >
          <input
            type="text"
            name="voucher_office_client_name"
            value={voucher_office_client_name.value || ''}
            onChange={onStateChange}
            onBlur={onStateChanged}
          />
        </FormGroup>
      </div>
      <PercentDollarInput
        isRequired={invalidGrossCommission}
        label={`${invalidGrossCommission ? '' : '* '}amount`}
        name={{
            percentage: 'voucher_gross_commission_percent',
            value: 'gross_commission',
            select: 'cbre_commission_is_percent',
          }}
        className={invalidGrossCommission ? 'errorInput' : ''}
        onTypeChange={onUpdateVoucher}
        onChange={onStateChange}
        onBlur={onStateChanged}
        baseTotal={baseTotal}
        percentage={voucher_gross_commission_percent.value}
        value={gross_commission.value}
        isPercent={cbre_commission_is_percent}
        maxPercent={100}
        maxPercentWarningText="Warning: The commission split exceeds 100%."
      />
      <FormGroup label="Producer Role" size="large" isRequired hasError={invalidProducerRole} errorMessage="Required" className="due-upon-field">
        <Select
          name="producer_role"
          defaultOption={options.producer_role.find(option => option.value === producer_role.value)}
          onChange={({ value }) => onStateChanged({
              target: { value, name: 'producer_role', type: 'text' },
            })}
          options={options.producer_role}
        />
      </FormGroup>
      <RadioButton
        name="broker_is_lead"
        checked={commission.broker_is_lead}
        onChange={onStateChanged}
      >
        Lead Producer
      </RadioButton>
      <IconButton className="blxs-button-icon-small" iconName="close-circle" onClick={onDelete} variant="basic" />
    </div>
  );
};

OfficeCommissionItem.propTypes = {
  id: PropTypes.number.isRequired,
  dealId: PropTypes.number.isRequired,
  voucherId: PropTypes.number.isRequired,
  showValidation: PropTypes.bool.isRequired,
  cbre_commission_is_percent: PropTypes.bool.isRequired,
};

export default OfficeCommissionItem;
