import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, IconButton } from '@cbrebuild/blocks';

import Progress from '../../nucleus/progress/progress';

const FileToUploadListItem = (props) => {
  const {
    file,
    onFileNameChange,
    onPrivacyChange,
    onRemove,
  } = props;
  const {
    name,
    uploadProgress,
  } = file;

  return (
    <React.Fragment>
      <li>
        <input
          onChange={e => onFileNameChange(e.target.value)}
          type="text"
          value={name}
        />
        <div className="file-item-actions">
          <Checkbox
            checked={file.isPrivate}
            onChange={({ target: { checked } }) => onPrivacyChange(checked)}
          >Private
          </Checkbox>
          <IconButton
            iconName="close"
            onClick={onRemove}
            variant="basic"
          />
        </div>
      </li>
      {!!uploadProgress && (
        <Progress progress={uploadProgress} />
      )}
    </React.Fragment>
  );
};

FileToUploadListItem.propTypes = {
  file: PropTypes.shape({
    fileData: PropTypes.shape({
      lastModified: PropTypes.number,
      lastModifiedDate: PropTypes.shape({}),
      name: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
      webkitRelativePath: PropTypes.string,
    }),
    isPrivate: PropTypes.bool,
    name: PropTypes.string,
    uploadProgress: PropTypes.number,
  }).isRequired,
  onFileNameChange: PropTypes.func.isRequired,
  onPrivacyChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default FileToUploadListItem;
