import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import {
  FETCH_COMMISSIONS_SUCCESS,
  UPDATE_COMMISSION_SUCCESS,
  CREATE_COMMISSION_SUCCESS,
  DELETE_COMMISSION_SUCCESS,
  CLEAR_FINANCIALS,
} from '../../actions/action-types';

const initialState = [];

const commissions = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMISSIONS_SUCCESS:
      return action.payload;
    case UPDATE_COMMISSION_SUCCESS: {
      const updatedIndex = findIndex(
        state,
        commission => commission.id === action.payload.id,
      );
      const stateCopy = [...state];
      stateCopy[updatedIndex] = action.payload;
      return stateCopy;
    }
    case CREATE_COMMISSION_SUCCESS: {
      const stateCopy = [...state];
      stateCopy.push(action.payload);
      return stateCopy;
    }
    case DELETE_COMMISSION_SUCCESS: {
      const stateCopy = [...state];
      remove(stateCopy, commission => commission.id === action.payload);
      return stateCopy;
    }
    case CLEAR_FINANCIALS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default commissions;
