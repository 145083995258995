
import React from 'react';
import PropTypes from 'prop-types';

import ButtonSegmented from '../button-segmented/button-segmented';

const ButtonSegmentedGroup = (props) => {
  const handleChange = (option) => {
    if (props.isMultiSelect) {
      const remove = Boolean(props.selected.find(s => s.value === option.value));
      if (remove) {
        props.onChange(props.selected.filter(s => s.value !== option.value));
      } else {
        props.onChange([...props.selected, option]);
      }
    } else {
      props.onChange(option);
    }
  };

  const {
    isMultiSelectGrid,
    isMultiSelect,
    options,
    selected,
  } = props;

  const gridLayoutClass = (isMultiSelectGrid) ? ' multi-select-grid' : '';
  const isOddClass = (options.length % 2) ? ' is-odd' : '';

  const renderOptions = options && options.map((option) => {
    const isActive = isMultiSelect
      ? Boolean(selected.find(s => s.value === option.value))
      : selected.value === option.value;
    const isDisabled = option.disabled === true;
    return (
      <ButtonSegmented
        isActive={isActive}
        isDisabled={isDisabled}
        isMultiSelect={isMultiSelect}
        key={option.value}
        dataE2e="button-segmented"
        onChange={handleChange}
        option={option}
      />
    );
  });

  return (
    <div className={`nd-button-segmented-group${gridLayoutClass}${isOddClass}`}>
      {renderOptions}
    </div>
  );
};

ButtonSegmentedGroup.propTypes = {
  isMultiSelectGrid: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    display_name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
    ]),
    value: PropTypes.string,
  })).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      display_name: PropTypes.string,
      value: PropTypes.string,
    })),
    PropTypes.shape({
      display_name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({}),
      ]),
      value: PropTypes.string,
    }),
  ]),
};

ButtonSegmentedGroup.defaultProps = {
  isMultiSelectGrid: false,
  isMultiSelect: false,
  selected: {},
};

export default ButtonSegmentedGroup;
