import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import { Button, Icon } from '@cbrebuild/blocks';

import Tag from '../../nucleus/legacy/tag/tag';
import userEventService from '../../services/user-event-service';

const SearchResult = (props) => {
  const {
    index,
    result,
    tags,
    closeFlyout,
    turducken,
  } = props;

  const {
    id,
    name,
    primary_deal_contact: primaryDealContact = {},
    deal_ids,
    deal_life_cycle_stage,
    is_archived,
    deal_progress,
  } = result;

  const trackSearchInteraction = () => {
    // tracks search interaction
    userEventService.trackEvent({
      eventAction: 'global_search_interaction', eventCategory: 'Global Search', eventLabel: index,
    });
    closeFlyout();
  };

  // _get checks if a key exists on an object, if that key has a value
  // of undefined, get allows you to declare a default value
  const givenName = get(primaryDealContact, 'given_name', '');
  const surName = get(primaryDealContact, 'surname', '');
  const emailAddressesArray = get(primaryDealContact, 'email_addresses', []);
  const phoneNumbersArray = get(primaryDealContact, 'phone_numbers', []);

  // empty states
  const nameEmptyState = <p className="name-empty-state">Missing Primary Contact</p>;

  // state checks for name, email, phoneNumber, and tags
  const primaryContactName = givenName || surName ?
    <p className="name">{givenName} {surName}</p> : nameEmptyState;

  const email = emailAddressesArray && emailAddressesArray.length > 0 ? (
    <Button linkable variant="text">
      <a className="email" href={`mailto:${emailAddressesArray[0].address}`}>
        <Icon iconName="envelope" />
      </a>
    </Button>
  ) : '';
  const phoneNumber = phoneNumbersArray && phoneNumbersArray.length > 0 ? (
    <a className="phone-number" href={`tel:${phoneNumbersArray[0].number}`}>
      {phoneNumbersArray[0].number}
    </a>
  ) : '';
  const tag = tags ? tags.map(_tag => <Tag key={uniqueId()} tag={_tag} />) : '';
  const dealStageText = () => {
    if (is_archived) {
      return 'Archived';
    } else if (deal_life_cycle_stage === 'prospect') {
      return `${deal_life_cycle_stage.charAt(0).toUpperCase()}${deal_life_cycle_stage.slice(1)}ing`;
    } else if (deal_life_cycle_stage === 'executing' && deal_progress !== 'Passive Dialog') {
      return `${deal_life_cycle_stage.charAt(0).toUpperCase()}${deal_life_cycle_stage.slice(1)} - ${deal_progress}`;
    }
    return deal_life_cycle_stage.charAt(0).toUpperCase() + deal_life_cycle_stage.slice(1);
  };
  return (
    <li className={is_archived ? 'search-result is-archived' : 'search-result'}>
      <div className="deal">
        <Link
          className="search-result-name"
          onClick={trackSearchInteraction}
          to={`/deals/detail/${id}/overview`}
          {...(turducken ? { target: '_self' } : {})}
        >
          {name}
        </Link>
        <div className="p deal-stage-container">
          <div className={`deal-status-indicator ${deal_life_cycle_stage}`} />
          {dealStageText()}
        </div>
        {primaryContactName}
        {(deal_life_cycle_stage === 'closed' && deal_ids && deal_ids.length > 0) &&
          <span className="mta">
            ID: {deal_ids.join(', ')}
          </span>
        }
        <div className="contact-info">
          {phoneNumber} {email}
        </div>
      </div>
      <div className="tags">
        {tag}
      </div>
    </li>
  );
};

SearchResult.propTypes = {
  result: PropTypes.shape({
    deal_ids: PropTypes.arrayOf(PropTypes.number),
    deal_life_cycle_stage: PropTypes.string,
    deal_progress: PropTypes.string,
    id: PropTypes.number,
    is_archived: PropTypes.bool,
    name: PropTypes.string,
    primary_deal_contact: PropTypes.shape({
      given_name: PropTypes.string,
      surName: PropTypes.string,
    }),
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  closeFlyout: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  turducken: PropTypes.bool.isRequired,
};

SearchResult.defaultProps = {
  tags: [],
};

export default SearchResult;

