import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon } from '@cbrebuild/blocks';
import Property from '../property/property';
import PaymentStatusIndicator from './payment-status-indicator';
import formatPaymentDate from '../../utils/format-payment-date';
import Currency from '../../nucleus/formats/currency';
import Tooltip from '../../nucleus/tooltip/tooltip';

const MyInstallmentsTableRow = ({ installment }) => (
  <tr>
    <td>
      <Property
        building={installment.full_transaction.final_properties[0]}
        callToAction={null}
      />
    </td>
    <td>{installment.installment_number}</td>
    <td>
      <div>{moment.utc(installment.installment_date).format('MMM DD, YYYY')}</div>
    </td>
    <td>
      {installment.payment_status === 'Write Off' ?
        <div>
          <span>{installment.payment_status}</span>
          {installment.payment_status === 'Write Off' &&
          <Tooltip message="
              This installment is a write off. Please contact Business Operations for more info."
          >
            <Icon iconName="info-circle" />
          </Tooltip>}
        </div> :
        <div>
          <PaymentStatusIndicator status={installment.payment_status} />
          <span>{formatPaymentDate(installment)}</span>
          {installment.payment_status === 'Paid' && installment.receivable_amount > 0.0 &&
          <Tooltip message="
              This installment was partially paid. Please contact Business Operations for more info."
          >
            <Icon iconName="info-circle" />
          </Tooltip>}
        </div>
      }
    </td>
    <td>
      <Currency value={installment.installment_amount} />
    </td>
    <td>
      {installment.related_deal_id ?
        <a href={`/deals/detail/${installment.related_deal_id}/commissions`} target="_self">{installment.related_deal_name}</a> :
        <a href={`/deals/detail/${installment.full_transaction.id}/commissions`} target="_self">{installment.full_transaction.name}</a>}
      <div>{installment.deal_id}</div>
    </td>
  </tr>
);

MyInstallmentsTableRow.propTypes = {
  installment: PropTypes.shape().isRequired,
};

const MyInstallmentsTable = ({ installments, isLoading }) => (
  <table className="my-installments-table" data-testid="installments-table">
    <colgroup>
      <col name="final property" width="20%" />
      <col name="installment #" width="10%" />
      <col name="date" width="15%" />
      <col name="status" width="15%" />
      <col name="gross" width="15%" />
      <col name="deal" width="25%" />
    </colgroup>
    <tbody>
      <tr className="dark-table-row">
        <th>Final Property</th>
        <th>Installment #</th>
        <th>Due Date</th>
        <th>Payment Status</th>
        <th>Amount</th>
        <th>Deal</th>
      </tr>
      {installments.map(installment => (
        <MyInstallmentsTableRow
          key={installment.id}
          installment={installment}
        />
      ))}
      {!installments.length && !isLoading && (
        <tr className="empty-state-row">
          <td colSpan="6" >
            No commissions data found with current filter.
            Please adjust your filter to see results.
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

export default MyInstallmentsTable;

MyInstallmentsTable.propTypes = {
  installments: PropTypes.arrayOf(PropTypes.shape({
    deal_id: PropTypes.string,
    full_transaction: PropTypes.shape({
      final_properties: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    id: PropTypes.number,
    installment_amount: PropTypes.number,
    installment_number: PropTypes.number,
    payment_status: PropTypes.string,
    receivable_amount: PropTypes.string,
  })).isRequired,
  isLoading: PropTypes.bool,
};

MyInstallmentsTable.defaultProps = {
  isLoading: false,
};
