import React from 'react';
import PropTypes from 'prop-types';
import AutocompleteFlyout from '../autocomplete/autocomplete-flyout';
import dealsService from '../../services/deals-service';

class DealAutocomplete extends React.Component {
  getName = result => result.name;

  renderListItem = result => <div>{result.name}</div>;

  render() {
    const {
      onChange,
      errorMessage,
      placeholder,
      initSearchTerm,
    } = this.props;

    return (
      <AutocompleteFlyout
        initSearchTerm={initSearchTerm}
        fetchRequest={dealsService.fetchDeals}
        requestParams={{ page_size: 25 }}
        onChange={onChange}
        placeholder={placeholder}
        renderListItem={this.renderListItem}
        findSelectedResultAttribute={this.getName}
        errorMessage={errorMessage}
      />
    );
  }
}

export default DealAutocomplete;

DealAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  initSearchTerm: PropTypes.string,
};

DealAutocomplete.defaultProps = {
  errorMessage: '',
  placeholder: '',
  initSearchTerm: '',
};
