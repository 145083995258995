import { connect } from 'react-redux';

import { loadNotifications, loadTaskAlerts } from '../actions/alerts-actions';

import TopNav from '../../components/navs/top-nav';

const mapStateToProps = state => ({
  userData: state.userData.data,
  userOptions: state.userOptions.userOptions,
  proxy: state.proxy.proxySettings,
});

const mapDispatchToProps = dispatch => ({
  setTaskAlertsState: params => dispatch(loadTaskAlerts(params)),
  setNotificationsState: params => dispatch(loadNotifications(params)),
});

const TopNavContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopNav);

export default TopNavContainer;
