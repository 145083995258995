import contactsService from '../../services/contacts-service';
import {
  ADD_DEAL_CONTACT_SUCCESS,
  ADD_DEAL_CONTACT_FAILURE,
  DELETE_DEAL_CONTACT_SUCCESS,
  DELETE_DEAL_CONTACT_FAILURE,
  IS_LOADING_CONTACTS_ACTION,
  FETCH_DEAL_CONTACTS_SUCCESS,
  FETCH_DEAL_CONTACTS_FAILURE,
  UPDATE_DEAL_CONTACT_SUCCESS,
  UPDATE_DEAL_CONTACT_FAILURE,
} from './action-types';
import { toast } from './toasts-actions';

const addContactSuccess = contact => ({
  type: ADD_DEAL_CONTACT_SUCCESS,
  payload: { ...contact },
});

const deleteContactSuccess = id => ({
  type: DELETE_DEAL_CONTACT_SUCCESS,
  payload: { id },
});

const fetchContactsSuccess = response => ({
  type: FETCH_DEAL_CONTACTS_SUCCESS,
  payload: { ...response },
});

const isLoadingAction = isLoading => ({
  type: IS_LOADING_CONTACTS_ACTION,
  payload: { isLoading },
});

const updateContactSuccess = contact => ({
  type: UPDATE_DEAL_CONTACT_SUCCESS,
  payload: { ...contact },
});

const handleError = (error, type) => ({
  type,
  payload: { error },
});

const addPersonalContact = params => (dispatch) => {
  contactsService.postPersonalContact(params)
    .catch(() => {
      dispatch(toast('Error Adding Personal Contact'));
    });
};

const addDealContact = params => dispatch =>
  contactsService.postContact(params)
    .then((contact) => {
      dispatch(addContactSuccess(contact));
      if (params.create_personal_contact) {
        dispatch(addPersonalContact(contact));
      }
      if (params.display_toast) {
        dispatch(toast('Contact Added'));
      }
    }).catch((error) => {
      dispatch(handleError(error, ADD_DEAL_CONTACT_FAILURE));
      if (params.display_toast) {
        dispatch(toast('Error Adding Contact'));
      }
    });

const deleteDealContact = (id, displayToast) => dispatch =>
  contactsService.deleteContact(id)
    .then(() => {
      dispatch(deleteContactSuccess(id));
      if (displayToast) {
        dispatch(toast('Contact Deleted'));
      }
    }).catch((error) => {
      dispatch(handleError(error, DELETE_DEAL_CONTACT_FAILURE));
      if (displayToast) {
        dispatch(toast('Error Deleting Contact'));
      }
    });

const fetchDealContacts = query => (dispatch) => {
  dispatch(isLoadingAction(true));
  return contactsService.getContacts(query)
    .then(response => dispatch(fetchContactsSuccess(response)))
    .catch((error) => {
      dispatch(handleError(error, FETCH_DEAL_CONTACTS_FAILURE));
      dispatch(toast('Error Fetching Contacts'));
    })
    .then(() => dispatch(isLoadingAction(false)));
};

const updateDealContact = (id, params) => dispatch =>
  contactsService.patchContact(id, params)
    .then((contact) => {
      dispatch(updateContactSuccess(contact));
      if (params.display_toast) {
        dispatch(toast('Contact Updated'));
      }
    }).catch((error) => {
      dispatch(handleError(error, UPDATE_DEAL_CONTACT_FAILURE));
      if (params.display_toast) {
        dispatch(toast('Error Updating Contact'));
      }
    });

export {
  addDealContact,
  deleteDealContact,
  fetchDealContacts,
  updateDealContact,
  addPersonalContact,
};
