import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon } from '@cbrebuild/blocks';
import Currency from '../../../../nucleus/formats/currency';
import ExpandToggle from '../../../../nucleus/expand-toggle/expand-toggle';
import Tooltip from '../../../../nucleus/tooltip/tooltip';

const CommissionsDetailInstallments = (props) => {
  const [expandedInstallments, setExpandedInstallments] = useState({});

  const formatUTCDate = date => (moment.utc(date).format('M/D/YYYY'));

  const getBorderClass = (installment) => {
    switch (installment.payment_status) {
      case 'Paid':
        return 'green-border';
      case 'Receivable':
        return 'red-border';
      case 'Upcoming':
        return 'blue-border';
      default:
        return '';
    }
  };

  const getTextClass = installment => (installment.payment_status === 'Receivable' ? 'past-due-text' : '');

  const showPaidOrReceivable = installment => (installment.payment_status === 'Paid' ||
  installment.payment_status === 'Write Off' ? installment.paid_amount : '');
  const showPaidDateOrDaysPastDue = (installment) => {
    if (installment.paid_date) {
      return moment(installment.paid_date).format('M/D/YYYY');
    }

    if (installment.payment_status === 'Receivable') {
      const today = moment(new Date());
      const pastDueDate = moment(new Date(installment.installment_date));
      return `${today.diff(pastDueDate, 'days')} Days Past Due`;
    }

    return '';
  };

  return (
    <table>
      <colgroup>
        <col name="expand" style={{ width: 'fit-content' }} />
        <col name="installment #" width="15%" />
        <col name="due date" width="20%" />
        <col name="installment amount" width="20%" />
        <col name="paid amount" width="20%" />
        <col name="date paid" width="25%" />
      </colgroup>
      <tbody>
        <tr className="borderless-row">
          <th />
          <th>Installment #</th>
          <th>Due Date</th>
          <th>Installment Amount</th>
          <th>Paid Amount</th>
          <th>Date Paid</th>
        </tr>
        {props.installments.map(installment => (
          <React.Fragment key={installment.id}>
            <tr className={`${getBorderClass(installment)} ${expandedInstallments[installment.id] && 'borderless-row'} text-dark text-semi-bold`} >
              <td>
                {((installment.payment_status === 'Write Off' && installment.installment_compensation.cbre_cash_distribution)
                  || (installment.payment_status !== 'Write Off')) &&
                    <ExpandToggle
                      onClick={() => setExpandedInstallments({
                        ...expandedInstallments,
                        [installment.id]: !expandedInstallments[installment.id],
                      })}
                      isOpen={expandedInstallments[installment.id]}
                    /> }
              </td>
              <td>{installment.installment_number}</td>
              <td>{formatUTCDate(installment.installment_date)}</td>
              <td><Currency value={Number(installment.installment_amount)} /></td>
              <td><Currency value={Number(showPaidOrReceivable(installment))} /></td>
              <td className={getTextClass(installment)}>
                {installment.payment_status === 'Write Off' ?
                  <div>
                    <span>Write Off</span>
                    <Tooltip message="This installment is a write off. Please contact Business Operations for more info.">
                      <Icon iconName="info-circle" />
                    </Tooltip>
                  </div> :
                  <div>
                    <span>{showPaidDateOrDaysPastDue(installment)}</span>
                    {installment.payment_status === 'Paid' && installment.receivable_amount > 0.0 &&
                    <Tooltip message="
                        This installment was partially paid. Please contact Business Operations for more info."
                    >
                      <Icon iconName="info-circle" />
                    </Tooltip>}
                  </div>
                }
              </td>
            </tr>
            {((installment.payment_status === 'Write Off' && installment.installment_compensation.cbre_cash_distribution)
            || (installment.payment_status !== 'Write Off'))
            && expandedInstallments[installment.id] && (
              <React.Fragment>
                <tr className="sub-row borderless-row">
                  <td />
                  <td />
                  <td>CBRE</td>
                  <td><Currency value={installment.installment_compensation.cbre_cash_distribution} /></td>
                  <td />
                  <td />
                </tr>
                <tr className="sub-row borderless-row">
                  <td />
                  <td />
                  <td>Rebate, Fee Share, 3rd Party</td>
                  <td><Currency value={installment.installment_compensation.other_distribution} /></td>
                  <td />
                  <td />
                </tr>
                <tr className="sub-row borderless-row">
                  <td />
                  <td />
                  <td>&mdash;</td>
                  <td />
                  <td />
                </tr>
                <tr className="sub-row borderless-row">
                  <td />
                  <td />
                  <td>My Gross</td>
                  <td><Currency value={installment.installment_compensation.broker_gross_payment} /></td>
                  <td />
                  <td />
                </tr>
                <tr className="sub-row">
                  <td />
                  <td />
                  <td>My Net</td>
                  <td><Currency value={installment.installment_compensation.broker_net_payment} /></td>
                  <td />
                  <td />
                </tr>
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default CommissionsDetailInstallments;

CommissionsDetailInstallments.propTypes = {
  installments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

