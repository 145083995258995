import React from 'react';
import PropTypes from 'prop-types';
import { Person, PersonType } from '../person';
import { fetchBrokers, fetchSharedEmails } from '../../services/brokers-service';

import AutocompleteFlyout from '../autocomplete/autocomplete-flyout';

class VoucherRecipientAutocomplete extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    placeholder: PropTypes.string,
    /** function to filter out search results, e.g.
     * filtering out existing team members when adding new ones  */
    filterResultsPredicate: PropTypes.func,
    /** The string you want to use in the input to represent a broker  */
    initSearchTerm: PropTypes.string,
    label: PropTypes.string,
    inputLabel: PropTypes.string,
    isSharedEmailAutocomplete: PropTypes.bool,
  };

  static defaultProps = {
    errorMessage: '',
    placeholder: 'Add Voucher Recipient',
    filterResultsPredicate: null,
    initSearchTerm: '',
    label: 'email',
    inputLabel: '',
    isSharedEmailAutocomplete: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: props.initSearchTerm,
    };
  }

  /**
   * Enhances the onSelect funtion before sending back
   * A broker cannot be added while searching so only objects are allowed
   * no strings or null typeof variable will return 'string' when its a string
   */
  onSelect = (broker) => {
    const {
      onSelect,
    } = this.props;
    if (broker && typeof broker !== 'string') {
      onSelect(broker);
    } else if (this.props.initSearchTerm || this.props.initSearchTerm === '') {
      this.setState({ searchTerm: broker });
      onSelect();
    }
  }

  getName = (broker) => {
    if (this.props.initSearchTerm) {
      return `${broker.first_name} ${broker.last_name}`;
    }
    return '';
  };

  renderListItem = result => (
    <Person
      person={result}
      type={PersonType.MEMBER_AUTOCOMPLETE}
      hoverAlign="left"
    />
  );

  renderFooterContent = () => (
    <p onClick={() =>
      this.props.onSelect(this.props.initSearchTerm || this.state.searchTerm)}
    >
      {`Add ${this.props.label}: "${this.props.initSearchTerm || this.state.searchTerm}"`}
    </p>
  );

  render() {
    const {
      errorMessage,
      placeholder,
      filterResultsPredicate,
      inputLabel,
      isSharedEmailAutocomplete,
    } = this.props;

    return (
      <AutocompleteFlyout
        initSearchTerm={this.props.initSearchTerm || this.state.searchTerm}
        fetchRequest={isSharedEmailAutocomplete ? fetchSharedEmails : fetchBrokers}
        filterResultsPredicate={filterResultsPredicate}
        requestParams={{ page_size: 25 }}
        onChange={this.onSelect}
        placeholder={placeholder}
        findSelectedResultAttribute={this.getName}
        renderFooterContent={this.renderFooterContent}
        renderListItem={this.renderListItem}
        empty="Some User Found"
        label={inputLabel}
        minSearchLength={2}
        errorMessage={errorMessage}
        dataE2e="new-deal-broker-textbox"
      />
    );
  }
}

export default VoucherRecipientAutocomplete;
