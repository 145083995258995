import React from 'react';
import PropTypes from 'prop-types';

import AlertsEmptyState from './alerts-empty-state';
import Badge from '../../nucleus/badge/badge';
import LoadMore from '../../nucleus/load-more/load-more';
import Flyout from '../../nucleus/flyout/flyout';
import TaskAlertItem from './task-alert-item';
import Toast from '../../nucleus/toast/toast';
import NDButton from '../../nucleus/button/button';

import userEventService from '../../services/user-event-service';

class TaskAlerts extends React.Component {
  state = {
    showFlyout: false,
    page: 1,
    showToast: false,
    toastMessage: '',
  };

  componentDidMount() {
    const { setTaskAlertsState } = this.props;
    setTaskAlertsState(); // get task alerts
    this.onSetInterval(); // begin polling the API
  }

  shouldComponentUpdate(nextProps) {
    const {
      snoozeMessage,
      dismissMessage,
      clearSnoozeMessage,
      clearDismissMessage,
      isPushEnabled,
    } = nextProps;
    const {
      snoozeMessage: prevSnoozeMessage,
      dismissMessage: prevDismissMessage,
    } = this.props;

    if (prevSnoozeMessage !== snoozeMessage && snoozeMessage) {
      this.handleToast(snoozeMessage, clearSnoozeMessage);
    }
    if (prevDismissMessage !== dismissMessage && dismissMessage) {
      this.handleToast(dismissMessage, clearDismissMessage);
    }
    if (isPushEnabled) { this.onClearInterval(); } // disable polling
    return true;
  }

  componentWillUnmount() {
    this.onClearInterval();
  }

  onSetInterval = () => {
    const { setTaskAlertsState } = this.props;
    this.alertHandle = setInterval(() => {
      const jwt = localStorage.getItem('JWT');
      if (!jwt) {
        this.onClearInterval();
      } else if (!this.state.showFlyout) {
        setTaskAlertsState();
      }
    }, 60000);
  };

  onClearInterval = () => {
    clearInterval(this.alertHandle);
  };

  alertHandle = null;

  handleDismissAlert = (taskID) => {
    const {
      tasks,
      taskAlertsMetadata,
      dismissAndLoadNextTask,
      dismissAlert,
    } = this.props;
    if (taskAlertsMetadata.hasNextPage) {
      dismissAndLoadNextTask(taskID, tasks.length);
    } else {
      dismissAlert(taskID);
    }
  };

  handleDismissTaskAndAlert = (taskID) => {
    const {
      tasks,
      taskAlertsMetadata,
      dismissAndLoadNextTask,
      dismissTask,
    } = this.props;
    if (taskAlertsMetadata.hasNextPage) {
      dismissAndLoadNextTask(taskID, tasks.length);
    } else {
      dismissTask(taskID);
    }
  };

  handleSnoozeAlert = (taskID, updatedTask, snoozeTime) => {
    const {
      snoozeTask,
      snoozeAndLoadNextTask,
      tasks,
      taskAlertsMetadata,
    } = this.props;
    if (taskAlertsMetadata.hasNextPage) {
      snoozeAndLoadNextTask(taskID, updatedTask, snoozeTime, tasks.length);
    } else {
      snoozeTask(taskID, updatedTask, snoozeTime);
    }
  };

  handleToast = (toastMessage, clearMessage) => {
    this.setState({
      showToast: true,
      toastMessage,
    });
    setTimeout(() => {
      this.setState({
        showToast: false,
        toastMessage: '',
      });
      clearMessage();
    }, 800);
  };

  handleLoadMoreButton = () => {
    const { setTaskAlertsState } = this.props;
    this.setState({
      page: this.state.page += 1,
    }, () => setTaskAlertsState({ page: this.state.page }));
  };

  // track when a user opens task alerts when badge count
  // exists. also track when a user opens task alerts
  // when badge count does not exist
  handleBadgeCountTrackingEvent = () => {
    const {
      taskAlertsMetadata,
    } = this.props;

    const eventLabel = taskAlertsMetadata.badgeCount
      ? 'task_alerts_opened_with_badge_count'
      : 'task_alerts_opened_without_badge_count';
    const eventMetadata = {
      eventCategory: 'Task Alerts',
      eventLabel,
      eventAction: 'task_alerts_opened',
      eventData: {
        badgeCount: taskAlertsMetadata.badgeCount,
      },
    };
    userEventService.trackEvent(eventMetadata);
  };

  openFlyout = () => {
    this.setState({
      showFlyout: true,
    });
    this.handleBadgeCountTrackingEvent();
  };

  closeFlyout = () => {
    this.setState({
      showFlyout: false,
      page: 1,
    }, () => this.markAsRead());
    const eventMetadata = {
      eventCategory: 'Task Alerts',
      eventAction: 'task_alerts_closed',
      eventLabel: 'task_alerts_closed_from_task',
    };
    userEventService.trackEvent(eventMetadata);
  };

  formatDate = (date) => {
    const dateStringArray = new Date(date).toDateString().split(' ');
    const weekday = dateStringArray[0];
    const restOfDate = dateStringArray.slice(1).join(' ');
    const formattedDate = `${weekday}, ${restOfDate}`;
    return formattedDate;
  };

  handleNavigateToTaskTrackingEvent = () => {
    const eventMetadata = {
      eventCategory: 'Task Alerts',
      eventAction: 'navigated_to_task',
      eventLabel: 'navigated_to_task_from_alerts',
    };
    userEventService.trackEvent(eventMetadata);
    this.closeFlyout();
  };

  markAsRead = () => {
    // markAsRead batch processes tasks using the object_id rather than alert.id
    // this method takes an array of objectIDs and stringifies them
    // so we can POST them to the API in the correct format.
    // We track task.id (alertIDs) so we can markTaskAsReadPending
    // and update local state until the next API poll occurs
    const {
      tasks,
      setMarkTaskAsReadState,
      setTaskAlertsState,
    } = this.props;
    const alertIDs = [];
    tasks.forEach((task) => {
      if (task.read === null) {
        alertIDs.push(task.id);
      }
    });
    this.setState({
      page: 1,
    });
    if (alertIDs.length > 0) {
      setMarkTaskAsReadState(alertIDs);
    } else {
      setTaskAlertsState();
    }
  };

  render() {
    const {
      isLoadingTaskAlerts,
      tasks,
      taskAlertsMetadata,
      isPushEnabled,
      turducken,
    } = this.props;

    const {
      showFlyout,
      showToast,
      toastMessage,
    } = this.state;

    const renderTaskAlertsItem = tasks.length ? tasks.map(task => (
      <TaskAlertItem
        key={task.id}
        handleDismissAlert={this.handleDismissAlert}
        handleSnoozeAlert={this.handleSnoozeAlert}
        handleDismissTaskAndAlert={this.handleDismissTaskAndAlert}
        formatDate={this.formatDate}
        handleNavigateToTaskTrackingEvent={this.handleNavigateToTaskTrackingEvent}
        task={task}
        turducken={turducken}
      />
    )) : <AlertsEmptyState type="task" />;

    const renderToast = showToast === true ? <Toast message={toastMessage} /> : '';

    let iconClick = this.openFlyout;
    if (isPushEnabled && Notification.permission !== 'granted') {
      iconClick = null;
    }

    return (
      <React.Fragment>
        <NDButton
          variant="icon"
          icon="alarm"
          aria-label="Task Alerts"
          onClick={iconClick}
        >
          <Badge
            count={taskAlertsMetadata.badgeCount}
            displayBadge={!showFlyout}
          />
        </NDButton>
        <Flyout
          additionalClassName="task-alerts-flyout"
          showFlyout={showFlyout}
          closeFlyout={this.closeFlyout}
        >
          <p className="tasks-alerts-header">Task Alerts</p>
          <ul className="task-alerts">
            {renderTaskAlertsItem}
          </ul>
          <LoadMore
            handleLoadMore={this.handleLoadMoreButton}
            isLoading={isLoadingTaskAlerts}
            hasNextPage={taskAlertsMetadata.hasNextPage}
          />
        </Flyout>
        {renderToast}
      </React.Fragment>
    );
  }
}

export default TaskAlerts;

TaskAlerts.propTypes = {
  isLoadingTaskAlerts: PropTypes.bool,
  setMarkTaskAsReadState: PropTypes.func,
  setTaskAlertsState: PropTypes.func.isRequired,
  snoozeTask: PropTypes.func.isRequired,
  snoozeAndLoadNextTask: PropTypes.func.isRequired,
  clearSnoozeMessage: PropTypes.func.isRequired,
  snoozeMessage: PropTypes.string,
  dismissTask: PropTypes.func.isRequired,
  dismissAlert: PropTypes.func.isRequired,
  dismissAndLoadNextTask: PropTypes.func.isRequired,
  clearDismissMessage: PropTypes.func.isRequired,
  dismissMessage: PropTypes.string,
  tasks: PropTypes.arrayOf(PropTypes.shape({
    read: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    id: PropTypes.number,
  })).isRequired,
  taskAlertsMetadata: PropTypes.shape({
    hasNextPage: PropTypes.number,
    badgeCount: PropTypes.number,
  }),
  isPushEnabled: PropTypes.bool,
  turducken: PropTypes.bool.isRequired,
};

TaskAlerts.defaultProps = {
  snoozeMessage: '',
  dismissMessage: '',
  isLoadingTaskAlerts: false,
  setMarkTaskAsReadState: () => { },
  taskAlertsMetadata: {
    hasNextPage: 0,
    badgeCount: 0,
  },
  isPushEnabled: false,
};

