import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, RadioButton, Icon, Button } from '@cbrebuild/blocks';
import useCommercialPropertyState from '../../../hooks/useCommercialPropertyState';
import { getAndSetLastPageVisited } from '../voucher-validation';
import AddressForm from '../AddressForm';
import Select from '../../../nucleus/select/select';
import PropertyAutocomplete from '../../autocomplete/property-autocomplete';
import vouchersService from '../../../services/voucher/vouchers-service';
import Tooltip from '../../../nucleus/tooltip/tooltip';

const PropertyLeaseTab = ({
  deal,
  voucher,
}) => {
  const [showValidation, setShowValidation] = useState(false);
  const [showMore, setShowMore] = useState({});
  const [linkedProperties, setLinkedProperties] = useState({});
  const [propertyOptions, setPropertyOptions] = useState([{ display_name: 'Select', value: null }]);
  const {
    properties, dealProperties, onPropertyBlur, onPropertyChange, onPropertyDelete,
    onSpaceBlur, onSpaceChange, onSpaceDelete, onDealPropertySelect, onDealPropertyAdd,
    onCommercialPropertyClear,
  } = useCommercialPropertyState({ dealId: deal.id, createIfEmpty: true });

  const validatePage = () => {
    const currentPageNumber = 2.1;
    const lastPageVisited = getAndSetLastPageVisited(voucher.id, currentPageNumber);

    if (lastPageVisited >= currentPageNumber) {
      setShowValidation(true);
    }
  };

  const addProperty = (id, property) => {
    onDealPropertyAdd(id, property, property.name);
  };

  useEffect(() => {
    vouchersService.getPropertyOptions().then((res) => {
      if (res.actions.POST.voucher_property_type_category.choices.length > 0 && propertyOptions.length === 1) {
        setPropertyOptions(propertyOptions.concat(res.actions.POST.voucher_property_type_category.choices));
      }
    });
    validatePage();
  }, [propertyOptions, validatePage]);

  const handleShowMore = (cpId) => {
    setShowMore(current => ({
      ...current, [cpId]: !current[cpId],
    }));
  };

  const handleLinkedProperty = (cpId, dp) => {
    onDealPropertySelect(cpId, dp);
    setLinkedProperties(current => ({
      ...current, [cpId]: dp.id,
    }));
  };

  const handleClearProperty = (cpId) => {
    onCommercialPropertyClear(cpId);
    setLinkedProperties(current => ({
      ...current, [cpId]: null,
    }));
  };

  const onRemapPropertyEvent = (e, callback) => {
    const { target: { type, name, value } } = e;
    if (name === 'postal') {
      callback({
        ...e,
        target: {
          ...e.target, name: 'postal_code', type, value,
        },
      });
    } else if (name === 'territory') {
      callback({
        ...e,
        target: {
          ...e.target, name: 'state_abbreviation', type, value,
        },
      });
    } else {
      callback(e);
    }
  };

  return (
    <div>
      <h2> New Property Information</h2>
      <div className="voucher-property">
        {properties.map((cp, index) => (
          <form key={cp.id}>
            <div>
              <legend>New Property #{index + 1}
                {properties.length > 1 &&
                  <span className="icon-align">
                    <Icon className="closeIcon" onClick={() => onPropertyDelete(cp.id)} iconName="close-circle" size="large" />
                  </span>}
              </legend>
            </div>
            <div>
              <ul className="property-list">
                {dealProperties && dealProperties.filter(dp => linkedProperties[cp.id] !== dp.id).map((dp, i) => ((i >= 2 && !showMore[cp.id]) ? null :
                  (
                    <li className="property-list-style" onClick={() => handleLinkedProperty(cp.id, dp)}>
                      <div className="person-component">
                        <div className="person-textbox">
                          <h4>{dp.name}</h4>
                          <p>{dp.building.full_address}</p>
                        </div>
                      </div>
                      <div>
                        <span className="add-icon">
                          <Icon iconName="plus-circle-outline" size="large" />
                        </span>
                      </div>
                    </li>
                  )))
                }
              </ul>
            </div>
            <span>
              {dealProperties.length > (linkedProperties[cp.id] ? 3 : 2) &&
                <React.Fragment>
                  <span className="poc-text">
                    {showMore[cp.id] ?
                      <span className="show-more" onClick={() => handleShowMore(cp.id)}>
                        <Icon iconName="minus-square icon-square" size="small" /> <span className="show-text">Show Less</span>
                      </span> :
                      <span className="show-more" onClick={() => handleShowMore(cp.id)}>
                        <Icon iconName="plus-square icon-square" size="small" />
                        <span className="show-text">Show More</span> ({dealProperties.length - (linkedProperties[cp.id] ? 3 : 2)})
                      </span>}
                  </span>
                </React.Fragment>}
            </span>
            <legend className="secondary-legend">Is Property Confidential?</legend>
            <RadioGroup
              name="is_property_confidential"
              className="radioVal"
              selectedValue={`${cp.is_property_confidential}`}
              onChange={onPropertyChange(cp.id)}
              orientation="vertical"
            >
              <RadioButton value="false">No</RadioButton>
              <RadioButton value="true">Yes</RadioButton>
            </RadioGroup>
            <legend className="secondary-legend">Is this the whole building, or suites in a building?</legend>
            <RadioGroup
              name="whole_building"
              className="radioVal"
              selectedValue={`${cp.whole_building}`}
              onChange={onPropertyChange(cp.id)}
              orientation="vertical"
            >
              <RadioButton value="true">Whole Building</RadioButton>
              <RadioButton value="false">Suite(s)</RadioButton>
            </RadioGroup>
            <div className="div-align">
              <label>Search for Property
                <Tooltip
                  message={
                    <>
                      <h3>Property Search Tip</h3>
                      <br />
                      <p>Try entering a street address, intersection, and include the city name.</p>
                    </>
                    }
                >
                  <Icon iconName="info-circle" />
                </Tooltip>
              </label>
              <PropertyAutocomplete
                errorMessage=""
                onSelect={property => addProperty(cp.id, property)}
              />
            </div>
            <div className="field">
              <label>Property Name</label>
              <input
                type="text"
                name="name"
                value={cp.name || ''}
                onChange={onPropertyChange(cp.id)}
                onBlur={onPropertyBlur(cp.id)}
              />
            </div>
            <AddressForm
              address={cp.address || ''}
              city={cp.city || ''}
              country={cp.country || ''}
              postal={cp.postal_code || ''}
              territory={cp.state_abbreviation || ''}
              onChange={e => onRemapPropertyEvent(e, onPropertyChange(cp.id))}
              onBlur={e => onRemapPropertyEvent(e, onPropertyBlur(cp.id))}
              isRelocating
              showValidation={showValidation}
            />
            <div className="field select-subtype">
              <label>
                <span className={(cp.voucher_property_type_category === '' && showValidation) ?
                  'asterisk-error' : ''}
                >*
                </span> Property Type / Subtype
              </label>
              <Select
                className={cp.voucher_property_type_category === '' ? 'errorInput' : ''}
                defaultOption={cp.voucher_property_type_category || 'Select'}
                options={propertyOptions.filter(opt => opt.value !== '')}
                onChange={e => onPropertyChange(cp.id)({
                  target: {
                    type: 'select',
                    name: 'voucher_property_type_category',
                    value: e.value === null ? '' : e.value,
                  },
                })}
              />
              {(cp.voucher_property_type_category === '' && showValidation) &&
                <p style={{ marginTop: '10px' }} className="asterisk-error">Property Type / Subtype is required</p>}
            </div>
            <div className="field">
              <label>Mix Property ID</label>
              <input
                type="text"
                name="mix_property_id"
                value={cp.mix_property_id || ''}
                onChange={onPropertyChange(cp.id)}
                onBlur={onPropertyBlur(cp.id)}
              />
            </div>
            {linkedProperties[cp.id] && <Button className="btn-clear clr-prop" onClick={() => handleClearProperty(cp.id)}>Clear</Button>}
            {!cp.whole_building && cp.spaces.map((space, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${cp.id}-${i}`}>
                <legend>Suite #{i + 1}
                </legend>
                <div className="form-row">
                  <div className="field-group col-3">
                    <label>Suite</label>
                    <input
                      type="text"
                      name="address_line2"
                      value={space.address_line2 || ''}
                      onChange={onSpaceChange(cp.id, i)}
                      onBlur={onSpaceBlur(cp.id, i)}
                    />
                  </div>
                  <div className="field-group col-3">
                    <label>Floor</label>
                    <input
                      type="text"
                      name="floor"
                      value={space.floor || ''}
                      onChange={onSpaceChange(cp.id, i)}
                      onBlur={onSpaceBlur(cp.id, i)}
                    />
                  </div>
                  <div className="field-group col-3">
                    <label>Square Feet</label>
                    <input
                      type="number"
                      name="space_total_square_feet"
                      value={space.space_total_square_feet || ''}
                      onChange={onSpaceChange(cp.id, i)}
                      onBlur={onSpaceBlur(cp.id, i)}
                    />
                  </div>
                  {cp.spaces.length > 1 &&
                  <Icon className="closeIcon" onClick={() => onSpaceDelete(cp.id, i)} iconName="close-circle" size="large" />
                }
                </div>
              </div>
            ))}
          </form>
        ))
        }
      </div>
    </div>
  );
};

PropertyLeaseTab.propTypes = {
  voucher: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  deal: PropTypes.shape({
    rep_role: PropTypes.string.isRequired,
    deal_type: PropTypes.string,
    is_dual_rep_deal: PropTypes.bool,
    id: PropTypes.number,
    client: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
};

export default PropertyLeaseTab;
