import {
  FETCH_VOUCHER_SUCCESS,
  FETCH_INSTALLMENT_OPTIONS_SUCCESS,
  FETCH_OFFICE_TYPES,
  FETCH_RECIPIENTS_SUCCESS,
  FETCH_VOUCHER_OPTIONS_SUCCESS,
  FETCH_COMMERCIAL_PROPERTIES,
  FETCH_MTA_NAICS_CHOICES,
  FETCH_MTA_NAICS_CHOICES_BY_CODE,
  UPDATE_COMPANY,
  FETCH_COMPANY_BY_ID,
  CREATE_COMMERCIAL_PROPERTIES,
  UPDATE_COMMERCIAL_PROPERTIES,
} from '../../actions/action-types';

const initialState = {
  voucher: {},
  recipients: [],
  options: [],
  voucherContingencyCodeChoices: [],
  officeTypes: [],
  commercialProperties: [],
  mtaNaicsChoices: [],
  searchValues: [],
  updatedInfo: [],
  companyInfo: [],
  createdCommercialProperties: [],
  updatedCommercialProperty: [],
};

const voucher = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VOUCHER_SUCCESS:
      return Object.assign({}, state, {
        voucher: action.payload,
      });
    case FETCH_RECIPIENTS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const stateCopy = Object.assign({}, state);
      stateCopy.voucher.send_to_email_addresses = action.payload;
      stateCopy.recipients = action.payload;
      return stateCopy;
    case FETCH_VOUCHER_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        options: Object.assign({}, state.options, action.payload),
      });
    case FETCH_OFFICE_TYPES:
      return Object.assign({}, state, {
        officeTypes: action.payload,
      });
    case FETCH_COMMERCIAL_PROPERTIES:
      return Object.assign({}, state, {
        commercialProperties: action.payload,
      });
    case FETCH_MTA_NAICS_CHOICES:
      return Object.assign({}, state, {
        mtaNaicsChoices: action.payload,
      });
    case FETCH_INSTALLMENT_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        voucherContingencyCodeChoices: action.payload,
      });
    case FETCH_MTA_NAICS_CHOICES_BY_CODE:
      return Object.assign({}, state, {
        searchValues: action.payload,
      });
    case UPDATE_COMPANY:
      return Object.assign({}, state, {
        updatedInfo: action.payload,
      });
    case FETCH_COMPANY_BY_ID:
      return Object.assign({}, state, {
        companyInfo: action.payload,
      });
    case CREATE_COMMERCIAL_PROPERTIES:
      return Object.assign({}, state, {
        createdCommercialProperties: action.payload,
      });
    case UPDATE_COMMERCIAL_PROPERTIES:
      return Object.assign({}, state, {
        updatedCommercialProperty: action.payload,
      });
    default:
      return state;
  }
};

export default voucher;
