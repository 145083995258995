import React from 'react';
import PropTypes from 'prop-types';

import contactsImg from '../../assets/images/svg/empty-state/contacts.svg';
import dealsImg from '../../assets/images/svg/empty-state/deals.svg';
import filesImg from '../../assets/images/svg/empty-state/files.svg';
import notesImg from '../../assets/images/svg/empty-state/notes.svg';
import propertiesImg from '../../assets/images/svg/empty-state/properties.svg';
import tasksImg from '../../assets/images/svg/empty-state/tasks.svg';
import craneImg from '../../assets/images/svg/empty-state/crane.svg';
import emptySearchImg from '../../assets/images/svg/empty-state/search.svg';

const EmptyState = (props) => {
  const { type } = props;
  const title = props.title ? props.title : `No ${type} found`;
  let imgSrc;
  switch (type) {
    case 'teams':
    case 'users':
    case 'contacts': {
      imgSrc = contactsImg;
      break;
    }
    case 'commission': {
      imgSrc = dealsImg;
      break;
    }
    case 'deals': {
      imgSrc = dealsImg;
      break;
    }
    case 'files': {
      imgSrc = filesImg;
      break;
    }
    case 'notes': {
      imgSrc = notesImg;
      break;
    }
    case 'crane': {
      imgSrc = craneImg;
      break;
    }
    case 'stacking plan':
    case 'properties': {
      imgSrc = propertiesImg;
      break;
    }
    case 'tasks': {
      imgSrc = tasksImg;
      break;
    }
    case 'proxy access': {
      imgSrc = emptySearchImg;
      break;
    }
    default:
      imgSrc = emptySearchImg;
      break;
  }

  return (
    <div className="empty-state">
      {!props.hideImg &&
        <div className="empty-state-wrapper">
          <img className={type} src={imgSrc} alt={`There are currently no ${type}`} />
        </div>
      }
      {!props.hideTitle &&
        <h2 className="message">{title}</h2>
      }
      {props.message &&
        <p>{props.message}</p>
      }
      {props.callToAction &&
        <div className="call-to-action">
          {props.callToAction}
        </div>
      }
    </div>
  );
};

EmptyState.propTypes = {
  callToAction: PropTypes.element,
  message: PropTypes.string,
  hideImg: PropTypes.bool,
  hideTitle: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.oneOf([
    'contacts',
    'commission',
    'deals',
    'files',
    'notes',
    'properties',
    'stacking plan',
    'tasks',
    'teams',
    'users',
    'crane',
    'proxy access',
  ]),
};

EmptyState.defaultProps = {
  callToAction: null,
  message: null,
  hideImg: false,
  hideTitle: false,
  title: undefined,
  type: null,
};

export default EmptyState;
