import React from 'react';
import PropTypes from 'prop-types';
import { Button, ToggleSwitch } from '@cbrebuild/blocks';
import ButtonWithFlyout from '../../nucleus/button-with-flyout/button-with-flyout';

import TeamCardMembers from './team-card-members';
import TeamCardHistory from './team-card-history';
import TeamAvatar from './team-avatar';
import TeamColorPicker from '../teams/team-color-picker';
import BulkAddDealsModal from '../modals/bulk-add-deals-modal';
import BulkRemoveDealsModal from '../modals/bulk-remove-deals-modal';
import Modal from '../../nucleus/modal/modal';
import NDButton from '../../nucleus/button/button';
import TeamCardSplit from './team-card-split';

class TeamCard extends React.Component {
  constructor(props) {
    super(props);

    this.tabs = ['members', 'history', 'Default Splits'];
    this.errorMessage = 'Team Name is required';

    this.state = {
      isEditing: false,
      teamName: null,
      activeTab: this.tabs[0],
      teamNameErrorMessage: '',
      teamColor: null,
      selectedColor: null,
      showBulkAddModal: false,
      showBulkRemoveModal: false,
      showBulkSuccessModal: false,
      dealCount: this.props.dealCount,
    };
  }

  componentDidMount() {
    if (this.state.isEditing) {
      this.addEventListeners();
    }
    this.setInitialProps();
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  onKeyUp = (e) => {
    if (e.keyCode === 9) {
      const focusedElement = document.activeElement;
      this.listenForEvents({ target: focusedElement });
    }
  };

  setInitialProps = () => {
    const {
      teamName,
      teamColor,
    } = this.props;
    this.setState({
      teamName,
      teamColor,
      selectedColor: teamColor,
    });
  };

  openEdit = () => {
    this.setState({
      isEditing: true,
      selectedColor: this.state.teamColor,
    });
    this.props.onEdit(true);
  };

  closeEdit = () => {
    const {
      teamName,
      teamColor,
      onEdit,
    } = this.props;
    this.removeEventListeners();
    this.setState({
      isEditing: false,
      teamName,
      teamColor,
      teamNameErrorMessage: '',
    });
    onEdit(false);
  };

  addEventListeners = () => {
    document.addEventListener('keyup', this.onKeyUp);
  };

  removeEventListeners = () => {
    document.removeEventListener('keyup', this.onKeyUp);
  };

  handleTeamNameChange = (teamName) => {
    this.setState({
      teamName,
      teamNameErrorMessage: teamName ? '' : this.errorMessage,
    });
  };

  save = () => {
    const {
      teamName,
      selectedColor,
    } = this.state;
    const {
      onUpdate,
      id,
      deleted,
    } = this.props;
    const data = {
      id,
      teamName,
      teamColor: selectedColor,
      deleted,
    };
    if (!teamName || teamName.trim() === '') {
      this.setState({ teamNameErrorMessage: this.errorMessage });
    } else {
      onUpdate(data);
      this.closeEdit();
      this.updateTeamName(teamName);
      this.handleSetTeamColor();
    }
  };

  toggleEditMode = () => {
    this.openEdit();
    this.addEventListeners();
  };

  handleTabToggle = (tab) => {
    this.setState({ activeTab: tab });
  };

  updateTeamName = (teamName) => {
    this.setState({ teamName });
  };

  handleSetTeamColor = () => {
    const { selectedColor } = this.state;
    if (selectedColor) {
      this.setState({ teamColor: selectedColor });
      this.setState({ selectedColor: undefined });
    }
  };

  handleSetSelectedColor = (color) => {
    this.setState({ selectedColor: color });
  };

  showBulkAddModal = () => {
    this.setState({ showBulkAddModal: true });
  };

  closeBulkAddModal = (param) => {
    if (param) {
      if (param.success === true) {
        this.showBulkSuccessModal(param);
      } else if (param.success === false) {
        this.props.toast(param.message);
      }
    }
    this.setState({ showBulkAddModal: false });
  };

  showBulkRemoveModal = () => {
    this.setState({ showBulkRemoveModal: true });
  };

  closeBulkRemoveModal = (param) => {
    if (param) {
      if (param.success === true) {
        this.showBulkSuccessModal(param);
      } else if (!param.success === false) {
        this.props.toast(param.message);
      }
    }
    this.setState({ showBulkRemoveModal: false });
  };

  showBulkSuccessModal = (param) => {
    this.setState({ showBulkSuccessModal: param });
  };

  closeBulkSuccessModal = () => {
    this.setState({ showBulkSuccessModal: { success: false } });
  };

  handleAddToDealCount = (change) => {
    const { dealCount } = this.state;
    const newDealCount = dealCount + change;
    this.setState({ dealCount: newDealCount });
  };

  handleRemoveFromDealCount = (change) => {
    const { dealCount } = this.state;
    const newDealCount = dealCount - change;
    this.setState({ dealCount: newDealCount });
  };

  handleUndeleteTeam = () => {
    const {
      teamName,
      selectedColor,
    } = this.state;
    const {
      undeleteTeam,
      id,
    } = this.props;
    undeleteTeam({
      id,
      teamName,
      teamColor: selectedColor,
      deleted: false,
    });
  };

  renderBulkSuccessModal = () => {
    const {
      teamName,
      showBulkSuccessModal,
    } = this.state;
    return (
      <Modal
        modalHeader={`Bulk ${showBulkSuccessModal.operation} Confirmation`}
        showModal
        handleModalToggle={this.closeBulkSuccessModal}
        hideModalControls
        className="bulk-confirmation-modal"
        modalWidth={400}
      >
        <div>
          <p>{teamName} is being
            {showBulkSuccessModal.operation === 'Add' ? ' added to ' : ' removed from '}
            {showBulkSuccessModal.numberOfDeals} {showBulkSuccessModal.numberOfDeals === 1 ? ' deal' : ' deals'}.
            It may take a few minutes.
          </p>
        </div>
        <div className="bulk-confirmation-modal-controls">
          <Button onClick={this.closeBulkSuccessModal} variant="primary">OK</Button>
        </div>
      </Modal>
    );
  };

  render() {
    const {
      isEditing,
      teamName,
      activeTab,
      dealCount,
      teamNameErrorMessage,
      teamColor,
      selectedColor,
      showBulkAddModal,
      showBulkRemoveModal,
      showBulkSuccessModal,
    } = this.state;

    const {
      teamMembers,
      id,
      deleted,
      onAddTeamMember,
      onRemoveTeamMember,
      onRemoveTeamAndMember,
      userData,
      audit_trail,
      auto_add_to_deals,
      onUpdateUserTeamAutoAddPreference,
    } = this.props;

    // we want to hide certain functionality on the admin page
    // AdminPage is the only parent that doesn't send down this prop
    // so use this prop to determine where we are, and what buttons to hide
    const isAdminPage = !onUpdateUserTeamAutoAddPreference;
    const isDeleted = deleted ? 'is-deleted' : '';

    const tabContent = (
      <div className="team-card-tabs">
        <div className="sub-tabs nd-tabnavs">
          <ul>
            {this.tabs.map(tab => (
              <li
                key={tab}
                className={activeTab === tab ? 'active' : ''}
                onClick={() => this.handleTabToggle(tab)}
              >
                <a>{tab} {tab === 'members' ? (
                  `(${teamMembers.length})`
                ) : ''}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {activeTab === this.tabs[0] &&
        <TeamCardMembers
          id={id}
          userData={userData}
          teamName={teamName}
          dealCount={dealCount}
          isCreating={false}
          teamMembers={teamMembers}
          onAddTeamMember={onAddTeamMember}
          onRemoveTeamMember={onRemoveTeamMember}
          onRemoveTeamAndMember={onRemoveTeamAndMember}
          analyticProperties={this.props.analyticProperties}
          isAdminPage={isAdminPage}
        />}
        {activeTab === this.tabs[1] &&
        <TeamCardHistory
          audit_trail={audit_trail}
        />}
        {activeTab === this.tabs[2] &&
          <TeamCardSplit
            id={id}
            userData={userData}
            teamName={teamName}
            teamMembers={teamMembers}
            isAdminPage={isAdminPage}
          />
        }
      </div>
    );

    const bulkAddModal = (
      <BulkAddDealsModal
        teamId={id}
        teamName={this.props.teamName}
        teamMembers={teamMembers}
        teamColor={this.props.teamColor}
        userData={userData}
        showModal={this.showBulkAddModal}
        closeModal={this.closeBulkAddModal}
        addToDealCount={this.handleAddToDealCount}
        analyticProperties={this.props.analyticProperties}
        isAdmin={isAdminPage}
      />
    );

    const bulkRemoveModal = (
      <BulkRemoveDealsModal
        teamId={id}
        teamName={this.props.teamName}
        teamMembers={teamMembers}
        teamColor={this.props.teamColor || ''}
        closeModal={this.closeBulkRemoveModal}
        removeFromDealCount={this.handleRemoveFromDealCount}
        analyticProperties={this.props.analyticProperties}
      />
    );

    return (
      <section className={`team-card card-interaction ${isDeleted}`}>
        {isEditing ?
          ( // editing
            <div className="team-header-edit">
              <div className={`row team-name-edit-wrapper ${teamNameErrorMessage ? 'missing-team-name' : ''}`}>
                <div className="field-item">
                  <label><span className={`${teamNameErrorMessage ? 'missing-team-name-asterisk' : ''}`}>*</span> Team Name</label>
                  <input
                    type="text"
                    value={teamName}
                    maxLength="50"
                    onChange={(e) => {
                      e.persist();
                      this.handleTeamNameChange(e.target.value);
                    }}
                    required
                  />
                  {teamNameErrorMessage && <p className="error">{teamNameErrorMessage}</p>}
                </div>
                <TeamColorPicker
                  setColor={this.handleSetSelectedColor}
                  currentColor={selectedColor}
                />
              </div>
              <div className="row card-edit-actions">
                <Button variant="secondary" onClick={this.closeEdit}>Cancel</Button>
                <Button onClick={this.save}>Save</Button>
              </div>
            </div>
          ) :
          ( // default
            <div className="team-header">
              <div className="team-name-wrapper">
                <TeamAvatar
                  teamName={teamName}
                  teamColor={teamColor}
                />
                <h1 className="team-name">{teamName}</h1>
                {isAdminPage && isDeleted &&
                  <h1 className="team-name">: Deleted Team</h1>}
              </div>
              <div className="team-menu">
                {!isAdminPage &&
                <ToggleSwitch
                  checked={auto_add_to_deals}
                  onChange={() => onUpdateUserTeamAutoAddPreference({ user_id: userData.id, team_id: id, auto_add: !auto_add_to_deals })}
                >
                  Auto-Suggest To New Deals
                </ToggleSwitch>}
                <ButtonWithFlyout
                  className="team-header-kebab"
                  disabled={false}
                  iconButton="more-v"
                  position="right"
                  closeFlyoutOnClick
                >
                  <ul className="dropdown-options">
                    <li><NDButton variant="option" width="full" onMouseDown={this.toggleEditMode} icon="edit">Edit Team Details</NDButton></li>
                    {!isAdminPage &&
                    <li><NDButton variant="option" width="full" onMouseDown={this.showBulkAddModal} icon="bulk-add">Bulk-add Deals</NDButton></li>}
                    {isAdminPage &&
                    <li>
                      <NDButton variant="option" width="full" onMouseDown={this.showBulkRemoveModal} icon="bulk-remove">Bulk-remove Deals</NDButton>
                    </li>}
                    { isDeleted && isAdminPage &&
                      <li>
                        <NDButton
                          variant="option"
                          width="full"
                          icon="reset"
                          onMouseDown={this.handleUndeleteTeam}
                        >
                          Un-Delete Team
                        </NDButton>
                      </li>
                    }
                  </ul>
                </ButtonWithFlyout>
              </div>
            </div>
          )
        }
        {tabContent}
        {showBulkAddModal && bulkAddModal}
        {showBulkRemoveModal && bulkRemoveModal}
        {showBulkSuccessModal.success && this.renderBulkSuccessModal()}
      </section>
    );
  }
}

export default TeamCard;

TeamCard.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  teamMembers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.shape({
      work: PropTypes.string,
    }),
    avatar: PropTypes.string,
  })),
  audit_trail: PropTypes.arrayOf(PropTypes.shape()),
  auto_add_to_deals: PropTypes.bool,
  deleted: PropTypes.bool,
  teamName: PropTypes.string,
  teamColor: PropTypes.string,
  dealCount: PropTypes.number,
  id: PropTypes.number,
  onEdit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateUserTeamAutoAddPreference: PropTypes.func,
  onAddTeamMember: PropTypes.func.isRequired,
  onRemoveTeamMember: PropTypes.func.isRequired,
  onRemoveTeamAndMember: PropTypes.func.isRequired,
  undeleteTeam: PropTypes.func,
  analyticProperties: PropTypes.shape().isRequired,
  toast: PropTypes.func.isRequired,
};

TeamCard.defaultProps = {
  teamMembers: [],
  teamName: '',
  id: undefined,
  deleted: false,
  teamColor: '',
  dealCount: -1,
  audit_trail: [{}],
  auto_add_to_deals: false,
  onUpdateUserTeamAutoAddPreference: null,
  undeleteTeam: null,
};
