import { connect } from 'react-redux';
import { fetchDealsAndTransactionsES, fetchDeals } from '../../redux/actions/deals-actions';
import { fetchDealTags } from '../../redux/actions/tags-actions';
import DealListPage from './deal-list-page';

const mapStateToProps = state => ({
  allDealTags: state.tags.dealTags,
  deals: state.deals.deals,
  dealCount: state.deals.dealCount,
  hasNextPage: state.deals.hasNextPage,
  isLoadingDeals: !!state.deals.requestId,
  lastQuery: state.deals.lastQuery,
  user: state.userData.data,
  featureFlags: state.userOptions.userOptions.feature_flags,
  proxy: state.proxy.proxySettings,
});

const mapDispatchToProps = {
  fetchDeals,
  fetchDealsAndTransactionsES,
  fetchDealTags,
};

// takes props sent from parent and merges with redux state and actions
const mergeProps = (state, actions, props) => {
  let deal_life_cycle_stage;
  const dealStage = props.match.params.type;
  if (dealStage) {
    deal_life_cycle_stage = dealStage === 'prospects'
      ? ['prospect']
      : [dealStage];
  }
  const stageName = dealStage === 'prospects' ? 'prospecting' : dealStage;
  const action = stageName ? `${stageName}_` : '';
  const category = stageName ? `${stageName.charAt(0).toUpperCase()}${stageName.slice(1)} ` : '';

  return ({
    ...state,
    ...actions,
    initQueryParams: {
      conversion_potential_min: 0,
      conversion_potential_max: 100,
      is_archived: false,
      deal_life_cycle_stage: deal_life_cycle_stage || [],
      rep_role: [],
      tags: [],
      date_approved_min: null,
      date_approved_max: null,
      ordering: '-modified',
      page: 1,
      page_size: 25,
      search: '',
      date_lease_to_min: null,
      date_lease_to_max: null,
      voucher_submission_status: null,
      is_untagged_deal: null,
      is_on_pipeline: null,
    },
    analyticProperties: {
      actionPrefix: `${action}deal_list`,
      categoryPrefix: `${category}Deal List `,
    },
  });
};

const DealListPageContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(DealListPage);

export default DealListPageContainer;

