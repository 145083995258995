import userOptionsService from '../../services/user-options-service';
import {
  IS_LOADING_USER_OPTIONS,
  IS_UPDATING_USER_OPTIONS,
  LOAD_USER_OPTIONS_SUCCESS,
  LOAD_USER_OPTIONS_FAILURE,
  UPDATE_USER_OPTIONS_SUCCESS,
  UPDATE_USER_OPTIONS_FAILURE,
} from './action-types';

// User Options Action Creators
const loadUserOptionsSuccess = userOptions => ({
  type: LOAD_USER_OPTIONS_SUCCESS,
  payload: { userOptions },
});

const loadUserOptionsFailure = error => ({
  type: LOAD_USER_OPTIONS_FAILURE,
  payload: { error },
});

const isLoadingUserOptions = isLoading => ({
  type: IS_LOADING_USER_OPTIONS,
  payload: { isLoadingOptions: isLoading },
});

// Async Action

const loadUserOptions = () => (dispatch) => {
  dispatch(isLoadingUserOptions(true));
  userOptionsService.fetchUserOptions()
    .then((data) => {
      dispatch(loadUserOptionsSuccess(data));
    })
    .then(() => dispatch(isLoadingUserOptions(false)))
    .catch(error => dispatch(loadUserOptionsFailure(error)));
};

// User Options Action Creators
const updateUserOptionsSuccess = userOptions => ({
  type: UPDATE_USER_OPTIONS_SUCCESS,
  payload: { userOptions },
});

const updateUserOptionsFailure = error => ({
  type: UPDATE_USER_OPTIONS_FAILURE,
  payload: { error },
});

const isUpdatingUserOptions = isUpdating => ({
  type: IS_UPDATING_USER_OPTIONS,
  payload: { isUpdatingOptions: isUpdating },
});

// Async Action

const updateUserOptions = params => (dispatch) => {
  dispatch(isUpdatingUserOptions(true));
  userOptionsService.updateUserOptions(params)
    .then((data) => {
      dispatch(updateUserOptionsSuccess(data));
    })
    .then(() => dispatch(isUpdatingUserOptions(false)))
    .catch(error => dispatch(updateUserOptionsFailure(error)));
};

export {
  loadUserOptions,
  loadUserOptionsSuccess,
  loadUserOptionsFailure,
  isLoadingUserOptions,
  updateUserOptions,
  updateUserOptionsSuccess,
  updateUserOptionsFailure,
  isUpdatingUserOptions,
};
