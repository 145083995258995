import superFetch from '../../utils/super-fetch';

const fetchAdditionalCommissionTypeOptions = () => {
  const url = '/additional_commissions';
  const options = {
    method: 'OPTIONS',
  };

  return superFetch(url, options)
    .then(data => data.json())
    .then(data => data.actions.POST.acommission_type.choices);
};

const fetchCommissions = (dealId, additionalParams) => {
  const url = '/additional_commissions';

  const options = {
    method: 'GET',
    params: {
      transaction: dealId,
      ...additionalParams,
    },
  };

  return superFetch(url, options)
    .then(data => data.json())
    .then(data => data.results || []);
};

const createCommission = (dataObj) => {
  const url = '/additional_commissions';

  const options = {
    method: 'POST',
  };

  const body = JSON.stringify(dataObj);

  return superFetch(url, options, body)
    .then(data => data.json());
};

const updateCommission = (id, dataObj) => {
  const url = `/additional_commissions/${id}`;
  const options = {
    method: 'PUT',
  };
  const body = JSON.stringify(dataObj);

  return superFetch(url, options, body)
    .then(data => data.json());
};

const patchCommission = (id, dataObj) => {
  const url = `/additional_commissions/${id}`;
  const options = {
    method: 'PATCH',
  };
  const body = JSON.stringify(dataObj);

  return superFetch(url, options, body)
    .then(data => data.json());
};

const deleteCommission = (id) => {
  const url = `/additional_commissions/${id}`;
  const options = {
    method: 'DELETE',
  };

  return superFetch(url, options);
};

export default {
  fetchCommissions,
  fetchAdditionalCommissionTypeOptions,
  createCommission,
  updateCommission,
  patchCommission,
  deleteCommission,
};
