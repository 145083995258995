import vouchersService from '../../../services/voucher/vouchers-service';
import {
  FETCH_VOUCHER_SUCCESS,
  FETCH_RECIPIENTS_SUCCESS,
  FETCH_OFFICE_TYPES,
  FETCH_COMMERCIAL_PROPERTIES,
  FETCH_MTA_NAICS_CHOICES,
  FETCH_MTA_NAICS_CHOICES_BY_CODE,
  UPDATE_COMPANY,
  FETCH_COMPANY_BY_ID,
  UPDATE_COMMERCIAL_PROPERTIES,
  DELETE_COMMERCIAL_PROPERTIES,
  CREATE_COMMERCIAL_PROPERTIES,
} from '../action-types';

// Action creators
const fetchVoucherSuccess = voucher => ({
  type: FETCH_VOUCHER_SUCCESS,
  payload: voucher,
});

const fetchRecipientsSuccess = recipients => ({
  type: FETCH_RECIPIENTS_SUCCESS,
  payload: recipients,
});

const fetchOfficeTypesSuccess = officeTypes => ({
  type: FETCH_OFFICE_TYPES,
  payload: officeTypes,
});

const fetchUpdatedCompanyInfoSuccess = updatedInfo => ({
  type: UPDATE_COMPANY,
  payload: updatedInfo,
});

const fetchMtaNaicsChoicesByCodesSuccess = searchValues => ({
  type: FETCH_MTA_NAICS_CHOICES_BY_CODE,
  payload: searchValues,
});

const fetchCommercialPropertySuccess = commercialProperties => ({
  type: FETCH_COMMERCIAL_PROPERTIES,
  payload: commercialProperties,
});

const fetchMtaNaicsChoicesSuccess = mtaNaicsChoices => ({
  type: FETCH_MTA_NAICS_CHOICES,
  payload: mtaNaicsChoices,
});

const fetchCompanyByIdSuccess = companyInfo => ({
  type: FETCH_COMPANY_BY_ID,
  payload: companyInfo,
});

const createCommercialPropertySuccess = commercialProperty => ({
  type: CREATE_COMMERCIAL_PROPERTIES,
  payload: commercialProperty,
});

const deleteCommercialPropertySuccess = commercialProperty => ({
  type: DELETE_COMMERCIAL_PROPERTIES,
  payload: commercialProperty,
});

const updateCommercialPropertySuccess = commercialProperty => ({
  type: UPDATE_COMMERCIAL_PROPERTIES,
  payload: commercialProperty,
});

// Async actions creators
const fetchVoucher = voucherId => (dispatch) => {
  vouchersService.fetchVoucher(voucherId)
    .then(voucher => dispatch(fetchVoucherSuccess(voucher)))
    .catch(error => console.log('Error fetching voucher: ', error));
};

const fetchVoucherByDealId = dealId => (dispatch) => {
  vouchersService.fetchVoucherByDealId(dealId)
    .then(voucher => dispatch(fetchVoucherSuccess(voucher)))
    .catch(error => console.log('Error fetching voucher: ', error));
};

const deleteCommercialProperty = id => (dispatch) => {
  vouchersService.deleteCommercialProperty(id)
    .then(commercialproperty => dispatch(deleteCommercialPropertySuccess(commercialproperty)))
    .catch(error => console.log('Error deleting commercial property: ', error));
};

const fetchCommercialProperty = dealId => (dispatch) => {
  vouchersService.fetchCommercialProperty(dealId)
    .then(commercialproperty => dispatch(fetchCommercialPropertySuccess(commercialproperty.results)))
    .catch(error => console.log('Error fetching commercial property: ', error));
};

const createCommercialProperty = params => (dispatch) => {
  vouchersService.createCommercialProperty(params)
    .then(commercialproperty => dispatch(createCommercialPropertySuccess(commercialproperty)))
    .catch(error => console.log('Error creating commercial property: ', error));
};

const fetchMtaNaicsChoices = () => (dispatch) => {
  vouchersService.getMtaNaicsChoices()
    .then(mtaNaicsChoices => dispatch(fetchMtaNaicsChoicesSuccess(mtaNaicsChoices)))
    .catch(error => console.log('Error fetching Naics choices: ', error));
};

const fetchMtaNaicsChoicesByCode = searchText => (dispatch) => {
  vouchersService.searchMtaNaicsChoicesByCode(searchText)
    .then(mtaNaicsChoices => dispatch(fetchMtaNaicsChoicesByCodesSuccess(mtaNaicsChoices)))
    .catch(error => console.log('Error fetching Naics Choices by code: ', error));
};

const fetchOfficeTypes = () => (dispatch) => {
  vouchersService
    .fetchVoucherOfficeTypes()
    .then(officeTypes => dispatch(fetchOfficeTypesSuccess(officeTypes)))
    .catch(error => console.log('Error fetching office types: ', error));
};

const fetchCompanyById = id => (dispatch) => {
  vouchersService
    .fetchCompanyById(id)
    .then(companyInfo => dispatch(fetchCompanyByIdSuccess(companyInfo)))
    .catch(error => console.log('Error fetching company: ', error));
};

const createVoucher = dealId => (dispatch) => {
  vouchersService.createVoucher(dealId)
    .then(voucher => dispatch(fetchVoucherSuccess(voucher)))
    .catch(error => console.log('Error creating voucher: ', error));
};

const updateCompany = (id, params) => (dispatch) => {
  vouchersService.updateCompany(id, params)
    .then(voucher => dispatch(fetchUpdatedCompanyInfoSuccess(voucher)))
    .catch(error => console.log('Error updating company: ', error));
};

const updateCommercialProperty = (id, params) => (dispatch) => {
  vouchersService.updateCommercialProperty(id, params)
    .then(commercialproperty => dispatch(updateCommercialPropertySuccess(commercialproperty)))
    .catch(error => console.log('Error updating commercial property: ', error));
};

const fetchOrCreateVoucher = dealId => (dispatch) => {
  vouchersService.fetchOrCreateVoucher(dealId)
    .then(voucher => dispatch(fetchVoucherSuccess(voucher)))
    .catch(error => console.log('Error fetching or creating voucher: ', error));
};

const updateVoucher = (id, params) => dispatch => vouchersService.updateVoucher(id, params)
  .then((voucher) => {
    dispatch(fetchVoucherSuccess(voucher));
    return voucher;
  })
  .catch(error => console.log('Error updating voucher: ', error));

const submitVoucher = voucherId => (dispatch) => {
  vouchersService.submitVoucher(voucherId)
    .then((voucher) => {
      dispatch(fetchVoucherSuccess(voucher));
      return voucher;
    })
    .catch(error => console.log('Error submitting voucher: ', error));
};

const populateRecipients = voucherId => (dispatch) => {
  vouchersService.populateRecipients(voucherId)
    .then((recipients) => {
      dispatch(fetchRecipientsSuccess(recipients));
    })
    .catch(error => console.log('Error populating recipients of voucher: ', error));
};

const addRecipient = (voucherId, recipient) => (dispatch) => {
  vouchersService.addRecipient(voucherId, recipient)
    .then((recipients) => {
      dispatch(fetchRecipientsSuccess(recipients));
    })
    .catch(error => console.log('Error adding recipient to voucher: ', error));
};

const addRecipients = (voucherId, recipients) => dispatch =>
  recipients.forEach(recipient =>
    dispatch(addRecipient(voucherId, recipient)));

const removeRecipient = (voucherId, recipient) => (dispatch) => {
  vouchersService.removeRecipient(voucherId, recipient)
    .then(recipients => dispatch(fetchRecipientsSuccess(recipients)))
    .catch(error => console.log('Error removing recipient from voucher: ', error));
};

export {
  fetchVoucher,
  fetchVoucherByDealId,
  createVoucher,
  fetchOrCreateVoucher,
  updateVoucher,
  populateRecipients,
  addRecipient,
  addRecipients,
  removeRecipient,
  fetchOfficeTypes,
  fetchCommercialProperty,
  fetchMtaNaicsChoices,
  fetchMtaNaicsChoicesByCode,
  updateCompany,
  fetchCompanyById,
  createCommercialProperty,
  deleteCommercialProperty,
  updateCommercialProperty,
  submitVoucher,
};

