import superFetch from '../utils/super-fetch';

// handle server response errors
const handleErrors = (response) => {
  if (!response.ok) {
    throw response;
  }
  return response;
};

const fetchTransaction = (id) => {
  const url = `/vol/${id}`;
  const options = {
    method: 'GET',
  };
  return superFetch(url, options)
    .then(handleErrors)
    .then(data => data.json())
    .catch((error) => {
      console.error('Error: ', error);
      return { error: error.status, message: error.statusText };
    });
};

const fetchTransactions = (params) => {
  const url = '/vol';
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json());
};

export default { fetchTransaction, fetchTransactions };
