import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import IntlTelInput from 'intl-tel-input';
import IntlTelInputUtil from 'intl-tel-input/build/js/utils';
// although these imports are seemingly unused,
// phone-input blows up without them
import css from 'intl-tel-input/build/css/intlTelInput.min.css'; // eslint-disable-line
import flags from '../../assets/images/png/flags.png'; // eslint-disable-line
import flags2 from '../../assets/images/png/flags@2x.png'; // eslint-disable-line


class PhoneInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
    };
    this.telInput = null;
    this.input = null;
    this.label = 'Phone Number';
    this.onBlur = this.onBlur.bind(this);
    this.uniqueId = `phone-${uniqueId()}`;
  }

  componentDidMount() {
    this.telInput = IntlTelInput(this.input, {
      utilsScript: IntlTelInputUtil,
      customPlaceholder: this.customPlaceHolder,
    });

    this.telInput.setNumber(this.props.value);
    if (this.props.label) {
      this.label = this.props.label;
    }
    if (!this.isValid()) {
      this.state.isValid = false;
    } else {
      this.state.isValid = true;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.telInput.setNumber(this.props.value);
    }
  }

  onFocus = () => {
    this.setState({
      isValid: true,
    });
  }

  onBlur = () => {
    if (this.telInput.getExtension()) {
      this.telInput.setNumber(`${this.telInput.getNumber()} ext. ${this.telInput.getExtension()}`);
    } else {
      this.telInput.setNumber(this.telInput.getNumber());
    }

    if (!this.isValid()) {
      this.setState({
        isValid: false,
      });
    } else {
      this.setState({
        isValid: true,
      });
    }

    if (this.props.onBlur) {
      this.props.onBlur(this.input.value, this.isValid());
    }
  }

  onKeyUp = (e) => {
    const {
      iso2,
    } = this.telInput.getSelectedCountryData();

    if (iso2 === 'us' && ((e && e.which !== 8) || !e)) {
      const val = this.input.value;

      // Adds the ( and ) as you type
      if (val.length === 3 &&
        this.isNumeric(val[0]) &&
        this.isNumeric(val[1]) &&
        this.isNumeric(val[2])) {
        this.input.value = `(${val}) `;
      }

      // Adds ) to number if the user types the (
      if (val.length === 4 && this.isNumeric(val[3])) {
        this.input.value = `${val}) `;
      }

      // Dash before last 4 digits
      if (val.length === 10 &&
        this.isNumeric(val[9]) &&
          val[0] === '(' &&
          val[4] === ')' &&
          val[5] === ' ') {
        this.input.value = `${val.substring(0, 9)}-${val.substring(9)}`;
      }

      // Extension
      if (val.length > 14 &&
        this.isNumeric(val[14]) &&
          val[0] === '(' &&
          val[4] === ')' &&
          val[5] === ' ' &&
          val[9] === '-') {
        this.input.value = `${val.substring(0, 14)} ext. ${val.substring(14)}`;
      }

      if (val.length === 14 &&
          !this.isNumeric(val[14]) &&
          val[0] === '(' &&
          val[4] === ')' &&
          val[5] === ' ' &&
          val[9] === '-') {
        this.input.value = val.substring(0, 14);
      }

      if (val.length > 14 && !this.isNumeric(val[val.length - 1])) {
        this.input.value = val.substring(0, val.length - 1);
      }

      if (this.props.onChange) {
        this.props.onChange(e.target.value, this.isValid());
      }
    }
  }

  customPlaceHolder = (selectedCountryPlaceholder, selectedCountryData) => {
    if (selectedCountryData.dialCode === '1') {
      return '(###) ###-#### x###';
    }
    return selectedCountryPlaceholder;
  }

  isValid = () => {
    if (this.input.value.length > 0 && !this.telInput.isValidNumber()) {
      return false;
    }
    return true;
  }

  // Do not use Number.isFinite doesn't work the same as global isFinite.
  // eslint-disable-next-line
  isNumeric = value => !isNaN(parseFloat(value)) && isFinite(value);

  render() {
    let invalidClassName = '';
    let invalidRender = '';
    if (this.state.isValid === false) {
      invalidClassName = 'invalid';
      invalidRender = <p>Invalid Phone Number</p>;
    }

    return (
      <div className={`validate-phone-number ${invalidClassName}`}>
        {!this.props.hideLabel && <label htmlFor="input">{this.label}</label>}
        <input type="tel" ref={(ref) => { this.input = ref; }} onKeyUp={this.onKeyUp} onBlur={this.onBlur} onFocus={this.onFocus} id={this.uniqueId} />
        { invalidRender }
      </div>
    );
  }
}

PhoneInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  hideLabel: PropTypes.bool,
};

PhoneInput.defaultProps = {
  label: null,
  value: '',
  onChange: null,
  onBlur: null,
  hideLabel: false,
};

export default PhoneInput;
