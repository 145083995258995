import superFetch from '../../utils/super-fetch';

const fetchEscalations = (dealId) => {
  const url = '/escalations';
  const params = { transaction: dealId };
  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json());
};

export default {
  fetchEscalations,
};
