import isEmpty from 'lodash/isEmpty';
import superFetch from '../../utils/super-fetch';

const url = '/mbb/vouchers';

const fetchVoucher = (voucherId) => {
  const options = { method: 'GET' };

  return superFetch(`${url}/${voucherId}`, options).then(data => data.json());
};

const fetchVoucherEmail = (dealId) => {
  const options = { method: 'GET' };
  
  return superFetch(`${url}/email_to/${dealId}`, options).then(data => data.json());
  
};

const fetchVoucherByDealId = (dealId) => {
  const params = { deal: dealId };
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json())
    .then(data => (data.results.length ? data.results[0] : {}));
};

const getPropertyOptions = () => {
  const options = {
    method: 'OPTIONS',
  };
  return superFetch('/mbb/voucher/commercialproperty', options)
    .then(data => data.json());
};

const populateRecipients = (voucherId) => {
  const options = {
    method: 'PATCH',
  };

  return superFetch(`${url}/${voucherId}/populate_recipients`, options)
    .then(data => data.json())
    .then(data => data.populate_recipients_result);
};

const createVoucher = (dealId) => {
  const options = { method: 'POST' };
  const body = { deal: dealId };

  return superFetch(url, options, JSON.stringify(body))
    .then(data => data.json())
    .then((voucherData) => {
      populateRecipients(voucherData.id);
      return voucherData;
    });
};

const fetchCommercialProperty = (dealId) => {
  const params = { deal: dealId };
  const options = {
    method: 'GET',
    params,
  };
  return superFetch('/mbb/voucher/commercialproperty', options)
    .then(data => data.json());
};

const createCommercialProperty = (params) => {
  const options = {
    method: 'POST',
  };

  const body = JSON.stringify(params);
  return superFetch('/mbb/voucher/commercialproperty', options, body)
    .then(data => data.json());
};

const deleteCommercialProperty = (id) => {
  const options = {
    method: 'DELETE',
  };

  return superFetch(`/mbb/voucher/commercialproperty/${id}`, options)
    .then(data => data);
};

const fetchOrCreateVoucher = dealId =>
  fetchVoucherByDealId(dealId)
    .then((existingData) => {
      if (!isEmpty(existingData)) {
        populateRecipients(existingData.id);
        return existingData;
      }
      return createVoucher(dealId);
    })
    .catch(console.error);

const updateVoucher = (id, params) => {
  const options = { method: 'PATCH' };
  const body = JSON.stringify(params);

  return superFetch(`${url}/${id}`, options, body).then(data => data.json());
};

const updateCommercialProperty = (id, params) => {
  const options = { method: 'PATCH' };
  const body = JSON.stringify(params);

  return superFetch(`/mbb/voucher/commercialproperty/${id}`, options, body).then(data => data.json());
};

const addRecipient = (voucherId, recipient) => {
  const options = {
    method: 'PATCH',
  };
  const body = JSON.stringify(recipient);

  return superFetch(`${url}/${voucherId}/add_recipient`, options, body)
    .then(data => data.json())
    .then(data => data.add_recipient_result);
};

const removeRecipient = (voucherId, recipient) => {
  const options = {
    method: 'PATCH',
  };
  const body = JSON.stringify(recipient);

  return superFetch(`${url}/${voucherId}/remove_recipient`, options, body)
    .then(data => data.json())
    .then(data => data.remove_recipient_result);
};

const fetchVoucherOfficeTypes = () => {
  const params = {
    is_mta_lead_office: true,
    ordering: 'managing_office_name',
  };
  const options = { method: 'GET', params };

  return superFetch(
    '/mta/office',
    options,
  ).then(data => data.json());
};

const getMtaNaicsChoices = () => {
  const params = {
    naics_parent_sic_code_exclude: 'C',
    naics_source: 'MTA_AUG_2019',
    naics_description_distinct: true,
  };

  const options = { method: 'GET', params };

  return superFetch(
    '/mta/naics',
    options,
  ).then(data => data.json());
};

const searchMtaNaicsChoicesByCode = (search) => {
  let params = {};
  if (Number.isNaN(Number(search))) {
    params = {
      naics_parent_sic_code_exclude: 'C',
      naics_source: 'MTA_AUG_2019',
      naics_description_distinct: true,
      naics_description__icontains: search,
    };
  } else {
    params = {
      naics_parent_sic_code_exclude: 'C',
      naics_source: 'MTA_AUG_2019',
      naics_description_distinct: true,
      naics_code__contains: search,
    };
  }

  const options = { method: 'GET', params };

  return superFetch(
    '/mta/naics',
    options,
  ).then(data => data.json());
};

const updateCompany = (id, params) => {
  const options = {
    method: 'PATCH',
  };

  const body = JSON.stringify(params);

  return superFetch(`/mbb/voucher/companies/${id}`, options, body)
    .then(data => data.json());
};

const fetchCompanyById = (id) => {
  const options = {
    method: 'GET',
  };

  return superFetch(`/mbb/voucher/companies/${id}`, options)
    .then(data => data.json());
};

const submitVoucher = (id) => {
  const options = {
    method: 'PATCH',
  };
  return superFetch(`/mbb/vouchers/${id}/submit`, options)
    .then(data => data.json());
};

// TODO:
// wasSubmitted
// isFoundInGroup
// getAndSetLastPageVisited

export default {
  createVoucher,
  submitVoucher,
  fetchVoucher,
  fetchVoucherEmail,
  fetchVoucherByDealId,
  fetchOrCreateVoucher,
  updateVoucher,
  populateRecipients,
  addRecipient,
  removeRecipient,
  fetchVoucherOfficeTypes,
  fetchCommercialProperty,
  getMtaNaicsChoices,
  searchMtaNaicsChoicesByCode,
  updateCompany,
  fetchCompanyById,
  createCommercialProperty,
  deleteCommercialProperty,
  updateCommercialProperty,
  getPropertyOptions,
};
