import { connect } from 'react-redux';
import {
  addDealContact,
  deleteDealContact,
  fetchDealContacts,
  updateDealContact,
} from '../../../redux/actions/deal-contacts-actions';
import ContactsTab from './contacts-tab';

const mapStateToProps = state => ({
  deal: state.deal.deal,
  isLoading: state.dealContacts.isLoading,
  next: state.dealContacts.next,
  contacts: state.dealContacts.contacts,
  primaryContact: state.dealContacts.primaryContact,
  user: state.userData.data,
});

const mapDispatchToProps = {
  addDealContact,
  deleteDealContact,
  fetchDealContacts,
  updateDealContact,
};

const mergeProps = (state, actions, props) => ({
  ...state,
  ...actions,
  analyticProperties: {
    actionPrefix: `${props.analyticProperties.actionPrefix}_contacts_tab`,
    categoryPrefix: `${props.analyticProperties.categoryPrefix}Contacts Tab `,
  },
});

const ContactsTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ContactsTab);

export default ContactsTabContainer;
