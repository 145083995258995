import React from 'react';
import PropTypes from 'prop-types';

function Card({
  children,
  className,
  ...other
}) {
  return (
    <div {...other} className={`nd-content-card ${className}`}>
      {children}
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Card.defaultProps = {
  children: null,
  className: '',
};

function InventoryCard({
  children,
  className,
  ...other
}) {
  return (
    <div {...other} className={`nd-inventory-card ${className}`}>
      {children}
    </div>
  );
}

InventoryCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

InventoryCard.defaultProps = {
  children: null,
  className: '',
};


export { Card, InventoryCard };
