import React from 'react';
import PropTypes from 'prop-types';
import ButtonWithFlyout from '../../nucleus/button-with-flyout/button-with-flyout';
import ButtonSegmentedGroup from '../../nucleus/button-segmented-group/button-segmented-group';
import NumberInput from '../../nucleus/inputs/number-input';
import Datepicker from '../../nucleus/datepicker/datepicker';

const CommissionsFilter = (props) => {
  const {
    type,
    currentParams,
    filterByKey,
  } = props;

  const paymentStatusOptions = [
    { display_name: 'Paid', value: 'Paid' },
    { display_name: 'Past Due', value: 'Receivable' },
    { display_name: 'Upcoming', value: 'Upcoming' },
    { display_name: 'Write Off', value: 'Write Off' },
  ];

  const currentPaymentStatus = (currentParams.payment_status && typeof currentParams.payment_status !== 'string')
    ? currentParams.payment_status : [];
  const selectedPaymentStatusOption = currentPaymentStatus.map(status =>
    paymentStatusOptions.find(option => option.value === status)) || [];

  // Payments Screen Filter
  const paymentStatusOptionsinMyPayments = [
    { display_name: 'Paid', value: 'Paid' },
    { display_name: 'Past Due', value: 'Receivable' },
    { display_name: 'Upcoming', value: 'Upcoming' },
  ];
  const currentPaymentStatusinMyPayments = (currentParams.payment_status && typeof currentParams.payment_status !== 'string')
    ? currentParams.payment_status : [];
  const selectedPaymentStatusOptioninMyPayments = currentPaymentStatusinMyPayments.map(status =>
    paymentStatusOptionsinMyPayments.find(option => option.value === status)) || [];

  const handlePaymentStatusChange = (option) => {
    const val = option.map(status => status.value);
    filterByKey(val, 'payment_status');
  };

  return (
    <ButtonWithFlyout
      actionIcon="filter"
      buttonText="Filter"
      buttonIcon="chevron-down"
      position="left"
    >
      <div className="my-installments-filter">
        <div className="filter">
          <ButtonSegmentedGroup
            isMultiSelect
            onChange={handlePaymentStatusChange}
            options={type === 'payments' ? paymentStatusOptionsinMyPayments : paymentStatusOptions}
            selected={type === 'payments' ? selectedPaymentStatusOptioninMyPayments : selectedPaymentStatusOption}
          />
        </div>
        {(type === 'installments') &&
          <div className="filter">
            <div className="filter-label">Amount ($)</div>
            <div className="filter-range-container">
              <NumberInput
                placeholder="Min"
                value={currentParams.min_amount}
                updateValue={value => filterByKey(value, 'min_amount')}
              />
              <span className="range-divider">-</span>
              <NumberInput
                placeholder="Max"
                value={currentParams.max_amount}
                updateValue={value => filterByKey(value, 'max_amount')}
              />
            </div>
          </div>
        }
        {(type === 'payments') &&
          <React.Fragment>
            <div className="filter">
              <div className="filter-label">Gross ($)</div>
              <div className="filter-range-container">
                <NumberInput
                  placeholder="Min"
                  value={currentParams.min_gross}
                  updateValue={value => filterByKey(value, 'min_gross')}
                />
                <span className="range-divider">-</span>
                <NumberInput
                  placeholder="Max"
                  value={currentParams.max_gross}
                  updateValue={value => filterByKey(value, 'max_gross')}
                />
              </div>
            </div>
            <div className="filter">
              <div className="filter-label">Net ($)</div>
              <div className="filter-range-container">
                <NumberInput
                  placeholder="Min"
                  value={currentParams.min_net}
                  updateValue={value => filterByKey(value, 'min_net')}
                />
                <span className="range-divider">-</span>
                <NumberInput
                  placeholder="Max"
                  value={currentParams.max_net}
                  updateValue={value => filterByKey(value, 'max_net')}
                />
              </div>
            </div>
          </React.Fragment>
        }
        <div className="filter">
          <div className="filter-label">Due Date {(type === 'payments') && ' / Date Paid'}</div>
          <div className="filter-range-container">
            <Datepicker
              date={type === 'payments' ? currentParams.start_date : currentParams.due_start_date}
              onDateChange={(date) => {
                const key = type === 'payments' ? 'start_date' : 'due_start_date';
                filterByKey(date, key);
              }}
            />
            <span className="range-divider">-</span>
            <Datepicker
              date={type === 'payments' ? currentParams.end_date : currentParams.due_end_date}
              onDateChange={(date) => {
                const key = type === 'payments' ? 'end_date' : 'due_end_date';
                filterByKey(date, key);
              }}
            />
          </div>
        </div>
        {(type === 'installments') &&
          <div className="filter">
            <div className="filter-label">Date Paid</div>
            <div className="filter-range-container">
              <Datepicker
                date={currentParams.paid_start_date}
                onDateChange={date => filterByKey(date, 'paid_start_date')}
              />
              <span className="range-divider">-</span>
              <Datepicker
                date={currentParams.paid_end_date}
                onDateChange={date => filterByKey(date, 'paid_end_date')}
              />
            </div>
          </div>
        }
      </div>
    </ButtonWithFlyout>
  );
};

export default CommissionsFilter;

CommissionsFilter.propTypes = {
  type: PropTypes.oneOf(['payments', 'installments']).isRequired,
  currentParams: PropTypes.shape({
    payment_status: PropTypes.arrayOf(PropTypes.string),
    min_amount: PropTypes.number,
    max_amount: PropTypes.number,
    min_gross: PropTypes.number,
    max_gross: PropTypes.number,
    min_net: PropTypes.number,
    max_net: PropTypes.number,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    due_start_date: PropTypes.string,
    due_end_date: PropTypes.string,
    paid_start_date: PropTypes.string,
    paid_end_date: PropTypes.string,
  }).isRequired,
  filterByKey: PropTypes.func.isRequired,
};

