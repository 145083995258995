import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@cbrebuild/blocks';
import Avatar from '../../nucleus/avatar/avatar';
import FilterMenu from '../../nucleus/filter-menu/FilterMenu';
import TeamTile from '../teams/team-tile';

const includesTerm = (term = '', search = '') => term.toLowerCase().includes(search.toLowerCase());

const ProducerFilters = ({
  currentParams,
  teams,
  brokers,
  users,
  filterByBroker,
  filterByTeam,
  filterByUser,
  filterByAllUsersAndTeams,
  onFilterOpen,
  onFilterClose,
  isInstallmentFlow,
}) => {
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState(search);

  useEffect(() => {
    const debounce = setTimeout(() => setSearchFilter(search), 300);
    return () => clearTimeout(debounce);
  }, [search]);

  const filteredUsers = useMemo(() => (
    searchFilter ? users.filter(({
      first_name, last_name,
    }) => includesTerm(`${first_name} ${last_name}`, searchFilter)) : users
  ), [searchFilter, users]);

  const filteredBrokers = useMemo(() => (
    searchFilter ? brokers.filter(({
      first_name, last_name,
    }) => includesTerm(`${first_name} ${last_name}`, searchFilter)) : brokers
  ), [searchFilter, brokers]);

  const filteredTeams = useMemo(() => (searchFilter ? teams.filter(({ name }) => includesTerm(name, searchFilter)) : teams), [teams, searchFilter]);

  const allSelected = (
    (teams?.length + users?.length + brokers?.length) ===
    (currentParams.teams ? currentParams.teams?.length : 0) +
      (currentParams.users ? currentParams.users?.length : 0) + (currentParams.brokers ? currentParams.brokers?.length : 0)
  );

  const selectionState = useMemo(() => {
    const selectedCount = (currentParams.teams ? currentParams.teams?.length : 0) +
      (currentParams.users ? currentParams.users?.length : 0) + (currentParams.brokers ? currentParams.brokers?.length : 0);
    const totalCount = teams?.length + users?.length + brokers?.length;
    return {
      indeterminate: !!selectedCount && selectedCount !== totalCount,
      checked: selectedCount && selectedCount === totalCount,
    };
  }, [
    currentParams.users,
    currentParams.teams,
    currentParams.brokers,
    users,
    teams,
    brokers,
  ]);

  return (
    <FilterMenu
      className="producer-filter"
      buttonText={isInstallmentFlow ? 'Producers' : 'Teams'}
      buttonIcon="chevron-down"
      position="left"
      onOpen={onFilterOpen}
      onClose={(e) => {
        onFilterClose(e);
        setSearch('');
      }}
    >
      <div className="producer-filters">
        <div className="filter producer-search">
          <input
            type="search"
            value={search}
            placeholder={isInstallmentFlow ? 'Search for Producer' : 'Search for Team or Team Member'}
            onChange={({ target: { value } }) => setSearch(value)}
          />
        </div>
        <div className="producer-filter-list">
          {!search.length && (
            <div className="filter">
              <Checkbox
                {...selectionState}
                onChange={() => filterByAllUsersAndTeams(!allSelected)}
              >
                All
              </Checkbox>
            </div>
          )}
          {filteredTeams.map(({
            name, id, avatar_color, users: teamUsers,
          }) => (
            <div className="filter" key={`${id}`}>
              <Checkbox
                name={`${id}`}
                checked={!!currentParams.teams.includes(id)}
                onChange={filterByTeam}
              >
                <span className="producer-filter-list-item">
                  <TeamTile
                    team={{
                      name,
                      users: teamUsers,
                      avatar_color,
                    }}
                    showHover
                    hoverAlign="center"
                  />
                </span>
              </Checkbox>
            </div>
          ))}
          {filteredUsers.map(({
            id, first_name, last_name, avatar, email: { work },
          }) => (
            <div className="filter" key={id}>
              <Checkbox
                name={`${id}`}
                checked={!!currentParams.users.includes(id)}
                onChange={filterByUser}
              >
                <span className="producer-filter-list-item">
                  <Avatar
                    image={avatar}
                    firstName={first_name}
                    lastName={last_name}
                  />
                  <div>
                    <p className="primary">{`${first_name} ${last_name}`}</p>
                    <p className="secondary">{work}</p>
                  </div>
                </span>
              </Checkbox>
            </div>
          ))}
          {filteredBrokers.map(({
            id, first_name, last_name, avatar, email,
          }) => (
            <div className="filter" key={id}>
              <Checkbox
                name={`${id}`}
                checked={!!currentParams.brokers.includes(id)}
                onChange={filterByBroker}
              >
                <span className="producer-filter-list-item">
                  <Avatar
                    image={avatar}
                    firstName={first_name}
                    lastName={last_name}
                  />
                  <div>
                    <p className="primary">{`${first_name} ${last_name}`}</p>
                    <p className="secondary">{`${email}`}</p>
                  </div>
                </span>
              </Checkbox>
            </div>
          ))}
          {!(filteredTeams.length + filteredUsers.length + filteredBrokers.length) && (
            <p>No Producers Found</p>
          )}
        </div>
      </div>
    </FilterMenu>
  );
};


ProducerFilters.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  brokers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  teams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  currentParams: PropTypes.shape({
    teams: PropTypes.arrayOf(PropTypes.number),
    users: PropTypes.arrayOf(PropTypes.number),
    brokers: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  filterByTeam: PropTypes.func,
  filterByUser: PropTypes.func.isRequired,
  filterByBroker: PropTypes.func,
  filterByAllUsersAndTeams: PropTypes.func.isRequired,
  onFilterOpen: PropTypes.func,
  onFilterClose: PropTypes.func,
  isInstallmentFlow: PropTypes.bool,
};

ProducerFilters.defaultProps = {
  onFilterOpen: () => { },
  onFilterClose: () => { },
  teams: [],
  brokers: [],
  filterByTeam: () => { },
  filterByBroker: () => { },
  isInstallmentFlow: false,
};

export default ProducerFilters;
