import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export default function EllipsisTooltip({ phrase }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useRef();
  useEffect(() => {
    function handleTooltip() {
      setShowTooltip(ref.current && ref.current.offsetWidth < ref.current.scrollWidth);
    }
    handleTooltip();
    // listener to call handleTooltip again when window resizes
    window.addEventListener('resize', handleTooltip);
    return () => {
      window.removeEventListener('resize', handleTooltip);
    };
  }, [phrase]);

  return (
    <span
      className="ellipsis-tooltip"
      ref={ref}
    >
      {phrase}
      {showTooltip && (<span className="hoverstatetext">{phrase}</span>)}
    </span>
  );
}

EllipsisTooltip.propTypes = {
  phrase: PropTypes.string.isRequired,
};
