import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../nucleus/modal/modal';
import ButtonSegmentedGroup from '../../nucleus/button-segmented-group/button-segmented-group';

const exportContactOptions = [
  {
    display_name: 'Primary',
    value: 'primary',
  },
  {
    display_name: 'All',
    value: 'all',
  },
];

const ExportDealListModal = (props) => {
  const [exportAllContacts, setExportAllContacts] = useState(false);
  return (
    <Modal
      showModal
      handleModalSubmit={() => props.onConfirm(exportAllContacts)}
      handleSecondaryButton={props.onCancel}
      handleModalToggle={props.onCancel}
      modalHeader="Export Deal List"
      primaryButtonText="Confirm"
      secondaryButtonText="Cancel"
      modalWidth={400}
    >
      <p>Which contacts would you like included in your Deal export?</p>
      <ButtonSegmentedGroup
        options={exportContactOptions}
        selected={exportContactOptions[exportAllContacts ? 1 : 0]}
        onChange={({ value }) => setExportAllContacts(value === 'all')}
      />
    </Modal>
  );
};

ExportDealListModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ExportDealListModal;
