import {
  VOUCHER_CREATE_COMMISSION_SUCCESS,
  VOUCHER_DELETE_COMMISSION_SUCCESS,
  VOUCHER_FETCH_COMMISSIONS_SUCCESS,
  VOUCHER_UPDATE_COMMISSION_SUCCESS,
} from './action-types';
import * as commissionsService from '../../services/voucher/commissions-service';
import { updateFinancials } from './financial-actions';

// action creators
const createCommissionSuccess = commission => ({
  type: VOUCHER_CREATE_COMMISSION_SUCCESS,
  payload: commission,
});
const deleteCommissionSuccess = id => ({
  type: VOUCHER_DELETE_COMMISSION_SUCCESS,
  payload: id,
});
const fetchCommissionsSuccess = commissions => ({
  type: VOUCHER_FETCH_COMMISSIONS_SUCCESS,
  payload: commissions,
});
const updateCommissionSuccess = commission => ({
  type: VOUCHER_UPDATE_COMMISSION_SUCCESS,
  payload: commission,
});

// async
const createCommission = params => dispatch => commissionsService.createCommission(params)
  .then(commission => dispatch(createCommissionSuccess(commission)))
  .catch(console.error);

const deleteCommission = id => (dispatch, getState) => commissionsService.deleteCommission(id)
  .then(() => {
    const current = getState().commissions.commissions.find(c => c.id === id);
    const wasLead = !!current?.broker_is_lead;
    dispatch(deleteCommissionSuccess(id));
    if (wasLead) {
      let newLead = getState().commissions.commissions.find(e => e.cbre_broker_type === 'employee');
      if (!newLead) {
        newLead = getState().commissions.commissions.find(e => e.cbre_broker_type === 'office');
      }
      if (!newLead) {
        newLead = getState().commissions.commissions.find(e => e.cbre_broker_type === 'pool');
      }
      if (newLead) {
        commissionsService.updateCommission(newLead.id, { broker_is_lead: true })
          .then(res => dispatch(updateCommissionSuccess(res)))
          .catch(console.error);
      }
      dispatch(updateFinancials(getState().deal.deal.id));
    }
  })
  .catch(console.error);

const fetchCommissions = params => dispatch => commissionsService.fetchCommissions(params)
  .then(commissions => dispatch(fetchCommissionsSuccess(commissions)))
  .catch(console.error);

const updateCommission = (id, data, ignoreFinancialUpdate = false) => (dispatch, getState) => commissionsService.updateCommission(id, data)
  .then((commission) => {
    if ('broker_is_lead' in data) {
      // we must remove the previous lead
      const previous = getState().commissions.commissions.find(c => c.broker_is_lead);
      if (previous) {
        commissionsService.updateCommission(previous.id, { broker_is_lead: false })
          .then(res => dispatch(updateCommissionSuccess(res))).catch(console.error);
      }
    }
    if ('gross_commission' in data && !ignoreFinancialUpdate) {
      dispatch(updateFinancials(commission.transaction));
    }
    return dispatch(updateCommissionSuccess(commission));
  })
  .catch(console.error);

export {
  createCommission,
  deleteCommission,
  fetchCommissions,
  updateCommission,
};
