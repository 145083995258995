import dealsService from '../../services/deals-service';
import {
  DEALS_LOAD_METADATA_SUCCESS,
  DEALS_LOAD_METADATA_FAILURE,
} from '../actions/action-types';

const loadDealsMetadataSuccess = deals => ({
  type: DEALS_LOAD_METADATA_SUCCESS,
  payload: deals,
});

const loadDealsMetadataFailure = () => ({
  type: DEALS_LOAD_METADATA_FAILURE,
  payload: [],
});

const loadDealsMetadata = () => dipatch =>
  dealsService.getMetadata()
  // dealsService.fetchDealsAndTransactionsES({ page_size: 5000 })
    .then((results) => {
      dipatch(loadDealsMetadataSuccess(results));
    }).catch(() => {
      dipatch(loadDealsMetadataFailure());
    });

export {
  // eslint-disable-next-line import/prefer-default-export
  loadDealsMetadata,
};
