import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import AddItem from '../../../../nucleus/add-item/add-item';
import AddTag from '../../../../nucleus/legacy/tag/add-tag';
import FilterTag from '../../../../nucleus/legacy/tag/filter-tag';

const AddressItem = ({ address, type }) => (
  <div className="address">
    <label>{`${type} Address`}</label>
    {address.street && <p>{address.street}</p>}
    <div className="city-state-zip">
      {address.city && <p>{address.city},</p>}
      {address.state && <p>{address.state}</p>}
      {address.postalCode && <p>{address.postalCode}</p>}
    </div>
  </div>
);

AddressItem.propTypes = {
  address: PropTypes.shape({
    city: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

const ContactListItemDetails = ({
  allContactTags,
  contact: {
    business_address,
    email_addresses,
    home_address,
    other_address,
    phone_numbers,
    tags,
  },
  isContactsTab,
  onAddTag,
  onEdit,
  onRemoveTag,
  onTagClick,
}) => {
  const hasBusinessAddress = !isEmpty(business_address);
  const hasHomeAddress = !isEmpty(home_address);
  const hasOtherAddress = !isEmpty(other_address);
  const bindOnFilterClick = (tag, action) => () => action(tag);
  return (
    <div>
      <div className="contact-methods">
        <div className="addresses">
          <React.Fragment>
            {(!hasBusinessAddress && !hasHomeAddress && !hasOtherAddress) &&
              <AddItem label="Address" onClick={onEdit} />
            }
            {hasBusinessAddress && <AddressItem address={business_address} type="Work" />}
            {hasHomeAddress && <AddressItem address={home_address} type="Home" />}
            {hasOtherAddress && <AddressItem address={other_address} type="Other" />}
          </React.Fragment>
        </div>
        <div className="phone-numbers">
          {phone_numbers.length
            ? (
              <React.Fragment>
                <label>Phone Numbers</label>
                <ul>
                  {phone_numbers.map(phone => (
                    <li key={phone.number}>
                      <a href={`tel:${phone.number}`}>{phone.type.substr(0, 1).toUpperCase()}: {phone.number}</a>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            )
            : <AddItem label="Phone" onClick={onEdit} />
          }
        </div>
        <div className="email-addresses">
          {email_addresses.length
            ? (
              <React.Fragment>
                <label>Email Addresses</label>
                <ul>
                  {email_addresses.map(email => (
                    <li key={email.address}>
                      <a href={`mailto:${email.address}`}>{email.type.substr(0, 1).toUpperCase()}: {email.address}</a>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            )
            : <AddItem label="Email" onClick={onEdit} />
          }
        </div>
      </div>
      {/* only add AddTag and Tags capabilities for "My Contacts" */}
      {!isContactsTab &&
        <div className="tags">
          {tags && tags.map(tag => (
            <FilterTag
              isButton={false}
              key={`contact-tag=${tag}`}
              onTagClick={bindOnFilterClick(tag, onTagClick)}
              value={tag}
              handleClearFilter={bindOnFilterClick(tag, onRemoveTag)}
            />
          ))}
          <AddTag
            onSubmit={onAddTag}
            suggestions={allContactTags}
          />
        </div>
      }
    </div>
  );
};

ContactListItemDetails.propTypes = {
  allContactTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  contact: PropTypes.shape({
    business_address: PropTypes.shape({}),
    email_addresses: PropTypes.arrayOf(PropTypes.shape({})),
    home_address: PropTypes.shape({}),
    other_address: PropTypes.shape({}),
    phone_numbers: PropTypes.arrayOf(PropTypes.shape({})),
    tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  isContactsTab: PropTypes.bool.isRequired,
  onAddTag: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  onTagClick: PropTypes.func.isRequired,
};

export default ContactListItemDetails;
