import React, { useEffect, useState } from 'react';
import { IconButton } from '@cbrebuild/blocks';
// eslint-disable-next-line import/no-extraneous-dependencies
import { _ } from 'core-js';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import VOUCHER_ROUTES from '../../../page-templates/voucher/voucher-subroutes';
import RequiredLabeledItem from './RequiredLabeledItem';
import formatAsCurrency from '../../../utils/format-as-currency';
import Percent from '../../../nucleus/formats/percent';
import { PAYMENT_GROUPS } from '../../../services/voucher/commissions-service';

const CompDataReview = () => {
  const {
    cbreCommissions, compData, commercialProperties, considerations, deal, outsideCommissions, voucher,
  } = useSelector(state => ({
    cbreCommissions: state.commissions.commissions?.filter(({ payment_group }) => payment_group === PAYMENT_GROUPS.CBRE_BROKER),
    compData: state.compData,
    commercialProperties: state.voucher.commercialProperties,
    considerations: state.voucherConsiderations?.considerations,
    deal: state.deal.deal,
    outsideCommissions: state.commissions.commissions?.filter(({ payment_group }) => payment_group === PAYMENT_GROUPS.OUTSIDE_BROKER),
    voucher: state.voucher.voucher,
  }));
  const [compDataIsLoaded, setCompDataIsLoaded] = useState(false);
  useEffect(() => {
    if (!compData.compData) setCompDataIsLoaded(true);
  }, [compData]);
  const history = useHistory();
  const url = VOUCHER_ROUTES[3].path.replace(':id', voucher.deal);
  const requiredText = <p className="voucher-error-text">Required</p>;
  const isDealType = args => !!_.find(args, arg => arg === deal.deal_type);
  const tenantBuyer = (deal.rep_role === 'tenant' || deal.rep_role === 'buyer') ? deal.client : deal.counter_party;
  const landlordSeller = (deal.rep_role === 'landlord' || deal.rep_role === 'seller') ? deal.client : deal.counter_party;
  const properties = _.sortBy(commercialProperties, 'id').filter(property => property.location_type_new === true);
  const getSquareFoot = (property) => {
    if (property) {
      return property.whole_building
        ? parseInt(property.building_total_square_feet, 10)
        : _.sumBy(property.spaces, o => o.space_total_square_feet);
    }
    return null;
  };
  const totalPropertySqft = _.sumBy(properties, o => (o.whole_building ? parseInt(o.building_total_square_feet, 10) : getSquareFoot(o)));
  const previousProperty = _.filter(commercialProperties, property => property.location_type_new === false)[0];
  const getLeaseDuration = () => {
    if (!deal.date_lease_from || !deal.date_lease_to) {
      return null;
    }
    const leaseStart = moment(deal.date_lease_from);
    const leaseEnd = moment(deal.date_lease_to);
    const leaseTermDays = leaseEnd.diff(leaseStart, 'days');
    const AVG_DAYS_PER_MONTH = 365 / 12;
    const leaseTermMonths = Math.round(leaseTermDays / (AVG_DAYS_PER_MONTH));
    return leaseTermMonths;
  };
  const formatYearMonthRent = (months) => {
    if (months === 0) {
      return '0 years 0 months';
    }
    if (months) {
      const year = Math.floor(compData?.free_rent / 12);
      const month = parseFloat((months % 12).toFixed(4));
      let yearStr = '';
      let monthStr = '';

      if (year > 0) yearStr = `${year} year`;
      if (year > 1) yearStr = `${year} years`;
      if (month > 0) monthStr = `${month} month`;
      if (month > 1) monthStr = `${month} months`;

      return `${yearStr} ${monthStr}`;
    }
    return null;
  };
  const returnMonthRent = (months) => {
    if (months === 0) return '0';
    if (months) return parseFloat((months % 12).toFixed(4));
    return null;
  };
  const returnYearRent = (months) => {
    if (months === 0) return '0';
    if (months) return Math.floor(months / 12);
    return null;
  };
  const purchasePrice = _.sumBy(considerations, o => ((o.consideration_type === 'Purchase Price') ? o.amount : 0));
  const getPricePerSqFt = () => (totalPropertySqft ? (purchasePrice / totalPropertySqft) : null);

  const displayEscalationSchedule = (
    <table>
      <colgroup>
        <col />
        <col name="rent" />
        <col name="years" />
        <col name="months" />
      </colgroup>
      <tbody>
        <tr className="borderless-row">
          <th />
          <th>Rent</th>
          <th>Years</th>
          <th>Months</th>
        </tr>
        {compData?.rent_escalation_custom?.map((item, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{!item.is_percent ? formatAsCurrency(item.rent_dollar) : <Percent value={item.rent_percent} decimalRounding={2} />}</td>
            <td>{item.months
              ? returnYearRent(item.months)
              : (index === 0 && requiredText) || '--'}
            </td>
            <td>{item.months
              ? returnMonthRent(item.months)
              : (index === 0 && requiredText) || '--'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  const displayRentSchedule = (
    <table>
      <colgroup>
        <col />
        <col name="rent type" />
        <col name="period" />
        <col name="rent" />
      </colgroup>
      <tbody>
        <tr className="borderless-row">
          <th>Rent Type</th>
          <th>Period</th>
          <th>Rent</th>
        </tr>
        {considerations?.map((item, index) => (
          <tr key={index}>
            <td>{item.consideration_type}</td>
            <td>{item.total_units}</td>
            <td>{formatAsCurrency(item.amount)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const sortedCBREBrokers = cbreCommissions.sort((a, b) => {
    if (a.broker_is_lead) return -1;
    if (b.broker_is_lead) return 1;
    return 0;
  });
  const sortedOutsideBrokers = outsideCommissions.sort((a, b) => {
    if (a.broker_is_lead) return -1;
    if (b.broker_is_lead) return 1;
    return 0;
  });

  return (
    <div className="voucher-review-group comp-data-review no-divider">
      <div className="navigation">
        <Link className="h2 link" to={url}>Comp Data</Link>
        <IconButton iconName="edit" onClick={() => { history.push(url); }} />
      </div>
      <div className="content">
        <div className="voucher-review-group no-divider">
          <h3>General Information</h3>
          <div className="two-cols">
            <div className="col-left">
              <RequiredLabeledItem label="Transaction Type" item={deal.deal_type === 'sale' ? 'Sale' : 'Lease'} notRequired isReview={compDataIsLoaded} />
              {isDealType(['lease']) ?
                <>
                  <RequiredLabeledItem label="Tenant" item={tenantBuyer?.name} isReview={compDataIsLoaded} />
                  <RequiredLabeledItem label="Tenant Industry" item={tenantBuyer?.voucher_naics_category} notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem
                    label="Tenant Represented By"
                    item={compData?.tenant_represented_by}
                    notRequired={voucher.is_early_invoice}
                    isReview={compDataIsLoaded}
                  />
                  <RequiredLabeledItem
                    label="Tenant Represented By Agent"
                    item={compData?.tenant_represented_by_agent}
                    notRequired={voucher.is_early_invoice}
                    isReview={compDataIsLoaded}
                  />
                  <RequiredLabeledItem label="Agile Deal" item={deal.is_agile_deal} format="bool" notRequired isReview={compDataIsLoaded} />
                </>
                : ''
              }
              {isDealType(['sale']) ?
                <>
                  <RequiredLabeledItem label="Buyer" item={tenantBuyer?.name} notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem label="Buyer Industry" item={tenantBuyer?.voucher_naics_category} notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem
                    label="Buyer Represented By"
                    item={compData?.buyer_represented_by}
                    notRequired={voucher.is_early_invoice}
                    isReview={compDataIsLoaded}
                  />
                  <RequiredLabeledItem
                    label="Buyer Represented By Agent"
                    item={compData?.buyer_represented_by_agent}
                    notRequired={voucher.is_early_invoice}
                    isReview={compDataIsLoaded}
                  />
                </>
                : ''
              }
            </div>
            <div className="col-right">
              <RequiredLabeledItem label="Account Driven" item={compData?.account_driven_deal} notRequired isReview={compDataIsLoaded} />
              {isDealType(['lease']) ?
                <>
                  <RequiredLabeledItem label="Landlord" item={landlordSeller?.name} notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem
                    label="Landlord Represented By"
                    item={compData?.landlord_represented_by}
                    notRequired={voucher.is_early_invoice}
                    isReview={compDataIsLoaded}
                  />
                  <RequiredLabeledItem
                    label="Landlord Represented By Agent"
                    item={compData?.landlord_represented_by_agent}
                    notRequired={voucher.is_early_invoice}
                    isReview={compDataIsLoaded}
                  />
                </>
                : ''
              }
              {isDealType(['sale']) ?
                <>
                  <RequiredLabeledItem label="Seller" item={landlordSeller?.name} notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem
                    label="Seller Represented By"
                    item={compData?.seller_represented_by}
                    notRequired={voucher.is_early_invoice}
                    isReview={compDataIsLoaded}
                  />
                  <RequiredLabeledItem
                    label="Seller Represented By Agent"
                    item={compData?.seller_represented_by_agent}
                    notRequired={voucher.is_early_invoice}
                    isReview={compDataIsLoaded}
                  />
                </>
                : ''
              }
              {(isDealType(['lease']) && deal.is_agile_deal) ?
                <>
                  <RequiredLabeledItem label="Cost Per Seat" item={deal.cost_per_seat} format="currency" notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem label="Number of Seats" item={deal.number_of_seats} format="number" notRequired isReview={compDataIsLoaded} />
                </>
                : ''
              }
            </div>
          </div>
        </div>
        {isDealType(['lease']) &&
          <div className="voucher-review-group no-divider">
            <h3>Lease Details</h3>
            <div className="two-cols">
              <div className="col-left">
                <RequiredLabeledItem label="Prior Location" item={previousProperty} format="address" notRequired isReview={compDataIsLoaded} />
                <RequiredLabeledItem label="Prior SF" item={getSquareFoot(previousProperty)} format="number" notRequired isReview={compDataIsLoaded} />
              </div>
              <div className="col-right">
                <RequiredLabeledItem label="Lease Agreement Type" item={deal.lease_agreement_type} notRequired isReview={compDataIsLoaded} />
                <RequiredLabeledItem label="Lease Commencement Date" item={deal.date_lease_from} format="shortDate" notRequired isReview={compDataIsLoaded} />
                <RequiredLabeledItem label="Lease Expiration Date" item={deal.date_lease_to} format="shortDate" notRequired isReview={compDataIsLoaded} />
                <RequiredLabeledItem label="Lease Term (months)" item={getLeaseDuration()} format="number" notRequired isReview={compDataIsLoaded} />
                <RequiredLabeledItem label="Lease Sign Date" item={deal.date_lease_execution} format="shortDate" notRequired isReview={compDataIsLoaded} />
                <RequiredLabeledItem label="Lease Occupancy Date" item={deal.date_lease_occupancy} format="shortDate" notRequired isReview={compDataIsLoaded} />
              </div>
            </div>
          </div>}
        <div className="voucher-review-group no-divider">
          {properties?.map((property, index) => (
            <div key={property.id || index}>
              <h3>Property #{index + 1}</h3>
              <div className="two-cols">
                <div className="left-col">
                  <RequiredLabeledItem label="Property Name" item={property?.name} notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem label="Property Address" item={property} format="address" notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem label="Property Type" item={property?.voucher_property_type_category} notRequired isReview={compDataIsLoaded} />
                  {property.whole_building &&
                    <>
                      <RequiredLabeledItem label="Square Feet" item={getSquareFoot(property)} format="number" notRequired isReview={compDataIsLoaded} />
                      <RequiredLabeledItem label="Acreage" item={property?.parcel_size} format="number" notRequired isReview={compDataIsLoaded} />
                    </>
                  }
                </div>
                <div className="right-col">
                  {!property.whole_building &&
                      property?.spaces?.map((space, indexSuite) => (
                        <div key={indexSuite}>
                          <h3>Suite #{indexSuite + 1}</h3>
                          <RequiredLabeledItem label="Suite" item={space.address_line2} format="number" notRequired isReview={compDataIsLoaded} />
                          <RequiredLabeledItem label="Floor" item={space.floor} format="number" notRequired isReview={compDataIsLoaded} />
                          <RequiredLabeledItem
                            label="Square Feet"
                            item={space.space_total_square_feet}
                            format="number"
                            notRequired
                            isReview={compDataIsLoaded}
                          />
                        </div>
                      ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="voucher-review-group no-divider">
          <h3>Rent &amp; Escalations</h3>
          {isDealType(['lease']) &&
            <div className="two-cols">
              <div className="left-col">
                <RequiredLabeledItem
                  label="Base Rent ($/SF per year)"
                  item={compData?.base_rent}
                  format="currency"
                  notRequired={voucher.is_early_invoice}
                  isReview={compDataIsLoaded}
                />
                <RequiredLabeledItem
                  label="Free Rent"
                  item={formatYearMonthRent(compData?.free_rent)}
                  notRequired={voucher.is_early_invoice}
                  isReview={compDataIsLoaded}
                />
                {!compData?.rent_escalation_is_custom &&
                    (!compData?.rent_escalation_is_percent
                      ? <RequiredLabeledItem
                          label="Escalation (Per Year)"
                          item={compData?.rent_escalation_dollar}
                          format="currency"
                          notRequired={voucher.is_early_invoice}
                          isReview={compDataIsLoaded}
                      />
                      : <RequiredLabeledItem
                          label="Escalation (Per Year)"
                          item={compData?.rent_escalation_percent}
                          format="percent"
                          notRequired={voucher.is_early_invoice}
                          isReview={compDataIsLoaded}
                      />
                      )
                }
                <RequiredLabeledItem
                  label="Escalations Schedule"
                  item={displayEscalationSchedule}
                  notRequired
                  isReview={compDataIsLoaded}
                />
              </div>
              <div className="right-col">
                <RequiredLabeledItem
                  label="Rent Schedule"
                  item={displayRentSchedule}
                  notRequired
                  isReview={compDataIsLoaded}
                />
              </div>
            </div>
          }
          {isDealType(['sale']) &&
            <div className="two-cols">
              <div className="left-col">
                <RequiredLabeledItem label="Sale Price" item={purchasePrice} format="currency" notRequired isReview={compDataIsLoaded} />
                <RequiredLabeledItem label="Price Per SF" item={getPricePerSqFt()} format="currency" notRequired isReview={compDataIsLoaded} />
                <RequiredLabeledItem label="Cap Rate" item={properties.length && properties[0].cap_yield_rate} notRequired isReview={compDataIsLoaded} />
              </div>
            </div>
          }
        </div>
        {isDealType(['lease']) &&
          <>
            <div className="voucher-review-group no-divider">
              <h3>Industrial Leases</h3>
              <div className="two-cols">
                <div className="left-col">
                  <RequiredLabeledItem label="Office Area (SF)" item={compData?.office_area} format="number" notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem
                    label="Warehouse Rent ($/SF)"
                    item={compData?.warehouse_rent_amount_per_sqft}
                    format="currency"
                    notRequired
                    isReview={compDataIsLoaded}
                  />
                  <RequiredLabeledItem label="Power (Amps)" item={compData?.power_amps} format="number" notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem label="GL Door Count" item={compData?.gl_door_count} format="number" notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem label="Truckwell Count" item={compData?.truckwell_count} format="number" notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem label="Rail Serviced" item={compData?.rail_serviced} notRequired isReview={compDataIsLoaded} />
                </div>
                <div className="right-col">
                  <RequiredLabeledItem
                    label="Office Rent ($/SF)"
                    item={compData?.office_rent_amount_per_sqft}
                    format="currency"
                    notRequired
                    isReview={compDataIsLoaded}
                  />
                  <RequiredLabeledItem label="Clear Height (Feet)" item={compData?.clear_height} notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem label="Power (Volts)" item={compData?.power_volts} format="number" notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem label="DH Door Count" item={compData?.dh_door_count} format="number" notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem label="Cross Dock Count" item={compData?.cross_dock_count} format="number" notRequired isReview={compDataIsLoaded} />
                </div>
              </div>
            </div>
            <div className="voucher-review-group no-divider">
              <h3>Expenses</h3>
              <div className="two-cols">
                <div className="left-col">
                  <RequiredLabeledItem
                    label="Tenant Improvement Allowance ($/SF)"
                    item={compData?.tenant_improvement_allowance_amount_per_sqft}
                    format="currency"
                    notRequired={voucher.is_early_invoice}
                    isReview={compDataIsLoaded}
                  />
                  <RequiredLabeledItem
                    label="Tenant Improvements Detail"
                    item={compData?.tenant_improvements_detail}
                    notRequired={voucher.is_early_invoice && compData?.tenant_improvement_allowance_amount_per_sqft !== 0}
                    isReview={compDataIsLoaded}
                  />
                  <RequiredLabeledItem
                    label="Operating Expenses ($/SF)"
                    item={compData?.operating_expenses_amount_per_sqft}
                    format="currency"
                    notRequired={voucher.is_early_invoice}
                    isReview={compDataIsLoaded}
                  />
                  <RequiredLabeledItem
                    label="Electric included in Oper Exp."
                    item={compData?.electric_included_in_operating_expenses}
                    format="bool"
                    isReview={compDataIsLoaded}
                  />
                  <RequiredLabeledItem
                    label="Electric Expense ($/SF)"
                    item={compData?.electric_expense_amount_per_sqft}
                    format="currency"
                    notRequired={voucher.is_early_invoice && !compData?.electric_included_in_operating_expenses}
                    isReview={compDataIsLoaded}
                  />
                </div>
                <div className="right-col">
                  <RequiredLabeledItem label="Parking Ratio" item={compData?.parking_ratio} format="number" notRequired isReview={compDataIsLoaded} />
                  <RequiredLabeledItem
                    label="Parking Expenses ($/Space)"
                    item={compData?.parking_expenses_amount_per_space}
                    format="currency"
                    notRequired
                    isReview={compDataIsLoaded}
                  />
                  <RequiredLabeledItem
                    label="Taxes ($/SF)"
                    item={compData?.tax_amount}
                    format="currency"
                    notRequired={voucher.is_early_invoice}
                    isReview={compDataIsLoaded}
                  />
                </div>
              </div>
            </div>
          </>
        }
        <div className="voucher-review-group no-divider">
          <h3>Brokers</h3>
          <div className="two-cols">
            <div className="left-col">
              {sortedCBREBrokers?.map(broker => (
                <RequiredLabeledItem
                  key={broker.id}
                  label={broker.broker_is_lead ? 'Lead CBRE Broker' : 'Additional CBRE Broker'}
                  item={broker.broker ? `${broker.broker.first_name} ${broker.broker.last_name}` : '--'}
                  isReview={compDataIsLoaded}
                />
              ))}
            </div>
            <div className="right-col">
              {sortedOutsideBrokers?.map(broker => (
                <div key={broker.id}>
                  <RequiredLabeledItem
                    label={broker.broker_is_lead ? 'Lead Outside Broker Company' : 'Additional Outside Broker Company'}
                    item={broker.vendor?.name}
                    isReview={compDataIsLoaded}
                    notRequired
                  />
                  <RequiredLabeledItem
                    label={broker.broker_is_lead ? 'Lead Outside Broker Name' : 'Additional Outside Broker Name'}
                    item={broker.outside_broker_name}
                    isReview={compDataIsLoaded}
                    notRequired
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="voucher-review-group no-divider">
          <RequiredLabeledItem label="General Comments" item={compData?.general_comments} notRequired isReview={compDataIsLoaded} />
        </div>
      </div>
    </div>
  );
};

export default CompDataReview;
