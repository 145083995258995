import { Button } from '@cbrebuild/blocks';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setProxyAccess } from '../../redux/actions/proxy-settings-actions';
import proxyService from '../../services/proxy-service';

const ProxyNavControl = () => {
  const [currentProxy, setCurrentProxy] = useState({});
  const { proxy, options } = useSelector(state => ({
    proxy: state.proxy,
    options: state.userOptions,
  }));
  const { userID } = useSelector(state => ({
    userID: state.userData.data.id,
  }));
  const proxyUser = `${currentProxy.email}`;
  const proxyString = 'Viewing as Proxy for';

  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentProxy(proxy.proxySettings);
  }, [proxy]);

  useEffect(() => {
    let proxyInServer;
    proxyService.fetchProxyUser().then((res) => {
      proxyInServer = res.privilege_access_users.find(proxyAccess => (proxyAccess.is_active === true));
    }).then(() => {
      if (proxyInServer) {
        setCurrentProxy(proxyInServer.user);
        dispatch(setProxyAccess(proxyInServer.user));
      }
    });
  }, [dispatch]);

  const triggerProxyClear = () => {
    proxyService.switchProxyUser(userID).then(() => {
      dispatch(setProxyAccess({}));
      window.location.assign(window.location.href);
    });
  };

  const proxyNavElement = () => (
    <div className="proxy-nav-control-container">
      <NavLink
        to="/profile/switch-proxy"
        className="proxy-nav-control-text"
        activeClassName="proxy-nav-active"
      >
        <div>{proxyString}&nbsp;</div>
        <div className="proxy-user-text">{proxyUser}</div>
      </NavLink>
      <Button className="proxy-off-button" onClick={() => triggerProxyClear()}>
        Exit
      </Button>
    </div>
  );

  return (
    <>
      {!isEmpty(currentProxy) && !!options.userOptions.feature_flags.proxy_user_flag ? proxyNavElement() : <></>}
    </>
  );
};

export default ProxyNavControl;
