import React from 'react';
import PropTypes from 'prop-types';
import NoteListPanel from './note-list-panel';

const NotesTab = (props) => {
  const {
    addNote,
    analyticProperties,
    dealId,
    deleteNote,
    fetchNotes,
    isLoading,
    next,
    newNote,
    newNoteCleanup,
    notes,
    updateNote,
    user,
  } = props;

  return (
    <div className="notes-tab detail-panels-layout">
      <div className="col-two-thirds">
        <NoteListPanel
          analyticProperties={analyticProperties}
          dealId={dealId}
          isLoading={isLoading}
          next={next}
          newNote={newNote}
          notes={notes}
          user={user}
          addNote={addNote}
          deleteNote={deleteNote}
          fetchNotes={fetchNotes}
          newNoteCleanup={newNoteCleanup}
          updateNote={updateNote}
          pageSize={25}
          shouldTruncateNotes={false}
        />
      </div>
    </div>
  );
};

NotesTab.propTypes = {
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  dealId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  next: PropTypes.number,
  newNote: PropTypes.shape({}),
  notes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({}).isRequired,
  addNote: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  fetchNotes: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  newNoteCleanup: PropTypes.func.isRequired,
};

NotesTab.defaultProps = {
  next: null,
  newNote: undefined,
};

export default NotesTab;
