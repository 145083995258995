import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';
import MapBox from '../../nucleus/maps/map';
import LabeledItem from '../../nucleus/labeled-item/labeled-item';
import formatAsCurrency from '../../utils/format-as-currency';
import { formatPricePerSqft, formatRent, formatSqFt } from '../../utils/transaction-utils';

const VoucherDetailProperty = (props) => {
  const renderPropertyDetails = () => {
    const {
      dealType,
      property,
      propertyType,
      price,
      pricePerSqFt,
      leaseRateType,
      squareFeet,
    } = props;

    const {
      latitude,
      longitude,
      name,
      full_address: fullAdress,
    } = property;

    const emptyState = '--';

    const renderLeaseDetail = (dealType === 'lease') &&
      <div>
        <LabeledItem label="Property Type" item={propertyType || emptyState} />
        <LabeledItem label="SqFt" item={formatSqFt(squareFeet) || emptyState} />
        <LabeledItem label="Annual Rent" item={formatRent(pricePerSqFt, leaseRateType) || emptyState} />
      </div>;

    const renderSaleDetail = (dealType === 'sale') &&
      <div>
        <LabeledItem label="Property Type" item={propertyType || emptyState} />
        <LabeledItem label="SqFt" item={formatSqFt(squareFeet) || emptyState} />
        <LabeledItem label="Sale Price" item={formatAsCurrency(price)} />
        <LabeledItem label="Sale Price / SqFt" item={formatPricePerSqft(pricePerSqFt) || emptyState} />
      </div>;

    const renderOtherDetail = (dealType === 'other' || dealType === 'consulting') &&
      <div>
        <LabeledItem label="Property Type" item={propertyType || emptyState} />
      </div>;

    const renderMap = latitude && longitude &&
      <div className="property-map-container">
        <MapBox latitude={latitude} longitude={longitude} />
      </div>;

    return (
      <div>
        <div className="property-header">
          <div className="property-icon">
            <Icon iconName="building" />
          </div>
          <div className="property-header-text">
            <div className="property-name">{name || emptyState}</div>
            <div className="property-address">{fullAdress || emptyState}</div>
          </div>
        </div>
        {renderMap}
        <div className="property-detail">
          {renderLeaseDetail}
          {renderSaleDetail}
          {renderOtherDetail}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const emptyResult = (
      <div className="empty-content">
        <span>No Property</span>
      </div>);
    return props.property ?
      renderPropertyDetails()
      : emptyResult;
  };

  return (
    <div className="panel panel-small" id="property-panel">
      <div className="panel-header">
        Deal Property
      </div>
      <div className="panel-content panel-content-padding">
        {renderContent()}
      </div>
    </div>);
};

VoucherDetailProperty.propTypes = {
  squareFeet: PropTypes.number,
  dealType: PropTypes.string.isRequired,
  pricePerSqFt: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  leaseRateType: PropTypes.string,
  propertyType: PropTypes.string,
  property: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    name: PropTypes.string,
    full_address: PropTypes.string,
  }),
};

VoucherDetailProperty.defaultProps = {
  property: {},
  propertyType: '',
  leaseRateType: '',
  squareFeet: 0,
};

export default VoucherDetailProperty;
