import React, {
  useState, useEffect, forwardRef, createRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';

import NDButton from '../button/button';

const FilterMenu = forwardRef(({
  actionIcon,
  buttonIcon,
  buttonText,
  buttonVariant,
  children,
  closeFlyoutOnClick,
  dataE2e,
  disabled,
  iconButton,
  position,
  className,
  onClose,
  onOpen,
}, ref) => {
  const [open, setOpen] = useState(false);
  const innerRef = ref || createRef();
  const openMenu = () => {
    setOpen(true);
    onOpen();
  };

  const closeMenu = useCallback(() => {
    setOpen(false);
    onClose();
  }, [onClose]);

  const toggleMenu = open ? closeMenu : openMenu;

  useEffect(() => {
    const clickListener = ({ clientX, target }) => {
      if (open) {
        // scrollbars are usually around 18px in width
        // we want to also ignore scrollbar clicks
        const scrollBarX = window.innerWidth - 18;
        const isScrollbarClick = clientX >= scrollBarX;

        const clickedInFlyout = innerRef.current?.contains(target);
        if ((!clickedInFlyout && !isScrollbarClick) || (closeFlyoutOnClick && clickedInFlyout)) {
          closeMenu();
        }
      }
    };
    document.addEventListener('click', clickListener);

    return () => {
      document.removeEventListener('click', clickListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, innerRef]);

  useEffect(() => {
    if (open) {
      const keyboardListener = ({ which }) => {
        // is escape or tab away close menu
        if (open && (which === 27 || (which === 9 && !innerRef.current?.contains(document.activeElement)))) {
          closeMenu();
        }
      };
      window.addEventListener('keyup', keyboardListener);
      return () => {
        window.removeEventListener('keyup', keyboardListener);
      };
    }
    return null;
  }, [closeMenu, innerRef, open]);

  return (
    <div className={`nd-filter-menu ${className}`} ref={innerRef}>
      <div
        className={`button-wrapper${actionIcon ? ' has-action-icon' : ''} ${position}`}
      >
        {actionIcon && (
          <IconButton
            className="action-icon blxs-button-icon-small"
            iconName={actionIcon}
            disabled={disabled}
            onClick={toggleMenu}
            variant="basic"
          />
        )}
        {(buttonText || buttonIcon) && (
          <NDButton
            className="action-button"
            variant={buttonVariant}
            icon={buttonIcon}
            dataE2e={dataE2e}
            iconPosition="right"
            disabled={disabled}
            onClick={toggleMenu}
          >
            {buttonText}
          </NDButton>
        )}
        {iconButton && (
          <IconButton
            className={`icon-button ${disabled ? 'disabled' : ''}`}
            iconName={iconButton}
            data-e2e={dataE2e}
            onClick={toggleMenu}
          />
        )}
      </div>
      {open && (
        <div className={`nd-flyout ${position}`}>
          {children}
        </div>
      )}
    </div>
  );
});

FilterMenu.propTypes = {
  actionIcon: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  buttonVariant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  closeFlyoutOnClick: PropTypes.bool,
  disabled: PropTypes.bool,
  iconButton: PropTypes.string,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  dataE2e: PropTypes.string,
  position: PropTypes.string,
  className: PropTypes.string,
};

FilterMenu.defaultProps = {
  actionIcon: undefined,
  buttonIcon: undefined,
  buttonText: undefined,
  buttonVariant: 'action',
  closeFlyoutOnClick: false,
  disabled: false,
  iconButton: undefined,
  dataE2e: '',
  onClose: () => { },
  onOpen: () => { },
  position: 'left',
  className: '',
};

export default FilterMenu;
