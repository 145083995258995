import React, { useState, forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';

const BaseInput = forwardRef(({
  className,
  variant = 'outlined',
  viewOnly,
  onBlur,
  onFocus,
  value = '',
  ...props
}, ref) => {
  const [focusedClassName, setFocusedClassName] = useState();

  const classNames = useMemo(() => ([
    'base-input', className, variant, focusedClassName, viewOnly && 'view-only',
  ].filter(c => c).join(' ')), [className, focusedClassName, variant, viewOnly]);

  return (
    <input
      {...props}
      className={classNames}
      ref={ref}
      size={(viewOnly || (!focusedClassName && variant === 'inline')) ? value.length : undefined}
      value={value}
      onBlur={(e) => {
        setFocusedClassName();
        onBlur(e);
      }}
      onFocus={(e) => {
        setFocusedClassName('focused');
        onFocus(e);
      }}
      {...(viewOnly ? { tabIndex: '-1' } : {})}
    />
  );
});

BaseInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'inline']),
  viewOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

const emptyFunction = () => { };

BaseInput.defaultProps = {
  className: undefined,
  value: undefined,
  variant: undefined,
  viewOnly: false,
  onBlur: emptyFunction,
  onFocus: emptyFunction,
};

export default BaseInput;
