import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@cbrebuild/blocks';
import PercentDollarInput from '../../../nucleus/inputs/PercentDollarInput';
import Select from '../../../nucleus/select/select';
import NumberInput from '../../../nucleus/inputs/NumberInput';
import Currency from '../../../nucleus/formats/currency';
import { deleteConsideration, updateConsideration } from '../../../redux/actions/considerations-actions';
import { updateVoucher } from '../../../redux/actions/voucher/voucher-actions';
import { updateDealPipelineTimestamp } from '../../../redux/actions/deal-actions';
import calculatePercentValueFromTotal from '../../../utils/calculate-percent-value-from-total';
import useFormState, { EVENT_VALUE_TYPES } from '../../../hooks/useFormState';

const disabledTypeMap = {
  'Free Monthly Rent': true,
  'Free Annual Rent': true,
};

const typeOverrides = {
  total_units: EVENT_VALUE_TYPES.FLOAT,
  amount: EVENT_VALUE_TYPES.FLOAT,
  commission_percent: EVENT_VALUE_TYPES.FLOAT,
  commission_amount: EVENT_VALUE_TYPES.FLOAT,
  considerations_is_percent: EVENT_VALUE_TYPES.BOOLEAN,
};

const selectConsiderationById = (state, id) => (
  state.voucherConsiderations.considerations.find(consideration => consideration.id === id)
);

const ConsiderationItem = ({
  id,
  showValidation,
  onDeleteReorder,
}) => {
  const consideration = useSelector(state => selectConsiderationById(state, id)) || {};
  const {
    considerations_is_percent,
    deal_type,
    options = [],
    voucherId,
    dealId,
  } = useSelector(state => ({
    voucherId: state.voucher.voucher.id,
    considerations_is_percent: state.voucher.voucher.considerations_is_percent,
    deal_type: state.deal.deal.deal_type,
    options: state.voucher.options.leaseConsiderationTypeOptions || [],
    dealId: state.deal.deal?.id,
  }));
  const dispatch = useDispatch();

  const {
    amount, commission_amount, commission_percent, consideration_amount, consideration_type, total_units,
    onStateChange, onStateChanged,
  } = useFormState({
    amount: consideration.amount,
    commission_amount: consideration.commission_amount,
    commission_percent: consideration.commission_percent,
    consideration_amount: consideration.consideration_amount,
    consideration_type: consideration.consideration_type,
    total_units: consideration.total_units,
  }, (update, current, patchCurrent) => {
    let patch = {};
    if ('consideration_type' in update && disabledTypeMap[update.consideration_type]) {
      patch = {
        amount: 0, consideration_amount: 0, commission_percent: 0, commission_amount: 0,
      };
    } else if ('total_units' in update || 'amount' in update) {
      patch.consideration_amount = current.amount.value * current.total_units.value;
      const values = calculatePercentValueFromTotal(patch.consideration_amount, considerations_is_percent, current.commission_percent.value);
      if (considerations_is_percent) {
        patch.commission_amount = values.value;
      } else {
        patch.commission_percent = values.percentage;
      }
    }
    patchCurrent(patch);
    dispatch(updateConsideration(id, { ...update, ...patch }));
    dispatch(updateDealPipelineTimestamp(dealId));
  }, typeOverrides);

  const isDisabled = !!disabledTypeMap[consideration_type.value];

  const onDelete = () => {
    dispatch(deleteConsideration(id));
    dispatch(updateDealPipelineTimestamp(dealId));
    onDeleteReorder(id);
  };

  const onUpdateVoucher = ({ target: { name, value } }) => {
    dispatch(updateVoucher(voucherId, { [name]: value }));
    dispatch(updateDealPipelineTimestamp(dealId));
  };

  const invalidTotalUnit = (showValidation || total_units.dirty) && total_units.value === null;
  const invalidAmount = (showValidation || amount.dirty) && amount.value === null;

  return (
    <div className="form-row-with-hints consideration-item-row">
      <div className="consideration-select-cell">
        {deal_type !== 'lease' ? 'Purchase Price' : (
          <Select
            defaultOption={consideration_type.value}
            onChange={({ value }) => onStateChanged({
              target: { value, type: 'text', name: 'consideration_type' },
            })}
            options={options}
          />
        )}
      </div>
      <div className="consideration-period-quantity-cell">
        <NumberInput
          name="total_units"
          value={total_units.value}
          precision={0}
          onChange={onStateChange}
          onBlur={onStateChanged}
          placeholder={consideration_type.value.includes('Monthly') ? 'Months' : 'Years'}
          invalid={invalidTotalUnit}
        />
        {invalidTotalUnit && (
          <p className="voucher-error-text">Required</p>
        )}
      </div>
      <div className="consideration-rent-price-cell">
        <NumberInput
          name="amount"
          disabled={isDisabled}
          value={amount.value}
          precision={2}
          onChange={onStateChange}
          onBlur={onStateChanged}
          placeholder="Amount"
          invalid={invalidAmount}
        />
        {invalidAmount && (
          <p className="voucher-error-text">Required</p>
        )}
      </div>
      <div className="consideration-total-cell">
        <Currency value={consideration_amount.value} emptyState="--" />
      </div>
      <div className="consideration-commission-cell">
        <PercentDollarInput
          name={{
            percentage: 'commission_percent',
            value: 'commission_amount',
            select: 'considerations_is_percent',
          }}
          onTypeChange={onUpdateVoucher}
          onChange={onStateChange}
          onBlur={onStateChanged}
          disabled={isDisabled}
          baseTotal={consideration_amount.value || 0}
          percentage={commission_percent.value}
          value={commission_amount.value}
          isPercent={considerations_is_percent}
        />
      </div>
      <div className="consideration-result-cell">
        <Currency value={commission_amount.value} emptyState="--" />
      </div>
      <div className="table-cell">
        <IconButton
          className="blxs-button-icon-small"
          iconName="close-circle"
          onClick={onDelete}
          variant="basic"
        />
      </div>
    </div>
  );
};

ConsiderationItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  showValidation: PropTypes.bool.isRequired,
  onDeleteReorder: PropTypes.func.isRequired,
};

export default ConsiderationItem;
