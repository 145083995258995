import superFetch from '../../utils/super-fetch';

const url = '/mbb/voucher/internalcomp';

const fetchCompData = (params) => {
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json())
    .then(data => data.results[0] || []);
};

const createCompData = (params) => {
  const options = {
    method: 'POST',
  };
  const body = JSON.stringify(params);
  return superFetch(url, options, body)
    .then(data => data.json());
};

const updateCompData = (id, dataObj) => {
  const options = {
    method: 'PATCH',
  };
  const body = JSON.stringify(dataObj);
  return superFetch(`${url}/${id}`, options, body)
    .then(data => data.json());
};

export {
  fetchCompData,
  createCompData,
  updateCompData,
};
