import { connect } from 'react-redux';
import { toast } from '../../redux/actions/toasts-actions';
import { fetchDealTags } from '../../redux/actions/tags-actions';

import { loadDealsMetadata } from '../../redux/actions/deals-metadata-actions';
import PipelineReportPage from './pipeline-report-page';

const mapStateToProps = state => ({
  userData: state.userData.data,
  allDealTags: state.tags.dealTags || [],
  teams: state.dealsMetadata.teams,
  users: state.dealsMetadata.users,
  proxy: state.proxy.proxySettings,
});

const mapDispatchToProps = {
  fetchDealTags, loadDealsMetadata, toast,
};

const PipelineReportPageContainer = connect(mapStateToProps, mapDispatchToProps)(PipelineReportPage);

export default PipelineReportPageContainer;
