import React from 'react';
import PropTypes from 'prop-types';

const BuildingIcon = (props) => {
  if (props.isUnknownProperty === true) {
    return (
      <div className="icon-presenter">
        <svg xmlns="http://www.w3.org/2000/svg" className="icon-complex-property unknown" viewBox="0 0 400 450" preserveAspectRatio="xMinYMin meet">
          <polygon className="foreground-color" points="289.65 109.09 289.65 0 68.97 0 0 68.18 0 450 331.03 450 400 381.82 400 109.09 289.65 109.09" />
          {/* eslint-disable-next-line */}
          <path className="background-color" d="M220.69,177.27V68.18H0V450H27.59V368.18H82.76V450H331V177.27Zm-137.93,150H27.59V245.45H82.76Zm0-136.36H27.59V109.09H82.76Zm96.55,136.36H124.14V245.45h55.17Zm0-136.36H124.14V109.09h55.17Zm96.55,136.36H220.69V245.45h55.17Z" />
          <text x="100" y="350" fontFamily="Source Sans Pro" fontSize="300px" fill="white">?</text>
        </svg>
      </div>
    );
  }
  return (
    <div className="icon-presenter">
      <svg xmlns="http://www.w3.org/2000/svg" className={`icon-complex-property ${props.dealStage}`} viewBox="0 0 400 450" preserveAspectRatio="xMinYMin meet">
        <polygon className="foreground-color" points="289.65 109.09 289.65 0 68.97 0 0 68.18 0 450 331.03 450 400 381.82 400 109.09 289.65 109.09" />
        {/* eslint-disable-next-line */}
        <path className="background-color" d="M220.69,177.27V68.18H0V450H27.59V368.18H82.76V450H331V177.27Zm-137.93,150H27.59V245.45H82.76Zm0-136.36H27.59V109.09H82.76Zm96.55,136.36H124.14V245.45h55.17Zm0-136.36H124.14V109.09h55.17Zm96.55,136.36H220.69V245.45h55.17Z" />
      </svg>
    </div>
  );
};

BuildingIcon.propTypes = {
  dealStage: PropTypes.string,
  isUnknownProperty: PropTypes.bool,
};

BuildingIcon.defaultProps = {
  dealStage: 'default',
  isUnknownProperty: false,
};

export default BuildingIcon;
