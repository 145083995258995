import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams, Link, useHistory } from 'react-router-dom';
import { Button } from '@cbrebuild/blocks';
import { useDispatch, useSelector } from 'react-redux';
import PageHeaderDefault from '../../nucleus/header/page-header-default';
import VOUCHER_ROUTES from './voucher-subroutes';
import RouteWithSubRoutes from '../../utils/voucher-route-utils';
import { loadDeal } from '../../redux/actions/deal-actions';
import { fetchOrCreateVoucher, updateVoucher } from '../../redux/actions/voucher/voucher-actions';
import { fetchCommissionOptions } from '../../redux/actions/options-actions';

const VoucherSubmission = () => {
  const { id } = useParams();
  const { pathname, state: { isEarlyInvoice: is_early_invoice } = {} } = useLocation();
  const history = useHistory();
  const { deal, voucher, options } = useSelector(state => ({
    deal: state.deal.deal,
    voucher: state.voucher.voucher,
    options: state.options,
  }));
  const dispatch = useDispatch();
  const { deal_type } = deal || {};
  const backLink = `/deals/detail/${id}/overview`;
  document.title = 'Deal IQ | Voucher Submission';

  // set deal and voucher on load or when id changes
  useEffect(() => {
    dispatch(loadDeal(id));
    dispatch(fetchOrCreateVoucher(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!options.voucher_broker_rep_role.length) {
      dispatch(fetchCommissionOptions());
    }
  }, [dispatch, options.voucher_broker_rep_role]);

  // when the page was open with the different request than the current state update is_early_invoice
  useEffect(() => {
    const shouldCheck = is_early_invoice || is_early_invoice === false;
    if (shouldCheck && is_early_invoice !== voucher.is_early_invoice) {
      dispatch(updateVoucher(voucher.id, { is_early_invoice }));
    }
  }, [dispatch, is_early_invoice, voucher.id, voucher.is_early_invoice]);

  // memoizes the progress tabs
  const progressTabs = useMemo(() => {
    switch (deal_type) {
      case 'lease':
      case 'sale':
        return [
          {
            link: `/voucher/${id}/getting_started`,
            text: '1. Getting Started',
          },
          {
            link: `/voucher/${id}/voucher_details`,
            text: `2. ${deal_type} Details`,
          },
          {
            link: `/voucher/${id}/financials`,
            text: '3. Financials',
          },
          {
            link: `/voucher/${id}/comp_data`,
            text: `4. ${deal_type} Comp`,
          },
          {
            link: `/voucher/${id}/review_and_send`,
            text: '5. Review & Send',
          },
          {
            link: `/voucher/${id}/confirmation`,
            text: '6. Confirmation',
          },
        ];
      default:
        return [
          {
            link: `/voucher/${id}/getting_started`,
            text: '1. Getting Started',
          },
          {
            link: `/voucher/${id}/voucher_details`,
            text: '2. Overview',
          },
          {
            link: `/voucher/${id}/financials`,
            text: '3. Financials',
          },
          {
            link: `/voucher/${id}/review_and_send`,
            text: '4. Review and Send',
          },
          {
            link: `/voucher/${id}/confirmation`,
            text: '5. Confirmation',
          },
        ];
    }
  }, [id, deal_type]);

  if (!deal || !voucher) {
    return null;
  }

  const voucherUniqueID = (deal.voucher_submission_status === 'Voucher Submitted' ||
  deal.voucher_submission_status === 'Voucher Resubmitted' ||
  deal.voucher_submission_status === 'Voucher Completed' ||
  voucher.is_early_invoice) &&
  <> ({`Deal IQ Voucher ID: ${voucher.unique_id}`}) </>;

  return (
    <div>
      <PageHeaderDefault
        title={deal.name}
        subtitle={(
          <>
            <span className="voucher-subtitle">
              {/* lease landlord: client may not be defined, show nothing is what angular does */}
              {(deal_type === 'consulting' || deal_type === 'other')
                ? 'Misc Voucher' : `${deal_type.charAt(0).toUpperCase()}${deal_type.slice(1)} Voucher`} for <Link to={backLink}>{deal.client?.name}</Link>
              { voucherUniqueID }
            </span>
            {!!voucher && voucher.is_early_invoice && <span className="early-invoice-label">Early Invoice Request</span>}
          </>
        )}
        controls={(<Button variant="secondary" onClick={() => { history.push(backLink); }} >Return to Deal</Button>)}
        tabs={progressTabs}
        pageUrl={pathname}
        hasProgressTab
      />
      <div className="page-container voucher-container">
        {VOUCHER_ROUTES && VOUCHER_ROUTES.map(route => (
          <RouteWithSubRoutes key={route.path} {...route} />
        ))}
      </div>
    </div>
  );
};


export default VoucherSubmission;
