import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Button, Icon } from '@cbrebuild/blocks';

import { subRoutes, supportTabs } from './support-tabs';
import PageHeaderDefault from '../../nucleus/header/page-header-default';
import VideoModal from '../../components/modals/video-modal';
import supportService from '../../services/support-service';

const SupportPage = (props) => {
  const [showModal, toggleShowModal] = useState(false);
  const [changeLog, setChangeLog] = useState({});

  useMemo(() => {
    const options = {
      page: 1,
      page_size: 1,
    };

    // latest change log
    supportService.getChangeLogs(options)
      .then((data) => {
        setChangeLog(data.results[0]);
      })
      .catch((error) => {
        console.log('Error fetching change logs: ', error);
      });

    document.title = 'Deal IQ | Support';
  }, []);

  const onLaunchVideoModal = () => {
    toggleShowModal(true);
  };

  const playVideoButton = (
    <Button
      className="video-button"
      onClick={onLaunchVideoModal}
      variant="primary"
    >
      Watch Overview
      <Icon iconName="play" />
    </Button>
  );

  return (
    <div className="support-page">
      <PageHeaderDefault
        title="Support"
        pageUrl={props.location && props.location.pathname}
        controls={playVideoButton}
        tabs={supportTabs}
      />
      <VideoModal url="https://assets.cbre.io/deal-iq/dealiq-overview.mp4" showModal={showModal} height={450} width={800} />
      {subRoutes.map((route) => {
        const RouteComponent = route.component;
        return (<Route
          path={route.path}
          exact={route.exact}
          key={route.path}
          render={() => (
            <RouteComponent
              changeLog={changeLog}
            />
          )}
        />);
      })}
    </div>
  );
};

SupportPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default SupportPage;
