import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import { IconButton } from '@cbrebuild/blocks';

import RowItem from '../../nucleus/row-item/row-item';
import { Person, PersonType } from '../person';
import BrokerAutocomplete from '../autocomplete/broker-autocomplete';
import TeamAutocomplete from '../autocomplete/team-autocomplete';
import TeamTile from '../teams/team-tile';

const DealTeamInformation = (props) => {
  const {
    allTeams, // all teams of which user is a member
    dealTeams, // teams added to the deal
    teamMembers,
    addTeam,
    addTeamMember,
    removeTeam,
    removeTeamMember,
  } = props;

  const teamList = dealTeams.map((team) => {
    const buttonTeamRemove = (<IconButton
      className="blxs-button-icon-small"
      iconName="close-circle"
      onClick={() => {
        removeTeam(team.id);
      }}
      variant="basic"
    />);
    return (
      <RowItem key={uniqueId()}>
        <TeamTile
          team={team}
          showHover
        />
        {buttonTeamRemove}
      </RowItem>
    );
  });

  const teamMemberList = teamMembers.map((member) => {
    const {
      user_id: userId,
      isSelf,
      isProxy,
    } = member;
    const buttonTeamMemberRemove = (!isSelf && !isProxy) ? (<IconButton
      className="blxs-button-icon-small"
      iconName="close-circle"
      onClick={() => {
        removeTeamMember(userId);
      }}
      variant="basic"
    />) : '';

    return (
      <RowItem key={uniqueId()}>
        <Person
          person={member}
          type={PersonType.MEMBER}
          isSelf={isSelf}
          isProxy={isProxy}
        />
        {buttonTeamMemberRemove}
      </RowItem>
    );
  });
  const filterBrokerAutocompleteResultsPredicate = broker =>
    (!teamMembers.some(member => (broker.user_id === member.user_id)));

  const filterTeamAutocompleteResultsPredicate = teamResult =>
    (!dealTeams.some(team => (teamResult.id === team.id)));

  const renderNoTeams = <p className="no-teams-text">No team available. Go to Your Profile/Teams to create one</p>;

  return (
    <section className="team">
      <div className="header">
        <h3>Collaborators</h3>
      </div>
      <div className="field">
        <label>Teams</label>
        <TeamAutocomplete
          filterResultsPredicate={filterTeamAutocompleteResultsPredicate}
          isDisabled={!allTeams.length}
          onSelect={addTeam}
          placeholder={allTeams.length ? undefined : 'No teams available'}
        />
        <div className="teams">{allTeams.length ? teamList : renderNoTeams}</div>
      </div>
      <div className="field">
        <label>Individuals</label>
        <BrokerAutocomplete
          onSelect={addTeamMember}
          filterResultsPredicate={filterBrokerAutocompleteResultsPredicate}
        />
        <div className="team-members">{teamMemberList}</div>
      </div>
    </section>
  );
};

DealTeamInformation.propTypes = {
  addTeamMember: PropTypes.func.isRequired,
  addTeam: PropTypes.func.isRequired,
  removeTeam: PropTypes.func.isRequired,
  removeTeamMember: PropTypes.func.isRequired,
  allTeams: PropTypes.arrayOf(PropTypes.shape({})),
  dealTeams: PropTypes.arrayOf(PropTypes.shape({})),
  teamMembers: PropTypes.arrayOf(PropTypes.shape({})),
};

DealTeamInformation.defaultProps = {
  allTeams: [],
  dealTeams: [],
  teamMembers: [],
};
export default DealTeamInformation;
