import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RadioButton, RadioGroup } from '@cbrebuild/blocks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import AddItem from '../../../nucleus/add-item/add-item';
import FeeSharingItem from './FeeSharingItem';
import { createSharedFee, deleteSharedFee } from '../../../redux/actions/shared-fees-actions';
import SimpleConfirmationModal from '../../modals/SimpleConfirmationModal';

const selectSharedFeeIds = state => state.sharedFees.sharedFees.map(({ id }) => id);

const FeeSharing = ({
  dealId,
  voucherId,
  showValidation,
  fee_sharing_is_percent,
}) => {
  const sharedFeeIds = useSelector(selectSharedFeeIds, shallowEqual);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const addSharedFee = () => {
    dispatch(createSharedFee({
      payment_group: 4,
      transaction: dealId,
    }));
  };

  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);
  const onDeleteAll = () => {
    closeModal();
    sharedFeeIds.forEach((id) => {
      dispatch(deleteSharedFee(id));
    });
  };

  const toggle = () => {
    if (sharedFeeIds.length) {
      openModal();
    } else {
      addSharedFee();
    }
  };

  return (
    <div className="outside-brokers">
      <h2>
        Was there Any Fee Sharing?
      </h2>
      <RadioGroup
        selectedValue={`${sharedFeeIds.length !== 0}`}
        className="radioVal"
        onChange={toggle}
        orientation="vertical"
      >
        <RadioButton value="false">No fee sharing</RadioButton>
        <RadioButton value="true">Yes, there is fee sharing</RadioButton>
      </RadioGroup>
      {sharedFeeIds.length !== 0 && (
        <>
          <h2>Fee Sharing</h2>
          {sharedFeeIds.map(id => (
            <FeeSharingItem
              id={id}
              key={id}
              dealId={dealId}
              voucherId={voucherId}
              showValidation={showValidation}
              fee_sharing_is_percent={fee_sharing_is_percent}
            />
          ))}
          <AddItem label="Add Shared Fee" onClick={addSharedFee} />
        </>
      )}
      {showModal && (
        <SimpleConfirmationModal
          showModal={showModal}
          onCancel={closeModal}
          onAccept={onDeleteAll}
        >
          This will delete all fee sharing. Are you sure?
        </SimpleConfirmationModal>
      )}
    </div>
  );
};

FeeSharing.propTypes = {
  dealId: PropTypes.number.isRequired,
  voucherId: PropTypes.number.isRequired,
  showValidation: PropTypes.bool.isRequired,
  fee_sharing_is_percent: PropTypes.bool.isRequired,
};

export default FeeSharing;
