import { UPDATE_TABLE_CONFIG, RESET_TABLE_CONFIG, REORDER_TABLE_CONFIG } from './action-types';

const updateTableConfigAction = tableConfig => (
  {
    type: UPDATE_TABLE_CONFIG,
    payload: tableConfig,
  }
);

const resetTableConfigAction = () => (
  {
    type: RESET_TABLE_CONFIG,
  }
);

const reOrderTableConfAction = () => (
  {
    type: REORDER_TABLE_CONFIG,
  }
);
export {
  updateTableConfigAction,
  resetTableConfigAction,
  reOrderTableConfAction,
};
