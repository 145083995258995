/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';
import { isEmpty } from 'lodash';
import DealList from '../../components/property-details/deal-list';
import ExportDealListModal from '../../components/modals/export-deal-list-modal';
import Modal from '../../nucleus/modal/modal';
import PageHeaderDefault from '../../nucleus/header/page-header-default';

import dealsService from '../../services/deals-service';

const MODAL_STATE = {
  NONE: null,
  EXPORT: 'export',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

const DealListPage = (props) => {
  const [modalState, setModalState] = useState(MODAL_STATE.NONE);

  const {
    dealCount,
    initQueryParams,
    user,
    proxy,
  } = props;
  const colors = {
    prospect: 'blue',
    executing: 'orange',
    closed: 'lime',
    deals: 'green',
  };

  const titleKey = initQueryParams.deal_life_cycle_stage.length === 1
    ? initQueryParams.deal_life_cycle_stage[0]
    : 'deals';
  const title = titleKey === 'prospect'
    ? 'Prospecting'
    : `${titleKey.charAt(0).toUpperCase()}${titleKey.slice(1)}`;

  const closeModal = () => {
    setModalState(MODAL_STATE.NONE);
  };

  const handleModalConfirm = (export_all_contacts) => {
    const params = {
      ...props.lastQuery,
      export_all_contacts,
    };
    // api expects comma-separated list of tags
    if (params.tags) {
      params.tags = params.tags.join(',');
    }
    delete params.ordering;
    delete params.page_size;
    delete params.page;
    closeModal();
    return dealsService.exportDeals(params)
      .then(() => {
        setModalState(MODAL_STATE.SUCCESS);
      }).catch(() => {
        setModalState(MODAL_STATE.FAILURE);
      });
  };

  useMemo(() => {
    document.title = `Deal IQ | ${title}`;
  });

  return (
    <div>
      <PageHeaderDefault
        barColor={colors[titleKey]}
        controls={(
          <IconButton data-e2e="icon-download-button" iconName="download" onClick={() => setModalState(MODAL_STATE.EXPORT)} />
        )}
        title={title}
        subtitle={`${dealCount} deal(s)`}
      />
      <DealList
        key={titleKey}
        {...props}
      />
      {modalState === MODAL_STATE.EXPORT && (
        <ExportDealListModal
          onCancel={closeModal}
          onConfirm={handleModalConfirm}
        />
      )}
      {modalState === MODAL_STATE.SUCCESS && (
        <Modal
          showModal
          handleModalSubmit={closeModal}
          handleModalToggle={closeModal}
          modalHeader="CREATING YOUR FILE"
          primaryButtonText="Ok"
          modalWidth={400}
        >
          {!isEmpty(proxy) ?
           (<p>Your file will be emailed to {user.employee_work_email} and {proxy.email} once it is ready.</p>) :
           (<p>Your file will be emailed to {user.employee_work_email} once it is ready.</p>)}
        </Modal>
      )}
      {modalState === MODAL_STATE.FAILURE && (
        <Modal
          showModal
          handleModalSubmit={closeModal}
          handleModalToggle={closeModal}
          modalHeader="Export failed."
          primaryButtonText="Ok"
          modalWidth={400}
        >
          <p>List is Empty</p>
        </Modal>
      )}
    </div>
  );
};

DealListPage.propTypes = {
  allDealTags: PropTypes.arrayOf(PropTypes.string),
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  dealCount: PropTypes.number,
  deals: PropTypes.arrayOf(PropTypes.shape({})),
  hasNextPage: PropTypes.number,
  isLoadingDeals: PropTypes.bool,
  fetchDeals: PropTypes.func.isRequired,
  fetchDealsAndTransactionsES: PropTypes.func.isRequired,
  fetchDealTags: PropTypes.func.isRequired,
  initQueryParams: PropTypes.shape({
    search: PropTypes.string,
    page: PropTypes.number,
    page_size: PropTypes.number,
    ordering: PropTypes.string,
    building: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    conversion_potential_min: PropTypes.number,
    conversion_potential_max: PropTypes.number,
    is_archived: PropTypes.bool,
    deal_life_cycle_stage: PropTypes.arrayOf(PropTypes.string),
    rep_role: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    date_approved_min: PropTypes.string,
    date_approved_max: PropTypes.string,
    date_lease_to_min: PropTypes.string,
    date_lease_to_max: PropTypes.string,
    voucher_submission_status: PropTypes.arrayOf(PropTypes.string),
    is_untagged_deal: PropTypes.bool,
    is_on_pipeline: PropTypes.bool,
  }).isRequired,
  lastQuery: PropTypes.shape({
    search: PropTypes.string,
    page: PropTypes.number,
    page_size: PropTypes.number,
    ordering: PropTypes.string,
    building: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    conversion_potential_min: PropTypes.number,
    conversion_potential_max: PropTypes.number,
    is_archived: PropTypes.bool,
    deal_life_cycle_stage: PropTypes.arrayOf(PropTypes.string),
    rep_role: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    date_approved_min: PropTypes.string,
    date_approved_max: PropTypes.string,
    date_lease_to_min: PropTypes.string,
    date_lease_to_max: PropTypes.string,
    voucher_submission_status: PropTypes.arrayOf(PropTypes.string),
    is_untagged_deal: PropTypes.bool,
    is_on_pipeline: PropTypes.bool,
  }),
  user: PropTypes.shape({
    employee_work_email: PropTypes.string,
  }).isRequired,
  proxy: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};
DealListPage.defaultProps = {
  allDealTags: [],
  dealCount: 0,
  deals: [],
  hasNextPage: null,
  isLoadingDeals: false,
  lastQuery: {},
};
export default DealListPage;
