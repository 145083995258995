/* eslint-disable react/prop-types */
import React, { useMemo, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import RouteWithSubRoutes from '../../utils/voucher-route-utils';
import TabNav from '../../nucleus/tabnav/tabnav';
import BottomNav from './BottomNav';
import VoucherComments from '../voucher/VoucherComments';
import FinancialBreakdown from './FinancialBreakdown/FinancialBreakdown';
import { fetchFinancials } from '../../redux/actions/financial-actions';

const Financials = ({
  location,
  match: {
    params: {
      id,
      subtab,
    },
  },
  routes,
}) => {
  const tabs = useMemo(() => [
    {
      link: `/voucher/${id}/financials/consideration`,
      text: 'Consideration',
    },
    {
      link: `/voucher/${id}/financials/allocations`,
      text: 'Allocations',
    },
    {
      link: `/voucher/${id}/financials/commissions`,
      text: 'Commissions',
    },
    {
      link: `/voucher/${id}/financials/installments`,
      text: 'Installments',
    },
    {
      link: `/voucher/${id}/financials/bill-to`,
      text: 'Bill To',
    },
  ], [id]);

  const dealId = useSelector(state => state.deal.deal.id);
  const dealType = useSelector(state => state.deal.deal.deal_type);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFinancials(dealId, dealType));
  }, [dispatch, dealId, dealType]);

  return (
    <React.Fragment>
      <div className="voucher-content">
        <TabNav tabs={tabs} selectedTab={location.pathname} />
        {!subtab && <Redirect to={tabs[0].link} />}
        <Switch>
          {routes && routes.map(route => (
            <RouteWithSubRoutes key={route.path} {...route} />
          ))}
        </Switch>
        <BottomNav location={location.pathname} />
      </div>
      <div>
        <FinancialBreakdown />
        <VoucherComments />
      </div>
    </React.Fragment>
  );
};


Financials.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

Financials.defaultProps = {
  location: '',
};

export default Financials;
