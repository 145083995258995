import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';

const GroupAvatar = (props) => {
  const {
    icon,
    isCurrent,
  } = props;

  return (
    <React.Fragment>
      <Icon className={isCurrent ? 'active' : ''} iconName={icon} />
    </React.Fragment>
  );
};


GroupAvatar.propTypes = {
  isCurrent: PropTypes.bool,
  icon: PropTypes.string,
};

GroupAvatar.defaultProps = {
  isCurrent: false,
  icon: 'group',
};

export default GroupAvatar;
