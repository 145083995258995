const formatTelephone = (tel) => {
  if (typeof tel !== 'string') {
    return '';
  }
  const telGroup = tel.split(';ext=');
  const telNumber = telGroup[0].replace(/[^\d]/g, '');
  let country;
  let city;
  let number;

  switch (telNumber.length) {
    case 10: // Domestic
      country = '';
      city = telNumber.slice(0, 3);
      number = telNumber.slice(3);
      break;

    case 11: // International
      if (telNumber[0] !== '1') return telNumber;
      country = '';
      city = telNumber.slice(1, 4);
      number = telNumber.slice(4);
      break;

    case 13: // Triple-digit country code
      country = telNumber.slice(0, 3);
      city = telNumber.slice(3, 6);
      number = telNumber.slice(6);
      break;

    default:
      return telNumber;
  }

  if (country === 1) {
    country = '';
  }

  number = `${number.slice(0, 3)}-${number.slice(3)}`;

  let extension = '';
  if (telGroup[1]) {
    extension = ` x${telGroup[1]}`;
  }

  return (`${country} (${city}) ${number}`).trim() + extension;
};

export default formatTelephone;
