import { connect } from 'react-redux';
import {
  deleteFile,
  fetchFiles,
  fetchFilesDownloadLink,
  updateFile,
} from '../../../redux/actions/deal-files-actions';
import VoucherTab from './voucher-tab';

const mapStateToProps = state => ({
  dealId: state.deal.deal.id,
  files: state.dealFiles.files,
  userData: state.userData.data || {},
});

const mapDispatchToProps = {
  deleteFile,
  fetchFiles,
  fetchFilesDownloadLink,
  updateFile,
};

const VoucherTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VoucherTab);

export default VoucherTabContainer;
