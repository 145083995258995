import React from 'react';
import PropTypes from 'prop-types';
import Avatar from './group-avatar';

const GroupCard = (props) => {
  const {
    group,
    icon,
    isCurrent,
  } = props;

  const {
    name,
  } = group;

  return (
    <div className="group-card">
      <div className="icon-wrapper">
        <Avatar
          icon={icon}
          isCurrent={isCurrent}
        />
      </div>
      <p className={`primary-content ${icon === 'plus' ? 'action-text' : ''}`}>
        {name}
      </p>
    </div>
  );
};

GroupCard.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  icon: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool,
};

GroupCard.defaultProps = {
  isCurrent: false,
};

export default GroupCard;

