import React from 'react';
import PropTypes from 'prop-types';

import ButtonWithFlyout from '../../../nucleus/button-with-flyout/button-with-flyout';
import ChangeDealTypeModal from '../../deal-card/change-deal-type-modal';
import CreateDealModalContainer from '../../../redux/containers/create-deal-modal-container';
import DeleteDealModal from './delete-deal-modal';
import Toast from '../../../nucleus/toast/toast';
import SyncSalesforceModal from './sync-salesforce-modal';
import userEventService from '../../../services/user-event-service';
import NDButton from '../../../nucleus/button/button';

// wait for flyout to close before opening modal, since it listens for a 'click' event
const MODAL_TIMEOUT = 100;

class DealActionsDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      showDuplicateDealModal: false,
      showSalesforceModal: false,
      showChangeDealTypeModal: false,
      showToast: false,
      snoozeMessage: '',
    };
  }

  getDealStageName = () => {
    let stage = this.props.deal.deal_life_cycle_stage;
    if (stage === 'prospect') {
      stage = 'prospecting';
    }
    return stage;
  }

  toggleDeleteDealModal = () => {
    setTimeout(() => {
      this.setState({ showDeleteModal: !this.state.showDeleteModal });
    }, MODAL_TIMEOUT);
  }

  toggleSalesforceModal = () => {
    setTimeout(() => {
      this.setState({ showSalesforceModal: !this.state.showSalesforceModal });
    }, MODAL_TIMEOUT);
  }

  toggleDuplicateDealModal = () => {
    setTimeout(() => {
      this.setState({ showDuplicateDealModal: !this.state.showDuplicateDealModal });
    }, MODAL_TIMEOUT);
  }

  toggleChangeDealTypeModal = () => {
    setTimeout(() => {
      this.setState({ showChangeDealTypeModal: !this.state.showChangeDealTypeModal });
    }, MODAL_TIMEOUT);
  }

  handleArchiveDeal = () => {
    const {
      deal: {
        id,
      },
      updateDeal,
    } = this.props;
    updateDeal(id, { is_archived: true });
    this.trackDealActionChange(id, 'deal_archived');
    this.handleToast('Deal archived');
  };

  handleRestoreDeal = () => {
    const {
      deal: {
        id,
      },
      updateDeal,
    } = this.props;
    updateDeal(id, { is_archived: false });
    this.trackDealActionChange(id, 'deal_restored');
    this.handleToast('Deal restored');
  }

  handleDeleteDeal = () => {
    const {
      deal: {
        id,
      },
      updateDeal,
    } = this.props;
    this.toggleDeleteDealModal();
    updateDeal(id, { is_deleted: true });
    this.trackDealActionChange(id, 'deal_deletion');
    this.handleToast('Deal has been deleted');
  };

  handleSyncToSalesforce = (params) => {
    const {
      deal: {
        id,
      },
      updateDeal,
    } = this.props;
    this.toggleSalesforceModal();
    updateDeal(id, { ...params });
    this.trackDealActionChange(id, 'deal_synced_to_salesforce');
  }

  handleChangeDealType = (dealTypeRepRole) => {
    const {
      deal: {
        id,
        deal_type: dealType,
        rep_role: repRole,
      },
      updateDeal,
    } = this.props;
    this.toggleChangeDealTypeModal();
    updateDeal(id, dealTypeRepRole);
    const difference = {
      from_deal_type: dealType,
      to_deal_type: dealTypeRepRole.deal_type,
      from_rep_role: repRole,
      to_rep_role: dealTypeRepRole.rep_role,
    };
    this.trackDealActionChange(id, 'deal_change_deal_type_rep_role', difference);
  }

  trackDealActionChange = (id, eventAction, data = {}) => {
    // tracks deal change actions
    userEventService.trackEvent({
      eventAction, eventCategory: 'Deal Action', eventValue: id, eventData: data,
    }, { actionPrefix: this.props.analyticProperties.actionPrefix, categoryPrefix: this.props.analyticProperties.categoryPrefix });
  }

  handleToast = snoozeMessage => new Promise((resolve) => {
    // temporary until ddh is refactored
    if (!this.props.hideToasts) {
      this.setState({
        showToast: true,
        snoozeMessage,
      });

      setTimeout(() => {
        this.setState({
          showToast: false,
          snoozeMessage: '',
        });
        resolve();
      }, 2000);
    }
  });

  render() {
    const {
      showToast,
      snoozeMessage,
    } = this.state;
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
      deal,
      isKebab,
      dealUsers,
      dealTeams,
      viewOnly,
    } = this.props;

    const dealActionOptions = [
      {
        id: 1,
        displayName: 'Archive / Dead Deal',
        fontIconName: 'storagebox',
        onMouseDown: this.handleArchiveDeal,
        isHidden: deal.is_archived,
        dataE2e: 'flyout-actions-archive',
      },
      {
        id: 2,
        displayName: 'Restore Deal',
        fontIconName: 'history',
        onMouseDown: this.handleRestoreDeal,
        isHidden: !deal.is_archived,
        dataE2e: 'flyout-actions-restore',
      },
      {
        id: 4,
        displayName: 'Delete Deal',
        fontIconName: 'bin',
        onMouseDown: this.toggleDeleteDealModal,
        isHidden: deal.deal_life_cycle_stage === 'closed',
        dataE2e: 'flyout-actions-delete',
      },
      {
        id: 5,
        displayName: 'Duplicate Deal',
        fontIconName: 'duplicate',
        onMouseDown: this.toggleDuplicateDealModal,
        isHidden: deal.is_mta && deal.deal_life_cycle_stage === 'closed' && (
          deal.linked_mta_transactions === undefined || deal.linked_mta_transactions.length === 0
        ),
        dataE2e: 'flyout-actions-duplicate',
      },
      {
        id: 6,
        displayName: 'Change Deal Type',
        fontIconName: 'cycle',
        onMouseDown: this.toggleChangeDealTypeModal,
        isHidden: deal.deal_life_cycle_stage === 'closed',
        dataE2e: 'flyout-actions-change-type',
      },
    ];

    const renderToast = showToast ? <Toast message={snoozeMessage} /> : '';

    // USER_TRACKING for deal duplication
    const trackingEventProperties = {
      eventCategory: `${categoryPrefix}Action`,
      eventLabel: `deal_duplicated_from_${actionPrefix}`,
      eventAction: 'deal_duplicated',
    };
    const renderCreateModal = this.state.showDuplicateDealModal && (
      <CreateDealModalContainer
        showModal={this.state.showDuplicateDealModal}
        closeModal={this.toggleDuplicateDealModal}
        dealToDuplicate={deal}
        dealUsersToDuplicate={dealUsers} // Individual Collaborators
        dealTeamsToDuplicate={dealTeams} // Teams on the deal
        trackingEventProperties={trackingEventProperties}
      />
    );

    const renderSyncToSalesforceModal = this.state.showSalesforceModal && (
      <SyncSalesforceModal
        showModal={this.state.showSalesforceModal}
        handleModalToggle={this.toggleSalesforceModal}
        handleModalSubmit={this.handleSyncToSalesforce}
        dateEstClose={deal.date_est_close}
        isLeaseLandlord={deal.rep_role === 'landlord' && deal.deal_type === 'lease'}
        client={deal.client}
      />
    );

    const renderDealActionOptions = dealActionOptions.map(action => (
      <React.Fragment key={action.fontIconName}>
        {!action.isHidden &&
        <li key={action.id}>
          <NDButton
            variant="option"
            icon={action.fontIconName}
            onMouseDown={action.onMouseDown}
            dataE2e={action.dataE2e}
            width="full"
          >
            {action.displayName}
          </NDButton>
        </li>
        }
      </React.Fragment>
    ));

    const renderDeleteDealModal = this.state.showDeleteModal && (
      <DeleteDealModal
        showModal={this.state.showDeleteModal}
        handleModalToggle={this.toggleDeleteDealModal}
        handleModalSubmit={this.handleDeleteDeal}
      />
    );

    const renderChangeDealTypeModal = this.state.showChangeDealTypeModal && (
      <ChangeDealTypeModal
        dealType={deal.deal_type}
        repRole={deal.rep_role}
        showModal={this.state.showChangeDealTypeModal}
        handleModalToggle={this.toggleChangeDealTypeModal}
        handleModalSubmit={this.handleChangeDealType}
      />
    );

    return (
      <div className="deal-actions-dropdown">
        {/*
          this actions dropdown is used both in the deal card and in
          the deal detail header. based on isKebab prop, determine if
          the dropdown is presented as a button (like it is for DDH)
          or as a kebab (like it is within the DealCard)
        */}
        <ButtonWithFlyout
          buttonIcon={!isKebab ? 'chevron-down' : null}
          buttonText={!isKebab ? 'Actions' : null}
          dataE2e="deal-actions-flyout"
          buttonListIcon="more-v"
          closeFlyoutOnClick
          iconButton={isKebab ? 'more-v' : null}
          position={isKebab ? 'right' : 'left'}
          disabled={viewOnly}
        >
          <ul>
            {!viewOnly && renderDealActionOptions}
          </ul>
        </ButtonWithFlyout>
        {renderDeleteDealModal}
        {renderSyncToSalesforceModal}
        {renderCreateModal}
        {renderChangeDealTypeModal}
        {renderToast}
      </div>
    );
  }
}

DealActionsDropdown.propTypes = {
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  deal: PropTypes.shape(),
  dealUsers: PropTypes.arrayOf(PropTypes.shape({})),
  dealTeams: PropTypes.arrayOf(PropTypes.shape({})),
  updateDeal: PropTypes.func,
  // temporary until ddh is refactored for toaster
  hideToasts: PropTypes.bool,
  isKebab: PropTypes.bool,
  viewOnly: PropTypes.bool,
};

DealActionsDropdown.defaultProps = {
  deal: {},
  dealUsers: null,
  dealTeams: null,
  updateDeal: () => { },
  // temporary until ddh is refactored for toaster
  hideToasts: false,
  isKebab: false,
  viewOnly: false,
};

export default DealActionsDropdown;
