const fileUploadRequester = (url, file, fields, onProgress) => new Promise((resolve, reject) => {
  const req = new XMLHttpRequest();

  req.upload.addEventListener('progress', (progressEvent) => {
    if (progressEvent.lengthComputable && onProgress) {
      onProgress(progressEvent);
    }
  });

  req.upload.addEventListener('load', () => {
    resolve(req.response);
  });

  req.upload.addEventListener('error', () => {
    reject(req.response);
  });

  const formData = new FormData();
  formData.append('key', fields.key);
  formData.append('x-amz-date', fields['x-amz-date']);
  formData.append('x-amz-credential', fields['x-amz-credential']);
  formData.append('x-amz-algorithm', fields['x-amz-algorithm']);
  formData.append('x-amz-signature', fields['x-amz-signature']);
  formData.append('policy', fields.policy);
  formData.append('file', file);

  req.open('POST', url);
  req.send(formData);
});

export default fileUploadRequester;
