/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import PropertyDetailPage from './property-detail-page';

// this file waits to determine if we are doing V1 or V2 properties
// the flags must be loaded before we load the list
// this file should be removed once v2 is out.
const PropertyDetail = ({
  dealCount,
  id,
  url,
  tab,
  user,
}) => (
  <PropertyDetailPage
    buildingId={id}
    dealCount={dealCount}
    url={url}
    tab={tab}
    user={user}
  />
);

PropertyDetail.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

PropertyDetail.defaultProps = {
};

export default PropertyDetail;
