import { connect } from 'react-redux';
import { updateDeal, initializeCommissionsTotal } from '../../../redux/actions/deal-actions';
import { fetchConsiderations } from '../../../redux/actions/voucher/considerations-actions';
import { fetchAdditionalCommissions } from '../../../redux/actions/voucher/additional-commissions-actions';
import { addDealTag, fetchDealTags } from '../../../redux/actions/tags-actions';
import { fetchInstallments, updateInstallment } from '../../../redux/actions/voucher/installments-actions';
import { fetchCommissions, updateCommission } from '../../../redux/actions/voucher/commissions-actions';
import clearFinancials from '../../../redux/actions/voucher/financials-actions';

import DealDetailsForm from './deal-details-form';

const mapStateToProps = state => ({
  commissionsTotal: state.commissionsTotal,
  deal: state.deal.deal,
  dealTags: state.tags.dealTags,
  user: state.userData.data,
  userOptions: state.userOptions.userOptions || {},
  installments: state.voucherInstallments,
  commissions: state.voucherCommissions,
  voucher: state.voucher.voucher,
});

const mapDispatchToProps = {
  addDealTag,
  fetchDealTags,
  fetchConsiderations,
  fetchAdditionalCommissions,
  updateDeal,
  fetchInstallments,
  updateInstallment,
  fetchCommissions,
  updateCommission,
  clearFinancials,
  initializeCommissionsTotal,
};

const DealDetailsFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealDetailsForm);

export default DealDetailsFormContainer;
