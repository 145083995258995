/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import forEach from 'lodash/forEach';
import { IconButton, RadioGroup, RadioButton } from '@cbrebuild/blocks';
import { VictoryChart, VictoryStack, VictoryArea, VictoryAxis, VictoryBrushLine } from 'victory';

import traunchDetailsService from '../../services/traunch-details-service';
import Currency from '../../nucleus/formats/currency';
import Tooltip from '../../nucleus/tooltip/tooltip';
import proxyService from '../../services/proxy-service';

export const translateResults = (year, currentYearResponse, prevYearResponse) => {
  const currentYearResults = currentYearResponse.results;
  const receivablesArray = prevYearResponse && prevYearResponse.totals.filter(total => total.payment_status === 'Receivable');
  const prevReceivable = receivablesArray && receivablesArray.length ? receivablesArray[0] : null;

  if (!prevReceivable && !currentYearResults.length) {
    return null;
  }

  if (prevReceivable) {
    const receivableTemplate = {
      total_net_amount_cumulative: prevReceivable.total_net_amount,
      gross_amount_cumulative: prevReceivable.gross_amount,
      gross_amount: prevReceivable.gross_amount,
      payment_status: 'Receivable',
      total_net_amount: prevReceivable.total_net_amount,
      accounting_date: `${year}-01-01`,
    };

    currentYearResults.unshift(receivableTemplate);
  }

  const stackableData = {
    Paid: [],
    Receivable: [],
    Upcoming: [],
  };
  const statuses = ['Paid', 'Receivable', 'Upcoming'];
  let lastPaid = null;
  let lastReceivable = null;
  let lastUpcoming = null;

  // create first day of the year as a starting point
  if (!find(currentYearResults, result => result.accounting_date === `${year}-01-01`)) {
    const firstDayOfYear = {
      accounting_date: `${year}-01-01`,
      gross_amount: 0,
      gross_amount_cumulative: 0,
      payment_status: 'Paid',
      total_net_amount: 0,
      total_net_amount_cumulative: 0,
    };
    currentYearResults.unshift(firstDayOfYear);
  }

  // create data points
  const uniqDates = uniq(currentYearResults.map(result => result.accounting_date));

  // build 3 stacks for paid, receivable, upcoming based on data points
  uniqDates.forEach((date) => {
    statuses.forEach((status) => {
      const current = filter(currentYearResults, result => result.accounting_date === date && result.payment_status === status);
      if (current.length) {
        switch (status) {
          case 'Paid':
            [lastPaid] = current;
            stackableData.Paid.push(lastPaid);
            break;
          case 'Receivable':
            if (prevReceivable && prevReceivable.total_net_amount !== current[0].total_net_amount) {
              current[0].total_net_amount_cumulative += prevReceivable.total_net_amount;
              current[0].gross_amount_cumulative += prevReceivable.gross_amount;
            }
            [lastReceivable] = current;
            stackableData.Receivable.push(lastReceivable);
            break;
          case 'Upcoming':
            [lastUpcoming] = current;
            stackableData.Upcoming.push(lastUpcoming);
            break;
          default:
        }
      }

      if (!current.length) {
        switch (status) {
          case 'Paid':
            if (lastPaid) {
              const lastPaidCopy = cloneDeep(lastPaid);
              lastPaidCopy.accounting_date = date;
              stackableData.Paid.push(lastPaidCopy);
            }
            break;
          case 'Receivable':
            if (lastReceivable) {
              const lastReceivableCopy = cloneDeep(lastReceivable);
              lastReceivableCopy.accounting_date = date;
              stackableData.Receivable.push(lastReceivableCopy);
            }
            break;
          case 'Upcoming':
            if (lastUpcoming) {
              const lastUpcomingCopy = cloneDeep(lastUpcoming);
              lastUpcomingCopy.accounting_date = date;
              stackableData.Upcoming.push(lastUpcomingCopy);
            }
            break;
          default:
        }
      }
    });
  });

  // handle last day of year, final data point
  const lastDayOfYear = `${year}-12-31`;
  if (!uniqDates.includes(lastDayOfYear)) {
    if (lastPaid) {
      const lastPaidCopy = cloneDeep(lastPaid);
      lastPaidCopy.accounting_date = lastDayOfYear;
      stackableData.Paid.push(lastPaidCopy);
    }

    if (lastReceivable) {
      const lastReceivableCopy = cloneDeep(lastReceivable);
      lastReceivableCopy.accounting_date = lastDayOfYear;
      stackableData.Receivable.push(lastReceivableCopy);
    }

    if (lastUpcoming) {
      const lastUpcomingCopy = cloneDeep(lastUpcoming);
      lastUpcomingCopy.accounting_date = lastDayOfYear;
      stackableData.Upcoming.push(lastUpcomingCopy);
    }
  }

  // remove stack if data dosn't exist
  forEach(stackableData, (stack, key) => {
    if (!stack.length) {
      delete stackableData[key];
    }
  });

  return stackableData;
};

export const getDataProp = (set, mode) => set.map(item => ({
  x: Date.parse(item.accounting_date),
  y: mode === 'gross' ? item.gross_amount_cumulative : item.total_net_amount_cumulative,
}));

export const getStyleByStatus = (status) => {
  switch (status) {
    case 'Paid':
      return {
        data: {
          strokeWidth: 3,
          stroke: '#00A657',
          fill: '#00A657',
          fillOpacity: 0.3,
        },
      };
    case 'Receivable':
      return {
        data: {
          strokeWidth: 3,
          stroke: '#E02F4F',
          fill: '#E02F4F',
          fillOpacity: 0.3,
        },
      };
    case 'Upcoming':
      return {
        data: {
          strokeWidth: 3,
          strokeDasharray: 5,
          stroke: '#488FB8',
          fill: '#488FB8',
          fillOpacity: 0,
        },
      };
    default:
      return null;
  }
};

export const getSummaryTitle = (status) => {
  switch (status) {
    case 'Paid':
      return 'Paid This Year';
    case 'Receivable':
      return 'Past Due';
    case 'Upcoming':
      return 'Upcoming';
    default:
      return 'Year Total';
  }
};

export const todayFromStartOfYear = moment.duration(moment().diff(moment().startOf('year'))).asDays();

export const formatCommissionsTick = (t) => {
  if (t >= 1000000) {
    return `$${t / 1000000}M`;
  } else if (t >= 1000) {
    return `$${t / 1000}K`;
  }
  return `$${t}`;
};

const AnnualChart = ({ user }) => {
  const [year, setYear] = useState(moment().year());
  const [mode, setMode] = useState('gross');
  const [data, setData] = useState(null);
  const [totals, setTotals] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasPrevReceivable, setHasPrevReceivable] = useState(false);

  const handleFetch = async () => {
    if (!user.id) {
      return;
    }

    setIsLoading(true);

    const activeProxy = await proxyService.currentActiveProxy().then(res => res.results.find(access => access.is_active));

    const params = {
      end_date: `${year}-12-31`,
      start_date: `${year}-01-01`,
      user_id: activeProxy?.user_id || user.id,
    };

    const response = await traunchDetailsService.fetchTraunchDetailsChart(params);

    const paramsForPrevYear = {
      end_date: `${year - 1}-12-31`,
      start_date: `${year - 1}-01-01`,
      user_id: activeProxy?.user_id || user.id,
    };

    const responseForPrevYear = await traunchDetailsService.fetchTraunchDetailsChart(paramsForPrevYear);
    const receivablesArray = responseForPrevYear && responseForPrevYear.totals.filter(total => total.payment_status === 'Receivable');
    const prevReceivable = receivablesArray && receivablesArray.length ? receivablesArray[0] : null;
    if (prevReceivable) {
      setHasPrevReceivable(true);
    }

    const translatedResults = translateResults(year, response, responseForPrevYear);
    setData(translatedResults);

    const translatedTotals = traunchDetailsService.translateTotals(response.totals, responseForPrevYear.totals);
    setTotals(translatedTotals);
    setIsLoading(false);
  };

  useEffect(() => {
    setHasPrevReceivable(false);
    handleFetch();
  }, [year, user]);

  return (
    <div className="card-default annual-chart">
      <div className="annual-chart-controls">
        <IconButton className="icon-as-button" iconName="arrow-left" onClick={() => setYear(year - 1)} />
        <h4 className="chart-title">{year} Total Commissions</h4>
        <IconButton className="icon-as-button" iconName="arrow-right" onClick={() => setYear(year + 1)} />
      </div>

      {isLoading &&
        <div className="empty-chart">
          Loading Annual Chart
        </div>
      }
      {!isLoading &&
        <React.Fragment>
          <div className="chart-summary">
            {totals && totals.map(total => (
              <div className="summary" key={total.payment_status}>
                <div>
                  <p className="summary-title"><span className={`status-indicator ${total.payment_status}`} />{getSummaryTitle(total.payment_status)}</p>
                  {hasPrevReceivable && total.payment_status === 'Receivable' && <Tooltip message={`Includes past due payments from ${year - 1}`} />}
                </div>
                <div className="gross">
                  <p className="currency"><Currency value={total.gross_amount} emptyState="No Data" decimal={0} /><span className="spacer">|</span></p>
                  <span className="currency-label">Gross</span>
                </div>
                <div className="net">
                  <p className="currency"><Currency value={total.total_net_amount} emptyState="No Data" decimal={0} /></p>
                  <span className="currency-label">Net</span>
                </div>
              </div>))}
          </div>
          <div className="chart-toggles">
            <RadioGroup
              name="chart-options"
              onChange={e => setMode(e.target.value)}
              selectedValue={mode}
            >
              <RadioButton value="gross">Gross</RadioButton>
              <RadioButton value="net">Net</RadioButton>
            </RadioGroup>
          </div>
          {!data &&
            <div className="empty-chart">
              There is no data for this year
            </div>}
          {data &&
            <VictoryChart
              scale={{ x: 'time' }}
              width={1300}
              height={400}
              domainPadding={{ x: 0, y: 5 }}
            >
              <VictoryStack>
                {map(data, (dataset, key) => (
                  <VictoryArea
                    name={key}
                    key={key}
                    data={getDataProp(dataset, mode)}
                    style={getStyleByStatus(key)}
                    animate={{
                      duration: 500,
                      easing: 'cubicInOut',
                    }}
                    interpolation="basis"
                  />
                ))}
              </VictoryStack>
              <VictoryAxis
                name="commissions"
                dependentAxis
                offsetX={1250} // 1200(width) + 50(padding)
                tickFormat={t => formatCommissionsTick(t)}
                style={{
                  axis: { stroke: 'transparent' },
                  grid: {
                    stroke: '#A9B2B5',
                    strokeWidth: 1,
                    strokeDasharray: 2,
                  },
                  tickLabels: { fontSize: 12 },
                }}
              />
              <VictoryAxis
                tickCount={12}
                crossAxis
                name="months"
                style={{
                  tickLabels: { fontSize: 12 },
                }}
              />
              {[1, 3, 5, 7, 9, 11].map(tick => (
                <VictoryAxis
                  key={tick}
                  dependentAxis
                  offsetX={tick * 100}
                  style={{
                    axis: {
                      stroke: 'transparent',
                    },
                    tickLabels: { fill: 'none' },
                  }}
                  axisComponent={
                    <VictoryBrushLine
                      width={100}
                      disable
                    />
                  }
                />
              ))}
              {year === moment().year() && <VictoryAxis
                name="today"
                dependentAxis
                offsetX={((todayFromStartOfYear / 365) * 1200) + 50}
                style={{
                  axis: {
                    strokeWidth: 2,
                    stroke: '#A9B2B5',
                    strokeDasharray: 6,
                  },
                  tickLabels: { fill: 'none' },
                }}
              />}
            </VictoryChart>
          }
        </React.Fragment>
      }
    </div>
  );
};

export default AnnualChart;

AnnualChart.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
