import React from 'react';
import PropTypes from 'prop-types';

const RowItem = (props) => {
  const {
    additionalClassName,
    children,
    onClick,
    border,
  } = props;

  const classNameDefault = border ? 'nd-row-item border' : 'nd-row-item';
  const classNameAddition = additionalClassName ? ` ${additionalClassName}` : '';

  return (
    <div
      className={`${classNameDefault} ${classNameAddition}`}
      onClick={onClick}
      role="listitem"
    >
      {children}
    </div>
  );
};

RowItem.propTypes = {
  additionalClassName: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  border: PropTypes.bool,
};

RowItem.defaultProps = {
  additionalClassName: '',
  children: [],
  onClick: () => {},
  border: false,
};

export default RowItem;
