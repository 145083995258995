import {
  FETCH_CONSIDERATIONS_SUCCESS,
  UPDATE_CONSIDERATIONS_IN_MODAL,
  UPDATE_COMMISSIONS_TOTAL,
  INITIALIZE_COMMISSIONS_TOTAL,
  CLEAR_FINANCIALS,
} from '../../actions/action-types';

const considerations = (state = [], action) => {
  switch (action.type) {
    case FETCH_CONSIDERATIONS_SUCCESS:
      return action.payload;
    case CLEAR_FINANCIALS:
      return [];
    default:
      return state;
  }
};

const tempConsiderations = (state = [], action) => {
  switch (action.type) {
    case UPDATE_CONSIDERATIONS_IN_MODAL:
      return action.payload;
    default:
      return state;
  }
};

const commissionsTotal = (state = 0, action) => {
  switch (action.type) {
    case INITIALIZE_COMMISSIONS_TOTAL:
      return action.payload;
    case UPDATE_COMMISSIONS_TOTAL:
      return action.payload;
    case CLEAR_FINANCIALS:
      return 0;
    default:
      return state;
  }
};

export { considerations, tempConsiderations, commissionsTotal };
