import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Checkbox, Button } from '@cbrebuild/blocks';
import Modal from '../../nucleus/modal/modal';
import selectPipelineReport from '../../redux/selectors/pipeline-selectors';
import { updateTableConfigAction, reOrderTableConfAction } from '../../redux/actions/pipeline-table-actions';

const { SortableList } = require('@cbrebuild/blocks-react');

const EditColumnsButton = () => {
  const getHiddenFieldCount = updatedConf => (
    updatedConf.filter(conf => conf.checked === false).length
  );

  const tableConfig = useSelector(selectPipelineReport);
  const [modalOpen, setModalOpen] = useState(false);
  const [localTableConfig, setLocalTableConfig] = useState(tableConfig);
  const [hiddenFieldCount, setHiddenFieldCount] = useState(getHiddenFieldCount(tableConfig));
  const dispatch = useDispatch();

  const handleOrderChange = (oldIndex, newIndex) => {
    const updatedTableConfig = localTableConfig;
    const reorderedTableConfig = updatedTableConfig.splice(oldIndex, 1)[0];
    updatedTableConfig.splice(newIndex, 0, reorderedTableConfig);
    setLocalTableConfig(updatedTableConfig);
  };

  useEffect(() => {
    dispatch(reOrderTableConfAction());
  }, []);

  useEffect(() => {
    setHiddenFieldCount(getHiddenFieldCount(tableConfig));
    setLocalTableConfig(tableConfig);
  }, [tableConfig]);

  const handleChange = (checked, option) => {
    const updatedTableConfig = localTableConfig.map((conf) => {
      if (conf.value === option.value) {
        return {
          ...conf,
          checked,
          isHidden: !checked,
        };
      }
      return conf;
    });

    setHiddenFieldCount(getHiddenFieldCount(updatedTableConfig));
    setLocalTableConfig(updatedTableConfig);
  };

  const handleModalSubmit = () => {
    dispatch(updateTableConfigAction(localTableConfig));
    setModalOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const toggleChecked = (tableConf, checked) => {
    const updatedTableConfig = tableConf.map(conf => ({
      ...conf,
      checked,
      isHidden: !checked,
    }));

    setHiddenFieldCount(getHiddenFieldCount(updatedTableConfig));
    setLocalTableConfig(updatedTableConfig);
  };

  const sortableOpts = localTableConfig.filter(tableConf => tableConf.text !== 'Select All').map(conf => (
    <Checkbox
      key={conf.key}
      checked={conf.checked}
      onChange={e => handleChange(e.target.checked, conf)}
      className="edit-cols-modal-checkbox"
    >
      {conf.value}
    </Checkbox>
  ));
  return (
    <Fragment>
      <Modal
        showModal={modalOpen}
        modalWidth={400}
        primaryButtonText="Apply"
        secondaryButtonText="Close"
        handleModalSubmit={() => handleModalSubmit()}
        handleSecondaryButton={() => handleModalClose()}
      >
        <p>Select which columns you would like to view or hide, and drag & drop the columns to sort as you see fit</p>
        <Checkbox
          checked={!hiddenFieldCount}
          onChange={e => toggleChecked(localTableConfig, e.target.checked)}
          className="edit-cols-modal-checkbox"
          indeterminate={hiddenFieldCount > 0 && hiddenFieldCount < localTableConfig.length}
        >
          Select All
        </Checkbox>
        <SortableList
          className="blx-scrollable"
          onDrop={handleOrderChange}
        >
          {sortableOpts}
        </SortableList>
      </Modal>

      <Button
        className="edit-columns-button"
        variant="text"
        onClick={() => setModalOpen(true)}
      >
        <p>Edit Columns</p>
        <Icon iconName="menu" />
      </Button>
    </Fragment>
  );
};


export default EditColumnsButton;
