const sortByLastName = (a, b) => (a.user.last_name > b.user.last_name ? 1 : -1);

const sortTeamMembers = (members, userId) =>
  members.filter(member => member.user.id === userId)
    .concat(members.filter(member => member.user.id !== userId).sort(sortByLastName));

export {
  sortTeamMembers,
  sortByLastName,
};
