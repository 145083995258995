import React, { useEffect, useState, useRef } from 'react';
import debounce from 'lodash/debounce';

import { Button } from '@cbrebuild/blocks';
import EmptyState from '../../components/empty-state/empty-state';
import LoadMore from '../../nucleus/load-more/load-more';
import ListActionHeader from '../../nucleus/list-action-header/list-action-header';
import PageHeaderDefault from '../../nucleus/header/page-header-default';
import SearchInput from '../../nucleus/search/search-input';
import SPOCSearchListItem from './spoc-search-list-item';
import spocService from '../../services/spoc-service';
import userEventService from '../../services/user-event-service';

const analyticProperties = {
  actionPrefix: 'spoc_search_',
  categoryPrefix: 'SPOC ',
};

const SPOCSearch = () => {
  const [cachedParams, setCachedParams] = useState(JSON.parse(window.localStorage.getItem('spoc-search-params')) || {});
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    count: 0,
    next: 0,
    results: [],
  });
  const [searchParams, setSearchParams] = useState({
    page: 1,
    search: '',
    ...cachedParams,
  });

  const searching = useRef(false);

  useEffect(() => {
    document.title = 'Deal IQ | SPOC Search';
  }, []);

  useEffect(() => {
    const trackSearchResult = (data) => {
      const eventAction = `${analyticProperties.actionPrefix}${data.count ? 'success' : 'null_results'}`;
      const eventCategory = `${analyticProperties.categoryPrefix}Search Action`;
      userEventService.trackEvent({
        eventCategory,
        eventAction,
        eventLabel: 'on_search',
        eventData: {
          search: searchParams.search,
          results: data.count,
        },
      });
    };
    setLoading(true);
    spocService.getSPOCResults(searchParams)
      .then((data) => {
        setResponse({
          count: data.count,
          next: data.next,
          results: searchParams.page > 1
            ? [...response.results, ...data.results]
            : data.results,
        });
        if (searching.current) {
          trackSearchResult(data);
          searching.current = false;
        }
      })
      .then(() => {
        setLoading(false);
      });
    window.localStorage.setItem('spoc-search-params', JSON.stringify(searchParams));
    setCachedParams(searchParams);
  }, [searchParams]); //eslint-disable-line

  const trackLoadMoreClicked = () => {
    const eventCategory = `${analyticProperties.categoryPrefix}Search Action`;
    const eventAction = `${analyticProperties.actionPrefix}load_more_clicked`;
    userEventService.trackEvent({
      eventCategory,
      eventAction,
      eventLabel: 'load_more_clicked_from_spoc_search',
      eventData: {
        page: searchParams.page,
      },
    });
  };

  const onChange = (search) => {
    searching.current = true;
    setSearchParams({
      ...searchParams,
      search,
      page: 1,
    });
  };

  const debounceOnChange = debounce(onChange, 500);

  const onLoadMore = () => {
    trackLoadMoreClicked();
    setSearchParams({
      ...searchParams,
      page: searchParams.page += 1,
    });
  };

  const trackDownload = () => {
    const eventCategory = `${analyticProperties.categoryPrefix}Download Link`;
    const eventAction = `${analyticProperties.actionPrefix}download_clicked`;
    userEventService.trackEvent({
      eventCategory,
      eventAction,
      eventLabel: 'download_clicked_from_spoc_search',
    });
  };

  return (
    <div className="spoc-search">
      <PageHeaderDefault
        title="SPOC Search"
        subtitle="Look up Single Point of Contact details for any of CBRE’s clients. "
        subtitlePosition="inline"
      >
        <span className="count">{`${response.count > 0 ? response.count : 0} results`}</span>
      </PageHeaderDefault>
      <div className="page-content card-default">
        <ListActionHeader
          isLoading={loading}
          search={(
            <SearchInput
              handleSearchTermChange={debounceOnChange}
              initValue={searchParams.search}
              searchKey="accounts, SPOC names and email addresses"
            />
          )}
        />
        {(response.count > 0) &&
          <ul className="results-list">
            {response.results.map((result, index) => {
              const { _id: id } = result;
              return (
                <SPOCSearchListItem key={id} spoc={result} index={index.toString()} />
              );
            })}
          </ul>
        }
        {response.count > 25 &&
        <LoadMore
          hasNextPage={response.next}
          handleLoadMore={onLoadMore}
        />
        }
        {(response.count === 0 && !loading) &&
          <div className="empty-state-container">
            <EmptyState
              hideTitle={searchParams.search === ''}
              title={(searchParams.search !== '' && 'No Results Found') || ''}
              message={(searchParams.search !== ''
                ? `We couldn't find any accounts or SPOCs matching "${searchParams.search}"`
                : 'Get started by searching for a SPOC')}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default SPOCSearch;
