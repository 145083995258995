import React from 'react';
import PropTypes from 'prop-types';
import { fetchFunctionalUnits } from '../../services/voucher/commissions-service';
import SearchableDropdown from './searchable-dropdown';

const getDisplayText = ({ functional_unit_name, functional_unit_code }) => `${functional_unit_name} (${functional_unit_code})`;
export default function FunctionalUnitAutocomplete({
  deptid,
  errorMessage,
  onBlur,
  onSelect,
  placeholder,
}) {
  return (
    <SearchableDropdown
      errorMessage={errorMessage}
      fetchResults={fetchFunctionalUnits}
      getDisplayText={getDisplayText}
      findby="functional_unit_code"
      onBlur={onBlur}
      onSelect={onSelect}
      placeholder={placeholder}
      searchableValues={['functional_unit_code', 'functional_unit_name']}
      value={deptid}
    />
  );
}

FunctionalUnitAutocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  deptid: PropTypes.string,
  onBlur: PropTypes.func,
};
FunctionalUnitAutocomplete.defaultProps = {
  errorMessage: '',
  placeholder: '',
  deptid: '',
  onBlur: undefined,
};
