import { connect } from 'react-redux';
import AdminLabs from './admin-labs';
import { toast } from '../../redux/actions/toasts-actions';

const mapStateToProps = state => ({
  userData: (state.userData.data && state.userData.data.id) ? state.userData.data : {},
});


const AdminLabsContainer = connect(
  mapStateToProps,
  { toast },
)(AdminLabs);

export default AdminLabsContainer;
