import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';

class Dropzone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      highlight: false,
    };
    this.fileInputRef = React.createRef();
  }

  onDrop = (event) => {
    const {
      disabled,
      onFilesAdded,
    } = this.props;
    const { files } = event.dataTransfer;
    event.preventDefault();
    if (disabled) return;
    if (onFilesAdded) {
      const array = this.fileListToArray(files);
      onFilesAdded(array);
    }
    this.setState({ highlight: false });
  }

  onDragLeave = () => {
    this.setState({ highlight: false });
  }

  onDragOver = (event) => {
    const { disabled } = this.props;
    event.preventDefault();
    if (disabled) return;
    this.setState({ highlight: true });
  }

  handleFilesAdded = (event) => {
    const {
      disabled,
      onFilesAdded,
    } = this.props;
    if (disabled) return;
    const { files } = event.target;
    if (onFilesAdded) {
      const array = this.fileListToArray(files);
      onFilesAdded(array);
      if (this.fileInputRef.current && this.fileInputRef.current.value) {
        this.fileInputRef.current.value = null;
      }
    }
  }

  // convert the files receieved from FileList
  // to an array so its easier to work with
  fileListToArray = (fileList) => {
    const array = [];
    for (let i = 0; i < fileList.length; i++) {
      array.push(fileList.item(i));
    }
    return array;
  }

  // to actually open the file dialog, click
  // on the input using javascript
  openFileDialog = () => {
    const { disabled } = this.props;
    if (disabled) return;
    this.fileInputRef.current.click();
  }

  render() {
    const { highlight } = this.state;
    const { disabled } = this.props;
    return (
      <div
        className={`nd-dropzone ${highlight ? 'highlight' : ''}`}
        onClick={this.openFileDialog}
        onDrop={this.onDrop}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        style={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <input
          ref={this.fileInputRef}
          className="file-input"
          type="file"
          multiple
          onChange={this.handleFilesAdded}
        />
        <span className="dropzone-text">
          <Icon iconName="add-note" />
          Drag files here or click to upload
        </span>
      </div>
    );
  }
}

Dropzone.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onFilesAdded: PropTypes.func.isRequired,
};

export default Dropzone;
