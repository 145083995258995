import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import round from 'lodash/round';
import uniqueId from 'lodash/uniqueId';

import AdditionalCommissionItem from './additional-commission-item';
import AddItem from '../../../nucleus/add-item/add-item';
import Currency from '../../../nucleus/formats/currency';

class AdditionalCommissions extends Component {
  componentDidMount() {
    this.fetchAdditionalCommissionTypeOptions();
    // This gets additional commissions state fetched from API
    // and replaces tempAdditionalCommissions with it
    // so that users can play with it without changing the original state.
    this.props.updateAdditionalCommissionsInModal(this.props.additionalCommissions);
  }

  getCommissionTemplate = () => ({
    id: uniqueId(),
    acommission_type: this.props.additionalCommissionTypeOptions.filter(obj => obj.display_name === 'Consulting Fee')[0].value,
    total_units: null,
    amount: null,
    transaction: this.props.dealId,
    created: true, // temp field to notifiy modal to update
  });

  getTotalCommissions = () => reduce(
    this.props.tempAdditionalCommissions,
    (acc, cur) => acc + ((!cur.deleted && cur.commission_amount) || 0),
    0,
  );

  fetchAdditionalCommissionTypeOptions = () => {
    if (!this.props.additionalCommissionTypeOptions.length) {
      this.props.fetchAdditionalCommissionTypeOptions();
    }
  }

  addCommission = () => {
    const newItem = this.getCommissionTemplate();
    const additionalCommissions = cloneDeep(this.props.tempAdditionalCommissions);
    additionalCommissions.push(newItem);
    this.props.updateAdditionalCommissionsInModal(additionalCommissions);
  };

  updateCommission = (id, key, value) => {
    const additionalCommissions = cloneDeep(this.props.tempAdditionalCommissions);
    const commission = find(additionalCommissions, { id });
    commission[key] = value;
    commission.updated = true; // temp field to notifiy modal to update
    this.props.updateAdditionalCommissionsInModal(additionalCommissions);
  };

  deleteCommission = (id) => {
    const additionalCommissions = cloneDeep(this.props.tempAdditionalCommissions);
    const commission = find(additionalCommissions, { id });
    commission.deleted = true; // temp field to notifiy modal to update
    this.props.updateAdditionalCommissionsInModal(additionalCommissions);
  };

  // for items to calculate commission_amount on update
  calculateCommission = (id) => {
    const additionalCommissions = cloneDeep(this.props.tempAdditionalCommissions);
    const commission = find(additionalCommissions, { id });
    commission.commission_amount = round(commission.total_units * commission.amount, 2);
    commission.updated = true;
    this.props.updateAdditionalCommissionsInModal(additionalCommissions);
  };

  render() {
    const { dealType, additionalCommissionTypeOptions, tempAdditionalCommissions } = this.props;
    const titleByDealType = (dealType === 'consulting' || dealType === 'other') ? 'Commissions' : 'Additional Commissions';

    const renderAdditionalCommissionItem =
    !!tempAdditionalCommissions.length && tempAdditionalCommissions.map((commission, index) =>
      !commission.deleted && (<AdditionalCommissionItem
        key={commission.id || index}
        commission={commission}
        additionalCommissionTypeOptions={additionalCommissionTypeOptions}
        updateCommission={this.updateCommission}
        calculateCommission={this.calculateCommission}
        deleteCommission={this.deleteCommission}
      />));

    const renderTotalRow = (
      <div className="table-row table-footer">
        <div className="table-cell">Totals</div>
        <div className="table-cell" />
        <div className="table-cell" />
        <div className="table-cell">
          <Currency
            value={this.getTotalCommissions()}
            empthState="--"
          />
        </div>
        <div className="table-cell" />
      </div>
    );

    return (
      <div className="financials-section-wrapper">
        <h3>{titleByDealType}</h3>
        <div className="table additional-commissions-table">
          <div className="table-header">
            <div className="table-cell">Commission Type</div>
            <div className="table-cell">Quantity</div>
            <div className="table-cell">Amount($)</div>
            <div className="table-cell">Additional Commission</div>
            <div className="table-cell" />
          </div>
          {renderAdditionalCommissionItem}
          <div>
            <AddItem label="Add Commission" onClick={this.addCommission} />
          </div>
          {renderTotalRow}
        </div>
      </div>
    );
  }
}

AdditionalCommissions.propTypes = {
  dealId: PropTypes.number.isRequired,
  dealType: PropTypes.string.isRequired,
  // states
  additionalCommissions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tempAdditionalCommissions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  additionalCommissionTypeOptions: PropTypes.arrayOf(PropTypes.shape()),
  // actions
  updateAdditionalCommissionsInModal: PropTypes.func.isRequired,
  fetchAdditionalCommissionTypeOptions: PropTypes.func.isRequired,
};

AdditionalCommissions.defaultProps = {
  additionalCommissionTypeOptions: [],
};

export default AdditionalCommissions;
