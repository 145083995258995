import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RadioButton, RadioGroup } from '@cbrebuild/blocks';
import { useDispatch, useSelector } from 'react-redux';
import AddItem from '../../../nucleus/add-item/add-item';
import RebateItem from './RebateItem';
import { createRebate, deleteRebate } from '../../../redux/actions/rebates-actions';
import SimpleConfirmationModal from '../../modals/SimpleConfirmationModal';

const selectRebateIds = state => state.rebates.rebates.map(({ id }) => id);

const Rebates = ({
  dealId,
  voucherId,
  showValidation,
  rebate_is_percent,
}) => {
  const rebateIds = useSelector(selectRebateIds);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const addRebate = () => {
    dispatch(createRebate({
      payment_group: 3,
      transaction: dealId,
    }));
  };

  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);
  const onDeleteAll = () => {
    closeModal();
    rebateIds.forEach((id) => {
      dispatch(deleteRebate(id));
    });
  };

  const toggle = () => {
    if (rebateIds.length) {
      openModal();
    } else {
      addRebate();
    }
  };

  return (
    <div className="outside-brokers">
      <h2>
        Were Any Rebates to the Client Provided?
      </h2>
      <RadioGroup
        selectedValue={`${rebateIds.length !== 0}`}
        className="radioVal"
        onChange={toggle}
        orientation="vertical"
      >
        <RadioButton value="false">No rebates</RadioButton>
        <RadioButton value="true">Yes, there were rebate(s) to the Client</RadioButton>
      </RadioGroup>
      {rebateIds.length !== 0 && (
        <>
          <h2>Rebates</h2>
          {rebateIds.map(id => (
            <RebateItem
              id={id}
              key={id}
              dealId={dealId}
              voucherId={voucherId}
              showValidation={showValidation}
              rebate_is_percent={rebate_is_percent}
            />
          ))}
          <AddItem label="Add Rebate" onClick={addRebate} />
        </>
      )}
      {showModal && (
        <SimpleConfirmationModal
          showModal={showModal}
          onCancel={closeModal}
          onAccept={onDeleteAll}
        >
          This will delete all rebates. Are you sure?
        </SimpleConfirmationModal>
      )}
    </div>
  );
};

Rebates.propTypes = {
  dealId: PropTypes.number.isRequired,
  voucherId: PropTypes.number.isRequired,
  showValidation: PropTypes.bool.isRequired,
  rebate_is_percent: PropTypes.bool.isRequired,
};

export default Rebates;
