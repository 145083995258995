import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class EmailInput extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    label: PropTypes.string,
    onBlur: PropTypes.func,
  }

  static defaultProps = {
    value: '',
    onChange: null,
    onBlur: null,
    label: 'Email',
  }

  state = {
    isValid: true,
    warning: '',
    value: this.props.value,
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setValue(this.props.value);
    }
  }

  onBlur = (e) => {
    const input = e.target.value;
    this.setState({
      value: e.target.value,
    });
    if (this.props.onBlur) {
      const isValid = (this.validateEmail(e.target.value) || e.target.value.length === 0);
      this.props.onBlur(e.target.value, isValid);
    }
    if (this.validateEmail(input) || input.length === 0) {
      this.setState({
        isValid: true,
      });
    } else {
      this.setState({
        isValid: false,
        warning: 'Please enter a valid email address',
      });
    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
    if (this.props.onChange) {
      const isValid = (this.validateEmail(e.target.value) || e.target.value.length === 0);
      this.props.onChange(e.target.value, isValid);
    }
  }

  onFocus = () => {
    this.setState({
      isValid: true,
    });
  }

  setValue(val) {
    this.setState({ value: val });
  }


  validateEmail = (input) => {
    // eslint-disable-next-line
    const re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
    return re.test(input);
  }

  render() {
    const {
      isValid,
      warning,
      value,
    } = this.state;

    const { label } = this.props;

    const invalidClassName = !isValid ? 'invalid' : '';
    const renderWarning = !isValid ? <p>{warning}</p> : '';

    return (
      <div className={`email-input ${invalidClassName}`}>
        <label htmlFor="input">{label}</label>
        <input
          name="email"
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          type="text"
          value={value}
        />
        {renderWarning}
      </div>
    );
  }
}
