import { connect } from 'react-redux';
import {
  deleteFile,
  fetchFiles,
  fetchFilesDownloadLink,
  uploadFile,
  updateFile,
} from '../../../redux/actions/deal-files-actions';
import VoucherFiles from './voucher-files';

const mapStateToProps = (state, props) => ({
  dealId: props.dealId ? props.dealId : state.deal.deal.id,
  files: state.dealFiles.files,
  voucher: props.voucher ? props.voucher : {},
  isLoadingFiles: state.dealFiles.isLoadingFiles,
  next: state.dealFiles.next,
  userData: state.userData.data || {},
});

const mapDispatchToProps = {
  deleteFile,
  fetchFiles,
  fetchFilesDownloadLink,
  uploadFile,
  updateFile,
};

const VoucherFilesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VoucherFiles);

export default VoucherFilesContainer;
