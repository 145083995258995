import superFetch from '../utils/super-fetch';

const url = '/mbb/user/preferences';

const fetchUserOptions = () => {
  const options = {
    method: 'GET',
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const updateUserOptions = (params) => {
  const options = {
    method: 'PATCH',
  };
  return superFetch(url, options, JSON.stringify(params))
    .then(data => data.json());
};

const fetchFeatureFlags = () => {
  const options = {
    method: 'GET',
  };
  return superFetch('/mbb/user/featureflags', options)
    .then(data => data.json());
};

const updateFeatureFlags = (params) => {
  const options = {
    method: 'PATCH',
  };
  return superFetch('/mbb/user/featureflags', options, JSON.stringify(params))
    .then(data => data.json());
};

export default {
  fetchFeatureFlags,
  fetchUserOptions,
  updateFeatureFlags,
  updateUserOptions,
};

