import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import { Icon, IconButton } from '@cbrebuild/blocks';

import Flyout from '../../../nucleus/flyout/flyout';
import NDButton from '../../../nucleus/button/button';

class ButtonListFlyout extends React.Component {
  state = {
    showFlyout: false,
  }

  openFlyout = () => {
    this.setState({
      showFlyout: true,
    });
  }

  closeFlyout = () => {
    this.setState({
      showFlyout: false,
    });
  }

  render() {
    const {
      buttonList,
      buttonListIcon,
      buttonListName,
    } = this.props;
    const { showFlyout } = this.state;
    const flyoutPosition = buttonListIcon && !buttonListName ? 'right' : 'left';
    const renderButtons = buttonList.map(button => (
      <li key={uniqueId()} className={button.isHidden ? 'button-list-item hidden' : 'button-list-item'}>
        <NDButton
          className="option-button"
          variant="option"
          width="full"
          disabled={button.disabled}
          onClick={() => { button.onClick(); this.closeFlyout(); }}
          icon={button.fontIconName}
          iconPosition="left"
        >
          {button.displayName}
        </NDButton>
      </li>
    ));

    const flyout = (
      <Flyout
        showFlyout={showFlyout}
        closeFlyout={this.closeFlyout}
        position={flyoutPosition}
      >
        <ul role="navigation" className="button-list">
          {renderButtons}
        </ul>
      </Flyout>
    );
    if (buttonListIcon && buttonListName) {
      return (
        <div className="button-list-flyout">
          <div className="name-icon-button" onClick={this.openFlyout} >
            <Icon
              className="icon-button"
              iconName={buttonListIcon}
            />
            {buttonListName}
          </div>
          {flyout}
        </div>
      );
    } if (buttonListIcon) {
      return (
        <div className="button-list-flyout icon-button">
          <IconButton
            iconName={buttonListIcon}
            onClick={this.openFlyout}
          />
          {flyout}
        </div>
      );
    }
    return (
      <div className="button-list-flyout">
        <NDButton
          className="action-button"
          variant="action"
          onClick={this.openFlyout}
          icon="chevron-down"
          iconPosition="right"
        >
          {buttonListName}
        </NDButton>
        {flyout}
      </div>
    );
  }
}

ButtonListFlyout.propTypes = {
  buttonList: PropTypes.arrayOf(PropTypes.shape({
    displayName: PropTypes.string,
    fontIconName: PropTypes.string,
    onClick: PropTypes.func,
    isHidden: PropTypes.bool,
  })).isRequired,
  buttonListName: PropTypes.string,
  buttonListIcon: PropTypes.string,
};

ButtonListFlyout.defaultProps = {
  buttonListName: null,
  buttonListIcon: null,
};

export default ButtonListFlyout;
