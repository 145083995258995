const initialState = {};

// Task Alert Reducers
const loadTaskAlertsSuccess = (state, action) => {
  const payload = action.payload.taskAlerts;
  // Map the array of objects into a hashmap where key is property.id
  const allTaskIDs = [];
  const tasksByID = {};

  payload.forEach((alert) => {
    tasksByID[alert.id] = alert;
    allTaskIDs.push(alert.id);
  });
  const updatedState = {
    ...state,
    allTaskIDs,
    tasksByID,
  };
  return updatedState;
};

const loadTaskAlertsNextPage = (state, action) => {
  // handle pagination by creating an ID array and hashmap for next page of data
  // then merge the new hashmap and concat the new array with the data already in state
  const payload = action.payload.taskAlerts;
  const taskIDsNextPage = state.allTaskIDs || [];
  const tasksNextPageByID = state.tasksByID || {};

  payload.forEach((alert) => {
    if (!tasksNextPageByID[alert.id]) {
      tasksNextPageByID[alert.id] = alert;
      taskIDsNextPage.push(alert.id);
    }
  });
  const updatedState = {
    ...state,
    allTaskIDs: taskIDsNextPage,
    tasksByID: tasksNextPageByID,
  };
  return updatedState;
};

const loadTaskAlertsFailure = (state, action) => {
  const updatedState = {
    ...state,
    taskAlertsError: action.payload.taskAlertsError,
  };
  return updatedState;
};

const isLoadingTaskAlerts = (state, action) => {
  const updatedState = {
    ...state,
    isLoadingTaskAlerts: action.payload.loadingTaskAlerts,
  };
  return updatedState;
};

const loadTaskAlertsMetadata = (state, action) => {
  let metadata = { ...state.taskAlertsMetadata };
  if (!metadata) {
    metadata = {
      hasNextPage: null,
      badgeCount: 0,
    };
  }

  const { next, unread } = action.payload.metadata;
  metadata.hasNextPage = next !== undefined ? next : metadata.hasNextPage;

  if (unread !== undefined) {
    const unreadCount = unread.find(item => item.task);
    metadata.badgeCount = unreadCount ? unreadCount.task : 0;
  }

  const updatedState = {
    ...state,
    taskAlertsMetadata: {
      hasNextPage: metadata.hasNextPage,
      badgeCount: metadata.badgeCount,
    },
  };
  return updatedState;
};

const markTasksAsReadFailure = (state, action) => {
  const updatedState = {
    ...state,
    markTasksAsReadError: action.payload.markTasksAsReadError,
  };
  return updatedState;
};

const markTasksAsReadPending = (state, action) => {
  // notifications processes alerts in batches
  const { alertIDs } = action.payload;
  const { tasksByID } = state;
  const updatedTasks = { ...tasksByID };
  alertIDs.forEach((id) => {
    updatedTasks[id].read = new Date();
  });
  const updatedState = {
    ...state,
    tasksByID: updatedTasks,
  };
  return updatedState;
};

// Notification Alerts
const loadNotificationsSuccess = (state, action) => {
  const payload = action.payload.notifications;
  const allNotificationIDs = [];
  const notificationsByID = {};

  payload.forEach((alert) => {
    notificationsByID[alert.id] = alert;
    allNotificationIDs.push(alert.id);
  });

  const updatedState = {
    ...state,
    allNotificationIDs,
    notificationsByID,
  };
  return updatedState;
};

const loadNotificationsNextPage = (state, action) => {
  // handle pagination by creating an ID array and hashmap for next page of data
  // then merge the new hashmap and concat the new array with the data already in state
  const payload = action.payload.notifications;
  const notificationsIDsNextPage = state.allNotificationIDs || [];
  const notificationsNextPageByID = state.notificationsByID || {};

  payload.forEach((alert) => {
    if (!notificationsNextPageByID[alert.id]) {
      notificationsIDsNextPage.push(alert.id);
      notificationsNextPageByID[alert.id] = alert;
    }
  });
  const updatedState = {
    ...state,
    allNotificationIDs: notificationsIDsNextPage,
    notificationsByID: notificationsNextPageByID,
  };
  return updatedState;
};

const loadNotificationsFailure = (state, action) => {
  const updatedState = {
    ...state,
    notificationsError: action.payload.notificationsError,
  };
  return updatedState;
};

const isLoadingNotifications = (state, action) => {
  const updatedState = {
    ...state,
    isLoadingNotifications: action.payload.loadingNotifications,
  };
  return updatedState;
};

const loadNotificationsMetadata = (state, action) => {
  let metadata = { ...state.notificationsMetadata };
  if (!metadata) {
    metadata = {
      hasNextPage: null,
      badgeCount: 0,
    };
  }

  const { next, unread } = action.payload.metadata;
  metadata.hasNextPage = next !== undefined ? next : metadata.hasNextPage;

  if (unread !== undefined) {
    let badgeCount = 0;
    unread.forEach((index) => {
      if (index.user) {
        badgeCount += index.user;
      }
      if (index.transaction) {
        badgeCount += index.transaction;
      }
      if (index.audittrail) {
        badgeCount += index.audittrail;
      }
      if (index.team) {
        badgeCount += index.team;
      }
    });
    metadata.badgeCount = badgeCount;
  }

  const updatedState = {
    ...state,
    notificationsMetadata: {
      hasNextPage: metadata.hasNextPage,
      badgeCount: metadata.badgeCount,
    },
  };
  return updatedState;
};

const markNotificationsAsReadFailure = (state, action) => {
  const updatedState = {
    ...state,
    markNotificationsAsReadError: action.payload.markNotificationsAsReadError,
  };
  return updatedState;
};

const markNotificationsAsReadPending = (state, action) => {
  // notifications processes alerts in batches
  const { alertIDs } = action.payload;
  const { notificationsByID } = state;
  const updatedNotifications = { ...notificationsByID };
  alertIDs.forEach((id) => {
    updatedNotifications[id].read = new Date();
  });
  const updatedState = {
    ...state,
    notificationsByID: updatedNotifications,
  };
  return updatedState;
};

// All Alerts

const snoozeSuccess = (state, action) => {
  const { alertID, snoozeMessage } = action.payload;
  const upatedAllTaskIDs = state.allTaskIDs.filter(id => id !== alertID);
  const updatedTasksByID = state.tasksByID;
  delete updatedTasksByID[alertID];
  const updatedNotificationIDs = state.allNotificationIDs.filter(id => id !== alertID);
  const updatedNotificationsByID = state.notificationsByID;
  delete updatedNotificationsByID[alertID];
  const updatedState = {
    ...state,
    allTaskIDs: upatedAllTaskIDs,
    tasksByID: updatedTasksByID,
    allNotificationIDs: updatedNotificationIDs,
    notificationsByID: updatedNotificationsByID,
    snoozeMessage,
  };
  return updatedState;
};

const snoozeFailure = (state, action) => {
  const updatedState = {
    ...state,
    snoozeMessage: action.payload.error.snoozeMessage,
  };
  return updatedState;
};

const clearSnoozeMessage = (state) => {
  const updateState = {
    ...state,
    snoozeMessage: '',
  };
  return updateState;
};

const dismissSuccess = (state, action) => {
  const {
    alertID,
    dismissMessage,
  } = action.payload;
  const upatedAllTaskIDs = state.allTaskIDs.filter(id => id !== alertID);
  const updatedAllNotificationIDs = state.allNotificationIDs
    .filter(id => id !== alertID);
  const updatedTasksByID = state.tasksByID;
  const updatedAllNotificationsByID = state.notificationsByID;
  delete updatedTasksByID[alertID];
  delete updatedAllNotificationsByID[alertID];
  const updatedState = {
    ...state,
    allNotificationIDs: updatedAllNotificationIDs,
    allTaskIDs: upatedAllTaskIDs,
    tasksByID: updatedTasksByID,
    notificationsByID: updatedAllNotificationsByID,
    dismissMessage,
  };
  return updatedState;
};

const dismissFailure = (state, action) => {
  const updatedState = {
    ...state,
    dismissMessage: action.payload.error.dismissMessage,
  };
  return updatedState;
};

const clearDismissMessage = (state) => {
  const updateState = {
    ...state,
    dismissMessage: '',
  };
  return updateState;
};


const alerts = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_TASK_ALERTS_SUCCESS':
      return loadTaskAlertsSuccess(state, action);
    case 'LOAD_TASK_ALERTS_NEXT_PAGE':
      return loadTaskAlertsNextPage(state, action);
    case 'LOAD_TASK_ALERTS_FAILURE':
      return loadTaskAlertsFailure(state, action);
    case 'IS_LOADING_TASK_ALERTS':
      return isLoadingTaskAlerts(state, action);
    case 'LOAD_TASK_ALERTS_METADATA':
      return loadTaskAlertsMetadata(state, action);
    case 'MARK_TASKS_AS_READ_FAILURE':
      return markTasksAsReadFailure(state, action);
    case 'MARK_TASKS_AS_READ_PENDING':
      return markTasksAsReadPending(state, action);
    case 'LOAD_NOTIFICATIONS_SUCCESS':
      return loadNotificationsSuccess(state, action);
    case 'LOAD_NOTIFICATIONS_NEXT_PAGE':
      return loadNotificationsNextPage(state, action);
    case 'LOAD_NOTIFICATIONS_FAILURE':
      return loadNotificationsFailure(state, action);
    case 'IS_LOADING_NOTIFICATIONS':
      return isLoadingNotifications(state, action);
    case 'LOAD_NOTIFICATIONS_METADATA':
      return loadNotificationsMetadata(state, action);
    case 'MARK_NOTIFICATIONS_AS_READ_FAILURE':
      return markNotificationsAsReadFailure(state, action);
    case 'MARK_NOTIFICATIONS_AS_READ_PENDING':
      return markNotificationsAsReadPending(state, action);
    case 'SNOOZE_SUCCESS':
      return snoozeSuccess(state, action);
    case 'SNOOZE_FAILURE':
      return snoozeFailure(state, action);
    case 'CLEAR_SNOOZE_MESSAGE':
      return clearSnoozeMessage(state, action);
    case 'DISMISS_SUCCESS':
      return dismissSuccess(state, action);
    case 'DISMISS_FAILURE':
      return dismissFailure(state, action);
    case 'CLEAR_DISMISS_MESSAGE':
      return clearDismissMessage(state, action);
    default:
      return state;
  }
};

export default alerts;
