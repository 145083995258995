import superFetch from '../utils/super-fetch';

const fetchTask = (id) => {
  const url = `/mbb/tasks/${id}`;
  const options = {
    method: 'GET',
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const fetchTasks = (params) => {
  const url = '/mbb/tasks';
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const createTask = (params) => {
  const url = '/mbb/tasks';
  const options = {
    method: 'POST',
  };
  const filteredParams = {};
  Object.keys(params).forEach((key) => {
    if (params[key] || params[key] === false) {
      filteredParams[key] = params[key];
    }
  });
  const body = JSON.stringify(filteredParams);
  return superFetch(url, options, body)
    .then(data => data.json());
};

const updateTask = (id, update) => {
  const url = `/mbb/tasks/${id}`;
  const options = {
    method: 'PATCH',
  };

  const body = JSON.stringify(update);
  return superFetch(url, options, body)
    .then(data => data.json());
};

const deleteTask = (id) => {
  const url = `/mbb/tasks/${id}`;
  const options = {
    method: 'DELETE',
  };
  return superFetch(url, options)
    .then(() => ({ id, ...options }));
};

const exportTasks = (params) => {
  const url = '/mbb/tasks/export';
  const options = {
    method: 'GET',
    params,
    responseType: 'arraybuffer',
  };
  return superFetch(url, options)
    .then(data => data.json());
};

export default {
  createTask,
  deleteTask,
  exportTasks,
  fetchTask,
  fetchTasks,
  updateTask,
};
