import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';

import NDButton from '../button/button';

class ButtonWithFlyout extends React.Component {
  state = {
    showFlyout: false,
    clickableContent: React.createRef(),
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
    document.removeEventListener('mousedown', this.listenForEvents);
  }

  openFlyout = (e) => {
    this.props.onOpen();
    if (!this.state.showFlyout) {
      e.stopPropagation();
    }
    this.setState({ showFlyout: true }, () => {
      document.addEventListener('mousedown', this.listenForEvents);
    });
  }

  closeFlyout = () => {
    this.props.onAbort();
    this.setState({ showFlyout: false }, () => {
      document.removeEventListener('mousedown', this.listenForEvents);
    });
  }

  listenForEvents = ({ clientX, target }) => {
    // scrollbars are usually around 18px in width
    // we want to also ignore scrollbar clicks
    const scrollBarX = window.innerWidth - 18;
    const isScrollbarClick = clientX >= scrollBarX;
    const clickedInFlyout = this.state.clickableContent.current
      && this.state.clickableContent.current.contains(target);
    if (!clickedInFlyout && !isScrollbarClick) {
      this.closeFlyout();
    }
    // in some cases, like for ListSort, we want to close
    // the Flyout after a sort option has been selected
    if (this.props.closeFlyoutOnClick && clickedInFlyout) {
      this.closeFlyout();
    }
  }

  render() {
    const {
      actionIcon,
      buttonIcon,
      buttonText,
      buttonVariant,
      children,
      disabled,
      iconButton,
      position,
      className,
    } = this.props;

    const { showFlyout } = this.state;

    // if there is an actionIcon, the flyout needs to have margin-left
    // that will accomodate the additional space of the actionIcon
    const hasActionIconClass = actionIcon && ' has-action-icon';
    const renderActionIcon = actionIcon && (
      <IconButton
        className="action-icon blxs-button-icon-small"
        iconName={actionIcon}
        disabled={disabled}
        onMouseDown={this.openFlyout}
        variant="basic"
      />
    );
    const renderButton = (buttonText || buttonIcon) ? (
      <NDButton
        className="action-button"
        variant={buttonVariant}
        icon={buttonIcon}
        dataE2e={this.props.dataE2e}
        iconPosition="right"
        disabled={disabled}
        onMouseDown={this.openFlyout}
      >
        {buttonText}
      </NDButton>) : undefined;
    const renderIconButton = iconButton &&
      <IconButton className={`icon-button ${disabled ? 'disabled' : ''}`} iconName={iconButton} data-e2e={this.props.dataE2e} onMouseDown={this.openFlyout} />;

    return (
      <div className={`nd-button-with-flyout ${className}`}>
        <div
          className={`button-wrapper${hasActionIconClass || ''} ${position}`}
        >
          {renderActionIcon}
          {renderButton}
          {renderIconButton}
        </div>
        {showFlyout &&
          <div ref={this.state.clickableContent} className={`nd-flyout ${position}`}>
            {children}
          </div>}
      </div>
    );
  }
}

ButtonWithFlyout.propTypes = {
  actionIcon: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  buttonVariant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  closeFlyoutOnClick: PropTypes.bool,
  onRef: PropTypes.func,
  disabled: PropTypes.bool,
  iconButton: PropTypes.string,
  onAbort: PropTypes.func,
  onOpen: PropTypes.func,
  dataE2e: PropTypes.string,
  position: PropTypes.string,
  className: PropTypes.string,
};

ButtonWithFlyout.defaultProps = {
  actionIcon: undefined,
  buttonIcon: undefined,
  buttonText: undefined,
  buttonVariant: 'action',
  closeFlyoutOnClick: false,
  disabled: false,
  iconButton: undefined,
  onRef: () => {},
  dataE2e: '',
  onAbort: () => { },
  onOpen: () => { },
  position: 'left',
  className: '',
};

export default ButtonWithFlyout;
