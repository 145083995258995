import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@cbrebuild/blocks';

const Tooltip = ({
  children,
  position,
  size,
  message,
  ...other
}) => {
  const tooltipText = (
    <div className="tooltip-text-wrapper">
      <div className={`tooltip-text ${position} ${size}`}>
        {message}
      </div>
    </div>
  );

  return (
    <div className="nd-tooltip" {...other}>
      {position === 'top' && tooltipText}
      <div className="target">{children}</div>
      {position === 'bottom' && tooltipText}
      {position === 'right' && tooltipText}
    </div>
  );
};

Tooltip.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  children: PropTypes.node,
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  position: PropTypes.oneOf([
    'top',
    'bottom',
    'right',
  ]),
};

Tooltip.defaultProps = {
  children: <Icon iconName="help" />,
  position: 'top',
  size: 'medium',
};

export default Tooltip;
