import { useMemo } from 'react';

/**
 * useVoucherValidation
 * @param {number} id the id of the voucher
 * @param {int} pageNumber the page number of the voucher flow
 * @returns {{showPageValidation: boolean}} Whether or not the page should show validation
 */
export default function useVoucherValidation(voucherId, pageNumber) {
  const showPageValidation = useMemo(() => {
    if (!voucherId) {
      return false;
    }
    const key = 'last_page_visited_store';
    const voucherLastVisitedMap = JSON.parse(localStorage.getItem(key)) || {};
    const lastPageVisited = (voucherLastVisitedMap[voucherId] || 0);
    if (pageNumber > lastPageVisited) {
      voucherLastVisitedMap[voucherId] = pageNumber;
      localStorage.setItem(key, JSON.stringify(voucherLastVisitedMap));
    }
    return lastPageVisited >= pageNumber;
  }, [voucherId, pageNumber]);

  return { showPageValidation };
}
