import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, RadioButton, RadioGroup } from '@cbrebuild/blocks';
import { useDispatch, useSelector } from 'react-redux';
import OutsideBrokerItem from './OutsideBrokerItem';
import { createCommission, deleteCommission } from '../../../redux/actions/outside-broker-commissions-action';
import AddItem from '../../../nucleus/add-item/add-item';
import SimpleConfirmationModal from '../../modals/SimpleConfirmationModal';

const selectCommissionIds = state => state.outsideBrokerCommissions.commissions.map(({ id }) => id);

const OutsideBrokers = ({
  dealId,
  voucherId,
  outside_broker_is_percent,
  showValidation,
}) => {
  const commissionIds = useSelector(selectCommissionIds);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const addCommission = () => {
    dispatch(createCommission({
      paid_by_cbre: null,
      payment_group: 2,
      transaction: dealId,
    }));
  };

  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);
  const onDeleteAll = () => {
    closeModal();
    commissionIds.forEach((id) => {
      dispatch(deleteCommission(id));
    });
  };

  const toggle = () => {
    if (commissionIds.length) {
      openModal();
    } else {
      addCommission();
    }
  };

  const onDelete = (id) => {
    dispatch(deleteCommission(id));
  };

  return (
    <div className="outside-brokers">
      <h2 className="section-title">
        Were there any Outside Brokers on this deal?
        <legend className="tertiary-legend">These are non-CBRE brokers or CBRE affiliate brokers.</legend>
      </h2>
      <RadioGroup
        selectedValue={`${commissionIds.length !== 0}`}
        className="radioVal"
        onChange={toggle}
        orientation="vertical"
      >
        <RadioButton value="false">No outside brokers</RadioButton>
        <RadioButton value="true">Yes, there were outside broker(s)</RadioButton>
      </RadioGroup>
      {commissionIds.length !== 0 && (
        <>
          <h2 className="outside-brokers-header">Outside Brokers</h2>
          {commissionIds.map((id, index) => (
            <div key={id}>
              <div className="outside-broker-title">
                <h3>Outside Broker #{index + 1}</h3>
                <IconButton
                  iconName="close-circle"
                  onClick={() => onDelete(id)}
                  variant="basic"
                />
              </div>
              <OutsideBrokerItem
                id={id}
                dealId={dealId}
                voucherId={voucherId}
                showValidation={showValidation}
                outside_broker_is_percent={outside_broker_is_percent}
              />
            </div>
          ))}
          <AddItem label="Add Outside Broker" onClick={addCommission} />
        </>
      )}
      {showModal && (
        <SimpleConfirmationModal
          showModal={showModal}
          onCancel={closeModal}
          onAccept={onDeleteAll}
        >
          This will delete all outside brokers. Are you sure?
        </SimpleConfirmationModal>
      )}
    </div>
  );
};

OutsideBrokers.propTypes = {
  dealId: PropTypes.number.isRequired,
  voucherId: PropTypes.number.isRequired,
  outside_broker_is_percent: PropTypes.bool.isRequired,
  showValidation: PropTypes.bool.isRequired,
};

export default OutsideBrokers;
