import React from 'react';
import PropTypes from 'prop-types';
import FileDownloadItem from './file-download-item';

/* global CONFIG */
const { apiUrl } = CONFIG;

const FileDownloadList = (props) => {
  const { uniqueID: uID } = props;
  const items = props.files.map(file => (
    <FileDownloadItem
      file={file}
      key={file.created}
    />
  ));

  return (
    <div className="file-download-list card">
      <div className="file-list-header">
        <span>Voucher Files</span>
        {
          props.files.length > 0 &&
          <a href={`${apiUrl}/mbb/download_all/vouchers/${uID}`}>Download All Files</a>
        }
      </div>
      {items}
    </div>
  );
};

FileDownloadList.propTypes = {
  files: PropTypes.instanceOf(Array).isRequired,
  uniqueID: PropTypes.string.isRequired,
};

export default FileDownloadList;
