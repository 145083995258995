import superFetch from '../utils/super-fetch';

const userUrl = '/mbb/proxyuser';

const fetchProxyUser = async (params) => {
  const options = {
    method: 'GET',
    params,
  };

  const data = await superFetch(`${userUrl}/details`, options);
  return data.json();
};

const addProxyUser = async (params) => {
  const options = {
    method: 'POST',
  };
  await superFetch(userUrl, options, JSON.stringify(params));
};

const removeProxyUser = async (id) => {
  const options = {
    method: 'DELETE',
  };
  await superFetch(`${userUrl}/${id}`, options);
};

const switchProxyUser = async (user) => {
  const options = {
    method: 'PUT',
  };
  const data = await superFetch(`${userUrl}/${user}`, options);
  return data.json();
};

const currentActiveProxy = async () => {
  const options = {
    method: 'GET',
  };

  const data = await superFetch(`${userUrl}`, options);
  return data.json();
};

export default {
  fetchProxyUser,
  addProxyUser,
  removeProxyUser,
  switchProxyUser,
  currentActiveProxy,
};
