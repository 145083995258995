import React from 'react';
import { InventoryCard } from '../../nucleus/cards/cards';
import AddItem from '../../nucleus/add-item/add-item';
import BannerNotice from '../../nucleus/notices/banner-notice';

function FullWidth() {
  return (
    <div className="page-container">
      <BannerNotice mainMessage="This is Banner Notice" onDismiss={() => {}} />
      <InventoryCard>
        <div className="nd-inventory-card-row">
          <h2>Card Header</h2>
          <a>Call To Action</a>
        </div>
        <div className="nd-inventory-card-row">
          <AddItem label="add item" />
        </div>
        <div className="nd-inventory-card-row">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Nulla a tempor libero. Aenean eu condimentum est. In semper a dolor quis imperdiet.
            Cras et ultrices est. Quisque vestibulum ullamcorper maximus.
            Integer maximus vehicula magna, id vulputate nunc porta ut.
            Proin convallis pretium convallis. Pellentesque habitant morbi tristique
            senectus et netus et malesuada fames ac turpis egestas. Mauris suscipit
            lacus sit amet vulputate tempor.
          </p>
        </div>
      </InventoryCard>
    </div>
  );
}

export default FullWidth;
