import { IconButton } from '@cbrebuild/blocks';
import _, { uniqueId } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import VOUCHER_ROUTES from '../../../page-templates/voucher/voucher-subroutes';

const FutureActionsReview = () => {
  const {
    deal, futureActions, voucher,
  } = useSelector(state => ({
    deal: state.deal.deal,
    futureActions: state.voucher.voucher?.future_actions?.filter(
      action => (!!((action.future_action || action.future_action_date || action.notify_when_date)))),
    voucher: state.voucher.voucher,
  }));
  const history = useHistory();
  const url = VOUCHER_ROUTES[4].routes[0].path.replace(':id', voucher.deal);
  const isDealType = args => !!_.find(args, arg => arg === deal.deal_type);

  return (
    <div className="voucher-review-group future-actions-review">
      <div className="navigation">
        <Link className="h2 link" to={url}>Future Actions</Link>
        <IconButton iconName="edit" onClick={() => { history.push(url); }} />
      </div>
      <div className="content">
        {!futureActions?.length
          ? <>There were no future actions for this deal.</>
          : (
            <table>
              <colgroup>
                <col name="future action" />
                <col name="notification" />
              </colgroup>
              <tbody>
                <tr className="borderless-row">
                  <th>Future Action</th>
                  <th>{isDealType(['lease', 'sale']) ? 'Notification Time' : 'Notification Date'}</th>
                </tr>
                {futureActions?.map(action => (
                  <tr key={uniqueId()}>
                    <td>{action.future_action || 'Select a future action'}</td>
                    {isDealType(['lease', 'sale'])
                      ? <td>{action.notify_when_date || 'Select a notification time'}</td>
                      : <td>{moment(action.future_action_date).format('ll') || 'Select a notification date'}</td>
                    }
                  </tr>
                ))}
              </tbody>
            </table>)
        }
      </div>
    </div>
  );
};

export default FutureActionsReview;
