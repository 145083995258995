import {
  FETCH_ADDITIONAL_COMMISSIONS_SUCCESS,
  UPDATE_ADDITIONAL_COMMISSIONS_IN_MODAL,
  CLEAR_FINANCIALS,
} from '../../actions/action-types';

const additionalCommissions = (state = [], action) => {
  switch (action.type) {
    case FETCH_ADDITIONAL_COMMISSIONS_SUCCESS:
      return action.payload;
    case CLEAR_FINANCIALS:
      return [];
    default:
      return state;
  }
};

const tempAdditionalCommissions = (state = [], action) => {
  switch (action.type) {
    case UPDATE_ADDITIONAL_COMMISSIONS_IN_MODAL:
      return action.payload;
    default:
      return state;
  }
};

export { additionalCommissions, tempAdditionalCommissions };
