import superFetch from '../utils/super-fetch';

const fetchBrokers = (params) => {
  const url = '/brokersearch';
  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json());
};

const fetchSharedEmails = (params) => {
  const sharedEmailUrl = '/voucheremailsearch';
  const options = {
    method: 'GET',
    params,
  };

  return superFetch(sharedEmailUrl, options)
    .then(data => data.json());
};

const fetchBroker = (id) => {
  const url = `/brokers/${id}`;
  const options = {
    method: 'GET',
  };

  return superFetch(url, options)
    .then(data => data.json());
};

export {
  fetchBrokers,
  fetchBroker,
  fetchSharedEmails,
};
