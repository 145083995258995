import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@cbrebuild/blocks';
import NumberInput from '../../../nucleus/inputs/number-input';
import CommissionsModalContainer from './commissions-modal-container';
import formatAsCurrency from '../../../utils/format-as-currency';
import Slider from '../../../nucleus/slider/slider';
import userEventService from '../../../services/user-event-service';
import FormGroup from '../../../nucleus/form-group/form-group';
import sumTotalCommissions from '../../../services/voucher/total-commissions-service';
import { PAYMENT_GROUPS } from '../../../services/voucher/commissions-service';

const CommissionAgreement = (props) => {
  const {
    deal,
    updateDeal,
    commissionsTotal,
    fetchConsiderations,
    fetchAdditionalCommissions,
    updateDealPipelineTimestamp,
    clearFinancials,
    initializeCommissionsTotal,
    fetchCommissions,
  } = props;

  /*
    estimatedCommission: estimated total commission / value seen in input box
    commissionsTotal: expected total commission / value stored in Redux
  */
  const [estimatedCommission, setEstimatedCommission] = useState(deal.estimated_commission);
  const [showCommissionsModal, setShowCommissionsModal] = useState(false);
  const commissionsNotYetFetched = useRef(true);
  const sumOtherCommissions = arr => arr.reduce((acc, cur) => (
    (cur.payment_group !== PAYMENT_GROUPS.CBRE_BROKER && cur.payment_group !== PAYMENT_GROUPS.FEE_SHARE)
      ? (acc + cur.gross_commission)
      : acc
  ), 0);

  // Set initial total commissions info for Redux
  useEffect(() => {
    let total = 0;
    let totalCommissions = 0;
    let fetchedConsiderations = [];
    let fetchedAdditionalCommissions = [];

    const params = {
      transaction: deal.id,
    };

    fetchConsiderations(deal.id)
      .then((considerations) => {
        fetchedConsiderations = considerations;
        return fetchAdditionalCommissions(deal.id);
      })
      .then((additionalCommissions) => {
        fetchedAdditionalCommissions = additionalCommissions;
        totalCommissions = sumTotalCommissions(fetchedConsiderations, fetchedAdditionalCommissions);
        return fetchCommissions(params);
      })
      .then((commissions) => {
        const allCommissions = (commissions.payload);
        const outsideBrokerCommissions = Number(sumOtherCommissions(allCommissions).toFixed(2));
        total = totalCommissions - outsideBrokerCommissions;
        // if new total is different than deal.estimated_commission then we need to update state and server
        if (total > 0 && total.toFixed(2) !== (deal.estimated_commission || 0).toFixed(2)) {
          setEstimatedCommission(total);
          updateDeal(deal.id, { estimated_commission: total.toFixed(2), modified: new Date() });
        }
        initializeCommissionsTotal(total);
        commissionsNotYetFetched.current = false;
      })
      .catch(() => console.log('unable to fetch total commissions information'));
    return () => { clearFinancials(); };
  }, [clearFinancials, deal.id, fetchAdditionalCommissions, fetchConsiderations, fetchCommissions]);

  const updateEstimatedCommissions = () => {
    const params = { estimated_commission: estimatedCommission, modified: new Date() };
    updateDeal(deal.id, params);
  };

  const updateProbability = (value) => {
    const params = { conversion_potential: value, modified: new Date() };
    updateDeal(deal.id, params);
    userEventService.trackEvent(
      {
        eventAction: 'deal_conversion_potential_changed',
        eventCategory: 'Action',
        eventValue: value,
      },
      {
        actionPrefix: 'pipeline_details',
        categoryPrefix: 'Pipeline Details ',
      },
    );
  };

  const toggleCommissionsModal = () => {
    setShowCommissionsModal(!showCommissionsModal);
  };

  return (
    <div className="financial-card commission-agreement">
      <h3>Commission Agreement</h3>
      <div className="commission-agreement-row">
        <div className="col">
          <div className="form-row-with-labels-and-hints">
            {!commissionsTotal &&
              <FormGroup
                label="Estimated Total Commissions ($)"
                size="large"
                isRequired
                hasError={!estimatedCommission}
                errorMessage="Required"
              >
                <NumberInput
                  value={estimatedCommission}
                  updateValue={setEstimatedCommission}
                  onBlur={updateEstimatedCommissions}
                  precision={2}
                />
              </FormGroup>}
            {!!commissionsTotal &&
              <div className="form-group">
                <label>Expected Total Commissions</label>
                <div className="expected-total-commissions">{formatAsCurrency(commissionsTotal)}</div>
              </div>}
          </div>
        </div>
        <div className="col">
          <div className="form-row-with-labels-and-hints">
            <FormGroup
              label="Probability"
              size="large"
              isRequired
              hasError={!deal.conversion_potential}
              errorMessage="Required"
            >
              <Slider
                formatLabel={v => `${v}%`}
                minValue={0}
                maxValue={100}
                onChange={updateProbability}
                step={5}
                value={deal.conversion_potential || 0}
              />
            </FormGroup>
          </div>
        </div>
      </div>
      {!commissionsTotal &&
        <Button
          onClick={toggleCommissionsModal}
          variant="text"
        >
          <Icon iconName="calculator" />
          Calculate Considerations
        </Button>
      }
      {!!commissionsTotal &&
        <Button
          onClick={toggleCommissionsModal}
          variant="text"
        >
          <Icon iconName="edit" />
          Edit Considerations
        </Button>
      }
      {showCommissionsModal &&
        <CommissionsModalContainer
          toggleModal={toggleCommissionsModal}
          updateDealPipelineTimestamp={updateDealPipelineTimestamp}
        />}
    </div>
  );
};

CommissionAgreement.propTypes = {
  deal: PropTypes.shape({
    conversion_potential: PropTypes.number,
    estimated_commission: PropTypes.number,
    id: PropTypes.number,
    is_mta: PropTypes.bool,
    deal_type: PropTypes.string.isRequired,
  }).isRequired,
  commissionsTotal: PropTypes.number.isRequired,
  updateDeal: PropTypes.func.isRequired,
  updateDealPipelineTimestamp: PropTypes.func.isRequired,
  initializeCommissionsTotal: PropTypes.func.isRequired,
  // dispatches
  fetchConsiderations: PropTypes.func.isRequired,
  fetchAdditionalCommissions: PropTypes.func.isRequired,
  fetchCommissions: PropTypes.func.isRequired,
  clearFinancials: PropTypes.func.isRequired,
};

export default CommissionAgreement;
