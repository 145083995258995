import React from 'react';
import PropTypes from 'prop-types';

import PropertyForm from './property-form';
import PropertyReadOnly from './property-read-only';

class PropertyCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // if property.id is typeof string then we know the id
      // is uniquely generated uuid which means its a new
      // property since property.id did not come from the server
      showForm: typeof props.property.id === 'string' || false,
    };
  }

  closeForm = () => {
    this.setState({ showForm: false });
  }

  openForm = () => {
    this.setState({ showForm: true });
  }

  render() {
    const {
      addProperty,
      deleteProperty,
      dealId,
      dealType,
      property,
      updateProperty,
      analyticProperties,
    } = this.props;

    const { showForm } = this.state;

    return (
      <section className="property-card">
        {!showForm &&
          <PropertyReadOnly
            dealType={dealType}
            key={property.id}
            property={property}
            openForm={this.openForm}
          />
        }
        {showForm &&
        <PropertyForm
          analyticProperties={analyticProperties}
          addProperty={addProperty}
          dealId={dealId}
          deleteProperty={deleteProperty}
          key={property.id}
          onClose={this.closeForm}
          property={property}
          updateProperty={updateProperty}
        />}
      </section>
    );
  }
}

PropertyCard.propTypes = {
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  addProperty: PropTypes.func.isRequired,
  deleteProperty: PropTypes.func.isRequired,
  dealId: PropTypes.number.isRequired,
  dealType: PropTypes.string.isRequired,
  property: PropTypes.shape({
    building: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      full_address: PropTypes.string,
      id: PropTypes.number,
      postal_code: PropTypes.string,
      primary_property_name: PropTypes.string,
      state_abbreviation: PropTypes.string,
    }),
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    name: PropTypes.string,
    spaces: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string,
      description: PropTypes.string,
      address_line2: PropTypes.undefined,
      id: PropTypes.null,
      floor_code: PropTypes.string,
      lease_expiration_date: PropTypes.undefined,
      total_sqft: PropTypes.number,
      owned: PropTypes.undefined,
      whole_building: PropTypes.undefined,
      transactions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.null,
      })),
    })),
    usage: PropTypes.string,
  }),
  updateProperty: PropTypes.func.isRequired,
};

PropertyCard.defaultProps = {
  property: {},
};

export default PropertyCard;
