import {
  LOAD_USER_DATA_SUCCESS,
  LOAD_USER_DATA_FAILURE,
  IS_LOADING_USER_DATA,
  UPDATE_USER_DATA_SUCCESS,
  IS_UPDATING_USER_DATA,
  UPDATE_USER_DATA_FAILURE,
} from '../actions/action-types';

const initialState = {};

const loadUserDataSuccess = (state, action) => {
  const updatedState = {
    ...state,
    data: action.payload.userData,
  };
  return updatedState;
};

const loadUserDataFailure = (state, action) => {
  const updatedState = {
    ...state,
    error: action.payload.error,
  };
  return updatedState;
};

const isLoadingUserData = (state, action) => {
  const updatedState = {
    ...state,
    isLoadingUserData: action.payload.isLoadingUserData,
  };
  return updatedState;
};

const isUpdatingUserData = (state, action) => {
  const updatedState = {
    ...state,
    isUpdatingUserData: action.payload.isUpdatingUserData,
  };
  return updatedState;
};

const updateUserDataSuccess = (state, action) => {
  const updatedState = {
    ...state,
    data: action.payload.userData,
  };
  return updatedState;
};

const updateUserDataFailure = (state, action) => {
  const updatedState = {
    ...state,
    error: action.payload.error,
  };
  return updatedState;
};

const userData = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_DATA_SUCCESS:
      return loadUserDataSuccess(state, action);
    case LOAD_USER_DATA_FAILURE:
      return loadUserDataFailure(state, action);
    case IS_LOADING_USER_DATA:
      return isLoadingUserData(state, action);
    case IS_UPDATING_USER_DATA:
      return isUpdatingUserData(state, action);
    case UPDATE_USER_DATA_SUCCESS:
      return updateUserDataSuccess(state, action);
    case UPDATE_USER_DATA_FAILURE:
      return updateUserDataFailure(state, action);
    default:
      return state;
  }
};

export default userData;
