const getDealProperty = (deal) => {
  const {
    deal_life_cycle_stage,
    rep_role,
    primary_occupied_property: prospective,
    primary_prior_property: current,
  } = deal;
  const empty = { building: {} };
  let property;
  if (deal_life_cycle_stage === 'closed') {
    property = prospective;
    if (property) property.field_type = 'primary_occupied_property';
  } else if (rep_role === 'tenant') {
    if (deal_life_cycle_stage === 'executing') {
      property = prospective;
      if (property) property.field_type = 'primary_occupied_property';
    } else {
      property = current;
      if (property) property.field_type = 'primary_prior_property';
    }
  } else {
    // not closed, not tenant
    switch (rep_role) {
      case 'landlord':
      case 'buyer':
        property = prospective;
        if (property) property.field_type = 'primary_occupied_property';
        break;
      case 'seller':
      case 'consulting':
      case 'other':
        property = current;
        if (property) property.field_type = 'primary_prior_property';
        break;
      default:
        property = empty;
    }
  }
  return property || empty;
};

export default getDealProperty;
