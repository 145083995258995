import React from 'react';
import filter from 'lodash/filter';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';
import { sortBrokers, handleEmailClick, handlePhoneClick } from '../../utils/transaction-utils';

import Avatar from '../../nucleus/avatar/avatar';

class VoucherDetailProducers extends React.Component {
  renderSortedBrokers = () => {
    const { brokers } = this.props;
    const sortedBrokers = brokers.length && sortBrokers(brokers);
    const emptyState = <div className="empty-content"><span>No Producers</span></div>;

    return brokers && !brokers.length
      ? emptyState
      : sortedBrokers.map((broker) => {
        if (broker.broker) {
          return this.renderBroker(broker);
        }
        return false;
      });
  };

  renderBroker = (broker) => {
    const {
      id,
      avatar,
      first_name,
      last_name,
      phone,
      email,
    } = broker.broker;

    const emptyItem = '--';
    const brokerFullName = (!first_name && !last_name)
      ? emptyItem : `${first_name} ${last_name}`;
    const brokerIsLead = broker.broker_is_lead && 'Deal Lead';
    const renderPhoneNumber = () => {
      // It creates an array with values from phone object
      // and filter only truthy values (remove falsy values from the array)
      // and use the first number for display.
      const phoneNumbers = filter(values(phone), number => number);
      return (phoneNumbers.length ?
        <span role="button" tabIndex={0} className="link" onClick={() => handlePhoneClick(phoneNumbers[0])}>
          <span>{phoneNumbers[0]}</span>
        </span> :
        emptyItem);
    };

    const emails = filter(values(email), address => address);
    const brokerEmail = (emails.length > 0) && emails[0];
    const renderEmailIcon = () => (brokerEmail ?
      <IconButton tabIndex={0} iconName="envelope" className="link" onClick={() => { handleEmailClick(brokerEmail); }} /> :
      emptyItem);

    return (
      <div key={id} className="broker-wrapper">
        <div className="broker-avatar">
          <Avatar
            image={avatar}
            firstName={first_name}
            lastName={last_name}
            sizing="lg"
          />
        </div>
        <div className="broker-name-email">
          <span className="broker-name link">{brokerFullName}</span>
          <span className="broker-is-lead"> {brokerIsLead}</span>
          <span className="broker-email">{(brokerEmail || emptyItem)}</span>
        </div>
        <div className="broker-phone">
          {renderPhoneNumber() || emptyItem}
        </div>
        <div className="broker-email-icon">
          {renderEmailIcon()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="panel panel-medium" id="producers-panel">
        <div className="panel-header">
          Deal Producers
        </div>
        <div className="panel-content">
          {this.renderSortedBrokers()}
        </div>
      </div>
    );
  }
}

VoucherDetailProducers.propTypes = {
  brokers: PropTypes.arrayOf(PropTypes.object),
};

VoucherDetailProducers.defaultProps = {
  brokers: [],
};

export default VoucherDetailProducers;
