import React, { Component } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { isEmpty } from 'lodash';

import {
  PAYMENT_GROUPS,
  BROKER_TYPES,
  getSortedBrokerCommissions,
} from '../../../services/voucher/commissions-service';
import CommissionAllocationItem from './commission-allocation-item';
import AddItem from '../../../nucleus/add-item/add-item';
import ButtonWithFlyout from '../../../nucleus/button-with-flyout/button-with-flyout';
import NDButton from '../../../nucleus/button/button';
import Modal from '../../../nucleus/modal/modal';
import RowItem from '../../../nucleus/row-item/row-item';
import { Person, PersonType } from '../../person';
// import DealIQLogo from '../../../assets/images/svg/deal-iq-logo-only.svg';
import DealIQLogo from '../../../assets/images/svg/loading-icon.svg';

class CommissionAllocations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      teamToFill: undefined,
      processingDeletions: false,
      loadingDefaults: false,
    };
  }
  componentDidMount() {
    this.props.fetchCommissions(this.getParams())
      .then((res) => {
        if (!res.payload.length) {
          // add one broker row if automatically, if there is no results.
          this.addBroker(true);
        }
      })
      .catch(err => console.error(err));
  }

  // returns generic params that can be used to call commission APIs
  getParams = () => {
    const params = {
      transaction: this.props.deal.id,
      payment_group: PAYMENT_GROUPS.CBRE_BROKER,
      broker_is_lead: false, // this ensures that ordering on the excel export
    };
    return params;
  }

  addBroker = (setLead) => {
    const params = this.getParams();
    params.cbre_broker_type = BROKER_TYPES.EMPLOYEE;
    params.broker_is_lead = setLead;
    this.props.createCommission(params);
  };

  handleDeleteCommission = commission => this.props.deleteCommission(commission.id)
    .then(() => {
      if (commission.broker_is_lead) {
        this.setDefaultLeadBroker();
      }
      return commission;
    });

  setDefaultLeadBroker = () => {
    // sorts all commissions by cbre_broker_type employee, office then pool and sets the first one as lead
    // first filters out outside brokers that have no cbre_broker_type
    const [firstCommission] = this.props.commissions.filter(a => a.cbre_broker_type).sort((a, b) => (a.cbre_broker_type < b.cbre_broker_type ? -1 : 1));
    if (firstCommission) {
      this.props.updateCommission(firstCommission.id, {
        broker_is_lead: true,
      });
    }
  };

  addOffice = () => {
    const params = this.getParams();
    params.cbre_broker_type = BROKER_TYPES.OFFICE;
    this.props.createCommission(params);
  };

  addPoolAccount = () => {
    const params = this.getParams();
    params.cbre_broker_type = BROKER_TYPES.POOL;
    this.props.createCommission(params);
  };

  toggleSign = () => {
    const {
      updateVoucher,
      voucher,
    } = this.props;

    updateVoucher(voucher.id, {
      cbre_commission_is_percent: !voucher.cbre_commission_is_percent,
    })
      .then(() => this.props.updateDealPipelineTimestamp());
  }

  resetLeadProducer = (id) => {
    // current lead to false
    const currentLead = find(
      this.props.commissions,
      commission => commission.broker_is_lead === true,
    );

    if (currentLead) {
      this.props.updateCommission(currentLead.id, {
        broker_is_lead: false,
      });
    }

    // set new lead
    this.props.updateCommission(id, {
      broker_is_lead: true,
    })
      .then(() => {
        this.props.updateDealPipelineTimestamp();
      });
  }

  runOpenModalDefaults = (teamData) => {
    this.setState({
      showModal: true,
      teamToFill: teamData,
    });
  };

  loadTeamAllocations = async (teamToFill) => {
    Promise.all(teamToFill.team.users.map(async (teamMember, index) => {
      const calculatedGrossCommission = Number.parseFloat(this.props.baseTotal * (teamMember.default_splits_percentage ?
        (teamMember.default_splits_percentage / 100) :
        0))
        .toFixed(2);
      const params = {
        transaction: this.props.deal.id,
        payment_group: PAYMENT_GROUPS.CBRE_BROKER,
        broker_is_lead: index === 0,
        broker: teamMember.broker_id,
        cbre_broker_type: BROKER_TYPES.EMPLOYEE,
        cbre_commission_is_percent: true,
        gross_commission: calculatedGrossCommission,
        voucher_gross_commission_percent: teamMember.default_splits_percentage || 0,
      };
      if (teamMember.is_added_to_pipeline) {
        await Promise.resolve(await this.props.createCommission(params));
      }
    })).then(() => this.setState({
      loadingDefaults: false,
    }));
  }

  deleteAllCommissions = async () => Promise.all(this.props.commissions.map(commission =>
    (Promise.resolve(this.props.deleteCommission((commission.id))).then(() => this.setState({
      processingDeletions: false,
    })))));

  runFillDefaults = async (teamToFill) => {
    this.setState({
      showModal: false,
      processingDeletions: true,
      loadingDefaults: true,
    });
    await this.deleteAllCommissions();
    await this.loadTeamAllocations(teamToFill).then(this.setState({
      teamToFill: undefined,
    }));
  };

  closeConfirmationModal = () => {
    this.setState({
      showModal: false,
      teamToFill: undefined,
    });
  };

  renderConfirmationModal = () => {
    const {
      showModal,
      teamToFill,
    } = this.state;
    return (showModal && teamToFill &&
    <Modal
      className="remove-team-member-modal"
      modalHeader={`Fill defaults from "${teamToFill.team?.name}"?`}
      primaryButtonText="Load Defaults"
      handleModalSubmit={() => this.runFillDefaults(teamToFill)}
      secondaryButtonText="Cancel"
      handleSecondaryButton={this.closeConfirmationModal}
      handleModalToggle={this.closeConfirmationModal}
      modalWidth={650}
      showModal
    >
      <div className="confirmation-modal-header">
        <div>Loading default allocations for this team will <span className="delete-warning">DELETE</span> all current broker allocation information
          and replace it with the following values.
          <div>Please review the following data, and that you want these commission percentages in this deal.</div>
          <div>If the &quot;Add to pipeline&quot; checkbox is unselected for a user in the Teams page, that user will not be added.</div>
          <div className="confirmation-line">
            <b>Are you sure you want to continue?</b>
          </div>
        </div>
        {teamToFill?.team.users.map(teamMember => (
          <RowItem key={`default-info-${teamMember.id}`}>
            <div className={!teamMember.is_added_to_pipeline ? 'unselected-for-pipeline' : ''}>
              <Person
                person={teamMember}
                type={PersonType.MEMBER}
                hoverAlign="left"
                note={teamMember.is_added_to_pipeline ? `Default Split: 
            ${teamMember.default_splits_percentage || '0'}%` :
            "Won't be added (Unselected)"
          }
              />
            </div>
          </RowItem>))}
      </div>
    </Modal>);
  }

  render() {
    const {
      commissions,
      updateCommission,
      baseTotal,
      voucher,
      updateDealPipelineTimestamp,
    } = this.props;
    const sortedCommissions = getSortedBrokerCommissions(commissions);

    const rendeBrokerCommissionAllocationItems = (this.state.loadingDefaults || this.state.processingDeletions) ? (
      <div>
        <div className="spinner-container">
          <div className="loading-spinner">
            <img
              alt="Loading"
              src={DealIQLogo}
            />
          </div>
        </div>
      </div>) : (sortedCommissions.cbreBrokerCommissions.map(commission => (
        <CommissionAllocationItem
          key={commission.id}
          commission={commission}
          brokerType={BROKER_TYPES.EMPLOYEE}
          updateCommission={updateCommission}
          baseTotal={baseTotal}
          toggleSign={this.toggleSign}
          isCommissionPercent={voucher.cbre_commission_is_percent}
          deleteCommission={() => this.handleDeleteCommission(commission)}
          setLeadProducer={this.resetLeadProducer}
          deal={this.props.deal}
          updateDealPipelineTimestamp={updateDealPipelineTimestamp}
        >
          <div>{commission.id}</div>
        </CommissionAllocationItem>)));

    const shouldSetLeadBroker = !commissions.find(({ broker_is_lead, cbre_broker_type }) => cbre_broker_type && broker_is_lead);

    const rendeOfficeCommissionAllocationItems =
      sortedCommissions.officeCommissions.map(commission => (
        <CommissionAllocationItem
          key={commission.id}
          commission={commission}
          brokerType={BROKER_TYPES.OFFICE}
          updateCommission={updateCommission}
          baseTotal={baseTotal}
          toggleSign={this.toggleSign}
          isCommissionPercent={voucher.cbre_commission_is_percent}
          deleteCommission={() => this.handleDeleteCommission(commission)}
          setLeadProducer={this.resetLeadProducer}
          updateDealPipelineTimestamp={updateDealPipelineTimestamp}
        >
          <div>{commission.id}</div>
        </CommissionAllocationItem>));

    const rendePoolCommissionAllocationItems =
      sortedCommissions.poolCommissions.map(commission => (
        <CommissionAllocationItem
          key={commission.id}
          commission={commission}
          brokerType={BROKER_TYPES.POOL}
          updateCommission={updateCommission}
          baseTotal={baseTotal}
          toggleSign={this.toggleSign}
          isCommissionPercent={voucher.cbre_commission_is_percent}
          deleteCommission={() => this.handleDeleteCommission(commission)}
          setLeadProducer={this.resetLeadProducer}
          updateDealPipelineTimestamp={updateDealPipelineTimestamp}
        >
          <div>{commission.id}</div>
        </CommissionAllocationItem>));

    const renderTeamDefaults = () => (this.props.teamMembers && this.props.teamMembers.dealTeams && this.props.teamMembers.dealTeams.map(teamData => (
      <li key={`${teamData.team.name}-button`}>
        <NDButton
          variant="option"
          width="full"
          icon="group"
          onMouseDown={async () => this.runOpenModalDefaults(teamData)}
        >
          {`${teamData.team.name} Defaults`}
        </NDButton>
      </li>
    )));

    return (
      <div className="financial-card">
        <span className="allocations-title-and-button">
          <h3>Commission Allocations </h3> &nbsp;
          {this.props.teamMembers && (
          <ButtonWithFlyout
            buttonText="Load Default Allocations"
            buttonIcon="chevron-down"
            closeFlyoutOnClick
            className="default-splits-dropdown"
            disabled={isEmpty(this.props.teamMembers.dealTeams)}
          >
            <ul className="defaults-list">
              {renderTeamDefaults()}
            </ul>
          </ButtonWithFlyout>)}
        </span>
        {this.renderConfirmationModal()}
        <div className="allocation-type-group">
          {rendeBrokerCommissionAllocationItems}
        </div>
        <div className="single-cta-row">
          <AddItem label="Broker" onClick={() => this.addBroker(shouldSetLeadBroker)} />
        </div>
        <div className="allocation-type-group">
          {rendeOfficeCommissionAllocationItems}
        </div>
        <div className="single-cta-row">
          <AddItem label="Office" onClick={this.addOffice} />
        </div>
        <div className="allocation-type-group">
          {rendePoolCommissionAllocationItems}
        </div>
        <div className="single-cta-row">
          <AddItem label="Pool Account" onClick={this.addPoolAccount} />
        </div>
      </div>
    );
  }
}

CommissionAllocations.propTypes = ({
  // from store state
  deal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    deal_type: PropTypes.string.isRequired,
  }).isRequired,
  voucher: PropTypes.shape({
    id: PropTypes.number,
    cbre_commission_is_percent: PropTypes.bool,
  }),
  commissions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseTotal: PropTypes.number.isRequired,
  // from action dispatches
  fetchCommissions: PropTypes.func.isRequired,
  updateCommission: PropTypes.func.isRequired,
  createCommission: PropTypes.func.isRequired,
  deleteCommission: PropTypes.func.isRequired,
  updateVoucher: PropTypes.func.isRequired,
  updateDealPipelineTimestamp: PropTypes.func.isRequired,
  teamMembers: PropTypes.shape().isRequired,
});

CommissionAllocations.defaultProps = {
  voucher: {
    cbre_commission_is_percent: true,
  },
};

export default CommissionAllocations;
