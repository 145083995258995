/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import RouteWithSubRoutes from '../../utils/voucher-route-utils';
import TabNav from '../../nucleus/tabnav/tabnav';
import BottomNav from './BottomNav';
import FinancialBreakdown from './FinancialBreakdown/FinancialBreakdown';
import VoucherComments from './VoucherComments';

const ReviewSend = ({
  location,
  match: {
    params: {
      id, subtab,
    },
  },
  routes,
}) => {
  const isVoucherValid = JSON.parse(localStorage.getItem('voucher_valid'));
  const tabs = useMemo(() => [
    {
      link: `/voucher/${id}/review_and_send/future_actions`,
      text: 'Future Actions',
    },
    {
      link: `/voucher/${id}/review_and_send/review`,
      text: 'Review',
    },
    {
      link: `/voucher/${id}/review_and_send/send`,
      text: 'Send',
      disabled: !isVoucherValid,
    },
  ], [id, isVoucherValid]);
  return (
    <React.Fragment>
      <div className="voucher-content">
        <TabNav tabs={tabs} selectedTab={location.pathname} />
        {!subtab && <Redirect to={tabs[0].link} />}
        <Switch>
          {routes && routes.map(route => (
            <RouteWithSubRoutes key={route.path} {...route} />
          ))}
        </Switch>
        <BottomNav location={location.pathname} />
      </div>
      {location.pathname !== tabs[0].link &&
        <div>
          <FinancialBreakdown />
          <VoucherComments />
        </div>
      }
    </React.Fragment>
  );
};


export default ReviewSend;
