import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import ReduxRoot from './redux-root';

import { loadUserData } from './redux/actions/user-service-actions';
import { loadUserOptions } from './redux/actions/user-options-actions';

import App from './app';

const mapStateToProps = state => ({
  user: state.userData.data && state.userData.data.id ? state.userData.data : null,
  userOptions: state.userOptions.userOptions || null,
});

const mapDispatchToProps = ({
  loadUserData,
  loadUserOptions,
});

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

ReactDOM.render(
  <ReduxRoot>
    <AppContainer />
  </ReduxRoot>,
  document.getElementById('app-root'),
);
