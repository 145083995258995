import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import PageHeaderDefault from '../../nucleus/header/page-header-default';
import CommissionsWidget from '../../components/widgets/commissions-widget';
import PaymentsAndInstallmentsWidget from '../../components/widgets/payments-and-installments-widget';
import DealsWidget from '../../components/widgets/deals-widget';
import TasksWidget from '../../components/widgets/tasks-widget';
import BannerNotice from '../../nucleus/notices/banner-notice';

const DashboardPage = ({
  user, toast, updateUserOptions, userOptions, featureFlags,
}) => {
  useMemo(() => {
    document.title = 'Deal IQ | Dashboard';
  }, []);

  return (
    <React.Fragment>
      <PageHeaderDefault title="Dashboard" />
      <div className="page-container">
        {featureFlags.teams_tab_flag && !userOptions.teams_setup_banner_disabled &&
        <div>
          <BannerNotice
            iconClassName="group"
            mainMessage="Teams:"
            secondaryMessage="Create a team(s) to automatically share Deals, Notes, and Tasks with your team members!"
            controlButtonText="Configure Teams"
            onControlButtonClick={() => { (window.location.href = '/profile/teams'); }}
            onDismiss={() => { updateUserOptions({ teams_setup_banner_disabled: true }); }}
          />
        </div>}
        <div className="col-container">
          <div className="col flex-1">
            <CommissionsWidget user={user} />
            <DealsWidget />
          </div>
          <div className="col flex-1">
            <PaymentsAndInstallmentsWidget user={user} featureFlags={featureFlags} />
            <TasksWidget user={user} toast={toast} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardPage;

DashboardPage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  toast: PropTypes.func.isRequired,
  updateUserOptions: PropTypes.func.isRequired,
  userOptions: PropTypes.shape(),
  featureFlags: PropTypes.shape(),
};

DashboardPage.defaultProps = {
  user: {
    id: null,
  },
  userOptions: {},
  featureFlags: {},
};

