import { connect } from 'react-redux';

import {
  updateAdditionalCommissionsInModal,
  fetchAdditionalCommissionTypeOptions,
} from '../../../redux/actions/voucher/additional-commissions-actions';

import AdditionalCommissions from './additional-commissions';

const mapStateToProps = state => ({
  additionalCommissions: state.additionalCommissions,
  tempAdditionalCommissions: state.tempAdditionalCommissions,
  additionalCommissionTypeOptions: state.voucher.options.additionalCommissionTypeOptions,
});

const mapDispatchToProps = {
  updateAdditionalCommissionsInModal,
  fetchAdditionalCommissionTypeOptions,
};

const AdditionalCommissionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdditionalCommissions);

export default AdditionalCommissionsContainer;
