import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import FilterTag from '../../nucleus/legacy/tag/filter-tag';

const CommissionsFilterTags = (props) => {
  const {
    currentParams,
    applyParams,
    brokers: allBrokers,
    teams: allTeams,
    users: allUsers,
  } = props;

  const {
    payment_status = [],
    min_amount,
    max_amount,
    min_gross,
    max_gross,
    min_net,
    max_net,
    start_date,
    end_date,
    due_start_date,
    due_end_date,
    paid_start_date,
    paid_end_date,
    brokers = [],
    teams = [],
    users = [],
  } = currentParams;

  // so zero vals still show as filter tags
  function translateAmountValue(val) {
    return typeof val === 'number' ? String(val) : null;
  }

  const teamMap = useMemo(() => allTeams.reduce((acc, team) => {
    acc[team.id] = team;
    return acc;
  }, {}), [allTeams]);

  const userMap = useMemo(() => allUsers.reduce((acc, producer) => {
    acc[producer.id] = producer;
    return acc;
  }, {}), [allUsers]);
  const brokerMap = useMemo(() => allBrokers.reduce((acc, producer) => {
    acc[producer.id] = producer;
    return acc;
  }, {}), [allBrokers]);
  const potentialFilters = [
    ...payment_status.map((status, index) => ({
      key: index,
      value: status,
      type: 'payment',
    })),
    {
      key: 'amountRange',
      label: 'Amount',
      minValue: translateAmountValue(min_amount),
      maxValue: translateAmountValue(max_amount),
    },
    {
      key: 'grossRange',
      label: 'Gross',
      minValue: translateAmountValue(min_gross),
      maxValue: translateAmountValue(max_gross),
    },
    {
      key: 'netRange',
      label: 'Net',
      minValue: translateAmountValue(min_net),
      maxValue: translateAmountValue(max_net),
    },
    {
      key: 'dateRange',
      label: 'Date',
      minValue: start_date,
      maxValue: end_date,
    },
    {
      key: 'dueDateRange',
      label: 'Date',
      minValue: due_start_date,
      maxValue: due_end_date,
    },
    {
      key: 'paidDateRange',
      label: 'Date',
      minValue: paid_start_date,
      maxValue: paid_end_date,
    },
    teams && teams.filter(id => teamMap[id]).map(id => ({
      key: id,
      value: teamMap[id]?.name || '',
      type: 'team',
    })),
    ...users.filter(id => userMap[id]).map(id => ({
      key: id,
      value: `${userMap[id]?.first_name || ''} ${userMap[id]?.last_name || ''}`,
      type: 'user',
    })),
    ...brokers.filter(id => brokerMap[id]).map(id => ({
      key: id,
      value: `${brokerMap[id]?.first_name || ''} ${brokerMap[id]?.last_name || ''}`,
      type: 'broker',
    })),
  ];
  const appliedFilters = potentialFilters.filter(f => f?.value || f?.minValue || f?.maxValue);

  const handleClearFilter = (key, type) => {
    switch (type || key) {
      case 'payment': {
        const params = { payment_status: payment_status.filter(payment => payment !== payment_status[key]) };
        applyParams(params);
        break;
      }
      case 'amountRange': {
        const params = {
          min_amount: null,
          max_amount: null,
        };
        applyParams(params);
        break;
      }
      case 'grossRange': {
        const params = {
          min_gross: null,
          max_gross: null,
        };
        applyParams(params);
        break;
      }
      case 'netRange': {
        const params = {
          min_net: null,
          max_net: null,
        };
        applyParams(params);
        break;
      }
      case 'dateRange': {
        const params = {
          start_date: null,
          end_date: null,
        };
        applyParams(params);
        break;
      }
      case 'dueDateRange': {
        const params = {
          due_start_date: null,
          due_end_date: null,
        };
        applyParams(params);
        break;
      }
      case 'paidDateRange': {
        const params = {
          paid_start_date: null,
          paid_end_date: null,
        };
        applyParams(params);
        break;
      }
      case 'team': {
        const params = { teams: teams.filter(team => team !== key) };
        applyParams(params);
        break;
      }
      case 'user': {
        const params = { users: users.filter(user => user !== key) };
        applyParams(params);
        break;
      }
      case 'broker': {
        const params = { brokers: brokers.filter(broker => broker !== key) };
        applyParams(params);
        break;
      }
      default:
    }
  };

  const clearAllFilters = () => {
    const keysToClear = [
      'payment_status',
      'min_amount',
      'max_amount',
      'min_gross',
      'max_gross',
      'min_net',
      'max_net',
      'start_date',
      'end_date',
      'due_start_date',
      'due_end_date',
      'paid_start_date',
      'paid_end_date',
      'teams',
      'brokers',
    ];
    const keys = Object.keys(currentParams);
    keys.map((key) => {
      if (key && ['users', 'brokers', 'payment_status'].includes(key)) {
        currentParams[key] = [];
      } else if (keysToClear.includes(key)) {
        currentParams[key] = null;
      }
      return key;
    });

    applyParams(currentParams);
  };

  return appliedFilters.length ? (
    <div className="filter-tags-container">
      {appliedFilters.map(filter => (
        <FilterTag
          key={filter.key}
          value={filter.value}
          label={filter.label}
          minValue={filter.minValue}
          maxValue={filter.maxValue}
          handleClearFilter={() => handleClearFilter(filter.key, filter.type)}
        />
      ))}
      {appliedFilters.length > 1 && (
        <span
          className="link-text"
          onClick={clearAllFilters}
        >
          Clear All
        </span>
      )}
    </div>
  ) : null;
};

export default CommissionsFilterTags;

CommissionsFilterTags.propTypes = {
  currentParams: PropTypes.shape({
    payment_status: PropTypes.arrayOf(PropTypes.string),
    min_amount: PropTypes.number,
    max_amount: PropTypes.number,
    min_gross: PropTypes.number,
    max_gross: PropTypes.number,
    min_net: PropTypes.number,
    max_net: PropTypes.number,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    due_start_date: PropTypes.string,
    due_end_date: PropTypes.string,
    paid_start_date: PropTypes.string,
    paid_end_date: PropTypes.string,
    teams: PropTypes.arrayOf(PropTypes.number),
    users: PropTypes.arrayOf(PropTypes.number),
    brokers: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  applyParams: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  brokers: PropTypes.arrayOf(PropTypes.shape({})),
};

CommissionsFilterTags.defaultProps = {
  teams: [],
  users: [],
  brokers: [],
};
