import React from 'react';
import PropTypes from 'prop-types';

import TeamAvatar from './team-avatar';
import Avatar from '../../nucleus/avatar/avatar';

class TeamIndicator extends React.Component {
  getTeamAvatarType = () => {
    if (this.props.avatarBreakPoint + 1 >= this.props.teamMembers.length) {
      return 'expandAll';
    } else if (this.props.avatarBreakPoint >= 2) {
      return 'expand';
    }
    return 'card';
  };

  render() {
    const {
      teamName,
      teamColor,
      teamMembers,
      avatarBreakPoint,
      commissionedUserIds,
      showTeamMembersModal,
      allowDealTeamModal,
    } = this.props;

    let isAtLimit = false;
    const avatarOnClick = allowDealTeamModal ? showTeamMembersModal : () => {};

    const avatarsToRender = this.getTeamAvatarType() === 'expandAll' ? teamMembers.length : avatarBreakPoint;

    const hiddenCount = teamMembers.length - avatarsToRender;
    let additionalAvatars;
    if (hiddenCount > 0) {
      isAtLimit = true;
      additionalAvatars = `+${hiddenCount}`;
    }

    const extraMembers = isAtLimit ? teamMembers.slice(avatarsToRender, teamMembers.length)
      .map(teamMember => (
        <li key={teamMember.id} onClick={avatarOnClick}>{teamMember.first_name} {teamMember.last_name}</li>
      )) : '';

    const allMembers = teamMembers.map(teamMember => (
      <li key={teamMember.id} onClick={avatarOnClick}>{teamMember.first_name} {teamMember.last_name}</li>
    ));
    const showDollarIcon = member => (commissionedUserIds.includes(member.id) ? 'dollar' : null);

    let renderAvatar;
    if (isAtLimit) {
      renderAvatar = teamMembers.slice(0, avatarsToRender).map(teamMember => (
        <li key={teamMember.id} onClick={avatarOnClick}>
          <Avatar
            key={teamMember.id}
            image={teamMember.avatar}
            firstName={teamMember.first_name}
            lastName={teamMember.last_name}
            enableHover
            subIcon={showDollarIcon(teamMember)}
          />
        </li>
      ));
      const renderExtras = (
        <li className="extra-avatars" key="2" onClick={avatarOnClick}>
          {isAtLimit &&
            <ul>
              <div className="hoverstate nd-avatar md plus-n" data-initials={additionalAvatars}>
                <p className="hoverstatetext align-left">
                  {extraMembers}
                </p>
              </div>
            </ul>
          }
        </li>
      );
      renderAvatar.push(renderExtras);
    } else {
      renderAvatar = teamMembers.map(teamMember => (
        <li key={teamMember.id} onClick={avatarOnClick}>
          <Avatar
            key={teamMember.id}
            image={teamMember.avatar}
            firstName={teamMember.first_name}
            lastName={teamMember.last_name}
            enableHover
            subIcon={showDollarIcon(teamMember)}
          />
        </li>
      ));
    }

    const renderTeamAvatar = () => {
      if (this.getTeamAvatarType() === 'card') {
        return (
          <ul>
            <div className="hoverstate color-icon" onClick={avatarOnClick}>
              <TeamAvatar
                teamName={teamName}
                teamColor={teamColor}
                type="card"
              />
              <p className="hoverstatetext align-left">
                <li>{teamName}:</li>
                {allMembers}
              </p>
            </div>
          </ul>
        );
      }
      return (
        <ul>
          <div className="hoverstate color-icon" onClick={avatarOnClick}>
            <TeamAvatar
              teamName={teamName}
              teamColor={teamColor}
              type="expand"
            />
            <p className="hoverstatetext align-left">
              <li>{teamName}</li>
            </p>
          </div>
        </ul>
      );
    };

    return (
      <div className="team-indicator">
        {renderTeamAvatar()}
        {(this.getTeamAvatarType() === 'expand' || this.getTeamAvatarType() === 'expandAll') &&
          <div className="team-members">
            <ul>
              {renderAvatar}
            </ul>
          </div>}
      </div>
    );
  }
}

TeamIndicator.propTypes = {
  teamName: PropTypes.string.isRequired,
  teamColor: PropTypes.string.isRequired,
  teamMembers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  avatarBreakPoint: PropTypes.number.isRequired,
  allowDealTeamModal: PropTypes.bool,
  commissionedUserIds: PropTypes.arrayOf(PropTypes.number),
  showTeamMembersModal: PropTypes.func.isRequired,
};

TeamIndicator.defaultProps = {
  allowDealTeamModal: true,
  commissionedUserIds: [],
};

export default TeamIndicator;
