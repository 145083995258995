import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import GlobalNew from '../new-deal/global-new';
import AngularModalCenter from '../angular-modal-center/angular-modal-center';
import ToasterContainer from '../../redux/containers/toaster-container';

// import DealIQLogo from '../../assets/images/svg/deal-iq-logo-on-black.svg';
import DealIQLogo from '../../assets/images/svg/deal-iq-logo-white-on-black.svg';
import localStorageService from '../../services/local-storage-service';
import userOptionsService from '../../services/user-options-service';
import { getAttachmentsExtract } from '../../services/attachments-service';
import FileModal from '../../components/file-upload/file-modal';
import { abstractionLeaseFile } from '../../redux/actions/deal-files-actions';
import { connect } from 'react-redux';


class Sidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      angularPath: undefined,
      processingCompleted: false,
      results: [],
      showDataModal: false,
      lease_abstraction_flag: false,
    };
  }
  componentDidMount() {
    /** @todo this is required until react migration v1 is done, vouchers should be fine */
    window.addEventListener('setActiveNav', this.setAngularPath);
    /** @todo this should be removed and have deal details call the store directly */
    window.addEventListener('updateDealCounts', this.updateDealCounts);
    this.setDealCounts();

    let lease_abstraction_enabled;
    userOptionsService.fetchFeatureFlags()
      .then(({ results }) => {
        lease_abstraction_enabled = results?.find(featureFlag => featureFlag.feature_flag === 'lease_abstraction_flag')?.enabled;
        this.setState({ lease_abstraction_flag: lease_abstraction_enabled });
      })
      .catch(error => console.error('Error:', error));
  }

  /** @todo this should be removed and have deal details call the store directly */
  componentWillUnmount() {
    window.removeEventListener('updateDealCounts', this.updateDealCounts);
  }

  setDealCounts = () => {
    const { loadDealAndTransactionCount } = this.props;
    loadDealAndTransactionCount();
  };

  /** @todo this is required until react migration v1 is done, vouchers should be fine */
  setAngularPath = ({ detail }) => {
    this.setState({
      angularPath: detail,
    });
  };

  /** @todo this should be removed and have deal details call the store directly */
  updateDealCounts = () => {
    // triggered when a deal is created or updated in Angular
    this.setDealCounts();
  };

  getFileDetails = () => {
    const { abstractionLeaseFile } = this.props;
    const deal = JSON.parse(localStorage.getItem('dealTogglerData'));
    const leaseFileFromStorage = JSON.parse(localStorage.getItem(`leaseDeal_${deal?.id}`));
    return abstractionLeaseFile?.document_name ? abstractionLeaseFile : leaseFileFromStorage;
  }

  openDataModal = () => {
      const file = this.getFileDetails();
      if(file?.id) {
        getAttachmentsExtract({ deal: file.deal, file: file.id }).then((res) => {
          this.setState({ processingCompleted: res.result[0].status === 'Success' || res.result[0].status === 'Failure' });
          this.setState({ results: res.result });
        });
      }
    this.setState({ showDataModal: true });
  }

  hideDataModal = () => {
    this.setState({ showDataModal: false });
  }

  closeDataModal = () => {
    const file = this.getFileDetails();
    this.setState({ showDataModal: false });
    localStorage.removeItem(`leaseDeal_${file.deal}`);
    this.props.leaseFileUpdate({});
  }

  render() {
    const {
      dealCountProspect,
      dealCountExecuting,
      dealCountClosed,
      showSidebar,
      tabletBreakpoint,
      closeMenu,
      turducken,
      abstractionLeaseFile,
      leaseFileUpdate
    } = this.props;
    const { angularPath = '' } = this.state;
    const deal = JSON.parse(localStorage.getItem('dealTogglerData'));
    const leaseFileFromStorage = JSON.parse(localStorage.getItem(`leaseDeal_${deal?.id}`));
    const leaseFile = abstractionLeaseFile?.document_name ? abstractionLeaseFile : leaseFileFromStorage;
    const analyticProperties = {
      actionPrefix: 'side_menu_bar',
      categoryPrefix: 'SIDE MENU BAR',
    };
    /**
     * a page can have the following properties
     * angular: boolean - default: false - is this an angular page
     * color: string - default: green - the color of active nav
     * divider: boolean - default: false - is the a bottom divider
     * hidden: boolean - default: false - whether or not to hide
     * link: string - required - the route link
     * text: string - required - the text to be displayed
     */
    const pages = [
      {
        divider: true,
        link: '/dashboard',
        text: 'Dashboard',
      },
      {
        color: 'blue',
        link: '/deals/prospects',
        text: `Prospecting (${dealCountProspect})`,
      },
      {
        color: 'orange',
        link: '/deals/executing',
        text: `Executing (${dealCountExecuting})`,
      },
      {
        color: 'pale-green',
        divider: true,
        link: '/deals/closed',
        text: `Closed (${dealCountClosed})`,
      },
      {
        divider: true,
        link: '/properties',
        text: 'Properties',
      },
      {
        link: '/tasks',
        text: 'Tasks',
      },
      {
        divider: true,
        link: '/my-contacts',
        text: 'My Contacts',
      },
      {
        divider: true,
        link: '/commissions/annual_chart',
        text: 'Commissions',
      },
      {
        link: '/pipeline',
        text: 'Pipeline Report',
      },
      {
        link: '/vouchers',
        text: 'Voucher Search',
      },
      {
        divider: true,
        link: '/spoc-search',
        text: 'SPOC Search',
      },
    ];
    const dealTogglerData = localStorageService.getDealTogglerData();
    const getActiveClass = (page) => {
      // check for if navigated from pipeline report page or propsecting, executing, 
      // closed page as URL is same but active class should be different
      
      if (page.link.includes('/pipeline') && dealTogglerData?.source === 'Pipeline' && window.location.pathname.includes('pipeline')) {
        return 'active-nav green';
      } else {
        return `${(page.link.includes(`/deals/${this.props.dealStage}`) || angularPath.includes(page.link))
          && (!window.location.pathname.includes('pipeline') || dealTogglerData?.source === 'Deals')
          ? 'active-nav '
          : ''}${page.color || 'green'}`
      }
    }
    const closeMenuIfTablet = window.innerWidth < tabletBreakpoint ? closeMenu : undefined;

    return (
      <div className="sidenav-container" >
        <nav className={`sidebar-nav ${showSidebar ? 'sidebar-open' : 'sidebar-closed'}`} >
          <NavLink {...(turducken ? { target: '_top' } : {})} to="/dashboard" className="nav-logo">
            <img
              alt="Deal IQ Logo"
              src={DealIQLogo}
            />
          </NavLink>
          <AngularModalCenter />
          <GlobalNew closeMenu={closeMenu} turducken={turducken} />
          <ul className="sidebar-nav-list">
            {pages.map(page => (!page.hidden ? (
              <React.Fragment key={page.link}>
                <li>
                  <NavLink
                    // NavLink knows if its on that route or children so we don't need to check
                    activeClassName={angularPath ? '' : 'active-nav'}
                    // this classname check is here for deal detail pages
                    // redux has a deal in state when on deal details so we know the deal_life_cycle_stage
                    // it uses includes because the route doesn't match the deal_life_cycle_stage of prospect/prospects
                    className={getActiveClass(page)}
                    // this is required until react migration v1 is done, vouchers should be fine
                    onClick={closeMenuIfTablet}
                    to={page.link}
                    // if page is angular and turducken don't refresh or if not angular and not turducken don't refresh
                    // target self will refresh and get the correct environment from the server
                    {...(!!page.angular === turducken ? {} : { target: '_top' })}
                  >{page.text}
                  </NavLink>
                </li>
                {!!page.divider && <hr />}
              </React.Fragment>
            ) : null))}
            {this.state.lease_abstraction_flag && leaseFile?.document_name && (
            <li className='leaseFile'>
              <a onClick={(e) => {
                // const eventValue = `${leaseFile?.id}_${leaseFile?.title}`;
                // this.trackLeaseAbstraction(eventValue);
                this.openDataModal(e);
              }}
            >{leaseFile?.document_name}</a></li>)}
          </ul>
        </nav>
        {showSidebar && (
          <div
            className="sidebar-nav-overlay"
            onClick={closeMenu}
          />
        )}
        <ToasterContainer />
        {this.state.showDataModal && (
          <div className="modal-backdrop" onClick={this.hideDataModal} />
        )}
        {this.state.showDataModal && (
          <FileModal 
            hideDataModal={this.hideDataModal} 
            closeDataModal={this.closeDataModal} 
            results={this.state.results}
            file={leaseFile} 
            analyticProperties = {analyticProperties}
            deal={deal.id}/>
        )}
      </div >);
  }
}

Sidebar.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  dealCountProspect: PropTypes.number,
  dealCountExecuting: PropTypes.number,
  dealCountClosed: PropTypes.number,
  dealStage: PropTypes.string,
  tabletBreakpoint: PropTypes.number,
  loadDealAndTransactionCount: PropTypes.func.isRequired,
  showSidebar: PropTypes.bool,
  turducken: PropTypes.bool,
  abstractionLeaseFile: PropTypes.object,
};

Sidebar.defaultProps = {
  dealCountProspect: 0,
  dealCountExecuting: 0,
  dealCountClosed: 0,
  dealStage: '',
  tabletBreakpoint: 1024,
  showSidebar: false,
  turducken: false,
};

const mapStateToProps = (state) => {
  return { 
          abstractionLeaseFile: state.dealFiles.abstractionLeaseFile,
          }
}

const mapDispatchToProps = (dispatch) => {
  return {
      leaseFileUpdate: (file) => dispatch(abstractionLeaseFile(file))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

