import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../nucleus/modal/modal';
import Select from '../../nucleus/select/select';
import convertDealTypeRepRole from '../../utils/deal-type-rep-role';

class ChangeDealTypeModal extends React.Component {
  state = {
    updatedDealType: {},
  }

  setDealType = () => {
    this.props.handleModalSubmit(this.state.updatedDealType);
  };

  // since the selected option will send back an {}
  // with display_name and value keys, parse that obj
  // buy using the display_name and value to determine
  // dealType and repRole for the API call
  convertUpdatedDealType = (dealTypeObj) => {
    if (dealTypeObj.value === 'tenant') {
      this.setState({ updatedDealType: { deal_type: 'lease', rep_role: 'tenant' } });
    }
    if (dealTypeObj.value === 'landlord') {
      this.setState({ updatedDealType: { deal_type: 'lease', rep_role: 'landlord' } });
    }
    if (dealTypeObj.value === 'buyer') {
      this.setState({ updatedDealType: { deal_type: 'sale', rep_role: 'buyer' } });
    }
    if (dealTypeObj.value === 'seller') {
      this.setState({ updatedDealType: { deal_type: 'sale', rep_role: 'seller' } });
    }
    if (dealTypeObj.value === 'consulting') {
      this.setState({ updatedDealType: { deal_type: 'consulting', rep_role: 'consulting' } });
    }
    if (dealTypeObj.value === 'other') {
      this.setState({ updatedDealType: { deal_type: 'other', rep_role: 'other' } });
    }
  };

  render() {
    const {
      dealType,
      handleModalToggle,
      repRole,
      showModal,
    } = this.props;

    const dealTypes = [
      {
        display_name: 'Lease - Tenant',
        value: 'tenant',
      },
      {
        display_name: 'Lease - Landlord',
        value: 'landlord',
      },
      {
        display_name: 'Sale - Buyer',
        value: 'buyer',
      },
      {
        display_name: 'Sale - Seller',
        value: 'seller',
      },
      {
        display_name: 'Consulting',
        value: 'consulting',
      },
      {
        display_name: 'Other',
        value: 'other',
      },
    ];

    const defaultOption = dealType && repRole ?
      convertDealTypeRepRole(dealType, repRole) : 'Select';

    const modal = (
      <Modal
        className="change-deal-type-modal"
        showModal={showModal}
        handleModalToggle={handleModalToggle}
        handleModalSubmit={this.setDealType}
        handleSecondaryButton={handleModalToggle}
        modalHeader="Change Deal Type"
        primaryButtonText="Continue"
        secondaryButtonText="Cancel"
      >
        <div className="change-deal-type-modal-content">
          <p>
            Changing deal type will hide any information in your deal that is not applicable to the new deal type.
          </p>
          <label>Deal Type - Rep Role</label>
          <Select
            className="deal-type-select"
            defaultOption={defaultOption}
            options={dealTypes}
            onChange={e => this.convertUpdatedDealType(e)}
          />
        </div>
      </Modal>);
    return modal;
  }
}

ChangeDealTypeModal.propTypes = {
  dealType: PropTypes.string.isRequired,
  repRole: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleModalToggle: PropTypes.func.isRequired,
  handleModalSubmit: PropTypes.func.isRequired,
};

export default ChangeDealTypeModal;
