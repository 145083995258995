import { connect } from 'react-redux';

import InvestmentDetailsTab from './InvestmentDetailsTab';

const mapStateToProps = state => ({
  deal: state.deal.deal,
  voucher: state.voucher.voucher,
});

const InvestmentDetailsTabContainer = connect(
  mapStateToProps,
  null,
)(InvestmentDetailsTab);

export default InvestmentDetailsTabContainer;
