import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const BulkFailNotification = (props) => {
  const {
    variety,
    relatedObject,
    turducken,
  } = props;
  const {
    team_name: teamName,
    count,
  } = relatedObject;

  const renderTeamName = (
    <Link
      className="deal-name-link"
      to="/profile/teams"
      {...(turducken ? { target: '_self' } : {})}
    >
      { teamName }
    </Link>
  );

  const renderMessage = variety === 'bulk_add_to_deals_failed' ? (
    <React.Fragment>
      <span>Bulk addition of team {renderTeamName} to {count} deals failed. Please try again</span>
    </React.Fragment>
  ) :
    (
      <React.Fragment>
        <span>Bulk removal of team {renderTeamName} from {count} deals failed. Please try again</span>
      </React.Fragment>);
  return (
    <p>{renderMessage}</p>
  );
};

export default BulkFailNotification;

BulkFailNotification.propTypes = {
  variety: PropTypes.string.isRequired,
  relatedObject: PropTypes.shape().isRequired,
  turducken: PropTypes.bool.isRequired,
};
