import { connect } from 'react-redux';
import {
  addTask,
  completeTask,
  deleteTask,
  fetchTasks,
  updateTask,
} from '../../../redux/actions/deal-tasks-actions';
import TaskListPanel from '../tasks/task-list-panel';

const mapStateToProps = state => ({
  deal: state.deal.deal,
  isLoading: state.dealTasks.isLoading,
  count: state.dealTasks.count,
  tasks: state.dealTasks.tasks,
  next: null,
  pageSize: 5,
  user: state.userData.data,
});

const mapDispatchToProps = {
  addTask,
  completeTask,
  deleteTask,
  fetchTasks,
  updateTask,
};

const OverviewTaskListPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskListPanel);

export default OverviewTaskListPanelContainer;
