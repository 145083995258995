import { connect } from 'react-redux';
import { toast } from '../../redux/actions/toasts-actions';
import GrantProxyAccess from './grant-proxy-page';

const mapStateToProps = state => ({
  userData: (state.userData.data && state.userData.data.id) ? state.userData.data : {},
});

const mapDispatchToProps = {
  toast,
};

const GrantProxyAccessContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GrantProxyAccess);

export default GrantProxyAccessContainer;
