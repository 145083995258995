import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';

import BuildingIcon from '../../components/icons/building-icon';

const Property = (props) => {
  const {
    building,
    callToAction,
    isHeader,
    name,
  } = props;

  const {
    address,
    postal_code: postalCode,
    state_abbreviation: state,
  } = building;

  const renderCallToAction = callToAction && (
  <div className="cta">
    <IconButton className="blxs-button-icon-small" iconName="close-circle" onClick={() => callToAction()} variant="basic" />
  </div>
  );

  const isUnknown = name === 'Unknown Property';
  const propertyName = name || building.name;
  const renderAddress = isUnknown ?
    'You can add an address later when it becomes available.' : address;
  const renderCity = building.city && `${building.city} ,`;
  const getEmptyClass = string => !string && 'empty';

  return (
    <div className="property-component">
      <BuildingIcon isUnknownProperty={isUnknown} />
      <div className="property-textbox">
        {isHeader ?
          <h2 className="property-title">{name}</h2> :
          <h3 className={`property-title ${getEmptyClass(propertyName)}`}>{propertyName || 'Missing Property Name'}</h3>}
        <p className={`property-address ${getEmptyClass(renderAddress)}`}>{renderAddress || 'Missing Address'}</p>
        <p className="property-address">{renderCity} {state} {postalCode}</p>
      </div>
      {renderCallToAction}
    </div>
  );
};

Property.propTypes = {
  building: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    name: PropTypes.string,
    stateName: PropTypes.string,
    postal_code: PropTypes.string,
    state_abbreviation: PropTypes.string,
  }),
  callToAction: PropTypes.func,
  isHeader: PropTypes.bool,
  name: PropTypes.string,
};

Property.defaultProps = {
  building: {
    address: '',
    city: '',
    stateName: '',
    postal_code: '',
    state_abbreviation: '',
  },
  callToAction: null,
  isHeader: false,
  name: '',
};

export default Property;
