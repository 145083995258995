import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';
import { sortTeamMembers } from '../../utils/broker-utils';
import Modal from '../../nucleus/modal/modal';
import RowItem from '../../nucleus/row-item/row-item';
import { Person, PersonType } from '../person';
import BrokerAutocomplete from '../autocomplete/broker-autocomplete';
import Notice from '../../nucleus/notices/notice';
import TeamAutocomplete from '../autocomplete/team-autocomplete';
import TeamTile from '../teams/team-tile';

class DealTeamModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealUsers: props.relatedTransactionId ? [...props.relatedTransactionUsers] : [...props.dealUsers],
      dealTeams: props.relatedTransactionId ? [...props.relatedTransactionTeams] : [...props.dealTeams],
      pendingAdd: [],
      pendingRemove: [],
      pendingAddTeam: [],
      pendingRemoveTeam: [],
      deletingId: null,
      deleteTimer: null,
      deletingTeamId: null,
      deleteTeamTimer: null,
      pendingSave: false,
    };
  }

  componentDidUpdate() {
    if (this.state.pendingSave) {
      this.handleSaveAndClose();
    }
  }

  handleAdd = (selected) => {
    const {
      dealUsers,
      pendingRemove,
      pendingAdd,
    } = this.state;
    const {
      dealUsers: originalDealUsers,
      relatedTransactionUsers: originalCommissionUsers,
      relatedTransactionId,
    } = this.props;

    const originalTeam = relatedTransactionId ? originalCommissionUsers : originalDealUsers;

    if (selected.user_id) {
      const member = {
        locked: selected.locked || false,
        user: {
          id: selected.user_id,
          broker_search_id: selected.id,
          first_name: selected.first_name,
          last_name: selected.last_name,
          email: {
            work: selected.work_email,
          },
          avatar: selected.avatar,
        },
      };

      dealUsers.push(member);
      const found = !!(originalTeam.find(m => m.user.id === member.user.id));
      if (!found) {
        pendingAdd[member.user.id] = member;
      }
      delete pendingRemove[member.user.id];
      this.setState({
        dealUsers,
        pendingAdd,
        pendingRemove,
      });
    }
  };

  handleAddTeam = (selected) => {
    const {
      dealTeams,
      pendingRemoveTeam,
      pendingAddTeam,
    } = this.state;
    const {
      dealTeams: originalTeams,
    } = this.props;
    if (selected.id) {
      const newDealTeam = {
        team: { ...selected },
      };
      dealTeams.push(newDealTeam);
      const found = !!(originalTeams.find(t => t.team.id === newDealTeam.team.id));
      if (!found) {
        pendingAddTeam[selected.id] = newDealTeam;
      }
      delete pendingRemoveTeam[newDealTeam.team.id];
      this.setState({
        dealTeams,
        pendingAddTeam,
        pendingRemoveTeam,
      });
    }
  };

  handleRemove = (id) => {
    if (id) {
      // if previous timer is still running, clear the timer and run the function immediately.
      if (this.state.deleteTimer) {
        clearTimeout(this.state.deleteTimer);
        this.addMemberToRemove();
      }

      // set timer and deleting id
      this.setState({
        deletingId: parseInt(id, 10),
        deleteTimer: setTimeout(this.addMemberToRemove, 3000),
      });
    }
  };

  handleRemoveTeam = (id) => {
    if (id) {
      // if previous timer is still running, clear the timer and run the function immediately.
      if (this.state.deleteTeamTimer) {
        clearTimeout(this.state.deleteTeamTimer);
        this.addTeamToRemove();
      }
      // set timer and deleting id
      this.setState({
        deletingTeamId: parseInt(id, 10),
        deleteTeamTimer: setTimeout(this.addTeamToRemove, 3000),
      });
    }
  };

  handleSave = () => {
    if (this.state.deleteTimer) {
      // if timer is running, clear the timer and run the function immediately.
      clearTimeout(this.state.deleteTimer);

      this.addMemberToRemove();

      // pendingSave allows components to finish addMemberToRemove which changes some states.
      // to prevent error on changing state of unmounted(closed) component.
      // componentDidUpdate will catch the change and process the save and close properly.
      this.setState({
        pendingSave: true,
      });
    }

    if (this.state.deleteTeamTimer) {
      clearTimeout(this.state.deleteTeamTimer);

      this.addTeamToRemove();

      this.setState({
        pendingSave: true,
      });
    }
    this.handleSaveAndClose();
  };

  handleSaveAndClose = () => {
    const {
      addDealMembers,
      addCommissionMembers,
      addDealTeams,
      addCommissionTeams,
      addRecipients,
      closeModal,
      relatedTransactionId,
      deal: {
        id,
        voucher,
      },
      removeDealMembers,
      removeCommissionMembers,
      removeDealTeams,
      removeCommissionTeams,
      dealUsers,
      relatedTransactionUsers,
      dealTeams,
      relatedTransactionTeams,
    } = this.props;

    const currentTeamMembers = relatedTransactionId ? relatedTransactionUsers : dealUsers;
    const currentTeams = relatedTransactionId ? relatedTransactionTeams : dealTeams;

    const dealId = relatedTransactionId || id;
    const {
      pendingAdd,
      pendingRemove,
      pendingAddTeam,
      pendingRemoveTeam,
    } = this.state;

    // if pending member doesn't exist in the current team then we need to add it
    const membersToAdd = Object.keys(pendingAdd)
      .filter(a => !(currentTeamMembers.find(c => pendingAdd[a].user.id === c.user.id)));

    const teamsToAdd = Object.keys(pendingAddTeam)
      .filter(a => !(currentTeams.find(c => pendingAddTeam[a].team.id === c.team.id)));
    // if pending member exists in the current team then we need to remove it

    const membersToRemove = Object.keys(pendingRemove)
      .filter(r => (currentTeamMembers.find(c => c.user.id === pendingRemove[r].user.id)));

    const teamsToRemove = Object.keys(pendingRemoveTeam)
      .filter(r => (currentTeams.find(c => c.team.id === pendingRemoveTeam[r].team.id)));

    if (membersToAdd.length) {
      if (relatedTransactionId) {
        addCommissionMembers(dealId, membersToAdd);
      } else {
        addDealMembers(dealId, membersToAdd);
      }
      if (voucher) {
        const recipents = Object.keys(pendingAdd)
          .map((key) => {
            const {
              user: tempUser,
            } = pendingAdd[key];
            const user = tempUser.user || tempUser;
            const recipient = {
              employee_id: user.broker_search_id,
            };
            if (user.email && user.email.work) {
              recipient.email = user.email.work;
            } else if (user.work_email) {
              recipient.email = user.work_email;
            }
            return recipient;
          });
        addRecipients(voucher, recipents);
      }
    }
    if (teamsToAdd.length) {
      if (relatedTransactionId) {
        addCommissionTeams(dealId, teamsToAdd);
      } else {
        addDealTeams(dealId, teamsToAdd);
      }
    }

    if (membersToRemove.length) {
      if (relatedTransactionId) {
        removeCommissionMembers(membersToRemove.map(key => pendingRemove[key].id), dealId);
      } else {
        removeDealMembers(membersToRemove.map(key => pendingRemove[key].id), dealId);
      }
    }

    if (teamsToRemove.length) {
      if (relatedTransactionId) {
        removeCommissionTeams(teamsToRemove.map(key => pendingRemoveTeam[key].id), dealId);
      } else {
        removeDealTeams(teamsToRemove.map(key => pendingRemoveTeam[key].id), dealId);
      }
    }
    closeModal();
  };

  filterBrokerAutocompleteResultsPredicate = broker =>
    !(this.state.dealUsers.some(member => (broker.user_id === member.user.id)) ||
      this.props.usersToSuppress.some(memberId => (broker.user_id === memberId)));

  filterTeamAutocompleteResultsPredicate = teamResult =>
    !(this.state.dealTeams.some(team => (teamResult.id === team.team.id)) ||
      this.props.teamsToSuppress.some(teamId => (teamResult.id === teamId)));

  handleUndo = () => {
    clearTimeout(this.state.deleteTimer);
    this.setState({
      deletingId: null,
      deleteTimer: null,
    });
  };

  handleUndoTeam = () => {
    clearTimeout(this.state.deleteTeamTimer);
    this.setState({
      deletingTeamId: null,
      deleteTeamTimer: null,
    });
  };

  addMemberToRemove = () => {
    const {
      dealUsers,
      pendingAdd,
      pendingRemove,
      deletingId,
    } = this.state;

    const {
      dealUsers: originalDealUsers,
      relatedTransactionUsers: originalCommissionUsers,
      relatedTransactionId,
    } = this.props;

    const originalTeam = relatedTransactionId ? originalCommissionUsers : originalDealUsers;

    const member = dealUsers.find(m => m.user.id === deletingId);

    delete pendingAdd[member.user.id];
    const updatedDealUsers = dealUsers.filter(m => m.user.id !== deletingId);
    const found = !!(originalTeam.find(m => m.user.id === member.user.id));
    if (found) {
      pendingRemove[member.user.id] = member;
    }

    this.setState({
      deletingId: null,
      pendingRemove,
      pendingAdd,
      dealUsers: updatedDealUsers,
      deleteTimer: null,
    });
  };

  addTeamToRemove = () => {
    const {
      dealTeams,
      pendingAddTeam,
      pendingRemoveTeam,
      deletingTeamId,
    } = this.state;

    const {
      dealTeams: originalDealTeams,
      relatedTransactionTeams: originalCommissionTeams,
      relatedTransactionId,
    } = this.props;

    const originalTeams = relatedTransactionId ? originalCommissionTeams : originalDealTeams;
    const team = dealTeams.find(ts => ts.team.id === deletingTeamId);

    delete pendingAddTeam[team.team.id];
    const updatedDealTeams = dealTeams.filter(ts => ts.team.id !== deletingTeamId);
    const found = !!(originalTeams.find(ts => ts.team.id === team.team.id));

    if (found) {
      pendingRemoveTeam[team.team.id] = team;
    }
    this.setState({
      deletingTeamId: null,
      pendingRemoveTeam,
      pendingAddTeam,
      dealTeams: updatedDealTeams,
      deleteTeamTimer: null,
    });
  };

  render() {
    const {
      closeModal,
      user,
      relatedTransactionId,
      usersToSuppress,
      teamsToSuppress,
      commissionedUserIds,
      viewOnly,
    } = this.props;

    const {
      dealUsers,
      dealTeams,
      deletingId,
    } = this.state;

    const dealUsersToRender = dealUsers.filter(tm => !usersToSuppress.includes(tm.user.id));
    const dealTeamsToRender = dealTeams.filter(tm => !teamsToSuppress.includes(tm.team.id));
    const isCommissionViewModal = relatedTransactionId !== null;

    const renderMembers = () => sortTeamMembers(dealUsersToRender, user.id)
      .map((member) => {
        const {
          user: memberUser,
          locked,
        } = member;
        const userIsCommissioned = commissionedUserIds.includes(memberUser.id);
        const membersLength = deletingId ? dealUsersToRender.length - 1 : dealUsersToRender.length;
        const showRemove = isCommissionViewModal ?
          !locked && (!userIsCommissioned || !relatedTransactionId) :
          !locked && !viewOnly && membersLength > 1 && (!userIsCommissioned || !relatedTransactionId);
        const buttonRemove = (<IconButton
          iconName="close-circle"
          onClick={() => {
            this.handleRemove(memberUser.id);
          }}
          variant="basic"
        />);

        return (memberUser.id !== this.state.deletingId) ?
          (
            <RowItem key={memberUser.id}>
              <Person
                person={memberUser}
                type={relatedTransactionId ? PersonType.MEMBER_VIEW_ONLY : PersonType.MEMBER}
                commissioned={userIsCommissioned}
              />
              {showRemove && buttonRemove}
            </RowItem>
          ) :
          (
            <RowItem key={memberUser.id} additionalClassName="has-notice">
              <Notice text={`${memberUser.first_name} ${memberUser.last_name} removed.`} dismissText="undo" onDismiss={this.handleUndo} type="critical" />
            </RowItem>);
      });

    const renderTeams = () => dealTeamsToRender.map((team) => {
      const {
        locked,
      } = team;

      const buttonTeamRemove = (<IconButton
        iconName="close-circle"
        onClick={() => {
          this.handleRemoveTeam(team.team.id);
        }}
        variant="basic"
      />);
      const showRemove = !locked && !viewOnly;

      return (
        (this.state.deletingTeamId && team.team.id === this.state.deletingTeamId) ?
          (
            <RowItem key={team.team.id} additionalClassName="has-notice">
              <Notice text={`${team.team.name} removed.`} dismissText="undo" onDismiss={this.handleUndoTeam} type="critical" />
            </RowItem>)
          : (
            <RowItem key={team.team.id}>
              <TeamTile
                team={team.team}
                showHover
              />
              {showRemove && buttonTeamRemove}
            </RowItem>
          )
      );
    });

    const renderCommissionPermissionMessage = relatedTransactionId && (
      <p>Commission View allows users to see this commissions page and
        the most recently submitted voucher. They cannot see or modify any
        other deal information. People commissioned on the voucher cannot have
        Commissions View access removed
      </p>
    );

    const renderNoTeams = <p className="no-teams-text">No teams added to this {relatedTransactionId ? 'commission' : 'deal'}</p>;
    const renderNoUsers = <p className="no-users-text">No users added to this {relatedTransactionId ? 'commission' : 'deal'}</p>;

    return (
      <Modal
        className="deal-team-modal"
        primaryButtonText="Save"
        handleModalSubmit={this.handleSave}
        secondaryButtonText="Cancel"
        handleSecondaryButton={closeModal}
        handleModalToggle={closeModal}
        modalHeader={relatedTransactionId ? 'Configure Commission View' : 'Configure Collaborators'}
        showModal
        hideModalControls={viewOnly}
      >
        <div className="deal-team-modal-content">
          <React.Fragment>
            {renderCommissionPermissionMessage}
            <label>Teams</label>
            {!viewOnly &&
              <TeamAutocomplete
                onSelect={this.handleAddTeam}
                filterResultsPredicate={this.filterTeamAutocompleteResultsPredicate}
              />}
            <ul className="deal-teams">
              {dealTeamsToRender.length ? renderTeams() : renderNoTeams}
            </ul>
            <label>Individuals</label>
            {!viewOnly &&
              <BrokerAutocomplete
                placeholder="Add Deal Collaborators"
                onSelect={this.handleAdd}
                filterResultsPredicate={this.filterBrokerAutocompleteResultsPredicate}
              />}
          </React.Fragment>
          <ul className="deal-users">
            {dealUsersToRender.length ? renderMembers() : renderNoUsers}
          </ul>
        </div>
      </Modal>
    );
  }
}

DealTeamModal.propTypes = {
  addDealMembers: PropTypes.func.isRequired,
  addCommissionMembers: PropTypes.func.isRequired,
  addDealTeams: PropTypes.func.isRequired,
  addCommissionTeams: PropTypes.func.isRequired,
  addRecipients: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  deal: PropTypes.shape({
    id: PropTypes.number,
    deal_life_cycle_stage: PropTypes.string,
    voucher: PropTypes.number,
  }).isRequired,
  removeDealMembers: PropTypes.func.isRequired,
  removeCommissionMembers: PropTypes.func.isRequired,
  removeDealTeams: PropTypes.func.isRequired,
  removeCommissionTeams: PropTypes.func.isRequired,
  dealTeams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dealUsers: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
  })).isRequired,
  relatedTransactionUsers: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
  })).isRequired,
  relatedTransactionTeams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  relatedTransactionId: PropTypes.number,
  usersToSuppress: PropTypes.arrayOf(PropTypes.number),
  teamsToSuppress: PropTypes.arrayOf(PropTypes.number),
  commissionedUserIds: PropTypes.arrayOf(PropTypes.number),
  viewOnly: PropTypes.bool,
};

DealTeamModal.defaultProps = {
  relatedTransactionId: null,
  usersToSuppress: [],
  teamsToSuppress: [],
  commissionedUserIds: [],
  viewOnly: false,
};

export default DealTeamModal;
