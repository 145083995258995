import React from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

class MapBox extends React.Component {
  componentDidMount() {
    const {
      latitude,
      longitude,
    } = this.props;

    /* global CONFIG */
    const { mapBoxToken } = CONFIG;
    mapboxgl.accessToken = mapBoxToken;

    this.map = new mapboxgl.Map({
      container: this.container,
      center: [longitude, latitude],
      zoom: 13,
      style: 'mapbox://styles/cbremake/cjf1c07uv0la62snspt3do3kj',
      interactive: false,
      trackResize: true,
    });
  }

  componentWillUnmount() {
    this.map.remove();
  }

  render() {
    return (
      <div className="nd-property-map">
        <div className="map-marker" />
        <div className="map" ref={(x) => { this.container = x; }} />
      </div>
    );
  }
}

export default MapBox;

MapBox.propTypes = {
  latitude: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  longitude: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};
