import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import Avatar from '../../nucleus/avatar/avatar';

const TeamCardHistory = (props) => {
  const {
    audit_trail,
  } = props;

  const renderHistoryAction = (audit) => {
    const {
      message,
    } = audit;
    return (
      <ReactMarkdown>
        {message}
      </ReactMarkdown>);
  };

  const renderDate = (created) => {
    const today = moment(new Date());
    const daysFromCreated = today.diff(created, 'days');
    if (daysFromCreated < 11) {
      if (daysFromCreated === 0) {
        return <p>Today</p>;
      } else if (daysFromCreated === 1) {
        return <p>{daysFromCreated} day ago</p>;
      }
      return <p>{daysFromCreated} days ago</p>;
    }
    return (
      <p>{moment(created)
        .format('MM/DD/YYYY')}
      </p>
    );
  };

  return (
    <div className="team-card-history">
      {
        (audit_trail.map(audit =>
            (
              <div className="team-card-history-data" key={audit.id}>
                <div className="headers">
                  <div className="user">
                    <div className="small-data-label">USER</div>
                  </div>
                  <div className="action">
                    <div className="small-data-label">ACTION</div>
                  </div>
                  <div className="time">
                    <div className="small-data-label">DATE</div>
                  </div>
                </div>
                <div className="content">
                  <div className="user">
                    <Avatar
                      enableHover
                      firstName={audit.user.first_name}
                      hoverAlign="left"
                      image={audit.user.avatar}
                      lastName={audit.user.last_name}
                      sizing="sm"
                    />
                    <p>{audit.user.first_name} {audit.user.last_name}{audit.admin ? ' (admin)' : null}</p>
                  </div>
                  <div className="action">
                    {renderHistoryAction(audit)}
                  </div>
                  <div className="time">
                    {renderDate(audit.created)}
                  </div>
                </div>
              </div>
            ))
        )
      }
    </div>
  );
};

export default TeamCardHistory;

TeamCardHistory.propTypes = {
  audit_trail: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    category: PropTypes.string,
    information_json: PropTypes.shape(),
    message: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      avatar: PropTypes.string,
      email: PropTypes.shape({
        work: PropTypes.string,
      }),
    }),
    created: PropTypes.string,
  })).isRequired,
};
