import superFetch from '../utils/super-fetch';

const fetchSearchResults = (params) => {
  const url = '/mbb/deals';
  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json());
};

const fetchDealsAndTransactionsSearchResults = (params) => {
  const url = '/es/dealsandtransactions';
  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json());
};


export { fetchSearchResults, fetchDealsAndTransactionsSearchResults };
