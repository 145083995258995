import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, RadioButton } from '@cbrebuild/blocks';
import PercentDollarInput from '../../../nucleus/inputs/PercentDollarInput';
import BrokerAutocomplete from '../../autocomplete/broker-autocomplete';
import FormGroup from '../../../nucleus/form-group/form-group';
import Avatar from '../../../nucleus/avatar/avatar';
import useFormState, { EVENT_VALUE_TYPES } from '../../../hooks/useFormState';
import Select from '../../../nucleus/select/select';
import { fetchBroker } from '../../../services/brokers-service';
import { updateVoucher } from '../../../redux/actions/voucher/voucher-actions';
import { updateDealPipelineTimestamp } from '../../../redux/actions/deal-actions';
import { deleteCommission, updateCommission } from '../../../redux/actions/commissions-actions';
import { selectTotalCommissionsForCommissions } from '../../../redux/selectors/voucher-selectors';

const getBrokerName = ({ first_name = '', last_name = '' } = {}) => `${first_name}${first_name ? ' ' : ''}${last_name}`;

const selectCommissionById = (state, id) => (
  state.commissions.commissions.find(commission => commission.id === id)
);

const filterOptionsByDealType = (options, deal_type) => {
  switch (deal_type) {
    case 'lease':
      return options.filter(({ value }) => value.includes('Tenant') || value.includes('Landlord') || !value);
    case 'sale':
      return options.filter(({ value }) => value.includes('Seller') || value.includes('Buyer') || !value);
    case 'other':
    case 'consulting':
      return options.filter(({ value }) => value === 'Referral' || value === 'Represent' || !value);
    default:
      return options.filter(({ value }) => value.includes('Client') || !value);
  }
};

const typeOverrides = {
  voucher_gross_commission_percent: EVENT_VALUE_TYPES.FLOAT,
  gross_commission: EVENT_VALUE_TYPES.FLOAT,
  broker_is_lead: EVENT_VALUE_TYPES.BOOLEAN,
};

const BrokerCommissionItem = ({
  dealId,
  deal_type,
  voucherId,
  id,
  showValidation,
  cbre_commission_is_percent,
}) => {
  const [avatar, setAvatar] = useState();
  const commission = useSelector(state => selectCommissionById(state, id));
  const { broker = {} } = commission;
  const options = useSelector(state => state.options) || {
    producer_role: [],
    voucher_broker_rep_role: [],
  };
  options.voucher_broker_rep_role = filterOptionsByDealType(options.voucher_broker_rep_role, deal_type);
  const baseTotal = useSelector(selectTotalCommissionsForCommissions);
  const dispatch = useDispatch();
  const {
    broker: brokerId,
    gross_commission,
    producer_role,
    voucher_gross_commission_percent,
    voucher_broker_rep_role,
    onStateChange, onStateChanged,
  } = useFormState({
    broker: commission?.broker?.id,
    broker_is_lead: commission.broker_is_lead,
    gross_commission: commission.gross_commission,
    producer_role: commission.producer_role,
    voucher_gross_commission_percent: commission.voucher_gross_commission_percent,
    voucher_broker_rep_role: commission.voucher_broker_rep_role,
  }, changes => dispatch(updateCommission(id, changes)), typeOverrides);

  const onDelete = () => {
    dispatch(deleteCommission(id));
  };

  const onUpdateVoucher = ({ target: { name, value } }) => {
    dispatch(updateVoucher(voucherId, { [name]: value }));
    dispatch(updateDealPipelineTimestamp(dealId));
  };

  useEffect(() => {
    if (brokerId.value !== undefined && brokerId.value !== null) {
      fetchBroker(brokerId.value)
        .then(res => setAvatar(res.avatar))
        .catch(() => setAvatar());
    } else {
      setAvatar();
    }
  }, [brokerId.value]);

  const invalidGrossCommission = (showValidation || gross_commission.dirty) && !gross_commission.value;
  const invalidVoucherBrokerRepRole = (showValidation || voucher_broker_rep_role.dirty) && !voucher_broker_rep_role.value;
  const invalidProducerRole = (showValidation || producer_role.dirty) && !producer_role.value;

  const brokerHint = useMemo(() => {
    let hint = '';
    if (broker) {
      if (broker.work_email) {
        hint += broker.work_email;
      }

      if (broker.work_email && (broker.peoplesoft_id || broker.managing_office_name)) {
        hint += '\n';
      }

      if (broker.peoplesoft_id) {
        hint += `ID: ${broker.peoplesoft_id}`;
      }

      if (broker.peoplesoft_id && broker.managing_office_name) {
        hint += ' / ';
      }

      if (broker.managing_office_name) {
        hint += broker.managing_office_name;
      }
    }
    return hint;
  }, [broker]);

  return (
    <div className="form-row-with-labels-and-two-line-hints">
      <Avatar
        enableHover
        hoverAlign="left"
        firstName={commission?.broker?.first_name || ''}
        lastName={commission?.broker?.last_name || ''}
        image={avatar}
      />
      <FormGroup
        label="Broker"
        isRequired
        hasError={!brokerId.value}
        size="large"
        errorMessage="Required"
      >
        <BrokerAutocomplete
          onSelect={e => onStateChanged({
            target: {
              value: typeof e === 'object' ? e.id : null,
              name: 'broker',
              type: 'text',
            },
          })}
          initSearchTerm={getBrokerName(broker || undefined)}
        />
        <div className="input-hint">
          {brokerId.value && brokerHint}
        </div>
      </FormGroup>
      <FormGroup label="Rep Role" size="large" isRequired hasError={invalidVoucherBrokerRepRole} errorMessage="Required" className="due-upon-field">
        <Select
          name="voucher_broker_rep_role"
          defaultOption={options.voucher_broker_rep_role.find(option => option.value === voucher_broker_rep_role.value)}
          onChange={({ value }) => onStateChanged({
            target: { value, name: 'voucher_broker_rep_role', type: 'text' },
          })}
          options={options.voucher_broker_rep_role}
        />
      </FormGroup>
      <PercentDollarInput
        isRequired={invalidGrossCommission}
        label={`${invalidGrossCommission ? '' : '* '}amount`}
        name={{
          percentage: 'voucher_gross_commission_percent',
          value: 'gross_commission',
          select: 'cbre_commission_is_percent',
        }}
        className={invalidGrossCommission ? 'errorInput' : ''}
        onTypeChange={onUpdateVoucher}
        onChange={onStateChange}
        onBlur={onStateChanged}
        baseTotal={baseTotal}
        percentage={voucher_gross_commission_percent.value}
        value={gross_commission.value}
        isPercent={cbre_commission_is_percent}
        maxPercent={100}
        maxPercentWarningText="Warning: The commission split exceeds 100%."
      />
      <FormGroup label="Producer Role" size="large" isRequired hasError={invalidProducerRole} errorMessage="Required" className="due-upon-field">
        <Select
          name="producer_role"
          defaultOption={options.producer_role.find(option => option.value === producer_role.value)}
          onChange={({ value }) => onStateChanged({
            target: { value, name: 'producer_role', type: 'text' },
          })}
          options={options.producer_role}
        />
      </FormGroup>
      <RadioButton
        name="broker_is_lead"
        checked={commission.broker_is_lead}
        onChange={onStateChanged}
      >
        Lead Producer
      </RadioButton>
      <IconButton className="blxs-button-icon-small" iconName="close-circle" onClick={onDelete} variant="basic" />
    </div>
  );
};

BrokerCommissionItem.propTypes = {
  id: PropTypes.number.isRequired,
  dealId: PropTypes.number.isRequired,
  deal_type: PropTypes.string.isRequired,
  voucherId: PropTypes.number.isRequired,
  showValidation: PropTypes.bool.isRequired,
  cbre_commission_is_percent: PropTypes.bool.isRequired,
};

export default BrokerCommissionItem;
