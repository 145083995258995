import React from 'react';
import PropTypes from 'prop-types';

import DealRequirementsFormContainer from '../requirements/deal-requirements-form-container';
import DealDetailsContainer from './deal-details-form-container';
import OverviewNoteListPanelContainer from './overview-note-list-panel-container';
import OverviewTaskListPanelContainer from './overview-task-list-panel-container';
import OverviewPrimaryContactPanelContainer from './overview-primary-contact-panel-container';
import { Card } from '../../../nucleus/cards/cards';

const formatDate = (datetime) => {
  const dateObj = new Date(datetime);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  };
  return new Intl.DateTimeFormat('en-US', options).format(dateObj);
};

const OverviewTab = ({
  createdDetails: { createdBy, createdDate },
  analyticProperties,
}) => (
  <div className="overview-tab col-container">
    <div className="col flex-2">
      <OverviewNoteListPanelContainer
        isOverview
        analyticProperties={analyticProperties}
      />
      <OverviewTaskListPanelContainer
        isOverview
        analyticProperties={analyticProperties}
      />
    </div>
    <div className="col flex-1">
      <OverviewPrimaryContactPanelContainer
        analyticProperties={analyticProperties}
      />
      <Card>
        <DealDetailsContainer
          analyticProperties={analyticProperties}
        />
        <DealRequirementsFormContainer />
      </Card>
      <small>Deal created on: {formatDate(createdDate)} by {createdBy || 'Unknown'}</small>
    </div>
  </div>
);

OverviewTab.propTypes = {
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  createdDetails: PropTypes.shape({
    createdBy: PropTypes.string,
    createdDate: PropTypes.string,
  }).isRequired,
};

export default OverviewTab;
