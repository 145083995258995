import authService from '../services/auth-service';
import serializeParams from './serialize-params';

// fetch will go to onSuccess every time it receives a response, even if the response is an error
const onSuccess = (res) => {
  const err = !res.ok ? res : null;
  if (err) {
    throw err;
  }

  return res;
};

const onError = (error) => {
  switch (error.status) {
    // unauthorized
    case 401: {
      if (error.url.startsWith(CONFIG.apiUrl)) {
        authService.handleSSO();
      }
      throw error;
    }
    // forbidden
    case 403: {
      if (error.url.indexOf('members') === -1 &&
        !window.location.href.includes('agreement') &&
        error.url.indexOf('admin') === -1
      ) {
        window.location.assign('/'); // for generic cases, return user to dashboard
      }
      throw error;
    }
    // not found
    case 404: {
      // TODO: decide how we want to handle 404 at a high level, if at all
      throw error;
    }
    case 500: {
      // TODO: decide how we want to handle 500 at a high level, if at all
      throw error;
    }
    default: {
      throw error;
    }
  }
};

const superFetch = (url, options, body) => {
  const {
    method,
    params,
    headers,
  } = options;

  /* global CONFIG */
  const { apiUrl } = CONFIG;
  const fullUrl = (url.indexOf('http') > -1) ? url : apiUrl + url;
  const serializedUrl = params ? fullUrl + serializeParams(params) : fullUrl;
  const defaultHeaders = {
    Authorization: `JWT ${authService.getToken()}`,
    'Content-Type': 'application/json',
  };

  const customHeaders = headers || defaultHeaders;
  return fetch(serializedUrl, {
    method,
    headers: customHeaders,
    body,
  }).then(onSuccess)
    .catch(onError);
};

export default superFetch;
