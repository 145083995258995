import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@cbrebuild/blocks';

import Property from '../../property/property';

const PropertyReadOnly = (props) => {
  const {
    dealType,
    property,
    openForm,
  } = props;
  return (
    <div className="property-read-only">
      <div className="edit-property-row">
        <Property
          building={property.building}
          callToAction={null}
          name={property.name}
        />
        <IconButton onClick={openForm} iconName="edit" />
      </div>
      <React.Fragment>
        {property.spaces.map(space =>
          (
            <div className="space-information" key={space.id}>
              <div className="col-1">
                {!space.whole_building &&
                <div className="data-row">
                  <label>Suite</label>
                  <p>{space.address_line2 || '--'}</p>
                </div>
              }
                {space.whole_building &&
                <div className="data-row">
                  <label>Space</label>
                  <p>Whole Building</p>
                </div>
              }
                <div className="data-row">
                  <label>SQFT</label>
                  <p>{space.total_sqft || '--'}</p>
                </div>
                {(property.usage === 'Current' && !space.owned) &&
                <div className="data-row">
                  <label>Lease Exp.</label>
                  <p>{space.lease_expiration_date || '--'}</p>
                </div>
              }
                {(space.owned && (property.usage === 'Current' && dealType === 'sale')) &&
                <div className="data-row">
                  <label>Lease/Owned</label>
                  <p>Owned</p>
                </div>
              }
              </div>
              {!space.whole_building && !space.owned &&
              <div className="col-2">
                <div className="data-row">
                  <label>Floor</label>
                  <p>{space.floor_code || '--'}</p>
                </div>
                <div className="data-row">
                  <label>Status</label>
                  <p>{space.status || '--'}</p>
                </div>
                <div className="data-row">
                  <label>Comments</label>
                  <p>{space.description || '--'}</p>
                </div>
              </div>
              }
            </div>
        ))}
      </React.Fragment>
    </div>
  );
};

export default PropertyReadOnly;

PropertyReadOnly.propTypes = {
  dealType: PropTypes.string.isRequired,
  property: PropTypes.shape({
    building: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      full_address: PropTypes.string,
      id: PropTypes.number,
      postal_code: PropTypes.string,
      primary_property_name: PropTypes.string,
      state_abbreviation: PropTypes.string,
    }),
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    name: PropTypes.string,
    spaces: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string,
      description: PropTypes.string,
      address_line2: PropTypes.undefined,
      id: PropTypes.null,
      floor_code: PropTypes.string,
      lease_expiration_date: PropTypes.undefined,
      total_sqft: PropTypes.number,
      owned: PropTypes.undefined,
      whole_building: PropTypes.undefined,
      transactions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.null,
      })),
    })),
    usage: PropTypes.string,
  }),
  openForm: PropTypes.func.isRequired,
};

PropertyReadOnly.defaultProps = {
  property: {},
};

