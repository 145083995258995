import { connect } from 'react-redux';

import BillToTab from './BillToTab';
import { updateDeal } from '../../../redux/actions/deal-actions';
import { addDealContact, fetchDealContacts, updateDealContact, deleteDealContact } from '../../../redux/actions/deal-contacts-actions';
import { fetchCommissions } from '../../../redux/actions/voucher/commissions-actions';

const mapStateToProps = state => ({
  deal: state.deal.deal,
  voucher: state.voucher.voucher,
  contactInfo: state.dealContacts.contacts,
  commissions: state.commissions.commissions,
});

const mapDispatchProps = {
  updateDeal,
  fetchCommissions,
  addDealContact,
  fetchDealContacts,
  updateDealContact,
  deleteDealContact,
};


const BillToTabContainer = connect(
  mapStateToProps,
  mapDispatchProps,
)(BillToTab);

export default BillToTabContainer;
