import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, IconButton } from '@cbrebuild/blocks';
import PhoneInput from '../../nucleus/inputs/phone-input';
import EmailInput from '../../nucleus/inputs/email-input';
import ImageCropperModal from '../../components/image-cropper-modal/image-cropper-modal';
import Avatar from '../../nucleus/avatar/avatar';
import authService from '../../services/auth-service';

const ProfileOverview = (props) => {
  const [user, setUser] = useState(props.user);
  const [isEditable, toggleIsEditable] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage] = useState(null);
  const [modalState, toggleModalState] = useState(false);

  useMemo(() => {
    // for every re-render, or page refresh
    setUser(props.user);
  }, [props.user]);

  const onToggleEditUser = () => {
    toggleIsEditable(!isEditable);
  };

  const onPhoneNumberChange = (value, valid) => {
    setIsDirty(true);
    setIsValid(valid);

    const updatedUser = Object.assign(user, {
      first_name: user.first_name,
      last_name: user.last_name,
      work_email: user.work_email,
      work_phone: value,
    });

    setUser(updatedUser);
  };

  const onEmailChange = (value, valid) => {
    setIsDirty(true);
    setIsValid(valid);

    const updatedUser = Object.assign(user, {
      first_name: user && user.first_name,
      last_name: user && user.last_name,
      work_email: value,
      work_phone: user && user.work_phone,
    });
    setUser(updatedUser);
  };

  const onUpdateAvatar = () => {
    toggleModalState(!modalState);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    // only update if form has valid inputs
    if (isValid && isDirty) {
      props.updateUserData({
        first_name: user.first_name,
        last_name: user.last_name,
        work_email: user.work_email,
        work_phone: `+1${user.work_phone && user.work_phone.match(/\d+/g).join([])}`,
      });

      props.loadUserData();
      setIsDirty(false);
      toggleIsEditable(!isEditable);
    }

    if (isValid && !isDirty) {
      toggleIsEditable(!isEditable);
    }
  };

  const closeModal = () => {
    toggleModalState(false);
  };


  const handleLogout = (e) => {
    e.preventDefault();
    authService.logOut();
  };

  return (
    <React.Fragment>
      <div className="profile-page">
        <div className="main-content">
          {!isEditable &&
            <div className="card profile-card">
              <header className="card-header">
                <h2>My Profile</h2>
                <IconButton className="primary-icon-button" iconName="edit" onClick={e => onToggleEditUser(e)} />
              </header>
              <div>
                <span className="title">Title</span>
                <div className="info">{user && user.title}</div>
              </div>
              {user && user.work_phone &&
                <div>
                  <span className="title">Work Phone</span>
                  <div className="phone">{user && user.work_phone}</div>
                </div>
              }
              <div>
                <span className="title">Work Email</span>
                <div className="email">{user && user.work_email}</div>
              </div>

              <hr />

              <div className="card-footer">
                <span />
                <Button
                  className="logout-button"
                  onClick={e => handleLogout(e)}
                  variant="primary"
                >
                  <Icon iconName="log-out" />
                  Log Out
                </Button>
              </div>
            </div>
          }

          {isEditable &&
            <div className="card profile-card">
              <header className="card-header">
                <h2>Contact Info</h2>
              </header>
              <div className="note">
                <small>Note: Some contact information can&#39;t be edited here because it&#39;s associated with your CBRE corporate account.</small>
              </div>
              <div className="two-columns">
                <Avatar
                  enableHover
                  firstName={user.first_name}
                  image={props.avatar}
                  lastName={user.last_name}
                  location={{}}
                />
                {
                  modalState &&
                  <ImageCropperModal
                    closeModal={closeModal}
                    showModal
                    user={user}
                    loadUserData={props.loadUserData}
                  />
                }

                <Button
                  className="upload-btn"
                  onClick={() => { onUpdateAvatar(); }}
                  variant="secondary"
                >
                  <Icon iconName="open" />
                  Upload New Avatar
                </Button>

              </div>

              <div className="phone-input">
                <PhoneInput
                  value={user && user.work_phone}
                  onChange={(value, valid) => { onPhoneNumberChange(value, valid); }}
                  onBlur={(value, valid) => { onPhoneNumberChange(value, valid); }}
                />
              </div>
              <div className="email-input">
                <EmailInput
                  value={user && user.work_email}
                  onChange={(value, valid) => { onEmailChange(value, valid); }}
                  label="Work Email"
                />
              </div>
              {
                errorMessage &&
                <span>{errorMessage}</span>
              }
              <div className="form-controls">
                <Button
                  className="secondary-button"
                  variant="secondary"
                  onClick={() => { onToggleEditUser(); }}
                >
                  Cancel
                </Button>
                <Button
                  className="primary-button"
                  variant="primary"
                  onClick={(e) => { onFormSubmit(e); }}
                  disabled={!user.work_phone}
                >
                  Save
                </Button>
              </div>
            </div>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

ProfileOverview.propTypes = {
  user: PropTypes.shape({}).isRequired,
  loadUserData: PropTypes.func.isRequired,
  updateUserData: PropTypes.func.isRequired,
  avatar: PropTypes.string,
};

ProfileOverview.defaultProps = {
  avatar: undefined,
};

export default ProfileOverview;
