/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import RouteWithSubRoutes from '../../../utils/voucher-route-utils';
import VoucherComments from '../VoucherComments';
import BottomNav from '../BottomNav';
import FinancialBreakdown from '../FinancialBreakdown/FinancialBreakdown';
import TabNav from '../../../nucleus/tabnav/tabnav';
import SaleComp from './SaleComp';
import LeaseComp from './LeaseComp';
import { createCompData, fetchCompData } from '../../../redux/actions/voucher/comp-data-actions';

const CompDataTab = ({
  location,
  match: {
    params: {
      id,
    },
  },
  routes,
}) => {
  const {
    compData, dealId, dealType, repRole,
  } = useSelector(state => ({
    compData: state.compData,
    dealId: state.deal.deal?.id,
    dealType: state.deal.deal?.deal_type,
    repRole: state.deal.deal?.rep_role,
  }));
  const tabs = [
    {
      link: `/voucher/${id}/comp_data`,
      text: dealType === 'sale' ? 'Sale Comp' : 'Lease Comp',
    },
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    if (dealId) {
      dispatch(fetchCompData({ deal: dealId }))
        .then((results) => {
          // If there are no comp data after fetch, create one
          if (results.payload.length === 0) {
            dispatch(createCompData({
              deal: dealId,
              [`${repRole}_represented_by`]: 'CBRE',
              ...(dealType !== 'sale' && { rent_escalation_is_custom: false }),
            }));
          }
        });
    }
  }, [dispatch, dealId, dealType, repRole]);

  return (
    <React.Fragment>
      <div className="voucher-content">
        <TabNav tabs={tabs} selectedTab={location.pathname} />
        <Switch>
          {
          /* istanbul ignore next */
          routes && routes.map(route => (
            <RouteWithSubRoutes key={route.path} {...route} />
          ))}
        </Switch>
        <div className="comp-data-tab">
          <div className="informative-card">
            <h2>Share Comp Data With Research</h2>
            <p>Deal IQ now supports the collection of comp data and sends it to your local research team.
              While most of the data for a comp comes directly from the previous voucher pages,
              the following fields are needed to ensure a full and complete comp. Once you have entered the information below,
              you can view the completed comp on the review page.
            </p>
          </div>
          {compData.id &&
            (dealType === 'sale'
              ? <SaleComp />
              : <LeaseComp />
            )
          }
        </div>
        <BottomNav location={location.pathname} />
      </div>
      <div>
        <FinancialBreakdown />
        <VoucherComments />
      </div>
    </React.Fragment>
  );
};

CompDataTab.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

CompDataTab.defaultProps = {
  location: '',
};

export default CompDataTab;
