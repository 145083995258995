import React from 'react';
import PropTypes from 'prop-types';
import formatNumberWithCommas from '../../utils/format-number-with-commas';

const Percent = (props) => {
  const { decimalRounding, emptyState } = props;
  let { value } = props;
  if (decimalRounding !== undefined && value) {
    value = value.toFixed(decimalRounding);
  }

  return (
    <span>
      {value ? `${formatNumberWithCommas(value)}%` : emptyState}
    </span>
  );
};

Percent.propTypes = {
  value: PropTypes.number,
  decimalRounding: PropTypes.number,
  emptyState: PropTypes.string,
};

Percent.defaultProps = {
  decimalRounding: undefined,
  value: null,
  emptyState: '--',
};

export default Percent;
