import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DealTeamNotification = (props) => {
  const {
    handleNavigateTracking,
    variety,
    relatedObject,
    performedBy,
    turducken,
    read,
    fetchTeam,
  } = props;
  const {
    team_id: teamID,
    team_name: teamName,
    deal_name: dealName,
    deal_id: dealID,
    count,
  } = relatedObject;

  const singleDeal = count === 1;

  useEffect(() => {
    if (count !== 1 && read === null) {
      fetchTeam(teamID);
    }
  }, [count, fetchTeam, read, teamID]);

  const renderTeamName = (
    <Link
      className="deal-name-link"
      onClick={handleNavigateTracking}
      to="/profile/teams"
      {...(turducken ? { target: '_self' } : {})}
    >
      { teamName }
    </Link>
  );

  const renderDealName = (
    <Link
      className="deal-name-link"
      onClick={handleNavigateTracking}
      to={`/deals/detail/${dealID}/overview`}
      {...(turducken ? { target: '_self' } : {})}
    >
      {dealName}
    </Link>
  );

  const namelessMessage = () => {
    if (variety === 'team_added_to_deal') {
      if (singleDeal) {
        return (<span>{teamName} has been added to {dealName}</span>);
      }
      return (<span>{teamName} has been added to {count} deals</span>);
    } else if (variety === 'team_removed_from_deal') {
      if (singleDeal) {
        return (<span>{teamName} has been removed from {dealName}</span>);
      }
      return (<span>{teamName} has been removed from {count} deals</span>);
    }
    return 'A permission change was made to deal ';
  };

  const messageAction = () => {
    if (variety === 'team_added_to_deal') {
      if (singleDeal) {
        return (<span> added {renderTeamName} to </span>);
      }
      return (<span> added {renderTeamName} to {count} deals</span>);
    } else if (variety === 'team_removed_from_deal') {
      if (singleDeal) {
        return (<span> removed {renderTeamName} from </span>);
      }
      return (<span> removed {renderTeamName} from {count} deals</span>);
    }
    return (<span> made a change to </span>);
  };

  const renderMessage = performedBy ? (
    <React.Fragment>
      <span className="bold-name">
        {`${performedBy.first_name} ${performedBy.last_name}`}
      </span>
      {messageAction()}
      <span className="bold-name">
        {singleDeal && renderDealName}
      </span>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {namelessMessage}
      <span className="bold-name">
        {singleDeal && renderDealName}
      </span>
    </React.Fragment>
  );

  return (
    <p>{renderMessage}</p>
  );
};

export default DealTeamNotification;

DealTeamNotification.propTypes = {
  handleNavigateTracking: PropTypes.func.isRequired,
  variety: PropTypes.string,
  relatedObject: PropTypes.shape(),
  performedBy: PropTypes.shape({
    avatar: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  turducken: PropTypes.bool.isRequired,
  read: PropTypes.string,
  fetchTeam: PropTypes.func.isRequired,
};

DealTeamNotification.defaultProps = {
  variety: PropTypes.string,
  relatedObject: {},
  performedBy: PropTypes.shape({
    avatar: '',
    first_name: '',
    last_name: '',
  }),
  read: null,
};
