import React, { Fragment } from 'react';
import CreateDealModalContainer from '../../redux/containers/create-deal-modal-container';
import Toast from '../../nucleus/toast/toast';
import SyncSalesForceModal from '../headers/deal-detail-header/sync-salesforce-modal';

class AngularModalCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: undefined,
      trackingEventProperties: undefined,
      payload: undefined,
      promise: undefined,
      errorMessage: undefined,
    };
  }

  componentDidMount() {
    window.addEventListener('angularModalRequest', this.updateModalState);
  }

  componentDidCatch() {
    this.setState({
      modalType: undefined,
      trackingEventProperties: undefined,
      payload: undefined,
      promise: undefined,
      errorMessage: 'There was a problem duplicating your deal, please contact customer support.',
    });
    setTimeout(() => {
      this.setState({
        errorMessage: undefined,
      }, 3000);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('angularModalRequest', this.updateModalState);
  }

  onSubmit = (value) => {
    const {
      promise,
    } = this.state;
    promise.resolve(value);
    setTimeout(() => {
      this.closeModal();
    }, 500);
  }

  onCancel = () => {
    const {
      promise,
    } = this.state;
    promise.reject();
    this.closeModal();
  }

  closeModal = () => {
    this.setState({
      modalType: undefined,
      trackingEventProperties: undefined,
      payload: undefined,
      promise: undefined,
      errorMessage: undefined,
    });
  }

  updateModalState = (req) => {
    const {
      modalType,
      trackingEventProperties,
      payload,
      promise,
    } = req;

    if (modalType) {
      this.setState({
        modalType,
        trackingEventProperties,
        payload,
        promise,
      });
    } else {
      throw new Error('A modal type is required');
    }
  }

  render() {
    const {
      modalType,
      trackingEventProperties,
      payload,
      errorMessage,
    } = this.state;
    let modal;
    const showModal = !!modalType;
    switch (modalType) {
      case 'followup-deal': {
        const {
          clientCompany,
        } = payload;
        modal = (<CreateDealModalContainer
          closeModal={this.closeModal}
          showModal={showModal}
          clientCompany={clientCompany}
          trackingEventProperties={trackingEventProperties}
        />);
        break;
      }
      case 'duplicate-deal': {
        const {
          deal,
          team,
        } = payload;
        modal = (<CreateDealModalContainer
          closeModal={this.closeModal}
          showModal={showModal}
          dealToDuplicate={deal}
          dealTeamToDuplicate={team}
          trackingEventProperties={trackingEventProperties}
        />);
        break;
      }
      case 'sync-to-salesforce': {
        const {
          deal,
        } = payload;
        const {
          client,
          date_est_close: dateEstClose,
          deal_type: dealType,
          rep_role: repRole,
        } = deal || {};
        modal = (<SyncSalesForceModal
          showModal={showModal}
          dateEstClose={dateEstClose}
          isLeaseLandlord={repRole === 'landlord' && dealType === 'lease'}
          client={client}
          handleModalToggle={this.onCancel}
          handleModalSubmit={this.onSubmit}
        />);
        break;
      }
      default:
        return null;
    }
    const toast = errorMessage && <Toast message={errorMessage} />;
    return (
      <Fragment>
        {modal}
        {toast}
      </Fragment>
    );
  }
}

export default AngularModalCenter;
