import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Icon } from '@cbrebuild/blocks';
import formatDueDate from '../../utils/format-due-date';
import tasksService from '../../services/tasks-service';

import AddItem from '../../nucleus/add-item/add-item';
import Avatar from '../../nucleus/avatar/avatar';
import TasksModal from '../modals/tasks-modal';
import userEventService from '../../services/user-event-service';
import dealsService from '../../services/deals-service';

class DealCardTasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showForm: false,
      taskToEdit: undefined,
    };
  }

  handleAddTask = () => {
    this.setState({
      showModal: true,
      showForm: true,
      taskToEdit: undefined,
    });
  };

  handleEditTask = (task) => {
    this.setState({
      showModal: true,
      showForm: true,
      taskToEdit: task,
    });
  };

  handleTasksList = () => {
    this.setState({
      showModal: true,
      showForm: false,
      taskToEdit: undefined,
    });
  };

  closeTasksModal = () => {
    this.setState({
      showModal: false,
      taskToEdit: undefined,
    });
    this.props.fetchDealNoteAndTask([this.props.dealId]);
  };

  completeTask = (taskId) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
      dealId,
      toast,
      fetchDealNoteAndTask,
    } = this.props;
    const update = { is_complete: true };
    tasksService.updateTask(taskId, update)
      .then(() => {
        dealsService.updateDeal(dealId, {
          modified: new Date(),
        });
        toast('Task updated');
        fetchDealNoteAndTask([dealId]);
      })
      .catch(error => console.log('Error updating task: ', error));

    // tracks task status change
    userEventService.trackEvent({ eventCategory: 'Task Action', eventAction: 'task_status', eventValue: taskId }, {
      actionPrefix, categoryPrefix,
    });
  };

  render() {
    const {
      analyticProperties,
      toast,
      tasks,
      taskCount,
    } = this.props;
    const {
      showModal,
    } = this.state;

    const taskList = tasks && tasks.map((task) => {
      const onComplete = () => this.completeTask(task.id);
      const onEdit = () => this.handleEditTask(task);

      const {
        avatar,
        first_name: firstName,
        last_name: lastName,
      } = task.assignees[0] || {};

      const dueDate = formatDueDate(task.due_datetime);
      const warningIcon = task.is_priority && <Icon iconName="warning" />;

      return (
        <li key={task.id} className="flex layout-row">
          <Checkbox checked={task.is_complete} onChange={onComplete} />
          <span className="deal-card-task-content flex layout-column" onClick={onEdit}>
            <span className="deal-card-task-title">
              {warningIcon}
              {task.title}
            </span>
            <span className="deal-card-task-duedate">{dueDate}</span>
          </span>
          <Avatar
            image={avatar}
            firstName={firstName}
            lastName={lastName}
            sizing="md"
            enableHover
            hoverAlign="right"
          />
        </li>
      );
    });

    const seeMoreButton = taskCount > 3 && (
      <div className="primary-link" onClick={this.handleTasksList}>
        See More<Icon iconName="chevron-right" />
      </div>
    );

    const tasksModal = showModal && (
      <TasksModal
        analyticProperties={analyticProperties}
        dealName={this.props.dealName}
        dealId={this.props.dealId}
        onClose={this.closeTasksModal}
        taskToEdit={this.state.taskToEdit}
        showForm={this.state.showForm}
        toast={toast}
        user={this.props.user}
      />
    );

    return (
      <div className="deal-card-tasks">
        <ul className={tasks.length ? '' : 'empty-list'}>
          {taskList}
        </ul>
        <div className="deal-card-tasks-action-bar flex layout-row">
          <AddItem label="Task" onClick={this.handleAddTask} />
          {seeMoreButton}
        </div>
        {tasksModal}
      </div>
    );
  }
}

DealCardTasks.propTypes = {
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  dealName: PropTypes.string.isRequired,
  dealId: PropTypes.number.isRequired,
  toast: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape).isRequired,
  taskCount: PropTypes.number.isRequired,
  fetchDealNoteAndTask: PropTypes.func.isRequired,
};

export default DealCardTasks;
