import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Button, Icon, IconButton } from '@cbrebuild/blocks';
import dealsService from '../../../services/deals-service';
import pipelineService from '../../../services/pipeline-service';

const getCurrentDealListParams = (lifeCycle) => {
  let currentDealListParams;

  if (lifeCycle === 'prospect') {
    currentDealListParams = JSON.parse(window.localStorage.getItem('prospectingState'));
  } else if (lifeCycle === 'executing') {
    currentDealListParams = JSON.parse(window.localStorage.getItem('executingState'));
  } else if (lifeCycle === 'closed') {
    currentDealListParams = JSON.parse(window.localStorage.getItem('closedState'));
  }

  return currentDealListParams || { deal_life_cycle_stage: lifeCycle };
};

const navigateToDealList = (index, lifeCycle, setRedirectPath, source) => {
  let listViewName = lifeCycle;
  if (listViewName === 'prospect') {
    listViewName = 'prospects';
  }

  window.localStorage.setItem('dealListIndex', index);
  source === 'Deals' ? setRedirectPath(`/deals/${listViewName}`) : setRedirectPath('/pipeline');
};

const traverseDeals = (direction, index, count, lifeCycle, setRedirectPath, source) => {
  let newIndex;
  const params = getCurrentDealListParams(lifeCycle);
  params.page_size = 1;

  if (direction === 'previous') {
    newIndex = index - 1;
  } else if (direction === 'next') {
    newIndex = index + 1;
  }

  // add one because API uses one-based indexing of instead of zero-based
  params.page = newIndex + 1;

  const dealTogglerData = {
    index: newIndex,
    count,
    lifeCycle,
    source,
  };

  if (source === 'Deals') {
    dealsService.fetchDealsAndTransactionsES(params)
      .then((res) => {
        const deal = res.results[0];
        const { id } = deal;
        dealTogglerData.id = id;
        window.localStorage.setItem('dealTogglerData', JSON.stringify(dealTogglerData));
        setRedirectPath(`/deals/detail/${id}/overview`);
      })
      .catch(error => console.log('Error fetching deal: ', error));
  } else {
    const updatedParams = { ...params, ordering: '-is_on_pipeline' };
    pipelineService.fetchDeals(updatedParams)
      .then((res) => {
        const deal = res.results[0];
        const { id } = deal;
        dealTogglerData.id = id;
        window.localStorage.setItem('dealTogglerData', JSON.stringify(dealTogglerData));
        setRedirectPath(`/deals/detail/${id}/pipeline`);
      })
      .catch(error => console.log('Error fetching deal: ', error));
  }
};

const DealPagination = (props) => {
  const [redirectPath, setRedirectPath] = useState('');
  const dealTogglerData = JSON.parse(window.localStorage.getItem('dealTogglerData'));
  if (!dealTogglerData) {
    return null;
  }

  // must wait for this prop to come through before rendering
  if (props.dealID === undefined) {
    return null;
  }

  if (dealTogglerData.id !== props.dealID && !redirectPath) {
    // user is not in one of the flows supported by feature
    // i.e. They have multiple detail pages open in separate tabs
    // or they linked directly to a details page, rather than navigating to it through the UI
    // window.localStorage.removeItem('dealTogglerData');
    return null;
  }

  if (redirectPath) {
    return <Redirect push to={redirectPath} />;
  }

  const {
    index, count, lifeCycle, source,
  } = dealTogglerData;

  const prevBtnClass = (index === 0) ? 'disabled' : '';
  const nextBtnClass = (index === count - 1) ? 'disabled' : '';

  return (
    <div className="deal-pagination">
      <Button
        className="list-return-button"
        onClick={() => navigateToDealList(index, lifeCycle, setRedirectPath, source)}
        variant="text"
      >
        <Icon iconName="arrow-left" data-e2e="back to deals" />
        Back to {source}
      </Button>
      <IconButton
        className="pagination-btn"
        data-e2e="chevron-left-button"
        disabled={!!prevBtnClass}
        iconName="chevron-left"
        onClick={() => traverseDeals('previous', index, count, lifeCycle, setRedirectPath, source)}
      />
      <div className="pagination-text">
        <span>Deals</span>
        <span className="black">{index + 1}</span>
        <span>&nbsp;/&nbsp;</span>
        <span className="black">{count}</span>
      </div>
      <IconButton
        className="pagination-btn"
        data-e2e="chevron-right-button"
        disabled={!!nextBtnClass}
        iconName="chevron-right"
        onClick={() => traverseDeals('next', index, count, lifeCycle, setRedirectPath, source)}
      />
    </div>
  );
};

DealPagination.propTypes = {
  dealID: PropTypes.number.isRequired,
};

export default memo(DealPagination);
