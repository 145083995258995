import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@cbrebuild/blocks';

import StackedLabelItem from '../../../nucleus/stacked-labeled-item/stacked-labeled-item';
import Tag from '../../../nucleus/legacy/tag/tag';

function FeatureFlagListItem({ flag, onChange }) {
  const handleOnChange = ({ target: { checked } }) => onChange({ ...flag, enabled: checked });
  // flag.note = 'Title:Description';
  // flag.note should contain a colon as a delimiter, we use this to get the title and description
  // if the note is null or doesn't have a delimiter we fall back to flag.note as a description and flag.feature_flag as the title
  const splitNote = flag.note ? flag.note.split(':') : [];
  const [title, description] = splitNote.length > 1 ? splitNote : [flag.feature_flag, flag.note];
  return (
    <li className="feature-flag-list-item">
      <div className="info">
        <Checkbox
          checked={flag.enabled}
          disabled={flag.everyone}
          onChange={handleOnChange}
        />
        <StackedLabelItem
          label={title}
          value={description}
        />
      </div>
      <div className="groups">
        {flag.everyone
          ? <Tag tag="Everyone" />
          : flag.groups.map(group => (<Tag tag={group} key={group} />))
        }
      </div>
    </li>
  );
}

FeatureFlagListItem.propTypes = {
  flag: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    everyone: PropTypes.bool.isRequired,
    feature_flag: PropTypes.string,
    note: PropTypes.string.isRequired,
    groups: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FeatureFlagListItem;
