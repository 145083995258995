import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import maxBy from 'lodash/maxBy';
import { IconButton } from '@cbrebuild/blocks';
import floor from 'lodash/floor';
import round from 'lodash/round';

import PercentDollarInput from '../../../nucleus/inputs/percent-dollar-input';
import Datepicker from '../../../nucleus/datepicker/datepicker';
import AddItem from '../../../nucleus/add-item/add-item';
import FormGroup from '../../../nucleus/form-group/form-group';

class Installments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetched: false,
    };
  }
  componentDidMount() {
    const {
      deal,
    } = this.props;
    this.fetchOrCreateInstallments(deal.id);
  }

  onDayPickerHide = (id, date) => {
    // TODO: Update installment model to accept date instead of date time.
    // toISOString() adds time string to make it acceptable to BE model
    // Thist causes date difference based on where user's browser is located.
    // ex. Date added in NY sends "2020-06-17T04:00:00Z", and this displays "6/16/2020" in Seattle.
    // Date added by datepicker should not matter time zone and display the same date globally.
    const value = date ? moment(date).toDate().toISOString() : null;
    const params = { installment_date: value };
    this.props.updateInstallment(id, params)
      .then(() => {
        this.props.updateDealPipelineTimestamp();
      });
  }

  onAddInstallment = () => {
    this.props.createInstallment(this.getInstallmentTemplate());
  }

  handlePercentDollarBlur = (id, percent, dollar, totalCommission) => {
    const { voucher, baseTotal } = this.props;
    let newPercent;
    let newDollar;

    // Handle both estimated and expected total commission
    const denominator = totalCommission || baseTotal;

    if (voucher.installment_is_percent) {
      newPercent = percent;
      newDollar = (percent / 100) * denominator;
    } else {
      newDollar = dollar;
      newPercent = (dollar / denominator) * 100;
    }

    const params = {
      voucher_installment_percent: floor(newPercent, 8),
      installment_amount: round(newDollar, 2),
      percent_is_fixed: voucher.installment_is_percent,
    };

    this.props.updateInstallment(id, params);
  }

  getInstallmentTemplate = () => ({
    transaction: this.props.deal.id,
    installment_number: this.getNextInstallmentNumber(),
    contingencies: [],
  });

  getNextInstallmentNumber = () => {
    const { installments } = this.props;
    return installments.length > 0 ? maxBy(installments, 'installment_number').installment_number + 1 : 1;
  }

  fetchOrCreateInstallments = (dealId) => {
    const {
      fetchInstallments,
      addInstallment,
    } = this.props;

    fetchInstallments(dealId)
      .then((response) => {
        if (response.payload.length === 0) {
          addInstallment(this.getInstallmentTemplate());
        } else {
          this.ensureInstallmentNumbers(response.payload);
        }

        this.setState({ isFetched: true });
      });
  }

  ensureInstallmentNumbers = (installments) => {
    // This reassign installment number when deleting an installment or fetch installments
    // installment_number should match with the order of the installments as it appears in the UI
    const hasWrongInstallmentNumber = installments.find((installment, idx) => installment.installment_number !== idx + 1);
    if (hasWrongInstallmentNumber) {
      installments.forEach((installment, idx) => {
        const params = { installment_number: idx + 1 };
        this.props.updateInstallment(installment.id, params);
      });
    }
  }

  toggleSign = () => {
    const { voucher, updateVoucher } = this.props;
    const params = { installment_is_percent: !voucher.installment_is_percent };
    updateVoucher(voucher.id, params)
      .then(() => this.props.updateDealPipelineTimestamp());
  }

  handleDeleteInstallments = (installmentId) => {
    this.props.deleteInstallment(installmentId)
      .then(() => {
        // updated installments are fed by props(redux store)
        this.ensureInstallmentNumbers(this.props.installments);
        this.props.updateDealPipelineTimestamp();
      });
  }

  render() {
    const {
      installments,
      voucher,
      baseTotal,
      updateDealPipelineTimestamp,
    } = this.props;

    const renderInstallmentDisplay = (installment) => {
      let percentModel;
      let dollarModel;

      if (installment.percent_is_fixed) {
        percentModel = installment.voucher_installment_percent;
        dollarModel = round((baseTotal * (percentModel / 100)), 2);
      } else {
        dollarModel = installment.installment_amount;
        percentModel = floor(((dollarModel / baseTotal) * 100), 8);
      }

      return (
        <div key={installment.id} className="form-row-with-labels-and-hints">
          <PercentDollarInput
            isRequired
            label="amount"
            isPercent={voucher.installment_is_percent}
            dollarModel={dollarModel}
            percentModel={percentModel}
            toggleSign={this.toggleSign}
            updateChange={(percent, dollar) => this.handlePercentDollarBlur(installment.id, percent, dollar, installment.deal_total_commission)}
            updateDealPipelineTimestamp={updateDealPipelineTimestamp}
            baseTotal={baseTotal}
          />
          <FormGroup label="Due Date" size="medium" isRequired hasError={!installment.installment_date} errorMessage="Required">
            <Datepicker
              date={installment.installment_date}
              onDateChange={() => {}}
              onDayPickerHide={date => this.onDayPickerHide(installment.id, date)}
            />
          </FormGroup>
          <IconButton
            className="blxs-button-icon-small"
            iconName="close-circle"
            onClick={() => this.handleDeleteInstallments(installment.id)}
            variant="basic"
          />
        </div>
      );
    };

    return (
      <div className="financial-card installments-card">
        <h3>Installments</h3>
        {this.state.isFetched && installments.map(renderInstallmentDisplay)}
        <AddItem
          label="Installment"
          onClick={this.onAddInstallment}
        />
      </div>
    );
  }
}

Installments.propTypes = {
  // redux state
  deal: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  installments: PropTypes.arrayOf(PropTypes.object).isRequired,
  voucher: PropTypes.shape({
    id: PropTypes.number,
    installment_is_percent: PropTypes.bool,
  }),
  baseTotal: PropTypes.number.isRequired,
  // redux actions
  fetchInstallments: PropTypes.func.isRequired,
  addInstallment: PropTypes.func.isRequired,
  updateInstallment: PropTypes.func.isRequired,
  createInstallment: PropTypes.func.isRequired,
  deleteInstallment: PropTypes.func.isRequired,
  updateVoucher: PropTypes.func.isRequired,
  updateDealPipelineTimestamp: PropTypes.func.isRequired,
};

Installments.defaultProps = {
  voucher: {
    installment_is_percent: true,
  },
};

export default Installments;
