import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const tabItem = (tab, selectedTab) => {
  const isTabSelected = selectedTab.includes(tab.link);
  const active = isTabSelected ? 'active' : '';
  const disabled = tab.disabled ? 'disabled' : '';
  const anchor = (tab.rel === 'external') ? (
    <a href={tab.link}>{tab.text}</a>
  ) : (
    <Link to={tab.link} title={tab.text}>{tab.text}</Link>
  );

  return (
    <li className={`${active} ${disabled}`} key={tab.link} data-e2e={tab.text}>{anchor}</li>
  );
};

const primaryTabs = (tabs, selectedTab) => tabs.map((tab) => {
  // render primary tabs
  if (!tab.hidden && !tab.secondary) return tabItem(tab, selectedTab);
  return null;
});

const secondaryTabs = (tabs, selectedTab) => tabs.map((tab) => {
  let spacer = null;
  // render secondary tabs. show separator before first secondary tab
  if (tab.hidden || !tab.secondary) return null;
  if (spacer === null) {
    spacer = <li key="spacer" className="spacer" />;
    return [spacer, tabItem(tab, selectedTab)];
  }
  return tabItem(tab, selectedTab);
});

const TabNav = (props) => {
  const {
    tabs,
    selectedTab,
    isProgressTab,
  } = props;

  // voucher submission uses a custom tab style

  const style = isProgressTab ? 'progress-tabs' : 'nd-tabnavs';

  return (
    <div className={style}>
      <ul>
        {primaryTabs(tabs, selectedTab)}
        {secondaryTabs(tabs, selectedTab)}
      </ul>
    </div>
  );
};

TabNav.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
    text: PropTypes.string,
    rel: PropTypes.string,
  })).isRequired,
  selectedTab: PropTypes.string,
  isProgressTab: PropTypes.bool,
};

TabNav.defaultProps = {
  selectedTab: '',
  isProgressTab: false,
};

export default TabNav;
