import { connect } from 'react-redux';
import ProfilePage from './profile-page';
import { loadUserData, updateUserData } from '../../redux/actions/user-service-actions';
import { loadUserOptions } from '../../redux/actions/user-options-actions';

const mapStateToProps = state => ({
  userData: state.userData.data,
  userOptions: state.userOptions.userOptions,
  proxy: state.proxy.proxySettings,
});

const mapDispatchToProps = {
  loadUserData,
  loadUserOptions,
  updateUserData,
};

const ProfilePageContainer = connect(mapStateToProps, mapDispatchToProps)(ProfilePage);

export default ProfilePageContainer;
