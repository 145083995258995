import React from 'react';
import PropTypes from 'prop-types';
import { Button, ToggleSwitch } from '@cbrebuild/blocks';
import ButtonWithFlyout from '../../../nucleus/button-with-flyout/button-with-flyout';
import ButtonSegmentedGroup from '../../../nucleus/button-segmented-group/button-segmented-group';
import TagSearch from '../../../nucleus/legacy/tag/tag-search';
import FilterTag from '../../../nucleus/legacy/tag/filter-tag';

const CONTACT_TYPE = {
  ALL: 'ALL',
  OUTLOOK: 'OUTLOOK',
};
const options = [
  {
    display_name: 'All Contacts',
    value: CONTACT_TYPE.ALL,
  },
  {
    display_name: 'Outlook Contacts',
    value: CONTACT_TYPE.OUTLOOK,
  },
];

class ContactListFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: props.hidden,
      outlook_sync_enabled: props.outlook_sync_enabled,
      tags: props.tags,
      submitted: false,
    };
    this.buttonWithFlyoutRef = React.createRef();
    this.tagSearchRef = React.createRef();
  }

  handleContactChange = ({ value }) => {
    this.setState({ outlook_sync_enabled: value === CONTACT_TYPE.OUTLOOK });
  }

  handleHiddenChange = (e) => {
    this.setState({ hidden: e.target.checked });
  }

  handleAddTag = (tag) => {
    this.setState({
      tags: [...this.state.tags.filter(t => t !== tag), tag],
    });
  };

  handleRemoveTag = (tagToRemove) => {
    this.setState({
      tags: this.state.tags.filter(tag => tag !== tagToRemove),
    });
  }

  handleApply = () => {
    const {
      outlook_sync_enabled,
      hidden,
      tags,
    } = this.state;
    this.props.onApply({
      hidden,
      outlook_sync_enabled,
      tags,
    });
    this.setState({
      submitted: true,
    }, () => {
      this.buttonWithFlyoutRef.current.closeFlyout();
    });
  }

  handleClear = () => {
    this.setState({
      hidden: this.props.default_hidden,
      outlook_sync_enabled: this.props.default_outlook_sync_enabled,
      tags: this.props.default_tags,
    });
    this.tagSearchRef.current.applyFilter({ target: { value: '' } });
  }

  handleAbort = () => {
    if (this.state.submitted) {
      this.setState({ submitted: false });
    } else {
      this.props.onAbort();
    }
  }

  isDirty = () => !(
    this.state.hidden === this.props.hidden &&
    this.state.outlook_sync_enabled === this.props.outlook_sync_enabled &&
    this.state.tags.length === this.props.default_tags.length
  );

  render() {
    const {
      hidden,
      outlook_sync_enabled,
      tags,
    } = this.state;
    const {
      allContactTags,
      disabled,
    } = this.props;
    return (
      <div className="contact-list-filter">
        <ButtonWithFlyout
          actionIcon="filter"
          buttonText="Filter"
          buttonIcon="chevron-down"
          disabled={disabled}
          onAbort={this.handleAbort}
          ref={this.buttonWithFlyoutRef}
        >
          <div className="content">
            <ButtonSegmentedGroup
              onChange={this.handleContactChange}
              options={options}
              selected={outlook_sync_enabled ? options[1] : options[0]}
            />
            <ToggleSwitch
              checked={hidden}
              onChange={this.handleHiddenChange}
            >
              Show Hidden Contacts
            </ToggleSwitch>
            <label className="tags-label">Tags</label>
            <TagSearch
              onSelect={this.handleAddTag}
              tags={allContactTags}
              ref={this.tagSearchRef}
              selectedTags={tags}
            />
            <div className="selected-tags">
              {(tags && tags.length) ? tags.map(tag => (
                <FilterTag
                  isButton
                  key={`filter-tag-${tag}`}
                  value={tag}
                  handleClearFilter={() => this.handleRemoveTag(tag)}
                />
              )) : null}
            </div>
          </div>
          <footer>
            <Button variant="secondary" onClick={this.handleClear}>Clear</Button>
            <Button disabled={!this.isDirty()} onClick={this.handleApply}>Apply</Button>
          </footer>
        </ButtonWithFlyout>
      </div>
    );
  }
}

ContactListFilter.propTypes = {
  allContactTags: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  outlook_sync_enabled: PropTypes.bool,
  default_hidden: PropTypes.bool,
  default_outlook_sync_enabled: PropTypes.bool,
  onApply: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  default_tags: PropTypes.arrayOf(PropTypes.string),
};

ContactListFilter.defaultProps = {
  allContactTags: [],
  disabled: false,
  hidden: false,
  outlook_sync_enabled: false,
  default_hidden: false,
  default_outlook_sync_enabled: false,
  tags: [],
  default_tags: [],
};

export default ContactListFilter;
