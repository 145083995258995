import { createSelector } from 'reselect';

export const selectConsiderations = state => state.voucherConsiderations.considerations;
export const selectAdditionalCommissions = state => state.voucherAdditionalCommissions.additionalCommissions;
export const selectCommissions = state => state.commissions.commissions
  .filter(({ cbre_broker_type }) => !!cbre_broker_type)
  .sort((a, b) => (a.cbre_broker_type >= b.cbre_broker_type ? 1 : -1));
export const selectSharedFees = state => state.sharedFees.sharedFees;
export const selectOutsideBrokerCommissions = state => state.outsideBrokerCommissions.commissions;
export const selectRebates = state => state.rebates.rebates;
export const selectInstallments = state => state.voucherInstallments;
export const selectOutsideBrokerIsPercentage = state => state.voucher.voucher?.outside_broker_is_percent;
export const selectFeeSharingIsPercentage = state => state.voucher.voucher?.fee_sharing_is_percent;
export const selectRebateIsPercentage = state => state.voucher.voucher?.rebate_is_percent;
export const selectCBRECommissionIsPercent = state => state.voucher.voucher?.cbre_commission_is_percent;
export const selectInstallmentIsPercentage = state => state.voucher.voucher?.installment_is_percent;

export const selectConsiderationsCommissionAmountTotal = createSelector(
  selectConsiderations,
  items => items.reduce((subTotal, item) => item.commission_amount + subTotal, 0),
);

export const selectAdditionalCommissionAmountTotal = createSelector(
  selectAdditionalCommissions,
  items => items.reduce((subTotal, item) => item.commission_amount + subTotal, 0),
);

export const selectCommissionsGrossCommissionTotal = createSelector(
  selectCommissions,
  items => items.reduce((subTotal, item) => item.gross_commission + subTotal, 0),
);

export const selectSharedFeesGrossCommissionTotal = createSelector(
  selectSharedFees,
  items => items.reduce((subTotal, item) => item.gross_commission + subTotal, 0),
);

export const selectOutsideBrokerCommissionGrossCommissionTotal = createSelector(
  selectOutsideBrokerCommissions,
  items => items.reduce((subTotal, item) => item.gross_commission + subTotal, 0),
);

export const selectRebatesGrossCommissionTotal = createSelector(
  selectRebates,
  items => items.reduce((subTotal, item) => item.gross_commission + subTotal, 0),
);

export const selectTotalCommissions = createSelector(
  selectConsiderationsCommissionAmountTotal,
  selectAdditionalCommissionAmountTotal,
  (x, y) => x + y,
);

export const selectTotalCommissionsForRebates = createSelector(
  selectTotalCommissions,
  selectOutsideBrokerCommissionGrossCommissionTotal,
  (x, y) => x - y,
);

export const selectTotalCommissionsForSharedFees = selectTotalCommissionsForRebates;

export const selectTotalCommissionsForCommissions = createSelector(
  selectTotalCommissions,
  selectOutsideBrokerCommissionGrossCommissionTotal,
  selectRebatesGrossCommissionTotal,
  (x, y, z) => x - (y + z),
);

export const selectTotalCommissionsForInstallments = createSelector(
  selectTotalCommissions,
  selectOutsideBrokerCommissions,
  (x, y) => y.reduce((subTotal, item) => subTotal - (!item.paid_by_cbre ? item.gross_commission : 0), x),
);

export const selectAllocationsTotal = createSelector(
  selectCommissionsGrossCommissionTotal,
  selectOutsideBrokerCommissionGrossCommissionTotal,
  selectRebatesGrossCommissionTotal,
  (a, b, c) => a + b + c,
);
