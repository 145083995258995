import { UPDATE_TABLE_CONFIG,
  RESET_TABLE_CONFIG,
  REORDER_TABLE_CONFIG } from '../actions/action-types';

const initialState = [
  {
    id: 'on-pipeline-col',
    sortParam: 'is_on_pipeline',
    key: 'is_on_pipeline',
    value: 'Added',
    isHidden: false,
    additionalStyleClass: 'text-center',
    tdClass: 'text-center',
    checked: true,
  },
  {
    id: 'name-col',
    sortParam: 'name',
    key: 'name',
    value: 'Deal',
    isHidden: false,
    spanClass: 'dealname-header-wrapper',
    tdClass: 'truncate',
    checked: true,
  },
  {
    id: 'lead-producer-col',
    sortParam: 'lead_producer',
    key: 'lead_producer',
    value: 'Lead Producer',
    isHidden: false,
    tdClass: 'truncate',
    checked: true,
  },
  {
    id: 'deal-type-col',
    sortParam: 'deal_type',
    key: 'deal_type',
    hasTwoParams: true,
    value: 'Deal Type',
    isHidden: false,
    tdClass: 'capitalize',
    checked: true,
  },
  {
    id: 'deal-stage-col',
    sortParam: 'deal_life_cycle_stage',
    key: 'deal_life_cycle_stage',
    value: 'Deal Stage',
    isHidden: false,
    tdClass: 'capitalize',
    checked: true,
  },
  {
    id: 'gross-commission-col',
    sortParam: 'estimated_commission',
    key: 'estimated_commission',
    additionalStyleClass: 'text-right',
    value: 'Gross Commission',
    isHidden: false,
    tdClass: 'text-right commission-row',
    checked: true,
  },
  {
    id: 'probability-col',
    sortParam: 'conversion_potential',
    key: 'conversion_potential',
    additionalStyleClass: 'text-right',
    value: 'Probability',
    isHidden: false,
    tdClass: 'text-right',
    checked: true,
  },
  {
    id: 'first-installment-col',
    sortParam: 'installment__installment_date',
    key: 'firstInstallmentDate',
    value: 'First Installment',
    isHidden: false,
    tdClass: 'text-right',
    checked: true,
  },
  {
    id: 'client-col',
    sortParam: 'client__name',
    key: 'client_name',
    value: 'Client',
    isHidden: false,
    tdClass: 'capitalize',
    checked: true,
  },
  {
    id: 'property-col',
    sortParam: 'property',
    key: 'property_address',
    hasTwoParams: true,
    value: 'Property',
    isHidden: false,
    tdClass: 'capitalize',
    checked: true,
  },
  {
    id: 'property-type-col',
    sortParam: 'property_type',
    key: 'property_type',
    hasTwoParams: true,
    value: 'Property Type',
    isHidden: false,
    tdClass: 'capitalize',
    checked: true,
  },
  {
    id: 'lease-type-col',
    sortParam: 'lease_type',
    key: 'lease_type',
    hasTwoParams: true,
    value: 'Lease Type',
    isHidden: false,
    tdClass: 'capitalize',
    checked: true,
  },
  {
    id: 'lease-term-col',
    sortParam: 'lease_term',
    key: 'lease_term',
    hasTwoParams: true,
    value: 'Lease Term(Months)',
    isHidden: false,
    tdClass: 'capitalize',
    checked: true,
  },
  {
    id: 'square-feet-col',
    sortParam: 'square_feet',
    key: 'square_feet',
    hasTwoParams: true,
    value: 'Square Feet',
    isHidden: false,
    tdClass: 'capitalize',
    checked: true,
  },
  {
    id: 'team-col',
    sortParam: 'team',
    key: 'team',
    hasTwoParams: true,
    value: 'Team',
    isHidden: false,
    tdClass: '',
    checked: true,
  },
];
const TABLE_CONFIG_ORDER = 'TABLE_CONFIG_ORDER';

const setOrderToLocalStorage = (currState = initialState) => {
  const { localStorage } = window;
  const orderList = currState.map(state => ({ key: state.key, isHidden: state.isHidden }));
  localStorage.setItem(TABLE_CONFIG_ORDER, JSON.stringify({ orderList }));
};

const getOrderFromLocalStorage = () => {
  const { localStorage } = window;

  return localStorage.getItem(TABLE_CONFIG_ORDER);
};

const updateTableConfig = (state, action) => {
  setOrderToLocalStorage(action.payload);

  return [
    ...action.payload,
  ];
};

const sortTableConfig = (state = initialState) => {
  const order = JSON.parse(getOrderFromLocalStorage());

  if (!order) return state;
  const { orderList } = order;
  const reOrderState = orderList.reduce((prev, curr) => {
    const foundKey = initialState.find(conf => conf.key === curr.key);

    if (foundKey) {
      return [...prev, { ...foundKey, isHidden: curr.isHidden, checked: !curr.isHidden }];
    }
    return prev;
  }, []);
  return reOrderState;
};


const tableConfig = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TABLE_CONFIG:
      return updateTableConfig(state, action);
    case RESET_TABLE_CONFIG:
      return initialState;
    case REORDER_TABLE_CONFIG:
      return sortTableConfig(state);
    default:
      return state;
  }
};

export default tableConfig;
