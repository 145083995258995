import React from 'react';
import { RadioGroup, RadioButton } from '@cbrebuild/blocks';
import PropTypes from 'prop-types';

// import Tooltip from '../../nucleus/tooltip/tooltip';
import Datepicker from '../../nucleus/datepicker/datepicker';
import Select from '../../nucleus/select/select';

import './lease-abstraction.scss';
import PhoneInput from '../../nucleus/inputs/phone-input';

const LeaseAbstractionFieldType = Object.freeze({
  DATE: 0,
  TEXT: 1,
  SELECT: 2,
  RADIO: 3,
  PHONE: 4,
});

const LeaseAbstractionField = ({
  fieldType,
  fieldData,
  fieldChangeHandler,
  fieldValidityChangeHandler,
}) => {
  // console.log('fieldData', fieldData);
  switch (fieldType) {
    case LeaseAbstractionFieldType.DATE:
      return (
        <div className="file-modal-group-container">
          <div className="file-modal-group">
            <label className="acc-field-label">
              <span>{fieldData.label}</span>
              {/* <Tooltip
              size="small"
              position="right"
              message={
                <>
                  <p className="acc-tooltip-heading">
                    {fieldData.data.toolTip.section}
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Paragraph: {fieldData.data.toolTip.paragraph}</span>
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Page: {fieldData.data.toolTip.pageIndex}</span>
                  </p>
                </>
              }
            >
              <Icon iconName="info-circle" variant="primary" />
            </Tooltip> */}
            </label>
            <Datepicker
              date={fieldData.data.value}
              onDateChange={fieldChangeHandler}
              updateValidity={fieldValidityChangeHandler}
            />
          </div>
          {fieldData.data.toolTip.pageIndex?.length > 0 && (
            <p className="acc-page-info">
              <span>Page: {fieldData.data.toolTip.pageIndex}</span>
            </p>
          )}
        </div>
      );
    case LeaseAbstractionFieldType.RADIO:
      return (
        <div className="file-modal-group-container">
          <div className="acc-radio-group">
            <div className="acc-radio-group-label-container">
              <legend className="acc-radio-group-label">
                {fieldData.label}
              </legend>
              {fieldData.data.toolTip.pageIndex?.length > 0 && (
                <p className="acc-page-info">
                  <span>Page: {fieldData.data.toolTip.pageIndex}</span>
                </p>
              )}
              {/* <Tooltip
              size="small"
              position="right"
              message={
                <>
                  <p className="acc-tooltip-heading">
                    {fieldData.data.toolTip.section}
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Paragraph: {fieldData.data.toolTip.paragraph}</span>
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Page: {fieldData.data.toolTip.pageIndex}</span>
                  </p>
                </>
              }
            >
              <Icon iconName="info-circle" variant="primary" />
            </Tooltip> */}
            </div>
            <RadioGroup
              className="acc-radio-group-options"
              selectedValue={`${fieldData.data.value.toString().toLowerCase()}`}
              onChange={fieldChangeHandler}
              orientation="vertical"
            >
              {fieldData.radioButtons.map(({ value, text }) => (
                <RadioButton
                  key={text}
                  checked={
                    fieldData.data.value.toString().toLowerCase() === value
                  }
                  className="acc-radio-group-label"
                  value={value}
                >
                  {text}
                </RadioButton>
              ))}
            </RadioGroup>
          </div>
        </div>
      );
    case LeaseAbstractionFieldType.TEXT:
      return (
        <div className="file-modal-group-container">
          <div className="file-modal-group">
            <label className="acc-field-label">
              {fieldData.label}
              {/* <Tooltip
              size="small"
              position="right"
              message={
                <>
                  <p className="acc-tooltip-heading">
                    {fieldData.data.toolTip.section}
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Paragraph: {fieldData.data.toolTip.paragraph}</span>
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Page: {fieldData.data.toolTip.pageIndex}</span>
                  </p>
                </>
              }
            >
              <Icon iconName="info-circle" variant="primary" />
            </Tooltip> */}
            </label>
            <input
              type="text"
              className="acc-input-text"
              value={fieldData.data.value}
              placeholder={fieldData.label}
              onChange={fieldChangeHandler}
            />
            {fieldData?.errorMessage?.length > 0 && (
              <p className="acc-error-message">{fieldData?.errorMessage}</p>
            )}
          </div>
          {fieldData.data.toolTip.pageIndex?.length > 0 && (
            <p className="acc-page-info">
              <span>Page: {fieldData.data.toolTip.pageIndex}</span>
            </p>
          )}
        </div>
      );
    case LeaseAbstractionFieldType.SELECT:
      return (
        <div className="file-modal-group-container">
          <div className="file-modal-group">
            <label className="acc-field-label">
              {fieldData.label}
              {/* <Tooltip
              size="small"
              position="right"
              message={
                <>
                  <p className="acc-tooltip-heading">
                    {fieldData.data.toolTip.section}
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Paragraph: {fieldData.data.toolTip.paragraph}</span>
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Page: {fieldData.data.toolTip.pageIndex}</span>
                  </p>
                </>
              }
            >
              <Icon iconName="info-circle" variant="primary" />
            </Tooltip> */}
            </label>
            <Select
              defaultOption={
                fieldData.data.value?.length > 0
                  ? fieldData.data.value
                  : 'Select'
              }
              showDefault={fieldData.data.value?.length === 0}
              options={fieldData.options}
              onChange={fieldChangeHandler}
            />
          </div>
          {fieldData.data.toolTip.pageIndex?.length > 0 && (
            <p className="acc-page-info">
              <span>Page: {fieldData.data.toolTip.pageIndex}</span>
            </p>
          )}
        </div>
      );
    case LeaseAbstractionFieldType.PHONE:
      return (
        <div className="file-modal-group-container">
          <div className="file-modal-group">
            <label className="acc-field-label">
              {fieldData.label}
              {/* <Tooltip
              size="small"
              position="right"
              message={
                <>
                  <p className="acc-tooltip-heading">
                    {fieldData.data.toolTip.section}
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Paragraph: {fieldData.data.toolTip.paragraph}</span>
                  </p>
                  <p className="acc-tooltip-body">
                    <span>Page: {fieldData.data.toolTip.pageIndex}</span>
                  </p>
                </>
              }
            >
              <Icon iconName="info-circle" variant="primary" />
            </Tooltip> */}
            </label>
            <PhoneInput
              value={fieldData.data.value}
              onChange={fieldChangeHandler}
              hideLabel
            />
          </div>
          {fieldData.data.toolTip.pageIndex?.length > 0 && (
            <p className="acc-page-info">
              <span>Page: {fieldData.data.toolTip.pageIndex}</span>
            </p>
          )}
        </div>
      );
    default:
      break;
  }
  return <div />;
};

LeaseAbstractionField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fieldData: PropTypes.object,
  fieldType: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  fieldChangeHandler: PropTypes.func,
  fieldValidityChangeHandler: PropTypes.func,
};

LeaseAbstractionField.defaultProps = {
  fieldData: {},
  fieldType: 1,
  fieldChangeHandler: () => {},
  fieldValidityChangeHandler: () => {},
};

export { LeaseAbstractionField, LeaseAbstractionFieldType };
