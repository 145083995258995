import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import isString from 'lodash/isString';
import superFetch from '../../utils/super-fetch';

// The term 'Commissions' here includes
// broker allocations, rebates, fee shares, and outside broker commissions.
// The other term 'Commission' from commissions agreement
// (considerations and additional commissions) is the certain portion of
// entire transaction finance that goes to all different parties discribed above.

const url = '/commissions';
const poolUrl = '/mta/poolaccounts';
const officeUrl = '/mta/office?is_mta_lead_office=true&ordering=managing_office_name';
const functionalUnitUrl = '/mta/functionalunits';

const PAYMENT_GROUPS = {
  CBRE_BROKER: 1,
  OUTSIDE_BROKER: 2,
  REBATE: 3,
  FEE_SHARE: 4,
  TAX: 5,
};

const BROKER_TYPES = {
  EMPLOYEE: 'employee',
  OFFICE: 'office',
  POOL: 'pool',
};

const ALLOWED_STRINGS_ATTRIBUTES = {
  deptid: true,
  officename: true,
};

const convertEmptyStringToNull = (data) => {
  const dataCopy = cloneDeep(data);
  forEach(dataCopy, (value, key) => {
    if (!value && isString(value) && !ALLOWED_STRINGS_ATTRIBUTES[key]) {
      dataCopy[key] = null;
    }
  });
  return dataCopy;
};

let poolAccounts;
const fetchPoolAccounts = (params) => {
  if (poolAccounts) return Promise.resolve(poolAccounts);
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(poolUrl, options)
    .then(data => data.json())
    .then((data) => {
      poolAccounts = data;
      return data || [];
    });
};

let officeNames;
const fetchOfficeNames = () => {
  if (officeNames) return Promise.resolve(officeNames);
  const options = {
    method: 'GET',
  };
  return superFetch(officeUrl, options)
    .then(data => data.json())
    .then((data) => {
      officeNames = data;
      return data || [];
    });
};

let functionalUnits;
const fetchFunctionalUnits = () => {
  if (functionalUnits) return Promise.resolve(functionalUnits);
  const options = {
    method: 'GET',
  };
  return superFetch(functionalUnitUrl, options)
    .then(data => data.json())
    .then((data) => {
      functionalUnits = data;
      return data || [];
    });
};

// service methods
const fetchOptions = () => {
  const options = {
    method: 'OPTIONS',
  };

  return superFetch(url, options)
    .then(data => data.json());
};

// params = {
//   transaction: deal_id,
//   payment_group: payment_group,
//   broker_is_lead: Boolean,
//   ...
// }
const fetchCommissions = (params) => {
  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json())
    .then(data => data.results || []);
};

const updateCommission = (id, dataObj) => {
  const options = {
    method: 'PATCH',
  };

  const body = JSON.stringify(convertEmptyStringToNull(dataObj));

  return superFetch(`${url}/${id}`, options, body)
    .then(data => data.json());
};

const createCommission = async (params) => {
  const options = {
    method: 'POST',
  };

  const body = JSON.stringify(params);

  const data = await superFetch(url, options, body);
  return data.json();
};

const deleteCommission = async (id) => {
  const options = {
    method: 'DELETE',
  };
  await superFetch(`${url}/${id}`, options);
};

const getSortedBrokerCommissions = (commissions) => {
  const sortedCommissions = {
    cbreBrokerCommissions: [],
    officeCommissions: [],
    poolCommissions: [],
  };

  forEach(commissions, (commission) => {
    switch (commission.cbre_broker_type) {
      case BROKER_TYPES.EMPLOYEE:
        sortedCommissions.cbreBrokerCommissions.push(commission);
        break;
      case BROKER_TYPES.OFFICE:
        sortedCommissions.officeCommissions.push(commission);
        break;
      case BROKER_TYPES.POOL:
        sortedCommissions.poolCommissions.push(commission);
        break;
      default:
    }
  });
  return sortedCommissions;
};

export {
  PAYMENT_GROUPS,
  BROKER_TYPES,
  fetchOptions,
  fetchCommissions,
  updateCommission,
  createCommission,
  deleteCommission,
  getSortedBrokerCommissions,
  fetchPoolAccounts,
  fetchOfficeNames,
  fetchFunctionalUnits,
};
