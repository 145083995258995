import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Avatar from '../../nucleus/avatar/avatar';
import subroutes from './admin-subroutes';
import PageHeaderDefault from '../../nucleus/header/page-header-default';

const AdminPage = (props) => {
  const {
    userData,
    userOptions,
  } = props;

  useEffect(() => {
    document.title = 'Deal IQ | Admin';
  });

  const renderUserName = () => {
    const {
      first_name,
      last_name,
      avatar,
    } = props.userData || {};

    return (
      <div className="title-container">
        <Avatar
          enableHover
          firstName={first_name}
          hoverAlign="left"
          image={avatar}
          lastName={last_name}
          sizing="lg"
        />
        <div className="title">
          {
            first_name && last_name
              ? `${first_name} ${last_name}`
              : '(You)'
          }
          <br />
          <label>Admin</label>
        </div>
      </div>
    );
  };

  const renderTabs = () => {
    const {
      auth_groups,
    } = props.userData;
    return [
      {
        link: '/admin/labs',
        text: 'Labs',
      },
      {
        link: '/admin/teams',
        text: 'Teams',
        hidden: !(auth_groups && (auth_groups.indexOf('can_access_customer_success_tools') > -1)),
      },
      {
        link: '/admin/linking',
        text: 'Linking',
        hidden: !(auth_groups && (auth_groups.indexOf('can_access_customer_success_tools') > -1)),
      },
    ];
  };

  const redirect = () => {
    const {
      auth_groups,
    } = userData;

    if (!auth_groups || Object.keys(auth_groups).length === 0) {
      return null;
    } else if (!(auth_groups && (auth_groups.indexOf('can_access_customer_success_tools') > -1))) {
      return <Redirect to="/notfound" />;
    }

    return null;
  };

  return (
    <div className="admin-page">
      <PageHeaderDefault
        title={renderUserName()}
        tabs={renderTabs()}
        pageUrl={props.location.pathname}
      />
      {subroutes.map((route) => {
        const RouteComponent = route.component;
        return (<Route
          path={route.path}
          exact={route.exact}
          key={route.path}
          render={() => (<RouteComponent
            userOptions={userOptions}
            user={userData}
            avatar={userData.avatar}
            userData={userData}
            groups={props.groups}
            params={props.params}
          />)}
        />);
      })}
      { props.location.pathname === '/admin/page' &&
        <Redirect to="/admin/labs" />
      }
      {redirect()}
    </div>
  );
};

AdminPage.propTypes = {
  userData: PropTypes.shape({
    auth_groups: PropTypes.arrayOf(PropTypes.string),
    avatar: PropTypes.string,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  userOptions: PropTypes.shape({}),
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  params: PropTypes.shape({}),
};

AdminPage.defaultProps = {
  userOptions: {},
  groups: [],
  userData: {
    auth_groups: [],
  },
  location: {
    pathname: '',
  },
  params: {},
};

export default AdminPage;
