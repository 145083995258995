import React from 'react';
import PropTypes from 'prop-types';

import FileUploadAccordian from '../../file-upload-accordian';
import {
  LeaseAbstractionField,
  LeaseAbstractionFieldType,
} from '../../leaseAbstractionField';

import '../../lease-abstraction.scss';

const GeneralInformation = ({
  modalFieldState,
  modalFieldChangeHandler,
  fieldErrorMap,
}) => (
  <FileUploadAccordian accordianTitle="Genral Information">
    <LeaseAbstractionField
      key="tenantRepresentCompany"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.tenantRepresentCompany,
        label: 'Tenant Represented By',
        errorMessage: fieldErrorMap.tenantRepresentCompany,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantRepresentCompany', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="tenantRepresentAgent"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.tenantRepresentAgent,
        label: 'Tenant Represented By Agent',
        errorMessage: fieldErrorMap.tenantRepresentAgent,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('tenantRepresentAgent', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="landlordRepresentCompany"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.landlordRepresentCompany,
        label: 'Landlord Represented By',
        errorMessage: fieldErrorMap.landlordRepresentCompany,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('landlordRepresentCompany', {
          value: event.target.value,
        });
      }}
    />
    <LeaseAbstractionField
      key="landlordRepresentAgent"
      fieldType={LeaseAbstractionFieldType.TEXT}
      fieldData={{
        data: modalFieldState.landlordRepresentAgent,
        label: 'Landlord Represented By Agent',
        errorMessage: fieldErrorMap.landlordRepresentAgent,
      }}
      fieldChangeHandler={(event) => {
        modalFieldChangeHandler('landlordRepresentAgent', {
          value: event.target.value,
        });
      }}
    />
  </FileUploadAccordian>
);

GeneralInformation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  modalFieldState: PropTypes.object,
  modalFieldChangeHandler: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fieldErrorMap: PropTypes.object,
};

GeneralInformation.defaultProps = {
  modalFieldState: {},
  modalFieldChangeHandler: () => {},
  fieldErrorMap: {},
};

export default GeneralInformation;
