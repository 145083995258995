import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from '@cbrebuild/blocks';
import { Card } from '../../nucleus/cards/cards';

// Components
import PageHeaderDefault from '../../nucleus/header/page-header-default';

const ToolsPage = () => {
  document.title = 'Deal IQ | Tools';
  return (
    <div className="tools-page">
      <PageHeaderDefault
        title="Tools"
      />
      <div className="page-container">
        <div className="col-container">
          <div className="col flex-1">
            <Card>
              <h2>
                <Icon iconName="cash-dollars" />
                Voucher Search
              </h2>
              <div>Identify previous deals with companies.</div>
              <div className="center">
                <Link to="/tools/vouchers">
                  <Button variant="primary">Look Up Vouchers</Button>
                </Link>
              </div>
            </Card>
          </div>
          <div className="col flex-1">
            <Card>
              <h2>
                <Icon iconName="bullets-list" />
                SPOC List
              </h2>
              <div>Get a list of Single Points of Contact details from companies.</div>
              <div className="center">
                <a href="https://cbre.box.com/shared/static/5vgwu10xako4ean1yonzwkl0er6mo4p6.xlsx">
                  <Button>Download List</Button>
                </a>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolsPage;
