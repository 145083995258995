import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Currency from '../../../nucleus/formats/currency';
import Percent from '../../../nucleus/formats/percent';
import ExpandToggle from '../../../nucleus/expand-toggle/expand-toggle';

const CommissionsOverview = (props) => {
  const [isInternalBrokersExpanded, setIsInternalBrokersExpanded] = useState(false);
  const [isExternalBrokersExpanded, setIsExternalBrokersExpanded] = useState(false);

  const { data } = props;

  const expandInternalBrokers = data.CBRE.items.length > 0 && (
    <ExpandToggle onClick={() => setIsInternalBrokersExpanded(!isInternalBrokersExpanded)} isOpen={isInternalBrokersExpanded} />
  );

  const expandExternalBrokers = data['Outside Broker(age)'].items.length > 0 && (
    <ExpandToggle onClick={() => setIsExternalBrokersExpanded(!isExternalBrokersExpanded)} isOpen={isExternalBrokersExpanded} />
  );

  return (
    <div className="commissions-card">
      <h2 className="header-row">Commissions Overview</h2>
      <table>
        <colgroup>
          <col name="expand" style={{ width: 'fit-content' }} />
          <col name="party" width="35%" />
          <col name="amount" width="30%" />
          <col name="allocation" width="30%" />
        </colgroup>
        <tbody>
          <tr className="borderless-row">
            <th />
            <th>Party</th>
            <th>Amount</th>
            <th>Allocation</th>
          </tr>
          <tr className={`${isInternalBrokersExpanded && 'borderless-row'} text-dark text-semi-bold`}>
            <td>{expandInternalBrokers}</td>
            <td>CBRE</td>
            <td><Currency value={data.CBRE.total_amount} /></td>
            <td><Percent value={data.CBRE.allocation} decimalRounding={2} /></td>
          </tr>
          {isInternalBrokersExpanded && data.CBRE.items.map((item, idx) => (
            <tr className={`${data.CBRE.items.length - 1 !== idx && 'borderless-row'} sub-row`} key={item.broker_name}>
              <td />
              <td>{item.broker_name}</td>
              <td><Currency value={item.amount} /></td>
              <td><Percent value={item.broker_allocation} decimalRounding={2} /></td>
            </tr>
          ))}
          <tr className={`${isExternalBrokersExpanded && 'borderless-row'} text-dark text-semi-bold`}>
            <td>{expandExternalBrokers}</td>
            <td>Outside Brokers</td>
            <td><Currency value={data['Outside Broker(age)'].total_amount} /></td>
            <td><Percent value={data['Outside Broker(age)'].allocation} decimalRounding={2} /></td>
          </tr>
          {isExternalBrokersExpanded && data['Outside Broker(age)'].items.map((item, idx) => (
            <tr className={`${data['Outside Broker(age)'].items.length - 1 !== idx && 'borderless-row'} sub-row`} key={item.vendor_name}>
              <td />
              <td>{item.vendor_name}</td>
              <td><Currency value={item.amount} /></td>
              <td><Percent value={item.vendor_allocation} decimalRounding={2} /></td>
            </tr>
          ))}
          <tr className="text-dark text-semi-bold">
            <td />
            <td>Rebates</td>
            <td><Currency value={data.Rebate.total_amount} /></td>
            <td><Percent value={data.Rebate.allocation} decimalRounding={2} /></td>
          </tr>
          <tr className="text-dark text-semi-bold">
            <td />
            <td>Fee Sharing</td>
            <td><Currency value={data['Payment to House'].total_amount} /></td>
            <td><Percent value={data['Payment to House'].allocation} decimalRounding={2} /></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CommissionsOverview;

CommissionsOverview.propTypes = {
  data: PropTypes.shape({
    CBRE: PropTypes.shape({
      allocation: PropTypes.number,
      total_amount: PropTypes.number,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    'Outside Broker(age)': PropTypes.shape({
      allocation: PropTypes.number,
      total_amount: PropTypes.number,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    'Payment to House': PropTypes.shape({
      allocation: PropTypes.number,
      total_amount: PropTypes.number,
    }),
    Rebate: PropTypes.shape({
      allocation: PropTypes.number,
      total_amount: PropTypes.number,
    }),
  }).isRequired,
};
