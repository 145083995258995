const formatNumberWithCommas = (input) => {
  if (typeof input !== 'string' && typeof input !== 'number') {
    console.error('formatNumberWithCommas expected type number or string but received type', typeof input);
    return '';
  }

  if (typeof input === 'number') {
    input = input.toString(); // eslint-disable-line
  }

  // handle negatives
  const isNegative = Number(input) < 0;
  if (isNegative) {
    input = Math.abs(Number(input)).toString(); // eslint-disable-line
  }

  // handle decimals
  let hasDecimal = false;
  let afterDecimal;
  if (input.indexOf('.') !== -1) {
    hasDecimal = true;
    afterDecimal = input.split('.')[1]; // eslint-disable-line
    input = input.split('.')[0]; // eslint-disable-line
  }

  // add the commas
  let commaFormatted = '';
  let charactersAdded = 0;
  for (let i = input.length - 1; i >= 0; i--) {
    if (charactersAdded > 0 && charactersAdded % 3 === 0) {
      commaFormatted = `,${commaFormatted}`;
    }

    commaFormatted = input[i] + commaFormatted;
    charactersAdded++;
  }

  if (hasDecimal) {
    commaFormatted = `${commaFormatted}.${afterDecimal}`;
  }

  if (isNegative) {
    commaFormatted = `-${commaFormatted}`;
  }

  return commaFormatted;
};

export default formatNumberWithCommas;
