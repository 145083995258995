import { connect } from 'react-redux';
import { loadDealAndTransactionCount } from '../actions/deals-actions';

import Sidebar from '../../components/navs/sidebar';

const mapStateToProps = state => ({
  dealStage: state.deal.deal ? state.deal.deal.deal_life_cycle_stage : null,
  dealCountProspect: state.deals.dealCountProspect || 0,
  dealCountExecuting: state.deals.dealCountExecuting || 0,
  dealCountClosed: state.deals.dealCountClosed || 0,
  dealCountError: state.deals.dealCountError,
  featureFlags: state.userOptions.userOptions.feature_flags,
});

const SidebarContainer = connect(
  mapStateToProps,
  { loadDealAndTransactionCount },
)(Sidebar);

export default SidebarContainer;
