import { Button, IconButton } from '@cbrebuild/blocks';
import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../nucleus/confirmation-modal/confirmation-modal';

const SimpleConfirmationModal = ({
  showModal = false,
  onCancel,
  onAccept,
  children,
}) => (
  <ConfirmationModal
    className="deal-file-delete-confirmation tenant-modal voucher-modal"
    onCloseModal={onCancel}
    showModal={showModal}
  >
    <div className="header-modal">
      <h1 className="header-content conf-header">Confirmation</h1>
      <IconButton
        iconName="close-circle"
        className="icon-circle"
        variant="basic"
        onClick={onCancel}
      />
    </div>
    <div className="conf-text">
      <p>
        {children}
      </p>
    </div>
    <footer className="actions">
      <Button
        className="cancel-btn"
        variant="secondary"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        className="upload-btn ok-btn"
        onClick={onAccept}
      >Ok
      </Button>
    </footer>
  </ConfirmationModal>
);

SimpleConfirmationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
};


export default SimpleConfirmationModal;
