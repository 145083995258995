import React from 'react';
import PropTypes from 'prop-types';
import TaskListPanel from './task-list-panel';

const TasksTab = (props) => {
  const {
    analyticProperties,
    addTask,
    completeTask,
    deal,
    deleteTask,
    fetchTasks,
    isLoading,
    next,
    tasks,
    updateTask,
    user,
    taskId,
    count,
  } = props;
  return (
    <div className="tasks-tab detail-panels-layout">
      <div className="col-two-thirds">
        <TaskListPanel
          analyticProperties={analyticProperties}
          deal={deal}
          isLoading={isLoading}
          next={next}
          tasks={tasks}
          user={user}
          addTask={addTask}
          completeTask={completeTask}
          deleteTask={deleteTask}
          fetchTasks={fetchTasks}
          updateTask={updateTask}
          taskId={taskId}
          count={count}
          showFSS
        />
      </div>
    </div>
  );
};

TasksTab.propTypes = {
  analyticProperties: PropTypes.shape({
    actionPrefix: PropTypes.string,
    categoryPrefix: PropTypes.string,
  }).isRequired,
  deal: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  next: PropTypes.number,
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  addTask: PropTypes.func.isRequired,
  completeTask: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  fetchTasks: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  taskId: PropTypes.number,
  count: PropTypes.number,
};
TasksTab.defaultProps = {
  next: null,
  taskId: undefined,
  count: undefined,
};

export default TasksTab;
