/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import omit from 'lodash/omit';

import useLocalStorage from '../../hooks/use-local-storage';
import makeCancelable from '../../utils/make-cancelable';
import installmentsService from '../../services/voucher/installments-service';
import MyInstallmentsTable from '../../components/commissions/my-installments-table';
import CommissionsFilter from '../../components/commissions/commissions-filter';
import CommissionsFilterTags from '../../components/commissions/commissions-filter-tags';
import LoadMore from '../../nucleus/load-more/load-more';
import ListActionHeader from '../../nucleus/list-action-header/list-action-header';
import ListSort from '../../nucleus/list-sort/list-sort';
import SearchInput from '../../nucleus/search/search-input';
import ProducerFilters from '../../components/pipeline/producer-filters';
import trackingService from '../../services/tracking-service';

const MyInstallments = () => {
  const sortingKeys = [
    {
      displayName: 'Due Date',
      ascSortingKey: 'installment_date',
      descSortingKey: '-installment_date',
    },
    {
      displayName: 'Amount',
      ascSortingKey: '-installment_amount',
      descSortingKey: 'installment_amount',
    },
    {
      displayName: 'Payment Date',
      ascSortingKey: 'paid_date',
      descSortingKey: '-paid_date',
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [installments, setInstallments] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [lastFetch, setLastFetch] = useState(null);
  const [cachedParams, setCachedParams] = useLocalStorage('myInstallmentsParams', {});
  const [brokers, setBrokers] = useState([]);
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    page_size: 25,
    is_mta: true,
    users: [],
    brokers: [],
    ordering: sortingKeys[0].descSortingKey,
    payment_status: ['Paid', 'Receivable', 'Upcoming', 'Write Off'],
    ...cachedParams,
  });

  const [memberParams, setMemberParams] = useState({
    is_mta: true,
  });

  const cacheParams = (allParams) => {
    const paramKeysToOmit = ['page', 'page_size', 'is_mta'];
    const paramsToCache = omit(allParams, paramKeysToOmit);
    setCachedParams(paramsToCache);
  };

  const getMembers = async () => {
    installmentsService.getMetadata(memberParams).then((response) => {
      setUsers(response.users);
      setBrokers(response.brokers);
      setHasNextPage(!!response.next);
      setLastFetch(null);
    });
  };

  useMemo(() => {
    const handleFetch = async () => {
      setIsLoading(true);
      cacheParams(params);
      const cancelableFetch = makeCancelable(installmentsService.fetchAllInstallments(params));

      if (lastFetch) {
        lastFetch.cancel();
      }

      setLastFetch(cancelableFetch);
      const response = await cancelableFetch.promise;
      const newInstallments = params.page === 1 ? response.results : installments.concat(response.results);
      setInstallments(uniqBy(newInstallments, 'id'));
      setCount(response.count);
      setHasNextPage(!!response.next);
      setLastFetch(null);
      setIsLoading(false);
    };

    getMembers();
    handleFetch();
  }, [params]);

  const loadMoreInstallments = () => {
    setParams({
      ...params,
      page: params.page + 1,
    });
  };

  const handlefilterChange = (value, key) => {
    let updatedParams = {
      [key]: value,
    };

    // auto select Paid when using Date Paid filter
    if ((key === 'paid_start_date' || key === 'paid_end_date') && !params.payment_status?.includes('Paid')) {
      const v = 'Paid';
      const k = 'payment_status';
      updatedParams = {
        ...updatedParams,
        [k]: (params.payment_status ? [...params.payment_status, v] : [v]),
      };
    }

    setParams({
      ...params,
      ...updatedParams,
      page: 1,
    });
  };

  const handleSearchTermChange = (search) => {
    setParams({
      ...params,
      search,
      page: 1,
    });
  };

  const handleSortChange = (ordering) => {
    setParams({
      ...params,
      ordering,
      page: 1,
    });
  };

  const handleFilterTagChange = (newParams) => {
    setParams({
      ...params,
      ...newParams,
      page: 1,
    });
  };

  const applyParams = (newParams) => {
    setParams({
      ...params,
      ...newParams,
      page: 1,
    });
  };

  const filterByUser = ({ target: { name, checked } }) => {
    const ids = (params.users || []).filter(a => a !== +name);
    applyParams({
      users: !checked
        ? ids
        : [...ids, +name],
    });
  };

  const filterByBroker = ({ target: { name, checked } }) => {
    const ids = (params.brokers || []).filter(a => a !== +name);
    applyParams({
      brokers: !checked
        ? ids
        : [...ids, +name],
    });
  };

  const filterByAllUsersAndBrokers = (checked) => {
    applyParams({
      brokers: !checked ? [] : brokers.map(t => t.id),
      users: !checked ? [] : users.map(u => u.id),
    });
  };

  const trackFilters = (isFilterClosed) => {
    const prefix = 'installment_producer_filter_';
    const category = 'Installment Filter';
    const label = 'on_filter';
    trackingService.trackFilters(prefix, category, label, { params }, count, isFilterClosed);
  };

  return (
    <div className="card-default my-installments" >
      <ListActionHeader
        filter={(
          <div className="installment-filters-container">
            <CommissionsFilter
              type="installments"
              currentParams={(typeof params.payment_status === 'string'
                ? { ...params, payment_status: [] }
                : params)}
              filterByKey={debounce(handlefilterChange, 300)}
            />
            <ProducerFilters
              currentParams={params}
              brokers={brokers}
              users={users}
              filterByBroker={filterByBroker}
              filterByUser={filterByUser}
              filterByAllUsersAndTeams={filterByAllUsersAndBrokers}
              onFilterOpen={() => trackFilters(false)}
              onFilterClose={() => trackFilters(true)}
              isInstallmentFlow
            />
          </div>
        )}
        search={(
          <SearchInput
            initValue={params.search}
            handleSearchTermChange={debounce(handleSearchTermChange, 300)}
            searchKey="installments"
          />
        )}
        sort={(
          <ListSort
            currentSortingKey={params.ordering}
            onChange={handleSortChange}
            options={sortingKeys}
          />
        )}
        isLoading={isLoading}
      />
      <CommissionsFilterTags
        currentParams={(typeof params.payment_status === 'string'
          ? { ...params, payment_status: [] }
          : params)}
        applyParams={handleFilterTagChange}
        teams={[]}
        brokers={brokers}
        users={users}
      />
      <MyInstallmentsTable installments={installments} isLoading={isLoading} />
      <LoadMore
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        handleLoadMore={loadMoreInstallments}
      />
    </div>
  );
};

export default MyInstallments;
