import { useEffect, useRef, useState } from 'react';
import { isValidPostal } from '../components/voucher/voucher-validation';
import companiesService, { defaultCompanyData } from '../services/companies-service';

const eventTargetType = {
  text: true,
  number: true,
};

export default function useCompanyState(saved = {}, type, onUpdate) {
  const { id } = saved;
  const defaultCountry = 'United States';
  const mounted = useRef(true);

  const [company, setCompany] = useState({});

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (id) {
      companiesService.getCompany(id).then((res) => {
        if (!mounted.current) return;
        setCompany(res);
      }).catch(() => {
        if (!mounted.current) return;
        setCompany({});
      });
    } else {
      if (!mounted.current) return;
      setCompany({ ...company, country: defaultCountry });
    }
  }, [id]);

  const onCompanyBlur = ({ target } = {}) => {
    if (!target || saved[target.name] === target.value) return;
    if (target.name === 'postal' && !isValidPostal(target.value, company.country)) {
      setCompany({ ...company, postal: '' });
      // eslint-disable-next-line no-param-reassign
      target.value = null;
    }
    let patch = {};
    if (target.hasMultiple) {
      target.name.forEach((name, index) => {
        patch[name] = target.value[index];
      });
    } else {
      patch = target.name !== 'country' ? { [target.name]: target.value } : {
        [target.name]: target.value,
        territory: null,
      };
    }
    if (!company.id) {
      companiesService.createCompany({ ...defaultCompanyData, ...patch })
        .then((res) => {
          onUpdate({ [type]: res.id });
        });
    } else if (target.name === 'name') {
      companiesService.createCompanyHelper({ ...company, name: saved.name }, company)
        .then((res) => {
          onUpdate({ [type]: res.id });
        });
    } else {
      companiesService.patchCompany(company.id, patch).then((res) => {
        onUpdate({ [type]: res.id });
      });
    }
  };

  const onCompanyChange = ({ target }) => {
    // make check the type and call blur if not a text or number field?
    if (eventTargetType[target.type]) {
      setCompany({ ...company, [target.name]: target.value });
    } else {
      let patch = {};
      if (target.hasMultiple) {
        target.name.forEach((name, index) => {
          patch[name] = target.value[index];
        });
      } else {
        patch = target.name !== 'country' ? { [target.name]: target.value } : {
          [target.name]: target.value,
          territory: null,
        };
      }
      setCompany({ ...company, ...patch });
      onCompanyBlur({ target });
    }
  };

  const onCompanyCreate = () => {
    companiesService.createCompany({
      name: null,
      country: 'United States',
      address: null,
      address2: null,
      city: null,
      territory: null,
      postal: null,
    }).then((res) => {
      setCompany(res);
      onUpdate({ [type]: res });
    });
  };

  return {
    company,
    onCompanyBlur,
    onCompanyCreate,
    onCompanyChange,
  };
}
