import React from 'react';
import PropTypes from 'prop-types';

import ButtonSegmentedGroup from '../../nucleus/button-segmented-group/button-segmented-group';
import CompanyAutocomplete from '../autocomplete/company-autocomplete';
import Notice from '../../nucleus/notices/notice';

const DealInformation = (props) => {
  const {
    dealName,
    company,
    dealType,
    initCompanySearchTerm,
    initLandlordSearchTerm,
    setDealName,
    setDealStage,
    setCompany,
    setLandlordCompany,
    errorMessage,
    showCompanyWarning,
    onCompanyAlertDismiss,
  } = props;

  const dealStageOptions = [
    {
      display_name: 'Prospecting',
      value: 'prospect',
      dataE2e: 'radio-prospecting',
    },
    {
      display_name: 'Executing',
      value: 'executing',
      dataE2e: 'radio-executing',
    },
  ];

  // for DUPE deal, check the props.dealStage, set
  // dealStage toggle accordingly
  const dealStageOption = props.dealStage && props.dealStage === 'executing' ?
    dealStageOptions[1] : dealStageOptions[0];

  const createCompanyAlert = showCompanyWarning && (
    <Notice
      text={` New Company "${company}" will automatically be created.`}
      onDismiss={onCompanyAlertDismiss}
      type="alert"
    />
  );
  const companyLabelText = dealType.value === 'landlord' ? 'Prospective Tenant' : 'Company';

  const isLandlordDeal = dealType.value === 'landlord';

  return (
    <section className="tenant-information">
      <div className="field">
        <label>* {companyLabelText}</label>
        <CompanyAutocomplete
          placeholder="Search for Company"
          dataE2e="new-deal-company-textbox"
          onChange={setCompany}
          errorMessage={errorMessage}
          initSearchTerm={initCompanySearchTerm}
        />
        {createCompanyAlert}
      </div>
      {isLandlordDeal ? (
        <div className="field">
          <label>Landlord</label>
          <CompanyAutocomplete
            placeholder="Search for Company"
            dataE2e="new-deal-counter-party-textbox"
            onChange={setLandlordCompany}
            initSearchTerm={initLandlordSearchTerm}
          />
        </div>
      ) : ''}
      <div className="field deal-name">
        <div className="name-your-deal">
          <label>Name Your Deal</label>
          <input
            id="deal-name"
            data-e2e="new-deal-deal-name"
            value={dealName}
            onChange={e => setDealName(e.target.value)}
            type="text"
          />
        </div>
        <div className="deal-stage-controls">
          <ButtonSegmentedGroup
            options={dealStageOptions}
            onChange={type => setDealStage(type.value)}
            selected={dealStageOption}
          />
        </div>
      </div>
    </section>
  );
};

DealInformation.propTypes = {
  dealName: PropTypes.string,
  dealStage: PropTypes.string,
  dealType: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  company: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  initCompanySearchTerm: PropTypes.string,
  initLandlordSearchTerm: PropTypes.string,
  setDealName: PropTypes.func.isRequired,
  setDealStage: PropTypes.func.isRequired,
  setCompany: PropTypes.func.isRequired,
  setLandlordCompany: PropTypes.func.isRequired,
  onCompanyAlertDismiss: PropTypes.func.isRequired,
  showCompanyWarning: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

DealInformation.defaultProps = {
  dealName: '',
  dealStage: 'prospect',
  errorMessage: '',
  initCompanySearchTerm: '',
  initLandlordSearchTerm: '',
  company: {},
};
export default DealInformation;
