import {
  addNote as addDealNote,
  deleteNote as deleteDealNote,
  fetchNotes as fetchDealNotes,
  updateNote as updateDealNote,
} from '../../services/notes-service';
import {
  ADD_DEAL_NOTE_SUCCESS,
  ADD_DEAL_NOTE_FAILURE,
  DELETE_DEAL_NOTE_SUCCESS,
  DELETE_DEAL_NOTE_FAILURE,
  IS_LOADING_NOTES_ACTION,
  FETCH_DEAL_NOTES_SUCCESS,
  FETCH_DEAL_NOTES_FAILURE,
  UPDATE_DEAL_NOTE_SUCCESS,
  UPDATE_DEAL_NOTE_FAILURE,
  NEW_NOTE_CLEANUP,
} from './action-types';
import { toast } from './toasts-actions';

const addDealNoteSuccess = note => ({
  type: ADD_DEAL_NOTE_SUCCESS,
  payload: { ...note },
});

const deleteDealNoteSuccess = id => ({
  type: DELETE_DEAL_NOTE_SUCCESS,
  payload: { id },
});

const fetchDealNoteSuccess = response => ({
  type: FETCH_DEAL_NOTES_SUCCESS,
  payload: { ...response },
});

const isLoadingAction = isLoading => ({
  type: IS_LOADING_NOTES_ACTION,
  payload: { isLoading },
});

const updateDealNoteSuccess = note => ({
  type: UPDATE_DEAL_NOTE_SUCCESS,
  payload: note,
});

const handleError = (error, type) => ({
  type,
  payload: { error },
});

const newNoteCleanup = () => ({
  type: NEW_NOTE_CLEANUP,
});

const addNote = params => dispatch =>
  addDealNote(params)
    .then((note) => {
      dispatch(addDealNoteSuccess(note));
    }).catch((error) => {
      dispatch(handleError(error, ADD_DEAL_NOTE_FAILURE));
      dispatch(toast('Error Adding Note'));
    });

const deleteNote = id => dispatch =>
  deleteDealNote(id)
    .then(() => {
      dispatch(deleteDealNoteSuccess(id));
    }).catch((error) => {
      dispatch(handleError(error, DELETE_DEAL_NOTE_FAILURE));
      dispatch(toast('Error Deleting Note'));
    });

const fetchNotes = query => (dispatch) => {
  dispatch(isLoadingAction(true));
  return fetchDealNotes(query)
    .then(response => dispatch(fetchDealNoteSuccess(response)))
    .catch((error) => {
      dispatch(handleError(error, FETCH_DEAL_NOTES_FAILURE));
      dispatch(toast('Error Fetching Note'));
    })
    .then(() => dispatch(isLoadingAction(false)));
};

const updateNote = (id, params) => dispatch =>
  updateDealNote(id, params)
    .then((note) => {
      dispatch(updateDealNoteSuccess(note));
    }).catch((error) => {
      dispatch(handleError(error, UPDATE_DEAL_NOTE_FAILURE));
      dispatch(toast('Error Updating Note'));
    });

export {
  addNote,
  deleteNote,
  fetchNotes,
  updateNote,
  newNoteCleanup,
};
