import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import FilterTag from '../../nucleus/legacy/tag/filter-tag';
import StatusStrings from '../../utils/status-strings';

const PipelineFilterTags = (props) => {
  const {
    currentParams,
    applyParams,
    teams: allTeams,
    users: allUsers,
  } = props;

  const {
    estimated_commission_min,
    estimated_commission_max,
    deal_life_cycle_stage,
    rep_role,
    is_on_pipeline,
    is_untagged_deal,
    conversion_potential_min,
    conversion_potential_max,
    installment_date_range_after,
    installment_date_range_before,
    tags = [],
    teams = [],
    users = [],
  } = currentParams;

  // so zero vals still show as filter tags
  const commissionMin = typeof estimated_commission_min === 'number' ? String(estimated_commission_min) : null;
  const commissionMax = typeof estimated_commission_max === 'number' ? String(estimated_commission_max) : null;

  const probabilityMin = (conversion_potential_min === 0 && conversion_potential_max === 100) ? null : conversion_potential_min;
  const probabilityMax = (conversion_potential_min === 0 && conversion_potential_max === 100) ? null : conversion_potential_max;

  const teamMap = useMemo(() => allTeams.reduce((acc, team) => {
    acc[team.id] = team;
    return acc;
  }, {}), [allTeams]);

  const userMap = useMemo(() => allUsers.reduce((acc, producer) => {
    acc[producer.id] = producer;
    return acc;
  }, {}), [allUsers]);

  const getDealTypeRepRoleTag = repRoleArr => (repRoleArr &&
    repRoleArr.map(repRole => (
      StatusStrings.dealTypeAndRepRole.filter(option => (
        option.value === repRole))[0].display_name)).join(', '));

  const getDealStageTag = dealStages => dealStages &&
    dealStages.map(stage => (stage === 'prospect' ? 'prospecting' : stage)).join(', ');

  const potentialFilters = [
    {
      key: 'onPipeline',
      value: is_on_pipeline && 'On Pipeline',
    },
    {
      key: 'dealTypeRepRole',
      value: getDealTypeRepRoleTag(rep_role),
    },
    {
      key: 'dealStage',
      value: getDealStageTag(deal_life_cycle_stage),
    },
    {
      key: 'commissionRange',
      label: 'Commission',
      minValue: commissionMin,
      maxValue: commissionMax,
    },
    {
      key: 'probabilityRange',
      label: 'Probability',
      minValue: probabilityMin,
      maxValue: probabilityMax,
    },
    {
      key: 'installmentDateRange',
      label: 'Installment Date',
      minValue: installment_date_range_after,
      maxValue: installment_date_range_before,
    },
    {
      key: 'untaggedDeal',
      value: is_untagged_deal && 'Untagged Deals Only',
    },
    ...teams.filter(id => teamMap[id]).map(id => ({
      key: id,
      value: teamMap[id]?.name || '',
      type: 'team',
    })),
    ...users.filter(id => userMap[id]).map(id => ({
      key: id,
      value: `${userMap[id]?.first_name || ''} ${userMap[id]?.last_name || ''}`,
      type: 'user',
    })),
    ...tags.map(tag => ({
      key: tag,
      value: tag,
      type: 'custom',
    })),
  ];
  const appliedFilters = potentialFilters.filter(f => f.value || f.minValue || f.maxValue);

  const handleClearFilter = (key, type) => {
    switch (type || key) {
      case 'onPipeline': {
        const params = { is_on_pipeline: null };
        applyParams(params);
        break;
      }
      case 'dealStage': {
        const params = { deal_life_cycle_stage: null };
        applyParams(params);
        break;
      }
      case 'dealTypeRepRole': {
        const params = { rep_role: null };
        applyParams(params);
        break;
      }
      case 'commissionRange': {
        const params = {
          estimated_commission_min: null,
          estimated_commission_max: null,
        };
        applyParams(params);
        break;
      }
      case 'probabilityRange': {
        const params = {
          conversion_potential_min: null,
          conversion_potential_max: null,
        };
        applyParams(params);
        break;
      }
      case 'installmentDateRange': {
        const params = {
          installment_date_range_after: null,
          installment_date_range_before: null,
        };
        applyParams(params);
        break;
      }
      case 'untaggedDeal': {
        const params = { is_untagged_deal: null };
        applyParams(params);
        break;
      }
      case 'team': {
        const params = { teams: teams.filter(team => team !== key) };
        applyParams(params);
        break;
      }
      case 'user': {
        const params = { users: users.filter(user => user !== key) };
        applyParams(params);
        break;
      }
      case 'custom': {
        const params = { tags: tags.filter(tag => tag !== key) };
        applyParams(params);
        break;
      }
      default:
    }
  };

  const clearAllFilters = () => {
    const params = {
      is_on_pipeline: null,
      rep_role: null,
      deal_life_cycle_stage: null,
      estimated_commission_min: null,
      estimated_commission_max: null,
      conversion_potential_min: null,
      conversion_potential_max: null,
      installment_date_range_after: null,
      installment_date_range_before: null,
      tags: [],
      teams: [],
      users: [],
      is_untagged_deal: null,
    };
    applyParams(params);
  };

  return appliedFilters.length ? (
    <div className="filter-tags-container">
      {appliedFilters.map(filter => (
        <FilterTag
          className="filter-tag"
          key={filter.key}
          value={filter.value}
          label={filter.label}
          minValue={filter.minValue}
          maxValue={filter.maxValue}
          handleClearFilter={() => handleClearFilter(filter.key, filter.type)}
        />
      ))}
      {appliedFilters.length > 1 && (
        <span
          className="link-text"
          onClick={clearAllFilters}
        >
          Clear All
        </span>
      )}
    </div>
  ) : null;
};

export default PipelineFilterTags;

PipelineFilterTags.propTypes = {
  currentParams: PropTypes.shape({
    conversion_potential_min: PropTypes.number,
    conversion_potential_max: PropTypes.number,
    deal_life_cycle_stage: PropTypes.string,
    estimated_commission_min: PropTypes.number,
    estimated_commission_max: PropTypes.number,
    installment_date_range_after: PropTypes.string,
    installment_date_range_before: PropTypes.string,
    is_on_pipeline: PropTypes.bool,
    is_untagged_deal: PropTypes.bool,
    rep_role: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    teams: PropTypes.arrayOf(PropTypes.number),
    users: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  applyParams: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
