import React from 'react';
import PropTypes from 'prop-types';
import formatAsCurrency from '../../utils/format-as-currency';

const Currency = props => (
  <span className="nd-currency">
    {props.value ?
    formatAsCurrency(props.value, props.decimal) :
    props.emptyState}
  </span>
);

Currency.propTypes = {
  value: PropTypes.number,
  emptyState: PropTypes.string,
  decimal: PropTypes.number,
};

Currency.defaultProps = {
  value: null,
  emptyState: '--',
  decimal: 2,
};

export default Currency;
