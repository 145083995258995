import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton } from '@cbrebuild/blocks';

import AddItem from '../../../../nucleus/add-item/add-item';
import ExpandToggle from '../../../../nucleus/expand-toggle/expand-toggle';
import RowItem from '../../../../nucleus/row-item/row-item';
import { Person, PersonType } from '../../../person';

const ContactListItemHeader = ({
  contact,
  contact: {
    phone_numbers,
    email_addresses,
  },
  isEdit,
  isOpen,
  onEdit,
  onOpen,
}) => {
  // finds the primary item in a list by type by comparing type strings,
  // work > mobile > home, requirements are to show the first one found in that order
  const getPrimary = list => list.reduce((current, item) =>
    (item.type > current.type ? item : current), list[0]);

  const primaryPhone = getPrimary(phone_numbers);
  const primaryEmail = getPrimary(email_addresses);
  const collapsedClass = `primary-contact${isOpen ? '' : ' collapsed'}`;

  return (
    <RowItem
      additionalClassName={`contact-header${isEdit ? ' editing' : ''}`}
    >
      <div className="contact-details">
        <Person person={contact} type={PersonType.CONTACT} />
        {primaryPhone ? (
          <div className={collapsedClass}>
            <a href={`tel:${primaryPhone.number}`}>
              <Icon iconName="phone" />
              {primaryPhone.type.substr(0, 1).toUpperCase()}
              <span className="hide-on-mobile">: {primaryPhone.number}</span>
            </a>
          </div>
        ) :
          (
            <div className={collapsedClass}>
              <AddItem label="Phone" onClick={onEdit} />
            </div>
          )}
        {primaryEmail ? (
          <div className={collapsedClass}>
            <a href={`mailto:${primaryEmail.address}`}>
              <Icon iconName="envelope" />
              {primaryEmail.type.substr(0, 1).toUpperCase()}
              <span className="hide-on-mobile">: {primaryEmail.address}</span>
            </a>
          </div>
        ) :
          (
            <div className={collapsedClass}>
              <AddItem label="Email" onClick={onEdit} />
            </div>
          )}
      </div>
      <IconButton iconName="edit" onClick={isEdit ? undefined : onEdit} disabled={isEdit} />
      <ExpandToggle disabled={isEdit} isOpen={isOpen} onClick={isEdit ? undefined : onOpen} />
    </RowItem>
  );
};

ContactListItemHeader.propTypes = {
  contact: PropTypes.shape({
    phone_numbers: PropTypes.arrayOf(PropTypes.shape()),
    email_addresses: PropTypes.arrayOf(PropTypes.shape()),
    outlook_sync_enabled: PropTypes.bool,
  }).isRequired,
  isEdit: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
};

export default ContactListItemHeader;
