/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';

import GlobalSearchContainer from '../../redux/containers/global-search-container';
import Navatar from './navatar';
import NDButton from '../../nucleus/button/button';
import NotificationsAlertsContainer from '../../redux/containers/notification-alerts-container';
import TaskAlertsContainer from '../../redux/containers/task-alerts-container';

import userEventService from '../../services/user-event-service';
import notificationsService from '../../services/notifications-service';

class MainHeader extends React.Component {
  constructor(props) {
    super(props);
    // register for notifications only if browser supports webPush
    this.isPushEnabled = ('serviceWorker' in navigator && 'PushManager' in window);
  }

  componentDidMount() {
    if (this.isPushEnabled && window.firebase && !window.firebase.apps.length) {
      notificationsService.init(
        this.props.setNotificationsState,
        this.props.setTaskAlertsState,
      );
    }
  }

  onSupportButtonClick = () => {
    const eventMetadata = {
      eventCategory: 'Intercom',
      eventAction: 'intercom_opened',
    };
    userEventService.trackEvent(eventMetadata);
  };

  render() {
    const {
      openMenu,
      turducken,
      userData,
      userOptions,
      proxy,
    } = this.props;

    const menuButton = (
      <div className="hamburger">
        <NDButton
          className="hamburger-button"
          variant="icon"
          onClick={openMenu}
          icon="menu"
        />
      </div>);

    const supportButton = (
      <NDButton
        variant="icon"
        icon="message"
        id="IntercomSupport"
        aria-label="Feedback"
        onClick={() => this.onSupportButtonClick()}
      />
    );

    return (
      <div className="top-nav">
        {menuButton}
        <div className="top-nav-content">
          <GlobalSearchContainer turducken={turducken} />
          <div className="action-buttons">
            <NotificationsAlertsContainer
              isPushEnabled={this.isPushEnabled}
              turducken={turducken}
            />
            <TaskAlertsContainer
              isPushEnabled={this.isPushEnabled}
              turducken={turducken}
            />
            {supportButton}
            <Navatar
              featureFlags={userOptions.feature_flags}
              turducken={turducken}
              user={userData}
              proxy={proxy}
            />
          </div>
        </div>
      </div>
    );
  }
}

MainHeader.propTypes = {
  openMenu: PropTypes.func.isRequired,
  setNotificationsState: PropTypes.func,
  setTaskAlertsState: PropTypes.func,
  userData: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
  userOptions: PropTypes.shape({
    bleeding_edge_toggle_on: PropTypes.bool,
    feature_flags: PropTypes.shape({
      teams_tab_flag: PropTypes.bool,
    }),
  }).isRequired,
  turducken: PropTypes.bool.isRequired,
  proxy: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

MainHeader.defaultProps = {
  setTaskAlertsState: null,
  setNotificationsState: null,
};

export default MainHeader;

