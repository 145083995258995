import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@cbrebuild/blocks';
import ButtonSegmentedGroup from '../../nucleus/button-segmented-group/button-segmented-group';
import StatusStrings from '../../utils/status-strings';
import userEventService from '../../services/user-event-service';
import Datepicker from '../../nucleus/datepicker/datepicker';

class DealStatusForm extends Component {
  static propTypes = {
    deal: PropTypes.shape({
      dealStage: PropTypes.string,
    }).isRequired,
    closeFlyout: PropTypes.func.isRequired,
    updateDeal: PropTypes.func.isRequired,
    setActiveNav: PropTypes.func.isRequired,
    updateDealCounts: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    const {
      deal,
    } = this.props;

    this.dealId = deal.id;
    this.state = {
      dealStage: deal.deal_life_cycle_stage,
      dealSubStage: deal.deal_substage,
      dealEstimateCloseDate: deal.date_est_close ? deal.date_est_close : '',
      dateError: false,
      apiError: '',
    };
  }

  onFormSubmit = () => {
    if (this.isValidDate()) {
      const {
        dealStage,
        dealSubStage,
        dealEstimateCloseDate,
      } = this.state;

      const {
        deal,
      } = this.props;

      const submittedData = {
        deal_life_cycle_stage: dealStage,
      };

      if (dealStage === 'executing') {
        if (dealSubStage && dealSubStage.length > 0) {
          submittedData.deal_substage = dealSubStage;
        } else {
          submittedData.deal_substage = 'lease review';
        }
      }

      if (dealStage === 'executing' || dealStage === 'closed') {
        if (dealEstimateCloseDate && dealEstimateCloseDate.length > 0) {
          submittedData.date_est_close = dealEstimateCloseDate;
        } else if (dealStage === 'executing') {
          submittedData.date_est_close = null;
        }
      }

      if (deal.deal_life_cycle_stage !== dealStage) {
        userEventService.trackEvent({
          eventCategory: 'Deal Status',
          eventAction: 'deal_stage_changed',
          eventData: {
            from: deal.deal_life_cycle_stage,
            to: dealStage,
          },
          eventValue: deal.id,
        });
      }

      if (deal.deal_substage !== dealSubStage) {
        userEventService.trackEvent({
          eventCategory: 'Deal Substage Changed',
          eventAction: 'deal_substage_changed',
          eventData: {
            from: deal.deal_substage,
            to: dealSubStage,
          },
          eventValue: deal.id,
        });
      }

      this.props.updateDeal(this.dealId, submittedData)
        .then((response) => {
          if (response && response.payload && response.payload.error) {
            this.setState({ apiError: `Failed to update deal: ${response.payload.error.statusText}` });
            return;
          }

          this.props.setActiveNav(dealStage);
          this.props.updateDealCounts();
          this.props.closeFlyout();
        }).catch((err) => {
          this.setState({ apiError: `Failed to update deal: ${err.message}` });
        });
    }
  }

  onDealStageChange = (dealStageObj) => {
    this.setState({
      dealStage: dealStageObj.value,
      dealSubStage: '',
      dateError: false,
      apiError: '',
    });
  }

  radioOnChange = (dealSubStage) => {
    this.setState({
      dealSubStage: dealSubStage.value,
    });
  }

  dateOnChange = (date) => {
    this.setState({
      dealEstimateCloseDate: date,
      dateError: false,
    });
  }

  // When we get a real date picker, validation will be changed.
  isValidDate = () => {
    this.setState({ dateError: false });
    if (this.state.dealStage === 'closed') {
      if (this.state.dealEstimateCloseDate && this.state.dealEstimateCloseDate.length > 0) {
        return true;
      }
      this.setState({ dateError: true });
      return false;
    }
    return true;
  }

  render() {
    const {
      dealStage,
      dealSubStage,
      dealEstimateCloseDate,
      dateError,
      apiError,
    } = this.state;

    const {
      deal: {
        deal_type,
      },
    } = this.props;

    let renderButtonSegmentRadioGroup = '';
    const defaultOption = dealSubStage
      ? StatusStrings.getDealSubstageOption(dealSubStage)
      : 'NONE';

    if ((dealStage && dealStage.toLowerCase()) === 'executing' && (deal_type && deal_type.toLowerCase() === 'lease')) {
      renderButtonSegmentRadioGroup =
        (
          <div>
            <legend>Lease Progress</legend>
            <ButtonSegmentedGroup
              options={StatusStrings.getDealSubStages()}
              onChange={this.radioOnChange}
              selected={defaultOption}
            />
          </div>
        );
    }

    let renderDate = '';

    const selectedDealStage = StatusStrings.getDealStageOption(dealStage);
    const closeDateLabel = 'Estimated Close Date';

    if (dealStage === 'executing' || dealStage === 'closed') {
      const closeDateTitle = dealStage === 'closed' ? `* ${closeDateLabel}` : closeDateLabel;

      renderDate =
        (
          <div className="calendar">
            <label>{closeDateTitle}</label>
            <Datepicker
              onDateChange={this.dateOnChange}
              date={dealEstimateCloseDate}
              requiredText={dateError ? 'An estimated close date is required on all closed deals' : null}
            />
          </div>
        );
    }

    const renderCloseText = dealStage === 'closed' ? (
      <div className="grid-container">
        <div className="grid-item"><Icon iconName="info-circle" variant="basic" /></div>
        <div className="grid-item">
          Closed deals are read-only. If you need to update/edit a deal, move it back to Prospecting/Executing.
        </div>
      </div>) : '';

    return (
      <form className="deal-status-form">
        <ButtonSegmentedGroup
          onChange={e => this.onDealStageChange(e)}
          options={StatusStrings.dealStage}
          selected={selectedDealStage}
        />
        {renderButtonSegmentRadioGroup}
        {renderDate}
        {apiError &&
          <div>
            <span className="error">{apiError}</span>
            <br />
          </div>}
        {renderCloseText}
        <div className="form-controls">
          <Button variant="secondary" onClick={this.props.closeFlyout}>Cancel</Button>
          <Button onClick={this.onFormSubmit}>Save</Button>
        </div>
      </form>
    );
  }
}

DealStatusForm.propTypes = {
  deal: PropTypes.shape({
    deal_life_cycle_stage: PropTypes.string.isRequired,
    deal_substage: PropTypes.string,
    deal_type: PropTypes.string,
    date_est_close: PropTypes.string,
    id: PropTypes.number,
    is_mta: PropTypes.bool,
  }).isRequired,
};

export default DealStatusForm;
