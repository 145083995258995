import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@cbrebuild/blocks';
import { Link, useHistory } from 'react-router-dom';
import VOUCHER_ROUTES from '../../../page-templates/voucher/voucher-subroutes';
import LabeledItem from '../../../nucleus/labeled-item/labeled-item';
import { fetchFilesDownloadLink } from '../../../redux/actions/deal-files-actions';
import VoucherFileListItem from '../files/voucher-file-list-item';
import RequiredLabeledItem from './RequiredLabeledItem';

const GettingStartedReview = () => {
  const {
    deal, files, voucher,
  } = useSelector(state => ({
    deal: state.deal.deal,
    files: state.dealFiles?.files,
    voucher: state.voucher.voucher,
  }));
  const history = useHistory();
  const url = (VOUCHER_ROUTES[0].path).replace(':id', voucher.deal);

  /*
    #1 Is this deal Confidential? - Optional
    #2 This is a Referral Deal - Optional
    #3 Did CBRE represent both sides of this deal? - Optional
    #4 Lead Office - Required
    #5 Sourced By - Required (has default value)
    #6 Office Name - Conditionally Required
   */

  return (
    <div className="voucher-review-group getting-started-review">
      <div className="navigation">
        <Link className="h2 link" to={url}>Getting Started</Link>
        <IconButton iconName="edit" onClick={() => { history.push(url); }} />
      </div>
      <div className="content">
        {voucher.is_early_invoice &&
          <RequiredLabeledItem label="Reason for Early Invoice" item={voucher.reason_for_early_invoice} isReview />}
        <LabeledItem label="Deal Type" item={deal.deal_type} />
        <LabeledItem label="Rep Role" item={deal.rep_role} />
        <LabeledItem label="Confidential" item={deal.is_confidential ? 'Yes' : 'No'} />
        <LabeledItem label="Referral" item={deal.is_referral_deal ? 'Yes' : 'No'} />
        <LabeledItem label="Dual Repped" item={deal.is_dual_rep_deal ? 'Yes' : 'No'} />
        {(deal.deal_type === 'lease' || deal.deal_type === 'sale') &&
          <RequiredLabeledItem label="Lead Office" item={deal.voucher_lead_office} isReview />}
        <LabeledItem label="Sourced By" item={deal.sourced_by} />
        {(deal.deal_type === 'lease' || deal.deal_type === 'sale') &&
          <RequiredLabeledItem
            label="Office Name"
            item={deal.voucher_office_name}
            notRequired={deal.sourced_by !== 'Office' && deal.sourced_by !== 'Affiliate'}
            isReview
          />}
        <ul className="download-list">
          {files &&
            files.map(file => (
              file.is_lease_document &&
              <VoucherFileListItem
                fetchFilesDownloadLink={fetchFilesDownloadLink}
                file={file}
                key={file.id}
                voucher={voucher}
                isAddButton={false}
                isDropDown={false}
              />
            ))
          }
        </ul>
        {(deal.deal_type === 'lease' || deal.deal_type === 'sale') && files.length === 0 &&
          <LabeledItem label={`${deal.deal_type} Agreement`} emptyState="--" />
        }
      </div>
    </div>
  );
};

export default GettingStartedReview;
