import superFetch from '../utils/super-fetch';

const fetchTerms = () => {
  const url = '/mbb/terms';
  const options = {
    method: 'GET',
  };

  return superFetch(url, options)
    .then(data => data.json());
};

export default fetchTerms;
