/* eslint-disable jsx-a11y/no-autofocus */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button, Icon, IconButton, Textarea, RadioGroup, RadioButton } from '@cbrebuild/blocks';

import BrokerAutocomplete from '../autocomplete/broker-autocomplete';
import DatePicker from '../../nucleus/datepicker/datepicker';
import TimePicker from '../../nucleus/time-picker/time-picker';
import DealAutocomplete from '../autocomplete/deal-autocomplete';

const requiredTitleMessage = 'Task Title is required';
const exceededLengthTitleMessage = 'Task Title is too long. Try shortening the title and try again.';

const MAX_STRING_LENGTH = 255;

class TaskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertDate: undefined,
      alertTime: 9,
      alertDateTimeValidity: true,
      assignee: undefined,
      dealName: undefined,
      dealId: this.props.dealId,
      documentBody: '',
      dueDatetime: undefined,
      dueDateTimeValidity: true,
      id: undefined,
      isPriority: false,
      isPrivate: !this.props.dealId,
      title: '',
      titleErrorMessage: '',
    };
  }

  componentDidMount() {
    this.initTask();
  }

  initTask = () => {
    const {
      taskToEdit,
      user,
    } = this.props;

    if (taskToEdit) {
      this.setState({
        alertDate: taskToEdit.alert_datetime,
        alertTime: taskToEdit.alert_datetime ? moment(taskToEdit.alert_datetime).get('hour') : 9,
        assignee: taskToEdit.assignees[0] || undefined,
        dealName: taskToEdit.deal_name,
        documentBody: taskToEdit.document_body,
        dueDatetime: taskToEdit.due_datetime,
        id: taskToEdit.id,
        isPriority: taskToEdit.is_priority,
        isPrivate: taskToEdit.is_private,
        title: taskToEdit.title,
      });
    } else {
      this.setState({
        assignee: { ...user, user_id: user.id },
      });
    }
  };

  handleSubmit = () => {
    const {
      onSubmit,
      showDealAutocomplete,
    } = this.props;
    const taskToEdit = this.props.taskToEdit || {};
    const {
      alertDate,
      alertTime,
      alertDateTimeValidity,
      assignee,
      assigneeErrorMessage,
      dealName,
      documentBody,
      dueDatetime,
      dueDateTimeValidity,
      id,
      isPriority,
      isPrivate,
      title,
    } = this.state;
    if (!title) {
      this.setState({ titleErrorMessage: requiredTitleMessage });
    } else if (showDealAutocomplete && dealName && !this.state.dealId) {
      this.setState({ dealErrorMessage: 'Select a deal from dropdown' });
    } else if (title.length > MAX_STRING_LENGTH) {
      this.setState({ titleErrorMessage: exceededLengthTitleMessage });
    } else if (!assigneeErrorMessage && alertDateTimeValidity && dueDateTimeValidity) {
      let alertDatetime;
      if (alertDate) {
        alertDatetime = moment(alertDate);
        alertDatetime.set('hours', alertTime);
        // if date changed send new else send original
        alertDatetime = alertDatetime.isSame(moment(taskToEdit.alert_datetime))
          ? taskToEdit.alert_datetime
          : alertDatetime.utc();
      }
      // if date changed send new else send original
      let dueDate;
      if (dueDatetime && !moment(dueDatetime).isSame(taskToEdit.due_datetime)) {
        dueDate = moment(dueDatetime).utc();
      } else if (taskToEdit.due_datetime) {
        dueDate = taskToEdit.due_datetime;
      }

      const task = {
        alert_datetime: alertDatetime,
        assignees: assignee ? [assignee.user_id || assignee.id] : [],
        document_body: documentBody,
        deal: this.state.dealId || null,
        id,
        is_priority: isPriority,
        is_private: isPrivate,
        due_datetime: dueDate,
        title,
      };

      onSubmit(id, task);
    }
  };

  handleAssignToMe = () => {
    const {
      user,
    } = this.props;
    this.setState({ assignee: { ...user, user_id: user.id }, assigneeErrorMessage: '' });
  };
  handleAlertDateChange = (alertDate) => {
    this.setState({ alertDate });
  };

  handleAlertDateValidity = (alertDateTimeValidity) => {
    this.setState({ alertDateTimeValidity });
  };

  handleAlertTimeChange = (alertTime) => {
    this.setState({ alertTime });
  };

  handleDueDateChange = (dueDatetime) => {
    this.setState({ dueDatetime });
    const {
      dueDateTimeValidity,
      alertDate,
    } = this.state;

    // auto-populate initial alert date w/2 days before due date or same as
    if (alertDate === undefined) {
      let proposedAlertDate = moment(dueDatetime).subtract(2, 'days').toISOString();
      const today = new Date().toISOString();
      if (moment(proposedAlertDate).isBefore(today)) {
        proposedAlertDate = dueDatetime;
      }
      this.handleAlertDateChange(proposedAlertDate);
      this.handleAlertDateValidity(dueDateTimeValidity);
    }
  };

  handleDueDateValidity = (dueDateTimeValidity) => {
    this.setState({ dueDateTimeValidity });
  };

  handleDealAutocompleteChange = (val) => {
    if (typeof val === 'string') { // as user types in autocomplete
      this.setState({
        dealName: val,
        dealId: null,
        dealErrorMessage: '',
        isPrivate: true,
      });
    } else { // when user selects a deal from dropdown
      this.setState({
        dealName: val.name,
        dealId: val.id,
        dealErrorMessage: '',
      });
    }
  };

  handleBrokerSelect = (broker) => {
    if (broker) {
      this.setState({ assignee: broker });
    } else {
      this.setState({ assignee: undefined });
    }
  };

  handlePriorityChange = () => {
    this.setState({ isPriority: !this.state.isPriority });
  };

  handlePrivacyChange = (value) => {
    this.setState({
      isPrivate: value === 'private',
    });
  };

  handleTitleChange = (title) => {
    this.setState({ title, titleErrorMessage: title ? '' : requiredTitleMessage });
  };

  handleNoteChange = ({ target: { value } }) => {
    this.setState({ documentBody: value });
  };

  handleNoDueDateOnFocus = () => {
    if (!this.state.dueDatetime) {
      this.setState({
        dueDatetime: moment().add(1, 'day').startOf('day').toISOString(),
      });
    }
  };

  handleNoAlertDateOnFocus = () => {
    if (!this.state.alertDate) {
      this.setState({
        alertDate: moment().add(1, 'day').startOf('day').toISOString(),
        alertTime: 8,
      });
    }
  };

  render() {
    const {
      autoFocus,
      onCancel,
      onDelete,
      user,
      showDealAutocomplete,
    } = this.props;

    const {
      alertDate,
      alertTime,
      assigneeErrorMessage,
      assignee,
      dealId,
      dealName,
      dealErrorMessage,
      dueDatetime,
      id,
      isPriority,
      isPrivate,
      title,
      titleErrorMessage,
      documentBody,
    } = this.state;

    const brokerName = assignee ? `${assignee.first_name} ${assignee.last_name}` : '';

    return (
      <React.Fragment>
        <form className="task-form">
          <div className="layout-row">
            <div className={`layout-column task-title${titleErrorMessage ? ' missing-title' : ''}`}>
              <label>* Task Title</label>
              <input autoFocus={autoFocus} type="text" value={title} onChange={e => this.handleTitleChange(e.target.value)} required />
              {titleErrorMessage && <p>{titleErrorMessage}</p>}
            </div>
            <Button
              className="priority-link"
              onClick={this.handlePriorityChange}
              variant="text"
            >
              <Icon className={isPriority ? ' critical' : ''} iconName="warning" />
              <span className="high-priority-label">High Priority</span>
              <span className="priority-label">Priority</span>
            </Button>
          </div>
          <div className="assignee-permissions">
            <div className="layout-column task-assignee">
              <label>Assignee</label>
              <BrokerAutocomplete
                onSelect={this.handleBrokerSelect}
                initSearchTerm={brokerName}
                errorMessage={assigneeErrorMessage}
              />
            </div>
            <div className="task-assign-to-me-privacy">
              <div className="task-assign-to-me">
                <Button
                  disabled={assignee && user.id === assignee.user_id}
                  variant="text"
                  onClick={this.handleAssignToMe}
                >
                  Assign to me
                </Button>
              </div>
              <div className="task-privacy">
                <RadioGroup
                  name={`privacy-options-${id}`}
                  onChange={e => this.handlePrivacyChange(e.target.value)}
                  selectedValue={isPrivate ? 'private' : 'team'}
                >
                  <RadioButton value="team" disabled={dealId === null || (!this.props.dealId && !dealId)}>Team</RadioButton>
                  <RadioButton value="private">Private</RadioButton>
                </RadioGroup>
              </div>
            </div>
          </div>
          <div className="layout-row datetime-row">
            <div className="layout-column datetime-col">
              <label>Due Date</label>
              <DatePicker
                date={dueDatetime}
                onDateChange={this.handleDueDateChange}
                onFocus={this.handleNoDueDateOnFocus}
                updateValidity={this.handleDueDateValidity}
              />
            </div>
            <div className="layout-column datetime-col">
              <label>Reminder Date</label>
              <DatePicker
                date={alertDate}
                onDateChange={this.handleAlertDateChange}
                onFocus={this.handleNoAlertDateOnFocus}
                updateValidity={this.handleAlertDateValidity}
              />
            </div>
            {alertDate &&
              <div className="layout-column datetime-col">
                <label>Reminder Time</label>
                <TimePicker
                  onSelect={this.handleAlertTimeChange}
                  selectedHour={alertTime}
                />
              </div>}
          </div>
          {showDealAutocomplete && (
            <div className="layout-column">
              <label>Deal</label>
              <DealAutocomplete
                onChange={this.handleDealAutocompleteChange}
                initSearchTerm={dealName || ''}
                errorMessage={dealErrorMessage}
              />
              {this.state.dealId && (
                <div className="view-deal-link">
                  <Link
                    to={`/deals/detail/${this.state.dealId}/overview`}
                    target="_blank"
                  >
                    View Deal
                    <Icon iconName="new-tab" />
                  </Link>
                </div>
              )}
            </div>
          )}
          <div className="task-details">
            <label>Task Details</label>
            <Textarea
              key={id}
              onChange={this.handleNoteChange}
              rows={{ min: 4, max: 10 }}
              value={documentBody}
              placeholder="Click to add details"
            />
          </div>
        </form>
        <footer>
          {id && <IconButton iconName="bin" onClick={() => onDelete(id)} variant="basic" />}
          <Button variant="secondary" onClick={onCancel}>Cancel</Button>
          <Button onClick={this.handleSubmit}>Save</Button>
        </footer>
      </React.Fragment>
    );
  }
}

TaskForm.propTypes = {
  autoFocus: PropTypes.bool,
  dealId: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  taskToEdit: PropTypes.shape({
    alert_datetime: PropTypes.string,
    assignees: PropTypes.arrayOf(PropTypes.shape({})),
    deal_name: PropTypes.string,
    document_body: PropTypes.string,
    due_datetime: PropTypes.string,
    id: PropTypes.number,
    is_priority: PropTypes.bool,
    is_private: PropTypes.bool,
    title: PropTypes.string,
  }),
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  showDealAutocomplete: PropTypes.bool,
};

TaskForm.defaultProps = {
  dealId: null,
  autoFocus: true,
  taskToEdit: undefined,
  showDealAutocomplete: false,
};

export default TaskForm;
