import React, { Component } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import { IconButton } from '@cbrebuild/blocks';

import considerationsService from '../../../services/voucher/considerations-service';
import PercentDollarInput from '../../../nucleus/inputs/percent-dollar-input';
import Select from '../../../nucleus/select/select';
import NumberInput from '../../../nucleus/inputs/number-input';
import Currency from '../../../nucleus/formats/currency';

class ConsiderationItem extends Component {
  onChangeOption = (option) => {
    // handle free lease
    if (indexOf(considerationsService.FREE_LEASE, option.display_name) !== -1) {
      this.updateConsideration('amount', 0);
      this.onCommissionRateChange(0, 0);
      this.props.calculateConsideration(this.props.consideration.id);
    }
    this.updateConsideration('consideration_type', option.value);
  }

  onConsiderationBlur = () => {
    this.props.calculateConsideration(this.props.consideration.id);
  }

  onRemoveClick = () => {
    this.props.deleteConsideration(this.props.consideration.id);
  };

  onCommissionRateChange = (percent, dollar) => {
    this.props.updateCommissionRate(this.props.consideration.id, percent, dollar);
  }

  updateConsideration = (key, val) => {
    this.props.updateConsideration(this.props.consideration.id, key, val);
  };

  render() {
    const {
      consideration_type,
      total_units, // period
      amount, // rent or sale
      consideration_amount, // total_units * amount
      commission_percent, // commission rate in dollar
      commission_amount, // commission rate in percent
    } = this.props.consideration;

    const closeIcon = <IconButton className="blxs-button-icon-small" iconName="close-circle" onClick={this.onRemoveClick} variant="basic" />;

    const defaultOption = find(
      this.props.leaseConsiderationTypeOptions,
      options => options.value === consideration_type,
    );

    const renderConsiderationTypeCell =
      (this.props.dealType === 'lease') ?
        (<Select
          defaultOption={defaultOption}
          onChange={this.onChangeOption}
          options={this.props.leaseConsiderationTypeOptions}
        />) :
        'Purchase Price';

    const isDisabled = consideration_type === 'Free Monthly Rent' || consideration_type === 'Free Annual Rent';

    return (
      <div className="table-row form-row-with-hints">
        <div className="table-cell">
          {renderConsiderationTypeCell}
        </div>
        <div className="table-cell">
          <NumberInput
            value={total_units}
            precision={0}
            updateValue={val => this.updateConsideration('total_units', val)}
            onBlur={this.onConsiderationBlur}
          />
        </div>
        <div className="table-cell">
          <NumberInput
            disabled={isDisabled}
            value={amount}
            precision={2}
            updateValue={val => this.updateConsideration('amount', val)}
            onBlur={this.onConsiderationBlur}
          />
        </div>
        <div className="table-cell">
          <Currency value={consideration_amount} emptyState="--" />
        </div>
        <div className="table-cell">
          <PercentDollarInput
            disabled={isDisabled}
            baseTotal={consideration_amount}
            toggleSign={this.props.togglePercentDollarSign}
            updateChange={this.onCommissionRateChange}
            percentModel={commission_percent}
            dollarModel={commission_amount}
            isPercent={this.props.isConsiderationPercent}
          />
        </div>
        <div className="table-cell">
          <Currency value={commission_amount} emptyState="--" />
        </div>
        <div className="table-cell">
          {closeIcon}
        </div>
      </div>
    );
  }
}

ConsiderationItem.propTypes = {
  consideration: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    amount: PropTypes.number,
    commission_amount: PropTypes.number,
    commission_percent: PropTypes.number,
    consideration_amount: PropTypes.number,
    consideration_type: PropTypes.string,
    deal_id: PropTypes.number,
    total_units: PropTypes.number,
    transaction: PropTypes.number,
  }).isRequired,
  updateConsideration: PropTypes.func.isRequired,
  calculateConsideration: PropTypes.func.isRequired,
  deleteConsideration: PropTypes.func.isRequired,
  togglePercentDollarSign: PropTypes.func.isRequired,
  isConsiderationPercent: PropTypes.bool.isRequired,
  updateCommissionRate: PropTypes.func.isRequired,
  leaseConsiderationTypeOptions: PropTypes.arrayOf(PropTypes.shape()),
  dealType: PropTypes.string.isRequired,
};

ConsiderationItem.defaultProps = {
  leaseConsiderationTypeOptions: [],
};

export default ConsiderationItem;
