import {
  LOAD_DEAL_TEAM_SUCCESS,
  LOAD_DEAL_TEAM_FAILURE,
  LOAD_COMMISSION_TEAM_SUCCESS,
  LOAD_COMMISSION_TEAM_FAILURE,
  UPDATE_DEAL_USERS_SUCCESS,
  UPDATE_DEAL_USERS_FAILURE,
  UPDATE_COMMISSION_USERS_SUCCESS,
  UPDATE_COMMISSION_USERS_FAILURE,
  UPDATE_DEAL_TEAMS_SUCCESS,
  UPDATE_DEAL_TEAMS_FAILURE,
  UPDATE_COMMISSION_TEAMS_SUCCESS,
  UPDATE_COMMISSION_TEAMS_FAILURE,
} from '../actions/action-types';

const initialState = { relatedTransactionUsers: [] };

const loadDealTeamSuccess = (state, action) => {
  const dealUsers = action.payload.teamMembers.filter(member => member.user !== null);
  const dealTeams = action.payload.teamMembers.filter(member => member.team !== null);
  return {
    ...state,
    dealUsers,
    dealTeams,
  };
};

const loadCommissionTeamSuccess = (state, action) => {
  const relatedTransactionUsers = action.payload.teamMembers.filter(member => member.user !== null);
  const relatedTransactionTeams = action.payload.teamMembers.filter(member => member.team !== null);
  return {
    ...state,
    relatedTransactionUsers,
    relatedTransactionTeams,
  };
};

const updateDealUsersSuccess = (state, action) => {
  let dealUsers;
  let relatedTransactionUsers;
  let memberToDelete;

  if (action.payload.method === 'DELETE') {
    dealUsers = state.dealUsers.filter(member => member.id !== parseInt(action.payload.params, 10));
    memberToDelete = state.dealUsers.find(member => member.id === parseInt(action.payload.params, 10));
    relatedTransactionUsers = state.relatedTransactionUsers.filter(member => (member.user.id !== memberToDelete.user.id || member.locked));
  } else {
    dealUsers = state.dealUsers.map(member => member);
    dealUsers.push(action.payload);
  }

  return {
    ...state,
    dealUsers,
    relatedTransactionUsers: relatedTransactionUsers || state.relatedTransactionUsers,
  };
};

const updateCommissionUsersSuccess = (state, action) => {
  let relatedTransactionUsers;

  if (action.payload.method === 'DELETE') {
    relatedTransactionUsers = state.relatedTransactionUsers.filter(member => member.id !== parseInt(action.payload.params, 10));
  } else {
    relatedTransactionUsers = state.relatedTransactionUsers.map(member => member);
    relatedTransactionUsers.push(action.payload);
  }
  return {
    ...state,
    relatedTransactionUsers,
  };
};

const updateDealTeamsSuccess = (state, action) => {
  let dealTeams;
  let relatedTransactionTeams;
  let teamToDelete;

  if (action.payload.method === 'DELETE') {
    dealTeams = state.dealTeams.filter(team => team.id !== parseInt(action.payload.params, 10));
    teamToDelete = state.dealTeams.find(member => member.id === parseInt(action.payload.params, 10));
    relatedTransactionTeams = !state.relatedTransactionTeams ? state.relatedTransactionTeams
      : state.relatedTransactionTeams.filter(member => (member.team.id !== teamToDelete.team.id || member.locked));
  } else {
    dealTeams = state.dealTeams.map(team => team);
    dealTeams.push(action.payload);
  }
  return {
    ...state,
    dealTeams,
    relatedTransactionTeams: relatedTransactionTeams || state.relatedTransactionTeams,
  };
};

const updateCommissionTeamsSuccess = (state, action) => {
  let relatedTransactionTeams;

  if (action.payload.method === 'DELETE') {
    relatedTransactionTeams = state.relatedTransactionTeams.filter(team => team.id !== parseInt(action.payload.params, 10));
  } else {
    relatedTransactionTeams = state.relatedTransactionTeams.map(team => team);
    relatedTransactionTeams.push(action.payload);
  }
  return {
    ...state,
    relatedTransactionTeams,
  };
};

const loadTeamFailure = (state, action) => ({
  ...state,
  error: action.payload.error,
});

const teamMembers = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DEAL_TEAM_SUCCESS:
      return loadDealTeamSuccess(state, action);
    case LOAD_COMMISSION_TEAM_SUCCESS:
      return loadCommissionTeamSuccess(state, action);
    case UPDATE_DEAL_USERS_SUCCESS:
      return updateDealUsersSuccess(state, action);
    case UPDATE_COMMISSION_USERS_SUCCESS:
      return updateCommissionUsersSuccess(state, action);
    case UPDATE_DEAL_TEAMS_SUCCESS:
      return updateDealTeamsSuccess(state, action);
    case UPDATE_COMMISSION_TEAMS_SUCCESS:
      return updateCommissionTeamsSuccess(state, action);
    case LOAD_DEAL_TEAM_FAILURE:
    case LOAD_COMMISSION_TEAM_FAILURE:
    case UPDATE_DEAL_USERS_FAILURE:
    case UPDATE_COMMISSION_USERS_FAILURE:
    case UPDATE_DEAL_TEAMS_FAILURE:
    case UPDATE_COMMISSION_TEAMS_FAILURE:
      return loadTeamFailure(state, action);
    default:
      return state;
  }
};

export default teamMembers;
