import { connect } from 'react-redux';
import { toast } from '../../redux/actions/toasts-actions';
import { updateUserOptions } from '../../redux/actions/user-options-actions';

import DashboardPage from './dashboard-page';

const mapStateToProps = state => ({
  user: state.userData.data,
  userOptions: state.userOptions.userOptions,
  featureFlags: state.userOptions.userOptions.feature_flags,
});

const mapDispatchToProps = { toast, updateUserOptions };

const mergeProps = (state, actions) => ({
  ...state,
  ...actions,
  analyticProperties: {
    actionPrefix: 'dashboard',
    categoryPrefix: 'Dashboard ',
  },
});

const DashboardPageContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(DashboardPage);

export default DashboardPageContainer;
