import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Checkbox } from '@cbrebuild/blocks';

import AddTag from '../../../nucleus/legacy/tag/add-tag';
import ButtonSegmentedGroup from '../../../nucleus/button-segmented-group/button-segmented-group';
import Datepicker from '../../../nucleus/datepicker/datepicker';
import FilterTag from '../../../nucleus/legacy/tag/filter-tag';
import NumberInput from '../../../nucleus/inputs/number-input';
import Slider from '../../../nucleus/slider/slider';
import formatAsCurrency from '../../../utils/format-as-currency';
import formatNumberWithCommas from '../../../utils/format-number-with-commas';
import userEventService from '../../../services/user-event-service';
import sumTotalCommissions from '../../../services/voucher/total-commissions-service';

const dealSubstageOptions = [
  {
    display_name: 'Touring',
    value: 'touring',
  },
  {
    display_name: 'RFP',
    value: 'rfp',
  },
  {
    display_name: 'LOI',
    value: 'loi',
  },
  {
    display_name: 'Lease Review',
    value: 'lease review',
  },
];
class DealDetailsForm extends React.Component {
  state = {
    estimatedCommission: this.props.deal.estimated_commission,
  };

  componentDidMount() {
    const {
      deal: { id },
      fetchAdditionalCommissions,
      fetchConsiderations,
      fetchDealTags,
      fetchInstallments,
      fetchCommissions,
      initializeCommissionsTotal,
    } = this.props;

    let total = 0;
    let fetchedConsiderations = [];
    let fetchedAdditionalCommissions = [];

    fetchConsiderations(id)
      .then((considerations) => {
        fetchedConsiderations = considerations;
        return fetchAdditionalCommissions(id);
      })
      .then((additionalCommissions) => {
        fetchedAdditionalCommissions = additionalCommissions;
        total = sumTotalCommissions(fetchedConsiderations, fetchedAdditionalCommissions);
        return initializeCommissionsTotal(total);
      })
      .catch(() => console.log('unable to fetch total commissions information'));

    fetchDealTags();
    fetchInstallments(id);
    fetchCommissions({ transaction: id });
  }

  componentWillUnmount() {
    this.props.clearFinancials();
  }

  handleConversionChange = (value) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
      deal: {
        id,
      },
      updateDeal,
    } = this.props;
    updateDeal(id, { conversion_potential: value, modified: new Date() });
    // tracks conversion potential change
    userEventService.trackEvent(
      {
        eventAction: 'deal_conversion_potential_changed',
        eventCategory: 'Action',
        eventValue: value,
      },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  }

  handleDialogueChange = ({ target: { checked } }) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
      deal: {
        id,
      },
      updateDeal,
    } = this.props;
    updateDeal(id, { active_dialog: checked, modified: new Date() });
    userEventService.trackEvent(
      {
        eventAction: 'deal_active_dialogue_changed',
        eventCategory: 'Action',
        eventData: {
          activeDialogue: checked,
        },
      },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  }

  handleAddTag = (tagToAdd) => {
    const {
      addDealTag,
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
      deal,
      updateDeal,
    } = this.props;
    const hasTag = !!deal.tags.find(tag => tag === tagToAdd);
    if (!hasTag) {
      const params = { tags: deal.tags.concat(tagToAdd), modified: new Date() };
      updateDeal(deal.id, params);
      addDealTag(tagToAdd);
    }
    userEventService.trackEvent(
      {
        eventAction: 'deal_tag_added',
        eventCategory: 'Action',
      },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  }

  handleDealSubstageChange = ({ value }) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
      deal,
      updateDeal,
    } = this.props;
    const params = { deal_substage: value, modified: new Date() };
    updateDeal(deal.id, params);
    userEventService.trackEvent(
      {
        eventAction: 'deal_lease_progress_changed',
        eventCategory: 'Action',
        eventData: {
          leaseProgress: value,
        },
      },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  }

  handleEstimateCloseDateChange = (date) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
      deal,
      updateDeal,
    } = this.props;
    const params = { date_est_close: date, modified: new Date() };
    updateDeal(deal.id, params);
    userEventService.trackEvent(
      {
        eventAction: 'deal_estimated_close_date_changed',
        eventCategory: 'Action',
      },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  }

  handleTagRemove = (tagToRemove) => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
      deal,
      updateDeal,
    } = this.props;
    const params = { tags: deal.tags.filter(tag => tag !== tagToRemove) };
    updateDeal(deal.id, params);
    userEventService.trackEvent(
      {
        eventAction: 'deal_tag_removed',
        eventCategory: 'Action',
        eventData: {
          tagRemoved: tagToRemove,
        },
      },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  }

  formatSlider = value => `${value}%`;

  renderInlineFieldSet = (label, value = '--') => (
    <div className="inline-fieldset">
      <label>{label}</label>
      <p>{value}</p>
    </div>
  )

  updateTotalCommissions = () => {
    const {
      analyticProperties: {
        actionPrefix,
        categoryPrefix,
      },
      deal,
      updateDeal,
      installments,
      updateInstallment,
      commissions,
      updateCommission,
      voucher,
    } = this.props;
    const params = { estimated_commission: this.state.estimatedCommission };
    updateDeal(deal.id, params);

    // it should update installments and allocation commissions as estimated commission changes
    if (installments.length) {
      installments.forEach((installment) => {
        if (voucher.installment_is_percent) {
          const newAmount = parseFloat(((this.state.estimatedCommission * installment.voucher_installment_percent) / 100).toFixed(2));
          updateInstallment(installment.id, { installment_amount: newAmount || 0 });
        } else {
          const newPercent = this.state.estimatedCommission ?
            parseFloat(((installment.installment_amount / this.state.estimatedCommission) * 100).toFixed(8)) :
            0;
          updateInstallment(installment.id, { voucher_installment_percent: newPercent });
        }
      });
    }

    if (commissions.length) {
      commissions.forEach((commission) => {
        if (voucher.cbre_commission_is_percent) {
          const newAmount = parseFloat(((this.state.estimatedCommission * commission.voucher_gross_commission_percent) / 100).toFixed(2));
          updateCommission(commission.id, { gross_commission: newAmount });
        } else {
          const newPercent = this.state.estimatedCommission ?
            parseFloat(((commission.gross_commission / this.state.estimatedCommission) * 100).toFixed(8)) :
            0;
          updateCommission(commission.id, { voucher_gross_commission_percent: newPercent });
        }
      });
    }

    userEventService.trackEvent(
      {
        eventAction: 'deal_estimated_total_commissions_changed',
        eventCategory: 'Action',
      },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  }

  render() {
    const {
      commissionsTotal,
      deal: {
        active_dialog,
        client,
        conversion_potential,
        counter_party,
        date_approved,
        date_est_close,
        deal_substage,
        deal_type,
        deal_life_cycle_stage,
        estimated_commission,
        rep_role,
        sqft,
        tags,
        total_consideration,
      },
      dealTags,
    } = this.props;
    const clientName = client ? client.name : undefined;
    const counterPartyName = counter_party ? counter_party.name : undefined;

    return (
      <div className="deal-details-form">
        <h2>Deal Details</h2>
        {deal_life_cycle_stage === 'closed' && (
          <React.Fragment>
            {(deal_type === 'consulting' || deal_type === 'other') &&
              this.renderInlineFieldSet('Client', clientName)
            }
            {deal_type === 'lease' && (
              <React.Fragment>
                {rep_role === 'tenant' && (
                  <React.Fragment>
                    {this.renderInlineFieldSet('Tenant', clientName)}
                    {this.renderInlineFieldSet('Landlord', counterPartyName)}
                  </React.Fragment>
                )}
                {rep_role === 'landlord' && (
                  <React.Fragment>
                    {this.renderInlineFieldSet('Tenant', counterPartyName)}
                    {this.renderInlineFieldSet('Landlord', clientName)}
                  </React.Fragment>
                )}
                {this.renderInlineFieldSet('Final SqFt', sqft ? formatNumberWithCommas(sqft) : undefined)}
              </React.Fragment>
            )}
            {deal_type === 'sale' && (
              <React.Fragment>
                {rep_role === 'buyer' && (
                  <React.Fragment>
                    {this.renderInlineFieldSet('Buyer', clientName)}
                    {this.renderInlineFieldSet('Seller', counterPartyName)}
                  </React.Fragment>
                )}
                {rep_role === 'seller' && (
                  <React.Fragment>
                    {this.renderInlineFieldSet('Buyer', counterPartyName)}
                    {this.renderInlineFieldSet('Seller', clientName)}
                  </React.Fragment>
                )}
                {this.renderInlineFieldSet('Sale Price', total_consideration ? formatAsCurrency(total_consideration) : undefined)}
              </React.Fragment>
            )}
            {this.renderInlineFieldSet('Close Date', date_approved ? moment(date_approved).format('ll') : undefined)}
          </React.Fragment>
        )}
        {deal_life_cycle_stage !== 'closed' &&
          <React.Fragment>
            <label>Probability</label>
            <Slider
              formatLabel={this.formatSlider}
              minValue={0}
              maxValue={100}
              onChange={this.handleConversionChange}
              step={5}
              value={conversion_potential}
            />
          </React.Fragment>
        }
        {deal_life_cycle_stage === 'prospect' && (
          <div className="fieldset">
            <label>Dialogue</label>
            <Checkbox
              checked={active_dialog}
              onChange={this.handleDialogueChange}
            >Active Dialogue
            </Checkbox>
          </div>
        )}
        {(commissionsTotal)
          ? (
            <div className="form-row-with-labels fieldset">
              <div className="form-group">
                <label>Expected Total Commissions</label>
                <div className="expected-total-commissions">{formatAsCurrency(commissionsTotal)}</div>
              </div>
            </div>
          ) : (
            <div className="form-row-with-labels fieldset">
              <NumberInput
                label="Estimated Total Commissions ($)"
                value={estimated_commission}
                updateValue={(val) => { this.setState({ estimatedCommission: val }); }}
                onBlur={this.updateTotalCommissions}
                precision={2}
                width="large"
              />
            </div>
          )}
        <div className="fieldset">
          <label>Tags</label>
          <div className="deal-tags">
            {tags && tags.map(tag => (
              <FilterTag
                handleClearFilter={() => this.handleTagRemove(tag)}
                isButton={false}
                key={tag}
                value={tag}
              />
            ))}
            <AddTag
              onSubmit={this.handleAddTag}
              suggestions={dealTags.filter(tag => !tags.includes(tag))}
            />
          </div>
        </div>
        {deal_life_cycle_stage === 'executing' && (
          <React.Fragment>
            {deal_type === 'lease' && (
              <div className="fieldset">
                <label>Lease Progress</label>
                <ButtonSegmentedGroup
                  onChange={this.handleDealSubstageChange}
                  options={dealSubstageOptions}
                  selected={dealSubstageOptions.find(sub => sub.value === deal_substage)}
                />
              </div>
            )}
            <div className="form-row-with-labels fieldset">
              <div className="form-group">
                <label>Estimated Closed</label>
                <Datepicker
                  date={date_est_close}
                  onDateChange={this.handleEstimateCloseDateChange}
                  scrollOnFocus
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

DealDetailsForm.propTypes = {
  addDealTag: PropTypes.func.isRequired,
  analyticProperties: PropTypes.shape({
    categoryPrefix: PropTypes.string,
    actionPrefix: PropTypes.string,
  }).isRequired,
  commissionsTotal: PropTypes.number,
  deal: PropTypes.shape({
    active_dialog: PropTypes.bool,
    client: PropTypes.shape({
      name: PropTypes.string,
    }),
    counter_party: PropTypes.shape({
      name: PropTypes.string,
    }),
    conversion_potential: PropTypes.number,
    date_approved: PropTypes.string,
    date_est_close: PropTypes.string,
    deal_substage: PropTypes.string,
    id: PropTypes.number,
    rep_role: PropTypes.string,
    deal_type: PropTypes.string,
    deal_life_cycle_stage: PropTypes.string,
    estimated_commission: PropTypes.number,
    sqft: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
    total_consideration: PropTypes.number,
  }).isRequired,
  dealTags: PropTypes.arrayOf(PropTypes.string),
  fetchConsiderations: PropTypes.func.isRequired,
  fetchAdditionalCommissions: PropTypes.func.isRequired,
  fetchDealTags: PropTypes.func.isRequired,
  updateDeal: PropTypes.func.isRequired,
  fetchInstallments: PropTypes.func.isRequired,
  updateInstallment: PropTypes.func.isRequired,
  installments: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchCommissions: PropTypes.func.isRequired,
  updateCommission: PropTypes.func.isRequired,
  commissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  clearFinancials: PropTypes.func.isRequired,
  initializeCommissionsTotal: PropTypes.func.isRequired,
  voucher: PropTypes.shape({
    cbre_commission_is_percent: PropTypes.bool,
    installment_is_percent: PropTypes.bool,
  }).isRequired,
};

DealDetailsForm.defaultProps = {
  commissionsTotal: undefined,
  dealTags: [],
};

export default DealDetailsForm;
