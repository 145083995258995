import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@cbrebuild/blocks';
import { Card } from '../cards/cards';
import EmptyState from '../../components/empty-state/empty-state';

/**
 * ErrorBoundary catches render errors and shows a title and message to the user based on props.
 * if isDeveloper is true it will then show the errors that have been caught.
 * The refresh button will try to remount the children and clear out the error to see if that fixes the error.
 * onRefresh will notify the parent component when onRefresh happens
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  /**
   * error contains the error message and the stack trace
   * the componentError contains the component stack error
   */
  componentDidCatch({ message = '--', stack = '--' }, { componentStack = '--' }) {
    this.setState({
      error: {
        message,
        stack,
        componentStack: componentStack.trim(),
      },
    });
  }

  /**
   * gets called first, so we set the error state to true to prevent more errors from happening
   */
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  retry = () => {
    this.setState({
      hasError: false,
      error: null,
    });
    this.props.onRetry();
  }

  render() {
    const {
      hasError,
      error,
    } = this.state;
    const {
      ctaText,
      isDeveloper,
      message,
      title,
    } = this.props;
    if (hasError) {
      return (
        <div className="nd-error-boundary page-template">
          <Card>
            <EmptyState
              type="crane"
              title={title}
              message={message}
              callToAction={<Button onClick={this.retry}>{ctaText}</Button>}
            />
          </Card>
          {isDeveloper && (
            <Card>
              {error && (
                <Fragment>
                  <h3 className="error-title">Error Message:</h3>
                  <pre>{error.message}</pre>
                  <h3 className="error-title">Stacktrace:</h3>
                  <pre>{error.stack}</pre>
                  <h3 className="error-title">Component stack error:</h3>
                  <pre>{error.componentStack}</pre>
                </Fragment>
              )}
            </Card>
          )}
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  ctaText: PropTypes.string,
  isDeveloper: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string,
  onRetry: PropTypes.func,
};

ErrorBoundary.defaultProps = {
  children: undefined,
  ctaText: 'Retry',
  isDeveloper: false,
  message: 'Please try refreshing your browser. If this problem persists, you can contact our support team using the chat tool at the top of the page.',
  title: 'Oops! Something went wrong.',
  onRetry: () => { },
};

export default ErrorBoundary;
