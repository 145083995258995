import superFetch from '../../utils/super-fetch';

const fetchFinancials = (dealId, params) => {
  const url = `/mbb/deals/${dealId}/financials`;
  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json());
};

export default {
  fetchFinancials,
};
