import superFetch from '../utils/super-fetch';

const addDeal = (body) => {
  const url = '/deals/new';
  const options = {
    method: 'POST',
  };

  return superFetch(url, options, JSON.stringify(body))
    .then(data => data.json());
};

// Deprecated
const fetchDeal = (id) => {
  const url = `/mbb/deals/${id}`;
  const options = {
    method: 'GET',
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const fetchDeals = (params, requestId) => {
  const url = '/mbb/deals';
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json())
    .then(data => ({ ...data, requestId }));
};

const fetchDealsAndTransactionsES = (params, requestId) => {
  const url = '/es/dealsandtransactions';
  // destruct to avoid override on source
  const updatedParams = { ...params };
  if (params && params.is_untagged_deal) {
    updatedParams.tags = [''];
    updatedParams.is_untagged_deal = null;
  }
  if (params && params.created_by_name) {
    updatedParams.created_by_name = null;
  }
  const options = {
    method: 'GET',
    params: updatedParams,
  };
  return superFetch(url, options)
    .then(data => data.json())
    .then(data => ({ ...data, requestId }));
};

const fetchDealAndTransactionCount = () => {
  const url = '/mbb/deals/dealcounts';
  const options = {
    method: 'GET',
  };

  return superFetch(url, options)
    .then(data => data.json());
};

const updateDeal = async (id, params) => {
  const url = `/mbb/deals/${id}`;
  const options = {
    method: 'PATCH',
  };

  const body = JSON.stringify(params);

  const data = await superFetch(url, options, body);
  return data.json();
};

let dealOptions;

const fetchDealOptions = () => {
  const url = '/mbb/deals';
  const options = {
    method: 'OPTIONS',
  };
  return superFetch(url, options)
    .then(data => data.json())
    .then((data) => { dealOptions = data; return dealOptions; });
};

const getDealOptions = () => {
  // use cached value to minimize API requests
  if (dealOptions) return Promise.resolve(dealOptions);
  return fetchDealOptions();
};

const updateClient = (id, params) => {
  const url = `/mbb/deals/${id}/client`;
  const options = {
    method: 'PUT',
  };

  const body = JSON.stringify(params);

  return superFetch(url, options, body)
    .then(data => data.json());
};

// favorite/unfavorite have their own endpoints because is_favorite is unlike other deal attributes
// because is_favorite is specific to a user
const favoriteDeal = (id) => {
  const url = `/favorites/deals/${id}`;
  const options = { method: 'POST' };

  return superFetch(url, options); // endpoint does not return updated deal in response
};

const unfavoriteDeal = (id) => {
  const url = `/favorites/deals/${id}`;
  const options = { method: 'DELETE' };

  return superFetch(url, options); // endpoint does not return updated deal in response
};

const fetchDealFavorite = (id) => {
  const url = `/favorites/deals/${id}`;
  const options = { method: 'GET' };

  return superFetch(url, options);
};

const fetchAllDealFavorites = () => {
  const url = '/favorites/deals';
  const options = { method: 'GET' };

  return superFetch(url, options);
};

const addDealToPipeline = (id) => {
  const url = `/mbb/pipeline/deals/${id}`;
  const options = { method: 'POST' };

  return superFetch(url, options); // endpoint does not return updated deal in response
};

const removeDealFromPipeline = (id) => {
  const url = `/mbb/pipeline/deals/${id}`;
  const options = { method: 'Delete' };

  return superFetch(url, options); // endpoint does not return updated deal in response
};

const fetchDealTags = () => {
  const url = '/mbb/deals/tags';
  const options = { method: 'GET' };

  return superFetch(url, options)
    .then(data => data.json());
};

const fetchDealNoteAndTask = (dealIds) => {
  const url = '/mbb/deals/get_user_specific_deal_info';
  const options = { method: 'POST' };
  const body = JSON.stringify({ deal_ids: dealIds });
  return superFetch(url, options, body)
    .then(data => data.json());
};

const exportDeals = (params) => {
  const url = '/mbb/deals/export';
  const options = {
    method: 'GET',
    params,
    responseType: 'arraybuffer',
  };
  return superFetch(url, options)
    .then(data => data.json());
};

/**
 * We will use a different endpoint once the serializer is done
 */
const getMetadata = () => {
  const url = '/mbb/pipeline/members';
  const options = {
    method: 'GET',
    // params: {
    //   page_size: 50000,
    //   deal_life_cycle_stage: ['prospect', 'executing', 'closed'],
    //   permissions_only: true,
    // },
  };
  return superFetch(url, options)
    .then(data => data.json());
};

export default {
  getMetadata,
  addDeal,
  exportDeals,
  fetchDeal,
  fetchDeals,
  getDealOptions,
  fetchDealAndTransactionCount,
  updateDeal,
  updateClient,
  favoriteDeal,
  unfavoriteDeal,
  fetchDealFavorite,
  fetchAllDealFavorites,
  addDealToPipeline,
  removeDealFromPipeline,
  fetchDealTags,
  fetchDealNoteAndTask,
  fetchDealsAndTransactionsES,
};
