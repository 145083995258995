import superFetch from '../utils/super-fetch';

const esriApiUrl = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer';

const search = (params) => {
  const url = `${esriApiUrl}/suggest`;
  const options = {
    method: 'GET',
    params,
    headers: {},
  };
  return superFetch(url, options)
    .then(data => data.json());
};

const buildingLookup = (params) => {
  const url = '/buildinglookup';
  const options = {
    method: 'GET',
    params,
  };
  return superFetch(url, options)
    .then(data => data.json());
};

export {
  search,
  buildingLookup,
};
