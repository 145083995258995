import WhatsNew from './support-whats-new';

const supportTabs = [
  {
    link: '/support/whats-new',
    text: 'What\'s New',
  },
];

const subRoutes = [
  {
    path: '/support/whats-new',
    exact: true,
    component: WhatsNew,
  },
];

export {
  supportTabs,
  subRoutes,
};
