// admin teams page
export const ADD_TEAM_FOR_ADMIN_SUCCESS = 'ADMIN::ADD_TEAM_FOR_ADMIN_SUCCESS';
export const ADD_TEAM_FOR_ADMIN_FAILURE = 'ADMIN::ADD_TEAM_FOR_ADMIN_FAILURE';
export const ADD_TEAM_MEMBER_FOR_ADMIN_SUCCESS = 'ADMIN::ADD_TEAM_MEMBER_FOR_ADMIN_SUCCESS';
export const ADD_TEAM_MEMBER_FOR_ADMIN_FAILURE = 'ADMIN::ADD_TEAM_MEMBER_FOR_ADMIN_FAILURE';
export const LOAD_TEAMS_FOR_ADMIN_SUCCESS = 'ADMIN::LOAD_TEAMS_FOR_ADMIN_SUCCESS';
export const LOAD_TEAMS_FOR_ADMIN_FAILURE = 'ADMIN::LOAD_TEAMS_FOR_ADMIN_FAILURE';
export const UNDELETE_TEAM_SUCCESS = 'ADMIN::UNDELETE_TEAM_SUCCESS';
export const UNDELETE_TEAM_FAILURE = 'ADMIN::UNDELETE_TEAM_FAILURE';
export const UPDATE_TEAM_FOR_ADMIN_SUCCESS = 'ADMIN::UPDATE_TEAM_FOR_ADMIN_SUCCESS';
export const UPDATE_TEAM_FOR_ADMIN_FAILURE = 'ADMIN::UPDATE_TEAM_FOR_ADMIN_FAILURE';
export const REMOVE_TEAM_MEMBER_FOR_ADMIN_SUCCESS = 'ADMIN::REMOVE_TEAM_MEMBER_FOR_ADMIN_SUCCESS';
export const REMOVE_TEAM_MEMBER_FOR_ADMIN_FAILURE = 'ADMIN::REMOVE_TEAM_MEMBER_FOR_ADMIN_FAILURE';

// attachments
export const ADD_FILES_SUCCESS = 'DEAL_FILES::ADD_FILES_SUCCESS';
export const ADD_FILES_FAILURE = 'DEAL_FILES::ADD_FILES_FAILURE';
export const DELETE_FILE_SUCCESS = 'DEAL_FILES::DELETE_FILE_SUCCESS';
export const FETCH_FILES_DOWNLOAD_LINK_SUCCESS = 'DEAL_FILES::FETCH_FILES_DOWNLOAD_LINK_SUCCESS';
export const FETCH_UPLOADED_FILES_FAILURE = 'DEAL_FILES::FETCH_FILES_FAILURE';
export const FETCH_FILES_SUCCESS = 'DEAL_FILES::FETCH_UPLOADED_FILES_FAILURE';
export const FETCH_FILES_FAILURE = 'DEAL_FILES::FETCH_FILES_FAILURE';
export const IS_LOADING_FILES = 'DEAL_FILES::IS_LOADING_FILES,';
export const UPDATE_FILE_SUCCESS = 'DEAL_FILES::UPDATE_FILE_SUCCESS';
export const ABSTRACTION_LEASE_FILE = 'ABSTRACTION_LEASE_FILE';
export const UPDATE_FILE_FAILURE = 'DEAL_FILES::UPDATE_FILE_FAILURE';

// deal
export const CLEAR_DEAL_STORE = 'DEAL::CLEAR_DEAL_STORE';
export const LOAD_DEAL_SUCCESS = 'DEAL::LOAD_DEAL_SUCCESS';
export const LOAD_DEAL_FAILURE = 'DEAL::LOAD_DEAL_FAILURE';
export const IS_LOADING_DEAL = 'DEAL::IS_LOADING_DEAL';
export const UPDATE_DEAL_SUCCESS = 'DEAL::UPDATE_DEAL_SUCCESS';
export const UPDATE_DEAL_FAILURE = 'DEAL::UPDATE_DEAL_FAILURE';
export const UPDATE_DEAL_SUCCESS_ES = 'DEAL::UPDATE_DEAL_SUCCESS_ES';
export const UPDATE_DEAL_FAILURE_ES = 'DEAL::UPDATE_DEAL_FAILURE_ES';
export const UPDATE_DEAL_CLIENT_SUCCESS = 'DEAL::UPDATE_DEAL_CLIENT_SUCCESS';
export const UPDATE_DEAL_CLIENT_FAILURE = 'DEAL::UPDATE_DEAL_CLIENT_FAILURE';
export const UPDATE_DEAL_REQUIREMENTS_SUCCESS = 'DEAL::UPDATE_DEAL_REQUIREMENTS_SUCCESS';
export const UPDATE_DEAL_REQUIREMENTS_FAILURE = 'DEAL::UPDATE_DEAL_REQUIREMENTS_FAILURE';
export const FAVORITE_DEAL_FAILURE = 'DEAL::FAVORITE_DEAL_FAILURE';
export const UNFAVORITE_DEAL_FAILURE = 'DEAL::UNFAVORITE_DEAL_FAILURE';

// deals
export const DEALS_ARCHIVE_DEAL_SUCCESS = 'DEALS::ARCHIVE_DEAL_SUCCESS';
export const DEALS_RESTORE_DEAL_SUCCESS = 'DEALS::RESTORE_DEAL_SUCCESS';
export const DEALS_DELETE_DEAL_SUCCESS = 'DEALS::DELETE_DEAL_SUCCESS';
export const DEALS_UPDATE_DEAL_SUCCESS = 'DEALS::UPDATE_DEAL_SUCCESS';
export const DEALS_UPDATE_DEAL_CONTACT_SUCCESS = 'DEALS::UPDATE_DEAL_CONTACT_SUCCESS';
export const DEALS_DELETE_DEAL_CONTACT_SUCCESS = 'DEALS::DELETE_DEAL_CONTACT_SUCCESS';
export const DEALS_UPDATE_DEAL_NOTE_SUCCESS = 'DEALS::UPDATE_DEAL_NOTE_SUCCESS';
export const DEALS_CLEAR_STORE = 'DEALS::CLEAR_STORE';
export const DEALS_FETCH_DEALS_SUCCESS = 'DEALS::FETCH_DEALS_SUCCESS';
export const DEALS_FETCH_DEALS_FAILURE = 'DEALS::FETCH_DEALS_FAILURE';
export const DEALS_FETCH_DEALS_AND_TRANSACTIONS_ES_SUCCESS = 'DEALS::DEALS_FETCH_DEALS_AND_TRANSACTIONS_ES_SUCCESS';
export const DEALS_FETCH_DEALS_AND_TRANSACTIONS_ES_FAILURE = 'DEALS::DEALS_FETCH_DEALS_AND_TRANSACTIONS_ES_FAILURE';
export const DEALS_SET_FETCH_REQUEST_ID = 'DEALS::SET_FETCH_REQUEST_ID';
export const DEALS_NOTE_AND_TASK_SUCCESS = 'DEALS::DEALS_NOTE_AND_TASK_SUCCESS';
export const DEALS_NOTE_AND_TASK_FAILURE = 'DEALS::DEALS_NOTE_AND_TASK_FAILURE';
export const DEALS_LOAD_PROSPECT_COUNT_SUCCESS = 'DEALS::LOAD_PROSPECT_COUNT_SUCCESS';
export const DEALS_LOAD_EXECUTING_COUNT_SUCCESS = 'DEALS::LOAD_EXECUTING_COUNT_SUCCESS';
export const DEALS_LOAD_CLOSED_COUNT_SUCCESS = 'DEALS::LOAD_CLOSED_COUNT_SUCCESS';
export const DEALS_LOAD_DEAL_COUNT_FAILURE = 'DEALS::LOAD_DEAL_COUNT_FAILURE';
export const DEALS_SAVE_QUERY = 'DEALS::SAVE_QUERY';
export const DEALS_LOAD_METADATA_SUCCESS = 'DEALS::LOAD_METADATA_SUCCESS';
export const DEALS_LOAD_METADATA_FAILURE = 'DEALS::LOAD_METADATA_FAILURE';

// deal-contacts
export const ADD_DEAL_CONTACT_SUCCESS = 'DEAL_CONTACTS::ADD_DEAL_CONTACT_SUCCESS';
export const ADD_DEAL_CONTACT_FAILURE = 'DEAL_CONTACTS::ADD_DEAL_CONTACT_FAILURE';
export const DELETE_DEAL_CONTACT_SUCCESS = 'DEAL_CONTACTS::DELETE_CONTACT_SUCCESS';
export const DELETE_DEAL_CONTACT_FAILURE = 'DEAL_CONTACTS::DELETE_CONTACT_FAILURE';
export const IS_LOADING_CONTACTS_ACTION = 'DEAL_CONTACTS::IS_LOADING';
export const FETCH_DEAL_CONTACTS_SUCCESS = 'DEAL_CONTACTS::FETCH_DEAL_CONTACTS_SUCCESS';
export const FETCH_DEAL_CONTACTS_FAILURE = 'DEAL_CONTACTS::FETCH_DEAL_CONTACTS_FAILURE';
export const UPDATE_DEAL_CONTACT_SUCCESS = 'DEAL_CONTACTS::UPDATE_DEAL_CONTACT_SUCCESS';
export const UPDATE_DEAL_CONTACT_FAILURE = 'DEAL_CONTACTS::UPDATE_DEAL_CONTACT_FAILURE';

// deal-notes
export const ADD_DEAL_NOTE_SUCCESS = 'DEAL_NOTES::ADD_DEAL_NOTE_SUCCESS';
export const ADD_DEAL_NOTE_FAILURE = 'DEAL_NOTES::ADD_DEAL_NOTE_FAILURE';
export const DELETE_DEAL_NOTE_SUCCESS = 'DEAL_NOTES::DELETE_DEAL_NOTE_SUCCESS';
export const DELETE_DEAL_NOTE_FAILURE = 'DEAL_NOTES::DELETE_DEAL_NOTE_FAILURE';
export const IS_LOADING_NOTES_ACTION = 'DEAL_NOTES::IS_LOADING';
export const FETCH_DEAL_NOTES_SUCCESS = 'DEAL_NOTES::FETCH_DEAL_NOTES_SUCCESS';
export const FETCH_DEAL_NOTES_FAILURE = 'DEAL_NOTES::FETCH_DEAL_NOTES_FAILURE';
export const UPDATE_DEAL_NOTE_SUCCESS = 'DEAL_NOTES::UPDATE_DEAL_NOTE_SUCCESS';
export const UPDATE_DEAL_NOTE_FAILURE = 'DEAL_NOTES::UPDATE_DEAL_NOTE_FAILURE';
export const NEW_NOTE_CLEANUP = 'DEAL_NOTES::NEW_NOTE_CLEANUP';

// deal-tasks
export const ADD_DEAL_TASK_SUCCESS = 'DEAL_TASKS::ADD_DEAL_TASK_SUCCESS';
export const ADD_DEAL_TASK_FAILURE = 'DEAL_TASKS::ADD_DEAL_TASK_FAILURE';
export const DELETE_DEAL_TASK_SUCCESS = 'DEAL_TASKS::DELETE_DEAL_TASK_SUCCESS';
export const DELETE_DEAL_TASK_FAILURE = 'DEAL_TASKS::DELETE_DEAL_TASK_FAILURE';
export const IS_LOADING_TASKS_ACTION = 'DEAL_TASKS::IS_LOADING';
export const FETCH_DEAL_TASKS_SUCCESS = 'DEAL_TASKS::FETCH_DEAL_TASKS_SUCCESS';
export const FETCH_DEAL_TASKS_FAILURE = 'DEAL_TASKS::FETCH_DEAL_TASKS_FAILURE';
export const UPDATE_DEAL_TASK_SUCCESS = 'DEAL_TASKS::UPDATE_DEAL_TASK_SUCCESS';
export const UPDATE_DEAL_TASK_FAILURE = 'DEAL_TASKS::UPDATE_DEAL_TASK_FAILURE';

// deal-team
export const LOAD_DEAL_TEAM_SUCCESS = 'DEAL_TEAM::LOAD_DEAL_TEAM_SUCCESS';
export const LOAD_DEAL_TEAM_FAILURE = 'DEAL_TEAM::LOAD_DEAL_TEAM_FAILURE';
export const LOAD_COMMISSION_TEAM_SUCCESS = 'DEAL_TEAM::LOAD_COMMISSION_TEAM_SUCCESS';
export const LOAD_COMMISSION_TEAM_FAILURE = 'DEAL_TEAM::LOAD_COMMISSION_TEAM_FAILURE';
export const UPDATE_DEAL_USERS_SUCCESS = 'DEAL_TEAM::UPDATE_DEAL_USERS_SUCCESS';
export const UPDATE_DEAL_USERS_FAILURE = 'DEAL_TEAM::UPDATE_DEAL_USERS_FAILURE';
export const UPDATE_COMMISSION_USERS_SUCCESS = 'DEAL_TEAM::UPDATE_COMMISSION_USERS_SUCCESS';
export const UPDATE_COMMISSION_USERS_FAILURE = 'DEAL_TEAM::UPDATE_COMMISSION_USERS_FAILURE';
export const UPDATE_DEAL_TEAMS_SUCCESS = 'DEAL_TEAM::UPDATE_DEAL_TEAMS_SUCCESS';
export const UPDATE_DEAL_TEAMS_FAILURE = 'DEAL_TEAM::UPDATE_DEAL_TEAMS_FAILURE';
export const UPDATE_COMMISSION_TEAMS_SUCCESS = 'DEAL_TEAM::UPDATE_COMMISSION_TEAMS_SUCCESS';
export const UPDATE_COMMISSION_TEAMS_FAILURE = 'DEAL_TEAM::UPDATE_COMMISSION_TEAMS_FAILURE';

// properties
export const ADD_PROPERTY_SUCCESS = 'PROPERTIES::ADD_PROPERTY_SUCCESS';
export const ADD_PROPERTY_FAILURE = 'PROPERTIES::ADD_PROPERTY_FAILURE';
export const DELETE_PROPERTY_SUCCESS = 'PROPERTIES::DELETE_PROPERTY_SUCCESS';
export const DELETE_PROPERTY_FAILURE = 'PROPERTIES::DELETE_PROPERTY_FAILURE';
export const LOAD_PROPERTIES_SUCCESS = 'PROPERTIES::LOAD_PROPERTIES_SUCCESS';
export const LOAD_PROPERTIES_FAILURE = 'PROPERTIES::LOAD_PROPERTIES_FAILURE';
export const IS_LOADING_PROPERTIES = 'PROPERTIES::IS_LOADING_PROPERTIES';
export const UPDATE_PROPERTY_SUCCESS = 'PROPERTIES::UPDATE_PROPERTY_SUCCESS';
export const UPDATE_PROPERTY_FAILURE = 'PROPERTIES::UPDATE_PROPERTY_FAILURE';

// tags
export const ADD_TAG_TO_ALL_CONTACTS_TAGS = 'TAGS::ADD_TAG_TO_ALL_CONTACTS_TAGS';
export const FETCH_ALL_CONTACTS_TAGS_FAILURE = 'TAGS::FETCH_ALL_CONTACTS_TAGS_FAILURE';
export const FETCH_ALL_CONTACTS_TAGS_SUCCESS = 'TAGS::FETCH_ALL_CONTACTS_TAGS_SUCCESS';
export const TAGS_ADD_TAG_TO_DEAL_TAGS = 'TAGS::ADD_TAG_TO_DEAL_TAGS';
export const TAGS_FETCH_DEAL_TAGS_FAILURE = 'TAGS::FETCH_DEAL_TAGS_FAILURE';
export const TAGS_FETCH_DEAL_TAGS_SUCCESS = 'TAGS::FETCH_DEAL_TAGS_SUCCESS';

// teams
export const ADD_TEAM_SUCCESS = 'TEAMS::ADD_TEAM_SUCCESS';
export const ADD_TEAM_FAILURE = 'TEAMS::ADD_TEAM_FAILURE';
export const FETCH_TEAMS_SUCCESS = 'TEAMS::FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_FAILURE = 'TEAMS::FETCH_TEAMS_FAILURE';
export const FETCH_TEAM_BY_ID_SUCCESS = 'TEAMS::FETCH_TEAM_BY_ID_SUCCESS';
export const FETCH_TEAM_BY_ID_FAILURE = 'TEAMS::FETCH_TEAM_BY_ID_FAILURE';
export const UPDATE_TEAM_SUCCESS = 'TEAMS::UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_FAILURE = 'TEAMS::UPDATE_TEAM_FAILURE';
export const UPDATE_USER_TEAM_AUTO_ADD_SUCCESS = 'TEAMS::UPDATE_USER_TEAM_AUTO_ADD_SUCCESS';
export const UPDATE_USER_TEAM_AUTO_ADD_FAILURE = 'TEAMS::UPDATE_USER_TEAM_AUTO_ADD_FAILURE';
export const ADD_TEAM_MEMBER_SUCCESS = 'TEAMS::ADD_TEAM_MEMBER_SUCCESS';
export const ADD_TEAM_MEMBER_FAILURE = 'TEAMS::ADD_TEAM_MEMBER_FAILURE';
export const REMOVE_TEAM_MEMBER_SUCCESS = 'TEAMS::REMOVE_TEAM_MEMBER_SUCCESS';
export const REMOVE_TEAM_MEMBER_FAILURE = 'TEAMS::REMOVE_TEAM_MEMBER_FAILURE';
export const REMOVE_TEAM_AND_MEMBER_SUCCESS = 'TEAMS::REMOVE_TEAM_AND_MEMBER_SUCCESS';
export const REMOVE_TEAM_AND_MEMBER_FAILURE = 'TEAMS::REMOVE_TEAM_AND_MEMBER_FAILURE';

// user
export const LOAD_USER_DATA_SUCCESS = 'LOAD_USER_DATA_SUCCESS';
export const LOAD_USER_DATA_FAILURE = 'LOAD_USER_DATA_FAILURE';
export const IS_LOADING_USER_DATA = 'IS_LOADING_USER_DATA';
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
export const UPDATE_USER_DATA_FAILURE = 'UPDATE_USER_DATA_FAILURE';
export const IS_UPDATING_USER_DATA = 'IS_UPDATING_USER_DATA';

// user options
export const LOAD_USER_OPTIONS_SUCCESS = 'LOAD_USER_OPTIONS_SUCCESS';
export const LOAD_USER_OPTIONS_FAILURE = 'LOAD_USER_OPTIONS_FAILURE';
export const IS_LOADING_USER_OPTIONS = 'IS_LOADING_USER_OPTIONS';
export const UPDATE_USER_OPTIONS_SUCCESS = 'UPDATE_USER_OPTIONS_SUCCESS';
export const UPDATE_USER_OPTIONS_FAILURE = 'UPDATE_USER_OPTIONS_FAILURE';
export const IS_UPDATING_USER_OPTIONS = 'IS_UPDATING_USER_OPTIONS';

// voucher
export const FETCH_VOUCHER_SUCCESS = 'FETCH_VOUCHER_SUCCESS';
export const FETCH_RECIPIENTS_SUCCESS = 'FETCH_RECIPIENTS_SUCCESS';
export const FETCH_VOUCHER_OPTIONS_SUCCESS = 'FETCH_VOUCHER_OPTIONS_SUCCESS';
export const CLEAR_FINANCIALS = 'CLEAR_FINANCIALS';
export const FETCH_OFFICE_TYPES = 'FETCH_OFFICE_TYPES';
export const FETCH_COMMERCIAL_PROPERTIES = 'FETCH_COMMERCIAL_PROPERTIES';
export const CREATE_COMMERCIAL_PROPERTIES = 'CREATE_COMMERCIAL_PROPERTIES';
export const DELETE_COMMERCIAL_PROPERTIES = 'DELETE_COMMERCIAL_PROPERTIES';
export const UPDATE_COMMERCIAL_PROPERTIES = 'UPDATE_COMMERCIAL_PROPERTIES';
export const FETCH_MTA_NAICS_CHOICES = 'FETCH_MTA_NAICS_CHOICES';
export const FETCH_MTA_NAICS_CHOICES_BY_CODE = 'FETCH_MTA_NAICS_CHOICES_BY_CODE';

// voucher-additional-commissions
export const FETCH_ADDITIONAL_COMMISSIONS_SUCCESS = 'FETCH_ADDITIONAL_COMMISSIONS_SUCCESS';
export const UPDATE_ADDITIONAL_COMMISSIONS_IN_MODAL = 'UPDATE_ADDITIONAL_COMMISSIONS_IN_MODAL';

// additional-commissions
export const FETCH_ADDITIONAL_COMMISSIONS_BY_DEAL_ID_SUCCESS = 'FETCH_ADDITIONAL_COMMISSIONS_BY_DEAL_ID_SUCCESS';
export const CREATE_ADDITIONAL_COMMISSIONS_SUCCESS = 'CREATE_ADDITIONAL_COMMISSIONS_SUCCESS';
export const DELETE_ADDITIONAL_COMMISSIONS_SUCCESS = 'DELETE_ADDITIONAL_COMMISSIONS_SUCCESS';
export const UPDATE_ADDITIONAL_COMMISSIONS_SUCCESS = 'UPDATE_ADDITIONAL_COMMISSIONS_SUCCESS';

// shared fees
export const FETCH_SHARED_FEES_SUCCESS = 'FETCH_SHARED_FEES_SUCCESS';
export const CREATE_SHARED_FEE_SUCCESS = 'CREATE_SHARED_FEE_SUCCESS';
export const DELETE_SHARED_FEE_SUCCESS = 'DELETE_SHARED_FEE_SUCCESS';
export const UPDATE_SHARED_FEE_SUCCESS = 'UPDATE_SHARED_FEE_SUCCESS';

// rebate
export const FETCH_REBATES_SUCCESS = 'FETCH_REBATES_SUCCESS';
export const CREATE_REBATE_SUCCESS = 'CREATE_REBATE_SUCCESS';
export const DELETE_REBATE_SUCCESS = 'DELETE_REBATE_SUCCESS';
export const UPDATE_REBATE_SUCCESS = 'UPDATE_REBATE_SUCCESS';

// voucher-installments
export const FETCH_INSTALLMENT_OPTIONS_SUCCESS = 'FETCH_INSTALLMENT_OPTIONS_SUCCESS';
export const FETCH_INSTALLMENTS_SUCCESS = 'FETCH_INSTALLMENTS_SUCCESS';
export const UPDATE_INSTALLMENT_SUCCESS = 'UPDATE_INSTALLMENT_SUCCESS';
export const CREATE_INSTALLMENT_SUCCESS = 'CREATE_INSTALLMENT_SUCCESS';
export const DELETE_INSTALLMENT_SUCCESS = 'DELETE_INSTALLMENT_SUCCESS';

// voucher-commissions
export const FETCH_COMMISSIONS_SUCCESS = 'FETCH_COMMISSIONS_SUCCESS';
export const UPDATE_COMMISSION_SUCCESS = 'UPDATE_COMMISSION_SUCCESS';
export const CREATE_COMMISSION_SUCCESS = 'CREATE_COMMISSION_SUCCESS';
export const DELETE_COMMISSION_SUCCESS = 'DELETE_COMMISSION_SUCCESS';

// voucher-outside-broker-commissions
export const FETCH_OUTSIDE_BROKER_COMMISSIONS_SUCCESS = 'FETCH_OUTSIDE_BROKER_COMMISSIONS_SUCCESS';
export const UPDATE_OUTSIDE_BROKER_COMMISSION_SUCCESS = 'UPDATE_OUTSIDE_BROKER_COMMISSION_SUCCESS';
export const CREATE_OUTSIDE_BROKER_COMMISSION_SUCCESS = 'CREATE_OUTSIDE_BROKER_COMMISSION_SUCCESS';
export const DELETE_OUTSIDE_BROKER_COMMISSION_SUCCESS = 'DELETE_OUTSIDE_BROKER_COMMISSION_SUCCESS';
export const UPDATE_OUTSIDE_BROKER_COMMISSION_VENDOR_SUCCESS = 'UPDATE_OUTSIDE_BROKER_COMMISSION_VENDOR_SUCCESS';

// voucher-commissions
export const VOUCHER_FETCH_COMMISSIONS_SUCCESS = 'VOUCHER_FETCH_COMMISSIONS_SUCCESS';
export const VOUCHER_UPDATE_COMMISSION_SUCCESS = 'VOUCHER_UPDATE_COMMISSION_SUCCESS';
export const VOUCHER_CREATE_COMMISSION_SUCCESS = 'VOUCHER_CREATE_COMMISSION_SUCCESS';
export const VOUCHER_DELETE_COMMISSION_SUCCESS = 'VOUCHER_DELETE_COMMISSION_SUCCESS';

// voucher-comp-data
export const FETCH_COMP_DATA_SUCCESS = 'FETCH_COMP_DATA_SUCCESS';
export const UPDATE_COMP_DATA_SUCCESS = 'UPDATE_COMP_DATA_SUCCESS';
export const CREATE_COMP_DATA_SUCCESS = 'CREATE_COMP_DATA_SUCCESS';

// voucher-considerations
export const FETCH_CONSIDERATIONS_SUCCESS = 'FETCH_CONSIDERATIONS_SUCCESS';
export const UPDATE_CONSIDERATIONS_IN_MODAL = 'UPDATE_CONSIDERATIONS_IN_MODAL';

// considerations
export const FETCH_CONSIDERATIONS_BY_DEAL_ID_SUCCESS = 'FETCH_CONSIDERATIONS_BY_DEAL_ID_SUCCESS';
export const CREATE_CONSIDERATION_SUCCESS = 'CREATE_CONSIDERATION_SUCCESS';
export const DELETE_CONSIDERATION_SUCCESS = 'DELETE_CONSIDERATION_SUCCESS';
export const UPDATE_CONSIDERATION_SUCCESS = 'UPDATE_CONSIDERATION_SUCCESS';
export const UPDATE_CONSIDERATION_STATE = 'UPDATE_CONSIDERATION_STATE';

// voucher-total-commissions
export const UPDATE_COMMISSIONS_TOTAL = 'UPDATE_COMMISSIONS_TOTAL';
export const INITIALIZE_COMMISSIONS_TOTAL = 'INITIALIZE_COMMISSIONS_TOTAL';

// companies
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const FETCH_COMPANY_BY_ID = 'FETCH_COMPANY';
export const CREATE_COMPANY = 'CREATE_COMPANY';

// countries
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES';

// options
export const FETCH_COMMISSION_OPTIONS_SUCCESS = 'FETCH_COMMISSION_OPTIONS_SUCCESS';

// pipeline
export const UPDATE_TABLE_CONFIG = 'UPDATE_TABLE_CONFIG';
export const RESET_TABLE_CONFIG = 'RESET_TABLE_CONFIG';
export const REORDER_TABLE_CONFIG = 'REORDER_TABLE_CONFIG';

// proxy
export const SET_PROXY_ACCESS = 'SET_PROXY_ACCESS';
