import uniqueId from 'lodash/uniqueId';
import dealsService from '../../services/deals-service';
import contactsService from '../../services/contacts-service';
import localStorageService from '../../services/local-storage-service';
import userEventService from '../../services/user-event-service';
import { toast } from './toasts-actions';
import {
  DEALS_ARCHIVE_DEAL_SUCCESS,
  DEALS_RESTORE_DEAL_SUCCESS,
  DEALS_DELETE_DEAL_SUCCESS,
  DEALS_UPDATE_DEAL_SUCCESS,
  DEALS_UPDATE_DEAL_CONTACT_SUCCESS,
  DEALS_DELETE_DEAL_CONTACT_SUCCESS,
  DEALS_UPDATE_DEAL_NOTE_SUCCESS,
  DEALS_CLEAR_STORE,
  DEALS_FETCH_DEALS_SUCCESS,
  DEALS_FETCH_DEALS_FAILURE,
  DEALS_FETCH_DEALS_AND_TRANSACTIONS_ES_SUCCESS,
  DEALS_FETCH_DEALS_AND_TRANSACTIONS_ES_FAILURE,
  DEALS_SET_FETCH_REQUEST_ID,
  DEALS_LOAD_PROSPECT_COUNT_SUCCESS,
  DEALS_LOAD_EXECUTING_COUNT_SUCCESS,
  DEALS_LOAD_CLOSED_COUNT_SUCCESS,
  DEALS_LOAD_DEAL_COUNT_FAILURE,
  DEALS_SAVE_QUERY,
  DEALS_NOTE_AND_TASK_SUCCESS,
  DEALS_NOTE_AND_TASK_FAILURE,
} from '../actions/action-types';

// Deals Actions Creators
const archiveDealSuccess = deal => ({
  type: DEALS_ARCHIVE_DEAL_SUCCESS,
  payload: { deal },
});

const restoreDealSuccess = deal => ({
  type: DEALS_RESTORE_DEAL_SUCCESS,
  payload: { deal },
});

const deleteDealSuccess = id => ({
  type: DEALS_DELETE_DEAL_SUCCESS,
  payload: { id },
});

const updateDealSuccess = deal => ({
  type: DEALS_UPDATE_DEAL_SUCCESS,
  payload: { deal },
});

const updateDealContactSuccess = (id, contact) => ({
  type: DEALS_UPDATE_DEAL_CONTACT_SUCCESS,
  payload: { id, contact },
});

const deleteDealContactSuccess = id => ({
  type: DEALS_DELETE_DEAL_CONTACT_SUCCESS,
  payload: { id },
});

const updateDealNoteSuccess = (id, note) => ({
  type: DEALS_UPDATE_DEAL_NOTE_SUCCESS,
  payload: { id, note },
});

const clearDealsStore = () => ({
  type: DEALS_CLEAR_STORE,
});

const fetchDealsSuccess = data => ({
  type: DEALS_FETCH_DEALS_SUCCESS,
  payload: { ...data },
});

const fetchDealsFailure = error => ({
  type: DEALS_FETCH_DEALS_FAILURE,
  payload: { error },
});

const fetchDealsAndTransactionsESSuccess = data => ({
  type: DEALS_FETCH_DEALS_AND_TRANSACTIONS_ES_SUCCESS,
  payload: { ...data },
});

const fetchDealsAndTransactionsESFailure = error => ({
  type: DEALS_FETCH_DEALS_AND_TRANSACTIONS_ES_FAILURE,
  payload: { error },
});

const fetchDealNoteAndTaskSuccess = data => ({
  type: DEALS_NOTE_AND_TASK_SUCCESS,
  payload: { ...data },
});

const fetchDealNoteAndTaskFailure = dealIDs => ({
  type: DEALS_NOTE_AND_TASK_FAILURE,
  payload: dealIDs,
});

const setRequestId = requestId => ({
  type: DEALS_SET_FETCH_REQUEST_ID,
  payload: { requestId },
});

const loadProspectCountSuccess = dealCount => ({
  type: DEALS_LOAD_PROSPECT_COUNT_SUCCESS,
  payload: { dealCount },
});

const loadExecutingCountSuccess = dealCount => ({
  type: DEALS_LOAD_EXECUTING_COUNT_SUCCESS,
  payload: { dealCount },
});

const loadClosedCountSuccess = dealCount => ({
  type: DEALS_LOAD_CLOSED_COUNT_SUCCESS,
  payload: { dealCount },
});

const loadDealCountFailure = error => ({
  type: DEALS_LOAD_DEAL_COUNT_FAILURE,
  payload: { error },
});

const saveQuery = query => ({
  type: DEALS_SAVE_QUERY,
  payload: { query },
});


// ANALYTIC CALLS:
const sendSearchAnalytics = (analyticProperties) => {
  const {
    actionPrefix = '',
    afterResults = 0,
    categoryPrefix = '',
    queryParams = {},
  } = analyticProperties;
  const eventAction = `${actionPrefix}_search_${afterResults > 0 ? 'success' : 'null_results'}`;
  const {
    search,
  } = queryParams;
  userEventService.trackEvent({
    eventCategory: `${categoryPrefix}Search`,
    eventAction,
    eventLabel: search,
    eventData: {
      search_term: search,
      query: { ...queryParams },
    },
  });
};

const sendFilterAnalytics = (analyticProperties) => {
  const {
    actionPrefix = '',
    afterResults = 0,
    beforeResults = 0,
    categoryPrefix = '',
    filterDiff = {},
  } = analyticProperties;
  const eventAction = `${actionPrefix}_filter_${afterResults > 0 ? 'success' : 'null_results'}`;
  userEventService.trackEvent({
    eventCategory: `${categoryPrefix}Filter`,
    eventLabel: 'on_filter',
    eventAction,
    eventData: {
      filter_diff: filterDiff,
      before_results: beforeResults,
      after_results: afterResults,
    },
  });
};

// // ASYNC ACTIONS:
const archiveDeal = id => dispatch => dealsService.updateDeal(id, { is_archived: true })
  .then(deal => dispatch(archiveDealSuccess(deal)))
  .then(() => dispatch(toast('Deal archived')))
  .catch(() => dispatch(toast('Error archiving deal')));

const restoreDeal = id => dispatch => (
  dealsService.updateDeal(id, { is_archived: false })
    .then(deal => dispatch(restoreDealSuccess(deal)))
    .then(() => dispatch(toast('Deal restored')))
    .catch(() => dispatch(toast('Error restoring deal')))
);

const updateDeal = (id, params, successMessage) => dispatch => (
  dealsService.updateDeal(id, params)
    .then(deal => dispatch(updateDealSuccess(deal)))
    .then(() => dispatch(toast(successMessage)))
    .catch(() => dispatch(toast('Error updating deal')))
);

const addPersonalContact = params => dispatch =>
  contactsService.postPersonalContact(params)
    .catch(() => {
      dispatch(toast('Error saving personal contact'));
    });

const addPrimaryContact = (id, params) => dispatch =>
  contactsService.postContact(params)
    .then((contact) => {
      if (params.create_personal_contact) {
        dispatch(addPersonalContact(contact));
      }
      dispatch(updateDealContactSuccess(id, contact));
    }).catch(() => {
      dispatch(toast('Error saving deal contact'));
    });

const deletePrimaryContact = (dealId, id) => dispatch =>
  contactsService.deleteContact(id)
    .then(() => {
      dispatch(deleteDealContactSuccess(dealId));
    }).catch(() => {
      dispatch(toast('Error deleting deal contact'));
    });

const updatePrimaryContact = (dealId, id, params) => dispatch =>
  contactsService.patchContact(id, params)
    .then((contact) => {
      dispatch(updateDealContactSuccess(dealId, contact));
    }).catch(() => {
      dispatch(toast('Error updating deal contact'));
    });


const updateDealNote = (id, note) => (dispatch) => {
  dispatch(updateDealNoteSuccess(id, note));
};

const loadDealAndTransactionCount = () => dispatch =>
  dealsService.fetchDealAndTransactionCount()
    .then((data) => {
      dispatch(loadProspectCountSuccess(data.prospect));
      dispatch(loadExecutingCountSuccess(data.executing));
      return dispatch(loadClosedCountSuccess(data.closed));
    })
    .catch(error => dispatch(loadDealCountFailure(error)));

const deleteDeal = id => dispatch => (
  dealsService.updateDeal(id, { is_deleted: true })
    .then(() => dispatch(deleteDealSuccess(id)))
    .then(() => dispatch(loadDealAndTransactionCount()))
    .then(() => dispatch(toast('Deal has been deleted')))
    .catch(() => dispatch(toast('Error deleting deal')))
);

const fetchDealNoteAndTask = dealsIds => dispatch =>
  dealsService.fetchDealNoteAndTask(dealsIds)
    .then((data) => {
      dispatch(fetchDealNoteAndTaskSuccess(data));
    })
    .catch(() => {
      dispatch(fetchDealNoteAndTaskFailure(dealsIds));
    });

/**
 * fetchDeals can be called multiple times leading to the slowest request winning
 * requestId is used to make sure the last request sent is the only one stored
 */
const fetchDeals = (params, analyticProperties) => (dispatch, getState) => {
  const requestId = uniqueId();
  if (params.page === 1) {
    dispatch(clearDealsStore());
  }
  dispatch(setRequestId(requestId));
  return dealsService.fetchDeals(params)
    .then((data) => {
      const lastRequestId = getState().deals.requestId;
      if (requestId === lastRequestId) {
        if (analyticProperties) {
          const { type = '' } = analyticProperties;
          if (type === 'search') {
            sendSearchAnalytics({ ...analyticProperties, afterResults: data.count });
          } else if (type === 'filter') {
            sendFilterAnalytics({ ...analyticProperties, afterResults: data.count });
          }
        }
        dispatch(saveQuery(params));
        if (params.page === 1) {
          localStorageService.saveDealListQuery(params);
        }
        return dispatch(fetchDealsSuccess(data));
      }
      return null;
    })
    .then((data) => {
      const dealIds = (data.payload && data.payload.results)
        ? data.payload.results.reduce((acc, deal) => { acc.push(deal.id); return acc; }, [])
        : [];
      return dealIds.length ? dispatch(fetchDealNoteAndTask(dealIds)) : null;
    })
    .catch(error => dispatch(fetchDealsFailure(error)));
};

/**
 * fetchDealsAndTransactionsES can be called multiple times leading to the slowest request winning
 * requestId is used to make sure the last request sent is the only one stored
 */
const fetchDealsAndTransactionsES = (params, userID, analyticProperties) => (dispatch, getState) => {
  const requestId = uniqueId();
  if (params.page === 1) {
    dispatch(clearDealsStore());
  }
  dispatch(setRequestId(requestId));
  return dealsService.fetchDealsAndTransactionsES(params)
    .then((data) => {
      const lastRequestId = getState().deals.requestId;
      if (requestId === lastRequestId) {
        if (analyticProperties) {
          const { type = '' } = analyticProperties;
          if (type === 'search') {
            sendSearchAnalytics({ ...analyticProperties, afterResults: data.count });
          } else if (type === 'filter') {
            sendFilterAnalytics({ ...analyticProperties, afterResults: data.count });
          }
        }
        dispatch(saveQuery(params));
        if (params.page === 1) {
          localStorageService.saveDealListQuery(params);
        }
        return dispatch(fetchDealsAndTransactionsESSuccess(data));
      }
      return null;
    })
    .then((data) => {
      const dealIds = (data.payload && data.payload.results)
        ? data.payload.results.reduce((acc, deal) => {
          acc.push(deal.id);
          return acc;
        }, [])
        : [];
      return dealIds.length ? dispatch(fetchDealNoteAndTask(dealIds)) : null;
    })
    .catch(error => dispatch(fetchDealsAndTransactionsESFailure(error)));
};

export {
  archiveDeal,
  deleteDeal,
  fetchDeals,
  fetchDealsAndTransactionsES,
  loadDealAndTransactionCount,
  restoreDeal,
  updateDeal,
  addPrimaryContact,
  addPersonalContact,
  deletePrimaryContact,
  updatePrimaryContact,
  updateDealNote,
  fetchDealNoteAndTask,
};
