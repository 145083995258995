import { connect } from 'react-redux';
import {
  updateDeal,
} from '../../../redux/actions/deal-actions';
import {
  fetchCommercialProperty,
  updateVoucher,
} from '../../../redux/actions/voucher/voucher-actions';
import LeaseInformation from './LeaseInformationTab';


const mapStateToProps = state => ({
  deal: state.deal.deal,
  voucher: state.voucher.voucher,
  commercialProperties: state.voucher.commercialProperties,
});

const mapDispatchToProps = {
  updateDeal,
  updateVoucher,
  fetchCommercialProperty,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeaseInformation);
