import { connect } from 'react-redux';

import PropertyTab from './PropertyTab';

const mapStateToProps = state => ({
  deal: state.deal.deal,
  voucher: state.voucher.voucher,
});

const PropertyTabContainer = connect(
  mapStateToProps,
  null,
)(PropertyTab);

export default PropertyTabContainer;
