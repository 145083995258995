import { combineReducers } from 'redux';
import admin from './admin-reducers';
import alerts from './alerts-reducers';
import deal from './deal-reducers';
import deals from './deals-reducers';
import dealsMetadata from './deals-metadata-reducers';
import dealContacts from './deal-contacts-reducers';
import dealFiles from './deal-files-reducers';
import dealNotes from './deal-notes-reducers';
import dealTasks from './deal-tasks-reducers';
import globalSearch from './global-search-reducers';
import properties from './properties-reducers';
import tags from './tags-reducers';
import teamMembers from './team-members-reducers';
import teamsPage from './teams-reducers';
import toasts from './toasts-reducer';
import userData from './user-service-reducers';
import userOptions from './user-options-reducers';
import companies from './companies-reducers';
// vouchers
import voucher from './voucher/voucher-reducers';
import voucherCommissions from './voucher/commissions-reducers';
import { considerations, tempConsiderations, commissionsTotal } from './voucher/considerations-reducers';
import voucherConsiderations from './considerations-reducers';
import voucherInstallments from './voucher/installments-reducers';
import { additionalCommissions, tempAdditionalCommissions } from './voucher/additional-commissions-reducers';
import voucherAdditionalCommissions from './additional-commissions-reducers';
import outsideBrokerCommissions from './outside-broker-commissions-reducer';
import sharedFees from './shared-fees-reducer';
import rebates from './rebates-reducers';
import options from './options-reducers';
import commissions from './commissions-reducers';
import compData from './voucher/comp-data-reducers';
// pipeline
import tableConfig from './pipeline-table-reducers';
import proxy from './proxy-settings-reducers';

export default combineReducers({
  admin,
  alerts,
  deal,
  deals,
  dealsMetadata,
  dealContacts,
  dealFiles,
  dealNotes,
  dealTasks,
  globalSearch,
  properties,
  tags,
  teamMembers,
  companies,
  teamsPage,
  toasts,
  userData,
  userOptions,
  voucher,
  voucherCommissions,
  voucherInstallments,
  considerations,
  tempConsiderations,
  additionalCommissions,
  tempAdditionalCommissions,
  commissionsTotal,
  voucherAdditionalCommissions,
  voucherConsiderations,
  outsideBrokerCommissions,
  sharedFees,
  rebates,
  options,
  commissions,
  compData,
  tableConfig,
  proxy,
});
