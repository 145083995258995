import { connect } from 'react-redux';
import { addDealRequirements, updateDealRequirements, updateDeal } from '../../../redux/actions/deal-actions';

import DealRequirementsForm from './deal-requirements-form';

const mapStateToProps = state => ({
  deal: state.deal.deal || undefined,
  user: state.userData.data,
});

const mapDispatchToProps = {
  addDealRequirements,
  updateDealRequirements,
  updateDeal,
};

const DealRequirementsFormContainer = connect(mapStateToProps, mapDispatchToProps)(DealRequirementsForm);

export default DealRequirementsFormContainer;
