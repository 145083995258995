import additionalCommissionsService from '../../services/voucher/additional-commissions-service';

import {
  CREATE_ADDITIONAL_COMMISSIONS_SUCCESS,
  DELETE_ADDITIONAL_COMMISSIONS_SUCCESS,
  FETCH_ADDITIONAL_COMMISSIONS_BY_DEAL_ID_SUCCESS,
  FETCH_VOUCHER_OPTIONS_SUCCESS,
  UPDATE_ADDITIONAL_COMMISSIONS_SUCCESS,
} from './action-types';
import { updateFinancials, FINANCIAL_TYPES } from './financial-actions';

// action creators
const createAdditionalCommissionSuccess = additionalCommission => ({
  type: CREATE_ADDITIONAL_COMMISSIONS_SUCCESS,
  payload: additionalCommission,
});

const deleteAdditionalCommissionSuccess = id => ({
  type: DELETE_ADDITIONAL_COMMISSIONS_SUCCESS,
  payload: id,
});

const fetchAddtionalCommissionTypeOptionsSuccess = options => ({
  type: FETCH_VOUCHER_OPTIONS_SUCCESS,
  payload: { additionalCommissionTypeOptions: options },
});

const fetchAdditionalCommissionsSuccess = commissions => ({
  type: FETCH_ADDITIONAL_COMMISSIONS_BY_DEAL_ID_SUCCESS,
  payload: commissions,
});

const updateAdditionalCommissionSuccess = commission => ({
  type: UPDATE_ADDITIONAL_COMMISSIONS_SUCCESS,
  payload: commission,
});

// dispatches
const fetchAdditionalCommissionTypeOptions = () => dispatch =>
  additionalCommissionsService.fetchAdditionalCommissionTypeOptions()
    .then(options => dispatch(fetchAddtionalCommissionTypeOptionsSuccess(options)))
    .catch(error => console.error('Error fetching additional commission type options: ', error));

const createAdditionalCommission = post => dispatch =>
  additionalCommissionsService.createCommission(post)
    .then(res => dispatch(createAdditionalCommissionSuccess(res)))
    .catch(error => console.error('Error creating additional commission: ', error));

const deleteAdditionalCommission = id => (dispatch, getState) =>
  additionalCommissionsService.deleteCommission(id)
    .then(() => {
      dispatch(updateFinancials(getState().deal.deal.id, FINANCIAL_TYPES.ADDITIONAL_COMMISSION));
      dispatch(deleteAdditionalCommissionSuccess(id));
    })
    .catch(error => console.error('Error deleting consideration: ', error));

const fetchAdditionalCommissions = params => dispatch =>
  additionalCommissionsService.fetchCommissions(params)
    .then(commissions => dispatch(fetchAdditionalCommissionsSuccess(commissions)))
    .catch(error => console.error('Error fetching considerations: ', error));

const updateAdditionalCommissions = (id, params) => dispatch =>
  additionalCommissionsService.patchCommission(id, params)
    .then((res) => {
      // only patch financials if commission amount changes
      if ('commission_amount' in params) {
        dispatch(updateFinancials(res.transaction, FINANCIAL_TYPES.ADDITIONAL_COMMISSION));
      }
      dispatch(updateAdditionalCommissionSuccess(res));
    })
    .catch(error => console.error('Error updating consideration: ', error));

export {
  createAdditionalCommission,
  deleteAdditionalCommission,
  fetchAdditionalCommissionTypeOptions,
  fetchAdditionalCommissions,
  updateAdditionalCommissions,
};
