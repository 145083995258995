import { connect } from 'react-redux';
import {
  addProperty,
  deleteProperty,
  loadProperties,
  updateProperty,
} from '../../../redux/actions/properties-actions';
import PropertiesTab from './properties-tab';

const mapStateToProps = state => ({
  currentProperties: state.properties.properties
    ? state.properties.properties.filter(p => p.usage === 'Current')
    : [],
  deal: state.deal.deal || {},
  prospectiveProperties: state.properties.properties
    ? state.properties.properties.filter(p => p.usage === 'Prospective')
    : [],
});

const mapDispatchToProps = {
  addProperty,
  deleteProperty,
  loadProperties,
  updateProperty,
};

const mergeProps = (state, actions) => ({
  ...state,
  ...actions,
  analyticProperties: {
    actionPrefix: 'properties_tab',
    categoryPrefix: 'Properties Tab ',
  },
});

const PropertiesTabContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(PropertiesTab);

export default PropertiesTabContainer;
