import React from 'react';
import { Card, InventoryCard } from '../../nucleus/cards/cards';
import AddItem from '../../nucleus/add-item/add-item';
import Notice from '../../nucleus/notices/notice';

function TwoThirdsOneThird() {
  return (
    <div className="page-container">
      <Notice text="This is Info Notice" type="info" />
      <div className="col-container">
        <div className="col flex-2">
          <Card>
            <h2>Card Header</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nulla a tempor libero. Aenean eu condimentum est. In semper a dolor quis imperdiet.
              Cras et ultrices est. Quisque vestibulum ullamcorper maximus.
              Integer maximus vehicula magna, id vulputate nunc porta ut.
              Proin convallis pretium convallis. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Mauris suscipit
              lacus sit amet vulputate tempor.
            </p>
          </Card>
          <InventoryCard>
            <div className="nd-inventory-card-row">
              <h2>Inventory Card</h2>
              <a>Call To Action</a>
            </div>
            <div className="nd-inventory-card-row">
              <AddItem label="add item" />
            </div>
          </InventoryCard>
        </div>
        <div className="col flex-1">
          {/* Leave this column empty if only left column is used */}
          <Card>
            <h2>Card Header</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nulla a tempor libero. Aenean eu condimentum est. In semper a dolor quis imperdiet.
              Cras et ultrices est. Quisque vestibulum ullamcorper maximus.
              Integer maximus vehicula magna, id vulputate nunc porta ut.
              Proin convallis pretium convallis. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Mauris suscipit
              lacus sit amet vulputate tempor.
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default TwoThirdsOneThird;
