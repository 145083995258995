import { IconButton, Checkbox } from '@cbrebuild/blocks';
import { uniqueId } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RowItem from '../../../nucleus/row-item/row-item';
import { addRecipient, removeRecipient, updateVoucher } from '../../../redux/actions/voucher/voucher-actions';
import VoucherRecipientAutocomplete from '../../autocomplete/voucher-recipient-autocomplete';
import { Person, PersonType } from '../../person';

const Send = () => {
  const {
    voucher, voucherRecipients,
  } = useSelector(state => ({
    voucher: state.voucher.voucher,
    voucherRecipients: state.voucher.voucher?.send_to_email_addresses || state.voucher.voucher.recipients,
  }));
  const [isRushCheck, setIsRushCheck] = useState(voucher.is_rush_invoice);
  const dispatch = useDispatch();

  const filterVoucherRecipientResultsPredicate = recipient =>
    (!voucherRecipients.some(person => (recipient.user_id === person.user_id)));

  const addVoucherRecipient = (recipient) => {
    if (recipient) {
      // Individually typed email option
      if (!recipient.work_email) {
        dispatch(addRecipient(voucher.id, { email: recipient }));
      }

      const data = {
        email: recipient.work_email,
        employee_id: recipient.id,
        first_name: recipient.first_name,
        is_rush_invoice: isRushCheck,
      };
      dispatch(addRecipient(voucher.id, data));
    }
  };

  const removeVoucherRecipient = (recipient) => {
    if (recipient) {
      const data = {
        email: recipient.work_email || recipient.email,
        employee_id: recipient.id || recipient.employee_id,
      };
      dispatch(removeRecipient(voucher.id, data));
    }
  };

  const onRushCheck = (e) => {
    dispatch(updateVoucher(voucher.id, { is_rush_invoice: e.target.checked }));
    setIsRushCheck(!isRushCheck);
  };

  return (
    <div className="send-tab">
      <h2>Send {voucher.is_early_invoice ? 'Early Invoice Request' : 'Voucher'}</h2>
      <p><strong>Note:</strong> To send your voucher and associated files to Team members and Business Operations,
        you must include the individual/group email address in the list below. ONLY email addresses in the list below
        will receive the {voucher.is_early_invoice ? 'early invoice' : 'voucher'} and associated files.
      </p>
      <div className="search-bar">
        <div className="search-bar-item">
          <VoucherRecipientAutocomplete
            filterResultsPredicate={filterVoucherRecipientResultsPredicate}
            onSelect={addVoucherRecipient}
            inputLabel="Add individual email"
            placeholder={voucher.is_early_invoice ? 'Add Early Invoice Recipient' : 'Add Voucher Recipient'}
          />
        </div>
        <div className="search-bar-item">
          <VoucherRecipientAutocomplete
            onSelect={addVoucherRecipient}
            inputLabel="Add shared email"
            placeholder="Add Shared Email Address"
            isSharedEmailAutocomplete
          />
        </div>
      </div>
      <div className="list">
        <h2>Email the {voucher.is_early_invoice ? 'early invoice request' : 'voucher'} to the following people</h2>
        {voucherRecipients.map(recipient => (
          <RowItem key={recipient.user_id || uniqueId()} border >
            <Person
              person={recipient}
              type={recipient.first_name ? PersonType.MEMBER : PersonType.RECIPIENT}
            />
            <IconButton
              className="blxs-button-icon-small"
              iconName="close"
              onClick={() => removeVoucherRecipient(recipient)}
              variant="basic"
            />
          </RowItem>
          ))}
      </div>
      <div className="field-group">
        <Checkbox
          checked={isRushCheck}
          onChange={e => onRushCheck(e)}
        >Rush Invoice Needed
        </Checkbox>
      </div>
    </div>
  );
};

export default Send;
