import React from 'react';
import { IconButton } from '@cbrebuild/blocks';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Percent from '../../../nucleus/formats/percent';
import VOUCHER_ROUTES from '../../../page-templates/voucher/voucher-subroutes';
import formatAsCurrency from '../../../utils/format-as-currency';

const InstallmentsReview = () => {
  const {
    deal, voucher, voucherInstallments,
  } = useSelector(state => ({
    deal: state.deal.deal,
    voucher: state.voucher.voucher,
    voucherInstallments: state.voucherInstallments,
  }));
  const history = useHistory();
  const url = VOUCHER_ROUTES[2].routes[3].path.replace(':id', voucher.deal);
  const requiredText = <p className="voucher-error-text">Required</p>;

  return (
    <div className="voucher-review-group installments-review">
      <div className="navigation">
        <Link className="h2 link" to={url}>Installments</Link>
        <IconButton iconName="edit" onClick={() => { history.push(url); }} />
      </div>
      <div className="content">
        {!voucherInstallments.length &&
          <div className="voucher-error-text">You must add at least one installment to this {voucher.is_early_invoice ? 'early invoice' : 'voucher'}</div>}
        {voucherInstallments.length ?
          <table>
            <colgroup>
              <col name="due upon" />
              <col />
              <col name="allocation" />
              <col name="amount" />
              <col name="due date" />
            </colgroup>
            <tbody>
              <tr className="borderless-row">
                <th>{deal.deal_type === 'lease' && <>Due Upon</>}</th>
                <th />
                <th>Allocation</th>
                <th>Amount</th>
                <th>Due Date</th>
              </tr>
              {voucherInstallments.map(installment => (
                <tr key={installment.id}>
                  <td>{deal.deal_type === 'lease' && (installment.voucher_contingency_code || requiredText)}</td>
                  <td />
                  <td><Percent decimalRounding={2} value={Number(installment.voucher_installment_percent)} emptyState="0%" /></td>
                  <td>{(installment.installment_amount || installment?.installment_amount === 0)
                    ? formatAsCurrency(installment.installment_amount) : requiredText}
                  </td>
                  <td>{installment.installment_date ? moment(installment.installment_date).format('ll') : requiredText}</td>
                </tr>
              ))}
            </tbody>
          </table>
        : ''}
      </div>
    </div>
  );
};

export default InstallmentsReview;
