import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ButtonWithFlyout from '../button-with-flyout/button-with-flyout';
import NDButton from '../button/button';

const ListSort = (props) => {
  const {
    currentSortingKey,
    disabled,
    options,
    onSortAborted,
    position,
  } = props;

  let actionIcon = '';
  const [sortDirection, setSortDirection] = useState(currentSortingKey.includes('-') ? 'descending' : 'ascending');
  const [sortKey, setSortKey] = useState(currentSortingKey);

  const setCurrentOption = (option) => {
    let newOrdering = '';
    if (option.descSortingKey.includes(sortKey)) {
      setSortDirection(sortDirection === 'ascending'
        ? 'descending'
        : 'ascending');
      newOrdering = sortDirection === 'ascending'
        ? option.descSortingKey
        : option.ascSortingKey;
    } else {
      setSortDirection('descending');
      newOrdering = option.descSortingKey;
    }
    setSortKey(newOrdering);
    props.onChange(newOrdering);
  };

  const currentOption = options.find(option =>
    (option.ascSortingKey === sortKey ||
      option.descSortingKey === sortKey)) || {};

  const renderSortingOptions = options && options.map((option) => {
    const isActive = (
      option.ascSortingKey === sortKey ||
      option.descSortingKey === sortKey
    );
    const getSortingOrderClass = () => {
      let orderingIcon = '';
      if (isActive) {
        orderingIcon = sortDirection === 'ascending'
          ? 'arrow-up'
          : 'arrow-down';
        actionIcon = orderingIcon === 'arrow-up'
          ? 'sort-reverse'
          : 'sort';
      }
      return orderingIcon;
    };
    return (
      <li key={option.ascSortingKey} className={`option-item ${isActive ? 'active' : ''}`}>
        <NDButton
          variant="option"
          width="full"
          selected={isActive}
          icon={getSortingOrderClass()}
          iconPosition="right"
          onMouseDown={() => setCurrentOption(option)}
          dataE2e={option.dataE2e}
        >
          {option.displayName}
        </NDButton>
      </li>
    );
  });

  return (
    <ButtonWithFlyout
      actionIcon={actionIcon}
      buttonText={currentOption.displayName}
      buttonIcon="chevron-down"
      closeFlyoutOnClick
      disabled={disabled}
      onAbort={onSortAborted}
      position={position}
      dataE2e="sort-flyout-button"
    >
      <ul>
        {renderSortingOptions}
      </ul>
    </ButtonWithFlyout>
  );
};

ListSort.propTypes = {
  currentSortingKey: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onSortAborted: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    displayName: PropTypes.string,
    ascSortingKey: PropTypes.string,
    descSortingKey: PropTypes.string,
  })).isRequired,
  position: PropTypes.string,
};

ListSort.defaultProps = {
  currentSortingKey: '',
  disabled: false,
  onChange: () => {},
  onSortAborted: () => {},
  position: 'right',
};

export default ListSort;
