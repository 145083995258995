import { connect } from 'react-redux';
import { loadDealTeam, removeDealMember, addDealMember } from '../actions/team-members-actions';
import { loadDeal, updateDeal, clearDealStore } from '../actions/deal-actions';
import DealDetailPage from '../../page-templates/deals/deal-detail-page';

const mapStateToProps = state => ({
  deal: state.deal.deal,
  dealUsers: state.teamMembers.dealUsers,
  relatedTransactionUsers: state.teamMembers.relatedTransactionUsers,
  relatedTransactionTeams: state.teamMembers.relatedTransactionTeams,
  dealTeams: state.teamMembers.dealTeams,
  userData: state.userData.data,
  userOptions: state.userOptions.userOptions || {},
});

const mapDispatchToProps = dispatch => ({
  clearDealStore: () => dispatch(clearDealStore()),
  setDealState: (id, clearStore) => dispatch(loadDeal(id, clearStore)),
  loadDealTeam: params => dispatch(loadDealTeam(params)),
  addDealMember: params => dispatch(addDealMember(params)),
  removeDealMember: params => dispatch(removeDealMember(params)),
  updateDealState: (id, params) => dispatch(updateDeal(id, params)),
});

const DealDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DealDetailPage);

export default DealDetailContainer;
