import {
  FETCH_SHARED_FEES_SUCCESS,
  CREATE_SHARED_FEE_SUCCESS,
  DELETE_SHARED_FEE_SUCCESS,
  UPDATE_SHARED_FEE_SUCCESS,
} from '../actions/action-types';


const initialState = {
  sharedFees: [],
};

const addSharedFeeSuccess = (state, action) => ({
  ...state,
  sharedFees: [...state.sharedFees, action.payload],
});

const deleteSharedFeeSuccess = (state, action) => ({
  ...state,
  sharedFees: state.sharedFees.filter(({ id }) => id !== action.payload),
});

const fetchSharedFeesSuccess = (state, action) => ({
  ...state,
  sharedFees: action.payload,
});

const updateSharedFeeSuccess = (state, action) => ({
  ...state,
  sharedFees: state.sharedFees.map(fee => (
    fee.id !== action.payload.id
      ? fee : action.payload
  )),
});

const sharedFees = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SHARED_FEE_SUCCESS:
      return addSharedFeeSuccess(state, action);
    case DELETE_SHARED_FEE_SUCCESS:
      return deleteSharedFeeSuccess(state, action);
    case FETCH_SHARED_FEES_SUCCESS:
      return fetchSharedFeesSuccess(state, action);
    case UPDATE_SHARED_FEE_SUCCESS:
      return updateSharedFeeSuccess(state, action);
    default:
      return state;
  }
};

export default sharedFees;
