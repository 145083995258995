import React from 'react';
import PropTypes from 'prop-types';
import Currency from '../../../nucleus/formats/currency';

const BreakdownSectionSummary = ({ reducedBy, remaining }) => (
  <div className="breakdown-section-summary">
    <div className="breakdown-section-summary-reduced-by">
      Commission reduced by:
      <Currency
        value={reducedBy}
        emptyState="$0.00"
      />
    </div>
    <div className="breakdown-section-summary-remaining">
      Total remaining commission:
      <Currency
        value={remaining}
        emptyState="$0.00"
      />
    </div>
  </div>
);


BreakdownSectionSummary.propTypes = {
  reducedBy: PropTypes.number,
  remaining: PropTypes.number,
};

BreakdownSectionSummary.defaultProps = {
  reducedBy: undefined,
  remaining: undefined,
};

export default BreakdownSectionSummary;
