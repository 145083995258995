import React from 'react';
import PropTypes from 'prop-types';
import emptyStateImg from '../../assets/images/svg/empty-state.svg';

const AlertsEmptyState = (props) => {
  const { type } = props;
  const formattedType = type === 'task' ? 'tasks' : 'deal activities';

  return (
    <div className="alerts-empty-state">
      <img src={emptyStateImg} alt="No notifications" />
      <p><b>Relax, there are no alerts</b></p>
      <p>{`Reminders and notifications about important ${formattedType} will appear here`}</p>
    </div>
  );
};

export default AlertsEmptyState;

AlertsEmptyState.propTypes = {
  type: PropTypes.string.isRequired,
};
