import superFetch from '../utils/super-fetch';

const addRequirements = (requirements) => {
  const url = '/mbb/requirements';
  const options = {
    method: 'POST',
  };

  return superFetch(url, options, JSON.stringify(requirements))
    .then(data => data.json());
};

const updateRequirements = async (id, params) => {
  const url = `/mbb/requirements/${id}`;
  const options = {
    method: 'PATCH',
  };
  const data = await superFetch(url, options, JSON.stringify(params));
  return data.json();
};

export {
  addRequirements,
  updateRequirements,
};
