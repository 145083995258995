import React from 'react';
import PropTypes from 'prop-types';

class Flyout extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.showFlyout) {
      document.addEventListener('mousedown', this.handleClickOutside);
    } else {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
    return true;
  }

  setWrapperReference = (ele) => {
    this.wrapperReference = ele;
    // safari does not support options for scroll to and therfore look terrible, also we know all macs have trackpads.
    const isSafari = window.safari !== undefined;
    if (ele && ele.scrollIntoView && !isSafari) {
      ele.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  };

  handleClickOutside = (event) => {
    if ((this.wrapperReference && !this.wrapperReference.contains(event.target))) {
      this.props.closeFlyout();
    }
  };

  render() {
    const {
      additionalClassName,
      children,
      position,
      showFlyout,
    } = this.props;

    const renderFlyout = showFlyout
      ? (
        <div
          ref={this.setWrapperReference}
          className={`nd-flyout ${additionalClassName} ${position}`}
        >
          <div ref={this.contentRef}>
            {children}
          </div>
        </div>
      ) : null;

    return (
      <React.Fragment>
        {renderFlyout}
      </React.Fragment>
    );
  }
}

Flyout.propTypes = {
  additionalClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  closeFlyout: PropTypes.func,
  showFlyout: PropTypes.bool.isRequired,
  position: PropTypes.string,
};

Flyout.defaultProps = {
  additionalClassName: undefined,
  children: null,
  closeFlyout: () => { },
  position: 'left',
};

export default Flyout;
