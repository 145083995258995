import { connect } from 'react-redux';
import PropertyDetail from './property-detail';

const mapStateToProps = state => ({
  dealCount: state.deals.dealCount,
  user: state.userData.data,
  featureFlags: state.userOptions.userOptions.feature_flags,
});

// takes props sent from parent and merges with redux state and actions
const mergeProps = (state, actions, props) => ({
  ...state,
  ...actions,
  id: props.match.params.id,
  tab: props.match.params.tab,
  url: props.match.url,
});

const PropertyDetailPageContainer = connect(mapStateToProps, null, mergeProps)(PropertyDetail);

export default PropertyDetailPageContainer;
