import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@cbrebuild/blocks';
import uniqueId from 'lodash/uniqueId';
import FileUploadModal from './file-upload-modal';
import ConfirmationModal from '../../../nucleus/confirmation-modal/confirmation-modal';
import FileUploadModalListItem from './file-upload-modal-list-item';
import Notice from '../../../nucleus/notices/notice';
import dealsService from '../../../services/deals-service';


class FileUploadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      isUploadingFiles: false,
      showFileLimitExceededNotice: false,
      showModal: false,
    };
  }

  dismissNotice = () => {
    this.setState({ showFileLimitExceededNotice: false });
  }

  handleFilesAdded = (fileDataArr) => {
    this.setState({ showModal: true });
    const files = fileDataArr
      .filter((fileData) => {
        if (fileData.size > 1073741824) {
          this.setState({ showFileLimitExceededNotice: true });
          return false;
        }
        return true;
      })
      .map(fileData => ({
        fileData,
        // fileData is a JS file object so be wary as it has some special
        // behavior (i.e. cannot be spread over like regular objects)
        isPrivate: false,
        name: fileData.name, // user chosen attachment name, default to uploaded file name
        tempId: uniqueId(),
        uploadProgress: 0,
      }));
    this.setState(prevState => ({
      files: prevState.files.concat(files),
    }));
  }

  handleRemove = (tempId) => {
    const {
      dealId,
    } = this.props;
    this.setState({
      files: this.state.files.filter((file => file.tempId !== tempId)),
    });

    dealsService.updateDeal(dealId, {
      modified: new Date(),
    });
  }

  handleFileNameChange = (tempId, value) => {
    const {
      dealId,
    } = this.props;

    this.setState({
      files: this.state.files.map(file => (file.tempId === tempId
        ? ({ ...file, name: value })
        : file)),
    });

    dealsService.updateDeal(dealId, {
      modified: new Date(),
    });
  }

  handlePrivacyChange = (tempId, value) => {
    const {
      dealId,
    } = this.props;

    this.setState({
      files: this.state.files.map(file => (file.tempId === tempId
        ? ({ ...file, isPrivate: value })
        : file)),
    });

    dealsService.updateDeal(dealId, {
      modified: new Date(),
    });
  }

  handleProgress = (progressEvent, tempId) => {
    const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
    if (uploadProgress === 100) {
      this.setState({
        files: this.state.files.filter((file => file.tempId !== tempId)),
      });
    } else {
      this.setState({
        files: this.state.files.map(file => (file.tempId === tempId
          ? ({ ...file, uploadProgress })
          : file)),
      });
    }
  };

  closeConfirmationModal = () => {
    this.setState({ showModal: false });
    this.setState({
      isUploadingFiles: false,
      files: [],
    });
  }

  handleUploadFiles = async () => {
    const { files } = this.state;
    const { dealId } = this.props;
    this.setState({ isUploadingFiles: true });
    const promises = files.map(this.sendRequest);
    await Promise.all(promises)
      .then(() => {
        dealsService.updateDeal(dealId, {
          modified: new Date(),
        });
      });
    this.setState({
      isUploadingFiles: false,
      files: [],
    });
  }

  sendRequest = file => new Promise(async (resolve) => {
    const {
      dealId,
      uploadFile,
    } = this.props;
    const {
      fileData,
      isPrivate,
      name,
      tempId,
    } = file;
    const params = {
      deal: dealId,
      title: name,
      // document_name is not mutable, it should
      // always be the original file name
      document_name: fileData.name,
      privacy: isPrivate ? 'private' : 'team',
      is_lease_document: true,
    };
    await uploadFile(fileData, params, e => this.handleProgress(e, tempId));
    resolve();
  })

  testClick = () => {
    console.log(this.state.files);
  }

  render() {
    const {
      files,
      isUploadingFiles,
      showFileLimitExceededNotice,
      showModal,
    } = this.state;

    return (
      // has to be named deal-file-upload because of namespace
      // issue with file-upload
      <div className="voucher-file-upload">
        <div className="dropzone-wrapper">
          <FileUploadModal
            onFilesAdded={this.handleFilesAdded}
            disabled={isUploadingFiles}
          />
        </div>
        <ul>
          {showFileLimitExceededNotice &&
            <Notice
              text="Max file size is 1GB. Some of your files were removed from the list."
              onDismiss={this.dismissNotice}
              type="warning"
            />
          }
          {showModal && files.length > 0 &&
          <ConfirmationModal
            className="deal-file-delete-confirmation voucher-modal"
            showModal={showModal}
          >
            <div className="header-modal">
              <h2 className="header-content">Attach File</h2>
              <IconButton
                iconName="close"
                className="conf-close"
                variant="basic"
                onClick={this.closeConfirmationModal}
              />
            </div>
            <ul>
              {files.map(file => (
                <FileUploadModalListItem
                  key={file.tempId}
                  file={file}
                  type={file.file_type}
                  onFileNameChange={value => this.handleFileNameChange(file.tempId, value)}
                  onPrivacyChange={value => this.handlePrivacyChange(file.tempId, value)}
                  onRemove={value => this.handleRemove(file.tempId, value)}
                  showModal={showModal}
                />
            ))}
            </ul>
            <div className="upload-limit">
              <small>Maximum upload file size: 5GB.</small>
            </div>
            <div className="actions">
              <Button
                className="cancel-btn"
                onClick={this.closeConfirmationModal}
              > Cancel
              </Button>
              <Button
                className="upload-btn"
                disabled={isUploadingFiles}
                onClick={this.handleUploadFiles}
              > Upload
              </Button>
            </div>
          </ConfirmationModal>
  }
        </ul>
      </div>
    );
  }
}

FileUploadButton.propTypes = {
  dealId: PropTypes.number.isRequired,
  uploadFile: PropTypes.func.isRequired,
};

export default FileUploadButton;
