import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Icon } from '@cbrebuild/blocks';
import { isEmpty } from 'lodash';

import Avatar from '../../nucleus/avatar/avatar';
import Flyout from '../../nucleus/flyout/flyout';

const Navatar = ({
  turducken,
  user: {
    avatar,
    first_name,
    last_name,
    auth_groups,
  },
  featureFlags,
  proxy,
}) => {
  const [showFlyout, setShowFlyout] = useState(false);
  const closeFlyout = (e) => {
    e.stopPropagation();
    setShowFlyout(false);
  };

  const bindSetShowFlyout = isOpen => () => {
    setShowFlyout(isOpen);
  };

  const renderedAvatar = (
    <Avatar
      image={avatar}
      firstName={first_name}
      lastName={last_name}
    />
  );
  const pages = [
    {
      icon: 'user',
      link: '/profile/overview',
      text: 'Profile',
    },
    {
      icon: 'group',
      link: '/profile/teams',
      text: 'Teams',
    },
    {
      icon: 'labs',
      link: '/profile/labs',
      text: 'Labs',
    },
    {
      icon: 'eye',
      link: '/profile/switch-proxy',
      hidden: !featureFlags.proxy_user_flag,
      text: 'Proxy Access',
    },
    {
      icon: 'contacts',
      link: '/profile/manage-proxy',
      disabled: true,
      hidden: !featureFlags.proxy_user_flag || !isEmpty(proxy),
      text: 'Grant Proxy Access',
    },
    {
      icon: 'admin',
      link: '/admin',
      text: 'Admin',
      hidden: !(auth_groups && (auth_groups.indexOf('can_access_customer_success_tools') > -1)),
      separator: true,
    },
  ];
  return (
    <div className="navatar">
      <div className="avatar-flyout-container" >
        <div
          className="avatar-row"
          onClick={bindSetShowFlyout(true)}
        >
          {renderedAvatar}
          <Icon iconName="chevron-down" />
        </div>
        <Flyout
          closeFlyout={bindSetShowFlyout(false)}
          position="right"
          showFlyout={showFlyout}
        >
          <ul>
            {pages.map(page => (
              !page.hidden &&
              <li key={page.link} className={page.separator ? 'separator' : ''}>
                <NavLink
                  className={window.location.pathname === page.link ? 'active-nav' : ''}
                  onClick={closeFlyout}
                  to={page.link}
                  {...(!turducken ? {} : { target: '_top' })}
                >
                  <Icon iconName={page.icon} />
                  {page.text}
                </NavLink>
              </li>
            ))}
          </ul>
        </Flyout>
      </div>
    </div>
  );
};

Navatar.propTypes = {
  turducken: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    auth_groups: PropTypes.arrayOf(PropTypes.string),
    avatar: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
  featureFlags: PropTypes.shape({ proxy_user_flag: PropTypes.bool, react_vouchers_flag: PropTypes.bool }),
  proxy: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

Navatar.defaultProps = {
  featureFlags: { proxy_user_flag: false, react_vouchers_flag: false },
};

export default Navatar;
