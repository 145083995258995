import React from 'react';
import PropTypes from 'prop-types';

class ToggleView extends React.Component {
  state = {
    showDefault: true,
  };
  toggle = () => {
    this.setState({
      showDefault: !this.state.showDefault,
    });
  }
  render() {
    return this.state.showDefault
      ? this.props.render(this.toggle)
      : this.props.elseRender(this.toggle);
  }
}
ToggleView.propTypes = {
  render: PropTypes.func.isRequired,
  elseRender: PropTypes.func.isRequired,
};

export default ToggleView;
