import React from 'react';
import PropTypes from 'prop-types';

import FileListItem from '../../file-upload/file-list-item';

class VoucherTab extends React.Component {
  state = {
    queryParams: {
      ordering: '-created',
      page: 1,
      page_size: 25,
      is_lease_document: true,
    },
  };

  componentDidMount() {
    const { queryParams } = this.state;
    const { dealId, fetchFiles } = this.props;
    fetchFiles({ ...queryParams, deal: dealId });
  }

  render() {
    const {
      deleteFile,
      fetchFilesDownloadLink,
      files,
      updateFile,
      userData,
    } = this.props;

    const mostRecentVoucherFile = files && files.length && files[0];
    const overFlowFile =
      files.filter(file =>
        file.title.includes('_overflow') &&
        file.title.includes(mostRecentVoucherFile.title) &&
        !files[0].title.includes('_overflow'));
    if (!mostRecentVoucherFile) {
      return null;
    }

    return (
      <div className="voucher-tab detail-panels-layout">
        <div className="col-two-thirds card-default">
          <ul className="files">
            {mostRecentVoucherFile && (
              <FileListItem
                deleteFile={deleteFile}
                fetchFilesDownloadLink={fetchFilesDownloadLink}
                file={mostRecentVoucherFile}
                key={mostRecentVoucherFile.id}
                updateFile={updateFile}
                userData={userData}
              />
            )}
            {(overFlowFile.length > 0) && overFlowFile[0] && (
              <FileListItem
                deleteFile={deleteFile}
                fetchFilesDownloadLink={fetchFilesDownloadLink}
                file={overFlowFile[0]}
                key={overFlowFile[0].id}
                updateFile={updateFile}
                userData={userData}
              />
            )}
          </ul>
        </div>
      </div>
    );
  }
}

VoucherTab.propTypes = {
  dealId: PropTypes.number.isRequired,
  deleteFile: PropTypes.func.isRequired,
  fetchFiles: PropTypes.func.isRequired,
  fetchFilesDownloadLink: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    deal: PropTypes.number,
    document_name: PropTypes.string,
    title: PropTypes.string,
    file_type: PropTypes.string,
    file_size: PropTypes.number,
    created_by: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      avatar: PropTypes.string,
      email: PropTypes.shape({
        work: PropTypes.string,
      }),
    }),
    created: PropTypes.string,
    modified: PropTypes.string,
    can_edit: PropTypes.bool,
    privacy: PropTypes.string,
    is_lease_document: PropTypes.bool,
  })).isRequired,
  updateFile: PropTypes.func,
  userData: PropTypes.shape({}).isRequired,
};

VoucherTab.defaultProps = {
  updateFile: () => { },
};

export default VoucherTab;
