/* eslint-disable no-multi-assign */
import _ from 'lodash';

export const isValidAddress1 = address => !!address;
export const isValidCity = city => !!city;
export const isValidTerritory = territory => !!territory;
export const isValidPostal = (postal, country) => {
  const regexExp = new RegExp('^[0-9]{5}$');
  if (country === 'United States' || country === 'US') {
    if (postal && regexExp.test(postal)) {
      return true;
    } else if (postal && postal.length > 5) {
      return false;
    }
    return false;
  }
  return true;
};
export const normalizeAddress = (item) => {
  const updatedItem = item;
  if (item) {
    // Contacts use territory & postal. Properties use state_abbreviation & postal_code
    if (item.territory && (item.territory !== item.state_abbreviation)) {
      updatedItem.state_abbreviation = item.territory;
    }
    if (item.postal && (item.postal !== item.postal_code)) {
      updatedItem.postal_code = item.postal;
    }
    if (!item.country || item.country === null || item.country === '') {
      updatedItem.country = 'United States';
    }
  }
  return updatedItem;
};

export const validateAddress = (item, requireAll) => {
  let isValid = false;
  const updatedItem = normalizeAddress(item);

  if (updatedItem) {
    // address is valid if all fields are completed, or if item is a property & all fields are empty
    isValid = _.every([
      !!updatedItem.address,
      !!updatedItem.city,
      !!updatedItem.country,
    ]) || (!requireAll && _.every([
      !updatedItem.address,
      !updatedItem.city,
      !updatedItem.country,
    ]));

    // set error flag if field is empty for highlighting form fields
    if (!updatedItem.error) {
      updatedItem.error = {};
    }
    updatedItem.error.address = !updatedItem.address && !isValid;
    updatedItem.error.city = !updatedItem.city && !isValid;
    updatedItem.error.country = !updatedItem.country && !isValid;

    if (updatedItem.country === 'United States') {
      updatedItem.error.territory = updatedItem.error.state_abbreviation = !updatedItem.state_abbreviation;
      updatedItem.error.postal = updatedItem.error.postal_code = !updatedItem.postal_code;
      isValid = !!(isValid && updatedItem.state_abbreviation && updatedItem.postal_code);
    } else {
      updatedItem.error.territory = updatedItem.error.state_abbreviation = false;
      updatedItem.error.postal = updatedItem.error.postal_code = false;
    }
  }

  return isValid;
};

export const isValidCompanyName = name => !!name;
export const isValidFirstName = fname => !!fname;
export const isValidLastName = lname => !!lname;
export const isValidPurchaserType = voucher_purchaser_type => !!voucher_purchaser_type;
export const isValidNaicsCategory = voucher_naics_category => !!voucher_naics_category;

const getLastPageVisited = (voucherId) => {
  const key = 'last_page_visited_store';
  const lastPageVisitedStore = JSON.parse(window.localStorage.getItem(key)) || {};
  const lastPageVisited = _.clone(lastPageVisitedStore[voucherId]) || 0;

  return lastPageVisited;
};

const setLastPageVisited = (voucherId, pageNumber) => {
  const key = 'last_page_visited_store';
  const lastPageVisitedStore = JSON.parse(window.localStorage.getItem(key)) || {};
  lastPageVisitedStore[voucherId] = pageNumber;
  window.localStorage.setItem(key, JSON.stringify(lastPageVisitedStore));
};

export const getAndSetLastPageVisited = (voucherId, pageNumber) => {
  const lastPageVisited = getLastPageVisited(voucherId);

  // set a new value for last page visited
  // so long as new value is later in voucher flow than previous last page visited
  if (pageNumber > lastPageVisited) {
    setLastPageVisited(voucherId, pageNumber);
  }

  return lastPageVisited;
};

// @param {object} a company or property
export const getAddressString = (location) => {
  const updatedLocation = location;
  let addressString = '';
  if (!location) {
    return addressString;
  }

  // handle commercial property object
  if (updatedLocation.spaces) {
    updatedLocation.address2 = updatedLocation.spaces[0].address_line2;
    updatedLocation.territory = updatedLocation.state_abbreviation;
    updatedLocation.postal = updatedLocation.postal_code;
  }

  // handle company object
  const keysForCompany = ['address', 'address2', 'city', 'territory', 'postal', 'country'];
  _.forEach(keysForCompany, (key) => {
    // set separator
    let separator = ' ';
    if (key === 'address' && !updatedLocation.address2) {
      separator = '\n';
    } else if (key === 'address2') {
      separator = '\n';
    }

    if (key === 'city' && (updatedLocation.territory || updatedLocation.postal)) {
      separator = ', ';
    }

    if (key === 'postal' && updatedLocation.country !== 'United States') {
      separator = '\n';
    }

    // set combined string only when value exists
    if (updatedLocation[key]) {
      if (key === 'country' && updatedLocation[key] === 'United States') {
        return; // per design, do not include country in address string when country is 'United States'
      }
      addressString += updatedLocation[key] + separator;
    }
  });

  return addressString;
};

export const formatItemsInLines = (items) => {
  let contactline = '';
  _.forEach(items, (item, index) => {
    if (item) {
      contactline += item;
    }
    if (items[index + 1]) {
      contactline += '\n';
    }
  });
  return contactline;
};
