import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import Select from '../../nucleus/select/select';

const DealOverview = (props) => {
  const {
    dealType,
    setDealType,
    errorMessage,
    property,
  } = props;

  const dealTypes = [
    {
      display_name: 'Lease - Tenant',
      value: 'tenant',
      dataE2e: 'flyout-tenant',
    },
    {
      display_name: 'Lease - Landlord',
      value: 'landlord',
      dataE2e: 'flyout-landlord',
    },
    {
      display_name: 'Sale - Buyer',
      value: 'buyer',
      dataE2e: 'flyout-buyer',
    },
    {
      display_name: 'Sale - Seller',
      value: 'seller',
      dataE2e: 'flyout-seller',
    },
    {
      display_name: 'Consulting',
      value: 'consulting',
      dataE2e: 'flyout-consulting',
    },
    {
      display_name: 'Other',
      value: 'other',
      dataE2e: 'flyout-other',
    },
  ];

  const typeOfDeal = dealType || 'Select';

  return (
  // if a property exists, then we know this create deal
  // flow is initialized from a property detail view.
  // in this case, the user cannot change the Deal Type - Rep Role
  // or Property info when creating a new deal so these fields
  // are disabled and pre-populated with the appropriate info
    <section className="deal-overview">
      <div className="header">
        <h3>Deal Overview</h3>
      </div>
      <div className="field">
        <label>* Deal Type - Rep Role</label>
        <Select
          id="dealType"
          dataE2e="new-deal-flyout-button"
          disabled={!isEmpty(property)}
          defaultOption={!isEmpty(property) ? dealTypes[1] : typeOfDeal}
          options={dealTypes}
          onChange={setDealType}
          errorMessage={errorMessage}
        />
      </div>
    </section>
  );
};

DealOverview.propTypes = {
  dealType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]).isRequired,
  setDealType: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  property: PropTypes.shape({}),
};

DealOverview.defaultProps = {
  errorMessage: '',
  property: {},
};
export default DealOverview;
