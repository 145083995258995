import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Textarea } from '@cbrebuild/blocks';
import { getShallowChangesFromDeepComparison } from '../../../utils/object-utilities';
import { getLeaseTypes, getProductTypes, getLeaseTypeFromValue, getProductTypeFromValue } from '../../../utils/deal-requirements-utils';
import Select from '../../../nucleus/select/select';
import Datepicker from '../../../nucleus/datepicker/datepicker';

class DealRequirementsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPipelineDetailsPage: window.location.href.includes('pipeline'),
      requirements: {
        deal: undefined,
        product: undefined,
        lease_type: undefined,
        lease_term: '',
        occupancy_date: '',
        asking_price: undefined,
        assignment_expiration: undefined,
        listing_expiration: undefined,
        sqft: {
          max: '',
          min: '',
        },
        sale_price: {
          max: '',
          min: '',
        },
        target_rent: {
          max: '',
          min: '',
        },
        other: '',
      },
      validAssignmentExpiration: true,
      validListingExpiration: true,
      sqftError: undefined,
      salePriceError: undefined,
      targetRentError: undefined,
    };
  }

  componentDidMount() {
    this.initializeRequirements(this.props.deal.requirements);
  }

  handleRequirementsChange = () => {
    const {
      addDealRequirements,
      deal,
      updateDealRequirements,
      updateDeal,
    } = this.props;
    const {
      requirements,
    } = this.state;
    const isValid = this.validateForm();

    if (isValid) {
      const sanitized = this.sanitizeRequirements({ ...requirements, deal: deal.id }, null);
      if (deal.requirements) {
        const changes = getShallowChangesFromDeepComparison(deal.requirements, sanitized);
        delete changes.geom;
        delete changes.deal;

        if (Object.keys(changes).length > 0) {
          updateDealRequirements(deal.requirements.id || requirements.id, {
            ...sanitized,
          }).then(() => {
            updateDeal(deal.id, {
              modified: new Date(),
            });
          });
        }
      } else {
        addDealRequirements({ ...sanitized, deal: deal.id }).then(() => {
          updateDeal(deal.id, {
            modified: new Date(),
          });
        });
      }
    }
  }

  validateForm = () => {
    const {
      requirements: {
        sqft,
        sale_price: salePrice,
        target_rent: targetRent,
      },
      validAssignmentExpiration,
      validListingExpiration,
    } = this.state;
    let isValid = true;
    const errors = {};
    if (!validAssignmentExpiration || !validListingExpiration) {
      isValid = false;
    }
    const sqftMin = parseInt(sqft.min, 10);
    const sqftMax = parseInt(sqft.max, 10);
    if (sqftMin > sqftMax) {
      errors.sqftError = 'SqFt Min must be less than SqFt Max.';
      isValid = false;
    } else if ((sqftMin > 9999999) || (sqftMax > 9999999)) {
      errors.sqftError = 'SqFt must be 7 digits or less.';
      isValid = false;
    } else {
      errors.sqftError = undefined;
    }
    const saleMin = parseInt(salePrice.min, 10);
    const saleMax = parseInt(salePrice.max, 10);
    if (saleMin > saleMax) {
      errors.salePriceError = 'Sale Price Min must be less than Sale Price Max.';
      isValid = false;
    } else {
      errors.salePriceError = undefined;
    }
    const rentMin = parseInt(targetRent.min, 10);
    const rentMax = parseInt(targetRent.max, 10);
    if (rentMin > rentMax) {
      errors.targetRentError = 'Target Rent Min must be less than Target Rent Max.';
      isValid = false;
    } else {
      errors.targetRentError = undefined;
    }
    this.setState({
      sqftError: errors.sqftError,
      salePriceError: errors.salePriceError,
      targetRentError: errors.targetRentError,
    });

    return isValid;
  }

  /**
   * server wants nulls but react wants empty strings
   */
  sanitizeRequirements = (requirements, value) => {
    const sanitized = { ...requirements };
    sanitized.sqft = {
      min: parseInt(sanitized.sqft.min, 10) || value,
      max: parseInt(sanitized.sqft.max, 10) || value,
    };
    sanitized.sale_price = {
      min: parseInt(sanitized.sale_price.min, 10) || value,
      max: parseInt(sanitized.sale_price.max, 10) || value,
    };
    sanitized.target_rent = {
      min: parseInt(sanitized.target_rent.min, 10) || value,
      max: parseInt(sanitized.target_rent.max, 10) || value,
    };
    sanitized.other = sanitized.other || value;
    // server doesn't except certain properties as nulls must remove
    if (value === null) {
      if (sanitized.lease_term === null || sanitized.lease_term === '') {
        sanitized.lease_term = '';
      }
    } else {
      sanitized.lease_term = sanitized.lease_term || value;
    }
    return sanitized;
  }

  initializeRequirements = (requirements) => {
    if (requirements) {
      const newRequirements = this.sanitizeRequirements(requirements, '');
      this.setState({ requirements: newRequirements });
    }
  }

  handleSelectChange = (name, value) => {
    this.setState({
      requirements: {
        ...this.state.requirements,
        [name]: value,
      },
    }, () => this.handleRequirementsChange());
  }

  handleProductTypeChange = ({ value }) => this.handleSelectChange('product', value);
  handleLeaseTypeChange = ({ value }) => this.handleSelectChange('lease_type', value);

  handleDateChange = (name, value) => {
    this.setState({
      requirements: {
        ...this.state.requirements,
        [name]: value,
      },
    });
  }

  handleAssignmentExpirationChange = date => this.handleDateChange('assignment_expiration', date);
  handleListingExpirationChange = date => this.handleDateChange('listing_expiration', date);

  handleDateValidation = (name, isValid) => {
    this.setState({
      [name]: isValid,
    });
  }

  handleAssignmentExpirationValidation = isValid => this.handleDateValidation('validAssignmentExpiration', isValid);
  handleListingExpirationValidation = isValid => this.handleDateValidation('validListingExpiration', isValid);

  handleInputChange = ({ target: { name, value } }) => {
    const positiveOnlyKey = ['sale_price', 'sqft', 'target_rent', 'asking_price'];
    let requirements;
    if (name.includes('-')) {
      const [key, subKey] = name.split('-');
      if (positiveOnlyKey.includes(key) && value < 0) return;
      requirements = {
        ...this.state.requirements,
        [key]: {
          ...this.state.requirements[key],
          [subKey]: value,
        },
      };
    } else {
      requirements = {
        ...this.state.requirements,
        [name]: value,
      };
    }
    this.setState({
      requirements,
    });
  }

  render() {
    const {
      deal: {
        deal_type: dealType,
        rep_role: repRole,
        deal_life_cycle_stage: dealLifeCycleStage,
      },
    } = this.props;
    const {
      isPipelineDetailsPage,
      requirements,
    } = this.state;
    if (dealLifeCycleStage === 'closed') {
      return null;
    }
    return (
      <div className="deal-requirements-form">
        <div className="header">
          <h3>Requirements</h3>
        </div>
        {(dealType === 'other' || dealType === 'consulting')
          && !isPipelineDetailsPage
          &&
          <div className="field">
            <label>Assignment Expiration</label>
            <Datepicker
              onDateChange={this.handleAssignmentExpirationChange}
              onBlur={this.handleRequirementsChange}
              date={requirements.assignment_expiration}
              updateValidity={this.handleAssignmentExpirationValidation}
            />
          </div>
        }
        {!isPipelineDetailsPage &&
          <div className="field">
            <label>Property Type</label>
            <Select
              defaultOption={getProductTypeFromValue(requirements.product) || 'Select'}
              options={getProductTypes()}
              onChange={this.handleProductTypeChange}
            />
          </div>
        }
        {dealType === 'lease'
          &&
          <div className="field">
            <label>Lease Type</label>
            <Select
              defaultOption={getLeaseTypeFromValue(requirements.lease_type) || 'Select'}
              options={getLeaseTypes()}
              onChange={this.handleLeaseTypeChange}
            />
          </div>
        }
        {repRole === 'buyer'
          &&
          <div className={`field range${this.state.salePriceError ? ' requirements-error' : ''}`}>
            <label>Sale Price Range ($)</label>
            <input
              type="number"
              placeholder="Min"
              name="sale_price-min"
              value={requirements.sale_price.min}
              onChange={this.handleInputChange}
              onBlur={this.handleRequirementsChange}
            />
            <span className="hyphen">-</span>
            <input
              type="number"
              placeholder="Max"
              name="sale_price-max"
              value={requirements.sale_price.max}
              onChange={this.handleInputChange}
              onBlur={this.handleRequirementsChange}
            />
          </div>
        }
        {this.state.salePriceError
          &&
          <p className="requirement-error">{this.state.salePriceError}</p>
        }
        {(repRole === 'buyer' || dealType === 'lease')
          &&
          <div className={`field range${this.state.sqftError ? ' requirements-error' : ''}`}>
            <label>SqFt Range</label>
            <input
              type="number"
              placeholder="Min"
              name="sqft-min"
              value={requirements.sqft.min}
              onChange={this.handleInputChange}
              onBlur={this.handleRequirementsChange}
            />
            <span className="hyphen">-</span>
            <input
              type="number"
              placeholder="Max"
              name="sqft-max"
              value={requirements.sqft.max}
              onChange={this.handleInputChange}
              onBlur={this.handleRequirementsChange}
            />
          </div>
        }
        {this.state.sqftError
          &&
          <p className="requirement-error">{this.state.sqftError}</p>
        }
        {dealType === 'lease' && !isPipelineDetailsPage
          &&
          <div className="field">
            <label>Occupancy Date</label>
            <input
              name="occupancy_date"
              onChange={this.handleInputChange}
              onBlur={this.handleRequirementsChange}
              type="text"
              value={requirements.occupancy_date}
            />
          </div>
        }
        {repRole === 'tenant'
          &&
          <div className="field">
            <label>Lease Term</label>
            <input
              type="text"
              name="lease_term"
              value={requirements.lease_term}
              onChange={this.handleInputChange}
              onBlur={this.handleRequirementsChange}
            />
          </div>
        }
        {repRole === 'tenant' && !isPipelineDetailsPage
          &&
          <div className={`field range${this.state.targetRentError ? ' requirements-error' : ''}`}>
            <label>Target Rent</label>
            <input
              type="number"
              placeholder="Min"
              name="target_rent-min"
              value={requirements.target_rent.min}
              onChange={this.handleInputChange}
              onBlur={this.handleRequirementsChange}
            />
            <span className="hyphen">-</span>
            <input
              type="number"
              placeholder="Max"
              name="target_rent-max"
              value={requirements.target_rent.max}
              onChange={this.handleInputChange}
              onBlur={this.handleRequirementsChange}
            />
          </div>
        }
        {this.state.targetRentError
          &&
          <p className="requirement-error">{this.state.targetRentError}</p>
        }
        {repRole === 'seller' && !isPipelineDetailsPage
          &&
          <div className="field">
            <label>Asking Price ($)</label>
            <input
              type="number"
              placeholder="$"
              name="asking_price"
              value={requirements.asking_price}
              onChange={this.handleInputChange}
              onBlur={this.handleRequirementsChange}
            />
          </div>
        }
        {repRole === 'seller' && !isPipelineDetailsPage
          &&
          <div className="field">
            <label>Listing Expiration</label>
            <Datepicker
              onDateChange={this.handleListingExpirationChange}
              onBlur={this.handleRequirementsChange}
              date={requirements.listing_expiration}
              updateValidity={this.handleListingExpirationValidation}
            />
          </div>
        }
        {!isPipelineDetailsPage &&
          <div className="field">
            <label>Other Specs</label>
            <Textarea
              name="other"
              onBlur={this.handleRequirementsChange}
              onChange={this.handleInputChange}
              placeholder="e.g. 20+ Free parking lot"
              rows={{ min: 4, max: 10 }}
              value={requirements.other}
            />
          </div>
        }
      </div>
    );
  }
}

DealRequirementsForm.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.number,
    deal_type: PropTypes.string,
    deal_life_cycle_stage: PropTypes.string,
    rep_role: PropTypes.string,
    requirements: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  addDealRequirements: PropTypes.func.isRequired,
  updateDealRequirements: PropTypes.func.isRequired,
  updateDeal: PropTypes.func.isRequired,
};

export default DealRequirementsForm;
