/* eslint-env browser */
import superFetch from '../utils/super-fetch';

const getChangeLogs = (query) => {
  const url = '/support/changes';

  const params = {
    ordering: '-date',
    page: query && query.page,
    page_size: query && query.page_size,
  };

  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json());
};

const getFeatures = (query) => {
  const url = '/support/features';
  const params = {
    ordering: '-date',
    page: query.page,
    page_size: query.page_size,
  };

  const options = {
    method: 'GET',
    params,
  };

  return superFetch(url, options)
    .then(data => data.json());
};

export default { getChangeLogs, getFeatures };
