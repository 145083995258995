import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import routes from '../../routes';
import historyService from '../../services/history-service';

import SidebarContainer from '../../redux/containers/sidebar-container';
import TopNavContainer from '../../redux/containers/top-nav-container';
import ErrorBoundary from '../../nucleus/error-boundary/error-boundary';
import ProxyNavControl from './proxy-nav-control';

class MainNav extends React.PureComponent {
  constructor(props) {
    super(props);
    MainNav.propTypes = {
      turducken: PropTypes.bool,
      userOptions: PropTypes.shape({
        bleeding_edge_toggle_on: PropTypes.bool,
        can_see_bleeding_edge: PropTypes.bool,
      }).isRequired,
    };

    MainNav.defaultProps = {
      turducken: false,
    };

    this.tabletBreakpoint = 1024;

    this.state = {
      showSidebar: window.innerWidth > this.tabletBreakpoint,
      showGlobalNavigation: true,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setShowGlobalNavigation = (bool) => {
    this.setState({ showGlobalNavigation: bool });
  }

  handleResize = event => (event.target.innerWidth < this.tabletBreakpoint ?
    this.closeSidebar()
    : this.openSidebar());

  openSidebar = () => {
    this.setState({ showSidebar: true });
  }

  closeSidebar = () => {
    this.setState({ showSidebar: false });
  }

  render() {
    const {
      turducken,
    } = this.props;
    const {
      showGlobalNavigation,
      showSidebar,
    } = this.state;

    const displaySidebarClass = showGlobalNavigation ? 'sidebar-showing' : '';
    const topNavContainer = showGlobalNavigation ? (
      <TopNavContainer
        openMenu={this.openSidebar}
        turducken={turducken}
      />
    ) : null;

    const renderSidebar = props => (
      showGlobalNavigation ? (
        <SidebarContainer
          closeMenu={this.closeSidebar}
          location={props.location}
          tabletBreakpoint={this.tabletBreakpoint}
          showSidebar={showSidebar}
          turducken={turducken}
        />
      ) : null
    );

    const renderDashboardRedirect = () => (
      <Redirect push to="/dashboard" />
    );

    /**
     * On route change, it finds the react container scrolls back to the top if it exists
     * This should change and scroll restoration is implemented
     * The one case where this doesn't work is on the labs page where userOptions gets updated
     * and then rerenders the route, there for we do a check to not scroll on the labs page
     */
    const updateScrollPosition = (location) => {
      const reactContainer = document.getElementsByClassName('react-main-content');
      const labsPage = document.getElementsByClassName('admin-labs');

      if (reactContainer[0] && !(labsPage[0] && location.pathname === '/profile/labs')) {
        reactContainer[0].scrollTop = 0;
      }
    };

    const routesDisplay = !turducken && (
      routes.map((route) => {
        const RouteComponent = route.component;
        return (
          <Route
            path={route.path}
            exact={route.exact}
            render={(props) => {
              updateScrollPosition(props.location);
              historyService.onRouteChange();
              props.setShowGlobalNavigation = this.setShowGlobalNavigation; // eslint-disable-line
              props.userOptions = this.props.userOptions; // eslint-disable-line
              return (
                <ErrorBoundary
                  isDeveloper={this.props.userOptions.can_see_bleeding_edge}
                >
                  <RouteComponent {...props} />
                </ErrorBoundary>
              );
            }}
            key={route.path || 'not-found-page'}
          />
        );
      })
    );
    return (
      <BrowserRouter>
        <React.Fragment>
          <Route
            path="/"
            render={renderSidebar}
          />
          <div
            tabIndex="-1"
            role="main"
            className={`layout-column flex flex-fill react-main ${displaySidebarClass}`}
          >
            {topNavContainer}
            <ProxyNavControl />
            <Suspense fallback={<div />}>
              <div className="react-main-content">
                <Switch>
                  <Route
                    path="/"
                    exact
                    render={renderDashboardRedirect}
                  />
                  {routesDisplay}
                </Switch>
              </div>
            </Suspense>
          </div>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

export default MainNav;
